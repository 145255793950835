import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import React, { cloneElement, useState, useContext, useEffect } from 'react'
import { useLocation, useRouteMatch } from 'react-router'
import Drawer from './Drawer'
import Header from './Header'
import AppContext from 'containers/App/Store/AppContext'
import { addDays } from 'date-fns'


const drawerWidth = 240
const dateFormat = require('dateformat')


interface Props {
  children: any
}

const Layout = (props: Props) => {

  const { children } = props
  const theme = useTheme()
  // Desktop Checking
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },

      mainContent: {
        position: 'relative',
        top: settings ? 64 : 70,
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('sm')]: {
          top: settings ? 64 : 70,
          marginLeft: -drawerWidth,
          padding: theme.spacing(3),
        },
      },

      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },

      toolbar: theme.mixins.toolbar,
    })
  )
  let settings = useRouteMatch({
    path: [
      '/authentication/404',
      '/login',
      '/forgot-password',
      '/user/activate/:token',
      '/forgot-password',
      '/user/reset-password/:token',
      '/user/create-password/:token',
      '/qrinvoice'
    ],
    strict: true,
    sensitive: true,
  })
  const classes = useStyles()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  

  // Drawer Toggler
  // const [openDrawer, setOpenDrawer] = useState(true)
  // const handleDrawerToggle = () => {
  //   setOpenDrawer(!openDrawer)
  // }
  const { globalState, dispatch } = useContext(AppContext as any)
  const location = useLocation()
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  })

  useEffect(() => {
    setRoute(prev => ({ to: location.pathname, from: prev.to }))
  }, [location])

  const handleDrawer = () => {
    // if (globalState.drawerOpen) {
    //   document.getElementById('main-header').style.left = '24px'
    //   document.getElementById('main-header').style.width = 'calc(100% - 48px)'
    // } else {
    //   document.getElementById('main-header').style.left = '0px'
    //   document.getElementById('main-header').style.width = 'calc(100% - 288px)'
    //   document.getElementById('main-header').style.right = '24px'
    // }desktop-main-header
    if (globalState.drawerOpen) {
      document?.getElementById('main-header')?.classList.add('full-width')
      document?.getElementById('dynamic-subheader')?.classList.add('full-width')
      document?.getElementById('search-header')?.classList.add('full-width')
      document
        ?.getElementById('creditDebit-header')
        ?.classList.add('full-width')
      document
        ?.getElementById('date-picker-header')
        ?.classList.add('full-width')
      document?.getElementById('footer')?.classList.add('full-width')
      document
        ?.getElementById('dropdown-subheader')
        ?.classList.add('full-width')
    } else {
      document?.getElementById('main-header')?.classList.remove('full-width')
      document
        ?.getElementById('dynamic-subheader')
        ?.classList.remove('full-width')
      document?.getElementById('search-header')?.classList.remove('full-width')
      document
        ?.getElementById('creditDebit-header')
        ?.classList.remove('full-width')
      document
        ?.getElementById('date-picker-header')
        ?.classList.remove('full-width')
      document?.getElementById('footer')?.classList.remove('full-width')
      document
        ?.getElementById('dropdown-subheader')
        ?.classList.remove('full-width')
      document
        ?.getElementById('dropdown-subheader')
        ?.classList.add('full-width')
    }
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }

  useEffect(() => {
    if (route?.from !== route.to) {
      dispatch({
        type: 'drawerOpen',
        payload: true,
      })
    }
  }, [route])
  // console.log('globalState drawer', globalState)
  return (
    <>
      <div className={classes.root}>
        {!settings &&
          (isDesktop ? (
            <Header onDrawerOpen={handleDrawer} />
          ) : // <Header onDrawerOpen={handleDrawer} />
          null)}
        {!settings && (
          <Drawer
            onCloseDrawer={handleDrawer}
            open={isDesktop ? globalState.drawerOpen : !globalState.drawerOpen}
            variant={isDesktop ? 'persistent' : 'temporary'}
          />
        )}
        <main
          className={clsx(classes.mainContent, {
            [classes.contentShift]: globalState.drawerOpen,
          })}
        >
          {cloneElement(children, {})}
        </main>
      </div>
    </>
  )
}
export default Layout
