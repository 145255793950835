import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Description of my custom scalar type */
  MyCustomScalar: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export type AccountMappingGlInput = {
  Charges?: Maybe<Array<GlInput>>;
  Payment?: Maybe<Array<GlInput>>;
  Refund?: Maybe<Array<GlInput>>;
  Deposit?: Maybe<Array<GlInput>>;
  AdvancePayment?: Maybe<Array<GlInput>>;
  OfficialReceipt?: Maybe<Array<GlInput>>;
};

export type AccountMappingResponse = {
  __typename?: 'AccountMappingResponse';
  Description?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  GuestLedgerAcct?: Maybe<Scalars['JSON']>;
};

export type AccountMappingTaxGlInput = {
  TaxGL?: Maybe<Array<TaxGlInput>>;
};

export type ActionLogEntity = InterfaceBase & {
  __typename?: 'ActionLogEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  SoftwareName?: Maybe<Scalars['String']>;
  ActionName: Scalars['String'];
  IPAddress?: Maybe<Scalars['String']>;
  LoginDateTime: Scalars['DateTime'];
  LogoutDateTime?: Maybe<Scalars['DateTime']>;
  SocketID?: Maybe<Scalars['String']>;
};

export type ActionLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  SoftwareName?: Maybe<Scalars['String']>;
  ActionName?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  LoginDateTime?: Maybe<Scalars['DateTime']>;
  LogoutDateTime?: Maybe<Scalars['DateTime']>;
  SocketID?: Maybe<Scalars['String']>;
};

export type ActivitiesEntity = InterfaceBase & {
  __typename?: 'ActivitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  ActivityDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  Debtor?: Maybe<DebtorEntity>;
  Audio?: Maybe<GalleryEntity>;
};

export type ActivitiesInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  ActivityDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};

export type AddConfirmationInput = {
  RegistrationID: Scalars['String'];
  FullName: Scalars['String'];
  Email: Scalars['String'];
};

export type AddInformationInput = {
  RegistrationID: Scalars['String'];
  FullName: Scalars['String'];
  Email: Scalars['String'];
  NRIC: Scalars['String'];
  BirthDate: Scalars['DateTime'];
  MobileNo: Scalars['String'];
  Nationality: Scalars['String'];
  Address?: Maybe<AddressInput>;
  SpecialRequest?: Maybe<Scalars['String']>;
  IsTerm?: Maybe<Scalars['Boolean']>;
  IsPreCheckIn?: Maybe<Scalars['Boolean']>;
  IcPhotoFront: Array<Scalars['Upload']>;
  IcPhotoBack: Array<Scalars['Upload']>;
  Signature: Array<Scalars['Upload']>;
};

export type AdditionalMultiRegInput = {
  RegID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  IdPicture?: Maybe<Scalars['Upload']>;
  AttachmentInput?: Maybe<Scalars['Upload']>;
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AdjustmentTaxOutput = {
  __typename?: 'AdjustmentTaxOutput';
  TTx?: Maybe<Scalars['Boolean']>;
  TTxAmount?: Maybe<Scalars['Float']>;
  SSF?: Maybe<Scalars['Boolean']>;
  SSFAmount?: Maybe<Scalars['Float']>;
};

export type AdvancePaymentEntity = InterfaceTrx & {
  __typename?: 'AdvancePaymentEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedAdvancePaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<PaymentStatus>;
  IsPosted: Scalars['Boolean'];
  IsARPosted: Scalars['Boolean'];
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  CardNo?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['String']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Booking?: Maybe<BookingEntity>;
  Folio?: Maybe<FolioEntity>;
  ForfeitTax?: Maybe<Array<ForfeitTaxEntity>>;
};

export type AdvancePaymentInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedAdvancePaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  AdvancePaymentDate?: Maybe<Scalars['DateTime']>;
  PaymentDate?: Maybe<Scalars['DateTime']>;
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<PaymentStatus>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  einvoice_type?: Maybe<Scalars['String']>;
};

export type AdvPaymentGlEntity = InterfaceTrx & {
  __typename?: 'AdvPaymentGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AdvPaymentType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type AllocationEntity = InterfaceTrx & {
  __typename?: 'AllocationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  DebitRefTable?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  CreditRefTable?: Maybe<Scalars['String']>;
  CreditID?: Maybe<Scalars['String']>;
  AllocationAmount: Scalars['Float'];
  AllocDate?: Maybe<Scalars['DateTime']>;
  CreditDetail: Array<DebitNoteEntity>;
  DebitDetail: Array<CreditNoteEntity>;
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type AmenitiesInput = {
  ID: Scalars['String'];
  GalleryID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ArAccountOsLastPayment = {
  __typename?: 'ARAccountOSLastPayment';
  LastPaymentDT?: Maybe<Scalars['DateTime']>;
  TotalOstandingAmount?: Maybe<Scalars['Float']>;
};

export type ArAccountSummary = {
  __typename?: 'ARAccountSummary';
  DNSum?: Maybe<Scalars['Float']>;
  InvSum?: Maybe<Scalars['Float']>;
  CNSum?: Maybe<Scalars['Float']>;
  RefundSum?: Maybe<Scalars['Float']>;
  ORSum?: Maybe<Scalars['Float']>;
};

export type ArAgingAnalysisRespose = {
  __typename?: 'ARAgingAnalysisRespose';
  day30: Scalars['Float'];
  day60: Scalars['Float'];
  day90: Scalars['Float'];
  day120: Scalars['Float'];
  day150: Scalars['Float'];
  day180: Scalars['Float'];
  daygt180: Scalars['Float'];
};

export type ArSpecification = {
  __typename?: 'ARSpecification';
  Age1?: Maybe<Scalars['Float']>;
  Age2?: Maybe<Scalars['Float']>;
  Age3?: Maybe<Scalars['Float']>;
  Age4?: Maybe<Scalars['Float']>;
  Reminder1?: Maybe<Scalars['Float']>;
  Reminder2?: Maybe<Scalars['Float']>;
  Reminder3?: Maybe<Scalars['Float']>;
  Reminder4?: Maybe<Scalars['Float']>;
  InterestTrx?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  FinalReminder?: Maybe<Scalars['Float']>;
};

export type ArSpecificationInput = {
  Age1?: Maybe<Scalars['Float']>;
  Age2?: Maybe<Scalars['Float']>;
  Age3?: Maybe<Scalars['Float']>;
  Age4?: Maybe<Scalars['Float']>;
  Reminder1?: Maybe<Scalars['Float']>;
  Reminder2?: Maybe<Scalars['Float']>;
  Reminder3?: Maybe<Scalars['Float']>;
  Reminder4?: Maybe<Scalars['Float']>;
  InterestTrx?: Maybe<Scalars['String']>;
  InterestAccount?: Maybe<Scalars['String']>;
  FinalReminder?: Maybe<Scalars['Float']>;
};

export type ArSummaryInfo = {
  __typename?: 'ARSummaryInfo';
  LastCreatedDT?: Maybe<Scalars['DateTime']>;
  TotalOstandingAmount?: Maybe<Scalars['Float']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  noOfDocument?: Maybe<Scalars['Float']>;
};

export enum ArTransactionStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION'
}

export type AssignRoomInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
};

export type AtestEntity = InterfaceTrx & {
  __typename?: 'AtestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  Address?: Maybe<Array<Address>>;
};

export type AtestInput = {
  Address?: Maybe<Array<AddressInput>>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AvailabilityEntity = InterfaceTrx & {
  __typename?: 'AvailabilityEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  DynamicRoom?: Maybe<Scalars['Float']>;
  SystemAdj?: Maybe<Scalars['Float']>;
  Waitlist?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type AvailabilityInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  DynamicRoom?: Maybe<Scalars['Float']>;
  Waitlist?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AvailabilityOutput = {
  __typename?: 'AvailabilityOutput';
  ID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  BlockedRoom?: Maybe<Scalars['Float']>;
  AllottedRoom?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  TotalVacantShow?: Maybe<Scalars['Float']>;
  Waitlist?: Maybe<Scalars['Float']>;
  SeasonType: Scalars['String'];
  Pricing?: Maybe<Array<PricingEntity>>;
};

export type BankAccountEntity = InterfaceTrx & {
  __typename?: 'BankAccountEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BankName?: Maybe<Scalars['String']>;
  AccountHolderName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
  BankContactNo?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  IsActive?: Maybe<Scalars['Boolean']>;
  ContactPerson?: Maybe<Array<ContactPerson>>;
  Hotel?: Maybe<HotelEntity>;
  BankGLAcct?: Maybe<GuestLedgerAcctJson>;
  IsSecurityCash?: Maybe<Scalars['Boolean']>;
  IsKeyCardCash?: Maybe<Scalars['Boolean']>;
  IsCash?: Maybe<Scalars['Boolean']>;
  BankClearance?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type BankAccountInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BankName?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  AccountHolderName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
  BankContactNo?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput>;
  IsSecurityCash?: Maybe<Scalars['Boolean']>;
  IsKeyCardCash?: Maybe<Scalars['Boolean']>;
  IsCash?: Maybe<Scalars['Boolean']>;
  BankClearance?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Array<ContactPersonInput>>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BankDetails = {
  __typename?: 'BankDetails';
  Name?: Maybe<Scalars['String']>;
  AcctHolder?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  Name?: Maybe<Scalars['String']>;
  AcctHolder?: Maybe<Scalars['String']>;
  AcctNo?: Maybe<Scalars['String']>;
  Branch?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BiFolioResponse = {
  __typename?: 'BIFolioResponse';
  Folio?: Maybe<Array<FolioEntity>>;
  TotalFolio?: Maybe<Scalars['Float']>;
};

export type BillLedgerEntity = InterfaceTrx & {
  __typename?: 'BillLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  IncidentalChargeID: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Qty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  IsRoomService?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  CreatedName?: Maybe<Scalars['String']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  RefBillLedgerID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Hotel?: Maybe<HotelEntity>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  BillLedgerSignature?: Maybe<GalleryEntity>;
  TaxBillLedger?: Maybe<Array<BillLedgerTaxEntity>>;
  Payment?: Maybe<PaymentEntity>;
  PrincipalRegistration: RegistrationEntity;
};

export type BillLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  ReversedBillLedgerID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Qty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsDelivered?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  RefBillLedgerID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsRoomService?: Maybe<Scalars['Boolean']>;
  BillLedgerTaxInput?: Maybe<Array<BillLedgerTaxInput>>;
};

export type BillLedgerTaxEntity = InterfaceTrx & {
  __typename?: 'BillLedgerTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  BillLedgerID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  BillLedger?: Maybe<BillLedgerEntity>;
  TaxInfo?: Maybe<TaxEntity>;
};

export type BillLedgerTaxInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BillLedgerID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxSchemeDetailID?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BillScheduleEntity = InterfaceTrx & {
  __typename?: 'BillScheduleEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedBillScheduleID?: Maybe<Scalars['String']>;
  RateID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType?: Maybe<TransactionType>;
  HotelID?: Maybe<Scalars['String']>;
  TransactionID?: Maybe<Scalars['String']>;
  AdvancePaymentID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsPosted: Scalars['Boolean'];
  SplitGroup?: Maybe<Scalars['Float']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  FromRegistration?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  Rate?: Maybe<RateEntity>;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Transaction?: Maybe<IncidentalChargeEntity>;
  TaxBillSchedule?: Maybe<Array<BillScheduleTaxEntity>>;
  TaxInfo?: Maybe<TaxEntity>;
};

export type BillScheduleInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReversedBillScheduleID?: Maybe<Scalars['String']>;
  RateID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType: TransactionType;
  TransactionID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BillScheduleTaxEntity = InterfaceTrx & {
  __typename?: 'BillScheduleTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  BillScheduleID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  BillSchedule?: Maybe<BillScheduleEntity>;
};

export type BillSummaryInput = {
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  IsChooseRoom: Scalars['Boolean'];
  RoomID?: Maybe<Scalars['String']>;
};

export type BiReceiptRefundResponse = {
  __typename?: 'BIReceiptRefundResponse';
  BIListing?: Maybe<Array<PaymentTestClass>>;
  TotalListing?: Maybe<Scalars['Float']>;
};

export type BlockRoomLogEntity = InterfaceTrx & {
  __typename?: 'BlockRoomLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  BlockDate: Scalars['DateTime'];
  ReleaseDate: Scalars['DateTime'];
  ReleaseDT?: Maybe<Scalars['DateTime']>;
  BlockStatusID: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  IsRelease?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
  BlockStatus?: Maybe<RoomStatusEntity>;
  Gallery?: Maybe<GalleryEntity>;
  BlockRoomLogGallery?: Maybe<Array<BlockRoomLogGalleryEntity>>;
  ReleaseRoomStatus: Scalars['String'];
};

export type BlockRoomLogGalleryEntity = InterfaceBase & {
  __typename?: 'BlockRoomLogGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BlockRoomLogID: Scalars['String'];
  GalleryID: Scalars['String'];
  BlockRoomLog?: Maybe<BlockRoomLogEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type BlockRoomLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  BlockDate: Scalars['DateTime'];
  ReleaseDate: Scalars['DateTime'];
  BlockStatusID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsRelease?: Maybe<Scalars['Boolean']>;
  GalleryID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BookingAttachmentDetailEntity = InterfaceBase & {
  __typename?: 'BookingAttachmentDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingAttachmentID?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  BookingAttachment?: Maybe<BookingAttachmentEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type BookingAttachmentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingAttachmentID?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type BookingAttachmentEntity = InterfaceBase & {
  __typename?: 'BookingAttachmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Booking?: Maybe<BookingEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Hotel?: Maybe<HotelEntity>;
  AttachmentDetail?: Maybe<Array<BookingAttachmentDetailEntity>>;
};

export type BookingAttachmentInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type BookingBillScheduleResponse = {
  __typename?: 'BookingBillScheduleResponse';
  BillScheduleAmount?: Maybe<Array<BillScheduleEntity>>;
  DiscountAmount?: Maybe<Array<RateAdjustmentAuditEntity>>;
};

export type BookingCancellation = {
  __typename?: 'BookingCancellation';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NewExtendDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RateAmount?: Maybe<Scalars['Float']>;
  BookingNo?: Maybe<Scalars['String']>;
};

export type BookingEntity = InterfaceTrx & {
  __typename?: 'BookingEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingNo: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  ContactID: Scalars['String'];
  GroupName?: Maybe<Scalars['String']>;
  ChargeToCityLedger?: Maybe<Scalars['Boolean']>;
  IsGroupBooking?: Maybe<Scalars['Boolean']>;
  IsCms?: Maybe<Scalars['Boolean']>;
  IsCmsTtx?: Maybe<Scalars['Boolean']>;
  isPrepaid?: Maybe<Scalars['Boolean']>;
  SalesChannelID?: Maybe<Scalars['String']>;
  Source?: Maybe<Source>;
  SegmentID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  BookingRemark?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BookingStatus: BookingStatus;
  ReferenceNo?: Maybe<Scalars['String']>;
  OTACollection?: Maybe<Scalars['Boolean']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Contact?: Maybe<ContactEntity>;
  SalesChannel?: Maybe<SalesChannelEntity>;
  Segment?: Maybe<SegmentEntity>;
  Registration?: Maybe<Array<RegistrationEntity>>;
  Folio?: Maybe<Array<FolioEntity>>;
  AllRegistration?: Maybe<Array<RegistrationEntity>>;
  BookedRegistration?: Maybe<Array<RegistrationEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  Debtor?: Maybe<DebtorEntity>;
  TotalAdvPayment: Scalars['Float'];
  AdvPaymentIsAdjust: Scalars['Boolean'];
  TotalRoomRate: Scalars['Float'];
};


export type BookingEntityBookedRegistrationArgs = {
  Status?: Maybe<Scalars['String']>;
};

export type BookingInfoResponse = {
  __typename?: 'BookingInfoResponse';
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  RoomInfo?: Maybe<Array<RoomInfoResponse>>;
};

export type BookingInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  ContactID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  GroupName?: Maybe<Scalars['String']>;
  ChargeToCityLedger?: Maybe<Scalars['Boolean']>;
  IsGroupBooking?: Maybe<Scalars['Boolean']>;
  IsCms?: Maybe<Scalars['Boolean']>;
  IsCmsTtx?: Maybe<Scalars['Boolean']>;
  isPrepaid?: Maybe<Scalars['Boolean']>;
  SalesChannelID?: Maybe<Scalars['String']>;
  Source?: Maybe<Source>;
  SegmentID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  BookingRemark?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<BookingStatus>;
  ReferenceNo?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type BookingRoomAssignInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
};

export enum BookingStatus {
  Booked = 'Booked',
  Cancelled = 'Cancelled',
  NoShow = 'NoShow',
  Inhouse = 'Inhouse',
  CheckOut = 'CheckOut',
  Pending = 'Pending',
  Waitlist = 'Waitlist'
}

export type CbExportEntity = InterfaceTrx & {
  __typename?: 'CBExportEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  CBDate?: Maybe<Scalars['DateTime']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  CBType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  DescriptionTransfer?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};

export type ChannelManagerEntity = InterfaceBase & {
  __typename?: 'ChannelManagerEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Pass?: Maybe<Scalars['String']>;
  AuthKey?: Maybe<Scalars['String']>;
  Url?: Maybe<Scalars['String']>;
  HotelMapID?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type ChannelManagerInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Pass: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ChargeType {
  Booking = 'Booking',
  Package = 'Package',
  ExtendStay = 'ExtendStay',
  ExtendStayPkg = 'ExtendStayPkg',
  LateCheckOut = 'LateCheckOut',
  RoomUpgrade = 'RoomUpgrade',
  Taxes = 'Taxes'
}

export type CheckOut = {
  __typename?: 'CheckOut';
  item?: Maybe<Item>;
  code?: Maybe<Scalars['String']>;
  error?: Maybe<Errors>;
  PaySessionNo?: Maybe<Scalars['Float']>;
};

export type CheckoutReinstateResponse = {
  __typename?: 'CheckoutReinstateResponse';
  ReasonCode?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ChooseRoomAllocationEntity = InterfaceTrx & {
  __typename?: 'ChooseRoomAllocationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  LocationID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Location?: Maybe<LocationEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  Room?: Maybe<RoomEntity>;
};

export type ChooseRoomAllocationInput = {
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Input: Array<InputItem>;
};

export type CityLedgerAttachmentEntity = InterfaceBase & {
  __typename?: 'CityLedgerAttachmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InvoiceID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  OfficialReceiptID?: Maybe<Scalars['String']>;
  RefundID?: Maybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  Invoice?: Maybe<InvoiceEntity>;
  CreditNote?: Maybe<CreditNoteEntity>;
  DebitNote?: Maybe<DebitNoteEntity>;
  OfficialReceipt?: Maybe<OfficialReceiptEntity>;
  Refund?: Maybe<RefundEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type CityLedgerAttachmentInput = {
  ID: Scalars['String'];
  InvoiceID?: Maybe<Scalars['String']>;
  CreditNoteID?: Maybe<Scalars['String']>;
  DebitNoteID?: Maybe<Scalars['String']>;
  OfficialReceiptID?: Maybe<Scalars['String']>;
  RefundID?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CmsBookingEntity = InterfaceBase & {
  __typename?: 'CMSBookingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  ChannelManager?: Maybe<Scalars['String']>;
  IsProcess: Scalars['Boolean'];
};

export type CmsLogEntity = InterfaceBase & {
  __typename?: 'CMSLogEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LogDate: Scalars['DateTime'];
  BookingNo?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
};

export type CmsOperationControlEntity = InterfaceBase & {
  __typename?: 'CMSOperationControlEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ChannelManager?: Maybe<Scalars['String']>;
  ProcessType: Scalars['String'];
  ProcessStatus: Scalars['String'];
};

export type CmsOperationControlInput = {
  ID?: Maybe<Scalars['String']>;
  ChannelManager: Scalars['String'];
  ProcessType: Scalars['String'];
  ProcessStatus: Scalars['String'];
  ModifiedDT?: Maybe<Scalars['DateTime']>;
};

export type CmsResponseEntity = InterfaceBase & {
  __typename?: 'CMSResponseEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LogDate: Scalars['DateTime'];
  RawData?: Maybe<Scalars['String']>;
  ResponseData?: Maybe<Scalars['String']>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type CompanyEntity = {
  __typename?: 'companyEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  parentCompanyId?: Maybe<Scalars['String']>;
  baseCurrencyId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  address?: Maybe<Address>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  gstNo?: Maybe<Scalars['String']>;
  ttxNo?: Maybe<Scalars['String']>;
  gstExpiryDate?: Maybe<Scalars['DateTime']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  imageLogoPosition: Scalars['String'];
  watermarkImplementation: Scalars['Boolean'];
  watermarkWording: Scalars['String'];
  isoImplementation: Scalars['Boolean'];
  isoLogoPosition: Scalars['String'];
  companyTax: Scalars['String'];
  recordStatus: Scalars['String'];
  groupGstRegNo?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['DateTime']>;
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  isEInvIntegrated: Scalars['Boolean'];
  Hotel?: Maybe<Array<HotelEntity>>;
};

export type CompanyInput = {
  id?: Maybe<Scalars['String']>;
  parentCompanyId?: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  baseCurrencyId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  companyRegNo?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sstNo?: Maybe<Scalars['String']>;
  gstNo?: Maybe<Scalars['String']>;
  ttxNo?: Maybe<Scalars['String']>;
  gstExpiryDate?: Maybe<Scalars['DateTime']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  eInvClientID?: Maybe<Scalars['String']>;
  eInvClientSecret?: Maybe<Scalars['String']>;
  eInvClientSecret2?: Maybe<Scalars['String']>;
  regionId?: Maybe<Scalars['String']>;
  imageLogoPosition?: Maybe<Scalars['String']>;
  watermarkImplementation?: Maybe<Scalars['Boolean']>;
  watermarkWording?: Maybe<Scalars['String']>;
  isoImplementation?: Maybe<Scalars['Boolean']>;
  isoLogoPosition?: Maybe<Scalars['String']>;
  companyTax?: Maybe<Scalars['String']>;
  recordStatus?: Maybe<Scalars['String']>;
  groupGstRegNo?: Maybe<Scalars['String']>;
  officeTel?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  eSign?: Maybe<Scalars['String']>;
  eSignExpiryDate?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  eInvStartDate?: Maybe<Scalars['String']>;
  eInvEndDate?: Maybe<Scalars['String']>;
  isEInvIntegrated: Scalars['Boolean'];
};

export type CompOutput = {
  __typename?: 'CompOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export enum ComputationRules {
  FlatRate = 'FlatRate',
  PerPerson = 'PerPerson'
}

export enum ComputeMethod {
  TaxOnGross = 'TAX_ON_GROSS',
  TaxOnTaxedPrice = 'TAX_ON_TAXED_PRICE'
}

export type ContactEntity = InterfaceBase & {
  __typename?: 'ContactEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportExpireDate?: Maybe<Scalars['DateTime']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address>;
  Designation?: Maybe<Scalars['String']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  GeneralEmail?: Maybe<Scalars['String']>;
  ReservationEmail?: Maybe<Scalars['String']>;
  BusinessEmail?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  NotificationMobileNo?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  ProfilePictureGallery?: Maybe<GalleryEntity>;
};

export type ContactInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  PhoneNo?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  PassportExpireDate?: Maybe<Scalars['DateTime']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput>;
  ProfilePicture?: Maybe<Scalars['String']>;
  GeneralEmail?: Maybe<Scalars['String']>;
  ReservationEmail?: Maybe<Scalars['String']>;
  BusinessEmail?: Maybe<Scalars['String']>;
  InvoiceEmail?: Maybe<Scalars['String']>;
  OwnerEmail?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  NotificationMobileNo?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  OldGuestID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContractAllotmentEntity = InterfaceBase & {
  __typename?: 'ContractAllotmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  AllotDate: Scalars['DateTime'];
  InitialAllot?: Maybe<Scalars['Float']>;
  Adjust?: Maybe<Scalars['Float']>;
  SysAdjust?: Maybe<Scalars['Float']>;
  PickUp?: Maybe<Scalars['Float']>;
  SysRelease?: Maybe<Scalars['Float']>;
  Contract?: Maybe<ContractEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  AllowDeleteAllotment?: Maybe<Scalars['Boolean']>;
};

export type ContractAllotmentInput = {
  ID?: Maybe<Scalars['String']>;
  ContractID?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  AllotDate?: Maybe<Scalars['DateTime']>;
  InitialAllot?: Maybe<Scalars['Float']>;
  Adjust?: Maybe<Scalars['Float']>;
  SysAdjust?: Maybe<Scalars['Float']>;
  PickUp?: Maybe<Scalars['Float']>;
  SysRelease?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type ContractEntity = InterfaceBase & {
  __typename?: 'ContractEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomUpgrade?: Maybe<Scalars['Boolean']>;
  Elastic?: Maybe<Scalars['Boolean']>;
  Allotment?: Maybe<Scalars['Boolean']>;
  CutOffDays?: Maybe<Scalars['Float']>;
  PaymentTerms?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  IsCMS?: Maybe<Scalars['Boolean']>;
  ChannelManager?: Maybe<Scalars['String']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
  Debtor?: Maybe<DebtorEntity>;
  ContractRate?: Maybe<Array<ContractRateEntity>>;
  ContractAllotment?: Maybe<Array<ContractAllotmentEntity>>;
  ContractAllotmentByRoomType?: Maybe<Array<ContractAllotmentEntity>>;
  AllowDeleteContract?: Maybe<Scalars['Boolean']>;
};

export type ContractInput = {
  ID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContractNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomUpgrade?: Maybe<Scalars['Boolean']>;
  Elastic?: Maybe<Scalars['Boolean']>;
  Allotment?: Maybe<Scalars['Boolean']>;
  CutOffDays?: Maybe<Scalars['Float']>;
  PaymentTerms?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  IsCMS?: Maybe<Scalars['Boolean']>;
  ChannelManager?: Maybe<Scalars['String']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
};

export type ContractRateEntity = InterfaceBase & {
  __typename?: 'ContractRateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RatePolicyID: Scalars['String'];
  Contract?: Maybe<ContractEntity>;
  RatePolicy?: Maybe<RateEntity>;
};

export type ContractRateInput = {
  ID?: Maybe<Scalars['String']>;
  ContractID: Scalars['String'];
  RatePolicyID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CoreLedger = InterfaceTrx & {
  __typename?: 'CoreLedger';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type CoreLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreTaxTrxLedger = InterfaceTrx & {
  __typename?: 'CoreTaxTrxLedger';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
};

export type CoreTrxLedger = InterfaceTrx & {
  __typename?: 'CoreTrxLedger';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
};

export type CoreTrxLedgerInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export enum Country {
  Algeria = 'Algeria',
  AmericanSamoa = 'American_Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  AntiguaAndBarbuda = 'Antigua_and_Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bolivia = 'Bolivia',
  BosniaHerzegovina = 'Bosnia_Herzegovina',
  Brazil = 'Brazil',
  BritishVirginIslands = 'British_Virgin_Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina_Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape_Verde',
  CaymanIslands = 'Cayman_Islands',
  CentralAfricanRepublic = 'Central_African_Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CookIslands = 'Cook_Islands',
  CostaRica = 'Costa_Rica',
  CoteDIvoire = 'Cote_d_Ivoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech_Republic',
  Djibouti = 'Djibouti',
  Denmark = 'Denmark',
  DominicaCommonwealth = 'Dominica_Commonwealth',
  DominicanRepublic = 'Dominican_Republic',
  EastAfrica = 'East_Africa',
  Ecuador = 'Ecuador',
  ElSalvador = 'El_Salvador',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  Egypt = 'Egypt',
  FaeroeIsland = 'Faeroe_Island',
  FalklandIslands = 'Falkland_Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French_Guiana',
  FrenchPolynesia = 'French_Polynesia',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadaloupe = 'Guadaloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea_Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  NorthKorea = 'North_Korea',
  SouthKorea = 'South_Korea',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macau = 'Macau',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall_Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  MiddleEast = 'Middle_East',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'Netherlands_Antilles',
  NewCaledonia = 'New_Caledonia',
  NewZealand = 'New_Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  NiueIsland = 'Niue_Island',
  NorfolkIsland = 'Norfolk_Island',
  NorthernMarianaIslands = 'Northern_Mariana_Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Others = 'Others',
  Pakistan = 'Pakistan',
  Palestine = 'Palestine',
  Palau = 'Palau',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua_New_Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto_Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  Samoa = 'Samoa',
  SanMarino = 'San_Marino',
  SaoTomeAndPrincipe = 'Sao_Tome_and_Principe',
  SaudiArabia = 'Saudi_Arabia',
  Senegal = 'Senegal',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra_Leone',
  Singapore = 'Singapore',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon_Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South_Africa',
  Spain = 'Spain',
  SriLanka = 'Sri_Lanka',
  StHelena = 'St_Helena',
  StKittsAndNevis = 'St_Kitts_and_Nevis',
  StLucia = 'St_Lucia',
  StPierreAndMiquelon = 'St_Pierre_and_Miquelon',
  StVincentAndTheGrenadines = 'St_Vincent_and_the_Grenadines',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad_and_Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks_and_Caicos_Islands',
  Tuvalu = 'Tuvalu',
  UsVirginIslands = 'US_Virgin_Islands',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United_Arab_Emirates',
  UnitedKingdom = 'United_Kingdom',
  UnitedStatesOfAmerica = 'United_States_of_America',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  WallisAndFutuna = 'Wallis_and_Futuna',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe'
}

export type CountryEntity = {
  __typename?: 'countryEntity';
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  einv_code?: Maybe<Scalars['String']>;
};

export type CountryReportOutput = {
  __typename?: 'CountryReportOutput';
  CountryName?: Maybe<Scalars['String']>;
  CountryYRoomCount?: Maybe<Scalars['Float']>;
  CountryYGuestCount?: Maybe<Scalars['Float']>;
  CountryYRoomRevenue?: Maybe<Scalars['Float']>;
  CountryYOtherRevenue?: Maybe<Scalars['Float']>;
  CountryYARR?: Maybe<Scalars['Float']>;
  CountryMRoomCount?: Maybe<Scalars['Float']>;
  CountryMGuestCount?: Maybe<Scalars['Float']>;
  CountryMRoomRevenue?: Maybe<Scalars['Float']>;
  CountryMOtherRevenue?: Maybe<Scalars['Float']>;
  CountryMARR?: Maybe<Scalars['Float']>;
  CountryDRoomCount?: Maybe<Scalars['Float']>;
  CountryDGuestCount?: Maybe<Scalars['Float']>;
  CountryDRoomRevenue?: Maybe<Scalars['Float']>;
  CountryDOtherRevenue?: Maybe<Scalars['Float']>;
  CountryDARR?: Maybe<Scalars['Float']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreditAllocationInput = {
  DebitID?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  DebitRefTable?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
};

export type CreditCardMapping = {
  __typename?: 'CreditCardMapping';
  Name?: Maybe<Scalars['String']>;
};

export type CreditCardMappingInput = {
  Name?: Maybe<Scalars['String']>;
};

export type CreditNoteEntity = InterfaceTrx & {
  __typename?: 'CreditNoteEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<CreditNoteTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  SourceID?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  GroupDocumentNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type CreditNoteEntityInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
};

export type CreditNoteTrxEntity = InterfaceTrx & {
  __typename?: 'CreditNoteTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  CreditNoteTrxTax?: Maybe<Array<CreditNoteTrxTaxEntity>>;
};

export type CreditNoteTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type CreditNoteTrxTaxEntity = InterfaceTrx & {
  __typename?: 'CreditNoteTrxTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  CreditNoteTrxID: Scalars['String'];
  CreditNoteTrx?: Maybe<CreditNoteTrxEntity>;
};

export enum Currency {
  Afn = 'AFN',
  All = 'ALL',
  Dzd = 'DZD',
  Aoa = 'AOA',
  Ars = 'ARS',
  Amd = 'AMD',
  Awg = 'AWG',
  Aud = 'AUD',
  Azn = 'AZN',
  Bsd = 'BSD',
  Bhd = 'BHD',
  Bdt = 'BDT',
  Bbd = 'BBD',
  Byn = 'BYN',
  Bzd = 'BZD',
  Bmd = 'BMD',
  Btn = 'BTN',
  Bob = 'BOB',
  Bam = 'BAM',
  Bwp = 'BWP',
  Brl = 'BRL',
  Gbp = 'GBP',
  Bnd = 'BND',
  Bgn = 'BGN',
  Mmk = 'MMK',
  Bif = 'BIF',
  Khr = 'KHR',
  Cad = 'CAD',
  Cve = 'CVE',
  Kyd = 'KYD',
  Xaf = 'XAF',
  Xpf = 'XPF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Kmf = 'KMF',
  Cdf = 'CDF',
  Crc = 'CRC',
  Hrk = 'HRK',
  Cuc = 'CUC',
  Cup = 'CUP',
  Czk = 'CZK',
  Dkk = 'DKK',
  Djf = 'DJF',
  Dop = 'DOP',
  Xcd = 'XCD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fkp = 'FKP',
  Fjd = 'FJD',
  Gmd = 'GMD',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gtq = 'GTQ',
  Gnf = 'GNF',
  Gyd = 'GYD',
  Htg = 'HTG',
  Hnl = 'HNL',
  Hkd = 'HKD',
  Huf = 'HUF',
  Isk = 'ISK',
  Inr = 'INR',
  Idr = 'IDR',
  Irr = 'IRR',
  Iqd = 'IQD',
  Ils = 'ILS',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Jod = 'JOD',
  Kzt = 'KZT',
  Kes = 'KES',
  Kwd = 'KWD',
  Kgs = 'KGS',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lsl = 'LSL',
  Lrd = 'LRD',
  Lyd = 'LYD',
  Mop = 'MOP',
  Mkd = 'MKD',
  Mga = 'MGA',
  Mwk = 'MWK',
  Myr = 'MYR',
  Mvr = 'MVR',
  Mru = 'MRU',
  Mur = 'MUR',
  Mxn = 'MXN',
  Mdl = 'MDL',
  Mnt = 'MNT',
  Mad = 'MAD',
  Mzn = 'MZN',
  Nad = 'NAD',
  Npr = 'NPR',
  Ang = 'ANG',
  Twd = 'TWD',
  Nzd = 'NZD',
  Nio = 'NIO',
  Ngn = 'NGN',
  Kpw = 'KPW',
  Nok = 'NOK',
  Omr = 'OMR',
  Pkr = 'PKR',
  Pab = 'PAB',
  Pgk = 'PGK',
  Pyg = 'PYG',
  Pen = 'PEN',
  Php = 'PHP',
  Pln = 'PLN',
  Qar = 'QAR',
  Ron = 'RON',
  Rub = 'RUB',
  Rwf = 'RWF',
  Shp = 'SHP',
  Wst = 'WST',
  Stn = 'STN',
  Sar = 'SAR',
  Rsd = 'RSD',
  Scr = 'SCR',
  Sll = 'SLL',
  Sgd = 'SGD',
  Sbd = 'SBD',
  Sos = 'SOS',
  Zar = 'ZAR',
  Krw = 'KRW',
  Ssp = 'SSP',
  Lkr = 'LKR',
  Sdg = 'SDG',
  Srd = 'SRD',
  Szl = 'SZL',
  Sek = 'SEK',
  Chf = 'CHF',
  Syp = 'SYP',
  Tjs = 'TJS',
  Tzs = 'TZS',
  Thb = 'THB',
  Top = 'TOP',
  Ttd = 'TTD',
  Tnd = 'TND',
  Try = 'TRY',
  Tmt = 'TMT',
  Ugx = 'UGX',
  Uah = 'UAH',
  Aed = 'AED',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vuv = 'VUV',
  Ves = 'VES',
  Vnd = 'VND',
  Xof = 'XOF',
  Yer = 'YER',
  Zmw = 'ZMW'
}

export type CurrencyEntity = {
  __typename?: 'currencyEntity';
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  symbol: Scalars['String'];
  format: Scalars['String'];
  common_status: Scalars['String'];
};

export type CurrentInhouseOutput = {
  __typename?: 'CurrentInhouseOutput';
  RoomNo?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
};

export type CurrentTaxDetailsOutput = {
  __typename?: 'CurrentTaxDetailsOutput';
  HotelID?: Maybe<Scalars['String']>;
  GovTax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
};

export type CurrentTaxOutput = {
  __typename?: 'currentTaxOutput';
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
};

export type CustomizedDocumentEntity = InterfaceBase & {
  __typename?: 'CustomizedDocumentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DocType?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type DashBoardRespond = {
  __typename?: 'DashBoardRespond';
  DueIn?: Maybe<Scalars['Float']>;
  TotalDueIn?: Maybe<Scalars['Float']>;
  TotalInHouseRoom?: Maybe<Scalars['Float']>;
  DueOut?: Maybe<Scalars['Float']>;
  TotalDueOut?: Maybe<Scalars['Float']>;
  TotalGuest?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalUnAssignedRoomRegistration?: Maybe<Scalars['Float']>;
  RevenueToday?: Maybe<Scalars['Float']>;
  RevenueMonthToDay?: Maybe<Scalars['Float']>;
  CollectionToday?: Maybe<Scalars['Float']>;
  CollectionMonthToDay?: Maybe<Scalars['Float']>;
  CurrentOccupiedRoom?: Maybe<Scalars['Float']>;
  CurrentInHouse?: Maybe<Scalars['Float']>;
  CurrentComplimentary?: Maybe<Scalars['Float']>;
  CurrentDayUse?: Maybe<Scalars['Float']>;
  CurrentOthers?: Maybe<Scalars['Float']>;
  CurrentOccupancyRate?: Maybe<Scalars['Float']>;
  CurrentArr?: Maybe<Scalars['Float']>;
  CurrentRoomRevenue?: Maybe<Scalars['Float']>;
  ProjectOccupiedRoom?: Maybe<Scalars['Float']>;
  ProjectInHouse?: Maybe<Scalars['Float']>;
  ProjectComplimentary?: Maybe<Scalars['Float']>;
  ProjectDayUse?: Maybe<Scalars['Float']>;
  ProjectOthers?: Maybe<Scalars['Float']>;
  ProjectOccupancyRate?: Maybe<Scalars['Float']>;
  ProjectArr?: Maybe<Scalars['Float']>;
  ProjectRoomRevenue?: Maybe<Scalars['Float']>;
};

export type Data = {
  __typename?: 'data';
  ID?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  osAmt?: Maybe<Scalars['String']>;
};


export type DebitAllocationInput = {
  CreditID?: Maybe<Scalars['String']>;
  DebitID?: Maybe<Scalars['String']>;
  AllocationAmount: Scalars['Float'];
  CreditRefTable?: Maybe<Scalars['String']>;
  DebitRefTable?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
};

export type DebitNoteEntity = InterfaceTrx & {
  __typename?: 'DebitNoteEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<DebitNoteTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  QrCodeUrl?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type DebitNoteEntityInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
};

export type DebitNoteTrxEntity = InterfaceTrx & {
  __typename?: 'DebitNoteTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  DebitNoteTrxTax?: Maybe<Array<DebitNoteTrxTaxEntity>>;
};

export type DebitNoteTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type DebitNoteTrxTaxEntity = InterfaceTrx & {
  __typename?: 'DebitNoteTrxTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  DebitNoteTrxID: Scalars['String'];
  DebitNoteTrx?: Maybe<DebitNoteTrxEntity>;
  TaxInfo?: Maybe<TaxEntity>;
};

export enum DebtorCategory {
  Corporate = 'Corporate',
  TravelAgent = 'TravelAgent',
  Government = 'Government'
}

export type DebtorContactEntity = InterfaceBase & {
  __typename?: 'DebtorContactEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  ContactID: Scalars['String'];
  SalesChannelID: Scalars['String'];
  Debtor?: Maybe<DebtorEntity>;
  Contact?: Maybe<ContactEntity>;
};

export type DebtorContactInput = {
  ID: Scalars['String'];
  DebtorID: Scalars['String'];
  ContactID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type DebtorEntity = InterfaceBase & {
  __typename?: 'DebtorEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ContactID: Scalars['String'];
  DebtorProfileID?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  CreditCardMapping?: Maybe<Array<CreditCardMapping>>;
  DebtorCategory?: Maybe<DebtorCategory>;
  Name: Scalars['String'];
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorAccountRemark?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CreditTerm?: Maybe<Scalars['Float']>;
  CreditLimit?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  SalesChannel?: Maybe<Scalars['String']>;
  DebtorContacts?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
  IsIndividual?: Maybe<Scalars['Boolean']>;
  IseCommerce?: Maybe<Scalars['Boolean']>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  IsFrontDeskActive?: Maybe<Scalars['Boolean']>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  Hotel?: Maybe<HotelEntity>;
  Contact?: Maybe<ContactEntity>;
  DebtorType?: Maybe<DebtorTypeEntity>;
  SegmentDetails?: Maybe<SegmentEntity>;
  Contract?: Maybe<Array<ContractEntity>>;
  Booking?: Maybe<Array<BookingEntity>>;
  DebtorContact?: Maybe<Array<DebtorContactEntity>>;
  DebtorProfile?: Maybe<DebtorProfileEntity>;
  accessToken?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  Tin?: Maybe<Scalars['String']>;
  TaxRegNo?: Maybe<Scalars['String']>;
  InvoiceType?: Maybe<InvoiceType>;
  LastBooking?: Maybe<Scalars['DateTime']>;
  ARTotalOutstanding: Scalars['Float'];
  ARTotalDue: Scalars['Float'];
  LastPaymentDate?: Maybe<Scalars['DateTime']>;
};

export type DebtorInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ContactID?: Maybe<Scalars['String']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  DebtorContacts?: Maybe<Scalars['String']>;
  DebtorCategory?: Maybe<DebtorCategory>;
  CreditCardMapping?: Maybe<Array<CreditCardMappingInput>>;
  Name: Scalars['String'];
  DebtorAccount?: Maybe<Scalars['String']>;
  DebtorAccountRemark?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CreditTerm?: Maybe<Scalars['Float']>;
  CreditLimit?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Segment?: Maybe<Scalars['String']>;
  SalesChannel?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
  IsIndividual?: Maybe<Scalars['Boolean']>;
  IseCommerce?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  IsFrontDeskActive?: Maybe<Scalars['Boolean']>;
  Tin?: Maybe<Scalars['String']>;
  TaxRegNo?: Maybe<Scalars['String']>;
  InvoiceType?: Maybe<InvoiceType>;
};

export type DebtorPaymentInput = {
  InvoiceID?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
};

export type DebtorProfileEntity = InterfaceBase & {
  __typename?: 'DebtorProfileEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  Debtor?: Maybe<Array<DebtorEntity>>;
  Contact?: Maybe<ContactEntity>;
  accessToken?: Maybe<Scalars['String']>;
};

export type DebtorProfileInput = {
  ID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type DebtorTypeEntity = InterfaceBase & {
  __typename?: 'DebtorTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorType: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ControlAccount?: Maybe<Scalars['String']>;
  DepositAccount?: Maybe<Scalars['String']>;
  AdvanceAccount?: Maybe<Scalars['String']>;
  AccrualAccount?: Maybe<Scalars['String']>;
  RevenueAccount?: Maybe<Scalars['String']>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  Hotel?: Maybe<HotelEntity>;
};

export type DebtorTypeInput = {
  HotelID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ControlAccount?: Maybe<Scalars['String']>;
  DepositAccount?: Maybe<Scalars['String']>;
  AdvanceAccount?: Maybe<Scalars['String']>;
  AccrualAccount?: Maybe<Scalars['String']>;
  RevenueAccount?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export type DepartmentEntity = InterfaceBase & {
  __typename?: 'DepartmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason: Scalars['Boolean'];
  AllowSalesChannel: Scalars['Boolean'];
  AllowIncidental: Scalars['Boolean'];
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
  IsServiceRequest: Scalars['Boolean'];
  Hotel?: Maybe<HotelEntity>;
  SalesChannel?: Maybe<Array<SalesChannelEntity>>;
  Reason?: Maybe<Array<ReasonEntity>>;
  IncidentalCharge?: Maybe<Array<IncidentalChargeEntity>>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
};

export type DepartmentInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason?: Maybe<Scalars['Boolean']>;
  AllowSalesChannel?: Maybe<Scalars['Boolean']>;
  AllowIncidental?: Maybe<Scalars['Boolean']>;
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsServiceRequest?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DepartmentReportOutput = {
  __typename?: 'DepartmentReportOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export enum DepositClass {
  SecurityDeposit = 'SecurityDeposit',
  KeyCardDeposit = 'KeyCardDeposit',
  Refund = 'Refund',
  Forfeiture = 'Forfeiture',
  Reversed = 'Reversed'
}

export type DepositGlEntity = InterfaceTrx & {
  __typename?: 'DepositGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DepositType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type DepositInput = {
  FolioType: FolioType;
  FolioAmount: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DepositClass: DepositClass;
  PaymentType: PaymentType;
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
};

export type DepositLedgerEntity = InterfaceTrx & {
  __typename?: 'DepositLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  ReversedID?: Maybe<Scalars['String']>;
  DepositClass: DepositClass;
  PaymentType: PaymentType;
  TrxDate: Scalars['DateTime'];
  DepositAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  ActualDepositClass?: Maybe<Scalars['String']>;
};

export type DepositLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  DepositClass: DepositClass;
  DepositNo?: Maybe<Scalars['String']>;
  PaymentType: PaymentType;
  TrxDate: Scalars['DateTime'];
  DepositAmount: Scalars['Float'];
  ReferenceNo: Scalars['String'];
  ReversedID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DepositResponse = {
  __typename?: 'DepositResponse';
  DepositResult?: Maybe<Scalars['MyCustomScalar']>;
};

export enum DiscountType {
  ByAmount = 'By_Amount',
  ByPercentage = 'By_Percentage'
}

export enum DiscrepancyReason {
  Skip = 'Skip',
  Sleep = 'Sleep'
}

export type DisplaySequenceInput = {
  ID?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
};

export type DocResponse = {
  __typename?: 'docResponse';
  Invoice?: Maybe<Array<Test>>;
  CnDoc?: Maybe<Array<CreditNoteEntity>>;
  DnDoc?: Maybe<Array<DebitNoteEntity>>;
  OrDoc?: Maybe<Array<OfficialReceiptEntity>>;
  RefundDoc?: Maybe<Array<RefundEntity>>;
};

export type DocumentNumberDetailEntity = InterfaceBase & {
  __typename?: 'DocumentNumberDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
  DocumentNumberHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export type DocumentNumberDetailInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
};

export type DocumentNumberHeaderEntity = InterfaceBase & {
  __typename?: 'DocumentNumberHeaderEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  Type: Scalars['String'];
  SampleOutput?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  DocumentNumberDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
};

export type DocumentNumberHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  Type: Scalars['String'];
  RefTable?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
};

export type DocumentNumberingEntity = InterfaceBase & {
  __typename?: 'DocumentNumberingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Booking?: Maybe<Scalars['Float']>;
  Incidental?: Maybe<Scalars['Float']>;
  Deposit?: Maybe<Scalars['Float']>;
  Receipt?: Maybe<Scalars['Float']>;
  Folio?: Maybe<Scalars['Float']>;
  Invoice?: Maybe<Scalars['Float']>;
  CreditNote?: Maybe<Scalars['Float']>;
  DebitNote?: Maybe<Scalars['Float']>;
  Refund?: Maybe<Scalars['Float']>;
  HotelRefund?: Maybe<Scalars['Float']>;
  PaySession?: Maybe<Scalars['Float']>;
  ConsolidatedEInvoice?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type DocumentNumberingInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Booking?: Maybe<Scalars['Float']>;
  Incidental?: Maybe<Scalars['Float']>;
  Deposit?: Maybe<Scalars['Float']>;
  Receipt?: Maybe<Scalars['Float']>;
  Folio?: Maybe<Scalars['Float']>;
  CreditNote?: Maybe<Scalars['Float']>;
  DebitNote?: Maybe<Scalars['Float']>;
  Refund?: Maybe<Scalars['Float']>;
  HotelRefund?: Maybe<Scalars['Float']>;
  PaySession?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DocumentTemplateEntity = InterfaceBase & {
  __typename?: 'DocumentTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RegCard?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
};

export type DocumentTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  RegCard?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
};

export type DynamicRateJson = {
  __typename?: 'DynamicRateJson';
  Tier?: Maybe<Scalars['Float']>;
  RoomQuota?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type DynamicRateJsonInput = {
  Tier?: Maybe<Scalars['Float']>;
  RoomQuota?: Maybe<Scalars['Float']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type EditConfirmationInput = {
  RegistrationID: Scalars['String'];
  ContactID: Scalars['String'];
  FullName: Scalars['String'];
  Email: Scalars['String'];
  OldFullName?: Maybe<Scalars['String']>;
  OldNRIC?: Maybe<Scalars['String']>;
};

export type EditInformationInput = {
  RegistrationID: Scalars['String'];
  ContactID: Scalars['String'];
  FullName: Scalars['String'];
  Email: Scalars['String'];
  OldFullName?: Maybe<Scalars['String']>;
  OldNRIC?: Maybe<Scalars['String']>;
  NRIC: Scalars['String'];
  BirthDate: Scalars['DateTime'];
  MobileNo: Scalars['String'];
  Nationality: Scalars['String'];
  Address?: Maybe<AddressInput>;
  SpecialRequest?: Maybe<Scalars['String']>;
  IsTerm?: Maybe<Scalars['Boolean']>;
  IsPreCheckIn?: Maybe<Scalars['Boolean']>;
  IcPhotoFront?: Maybe<Array<Scalars['Upload']>>;
  IcPhotoBack?: Maybe<Array<Scalars['Upload']>>;
  Signature: Array<Scalars['Upload']>;
};

export type EditRegInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  OldRoomTypeID?: Maybe<Scalars['String']>;
  OldRatePolicyID?: Maybe<Scalars['String']>;
  OldNoOfRoom?: Maybe<Scalars['Float']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  OldRoomRate?: Maybe<Scalars['Float']>;
  OldArrivalDate?: Maybe<Scalars['DateTime']>;
  OldDepartureDate?: Maybe<Scalars['DateTime']>;
  NewRoomTypeID?: Maybe<Scalars['String']>;
  NewRatePolicyID?: Maybe<Scalars['String']>;
  NewNoOfRoom?: Maybe<Scalars['Float']>;
  NewPromoCode?: Maybe<Scalars['String']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
  NewRoomRate?: Maybe<Scalars['Float']>;
  Mode?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  OverbookRoomNo?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
};

export type EditTaxPolicyInput = {
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetailInput>;
  taxId: Scalars['String'];
  deletedId: Array<Scalars['String']>;
};

export type EditTaxSchemeInput = {
  createdBy: Scalars['String'];
  modBy: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  taxSchemeDetail?: Maybe<Array<TaxSchemeDetailInput>>;
  id?: Maybe<Scalars['String']>;
};

export type EDocParamsEntity = InterfaceBase & {
  __typename?: 'EDocParamsEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocType: Scalars['String'];
  ColName: Scalars['String'];
};

export type EDocTemplateEntity = InterfaceBase & {
  __typename?: 'EDocTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DocType?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  TemplateTitle?: Maybe<Scalars['String']>;
  IsDefault: Scalars['Boolean'];
};

export type EDocTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DocType?: Maybe<Scalars['String']>;
  TemplateBody?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  TemplateTitle?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type EffectiveRateJson = {
  __typename?: 'EffectiveRateJson';
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ComputationRule?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type EffectiveRateJsonInput = {
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ComputationRule?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type EInvoiceClassificationEntity = {
  __typename?: 'eInvoiceClassificationEntity';
  id: Scalars['String'];
  classificationCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type EInvoiceConsolidationDetailEntity = InterfaceTrx & {
  __typename?: 'EInvoiceConsolidationDetailEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  ConsolidationID: Scalars['String'];
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
  Amount: Scalars['Float'];
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmt: Scalars['Float'];
  DocAmt: Scalars['Float'];
  EIConsolidation?: Maybe<EInvoiceConsolidationEntity>;
};

export type EInvoiceConsolidationDetailInput = {
  ID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ConsolidationID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  RefRecordID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
};

export type EInvoiceConsolidationEntity = InterfaceTrx & {
  __typename?: 'EInvoiceConsolidationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocDate: Scalars['DateTime'];
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<EInvoiceConsolidationTrxType>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  EIConsolidationDetail?: Maybe<Array<EInvoiceConsolidationDetailEntity>>;
  Module?: Maybe<Scalars['String']>;
};

export type EInvoiceConsolidationInput = {
  ID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_trx_type?: Maybe<Scalars['String']>;
  einvoice_sent_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_send_reason?: Maybe<Scalars['String']>;
  einvoice_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_date?: Maybe<Scalars['DateTime']>;
  einvoice_failed_validation_reason?: Maybe<Scalars['String']>;
  einvoice_cancelled_by?: Maybe<Scalars['String']>;
  einvoice_cancelled_date?: Maybe<Scalars['DateTime']>;
  einvoice_cancelled_reason?: Maybe<Scalars['String']>;
  einvoice_rejected_request_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_confirm_by?: Maybe<Scalars['String']>;
  einvoice_rejected_confirm_date?: Maybe<Scalars['DateTime']>;
  einvoice_rejected_reason?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
};

export enum EInvoiceConsolidationTrxType {
  Invoice = 'INVOICE',
  DebitNote = 'DEBIT_NOTE',
  CreditNote = 'CREDIT_NOTE'
}

export type EInvoiceMsicEntity = {
  __typename?: 'eInvoiceMsicEntity';
  id: Scalars['String'];
  msicCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  categoryReference?: Maybe<Scalars['String']>;
};

export type EInvoiceQrResponse = {
  __typename?: 'EInvoiceQrResponse';
  ID?: Maybe<Scalars['String']>;
  DocStatus?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  QrCodeUrl?: Maybe<Scalars['String']>;
};

export type EInvoiceSubscriptionEntity = InterfaceBase & {
  __typename?: 'EInvoiceSubscriptionEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
};

export enum EInvoiceType {
  Account = 'Account',
  Individual = 'Individual'
}

export type EmailAttachmentInput = {
  filename?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  cid?: Maybe<Scalars['String']>;
};

export type EmailBodyInput = {
  Body: Scalars['String'];
  Header: Scalars['String'];
};

export type EmailCampignEntity = InterfaceBase & {
  __typename?: 'EmailCampignEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsSent?: Maybe<Scalars['Boolean']>;
  GalleryID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  ShareDate?: Maybe<Scalars['DateTime']>;
  EmailDate?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type EmailCampignInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  IsDraft?: Maybe<Scalars['Boolean']>;
  IsSent?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GalleryID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Attachment?: Maybe<Scalars['Upload']>;
  ShareDate?: Maybe<Scalars['DateTime']>;
  EmailDate?: Maybe<Scalars['DateTime']>;
};

export type EmailLogEntity = InterfaceTrx & {
  __typename?: 'EmailLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type EmailTemplateEntity = InterfaceBase & {
  __typename?: 'EmailTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Welcome?: Maybe<Scalars['String']>;
  ThankYou?: Maybe<Scalars['String']>;
  Prepaid?: Maybe<Scalars['String']>;
  Postpaid?: Maybe<Scalars['String']>;
  CancelBooking?: Maybe<Scalars['String']>;
};

export type EmailTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  Welcome?: Maybe<Scalars['String']>;
  ThankYou?: Maybe<Scalars['String']>;
  Prepaid?: Maybe<Scalars['String']>;
  CancelBooking?: Maybe<Scalars['String']>;
  PostPaid?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type EntRoleUsrAsgEntity = AuditEntity & {
  __typename?: 'EntRoleUsrAsgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type EntRoleUsrAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  hotelID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type Errors = {
  __typename?: 'Errors';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  debug?: Maybe<Scalars['String']>;
};

export type ExportedGlTransferResponse = {
  __typename?: 'ExportedGLTransferResponse';
  AccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  JournalTypeName?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalDate?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  DepartmentCode?: Maybe<Scalars['String']>;
  MasterCOACode?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type ExtendStayOutPut = {
  __typename?: 'ExtendStayOutPut';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NewExtendDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type FacilityEntity = InterfaceBase & {
  __typename?: 'FacilityEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  FacilityGallery?: Maybe<Array<FacilityGalleryEntity>>;
};

export type FacilityGalleryEntity = InterfaceBase & {
  __typename?: 'FacilityGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FacilityID: Scalars['String'];
  GalleryID: Scalars['String'];
  Facility?: Maybe<FacilityEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type FacilityGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  FacilityID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FacilityInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FolioEntity = InterfaceBase & {
  __typename?: 'FolioEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  FolioType?: Maybe<FolioType>;
  RefID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  RefFolioID?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  ClosedDate?: Maybe<Scalars['DateTime']>;
  ClosedBy?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  SendEInvoice?: Maybe<Scalars['Boolean']>;
  IsSuspend?: Maybe<Scalars['Boolean']>;
  IsPabx?: Maybe<Scalars['Boolean']>;
  FolioName?: Maybe<Scalars['String']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  BeneficiaryAddress?: Maybe<Scalars['String']>;
  NonGuestInfo?: Maybe<NonGuestInfoJson>;
  DocumentType?: Maybe<Scalars['String']>;
  IsConsolidated?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  QrCodeUrl?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  einvoice_type?: Maybe<EInvoiceType>;
  Hotel?: Maybe<HotelEntity>;
  Booking?: Maybe<BookingEntity>;
  Registration?: Maybe<RegistrationEntity>;
  RefFolio?: Maybe<FolioEntity>;
  RoomLedger?: Maybe<Array<RoomLedgerEntity>>;
  RoomCharges?: Maybe<Array<RoomLedgerEntity>>;
  RoomChargesSuspend?: Maybe<Array<RoomLedgerEntity>>;
  TaxLedger?: Maybe<Array<TaxLedgerEntity>>;
  DepositLedger?: Maybe<Array<DepositLedgerEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  NonGuestBalance: NonGuestBalance;
};

export type FolioHistoryResponse = {
  __typename?: 'FolioHistoryResponse';
  InvoiceNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  FolioType?: Maybe<Scalars['String']>;
};

export type FolioInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  FolioType?: Maybe<FolioType>;
  RefID?: Maybe<Scalars['String']>;
  RefFolioID?: Maybe<Scalars['String']>;
  InvoiceNo?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  ClosedDate?: Maybe<Scalars['DateTime']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  BeneficiaryAddress?: Maybe<Scalars['String']>;
  ClosedBy?: Maybe<Scalars['String']>;
  NonGuestInfo?: Maybe<NonGuestInfoJsonInput>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsSuspend?: Maybe<Scalars['Boolean']>;
  SendEInvoice?: Maybe<Scalars['Boolean']>;
  einvoice_type?: Maybe<EInvoiceType>;
};

export type FolioOutput = {
  __typename?: 'FolioOutput';
  FolioID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  FolioType?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  RegID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  RegistrationStatus?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  RoomRate?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  Signature?: Maybe<Scalars['String']>;
};

export enum FolioType {
  GuestFolio = 'GuestFolio',
  GroupFolio = 'GroupFolio',
  NonGuest = 'NonGuest',
  DepositFolio = 'DepositFolio',
  OpenFolio = 'OpenFolio',
  RefundForfeiture = 'RefundForfeiture'
}

export type ForecastOutPut = {
  __typename?: 'ForecastOutPut';
  Category?: Maybe<Scalars['String']>;
  TodayDate?: Maybe<Scalars['Float']>;
  TomorrowDate?: Maybe<Scalars['Float']>;
  DayAfterTomorrow?: Maybe<Scalars['Float']>;
};

export type ForfeitTaxEntity = InterfaceTrx & {
  __typename?: 'ForfeitTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  PaymentID: Scalars['String'];
  SourceTable?: Maybe<Scalars['String']>;
  SourceType?: Maybe<Scalars['String']>;
  AdvancePayment?: Maybe<AdvancePaymentEntity>;
  Payment?: Maybe<PaymentEntity>;
  TaxInfo?: Maybe<TaxEntity>;
};

export type FrontDeskInfoLineResponse = {
  __typename?: 'FrontDeskInfoLineResponse';
  TotalDueIn?: Maybe<Scalars['Float']>;
  TotalCheckIn?: Maybe<Scalars['Float']>;
  TotalGroupDueIn?: Maybe<Scalars['Float']>;
  TotalGroupCheckIn?: Maybe<Scalars['Float']>;
  TotalWalkIn?: Maybe<Scalars['Float']>;
  TotalDepartureDueOut?: Maybe<Scalars['Float']>;
  DepartureDueOut?: Maybe<Scalars['Float']>;
  TotalRegistrationDueOut?: Maybe<Scalars['Float']>;
  RegistrationDueOut?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalGuest?: Maybe<Scalars['Float']>;
  DepositSum?: Maybe<Scalars['Float']>;
  TotalDepositRoom?: Maybe<Scalars['Float']>;
  TotalUnAssignedRoomRegistration?: Maybe<Scalars['Float']>;
  TotalNonGuestFolio?: Maybe<Scalars['Float']>;
  TotalSuspendFolio?: Maybe<Scalars['Float']>;
  TotalContactTracingOutlet?: Maybe<Scalars['Float']>;
};

export type FrontDeskInfoResponse = {
  __typename?: 'FrontDeskInfoResponse';
  RegistrationID?: Maybe<Scalars['String']>;
  BillScheduleSum?: Maybe<Scalars['Float']>;
  IncidentalChargesSum?: Maybe<Scalars['Float']>;
  TotalOutStandingAmount?: Maybe<Scalars['Float']>;
  DepositSum?: Maybe<Scalars['Float']>;
  FolioCount?: Maybe<Scalars['Float']>;
  PaymentSum?: Maybe<Scalars['Float']>;
  AdvPaymentSum?: Maybe<Scalars['Float']>;
  ServiceRequestCount?: Maybe<Scalars['Float']>;
};

export type FrontDeskPaymentInput = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  PaymentType?: Maybe<PaymentType>;
  Amount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<ChargeType>;
  PaymentStatus?: Maybe<PaymentStatus>;
  CheckOutID?: Maybe<Scalars['String']>;
  PaymentClass?: Maybe<PaymentClass>;
  TransactionType?: Maybe<TransactionType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  BillLedgerID?: Maybe<Scalars['String']>;
  RoomLedgerID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  LedgerTypeID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ModuleName?: Maybe<Scalars['String']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  FromRegistration?: Maybe<Scalars['String']>;
  eInvoiceType?: Maybe<Scalars['String']>;
};

export type FrontDeskResponse = {
  __typename?: 'FrontDeskResponse';
  BookingNo?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  Code?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  IncidentalAmt?: Maybe<Scalars['Float']>;
  PaymentAmt?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  PaymentID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<ChargeType>;
  DepositClass?: Maybe<DepositClass>;
  PaymentType?: Maybe<PaymentType>;
  FolioID?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  DepositID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionType>;
};

export type GaInHouseResponse = {
  __typename?: 'GAInHouseResponse';
  GuestListing?: Maybe<Array<RegistrationEntity>>;
  GuestBilling?: Maybe<Array<PaymentFoilioResponse>>;
};

export type GalleryEntity = InterfaceBase & {
  __typename?: 'GalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type GalleryInput = {
  ID: Scalars['String'];
  AccountID: Scalars['String'];
  BucketFileName?: Maybe<Scalars['String']>;
  ImageURL?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
};

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export type GeneralTinEntity = {
  __typename?: 'GeneralTinEntity';
  id: Scalars['String'];
  tinParty?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  displaySeq?: Maybe<Scalars['Float']>;
};

export type GlAccountPeriodEntity = {
  __typename?: 'GLAccountPeriodEntity';
  AccountPeriodID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  MonthEndClose?: Maybe<Scalars['Boolean']>;
  YearEndClose?: Maybe<Scalars['Boolean']>;
  FYear?: Maybe<Scalars['String']>;
  FPeriod?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
};

export type GlExportEntity = InterfaceTrx & {
  __typename?: 'GLExportEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchNo?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['DateTime'];
  TrxDate: Scalars['DateTime'];
  GLType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Submenu?: Maybe<Scalars['String']>;
  Registration?: Maybe<HotelEntity>;
};

export type GlInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  GL?: Maybe<GuestLedgerAcctJsonInput>;
};

export type GlPostResponse = {
  __typename?: 'GLPostResponse';
  GLPost?: Maybe<Scalars['Boolean']>;
  ExportedGLTransfer?: Maybe<Array<ExportedGlTransferResponse>>;
};

export type GroupInHouseOutput = {
  __typename?: 'GroupInHouseOutput';
  GroupName?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type GuestAppFeatureEntity = InterfaceBase & {
  __typename?: 'GuestAppFeatureEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  MyProfile?: Maybe<Scalars['Boolean']>;
  Booking?: Maybe<Scalars['Boolean']>;
  CheckIn?: Maybe<Scalars['Boolean']>;
  ScanQR?: Maybe<Scalars['Boolean']>;
  Directories?: Maybe<Scalars['Boolean']>;
  CheckOut?: Maybe<Scalars['Boolean']>;
  MyRequest?: Maybe<Scalars['Boolean']>;
  PackageRedemption?: Maybe<Scalars['Boolean']>;
  LateCheckOut?: Maybe<Scalars['Boolean']>;
  ExtendStay?: Maybe<Scalars['Boolean']>;
  Bills?: Maybe<Scalars['Boolean']>;
  RoomService?: Maybe<Scalars['Boolean']>;
  OnlineShop?: Maybe<Scalars['Boolean']>;
  DigitalKey?: Maybe<Scalars['Boolean']>;
  SelfCheckin?: Maybe<Scalars['Boolean']>;
  SelfChooseRoom?: Maybe<Scalars['Boolean']>;
  PreCheckinAdvTime?: Maybe<Scalars['Float']>;
  Chargeable?: Maybe<Scalars['Boolean']>;
  DefaultTrx?: Maybe<Scalars['String']>;
  OnlinePayment?: Maybe<Scalars['Boolean']>;
  Incidental?: Maybe<IncidentalChargeEntity>;
  OperationHour?: Maybe<Scalars['String']>;
  CloseHour?: Maybe<Scalars['String']>;
};

export type GuestAppSettingInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  MyProfile?: Maybe<Scalars['Boolean']>;
  Booking?: Maybe<Scalars['Boolean']>;
  CheckIn?: Maybe<Scalars['Boolean']>;
  ScanQR?: Maybe<Scalars['Boolean']>;
  Directories?: Maybe<Scalars['Boolean']>;
  CheckOut?: Maybe<Scalars['Boolean']>;
  MyRequest?: Maybe<Scalars['Boolean']>;
  PackageRedemption?: Maybe<Scalars['Boolean']>;
  LateCheckOut?: Maybe<Scalars['Boolean']>;
  ExtendStay?: Maybe<Scalars['Boolean']>;
  Bills?: Maybe<Scalars['Boolean']>;
  RoomService?: Maybe<Scalars['Boolean']>;
  OnlineShop?: Maybe<Scalars['Boolean']>;
  DigitalKey?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  SelfCheckin?: Maybe<Scalars['Boolean']>;
  SelfChooseRoom?: Maybe<Scalars['Boolean']>;
  PreCheckinAdvTime?: Maybe<Scalars['Float']>;
  Chargeable?: Maybe<Scalars['Boolean']>;
  DefaultTrx?: Maybe<Scalars['String']>;
  OnlinePayment?: Maybe<Scalars['Boolean']>;
  OperationHour?: Maybe<Scalars['String']>;
  CloseHour?: Maybe<Scalars['String']>;
};

export type GuestLedgerAcctInput = {
  Div?: Maybe<Scalars['String']>;
  Dept?: Maybe<Scalars['String']>;
  Acct?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type GuestLedgerAcctJson = {
  __typename?: 'GuestLedgerAcctJson';
  RevenueDiv?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  ExpenseDiv?: Maybe<Scalars['String']>;
  ExpenseDept?: Maybe<Scalars['String']>;
  ExpenseDebit?: Maybe<Scalars['String']>;
  ExpenseCredit?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  ReverseType?: Maybe<Scalars['String']>;
  ExpenseType?: Maybe<Scalars['String']>;
};

export type GuestLedgerAcctJsonInput = {
  RevenueDiv?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  ExpenseDiv?: Maybe<Scalars['String']>;
  ExpenseDept?: Maybe<Scalars['String']>;
  ExpenseDebit?: Maybe<Scalars['String']>;
  ExpenseCredit?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  ReverseType?: Maybe<Scalars['String']>;
  ExpenseType?: Maybe<Scalars['String']>;
};

export type GuestMovementEntity = InterfaceBase & {
  __typename?: 'GuestMovementEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GuestID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  GuestProfile?: Maybe<GuestProfileEntity>;
  Booking?: Maybe<BookingEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type GuestMovementInput = {
  ID?: Maybe<Scalars['String']>;
  GuestID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type GuestPackageRedeem = {
  __typename?: 'GuestPackageRedeem';
  GuestName?: Maybe<Scalars['String']>;
  IsRedeem?: Maybe<Scalars['Boolean']>;
  IsBillLedger?: Maybe<Scalars['Boolean']>;
  BillLedgerID?: Maybe<Scalars['String']>;
  Pax?: Maybe<Scalars['Float']>;
};

export type GuestProfileEntity = InterfaceBase & {
  __typename?: 'GuestProfileEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Company?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Scalars['String']>;
  IcBackPicture?: Maybe<Scalars['String']>;
  PassportPicture?: Maybe<Scalars['String']>;
  NewsLetter?: Maybe<Scalars['Boolean']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  Booking?: Maybe<Array<BookingEntity>>;
  ICGallery?: Maybe<GalleryEntity>;
  ICBackGallery?: Maybe<GalleryEntity>;
  PassportGallery?: Maybe<GalleryEntity>;
  Contact?: Maybe<ContactEntity>;
  Hotel?: Maybe<HotelEntity>;
  accessToken?: Maybe<Scalars['String']>;
  GuestType?: Maybe<GuestType>;
  SpecialRequest?: Maybe<Scalars['String']>;
  GuestHistoryList: Array<RegistrationEntity>;
  GuestBookedRoomList: Array<RegistrationEntity>;
  GuestHistoryCount: Scalars['Float'];
  ServiceRequestCount: Scalars['Float'];
  NoOfVisit: Scalars['Float'];
  LastYearOfVisit: Scalars['Float'];
};

export type GuestProfileInput = {
  ID: Scalars['String'];
  ContactID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  Login?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  VehicleNo?: Maybe<Scalars['String']>;
  NewsLetter?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Scalars['String']>;
  IcBackPicture?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  PassportPicture?: Maybe<Scalars['String']>;
  GuestType?: Maybe<GuestType>;
  DebtorID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type GuestProfileResponse = {
  __typename?: 'GuestProfileResponse';
  GuestProfile?: Maybe<Array<GuestProfileEntity>>;
  TotalGuestProfile?: Maybe<Scalars['Float']>;
};

export type GuestReviewInfoLineResponse = {
  __typename?: 'GuestReviewInfoLineResponse';
  InActivated?: Maybe<Scalars['Float']>;
  LessThan3Star?: Maybe<Scalars['Float']>;
  MoreThan3Star?: Maybe<Scalars['Float']>;
};

export enum GuestType {
  Normal = 'Normal',
  Vip1 = 'VIP1',
  Vip2 = 'VIP2',
  Vip3 = 'VIP3',
  Vip4 = 'VIP4',
  Vip5 = 'VIP5',
  Skipper = 'Skipper',
  Blacklist = 'Blacklist'
}

export type HlsBookingRecordEntity = InterfaceBase & {
  __typename?: 'HLSBookingRecordEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
};

export type HlsBookingRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelAvailabilityOutput = {
  __typename?: 'HotelAvailabilityOutput';
  RoomTypesList?: Maybe<Array<RoomTypesListOutput>>;
  Occupancy?: Maybe<Array<Occupancy>>;
};

export type HotelEntity = InterfaceBase & {
  __typename?: 'HotelEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelName?: Maybe<Scalars['String']>;
  HotelAddress?: Maybe<Address>;
  SSTRegNo?: Maybe<Scalars['String']>;
  TTxRegNo?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Logo?: Maybe<Scalars['String']>;
  HotelType?: Maybe<PropertyType>;
  ContactID?: Maybe<Scalars['String']>;
  CheckInTime?: Maybe<Scalars['DateTime']>;
  CheckOutTime?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Scalars['String']>;
  NAProcessTime?: Maybe<Scalars['DateTime']>;
  LiveDate?: Maybe<Scalars['DateTime']>;
  IsPostPaid?: Maybe<Scalars['Boolean']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  IsOnlinePayment?: Maybe<Scalars['Boolean']>;
  Tax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsInclusive?: Maybe<Scalars['Boolean']>;
  TTx?: Maybe<Scalars['Float']>;
  HTx?: Maybe<Scalars['Float']>;
  Levy?: Maybe<Scalars['Float']>;
  CheckInPrompt: Scalars['Boolean'];
  SecurityDeposit?: Maybe<Scalars['Float']>;
  KeycardDeposit?: Maybe<Scalars['Float']>;
  CheckInRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckInRoomStatusID?: Maybe<Scalars['String']>;
  TransferRoomStatusID?: Maybe<Scalars['String']>;
  BlockRoomReleaseStatusID?: Maybe<Scalars['String']>;
  CheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CeilingPercentage?: Maybe<Scalars['Float']>;
  FloorPercentage?: Maybe<Scalars['Float']>;
  DayUseID?: Maybe<Scalars['String']>;
  HouseUseID?: Maybe<Scalars['String']>;
  ComplimentaryID?: Maybe<Scalars['String']>;
  FloorPriceKickInDays?: Maybe<Scalars['Float']>;
  AllowCancellationDays?: Maybe<Scalars['Float']>;
  OnlineTnC?: Maybe<Scalars['String']>;
  RegCardTnC?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  GuestAppURL?: Maybe<Scalars['String']>;
  TravelAgentURL?: Maybe<Scalars['String']>;
  CorporateURL?: Maybe<Scalars['String']>;
  AboutHotelGalleryID?: Maybe<Scalars['String']>;
  AboutHotel?: Maybe<Scalars['String']>;
  ARSpecification?: Maybe<ArSpecification>;
  ChannelManagerID?: Maybe<Scalars['String']>;
  PaymentGatewayID?: Maybe<Scalars['String']>;
  OnlinePaymentStoreID?: Maybe<Scalars['String']>;
  KeycardVendor?: Maybe<Scalars['String']>;
  HotelCode?: Maybe<Scalars['String']>;
  GuestLedgerAcct?: Maybe<Scalars['JSON']>;
  RegulationClause?: Maybe<Scalars['String']>;
  PDPA?: Maybe<Scalars['String']>;
  OnlinePaymentTnC?: Maybe<Scalars['String']>;
  DocumentRemark?: Maybe<Scalars['String']>;
  GuestNotice?: Maybe<Scalars['Boolean']>;
  StateFund?: Maybe<Scalars['Boolean']>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsDayUse?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  IsOnlineBooking?: Maybe<Scalars['Boolean']>;
  IsOOO?: Maybe<Scalars['Boolean']>;
  IsOOI?: Maybe<Scalars['Boolean']>;
  IsFloorPlan?: Maybe<Scalars['Boolean']>;
  CompanyName?: Maybe<Scalars['String']>;
  EInvoiceSCClassification?: Maybe<Scalars['String']>;
  EInvoiceRCClassification?: Maybe<Scalars['String']>;
  EInvoiceAdvPaymentClassification?: Maybe<Scalars['String']>;
  EInvoiceDepositForfeitClassification?: Maybe<Scalars['String']>;
  EInvoiceStateTaxClassification?: Maybe<Scalars['String']>;
  IsCmsAllotment?: Maybe<Scalars['Boolean']>;
  EmailBlastDays?: Maybe<Scalars['Float']>;
  Q3StatisticsMapping?: Maybe<Q3StatisticsMapping>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  RoundingGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<HotelQ3RevenueAcctJson>;
  Encoders?: Maybe<Scalars['JSON']>;
  Denomination?: Maybe<Scalars['Float']>;
  RoundingMethod?: Maybe<Scalars['String']>;
  IsRoomTaxable?: Maybe<Scalars['Boolean']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  AdvanceForfeitTaxID?: Maybe<Scalars['String']>;
  DepositForfeitTaxID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MSICCode?: Maybe<Scalars['String']>;
  MSICDesc?: Maybe<Scalars['String']>;
  EInvEffectiveDate?: Maybe<Scalars['DateTime']>;
  SOAFooter?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  CheckInRoomStatus?: Maybe<RoomStatusEntity>;
  CancelCheckInRoomStatus?: Maybe<RoomStatusEntity>;
  TransferRoomStatus?: Maybe<RoomStatusEntity>;
  CheckOutRoomStatus?: Maybe<RoomStatusEntity>;
  BlockRoomReleaseStatus?: Maybe<RoomStatusEntity>;
  CancelCheckOutRoomStatus?: Maybe<RoomStatusEntity>;
  DayUse?: Maybe<RateEntity>;
  HouseUse?: Maybe<RateEntity>;
  Complimentary?: Maybe<RateEntity>;
  AboutHotelImage?: Maybe<GalleryEntity>;
  LogoImage?: Maybe<GalleryEntity>;
  SOAImage?: Maybe<GalleryEntity>;
  ChannelManager?: Maybe<ChannelManagerEntity>;
  PaymentGateway?: Maybe<PaymentGatewayEntity>;
  Facility?: Maybe<Array<FacilityEntity>>;
  News?: Maybe<Array<NewsEntity>>;
  Segment?: Maybe<Array<SegmentEntity>>;
  DocumentNumbering?: Maybe<DocumentNumberingEntity>;
  HotelRating?: Maybe<Array<HotelRatingEntity>>;
  Department?: Maybe<Array<DepartmentEntity>>;
  Promotion?: Maybe<Array<PromotionEntity>>;
  Location?: Maybe<Array<LocationEntity>>;
  RoomStatus?: Maybe<Array<RoomStatusEntity>>;
  RoomType?: Maybe<Array<RoomTypeEntity>>;
  Room?: Maybe<Array<RoomEntity>>;
  SeasonCalendar?: Maybe<Array<SeasonCalendarEntity>>;
  LateCheckOutCharge?: Maybe<Array<LateCheckOutChargeEntity>>;
  RatePolicy?: Maybe<Array<RateEntity>>;
  Debtor?: Maybe<Array<DebtorEntity>>;
  DebtorType?: Maybe<Array<DebtorTypeEntity>>;
  HouseKeepingLog?: Maybe<Array<HouseKeepingLogEntity>>;
  BlockRoomLog?: Maybe<Array<BlockRoomLogEntity>>;
  RoomDiscrepancyLog?: Maybe<Array<RoomDiscrepancyLogEntity>>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
  Booking?: Maybe<Array<BookingEntity>>;
  EmailCampign?: Maybe<Array<EmailCampignEntity>>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  Folio?: Maybe<Array<FolioEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  Price?: Maybe<Array<PricingOutput>>;
  HotelDebtor?: Maybe<DebtorEntity>;
  HotelRate?: Maybe<Array<RoomTypeEntity>>;
  HotelGallery?: Maybe<Array<HotelGalleryEntity>>;
  HotelAdjustmentIncidentalID?: Maybe<Scalars['String']>;
  WeightedAverage: Scalars['Float'];
};

export type HotelGalleryEntity = InterfaceBase & {
  __typename?: 'HotelGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type HotelGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelName?: Maybe<Scalars['String']>;
  HotelAddress?: Maybe<AddressInput>;
  SSTRegNo?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Logo?: Maybe<Scalars['String']>;
  HotelType?: Maybe<PropertyType>;
  ContactID?: Maybe<Scalars['String']>;
  CheckInTime?: Maybe<Scalars['DateTime']>;
  CheckOutTime?: Maybe<Scalars['DateTime']>;
  Currency?: Maybe<Scalars['String']>;
  NAProcessTime?: Maybe<Scalars['DateTime']>;
  IsPostPaid?: Maybe<Scalars['Boolean']>;
  Tax?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  IsInclusive?: Maybe<Scalars['Boolean']>;
  IsRoomTaxable?: Maybe<Scalars['Boolean']>;
  Q3StatisticsMapping?: Maybe<Q3StatisticsMappingInput>;
  TTx?: Maybe<Scalars['Float']>;
  HTx?: Maybe<Scalars['Float']>;
  Levy?: Maybe<Scalars['Float']>;
  CheckInPrompt?: Maybe<Scalars['Boolean']>;
  SecurityDeposit?: Maybe<Scalars['Float']>;
  KeycardDeposit?: Maybe<Scalars['Float']>;
  CheckInRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckInRoomStatusID?: Maybe<Scalars['String']>;
  TransferRoomStatusID?: Maybe<Scalars['String']>;
  CheckOutRoomStatusID?: Maybe<Scalars['String']>;
  CancelCheckOutRoomStatusID?: Maybe<Scalars['String']>;
  BlockRoomReleaseStatusID?: Maybe<Scalars['String']>;
  CeilingPercentage?: Maybe<Scalars['Float']>;
  FloorPercentage?: Maybe<Scalars['Float']>;
  DayUseID?: Maybe<Scalars['String']>;
  HotelCode?: Maybe<Scalars['String']>;
  HouseUseID?: Maybe<Scalars['String']>;
  ComplimentaryID?: Maybe<Scalars['String']>;
  FloorPriceKickInDays?: Maybe<Scalars['Float']>;
  AllowCancellationDays?: Maybe<Scalars['Float']>;
  OnlineTnC?: Maybe<Scalars['String']>;
  OnlinePaymentTnC?: Maybe<Scalars['String']>;
  RegCardTnC?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  GuestAppURL?: Maybe<Scalars['String']>;
  TravelAgentURL?: Maybe<Scalars['String']>;
  CorporateURL?: Maybe<Scalars['String']>;
  AboutHotelGalleryID?: Maybe<Scalars['String']>;
  AboutHotel?: Maybe<Scalars['String']>;
  ARSpecification?: Maybe<ArSpecificationInput>;
  ChannelManagerID?: Maybe<Scalars['String']>;
  PaymentGatewayID?: Maybe<Scalars['String']>;
  RegulationClause?: Maybe<Scalars['String']>;
  PDPA?: Maybe<Scalars['String']>;
  DocumentRemark?: Maybe<Scalars['String']>;
  GuestNotice?: Maybe<Scalars['Boolean']>;
  StateFund?: Maybe<Scalars['Boolean']>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsDayUse?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  IsOOO?: Maybe<Scalars['Boolean']>;
  IsOOI?: Maybe<Scalars['Boolean']>;
  IsFloorPlan?: Maybe<Scalars['Boolean']>;
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsCmsAllotment?: Maybe<Scalars['Boolean']>;
  EmailBlastDays?: Maybe<Scalars['Float']>;
  IsChargeToRoom?: Maybe<Scalars['Boolean']>;
  IsOnlineBooking?: Maybe<Scalars['Boolean']>;
  IsOnlinePayment?: Maybe<Scalars['Boolean']>;
  AdvanceForfeitTaxID?: Maybe<Scalars['String']>;
  DepositForfeitTaxID?: Maybe<Scalars['String']>;
  MSICCode?: Maybe<Scalars['String']>;
  MSICDesc?: Maybe<Scalars['String']>;
  EInvoiceSCClassification?: Maybe<Scalars['String']>;
  EInvoiceRCClassification?: Maybe<Scalars['String']>;
  EInvoiceAdvPaymentClassification?: Maybe<Scalars['String']>;
  EInvoiceDepositForfeitClassification?: Maybe<Scalars['String']>;
  EInvoiceStateTaxClassification?: Maybe<Scalars['String']>;
  SOAFooter?: Maybe<Scalars['String']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
};

export type HotelNotificationEntity = InterfaceBase & {
  __typename?: 'HotelNotificationEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  ReadStatus?: Maybe<Scalars['Boolean']>;
  NotificationType?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  RefItem?: Maybe<Scalars['String']>;
  User?: Maybe<UserEntity>;
};

export type HotelNotificationInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  ReadStatus?: Maybe<Scalars['Boolean']>;
  NotificationType?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  RefItem?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

/** These permissions are latest applicable for HMS */
export enum HotelPermission {
  BookingIgnoreView = 'BOOKING_IGNORE_______VIEW',
  BookingIgnoreCreate = 'BOOKING_IGNORE_______CREATE',
  BookingBookingInfoEdit = 'BOOKING_BOOKING_INFO_______EDIT',
  BookingCloneBookingCreate = 'BOOKING_CLONE_BOOKING_______CREATE',
  BookingRoomingListListView = 'BOOKING_ROOMING_LIST_LIST____VIEW',
  BookingRoomingListListCreate = 'BOOKING_ROOMING_LIST_LIST____CREATE',
  BookingRoomingListListEdit = 'BOOKING_ROOMING_LIST_LIST____EDIT',
  BookingRoomingListListDelete = 'BOOKING_ROOMING_LIST_LIST____DELETE',
  BookingRoomAssignmentListView = 'BOOKING_ROOM_ASSIGNMENT_LIST____VIEW',
  BookingRoomAssignmentListAssignment = 'BOOKING_ROOM_ASSIGNMENT_LIST____ASSIGNMENT',
  BookingSpecialRequestView = 'BOOKING_SPECIAL_REQUEST_______VIEW',
  BookingSpecialRequestCreate = 'BOOKING_SPECIAL_REQUEST_______CREATE',
  BookingAdvancePaymentInfoView = 'BOOKING_ADVANCE_PAYMENT_INFO____VIEW',
  BookingAdvancePaymentInfoCreate = 'BOOKING_ADVANCE_PAYMENT_INFO____CREATE',
  BookingAdvancePaymentInfoEdit = 'BOOKING_ADVANCE_PAYMENT_INFO____EDIT',
  BookingAttachmentsCreate = 'BOOKING_ATTACHMENTS____CREATE',
  BookingAttachmentsEdit = 'BOOKING_ATTACHMENTS____EDIT',
  BookingAttachmentsDelete = 'BOOKING_ATTACHMENTS____DELETE',
  BookingRemarksView = 'BOOKING_REMARKS_______VIEW',
  BookingRemarksCreate = 'BOOKING_REMARKS____CREATE',
  BookingBillingInstructionView = 'BOOKING_BILLING_INSTRUCTION_______VIEW',
  BookingBillingInstructionCreate = 'BOOKING_BILLING_INSTRUCTION_______CREATE',
  BookingHouseLimitView = 'BOOKING_HOUSE_LIMIT_______VIEW',
  BookingHouseLimitCreate = 'BOOKING_HOUSE_LIMIT_______CREATE',
  BookingCancelBookingCancel = 'BOOKING_CANCEL_BOOKING_______CANCEL',
  BookingCancelRoomCancel = 'BOOKING_CANCEL_ROOM_______CANCEL',
  BookingConfirmLetterView = 'BOOKING_CONFIRM_LETTER_______VIEW',
  BookingAttachmentsView = 'BOOKING_ATTACHMENTS_______VIEW',
  BookingBillingScheduleView = 'BOOKING_BILLING_SCHEDULE_______VIEW',
  BookingBillingScheduleEdit = 'BOOKING_BILLING_SCHEDULE_______EDIT',
  BookingAuditlogView = 'BOOKING_AUDITLOG_______VIEW',
  FrontDeskIgnoreView = 'FRONT_DESK_IGNORE_______VIEW',
  FrontDeskCheckInView = 'FRONT_DESK_CHECK_IN_______VIEW',
  FrontDeskCheckInWalkInCreate = 'FRONT_DESK_CHECK_IN_WALK_IN____CREATE',
  FrontDeskCheckInAssignment = 'FRONT_DESK_CHECK_IN_______ASSIGNMENT',
  FrontDeskCheckInCheckIn = 'FRONT_DESK_CHECK_IN_______CHECK_IN',
  FrontDeskCheckInBookingInfoView = 'FRONT_DESK_CHECK_IN_BOOKING_INFO____VIEW',
  FrontDeskCheckInBookingInfoEdit = 'FRONT_DESK_CHECK_IN_BOOKING_INFO____EDIT',
  FrontDeskCheckInRoomingListView = 'FRONT_DESK_CHECK_IN_ROOMING_LIST____VIEW',
  FrontDeskCheckInRoomingListListView = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_VIEW',
  FrontDeskCheckInRoomingListListEdit = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_EDIT',
  FrontDeskCheckInRoomingListListCreate = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_CREATE',
  FrontDeskCheckInRoomingListListDelete = 'FRONT_DESK_CHECK_IN_ROOMING_LIST_LIST_DELETE',
  FrontDeskCheckInIncidentalChargesView = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____VIEW',
  FrontDeskCheckInIncidentalChargesCreate = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____CREATE',
  FrontDeskCheckInIncidentalChargesVoid = 'FRONT_DESK_CHECK_IN_INCIDENTAL_CHARGES____VOID',
  FrontDeskCheckInDepositView = 'FRONT_DESK_CHECK_IN_DEPOSIT____VIEW',
  FrontDeskCheckInDepositListCreate = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_CREATE',
  FrontDeskCheckInDepositListRefund = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_REFUND',
  FrontDeskCheckInDepositListPrint = 'FRONT_DESK_CHECK_IN_DEPOSIT_LIST_PRINT',
  FrontDeskCheckInAdvancePaymentView = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT____VIEW',
  FrontDeskCheckInAdvancePaymentListCreate = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_CREATE',
  FrontDeskCheckInAdvancePaymentListRefund = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_REFUND',
  FrontDeskCheckInRemarksView = 'FRONT_DESK_CHECK_IN_REMARKS_______VIEW',
  FrontDeskCheckInRemarksCreate = 'FRONT_DESK_CHECK_IN_REMARKS____CREATE',
  FrontDeskCheckInAdvancePaymentListPrint = 'FRONT_DESK_CHECK_IN_ADVANCE_PAYMENT_LIST_PRINT',
  FrontDeskCheckInFolioView = 'FRONT_DESK_CHECK_IN_FOLIO____VIEW',
  FrontDeskCheckInFolioListView = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_VIEW',
  FrontDeskCheckInFolioListSplit = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_SPLIT',
  FrontDeskCheckInFolioListTransfer = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_TRANSFER',
  FrontDeskCheckInFolioListInhouse = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_INHOUSE',
  FrontDeskCheckInFolioListSuspend = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_SUSPEND',
  FrontDeskCheckInFolioListPrint = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_PRINT',
  FrontDeskCheckInFolioListCreate = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_CREATE',
  FrontDeskCheckInFolioListVoid = 'FRONT_DESK_CHECK_IN_FOLIO_LIST_VOID',
  FrontDeskCheckInSpecialRequestView = 'FRONT_DESK_CHECK_IN_SPECIAL_REQUEST____VIEW',
  FrontDeskCheckInSpecialRequestCreate = 'FRONT_DESK_CHECK_IN_SPECIAL_REQUEST____CREATE',
  FrontDeskCheckInBillingScheduleView = 'FRONT_DESK_CHECK_IN_BILLING_SCHEDULE____VIEW',
  FrontDeskCheckInBillingScheduleEdit = 'FRONT_DESK_CHECK_IN_BILLING_SCHEDULE____EDIT',
  FrontDeskCheckInCheckinCancelCancel = 'FRONT_DESK_CHECK_IN_CHECKIN_CANCEL____CANCEL',
  FrontDeskCheckInKeyCardView = 'FRONT_DESK_CHECK_IN_KEY_CARD____VIEW',
  FrontDeskCheckInKeyCardCreate = 'FRONT_DESK_CHECK_IN_KEY_CARD____CREATE',
  FrontDeskGroupCheckinView = 'FRONT_DESK_GROUP_CHECKIN_______VIEW',
  FrontDeskGroupCheckinListView = 'FRONT_DESK_GROUP_CHECKIN_LIST____VIEW',
  FrontDeskGroupCheckinListAssignment = 'FRONT_DESK_GROUP_CHECKIN_LIST____ASSIGNMENT',
  FrontDeskGroupCheckinListCheckIn = 'FRONT_DESK_GROUP_CHECKIN_LIST____CHECK_IN',
  FrontDeskCheckOutView = 'FRONT_DESK_CHECK_OUT_______VIEW',
  FrontDeskCheckOutDepositView = 'FRONT_DESK_CHECK_OUT_DEPOSIT____VIEW',
  FrontDeskCheckOutDepositListCreate = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_CREATE',
  FrontDeskCheckOutDepositListRefund = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_REFUND',
  FrontDeskCheckOutDepositListPrint = 'FRONT_DESK_CHECK_OUT_DEPOSIT_LIST_PRINT',
  FrontDeskCheckOutIncidentalChargesView = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____VIEW',
  FrontDeskCheckOutIncidentalChargesCreate = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____CREATE',
  FrontDeskCheckOutIncidentalChargesVoid = 'FRONT_DESK_CHECK_OUT_INCIDENTAL_CHARGES____VOID',
  FrontDeskCheckOutRemarksView = 'FRONT_DESK_CHECK_OUT_REMARKS_______VIEW',
  FrontDeskCheckOutRemarksCreate = 'FRONT_DESK_CHECK_OUT_REMARKS____CREATE',
  FrontDeskCheckOutFolioView = 'FRONT_DESK_CHECK_OUT_FOLIO____VIEW',
  FrontDeskCheckOutFolioListView = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_VIEW',
  FrontDeskCheckOutFolioListSplit = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_SPLIT',
  FrontDeskCheckOutFolioListTransfer = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_TRANSFER',
  FrontDeskCheckOutFolioListInhouse = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_INHOUSE',
  FrontDeskCheckOutFolioListSuspend = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_SUSPEND',
  FrontDeskCheckOutFolioListPrint = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_PRINT',
  FrontDeskCheckOutFolioListCreate = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_CREATE',
  FrontDeskCheckOutFolioListVoid = 'FRONT_DESK_CHECK_OUT_FOLIO_LIST_VOID',
  FrontDeskCheckOutFolioHistoryView = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____VIEW',
  FrontDeskCheckOutFolioHistoryShare = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____SHARE',
  FrontDeskCheckOutFolioHistoryPrint = 'FRONT_DESK_CHECK_OUT_FOLIO_HISTORY____PRINT',
  FrontDeskCheckOutBillingScheduleView = 'FRONT_DESK_CHECK_OUT_BILLING_SCHEDULE____VIEW',
  FrontDeskCheckOutBillingScheduleEdit = 'FRONT_DESK_CHECK_OUT_BILLING_SCHEDULE____EDIT',
  FrontDeskRoomAssignmentView = 'FRONT_DESK_ROOM_ASSIGNMENT_______VIEW',
  FrontDeskRoomAssignmentAssignment = 'FRONT_DESK_ROOM_ASSIGNMENT_______ASSIGNMENT',
  FrontDeskRoomAssignmentAssignRoomAssignment = 'FRONT_DESK_ROOM_ASSIGNMENT_ASSIGN_ROOM____ASSIGNMENT',
  FrontDeskStayViewView = 'FRONT_DESK_STAY_VIEW_______VIEW',
  FrontDeskInhouseView = 'FRONT_DESK_INHOUSE_______VIEW',
  FrontDeskInhouseBookingInfoView = 'FRONT_DESK_INHOUSE_BOOKING_INFO____VIEW',
  FrontDeskInhouseBookingInfoEdit = 'FRONT_DESK_INHOUSE_BOOKING_INFO____EDIT',
  FrontDeskInhouseRoomingListView = 'FRONT_DESK_INHOUSE_ROOMING_LIST____VIEW',
  FrontDeskInhouseAttachmentsView = 'FRONT_DESK_INHOUSE_ATTACHMENTS____VIEW',
  FrontDeskInhouseCheckinCancelCancel = 'FRONT_DESK_INHOUSE_CHECKIN_CANCEL____CANCEL',
  FrontDeskInhouseBillingScheduleEdit = 'FRONT_DESK_INHOUSE_BILLING_SCHEDULE____EDIT',
  FrontDeskInhouseRoomingListListView = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_VIEW',
  FrontDeskInhouseRoomingListListEdit = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_EDIT',
  FrontDeskInhouseRoomingListListCreate = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_CREATE',
  FrontDeskInhouseRoomingListListDelete = 'FRONT_DESK_INHOUSE_ROOMING_LIST_LIST_DELETE',
  FrontDeskInhouseServiceRequestView = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____VIEW',
  FrontDeskInhouseServiceRequestListCreate = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST_LIST_CREATE',
  FrontDeskInhouseServiceRequestEdit = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____EDIT',
  FrontDeskInhouseServiceRequestUpdateStatus = 'FRONT_DESK_INHOUSE_SERVICE_REQUEST____UPDATE_STATUS',
  FrontDeskInhouseRemarksView = 'FRONT_DESK_INHOUSE_REMARKS_______VIEW',
  FrontDeskInhouseRemarksCreate = 'FRONT_DESK_INHOUSE_REMARKS____CREATE',
  FrontDeskInhouseIncidentalChargesView = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____VIEW',
  FrontDeskInhouseIncidentalChargesCreate = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____CREATE',
  FrontDeskInhouseIncidentalChargesVoid = 'FRONT_DESK_INHOUSE_INCIDENTAL_CHARGES____VOID',
  FrontDeskInhouseDepositView = 'FRONT_DESK_INHOUSE_DEPOSIT____VIEW',
  FrontDeskInhouseDepositListCreate = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_CREATE',
  FrontDeskInhouseDepositListRefund = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_REFUND',
  FrontDeskInhouseDepositListPrint = 'FRONT_DESK_INHOUSE_DEPOSIT_LIST_PRINT',
  FrontDeskInhouseAdvancePaymentView = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT____VIEW',
  FrontDeskInhouseAdvancePaymentListCreate = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_CREATE',
  FrontDeskInhouseAdvancePaymentListRefund = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_REFUND',
  FrontDeskInhouseAdvancePaymentListPrint = 'FRONT_DESK_INHOUSE_ADVANCE_PAYMENT_LIST_PRINT',
  FrontDeskInhouseFolioView = 'FRONT_DESK_INHOUSE_FOLIO____VIEW',
  FrontDeskInhouseFolioListView = 'FRONT_DESK_INHOUSE_FOLIO_LIST_VIEW',
  FrontDeskInhouseFolioListSplit = 'FRONT_DESK_INHOUSE_FOLIO_LIST_SPLIT',
  FrontDeskInhouseFolioListTransfer = 'FRONT_DESK_INHOUSE_FOLIO_LIST_TRANSFER',
  FrontDeskInhouseFolioListInhouse = 'FRONT_DESK_INHOUSE_FOLIO_LIST_INHOUSE',
  FrontDeskInhouseFolioListSuspend = 'FRONT_DESK_INHOUSE_FOLIO_LIST_SUSPEND',
  FrontDeskInhouseFolioListPrint = 'FRONT_DESK_INHOUSE_FOLIO_LIST_PRINT',
  FrontDeskInhouseFolioListCreate = 'FRONT_DESK_INHOUSE_FOLIO_LIST_CREATE',
  FrontDeskInhouseFolioListVoid = 'FRONT_DESK_INHOUSE_FOLIO_LIST_VOID',
  FrontDeskInhouseEarlyCheckoutView = 'FRONT_DESK_INHOUSE_EARLY_CHECKOUT____VIEW',
  FrontDeskInhouseLateCheckoutView = 'FRONT_DESK_INHOUSE_LATE_CHECKOUT____VIEW',
  FrontDeskInhouseLateCheckoutCreate = 'FRONT_DESK_INHOUSE_LATE_CHECKOUT____CREATE',
  FrontDeskInhouseExtendStayView = 'FRONT_DESK_INHOUSE_EXTEND_STAY____VIEW',
  FrontDeskInhouseExtendStayCreate = 'FRONT_DESK_INHOUSE_EXTEND_STAY____CREATE',
  FrontDeskInhouseRateAdjustmentView = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT____VIEW',
  FrontDeskInhouseRateAdjustmentCreate = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT____CREATE',
  FrontDeskInhouseRateAdjustmentListView = 'FRONT_DESK_INHOUSE_RATE_ADJUSTMENT__LIST_VIEW',
  FrontDeskInhouseRoomTransferView = 'FRONT_DESK_INHOUSE_ROOM_TRANSFER____VIEW',
  FrontDeskInhouseRoomTransferCreate = 'FRONT_DESK_INHOUSE_ROOM_TRANSFER____CREATE',
  FrontDeskInhouseRoomUpgradeView = 'FRONT_DESK_INHOUSE_ROOM_UPGRADE____VIEW',
  FrontDeskInhouseRoomUpgradeCreate = 'FRONT_DESK_INHOUSE_ROOM_UPGRADE____CREATE',
  FrontDeskInhouseFolioHistoryView = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____VIEW',
  FrontDeskInhouseFolioHistoryShare = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____SHARE',
  FrontDeskInhouseFolioHistoryPrint = 'FRONT_DESK_INHOUSE_FOLIO_HISTORY____PRINT',
  FrontDeskInhouseAttachmentsCreate = 'FRONT_DESK_INHOUSE_Attachments____CREATE',
  FrontDeskInhouseAttachmentsEdit = 'FRONT_DESK_INHOUSE_Attachments____EDIT',
  FrontDeskInhouseAttachmentsDelete = 'FRONT_DESK_INHOUSE_Attachments____DELETE',
  FrontDeskInhouseHouseLimitView = 'FRONT_DESK_INHOUSE_HOUSE_LIMIT____VIEW',
  FrontDeskInhouseHouseLimitCreate = 'FRONT_DESK_INHOUSE_HOUSE_LIMIT____CREATE',
  FrontDeskInhouseBillingScheduleView = 'FRONT_DESK_INHOUSE_BILLING_SCHEDULE____VIEW',
  FrontDeskInhouseKeyCardView = 'FRONT_DESK_INHOUSE_KEY_CARD____VIEW',
  FrontDeskInhouseKeyCardCreate = 'FRONT_DESK_INHOUSE_KEY_CARD____CREATE',
  FrontDeskDepositView = 'FRONT_DESK_DEPOSIT_______VIEW',
  FrontDeskDepositListCreate = 'FRONT_DESK_DEPOSIT_LIST____CREATE',
  FrontDeskDepositListRefund = 'FRONT_DESK_DEPOSIT_LIST____REFUND',
  FrontDeskDepositListPrint = 'FRONT_DESK_DEPOSIT_LIST____PRINT',
  FrontDeskDepositListShare = 'FRONT_DESK_DEPOSIT_LIST____SHARE',
  FrontDeskServiceRequestView = 'FRONT_DESK_SERVICE_REQUEST_______VIEW',
  FrontDeskServiceRequestListCreate = 'FRONT_DESK_SERVICE_REQUEST_LIST____CREATE',
  FrontDeskServiceRequestListEdit = 'FRONT_DESK_SERVICE_REQUEST_LIST____EDIT',
  FrontDeskServiceRequestListUpdateStatus = 'FRONT_DESK_SERVICE_REQUEST_LIST____UPDATE_STATUS',
  FrontDeskServiceRequestListAssignment = 'FRONT_DESK_SERVICE_REQUEST_LIST____ASSIGNMENT',
  FrontDeskNonguestFolioView = 'FRONT_DESK_NONGUEST_FOLIO_______VIEW',
  FrontDeskNonguestFolioListCreate = 'FRONT_DESK_NONGUEST_FOLIO_LIST____CREATE',
  FrontDeskNonguestFolioListEdit = 'FRONT_DESK_NONGUEST_FOLIO_LIST____EDIT',
  FrontDeskNonguestFolioListDelete = 'FRONT_DESK_NONGUEST_FOLIO_LIST____DELETE',
  FrontDeskNonguestFolioListFolio = 'FRONT_DESK_NONGUEST_FOLIO_LIST____FOLIO',
  FrontDeskNonguestFolioListFolioCharges = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_CHARGES',
  FrontDeskNonguestFolioListFolioPayment = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_PAYMENT',
  FrontDeskNonguestFolioListFolioClose = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_CLOSE',
  FrontDeskNonguestFolioListFolioPrint = 'FRONT_DESK_NONGUEST_FOLIO_LIST_FOLIO_PRINT',
  FrontDeskSuspendFolioView = 'FRONT_DESK_SUSPEND_FOLIO_______VIEW',
  FrontDeskSuspendFolioListCreate = 'FRONT_DESK_SUSPEND_FOLIO_LIST____CREATE',
  FrontDeskSuspendFolioListEdit = 'FRONT_DESK_SUSPEND_FOLIO_LIST____EDIT',
  FrontDeskSuspendFolioListDelete = 'FRONT_DESK_SUSPEND_FOLIO_LIST____DELETE',
  FrontDeskSuspendFolioListFolio = 'FRONT_DESK_SUSPEND_FOLIO_LIST____FOLIO',
  FrontDeskSuspendFolioListFolioCharges = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_CHARGES',
  FrontDeskSuspendFolioListFolioPayment = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_PAYMENT',
  FrontDeskSuspendFolioListFolioClose = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_CLOSE',
  FrontDeskSuspendFolioListFolioPrint = 'FRONT_DESK_SUSPEND_FOLIO_LIST_FOLIO_PRINT',
  FrontDeskContactTracingView = 'FRONT_DESK_CONTACT_TRACING_______VIEW',
  FrontDeskContactTracingInfoView = 'FRONT_DESK_CONTACT_TRACING_INFO____VIEW',
  FrontDeskContactTracingInfoShare = 'FRONT_DESK_CONTACT_TRACING_INFO____SHARE',
  HouseKeepingIgnoreView = 'HOUSE_KEEPING_IGNORE_______VIEW',
  HouseKeepingBlockRoomView = 'HOUSE_KEEPING_BLOCK_ROOM_______VIEW',
  HouseKeepingBlockRoomEdit = 'HOUSE_KEEPING_BLOCK_ROOM_______EDIT',
  HouseKeepingBlockRoomBlockRoomStatusView = 'HOUSE_KEEPING_BLOCK_ROOM_BLOCK_ROOM_STATUS_______VIEW',
  HouseKeepingBlockRoomBlockRoomStatusOooView = 'HOUSE_KEEPING_BLOCK_ROOM_BLOCK_ROOM_STATUS_OOO_______VIEW',
  HouseKeepingBlockRoomBlockRoomStatusOoiView = 'HOUSE_KEEPING_BLOCK_ROOM_BLOCK_ROOM_STATUS_OOI_______VIEW',
  HouseKeepingRoomStatusView = 'HOUSE_KEEPING_ROOM_STATUS_______VIEW',
  HouseKeepingRoomStatusUpdateStatus = 'HOUSE_KEEPING_ROOM_STATUS_______UPDATE_STATUS',
  HouseKeepingServiceRequestView = 'HOUSE_KEEPING_SERVICE_REQUEST_______VIEW',
  HouseKeepingServiceRequestCreate = 'HOUSE_KEEPING_SERVICE_REQUEST_______CREATE',
  HouseKeepingServiceRequestEdit = 'HOUSE_KEEPING_SERVICE_REQUEST_______EDIT',
  HouseKeepingServiceRequestUpdateStatus = 'HOUSE_KEEPING_SERVICE_REQUEST_______UPDATE_STATUS',
  HouseKeepingIncidentalChargesView = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______VIEW',
  HouseKeepingIncidentalChargesCreate = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______CREATE',
  HouseKeepingIncidentalChargesVoid = 'HOUSE_KEEPING_INCIDENTAL_CHARGES_______VOID',
  ServiceRequestIgnoreView = 'SERVICE_REQUEST_IGNORE_______VIEW',
  ServiceRequestIgnoreEdit = 'SERVICE_REQUEST_IGNORE_______EDIT',
  ServiceRequestIgnoreCreate = 'SERVICE_REQUEST_IGNORE_______CREATE',
  ServiceRequestIgnoreUpdateStatus = 'SERVICE_REQUEST_IGNORE_______UPDATE_STATUS',
  ServiceRequestIgnoreAssignment = 'SERVICE_REQUEST_IGNORE_______ASSIGNMENT',
  PackagesRedemptionIgnoreView = 'PACKAGES_REDEMPTION_IGNORE_______VIEW',
  PackagesRedemptionIgnoreEdit = 'PACKAGES_REDEMPTION_IGNORE_______EDIT',
  CorpGovtIgnoreView = 'CORP_GOVT_IGNORE_______VIEW',
  CorpGovtPrivateCorporationView = 'CORP_GOVT_PRIVATE_CORPORATION_______VIEW',
  CorpGovtPrivateCorporationCreate = 'CORP_GOVT_PRIVATE_CORPORATION_______CREATE',
  CorpGovtPrivateCorporationActive = 'CORP_GOVT_PRIVATE_CORPORATION_______ACTIVE',
  CorpGovtPrivateCorporationDelete = 'CORP_GOVT_PRIVATE_CORPORATION_______DELETE',
  CorpGovtPrivateCorporationCompanyInfoView = 'CORP_GOVT_PRIVATE_CORPORATION_COMPANY_INFO____VIEW',
  CorpGovtPrivateCorporationCompanyInfoEdit = 'CORP_GOVT_PRIVATE_CORPORATION_COMPANY_INFO____EDIT',
  CorpGovtPrivateCorporationContractsView = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____VIEW',
  CorpGovtPrivateCorporationContractsCreate = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____CREATE',
  CorpGovtPrivateCorporationContractsEdit = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____EDIT',
  CorpGovtPrivateCorporationContractsActive = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____ACTIVE',
  CorpGovtPrivateCorporationContractsDelete = 'CORP_GOVT_PRIVATE_CORPORATION_CONTRACTS____DELETE',
  CorpGovtPrivateCorporationBookingRecordsView = 'CORP_GOVT_PRIVATE_CORPORATION_BOOKING_RECORDS____VIEW',
  CorpGovtPrivateCorporationAnalysisView = 'CORP_GOVT_PRIVATE_CORPORATION_ANALYSIS____VIEW',
  CorpGovtGovtBodiesView = 'CORP_GOVT_GOVT_BODIES_______VIEW',
  CorpGovtGovtBodiesCreate = 'CORP_GOVT_GOVT_BODIES_______CREATE',
  CorpGovtGovtBodiesActive = 'CORP_GOVT_GOVT_BODIES_______ACTIVE',
  CorpGovtGovtBodiesDelete = 'CORP_GOVT_GOVT_BODIES_______DELETE',
  CorpGovtGovtBodiesCompanyInfoView = 'CORP_GOVT_GOVT_BODIES_COMPANY_INFO____VIEW',
  CorpGovtGovtBodiesCompanyInfoEdit = 'CORP_GOVT_GOVT_BODIES_COMPANY_INFO____EDIT',
  CorpGovtGovtBodiesContractsView = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____VIEW',
  CorpGovtGovtBodiesContractsCreate = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____CREATE',
  CorpGovtGovtBodiesContractsEdit = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____EDIT',
  CorpGovtGovtBodiesContractsActive = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____ACTIVE',
  CorpGovtGovtBodiesContractsDelete = 'CORP_GOVT_GOVT_BODIES_CONTRACTS____DELETE',
  CorpGovtGovtBodiesBookingRecordsView = 'CORP_GOVT_GOVT_BODIES_BOOKING_RECORDS____VIEW',
  CorpGovtGovtBodiesAnalysisView = 'CORP_GOVT_GOVT_BODIES_ANALYSIS____VIEW',
  CorpGovtRatePolicyView = 'CORP_GOVT_RATE_POLICY_______VIEW',
  TravelAgentIgnoreView = 'TRAVEL_AGENT_IGNORE_______VIEW',
  TravelAgentAgentListingView = 'TRAVEL_AGENT_AGENT_LISTING_______VIEW',
  TravelAgentAgentListingCreate = 'TRAVEL_AGENT_AGENT_LISTING_______CREATE',
  TravelAgentAgentListingActive = 'TRAVEL_AGENT_AGENT_LISTING_______ACTIVE',
  TravelAgentAgentListingDelete = 'TRAVEL_AGENT_AGENT_LISTING_______DELETE',
  TravelAgentAgentListingCompanyInfoView = 'TRAVEL_AGENT_AGENT_LISTING_COMPANY_INFO____VIEW',
  TravelAgentAgentListingCompanyInfoEdit = 'TRAVEL_AGENT_AGENT_LISTING_COMPANY_INFO____EDIT',
  TravelAgentAgentListingContractsView = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____VIEW',
  TravelAgentAgentListingContractsCreate = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____CREATE',
  TravelAgentAgentListingContractsEdit = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____EDIT',
  TravelAgentAgentListingContractsActive = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____ACTIVE',
  TravelAgentAgentListingContractsDelete = 'TRAVEL_AGENT_AGENT_LISTING_CONTRACTS____DELETE',
  TravelAgentAgentListingBookingRecordsView = 'TRAVEL_AGENT_AGENT_LISTING_BOOKING_RECORDS____VIEW',
  TravelAgentAgentListingAnalysisView = 'TRAVEL_AGENT_AGENT_LISTING_ANALYSIS____VIEW',
  TravelAgentRatePolicyView = 'TRAVEL_AGENT_RATE_POLICY_______VIEW',
  GuestProfileIgnoreView = 'GUEST_PROFILE_IGNORE_______VIEW',
  GuestProfileIgnoreEdit = 'GUEST_PROFILE_IGNORE_______EDIT',
  DigitalMarketingIgnoreView = 'DIGITAL_MARKETING_IGNORE_______VIEW',
  DigitalMarketingPromoCodeView = 'DIGITAL_MARKETING_PROMO_CODE_______VIEW',
  DigitalMarketingPromoCodeCreate = 'DIGITAL_MARKETING_PROMO_CODE_______CREATE',
  DigitalMarketingPromoCodeEdit = 'DIGITAL_MARKETING_PROMO_CODE_______EDIT',
  DigitalMarketingPromoCodeActive = 'DIGITAL_MARKETING_PROMO_CODE_______ACTIVE',
  DigitalMarketingMarketingAdsView = 'DIGITAL_MARKETING_MARKETING_ADS_______VIEW',
  DigitalMarketingMarketingAdsCreate = 'DIGITAL_MARKETING_MARKETING_ADS_______CREATE',
  DigitalMarketingMarketingAdsEdit = 'DIGITAL_MARKETING_MARKETING_ADS_______EDIT',
  DigitalMarketingMarketingAdsShare = 'DIGITAL_MARKETING_MARKETING_ADS_______SHARE',
  DigitalMarketingMarketingAdsEmailBlasting = 'DIGITAL_MARKETING_MARKETING_ADS_______EMAIL_BLASTING',
  DigitalMarketingGuestRatingReviewView = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_______VIEW',
  DigitalMarketingGuestRatingReviewInfoView = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____VIEW',
  DigitalMarketingGuestRatingReviewInfoUpdateStatus = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____UPDATE_STATUS',
  DigitalMarketingGuestRatingReviewInfoShare = 'DIGITAL_MARKETING_GUEST_RATING_REVIEW_INFO____SHARE',
  RoomServiceIgnoreView = 'ROOM_SERVICE_IGNORE_______VIEW',
  RoomServiceIgnoreCreate = 'ROOM_SERVICE_IGNORE_______CREATE',
  RoomServiceIgnoreCancel = 'ROOM_SERVICE_IGNORE_______CANCEL',
  RoomServiceIgnoreUpdateStatus = 'ROOM_SERVICE_IGNORE_______UPDATE_STATUS',
  CheckoutAdjustmentIgnoreView = 'CHECKOUT_ADJUSTMENT_IGNORE_______VIEW',
  CheckoutAdjustmentIgnoreCreate = 'CHECKOUT_ADJUSTMENT_IGNORE_______CREATE',
  CheckoutAdjustmentIgnoreBenificiary = 'CHECKOUT_ADJUSTMENT_IGNORE_______BENIFICIARY',
  CheckoutAdjustmentIgnoreCreditNote = 'CHECKOUT_ADJUSTMENT_IGNORE_______CREDIT_NOTE',
  CheckoutAdjustmentIgnoreDebitNote = 'CHECKOUT_ADJUSTMENT_IGNORE_______DEBIT_NOTE',
  CheckoutAdjustmentIgnoreChangePayment = 'CHECKOUT_ADJUSTMENT_IGNORE_______CHANGE_PAYMENT',
  CheckoutAdjustmentIgnoreShare = 'CHECKOUT_ADJUSTMENT_IGNORE_______SHARE',
  FrontDeskCheckOutCheckoutReinstatementCreate = 'FRONT_DESK_CHECK_OUT_CHECKOUT_REINSTATEMENT____CREATE',
  BusinessInsightIgnoreView = 'BUSINESS_INSIGHT_IGNORE_______VIEW',
  BusinessInsightDigitalReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_______VIEW',
  BusinessInsightDigitalReportCashierSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_SUMMARY____VIEW',
  BusinessInsightDigitalReportCashierDetailListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_DETAIL_LISTING____VIEW',
  BusinessInsightDigitalReportSalesAndServicesListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SALES_AND_SERVICES_LISTING____VIEW',
  BusinessInsightDigitalReportTransactionLedgerView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_LEDGER____VIEW',
  BusinessInsightDigitalReportBookingStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_BOOKING_STATUS____VIEW',
  BusinessInsightDigitalReportManagerReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_MANAGER_REPORT____VIEW',
  BusinessInsightDigitalReportManagerReportFinancialView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_MANAGER_REPORT_FINANCIAL____VIEW',
  BusinessInsightDigitalReportProductionRoomNoView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_ROOM_NO___VIEW',
  BusinessInsightDigitalReportProductionRateCodeeView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_RATE_CODEE____VIEW',
  BusinessInsightDigitalReportProductionRoomTypeView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_ROOM_TYPE____VIEW',
  BusinessInsightDigitalReportProductionCorpGovView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_CORP_GOV____VIEW',
  BusinessInsightDigitalReportProductionSegmentView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SEGMENT____VIEW',
  BusinessInsightDigitalReportProductionSourceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SOURCE____VIEW',
  BusinessInsightDigitalReportProductionStateView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_STATE____VIEW',
  BusinessInsightDigitalReportPackageListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PACKAGE_LISTING____VIEW',
  BusinessInsightDigitalReportRoomStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_STATUS____VIEW',
  BusinessInsightDigitalReportRoomStatusMovementView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_STATUS_MOVEMENT____VIEW',
  BusinessInsightDigitalReportProductionSalesChannelView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PRODUCTION_SALES_CHANNEL____VIEW',
  BusinessInsightDigitalReportInhouseGuestView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_INHOUSE_GUEST____VIEW',
  BusinessInsightDigitalReportSecurityDepositView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SECURITY_DEPOSIT____VIEW',
  BusinessInsightDigitalReportRoomDiscrepancyView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_DISCREPANCY____VIEW',
  BusinessInsightDigitalReportTourismTaxView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TOURISM_TAX____VIEW',
  BusinessInsightDigitalReportTransactionCancelView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_CANCEL____VIEW',
  BusinessInsightDigitalReportServiceRequestView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_SERVICE_REQUEST____VIEW',
  BusinessInsightDigitalReportGuestBalanceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GUEST_BALANCE____VIEW',
  BusinessInsightDigitalReportGuestRoomView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GUEST_ROOM____VIEW',
  BusinessInsightDigitalReportOccupiedView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_OCCUPIED____VIEW',
  BusinessInsightDigitalReportOutstandingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_OUTSTANDING____VIEW',
  BusinessInsightDigitalReportGroupReservationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GROUP_RESERVATION____VIEW',
  BusinessInsightDigitalReportGroupReservationSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_GROUP_RESERVATION_SUMMARY____VIEW',
  BusinessInsightDigitalReportRoomAvailabilityView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_AVAILABILITY____VIEW',
  BusinessInsightDigitalReportDownloadGlView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DOWNLOAD_GL____VIEW',
  BusinessInsightDigitalReportDebtorLedgerView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_LEDGER____VIEW',
  BusinessInsightDigitalReportDebtorLedgerDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_LEDGER_DETAIL____VIEW',
  BusinessInsightDigitalReportDebtorAllocationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_ALLOCATION____VIEW',
  BusinessInsightDigitalReportDebtorAgingSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_AGING_SUMMARY____VIEW',
  BusinessInsightDigitalReportDebtorAgingDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_AGING_DETAIL____VIEW',
  BusinessInsightDigitalReportStatementOfAccountView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_STATEMENT_OF_ACCOUNT____VIEW',
  BusinessInsightDigitalReportStatementOfAccountOutstandingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_STATEMENT_OF_ACCOUNT_OUTSTANDING____VIEW',
  BusinessInsightDigitalReportPendingCreditAllocationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_PENDING_CREDIT_ALLOCATION____VIEW',
  BusinessInsightDigitalReportDebtorStatusView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_STATUS____VIEW',
  BusinessInsightDigitalReportDebtorRevenueView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DEBTOR_REVENUE____VIEW',
  BusinessInsightDigitalReportDailyPostingDetailView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_DAILY_POSTING_DETAIL____VIEW',
  BusinessInsightDigitalReportHistoricalForecastView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_HISTORICAL_FORECAST____VIEW',
  BusinessInsightDigitalReportRoomMaintenanceView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_ROOM_MAINTENANCE____VIEW',
  BusinessInsightDigitalReportTransactionTransferView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_TRANSACTION_TRANSFER____VIEW',
  BusinessInsightDigitalReportConsolidatedEinvoiceReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CONSOLIDATED_EINVOICE_REPORT____VIEW',
  BusinessInsightDigitalReportEinvoiceListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_EINVOICE_LISTING____VIEW',
  BusinessInsightDigitalReportEinvoiceReconciliationView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_EINVOICE_RECONCILIATION____VIEW',
  BusinessInsightDigitalDocumentView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_______VIEW',
  BusinessInsightDigitalDocumentRegistrationCardView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____VIEW',
  BusinessInsightDigitalDocumentRegistrationCardShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____SHARE',
  BusinessInsightDigitalDocumentRegistrationCardPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_REGISTRATION_CARD____PRINT',
  BusinessInsightDigitalDocumentFolioView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____VIEW',
  BusinessInsightDigitalDocumentFolioShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____SHARE',
  BusinessInsightDigitalDocumentFolioPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_FOLIO____PRINT',
  BusinessInsightDigitalDocumentReceiptView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____VIEW',
  BusinessInsightDigitalDocumentReceiptShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____SHARE',
  BusinessInsightDigitalDocumentReceiptPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_RECEIPT____PRINT',
  BusinessInsightDigitalDocumentDepositView = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_DEPOSIT____VIEW',
  BusinessInsightDigitalDocumentDepositShare = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_DEPOSIT____SHARE',
  BusinessInsightDigitalDocumentDepositPrint = 'BUSINESS_INSIGHT_DIGITAL_DOCUMENT_DEPOSIT____PRINT',
  BusinessInsightAnalyticsView = 'BUSINESS_INSIGHT_ANALYTICS_______VIEW',
  BusinessInsightAnalyticsHotelStatusView = 'BUSINESS_INSIGHT_ANALYTICS_HOTEL_STATUS____VIEW',
  BusinessInsightAnalyticsHotelStatisticView = 'BUSINESS_INSIGHT_ANALYTICS_HOTEL_STATISTIC____VIEW',
  BusinessInsightAnalyticsRevenueAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsSourceAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_SOURCE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsCountryAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_COUNTRY_ANALYSIS____VIEW',
  BusinessInsightAnalyticsNationalityAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_NATIONALITY_ANALYSIS____VIEW',
  BusinessInsightAnalyticsDebtorAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_DEBTOR_ANALYSIS____VIEW',
  BusinessInsightAnalyticsMarketingPersonnalView = 'BUSINESS_INSIGHT_ANALYTICS_MARKETING_PERSONNAL____VIEW',
  BusinessInsightAnalyticsRevenueByRoomtypeView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_BY_ROOMTYPE____VIEW',
  BusinessInsightAnalyticsOnlineTravelAgentView = 'BUSINESS_INSIGHT_ANALYTICS_ONLINE_TRAVEL_AGENT____VIEW',
  BusinessInsightAnalyticsSegmentAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_SEGMENT_ANALYSIS____VIEW',
  BusinessInsightAnalyticsRevenueByTransactionView = 'BUSINESS_INSIGHT_ANALYTICS_REVENUE_BY_TRANSACTION____VIEW',
  BusinessInsightAnalyticsServiceRequestView = 'BUSINESS_INSIGHT_ANALYTICS_SERVICE_REQUEST____VIEW',
  BusinessInsightAnalyticsMaidProductivityView = 'BUSINESS_INSIGHT_ANALYTICS_MAID_PRODUCTIVITY____VIEW',
  BusinessInsightAnalyticsRatingReviewAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_RATING_REVIEW_ANALYSIS____VIEW',
  BusinessInsightAnalyticsStateAnalysisView = 'BUSINESS_INSIGHT_ANALYTICS_STATE_ANALYSIS____VIEW',
  BusinessInsightAnalyticsDebtorLedgerStatusView = 'BUSINESS_INSIGHT_ANALYTICS_DEBTOR_LEDGER_STATUS____VIEW',
  BusinessInsightAnalyticsTotalAgingView = 'BUSINESS_INSIGHT_ANALYTICS_TOTAL_AGING____VIEW',
  BusinessInsightAnalyticsAmountDueByAgingView = 'BUSINESS_INSIGHT_ANALYTICS_AMOUNT_DUE_BY_AGING____VIEW',
  BusinessInsightAnalyticsCustomerByRevenueView = 'BUSINESS_INSIGHT_ANALYTICS_CUSTOMER_BY_REVENUE____VIEW',
  BusinessInsightAnalyticsCollectionByDebtortypeView = 'BUSINESS_INSIGHT_ANALYTICS_COLLECTION_BY_DEBTORTYPE____VIEW',
  BusinessInsightDigitalReportCashierCollectionSummaryView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CASHIER_COLLECTION_SUMMARY____VIEW',
  BusinessInsightDigitalReportCityLedgerTransactionView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_CITY_LEDGER_TRANSACTION____VIEW',
  BusinessInsightDigitalReportBookingAdvancePaymentListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_BOOKING_ADVANCE_PAYMENT_LISTING____VIEW',
  BusinessInsightDigitalReportRefundableDepositListingView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_REFUNDABLE_DEPOSIT_LISTING____VIEW',
  BusinessInsightDigitalReportUserActivityLogReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_USER_ACTIVITY_LOG_REPORT____VIEW',
  BusinessInsightDigitalReportKeyCardIssueReportView = 'BUSINESS_INSIGHT_DIGITAL_REPORT_KEY_CARD_ISSUE_REPORT____VIEW',
  HotelSettingsIgnoreView = 'HOTEL_SETTINGS_IGNORE_______VIEW',
  HotelSettingsHotelSetupView = 'HOTEL_SETTINGS_HOTEL_SETUP_______VIEW',
  HotelSettingsHotelSetupProfileView = 'HOTEL_SETTINGS_HOTEL_SETUP_PROFILE____VIEW',
  HotelSettingsHotelSetupProfileEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_PROFILE____EDIT',
  HotelSettingsHotelSetupAboutView = 'HOTEL_SETTINGS_HOTEL_SETUP_ABOUT____VIEW',
  HotelSettingsHotelSetupAboutEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_ABOUT____EDIT',
  HotelSettingsHotelSetupGalleryView = 'HOTEL_SETTINGS_HOTEL_SETUP_GALLERY____VIEW',
  HotelSettingsHotelSetupGalleryEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_GALLERY____EDIT',
  HotelSettingsHotelSetupFacilitiesView = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES____VIEW',
  HotelSettingsHotelSetupFacilitiesEdit = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES____EDIT',
  HotelSettingsHotelSetupFacilitiesQrCodePrint = 'HOTEL_SETTINGS_HOTEL_SETUP_FACILITIES_QR_CODE_PRINT',
  HotelSettingsDepartmentView = 'HOTEL_SETTINGS_DEPARTMENT_______VIEW',
  HotelSettingsDepartmentCreate = 'HOTEL_SETTINGS_DEPARTMENT_______CREATE',
  HotelSettingsDepartmentIncidentalChargesView = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____VIEW',
  HotelSettingsDepartmentIncidentalChargesCreate = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____CREATE',
  HotelSettingsDepartmentIncidentalChargesActive = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____ACTIVE',
  HotelSettingsDepartmentIncidentalChargesDelete = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES____DELETE',
  HotelSettingsDepartmentIncidentalChargesQrCodeView = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES_QR_CODE_VIEW',
  HotelSettingsDepartmentIncidentalChargesQrCodePrint = 'HOTEL_SETTINGS_DEPARTMENT_INCIDENTAL_CHARGES_QR_CODE_PRINT',
  HotelSettingsDepartmentReasonView = 'HOTEL_SETTINGS_DEPARTMENT_REASON____VIEW',
  HotelSettingsDepartmentReasonCreate = 'HOTEL_SETTINGS_DEPARTMENT_REASON____CREATE',
  HotelSettingsDepartmentReasonEdit = 'HOTEL_SETTINGS_DEPARTMENT_REASON____EDIT',
  HotelSettingsDepartmentReasonDelete = 'HOTEL_SETTINGS_DEPARTMENT_REASON____DELETE',
  HotelSettingsDepartmentSalesChannelView = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____VIEW',
  HotelSettingsDepartmentSalesChannelCreate = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____CREATE',
  HotelSettingsDepartmentSalesChannelEdit = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____EDIT',
  HotelSettingsDepartmentSalesChannelDelete = 'HOTEL_SETTINGS_DEPARTMENT_SALES_CHANNEL____DELETE',
  HotelSettingsLocationView = 'HOTEL_SETTINGS_LOCATION_______VIEW',
  HotelSettingsLocationCreate = 'HOTEL_SETTINGS_LOCATION_______CREATE',
  HotelSettingsLocationEdit = 'HOTEL_SETTINGS_LOCATION_______EDIT',
  HotelSettingsRoomTypeView = 'HOTEL_SETTINGS_ROOM_TYPE_______VIEW',
  HotelSettingsRoomTypeCreate = 'HOTEL_SETTINGS_ROOM_TYPE_______CREATE',
  HotelSettingsRoomTypeEdit = 'HOTEL_SETTINGS_ROOM_TYPE_______EDIT',
  HotelSettingsRoomTypeActive = 'HOTEL_SETTINGS_ROOM_TYPE_______ACTIVE',
  HotelSettingsRoomView = 'HOTEL_SETTINGS_ROOM_______VIEW',
  HotelSettingsRoomListView = 'HOTEL_SETTINGS_ROOM_LIST____VIEW',
  HotelSettingsRoomListCreate = 'HOTEL_SETTINGS_ROOM_LIST____CREATE',
  HotelSettingsRoomListEdit = 'HOTEL_SETTINGS_ROOM_LIST____EDIT',
  HotelSettingsRoomListActive = 'HOTEL_SETTINGS_ROOM_LIST____ACTIVE',
  HotelSettingsRoomListDelete = 'HOTEL_SETTINGS_ROOM_LIST____DELETE',
  HotelSettingsRoomStatusView = 'HOTEL_SETTINGS_ROOM_STATUS_______VIEW',
  HotelSettingsRoomStatusEdit = 'HOTEL_SETTINGS_ROOM_STATUS_______EDIT',
  HotelSettingsRatePolicyView = 'HOTEL_SETTINGS_RATE_POLICY_______VIEW',
  HotelSettingsRatePolicySeasonSetupView = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____VIEW',
  HotelSettingsRatePolicySeasonSetupCreate = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____CREATE',
  HotelSettingsRatePolicySeasonSetupEdit = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____EDIT',
  HotelSettingsRatePolicySeasonSetupActive = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_SETUP____ACTIVE',
  HotelSettingsRatePolicySeasonCalendarView = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_CALENDAR____VIEW',
  HotelSettingsRatePolicySeasonCalendarEdit = 'HOTEL_SETTINGS_RATE_POLICY_SEASON_CALENDAR____EDIT',
  HotelSettingsRatePolicyRateElementView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____VIEW',
  HotelSettingsRatePolicyRateElementCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____CREATE',
  HotelSettingsRatePolicyRateElementEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____EDIT',
  HotelSettingsRatePolicyRateElementActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_ELEMENT____ACTIVE',
  HotelSettingsRatePolicyAddOnView = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____VIEW',
  HotelSettingsRatePolicyAddOnCreate = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____CREATE',
  HotelSettingsRatePolicyAddOnEdit = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____EDIT',
  HotelSettingsRatePolicyAddOnActive = 'HOTEL_SETTINGS_RATE_POLICY_ADD_ON____ACTIVE',
  HotelSettingsRatePolicyRateTypeView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____VIEW',
  HotelSettingsRatePolicyRateTypeCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____CREATE',
  HotelSettingsRatePolicyRateTypeEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____EDIT',
  HotelSettingsRatePolicyRateTypeActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_TYPE____ACTIVE',
  HotelSettingsRatePolicyRateSetupView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____VIEW',
  HotelSettingsRatePolicyRateSetupCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____CREATE',
  HotelSettingsRatePolicyRateSetupEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____EDIT',
  HotelSettingsRatePolicyRateSetupActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP____ACTIVE',
  HotelSettingsRatePolicyRateSetupValidityPeriodView = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_VIEW',
  HotelSettingsRatePolicyRateSetupValidityPeriodCreate = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_CREATE',
  HotelSettingsRatePolicyRateSetupValidityPeriodEdit = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_EDIT',
  HotelSettingsRatePolicyRateSetupValidityPeriodActive = 'HOTEL_SETTINGS_RATE_POLICY_RATE_SETUP_VALIDITY_PERIOD_ACTIVE',
  HotelSettingsRoomSaleOptView = 'HOTEL_SETTINGS_ROOM_SALE_OPT_______VIEW',
  HotelSettingsRoomSaleOptEdit = 'HOTEL_SETTINGS_ROOM_SALE_OPT_______EDIT',
  HotelSettingsStandardPolicyView = 'HOTEL_SETTINGS_STANDARD_POLICY_______VIEW',
  HotelSettingsStandardPolicyHotelOperationalView = 'HOTEL_SETTINGS_STANDARD_POLICY_HOTEL_OPERATIONAL____VIEW',
  HotelSettingsStandardPolicyHotelOperationalEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_HOTEL_OPERATIONAL____EDIT',
  HotelSettingsStandardPolicySecurityDepositView = 'HOTEL_SETTINGS_STANDARD_POLICY_SECURITY_DEPOSIT____VIEW',
  HotelSettingsStandardPolicySecurityDepositEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_SECURITY_DEPOSIT____EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE____VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TAX_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeServiceChargeEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_SERVICE_CHARGE_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeLevyHeritageTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_LEVY_HERITAGE_TAX_EDIT',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxView = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_VIEW',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxCreate = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_CREATE',
  HotelSettingsStandardPolicyTaxServiceChargeTourismTaxEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_TAX_SERVICE_CHARGE_TOURISM_TAX_EDIT',
  HotelSettingsStandardPolicyRoomStatusView = 'HOTEL_SETTINGS_STANDARD_POLICY_ROOM_STATUS____VIEW',
  HotelSettingsStandardPolicyRoomStatusEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_ROOM_STATUS____EDIT',
  HotelSettingsStandardPolicyCeillingFloorPriceView = 'HOTEL_SETTINGS_STANDARD_POLICY_CEILLING_FLOOR_PRICE____VIEW',
  HotelSettingsStandardPolicyCeillingFloorPriceEdit = 'HOTEL_SETTINGS_STANDARD_POLICY_CEILLING_FLOOR_PRICE____EDIT',
  HotelSettingsLatecheckoutChargeView = 'HOTEL_SETTINGS_LATECHECKOUT_CHARGE_______VIEW',
  HotelSettingsLatecheckoutChargeEdit = 'HOTEL_SETTINGS_LATECHECKOUT_CHARGE_______EDIT',
  HotelSettingsSegmentView = 'HOTEL_SETTINGS_SEGMENT_______VIEW',
  HotelSettingsSegmentCreate = 'HOTEL_SETTINGS_SEGMENT_______CREATE',
  HotelSettingsSegmentEdit = 'HOTEL_SETTINGS_SEGMENT_______EDIT',
  HotelSettingsSegmentActive = 'HOTEL_SETTINGS_SEGMENT_______ACTIVE',
  HotelSettingsEdoctemplateView = 'HOTEL_SETTINGS_EDOCTEMPLATE_______VIEW',
  HotelSettingsGuestappView = 'HOTEL_SETTINGS_GUESTAPP_______VIEW',
  HotelSettingsGuestappActive = 'HOTEL_SETTINGS_GUESTAPP_______ACTIVE',
  CommonSettingsIgnoreView = 'COMMON_SETTINGS_IGNORE_______VIEW',
  CommonSettingsTaxPolicyView = 'COMMON_SETTINGS_TAX_POLICY_______VIEW',
  CommonSettingsTaxPolicyCreate = 'COMMON_SETTINGS_TAX_POLICY_______CREATE',
  CommonSettingsTaxPolicyEdit = 'COMMON_SETTINGS_TAX_POLICY_______EDIT',
  CommonSettingsTaxPolicyDelete = 'COMMON_SETTINGS_TAX_POLICY_______DELETE',
  CommonSettingsTaxSchemePolicyView = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______VIEW',
  CommonSettingsTaxSchemePolicyCreate = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______CREATE',
  CommonSettingsTaxSchemePolicyEdit = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______EDIT',
  CommonSettingsTaxSchemePolicyDelete = 'COMMON_SETTINGS_TAX_SCHEME_POLICY_______DELETE',
  CommonSettingsCompanyListingView = 'COMMON_SETTINGS_COMPANY_LISTING_______VIEW',
  CommonSettingsCompanyListingCreate = 'COMMON_SETTINGS_COMPANY_LISTING_______CREATE',
  CommonSettingsCompanyListingEdit = 'COMMON_SETTINGS_COMPANY_LISTING_______EDIT',
  CommonSettingsCompanyListingActive = 'COMMON_SETTINGS_COMPANY_LISTING_______ACTIVE',
  ConsolidatedEInvoiceIgnoreView = 'CONSOLIDATED_E_INVOICE_IGNORE_______VIEW',
  ConsolidatedEInvoiceIgnoreCreate = 'CONSOLIDATED_E_INVOICE_IGNORE_______CREATE',
  ConsolidatedEInvoiceIgnoreRead = 'CONSOLIDATED_E_INVOICE_IGNORE_______READ',
  CityLedgerIgnoreView = 'CITY_LEDGER_IGNORE_______VIEW',
  CityLedgerScheduleBillingView = 'CITY_LEDGER_SCHEDULE_BILLING_______VIEW',
  CityLedgerScheduleBillingPost = 'CITY_LEDGER_SCHEDULE_BILLING_______POST',
  CityLedgerAccountTransactionView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_______VIEW',
  CityLedgerAccountTransactionInvoiceView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____VIEW',
  CityLedgerAccountTransactionInvoiceCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____CREATE',
  CityLedgerAccountTransactionInvoiceEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____EDIT',
  CityLedgerAccountTransactionInvoiceDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____DRAFT',
  CityLedgerAccountTransactionInvoiceSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____SUBMIT',
  CityLedgerAccountTransactionInvoiceAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____ALLOCATE',
  CityLedgerAccountTransactionInvoicePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____PRINT',
  CityLedgerAccountTransactionGroupInvoiceView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____VIEW',
  CityLedgerAccountTransactionGroupInvoiceCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____CREATE',
  CityLedgerAccountTransactionGroupInvoiceEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____EDIT',
  CityLedgerAccountTransactionGroupInvoiceDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____DRAFT',
  CityLedgerAccountTransactionGroupInvoiceSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____SUBMIT',
  CityLedgerAccountTransactionGroupInvoiceAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____ALLOCATE',
  CityLedgerAccountTransactionGroupInvoicePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____PRINT',
  CityLedgerAccountTransactionDebitNoteView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____VIEW',
  CityLedgerAccountTransactionDebitNoteCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____CREATE',
  CityLedgerAccountTransactionDebitNoteEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____EDIT',
  CityLedgerAccountTransactionDebitNoteDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____DRAFT',
  CityLedgerAccountTransactionDebitNoteSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____SUBMIT',
  CityLedgerAccountTransactionDebitNoteAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____ALLOCATE',
  CityLedgerAccountTransactionDebitNotePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____PRINT',
  CityLedgerAccountTransactionCreditNoteView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____VIEW',
  CityLedgerAccountTransactionCreditNoteCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____CREATE',
  CityLedgerAccountTransactionCreditNoteEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____EDIT',
  CityLedgerAccountTransactionCreditNoteDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____DRAFT',
  CityLedgerAccountTransactionCreditNoteSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____SUBMIT',
  CityLedgerAccountTransactionCreditNoteAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____ALLOCATE',
  CityLedgerAccountTransactionCreditNotePrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____PRINT',
  CityLedgerAccountTransactionOfficialReceiptView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____VIEW',
  CityLedgerAccountTransactionOfficialReceiptCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____CREATE',
  CityLedgerAccountTransactionOfficialReceiptEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____EDIT',
  CityLedgerAccountTransactionOfficialReceiptDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____DRAFT',
  CityLedgerAccountTransactionOfficialReceiptSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____SUBMIT',
  CityLedgerAccountTransactionOfficialReceiptAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____ALLOCATE',
  CityLedgerAccountTransactionOfficialReceiptPrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____PRINT',
  CityLedgerAccountTransactionRefundView = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____VIEW',
  CityLedgerAccountTransactionRefundCreate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____CREATE',
  CityLedgerAccountTransactionRefundEdit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____EDIT',
  CityLedgerAccountTransactionRefundDraft = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____DRAFT',
  CityLedgerAccountTransactionRefundSubmit = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____SUBMIT',
  CityLedgerAccountTransactionRefundAllocate = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____ALLOCATE',
  CityLedgerAccountTransactionRefundPrint = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____PRINT',
  CityLedgerSetupView = 'CITY_LEDGER_SETUP_______VIEW',
  CityLedgerSetupDebtorTypeView = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____VIEW',
  CityLedgerSetupDebtorTypeCreate = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____CREATE',
  CityLedgerSetupDebtorTypeEdit = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____EDIT',
  CityLedgerSetupDebtorTypeDelete = 'CITY_LEDGER_SETUP_DEBTOR_TYPE____DELETE',
  CityLedgerSetupDebtorProfileView = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____VIEW',
  CityLedgerSetupDebtorProfileCreate = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____CREATE',
  CityLedgerSetupDebtorProfileEdit = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____EDIT',
  CityLedgerSetupDebtorProfileActive = 'CITY_LEDGER_SETUP_DEBTOR_PROFILE____ACTIVE',
  CityLedgerCreditControlView = 'CITY_LEDGER_CREDIT_CONTROL_______VIEW',
  CityLedgerStatementOfAccView = 'CITY_LEDGER_STATEMENT_OF_ACC_______VIEW',
  CityLedgerStatementOfAccShare = 'CITY_LEDGER_STATEMENT_OF_ACC_______SHARE',
  CityLedgerAccountTransactionInvoiceCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_INVOICE____CANCEL',
  CityLedgerAccountTransactionGroupInvoiceCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_GROUP_INVOICE____CANCEL',
  CityLedgerAccountTransactionDebitNoteCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_DEBIT_NOTE____CANCEL',
  CityLedgerAccountTransactionCreditNoteCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_CREDIT_NOTE____CANCEL',
  CityLedgerAccountTransactionOfficialReceiptCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_OFFICIAL_RECEIPT____CANCEL',
  CityLedgerAccountTransactionRefundCancel = 'CITY_LEDGER_ACCOUNT_TRANSACTION_REFUND____CANCEL',
  AcctJournalIgnoreView = 'ACCT_JOURNAL_IGNORE_______VIEW',
  AcctJournalAcctMappingHotelGlView = 'ACCT_JOURNAL_ACCT_MAPPING_HOTEL_GL____VIEW',
  AcctJournalAcctMappingHotelGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_HOTEL_GL____EDIT',
  AcctJournalAcctMappingTaxGlView = 'ACCT_JOURNAL_ACCT_MAPPING_TAX_GL____VIEW',
  AcctJournalAcctMappingTaxGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_TAX_GL____EDIT',
  AcctJournalAcctMappingCityGlView = 'ACCT_JOURNAL_ACCT_MAPPING_CITY_GL____VIEW',
  AcctJournalAcctMappingCityGlEdit = 'ACCT_JOURNAL_ACCT_MAPPING_CITY_GL____EDIT',
  AcctJournalAcctMappingBankAcctView = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____VIEW',
  AcctJournalAcctMappingBankAcctCreate = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____CREATE',
  AcctJournalAcctMappingBankAcctEdit = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____EDIT',
  AcctJournalAcctMappingBankAcctDelete = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____DELETE',
  AcctJournalAcctMappingBankAcctActive = 'ACCT_JOURNAL_ACCT_MAPPING_BANK_ACCT____ACTIVE',
  AcctJournalGlJournalView = 'ACCT_JOURNAL_GL_JOURNAL_______VIEW',
  AcctJournalGlJournalCreate = 'ACCT_JOURNAL_GL_JOURNAL_______CREATE',
  AcctJournalGlJournalDownload = 'ACCT_JOURNAL_GL_JOURNAL_______DOWNLOAD',
  AcctJournalGlJournalShare = 'ACCT_JOURNAL_GL_JOURNAL_______SHARE',
  AcctJournalGlJournalDetailView = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____VIEW',
  AcctJournalGlJournalDetailDownload = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____DOWNLOAD',
  AcctJournalGlJournalDetailShare = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL____SHARE',
  AcctJournalGlJournalDetailIndividualView = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_VIEW',
  AcctJournalGlJournalDetailIndividualDownload = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_DOWNLOAD',
  AcctJournalGlJournalDetailIndividualShare = 'ACCT_JOURNAL_GL_JOURNAL_DETAIL_INDIVIDUAL_SHARE',
  AcctJournalCbJournalView = 'ACCT_JOURNAL_CB_JOURNAL_______VIEW',
  AcctJournalCbJournalCreate = 'ACCT_JOURNAL_CB_JOURNAL_______CREATE',
  AcctJournalCbJournalDownload = 'ACCT_JOURNAL_CB_JOURNAL_______DOWNLOAD',
  AcctJournalCbJournalShare = 'ACCT_JOURNAL_CB_JOURNAL_______SHARE',
  AcctJournalCbJournalDetailView = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____VIEW',
  AcctJournalCbJournalDetailDownload = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____DOWNLOAD',
  AcctJournalCbJournalDetailShare = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL____SHARE',
  AcctJournalCbJournalDetailIndividualView = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_VIEW',
  AcctJournalCbJournalDetailIndividualDownload = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_DOWNLOAD',
  AcctJournalCbJournalDetailIndividualShare = 'ACCT_JOURNAL_CB_JOURNAL_DETAIL_INDIVIDUAL_SHARE',
  AcctJournalAcctMappingView = 'ACCT_JOURNAL_ACCT_MAPPING_______VIEW',
  FrontDeskInhouseBillingInstructionView = 'FRONT_DESK_INHOUSE_BILLING_INSTRUCTION____VIEW',
  FrontDeskInhouseBillingInstructionCreate = 'FRONT_DESK_INHOUSE_BILLING_INSTRUCTION____CREATE',
  FrontDeskCheckOutBillingInstructionView = 'FRONT_DESK_CHECK_OUT_BILLING_INSTRUCTION____VIEW',
  FrontDeskCheckOutBillingInstructionCreate = 'FRONT_DESK_CHECK_OUT_BILLING_INSTRUCTION____CREATE',
  FrontDeskCheckInBillingInstructionView = 'FRONT_DESK_CHECK_IN_BILLING_INSTRUCTION____VIEW',
  FrontDeskCheckInBillingInstructionCreate = 'FRONT_DESK_CHECK_IN_BILLING_INSTRUCTION____CREATE',
  SystemAdminIgnoreView = 'SYSTEM_ADMIN_IGNORE_______VIEW',
  SystemAdminUserView = 'SYSTEM_ADMIN_USER____VIEW',
  SystemAdminRolesView = 'SYSTEM_ADMIN_ROLES____VIEW',
  SystemAdminHotelView = 'SYSTEM_ADMIN_HOTEL____VIEW'
}

export type HotelQ3RevenueAcctJson = {
  __typename?: 'HotelQ3RevenueAcctJson';
  HTLRevenueAcct?: Maybe<Array<Q3RevenueAcctJson>>;
  RoundingRevenueAcct?: Maybe<Array<Q3RevenueAcctJson>>;
  GLRevenueAcct?: Maybe<Scalars['JSON']>;
};

export type HotelQ3RevenueAcctJsonInput = {
  HTLRevenueAcct?: Maybe<Array<Q3RevenueAcctJsonInput>>;
  RoundingRevenueAcct?: Maybe<Array<Q3RevenueAcctJsonInput>>;
  GLRevenueAcct?: Maybe<Scalars['JSON']>;
};

export type HotelRatingEntity = InterfaceBase & {
  __typename?: 'HotelRatingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
  TravelAs?: Maybe<Scalars['String']>;
  Cleanliness?: Maybe<Scalars['Float']>;
  Facilities?: Maybe<Scalars['Float']>;
  Location?: Maybe<Scalars['Float']>;
  ComfortQuality?: Maybe<Scalars['Float']>;
  Service?: Maybe<Scalars['Float']>;
  OverallExperience?: Maybe<Scalars['Float']>;
  Comments?: Maybe<Scalars['String']>;
  ReviewDate: Scalars['DateTime'];
  ReviewCount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Booking?: Maybe<BookingEntity>;
};

export type HotelRatingInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  TravelAs?: Maybe<Scalars['String']>;
  Cleanliness?: Maybe<Scalars['Float']>;
  Facilities?: Maybe<Scalars['Float']>;
  Location?: Maybe<Scalars['Float']>;
  ComfortQuality?: Maybe<Scalars['Float']>;
  Service?: Maybe<Scalars['Float']>;
  OverallExperience?: Maybe<Scalars['Float']>;
  Comments?: Maybe<Scalars['String']>;
  ReviewDate?: Maybe<Scalars['DateTime']>;
  ReviewCount?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelStatisticRespond = {
  __typename?: 'HotelStatisticRespond';
  TotalRoomRevenue?: Maybe<Scalars['Float']>;
  TotalFnBRevenue?: Maybe<Scalars['Float']>;
  TotalOtherRevenue?: Maybe<Scalars['Float']>;
  TotalOccupancyRate?: Maybe<Scalars['Float']>;
  TotalArr?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  OOOOOI?: Maybe<Scalars['Float']>;
  TotalRentableRoom?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalComplimentary?: Maybe<Scalars['Float']>;
  TotalHouseUse?: Maybe<Scalars['Float']>;
  TotalDayUse?: Maybe<Scalars['Float']>;
  TotalPax?: Maybe<Scalars['Float']>;
};

export type HotelTaxOutput = {
  __typename?: 'HotelTaxOutput';
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['String']>;
  SeqNo?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
};

export type HotelTaxPolicyEntity = InterfaceBase & {
  __typename?: 'HotelTaxPolicyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TaxType?: Maybe<TaxType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  TTxExemptedReason?: Maybe<Scalars['String']>;
  TTxNonExemptedReason?: Maybe<Scalars['String']>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  TTxExempted?: Maybe<ReasonEntity>;
  TTxNonExempted?: Maybe<ReasonEntity>;
};

export type HotelTaxPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TaxType?: Maybe<TaxType>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  IsHouseUse?: Maybe<Scalars['Boolean']>;
  IsComplimentary?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  TTxExemptedReason?: Maybe<Scalars['String']>;
  TTxNonExemptedReason?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HotelVendorInfoEntity = InterfaceTrx & {
  __typename?: 'HotelVendorInfoEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  VendorName: Scalars['String'];
  VendorInfo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
};

export type HotelVendorInfoInput = {
  HotelID: Scalars['String'];
  VendorName?: Maybe<Scalars['String']>;
  VendorInfo?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type HotelXOtpEntity = InterfaceTrx & {
  __typename?: 'HotelXOtpEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Expiry?: Maybe<Scalars['DateTime']>;
  IPAddress?: Maybe<Scalars['String']>;
};

export type HouseKeepingLogEntity = InterfaceTrx & {
  __typename?: 'HouseKeepingLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  Status: Scalars['String'];
  CleanedBy?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
};

export type HouseKeepingLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  Status: Scalars['String'];
  CleanedBy?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
};

export type HouseLimitInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
};

export type HouseUseOutput = {
  __typename?: 'HouseUseOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type IncidentalChargeEntity = InterfaceBase & {
  __typename?: 'IncidentalChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  IsRoomRevenue?: Maybe<Scalars['Boolean']>;
  IsFnB?: Maybe<Scalars['Boolean']>;
  IsEShop?: Maybe<Scalars['Boolean']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  IsNegative?: Maybe<Scalars['Boolean']>;
  IsPackage?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  IsRateElement?: Maybe<Scalars['Boolean']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  EInvClassification?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
  Gallery?: Maybe<GalleryEntity>;
  Package?: Maybe<Array<PackagesEntity>>;
  isEInvIntegrated?: Maybe<Scalars['Boolean']>;
  MSICCode?: Maybe<Scalars['String']>;
};

export type IncidentalChargeInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  IsRoomRevenue?: Maybe<Scalars['Boolean']>;
  IsRoomService?: Maybe<Scalars['Boolean']>;
  IsFnB?: Maybe<Scalars['Boolean']>;
  IsEShop?: Maybe<Scalars['Boolean']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  IsNegative?: Maybe<Scalars['Boolean']>;
  IsPackage?: Maybe<Scalars['Boolean']>;
  OutletCode?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsRateElement?: Maybe<Scalars['Boolean']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  EInvClassification?: Maybe<Scalars['String']>;
  isEInvIntegrated?: Maybe<Scalars['Boolean']>;
  MSICCode?: Maybe<Scalars['String']>;
  IsKiosk?: Maybe<Scalars['Boolean']>;
};

export type InputItem = {
  LocationID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID: Array<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
};

export type InputReg = {
  RegID?: Maybe<Scalars['String']>;
};

export type InterestEntity = InterfaceTrx & {
  __typename?: 'InterestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  OverDueDays: Scalars['Float'];
  OverDueAmt: Scalars['Float'];
  InterestDate: Scalars['DateTime'];
  InterestRate: Scalars['DateTime'];
  InteresAmt: Scalars['Float'];
  InterestAmt: Scalars['Float'];
  TrxStatus: ArTransactionStatus;
  IsWaived: Scalars['Boolean'];
};

export type InterestEntityInput = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  LedgerID: Scalars['String'];
  OverDueDays: Scalars['Float'];
  OverDueAmt: Scalars['Float'];
  InterestDate: Scalars['DateTime'];
  InterestRate: Scalars['DateTime'];
  InteresAmt: Scalars['Float'];
  InterestAmt: Scalars['Float'];
  TrxStatus?: Maybe<ArTransactionStatus>;
  IsWaived: Scalars['Boolean'];
};

export type InterestResponse = {
  __typename?: 'InterestResponse';
  TotalTrxAmt: Scalars['Float'];
  TotalInterestAmt: Scalars['Float'];
};

export type InterfaceBase = {
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceTaxTrx = InterfaceTrx & {
  __typename?: 'InterfaceTaxTrx';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
};

export type InterfaceTrx = {
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceTrxPabx = {
  rowid: Scalars['String'];
};

export type InvoiceEntity = InterfaceTrx & {
  __typename?: 'InvoiceEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<InvoiceTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  SourceID?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  GroupDocumentNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type InvoiceEntityInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
};

export type InvoiceTrxEntity = InterfaceTrx & {
  __typename?: 'InvoiceTrxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  TransactionID: Scalars['String'];
  TrxName?: Maybe<Scalars['String']>;
  TrxAmount: Scalars['Float'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  InvoiceID: Scalars['String'];
  InvoiceTrxTax?: Maybe<Array<InvoiceTrxTaxEntity>>;
};

export type InvoiceTrxInput = {
  TransactionID: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  UnitPrice: Scalars['Float'];
  Quantity: Scalars['Float'];
  DiscountedAmount: Scalars['Float'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  AllocationAmount?: Maybe<Scalars['Float']>;
  TrxName?: Maybe<Scalars['String']>;
};

export type InvoiceTrxTaxEntity = InterfaceTrx & {
  __typename?: 'InvoiceTrxTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  InvoiceTrxID: Scalars['String'];
  InvoiceTrx?: Maybe<InvoiceTrxEntity>;
};

export enum InvoiceType {
  Individual = 'Individual',
  Company = 'Company',
  ForeignBuyer = 'ForeignBuyer',
  Government = 'Government'
}

export type Item = {
  __typename?: 'item';
  checkoutId?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  qrCodeUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type KeyCardEntity = {
  __typename?: 'KeyCardEntity';
  rowid: Scalars['Float'];
  s_data?: Maybe<Scalars['String']>;
  status_flag?: Maybe<Scalars['String']>;
  audit_date?: Maybe<Scalars['DateTime']>;
};

export type KeyCardInfoEntity = {
  __typename?: 'KeyCardInfoEntity';
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  RegistrationID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  IssueType?: Maybe<Scalars['String']>;
  EncoderNo?: Maybe<Scalars['String']>;
};

export type KeyCardInput = {
  s_data?: Maybe<Scalars['String']>;
  Operation?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['String']>;
  CheckOutDate?: Maybe<Scalars['String']>;
  encoder?: Maybe<Scalars['String']>;
  lift?: Maybe<Scalars['String']>;
  commonDoor?: Maybe<Scalars['String']>;
  status_flag?: Maybe<Scalars['String']>;
  audit_date?: Maybe<Scalars['DateTime']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
};

export type KiwireLogEntity = InterfaceTrx & {
  __typename?: 'KiwireLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type LateCheckOutChargeEntity = InterfaceBase & {
  __typename?: 'LateCheckOutChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
};

export type LateCheckOutChargeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LedgerIDsInput = {
  BillSchedulesSplit?: Maybe<Array<Scalars['Float']>>;
  BillSchedulesDescription?: Maybe<Array<Scalars['String']>>;
  BillSchedules?: Maybe<Array<Scalars['String']>>;
  BillSchedulesDate?: Maybe<Array<Scalars['DateTime']>>;
  RoomLedgersSplit?: Maybe<Array<Scalars['Float']>>;
  RoomLedgersDescription?: Maybe<Array<Scalars['String']>>;
  RoomLedgers?: Maybe<Array<Scalars['String']>>;
  RoomLedgersDate?: Maybe<Array<Scalars['DateTime']>>;
  BillLedgerIDs?: Maybe<Array<Scalars['String']>>;
  TaxLedgerIDs?: Maybe<Array<Scalars['String']>>;
  PaymentIDs?: Maybe<Array<Scalars['String']>>;
  AdvancePaymentIDs?: Maybe<Array<Scalars['String']>>;
  DepositLedgerIDs?: Maybe<Array<Scalars['String']>>;
};

export type LedgerInfoOutput = {
  __typename?: 'LedgerInfoOutput';
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
};

export type LedgerOutput = {
  __typename?: 'LedgerOutput';
  TaxRate?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  TaxInfo?: Maybe<TaxEntity>;
};

export type ListEntRoleUsrAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  hotelID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LocationEntity = InterfaceBase & {
  __typename?: 'LocationEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<Array<RoomEntity>>;
  GalleryID?: Maybe<Scalars['String']>;
  Gallery?: Maybe<GalleryEntity>;
};


export type LocationEntityRoomArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
};

export type LocationFloorPlanEntity = InterfaceBase & {
  __typename?: 'LocationFloorPlanEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  RoomID: Scalars['String'];
  IsHotel?: Maybe<Scalars['Boolean']>;
  IsPreCheckin?: Maybe<Scalars['Boolean']>;
  RoomTypeID: Scalars['String'];
  Coordinates: Scalars['String'];
  RoomNo: Scalars['String'];
  Room?: Maybe<RoomEntity>;
};

export type LocationFloorPlanInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  LocationID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  IsHotel?: Maybe<Scalars['Boolean']>;
  IsPreCheckin?: Maybe<Scalars['Boolean']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Coordinates?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type ManagerReportOutput = {
  __typename?: 'ManagerReportOutput';
  SegmentReport?: Maybe<Array<SegmentReportOutput>>;
  SourceReport?: Maybe<Array<SourceReportOutput>>;
  CountryReport?: Maybe<Array<CountryReportOutput>>;
  DepartmentReport?: Maybe<Array<DepartmentReportOutput>>;
  TaxReport?: Maybe<Array<TaxReportOutput>>;
  PaymentReport?: Maybe<Array<PaymentOutput>>;
  BookingCancellation?: Maybe<Array<BookingCancellation>>;
  VIPInHouse?: Maybe<Array<VipInHouseOutput>>;
  GroupInHouse?: Maybe<Array<GroupInHouseOutput>>;
  TomorrowGroup?: Maybe<Array<TmrGroupOutput>>;
  HouseUseRoom?: Maybe<Array<HouseUseOutput>>;
  CompRoom?: Maybe<Array<CompOutput>>;
  ExtendStay?: Maybe<Array<ExtendStayOutPut>>;
  ThreeDayForcast?: Maybe<Array<ForecastOutPut>>;
};

export enum Marital {
  Divorced = 'Divorced',
  Married = 'Married',
  Seperated = 'Seperated',
  Single = 'Single',
  Widowed = 'Widowed'
}

export type MeasurementEntity = InterfaceBase & {
  __typename?: 'MeasurementEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Measurement: Scalars['String'];
  Size?: Maybe<Scalars['Float']>;
};

export type MeasurementInput = {
  ID: Scalars['String'];
  Type: Scalars['String'];
  Measurement: Scalars['String'];
  Size?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum MeasureType {
  SquareMetre = 'Square_Metre',
  FootSquare = 'Foot_Square'
}

export type Mutation = {
  __typename?: 'Mutation';
  HotelTaxPolicyInsert: Scalars['Boolean'];
  HotelTaxPolicyUpdate: Scalars['Boolean'];
  HotelTaxPolicyDelete: Scalars['Boolean'];
  HLSBookingNotification: Scalars['Boolean'];
  UploadImage: Array<Scalars['String']>;
  DeleteImage: Scalars['Boolean'];
  SpecialReqUpdate: Scalars['Boolean'];
  AssignRoom: Scalars['Boolean'];
  MultiCheckIn: Scalars['Boolean'];
  CheckIn: Scalars['Boolean'];
  TransferRoom: Scalars['Boolean'];
  GuestInsert: Scalars['Boolean'];
  DepositInsert: Scalars['Boolean'];
  RefundDeposit: Scalars['Boolean'];
  CloseFolio: Scalars['Boolean'];
  VoidLedger: Scalars['Boolean'];
  RegistrationSignatureUpdate: RegistrationEntity;
  VoidBillLedger: Scalars['Boolean'];
  PosV7VoidBillLedger: Scalars['Boolean'];
  RefundPayment?: Maybe<CheckOut>;
  FrontDeskPaymentInsert: PaymentEntity;
  CheckOut: Scalars['Boolean'];
  WalkIngBookingInsert: Scalars['JSON'];
  LateCheckOutInsert: Scalars['JSON'];
  ExtendStayInsert: Scalars['JSON'];
  ShortenStayInsert: Scalars['String'];
  RoomUpGradeInsert: Scalars['JSON'];
  FolioDelete: Scalars['Boolean'];
  NonGuestFolioInsert: Scalars['Boolean'];
  SuspendFolioInsert: Scalars['Boolean'];
  NonGuestFolioUpdate: Scalars['Boolean'];
  NonGuestVoidLedger: Scalars['Boolean'];
  NonGuestPaymentInsert: PaymentEntity;
  NonGuestCloseFolio: Scalars['Boolean'];
  NonGuestTransferFolio: Scalars['Boolean'];
  SplitPaymentV2: Scalars['Boolean'];
  TransferPayment: Scalars['Boolean'];
  RegistrationSign: Scalars['Boolean'];
  PaymentUpdate: Scalars['Boolean'];
  CancelCheckIn: Scalars['Boolean'];
  FolioBeneficiaryNameUpdate: Scalars['Boolean'];
  FDReinstateBooking: Scalars['Boolean'];
  LockUserProcess: Scalars['JSON'];
  HotelNotificationUpdate: Scalars['Boolean'];
  GuestRegister: Scalars['Boolean'];
  GuestLogin: GuestProfileEntity;
  GuestAppAuthGenerator: GuestProfileEntity;
  GuestChangePassword: GuestProfileEntity;
  GuestForgotPassword: Scalars['Boolean'];
  EmailResetPassword: Scalars['Boolean'];
  GuestResetPassword: Scalars['Boolean'];
  OnlineBookingInsert: Scalars['JSON'];
  GuestRoomServiceInsert: Scalars['JSON'];
  HotelRatingInsert: HotelRatingEntity;
  QrScannerInsert: Scalars['String'];
  PromotionUpdate: Scalars['Boolean'];
  PromotionInsert: PromotionEntity;
  PromotionActiveUpdate: Scalars['Boolean'];
  PromotionDetailUpdate: Scalars['Boolean'];
  PromotionDetailInsert: PromotionDetailEntity;
  EmailCampignUpdate: Scalars['Boolean'];
  EmailCampignInsert: EmailCampignEntity;
  EmailCampaign: Scalars['Boolean'];
  PromotionDelete: Scalars['Boolean'];
  EmailDelete: Scalars['Boolean'];
  GuestReviewActiveUpdate: Scalars['Boolean'];
  BookingInsert: Scalars['String'];
  GuestAppRoomTypeDelete: Scalars['Boolean'];
  BookingRoomTypeUpdate: Scalars['Boolean'];
  HouseLimitUpdate: Scalars['Boolean'];
  BookingUpdate: Scalars['Boolean'];
  CancelBooking: Scalars['Boolean'];
  AdvancePaymentInsert: AdvancePaymentEntity;
  AdvancePaymentRefund: Scalars['Boolean'];
  AdvancePaymentUpdate: Scalars['Boolean'];
  ReinstateBooking: Scalars['Boolean'];
  ImageUpload: GalleryEntity;
  ManualNightAudit: Scalars['Boolean'];
  AvailabilityUpdate: Scalars['Boolean'];
  AtestInsert: Scalars['Boolean'];
  walkInRoomAssigned: Scalars['Boolean'];
  BookingRoomAssignment: Scalars['Boolean'];
  BillScheduleAdjustment: Scalars['Boolean'];
  NewBookingInfoUpdate: Scalars['Boolean'];
  NewBookingRemark: Scalars['Boolean'];
  BookingUpdateV2: Scalars['Boolean'];
  CancelRoom: Scalars['Boolean'];
  ReinstateRoom: Scalars['Boolean'];
  WaitinglistNewAPI: Scalars['Boolean'];
  BookingIncChargesCancel: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  MenuOptionUpdate: Scalars['Boolean'];
  NotificationInsert: Scalars['Boolean'];
  OnlinePayment?: Maybe<CheckOut>;
  sendRegisterOTP: Scalars['Boolean'];
  verifyOTP: Scalars['Boolean'];
  createUser: UserEntity;
  createPassword: Scalars['Boolean'];
  updateUser: UserEntity;
  updateProfile: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: UserEntity;
  logout: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  unLockUser: Scalars['Boolean'];
  reSendActivationEmail: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  createSuperUser: UserEntity;
  InsertInterestAmt: Scalars['Boolean'];
  testapi: Scalars['Boolean'];
  UpdateInterestAmt: Scalars['Float'];
  ManualNightAuditforAllHotel: Scalars['Boolean'];
  genHashPassword: Scalars['JSON'];
  RegenerateBillSchedule: Scalars['Boolean'];
  RegeneratePostTaxes: Scalars['Boolean'];
  ManualHotelNA: Scalars['Boolean'];
  ManualNARegistrationInsert: Scalars['String'];
  ManualAvailabilityUpdate: Scalars['String'];
  CMSManualAvailUpdate: Scalars['Boolean'];
  CutOffBilling: Scalars['String'];
  KeyCardInsert: Scalars['Boolean'];
  DebitNoteInsert: DebitNoteEntity;
  DebitNoteUpdate: Scalars['Boolean'];
  DebitNoteCancel: Scalars['Boolean'];
  AllocateDebitNote: Scalars['Boolean'];
  DebitNoteDelete: Scalars['Boolean'];
  CreditNoteInsert: CreditNoteEntity;
  CreditNoteUpdate: Scalars['Boolean'];
  CreditNoteCancel: Scalars['Boolean'];
  AllocateCreditNote: Scalars['Boolean'];
  CreditNoteDelete: Scalars['Boolean'];
  RefundInsert: RefundEntity;
  RefundUpdate: Scalars['Boolean'];
  RefundCancel: Scalars['Boolean'];
  AllocateRefund: Scalars['Boolean'];
  RefundDelete: Scalars['Boolean'];
  InvoiceInsert: InvoiceEntity;
  InvoiceUpdate: Scalars['Boolean'];
  InvoiceCancel: Scalars['Boolean'];
  AllocateInvoice: Scalars['Boolean'];
  InvoiceDelete: Scalars['Boolean'];
  OfficialReceiptInsert: OfficialReceiptEntity;
  OfficialReceiptUpdate: Scalars['Boolean'];
  OfficialReceiptCancel: Scalars['Boolean'];
  AllocateOfficialReceipt: Scalars['Boolean'];
  OfficialReceiptDelete: Scalars['Boolean'];
  ReminderInsert: ReminderEntity;
  ReminderUpdate: Scalars['Boolean'];
  ReminderDelete: Scalars['Boolean'];
  asyncInterestInsert: InterestEntity;
  InterestUpdate: Scalars['Boolean'];
  InterestDelete: Scalars['Boolean'];
  DocumentNumberingSetupInsert: Scalars['Boolean'];
  DocumentNumberingUpdate: Scalars['Boolean'];
  DebtorTypeInsert: DebtorTypeEntity;
  DebtorTypeUpdate: Scalars['Boolean'];
  DebtorTypeDelete: Scalars['Boolean'];
  PostScheduleBilling: Scalars['Boolean'];
  RoomSaleOptimizationUpdate: Scalars['Boolean'];
  MigrationAPI: Scalars['Boolean'];
  BookingAttachmentInsert: Scalars['Boolean'];
  BookingAttachmentUpdate: Scalars['Boolean'];
  BookingAttachmentDelete: Scalars['Boolean'];
  EmailSend: Scalars['Boolean'];
  BISharedGAlleryInsert: SharedGalleryEntity;
  ChooseRoomAllocationInsert: Scalars['Boolean'];
  ChooseRoomAllocationUpdate: Scalars['Boolean'];
  ChooseRoomAllocationDelete: Scalars['Boolean'];
  CompanyInsert: CompanyEntity;
  CompanyUpdate: Scalars['Boolean'];
  CompanyUpdateStatus: Scalars['Boolean'];
  CorporateInsert: DebtorEntity;
  GovermentTestingUpdate: Scalars['Boolean'];
  CorporateUpdate: Scalars['Boolean'];
  CorporateContractUpdate: Scalars['Boolean'];
  CorporateContractInsert: ContractEntity;
  CorporateDelete: Scalars['Boolean'];
  ActivityInsert: Scalars['Boolean'];
  ActivityUpdate: Scalars['Boolean'];
  GenerateConsolidatedDocOld: Scalars['Boolean'];
  EventScheduleBilling: Scalars['Boolean'];
  EventVoidBillLedger: Scalars['Boolean'];
  EventBillLedgerInsert: Scalars['Boolean'];
  RegenerateNARegistration: Scalars['JSON'];
  GuestUpdate: Scalars['Boolean'];
  GuestProfileInsert: RegistrationEntity;
  GuestContactUpdate: Scalars['Boolean'];
  GuestProfileDelete: Scalars['Boolean'];
  NoOfPaxUpdate: Scalars['Boolean'];
  GuestMovementInsert: Scalars['Boolean'];
  CBPost: Scalars['JSON'];
  GLPost: GlPostResponse;
  PaymentTypeGLInsert: Scalars['Boolean'];
  BillLedgerUpdate: Scalars['Boolean'];
  BillLedgerCancel: Scalars['Boolean'];
  TaxLedgerInsert: Array<Scalars['String']>;
  BillLedgerInsert: Scalars['Boolean'];
  PosV7BillLedgerInsert: Scalars['Boolean'];
  FolioInsert: Scalars['Boolean'];
  RoomServicePaymentUpdate: Scalars['Boolean'];
  OnlinePaymentRoomServices: OnlineItemEntity;
  FolioAdjustmentInsert: PaymentEntity;
  ChangePayment: Scalars['Boolean'];
  POSChargeToCityLedger: Scalars['Boolean'];
  POSV7ScheduleBilling: Scalars['Boolean'];
  POSV7DailyRevenue: Scalars['Boolean'];
  MenuXDailyRevenue: Scalars['Boolean'];
  HuaweiOCR?: Maybe<Scalars['JSON']>;
  Innov8OCR?: Maybe<Scalars['JSON']>;
  PackageRedemptionInsert: Scalars['Boolean'];
  PreRegPaymentInsert: Scalars['JSON'];
  UpdateRoomAssignment: Scalars['Boolean'];
  ExpressCheckinConfirmation: Scalars['Boolean'];
  CheckInInsertV2: Scalars['Boolean'];
  PreCheckInInsert: Scalars['Boolean'];
  Q3DocumentInsert: Scalars['Boolean'];
  RevenueMappingInsert: Scalars['Boolean'];
  SegmentMappingInsert: Scalars['Boolean'];
  StatisticMappingInsert: Scalars['Boolean'];
  BlockRoomLogInsert: BlockRoomLogEntity;
  BlockRoomLogInsertV2: Array<BlockRoomLogEntity>;
  ReleaseRoomLogUpdateV2: Scalars['Boolean'];
  BlockRoomLogUpdate: Scalars['Boolean'];
  HouseKeepingLogInsert: Scalars['Boolean'];
  HousekeepingServiceRequestInsert: ServiceRequestEntity;
  HousekeepingServiceRequestUpdate: Scalars['Boolean'];
  HousekeepingBillLedgerUpdate: Scalars['Boolean'];
  DepartmentInsert: Scalars['Boolean'];
  DepartmentUpdate: Scalars['Boolean'];
  IncidentalChargesUpdate: Scalars['Boolean'];
  DepartmentDelete: Scalars['Boolean'];
  EDocInsert: Scalars['Boolean'];
  EDocTemplateUpdate: Scalars['Boolean'];
  ConfirmationTemplateDelete: Scalars['Boolean'];
  AccountMappingGLInsert: Scalars['Boolean'];
  CityLedgerGLInsert: Scalars['Boolean'];
  AccountMappingTaxGLInsert: Scalars['Boolean'];
  CityLedgerTaxGLInsert: Scalars['Boolean'];
  BankAccountUpdate: Scalars['Boolean'];
  BankAccountInsert: BankAccountEntity;
  BankAccountDelete: Scalars['Boolean'];
  BankAccountInActive: Scalars['Boolean'];
  FacilitiesInactiveDelete: Scalars['Boolean'];
  GuestAppSetupUpdate: Scalars['Boolean'];
  InvoiceRemark: Scalars['Boolean'];
  HotelUpdate: Scalars['Boolean'];
  HotelInsert: HotelEntity;
  HotelContactUpdate: Scalars['Boolean'];
  HotelContactInsert: ContactEntity;
  HotelFacilityUpdate: Scalars['Boolean'];
  HotelFacilityInsert: FacilityEntity;
  HotelNewsUpdate: Scalars['Boolean'];
  HotelNewsInsert: NewsEntity;
  IncidentalChargeInsert: Scalars['Boolean'];
  IncidentalChargeUpdate: Scalars['Boolean'];
  IncidentalChargeDelete: Scalars['Boolean'];
  LateCheckOutChargeInsert: Scalars['Boolean'];
  FloorPlanUpdate: Scalars['Boolean'];
  FloorPlanReset: Scalars['Boolean'];
  LocationInsert: LocationEntity;
  LocationUpdate: Scalars['Boolean'];
  DisplaySequenceUpdate: Scalars['Boolean'];
  LocationDelete: Scalars['Boolean'];
  RateElementInsert: RateElementEntity;
  RateElementUpdate: Scalars['Boolean'];
  RateAddOnInsert: RateAddOnEntity;
  RateAddOnUpdate: Scalars['Boolean'];
  RateTypeInsert: RateTypeEntity;
  RateTypeUpdate: Scalars['Boolean'];
  RateInsert: RateEntity;
  RateUpdate: Scalars['Boolean'];
  RateEffectiveInsert: Scalars['Boolean'];
  RateEffectiveUpdate: Scalars['Boolean'];
  CMSRateSync: Scalars['Boolean'];
  RateEffectiveStatusUpdate: Scalars['Boolean'];
  RateAdjustmentAuditInsert: Scalars['Boolean'];
  RegenBSQueueInsert: Scalars['Boolean'];
  RegenBSQueueConfirm: Scalars['Boolean'];
  SeasonInsert: SeasonEntity;
  SeasonUpdate: Scalars['Boolean'];
  SeasonCalendarUpdate: Scalars['Boolean'];
  RatePolicyInsert: RatePolicyEntity;
  RatePolicyUpdate: Scalars['Boolean'];
  PackageInsert: Scalars['Boolean'];
  PackageUpdate: Scalars['Boolean'];
  RatePolicyDelete: Scalars['Boolean'];
  ReasonInsert: Scalars['Boolean'];
  ReasonUpdate: Scalars['Boolean'];
  ReasonDelete: Scalars['Boolean'];
  RoomInsert: RoomEntity;
  RoomUpdate: Scalars['Boolean'];
  RoomDelete: Scalars['Boolean'];
  RoomStatusInsert: Scalars['Boolean'];
  RoomTypeUpdate: Scalars['Boolean'];
  RoomTypeInsert: RoomTypeEntity;
  RoomTypeDelete: Scalars['Boolean'];
  SalesChannelInsert: Scalars['Boolean'];
  SalesChannelUpdate: Scalars['Boolean'];
  SalesChannelDelete: Scalars['Boolean'];
  SegmentUpdate: Scalars['Boolean'];
  SegmentInsert: SegmentEntity;
  SegmentDelete: Scalars['Boolean'];
  TaxInsert: Scalars['Boolean'];
  TaxUpdate: Scalars['Boolean'];
  TaxDelete: Scalars['Boolean'];
  TaxSchemeInsert: Scalars['Boolean'];
  TaxSchemeUpdate: Scalars['Boolean'];
  TaxSchemeDelete: Scalars['Boolean'];
  SetDefaultTaxScheme: Scalars['Boolean'];
  TravelAgentInsert: DebtorEntity;
  TravelAgentMainUpdate: Scalars['Boolean'];
  TravelAgentUpdate: Scalars['Boolean'];
  TravelAgentContractUpdate: Scalars['Boolean'];
  TravelAgentContractInsert: ContractEntity;
  UpdateAllotment: Scalars['Boolean'];
  TravelAgentDelete: Scalars['Boolean'];
  DebtorLogin: DebtorProfileEntity;
  DebtorLastAccessedHotelUpdate: Scalars['Boolean'];
  DebtorChangePassword: DebtorProfileEntity;
  DebtorForgotPassword: Scalars['Boolean'];
  AgentDelete: Scalars['Boolean'];
  DebtorResetPassword: Scalars['Boolean'];
  DebtorPaymentInsert: Scalars['Boolean'];
};


export type MutationHotelTaxPolicyInsertArgs = {
  HotelTaxPolicyInput: HotelTaxPolicyInput;
};


export type MutationHotelTaxPolicyUpdateArgs = {
  HotelTaxPolicyInput: HotelTaxPolicyInput;
};


export type MutationHotelTaxPolicyDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationHlsBookingNotificationArgs = {
  BookingId: Scalars['String'];
  HotelId: Scalars['String'];
};


export type MutationUploadImageArgs = {
  FileInput: Array<Scalars['Upload']>;
};


export type MutationDeleteImageArgs = {
  OldGalleryID: Array<Scalars['String']>;
};


export type MutationSpecialReqUpdateArgs = {
  SpecialReq: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type MutationAssignRoomArgs = {
  Mode?: Maybe<Scalars['Boolean']>;
  RoomID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationMultiCheckInArgs = {
  GroupCheckIn?: Maybe<Scalars['Boolean']>;
  GuestAppCheckIn?: Maybe<Scalars['Boolean']>;
  ContactInput?: Maybe<Array<ContactInput>>;
  AdditionalMultiRegInput?: Maybe<Array<AdditionalMultiRegInput>>;
  RegistrationIDs: Array<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationCheckInArgs = {
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  GroupCheckIn?: Maybe<Scalars['Boolean']>;
  GuestAppCheckIn?: Maybe<Scalars['Boolean']>;
  IdPictureBack?: Maybe<Array<Scalars['Upload']>>;
  IdPicture?: Maybe<Array<Scalars['Upload']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ContactInput?: Maybe<ContactInput>;
  RegistrationIDs: Array<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationTransferRoomArgs = {
  Remark?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  NewRoomID: Scalars['String'];
  OldRoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationGuestInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  guestType?: Maybe<GuestType>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ContactInput: Array<ContactInput>;
};


export type MutationDepositInsertArgs = {
  input: DepositInput;
};


export type MutationRefundDepositArgs = {
  input: RefundDepositInput;
};


export type MutationCloseFolioArgs = {
  PaymentID: Scalars['String'];
  RegistrationID: Array<Scalars['String']>;
  FolioInput: FolioInput;
};


export type MutationVoidLedgerArgs = {
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  ChargeType?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  ID: Scalars['String'];
  LedgerType: Scalars['String'];
};


export type MutationRegistrationSignatureUpdateArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  RegistrationID: Scalars['String'];
};


export type MutationVoidBillLedgerArgs = {
  input: BillLedgerInput;
};


export type MutationPosV7VoidBillLedgerArgs = {
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  input: BillLedgerInput;
};


export type MutationRefundPaymentArgs = {
  ReceiptNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationFrontDeskPaymentInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  AdjustedAmount?: Maybe<Scalars['Float']>;
  IsGroupFolio?: Maybe<Scalars['Boolean']>;
  DebtorID?: Maybe<Scalars['String']>;
  BeneficiaryAddress?: Maybe<Scalars['String']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  TotalPaymentAmount: Scalars['Float'];
  input: Array<FrontDeskPaymentInput>;
};


export type MutationCheckOutArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type MutationWalkIngBookingInsertArgs = {
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  IdPicture?: Maybe<Array<Scalars['Upload']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RegistrationInput: RegistrationInput;
  ContactInput: ContactInput;
  BookingInput: BookingInput;
};


export type MutationLateCheckOutInsertArgs = {
  AdjustedAmount?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  AppName?: Maybe<Scalars['String']>;
  hour: Scalars['Float'];
  input: FrontDeskPaymentInput;
};


export type MutationExtendStayInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  AdjustedAmount?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  RoomTypeID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  input: FrontDeskPaymentInput;
};


export type MutationShortenStayInsertArgs = {
  RoomTypeID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
};


export type MutationRoomUpGradeInsertArgs = {
  AdjustedAmount?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
  IsBooking?: Maybe<Scalars['Boolean']>;
  RoomUpgradeInput: RoomUpgradeInput;
  PaymentInput?: Maybe<FrontDeskPaymentInput>;
};


export type MutationFolioDeleteArgs = {
  FolioID: Scalars['String'];
};


export type MutationNonGuestFolioInsertArgs = {
  FolioInput: FolioInput;
};


export type MutationSuspendFolioInsertArgs = {
  FolioInput: FolioInput;
};


export type MutationNonGuestFolioUpdateArgs = {
  FolioInput: FolioInput;
};


export type MutationNonGuestVoidLedgerArgs = {
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  BillLedgerID?: Maybe<Scalars['String']>;
};


export type MutationNonGuestPaymentInsertArgs = {
  HotelID?: Maybe<Scalars['String']>;
  BillLedgerIDs?: Maybe<Array<Scalars['String']>>;
  PaymentInput: PaymentInput;
};


export type MutationNonGuestCloseFolioArgs = {
  eInvoiceType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  FolioID: Scalars['String'];
};


export type MutationNonGuestTransferFolioArgs = {
  HotelID?: Maybe<Scalars['String']>;
  IsSuspend?: Maybe<Scalars['Boolean']>;
  RecordIDs: Array<Scalars['String']>;
  FolioID: Scalars['String'];
};


export type MutationSplitPaymentV2Args = {
  SplitValue: Scalars['Float'];
  SplitType: Scalars['String'];
  SplitBy: Scalars['Float'];
  RecordToSplit: Array<PaymentFolioInput>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationTransferPaymentArgs = {
  FolioID?: Maybe<Scalars['String']>;
  IsNonguest?: Maybe<Scalars['Boolean']>;
  RecordIDs: Array<Scalars['String']>;
  FromRegistrationID?: Maybe<Scalars['String']>;
  NewRegistrationID: Scalars['String'];
};


export type MutationRegistrationSignArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  ID: Scalars['String'];
};


export type MutationPaymentUpdateArgs = {
  Mode?: Maybe<Scalars['String']>;
  AppName?: Maybe<Scalars['String']>;
  IsGuestApp?: Maybe<Scalars['Boolean']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};


export type MutationCancelCheckInArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationFolioBeneficiaryNameUpdateArgs = {
  BeneficiaryAddress: Scalars['String'];
  BeneficiaryName: Scalars['String'];
  FolioID: Scalars['String'];
};


export type MutationFdReinstateBookingArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationLockUserProcessArgs = {
  RoomNo: Array<Scalars['String']>;
  Process: Scalars['String'];
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
};


export type MutationHotelNotificationUpdateArgs = {
  operationType?: Maybe<Scalars['String']>;
  input: Array<HotelNotificationInput>;
};


export type MutationGuestRegisterArgs = {
  AccountID: Scalars['String'];
  Password: Scalars['String'];
  Login: Scalars['String'];
  NRIC: Scalars['String'];
  FullName: Scalars['String'];
  MobileNo: Scalars['String'];
  Email: Scalars['String'];
};


export type MutationGuestLoginArgs = {
  AccountID: Scalars['String'];
  Password: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};


export type MutationGuestAppAuthGeneratorArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationGuestChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationGuestForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationEmailResetPasswordArgs = {
  Email?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type MutationGuestResetPasswordArgs = {
  password: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
};


export type MutationOnlineBookingInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  ContactInput: ContactInput;
  RegistrationInput: Array<RegistrationInput>;
  BookingInput: BookingInput;
};


export type MutationGuestRoomServiceInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
  PaymentMode: Scalars['String'];
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationHotelRatingInsertArgs = {
  input: HotelRatingInput;
};


export type MutationQrScannerInsertArgs = {
  DeviceData?: Maybe<Scalars['String']>;
  QrData?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationPromotionUpdateArgs = {
  PromotionDetailInput: Array<PromotionDetailInput>;
  PromotionInput: PromotionInput;
};


export type MutationPromotionInsertArgs = {
  PromotionDetailInput: Array<PromotionDetailInput>;
  PromotionInput: PromotionInput;
};


export type MutationPromotionActiveUpdateArgs = {
  PromotionInput: PromotionInput;
};


export type MutationPromotionDetailUpdateArgs = {
  PromotionDetailInput: PromotionDetailInput;
};


export type MutationPromotionDetailInsertArgs = {
  PromotionDetailInput: PromotionDetailInput;
};


export type MutationEmailCampignUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  EmailCampignInput: EmailCampignInput;
};


export type MutationEmailCampignInsertArgs = {
  EmailCampignInput: EmailCampignInput;
};


export type MutationEmailCampaignArgs = {
  HotelID: Scalars['String'];
  IsSelectAll: Scalars['Boolean'];
  id: Scalars['String'];
  email: Array<Scalars['String']>;
};


export type MutationPromotionDeleteArgs = {
  Date: Scalars['DateTime'];
  PromoCode: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationEmailDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationGuestReviewActiveUpdateArgs = {
  HotelRatingInput: HotelRatingInput;
};


export type MutationBookingInsertArgs = {
  IsSharedConfirmationLetter?: Maybe<Scalars['Boolean']>;
  DebtorType?: Maybe<Scalars['String']>;
  RegistrationInput: Array<RegistrationInput>;
  ContactInput: ContactInput;
  BookingInput: BookingInput;
};


export type MutationGuestAppRoomTypeDeleteArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['Float']>;
  BookingID: Scalars['String'];
};


export type MutationBookingRoomTypeUpdateArgs = {
  EditRegInput: EditRegInput;
};


export type MutationHouseLimitUpdateArgs = {
  HouseLimitInput?: Maybe<Array<HouseLimitInput>>;
};


export type MutationBookingUpdateArgs = {
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput>>;
  ContactInput?: Maybe<ContactInput>;
  BookingInput: BookingInput;
};


export type MutationCancelBookingArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationAdvancePaymentInsertArgs = {
  AdvancePaymentInput: AdvancePaymentInput;
};


export type MutationAdvancePaymentRefundArgs = {
  AdvancePaymentInput: Array<AdvancePaymentInput>;
};


export type MutationAdvancePaymentUpdateArgs = {
  AppName?: Maybe<Scalars['String']>;
  IsGuestApp?: Maybe<Scalars['Boolean']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
};


export type MutationReinstateBookingArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
};


export type MutationImageUploadArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
};


export type MutationManualNightAuditArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationAvailabilityUpdateArgs = {
  TravelAgentID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type MutationAtestInsertArgs = {
  BookingID: Scalars['String'];
};


export type MutationWalkInRoomAssignedArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationBookingRoomAssignmentArgs = {
  RoomTypeID: Scalars['String'];
  BookingRoomAssignInput?: Maybe<Array<BookingRoomAssignInput>>;
  RegistrationIDs?: Maybe<Array<Scalars['String']>>;
  RoomIDs?: Maybe<Array<Scalars['String']>>;
  BookingID: Scalars['String'];
};


export type MutationBillScheduleAdjustmentArgs = {
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
  Reason: Scalars['String'];
  Discount: Scalars['Float'];
  RateID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
  TrxDates: Array<Scalars['DateTime']>;
};


export type MutationNewBookingInfoUpdateArgs = {
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput>>;
  BookingInput: BookingInput;
  BookingID: Scalars['String'];
};


export type MutationNewBookingRemarkArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  BookingRemark: Scalars['String'];
  BookingID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type MutationBookingUpdateV2Args = {
  EditRegInput?: Maybe<Array<EditRegInput>>;
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput>>;
  ContactInput?: Maybe<ContactInput>;
  BookingInput: BookingInput;
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
  PrintRate?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
};


export type MutationCancelRoomArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationReinstateRoomArgs = {
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationWaitinglistNewApiArgs = {
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
  BookingID: Scalars['String'];
};


export type MutationBookingIncChargesCancelArgs = {
  BillID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<HotelPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<HotelPermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntRoleUsrAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  hotelID: Scalars['String'];
};


export type MutationMenuOptionUpdateArgs = {
  input: RoleInput;
};


export type MutationOnlinePaymentArgs = {
  PaymentID?: Maybe<Scalars['String']>;
  AdvancePaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  redirectUrl: Scalars['String'];
  PayAmount: Scalars['Float'];
  Detail: Scalars['String'];
  Title: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationSendRegisterOtpArgs = {
  OtpType: Scalars['String'];
  MobileNo: Scalars['String'];
};


export type MutationVerifyOtpArgs = {
  OtpType: Scalars['String'];
  OtpCode: Scalars['String'];
  MobileNo: Scalars['String'];
};


export type MutationCreateUserArgs = {
  HotelID: Scalars['String'];
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  softwareCode: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateProfileArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: UserInput;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUnLockUserArgs = {
  password: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationReSendActivationEmailArgs = {
  userID: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationUpdateInterestAmtArgs = {
  InterestRate: Scalars['Float'];
};


export type MutationManualNightAuditforAllHotelArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
};


export type MutationGenHashPasswordArgs = {
  Password?: Maybe<Scalars['String']>;
};


export type MutationRegenerateBillScheduleArgs = {
  IsInhouse: Scalars['Boolean'];
  IsCms: Scalars['Boolean'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationRegeneratePostTaxesArgs = {
  IsCms: Scalars['Boolean'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationManualHotelNaArgs = {
  Action: Scalars['String'];
  EndDate: Scalars['DateTime'];
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationManualNaRegistrationInsertArgs = {
  UserID: Scalars['String'];
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type MutationManualAvailabilityUpdateArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  BlockDate: Scalars['DateTime'];
  days: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type MutationCmsManualAvailUpdateArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  AvailAmt: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationCutOffBillingArgs = {
  IsSuspend?: Maybe<Scalars['Boolean']>;
  FolioItemID?: Maybe<Scalars['String']>;
  FolioName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  Operation: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationKeyCardInsertArgs = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  input: KeyCardInput;
};


export type MutationDebitNoteInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<DebitNoteTrxInput>;
  input: DebitNoteEntityInput;
};


export type MutationDebitNoteUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<DebitNoteTrxInput>;
  input: DebitNoteEntityInput;
  ID: Scalars['String'];
};


export type MutationDebitNoteCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateDebitNoteArgs = {
  allocInput: Array<DebitAllocationInput>;
  DebitNoteID: Scalars['String'];
};


export type MutationDebitNoteDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationCreditNoteInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<CreditNoteTrxInput>;
  input: CreditNoteEntityInput;
};


export type MutationCreditNoteUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<CreditNoteTrxInput>;
  input: CreditNoteEntityInput;
  ID: Scalars['String'];
};


export type MutationCreditNoteCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateCreditNoteArgs = {
  allocInput: Array<CreditAllocationInput>;
  CreditNoteID: Scalars['String'];
};


export type MutationCreditNoteDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationRefundInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: RefundEntityInput;
};


export type MutationRefundUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: RefundEntityInput;
  ID: Scalars['String'];
};


export type MutationRefundCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateRefundArgs = {
  allocInput: Array<DebitAllocationInput>;
  RefundID: Scalars['String'];
};


export type MutationRefundDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationInvoiceInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<InvoiceTrxInput>;
  input: InvoiceEntityInput;
};


export type MutationInvoiceUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ChargeItemInput: Array<InvoiceTrxInput>;
  input: InvoiceEntityInput;
  ID: Scalars['String'];
};


export type MutationInvoiceCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateInvoiceArgs = {
  allocInput: Array<DebitAllocationInput>;
  InvoiceID: Scalars['String'];
};


export type MutationInvoiceDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationOfficialReceiptInsertArgs = {
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: OfficialReceiptEntityInput;
};


export type MutationOfficialReceiptUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  input: OfficialReceiptEntityInput;
  ID: Scalars['String'];
};


export type MutationOfficialReceiptCancelArgs = {
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAllocateOfficialReceiptArgs = {
  allocInput: Array<CreditAllocationInput>;
  ReceiptID: Scalars['String'];
};


export type MutationOfficialReceiptDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationReminderInsertArgs = {
  input: ReminderEntityInput;
};


export type MutationReminderUpdateArgs = {
  input: ReminderEntityInput;
  ID: Scalars['String'];
};


export type MutationReminderDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationAsyncInterestInsertArgs = {
  input: InterestEntityInput;
};


export type MutationInterestUpdateArgs = {
  input: InterestEntityInput;
  ID: Scalars['String'];
};


export type MutationInterestDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDocumentNumberingSetupInsertArgs = {
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderinput: DocumentNumberHeaderInput;
};


export type MutationDocumentNumberingUpdateArgs = {
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  SampleOutput: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDebtorTypeInsertArgs = {
  input: DebtorTypeInput;
};


export type MutationDebtorTypeUpdateArgs = {
  input: DebtorTypeInput;
  ID: Scalars['String'];
};


export type MutationDebtorTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationPostScheduleBillingArgs = {
  input: Array<ScheduleBillingInput>;
  InvoiceDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type MutationRoomSaleOptimizationUpdateArgs = {
  input: Array<AvailabilityInput>;
};


export type MutationBookingAttachmentInsertArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  Remark?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationBookingAttachmentUpdateArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  Remark?: Maybe<Scalars['String']>;
  AttachmentDetailsID?: Maybe<Array<Scalars['String']>>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingAttachmentID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationBookingAttachmentDeleteArgs = {
  BookingAttachmentID?: Maybe<Scalars['String']>;
};


export type MutationEmailSendArgs = {
  Attachments?: Maybe<EmailAttachmentInput>;
  isGuestEmail?: Maybe<Scalars['Boolean']>;
  BookingID?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Body: EmailBodyInput;
};


export type MutationBiSharedGAlleryInsertArgs = {
  AttachmentInput: Array<Scalars['Upload']>;
  HotelID: Scalars['String'];
};


export type MutationChooseRoomAllocationInsertArgs = {
  RoomAllocationInput: ChooseRoomAllocationInput;
};


export type MutationChooseRoomAllocationUpdateArgs = {
  OldEffectiveDate: Scalars['DateTime'];
  RoomAllocationInput: ChooseRoomAllocationInput;
};


export type MutationChooseRoomAllocationDeleteArgs = {
  EffectiveDate: Scalars['DateTime'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationCompanyInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  CompanyInput: CompanyInput;
};


export type MutationCompanyUpdateArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  CompanyInput: CompanyInput;
};


export type MutationCompanyUpdateStatusArgs = {
  CompanyID: Scalars['String'];
  Status: Scalars['String'];
};


export type MutationCorporateInsertArgs = {
  DebtorContactInput: Array<ContactInput>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationGovermentTestingUpdateArgs = {
  DebtorInput: DebtorInput;
};


export type MutationCorporateUpdateArgs = {
  DebtorContactInput?: Maybe<Array<ContactInput>>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationCorporateContractUpdateArgs = {
  RatePolicyIDs?: Maybe<Array<Scalars['String']>>;
  ContractInput: ContractInput;
};


export type MutationCorporateContractInsertArgs = {
  RatePolicyIDs: Array<Scalars['String']>;
  ContractInput: ContractInput;
};


export type MutationCorporateDeleteArgs = {
  DebtorID: Scalars['String'];
};


export type MutationActivityInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ActivitiesInput?: Maybe<Array<ActivitiesInput>>;
};


export type MutationActivityUpdateArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ActivitiesInput?: Maybe<Array<ActivitiesInput>>;
};


export type MutationGenerateConsolidatedDocOldArgs = {
  limit?: Maybe<Scalars['Float']>;
  DocNo?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  DocType?: Maybe<Scalars['String']>;
  EInvoiceConsolidationInput: EInvoiceConsolidationInput;
  TrxDate: Scalars['DateTime'];
};


export type MutationEventScheduleBillingArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationEventVoidBillLedgerArgs = {
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  input: BillLedgerInput;
};


export type MutationEventBillLedgerInsertArgs = {
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationRegenerateNaRegistrationArgs = {
  HotelID?: Maybe<Scalars['String']>;
  EndArrDate: Scalars['DateTime'];
  StartArrDate: Scalars['DateTime'];
};


export type MutationGuestUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  ProfilePictureAttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  GuestProfileInput: GuestProfileInput;
  ContactInput: ContactInput;
};


export type MutationGuestProfileInsertArgs = {
  ContactInput: ContactInput;
  RegistrationID: Scalars['String'];
};


export type MutationGuestContactUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  guestType?: Maybe<GuestType>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  VehicleNo?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  ContactInput: ContactInput;
};


export type MutationGuestProfileDeleteArgs = {
  PrincipalRegistrationID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['Float']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
};


export type MutationNoOfPaxUpdateArgs = {
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
  NoOfAdult: Scalars['Float'];
  SharerNo: Scalars['Float'];
  RoomTypeID: Scalars['String'];
};


export type MutationGuestMovementInsertArgs = {
  GuestMovementInput: GuestMovementInput;
};


export type MutationCbPostArgs = {
  IsTransfer?: Maybe<Scalars['Boolean']>;
  GLDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationGlPostArgs = {
  IsTransfer?: Maybe<Scalars['Boolean']>;
  GLDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type MutationPaymentTypeGlInsertArgs = {
  HotelID?: Maybe<Scalars['String']>;
  PaymentType: Array<Scalars['String']>;
};


export type MutationBillLedgerUpdateArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationBillLedgerCancelArgs = {
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationTaxLedgerInsertArgs = {
  TaxLedgerInput: Array<TaxLedgerInput>;
};


export type MutationBillLedgerInsertArgs = {
  IsBookingIncCharges?: Maybe<Scalars['Boolean']>;
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationPosV7BillLedgerInsertArgs = {
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationFolioInsertArgs = {
  RefFolioID?: Maybe<Scalars['String']>;
  PaymentInput: PaymentInput;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationRoomServicePaymentUpdateArgs = {
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type MutationOnlinePaymentRoomServicesArgs = {
  PaymentInput: PaymentInput;
  BillLedgerInput: Array<BillLedgerInput>;
};


export type MutationFolioAdjustmentInsertArgs = {
  IsEInvoice?: Maybe<Scalars['Boolean']>;
  RefFolioID?: Maybe<Scalars['String']>;
  FolioType: Scalars['String'];
  IsSuspend?: Maybe<Scalars['Boolean']>;
  PaymentInput: PaymentInput;
  AdvancePaymentInput: Array<AdvancePaymentInput>;
  DepositLedgerInput: Array<DepositLedgerInput>;
  TaxLedgerInput: Array<TaxLedgerInput>;
  RoomLedgerInput: Array<RoomLedgerInput>;
  BillLedgerInput: Array<BillLedgerInput>;
  HotelID: Scalars['String'];
};


export type MutationChangePaymentArgs = {
  PaymentInput: PaymentInput;
  ReversedPaymentID: Scalars['String'];
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationPosChargeToCityLedgerArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationPosv7ScheduleBillingArgs = {
  ScheduleBillingInput: Array<SchBillingInput>;
};


export type MutationPosv7DailyRevenueArgs = {
  POSV7DailyRevenueInput: Array<Posv7DailyRevenueInput>;
};


export type MutationMenuXDailyRevenueArgs = {
  Input?: Maybe<Array<Posv7DailyRevenueInput>>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationHuaweiOcrArgs = {
  File: Scalars['String'];
};


export type MutationInnov8OcrArgs = {
  File: Scalars['String'];
};


export type MutationPackageRedemptionInsertArgs = {
  PackageRedemptionInput: PackageRedemptionInput;
};


export type MutationPreRegPaymentInsertArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  Amount: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationUpdateRoomAssignmentArgs = {
  IsCompleted?: Maybe<Scalars['Boolean']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationExpressCheckinConfirmationArgs = {
  AddConfirmationInput?: Maybe<Array<AddConfirmationInput>>;
  EditConfirmationInput?: Maybe<Array<EditConfirmationInput>>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationCheckInInsertV2Args = {
  AddInformationInput?: Maybe<Array<AddInformationInput>>;
  EditInformationInput?: Maybe<Array<EditInformationInput>>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type MutationPreCheckInInsertArgs = {
  Signature: Array<Scalars['Upload']>;
  IcPhotoBack?: Maybe<Array<Scalars['Upload']>>;
  IcPhotoFront?: Maybe<Array<Scalars['Upload']>>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  PreCheckInInput: PreCheckInInput;
};


export type MutationQ3DocumentInsertArgs = {
  Q3DocumentDetailInput: Array<Q3DocumentDetailInput>;
  Q3DocumentHeaderInput: Q3DocumentHeaderInput;
};


export type MutationRevenueMappingInsertArgs = {
  GuestLedgerAcctInput: RevenueGuestLedgerAcctInput;
  RevenueInput: RevenueMappingInput;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationSegmentMappingInsertArgs = {
  SegmentInput: Array<SegmentInput>;
  HotelID: Scalars['String'];
};


export type MutationStatisticMappingInsertArgs = {
  Q3Statistic: Array<Q3StatisticInput>;
  HotelID: Scalars['String'];
};


export type MutationBlockRoomLogInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationBlockRoomLogInsertV2Args = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: Array<BlockRoomLogInput>;
};


export type MutationReleaseRoomLogUpdateV2Args = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationBlockRoomLogUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  BlockRoomLogInput: BlockRoomLogInput;
};


export type MutationHouseKeepingLogInsertArgs = {
  RoomIDs?: Maybe<Array<Scalars['String']>>;
  HouseKeepingLogInput: HouseKeepingLogInput;
};


export type MutationHousekeepingServiceRequestInsertArgs = {
  AppName?: Maybe<Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ServiceRequestInput: ServiceRequestInput;
};


export type MutationHousekeepingServiceRequestUpdateArgs = {
  AppName?: Maybe<Scalars['String']>;
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  ServiceRequestInput: ServiceRequestInput;
};


export type MutationHousekeepingBillLedgerUpdateArgs = {
  BillLedgerInput: BillLedgerInput;
};


export type MutationDepartmentInsertArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationDepartmentUpdateArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationIncidentalChargesUpdateArgs = {
  status: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationDepartmentDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationEDocInsertArgs = {
  EDocTemplateInput: EDocTemplateInput;
};


export type MutationEDocTemplateUpdateArgs = {
  EDocTemplateInput: EDocTemplateInput;
};


export type MutationConfirmationTemplateDeleteArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationAccountMappingGlInsertArgs = {
  GuestLedgerAcctInput: GuestLedgerAcctInput;
  GLInput: AccountMappingGlInput;
  HotelID: Scalars['String'];
};


export type MutationCityLedgerGlInsertArgs = {
  GLInput: AccountMappingGlInput;
  HotelID: Scalars['String'];
};


export type MutationAccountMappingTaxGlInsertArgs = {
  TaxGLInput: AccountMappingTaxGlInput;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationCityLedgerTaxGlInsertArgs = {
  TaxGLInput: AccountMappingTaxGlInput;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationBankAccountUpdateArgs = {
  BankAccountInput: BankAccountInput;
};


export type MutationBankAccountInsertArgs = {
  BankAccountInput: BankAccountInput;
};


export type MutationBankAccountDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationBankAccountInActiveArgs = {
  IsActive: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationFacilitiesInactiveDeleteArgs = {
  id: Scalars['String'];
  isInactive: Scalars['Boolean'];
};


export type MutationGuestAppSetupUpdateArgs = {
  GuestAppSettingInput: GuestAppSettingInput;
};


export type MutationInvoiceRemarkArgs = {
  InvoiceRemark?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type MutationHotelUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  Type?: Maybe<Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  HotelInput: HotelInput;
};


export type MutationHotelInsertArgs = {
  Type?: Maybe<Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  HotelInput: HotelInput;
};


export type MutationHotelContactUpdateArgs = {
  ContactInput: ContactInput;
};


export type MutationHotelContactInsertArgs = {
  HotelID: Scalars['String'];
  ContactInput: ContactInput;
};


export type MutationHotelFacilityUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  FacilityInput: FacilityInput;
};


export type MutationHotelFacilityInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  FacilityInput: FacilityInput;
};


export type MutationHotelNewsUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  NewsInput: NewsInput;
};


export type MutationHotelNewsInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  NewsInput: NewsInput;
};


export type MutationIncidentalChargeInsertArgs = {
  IncidentalChargeInput: IncidentalChargeInput;
};


export type MutationIncidentalChargeUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  IncidentalChargeInput: IncidentalChargeInput;
};


export type MutationIncidentalChargeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationLateCheckOutChargeInsertArgs = {
  LateCheckOutChargeInput: Array<LateCheckOutChargeInput>;
};


export type MutationFloorPlanUpdateArgs = {
  Type?: Maybe<Scalars['String']>;
  LocationFloorPlanInput: Array<LocationFloorPlanInput>;
};


export type MutationFloorPlanResetArgs = {
  Type?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
};


export type MutationLocationInsertArgs = {
  LocationInput: LocationInput;
};


export type MutationLocationUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  LocationInput: LocationInput;
};


export type MutationDisplaySequenceUpdateArgs = {
  Type: Scalars['String'];
  Input: Array<DisplaySequenceInput>;
};


export type MutationLocationDeleteArgs = {
  Description: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationRateElementInsertArgs = {
  RateElementInput: RateElementInput;
};


export type MutationRateElementUpdateArgs = {
  RateElementInput: RateElementInput;
};


export type MutationRateAddOnInsertArgs = {
  RateAddOnInput: RateAddOnInput;
};


export type MutationRateAddOnUpdateArgs = {
  RateAddOnInput: RateAddOnInput;
};


export type MutationRateTypeInsertArgs = {
  RateTypeInput: RateTypeInput;
};


export type MutationRateTypeUpdateArgs = {
  RateTypeInput: RateTypeInput;
};


export type MutationRateInsertArgs = {
  RateInput: RateInput;
};


export type MutationRateUpdateArgs = {
  RateInput: RateInput;
};


export type MutationRateEffectiveInsertArgs = {
  SeasonalRateInput?: Maybe<Array<SeasonalRateInput>>;
  RateEffectiveInput: RateEffectiveInput;
};


export type MutationRateEffectiveUpdateArgs = {
  SeasonalRateInput?: Maybe<Array<SeasonalRateInput>>;
  RateEffectiveInput: RateEffectiveInput;
};


export type MutationCmsRateSyncArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationRateEffectiveStatusUpdateArgs = {
  RateEffectiveID: Scalars['String'];
  IsActive: Scalars['Boolean'];
};


export type MutationRateAdjustmentAuditInsertArgs = {
  RateAdjustmentAuditInput: RateAdjustmentAuditInput;
};


export type MutationRegenBsQueueInsertArgs = {
  IsCms?: Maybe<Scalars['Boolean']>;
  FullName?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RateCodeID?: Maybe<Scalars['String']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationRegenBsQueueConfirmArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type MutationSeasonInsertArgs = {
  SeasonInput: SeasonInput;
};


export type MutationSeasonUpdateArgs = {
  SeasonInput: SeasonInput;
};


export type MutationSeasonCalendarUpdateArgs = {
  HotelID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  SeasonCalendarInput: Array<SeasonCalendarInput>;
};


export type MutationRatePolicyInsertArgs = {
  PackagesInput: Array<PackagesInput>;
  PricingInput: Array<PricingInput>;
  RatePolicyInput: RatePolicyInput;
};


export type MutationRatePolicyUpdateArgs = {
  PricingInput?: Maybe<Array<PricingInput>>;
  RatePolicyInput: RatePolicyInput;
};


export type MutationPackageInsertArgs = {
  PackagesInput: Array<PackagesInput>;
};


export type MutationPackageUpdateArgs = {
  PackagesInput: PackagesInput;
};


export type MutationRatePolicyDeleteArgs = {
  DebtorCategory: Scalars['String'];
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationReasonInsertArgs = {
  ReasonInput: ReasonInput;
};


export type MutationReasonUpdateArgs = {
  ReasonInput: ReasonInput;
};


export type MutationReasonDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationRoomInsertArgs = {
  RoomInput: RoomInput;
};


export type MutationRoomUpdateArgs = {
  RoomInput: RoomInput;
};


export type MutationRoomDeleteArgs = {
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationRoomStatusInsertArgs = {
  RoomStatusInput: Array<RoomStatusInput>;
};


export type MutationRoomTypeUpdateArgs = {
  OldGalleryID?: Maybe<Array<Scalars['String']>>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RoomTypeAmenitiesInput?: Maybe<Array<RoomTypeAmenitiesInput>>;
  RoomTypeInput: RoomTypeInput;
};


export type MutationRoomTypeInsertArgs = {
  AttachmentInput?: Maybe<Array<Scalars['Upload']>>;
  RoomTypeAmenitiesInput: Array<RoomTypeAmenitiesInput>;
  RoomTypeInput: RoomTypeInput;
};


export type MutationRoomTypeDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSalesChannelInsertArgs = {
  SalesChannelInput: SalesChannelInput;
};


export type MutationSalesChannelUpdateArgs = {
  SalesChannelInput: SalesChannelInput;
};


export type MutationSalesChannelDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationSegmentUpdateArgs = {
  SegmentInput: SegmentInput;
};


export type MutationSegmentInsertArgs = {
  SegmentInput: SegmentInput;
};


export type MutationSegmentDeleteArgs = {
  Description: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationTaxInsertArgs = {
  TaxInsertInput: TaxPolicyInput;
  AccountID: Scalars['String'];
};


export type MutationTaxUpdateArgs = {
  TaxEditInput: EditTaxPolicyInput;
  AccountID: Scalars['String'];
};


export type MutationTaxDeleteArgs = {
  TaxDeleteInput: Array<TaxDetailInput>;
  TaxID: Scalars['String'];
};


export type MutationTaxSchemeInsertArgs = {
  TaxSchemeInput: TaxSchemeInput;
  AccountID: Scalars['String'];
};


export type MutationTaxSchemeUpdateArgs = {
  EditTaxSchemeInput: EditTaxSchemeInput;
  AccountID: Scalars['String'];
};


export type MutationTaxSchemeDeleteArgs = {
  TaxSchemeID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationSetDefaultTaxSchemeArgs = {
  TaxSchemeID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type MutationTravelAgentInsertArgs = {
  DebtorContactInput: Array<ContactInput>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentMainUpdateArgs = {
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentUpdateArgs = {
  DebtorContactInput?: Maybe<Array<ContactInput>>;
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
};


export type MutationTravelAgentContractUpdateArgs = {
  HotelID: Scalars['String'];
  RoomTypeList?: Maybe<Array<ContractAllotmentInput>>;
  RatePolicyIDs?: Maybe<Array<Scalars['String']>>;
  ContractInput: ContractInput;
};


export type MutationTravelAgentContractInsertArgs = {
  HotelID: Scalars['String'];
  RoomTypeList: Array<ContractAllotmentInput>;
  RatePolicyIDs: Array<Scalars['String']>;
  ContractInput: ContractInput;
};


export type MutationUpdateAllotmentArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  DebtorID: Scalars['String'];
  AllotmentInput: Array<ContractAllotmentInput>;
};


export type MutationTravelAgentDeleteArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type MutationDebtorLoginArgs = {
  Password: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};


export type MutationDebtorLastAccessedHotelUpdateArgs = {
  HotelID: Scalars['String'];
  Email: Scalars['String'];
};


export type MutationDebtorChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationDebtorForgotPasswordArgs = {
  Email: Scalars['String'];
};


export type MutationAgentDeleteArgs = {
  ID: Scalars['String'];
};


export type MutationDebtorResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationDebtorPaymentInsertArgs = {
  DebtorPaymentInput: Array<DebtorPaymentInput>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type NaProcessLogEntity = InterfaceBase & {
  __typename?: 'NAProcessLogEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  PendingBooking?: Maybe<Scalars['Boolean']>;
  NoShowBooking?: Maybe<Scalars['Boolean']>;
  HouseKeepingUpdate?: Maybe<Scalars['Boolean']>;
  ReleaseBlockRoom?: Maybe<Scalars['Boolean']>;
  PostAdvancePayment?: Maybe<Scalars['Boolean']>;
  PostRoomRevenue?: Maybe<Scalars['Boolean']>;
  AvailabilityUpdate?: Maybe<Scalars['Boolean']>;
  AutoExtendStay?: Maybe<Scalars['Boolean']>;
  DailyRegistration?: Maybe<Scalars['Boolean']>;
  NAStatus?: Maybe<Scalars['String']>;
  PendingBookingComplete?: Maybe<Scalars['DateTime']>;
  NoShowBookingComplete?: Maybe<Scalars['DateTime']>;
  HouseKeepingUpdateComplete?: Maybe<Scalars['DateTime']>;
  ReleaseBlockRoomComplete?: Maybe<Scalars['DateTime']>;
  PostAdvancePaymentComplete?: Maybe<Scalars['DateTime']>;
  PostRoomRevenueComplete?: Maybe<Scalars['DateTime']>;
  AvailabilityUpdateComplete?: Maybe<Scalars['DateTime']>;
  AutoExtendStayComplete?: Maybe<Scalars['DateTime']>;
  DailyRegistrationComplete?: Maybe<Scalars['DateTime']>;
};

export type NaRegistration2Entity = InterfaceTrx & {
  __typename?: 'NARegistration2Entity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  SegmentID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  SharerNo?: Maybe<Scalars['Float']>;
  TourismTax?: Maybe<Scalars['Boolean']>;
  TTxAmount?: Maybe<Scalars['Float']>;
};

export type NaRegistrationEntity = InterfaceTrx & {
  __typename?: 'NARegistrationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelDate?: Maybe<Scalars['DateTime']>;
  HotelID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  SegmentID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  SharerNo?: Maybe<Scalars['Float']>;
  TourismTax?: Maybe<Scalars['Boolean']>;
  TTxAmount?: Maybe<Scalars['Float']>;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Bahraini = 'Bahraini',
  Belarusian = 'Belarusian',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  Bruneian = 'Bruneian',
  British = 'British',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kazakhstani = 'Kazakhstani',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mauritian = 'Mauritian',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Omani = 'Omani',
  Others = 'Others',
  Pakistani = 'Pakistani',
  Palestinians = 'Palestinians',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Uzbekistani = 'Uzbekistani',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Yemeni = 'Yemeni',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean',
  Maldivian = 'Maldivian',
  Deutsch = 'Deutsch',
  RepublicOfTrinidad = 'Republic_Of_Trinidad'
}

export type NewsEntity = InterfaceBase & {
  __typename?: 'NewsEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  NewsGallery?: Maybe<Array<NewsGalleryEntity>>;
};

export type NewsGalleryEntity = InterfaceBase & {
  __typename?: 'NewsGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  GalleryID: Scalars['String'];
  News?: Maybe<NewsEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type NewsGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NewsInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NonGuestBalance = {
  __typename?: 'NonGuestBalance';
  TotalBill?: Maybe<Scalars['Float']>;
  TotalPaid?: Maybe<Scalars['Float']>;
  TotalLeft?: Maybe<Scalars['Float']>;
};

export type NonGuestFolioResponse = {
  __typename?: 'NonGuestFolioResponse';
  ID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  IncidentalDescription?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  ReversedID?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Qty?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  TaxBillLedger?: Maybe<Array<BillLedgerTaxEntity>>;
  TaxRoomLedger?: Maybe<Array<RoomLedgerTaxEntity>>;
  Reason?: Maybe<Scalars['String']>;
};

export type NonGuestInfoJson = {
  __typename?: 'NonGuestInfoJson';
  EffectiveDate?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  ReferenceID?: Maybe<Scalars['String']>;
};

export type NonGuestInfoJsonInput = {
  EffectiveDate?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  ReferenceID?: Maybe<Scalars['String']>;
};

export type NotificationEntity = {
  __typename?: 'NotificationEntity';
  ID: Scalars['ID'];
  HotelID?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
};

export type Occupancy = {
  __typename?: 'Occupancy';
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  Season: Scalars['String'];
  Occupancy?: Maybe<Scalars['Float']>;
  TotalOccupied?: Maybe<Scalars['Float']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  TotalVacantShow?: Maybe<Scalars['Float']>;
  TotalBlock?: Maybe<Scalars['Float']>;
};

export type OfficialReceiptEntity = InterfaceTrx & {
  __typename?: 'OfficialReceiptEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetails;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type OfficialReceiptEntityInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetailsInput;
};

export type OnlineItemEntity = InterfaceTrx & {
  __typename?: 'OnlineItemEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
};

export type OnlineItemInput = {
  FolioID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
};

export type OpenClosedResponse = {
  __typename?: 'OpenClosedResponse';
  OpenInvoice?: Maybe<Array<Data>>;
  OpendebitNote?: Maybe<Array<Data>>;
  OpenCreditNote?: Maybe<Array<Data>>;
  OpenOR?: Maybe<Array<Data>>;
  OpenRefund?: Maybe<Array<Data>>;
  ClosedInvoice?: Maybe<Array<Data>>;
  CloseddebitNote?: Maybe<Array<Data>>;
  ClosedCreditNote?: Maybe<Array<Data>>;
  ClosedOR?: Maybe<Array<Data>>;
  ClosedRefund?: Maybe<Array<Data>>;
};

export type OutgoingDocTemplateEntity = InterfaceBase & {
  __typename?: 'OutgoingDocTemplateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
  DebitNote?: Maybe<Scalars['String']>;
  CreditNote?: Maybe<Scalars['String']>;
  OfficialReceipt?: Maybe<Scalars['String']>;
  Refund?: Maybe<Scalars['String']>;
  DepositRefund?: Maybe<Scalars['String']>;
  DepositInvoice?: Maybe<Scalars['String']>;
  APStatement?: Maybe<Scalars['String']>;
};

export type OutgoingDocTemplateInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Folio?: Maybe<Scalars['String']>;
  Receipt?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
};

export type Output = {
  __typename?: 'Output';
  isNRICExist?: Maybe<Scalars['String']>;
  isEmailExist?: Maybe<Scalars['String']>;
  isLoginIDExist?: Maybe<Scalars['String']>;
};

export type PabxLogEntity = InterfaceTrx & {
  __typename?: 'PABXLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type PackagePriceOutput = {
  __typename?: 'PackagePriceOutput';
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomTypeCode?: Maybe<Scalars['String']>;
  RoomTypeName?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RateFrequency?: Maybe<Scalars['String']>;
  TotalVacant?: Maybe<Scalars['Float']>;
  Pricing?: Maybe<Array<PricingOutput>>;
};

export type PackageRedemptionEntity = InterfaceBase & {
  __typename?: 'PackageRedemptionEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsBillLedger?: Maybe<Scalars['Boolean']>;
  Registration?: Maybe<RegistrationEntity>;
};

export type PackageRedemptionInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  GuestName?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsBillLedger?: Maybe<Scalars['Boolean']>;
};

export type PackageRedemptionListOutput = {
  __typename?: 'PackageRedemptionListOutput';
  ID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ComputationRule?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  BillScheduleID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  GuestNames?: Maybe<Array<Scalars['String']>>;
  GuestPackageRedeem?: Maybe<Array<GuestPackageRedeem>>;
  Amount?: Maybe<Scalars['Float']>;
  Total?: Maybe<Scalars['Float']>;
  Redeemed?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  DebtorName?: Maybe<Scalars['String']>;
  MaxPackage?: Maybe<Scalars['Float']>;
};

export type PackagesEntity = InterfaceBase & {
  __typename?: 'PackagesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RatePolicyID: Scalars['String'];
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  ComputationRules?: Maybe<ComputationRules>;
  IncludeRate?: Maybe<Scalars['Boolean']>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type PackagesInput = {
  ID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  GalleryID?: Maybe<Scalars['String']>;
  DisplaySeq?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  ComputationRules?: Maybe<ComputationRules>;
  IncludeRate?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PassCodeInput = {
  keyboardPwd?: Maybe<Scalars['String']>;
  keyboardPwdId?: Maybe<Scalars['String']>;
};

export enum PaymentClass {
  Advance = 'Advance',
  Room = 'Room',
  Bill = 'Bill',
  Tax = 'Tax',
  Folio = 'Folio',
  Refund = 'Refund',
  Forfeit = 'Forfeit',
  Rounding = 'Rounding'
}

export type PaymentEntity = InterfaceTrx & {
  __typename?: 'PaymentEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ReversedPaymentID?: Maybe<Scalars['String']>;
  ReferenceID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo: Scalars['Float'];
  CheckOutID?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  ChangeAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  PaymentStatus: PaymentStatus;
  IsARPosted?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  FromRegistration?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Folio?: Maybe<FolioEntity>;
  ForfeitTax?: Maybe<Array<ForfeitTaxEntity>>;
};

export type PaymentFoilioResponse = {
  __typename?: 'PaymentFoilioResponse';
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedName?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  FromRegistration?: Maybe<Scalars['String']>;
  GuestAppDepartment?: Maybe<Scalars['String']>;
  IsOutlet?: Maybe<Scalars['Boolean']>;
  IsEventInterface?: Maybe<Scalars['Boolean']>;
  BillScheduleTax?: Maybe<Array<BillScheduleTaxEntity>>;
  RoomLedgerTax?: Maybe<Array<RoomLedgerTaxEntity>>;
  BillLedgerTax?: Maybe<Array<BillLedgerTaxEntity>>;
};

export type PaymentFolioInput = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  ChargeType?: Maybe<Scalars['String']>;
  LedgerType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedName?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
};

export type PaymentGatewayEntity = InterfaceBase & {
  __typename?: 'PaymentGatewayEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Client?: Maybe<Scalars['String']>;
  Secret?: Maybe<Scalars['String']>;
  Store?: Maybe<Scalars['String']>;
  PrivateKey?: Maybe<Scalars['String']>;
  PublicKey?: Maybe<Scalars['String']>;
  Environment?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type PaymentGatewayInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Client?: Maybe<Scalars['String']>;
  Secret?: Maybe<Scalars['String']>;
  Store?: Maybe<Scalars['String']>;
  PrivateKey?: Maybe<Scalars['String']>;
  PublicKey?: Maybe<Scalars['String']>;
  Environment?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PaymentGlEntity = InterfaceTrx & {
  __typename?: 'PaymentGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  PaymentType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type PaymentInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass: PaymentClass;
  PaymentType: PaymentType;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo?: Maybe<Scalars['Float']>;
  CheckOutID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentStatus: PaymentStatus;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
};

export type PaymentListing123 = {
  __typename?: 'PaymentListing123';
  RegistrationID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
  PaymentStatus?: Maybe<Scalars['String']>;
  PaymentClass?: Maybe<Scalars['String']>;
  PaymentDate?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  Result?: Maybe<Array<RegistrationResult>>;
};

export type PaymentOutput = {
  __typename?: 'PaymentOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export enum PaymentStatus {
  Pending = 'Pending',
  Paid = 'Paid',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected'
}

export type PaymentTestClass = {
  __typename?: 'PaymentTestClass';
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  ReversedPaymentID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  PaymentDate: Scalars['DateTime'];
  PaymentClass?: Maybe<PaymentClass>;
  PaymentType?: Maybe<PaymentType>;
  DebtorAccount?: Maybe<Scalars['String']>;
  PaySessionNo: Scalars['Float'];
  CheckOutID?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  ChangeAmount?: Maybe<Scalars['Float']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  PaymentStatus: PaymentStatus;
  Hotel?: Maybe<Array<HotelEntity>>;
  Registration?: Maybe<Array<RegistrationEntity>>;
  Folio?: Maybe<Array<FolioEntity>>;
  Booking?: Maybe<BookingEntity>;
};

export enum PaymentType {
  Online = 'Online',
  Cash = 'Cash',
  VisaCard = 'VisaCard',
  MasterCard = 'MasterCard',
  AmericanExpressCard = 'AmericanExpressCard',
  DinersCard = 'DinersCard',
  JcbCard = 'JCBCard',
  Unionpay = 'UNIONPAY',
  CityLedger = 'CityLedger',
  Other = 'Other',
  DebitCard = 'DebitCard',
  EWallet = 'EWallet',
  Room = 'Room',
  BankTt = 'BankTT',
  Voucher = 'Voucher',
  Cheque = 'Cheque'
}

export type PermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
  hotelPerms?: Maybe<HotelPermission>;
};

export type PlatformAmenitiesEntity = InterfaceBase & {
  __typename?: 'PlatformAmenitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  GalleryID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  Gallery?: Maybe<GalleryEntity>;
};

export type PlatformDepartmentEntity = InterfaceBase & {
  __typename?: 'PlatformDepartmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AllowReason?: Maybe<Scalars['Boolean']>;
  AllowSalesChannel?: Maybe<Scalars['Boolean']>;
  AllowIncidental?: Maybe<Scalars['Boolean']>;
  AllowGuestApp?: Maybe<Scalars['Boolean']>;
};

export type PlatformHeritageTaxEntity = InterfaceBase & {
  __typename?: 'PlatformHeritageTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformHeritageTaxInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformLevyEntity = InterfaceBase & {
  __typename?: 'PlatformLevyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformLevyInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformServiceChargeEntity = InterfaceBase & {
  __typename?: 'PlatformServiceChargeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformServiceChargeInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformStateFundEntity = InterfaceBase & {
  __typename?: 'PlatformStateFundEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformStateFundInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformTaxEntity = InterfaceBase & {
  __typename?: 'PlatformTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
};

export type PlatformTaxInput = {
  TaxScheme?: Maybe<Scalars['String']>;
  TaxCode?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformTourismTaxEntity = InterfaceBase & {
  __typename?: 'PlatformTourismTaxEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
};

export type PlatformTourismTaxInput = {
  ID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DefaultValue?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type Posv7DailyRevenueEntity = InterfaceBase & {
  __typename?: 'POSV7DailyRevenueEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DocumentDate: Scalars['DateTime'];
  OutletDescription?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  PaymentDescription?: Maybe<Scalars['String']>;
  TrxType?: Maybe<Scalars['String']>;
  DiscountedAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  OutletCode?: Maybe<Scalars['String']>;
};

export type Posv7DailyRevenueHistoryEntity = InterfaceBase & {
  __typename?: 'POSV7DailyRevenueHistoryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DocumentDate: Scalars['DateTime'];
  OutletDescription?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  PaymentDescription?: Maybe<Scalars['String']>;
  TrxType?: Maybe<Scalars['String']>;
  DiscountedAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  OutletCode?: Maybe<Scalars['String']>;
};

export type Posv7DailyRevenueInput = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DocumentDate?: Maybe<Scalars['DateTime']>;
  CardNo?: Maybe<Scalars['String']>;
  OutletDescription?: Maybe<Scalars['String']>;
  MajorDescription?: Maybe<Scalars['String']>;
  PaymentDescription?: Maybe<Scalars['String']>;
  TrxType?: Maybe<Scalars['String']>;
  DiscountedAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  OutletCode?: Maybe<Scalars['String']>;
};

export type PreCheckInInput = {
  NRICNo: Scalars['String'];
  DateofBirth: Scalars['DateTime'];
  MobileNo: Scalars['String'];
  Nationality: Scalars['String'];
  Address?: Maybe<AddressInput>;
  IsPreCheckIn?: Maybe<Scalars['Boolean']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type PricingEntity = InterfaceBase & {
  __typename?: 'PricingEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RatePolicyID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Standard?: Maybe<Scalars['Float']>;
  Ceiling?: Maybe<Scalars['Float']>;
  Floor?: Maybe<Scalars['Float']>;
  PeakStandard?: Maybe<Scalars['Float']>;
  PeakCeiling?: Maybe<Scalars['Float']>;
  PeakFloor?: Maybe<Scalars['Float']>;
  SuperPeakStandard?: Maybe<Scalars['Float']>;
  SuperPeakCeiling?: Maybe<Scalars['Float']>;
  SuperPeakFloor?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RatePolicy?: Maybe<RatePolicyEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  PackageAmount?: Maybe<Scalars['Float']>;
  SellingPrice?: Maybe<Scalars['Float']>;
  Season?: Maybe<Scalars['String']>;
};

export type PricingInput = {
  ID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Standard?: Maybe<Scalars['Float']>;
  Ceiling?: Maybe<Scalars['Float']>;
  Floor?: Maybe<Scalars['Float']>;
  PeakStandard?: Maybe<Scalars['Float']>;
  PeakCeiling?: Maybe<Scalars['Float']>;
  PeakFloor?: Maybe<Scalars['Float']>;
  SuperPeakStandard?: Maybe<Scalars['Float']>;
  SuperPeakCeiling?: Maybe<Scalars['Float']>;
  SuperPeakFloor?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PricingOutput = {
  __typename?: 'PricingOutput';
  Date?: Maybe<Scalars['DateTime']>;
  Price?: Maybe<Scalars['Float']>;
  BasePrice?: Maybe<Scalars['Float']>;
  PackageName?: Maybe<Scalars['Float']>;
  PackagePrice?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  Packages?: Maybe<Array<PackagesEntity>>;
};

export type PromoCodeInput = {
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  Pricing?: Maybe<Array<PromoPricingInput>>;
};

export type PromoCodeResponse = {
  __typename?: 'PromoCodeResponse';
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  DiscountAmt?: Maybe<Scalars['Float']>;
};

export type PromoPricingInput = {
  Date?: Maybe<Scalars['DateTime']>;
  Price?: Maybe<Scalars['Float']>;
};

export type PromotionDetailEntity = InterfaceBase & {
  __typename?: 'PromotionDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PromotionID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  Promotion?: Maybe<PromotionEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  RatePolicy?: Maybe<RateEntity>;
};

export type PromotionDetailInput = {
  ID?: Maybe<Scalars['String']>;
  PromotionID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PromotionEntity = InterfaceBase & {
  __typename?: 'PromotionEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscountType: DiscountType;
  DiscountValue?: Maybe<Scalars['Float']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  PromotionDetail?: Maybe<Array<PromotionDetailEntity>>;
  PromotionDetailRatePolicy?: Maybe<Array<PromotionDetailEntity>>;
};


export type PromotionEntityPromotionDetailRatePolicyArgs = {
  RatePolicyID?: Maybe<Scalars['String']>;
};

export type PromotionInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DiscountType: DiscountType;
  DiscountValue?: Maybe<Scalars['Float']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum PropertyType {
  Apartment = 'Apartment',
  Bungalow = 'Bungalow',
  Cabin = 'Cabin',
  Campsite = 'Campsite',
  Cottage = 'Cottage',
  Dorm = 'Dorm',
  Room = 'Room',
  Villa = 'Villa'
}

export type Q3DocumentDetailEntity = InterfaceTrx & {
  __typename?: 'Q3DocumentDetailEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DownloadDate?: Maybe<Scalars['DateTime']>;
  HeaderID: Scalars['String'];
  DownloadNo?: Maybe<Scalars['Float']>;
  BatchNo?: Maybe<Scalars['Float']>;
  Q3DocumentHeader?: Maybe<Q3DocumentHeaderEntity>;
};

export type Q3DocumentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  DownloadDate?: Maybe<Scalars['DateTime']>;
  HeaderID?: Maybe<Scalars['String']>;
};

export type Q3DocumentHeaderEntity = InterfaceTrx & {
  __typename?: 'Q3DocumentHeaderEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DocumentType: Scalars['String'];
  Q3DocumentDetail?: Maybe<Array<Q3DocumentDetailEntity>>;
};

export type Q3DocumentHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['String']>;
};

export type Q3FStatisticResponse = {
  __typename?: 'Q3FStatisticResponse';
  RoomsAvailable?: Maybe<Scalars['Float']>;
  TotalRoomOccupied?: Maybe<Scalars['Float']>;
  RoomOccupiedWithComplimentary?: Maybe<Scalars['Float']>;
  ComplimentaryRoom?: Maybe<Scalars['Float']>;
  HouseUse?: Maybe<Scalars['Float']>;
  NoOfRoomGuests?: Maybe<Scalars['Float']>;
  NoOfShowRoom?: Maybe<Scalars['Float']>;
  TotalRooms?: Maybe<Scalars['Float']>;
  OutOfOrderRoom?: Maybe<Scalars['Float']>;
  CancellationRoom?: Maybe<Scalars['Float']>;
  DayUseRoom?: Maybe<Scalars['Float']>;
};

export type Q3MarketSegmentMapping = {
  __typename?: 'Q3MarketSegmentMapping';
  RoomAnalysis?: Maybe<Scalars['String']>;
  RoomDepartment?: Maybe<Scalars['String']>;
  RoomDebit?: Maybe<Scalars['String']>;
  GuestsAnalysis?: Maybe<Scalars['String']>;
  GuestsDepartment?: Maybe<Scalars['String']>;
  GuestsDebit?: Maybe<Scalars['String']>;
};

export type Q3MarketSegmentMappingInput = {
  RoomAnalysis?: Maybe<Scalars['String']>;
  RoomDepartment?: Maybe<Scalars['String']>;
  RoomDebit?: Maybe<Scalars['String']>;
  GuestsAnalysis?: Maybe<Scalars['String']>;
  GuestsDepartment?: Maybe<Scalars['String']>;
  GuestsDebit?: Maybe<Scalars['String']>;
};

export type Q3RevenueAcctJson = {
  __typename?: 'Q3RevenueAcctJson';
  RevenueDiv?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  ExpenseDiv?: Maybe<Scalars['String']>;
  ExpenseDept?: Maybe<Scalars['String']>;
  ExpenseDebit?: Maybe<Scalars['String']>;
  ExpenseCredit?: Maybe<Scalars['String']>;
};

export type Q3RevenueAcctJsonInput = {
  RevenueDiv?: Maybe<Scalars['String']>;
  RevenueDept?: Maybe<Scalars['String']>;
  RevenueDebit?: Maybe<Scalars['String']>;
  RevenueCredit?: Maybe<Scalars['String']>;
  ReverseDiv?: Maybe<Scalars['String']>;
  ReverseDept?: Maybe<Scalars['String']>;
  ReverseDebit?: Maybe<Scalars['String']>;
  ReverseCredit?: Maybe<Scalars['String']>;
  ExpenseDiv?: Maybe<Scalars['String']>;
  ExpenseDept?: Maybe<Scalars['String']>;
  ExpenseDebit?: Maybe<Scalars['String']>;
  ExpenseCredit?: Maybe<Scalars['String']>;
};

export type Q3Statistic = {
  __typename?: 'Q3Statistic';
  Name?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['String']>;
  Analysis?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type Q3StatisticInput = {
  Name?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['String']>;
  Analysis?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
};

export type Q3StatisticsMapping = {
  __typename?: 'Q3StatisticsMapping';
  RoomsAvailable?: Maybe<Q3Statistic>;
  TotalRoomOccupied?: Maybe<Q3Statistic>;
  RoomOccupiedWithComplimentary?: Maybe<Q3Statistic>;
  ComplimentaryRoom?: Maybe<Q3Statistic>;
  HouseUse?: Maybe<Q3Statistic>;
  NoOfRoomGuests?: Maybe<Q3Statistic>;
  NoOfShowRoom?: Maybe<Q3Statistic>;
  TotalRooms?: Maybe<Q3Statistic>;
  OutOfOrderRoom?: Maybe<Q3Statistic>;
  CancellationRoom?: Maybe<Q3Statistic>;
  DayUseRoom?: Maybe<Q3Statistic>;
};

export type Q3StatisticsMappingInput = {
  RoomsAvailable?: Maybe<Q3StatisticInput>;
  TotalRoomOccupied?: Maybe<Q3StatisticInput>;
  RoomOccupiedWithComplimentary?: Maybe<Q3StatisticInput>;
  ComplimentaryRoom?: Maybe<Q3StatisticInput>;
  HouseUse?: Maybe<Q3StatisticInput>;
  NoOfRoomGuests?: Maybe<Q3StatisticInput>;
  NoOfShowRoom?: Maybe<Q3StatisticInput>;
  TotalRooms?: Maybe<Q3StatisticInput>;
  OutOfOrderRoom?: Maybe<Q3StatisticInput>;
  CancellationRoom?: Maybe<Q3StatisticInput>;
  DayUseRoom?: Maybe<Q3StatisticInput>;
};

export type QrScannerDetailEntity = InterfaceBase & {
  __typename?: 'QrScannerDetailEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  QrData?: Maybe<Scalars['String']>;
  DeviceData?: Maybe<Scalars['String']>;
};

export type QrScannerDetailInput = {
  ID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  QrData?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  IsScanned?: Maybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  TaxEffectiveDateListing: Array<TaxEffectiveDateEntity>;
  PlatformHeritageTaxListing: PlatformHeritageTaxEntity;
  TaxInUseListing: Scalars['Boolean'];
  ComputeTaxbyHotel: TaxOutput;
  ComputeUpgradebyHotel: TaxOutput;
  ComputeBookingbyHotel: Array<Scalars['JSON']>;
  ComputeTaxBooking: Array<Scalars['JSON']>;
  ComputeTaxArrByHotel: Array<TaxOutput>;
  ComputeTaxbyTaxType: HotelTaxOutput;
  GetAdjustmentTax: AdjustmentTaxOutput;
  GetLevyTax: CurrentTaxOutput;
  GetServiceChargeTax: CurrentTaxOutput;
  GetCurrentTourismTax: CurrentTaxOutput;
  GetPlatformTax: CurrentTaxOutput;
  GetCurrentGovTax: CurrentTaxOutput;
  TaxPolicyListing: Array<HotelTaxPolicyEntity>;
  CMSCustomControl: Scalars['Boolean'];
  WHBookingNotification: Scalars['String'];
  FrontDeskListing: Array<BookingEntity>;
  CheckInListing: Array<BookingEntity>;
  RegistrationByBookingListing: Array<RegistrationEntity>;
  FrontDeskListingV2: Array<RegistrationEntity>;
  CheckInListingCount: Scalars['JSON'];
  AssignRoomFrontDeskListing: Array<BookingEntity>;
  RoomAssignListing: Array<RoomEntity>;
  FloorPlanGallery: GalleryEntity;
  AllRoomAsignListing: Array<RoomEntity>;
  GuestProfile: GuestProfileEntity;
  BookingProfile: BookingEntity;
  SelectFolio: FolioEntity;
  FolioDetails: FolioEntity;
  DebtorFolioInfo?: Maybe<DebtorEntity>;
  FolioDetailsWithIDs: RegistrationEntity;
  DepositListingByBooking: Array<DepositLedgerEntity>;
  DepositListingByBookingV2: Scalars['JSON'];
  UnutilizedDepositListing?: Maybe<DepositResponse>;
  UtilizedDepositListing?: Maybe<DepositResponse>;
  UnutilizedDepositListingV2: Scalars['JSON'];
  UtilizedDepositListingV2: Scalars['JSON'];
  DepositLedgerDetails: Scalars['JSON'];
  DepositRefundDetails: DepositLedgerEntity;
  FrontDeskPaymentListing: Array<FrontDeskResponse>;
  BillingByRegistration: Array<PaymentFoilioResponse>;
  IsRoomAvailable: Scalars['Boolean'];
  IsRoomAvailableGroup: Scalars['JSON'];
  RoomUpgradeCheck: Scalars['Boolean'];
  FrontDeskIncidentalChargeListing: Array<BillLedgerEntity>;
  RegistrationTaxLedgerListing: Array<TaxLedgerEntity>;
  FrontDeskServiceRequestListing: Array<ServiceRequestEntity>;
  ActiveBookingRoomListing: Array<RoomEntity>;
  PrincipalGuestListing: Array<RegistrationEntity>;
  FolioListingByRegistration: Array<FolioEntity>;
  FolioHistoryListing: Array<FolioHistoryResponse>;
  FolioListing: Array<FolioEntity>;
  NonGuestFolioListing: Array<FolioEntity>;
  NonGuestFolioListingv2: SuspendFolioResponse;
  FolioUseListing: Scalars['Boolean'];
  SuspendFolioListing: Array<FolioEntity>;
  SuspendFolioListingv2: SuspendFolioResponse;
  NonGuestBillingListing: Array<NonGuestFolioResponse>;
  PendingBillingListing: Array<NonGuestFolioResponse>;
  SelectedRegistration: RegistrationEntity;
  SuspendDetail: FolioEntity;
  ReceiptListing: Array<PaymentFoilioResponse>;
  PaymentDetail: PaymentEntity;
  AdvancePaymentDetail: AdvancePaymentEntity;
  FrontDeskInfoLines: FrontDeskInfoLineResponse;
  ContactPagination: Array<ContactEntity>;
  RegistrationListing: Array<RegistrationEntity>;
  CurrentTaxDetails: CurrentTaxDetailsOutput;
  FrontDeskBookingInfo: BookingInfoResponse;
  HotelXCredentials: Scalars['JSON'];
  MenuXInhouseList: Scalars['JSON'];
  MenuXInhouseStatus: Scalars['Boolean'];
  MenuXDebtorList: Scalars['JSON'];
  InhouseQr: Scalars['JSON'];
  CheckoutReinstateInfo: CheckoutReinstateResponse;
  FrontDeskSubMenuInfo: Array<FrontDeskInfoResponse>;
  IsLockUserProcess: Scalars['JSON'];
  IsPresetIncChargesExist: Scalars['Boolean'];
  StayViewListing: Scalars['JSON'];
  HotelNotificationListing: Array<HotelNotificationEntity>;
  HotelsByCity: Array<HotelEntity>;
  HotelPricesByDateRange: HotelEntity;
  loggedInGuestProfile?: Maybe<GuestProfileEntity>;
  GuestBookingListing: Array<BookingEntity>;
  DisCountByPromoCode: Array<PromoCodeResponse>;
  GuestRoomServiceListing: Array<BillLedgerEntity>;
  GuestReviewDetail?: Maybe<Scalars['JSON']>;
  GAInHouseGuestListing: GaInHouseResponse;
  OnlineGAInHouseGuestListing: Array<RegistrationEntity>;
  ServicePendingCount: Scalars['JSON'];
  HotelReviewListing: Array<HotelRatingEntity>;
  GAMarketingAdsListing: Array<EmailCampignEntity>;
  IsGuestAppUser: GuestProfileEntity;
  GuestAppFeatureList: GuestAppFeatureEntity;
  PromotionListing: Array<PromotionEntity>;
  PromotionDetailsInUseListing: Scalars['Boolean'];
  PromotionDetailListing: Array<PromotionDetailEntity>;
  EmailMarketingListing: Array<EmailCampignEntity>;
  EmailMarketingDetail: Array<EmailCampignEntity>;
  PromotionInUseListing: Scalars['Boolean'];
  GuestReviewListing: Array<HotelRatingEntity>;
  GuestReviewInfoLines: GuestReviewInfoLineResponse;
  BookingListing: Array<BookingEntity>;
  BookingDetails: BookingEntity;
  AdvancePaymentListing: Array<AdvancePaymentEntity>;
  InhousePaymentListing: Array<PaymentEntity>;
  InhouseRefundListing: Array<PaymentEntity>;
  RoomServiceBookingListing: Array<BookingEntity>;
  RoomNumberForFoodListing: Array<BookingEntity>;
  AvailabilityListing: HotelAvailabilityOutput;
  RoomTypeAvailable: Scalars['JSON'];
  AvailabilityCheckingRevamp: Scalars['JSON'];
  CheckForBlockRoom: Scalars['Boolean'];
  RoleView: Scalars['JSON'];
  PackagePriceListing: Array<PackagePriceOutput>;
  BookingPackagePriceListing: Array<PackagePriceOutput>;
  ChangeOfDateCheckAvailability: Scalars['Boolean'];
  ChangeOfDateCheckAvailabilityBookEdit: Scalars['Boolean'];
  IsRoomNoAvailable: Scalars['JSON'];
  ShowBookingConfirmationEmail: Scalars['String'];
  BookingConfirmationTemplate: Scalars['String'];
  CurrentInhouseList: Array<CurrentInhouseOutput>;
  CurrentInhouseListV7: Scalars['JSON'];
  BillScheduleListByBooking: Scalars['JSON'];
  BillScheduleListing: Scalars['JSON'];
  OAuthHotelX: Scalars['JSON'];
  InventoryRatePlansHotelX: HotelEntity;
  GuestReviewsHotelX: Array<HotelRatingEntity>;
  PromotionListHotelX: Array<PromotionEntity>;
  PromoDiscHotelX: Array<PromoCodeResponse>;
  HotelTaxDetails: CurrentTaxDetailsOutput;
  ComputeTaxHotelX: TaxOutput;
  BookingSubMenuInfo: Scalars['JSON'];
  BookingBillScheduleInfo: BookingBillScheduleResponse;
  BookingIncChargesRegistrationListing: Array<RegistrationEntity>;
  IsExistRegistrationIncCharges: Scalars['Boolean'];
  ChannelManagerProfile: ChannelManagerEntity;
  EntRoleUsrAsgList: Array<EntRoleUsrAsgEntity>;
  getRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  HotelAuthorizationInfoLine: Scalars['JSON'];
  getHotelPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Array<UserEntity>;
  GetMenuOption: RoleEntity;
  Check: Scalars['Boolean'];
  UserEntities: Array<Scalars['String']>;
  RoundingAdjustment: Scalars['JSON'];
  RoundingAmount: Scalars['JSON'];
  SubscribedPackages: Scalars['JSON'];
  GetUserByUsername: UserEntity;
  loggedInUserProfile: UserEntity;
  UserType: Scalars['JSON'];
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  updateLastestAccessHotel: UserEntity;
  isLoginPermitted: UserEntity;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  InterstTotal: InterestResponse;
  TestService: Scalars['String'];
  HLSInvetoryUpdate: Scalars['String'];
  ArrayChallenge: Scalars['JSON'];
  DebitNoteDetail: DebitNoteEntity;
  GLAccountPeriod: Scalars['JSON'];
  DebitNoteListings: Array<DebitNoteEntity>;
  CreditNoteListingsOld: Array<CreditNoteEntity>;
  CreditNoteListings: Scalars['JSON'];
  RefundDetail: RefundEntity;
  CheckBanknameInUse: Scalars['JSON'];
  RefundListings: Array<RefundEntity>;
  EInvoiceDocListings: Array<InvoiceEntity>;
  InvoiceDetail: InvoiceEntity;
  PostedScheduleBilling: Scalars['JSON'];
  InvoiceListings: Array<InvoiceEntity>;
  CreditNoteAndInvoiceCount: Scalars['JSON'];
  GroupInvoiceCount: Scalars['JSON'];
  CreditNoteAndInvoiceListings: Scalars['JSON'];
  GroupCreditNoteAndInvoiceListings: Scalars['JSON'];
  CityLedgerInvoiceListings: Scalars['JSON'];
  ARInvoiceListings: Array<InvoiceEntity>;
  StatementOfAccDocument: DocResponse;
  OfficialReceiptDetail: OfficialReceiptEntity;
  OfficialReceiptListingsOld: Array<OfficialReceiptEntity>;
  OfficialReceiptListings: Scalars['JSON'];
  ReminderListing: ReminderEntity;
  ReminderListings: Array<ReminderEntity>;
  InterestListing: InterestEntity;
  InterestListings: Array<ReminderEntity>;
  DebtorAgingListing: Scalars['JSON'];
  AgingByDebtor: Scalars['JSON'];
  MonthlyStatementListing: Scalars['JSON'];
  OpeningClosingAmount: OpenClosedResponse;
  OpeningClosingAmountV2: Scalars['JSON'];
  DebtorListing: Array<DebtorEntity>;
  DebtorTypeCount: Scalars['JSON'];
  DebtorCountListing: Scalars['JSON'];
  DebtorTypeCountListing: Scalars['JSON'];
  ScheduleBillingCountList: Scalars['JSON'];
  ActivitiesCountListing: Scalars['JSON'];
  DebtorListingCreditFacility: Array<DebtorEntity>;
  DebtorListingNonCreditFacility: Array<DebtorEntity>;
  CalculateDebtorListing: Scalars['JSON'];
  DebtorDetail: DebtorEntity;
  DebtorDetailByAccountCode: DebtorEntity;
  CreditNoteAllocationListing: Scalars['JSON'];
  DebitNoteAllocationListing: Scalars['JSON'];
  AllocationDetailListing: Scalars['JSON'];
  InvoiceInfo: ArSummaryInfo;
  DebitNoteInfo: ArSummaryInfo;
  CreditNoteInfo: ArSummaryInfo;
  OfficialReceiptInfo: ArSummaryInfo;
  RefundInfo: ArSummaryInfo;
  ARAccountSummary: ArAccountSummary;
  ARTotalOutstanding: ArAccountOsLastPayment;
  ARTotalDue: Scalars['Float'];
  ARAgingAnalysis: ArAgingAnalysisRespose;
  TotalAgingBI: Scalars['String'];
  DocumentNumberHeaderListing: Array<DocumentNumberHeaderEntity>;
  DebtorTypeListing: Array<DebtorTypeEntity>;
  DebtorTypeInUse: Scalars['Boolean'];
  ScheduleBillingList: Array<ScheduleBillingResponse>;
  AuditLogDetails: Scalars['JSON'];
  RoomSaleOptimizationListing: Array<RoomSaleOptimizationOutput>;
  BookingAttachmentListing: Array<BookingAttachmentEntity>;
  BookingAttachmentSummary: Array<BookingAttachmentEntity>;
  BIBillLedgerListing: Array<BillLedgerEntity>;
  BITransactionCancellation: Array<BillLedgerEntity>;
  BIDepositLedgerListing: Array<BookingEntity>;
  BIHotelListing: HotelEntity;
  BIAnalysis: Array<BookingEntity>;
  BISource: Array<BookingEntity>;
  BISourceV2: Scalars['JSON'];
  BISourceV3: Scalars['JSON'];
  BITop10Country: Array<BookingEntity>;
  BITop10CountryV2: Scalars['JSON'];
  BITop10Nationality: Array<BookingEntity>;
  BITop10NationalityV2: Scalars['JSON'];
  BICorpGovTa: Array<BookingEntity>;
  BICorpGovTaV2: Scalars['JSON'];
  BIMarketingPersonnal: Array<BookingEntity>;
  BIMarketingPersonnalV2: Scalars['JSON'];
  BIRoomTypeRevenueDetail: Array<BookingEntity>;
  BIRoomTypeRevenueDetailV2: Scalars['JSON'];
  BIRevenueByRoomTypeAnalysis: Scalars['JSON'];
  BITop5OTA: Array<BookingEntity>;
  BITop5OTAV2: Scalars['JSON'];
  BISegment: Array<BookingEntity>;
  BISegmentV2: Scalars['JSON'];
  BITop10Revenue: Array<BookingEntity>;
  BITop10RevenueV2: Scalars['JSON'];
  TotalOverdueByDebtorType: Scalars['JSON'];
  DebtorAging: Scalars['JSON'];
  TotalDueAging: Scalars['JSON'];
  Top10DebtorRevenue: Scalars['JSON'];
  CollectionByDebtorTypeVSOverdue: Scalars['JSON'];
  BusinessAnalyticInfoLine: Scalars['JSON'];
  CountItem: RegAndBooking;
  BIRoomRateListing: Array<BookingEntity>;
  BIUsersLisiting: UserEntity;
  HotelStateAnalysis: Scalars['JSON'];
  StateAnalysisRateAndRoom: Array<BookingEntity>;
  BIInHouse: Array<RegistrationEntity>;
  BiAdvPaymentListing: Array<AdvancePaymentEntity>;
  BIPaymentListing: Array<PaymentTestClass>;
  PaymentDetails: PaymentEntity;
  DepositClass: DepositLedgerEntity;
  DepositForfeitlist: Array<DepositLedgerEntity>;
  DepositRefundlist: Array<DepositLedgerEntity>;
  AdvancePaymentDetails: AdvancePaymentEntity;
  AdvancePaymentReversedDetails: Array<AdvancePaymentEntity>;
  RegistrationDetails: RegistrationEntity;
  RegistrationDetailsMulti: Array<RegistrationEntity>;
  BIPaymentListingTest: Array<PaymentListing123>;
  BIRegistrationListing: Array<BookingEntity>;
  BIHotelDetails: HotelEntity;
  BIRoomListing: Array<RoomEntity>;
  BIServiceRequestListing: Array<ServiceRequestEntity>;
  BIRevenueListing: Array<RevenueAnalysis>;
  BIRevenueListingV2: Scalars['JSON'];
  BIYTDRevenue: RevenueAnalysis;
  BICorpGovTAYTDRevenue: RevenueAnalysis;
  BISourceYTDRevenue: RevenueAnalysis;
  BITopCountry: Array<RevenueAnalysis>;
  BITopNationality: Array<RevenueAnalysis>;
  BIMarketingPersonnalTopSales: RevenueAnalysis;
  BIRoomTypeRevenue: RevenueAnalysis;
  BITravelAgent: Array<RevenueAnalysis>;
  BISegmentRevenue: RevenueAnalysis;
  BITopTransaction: RevenueAnalysis;
  BIServiceRequest: Array<RevenueAnalysis>;
  BIForeignListing: Array<BookingEntity>;
  BISecurityKeyCardDeposit: Array<DepositLedgerEntity>;
  BIRoomDiscrepancyLog: Array<RoomDiscrepancyLogEntity>;
  BIRoomStatus: Array<RoomEntity>;
  BIBookingStatus: Array<BookingEntity>;
  BIFolioListing: BiFolioResponse;
  DepositListing: BiFolioResponse;
  BIAdvancePaymentListing: Array<AdvancePaymentEntity>;
  BIReceiptRefundListing: BiReceiptRefundResponse;
  BITestListing: Array<FolioOutput>;
  BITransactionLedger: Array<BookingEntity>;
  BIPackage: Array<BillScheduleEntity>;
  ManagerReport: ManagerReportOutput;
  ManagerReportDepartment: ManagerReportOutput;
  CheckRecord?: Maybe<Output>;
  RoomAllocationLocationListing: Array<Scalars['JSON']>;
  ChooseRoomAllocationListing: Array<Scalars['JSON']>;
  ChooseRoomAllocationDetail: Array<ChooseRoomAllocationEntity>;
  IsChargeable: GuestAppFeatureEntity;
  CompanyListing: Array<CompanyEntity>;
  CurrencyListing: Array<CurrencyEntity>;
  CountryListing: Array<CountryEntity>;
  StateListing: Array<StateEntity>;
  DebtorListingDropDown: Array<DebtorEntity>;
  CorporateGovernmentListing: Array<DebtorEntity>;
  GovernmentListing: Array<DebtorEntity>;
  CorporateBookingListing: Array<BookingEntity>;
  CorporateGovernmentDetail: DebtorEntity;
  CorporateContractListing: Array<ContractEntity>;
  ContractDetails: ContractEntity;
  CorporateInUseListing: Scalars['Boolean'];
  CoporateInUseListing: Scalars['Boolean'];
  TotalAging: Scalars['JSON'];
  ActivitiesListing: Array<ActivitiesEntity>;
  DashBoardAllListing: DashBoardRespond;
  DashBoardListing: Array<BookingEntity>;
  AdvancePaymentFullListing: Array<AdvancePaymentEntity>;
  PaymentListing: Array<PaymentEntity>;
  RoomServicesCount: Scalars['Float'];
  HotelStatisticListing: HotelStatisticRespond;
  HotelStatisticListingV2: Scalars['JSON'];
  DocumentTemplateList: DocumentTemplateEntity;
  ConsolidatedInvoiceListing: Array<EInvoiceConsolidationEntity>;
  DocToConsolidateListing: Scalars['JSON'];
  DocToConsolidateView: Scalars['JSON'];
  getMsicCodeListing: Array<EInvoiceMsicEntity>;
  getClassificationListing: Array<EInvoiceClassificationEntity>;
  getClassificationListingByCode: EInvoiceClassificationEntity;
  getGeneralTin: Array<GeneralTinEntity>;
  EInvoiceInformation: Scalars['JSON'];
  getFolioInfo: EInvoiceQrResponse;
  EInvSubscription: Array<EInvoiceSubscriptionEntity>;
  NARegistrationDetails: Array<NaRegistrationEntity>;
  GuestProfileListing: GuestProfileResponse;
  GuestProfileListingV2: Array<GuestProfileEntity>;
  GuestProfileListingByID: Array<RegistrationEntity>;
  SelectedGuestListing: GuestProfileEntity;
  GuestHistoryListing: Array<RegistrationEntity>;
  GuestHistoryListingV2?: Maybe<Scalars['JSON']>;
  GuestProfileHistoryListing: Array<RegistrationEntity>;
  GuestProfileListbyRoom: Array<GuestProfileEntity>;
  SearchGuest?: Maybe<Array<SearchGuestResponse>>;
  GuestMovementList?: Maybe<Scalars['JSON']>;
  GuestMovementListing: Array<GuestMovementEntity>;
  GuestListingByLocationTime: Array<GuestMovementEntity>;
  CBExport: Scalars['JSON'];
  ExportedCBListing: Scalars['JSON'];
  ExportedCBDetails: Array<Scalars['JSON']>;
  CBExportDetailsByTrxType: Scalars['JSON'];
  ExportedGLListing: Scalars['JSON'];
  ExportedGLDetails: Array<Scalars['JSON']>;
  ExportedGLTransferDetails: Array<ExportedGlTransferResponse>;
  GLExport: Scalars['JSON'];
  GLExportDetailsByTrxType: Scalars['JSON'];
  RoomServiceFolioListing: Array<FolioEntity>;
  PaymentRoomServiceDetail: PaymentEntity;
  BillLedgerFullListing: Array<BillLedgerEntity>;
  PaymentBillLedgerListing: Array<BillLedgerEntity>;
  PaymentRegistrationListing: RegistrationEntity;
  checkoutfoliodeposiit: Array<FolioEntity>;
  CheckedOutFolioListingOLD: Scalars['JSON'];
  CheckedOutFolioListing: Scalars['JSON'];
  AdjustedFolioDetails: Array<FolioEntity>;
  CheckoutAdjustmentListing: Array<FolioEntity>;
  SearchFolio?: Maybe<FolioEntity>;
  RoomServiceListing: Array<BillLedgerEntity>;
  DeliveredRoomServiceListing: Array<BillLedgerEntity>;
  RoomServiceRegistrationListing: Array<RegistrationEntity>;
  DeliveredRoomServiceRegistrationListing: Array<RegistrationEntity>;
  GuestRoomResult: Scalars['JSON'];
  FolioRegIds: Scalars['JSON'];
  CheckIsGroupInvoice: Scalars['JSON'];
  FolioChargeToList: Scalars['JSON'];
  IsHotelLockUser?: Maybe<Scalars['Boolean']>;
  TTlockInfo?: Maybe<Scalars['JSON']>;
  HotelAccountXList?: Maybe<HotelVendorInfoEntity>;
  LoyaltyAppHotelGroup: Scalars['JSON'];
  CheckNAProcessLog: Scalars['Boolean'];
  OutGoingDocTemplate?: Maybe<OutgoingDocTemplateEntity>;
  PackageRedemptionListing: Array<PackageRedemptionListOutput>;
  PackageRedemptionByItem: Array<PackageRedemptionListOutput>;
  PreRegBookingInfo?: Maybe<Scalars['JSON']>;
  PreRegRegistrationDetails: RegistrationEntity;
  BillSummaryDetails: Scalars['JSON'];
  PreRegRoomAllocation: Array<Scalars['JSON']>;
  IsRoomExist: Scalars['Boolean'];
  PreRegBooking?: Maybe<Scalars['JSON']>;
  GuestAppFeature: GuestAppFeatureEntity;
  PreCheckInQr: Scalars['JSON'];
  IsBookingExist: Scalars['JSON'];
  Q3DocumentHeader: Array<Q3DocumentHeaderEntity>;
  Q3Listings: Array<Scalars['JSON']>;
  IsQ3FInterface: Scalars['Boolean'];
  Q3FSegment: Scalars['JSON'];
  Q3FRevenueOld: Scalars['JSON'];
  Q3FRevenue: Scalars['JSON'];
  Q3FStatistic: Scalars['JSON'];
  Q3DocumentDetailByHeader: Array<Q3DocumentDetailEntity>;
  Q3MappingChargesList: Scalars['JSON'];
  Q3MappingPaymentList: Scalars['JSON'];
  Q3MappingAdvPaymentList: Scalars['JSON'];
  Q3MappingRefundList: Scalars['JSON'];
  Q3MappingDepositList: Scalars['JSON'];
  Q3MappingTaxList: Scalars['JSON'];
  Q3MappingDebtorList: Scalars['JSON'];
  HousekeepingListing: Array<RoomEntity>;
  TodayBookingListing: Array<BookingEntity>;
  HousekeepingDetail: RoomEntity;
  IsBlockRoomAvailable: Scalars['JSON'];
  HousekeepingServiceRequestListing: Array<ServiceRequestEntity>;
  HousekeepingRoomStatusListing: Array<RoomStatusEntity>;
  BillLedgerListing: Array<BillLedgerEntity>;
  HousekeepingIncidentalChargeListing: Array<IncidentalChargeEntity>;
  HKMasterListing: Scalars['JSON'];
  ServiceRequestDetail: Array<ServiceRequestEntity>;
  ServiceRequestFullListing: Array<ServiceRequestEntity>;
  ServiceRequestListing: Array<ServiceRequestEntity>;
  ServiceRequestv2Listing: Array<ServiceRequestEntity>;
  SrLocationListing: Array<LocationEntity>;
  DepartmentListing: Array<DepartmentEntity>;
  DepartmentInUseListing: Scalars['Boolean'];
  IncidentalDepartmentListing: Array<DepartmentEntity>;
  DeparmentChecking: Scalars['Boolean'];
  EDocTemplateListing: Array<EDocTemplateEntity>;
  EDocParamsListing: Array<EDocParamsEntity>;
  ConfirmationLetterListing: Array<EDocTemplateEntity>;
  AccountMappingChargesList: Scalars['JSON'];
  AccountMappingPaymentList: Scalars['JSON'];
  AccountMappingAdvPaymentList: Scalars['JSON'];
  AccountMappingRefundList: Scalars['JSON'];
  AccountMappingDepositList: Scalars['JSON'];
  CityLedgerChargesList: Scalars['JSON'];
  CityLedgerDebtorList: Scalars['JSON'];
  AccountMappingTaxGLList: Scalars['JSON'];
  CityLedgerTaxGLList: Scalars['JSON'];
  BankAccountListing: Array<BankAccountEntity>;
  HotelListing: Array<HotelEntity>;
  MenuXHotelListing: Array<HotelEntity>;
  HotelDetails: HotelEntity;
  HotelPreRegQr: Scalars['JSON'];
  GuestappsettingDetail?: Maybe<Scalars['JSON']>;
  IsPaymentGateWayHotel: Scalars['Boolean'];
  ServicePaymentSetting: Scalars['JSON'];
  CustomizedDocListing: Array<CustomizedDocumentEntity>;
  IncidentalChargeListing: Array<IncidentalChargeEntity>;
  IncidentalChargeByID: IncidentalChargeEntity;
  IncidentalChargeInUseListing: Scalars['Boolean'];
  IncidentalChargeInRateElement: Scalars['JSON'];
  RateElementInactiveCheck: Scalars['JSON'];
  IncidentalChargeListingByHotel: Array<IncidentalChargeEntity>;
  LateCheckOutChargeListing: Array<LateCheckOutChargeEntity>;
  FloorPlanListing: Array<LocationFloorPlanEntity>;
  LocationListing: Array<LocationEntity>;
  LocationFloorPlanListing: Array<LocationEntity>;
  LocationInUseListing: Scalars['Boolean'];
  RoomInUseListing: Scalars['Boolean'];
  RoomInHouseListing: Scalars['Boolean'];
  RoomBlockRoomListing: Scalars['Boolean'];
  HKLocationMasterListing: Scalars['JSON'];
  MeasurementListing: Array<MeasurementEntity>;
  PlatformAmenitiesListing: Array<PlatformAmenitiesEntity>;
  PlatformDepartmentListing: Array<PlatformDepartmentEntity>;
  RateElementListing: Array<RateElementEntity>;
  RateAddOnListing: Array<RateAddOnEntity>;
  RateTypeListing: Array<RateTypeEntity>;
  RateListing: Array<RateEntity>;
  RateInHouseListing: Scalars['Boolean'];
  RateEffectiveListing: Array<RateEffectiveEntity>;
  RatePolicyInfo: RatePolicyResponse;
  RateAdjustmentAuditListing: Array<RateAdjustmentAuditEntity>;
  IsRateInUse: Scalars['Boolean'];
  RegenBSQueueListing: Array<RegenBsQueueEntity>;
  SeasonListing: Array<SeasonEntity>;
  SeasonListingCalender: Array<SeasonEntity>;
  SeasonCalendarListing: Array<SeasonCalendarEntity>;
  RatePolicyListing: Array<RateEntity>;
  RatePolicyRoomTypeListing: Array<RoomTypeEntity>;
  RatePolicyDetails: RatePolicyEntity;
  RatePolicyInUseListing: Scalars['Boolean'];
  ReasonListing: Array<ReasonEntity>;
  CancelReasonListing: Array<ReasonEntity>;
  BookingCancelReasonListing: Array<ReasonEntity>;
  ReaonInUseListing: Scalars['Boolean'];
  RoomListing: Array<RoomEntity>;
  BlockRoomLogListing: Scalars['Boolean'];
  BlockRoomLogListingV2: Array<BlockRoomLogEntity>;
  HotelRoomNumberList: Array<RoomEntity>;
  RoomStatusListing: Array<RoomStatusEntity>;
  RoomTypeAmenitiesListing: Array<RoomTypeAmenitiesEntity>;
  RoomTypeListing: Array<RoomTypeEntity>;
  RegistrationRoomTypeListing: Scalars['Boolean'];
  SalesChannelListing: Array<SalesChannelEntity>;
  HotelSalesChannelListing: Array<SalesChannelEntity>;
  SalesChannelinUseListing: Scalars['Boolean'];
  SegmentListing: Array<SegmentEntity>;
  SegmentInUseListing: Scalars['Boolean'];
  GetTaxTypeListing: Scalars['JSON'];
  GetTaxListing: Scalars['JSON'];
  GetTaxListingDropdown: Array<TaxListingOutput>;
  GetTaxDetails: TaxEntity;
  IsTaxInUse: Scalars['Boolean'];
  IsTaxSameCode: Scalars['Boolean'];
  GetTaxSchemeListing: Array<TaxSchemeEntity>;
  GetTaxSchemeDetailItemRate: Array<TaxSchemeDetailItemRateOutput>;
  GetTaxSchemeDetail: Array<TaxSchemeDetailOutput>;
  IsTaxSchemeInUse: Scalars['Boolean'];
  IsTaxSameName: Scalars['Boolean'];
  TravelAgentHotelListing: Array<HotelEntity>;
  TravelAgentListing: Array<DebtorEntity>;
  AgentInUseLisiting: Scalars['Boolean'];
  TravelAgentDetail: DebtorEntity;
  PMSTravelAgentDetail: DebtorEntity;
  TravelAgentContractListing: Array<ContractEntity>;
  TravelAgBookingListing: Array<BookingEntity>;
  TravelAgentInUseListing: Scalars['Boolean'];
  AllotmentListing: Array<ContractAllotmentEntity>;
  loggedInDebtorProfile: DebtorProfileEntity;
  DebtorAnalysis: Array<ContractEntity>;
  TravelAgentRoomRevenue: Array<RegistrationEntity>;
};


export type QueryTaxInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryComputeTaxbyHotelArgs = {
  input: TaxInput;
};


export type QueryComputeUpgradebyHotelArgs = {
  Amount?: Maybe<Scalars['Float']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Mode: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryComputeBookingbyHotelArgs = {
  HotelID: Scalars['String'];
  input: Array<TaxInput>;
};


export type QueryComputeTaxBookingArgs = {
  DiscountAmount: Scalars['Float'];
  HotelID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  RateID: Scalars['String'];
  RoomTypeID: Scalars['String'];
};


export type QueryComputeTaxArrByHotelArgs = {
  TaxInput: Array<TaxInput>;
};


export type QueryComputeTaxbyTaxTypeArgs = {
  HotelID: Scalars['String'];
  input: TaxType;
};


export type QueryGetAdjustmentTaxArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryGetLevyTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetServiceChargeTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetCurrentTourismTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetPlatformTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryGetCurrentGovTaxArgs = {
  HotelID: Scalars['String'];
};


export type QueryTaxPolicyListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryCmsCustomControlArgs = {
  BookingID: Scalars['String'];
  ControlOP: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryWhBookingNotificationArgs = {
  HotelID: Scalars['String'];
};


export type QueryFrontDeskListingArgs = {
  SortByOrder?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryCheckInListingArgs = {
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  SearchValue?: Maybe<Scalars['String']>;
  BookingStatus: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRegistrationByBookingListingArgs = {
  RegistrationStatus: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryFrontDeskListingV2Args = {
  Search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SortByOrder?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  SearchValue?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryCheckInListingCountArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAssignRoomFrontDeskListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomAssignListingArgs = {
  RoomID?: Maybe<Array<Scalars['String']>>;
  RegistrationIDs?: Maybe<Array<Scalars['String']>>;
  IsBooking?: Maybe<Scalars['Boolean']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  RoomTypeID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryFloorPlanGalleryArgs = {
  ID: Scalars['String'];
};


export type QueryAllRoomAsignListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  IsBooking?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryGuestProfileArgs = {
  GuestID: Scalars['String'];
};


export type QueryBookingProfileArgs = {
  ID: Scalars['String'];
};


export type QuerySelectFolioArgs = {
  ID: Scalars['String'];
};


export type QueryFolioDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryDebtorFolioInfoArgs = {
  HotelID: Scalars['String'];
  DebtorAccount?: Maybe<Scalars['String']>;
};


export type QueryFolioDetailsWithIDsArgs = {
  LedgerIDs?: Maybe<LedgerIDsInput>;
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepositListingByBookingArgs = {
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepositListingByBookingV2Args = {
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryUnutilizedDepositListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryUtilizedDepositListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryUnutilizedDepositListingV2Args = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryUtilizedDepositListingV2Args = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryDepositLedgerDetailsArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepositRefundDetailsArgs = {
  HotelID: Scalars['String'];
  DepositID: Scalars['String'];
};


export type QueryFrontDeskPaymentListingArgs = {
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryBillingByRegistrationArgs = {
  IsHMS?: Maybe<Scalars['Boolean']>;
  RegistrationID: Array<Scalars['String']>;
  BookingID: Scalars['String'];
};


export type QueryIsRoomAvailableArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryIsRoomAvailableGroupArgs = {
  RegistrationIDs: Array<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomIDs: Array<Scalars['String']>;
};


export type QueryRoomUpgradeCheckArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryFrontDeskIncidentalChargeListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryRegistrationTaxLedgerListingArgs = {
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryFrontDeskServiceRequestListingArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QueryActiveBookingRoomListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPrincipalGuestListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryFolioListingByRegistrationArgs = {
  AccountID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryFolioHistoryListingArgs = {
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryFolioListingArgs = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryNonGuestFolioListingArgs = {
  FolioID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryNonGuestFolioListingv2Args = {
  DebtorID?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  Title?: Maybe<Scalars['String']>;
  ReferenceID?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  FolioID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryFolioUseListingArgs = {
  HotelID: Scalars['String'];
  FolioID: Scalars['String'];
};


export type QuerySuspendFolioListingArgs = {
  FolioID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QuerySuspendFolioListingv2Args = {
  DebtorID?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  Title?: Maybe<Scalars['String']>;
  ReferenceID?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  FolioID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryNonGuestBillingListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
};


export type QueryPendingBillingListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
};


export type QuerySelectedRegistrationArgs = {
  ID: Scalars['String'];
};


export type QuerySuspendDetailArgs = {
  ID: Scalars['String'];
};


export type QueryReceiptListingArgs = {
  PaymentClass: PaymentClass;
  ReceiptNo: Scalars['String'];
};


export type QueryPaymentDetailArgs = {
  HotelID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Array<Scalars['String']>>;
};


export type QueryAdvancePaymentDetailArgs = {
  HotelID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
};


export type QueryFrontDeskInfoLinesArgs = {
  HotelID: Scalars['String'];
};


export type QueryContactPaginationArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
};


export type QueryRegistrationListingArgs = {
  RoomID: Scalars['String'];
};


export type QueryCurrentTaxDetailsArgs = {
  HotelID: Scalars['String'];
};


export type QueryFrontDeskBookingInfoArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHotelXCredentialsArgs = {
  isHotel?: Maybe<Scalars['Boolean']>;
  Authorization: Scalars['String'];
};


export type QueryMenuXInhouseListArgs = {
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryMenuXInhouseStatusArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryMenuXDebtorListArgs = {
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryInhouseQrArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryCheckoutReinstateInfoArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryFrontDeskSubMenuInfoArgs = {
  RegistrationIDs?: Maybe<Array<Scalars['String']>>;
  HotelID: Scalars['String'];
};


export type QueryIsLockUserProcessArgs = {
  RoomNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsPresetIncChargesExistArgs = {
  newDepartureDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryStayViewListingArgs = {
  Location?: Maybe<Array<Scalars['String']>>;
  RoomNo?: Maybe<Array<Scalars['String']>>;
  RoomTypeIDs?: Maybe<Array<Scalars['String']>>;
  enddate: Scalars['DateTime'];
  startdate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryHotelNotificationListingArgs = {
  NotificationType: Scalars['String'];
  UserID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHotelsByCityArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  DebtorType?: Maybe<Scalars['String']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  NoOfGuests?: Maybe<Scalars['Float']>;
  NoOfRooms?: Maybe<Scalars['Float']>;
  GuestID?: Maybe<Scalars['String']>;
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  City: Scalars['String'];
};


export type QueryHotelPricesByDateRangeArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  CheckOut?: Maybe<Scalars['DateTime']>;
  CheckIn?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryGuestBookingListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  GuestID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryDisCountByPromoCodeArgs = {
  PromoCodeInput: Array<PromoCodeInput>;
  PromoCode: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestRoomServiceListingArgs = {
  ServiceType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
};


export type QueryGuestReviewDetailArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  Rating?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGaInHouseGuestListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
};


export type QueryOnlineGaInHouseGuestListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
};


export type QueryServicePendingCountArgs = {
  GuestID?: Maybe<Scalars['String']>;
};


export type QueryHotelReviewListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryGaMarketingAdsListingArgs = {
  isLoyaltyApp?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryIsGuestAppUserArgs = {
  Login?: Maybe<Scalars['String']>;
};


export type QueryGuestAppFeatureListArgs = {
  HotelID: Scalars['String'];
};


export type QueryPromotionListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryPromotionDetailsInUseListingArgs = {
  PromoCode: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryPromotionDetailListingArgs = {
  PromotionID: Scalars['String'];
};


export type QueryEmailMarketingListingArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryEmailMarketingDetailArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryPromotionInUseListingArgs = {
  Date: Scalars['DateTime'];
  PromoCode: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryGuestReviewListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  Rating?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryGuestReviewInfoLinesArgs = {
  HotelID: Scalars['String'];
};


export type QueryBookingListingArgs = {
  SearchValue?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SortByOrder?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  GroupName?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  BookingDate?: Maybe<Scalars['DateTime']>;
  BookingStatus?: Maybe<Scalars['String']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  DebtorProfileID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
};


export type QueryBookingDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryAdvancePaymentListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryInhousePaymentListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryInhouseRefundListingArgs = {
  BookingID: Scalars['String'];
};


export type QueryRoomServiceBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomNumberForFoodListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAvailabilityListingArgs = {
  TravelAgentID?: Maybe<Scalars['String']>;
  RoomTypeIDs?: Maybe<Array<Scalars['String']>>;
  Adddate?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryRoomTypeAvailableArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryAvailabilityCheckingRevampArgs = {
  DepartureDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryCheckForBlockRoomArgs = {
  RoomID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryRoleViewArgs = {
  UserID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryPackagePriceListingArgs = {
  NoOfRoom?: Maybe<Scalars['Float']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBookingPackagePriceListingArgs = {
  NoOfRoom?: Maybe<Scalars['Float']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Array<Scalars['String']>>;
  IsOnline?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryChangeOfDateCheckAvailabilityArgs = {
  Reinstate?: Maybe<Scalars['Boolean']>;
  DepartureDate: Scalars['DateTime'];
  ArrivalDate: Scalars['DateTime'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryChangeOfDateCheckAvailabilityBookEditArgs = {
  roomTypeIDs?: Maybe<Array<Scalars['String']>>;
  Reinstate?: Maybe<Scalars['Boolean']>;
  EditRegInput: Array<EditRegInput>;
  DepartureDate: Scalars['DateTime'];
  ArrivalDate: Scalars['DateTime'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsRoomNoAvailableArgs = {
  RegistrationInput?: Maybe<Array<RegistrationInput>>;
  HotelID: Scalars['String'];
};


export type QueryShowBookingConfirmationEmailArgs = {
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryBookingConfirmationTemplateArgs = {
  TemplateID?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
};


export type QueryCurrentInhouseListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCurrentInhouseListV7Args = {
  HotelID: Scalars['String'];
};


export type QueryBillScheduleListByBookingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  IsFrontDesk?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
};


export type QueryBillScheduleListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  IsFrontDesk?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
};


export type QueryOAuthHotelXArgs = {
  ClientID?: Maybe<Scalars['String']>;
  Secret: Scalars['String'];
};


export type QueryInventoryRatePlansHotelXArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ClientID: Scalars['String'];
};


export type QueryGuestReviewsHotelXArgs = {
  ClientID?: Maybe<Scalars['String']>;
};


export type QueryPromotionListHotelXArgs = {
  ClientID: Scalars['String'];
};


export type QueryPromoDiscHotelXArgs = {
  PromoCodeInput: Array<PromoCodeInput>;
  PromoCode: Scalars['String'];
  ClientID: Scalars['String'];
};


export type QueryHotelTaxDetailsArgs = {
  ClientID: Scalars['String'];
};


export type QueryComputeTaxHotelXArgs = {
  input: TaxInput;
};


export type QueryBookingSubMenuInfoArgs = {
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryBookingBillScheduleInfoArgs = {
  IsCurrentDate?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationIDs: Array<Scalars['String']>;
};


export type QueryBookingIncChargesRegistrationListingArgs = {
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type QueryIsExistRegistrationIncChargesArgs = {
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']>;
};


export type QueryChannelManagerProfileArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryEntRoleUsrAsgListArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  hotelID: Scalars['String'];
};


export type QueryHotelAuthorizationInfoLineArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetUsersRoleContractArgs = {
  hotelID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryUserEntitiesArgs = {
  userID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
};


export type QueryRoundingAdjustmentArgs = {
  Amount: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryRoundingAmountArgs = {
  Amount: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QuerySubscribedPackagesArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetUserByUsernameArgs = {
  UserName: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  status?: Maybe<CommonStatus>;
  superUser?: Maybe<Scalars['Boolean']>;
  LatetestUser?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryUpdateLastestAccessHotelArgs = {
  hotelID: Scalars['String'];
  userIDs: Scalars['String'];
};


export type QueryIsLoginPermittedArgs = {
  deviceIP: Scalars['String'];
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryHlsInvetoryUpdateArgs = {
  RoomTypeIDs?: Maybe<Array<Scalars['String']>>;
  RateIDs?: Maybe<Array<Scalars['String']>>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryArrayChallengeArgs = {
  arr: Array<Scalars['Float']>;
};


export type QueryDebitNoteDetailArgs = {
  ID: Scalars['String'];
};


export type QueryGlAccountPeriodArgs = {
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryDebitNoteListingsArgs = {
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteListingsOldArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteListingsArgs = {
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRefundDetailArgs = {
  ID: Scalars['String'];
};


export type QueryCheckBanknameInUseArgs = {
  BankName: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRefundListingsArgs = {
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryEInvoiceDocListingsArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryInvoiceDetailArgs = {
  ID: Scalars['String'];
};


export type QueryPostedScheduleBillingArgs = {
  DebtorID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryInvoiceListingsArgs = {
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteAndInvoiceCountArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGroupInvoiceCountArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCreditNoteAndInvoiceListingsArgs = {
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGroupCreditNoteAndInvoiceListingsArgs = {
  GroupReferenceNo?: Maybe<Scalars['String']>;
  GroupInvoiceNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCityLedgerInvoiceListingsArgs = {
  Status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryArInvoiceListingsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryStatementOfAccDocumentArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryOfficialReceiptDetailArgs = {
  ID: Scalars['String'];
};


export type QueryOfficialReceiptListingsOldArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryOfficialReceiptListingsArgs = {
  Amount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryReminderListingArgs = {
  ID: Scalars['String'];
};


export type QueryInterestListingArgs = {
  ID: Scalars['String'];
};


export type QueryDebtorAgingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAgingByDebtorArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryMonthlyStatementListingArgs = {
  ID: Scalars['String'];
};


export type QueryOpeningClosingAmountArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
};


export type QueryOpeningClosingAmountV2Args = {
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
};


export type QueryDebtorListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorTypeCountArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorCountListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorTypeCountListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryScheduleBillingCountListArgs = {
  DebtorAcc?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryActivitiesCountListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorListingCreditFacilityArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorListingNonCreditFacilityArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  CompanyName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryCalculateDebtorListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDebtorDetailArgs = {
  ID: Scalars['String'];
};


export type QueryDebtorDetailByAccountCodeArgs = {
  HotelID: Scalars['String'];
  AccountCode: Scalars['String'];
};


export type QueryCreditNoteAllocationListingArgs = {
  DebtorID: Scalars['String'];
  CreditID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDebitNoteAllocationListingArgs = {
  DebtorID: Scalars['String'];
  DebitID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAllocationDetailListingArgs = {
  DebtorID: Scalars['String'];
  CreditID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryInvoiceInfoArgs = {
  IsPostedDoc: Scalars['Boolean'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryDebitNoteInfoArgs = {
  IsPostedDoc: Scalars['Boolean'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryCreditNoteInfoArgs = {
  IsPostedDoc: Scalars['Boolean'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryOfficialReceiptInfoArgs = {
  IsPostedDoc: Scalars['Boolean'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryRefundInfoArgs = {
  IsPostedDoc: Scalars['Boolean'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArAccountSummaryArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryArTotalOutstandingArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArTotalDueArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryArAgingAnalysisArgs = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryTotalAgingBiArgs = {
  HotelID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
};


export type QueryDocumentNumberHeaderListingArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorTypeListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryDebtorTypeInUseArgs = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryScheduleBillingListArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsGroupInvoice?: Maybe<Scalars['String']>;
  IsGroupBooking?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  DocEndDate?: Maybe<Scalars['DateTime']>;
  DocStartDate?: Maybe<Scalars['DateTime']>;
  DebtorTypeID: Array<Scalars['String']>;
  DebtorAcc: Array<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryAuditLogDetailsArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  FieldID: Scalars['String'];
  TableName: Scalars['String'];
};


export type QueryRoomSaleOptimizationListingArgs = {
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBookingAttachmentListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryBookingAttachmentSummaryArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryBiBillLedgerListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTransactionCancellationArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiDepositLedgerListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiHotelListingArgs = {
  ID: Scalars['String'];
};


export type QueryBiAnalysisArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiSourceArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiSourceV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiSourceV3Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10CountryArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10CountryV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiTop10NationalityArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10NationalityV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiCorpGovTaArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiCorpGovTaV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRoomTypeRevenueDetailArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiRoomTypeRevenueDetailV2Args = {
  Mode?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRevenueByRoomTypeAnalysisArgs = {
  Mode?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiTop5OtaArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop5Otav2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiSegmentArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiSegmentV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiTop10RevenueArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTop10RevenueV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryTotalOverdueByDebtorTypeArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryDebtorAgingArgs = {
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryTotalDueAgingArgs = {
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryTop10DebtorRevenueArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryCollectionByDebtorTypeVsOverdueArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBusinessAnalyticInfoLineArgs = {
  HotelID: Scalars['String'];
};


export type QueryCountItemArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiRoomRateListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  GuestID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBiUsersLisitingArgs = {
  ID: Scalars['String'];
};


export type QueryHotelStateAnalysisArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryStateAnalysisRateAndRoomArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiInHouseArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiAdvPaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiPaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryPaymentDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryDepositClassArgs = {
  ReversedID: Scalars['String'];
};


export type QueryDepositForfeitlistArgs = {
  ReversedID: Scalars['String'];
};


export type QueryDepositRefundlistArgs = {
  ReversedID: Scalars['String'];
};


export type QueryAdvancePaymentDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryAdvancePaymentReversedDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryRegistrationDetailsArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  GuestID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryRegistrationDetailsMultiArgs = {
  InputReg?: Maybe<Array<InputReg>>;
};


export type QueryBiPaymentListingTestArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiRegistrationListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiHotelDetailsArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomListingArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBiServiceRequestListingArgs = {
  Department?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRevenueListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiRevenueListingV2Args = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiytdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiCorpGovTaytdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiSourceYtdRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopCountryArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopNationalityArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiMarketingPersonnalTopSalesArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomTypeRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTravelAgentArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiSegmentRevenueArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiTopTransactionArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiServiceRequestArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiForeignListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiSecurityKeyCardDepositArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiRoomDiscrepancyLogArgs = {
  HotelID: Scalars['String'];
};


export type QueryBiRoomStatusArgs = {
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryBiBookingStatusArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiFolioListingArgs = {
  DocumentType?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SearchValue?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDepositListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SearchValue?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBiAdvancePaymentListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiReceiptRefundListingArgs = {
  Limit?: Maybe<Scalars['Float']>;
  Offset?: Maybe<Scalars['Float']>;
  SearchValue?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTestListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryBiTransactionLedgerArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryBiPackageArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryManagerReportArgs = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryManagerReportDepartmentArgs = {
  EffectiveDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryCheckRecordArgs = {
  AccountID?: Maybe<Scalars['String']>;
  Mode?: Maybe<Scalars['String']>;
  Login?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
};


export type QueryRoomAllocationLocationListingArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryChooseRoomAllocationListingArgs = {
  DateSearch?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryChooseRoomAllocationDetailArgs = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsChargeableArgs = {
  HotelID: Scalars['String'];
};


export type QueryCompanyListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  AccountID: Scalars['String'];
};


export type QueryDebtorListingDropDownArgs = {
  HotelID: Scalars['String'];
};


export type QueryCorporateGovernmentListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SalesChannel?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  DebtorCategory?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryGovernmentListingArgs = {
  DebtorCategory?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryCorporateBookingListingArgs = {
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCorporateGovernmentDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCorporateContractListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryContractDetailsArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryCorporateInUseListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryCoporateInUseListingArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
};


export type QueryTotalAgingArgs = {
  DebtorID?: Maybe<Array<Scalars['String']>>;
  HotelID: Scalars['String'];
};


export type QueryActivitiesListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  ActivityDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryDashBoardAllListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDashBoardListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryAdvancePaymentFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomServicesCountArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelStatisticListingArgs = {
  BlockRoom?: Maybe<Scalars['Boolean']>;
  Complimentary?: Maybe<Scalars['Boolean']>;
  DayUse?: Maybe<Scalars['Boolean']>;
  HouseUse?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryHotelStatisticListingV2Args = {
  BlockRoom?: Maybe<Scalars['Boolean']>;
  Complimentary?: Maybe<Scalars['Boolean']>;
  DayUse?: Maybe<Scalars['Boolean']>;
  HouseUse?: Maybe<Scalars['Boolean']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryDocumentTemplateListArgs = {
  ID: Scalars['String'];
};


export type QueryConsolidatedInvoiceListingArgs = {
  Submenu?: Maybe<Scalars['String']>;
  DocType?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDocToConsolidateListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  IsSearch?: Maybe<Scalars['Boolean']>;
  DocNo?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  DocType?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryDocToConsolidateViewArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  DocType?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  ConsolidationID: Scalars['String'];
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGetClassificationListingByCodeArgs = {
  code?: Maybe<Scalars['String']>;
};


export type QueryEInvoiceInformationArgs = {
  Type: Scalars['String'];
  ID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGetFolioInfoArgs = {
  FolioID: Scalars['String'];
  DocType: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryEInvSubscriptionArgs = {
  AccountID: Scalars['String'];
};


export type QueryNaRegistrationDetailsArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListingArgs = {
  SearchValue?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  FullName?: Maybe<Scalars['String']>;
  Age2?: Maybe<Scalars['String']>;
  Age1?: Maybe<Scalars['String']>;
  VisitNo2?: Maybe<Scalars['String']>;
  VisitNo1?: Maybe<Scalars['String']>;
  BirthMonth?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListingV2Args = {
  RoomNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Age2?: Maybe<Scalars['String']>;
  Age1?: Maybe<Scalars['String']>;
  VisitNo2?: Maybe<Scalars['String']>;
  VisitNo1?: Maybe<Scalars['String']>;
  BirthMonth?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListingByIdArgs = {
  RegistrationIDs: Array<Scalars['String']>;
};


export type QuerySelectedGuestListingArgs = {
  HotelID: Scalars['String'];
  GuestID: Scalars['String'];
};


export type QueryGuestHistoryListingArgs = {
  GuestID: Scalars['String'];
};


export type QueryGuestHistoryListingV2Args = {
  GuestID: Scalars['String'];
};


export type QueryGuestProfileHistoryListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestProfileListbyRoomArgs = {
  BookingID: Scalars['String'];
  RoomID: Scalars['String'];
};


export type QuerySearchGuestArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  GuestName: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestMovementListArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  GuestID: Scalars['String'];
};


export type QueryGuestMovementListingArgs = {
  Take?: Maybe<Scalars['Float']>;
  Skip?: Maybe<Scalars['Float']>;
  HotelID: Scalars['String'];
};


export type QueryGuestListingByLocationTimeArgs = {
  Take?: Maybe<Scalars['Float']>;
  Skip?: Maybe<Scalars['Float']>;
  CheckInDate: Scalars['DateTime'];
  GuestID: Scalars['String'];
  RefID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCbExportArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryExportedCbListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryExportedCbDetailsArgs = {
  BatchNo: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryCbExportDetailsByTrxTypeArgs = {
  TrxDate: Scalars['DateTime'];
  TrxType: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryExportedGlListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryExportedGlDetailsArgs = {
  BatchNo: Scalars['Float'];
  HotelID: Scalars['String'];
};


export type QueryExportedGlTransferDetailsArgs = {
  BatchNo: Scalars['Float'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGlExportArgs = {
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGlExportDetailsByTrxTypeArgs = {
  AccountCode?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  BatchNo: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  TrxDesc: Scalars['String'];
  TrxType: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryRoomServiceFolioListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentRoomServiceDetailArgs = {
  RegistrationID: Scalars['String'];
  OrderID: Scalars['String'];
};


export type QueryBillLedgerFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryPaymentBillLedgerListingArgs = {
  FolioID: Scalars['String'];
};


export type QueryPaymentRegistrationListingArgs = {
  RegistrationID: Scalars['String'];
};


export type QueryCheckoutfoliodeposiitArgs = {
  HotelID: Scalars['String'];
};


export type QueryCheckedOutFolioListingOldArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountName?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryCheckedOutFolioListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  AccountName?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  HotelID: Scalars['String'];
};


export type QueryAdjustedFolioDetailsArgs = {
  HotelID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryCheckoutAdjustmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySearchFolioArgs = {
  FolioNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRoomServiceListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryDeliveredRoomServiceListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryRoomServiceRegistrationListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryDeliveredRoomServiceRegistrationListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryGuestRoomResultArgs = {
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryFolioRegIdsArgs = {
  FolioID: Scalars['String'];
};


export type QueryCheckIsGroupInvoiceArgs = {
  HotelID: Scalars['String'];
  SourceID: Scalars['String'];
};


export type QueryFolioChargeToListArgs = {
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsHotelLockUserArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryTTlockInfoArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryHotelAccountXListArgs = {
  HotelID: Scalars['String'];
};


export type QueryLoyaltyAppHotelGroupArgs = {
  LoyaltyAppURL?: Maybe<Scalars['String']>;
};


export type QueryCheckNaProcessLogArgs = {
  HotelID: Scalars['String'];
};


export type QueryOutGoingDocTemplateArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryPackageRedemptionListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryPackageRedemptionByItemArgs = {
  ComputationRule: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryPreRegBookingInfoArgs = {
  QR?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryPreRegRegistrationDetailsArgs = {
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBillSummaryDetailsArgs = {
  BillSummaryInput: BillSummaryInput;
};


export type QueryPreRegRoomAllocationArgs = {
  RoomTypeID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryIsRoomExistArgs = {
  RoomTypeID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryPreRegBookingArgs = {
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGuestAppFeatureArgs = {
  HotelID: Scalars['String'];
};


export type QueryPreCheckInQrArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
};


export type QueryIsBookingExistArgs = {
  BookingNo: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3DocumentHeaderArgs = {
  DocumentType: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3ListingsArgs = {
  HotelID: Scalars['String'];
};


export type QueryIsQ3FInterfaceArgs = {
  UserID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3FSegmentArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
};


export type QueryQ3FRevenueOldArgs = {
  DownloadDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryQ3FRevenueArgs = {
  BatchNo?: Maybe<Scalars['Float']>;
  DownloadMode: Scalars['String'];
  DownloadDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryQ3FStatisticArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
};


export type QueryQ3DocumentDetailByHeaderArgs = {
  HeaderID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3MappingChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingAdvPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingRefundListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingDepositListArgs = {
  HotelID: Scalars['String'];
};


export type QueryQ3MappingTaxListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryQ3MappingDebtorListArgs = {
  HotelID: Scalars['String'];
};


export type QueryHousekeepingListingArgs = {
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryTodayBookingListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryHousekeepingDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsBlockRoomAvailableArgs = {
  BlockRoomID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RoomID: Array<Scalars['String']>;
};


export type QueryHousekeepingServiceRequestListingArgs = {
  RoomID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryHousekeepingRoomStatusListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryBillLedgerListingArgs = {
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryHousekeepingIncidentalChargeListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryHkMasterListingArgs = {
  IsBlocked?: Maybe<Scalars['Boolean']>;
  RoomStatusID?: Maybe<Scalars['String']>;
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryServiceRequestDetailArgs = {
  HotelID: Scalars['String'];
};


export type QueryServiceRequestFullListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryServiceRequestListingArgs = {
  DepartmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryServiceRequestv2ListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QuerySrLocationListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryDepartmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDepartmentInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalDepartmentListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryDeparmentCheckingArgs = {
  DepartmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryEDocTemplateListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryEDocParamsListingArgs = {
  DocType: Scalars['String'];
};


export type QueryConfirmationLetterListingArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAccountMappingChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingAdvPaymentListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingRefundListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingDepositListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCityLedgerChargesListArgs = {
  HotelID: Scalars['String'];
};


export type QueryCityLedgerDebtorListArgs = {
  HotelID: Scalars['String'];
};


export type QueryAccountMappingTaxGlListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryCityLedgerTaxGlListArgs = {
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBankAccountListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryHotelListingArgs = {
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryMenuXHotelListingArgs = {
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryHotelDetailsArgs = {
  CorporateURL?: Maybe<Scalars['String']>;
  TravelAgentURL?: Maybe<Scalars['String']>;
  GuestAppURL?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryHotelPreRegQrArgs = {
  HotelID: Scalars['String'];
};


export type QueryGuestappsettingDetailArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryIsPaymentGateWayHotelArgs = {
  HotelID: Scalars['String'];
};


export type QueryServicePaymentSettingArgs = {
  HotelID: Scalars['String'];
};


export type QueryCustomizedDocListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryIncidentalChargeListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryIncidentalChargeByIdArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalChargeInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryIncidentalChargeInRateElementArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRateElementInactiveCheckArgs = {
  Action: Scalars['String'];
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIncidentalChargeListingByHotelArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QueryLateCheckOutChargeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryFloorPlanListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  LocationID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryLocationListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryLocationFloorPlanListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryLocationInUseListingArgs = {
  LocationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRoomInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryRoomInHouseListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRoomBlockRoomListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryHkLocationMasterListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRateElementListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateAddOnListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateTypeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateListingArgs = {
  Category?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryRateInHouseListingArgs = {
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryRateEffectiveListingArgs = {
  RateID: Scalars['String'];
};


export type QueryRatePolicyInfoArgs = {
  HotelID: Scalars['String'];
};


export type QueryRateAdjustmentAuditListingArgs = {
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsRateInUseArgs = {
  RateEffectiveID: Scalars['String'];
  RateID: Scalars['String'];
};


export type QueryRegenBsQueueListingArgs = {
  HotelID?: Maybe<Scalars['String']>;
};


export type QuerySeasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySeasonListingCalenderArgs = {
  HotelID: Scalars['String'];
};


export type QuerySeasonCalendarListingArgs = {
  CalendarDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
};


export type QueryRatePolicyListingArgs = {
  DebtorType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryRatePolicyRoomTypeListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRatePolicyDetailsArgs = {
  HotelID: Scalars['String'];
  RatePolicyID: Scalars['String'];
};


export type QueryRatePolicyInUseListingArgs = {
  DebtorCategory: Scalars['String'];
  RatePolicyID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryReasonListingArgs = {
  DepartmentID?: Maybe<Scalars['String']>;
};


export type QueryCancelReasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryBookingCancelReasonListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryReaonInUseListingArgs = {
  ID: Scalars['String'];
};


export type QueryRoomListingArgs = {
  Location?: Maybe<Array<Scalars['String']>>;
  IsActive?: Maybe<Scalars['Boolean']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryBlockRoomLogListingArgs = {
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryBlockRoomLogListingV2Args = {
  HotelID: Scalars['String'];
};


export type QueryHotelRoomNumberListArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRoomStatusListingArgs = {
  HotelID: Scalars['String'];
};


export type QueryRoomTypeListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QueryRegistrationRoomTypeListingArgs = {
  RoomTypeID: Scalars['String'];
};


export type QuerySalesChannelListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  DepartmentID: Scalars['String'];
};


export type QueryHotelSalesChannelListingArgs = {
  HotelID: Scalars['String'];
};


export type QuerySalesChannelinUseListingArgs = {
  ID: Scalars['String'];
};


export type QuerySegmentListingArgs = {
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
};


export type QuerySegmentInUseListingArgs = {
  SegmentID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryGetTaxTypeListingArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxListingArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxListingDropdownArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxDetailsArgs = {
  TaxID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryIsTaxInUseArgs = {
  TaxID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryIsTaxSameCodeArgs = {
  TaxCode: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetTaxSchemeListingArgs = {
  AccountID: Scalars['String'];
};


export type QueryGetTaxSchemeDetailItemRateArgs = {
  EffectiveDate: Scalars['DateTime'];
  TaxSchemeID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetTaxSchemeDetailArgs = {
  TaxSchemeID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryIsTaxSchemeInUseArgs = {
  TaxSchemeID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryIsTaxSameNameArgs = {
  TaxSchemeName: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryTravelAgentHotelListingArgs = {
  DebtorCategory: Scalars['String'];
  Login: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryTravelAgentListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SalesChannel?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryAgentInUseLisitingArgs = {
  ID: Scalars['String'];
};


export type QueryTravelAgentDetailArgs = {
  ID: Scalars['String'];
};


export type QueryPmsTravelAgentDetailArgs = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type QueryTravelAgentContractListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryTravelAgBookingListingArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SortByOrder?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  GroupName?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  BookingDate?: Maybe<Scalars['DateTime']>;
  BookingStatus?: Maybe<Scalars['String']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
};


export type QueryTravelAgentInUseListingArgs = {
  DebtorID: Scalars['String'];
};


export type QueryAllotmentListingArgs = {
  RoomTypeID?: Maybe<Scalars['String']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  ContractID: Scalars['String'];
};


export type QueryDebtorAnalysisArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID: Scalars['String'];
};


export type QueryTravelAgentRoomRevenueArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
  HotelID: Scalars['String'];
};

export enum Race {
  Malay = 'Malay',
  Chinese = 'Chinese',
  Indian = 'Indian',
  Others = 'Others'
}

export type RateAddOnEntity = InterfaceBase & {
  __typename?: 'RateAddOnEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  Description?: Maybe<Scalars['String']>;
  PostingRythm?: Maybe<Scalars['String']>;
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateAddOnInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  Description: Scalars['String'];
  PostingRythm: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type RateAdjustmentAuditEntity = InterfaceBase & {
  __typename?: 'RateAdjustmentAuditEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  OldRateID: Scalars['String'];
  NewRateID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  Reason?: Maybe<Scalars['String']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldRoomRate?: Maybe<Scalars['Float']>;
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  Booking?: Maybe<BookingEntity>;
  Registration?: Maybe<RegistrationEntity>;
  OldRate?: Maybe<RateEntity>;
  NewRate?: Maybe<RateEntity>;
};

export type RateAdjustmentAuditInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  OldRateID: Scalars['String'];
  NewRateID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  Reason?: Maybe<Scalars['String']>;
  OldPromoCode?: Maybe<Scalars['String']>;
  OldRoomRate?: Maybe<Scalars['Float']>;
  OldDiscountAmount?: Maybe<Scalars['Float']>;
  NewPromoCode?: Maybe<Scalars['String']>;
  NewRoomRate?: Maybe<Scalars['Float']>;
  NewDiscountAmount?: Maybe<Scalars['Float']>;
};

export type RateAndDisc = {
  RoomRate?: Maybe<Scalars['Float']>;
  Discount?: Maybe<Scalars['Float']>;
};

export type RateAndDiscs = {
  RoomRate?: Maybe<Scalars['Float']>;
  Discount?: Maybe<Scalars['Float']>;
};

export enum RateCategory {
  Corporate = 'Corporate',
  Government = 'Government',
  TravelAgent = 'TravelAgent',
  Public = 'Public',
  DayUse = 'DayUse',
  HouseUse = 'HouseUse',
  Complimentary = 'Complimentary'
}

export type RateEffectiveEntity = InterfaceBase & {
  __typename?: 'RateEffectiveEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RateElementIDs?: Maybe<Array<EffectiveRateJson>>;
  RateAddOnIDs?: Maybe<Array<EffectiveRateJson>>;
  SeasonalRate?: Maybe<Array<SeasonalRateEntity>>;
  Rate?: Maybe<RateEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RateElementIDs?: Maybe<Array<EffectiveRateJsonInput>>;
  RateAddOnIDs?: Maybe<Array<EffectiveRateJsonInput>>;
};

export type RateElementEntity = InterfaceBase & {
  __typename?: 'RateElementEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  ComputationRule?: Maybe<Scalars['String']>;
  Min: Scalars['Float'];
  Max: Scalars['Float'];
  IncidentalCharge?: Maybe<IncidentalChargeEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateElementInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  Amount: Scalars['Float'];
  ComputationRule: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Min?: Maybe<Scalars['Float']>;
  Max?: Maybe<Scalars['Float']>;
};

export type RateEntity = InterfaceBase & {
  __typename?: 'RateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RateTypeID?: Maybe<Scalars['String']>;
  RateCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RateFrequency?: Maybe<Scalars['String']>;
  Category?: Maybe<RateCategory>;
  OnlineRate: Scalars['Boolean'];
  RateType?: Maybe<RateTypeEntity>;
  Hotel?: Maybe<HotelEntity>;
  RateEffective?: Maybe<Array<RateEffectiveEntity>>;
  ContractRates?: Maybe<Array<ContractRateEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
};

export type RateGainBookingRecordEntity = InterfaceBase & {
  __typename?: 'RateGainBookingRecordEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  RGStatus?: Maybe<Scalars['String']>;
};

export type RateGainBookingRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RGStatus?: Maybe<Scalars['String']>;
};

export type RateInput = {
  ID?: Maybe<Scalars['String']>;
  RateTypeID: Scalars['String'];
  HotelID: Scalars['String'];
  RateCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RateFrequency?: Maybe<Scalars['String']>;
  OnlineRate: Scalars['Boolean'];
  Category?: Maybe<RateCategory>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type RatePolicyEntity = InterfaceBase & {
  __typename?: 'RatePolicyEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Category?: Maybe<RateCategory>;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  PublishOnline?: Maybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Hotel?: Maybe<HotelEntity>;
  Package?: Maybe<Array<PackagesEntity>>;
  Pricing?: Maybe<Array<PricingEntity>>;
};

export type RatePolicyInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Category: RateCategory;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  PublishOnline?: Maybe<Scalars['Boolean']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RatePolicyResponse = {
  __typename?: 'RatePolicyResponse';
  TotalSeason?: Maybe<Scalars['Float']>;
  TotalAddOn?: Maybe<Scalars['Float']>;
  SeasonCalendarDate?: Maybe<Scalars['DateTime']>;
  RateElementDate?: Maybe<Scalars['DateTime']>;
  RateTypeDate?: Maybe<Scalars['DateTime']>;
  RateSetupDate?: Maybe<Scalars['DateTime']>;
};

export type RateRoomTypeEntity = InterfaceBase & {
  __typename?: 'RateRoomTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RateEffectiveID: Scalars['String'];
  RoomTypeID: Scalars['DateTime'];
  RateEffective?: Maybe<RateEffectiveEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type RateRoomTypeInput = {
  RateEffectiveID: Scalars['String'];
  RoomTypeID: Scalars['String'];
};

export type Rates = {
  Date?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  roomID?: Maybe<Scalars['String']>;
  RoomIndex?: Maybe<Scalars['Float']>;
};

export type Ratess = {
  Date?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  roomID?: Maybe<Scalars['String']>;
  RoomIndex?: Maybe<Scalars['Float']>;
};

export type RateTypeEntity = InterfaceBase & {
  __typename?: 'RateTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type RateTypeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type ReasonEntity = InterfaceBase & {
  __typename?: 'ReasonEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
};

export type ReasonInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RefundDepositInput = {
  DepositID: Scalars['String'];
  FolioID: Scalars['String'];
  RefundAmount: Scalars['Float'];
  ForfeitureAmount?: Maybe<Scalars['Float']>;
  Remarks: Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  RegistrationID: Scalars['String'];
};

export type RefundEntity = InterfaceTrx & {
  __typename?: 'RefundEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetails;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  IsCBPosted?: Maybe<Scalars['Boolean']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
};

export type RefundEntityInput = {
  ID?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status?: Maybe<ArTransactionStatus>;
  HotelID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  DebtorID: Scalars['String'];
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_doc_ref_invoice_id?: Maybe<Scalars['String']>;
  Commission: Scalars['Float'];
  bankDetails: BankDetailsInput;
};

export type RefundGlEntity = InterfaceTrx & {
  __typename?: 'RefundGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RefundType?: Maybe<Scalars['String']>;
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
};

export type RegAndBooking = {
  __typename?: 'RegAndBooking';
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
};

export type RegenBsLogEntity = InterfaceTrx & {
  __typename?: 'RegenBSLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
  AccountID: Scalars['String'];
  ActionType?: Maybe<Scalars['String']>;
};

export type RegenBsLogInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
  AccountID: Scalars['String'];
  ActionType?: Maybe<Scalars['String']>;
};

export type RegenBsQueueEntity = InterfaceBase & {
  __typename?: 'RegenBSQueueEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  UserID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  Status: RegenBsQueueStatus;
  Registration?: Maybe<RegistrationEntity>;
};

export type RegenBsQueueInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  UserID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BatchNo: Scalars['Float'];
  Status: RegenBsQueueStatus;
};

export enum RegenBsQueueStatus {
  Pending = 'Pending',
  Processing = 'Processing',
  Completed = 'Completed',
  Cancelled = 'Cancelled'
}

export type RegistrationEntity = InterfaceTrx & {
  __typename?: 'RegistrationEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  BookingID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  CheckInBy?: Maybe<Scalars['String']>;
  CheckOutBy?: Maybe<Scalars['String']>;
  ChargeRoomTypeID: Scalars['String'];
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  PromoCode?: Maybe<Scalars['String']>;
  RoomRate?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  PrintRate?: Maybe<Scalars['Boolean']>;
  RegistrationStatus?: Maybe<BookingStatus>;
  PassCode: Scalars['String'];
  IsRoomUpgrade?: Maybe<Scalars['Boolean']>;
  SharerGuest?: Maybe<Array<Scalars['String']>>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  Booking?: Maybe<BookingEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  RatePolicy?: Maybe<RateEntity>;
  Room?: Maybe<RoomEntity>;
  Guest?: Maybe<GuestProfileEntity>;
  ServiceRequest?: Maybe<Array<ServiceRequestEntity>>;
  BillSchedule?: Maybe<Array<BillScheduleEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  AdvancePayment?: Maybe<Array<AdvancePaymentEntity>>;
  RoomLedger?: Maybe<Array<RoomLedgerEntity>>;
  TaxLedger?: Maybe<Array<TaxLedgerEntity>>;
  PackageRedemption?: Maybe<Array<PackageRedemptionEntity>>;
  DepositLedger?: Maybe<Array<DepositLedgerEntity>>;
  BillLedger?: Maybe<Array<BillLedgerEntity>>;
  RegistrationSignature?: Maybe<GalleryEntity>;
  RoomCharges?: Maybe<Array<RoomLedgerEntity>>;
  IsPreCheckIn?: Maybe<Scalars['Boolean']>;
  IsEmailBlasted?: Maybe<Scalars['Boolean']>;
  IsChooseRoom?: Maybe<Scalars['Boolean']>;
  BookingRemark?: Maybe<Scalars['String']>;
  SharerRegistration?: Maybe<Array<RegistrationEntity>>;
  DepositSum: Scalars['Float'];
  PaymentSum: Scalars['Float'];
  AdvancePaymentSum: Scalars['Float'];
  IncidentalChargesSum: Scalars['Float'];
  IncidentalChargesCount: Scalars['Float'];
  FolioCount?: Maybe<Scalars['Float']>;
  RoomOutStanding: Scalars['Float'];
  TotalRoomPrice: Scalars['Float'];
  TotalRoomTax: Scalars['Float'];
  TotalPackagePrice: Scalars['Float'];
  TotalPrice: Scalars['Float'];
  ServiceRequestCount: Scalars['Float'];
  TotalOutStandingAmount: Scalars['Float'];
  AdvPaymentSum: Scalars['Float'];
  RoomRateDisplay: Scalars['Float'];
};

export type RegistrationInput = {
  ID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  PromoCode?: Maybe<Scalars['String']>;
  RoomRate?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  SharerNo?: Maybe<Scalars['Float']>;
  NoOfAdult?: Maybe<Scalars['Float']>;
  NoOfChild?: Maybe<Scalars['Float']>;
  NoOfInfant?: Maybe<Scalars['Float']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  Signature?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  RegistrationStatus?: Maybe<BookingStatus>;
  UserID?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['Float']>;
  AvailabilityID?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  IsPDPA?: Maybe<Scalars['Boolean']>;
  PrintRate?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CheckInBy?: Maybe<Scalars['String']>;
  CheckOutBy?: Maybe<Scalars['String']>;
  contactInput?: Maybe<Array<ContactInput>>;
  IsRoomUpgrade?: Maybe<Scalars['Boolean']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
  IsChooseRoom?: Maybe<Scalars['Boolean']>;
};

export type RegistrationResult = {
  __typename?: 'RegistrationResult';
  Room?: Maybe<RoomEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  GuestProfile?: Maybe<GuestProfileEntity>;
  Contact?: Maybe<ContactEntity>;
  RegistrationID?: Maybe<Scalars['String']>;
  Registration?: Maybe<RegistrationEntity>;
  BookingID?: Maybe<Scalars['String']>;
  Booking?: Maybe<BookingEntity>;
  BillSchedule?: Maybe<BillScheduleEntity>;
  AdvancePayment?: Maybe<AdvancePaymentEntity>;
  BillLedger?: Maybe<BillLedgerEntity>;
};

export enum Religion {
  Islam = 'Islam',
  Buddhism = 'Buddhism',
  Christianity = 'Christianity',
  Hinduism = 'Hinduism',
  Taoism = 'Taoism',
  Others = 'Others'
}

export type ReminderEntity = InterfaceTrx & {
  __typename?: 'ReminderEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  ReminderNo: Scalars['String'];
  BalanceAmt: Scalars['Float'];
  ReminderDate: Scalars['DateTime'];
  ReminderSeq: Scalars['Float'];
};

export type ReminderEntityInput = {
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  LedgerID: Scalars['String'];
  ReminderNo: Scalars['String'];
  BalanceAmt: Scalars['Float'];
  ReminderDate: Scalars['DateTime'];
  ReminderSeq: Scalars['Float'];
};

export type ReservationInput = {
  bookingId?: Maybe<Scalars['String']>;
  roomNo?: Maybe<Scalars['String']>;
  roomTypeCode?: Maybe<Scalars['String']>;
  checkIn?: Maybe<Scalars['DateTime']>;
  checkOut?: Maybe<Scalars['DateTime']>;
  nights?: Maybe<Scalars['Float']>;
  guestName?: Maybe<Scalars['String']>;
  guestID?: Maybe<Scalars['String']>;
  guestNationality?: Maybe<Scalars['String']>;
  guestCountry?: Maybe<Scalars['String']>;
  guestState?: Maybe<Scalars['String']>;
  guestCity?: Maybe<Scalars['String']>;
  guestAddress?: Maybe<Scalars['String']>;
  guestTelNo?: Maybe<Scalars['String']>;
  guestEmail?: Maybe<Scalars['String']>;
  noOfAdult?: Maybe<Scalars['Float']>;
  noOfChildren?: Maybe<Scalars['Float']>;
  deposit?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
};

export type RevenueAnalysis = {
  __typename?: 'RevenueAnalysis';
  HotelDate?: Maybe<Scalars['DateTime']>;
  OCCRoom?: Maybe<Scalars['Float']>;
  DueIn?: Maybe<Scalars['Float']>;
  DueOut?: Maybe<Scalars['Float']>;
  ExpOcc?: Maybe<Scalars['Float']>;
  OCC?: Maybe<Scalars['Float']>;
  NoOfPax?: Maybe<Scalars['Float']>;
  BookedRoom?: Maybe<Scalars['Float']>;
  AvailableRoom?: Maybe<Scalars['Float']>;
  DayUse?: Maybe<Scalars['Float']>;
  CompUse?: Maybe<Scalars['Float']>;
  RoomRevenue?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  OtherRevenue?: Maybe<Scalars['Float']>;
  Arr?: Maybe<Scalars['Float']>;
  OOI?: Maybe<Scalars['Float']>;
  OOO?: Maybe<Scalars['Float']>;
};

export type RevenueGuestLedgerAcctInput = {
  Div?: Maybe<Scalars['String']>;
  Dept?: Maybe<Scalars['String']>;
  Acct?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type RevenueInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Q3FAcct?: Maybe<Q3RevenueAcctJsonInput>;
  GuestLedgerAcct?: Maybe<Scalars['JSON']>;
};

export type RevenueMappingInput = {
  Charges?: Maybe<Array<RevenueInput>>;
  Payment?: Maybe<Array<RevenueInput>>;
  Refund?: Maybe<Array<RevenueInput>>;
  Deposit?: Maybe<Array<RevenueInput>>;
  AdvancePayment?: Maybe<Array<RevenueInput>>;
  Tax?: Maybe<Array<RevenueTaxInput>>;
  DebtorType?: Maybe<Array<RevenueInput>>;
  RefundDebtor?: Maybe<Array<RevenueInput>>;
  OfficialReceipt?: Maybe<Array<RevenueInput>>;
};

export type RevenueTaxInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
  Q3FAcct?: Maybe<Q3RevenueAcctJsonInput>;
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm?: Maybe<Array<RolePermAsgEntity>>;
  MenuOption?: Maybe<Scalars['String']>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  menuOption?: Maybe<Scalars['String']>;
};

export type RolePermAsgEntity = AuditEntity & {
  __typename?: 'RolePermAsgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoomDiscrepancyLogEntity = InterfaceTrx & {
  __typename?: 'RoomDiscrepancyLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomStatusID?: Maybe<Scalars['String']>;
  HouseKeepingStatusID?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  DiscrepancyReason: DiscrepancyReason;
  AttendedBy: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  IsSolved?: Maybe<Scalars['Boolean']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
  RoomStatus?: Maybe<RoomStatusEntity>;
  HouseKeepingStatus?: Maybe<RoomStatusEntity>;
};

export type RoomDiscrepancyLogInput = {
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  DiscrepancyReason: DiscrepancyReason;
  AttendedBy: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  IsSolved?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomEntity = InterfaceBase & {
  __typename?: 'RoomEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RoomNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  Location?: Maybe<LocationEntity>;
  BlockRoomLog?: Maybe<Array<BlockRoomLogEntity>>;
  HouseKeepingLog?: Maybe<Array<HouseKeepingLogEntity>>;
  RoomDiscrepancyLog?: Maybe<Array<RoomDiscrepancyLogEntity>>;
  PrincipalRegistration?: Maybe<Array<RegistrationEntity>>;
  Registrations?: Maybe<Array<RegistrationEntity>>;
  FloorPlanID?: Maybe<Scalars['String']>;
  FloorPlan?: Maybe<Array<LocationFloorPlanEntity>>;
  TodayBookingNo?: Maybe<Array<RegistrationEntity>>;
  RoomStatus?: Maybe<RoomStatusEntity>;
  RoomAssignmentStatus?: Maybe<RoomStatusEntity>;
  ServiceRequestCount: Scalars['Float'];
  RoomStatusCode: Scalars['String'];
  HKRoomStatus: Scalars['JSON'];
};


export type RoomEntityRoomAssignmentStatusArgs = {
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
};

export type RoomInfoResponse = {
  __typename?: 'RoomInfoResponse';
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RateDescription?: Maybe<Scalars['String']>;
  TrxDate?: Maybe<Scalars['DateTime']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  RegistrationID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
  BookingRemark?: Maybe<Scalars['String']>;
  BillingInstruction?: Maybe<Scalars['String']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  LedgerInfo?: Maybe<Array<LedgerInfoOutput>>;
  LedgerTax?: Maybe<Array<LedgerOutput>>;
};

export type RoomInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RoomNo: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  RoomSize: Scalars['Float'];
  DisplaySequence?: Maybe<Scalars['Float']>;
  FloorPlanID?: Maybe<Scalars['String']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomLedgerEntity = InterfaceTrx & {
  __typename?: 'RoomLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  RateID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  ReversedRoomLedgerID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TrxDescription?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType?: Maybe<TransactionType>;
  TransactionID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  SplitGroup?: Maybe<Scalars['Float']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  RefRoomLedgerID?: Maybe<Scalars['String']>;
  FromRegistration?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  LedgerID?: Maybe<Scalars['String']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Transaction?: Maybe<IncidentalChargeEntity>;
  TaxRoomLedger?: Maybe<Array<RoomLedgerTaxEntity>>;
};

export type RoomLedgerInput = {
  ID: Scalars['String'];
  FolioID: Scalars['String'];
  RegistrationID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RateID?: Maybe<Scalars['String']>;
  ReversedRoomLedgerID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ChargeType: ChargeType;
  TransactionType: TransactionType;
  TransactionID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  RefRoomLedgerID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomLedgerTaxEntity = InterfaceTrx & {
  __typename?: 'RoomLedgerTaxEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  TaxSchemeDetailID: Scalars['String'];
  TaxID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  RoomLedgerID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  RoomLedger?: Maybe<RoomLedgerEntity>;
  TaxInfo?: Maybe<TaxEntity>;
};

export type RoomSaleOptimizationOutput = {
  __typename?: 'RoomSaleOptimizationOutput';
  RoomType?: Maybe<RoomTypeEntity>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
};

export enum RoomStatus {
  VacantClean = 'Vacant_Clean',
  VacantDirty = 'Vacant_Dirty',
  OccupiedClean = 'Occupied_Clean',
  OccupiedDirty = 'Occupied_Dirty',
  VacantInspection = 'Vacant_Inspection',
  VacantReady = 'Vacant_Ready',
  OutOfOrder = 'Out_of_Order',
  OutOfInventory = 'Out_of_Inventory'
}

export type RoomStatusEntity = InterfaceBase & {
  __typename?: 'RoomStatusEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Description: RoomStatus;
  Code?: Maybe<Scalars['String']>;
  VacantOrOccupied?: Maybe<Scalars['String']>;
  CleanOrDirty?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
};

export type RoomStatusInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description: RoomStatus;
  Code: Scalars['String'];
  VacantOrOccupied: Scalars['String'];
  CleanOrDirty: Scalars['String'];
  RGBColor?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomStatusMovementEntity = InterfaceTrx & {
  __typename?: 'RoomStatusMovementEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  HotelDate?: Maybe<Scalars['DateTime']>;
  RoomID: Scalars['String'];
  RoomNo: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RoomType: Scalars['String'];
  LocationID: Scalars['String'];
  Location: Scalars['String'];
  Name: Scalars['String'];
  OldStatusID: Scalars['String'];
  OldStatus: Scalars['String'];
  NewStatusID: Scalars['String'];
  NewStatus: Scalars['String'];
};

export type RoomStatusMovementInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  HotelDate: Scalars['DateTime'];
  RoomID: Scalars['String'];
  RoomNo?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  RoomType?: Maybe<Scalars['String']>;
  LocationID: Scalars['String'];
  Location?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OldStatusID?: Maybe<Scalars['String']>;
  NewStatusID?: Maybe<Scalars['String']>;
  OldStatus?: Maybe<Scalars['String']>;
  NewStatus?: Maybe<Scalars['String']>;
};

export type RoomTransferLogEntity = InterfaceTrx & {
  __typename?: 'RoomTransferLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  FromRoomID: Scalars['String'];
  FromRoomTypeID?: Maybe<Scalars['String']>;
  FromRoomType?: Maybe<Scalars['String']>;
  Upgrade?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  Room?: Maybe<RoomEntity>;
};

export type RoomTransferLogInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  HotelDate: Scalars['DateTime'];
  RegistrationID: Scalars['String'];
  FromRoomID: Scalars['String'];
  FromRoomTypeID?: Maybe<Scalars['String']>;
  FromRoomType?: Maybe<Scalars['String']>;
  Upgrade?: Maybe<Scalars['String']>;
};

export type RoomTypeAmenitiesEntity = InterfaceBase & {
  __typename?: 'RoomTypeAmenitiesEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  Name: Scalars['String'];
  GalleryID: Scalars['String'];
  IsSelected: Scalars['Boolean'];
  Hotel?: Maybe<HotelEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type RoomTypeAmenitiesInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  GalleryID?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomTypeEntity = InterfaceBase & {
  __typename?: 'RoomTypeEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  PropertyType?: Maybe<PropertyType>;
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  MeasureType?: Maybe<MeasureType>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  Hotel?: Maybe<HotelEntity>;
  RoomTypeAmenities?: Maybe<Array<RoomTypeAmenitiesEntity>>;
  RoomTypeGallery?: Maybe<Array<RoomTypeGalleryEntity>>;
  Room?: Maybe<Array<RoomEntity>>;
  Availability?: Maybe<Array<AvailabilityEntity>>;
  Price?: Maybe<Array<PricingEntity>>;
  ContractAllotment?: Maybe<Array<ContractAllotmentEntity>>;
  RoomTypeAvailability?: Maybe<Array<AvailabilityOutput>>;
  TotalPackages?: Maybe<Array<TotalPackagesResponse>>;
  Overbooking: Scalars['Float'];
};

export type RoomTypeGalleryEntity = InterfaceBase & {
  __typename?: 'RoomTypeGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoomTypeID: Scalars['String'];
  GalleryID: Scalars['String'];
  RoomType?: Maybe<RoomTypeEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type RoomTypeGalleryInput = {
  ID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  GalleryID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RoomTypeInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  MaxGuest?: Maybe<Scalars['Float']>;
  RoomSize?: Maybe<Scalars['Float']>;
  HouseLimit?: Maybe<Scalars['Float']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  PropertyType?: Maybe<PropertyType>;
  MeasureType?: Maybe<MeasureType>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Overbooking: Scalars['Float'];
};

export type RoomTypesListOutput = {
  __typename?: 'RoomTypesListOutput';
  ID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TotalRoom?: Maybe<Scalars['Float']>;
  Overbooking: Scalars['Float'];
  RoomTypeAvailability?: Maybe<Array<AvailabilityOutput>>;
};

export type RoomUpgradeInput = {
  RegistrationIDs?: Maybe<Array<Scalars['String']>>;
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  OldRoomID?: Maybe<Scalars['String']>;
  PromoCode?: Maybe<Scalars['String']>;
  FreeUpgrade?: Maybe<Scalars['Boolean']>;
  BookingID?: Maybe<Scalars['String']>;
  OldRoomTypeID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type SalesChannelEntity = InterfaceBase & {
  __typename?: 'SalesChannelEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Department?: Maybe<DepartmentEntity>;
};

export type SalesChannelInput = {
  ID?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SchBillingInput = {
  AccountID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  BillNo?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  BillDate?: Maybe<Scalars['DateTime']>;
  BillAmount?: Maybe<Scalars['Float']>;
  DebtorID?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ScheduleBillingEntity = {
  __typename?: 'ScheduleBillingEntity';
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  BillNo: Scalars['String'];
  CardNo: Scalars['String'];
  BillDate: Scalars['DateTime'];
  BillAmount: Scalars['Float'];
  DebtorID: Scalars['String'];
  IsPosted: Scalars['Boolean'];
  IsGLPosted: Scalars['Boolean'];
};

export type ScheduleBillingInput = {
  BookingID?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  InvoiceDate?: Maybe<Scalars['DateTime']>;
  FolioAmount: Scalars['Float'];
  Description?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
};

export type ScheduleBillingResponse = {
  __typename?: 'ScheduleBillingResponse';
  CreatedBy?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  GuestEmail?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  ActualFolioNo?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  IsGroupBooking?: Maybe<Scalars['Boolean']>;
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
};

export type ScheduleBillingResponse2 = {
  __typename?: 'ScheduleBillingResponse2';
  BookingNo?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestName?: Maybe<Scalars['String']>;
  GuestEmail?: Maybe<Scalars['String']>;
  DebtorName?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FolioAmount?: Maybe<Scalars['Float']>;
  RoomNo?: Maybe<Scalars['String']>;
  RoomType?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  FolioID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  IsPosted?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
};

export type SearchGuestResponse = {
  __typename?: 'SearchGuestResponse';
  GuestID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  NRIC?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
};

export type SeasonalRateEntity = InterfaceBase & {
  __typename?: 'SeasonalRateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SeasonID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  RateEffectiveID: Scalars['String'];
  DynamicRate: Array<DynamicRateJson>;
  StandardRate: Scalars['Float'];
  RateEffective?: Maybe<RateEffectiveEntity>;
  Season?: Maybe<SeasonEntity>;
  RoomType?: Maybe<RoomTypeEntity>;
};

export type SeasonalRateInput = {
  SeasonID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  SeasonRate: SeasonRateInput;
};

export type SeasonCalendarEntity = InterfaceBase & {
  __typename?: 'SeasonCalendarEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  SeasonID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  SeasonType: SeasonType;
  Season?: Maybe<SeasonEntity>;
  Hotel?: Maybe<HotelEntity>;
};

export type SeasonCalendarInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  SeasonID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  SeasonType?: Maybe<SeasonType>;
};

export type SeasonEntity = InterfaceBase & {
  __typename?: 'SeasonEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  Hotel?: Maybe<HotelEntity>;
  SeasonalRate?: Maybe<Array<SeasonalRateEntity>>;
};


export type SeasonEntitySeasonalRateArgs = {
  RateEffectiveID?: Maybe<Scalars['String']>;
};

export type SeasonInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RGBColor?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type SeasonRateInput = {
  StandardRate: Scalars['Float'];
  DynamicRate: Array<DynamicRateJsonInput>;
};

export enum SeasonType {
  Peak = 'Peak',
  SuperPeak = 'SuperPeak',
  NonPeak = 'NonPeak'
}

export type SegmentEntity = InterfaceBase & {
  __typename?: 'SegmentEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  Q3MarketSegmentMapping?: Maybe<Q3MarketSegmentMapping>;
  Hotel?: Maybe<HotelEntity>;
  StayViewIcon?: Maybe<Scalars['String']>;
};

export type SegmentInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DisplaySequence?: Maybe<Scalars['Float']>;
  StayViewIcon?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Q3MarketSegmentMapping?: Maybe<Q3MarketSegmentMappingInput>;
};

export type SegmentReportOutput = {
  __typename?: 'SegmentReportOutput';
  SegmentID?: Maybe<Scalars['String']>;
  SegmentName?: Maybe<Scalars['String']>;
  SegmentYRoomCount?: Maybe<Scalars['Float']>;
  SegmentYGuestCount?: Maybe<Scalars['Float']>;
  SegmentYRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentYOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentYARR?: Maybe<Scalars['Float']>;
  SegmentMRoomCount?: Maybe<Scalars['Float']>;
  SegmentMGuestCount?: Maybe<Scalars['Float']>;
  SegmentMRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentMOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentMARR?: Maybe<Scalars['Float']>;
  SegmentDRoomCount?: Maybe<Scalars['Float']>;
  SegmentDGuestCount?: Maybe<Scalars['Float']>;
  SegmentDRoomRevenue?: Maybe<Scalars['Float']>;
  SegmentDOtherRevenue?: Maybe<Scalars['Float']>;
  SegmentDARR?: Maybe<Scalars['Float']>;
};

export type ServiceRequestEntity = InterfaceTrx & {
  __typename?: 'ServiceRequestEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DepartmentID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RequestBy: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  EstimatedCompletion: Scalars['DateTime'];
  GalleryID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status: ServiceRequestStatus;
  RegistrationID?: Maybe<Scalars['String']>;
  ClosedDate?: Maybe<Scalars['DateTime']>;
  Hotel?: Maybe<HotelEntity>;
  Department?: Maybe<DepartmentEntity>;
  Room?: Maybe<RoomEntity>;
  Gallery?: Maybe<GalleryEntity>;
  Registration?: Maybe<RegistrationEntity>;
  ServiceRequestGallery?: Maybe<Array<ServiceRequestGalleryEntity>>;
};

export type ServiceRequestGalleryEntity = InterfaceBase & {
  __typename?: 'ServiceRequestGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ServiceRequestID: Scalars['String'];
  GalleryID: Scalars['String'];
  ServiceRequest?: Maybe<ServiceRequestEntity>;
  Gallery?: Maybe<GalleryEntity>;
};

export type ServiceRequestInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DepartmentID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RequestBy: Scalars['String'];
  Title?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  EstimatedCompletion?: Maybe<Scalars['DateTime']>;
  GalleryID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status: ServiceRequestStatus;
  RegistrationID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ServiceRequestStatus {
  Open = 'Open',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Cancelled = 'Cancelled'
}

export type SharedGalleryEntity = InterfaceBase & {
  __typename?: 'SharedGalleryEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  Gallery?: Maybe<GalleryEntity>;
};

export type SharedGalleryInput = {
  ID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  GalleryID: Scalars['String'];
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum Source {
  WalkIn = 'Walk_In',
  Online = 'Online',
  Booking = 'Booking',
  Corporate = 'Corporate',
  Government = 'Government',
  TravelAgent = 'Travel_Agent',
  ChannelManager = 'Channel_Manager',
  Alliance = 'Alliance',
  WhatsApp = 'WhatsApp',
  Telephone = 'Telephone',
  Email = 'Email',
  Membership = 'Membership',
  Ota = 'OTA',
  Kiosk = 'Kiosk'
}

export type SourceReportOutput = {
  __typename?: 'SourceReportOutput';
  SourceName?: Maybe<Scalars['String']>;
  SourceYRoomCount?: Maybe<Scalars['Float']>;
  SourceYGuestCount?: Maybe<Scalars['Float']>;
  SourceYRoomRevenue?: Maybe<Scalars['Float']>;
  SourceYOtherRevenue?: Maybe<Scalars['Float']>;
  SourceYARR?: Maybe<Scalars['Float']>;
  SourceMRoomCount?: Maybe<Scalars['Float']>;
  SourceMGuestCount?: Maybe<Scalars['Float']>;
  SourceMRoomRevenue?: Maybe<Scalars['Float']>;
  SourceMOtherRevenue?: Maybe<Scalars['Float']>;
  SourceMARR?: Maybe<Scalars['Float']>;
  SourceDRoomCount?: Maybe<Scalars['Float']>;
  SourceDGuestCount?: Maybe<Scalars['Float']>;
  SourceDRoomRevenue?: Maybe<Scalars['Float']>;
  SourceDOtherRevenue?: Maybe<Scalars['Float']>;
  SourceDARR?: Maybe<Scalars['Float']>;
};

export type SpecialRequestInput = {
  ID?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type StaahBookingRecordEntity = InterfaceBase & {
  __typename?: 'StaahBookingRecordEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
};

export type StaahBookingRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type StandardAuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export enum State {
  Johor = 'Johor',
  Kedah = 'Kedah',
  Kelantan = 'Kelantan',
  KualaLumpur = 'Kuala_Lumpur',
  Labuan = 'Labuan',
  Malacca = 'Malacca',
  NegeriSembilan = 'Negeri_Sembilan',
  Pahang = 'Pahang',
  Penang = 'Penang',
  Perak = 'Perak',
  Perlis = 'Perlis',
  Putrajaya = 'Putrajaya',
  Sabah = 'Sabah',
  Sarawak = 'Sarawak',
  Selangor = 'Selangor',
  Terengganu = 'Terengganu'
}

export type StateEntity = {
  __typename?: 'stateEntity';
  ID: Scalars['String'];
  name: Scalars['String'];
  countryID: Scalars['String'];
  einv_code?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  RoomAssignmentNotify: Array<RoomEntity>;
  HotelNotify: Array<HotelNotificationEntity>;
  NewNotification: NotificationEntity;
};


export type SubscriptionRoomAssignmentNotifyArgs = {
  RoomID?: Maybe<Scalars['String']>;
  IsBooking?: Maybe<Scalars['Boolean']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate: Scalars['DateTime'];
  RoomTypeID: Scalars['String'];
  HotelID: Scalars['String'];
};


export type SubscriptionHotelNotifyArgs = {
  NotificationType?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
};


export type SubscriptionNewNotificationArgs = {
  HotelID: Scalars['String'];
  registrationToken: Scalars['String'];
};

export type SubscriptionEntity = InterfaceTrx & {
  __typename?: 'SubscriptionEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  LoyaltyAppURL: Scalars['String'];
  LoyaltyAppGroupName: Scalars['String'];
  LoyaltyAppLogo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  Gallery?: Maybe<GalleryEntity>;
};

export type SuspendFolioResponse = {
  __typename?: 'SuspendFolioResponse';
  TotalCount?: Maybe<Scalars['Float']>;
  Listing?: Maybe<Array<FolioEntity>>;
};

export type TaxDetailEntity = {
  __typename?: 'taxDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxId: Scalars['String'];
  tax: TaxEntity;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
  commonStatus: Scalars['String'];
};

export type TaxDetailInput = {
  id?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  rate: Scalars['Float'];
};

export type TaxEffectiveDateEntity = InterfaceBase & {
  __typename?: 'TaxEffectiveDateEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  Name?: Maybe<Scalars['String']>;
};

export type TaxEffectiveDateInput = {
  ID: Scalars['String'];
  PlatformID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  IsFixAmount?: Maybe<Scalars['Boolean']>;
  IsTaxable?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TaxEntity = {
  __typename?: 'taxEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  schemeTypeId: Scalars['String'];
  schemeType: TaxSchemeTypeEntity;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  commonStatus: Scalars['String'];
  taxDetail: Array<TaxDetailEntity>;
  taxSchemeDetailItem: Array<TaxSchemeDetailItemEntity>;
};

export type TaxGlEntity = InterfaceTrx & {
  __typename?: 'TaxGLEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxID: Scalars['String'];
  SchemeTypeID: Scalars['String'];
  HTGLAcct?: Maybe<GuestLedgerAcctJson>;
  CityLedgerGLAcct?: Maybe<GuestLedgerAcctJson>;
  Q3RevenueMapping?: Maybe<Q3RevenueAcctJson>;
  Hotel?: Maybe<HotelEntity>;
  tax?: Maybe<TaxEntity>;
  taxSchemeType?: Maybe<TaxSchemeTypeEntity>;
};

export type TaxGlInput = {
  Description?: Maybe<Scalars['String']>;
  DescriptionType?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  TaxID?: Maybe<Scalars['String']>;
  GL?: Maybe<GuestLedgerAcctJsonInput>;
};

export enum TaxIdentifier {
  Room = 'Room',
  Incidental = 'Incidental'
}

export type TaxInput = {
  HotelID?: Maybe<Scalars['String']>;
  TaxIdentifier: TaxIdentifier;
  Tax?: Maybe<Scalars['Boolean']>;
  ServiceCharge?: Maybe<Scalars['Boolean']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  DiscountAmount?: Maybe<Scalars['Float']>;
  IncidentalChargeID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
};

export type TaxLedgerEntity = InterfaceTrx & {
  __typename?: 'TaxLedgerEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReversedTaxLedgerID?: Maybe<Scalars['String']>;
  RefTaxLedgerID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TaxType: TaxType;
  TrxDate: Scalars['DateTime'];
  TaxAmount?: Maybe<Scalars['Float']>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  Folio?: Maybe<FolioEntity>;
  Registration?: Maybe<RegistrationEntity>;
  Qty?: Maybe<Scalars['Float']>;
  IsAdjustment?: Maybe<Scalars['Boolean']>;
  FromRegistration?: Maybe<Scalars['String']>;
  IsCNAdjusted?: Maybe<Scalars['Boolean']>;
  TaxDescription?: Maybe<Scalars['String']>;
};

export type TaxLedgerInput = {
  ID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  ReversedTaxLedgerID?: Maybe<Scalars['String']>;
  RefTaxLedgerID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  Reason: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TaxDescription?: Maybe<Scalars['String']>;
  TaxType: TaxType;
  TrxDate: Scalars['DateTime'];
  TaxAmount?: Maybe<Scalars['Float']>;
  UserID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Qty?: Maybe<Scalars['Float']>;
  IsAdjustment?: Maybe<Scalars['Boolean']>;
};

export type TaxListingOutput = {
  __typename?: 'TaxListingOutput';
  tax_id: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  class_type: Scalars['String'];
  tax_status: Scalars['String'];
  effective_date: Scalars['DateTime'];
  rate: Scalars['String'];
  rn?: Maybe<Scalars['String']>;
  tax_type?: Maybe<Scalars['String']>;
  scheme_type_id?: Maybe<Scalars['String']>;
};

export type TaxOnSeq = {
  __typename?: 'TaxOnSeq';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxOnSeqInput = {
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

/** JsonType */
export type TaxOnSeqObjects = {
  __typename?: 'taxOnSeqObjects';
  taxSchemeDetailItemId: Scalars['String'];
  seqNo: Scalars['Float'];
};

export type TaxOutput = {
  __typename?: 'TaxOutput';
  DiscountAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  HotelTaxOutput?: Maybe<Array<HotelTaxOutput>>;
};

export type TaxPolicyInput = {
  createdBy?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  classType: Scalars['String'];
  isClaimable: Scalars['Boolean'];
  schemeTypeId: Scalars['String'];
  taxDetail: Array<TaxDetailInput>;
};

export type TaxReportOutput = {
  __typename?: 'TaxReportOutput';
  DepartmentName?: Maybe<Scalars['String']>;
  TransactionName?: Maybe<Scalars['String']>;
  TransactionDAmount?: Maybe<Scalars['Float']>;
  TransactionMAmount?: Maybe<Scalars['Float']>;
  TransactionYAmount?: Maybe<Scalars['Float']>;
};

export type TaxSchemeDetailEntity = {
  __typename?: 'taxSchemeDetailEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeId: Scalars['String'];
  taxScheme: TaxSchemeEntity;
  effectiveDate: Scalars['DateTime'];
  commonStatus: Scalars['String'];
  taxSchemeDetailItems?: Maybe<Array<TaxSchemeDetailItemEntity>>;
};

export type TaxSchemeDetailInput = {
  detailId?: Maybe<Scalars['String']>;
  effectiveDate: Scalars['DateTime'];
  taxSchemeDetailItem: Array<TaxSchemeDetailItemInput>;
};

export type TaxSchemeDetailItemEntity = {
  __typename?: 'taxSchemeDetailItemEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  taxSchemeDetailId: Scalars['String'];
  taxSchemeDetail: TaxSchemeDetailEntity;
  taxId: Scalars['String'];
  tax: TaxEntity;
  computeMethod: Scalars['String'];
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  commonStatus: Scalars['String'];
};

export type TaxSchemeDetailItemInput = {
  detailItemId?: Maybe<Scalars['String']>;
  detailId?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  classType: Scalars['String'];
  computeMethod: ComputeMethod;
  seqNo?: Maybe<Scalars['Float']>;
  taxOnSeq?: Maybe<Array<TaxOnSeqInput>>;
};

export type TaxSchemeDetailItemRateOutput = {
  __typename?: 'TaxSchemeDetailItemRateOutput';
  isExclusive: Scalars['Boolean'];
  taxId: Scalars['String'];
  taxRate: Scalars['Float'];
  computeMethod: Scalars['String'];
  seqNo: Scalars['String'];
  taxOnSeq?: Maybe<Array<TaxOnSeqObjects>>;
  taxSchemeDetailId: Scalars['String'];
};

export type TaxSchemeDetailOutput = {
  __typename?: 'TaxSchemeDetailOutput';
  taxId: Scalars['String'];
  taxRate: Scalars['Float'];
  taxCode: Scalars['String'];
  taxType: Scalars['String'];
  taxSeqNo: Scalars['String'];
};

export type TaxSchemeEntity = {
  __typename?: 'taxSchemeEntity';
  id: Scalars['String'];
  accountId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  commonStatus: Scalars['String'];
  taxSchemeDetails?: Maybe<Array<TaxSchemeDetailEntity>>;
};

export type TaxSchemeInput = {
  createdBy: Scalars['String'];
  modBy: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  taxSchemeDetail?: Maybe<Array<TaxSchemeDetailInput>>;
};

export type TaxSchemeTypeEntity = {
  __typename?: 'taxSchemeTypeEntity';
  id: Scalars['String'];
  code: Scalars['String'];
  name: Scalars['String'];
  softwareCode: Scalars['String'];
  commonStatus: Scalars['String'];
  tax?: Maybe<Array<TaxEntity>>;
};

export enum TaxType {
  HeritageTax = 'HeritageTax',
  TourismTax = 'TourismTax',
  Levy = 'Levy',
  GovTax = 'GovTax',
  ServiceCharge = 'ServiceCharge',
  StateFund = 'StateFund'
}

export type Test = InterfaceTrx & {
  __typename?: 'test';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNo: Scalars['String'];
  ReferenceNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DocumentDate: Scalars['DateTime'];
  Amount: Scalars['Float'];
  Status: ArTransactionStatus;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DebtorID: Scalars['String'];
  Attachment?: Maybe<Scalars['String']>;
  Currency?: Maybe<Currency>;
  CurrencyRate?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  ReasonCode?: Maybe<Scalars['String']>;
  VoidDate?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  LedgerTrx: Array<InvoiceTrxEntity>;
  IsGLPosted?: Maybe<Scalars['Boolean']>;
  IsPostedDoc?: Maybe<Scalars['Boolean']>;
  SourceID?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  GroupDocumentNo?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
  einvoice_directsend?: Maybe<Scalars['Boolean']>;
  is_einvoice?: Maybe<Scalars['Boolean']>;
  einvoice_irbm_uniqueID?: Maybe<Scalars['String']>;
  einvoice_status?: Maybe<Scalars['String']>;
  einvoice_buyer_name?: Maybe<Scalars['String']>;
  einvoice_buyer_tin?: Maybe<Scalars['String']>;
  einvoice_buyer_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_tax_regno?: Maybe<Scalars['String']>;
  einvoice_buyer_address?: Maybe<Scalars['String']>;
  einvoice_buyer_post_code?: Maybe<Scalars['String']>;
  einvoice_buyer_city?: Maybe<Scalars['String']>;
  einvoice_buyer_state?: Maybe<Scalars['String']>;
  einvoice_buyer_country?: Maybe<Scalars['String']>;
  einvoice_buyer_contact_no?: Maybe<Scalars['String']>;
  einvoice_buyer_email?: Maybe<Scalars['String']>;
  einvoice_buyer_identity_type?: Maybe<Scalars['String']>;
  einvoice_qr_url?: Maybe<Scalars['String']>;
  CityLedgerAttachment?: Maybe<Array<CityLedgerAttachmentEntity>>;
  OutstandingAmount: Scalars['Float'];
  Allocation: Array<AllocationEntity>;
  Booking?: Maybe<Scalars['JSON']>;
};

export type TestCountry = {
  __typename?: 'TestCountry';
  con: Country;
};

export type TestState = {
  __typename?: 'TestState';
  con: State;
};

export type TmrGroupOutput = {
  __typename?: 'TmrGroupOutput';
  GroupName?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type TotalPackagesResponse = {
  __typename?: 'TotalPackagesResponse';
  PackageName?: Maybe<Scalars['String']>;
  Price?: Maybe<Scalars['Float']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  PriceID?: Maybe<Scalars['String']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type TransactionTransferLogEntity = InterfaceTrx & {
  __typename?: 'TransactionTransferLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  SourceTable?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  TrxType?: Maybe<Scalars['String']>;
  TrxAmount?: Maybe<Scalars['Float']>;
  BaseAmount?: Maybe<Scalars['Float']>;
  TaxAmount?: Maybe<Scalars['Float']>;
  ServiceCharge?: Maybe<Scalars['Float']>;
  FromRegistrationID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  FromRoomID?: Maybe<Scalars['String']>;
  RoomID?: Maybe<Scalars['String']>;
  FromFolioType?: Maybe<Scalars['String']>;
  FolioType?: Maybe<Scalars['String']>;
  FromFolioID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  FromGuestID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type TransactionTransferLogInput = {
  ID?: Maybe<Scalars['String']>;
  SourceTable: Scalars['String'];
  SourceID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  TrxType: Scalars['String'];
  TrxAmount: Scalars['Float'];
  BaseAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  ServiceCharge: Scalars['Float'];
  FromRegistrationID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
  FromRoomID: Scalars['String'];
  RoomID: Scalars['String'];
  FromFolioType: Scalars['String'];
  FolioType: Scalars['String'];
  FromFolioID: Scalars['String'];
  FolioID: Scalars['String'];
  FromGuestID: Scalars['String'];
  GuestID: Scalars['String'];
  Description: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  IsActive: Scalars['Boolean'];
  CreatedDT: Scalars['DateTime'];
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum TransactionType {
  Rate = 'Rate',
  Package = 'Package',
  TourismTax = 'TourismTax'
}

export type TransferAxLogEntity = InterfaceTrx & {
  __typename?: 'TransferAXLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
};

export type TtLockLogEntity = InterfaceTrx & {
  __typename?: 'TTLockLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};


export type UserAccessInfoJson = {
  __typename?: 'UserAccessInfoJson';
  OpenFolio?: Maybe<Array<Scalars['String']>>;
};

export type UserAccessInfoJsonInput = {
  OpenFolio?: Maybe<Array<Scalars['String']>>;
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserOnlineEntity = InterfaceBase & {
  __typename?: 'UserOnlineEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CommonStatus: Scalars['String'];
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
  SoftwareCode?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  IPAddress?: Maybe<Scalars['String']>;
  LastLogin: Scalars['DateTime'];
  LastLogout?: Maybe<Scalars['DateTime']>;
  SocketID?: Maybe<Scalars['String']>;
  UserAccessInfo?: Maybe<UserAccessInfoJson>;
};

export type UserOnlineInput = {
  ID?: Maybe<Scalars['String']>;
  CommonStatus?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  SoftwareCode?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  LastLogin?: Maybe<Scalars['DateTime']>;
  LastLogout?: Maybe<Scalars['DateTime']>;
  SocketID?: Maybe<Scalars['String']>;
  UserAccessInfo?: Maybe<UserAccessInfoJsonInput>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type VendFunBookingRecordEntity = InterfaceBase & {
  __typename?: 'VendFunBookingRecordEntity';
  ID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
};

export type VendFunBookingRecordInput = {
  ID?: Maybe<Scalars['String']>;
  ReservationPayload?: Maybe<Scalars['String']>;
  Processed?: Maybe<Scalars['Boolean']>;
  ProcessCount?: Maybe<Scalars['Float']>;
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type VipInHouseOutput = {
  __typename?: 'VIPInHouseOutput';
  GuestName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  NoOfGuest?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  SpecialRequest?: Maybe<Scalars['String']>;
};

export type WhRates = {
  Date?: Maybe<Scalars['DateTime']>;
  Rate?: Maybe<Scalars['Float']>;
  roomID?: Maybe<Scalars['String']>;
};

export type XPossibleLogEntity = InterfaceTrx & {
  __typename?: 'XPossibleLogEntity';
  ID: Scalars['String'];
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT: Scalars['DateTime'];
  ModifiedBy?: Maybe<Scalars['String']>;
  RequestBody: Scalars['String'];
  ResponseBody: Scalars['String'];
  LogData: Scalars['String'];
  ActionType: Scalars['String'];
  RoomNo: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
};

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'email' | 'avatar' | 'contactNo' | 'department' | 'accountID' | 'jobTitle' | 'lastestAccessedEntity' | 'superUser'>
  ) }
);

export type GetUserByUsernameQueryVariables = Exact<{
  UserName: Scalars['String'];
}>;


export type GetUserByUsernameQuery = (
  { __typename?: 'Query' }
  & { GetUserByUsername: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'userName'>
  ) }
);

export type UpdateLastestAccessHotelQueryVariables = Exact<{
  userIDs: Scalars['String'];
  hotelID: Scalars['String'];
}>;


export type UpdateLastestAccessHotelQuery = (
  { __typename?: 'Query' }
  & { updateLastestAccessHotel: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'email' | 'contactNo' | 'department' | 'accountID' | 'jobTitle' | 'lastestAccessedEntity'>
  ) }
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  loginId: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'ID' | 'name' | 'email' | 'avatar' | 'contactNo' | 'superUser' | 'department' | 'accountID' | 'jobTitle' | 'lastestAccessedEntity'>
    ) }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'name'>
  ) }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type CreatePasswordMutationVariables = Exact<{
  softwareCode: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
  confirmPassword: Scalars['String'];
  password: Scalars['String'];
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type GetRolePermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolePermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRolePermission'>
);

export type GetHotelPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHotelPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getHotelPermission'>
);

export type BookingAttachmentListingQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type BookingAttachmentListingQuery = (
  { __typename?: 'Query' }
  & { BookingAttachmentListing: Array<(
    { __typename?: 'BookingAttachmentEntity' }
    & Pick<BookingAttachmentEntity, 'ID' | 'CreatedDT' | 'HotelID' | 'Remark' | 'BookingID' | 'RegistrationID'>
    & { AttachmentDetail?: Maybe<Array<(
      { __typename?: 'BookingAttachmentDetailEntity' }
      & Pick<BookingAttachmentDetailEntity, 'ID' | 'CreatedDT' | 'Remark'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>> }
  )> }
);

export type BookingAttachmentSummaryQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type BookingAttachmentSummaryQuery = (
  { __typename?: 'Query' }
  & { BookingAttachmentSummary: Array<(
    { __typename?: 'BookingAttachmentEntity' }
    & Pick<BookingAttachmentEntity, 'ID' | 'ModifiedDT'>
  )> }
);

export type BookingAttachmentInsertMutationVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  AttachmentInput: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type BookingAttachmentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingAttachmentInsert'>
);

export type BookingAttachmentUpdateMutationVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  BookingAttachmentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  AttachmentDetailsID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  AttachmentInput: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type BookingAttachmentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingAttachmentUpdate'>
);

export type BookingAttachmentDeleteMutationVariables = Exact<{
  BookingAttachmentID?: Maybe<Scalars['String']>;
}>;


export type BookingAttachmentDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingAttachmentDelete'>
);

export type BookFormQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  IsActive?: Maybe<Scalars['Boolean']>;
}>;


export type BookFormQuery = (
  { __typename?: 'Query' }
  & { SegmentListing: Array<(
    { __typename?: 'SegmentEntity' }
    & Pick<SegmentEntity, 'ID' | 'IsActive' | 'Description' | 'StayViewIcon'>
  )>, RoomTypeListing: Array<(
    { __typename?: 'RoomTypeEntity' }
    & Pick<RoomTypeEntity, 'ID' | 'HouseLimit' | 'Code' | 'Description' | 'Overbooking'>
  )>, PromotionListing: Array<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'ID' | 'Code' | 'Description' | 'DiscountType' | 'DiscountValue'>
    & { PromotionDetail?: Maybe<Array<(
      { __typename?: 'PromotionDetailEntity' }
      & Pick<PromotionDetailEntity, 'ID' | 'RatePolicyID' | 'Value'>
    )>> }
  )>, HotelSalesChannelListing: Array<(
    { __typename?: 'SalesChannelEntity' }
    & Pick<SalesChannelEntity, 'ID' | 'Description' | 'IsActive'>
  )>, DebtorListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'IsFrontDeskActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'SalesChannel' | 'Remarks' | 'Segment' | 'Login' | 'Password' | 'IseCommerce'>
  )>, ChannelManagerProfile: (
    { __typename?: 'ChannelManagerEntity' }
    & Pick<ChannelManagerEntity, 'ID' | 'Name'>
  ) }
);

export type CancelReasonListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type CancelReasonListingQuery = (
  { __typename?: 'Query' }
  & { CancelReasonListing: Array<(
    { __typename?: 'ReasonEntity' }
    & Pick<ReasonEntity, 'ID' | 'Description' | 'Code'>
  )> }
);

export type ShowBookingConfirmationEmailQueryVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type ShowBookingConfirmationEmailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ShowBookingConfirmationEmail'>
);

export type BookingConfirmationTemplateQueryVariables = Exact<{
  BookingID: Scalars['String'];
  TemplateID?: Maybe<Scalars['String']>;
}>;


export type BookingConfirmationTemplateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BookingConfirmationTemplate'>
);

export type BookingCancelReasonListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type BookingCancelReasonListingQuery = (
  { __typename?: 'Query' }
  & { BookingCancelReasonListing: Array<(
    { __typename?: 'ReasonEntity' }
    & Pick<ReasonEntity, 'ID' | 'Description' | 'Code'>
    & { Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'ID' | 'Code' | 'Name'>
    )> }
  )> }
);

export type GuestProfileListingByIdQueryVariables = Exact<{
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GuestProfileListingByIdQuery = (
  { __typename?: 'Query' }
  & { GuestProfileListingByID: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'RoomTypeID' | 'RatePolicyID' | 'RoomID' | 'ArrivalDate' | 'DepartureDate' | 'RegistrationStatus' | 'BookingID' | 'IsPrincipal' | 'SharerNo' | 'IsTTx' | 'TTxReasonID'>
    & { RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'MaxGuest'>
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'ID' | 'IdPicture' | 'TTxReasonID' | 'IsTTx' | 'GuestHistoryCount' | 'VehicleNo' | 'GuestType' | 'NewsLetter' | 'IsVIP'>
      & { ICGallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL'>
      )>, GuestHistoryList: Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice' | 'RegistrationStatus'>
        & { Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'ArrivalDate' | 'DepartureDate' | 'IsCmsTtx'>
        )>, RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'ID' | 'Code'>
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'ID' | 'RoomNo'>
        )>, RatePolicy?: Maybe<(
          { __typename?: 'RateEntity' }
          & Pick<RateEntity, 'ID' | 'RateCode'>
        )> }
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'BirthDate' | 'Email' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'Nationality' | 'Designation'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
        )> }
      )> }
    )>, TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'ID' | 'TrxDate' | 'TaxType' | 'TaxDescription' | 'FolioID' | 'ReversedTaxLedgerID' | 'IsGLPosted' | 'FromRegistration' | 'IsAdjustment'>
    )>> }
  )> }
);

export type BookingListingQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
  BookingStatus?: Maybe<Scalars['String']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  BookingDate?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  GroupName?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  SortByOrder?: Maybe<Scalars['String']>;
  SearchValue?: Maybe<Scalars['String']>;
}>;


export type BookingListingQuery = (
  { __typename?: 'Query' }
  & { BookingListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'ChargeToCityLedger' | 'TotalAdvPayment' | 'IsGroupBooking' | 'CreatedDT' | 'BookingNo' | 'IsCmsTtx' | 'ArrivalDate' | 'DepartureDate' | 'ContactID' | 'ReferenceNo' | 'GroupName' | 'SalesChannelID' | 'Source' | 'SegmentID' | 'SpecialRequest' | 'DebtorAccount' | 'BillingInstruction' | 'BookingRemark' | 'Reason' | 'BookingStatus'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'HotelName'>
      & { HotelAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'BirthDate' | 'PhoneNo' | 'Email' | 'Nationality' | 'ID' | 'MobileNo' | 'PassportNo' | 'NRIC'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorCategory' | 'ID' | 'Name' | 'IseCommerce'>
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, AllRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate'>
      & { Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'Email' | 'PhoneNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'siteName' | 'address2' | 'country' | 'city' | 'state' | 'postCode' | 'AddressType'>
          )> }
        )> }
      )> }
    )>>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'ArrivalDate' | 'DepartureDate' | 'PromoCode' | 'NoOfAdult' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'RoomTypeID' | 'RatePolicyID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice' | 'PrintRate' | 'IncidentalChargesSum'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'ID' | 'Overbooking'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'BirthDate' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
        )> }
      )> }
    )>> }
  )> }
);

export type BookingDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type BookingDetailsQuery = (
  { __typename?: 'Query' }
  & { BookingDetails: (
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment' | 'AdvPaymentIsAdjust' | 'IsCms' | 'CreatedDT' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'IsCmsTtx' | 'ContactID' | 'GroupName' | 'IsGroupBooking' | 'ChargeToCityLedger' | 'SalesChannelID' | 'Source' | 'SegmentID' | 'SpecialRequest' | 'DebtorAccount' | 'BillingInstruction' | 'BookingRemark' | 'Reason' | 'Remark' | 'ReferenceNo' | 'BookingStatus'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'HotelName' | 'CheckOutTime' | 'IsFloorPlan'>
      & { HotelAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'BirthDate' | 'PhoneNo' | 'MobileNo' | 'Email' | 'Nationality' | 'Designation' | 'ID' | 'PassportNo' | 'NRIC'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name' | 'CreditLimit' | 'IseCommerce'>
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'DiscountAmount' | 'AdvPaymentSum' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'HouseLimit' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'FolioCount' | 'ServiceRequestCount' | 'PaymentSum' | 'IncidentalChargesCount' | 'IncidentalChargesSum' | 'RoomTypeID' | 'ChargeRoomTypeID' | 'RatePolicyID' | 'Reason' | 'Remark' | 'ModifiedBy' | 'ModifiedDT' | 'BookingID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalOutStandingAmount' | 'RoomOutStanding' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice' | 'DepositSum' | 'PrintRate' | 'IsRoomUpgrade'>
      & { BillSchedule?: Maybe<Array<(
        { __typename?: 'BillScheduleEntity' }
        & Pick<BillScheduleEntity, 'ID' | 'TrxAmount' | 'ChargeType' | 'RateID'>
      )>>, RoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerEntity' }
        & Pick<RoomLedgerEntity, 'ID' | 'ChargeType'>
      )>>, AdvancePayment?: Maybe<Array<(
        { __typename?: 'AdvancePaymentEntity' }
        & Pick<AdvancePaymentEntity, 'ID'>
      )>>, Payment?: Maybe<Array<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'ID'>
      )>>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'ID' | 'Description' | 'TrxDate'>
      )>>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'MaxGuest' | 'ID' | 'Overbooking'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo' | 'ID' | 'HKRoomStatus'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description' | 'Category'>
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'IsCms' | 'isPrepaid' | 'IsCmsTtx'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name' | 'ID' | 'DebtorCategory' | 'DebtorAccount'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo' | 'IdPicture' | 'GuestType'>
        & { ICGallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'BirthDate' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>>, AllRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'DiscountAmount' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'IsPreCheckIn' | 'IsEmailBlasted' | 'HouseLimit' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'IncidentalChargesCount' | 'RoomTypeID' | 'RatePolicyID' | 'DepartureDate' | 'BookingID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalOutStandingAmount' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice' | 'DepositSum' | 'IsRoomUpgrade'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'MaxGuest' | 'ID'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo' | 'ID'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name' | 'ID' | 'DebtorCategory' | 'DebtorAccount'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo' | 'IdPicture' | 'GuestType'>
        & { ICGallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type IncChargeBookingDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type IncChargeBookingDetailsQuery = (
  { __typename?: 'Query' }
  & { BookingDetails: (
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate'>
    & { BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'IsPrincipal' | 'RegistrationStatus' | 'IncidentalChargesSum' | 'TotalRoomPrice' | 'TotalPrice'>
    )>> }
  ) }
);

export type MiniBookingDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type MiniBookingDetailsQuery = (
  { __typename?: 'Query' }
  & { BookingDetails: (
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment' | 'IsCms' | 'CreatedDT' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'IsCmsTtx' | 'isPrepaid' | 'ContactID' | 'GroupName' | 'IsGroupBooking' | 'ChargeToCityLedger' | 'SalesChannelID' | 'Source' | 'SegmentID' | 'SpecialRequest' | 'DebtorAccount' | 'BillingInstruction' | 'BookingRemark' | 'Reason' | 'Remark' | 'ReferenceNo' | 'BookingStatus'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'HotelName' | 'CheckOutTime' | 'IsFloorPlan'>
      & { HotelAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'BirthDate' | 'PhoneNo' | 'MobileNo' | 'Email' | 'Nationality' | 'Designation'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name' | 'CreditLimit'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'DiscountAmount' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'HouseLimit' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'RoomTypeID' | 'ChargeRoomTypeID' | 'RatePolicyID' | 'Reason' | 'Remark' | 'ModifiedBy' | 'ModifiedDT' | 'BookingID' | 'RoomRate' | 'TotalRoomPrice' | 'DepositSum' | 'PrintRate' | 'IsRoomUpgrade'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'MaxGuest' | 'ID' | 'Overbooking'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo' | 'ID' | 'HKRoomStatus'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description' | 'Category'>
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'isPrepaid' | 'IsCms' | 'IsCmsTtx'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name' | 'ID' | 'DebtorCategory' | 'DebtorAccount'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo' | 'IdPicture' | 'GuestType' | 'GuestHistoryCount'>
        & { ICGallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'BirthDate' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>>, AllRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'DiscountAmount' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'IsPreCheckIn' | 'IsEmailBlasted' | 'HouseLimit' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'IncidentalChargesCount' | 'RoomTypeID' | 'RatePolicyID' | 'DepartureDate' | 'BookingID' | 'RoomRate' | 'IsRoomUpgrade'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'MaxGuest' | 'ID'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo' | 'ID'>
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name' | 'ID' | 'DebtorCategory' | 'DebtorAccount'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo' | 'IdPicture' | 'GuestType' | 'GuestHistoryCount'>
        & { ICGallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL'>
        )>, Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type AvailabilityListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate?: Maybe<Scalars['DateTime']>;
  Adddate?: Maybe<Scalars['Float']>;
  RoomTypeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  TravelAgentID?: Maybe<Scalars['String']>;
}>;


export type AvailabilityListingQuery = (
  { __typename?: 'Query' }
  & { AvailabilityListing: (
    { __typename?: 'HotelAvailabilityOutput' }
    & { RoomTypesList?: Maybe<Array<(
      { __typename?: 'RoomTypesListOutput' }
      & Pick<RoomTypesListOutput, 'ID' | 'Code' | 'Description' | 'TotalRoom' | 'Overbooking'>
      & { RoomTypeAvailability?: Maybe<Array<(
        { __typename?: 'AvailabilityOutput' }
        & Pick<AvailabilityOutput, 'ID' | 'EffectiveDate' | 'BookedRoom' | 'BlockedRoom' | 'AllottedRoom' | 'TotalOccupied' | 'TotalVacant' | 'SeasonType' | 'TotalVacantShow' | 'Waitlist'>
      )>> }
    )>>, Occupancy?: Maybe<Array<(
      { __typename?: 'Occupancy' }
      & Pick<Occupancy, 'EffectiveDate' | 'Season' | 'Occupancy' | 'TotalOccupied' | 'TotalRoom' | 'TotalVacant' | 'TotalVacantShow' | 'TotalBlock'>
    )>> }
  ) }
);

export type PackagePriceListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
}>;


export type PackagePriceListingQuery = (
  { __typename?: 'Query' }
  & { PackagePriceListing: Array<(
    { __typename?: 'PackagePriceOutput' }
    & Pick<PackagePriceOutput, 'RoomTypeID' | 'RoomTypeCode' | 'RoomTypeName' | 'RatePolicyID' | 'RateFrequency' | 'Code' | 'TotalVacant'>
    & { Pricing?: Maybe<Array<(
      { __typename?: 'PricingOutput' }
      & Pick<PricingOutput, 'Date' | 'Type' | 'Price' | 'BasePrice'>
      & { Packages?: Maybe<Array<(
        { __typename?: 'PackagesEntity' }
        & Pick<PackagesEntity, 'ID' | 'IncidentalChargeID' | 'Description' | 'IncludeRate' | 'ComputationRules' | 'ServiceCharge' | 'Tax' | 'Amount'>
      )>> }
    )>> }
  )> }
);

export type BookingPackagePriceListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
  DebtorID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  RatePolicyID?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
}>;


export type BookingPackagePriceListingQuery = (
  { __typename?: 'Query' }
  & { BookingPackagePriceListing: Array<(
    { __typename?: 'PackagePriceOutput' }
    & Pick<PackagePriceOutput, 'RoomTypeID' | 'RoomTypeCode' | 'RoomTypeName' | 'RatePolicyID' | 'Code' | 'TotalVacant'>
    & { Pricing?: Maybe<Array<(
      { __typename?: 'PricingOutput' }
      & Pick<PricingOutput, 'Date' | 'Type' | 'Price' | 'BasePrice'>
      & { Packages?: Maybe<Array<(
        { __typename?: 'PackagesEntity' }
        & Pick<PackagesEntity, 'ID' | 'IncidentalChargeID' | 'Description' | 'IncludeRate' | 'ComputationRules' | 'ServiceCharge' | 'Tax' | 'Amount'>
      )>> }
    )>> }
  )> }
);

export type AdvancePaymentListingQueryVariables = Exact<{
  BookingID: Scalars['String'];
}>;


export type AdvancePaymentListingQuery = (
  { __typename?: 'Query' }
  & { AdvancePaymentListing: Array<(
    { __typename?: 'AdvancePaymentEntity' }
    & Pick<AdvancePaymentEntity, 'ID' | 'PaymentDate' | 'HotelID' | 'BookingID' | 'RegistrationID' | 'PaymentClass' | 'ReceiptNo' | 'PaymentType' | 'DebtorAccount' | 'PaySessionNo' | 'CheckOutID' | 'Amount' | 'ReferenceNo' | 'Remark' | 'PaymentStatus' | 'IsPosted' | 'ReversedAdvancePaymentID' | 'CreatedBy'>
  )> }
);

export type InhousePaymentListingQueryVariables = Exact<{
  RegistrationID: Scalars['String'];
}>;


export type InhousePaymentListingQuery = (
  { __typename?: 'Query' }
  & { InhousePaymentListing: Array<(
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ID' | 'PaymentDate' | 'FolioID' | 'HotelID' | 'RegistrationID' | 'PaymentClass' | 'ReceiptNo' | 'PaymentType' | 'DebtorAccount' | 'PaySessionNo' | 'CheckOutID' | 'Amount' | 'ReferenceNo' | 'Remark' | 'PaymentStatus' | 'ReversedPaymentID' | 'CreatedBy' | 'ModifiedBy'>
  )> }
);

export type InhouseRefundListingQueryVariables = Exact<{
  BookingID: Scalars['String'];
}>;


export type InhouseRefundListingQuery = (
  { __typename?: 'Query' }
  & { InhouseRefundListing: Array<(
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ID' | 'PaymentDate' | 'FolioID' | 'HotelID' | 'RegistrationID' | 'PaymentClass' | 'ReceiptNo' | 'PaymentType' | 'DebtorAccount' | 'PaySessionNo' | 'CheckOutID' | 'Amount' | 'ReferenceNo' | 'Remark' | 'PaymentStatus' | 'ReversedPaymentID' | 'CreatedBy' | 'ModifiedBy'>
  )> }
);

export type ChangeOfDateCheckAvailabilityQueryVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
}>;


export type ChangeOfDateCheckAvailabilityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ChangeOfDateCheckAvailability'>
);

export type ChangeOfDateCheckAvailabilityBookEditQueryVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  EditRegInput: Array<EditRegInput> | EditRegInput;
  roomTypeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ChangeOfDateCheckAvailabilityBookEditQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ChangeOfDateCheckAvailabilityBookEdit'>
);

export type AdvancePaymentDetailQueryVariables = Exact<{
  BookingID: Scalars['String'];
  OrderID: Scalars['String'];
}>;


export type AdvancePaymentDetailQuery = (
  { __typename?: 'Query' }
  & { AdvancePaymentDetail: (
    { __typename?: 'AdvancePaymentEntity' }
    & Pick<AdvancePaymentEntity, 'ID' | 'RegistrationID' | 'HotelID' | 'ReceiptNo' | 'PaymentDate' | 'PaymentClass' | 'PaymentType' | 'DebtorAccount' | 'PaySessionNo' | 'CheckOutID' | 'Amount' | 'ReferenceNo' | 'PaymentStatus'>
  ) }
);

export type BookingInsertMutationVariables = Exact<{
  BookingInput: BookingInput;
  ContactInput: ContactInput;
  RegistrationInput: Array<RegistrationInput> | RegistrationInput;
  DebtorType?: Maybe<Scalars['String']>;
  IsSharedConfirmationLetter?: Maybe<Scalars['Boolean']>;
}>;


export type BookingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingInsert'>
);

export type BookingUpdateMutationVariables = Exact<{
  BookingInput: BookingInput;
  ContactInput?: Maybe<ContactInput>;
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput> | SpecialRequestInput>;
}>;


export type BookingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingUpdate'>
);

export type ReinstateBookingMutationVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
}>;


export type ReinstateBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReinstateBooking'>
);

export type CancelBookingMutationVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  ReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
}>;


export type CancelBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CancelBooking'>
);

export type BookingRoomTypeUpdateMutationVariables = Exact<{
  EditRegInput: EditRegInput;
}>;


export type BookingRoomTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingRoomTypeUpdate'>
);

export type GuestProfileInsertMutationVariables = Exact<{
  RegistrationID: Scalars['String'];
  ContactInput: ContactInput;
}>;


export type GuestProfileInsertMutation = (
  { __typename?: 'Mutation' }
  & { GuestProfileInsert: (
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID'>
  ) }
);

export type GuestContactUpdateMutationVariables = Exact<{
  ContactInput: ContactInput;
  VehicleNo?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  IsPrincipal?: Maybe<Scalars['Boolean']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  guestType?: Maybe<GuestType>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
}>;


export type GuestContactUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestContactUpdate'>
);

export type GuestProfileDeleteMutationVariables = Exact<{
  RegistrationID?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  SharerNo?: Maybe<Scalars['Float']>;
}>;


export type GuestProfileDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestProfileDelete'>
);

export type AdvancePaymentInsertMutationVariables = Exact<{
  AdvancePaymentInput: AdvancePaymentInput;
}>;


export type AdvancePaymentInsertMutation = (
  { __typename?: 'Mutation' }
  & { AdvancePaymentInsert: (
    { __typename?: 'AdvancePaymentEntity' }
    & Pick<AdvancePaymentEntity, 'ReceiptNo' | 'PaymentDate' | 'PaymentClass' | 'PaymentType' | 'Amount' | 'ReferenceNo' | 'PaymentStatus' | 'ID'>
  ) }
);

export type AdvancePaymentRefundMutationVariables = Exact<{
  AdvancePaymentInput: Array<AdvancePaymentInput> | AdvancePaymentInput;
}>;


export type AdvancePaymentRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AdvancePaymentRefund'>
);

export type AdvancePaymentUpdateMutationVariables = Exact<{
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
}>;


export type AdvancePaymentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AdvancePaymentUpdate'>
);

export type ImageUploadMutationVariables = Exact<{
  AttachmentInput: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type ImageUploadMutation = (
  { __typename?: 'Mutation' }
  & { ImageUpload: (
    { __typename?: 'GalleryEntity' }
    & Pick<GalleryEntity, 'ImageURL'>
  ) }
);

export type BookingRoomAssignmentMutationVariables = Exact<{
  RoomTypeID: Scalars['String'];
  RegistrationIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  RoomIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  BookingID: Scalars['String'];
  BookingRoomAssignInput?: Maybe<Array<BookingRoomAssignInput> | BookingRoomAssignInput>;
}>;


export type BookingRoomAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingRoomAssignment'>
);

export type BillScheduleListByBookingQueryVariables = Exact<{
  BookingID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
  IsFrontDesk?: Maybe<Scalars['Boolean']>;
}>;


export type BillScheduleListByBookingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BillScheduleListByBooking'>
);

export type NoOfPaxUpdateMutationVariables = Exact<{
  BookingID: Scalars['String'];
  NoOfAdult: Scalars['Float'];
  SharerNo: Scalars['Float'];
  RoomTypeID: Scalars['String'];
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
}>;


export type NoOfPaxUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NoOfPaxUpdate'>
);

export type NewBookingInfoUpdateMutationVariables = Exact<{
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput> | SpecialRequestInput>;
  BookingInput: BookingInput;
  BookingID: Scalars['String'];
}>;


export type NewBookingInfoUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewBookingInfoUpdate'>
);

export type NewBookingRemarkMutationVariables = Exact<{
  BookingRemark: Scalars['String'];
  BookingID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type NewBookingRemarkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NewBookingRemark'>
);

export type BookingUpdateV2MutationVariables = Exact<{
  EditRegInput?: Maybe<Array<EditRegInput> | EditRegInput>;
  SpecialRequestInput?: Maybe<Array<SpecialRequestInput> | SpecialRequestInput>;
  ContactInput?: Maybe<ContactInput>;
  BookingInput: BookingInput;
  PrintRate: Scalars['Boolean'];
  BookingID: Scalars['String'];
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
}>;


export type BookingUpdateV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingUpdateV2'>
);

export type BookingIncChargesCancelMutationVariables = Exact<{
  BillID?: Maybe<Scalars['String']>;
  RegistrationID: Scalars['String'];
}>;


export type BookingIncChargesCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BookingIncChargesCancel'>
);

export type ReinstateRoomMutationVariables = Exact<{
  SharerNo: Scalars['Float'];
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  ReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
}>;


export type ReinstateRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReinstateRoom'>
);

export type CancelRoomMutationVariables = Exact<{
  SharerNo: Scalars['Float'];
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  ReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
}>;


export type CancelRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CancelRoom'>
);

export type BillScheduleListingQueryVariables = Exact<{
  RegistrationID?: Maybe<Scalars['String']>;
  IsFrontDesk?: Maybe<Scalars['Boolean']>;
  BookingID: Scalars['String'];
}>;


export type BillScheduleListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BillScheduleListing'>
);

export type BillScheduleAdjustmentMutationVariables = Exact<{
  Reason: Scalars['String'];
  Discount: Scalars['Float'];
  RateID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
  TrxDates: Array<Scalars['DateTime']> | Scalars['DateTime'];
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
}>;


export type BillScheduleAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BillScheduleAdjustment'>
);

export type BookingSubMenuInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type BookingSubMenuInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BookingSubMenuInfo'>
);

export type WaitinglistNewApiMutationVariables = Exact<{
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type WaitinglistNewApiMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'WaitinglistNewAPI'>
);

export type CmsCustomControlQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  ControlOP: Scalars['String'];
}>;


export type CmsCustomControlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CMSCustomControl'>
);

export type HotelNotificationUpdateMutationVariables = Exact<{
  operationType?: Maybe<Scalars['String']>;
  input: Array<HotelNotificationInput> | HotelNotificationInput;
}>;


export type HotelNotificationUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HotelNotificationUpdate'>
);

export type HotelNotificationListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
  NotificationType: Scalars['String'];
}>;


export type HotelNotificationListingQuery = (
  { __typename?: 'Query' }
  & { HotelNotificationListing: Array<(
    { __typename?: 'HotelNotificationEntity' }
    & Pick<HotelNotificationEntity, 'ID' | 'HotelID' | 'UserID' | 'AccountID' | 'RefTable' | 'RefID' | 'ReadStatus' | 'NotificationType'>
  )> }
);

export type HotelNotifySubscriptionVariables = Exact<{
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
  NotificationType: Scalars['String'];
}>;


export type HotelNotifySubscription = (
  { __typename?: 'Subscription' }
  & { HotelNotify: Array<(
    { __typename?: 'HotelNotificationEntity' }
    & Pick<HotelNotificationEntity, 'ID' | 'HotelID' | 'AccountID' | 'RefTable' | 'RefID' | 'ReadStatus' | 'NotificationType'>
  )> }
);

export type RoomTypeAvailableQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomTypeID?: Maybe<Scalars['String']>;
}>;


export type RoomTypeAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RoomTypeAvailable'>
);

export type AvailabilityCheckingRevampQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomTypeID?: Maybe<Scalars['String']>;
  NoOfRoom?: Maybe<Scalars['Float']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  DepartureDate?: Maybe<Scalars['DateTime']>;
}>;


export type AvailabilityCheckingRevampQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AvailabilityCheckingRevamp'>
);

export type CheckForBlockRoomQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  RoomID?: Maybe<Scalars['String']>;
}>;


export type CheckForBlockRoomQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckForBlockRoom'>
);

export type BookingBillScheduleInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
  IsCurrentDate?: Maybe<Scalars['Boolean']>;
}>;


export type BookingBillScheduleInfoQuery = (
  { __typename?: 'Query' }
  & { BookingBillScheduleInfo: (
    { __typename?: 'BookingBillScheduleResponse' }
    & { BillScheduleAmount?: Maybe<Array<(
      { __typename?: 'BillScheduleEntity' }
      & Pick<BillScheduleEntity, 'ID' | 'RegistrationID' | 'RateID' | 'TrxDate' | 'TrxAmount' | 'DiscountAmount'>
    )>>, DiscountAmount?: Maybe<Array<(
      { __typename?: 'RateAdjustmentAuditEntity' }
      & Pick<RateAdjustmentAuditEntity, 'ID' | 'RegistrationID' | 'NewDiscountAmount' | 'EffectiveDate'>
    )>> }
  ) }
);

export type IsRoomNoAvailableQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RegistrationInput: Array<RegistrationInput> | RegistrationInput;
}>;


export type IsRoomNoAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsRoomNoAvailable'>
);

export type AuditLogDetailsQueryVariables = Exact<{
  FieldID: Scalars['String'];
  TableName: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type AuditLogDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AuditLogDetails'>
);

export type IsExistRegistrationIncChargesQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type IsExistRegistrationIncChargesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsExistRegistrationIncCharges'>
);

export type BookingIncChargesRegistrationListingQueryVariables = Exact<{
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type BookingIncChargesRegistrationListingQuery = (
  { __typename?: 'Query' }
  & { BookingIncChargesRegistrationListing: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID'>
    & { BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'ReversedBillLedgerID' | 'IncidentalChargeID' | 'Reason' | 'Description' | 'TrxDate' | 'Qty' | 'UnitPrice' | 'TrxAmount' | 'DiscountAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'ReferenceNo' | 'PaymentID' | 'FolioID' | 'CreatedBy' | 'CreatedDT' | 'RegistrationID'>
      & { Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'ID' | 'FullName'>
          )> }
        )> }
      )>, TaxBillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerTaxEntity' }
        & Pick<BillLedgerTaxEntity, 'ID' | 'TaxID' | 'TaxSchemeID' | 'TaxRate' | 'TaxAmount' | 'BillLedgerID'>
        & { TaxInfo?: Maybe<(
          { __typename?: 'taxEntity' }
          & Pick<TaxEntity, 'code'>
        )> }
      )>> }
    )>> }
  )> }
);

export type CustomizedDocListingQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
}>;


export type CustomizedDocListingQuery = (
  { __typename?: 'Query' }
  & { CustomizedDocListing: Array<(
    { __typename?: 'CustomizedDocumentEntity' }
    & Pick<CustomizedDocumentEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'DocType'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'ID' | 'HotelName'>
    )> }
  )> }
);

export type OutGoingDocTemplateQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
}>;


export type OutGoingDocTemplateQuery = (
  { __typename?: 'Query' }
  & { OutGoingDocTemplate?: Maybe<(
    { __typename?: 'OutgoingDocTemplateEntity' }
    & Pick<OutgoingDocTemplateEntity, 'ID' | 'HotelID' | 'Folio' | 'Receipt' | 'Invoice' | 'DebitNote' | 'CreditNote' | 'OfficialReceipt' | 'Refund' | 'APStatement'>
  )> }
);

export type CashierSummaryQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type CashierSummaryQuery = (
  { __typename?: 'Query' }
  & { BIBillLedgerListing: Array<(
    { __typename?: 'BillLedgerEntity' }
    & Pick<BillLedgerEntity, 'ID' | 'RegistrationID' | 'TrxDate' | 'Qty' | 'UnitPrice' | 'TrxAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Description' | 'ReferenceNo' | 'PaymentID'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )>, IncidentalCharge?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'Description' | 'DepartmentID'>
      & { Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Code' | 'Name'>
      )> }
    )> }
  )>, BIDepositLedgerListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID'>
    & { Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & { DepositLedger?: Maybe<Array<(
        { __typename?: 'DepositLedgerEntity' }
        & Pick<DepositLedgerEntity, 'DepositClass' | 'DepositAmount' | 'TrxDate' | 'ReversedID' | 'Remarks'>
      )>>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>> }
  )> }
);

export type EmailSendMutationVariables = Exact<{
  Body: EmailBodyInput;
  HotelID: Scalars['String'];
  Email?: Maybe<Scalars['String']>;
  BookingID?: Maybe<Scalars['String']>;
  isGuestEmail?: Maybe<Scalars['Boolean']>;
  Attachments?: Maybe<EmailAttachmentInput>;
}>;


export type EmailSendMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmailSend'>
);

export type BiRevenueListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiRevenueListingQuery = (
  { __typename?: 'Query' }
  & { BIRevenueListing: Array<(
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'HotelDate' | 'OCCRoom' | 'DueIn' | 'DueOut' | 'ExpOcc' | 'OCC' | 'NoOfPax' | 'BookedRoom' | 'AvailableRoom' | 'DayUse' | 'CompUse' | 'RoomRevenue' | 'OtherRevenue' | 'Arr' | 'OOO' | 'OOI'>
  )> }
);

export type BiRevenueListingV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiRevenueListingV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BIRevenueListingV2'>
);

export type CountItemQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type CountItemQuery = (
  { __typename?: 'Query' }
  & { CountItem: (
    { __typename?: 'RegAndBooking' }
    & Pick<RegAndBooking, 'CheckInDate' | 'CheckOutDate' | 'ArrivalDate' | 'DepartureDate'>
  ) }
);

export type Top10DebtorRevenueQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type Top10DebtorRevenueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'Top10DebtorRevenue'>
);

export type DebtorAgingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
}>;


export type DebtorAgingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DebtorAging'>
);

export type AgingByDebtorQueryVariables = Exact<{
  HotelID: Scalars['String'];
  ID: Scalars['String'];
}>;


export type AgingByDebtorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AgingByDebtor'>
);

export type TotalAgingQueryVariables = Exact<{
  DebtorID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  HotelID: Scalars['String'];
}>;


export type TotalAgingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TotalAging'>
);

export type TransactionLedgerQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type TransactionLedgerQuery = (
  { __typename?: 'Query' }
  & { BIBillLedgerListing: Array<(
    { __typename?: 'BillLedgerEntity' }
    & Pick<BillLedgerEntity, 'CreatedBy' | 'ID' | 'RegistrationID' | 'TrxDate' | 'Qty' | 'UnitPrice' | 'TrxAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Description' | 'ReferenceNo' | 'PaymentID'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )>, IncidentalCharge?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'Description' | 'DepartmentID'>
      & { Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Code' | 'Name'>
      )> }
    )> }
  )> }
);

export type BiHotelListingQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type BiHotelListingQuery = (
  { __typename?: 'Query' }
  & { BIHotelListing: (
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'RegCardTnC'>
  ) }
);

export type BiUsersLisitingQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type BiUsersLisitingQuery = (
  { __typename?: 'Query' }
  & { BIUsersLisiting: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'name' | 'userName'>
  ) }
);

export type BiAdvancePaymentListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiAdvancePaymentListingQuery = (
  { __typename?: 'Query' }
  & { BIAdvancePaymentListing: Array<(
    { __typename?: 'AdvancePaymentEntity' }
    & Pick<AdvancePaymentEntity, 'ID' | 'PaymentDate' | 'ReceiptNo' | 'ReferenceNo' | 'PaymentStatus' | 'PaymentType' | 'PaymentClass' | 'Amount' | 'BookingID'>
    & { Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo'>
      & { Registration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'IsPrincipal'>
        & { Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )>, RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code'>
        )>, Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName' | 'Email'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'country' | 'city' | 'state' | 'postCode'>
            )> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type BiFolioListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  SearchValue?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type BiFolioListingQuery = (
  { __typename?: 'Query' }
  & { BIFolioListing: (
    { __typename?: 'BIFolioResponse' }
    & Pick<BiFolioResponse, 'TotalFolio'>
    & { Folio?: Maybe<Array<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'ID' | 'FolioNo' | 'FolioType' | 'FolioDate' | 'ClosedDate' | 'DocumentType' | 'FolioAmount' | 'CreatedBy' | 'IsSuspend' | 'einvoice_irbm_uniqueID' | 'is_einvoice' | 'einvoice_status' | 'IsConsolidated'>
      & { NonGuestInfo?: Maybe<(
        { __typename?: 'NonGuestInfoJson' }
        & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit'>
      )>, Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
        & { Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'ID'>
          & { Debtor?: Maybe<(
            { __typename?: 'DebtorEntity' }
            & Pick<DebtorEntity, 'Name'>
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )>, RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code'>
        )>, Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )>, AdvancePayment?: Maybe<Array<(
        { __typename?: 'AdvancePaymentEntity' }
        & Pick<AdvancePaymentEntity, 'Amount' | 'PaymentType' | 'PaymentClass' | 'PaySessionNo' | 'ReferenceNo' | 'ID'>
        & { Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'ID' | 'BookingNo'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )>>, TaxLedger?: Maybe<Array<(
        { __typename?: 'TaxLedgerEntity' }
        & Pick<TaxLedgerEntity, 'TrxDate' | 'TaxType' | 'TaxDescription' | 'TaxAmount' | 'Reason' | 'Description'>
      )>>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'BaseAmount' | 'ServiceCharge' | 'TaxAmount' | 'TrxAmount' | 'ReferenceNo'>
        & { IncidentalCharge?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description'>
        )> }
      )>>, RoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerEntity' }
        & Pick<RoomLedgerEntity, 'ID' | 'TrxAmount' | 'TrxDate' | 'TaxAmount' | 'BaseAmount' | 'TransactionType' | 'TransactionID' | 'ServiceCharge' | 'ReferenceNo' | 'Description'>
        & { Transaction?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description'>
        )> }
      )>>, DepositLedger?: Maybe<Array<(
        { __typename?: 'DepositLedgerEntity' }
        & Pick<DepositLedgerEntity, 'DepositAmount' | 'DepositClass' | 'ReferenceNo' | 'TrxDate'>
      )>>, Payment?: Maybe<Array<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'PaymentType' | 'DebtorAccount'>
      )>> }
    )>> }
  ) }
);

export type DepositListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  SearchValue?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type DepositListingQuery = (
  { __typename?: 'Query' }
  & { DepositListing: (
    { __typename?: 'BIFolioResponse' }
    & Pick<BiFolioResponse, 'TotalFolio'>
    & { Folio?: Maybe<Array<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'ID' | 'FolioNo' | 'FolioType' | 'FolioDate' | 'ClosedDate' | 'DocumentType' | 'FolioAmount' | 'IsSuspend' | 'einvoice_irbm_uniqueID' | 'is_einvoice' | 'einvoice_status' | 'IsConsolidated' | 'CreatedBy'>
      & { NonGuestInfo?: Maybe<(
        { __typename?: 'NonGuestInfoJson' }
        & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit'>
      )>, Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
        & { Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'ID'>
          & { Debtor?: Maybe<(
            { __typename?: 'DebtorEntity' }
            & Pick<DebtorEntity, 'Name'>
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )>, RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code'>
        )>, Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )>, AdvancePayment?: Maybe<Array<(
        { __typename?: 'AdvancePaymentEntity' }
        & Pick<AdvancePaymentEntity, 'Amount' | 'PaymentType' | 'PaymentClass' | 'PaySessionNo' | 'ReferenceNo' | 'ID'>
        & { Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'ID' | 'BookingNo'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )>>, TaxLedger?: Maybe<Array<(
        { __typename?: 'TaxLedgerEntity' }
        & Pick<TaxLedgerEntity, 'TrxDate' | 'TaxType' | 'TaxDescription' | 'TaxAmount' | 'Reason' | 'Description'>
      )>>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'BaseAmount' | 'ServiceCharge' | 'TaxAmount' | 'TrxAmount' | 'ReferenceNo'>
        & { IncidentalCharge?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description'>
        )> }
      )>>, RoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerEntity' }
        & Pick<RoomLedgerEntity, 'ID' | 'TrxAmount' | 'TrxDate' | 'TaxAmount' | 'BaseAmount' | 'TransactionType' | 'TransactionID' | 'ServiceCharge' | 'ReferenceNo' | 'Description'>
        & { Transaction?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description'>
        )> }
      )>>, DepositLedger?: Maybe<Array<(
        { __typename?: 'DepositLedgerEntity' }
        & Pick<DepositLedgerEntity, 'DepositAmount' | 'DepositClass' | 'ReferenceNo' | 'TrxDate'>
      )>>, Payment?: Maybe<Array<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'PaymentType' | 'DebtorAccount'>
      )>> }
    )>> }
  ) }
);

export type BiReceiptRefundListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  SearchValue?: Maybe<Scalars['String']>;
  Limit?: Maybe<Scalars['Float']>;
  Offset?: Maybe<Scalars['Float']>;
}>;


export type BiReceiptRefundListingQuery = (
  { __typename?: 'Query' }
  & { BIReceiptRefundListing: (
    { __typename?: 'BIReceiptRefundResponse' }
    & Pick<BiReceiptRefundResponse, 'TotalListing'>
    & { BIListing?: Maybe<Array<(
      { __typename?: 'PaymentTestClass' }
      & Pick<PaymentTestClass, 'ID' | 'ReceiptNo' | 'ReferenceNo' | 'PaymentDate' | 'BookingID' | 'PaymentType' | 'Amount' | 'CreatedBy' | 'PaymentStatus' | 'PaymentClass' | 'DebtorAccount' | 'FolioID'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
          )> }
        )> }
      )>, Folio?: Maybe<Array<(
        { __typename?: 'FolioEntity' }
        & Pick<FolioEntity, 'ID' | 'FolioType' | 'FolioNo' | 'IsSuspend'>
        & { NonGuestInfo?: Maybe<(
          { __typename?: 'NonGuestInfoJson' }
          & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit'>
        )>, BillLedger?: Maybe<Array<(
          { __typename?: 'BillLedgerEntity' }
          & Pick<BillLedgerEntity, 'TrxDate' | 'TrxAmount' | 'BaseAmount' | 'ReferenceNo' | 'TaxAmount' | 'ServiceCharge' | 'Qty'>
          & { IncidentalCharge?: Maybe<(
            { __typename?: 'IncidentalChargeEntity' }
            & Pick<IncidentalChargeEntity, 'Description'>
          )> }
        )>>, RoomLedger?: Maybe<Array<(
          { __typename?: 'RoomLedgerEntity' }
          & Pick<RoomLedgerEntity, 'ReferenceNo' | 'BaseAmount' | 'TrxAmount' | 'TrxDate' | 'ServiceCharge' | 'TaxAmount' | 'TransactionType'>
          & { Transaction?: Maybe<(
            { __typename?: 'IncidentalChargeEntity' }
            & Pick<IncidentalChargeEntity, 'Description'>
          )> }
        )>>, DepositLedger?: Maybe<Array<(
          { __typename?: 'DepositLedgerEntity' }
          & Pick<DepositLedgerEntity, 'ID' | 'DepositAmount' | 'PaymentType' | 'ReferenceNo' | 'DepositClass' | 'ReversedID'>
        )>> }
      )>>, Registration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ArrivalDate' | 'CheckInDate' | 'CheckOutDate' | 'DepartureDate'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
            )> }
          )> }
        )>, Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount' | 'Source' | 'ArrivalDate' | 'DepartureDate' | 'CreatedBy'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
            )> }
          )> }
        )>, TaxLedger?: Maybe<Array<(
          { __typename?: 'TaxLedgerEntity' }
          & Pick<TaxLedgerEntity, 'TaxType' | 'TaxDescription' | 'TaxAmount'>
        )>>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
          & { Registrations?: Maybe<Array<(
            { __typename?: 'RegistrationEntity' }
            & Pick<RegistrationEntity, 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
            & { Guest?: Maybe<(
              { __typename?: 'GuestProfileEntity' }
              & { Contact?: Maybe<(
                { __typename?: 'ContactEntity' }
                & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'Email'>
                & { Address?: Maybe<(
                  { __typename?: 'Address' }
                  & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
                )> }
              )> }
            )> }
          )>>, RoomType?: Maybe<(
            { __typename?: 'RoomTypeEntity' }
            & Pick<RoomTypeEntity, 'Code'>
          )> }
        )> }
      )>> }
    )>> }
  ) }
);

export type BiPaymentListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiPaymentListingQuery = (
  { __typename?: 'Query' }
  & { BIAdvancePaymentListing: Array<(
    { __typename?: 'AdvancePaymentEntity' }
    & Pick<AdvancePaymentEntity, 'ID' | 'ReceiptNo' | 'ReferenceNo' | 'HotelID' | 'CreatedBy' | 'BookingID' | 'DebtorAccount' | 'PaymentClass' | 'PaymentStatus' | 'PaymentDate' | 'PaymentType' | 'Amount'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'IsPrincipal'>
      & { Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate'>
      & { Registration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'IsPrincipal'>
        & { Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )>, RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code'>
        )>, Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName' | 'Email'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'country' | 'city' | 'state' | 'postCode'>
            )> }
          )> }
        )> }
      )>>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
        )> }
      )> }
    )>, Folio?: Maybe<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'FolioType'>
      & { NonGuestInfo?: Maybe<(
        { __typename?: 'NonGuestInfoJson' }
        & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit'>
      )>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'ID'>
      )>> }
    )> }
  )>, BIPaymentListing: Array<(
    { __typename?: 'PaymentTestClass' }
    & Pick<PaymentTestClass, 'ID' | 'ReceiptNo' | 'ReferenceNo' | 'PaymentDate' | 'BookingID' | 'PaymentType' | 'Amount' | 'CreatedBy' | 'PaymentStatus' | 'PaymentClass' | 'DebtorAccount'>
    & { Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
        )> }
      )> }
    )>, Folio?: Maybe<Array<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'ID' | 'FolioType' | 'FolioNo' | 'IsSuspend'>
      & { NonGuestInfo?: Maybe<(
        { __typename?: 'NonGuestInfoJson' }
        & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit'>
      )>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'TrxDate' | 'TrxAmount' | 'BaseAmount' | 'ReferenceNo' | 'TaxAmount' | 'ServiceCharge' | 'Qty'>
        & { IncidentalCharge?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description'>
        )>, TaxBillLedger?: Maybe<Array<(
          { __typename?: 'BillLedgerTaxEntity' }
          & Pick<BillLedgerTaxEntity, 'ID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'BillLedgerID' | 'TaxSchemeID'>
          & { TaxInfo?: Maybe<(
            { __typename?: 'taxEntity' }
            & Pick<TaxEntity, 'code'>
          )> }
        )>> }
      )>>, RoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerEntity' }
        & Pick<RoomLedgerEntity, 'ReferenceNo' | 'BaseAmount' | 'TrxAmount' | 'TrxDate' | 'ServiceCharge' | 'TaxAmount' | 'TransactionType'>
        & { Transaction?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description'>
        )>, TaxRoomLedger?: Maybe<Array<(
          { __typename?: 'RoomLedgerTaxEntity' }
          & Pick<RoomLedgerTaxEntity, 'ID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'RoomLedgerID' | 'TaxSchemeID'>
          & { TaxInfo?: Maybe<(
            { __typename?: 'taxEntity' }
            & Pick<TaxEntity, 'code'>
          )> }
        )>> }
      )>>, DepositLedger?: Maybe<Array<(
        { __typename?: 'DepositLedgerEntity' }
        & Pick<DepositLedgerEntity, 'ID' | 'DepositAmount' | 'PaymentType' | 'ReferenceNo' | 'DepositClass' | 'ReversedID'>
      )>> }
    )>>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ArrivalDate' | 'CheckInDate' | 'CheckOutDate' | 'DepartureDate'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
          )> }
        )> }
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount' | 'Source' | 'ArrivalDate' | 'DepartureDate' | 'CreatedBy'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
          )> }
        )> }
      )>, TaxLedger?: Maybe<Array<(
        { __typename?: 'TaxLedgerEntity' }
        & Pick<TaxLedgerEntity, 'TaxType' | 'TaxDescription' | 'TaxAmount'>
      )>>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
        & { Registrations?: Maybe<Array<(
          { __typename?: 'RegistrationEntity' }
          & Pick<RegistrationEntity, 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
          & { Guest?: Maybe<(
            { __typename?: 'GuestProfileEntity' }
            & { Contact?: Maybe<(
              { __typename?: 'ContactEntity' }
              & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'Email'>
              & { Address?: Maybe<(
                { __typename?: 'Address' }
                & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
              )> }
            )> }
          )> }
        )>>, RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code'>
        )> }
      )> }
    )>> }
  )> }
);

export type BiPaymentListingTestQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiPaymentListingTestQuery = (
  { __typename?: 'Query' }
  & { BIPaymentListingTest: Array<(
    { __typename?: 'PaymentListing123' }
    & Pick<PaymentListing123, 'RegistrationID' | 'Amount' | 'ReceiptNo' | 'ReferenceNo' | 'PaymentType' | 'PaymentStatus' | 'PaymentClass' | 'PaymentDate' | 'CreatedDT' | 'CreatedBy'>
    & { Result?: Maybe<Array<(
      { __typename?: 'RegistrationResult' }
      & { Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ArrivalDate' | 'DepartureDate' | 'NoOfAdult' | 'IsPrincipal'>
      )>, BillLedger?: Maybe<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'Description' | 'BaseAmount'>
      )>, BillSchedule?: Maybe<(
        { __typename?: 'BillScheduleEntity' }
        & Pick<BillScheduleEntity, 'Description' | 'BaseAmount'>
      )>, AdvancePayment?: Maybe<(
        { __typename?: 'AdvancePaymentEntity' }
        & Pick<AdvancePaymentEntity, 'ReferenceNo'>
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'BookingNo'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
      )>, GuestProfile?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'Email'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'siteName' | 'address'>
        )> }
      )>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code'>
      )> }
    )>> }
  )> }
);

export type BiRegistrationListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiRegistrationListingQuery = (
  { __typename?: 'Query' }
  & { BIRegistrationListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'BookingNo' | 'BookingStatus' | 'ArrivalDate' | 'TotalRoomRate' | 'DepartureDate' | 'BillingInstruction'>
    & { Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomRate' | 'DiscountAmount' | 'Signature' | 'SpecialRequest' | 'RoomRateDisplay' | 'CheckInDate' | 'CheckOutDate' | 'NoOfAdult' | 'NoOfInfant' | 'NoOfChild'>
      & { RegistrationSignature?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )>, Payment?: Maybe<Array<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'ReferenceNo' | 'Amount'>
      )>>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
        & { RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'PassportExpireDate' | 'Nationality' | 'PassportNo' | 'BirthDate' | 'FullName' | 'NRIC' | 'Email' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode' | 'AddressType'>
          )> }
        )> }
      )> }
    )>>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'DebtorAccount' | 'Name'>
    )> }
  )> }
);

export type BiSharedGAlleryInsertMutationVariables = Exact<{
  HotelID: Scalars['String'];
  AttachmentInput: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type BiSharedGAlleryInsertMutation = (
  { __typename?: 'Mutation' }
  & { BISharedGAlleryInsert: (
    { __typename?: 'SharedGalleryEntity' }
    & Pick<SharedGalleryEntity, 'ID'>
    & { Gallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ImageURL'>
    )> }
  ) }
);

export type BiRoomRateListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  Status?: Maybe<Scalars['String']>;
  GuestID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type BiRoomRateListingQuery = (
  { __typename?: 'Query' }
  & { BIRoomRateListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'BookingStatus' | 'Source' | 'GroupName' | 'TotalAdvPayment' | 'TotalRoomRate' | 'ModifiedBy' | 'ModifiedDT'>
    & { Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'Name' | 'CreditLimit' | 'DebtorAccount' | 'Segment' | 'Password' | 'Login'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'Email' | 'MobileNo'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'MobileNo' | 'PhoneNo' | 'PassportNo' | 'NRIC'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RegistrationStatus' | 'PromoCode' | 'TotalPackagePrice' | 'SpecialRequest' | 'TotalPrice' | 'DepositSum' | 'PaymentSum' | 'CheckOutDate' | 'IncidentalChargesSum' | 'IsPrincipal' | 'NoOfAdult' | 'NoOfChild' | 'RoomRate' | 'DiscountAmount'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'ID' | 'MaxGuest' | 'Code'>
      )>, BillSchedule?: Maybe<Array<(
        { __typename?: 'BillScheduleEntity' }
        & Pick<BillScheduleEntity, 'ID' | 'TransactionType' | 'ServiceCharge' | 'TaxAmount' | 'TrxDate' | 'BaseAmount' | 'TrxAmount' | 'AdvancePaymentID' | 'ReversedBillScheduleID' | 'IsPosted'>
      )>>, TaxLedger?: Maybe<Array<(
        { __typename?: 'TaxLedgerEntity' }
        & Pick<TaxLedgerEntity, 'ID' | 'TaxType' | 'TaxDescription' | 'TaxAmount' | 'TrxDate'>
      )>>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'TrxAmount' | 'ReversedBillLedgerID' | 'PaymentID' | 'Reason'>
      )>>, DepositLedger?: Maybe<Array<(
        { __typename?: 'DepositLedgerEntity' }
        & Pick<DepositLedgerEntity, 'ID' | 'FolioID' | 'DepositAmount' | 'DepositClass' | 'ReversedID' | 'ReferenceNo'>
      )>>, RoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerEntity' }
        & Pick<RoomLedgerEntity, 'ID' | 'TrxAmount' | 'TaxAmount' | 'CreatedBy'>
      )>>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
        & { RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'ID' | 'Code'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'ContactID' | 'VehicleNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Nationality' | 'MobileNo' | 'NRIC' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'state' | 'country' | 'postCode' | 'city'>
          )> }
        )> }
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )> }
    )>> }
  )> }
);

export type AllBiListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type AllBiListingQuery = (
  { __typename?: 'Query' }
  & { BIServiceRequestListing: Array<(
    { __typename?: 'ServiceRequestEntity' }
    & Pick<ServiceRequestEntity, 'ID' | 'Description' | 'RegistrationID' | 'RequestBy' | 'CreatedDT' | 'Title' | 'Remarks' | 'Status' | 'EstimatedCompletion'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'ID' | 'Code' | 'Name'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
      & { Registrations?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'CheckInDate' | 'CheckOutDate' | 'ID' | 'IsPrincipal'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & Pick<GuestProfileEntity, 'ID'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )>> }
    )> }
  )>, BIAnalysis: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName' | 'Source'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name'>
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description' | 'DepartmentID'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'IsPrincipal' | 'RegistrationStatus' | 'NoOfAdult' | 'NoOfChild' | 'RatePolicyID' | 'CheckInDate' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
        & { IncidentalCharge?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description' | 'Amount'>
        )> }
      )>>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
        & { RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code' | 'Description' | 'ID'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'Nationality'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state' | 'AddressType'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type BiAnalysisQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BiAnalysisQuery = (
  { __typename?: 'Query' }
  & { BIAnalysis: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName' | 'Source'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name'>
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description' | 'DepartmentID'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'NoOfAdult' | 'NoOfChild' | 'RatePolicyID' | 'CheckInDate' | 'IsPrincipal' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
        & { IncidentalCharge?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description' | 'Amount'>
        )> }
      )>>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
        & { RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code' | 'Description' | 'ID'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'Nationality'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state' | 'AddressType'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type BiSourceQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BiSourceQuery = (
  { __typename?: 'Query' }
  & { BISource: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName' | 'Source'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'NoOfAdult' | 'NoOfChild' | 'RatePolicyID' | 'CheckInDate' | 'IsPrincipal' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
      )>> }
    )>> }
  )> }
);

export type BiSourceV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiSourceV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BISourceV2'>
);

export type BiSourceV3QueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiSourceV3Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BISourceV3'>
);

export type BiTop10CountryV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiTop10CountryV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BITop10CountryV2'>
);

export type BiTop10CountryQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BiTop10CountryQuery = (
  { __typename?: 'Query' }
  & { BITop10Country: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName'>
    & { Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'NoOfAdult' | 'NoOfChild' | 'RatePolicyID' | 'CheckInDate' | 'IsPrincipal' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
      )>>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'Nationality'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state' | 'AddressType'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type BiTop10NationalityQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BiTop10NationalityQuery = (
  { __typename?: 'Query' }
  & { BITop10Nationality: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName'>
    & { Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'NoOfAdult' | 'NoOfChild' | 'RatePolicyID' | 'CheckInDate' | 'IsPrincipal' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
      )>>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'Nationality'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state' | 'AddressType'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type BiTop10NationalityV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiTop10NationalityV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BITop10NationalityV2'>
);

export type BiCorpGovTaQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BiCorpGovTaQuery = (
  { __typename?: 'Query' }
  & { BICorpGovTa: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'NoOfAdult' | 'NoOfChild' | 'RatePolicyID' | 'CheckInDate' | 'IsPrincipal' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
      )>> }
    )>> }
  )> }
);

export type BiCorpGovTaV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiCorpGovTaV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BICorpGovTaV2'>
);

export type BiMarketingPersonnalQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BiMarketingPersonnalQuery = (
  { __typename?: 'Query' }
  & { BIMarketingPersonnal: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName' | 'Source'>
    & { SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description' | 'DepartmentID'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'NoOfAdult' | 'NoOfChild' | 'RatePolicyID' | 'CheckInDate' | 'IsPrincipal' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
      )>> }
    )>> }
  )> }
);

export type BiRoomTypeRevenueDetailV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Mode: Scalars['String'];
}>;


export type BiRoomTypeRevenueDetailV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BIRoomTypeRevenueDetailV2'>
);

export type BiRevenueByRoomTypeAnalysisQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Mode: Scalars['String'];
}>;


export type BiRevenueByRoomTypeAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BIRevenueByRoomTypeAnalysis'>
);

export type BiMarketingPersonnalV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiMarketingPersonnalV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BIMarketingPersonnalV2'>
);

export type BiRoomTypeRevenueDetailQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BiRoomTypeRevenueDetailQuery = (
  { __typename?: 'Query' }
  & { BIRoomTypeRevenueDetail: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName' | 'Source'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name'>
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description' | 'DepartmentID'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'NoOfAdult' | 'NoOfChild' | 'RatePolicyID' | 'CheckInDate' | 'IsPrincipal' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
        & { IncidentalCharge?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description' | 'Amount'>
        )> }
      )>>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
        & { RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code' | 'Description' | 'ID'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'Nationality'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state' | 'AddressType'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type BiTop5OtaQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BiTop5OtaQuery = (
  { __typename?: 'Query' }
  & { BITop5OTA: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName' | 'Source'>
    & { Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'NoOfAdult' | 'NoOfChild' | 'RatePolicyID' | 'CheckInDate' | 'IsPrincipal' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
      )>> }
    )>> }
  )> }
);

export type BiSegmentQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BiSegmentQuery = (
  { __typename?: 'Query' }
  & { BISegment: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName'>
    & { Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'CheckInDate' | 'IsPrincipal' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount' | 'NoOfAdult' | 'NoOfChild'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
        & { IncidentalCharge?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description' | 'Amount'>
        )> }
      )>> }
    )>> }
  )> }
);

export type BiSegmentV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiSegmentV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BISegmentV2'>
);

export type BiTop10RevenueQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type BiTop10RevenueQuery = (
  { __typename?: 'Query' }
  & { BITop10Revenue: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'BookingStatus' | 'CreatedDT' | 'ArrivalDate' | 'DepartureDate' | 'GroupName' | 'Source'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'RatePolicyID' | 'CheckInDate' | 'IsPrincipal' | 'CheckOutDate' | 'RoomRate' | 'DiscountAmount'>
      & { BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'Description' | 'Qty' | 'ServiceCharge' | 'DiscountAmount'>
        & { IncidentalCharge?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description' | 'Amount'>
        )> }
      )>>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
        & { RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code' | 'Description' | 'ID'>
        )> }
      )> }
    )>> }
  )> }
);

export type BiHotelDetailsQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type BiHotelDetailsQuery = (
  { __typename?: 'Query' }
  & { BIHotelDetails: (
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'IsActive' | 'CreatedBy' | 'ModifiedBy' | 'AccountID' | 'EmailBlastDays' | 'SSTRegNo' | 'CompanyRegNo' | 'Logo' | 'HotelType' | 'HotelName' | 'ContactID' | 'CheckInTime' | 'CheckOutTime' | 'Currency' | 'NAProcessTime' | 'IsPostPaid' | 'Tax' | 'ServiceCharge' | 'IsInclusive' | 'TTx' | 'HTx' | 'Levy' | 'CheckInPrompt' | 'SecurityDeposit' | 'KeycardDeposit' | 'CheckInRoomStatusID' | 'CancelCheckInRoomStatusID' | 'TransferRoomStatusID' | 'CheckOutRoomStatusID' | 'CancelCheckOutRoomStatusID' | 'BlockRoomReleaseStatusID' | 'CeilingPercentage' | 'FloorPercentage' | 'DayUseID' | 'HouseUseID' | 'ComplimentaryID' | 'FloorPriceKickInDays' | 'AllowCancellationDays' | 'OnlineTnC' | 'RegCardTnC' | 'OnlineBookingURL' | 'AboutHotelGalleryID' | 'AboutHotel' | 'ChannelManagerID' | 'PaymentGatewayID'>
    & { HotelAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
    )>, ARSpecification?: Maybe<(
      { __typename?: 'ARSpecification' }
      & Pick<ArSpecification, 'InterestAccount'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'MobileNo' | 'GeneralEmail' | 'ReservationEmail' | 'BusinessEmail' | 'WebUrl' | 'NotificationMobileNo'>
    )>, Facility?: Maybe<Array<(
      { __typename?: 'FacilityEntity' }
      & Pick<FacilityEntity, 'ID' | 'HotelID' | 'IsActive' | 'Title' | 'Description' | 'Level'>
      & { FacilityGallery?: Maybe<Array<(
        { __typename?: 'FacilityGalleryEntity' }
        & Pick<FacilityGalleryEntity, 'ID' | 'FacilityID' | 'GalleryID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
        )> }
      )>> }
    )>>, News?: Maybe<Array<(
      { __typename?: 'NewsEntity' }
      & Pick<NewsEntity, 'ID' | 'IsActive' | 'Description' | 'Title' | 'HotelID'>
      & { NewsGallery?: Maybe<Array<(
        { __typename?: 'NewsGalleryEntity' }
        & Pick<NewsGalleryEntity, 'ID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
        )> }
      )>> }
    )>>, DayUse?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
    )>, HouseUse?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
    )>, Complimentary?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
    )>, AboutHotelImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
    )>, LogoImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
    )> }
  ) }
);

export type BiServiceRequestListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Department?: Maybe<Scalars['String']>;
}>;


export type BiServiceRequestListingQuery = (
  { __typename?: 'Query' }
  & { BIServiceRequestListing: Array<(
    { __typename?: 'ServiceRequestEntity' }
    & Pick<ServiceRequestEntity, 'ID' | 'Description' | 'RegistrationID' | 'RequestBy' | 'CreatedDT' | 'Title' | 'Remarks' | 'Status' | 'EstimatedCompletion'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'ID' | 'Code' | 'Name'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
      & { Registrations?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'CheckInDate' | 'CheckOutDate' | 'ID' | 'IsPrincipal'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & Pick<GuestProfileEntity, 'ID'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type BiRoomListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type BiRoomListingQuery = (
  { __typename?: 'Query' }
  & { BIRoomListing: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'IsActive' | 'HotelID' | 'LocationID' | 'RoomTypeID' | 'RoomNo' | 'MaxGuest' | 'RoomSize' | 'DisplaySequence' | 'Description'>
  )> }
);

export type ManagerReportQueryVariables = Exact<{
  HotelID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
}>;


export type ManagerReportQuery = (
  { __typename?: 'Query' }
  & { ManagerReport: (
    { __typename?: 'ManagerReportOutput' }
    & { SegmentReport?: Maybe<Array<(
      { __typename?: 'SegmentReportOutput' }
      & Pick<SegmentReportOutput, 'SegmentID' | 'SegmentName' | 'SegmentYRoomCount' | 'SegmentYGuestCount' | 'SegmentYRoomRevenue' | 'SegmentYOtherRevenue' | 'SegmentYARR' | 'SegmentMRoomCount' | 'SegmentMGuestCount' | 'SegmentMRoomRevenue' | 'SegmentMOtherRevenue' | 'SegmentMARR' | 'SegmentDRoomCount' | 'SegmentDGuestCount' | 'SegmentDRoomRevenue' | 'SegmentDOtherRevenue' | 'SegmentDARR'>
    )>>, SourceReport?: Maybe<Array<(
      { __typename?: 'SourceReportOutput' }
      & Pick<SourceReportOutput, 'SourceName' | 'SourceYRoomCount' | 'SourceYGuestCount' | 'SourceYRoomRevenue' | 'SourceYOtherRevenue' | 'SourceYARR' | 'SourceMRoomCount' | 'SourceMGuestCount' | 'SourceMRoomRevenue' | 'SourceMOtherRevenue' | 'SourceMARR' | 'SourceDRoomCount' | 'SourceDGuestCount' | 'SourceDRoomRevenue' | 'SourceDOtherRevenue' | 'SourceDARR'>
    )>>, CountryReport?: Maybe<Array<(
      { __typename?: 'CountryReportOutput' }
      & Pick<CountryReportOutput, 'CountryName' | 'CountryYRoomCount' | 'CountryYGuestCount' | 'CountryYRoomRevenue' | 'CountryYOtherRevenue' | 'CountryYARR' | 'CountryMRoomCount' | 'CountryMGuestCount' | 'CountryMRoomRevenue' | 'CountryMOtherRevenue' | 'CountryMARR' | 'CountryDRoomCount' | 'CountryDGuestCount' | 'CountryDRoomRevenue' | 'CountryDOtherRevenue' | 'CountryDARR'>
    )>> }
  ) }
);

export type BiTransactionCancellationQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiTransactionCancellationQuery = (
  { __typename?: 'Query' }
  & { BITransactionCancellation: Array<(
    { __typename?: 'BillLedgerEntity' }
    & Pick<BillLedgerEntity, 'CreatedBy' | 'ID' | 'RegistrationID' | 'TrxDate' | 'Qty' | 'UnitPrice' | 'TrxAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Description' | 'ReferenceNo' | 'PaymentID' | 'Reason'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )>, IncidentalCharge?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'Description' | 'DepartmentID'>
      & { Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'Code' | 'Name'>
      )> }
    )> }
  )> }
);

export type BiForeignListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiForeignListingQuery = (
  { __typename?: 'Query' }
  & { BIForeignListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'ArrivalDate' | 'DepartureDate'>
    & { Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'IsPrincipal'>
      & { TaxLedger?: Maybe<Array<(
        { __typename?: 'TaxLedgerEntity' }
        & Pick<TaxLedgerEntity, 'ID' | 'TaxAmount' | 'TaxType' | 'TaxDescription'>
      )>>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Nationality' | 'MobileNo' | 'PassportNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type BiSecurityKeyCardDepositQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiSecurityKeyCardDepositQuery = (
  { __typename?: 'Query' }
  & { BISecurityKeyCardDeposit: Array<(
    { __typename?: 'DepositLedgerEntity' }
    & Pick<DepositLedgerEntity, 'ID' | 'TrxDate' | 'ReferenceNo' | 'DepositAmount' | 'DepositClass' | 'ReversedID' | 'CreatedBy'>
    & { Folio?: Maybe<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'FolioAmount' | 'FolioNo'>
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )> }
  )> }
);

export type BiRoomDiscrepancyLogQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type BiRoomDiscrepancyLogQuery = (
  { __typename?: 'Query' }
  & { BIRoomDiscrepancyLog: Array<(
    { __typename?: 'RoomDiscrepancyLogEntity' }
    & Pick<RoomDiscrepancyLogEntity, 'ID' | 'DiscrepancyReason' | 'CreatedBy' | 'CreatedDT' | 'ModifiedDT'>
    & { RoomStatus?: Maybe<(
      { __typename?: 'RoomStatusEntity' }
      & Pick<RoomStatusEntity, 'ID' | 'Description' | 'Code'>
    )>, HouseKeepingStatus?: Maybe<(
      { __typename?: 'RoomStatusEntity' }
      & Pick<RoomStatusEntity, 'ID' | 'Description' | 'Code'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
      & { Location?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'Description'>
      )>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code'>
      )> }
    )> }
  )> }
);

export type BiRoomStatusQueryVariables = Exact<{
  HotelID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
}>;


export type BiRoomStatusQuery = (
  { __typename?: 'Query' }
  & { BIRoomStatus: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'RoomNo'>
    & { Location?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'Code'>
    )>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'Code'>
    )>, RoomStatus?: Maybe<(
      { __typename?: 'RoomStatusEntity' }
      & Pick<RoomStatusEntity, 'Code'>
    )>, Registrations?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RegistrationStatus' | 'SpecialRequest' | 'IsPrincipal'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ArrivalDate' | 'DepartureDate' | 'SpecialRequest'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>> }
  )> }
);

export type BiBookingStatusQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiBookingStatusQuery = (
  { __typename?: 'Query' }
  & { BIBookingStatus: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'GroupName' | 'ArrivalDate' | 'DepartureDate' | 'BookingStatus' | 'BookingNo' | 'TotalAdvPayment' | 'TotalRoomRate' | 'SpecialRequest' | 'CreatedDT'>
    & { AdvancePayment?: Maybe<Array<(
      { __typename?: 'AdvancePaymentEntity' }
      & Pick<AdvancePaymentEntity, 'PaymentStatus' | 'Amount'>
    )>>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'Name'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'Description'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'IsPrincipal' | 'NoOfAdult' | 'RoomRate' | 'DiscountAmount' | 'SpecialRequest' | 'DepositSum' | 'TotalRoomPrice' | 'TotalRoomTax'>
      & { Payment?: Maybe<Array<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'PaymentStatus'>
      )>>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'Nationality'>
        )> }
      )> }
    )>>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'Nationality'>
    )> }
  )> }
);

export type BiTransactionLedgerQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiTransactionLedgerQuery = (
  { __typename?: 'Query' }
  & { BITransactionLedger: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'BookingNo'>
    & { Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'IsPrincipal'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, RoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerEntity' }
        & Pick<RoomLedgerEntity, 'ID' | 'TrxAmount' | 'TaxAmount' | 'TrxDate' | 'BaseAmount' | 'TransactionID' | 'ServiceCharge' | 'ReferenceNo' | 'ReversedRoomLedgerID' | 'ChargeType' | 'Description' | 'CreatedBy'>
        & { Transaction?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'ID' | 'Description'>
        )> }
      )>>, TaxLedger?: Maybe<Array<(
        { __typename?: 'TaxLedgerEntity' }
        & Pick<TaxLedgerEntity, 'ReversedTaxLedgerID' | 'TrxDate' | 'TaxType' | 'TaxDescription' | 'TaxAmount' | 'CreatedBy'>
      )>>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'ID' | 'TrxAmount' | 'TaxAmount' | 'TrxDate' | 'BaseAmount' | 'ServiceCharge' | 'DiscountAmount' | 'ReferenceNo' | 'ReversedBillLedgerID' | 'Description' | 'CreatedBy'>
        & { IncidentalCharge?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'Description'>
        )> }
      )>> }
    )>> }
  )> }
);

export type BiPackageQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type BiPackageQuery = (
  { __typename?: 'Query' }
  & { BIPackage: Array<(
    { __typename?: 'BillScheduleEntity' }
    & Pick<BillScheduleEntity, 'ID' | 'TransactionType' | 'TrxDate'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'NoOfAdult' | 'NoOfChild' | 'NoOfInfant'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ArrivalDate' | 'DepartureDate' | 'BookingStatus'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>, Transaction?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'Description'>
    )> }
  )> }
);

export type BiInHouseQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type BiInHouseQuery = (
  { __typename?: 'Query' }
  & { BIInHouse: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'SpecialRequest' | 'NoOfAdult' | 'NoOfChild' | 'RoomRate' | 'CheckInDate' | 'CheckOutDate'>
    & { RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'PaymentID' | 'ReversedRoomLedgerID' | 'BaseAmount' | 'ServiceCharge' | 'TaxAmount'>
    )>>, BillSchedule?: Maybe<Array<(
      { __typename?: 'BillScheduleEntity' }
      & Pick<BillScheduleEntity, 'IsPosted' | 'ReversedBillScheduleID' | 'AdvancePaymentID' | 'Reason' | 'BaseAmount' | 'ServiceCharge' | 'TaxAmount'>
    )>>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'PaymentID' | 'ReversedBillLedgerID' | 'Reason' | 'BaseAmount' | 'ServiceCharge' | 'TaxAmount'>
    )>>, TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'TaxAmount'>
    )>>, AdvancePayment?: Maybe<Array<(
      { __typename?: 'AdvancePaymentEntity' }
      & Pick<AdvancePaymentEntity, 'IsPosted' | 'PaymentStatus' | 'ReversedAdvancePaymentID' | 'Amount'>
    )>>, RatePolicy?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'RateCode'>
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'VehicleNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'Nationality' | 'MobileNo'>
      )> }
    )>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'GroupName' | 'ArrivalDate' | 'DepartureDate' | 'IsGroupBooking' | 'SpecialRequest'>
      & { Registration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
      )>>, Debtor?: Maybe<(
        { __typename?: 'DebtorEntity' }
        & Pick<DebtorEntity, 'Name'>
      )> }
    )>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'Code'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
    )> }
  )> }
);

export type ManagerReportDepartmentQueryVariables = Exact<{
  HotelID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
}>;


export type ManagerReportDepartmentQuery = (
  { __typename?: 'Query' }
  & { ManagerReportDepartment: (
    { __typename?: 'ManagerReportOutput' }
    & { DepartmentReport?: Maybe<Array<(
      { __typename?: 'DepartmentReportOutput' }
      & Pick<DepartmentReportOutput, 'DepartmentName' | 'TransactionName' | 'TransactionDAmount' | 'TransactionMAmount' | 'TransactionYAmount'>
    )>>, TaxReport?: Maybe<Array<(
      { __typename?: 'TaxReportOutput' }
      & Pick<TaxReportOutput, 'DepartmentName' | 'TransactionName' | 'TransactionDAmount' | 'TransactionMAmount' | 'TransactionYAmount'>
    )>>, PaymentReport?: Maybe<Array<(
      { __typename?: 'PaymentOutput' }
      & Pick<PaymentOutput, 'DepartmentName' | 'TransactionName' | 'TransactionDAmount' | 'TransactionMAmount' | 'TransactionYAmount'>
    )>>, BookingCancellation?: Maybe<Array<(
      { __typename?: 'BookingCancellation' }
      & Pick<BookingCancellation, 'GuestName' | 'RoomNo' | 'NoOfGuest' | 'ArrivalDate' | 'DepartureDate' | 'NewExtendDate' | 'SpecialRequest' | 'RateAmount' | 'BookingNo'>
    )>>, VIPInHouse?: Maybe<Array<(
      { __typename?: 'VIPInHouseOutput' }
      & Pick<VipInHouseOutput, 'GuestName' | 'RoomNo' | 'NoOfGuest' | 'ArrivalDate' | 'DepartureDate' | 'SpecialRequest'>
    )>>, GroupInHouse?: Maybe<Array<(
      { __typename?: 'GroupInHouseOutput' }
      & Pick<GroupInHouseOutput, 'GroupName' | 'NoOfRoom' | 'NoOfGuest' | 'ArrivalDate' | 'DepartureDate' | 'SpecialRequest'>
    )>>, TomorrowGroup?: Maybe<Array<(
      { __typename?: 'TmrGroupOutput' }
      & Pick<TmrGroupOutput, 'GroupName' | 'NoOfRoom' | 'NoOfGuest' | 'ArrivalDate' | 'DepartureDate' | 'SpecialRequest'>
    )>>, HouseUseRoom?: Maybe<Array<(
      { __typename?: 'HouseUseOutput' }
      & Pick<HouseUseOutput, 'GuestName' | 'RoomNo' | 'NoOfGuest' | 'ArrivalDate' | 'DepartureDate' | 'SpecialRequest'>
    )>>, CompRoom?: Maybe<Array<(
      { __typename?: 'CompOutput' }
      & Pick<CompOutput, 'GuestName' | 'RoomNo' | 'NoOfGuest' | 'ArrivalDate' | 'DepartureDate' | 'SpecialRequest'>
    )>>, ExtendStay?: Maybe<Array<(
      { __typename?: 'ExtendStayOutPut' }
      & Pick<ExtendStayOutPut, 'GuestName' | 'RoomNo' | 'NoOfGuest' | 'ArrivalDate' | 'DepartureDate' | 'NewExtendDate' | 'SpecialRequest'>
    )>>, ThreeDayForcast?: Maybe<Array<(
      { __typename?: 'ForecastOutPut' }
      & Pick<ForecastOutPut, 'Category' | 'TodayDate' | 'TomorrowDate' | 'DayAfterTomorrow'>
    )>> }
  ) }
);

export type DebtorFolioInfoQueryVariables = Exact<{
  DebtorAccount?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
}>;


export type DebtorFolioInfoQuery = (
  { __typename?: 'Query' }
  & { DebtorFolioInfo?: Maybe<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'Name' | 'DebtorAccount'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
      )> }
    )> }
  )> }
);

export type FolioDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type FolioDetailsQuery = (
  { __typename?: 'Query' }
  & { FolioDetails: (
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'FolioNo' | 'FolioType' | 'FolioDate' | 'FolioAmount' | 'BeneficiaryName' | 'BeneficiaryAddress' | 'CreatedBy' | 'ClosedDate' | 'ClosedBy' | 'IsSuspend' | 'DocumentType' | 'einvoice_qr_url' | 'is_einvoice' | 'einvoice_status' | 'einvoice_directsend' | 'QrCodeUrl'>
    & { NonGuestInfo?: Maybe<(
      { __typename?: 'NonGuestInfoJson' }
      & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit'>
    )>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ReferenceNo' | 'GroupName' | 'ArrivalDate' | 'DepartureDate'>
      & { Debtor?: Maybe<(
        { __typename?: 'DebtorEntity' }
        & Pick<DebtorEntity, 'DebtorAccount' | 'Name' | 'ID'>
      )>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, Registration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
        & { Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )>, Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'NRIC' | 'PassportNo'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
            )> }
          )> }
        )> }
      )>> }
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'NRIC' | 'PassportNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
          )> }
        )> }
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'BookingNo' | 'isPrepaid' | 'ReferenceNo'>
        & { BookedRegistration?: Maybe<Array<(
          { __typename?: 'RegistrationEntity' }
          & Pick<RegistrationEntity, 'ID'>
          & { Room?: Maybe<(
            { __typename?: 'RoomEntity' }
            & Pick<RoomEntity, 'RoomNo'>
          )>, Guest?: Maybe<(
            { __typename?: 'GuestProfileEntity' }
            & { Contact?: Maybe<(
              { __typename?: 'ContactEntity' }
              & Pick<ContactEntity, 'FullName'>
            )> }
          )> }
        )>>, Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name' | 'DebtorAccount'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )>, AdvancePayment?: Maybe<Array<(
      { __typename?: 'AdvancePaymentEntity' }
      & Pick<AdvancePaymentEntity, 'Amount' | 'TaxAmount' | 'ServiceCharge' | 'PaymentType' | 'PaymentClass' | 'PaySessionNo' | 'ReferenceNo' | 'ID' | 'DocumentType' | 'PaymentDate' | 'ReceiptNo' | 'BookingID' | 'HotelID' | 'ReversedAdvancePaymentID' | 'IsCNAdjusted'>
      & { ForfeitTax?: Maybe<Array<(
        { __typename?: 'ForfeitTaxEntity' }
        & Pick<ForfeitTaxEntity, 'ID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'TaxSchemeID'>
        & { TaxInfo?: Maybe<(
          { __typename?: 'taxEntity' }
          & Pick<TaxEntity, 'code'>
        )> }
      )>> }
    )>>, Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'Amount' | 'PaymentType' | 'CardNo' | 'DebtorAccount' | 'ReceiptNo' | 'PaymentClass' | 'PaySessionNo' | 'PaymentDate' | 'ReversedPaymentID' | 'ReferenceNo' | 'ID' | 'DocumentType'>
      & { ForfeitTax?: Maybe<Array<(
        { __typename?: 'ForfeitTaxEntity' }
        & Pick<ForfeitTaxEntity, 'ID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'TaxSchemeID'>
        & { TaxInfo?: Maybe<(
          { __typename?: 'taxEntity' }
          & Pick<TaxEntity, 'code'>
        )> }
      )>>, Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )> }
    )>>, TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'ID' | 'TrxDate' | 'TaxType' | 'TaxDescription' | 'TaxAmount' | 'Reason' | 'Description' | 'RegistrationID' | 'Qty' | 'IsCNAdjusted'>
      & { Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )> }
    )>>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'RefBillLedgerID' | 'ReversedBillLedgerID' | 'TrxDate' | 'BaseAmount' | 'ServiceCharge' | 'TaxAmount' | 'TrxAmount' | 'Qty' | 'UnitPrice' | 'ReferenceNo' | 'RegistrationID' | 'Description' | 'IsCNAdjusted'>
      & { Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )>, IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description' | 'ID' | 'IsOutlet' | 'IsEventInterface' | 'isEInvIntegrated'>
      )>, TaxBillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerTaxEntity' }
        & Pick<BillLedgerTaxEntity, 'ID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'BillLedgerID' | 'TaxSchemeID'>
        & { TaxInfo?: Maybe<(
          { __typename?: 'taxEntity' }
          & Pick<TaxEntity, 'code'>
        )> }
      )>> }
    )>>, RoomCharges?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'TrxDate' | 'BaseAmount' | 'Description' | 'TaxAmount' | 'TrxAmount' | 'ServiceCharge' | 'RegistrationID' | 'TrxDescription' | 'LedgerID' | 'FromRegistration'>
      & { TaxRoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerTaxEntity' }
        & Pick<RoomLedgerTaxEntity, 'ID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'RoomLedgerID' | 'TaxSchemeID'>
        & { TaxInfo?: Maybe<(
          { __typename?: 'taxEntity' }
          & Pick<TaxEntity, 'code'>
        )> }
      )>> }
    )>>, RoomChargesSuspend?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'TrxDate' | 'BaseAmount' | 'Description' | 'TaxAmount' | 'TrxAmount' | 'ServiceCharge' | 'RegistrationID' | 'TrxDescription' | 'LedgerID'>
      & { TaxRoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerTaxEntity' }
        & Pick<RoomLedgerTaxEntity, 'ID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'RoomLedgerID' | 'TaxSchemeID'>
        & { TaxInfo?: Maybe<(
          { __typename?: 'taxEntity' }
          & Pick<TaxEntity, 'code'>
        )> }
      )>> }
    )>>, RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'ID' | 'RefRoomLedgerID' | 'TrxAmount' | 'TrxDate' | 'ChargeType' | 'TaxAmount' | 'BaseAmount' | 'IsCNAdjusted' | 'FromRegistration' | 'TransactionType' | 'TransactionID' | 'ServiceCharge' | 'ReferenceNo' | 'Description'>
      & { Transaction?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description'>
      )>, Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )>, TaxRoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerTaxEntity' }
        & Pick<RoomLedgerTaxEntity, 'ID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'RoomLedgerID' | 'TaxSchemeID'>
        & { TaxInfo?: Maybe<(
          { __typename?: 'taxEntity' }
          & Pick<TaxEntity, 'code'>
        )> }
      )>> }
    )>>, DepositLedger?: Maybe<Array<(
      { __typename?: 'DepositLedgerEntity' }
      & Pick<DepositLedgerEntity, 'ID' | 'DepositAmount' | 'DepositClass' | 'ReferenceNo' | 'TrxDate' | 'RegistrationID' | 'ReversedID' | 'ActualDepositClass' | 'IsCNAdjusted'>
    )>> }
  ) }
);

export type FolioDetailsWithIDsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  LedgerIDs?: Maybe<LedgerIDsInput>;
}>;


export type FolioDetailsWithIDsQuery = (
  { __typename?: 'Query' }
  & { FolioDetailsWithIDs: (
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
    & { Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'NRIC' | 'PassportNo'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
        )> }
      )> }
    )>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ReferenceNo'>
      & { Registration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'NRIC' | 'PassportNo'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
            )> }
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )>> }
    )>, AdvancePayment?: Maybe<Array<(
      { __typename?: 'AdvancePaymentEntity' }
      & Pick<AdvancePaymentEntity, 'Amount' | 'PaymentType' | 'PaymentClass' | 'PaySessionNo' | 'ReferenceNo' | 'ID'>
    )>>, TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'ID' | 'TrxDate' | 'TaxType' | 'TaxDescription' | 'TaxAmount' | 'Reason' | 'Description' | 'FolioID'>
    )>>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'BaseAmount' | 'ServiceCharge' | 'TaxAmount' | 'TrxAmount' | 'ReferenceNo'>
      & { IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description'>
      )> }
    )>>, RoomCharges?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'TrxDate' | 'BaseAmount' | 'Description' | 'TaxAmount' | 'ServiceCharge' | 'ReferenceNo' | 'Reason' | 'RegistrationID' | 'TrxDescription'>
    )>>, RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'ID' | 'TrxAmount' | 'TrxDate' | 'TaxAmount' | 'BaseAmount' | 'TransactionType' | 'FromRegistration' | 'TransactionID' | 'ServiceCharge' | 'ReferenceNo' | 'Description'>
      & { Transaction?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description'>
      )> }
    )>>, DepositLedger?: Maybe<Array<(
      { __typename?: 'DepositLedgerEntity' }
      & Pick<DepositLedgerEntity, 'DepositAmount' | 'DepositClass' | 'ReferenceNo' | 'TrxDate'>
    )>> }
  ) }
);

export type PaymentDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type PaymentDetailsQuery = (
  { __typename?: 'Query' }
  & { PaymentDetails: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ID' | 'ReceiptNo' | 'ReferenceNo' | 'PaymentDate' | 'PaymentType' | 'Amount' | 'CreatedBy' | 'PaymentStatus' | 'PaymentClass' | 'DebtorAccount' | 'CardNo' | 'DocumentType'>
    & { Folio?: Maybe<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'FolioNo' | 'ID' | 'FolioType' | 'BeneficiaryName' | 'BeneficiaryAddress' | 'DocumentType'>
      & { NonGuestInfo?: Maybe<(
        { __typename?: 'NonGuestInfoJson' }
        & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit'>
      )>, DepositLedger?: Maybe<Array<(
        { __typename?: 'DepositLedgerEntity' }
        & Pick<DepositLedgerEntity, 'DepositClass' | 'ReversedID' | 'Remarks'>
      )>> }
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount'>
        & { Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'NRIC' | 'PassportNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type DepositClassQueryVariables = Exact<{
  ReversedID: Scalars['String'];
}>;


export type DepositClassQuery = (
  { __typename?: 'Query' }
  & { DepositClass: (
    { __typename?: 'DepositLedgerEntity' }
    & Pick<DepositLedgerEntity, 'ID' | 'DepositClass'>
  ) }
);

export type DepositForfeitlistQueryVariables = Exact<{
  ReversedID: Scalars['String'];
}>;


export type DepositForfeitlistQuery = (
  { __typename?: 'Query' }
  & { DepositForfeitlist: Array<(
    { __typename?: 'DepositLedgerEntity' }
    & Pick<DepositLedgerEntity, 'ID' | 'DepositClass' | 'ReversedID' | 'FolioID' | 'PaymentType' | 'TrxDate' | 'ReferenceNo' | 'Remarks' | 'DepositAmount'>
    & { Folio?: Maybe<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'ID'>
      & { Payment?: Maybe<Array<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'ID' | 'ReceiptNo'>
      )>> }
    )> }
  )> }
);

export type DepositRefundlistQueryVariables = Exact<{
  ReversedID: Scalars['String'];
}>;


export type DepositRefundlistQuery = (
  { __typename?: 'Query' }
  & { DepositRefundlist: Array<(
    { __typename?: 'DepositLedgerEntity' }
    & Pick<DepositLedgerEntity, 'ID' | 'DepositClass' | 'ReversedID' | 'FolioID' | 'PaymentType' | 'TrxDate' | 'ReferenceNo' | 'Remarks' | 'DepositAmount'>
    & { Folio?: Maybe<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'ID'>
      & { Payment?: Maybe<Array<(
        { __typename?: 'PaymentEntity' }
        & Pick<PaymentEntity, 'ID' | 'ReceiptNo'>
      )>> }
    )> }
  )> }
);

export type AdvancePaymentDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type AdvancePaymentDetailsQuery = (
  { __typename?: 'Query' }
  & { AdvancePaymentDetails: (
    { __typename?: 'AdvancePaymentEntity' }
    & Pick<AdvancePaymentEntity, 'ID' | 'ReceiptNo' | 'ReferenceNo' | 'PaymentDate' | 'PaymentType' | 'Amount' | 'TaxAmount' | 'ServiceCharge' | 'CreatedBy' | 'PaymentStatus' | 'PaymentClass' | 'DebtorAccount' | 'DocumentType' | 'ReversedAdvancePaymentID'>
    & { Folio?: Maybe<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'ID' | 'FolioDate' | 'FolioNo' | 'BeneficiaryName' | 'BeneficiaryAddress' | 'DocumentType'>
    )>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount' | 'IsGroupBooking' | 'GroupName' | 'ArrivalDate' | 'DepartureDate'>
      & { Debtor?: Maybe<(
        { __typename?: 'DebtorEntity' }
        & Pick<DebtorEntity, 'ID' | 'Name' | 'DebtorAccount'>
      )>, Registration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
        & { Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )>, Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'NRIC' | 'PassportNo'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
            )> }
          )> }
        )> }
      )>>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'NRIC' | 'PassportNo'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
        )> }
      )> }
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type AdvancePaymentReversedDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type AdvancePaymentReversedDetailsQuery = (
  { __typename?: 'Query' }
  & { AdvancePaymentReversedDetails: Array<(
    { __typename?: 'AdvancePaymentEntity' }
    & Pick<AdvancePaymentEntity, 'ID' | 'ReceiptNo' | 'ReferenceNo' | 'PaymentDate' | 'PaymentType' | 'Amount' | 'CreatedBy' | 'PaymentStatus' | 'PaymentClass' | 'DebtorAccount' | 'ReversedAdvancePaymentID'>
  )> }
);

export type RegistrationDetailsQueryVariables = Exact<{
  BookingID: Scalars['String'];
  GuestID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type RegistrationDetailsQuery = (
  { __typename?: 'Query' }
  & { RegistrationDetails: (
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal' | 'RoomRate' | 'IsPDPA' | 'DiscountAmount' | 'TotalRoomPrice' | 'NoOfAdult' | 'PrintRate' | 'RoomRateDisplay'>
    & { RegistrationSignature?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
    )>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'Code'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'VehicleNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'Designation' | 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'BirthDate' | 'Nationality' | 'NRIC' | 'PassportNo' | 'PassportExpireDate'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
        )> }
      )> }
    )>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount' | 'BillingInstruction'>
      & { Debtor?: Maybe<(
        { __typename?: 'DebtorEntity' }
        & Pick<DebtorEntity, 'ID' | 'Name'>
      )> }
    )> }
  ) }
);

export type RegistrationDetailsMultiQueryVariables = Exact<{
  InputReg?: Maybe<Array<InputReg> | InputReg>;
}>;


export type RegistrationDetailsMultiQuery = (
  { __typename?: 'Query' }
  & { RegistrationDetailsMulti: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal' | 'RoomRate' | 'IsPDPA' | 'DiscountAmount' | 'TotalRoomPrice' | 'NoOfAdult' | 'PrintRate' | 'RoomRateDisplay' | 'SpecialRequest'>
    & { RegistrationSignature?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
    )>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'Code'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'VehicleNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'Designation' | 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'BirthDate' | 'Nationality' | 'NRIC' | 'PassportNo' | 'PassportExpireDate'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'address2' | 'city' | 'country' | 'postCode' | 'state'>
        )> }
      )> }
    )>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount' | 'BillingInstruction'>
      & { Debtor?: Maybe<(
        { __typename?: 'DebtorEntity' }
        & Pick<DebtorEntity, 'ID' | 'Name'>
      )> }
    )> }
  )> }
);

export type HotelStatisticListingQueryVariables = Exact<{
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  HouseUse?: Maybe<Scalars['Boolean']>;
  DayUse?: Maybe<Scalars['Boolean']>;
  Complimentary?: Maybe<Scalars['Boolean']>;
}>;


export type HotelStatisticListingQuery = (
  { __typename?: 'Query' }
  & { HotelStatisticListing: (
    { __typename?: 'HotelStatisticRespond' }
    & Pick<HotelStatisticRespond, 'TotalRoomRevenue' | 'TotalFnBRevenue' | 'TotalOtherRevenue' | 'TotalOccupancyRate' | 'TotalArr' | 'TotalRoom' | 'OOOOOI' | 'TotalRentableRoom' | 'TotalOccupied' | 'TotalComplimentary' | 'TotalHouseUse' | 'TotalDayUse' | 'TotalPax'>
  ) }
);

export type HotelStatisticListingV2QueryVariables = Exact<{
  BlockRoom?: Maybe<Scalars['Boolean']>;
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  HouseUse?: Maybe<Scalars['Boolean']>;
  DayUse?: Maybe<Scalars['Boolean']>;
  Complimentary?: Maybe<Scalars['Boolean']>;
}>;


export type HotelStatisticListingV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'HotelStatisticListingV2'>
);

export type HotelStateAnalysisQueryVariables = Exact<{
  HotelID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
}>;


export type HotelStateAnalysisQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'HotelStateAnalysis'>
);

export type TotalDueAgingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
}>;


export type TotalDueAgingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TotalDueAging'>
);

export type BiServiceRequestQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type BiServiceRequestQuery = (
  { __typename?: 'Query' }
  & { BIServiceRequest: Array<(
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue' | 'Description'>
  )> }
);

export type BiytdRevenueQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type BiytdRevenueQuery = (
  { __typename?: 'Query' }
  & { BIYTDRevenue: (
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue'>
  ), BICorpGovTAYTDRevenue: (
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue'>
  ), BISourceYTDRevenue: (
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue' | 'Description'>
  ), BITopCountry: Array<(
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue' | 'Description'>
  )>, BITopNationality: Array<(
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue' | 'Description'>
  )>, BIRoomTypeRevenue: (
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue' | 'Description'>
  ), BIMarketingPersonnalTopSales: (
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue' | 'Description'>
  ), BISegmentRevenue: (
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue' | 'Description'>
  ), BIServiceRequest: Array<(
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue' | 'Description'>
  )>, BITravelAgent: Array<(
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue' | 'Description'>
  )>, BITopTransaction: (
    { __typename?: 'RevenueAnalysis' }
    & Pick<RevenueAnalysis, 'RoomRevenue' | 'Description'>
  ) }
);

export type TotalOverdueByDebtorTypeQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type TotalOverdueByDebtorTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TotalOverdueByDebtorType'>
);

export type CollectionByDebtorTypeVsOverdueQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type CollectionByDebtorTypeVsOverdueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CollectionByDebtorTypeVSOverdue'>
);

export type DepartmentListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type DepartmentListingQuery = (
  { __typename?: 'Query' }
  & { DepartmentListing: Array<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'Code' | 'Name' | 'AllowReason' | 'AllowSalesChannel' | 'AllowIncidental' | 'AllowGuestApp' | 'IsServiceRequest'>
    & { ServiceRequest?: Maybe<Array<(
      { __typename?: 'ServiceRequestEntity' }
      & Pick<ServiceRequestEntity, 'ID'>
    )>>, IncidentalCharge?: Maybe<Array<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'IsRateElement' | 'IsActive' | 'DepartmentID' | 'ID' | 'Description' | 'Amount' | 'Tax' | 'ServiceCharge' | 'IsFnB' | 'IsEShop' | 'IsRoomRevenue' | 'GalleryID' | 'IsOutlet' | 'IsNegative' | 'IsEventInterface' | 'isEInvIntegrated' | 'EInvClassification'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>>, Reason?: Maybe<Array<(
      { __typename?: 'ReasonEntity' }
      & Pick<ReasonEntity, 'ID'>
    )>>, SalesChannel?: Maybe<Array<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID'>
    )>> }
  )> }
);

export type BusinessAnalyticInfoLineQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type BusinessAnalyticInfoLineQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BusinessAnalyticInfoLine'>
);

export type BiTop5Otav2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type BiTop5Otav2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BITop5OTAV2'>
);

export type BiTop10RevenueV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type BiTop10RevenueV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'BITop10RevenueV2'>
);

export type CheckoutAdjustmentListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type CheckoutAdjustmentListingQuery = (
  { __typename?: 'Query' }
  & { CheckoutAdjustmentListing: Array<(
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'FolioNo' | 'FolioType' | 'RefFolioID' | 'FolioDate' | 'IsActive' | 'HotelID' | 'FolioAmount' | 'ClosedDate' | 'ClosedBy' | 'IsPosted'>
    & { RefFolio?: Maybe<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'ID' | 'FolioType' | 'FolioNo' | 'RefFolioID' | 'FolioDate' | 'IsActive' | 'HotelID' | 'FolioAmount' | 'ClosedDate' | 'ClosedBy' | 'IsPosted' | 'CreatedBy' | 'ModifiedBy'>
      & { Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate'>
        & { Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'ID' | 'BookingNo'>
        )>, Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & Pick<GuestProfileEntity, 'GuestType' | 'ID'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'ID' | 'Nationality' | 'FullName' | 'PhoneNo' | 'Email' | 'MobileNo'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
            )> }
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
          & { RoomType?: Maybe<(
            { __typename?: 'RoomTypeEntity' }
            & Pick<RoomTypeEntity, 'ID' | 'Description' | 'Code'>
          )> }
        )> }
      )>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'TrxAmount' | 'TaxAmount' | 'ServiceCharge' | 'PaymentID' | 'ReferenceNo' | 'RegistrationID' | 'Description'>
        & { PrincipalRegistration: (
          { __typename?: 'RegistrationEntity' }
          & Pick<RegistrationEntity, 'ID'>
        ), IncidentalCharge?: Maybe<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'ID' | 'Description' | 'DepartmentID'>
        )> }
      )>> }
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'GuestType' | 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'Nationality' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
          )> }
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
        & { RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'ID' | 'Description' | 'Code'>
        )> }
      )> }
    )>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'TrxAmount' | 'TaxAmount' | 'ServiceCharge' | 'PaymentID' | 'ReferenceNo' | 'RegistrationID' | 'Description'>
      & { PrincipalRegistration: (
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
      ), IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'ID' | 'Description' | 'DepartmentID'>
      )> }
    )>> }
  )> }
);

export type SearchFolioQueryVariables = Exact<{
  HotelID: Scalars['String'];
  FolioNo: Scalars['String'];
}>;


export type SearchFolioQuery = (
  { __typename?: 'Query' }
  & { SearchFolio?: Maybe<(
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'FolioNo' | 'RefID' | 'FolioType' | 'FolioDate' | 'FolioAmount' | 'CreatedBy'>
    & { Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo' | 'DebtorAccount'>
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'RegistrationStatus'>
      & { Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
        & { RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'GuestType'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Nationality' | 'PhoneNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
          )> }
        )> }
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'BookingNo' | 'BookingStatus'>
        & { Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'ID' | 'DebtorAccount' | 'Name'>
        )> }
      )> }
    )>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'RegistrationID' | 'TrxDate' | 'TrxAmount' | 'Description' | 'ReferenceNo'>
      & { IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'ID' | 'Description'>
      )> }
    )>>, RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'ID' | 'RegistrationID' | 'TrxDate' | 'TrxAmount' | 'Description' | 'ReferenceNo'>
    )>>, Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'ID' | 'RegistrationID' | 'PaymentDate' | 'Amount' | 'CardNo' | 'ReceiptNo' | 'PaymentClass' | 'PaymentType' | 'PaymentStatus' | 'ReferenceNo' | 'DebtorAccount'>
    )>> }
  )> }
);

export type CheckedOutFolioListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  FolioDate?: Maybe<Scalars['DateTime']>;
  RoomNo?: Maybe<Scalars['String']>;
  FolioNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  AccountName?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type CheckedOutFolioListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckedOutFolioListing'>
);

export type AdjustedFolioDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type AdjustedFolioDetailsQuery = (
  { __typename?: 'Query' }
  & { AdjustedFolioDetails: Array<(
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'FolioNo' | 'FolioType' | 'IsSuspend' | 'FolioDate' | 'FolioAmount' | 'CreatedBy' | 'DocumentType'>
    & { NonGuestInfo?: Maybe<(
      { __typename?: 'NonGuestInfoJson' }
      & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit'>
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'GuestType'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'Nationality' | 'FullName'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
      )> }
    )>, AdvancePayment?: Maybe<Array<(
      { __typename?: 'AdvancePaymentEntity' }
      & Pick<AdvancePaymentEntity, 'Amount' | 'PaymentType' | 'PaymentClass' | 'PaySessionNo' | 'ReferenceNo' | 'ID'>
    )>>, Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'Amount' | 'CardNo' | 'ReceiptNo' | 'PaymentDate' | 'PaymentType' | 'PaymentClass' | 'DebtorAccount' | 'PaySessionNo' | 'ReferenceNo' | 'ID'>
      & { Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )> }
    )>>, TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'ID' | 'TrxDate' | 'TaxType' | 'TaxDescription' | 'TaxAmount' | 'Reason' | 'Description' | 'Qty' | 'IsCNAdjusted'>
      & { Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )> }
    )>>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'BaseAmount' | 'ServiceCharge' | 'TaxAmount' | 'TrxAmount' | 'ReferenceNo' | 'Qty' | 'UnitPrice' | 'IsCNAdjusted'>
      & { IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'ID' | 'Description'>
      )>, Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )> }
    )>>, RoomCharges?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'TrxDate' | 'BaseAmount' | 'Description' | 'TaxAmount' | 'ServiceCharge' | 'RegistrationID'>
    )>>, RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'ID' | 'TrxAmount' | 'TrxDate' | 'TaxAmount' | 'BaseAmount' | 'TransactionType' | 'TransactionID' | 'IsCNAdjusted' | 'ServiceCharge' | 'ReferenceNo' | 'Description'>
      & { Transaction?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description'>
      )>, Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'FullName'>
          )> }
        )>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )> }
    )>>, DepositLedger?: Maybe<Array<(
      { __typename?: 'DepositLedgerEntity' }
      & Pick<DepositLedgerEntity, 'DepositAmount' | 'DepositClass' | 'ReferenceNo' | 'TrxDate' | 'ActualDepositClass' | 'IsCNAdjusted'>
    )>> }
  )> }
);

export type ChangePaymentMutationVariables = Exact<{
  PaymentInput: PaymentInput;
  ReversedPaymentID: Scalars['String'];
  FolioID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type ChangePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ChangePayment'>
);

export type CheckIsGroupInvoiceQueryVariables = Exact<{
  HotelID: Scalars['String'];
  SourceID: Scalars['String'];
}>;


export type CheckIsGroupInvoiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckIsGroupInvoice'>
);

export type FolioRegIdsQueryVariables = Exact<{
  FolioID: Scalars['String'];
}>;


export type FolioRegIdsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'FolioRegIds'>
);

export type FolioAdjustmentInsertMutationVariables = Exact<{
  RefFolioID?: Maybe<Scalars['String']>;
  FolioType: Scalars['String'];
  IsSuspend: Scalars['Boolean'];
  PaymentInput: PaymentInput;
  RoomLedgerInput: Array<RoomLedgerInput> | RoomLedgerInput;
  BillLedgerInput: Array<BillLedgerInput> | BillLedgerInput;
  TaxLedgerInput: Array<TaxLedgerInput> | TaxLedgerInput;
  DepositLedgerInput: Array<DepositLedgerInput> | DepositLedgerInput;
  AdvancePaymentInput: Array<AdvancePaymentInput> | AdvancePaymentInput;
  HotelID: Scalars['String'];
  IsEInvoice?: Maybe<Scalars['Boolean']>;
}>;


export type FolioAdjustmentInsertMutation = (
  { __typename?: 'Mutation' }
  & { FolioAdjustmentInsert: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ReceiptNo' | 'ID'>
  ) }
);

export type FolioChargeToListQueryVariables = Exact<{
  HotelID: Scalars['String'];
  FolioID: Scalars['String'];
}>;


export type FolioChargeToListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'FolioChargeToList'>
);

export type GuestRoomResultQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type GuestRoomResultQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GuestRoomResult'>
);

export type ActivityInsertMutationVariables = Exact<{
  ActivitiesInput?: Maybe<Array<ActivitiesInput> | ActivitiesInput>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type ActivityInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ActivityInsert'>
);

export type ActivityUpdateMutationVariables = Exact<{
  ActivitiesInput?: Maybe<Array<ActivitiesInput> | ActivitiesInput>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type ActivityUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ActivityUpdate'>
);

export type ActivitiesListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID?: Maybe<Scalars['String']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ActivityDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type ActivitiesListingQuery = (
  { __typename?: 'Query' }
  & { ActivitiesListing: Array<(
    { __typename?: 'ActivitiesEntity' }
    & Pick<ActivitiesEntity, 'ID' | 'HotelID' | 'Status' | 'ActivityDate' | 'Attachment' | 'Remark' | 'CreatedBy' | 'CreatedDT' | 'ModifiedBy' | 'ModifiedDT' | 'AccountID'>
    & { Audio?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL'>
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'ARTotalOutstanding' | 'ARTotalDue' | 'Name' | 'LastPaymentDate'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'Email' | 'PhoneNo' | 'MobileNo'>
      )>, DebtorContact?: Maybe<Array<(
        { __typename?: 'DebtorContactEntity' }
        & Pick<DebtorContactEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'MobileNo' | 'Designation'>
        )> }
      )>> }
    )> }
  )> }
);

export type DebtorListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  Country?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type DebtorListingQuery = (
  { __typename?: 'Query' }
  & { DebtorListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'IseCommerce' | 'ID' | 'IsActive' | 'IsFrontDeskActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'IsGroupInvoice' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks' | 'Segment' | 'Login' | 'Password' | 'Tin' | 'InvoiceType' | 'TaxRegNo' | 'IsIndividual' | 'LastPaymentDate'>
    & { CreditCardMapping?: Maybe<Array<(
      { __typename?: 'CreditCardMapping' }
      & Pick<CreditCardMapping, 'Name'>
    )>>, DebtorType?: Maybe<(
      { __typename?: 'DebtorTypeEntity' }
      & Pick<DebtorTypeEntity, 'DebtorType' | 'Description'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'Email'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address2' | 'address' | 'city' | 'state' | 'country' | 'postCode'>
      )> }
    )>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'MobileNo' | 'Designation'>
      )> }
    )>> }
  )> }
);

export type SoADebtorListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  Country?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  Offset?: Maybe<Scalars['Float']>;
  Limit?: Maybe<Scalars['Float']>;
}>;


export type SoADebtorListingQuery = (
  { __typename?: 'Query' }
  & { DebtorListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'IsGroupInvoice' | 'ARTotalOutstanding' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks' | 'Segment' | 'Login' | 'Password' | 'LastPaymentDate'>
    & { CreditCardMapping?: Maybe<Array<(
      { __typename?: 'CreditCardMapping' }
      & Pick<CreditCardMapping, 'Name'>
    )>>, DebtorType?: Maybe<(
      { __typename?: 'DebtorTypeEntity' }
      & Pick<DebtorTypeEntity, 'DebtorType' | 'Description'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'Email'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address2' | 'address' | 'city' | 'state' | 'country' | 'postCode'>
      )> }
    )>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'MobileNo' | 'Designation'>
      )> }
    )>> }
  )> }
);

export type DebtorBookingListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  Country?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  Offset?: Maybe<Scalars['Float']>;
  Limit?: Maybe<Scalars['Float']>;
}>;


export type DebtorBookingListingQuery = (
  { __typename?: 'Query' }
  & { DebtorListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'DebtorCategory' | 'Name' | 'DebtorAccount'>
    & { CreditCardMapping?: Maybe<Array<(
      { __typename?: 'CreditCardMapping' }
      & Pick<CreditCardMapping, 'Name'>
    )>> }
  )> }
);

export type DebtorListingCreditFacilityQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  Country?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  Offset?: Maybe<Scalars['Float']>;
  Limit?: Maybe<Scalars['Float']>;
}>;


export type DebtorListingCreditFacilityQuery = (
  { __typename?: 'Query' }
  & { DebtorListingCreditFacility: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks' | 'Segment' | 'Login' | 'Password' | 'ARTotalDue' | 'LastPaymentDate'>
    & { CreditCardMapping?: Maybe<Array<(
      { __typename?: 'CreditCardMapping' }
      & Pick<CreditCardMapping, 'Name'>
    )>>, DebtorType?: Maybe<(
      { __typename?: 'DebtorTypeEntity' }
      & Pick<DebtorTypeEntity, 'DebtorType' | 'Description'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'Email'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address2' | 'address' | 'city' | 'state' | 'country' | 'postCode'>
      )> }
    )>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'MobileNo' | 'Designation'>
      )> }
    )>> }
  )> }
);

export type DebtorListingNonCreditFacilityQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  Country?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  Offset?: Maybe<Scalars['Float']>;
  Limit?: Maybe<Scalars['Float']>;
}>;


export type DebtorListingNonCreditFacilityQuery = (
  { __typename?: 'Query' }
  & { DebtorListingNonCreditFacility: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks' | 'Segment' | 'Login' | 'Password' | 'ARTotalDue' | 'LastPaymentDate'>
    & { CreditCardMapping?: Maybe<Array<(
      { __typename?: 'CreditCardMapping' }
      & Pick<CreditCardMapping, 'Name'>
    )>>, DebtorType?: Maybe<(
      { __typename?: 'DebtorTypeEntity' }
      & Pick<DebtorTypeEntity, 'DebtorType' | 'Description'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'Email'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address2' | 'address' | 'city' | 'state' | 'country' | 'postCode'>
      )> }
    )>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'MobileNo' | 'Designation'>
      )> }
    )>> }
  )> }
);

export type CalculateDebtorListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type CalculateDebtorListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CalculateDebtorListing'>
);

export type DebtorListingInfoLines2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  IsPosted?: Maybe<Scalars['Boolean']>;
  ActivityStartDate?: Maybe<Scalars['DateTime']>;
  ActivityEndDate?: Maybe<Scalars['DateTime']>;
}>;


export type DebtorListingInfoLines2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'DebtorCountListing' | 'DebtorTypeCountListing' | 'ScheduleBillingCountList' | 'ActivitiesCountListing'>
);

export type DebtorListQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
}>;


export type DebtorListQuery = (
  { __typename?: 'Query' }
  & { DebtorListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'Name' | 'DebtorAccount'>
  )> }
);

export type DebtorDetailByAccountCodeQueryVariables = Exact<{
  AccountCode: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type DebtorDetailByAccountCodeQuery = (
  { __typename?: 'Query' }
  & { DebtorDetailByAccountCode: (
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'Name' | 'IsIndividual' | 'DebtorAccount'>
  ) }
);

export type DebtorDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DebtorDetailQuery = (
  { __typename?: 'Query' }
  & { DebtorDetail: (
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'ContactID' | 'DebtorTypeID' | 'DebtorCategory' | 'Name' | 'DebtorAccount' | 'DebtorContacts' | 'CompanyRegNo' | 'GSTNo' | 'Currency' | 'CreditTerm' | 'CreditLimit' | 'Remarks' | 'Segment' | 'Login' | 'Password' | 'ARTotalDue' | 'ARTotalOutstanding' | 'TaxRegNo' | 'Tin'>
    & { SegmentDetails?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'Description'>
    )>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'MobileNo' | 'Email'>
      )> }
    )>>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'PhoneNo' | 'Email' | 'FullName' | 'MobileNo' | 'BirthDate' | 'Designation'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'address2' | 'postCode' | 'city' | 'state' | 'country'>
      )> }
    )> }
  ) }
);

export type DebtorInfoLineQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type DebtorInfoLineQuery = (
  { __typename?: 'Query' }
  & { ARTotalOutstanding: (
    { __typename?: 'ARAccountOSLastPayment' }
    & Pick<ArAccountOsLastPayment, 'LastPaymentDT' | 'TotalOstandingAmount'>
  ) }
);

export type LedgerDocumentInfoLineQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
}>;


export type LedgerDocumentInfoLineQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GroupInvoiceCount'>
  & { InvoiceInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), DebitNoteInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), CreditNoteInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), OfficialReceiptInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ), RefundInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type MonthlyStatementListingQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type MonthlyStatementListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'MonthlyStatementListing'>
);

export type OpeningClosingAmountQueryVariables = Exact<{
  DebtorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type OpeningClosingAmountQuery = (
  { __typename?: 'Query' }
  & { OpeningClosingAmount: (
    { __typename?: 'OpenClosedResponse' }
    & { OpenInvoice?: Maybe<Array<(
      { __typename?: 'data' }
      & Pick<Data, 'ID' | 'DocumentNo' | 'osAmt'>
    )>>, OpendebitNote?: Maybe<Array<(
      { __typename?: 'data' }
      & Pick<Data, 'ID' | 'DocumentNo' | 'osAmt'>
    )>>, OpenCreditNote?: Maybe<Array<(
      { __typename?: 'data' }
      & Pick<Data, 'ID' | 'DocumentNo' | 'osAmt'>
    )>>, OpenOR?: Maybe<Array<(
      { __typename?: 'data' }
      & Pick<Data, 'ID' | 'DocumentNo' | 'osAmt'>
    )>>, OpenRefund?: Maybe<Array<(
      { __typename?: 'data' }
      & Pick<Data, 'ID' | 'DocumentNo' | 'osAmt'>
    )>>, ClosedInvoice?: Maybe<Array<(
      { __typename?: 'data' }
      & Pick<Data, 'ID' | 'DocumentNo' | 'osAmt'>
    )>>, CloseddebitNote?: Maybe<Array<(
      { __typename?: 'data' }
      & Pick<Data, 'ID' | 'DocumentNo' | 'osAmt'>
    )>>, ClosedCreditNote?: Maybe<Array<(
      { __typename?: 'data' }
      & Pick<Data, 'ID' | 'DocumentNo' | 'osAmt'>
    )>>, ClosedOR?: Maybe<Array<(
      { __typename?: 'data' }
      & Pick<Data, 'ID' | 'DocumentNo' | 'osAmt'>
    )>>, ClosedRefund?: Maybe<Array<(
      { __typename?: 'data' }
      & Pick<Data, 'ID' | 'DocumentNo' | 'osAmt'>
    )>> }
  ) }
);

export type StatementOfAccDocumentQueryVariables = Exact<{
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type StatementOfAccDocumentQuery = (
  { __typename?: 'Query' }
  & { StatementOfAccDocument: (
    { __typename?: 'docResponse' }
    & { Invoice?: Maybe<Array<(
      { __typename?: 'test' }
      & Pick<Test, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Booking'>
      & { LedgerTrx: Array<(
        { __typename?: 'InvoiceTrxEntity' }
        & Pick<InvoiceTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
      )> }
    )>>, CnDoc?: Maybe<Array<(
      { __typename?: 'CreditNoteEntity' }
      & Pick<CreditNoteEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
      & { LedgerTrx: Array<(
        { __typename?: 'CreditNoteTrxEntity' }
        & Pick<CreditNoteTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
      )> }
    )>>, DnDoc?: Maybe<Array<(
      { __typename?: 'DebitNoteEntity' }
      & Pick<DebitNoteEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
      & { LedgerTrx: Array<(
        { __typename?: 'DebitNoteTrxEntity' }
        & Pick<DebitNoteTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
      )> }
    )>>, OrDoc?: Maybe<Array<(
      { __typename?: 'OfficialReceiptEntity' }
      & Pick<OfficialReceiptEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Commission'>
      & { Allocation: Array<(
        { __typename?: 'AllocationEntity' }
        & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
      )> }
    )>>, RefundDoc?: Maybe<Array<(
      { __typename?: 'RefundEntity' }
      & Pick<RefundEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Commission'>
      & { Allocation: Array<(
        { __typename?: 'AllocationEntity' }
        & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
      )> }
    )>> }
  ) }
);

export type PaymentFolioDebtorListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  Country?: Maybe<Scalars['String']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type PaymentFolioDebtorListingQuery = (
  { __typename?: 'Query' }
  & { DebtorListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'IsActive' | 'DebtorCategory' | 'Name' | 'DebtorAccount'>
    & { CreditCardMapping?: Maybe<Array<(
      { __typename?: 'CreditCardMapping' }
      & Pick<CreditCardMapping, 'Name'>
    )>>, DebtorType?: Maybe<(
      { __typename?: 'DebtorTypeEntity' }
      & Pick<DebtorTypeEntity, 'DebtorType'>
    )> }
  )> }
);

export type DebtorTypeCountQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type DebtorTypeCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DebtorTypeCount'>
);

export type InvoiceListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  Offset?: Maybe<Scalars['Float']>;
  Limit?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
}>;


export type InvoiceListingsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CityLedgerInvoiceListings'>
);

export type PostedScheduleBillingQueryVariables = Exact<{
  ID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type PostedScheduleBillingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PostedScheduleBilling'>
);

export type EInvoiceDocListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type EInvoiceDocListingsQuery = (
  { __typename?: 'Query' }
  & { EInvoiceDocListings: Array<(
    { __typename?: 'InvoiceEntity' }
    & Pick<InvoiceEntity, 'ID' | 'DocumentNo' | 'DocumentDate' | 'is_einvoice' | 'einvoice_status'>
  )> }
);

export type InvoiceInsertMutationVariables = Exact<{
  input: InvoiceEntityInput;
  ChargeItemInput: Array<InvoiceTrxInput> | InvoiceTrxInput;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type InvoiceInsertMutation = (
  { __typename?: 'Mutation' }
  & { InvoiceInsert: (
    { __typename?: 'InvoiceEntity' }
    & Pick<InvoiceEntity, 'ID'>
  ) }
);

export type InvoiceUpdateMutationVariables = Exact<{
  input: InvoiceEntityInput;
  ChargeItemInput: Array<InvoiceTrxInput> | InvoiceTrxInput;
  ID: Scalars['String'];
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type InvoiceUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InvoiceUpdate'>
);

export type InvoiceCancelMutationVariables = Exact<{
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
}>;


export type InvoiceCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InvoiceCancel'>
);

export type InvoiceDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type InvoiceDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InvoiceDelete'>
);

export type AllocateInvoiceMutationVariables = Exact<{
  allocInput: Array<DebitAllocationInput> | DebitAllocationInput;
  InvoiceID: Scalars['String'];
}>;


export type AllocateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AllocateInvoice'>
);

export type InvoiceInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
}>;


export type InvoiceInfoQuery = (
  { __typename?: 'Query' }
  & { InvoiceInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type DebitNoteListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Offset?: Maybe<Scalars['Float']>;
  Limit?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
}>;


export type DebitNoteListingsQuery = (
  { __typename?: 'Query' }
  & { DebitNoteListings: Array<(
    { __typename?: 'DebitNoteEntity' }
    & Pick<DebitNoteEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'IsPostedDoc' | 'einvoice_doc_ref_invoice_id' | 'is_einvoice' | 'einvoice_irbm_uniqueID' | 'einvoice_status' | 'QrCodeUrl'>
    & { LedgerTrx: Array<(
      { __typename?: 'DebitNoteTrxEntity' }
      & Pick<DebitNoteTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
      & { DebitNoteTrxTax?: Maybe<Array<(
        { __typename?: 'DebitNoteTrxTaxEntity' }
        & Pick<DebitNoteTrxTaxEntity, 'TaxID' | 'TaxAmount' | 'TaxRate' | 'TaxSchemeID'>
        & { TaxInfo?: Maybe<(
          { __typename?: 'taxEntity' }
          & Pick<TaxEntity, 'id' | 'code'>
        )> }
      )>> }
    )>, Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'ModifiedDT' | 'CreditID' | 'AllocationAmount' | 'AllocDate'>
      & { DebitDetail: Array<(
        { __typename?: 'CreditNoteEntity' }
        & Pick<CreditNoteEntity, 'ID' | 'DocumentNo' | 'DocumentDate'>
      )>, CreditDetail: Array<(
        { __typename?: 'DebitNoteEntity' }
        & Pick<DebitNoteEntity, 'ID' | 'DocumentNo' | 'DocumentDate'>
      )> }
    )>, CityLedgerAttachment?: Maybe<Array<(
      { __typename?: 'CityLedgerAttachmentEntity' }
      & Pick<CityLedgerAttachmentEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>> }
  )> }
);

export type DebitNoteDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DebitNoteDetailQuery = (
  { __typename?: 'Query' }
  & { DebitNoteDetail: (
    { __typename?: 'DebitNoteEntity' }
    & Pick<DebitNoteEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate'>
    & { LedgerTrx: Array<(
      { __typename?: 'DebitNoteTrxEntity' }
      & Pick<DebitNoteTrxEntity, 'TransactionID' | 'TrxName' | 'TrxAmount' | 'ReferenceNo' | 'UnitPrice' | 'Quantity' | 'DiscountedAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'Remarks'>
    )>, Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
    )> }
  ) }
);

export type DebitNoteInsertMutationVariables = Exact<{
  input: DebitNoteEntityInput;
  ChargeItemInput: Array<DebitNoteTrxInput> | DebitNoteTrxInput;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type DebitNoteInsertMutation = (
  { __typename?: 'Mutation' }
  & { DebitNoteInsert: (
    { __typename?: 'DebitNoteEntity' }
    & Pick<DebitNoteEntity, 'ID'>
  ) }
);

export type DebitNoteUpdateMutationVariables = Exact<{
  input: DebitNoteEntityInput;
  ChargeItemInput: Array<DebitNoteTrxInput> | DebitNoteTrxInput;
  ID: Scalars['String'];
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DebitNoteUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebitNoteUpdate'>
);

export type DebitNoteCancelMutationVariables = Exact<{
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
}>;


export type DebitNoteCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebitNoteCancel'>
);

export type DebitNoteDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DebitNoteDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebitNoteDelete'>
);

export type AllocateDebitNoteMutationVariables = Exact<{
  allocInput: Array<DebitAllocationInput> | DebitAllocationInput;
  DebitNoteID: Scalars['String'];
}>;


export type AllocateDebitNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AllocateDebitNote'>
);

export type DebitNoteInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
}>;


export type DebitNoteInfoQuery = (
  { __typename?: 'Query' }
  & { DebitNoteInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type CreditNoteListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Offset?: Maybe<Scalars['Float']>;
  Limit?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
}>;


export type CreditNoteListingsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CreditNoteListings'>
);

export type CreditNoteInsertMutationVariables = Exact<{
  input: CreditNoteEntityInput;
  ChargeItemInput: Array<CreditNoteTrxInput> | CreditNoteTrxInput;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type CreditNoteInsertMutation = (
  { __typename?: 'Mutation' }
  & { CreditNoteInsert: (
    { __typename?: 'CreditNoteEntity' }
    & Pick<CreditNoteEntity, 'ID'>
  ) }
);

export type CreditNoteUpdateMutationVariables = Exact<{
  input: CreditNoteEntityInput;
  ChargeItemInput: Array<CreditNoteTrxInput> | CreditNoteTrxInput;
  ID: Scalars['String'];
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CreditNoteUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreditNoteUpdate'>
);

export type CreditNoteCancelMutationVariables = Exact<{
  Reason: Scalars['String'];
  ReasonCode: Scalars['String'];
  ID: Scalars['String'];
}>;


export type CreditNoteCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreditNoteCancel'>
);

export type CreditNoteDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type CreditNoteDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreditNoteDelete'>
);

export type AllocateCreditNoteMutationVariables = Exact<{
  allocInput: Array<CreditAllocationInput> | CreditAllocationInput;
  CreditNoteID: Scalars['String'];
}>;


export type AllocateCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AllocateCreditNote'>
);

export type CreditNoteInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
}>;


export type CreditNoteInfoQuery = (
  { __typename?: 'Query' }
  & { CreditNoteInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type OfficialReceiptListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Offset?: Maybe<Scalars['Float']>;
  Limit?: Maybe<Scalars['Float']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
}>;


export type OfficialReceiptListingsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'OfficialReceiptListings'>
);

export type OfficialReceiptDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type OfficialReceiptDetailQuery = (
  { __typename?: 'Query' }
  & { OfficialReceiptDetail: (
    { __typename?: 'OfficialReceiptEntity' }
    & Pick<OfficialReceiptEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Commission'>
    & { Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
    )> }
  ) }
);

export type OfficialReceiptInsertMutationVariables = Exact<{
  input: OfficialReceiptEntityInput;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type OfficialReceiptInsertMutation = (
  { __typename?: 'Mutation' }
  & { OfficialReceiptInsert: (
    { __typename?: 'OfficialReceiptEntity' }
    & Pick<OfficialReceiptEntity, 'ID'>
  ) }
);

export type OfficialReceiptUpdateMutationVariables = Exact<{
  input: OfficialReceiptEntityInput;
  ID: Scalars['String'];
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type OfficialReceiptUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OfficialReceiptUpdate'>
);

export type OfficialReceiptCancelMutationVariables = Exact<{
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
}>;


export type OfficialReceiptCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OfficialReceiptCancel'>
);

export type OfficialReceiptDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type OfficialReceiptDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'OfficialReceiptDelete'>
);

export type AllocateOfficialReceiptMutationVariables = Exact<{
  allocInput: Array<CreditAllocationInput> | CreditAllocationInput;
  ReceiptID: Scalars['String'];
}>;


export type AllocateOfficialReceiptMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AllocateOfficialReceipt'>
);

export type OfficialReceiptInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
}>;


export type OfficialReceiptInfoQuery = (
  { __typename?: 'Query' }
  & { OfficialReceiptInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type RefundListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Offset?: Maybe<Scalars['Float']>;
  Limit?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
}>;


export type RefundListingsQuery = (
  { __typename?: 'Query' }
  & { RefundListings: Array<(
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Commission' | 'CreatedBy' | 'ModifiedBy' | 'IsPostedDoc'>
    & { bankDetails: (
      { __typename?: 'BankDetails' }
      & Pick<BankDetails, 'Name'>
    ), Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'ModifiedDT' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount' | 'AllocDate'>
      & { DebitDetail: Array<(
        { __typename?: 'CreditNoteEntity' }
        & Pick<CreditNoteEntity, 'ID' | 'DocumentNo' | 'DocumentDate'>
      )>, CreditDetail: Array<(
        { __typename?: 'DebitNoteEntity' }
        & Pick<DebitNoteEntity, 'ID' | 'DocumentNo' | 'DocumentDate'>
      )> }
    )>, CityLedgerAttachment?: Maybe<Array<(
      { __typename?: 'CityLedgerAttachmentEntity' }
      & Pick<CityLedgerAttachmentEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>> }
  )> }
);

export type RefundDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type RefundDetailQuery = (
  { __typename?: 'Query' }
  & { RefundDetail: (
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'ID' | 'DocumentNo' | 'ReferenceNo' | 'Description' | 'DocumentDate' | 'Amount' | 'Status' | 'HotelID' | 'AccountID' | 'DebtorID' | 'Attachment' | 'Currency' | 'OutstandingAmount' | 'CurrencyRate' | 'Reason' | 'VoidDate' | 'PostedDate' | 'Commission'>
    & { Allocation: Array<(
      { __typename?: 'AllocationEntity' }
      & Pick<AllocationEntity, 'ID' | 'HotelID' | 'DebtorID' | 'AccountID' | 'DebitRefTable' | 'DebitID' | 'CreditRefTable' | 'CreditID' | 'AllocationAmount'>
    )> }
  ) }
);

export type RefundInsertMutationVariables = Exact<{
  input: RefundEntityInput;
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type RefundInsertMutation = (
  { __typename?: 'Mutation' }
  & { RefundInsert: (
    { __typename?: 'RefundEntity' }
    & Pick<RefundEntity, 'ID'>
  ) }
);

export type RefundUpdateMutationVariables = Exact<{
  input: RefundEntityInput;
  ID: Scalars['String'];
  CityLedgerAttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type RefundUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RefundUpdate'>
);

export type RefundCancelMutationVariables = Exact<{
  ReasonCode: Scalars['String'];
  Reason: Scalars['String'];
  ID: Scalars['String'];
}>;


export type RefundCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RefundCancel'>
);

export type RefundDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type RefundDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RefundDelete'>
);

export type AllocateRefundMutationVariables = Exact<{
  allocInput: Array<DebitAllocationInput> | DebitAllocationInput;
  RefundID: Scalars['String'];
}>;


export type AllocateRefundMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AllocateRefund'>
);

export type RefundInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  IsPostedDoc: Scalars['Boolean'];
}>;


export type RefundInfoQuery = (
  { __typename?: 'Query' }
  & { RefundInfo: (
    { __typename?: 'ARSummaryInfo' }
    & Pick<ArSummaryInfo, 'LastCreatedDT' | 'TotalOstandingAmount' | 'TotalAmount' | 'noOfDocument'>
  ) }
);

export type IncidentalChargeByIdQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type IncidentalChargeByIdQuery = (
  { __typename?: 'Query' }
  & { IncidentalChargeByID: (
    { __typename?: 'IncidentalChargeEntity' }
    & Pick<IncidentalChargeEntity, 'ID' | 'Description' | 'EInvClassification'>
  ) }
);

export type OpeningClosingAmountV2QueryVariables = Exact<{
  DebtorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
}>;


export type OpeningClosingAmountV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'OpeningClosingAmountV2'>
);

export type CreditNoteAllocationListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  CreditID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type CreditNoteAllocationListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CreditNoteAllocationListing'>
);

export type AllocationDetailListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  CreditID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type AllocationDetailListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AllocationDetailListing'>
);

export type DebitNoteAllocationListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebitID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type DebitNoteAllocationListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DebitNoteAllocationListing'>
);

export type CreditNoteAndInvoiceListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  IsGroupInvoice?: Maybe<Scalars['Boolean']>;
}>;


export type CreditNoteAndInvoiceListingsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CreditNoteAndInvoiceListings'>
);

export type GroupCreditNoteAndInvoiceListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  DocumentNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  GroupInvoiceNo?: Maybe<Scalars['String']>;
  GroupReferenceNo?: Maybe<Scalars['String']>;
}>;


export type GroupCreditNoteAndInvoiceListingsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GroupCreditNoteAndInvoiceListings'>
);

export type CheckBanknameInUseQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BankName: Scalars['String'];
}>;


export type CheckBanknameInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckBanknameInUse'>
);

export type CreditNoteAndInvoiceCountQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID: Scalars['String'];
}>;


export type CreditNoteAndInvoiceCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CreditNoteAndInvoiceCount'>
);

export type DocumentNumberHeaderListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type DocumentNumberHeaderListingQuery = (
  { __typename?: 'Query' }
  & { DocumentNumberHeaderListing: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'ID' | 'HotelID' | 'AccountID' | 'Type' | 'SampleOutput'>
    & { DocumentNumberDetail?: Maybe<Array<(
      { __typename?: 'DocumentNumberDetailEntity' }
      & Pick<DocumentNumberDetailEntity, 'ID' | 'DocumentNumberHeaderID' | 'Sequence' | 'FormatType' | 'FormatValue' | 'NextNumber' | 'StartFrom' | 'IsActive'>
    )>> }
  )> }
);

export type DocumentNumberingSetupInsertMutationVariables = Exact<{
  DocumentNumberHeaderinput: DocumentNumberHeaderInput;
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput> | DocumentNumberDetailInput;
}>;


export type DocumentNumberingSetupInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DocumentNumberingSetupInsert'>
);

export type DocumentNumberingUpdateMutationVariables = Exact<{
  DocumentNumberHeaderID: Scalars['String'];
  SampleOutput: Scalars['String'];
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput> | DocumentNumberDetailInput;
}>;


export type DocumentNumberingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DocumentNumberingUpdate'>
);

export type ScheduleBillingListQueryVariables = Exact<{
  HotelID: Scalars['String'];
  IsPosted?: Maybe<Scalars['Boolean']>;
  DebtorAcc: Array<Scalars['String']> | Scalars['String'];
  DocStartDate?: Maybe<Scalars['DateTime']>;
  DocEndDate?: Maybe<Scalars['DateTime']>;
  DebtorTypeID: Array<Scalars['String']> | Scalars['String'];
  BookingNo?: Maybe<Scalars['String']>;
  IsGroupBooking?: Maybe<Scalars['String']>;
  IsGroupInvoice?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type ScheduleBillingListQuery = (
  { __typename?: 'Query' }
  & { ScheduleBillingList: Array<(
    { __typename?: 'ScheduleBillingResponse' }
    & Pick<ScheduleBillingResponse, 'CreatedBy' | 'BookingNo' | 'BookingID' | 'GuestName' | 'GuestEmail' | 'DebtorName' | 'DebtorAccount' | 'ActualFolioNo' | 'CreditTerm' | 'DebtorID' | 'FolioNo' | 'FolioAmount' | 'RoomNo' | 'RoomType' | 'FolioDate' | 'FolioID' | 'PaymentID' | 'Description' | 'SourceTable' | 'IsGroupBooking' | 'IsGroupInvoice'>
  )> }
);

export type PostScheduleBillingMutationVariables = Exact<{
  input: Array<ScheduleBillingInput> | ScheduleBillingInput;
  HotelID: Scalars['String'];
  InvoiceDate?: Maybe<Scalars['DateTime']>;
}>;


export type PostScheduleBillingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PostScheduleBilling'>
);

export type CompanyInsertMutationVariables = Exact<{
  CompanyInput: CompanyInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type CompanyInsertMutation = (
  { __typename?: 'Mutation' }
  & { CompanyInsert: (
    { __typename?: 'companyEntity' }
    & Pick<CompanyEntity, 'id'>
  ) }
);

export type CompanyUpdateMutationVariables = Exact<{
  CompanyInput: CompanyInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type CompanyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyUpdate'>
);

export type CompanyUpdateStatusMutationVariables = Exact<{
  Status: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type CompanyUpdateStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CompanyUpdateStatus'>
);

export type CompanyListingQueryVariables = Exact<{
  AccountID: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type CompanyListingQuery = (
  { __typename?: 'Query' }
  & { CompanyListing: Array<(
    { __typename?: 'companyEntity' }
    & Pick<CompanyEntity, 'id' | 'parentCompanyId' | 'accountId' | 'baseCurrencyId' | 'name' | 'description' | 'companyRegNo' | 'code' | 'contactNo' | 'email' | 'sstNo' | 'gstNo' | 'ttxNo' | 'gstExpiryDate' | 'image' | 'imageUrl' | 'imageBucketFile' | 'websiteUrl' | 'eInvClientID' | 'eInvClientSecret' | 'eInvClientSecret2' | 'regionId' | 'imageLogoPosition' | 'watermarkImplementation' | 'watermarkWording' | 'isoImplementation' | 'isoLogoPosition' | 'companyTax' | 'recordStatus' | 'groupGstRegNo' | 'officeTel' | 'tin' | 'eSign' | 'eSignExpiryDate' | 'eInvStartDate' | 'eInvEndDate' | 'isEInvIntegrated'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
    )> }
  )> }
);

export type CurrencyListingQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrencyListingQuery = (
  { __typename?: 'Query' }
  & { CurrencyListing: Array<(
    { __typename?: 'currencyEntity' }
    & Pick<CurrencyEntity, 'id' | 'code' | 'name' | 'symbol' | 'format' | 'common_status'>
  )> }
);

export type CountryListingQueryVariables = Exact<{ [key: string]: never; }>;


export type CountryListingQuery = (
  { __typename?: 'Query' }
  & { CountryListing: Array<(
    { __typename?: 'countryEntity' }
    & Pick<CountryEntity, 'ID' | 'name' | 'code' | 'einv_code'>
  )> }
);

export type StateListingQueryVariables = Exact<{ [key: string]: never; }>;


export type StateListingQuery = (
  { __typename?: 'Query' }
  & { StateListing: Array<(
    { __typename?: 'stateEntity' }
    & Pick<StateEntity, 'ID' | 'name' | 'countryID' | 'einv_code'>
  )> }
);

export type EInvSubscriptionQueryVariables = Exact<{
  AccountID: Scalars['String'];
}>;


export type EInvSubscriptionQuery = (
  { __typename?: 'Query' }
  & { EInvSubscription: Array<(
    { __typename?: 'EInvoiceSubscriptionEntity' }
    & Pick<EInvoiceSubscriptionEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'AccountID'>
  )> }
);

export type EInvoiceInformationQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  ID: Scalars['String'];
  Type: Scalars['String'];
}>;


export type EInvoiceInformationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'EInvoiceInformation'>
);

export type DocToConsolidateListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DocType: Scalars['String'];
  Year: Scalars['Float'];
  Month: Scalars['Float'];
  Name?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  IsSearch?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type DocToConsolidateListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DocToConsolidateListing'>
);

export type ConsolidatedInvoiceListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  DocNo?: Maybe<Scalars['String']>;
  DocType?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
}>;


export type ConsolidatedInvoiceListingQuery = (
  { __typename?: 'Query' }
  & { ConsolidatedInvoiceListing: Array<(
    { __typename?: 'EInvoiceConsolidationEntity' }
    & Pick<EInvoiceConsolidationEntity, 'ID' | 'DocDate' | 'DocNo' | 'Description' | 'DocAmt' | 'Module' | 'einvoice_status' | 'einvoice_sent_date' | 'einvoice_trx_type' | 'CreatedDT' | 'CreatedBy'>
    & { EIConsolidationDetail?: Maybe<Array<(
      { __typename?: 'EInvoiceConsolidationDetailEntity' }
      & Pick<EInvoiceConsolidationDetailEntity, 'ID' | 'RefTable'>
    )>> }
  )> }
);

export type GenerateConsolidatedDocOldMutationVariables = Exact<{
  TrxDate: Scalars['DateTime'];
  EInvoiceConsolidationInput: EInvoiceConsolidationInput;
  DocType?: Maybe<Scalars['String']>;
  Year: Scalars['Float'];
  Month: Scalars['Float'];
  Name?: Maybe<Scalars['String']>;
  Submenu?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type GenerateConsolidatedDocOldMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GenerateConsolidatedDocOld'>
);

export type DocToConsolidateViewQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  ConsolidationID: Scalars['String'];
  Submenu?: Maybe<Scalars['String']>;
  DocType: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type DocToConsolidateViewQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DocToConsolidateView'>
);

export type CorporateGovernmentListingQueryVariables = Exact<{
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SalesChannel?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type CorporateGovernmentListingQuery = (
  { __typename?: 'Query' }
  & { CorporateGovernmentListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'Name' | 'IsActive' | 'IsIndividual' | 'DebtorCategory' | 'DebtorTypeID' | 'DebtorAccount' | 'CreditLimit' | 'Login' | 'Password' | 'Segment' | 'SalesChannel' | 'BillingInstruction' | 'IsFrontDeskActive' | 'CompanyRegNo' | 'Tin' | 'TaxRegNo' | 'InvoiceType'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'Email' | 'PhoneNo' | 'MobileNo' | 'AccountID'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
      )> }
    )>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'ID' | 'DebtorID' | 'ContactID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'Designation' | 'MobileNo' | 'Email' | 'IsActive'>
      )> }
    )>> }
  )> }
);

export type GovernmentListingQueryVariables = Exact<{
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type GovernmentListingQuery = (
  { __typename?: 'Query' }
  & { GovernmentListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'Name' | 'IsActive'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'Email' | 'PhoneNo' | 'AccountID'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
      )> }
    )> }
  )> }
);

export type CorporateGovernmentDetailQueryVariables = Exact<{
  HotelID: Scalars['String'];
  ID: Scalars['String'];
}>;


export type CorporateGovernmentDetailQuery = (
  { __typename?: 'Query' }
  & { CorporateGovernmentDetail: (
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'Name' | 'LastBooking' | 'IsIndividual' | 'DebtorCategory' | 'DebtorTypeID' | 'DebtorAccount' | 'CreditLimit' | 'Login' | 'Password' | 'Segment' | 'Remarks' | 'SalesChannel' | 'BillingInstruction' | 'CompanyRegNo' | 'TaxRegNo' | 'Tin' | 'InvoiceType'>
    & { Booking?: Maybe<Array<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID'>
    )>>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'Email' | 'PhoneNo' | 'MobileNo' | 'AccountID'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
      )> }
    )>, Contract?: Maybe<Array<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'IsActive' | 'ID'>
    )>>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'ID' | 'DebtorID' | 'ContactID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'AccountID' | 'FullName' | 'Designation' | 'MobileNo' | 'Email' | 'IsActive'>
      )> }
    )>> }
  ) }
);

export type CorporateContractListingQueryVariables = Exact<{
  DebtorID: Scalars['String'];
}>;


export type CorporateContractListingQuery = (
  { __typename?: 'Query' }
  & { CorporateContractListing: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'DebtorID' | 'ContractNo' | 'Description' | 'StartDate' | 'EndDate' | 'RoomUpgrade' | 'Elastic' | 'Allotment' | 'CutOffDays' | 'PaymentTerms' | 'AllowDeleteContract' | 'ModifiedDT' | 'Remarks' | 'IsActive'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'Name'>
    )>, ContractRate?: Maybe<Array<(
      { __typename?: 'ContractRateEntity' }
      & Pick<ContractRateEntity, 'ID' | 'IsActive' | 'ContractID' | 'RatePolicyID'>
      & { RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
      )> }
    )>> }
  )> }
);

export type CorporateBookingListingQueryVariables = Exact<{
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type CorporateBookingListingQuery = (
  { __typename?: 'Query' }
  & { CorporateBookingListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment' | 'CreatedDT' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'ContactID' | 'GroupName' | 'SalesChannelID' | 'Source' | 'SegmentID' | 'SpecialRequest' | 'DebtorAccount' | 'BillingInstruction' | 'Reason' | 'BookingStatus'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'HotelName'>
      & { HotelAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'Email' | 'Nationality' | 'ID' | 'MobileNo' | 'PassportNo' | 'NRIC'>
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorCategory' | 'ID' | 'Name'>
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'RoomTypeID' | 'RatePolicyID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'ID'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
        )> }
      )> }
    )>> }
  )> }
);

export type CorporateContractUpdateMutationVariables = Exact<{
  ContractInput: ContractInput;
  RatePolicyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type CorporateContractUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CorporateContractUpdate'>
);

export type CorporateContractInsertMutationVariables = Exact<{
  ContractInput: ContractInput;
  RatePolicyIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type CorporateContractInsertMutation = (
  { __typename?: 'Mutation' }
  & { CorporateContractInsert: (
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID'>
  ) }
);

export type CorporateUpdateMutationVariables = Exact<{
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
  DebtorContactInput?: Maybe<Array<ContactInput> | ContactInput>;
}>;


export type CorporateUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CorporateUpdate'>
);

export type GovermentTestingUpdateMutationVariables = Exact<{
  DebtorInput: DebtorInput;
}>;


export type GovermentTestingUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GovermentTestingUpdate'>
);

export type CorporateInsertMutationVariables = Exact<{
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
  DebtorContactInput: Array<ContactInput> | ContactInput;
}>;


export type CorporateInsertMutation = (
  { __typename?: 'Mutation' }
  & { CorporateInsert: (
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID'>
  ) }
);

export type CorporateDeleteMutationVariables = Exact<{
  DebtorID: Scalars['String'];
}>;


export type CorporateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CorporateDelete'>
);

export type CoporateInUseListingQueryVariables = Exact<{
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type CoporateInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CoporateInUseListing'>
);

export type DebtorListingDropDownQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type DebtorListingDropDownQuery = (
  { __typename?: 'Query' }
  & { DebtorListingDropDown: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'Name' | 'DebtorAccount' | 'DebtorCategory' | 'IsActive'>
  )> }
);

export type GuestReviewInfoLinesQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type GuestReviewInfoLinesQuery = (
  { __typename?: 'Query' }
  & { GuestReviewInfoLines: (
    { __typename?: 'GuestReviewInfoLineResponse' }
    & Pick<GuestReviewInfoLineResponse, 'InActivated' | 'LessThan3Star' | 'MoreThan3Star'>
  ) }
);

export type GuestReviewListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  Rating?: Maybe<Scalars['Float']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  StartDate?: Maybe<Scalars['DateTime']>;
}>;


export type GuestReviewListingQuery = (
  { __typename?: 'Query' }
  & { GuestReviewListing: Array<(
    { __typename?: 'HotelRatingEntity' }
    & Pick<HotelRatingEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'Cleanliness' | 'Facilities' | 'Location' | 'ComfortQuality' | 'Service' | 'OverallExperience' | 'Comments' | 'ReviewCount'>
    & { Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )>, BookedRegistration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & { Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )>>, Registration?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & Pick<GuestProfileEntity, 'GuestType'>
        )> }
      )>> }
    )> }
  )> }
);

export type GuestReviewActiveUpdateMutationVariables = Exact<{
  HotelRatingInput: HotelRatingInput;
}>;


export type GuestReviewActiveUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestReviewActiveUpdate'>
);

export type PromotionListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type PromotionListingQuery = (
  { __typename?: 'Query' }
  & { PromotionListing: Array<(
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'ID' | 'Code' | 'Description' | 'DiscountType' | 'DiscountValue' | 'StartDate' | 'EndDate' | 'IsActive'>
    & { PromotionDetail?: Maybe<Array<(
      { __typename?: 'PromotionDetailEntity' }
      & Pick<PromotionDetailEntity, 'ID' | 'PromotionID'>
      & { RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'ID' | 'RateCode' | 'Description' | 'IsActive'>
      )> }
    )>> }
  )> }
);

export type PromotionDetailListingQueryVariables = Exact<{
  PromotionID: Scalars['String'];
}>;


export type PromotionDetailListingQuery = (
  { __typename?: 'Query' }
  & { PromotionDetailListing: Array<(
    { __typename?: 'PromotionDetailEntity' }
    & Pick<PromotionDetailEntity, 'ID'>
    & { RatePolicy?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateCode' | 'Description' | 'IsActive'>
    )> }
  )> }
);

export type PromotionInUseListingQueryVariables = Exact<{
  ID: Scalars['String'];
  PromoCode: Scalars['String'];
  Date: Scalars['DateTime'];
}>;


export type PromotionInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PromotionInUseListing'>
);

export type PromotionDetailsInUseListingQueryVariables = Exact<{
  RatePolicyID: Scalars['String'];
  PromoCode: Scalars['String'];
}>;


export type PromotionDetailsInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PromotionDetailsInUseListing'>
);

export type PromotionUpdateMutationVariables = Exact<{
  PromotionInput: PromotionInput;
  PromotionDetailInput: Array<PromotionDetailInput> | PromotionDetailInput;
}>;


export type PromotionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PromotionUpdate'>
);

export type PromotionActiveUpdateMutationVariables = Exact<{
  PromotionInput: PromotionInput;
}>;


export type PromotionActiveUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PromotionActiveUpdate'>
);

export type PromotionInsertMutationVariables = Exact<{
  PromotionInput: PromotionInput;
  PromotionDetailInput: Array<PromotionDetailInput> | PromotionDetailInput;
}>;


export type PromotionInsertMutation = (
  { __typename?: 'Mutation' }
  & { PromotionInsert: (
    { __typename?: 'PromotionEntity' }
    & Pick<PromotionEntity, 'ID' | 'Description' | 'Code'>
  ) }
);

export type EmailCampignInsertMutationVariables = Exact<{
  EmailCampignInput: EmailCampignInput;
}>;


export type EmailCampignInsertMutation = (
  { __typename?: 'Mutation' }
  & { EmailCampignInsert: (
    { __typename?: 'EmailCampignEntity' }
    & Pick<EmailCampignEntity, 'ID'>
  ) }
);

export type EmailCampignUpdateMutationVariables = Exact<{
  EmailCampignInput: EmailCampignInput;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type EmailCampignUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmailCampignUpdate'>
);

export type EmailMarketingListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type EmailMarketingListingQuery = (
  { __typename?: 'Query' }
  & { EmailMarketingListing: Array<(
    { __typename?: 'EmailCampignEntity' }
    & Pick<EmailCampignEntity, 'ID' | 'Title' | 'Description' | 'CreatedDT' | 'html' | 'IsDraft' | 'IsSent' | 'StartDate' | 'EndDate' | 'ShareDate' | 'EmailDate' | 'GalleryID'>
    & { Gallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL'>
    )> }
  )> }
);

export type EmailMarketingDetailQueryVariables = Exact<{
  HotelID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
}>;


export type EmailMarketingDetailQuery = (
  { __typename?: 'Query' }
  & { EmailMarketingDetail: Array<(
    { __typename?: 'EmailCampignEntity' }
    & Pick<EmailCampignEntity, 'ID' | 'Title' | 'Description' | 'html' | 'IsDraft' | 'IsSent'>
  )> }
);

export type EmailCampaignMutationVariables = Exact<{
  email: Array<Scalars['String']> | Scalars['String'];
  id: Scalars['String'];
  IsSelectAll: Scalars['Boolean'];
  HotelID: Scalars['String'];
}>;


export type EmailCampaignMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmailCampaign'>
);

export type EmailDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type EmailDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EmailDelete'>
);

export type PromotionDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
  PromoCode: Scalars['String'];
  Date: Scalars['DateTime'];
}>;


export type PromotionDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PromotionDelete'>
);

export type GuestMovementListQueryVariables = Exact<{
  HotelID: Scalars['String'];
  GuestID: Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type GuestMovementListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GuestMovementList'>
);

export type SearchGuestQueryVariables = Exact<{
  HotelID: Scalars['String'];
  GuestName: Scalars['String'];
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type SearchGuestQuery = (
  { __typename?: 'Query' }
  & { SearchGuest?: Maybe<Array<(
    { __typename?: 'SearchGuestResponse' }
    & Pick<SearchGuestResponse, 'GuestID' | 'ContactID' | 'FullName' | 'BookingID' | 'ArrivalDate' | 'DepartureDate' | 'NRIC' | 'Nationality' | 'MobileNo' | 'Email'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'state' | 'city' | 'country' | 'postCode'>
    )> }
  )>> }
);

export type DepositListingByBookingQueryVariables = Exact<{
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type DepositListingByBookingQuery = (
  { __typename?: 'Query' }
  & { DepositListingByBooking: Array<(
    { __typename?: 'DepositLedgerEntity' }
    & Pick<DepositLedgerEntity, 'ID' | 'DepositClass' | 'TrxDate' | 'Remarks' | 'ReversedID' | 'DepositAmount'>
    & { Folio?: Maybe<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'FolioNo' | 'ID' | 'FolioAmount'>
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & { DepositLedger?: Maybe<Array<(
        { __typename?: 'DepositLedgerEntity' }
        & Pick<DepositLedgerEntity, 'ID' | 'FolioID' | 'DepositAmount' | 'DepositClass'>
      )>> }
    )> }
  )> }
);

export type DepositListingByBookingV2QueryVariables = Exact<{
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type DepositListingByBookingV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'DepositListingByBookingV2'>
);

export type DepositInsertMutationVariables = Exact<{
  input: DepositInput;
}>;


export type DepositInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DepositInsert'>
);

export type RefundDepositMutationVariables = Exact<{
  input: RefundDepositInput;
}>;


export type RefundDepositMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RefundDeposit'>
);

export type ActiveBookingRoomListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type ActiveBookingRoomListingQuery = (
  { __typename?: 'Query' }
  & { ActiveBookingRoomListing: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'RoomNo'>
    & { TodayBookingNo?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo'>
      )> }
    )>> }
  )> }
);

export type PrincipalGuestListingQueryVariables = Exact<{
  BookingID: Scalars['String'];
}>;


export type PrincipalGuestListingQuery = (
  { __typename?: 'Query' }
  & { PrincipalGuestListing: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID'>
    & { Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'ID'>
      )> }
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName'>
      )> }
    )> }
  )> }
);

export type UnutilizedDepositListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type UnutilizedDepositListingQuery = (
  { __typename?: 'Query' }
  & { UnutilizedDepositListing?: Maybe<(
    { __typename?: 'DepositResponse' }
    & Pick<DepositResponse, 'DepositResult'>
  )> }
);

export type UtilizedDepositListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type UtilizedDepositListingQuery = (
  { __typename?: 'Query' }
  & { UtilizedDepositListing?: Maybe<(
    { __typename?: 'DepositResponse' }
    & Pick<DepositResponse, 'DepositResult'>
  )> }
);

export type UnutilizedDepositListingV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type UnutilizedDepositListingV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'UnutilizedDepositListingV2'>
);

export type UtilizedDepositListingV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type UtilizedDepositListingV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'UtilizedDepositListingV2'>
);

export type DepositLedgerDetailsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  ID: Scalars['String'];
}>;


export type DepositLedgerDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DepositLedgerDetails'>
);

export type DepositRefundDetailsQueryVariables = Exact<{
  DepositID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type DepositRefundDetailsQuery = (
  { __typename?: 'Query' }
  & { DepositRefundDetails: (
    { __typename?: 'DepositLedgerEntity' }
    & Pick<DepositLedgerEntity, 'ID' | 'FolioID'>
    & { Folio?: Maybe<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'FolioNo'>
    )> }
  ) }
);

export type DocumentTemplateListQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DocumentTemplateListQuery = (
  { __typename?: 'Query' }
  & { DocumentTemplateList: (
    { __typename?: 'DocumentTemplateEntity' }
    & Pick<DocumentTemplateEntity, 'RegCard' | 'Folio' | 'Receipt' | 'Invoice'>
  ) }
);

export type RegistrationSignMutationVariables = Exact<{
  ID: Scalars['String'];
  AttachmentInput: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type RegistrationSignMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RegistrationSign'>
);

export type SelectedRegistrationQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type SelectedRegistrationQuery = (
  { __typename?: 'Query' }
  & { SelectedRegistration: (
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'Signature' | 'DiscountAmount' | 'RoomRate' | 'CheckInDate'>
    & { Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'VehicleNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, RegistrationSignature?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
    )> }
  ) }
);

export type SelectFolioQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type SelectFolioQuery = (
  { __typename?: 'Query' }
  & { SelectFolio: (
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'FolioNo'>
  ) }
);

export type BookingProfileQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type BookingProfileQuery = (
  { __typename?: 'Query' }
  & { BookingProfile: (
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'ContactID' | 'DebtorID'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'Name'>
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID'>
    )>> }
  ) }
);

export type FrontDeskListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  Status?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  SortByOrder?: Maybe<Scalars['String']>;
}>;


export type FrontDeskListingQuery = (
  { __typename?: 'Query' }
  & { FrontDeskListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'BookingStatus' | 'IsGroupBooking' | 'GroupName' | 'IsCms' | 'IsCmsTtx' | 'ChargeToCityLedger' | 'BillingInstruction' | 'ReferenceNo' | 'isPrepaid' | 'Source'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'IseCommerce' | 'ID' | 'Name' | 'DebtorCategory' | 'CreditLimit' | 'DebtorAccount' | 'DebtorContacts' | 'Segment' | 'Password' | 'Login'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'Email' | 'MobileNo' | 'BirthDate' | 'Designation'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
        )> }
      )>, DebtorContact?: Maybe<Array<(
        { __typename?: 'DebtorContactEntity' }
        & Pick<DebtorContactEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'Email' | 'MobileNo'>
        )> }
      )>> }
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'MobileNo' | 'PhoneNo' | 'PassportNo' | 'NRIC'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'city' | 'state' | 'country' | 'postCode'>
      )> }
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'IsPDPA' | 'PrintRate' | 'SpecialRequest' | 'BookingID' | 'RegistrationStatus' | 'HouseLimit' | 'PromoCode' | 'DiscountAmount' | 'SharerNo' | 'RoomRate' | 'Signature' | 'ArrivalDate' | 'DepartureDate' | 'ServiceRequestCount' | 'BookingRemark' | 'NoOfAdult' | 'NoOfChild' | 'NoOfInfant' | 'CheckInBy' | 'CheckOutBy' | 'PaymentSum' | 'AdvancePaymentSum' | 'TotalOutStandingAmount' | 'IsPreCheckIn' | 'ID' | 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal' | 'IsRoomUpgrade' | 'RoomID' | 'IsTTx' | 'TTxReasonID'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'isPrepaid' | 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name' | 'ID' | 'DebtorCategory' | 'DebtorAccount'>
        )> }
      )>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'ID' | 'MaxGuest' | 'Code' | 'Overbooking'>
      )>, DepositLedger?: Maybe<Array<(
        { __typename?: 'DepositLedgerEntity' }
        & Pick<DepositLedgerEntity, 'ID' | 'FolioID' | 'DepositAmount' | 'DepositClass'>
      )>>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo' | 'RoomStatusCode' | 'HKRoomStatus' | 'ServiceRequestCount'>
        & { HouseKeepingLog?: Maybe<Array<(
          { __typename?: 'HouseKeepingLogEntity' }
          & Pick<HouseKeepingLogEntity, 'ID' | 'HotelDate'>
        )>> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'ContactID' | 'VehicleNo' | 'GuestType' | 'IsTTx' | 'TTxReasonID' | 'GuestHistoryCount'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Nationality' | 'MobileNo' | 'BirthDate' | 'NRIC' | 'PassportNo' | 'Email' | 'Designation'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'state' | 'country' | 'postCode' | 'city'>
          )> }
        )> }
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )> }
    )>> }
  )> }
);

export type CheckInListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  BookingStatus: Scalars['String'];
  SearchValue?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type CheckInListingQuery = (
  { __typename?: 'Query' }
  & { CheckInListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'BookingStatus' | 'DepartureDate' | 'ReferenceNo' | 'BillingInstruction' | 'Source'>
    & { Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'Description'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'MobileNo' | 'Email' | 'Designation'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'IsPrincipal' | 'RegistrationStatus' | 'ArrivalDate' | 'DepartureDate' | 'PrintRate'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'ContactID' | 'VehicleNo' | 'GuestType' | 'IsTTx' | 'TTxReasonID' | 'GuestHistoryCount'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Nationality' | 'MobileNo' | 'BirthDate' | 'NRIC' | 'PassportNo' | 'Email' | 'Designation'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'state' | 'country' | 'postCode' | 'city'>
          )> }
        )> }
      )> }
    )>>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'Name' | 'Segment'>
    )> }
  )> }
);

export type RegistrationByBookingListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationStatus: Scalars['String'];
}>;


export type RegistrationByBookingListingQuery = (
  { __typename?: 'Query' }
  & { RegistrationByBookingListing: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'RegistrationStatus' | 'IsRoomUpgrade' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'IsPreCheckIn' | 'SharerNo' | 'BookingID' | 'IsPrincipal' | 'SpecialRequest'>
    & { Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'BookingNo' | 'DebtorAccount' | 'BillingInstruction'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo' | 'HKRoomStatus'>
    )>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code'>
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'ID' | 'GuestType' | 'ContactID' | 'GuestHistoryCount' | 'VehicleNo'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'MobileNo' | 'Email' | 'Nationality' | 'NRIC' | 'Designation' | 'BirthDate'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'state' | 'city' | 'postCode' | 'country'>
        )> }
      )> }
    )> }
  )> }
);

export type CheckInListingCountQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type CheckInListingCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckInListingCount'>
);

export type FrontDeskListingV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  Status?: Maybe<Scalars['String']>;
  SearchValue?: Maybe<Scalars['String']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  SortByOrder?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type FrontDeskListingV2Query = (
  { __typename?: 'Query' }
  & { FrontDeskListingV2: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'BookingID' | 'RegistrationStatus' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'IsRoomUpgrade' | 'IsPrincipal' | 'SharerGuest' | 'RoomRate' | 'DiscountAmount' | 'PromoCode' | 'SharerNo' | 'AdvancePaymentSum' | 'TotalOutStandingAmount'>
    & { SharerRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'BookingStatus' | 'BookingNo' | 'DebtorAccount' | 'ChargeToCityLedger'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'NRIC'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
        )> }
      )>, Debtor?: Maybe<(
        { __typename?: 'DebtorEntity' }
        & Pick<DebtorEntity, 'ID' | 'Name' | 'DebtorCategory' | 'Segment'>
      )> }
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'ID' | 'GuestType' | 'GuestHistoryCount'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'NRIC'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
        )> }
      )> }
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo' | 'HKRoomStatus'>
    )>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code'>
    )>, RatePolicy?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateCode'>
    )> }
  )> }
);

export type GuestInsertMutationVariables = Exact<{
  ContactInput: Array<ContactInput> | ContactInput;
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  guestType?: Maybe<GuestType>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
}>;


export type GuestInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestInsert'>
);

export type ContactPaginationQueryVariables = Exact<{
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type ContactPaginationQuery = (
  { __typename?: 'Query' }
  & { ContactPagination: Array<(
    { __typename?: 'ContactEntity' }
    & Pick<ContactEntity, 'ID' | 'FullName' | 'Email'>
  )> }
);

export type GuestProfileListbyRoomQueryVariables = Exact<{
  BookingID: Scalars['String'];
  RoomID: Scalars['String'];
}>;


export type GuestProfileListbyRoomQuery = (
  { __typename?: 'Query' }
  & { GuestProfileListbyRoom: Array<(
    { __typename?: 'GuestProfileEntity' }
    & Pick<GuestProfileEntity, 'ID' | 'VehicleNo'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'Nationality' | 'MobileNo' | 'NRIC' | 'PassportNo' | 'Email'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'state' | 'country' | 'postCode' | 'city'>
      )> }
    )> }
  )> }
);

export type FrontDeskBookingInfoQueryVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type FrontDeskBookingInfoQuery = (
  { __typename?: 'Query' }
  & { FrontDeskBookingInfo: (
    { __typename?: 'BookingInfoResponse' }
    & Pick<BookingInfoResponse, 'BookingNo' | 'FullName' | 'ContactNo' | 'BookingStatus' | 'ArrivalDate' | 'DepartureDate' | 'DebtorName' | 'DebtorID' | 'PromoCode' | 'DiscountAmount'>
    & { RoomInfo?: Maybe<Array<(
      { __typename?: 'RoomInfoResponse' }
      & Pick<RoomInfoResponse, 'RegistrationID' | 'RoomNo' | 'RoomType' | 'Description' | 'RateDescription' | 'TrxDate' | 'TrxAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'SpecialRequest' | 'BookingRemark' | 'BillingInstruction' | 'IsTTx'>
      & { LedgerInfo?: Maybe<Array<(
        { __typename?: 'LedgerInfoOutput' }
        & Pick<LedgerInfoOutput, 'TrxAmount' | 'ServiceCharge' | 'TaxAmount' | 'Description' | 'BaseAmount'>
      )>>, LedgerTax?: Maybe<Array<(
        { __typename?: 'LedgerOutput' }
        & Pick<LedgerOutput, 'TaxRate' | 'TaxAmount' | 'TrxDate'>
        & { TaxInfo?: Maybe<(
          { __typename?: 'taxEntity' }
          & Pick<TaxEntity, 'code'>
        )> }
      )>> }
    )>> }
  ) }
);

export type CancelCheckInMutationVariables = Exact<{
  Remark?: Maybe<Scalars['String']>;
  ReasonID?: Maybe<Scalars['String']>;
  RoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type CancelCheckInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CancelCheckIn'>
);

export type MultiCheckInMutationVariables = Exact<{
  GroupCheckIn?: Maybe<Scalars['Boolean']>;
  GuestAppCheckIn?: Maybe<Scalars['Boolean']>;
  ContactInput?: Maybe<Array<ContactInput> | ContactInput>;
  AdditionalMultiRegInput?: Maybe<Array<AdditionalMultiRegInput> | AdditionalMultiRegInput>;
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type MultiCheckInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MultiCheckIn'>
);

export type CheckInMutationVariables = Exact<{
  IsPDPA?: Maybe<Scalars['Boolean']>;
  GroupCheckIn?: Maybe<Scalars['Boolean']>;
  GuestAppCheckIn?: Maybe<Scalars['Boolean']>;
  IdPicture?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  IdPictureBack?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  ContactInput?: Maybe<ContactInput>;
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
}>;


export type CheckInMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CheckIn'>
);

export type PreRegBookingInfoQueryVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
  QR?: Maybe<Scalars['String']>;
}>;


export type PreRegBookingInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'PreRegBookingInfo'>
);

export type DueInSubmenuBookingDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type DueInSubmenuBookingDetailsQuery = (
  { __typename?: 'Query' }
  & { BookingDetails: (
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'IsGroupBooking' | 'ChargeToCityLedger' | 'Source' | 'DebtorAccount' | 'BillingInstruction' | 'BookingStatus'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name' | 'CreditLimit'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'ArrivalDate' | 'BookingRemark' | 'DepartureDate' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'NoOfAdult' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'HouseLimit' | 'SpecialRequest' | 'RegistrationStatus' | 'ServiceRequestCount' | 'RoomTypeID' | 'ChargeRoomTypeID' | 'BookingID'>
      & { BillSchedule?: Maybe<Array<(
        { __typename?: 'BillScheduleEntity' }
        & Pick<BillScheduleEntity, 'ID' | 'ChargeType' | 'RateID'>
      )>>, RoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerEntity' }
        & Pick<RoomLedgerEntity, 'ID' | 'ChargeType'>
      )>>, AdvancePayment?: Maybe<Array<(
        { __typename?: 'AdvancePaymentEntity' }
        & Pick<AdvancePaymentEntity, 'ID'>
      )>>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'ID' | 'ReversedBillLedgerID'>
      )>>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'MaxGuest' | 'ID' | 'Overbooking'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo' | 'ID'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'IsCms' | 'IsCmsTtx'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name' | 'ID' | 'DebtorCategory' | 'DebtorAccount'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'BirthDate' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type FolioListingByRegistrationQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  FolioID?: Maybe<Scalars['String']>;
}>;


export type FolioListingByRegistrationQuery = (
  { __typename?: 'Query' }
  & { FolioListingByRegistration: Array<(
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'FolioNo' | 'FolioType' | 'FolioDate' | 'FolioAmount' | 'CreatedBy' | 'FolioName'>
    & { TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'TrxDate' | 'TaxType' | 'TaxDescription' | 'TaxAmount' | 'Reason' | 'Description'>
    )>>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'ServiceCharge' | 'TaxAmount' | 'TrxAmount' | 'BaseAmount' | 'ReferenceNo'>
      & { IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description'>
      )>, Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & { Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount' | 'Source' | 'ArrivalDate' | 'DepartureDate' | 'CreatedBy'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'Email'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
            )> }
          )> }
        )>, TaxLedger?: Maybe<Array<(
          { __typename?: 'TaxLedgerEntity' }
          & Pick<TaxLedgerEntity, 'TaxType' | 'TaxDescription' | 'TaxAmount' | 'TrxDate'>
        )>>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
          & { Registrations?: Maybe<Array<(
            { __typename?: 'RegistrationEntity' }
            & Pick<RegistrationEntity, 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
            & { Guest?: Maybe<(
              { __typename?: 'GuestProfileEntity' }
              & { Contact?: Maybe<(
                { __typename?: 'ContactEntity' }
                & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email'>
                & { Address?: Maybe<(
                  { __typename?: 'Address' }
                  & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
                )> }
              )> }
            )> }
          )>>, RoomType?: Maybe<(
            { __typename?: 'RoomTypeEntity' }
            & Pick<RoomTypeEntity, 'Code'>
          )> }
        )> }
      )> }
    )>>, RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'ID' | 'TrxAmount' | 'TaxAmount' | 'BaseAmount' | 'ServiceCharge' | 'TrxDate' | 'ReferenceNo' | 'TransactionType' | 'ChargeType' | 'TransactionID'>
      & { Transaction?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'ID' | 'Description'>
      )>, Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & { Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount' | 'Source' | 'ArrivalDate' | 'DepartureDate' | 'CreatedBy'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'Email'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
            )> }
          )> }
        )>, TaxLedger?: Maybe<Array<(
          { __typename?: 'TaxLedgerEntity' }
          & Pick<TaxLedgerEntity, 'TaxType' | 'TaxDescription' | 'TaxAmount'>
        )>>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
          & { Registrations?: Maybe<Array<(
            { __typename?: 'RegistrationEntity' }
            & Pick<RegistrationEntity, 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
            & { Guest?: Maybe<(
              { __typename?: 'GuestProfileEntity' }
              & { Contact?: Maybe<(
                { __typename?: 'ContactEntity' }
                & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'Email' | 'Nationality'>
                & { Address?: Maybe<(
                  { __typename?: 'Address' }
                  & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
                )> }
              )> }
            )> }
          )>>, RoomType?: Maybe<(
            { __typename?: 'RoomTypeEntity' }
            & Pick<RoomTypeEntity, 'Code'>
          )> }
        )> }
      )> }
    )>>, DepositLedger?: Maybe<Array<(
      { __typename?: 'DepositLedgerEntity' }
      & Pick<DepositLedgerEntity, 'DepositAmount' | 'DepositClass' | 'ReferenceNo' | 'TrxDate'>
      & { Registration?: Maybe<(
        { __typename?: 'RegistrationEntity' }
        & { Booking?: Maybe<(
          { __typename?: 'BookingEntity' }
          & Pick<BookingEntity, 'BookingNo' | 'DebtorAccount' | 'Source' | 'ArrivalDate' | 'DepartureDate' | 'CreatedBy'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'Email'>
            & { Address?: Maybe<(
              { __typename?: 'Address' }
              & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
            )> }
          )> }
        )>, TaxLedger?: Maybe<Array<(
          { __typename?: 'TaxLedgerEntity' }
          & Pick<TaxLedgerEntity, 'TaxType' | 'TaxDescription' | 'TaxAmount'>
        )>>, Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
          & { Registrations?: Maybe<Array<(
            { __typename?: 'RegistrationEntity' }
            & Pick<RegistrationEntity, 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
            & { Guest?: Maybe<(
              { __typename?: 'GuestProfileEntity' }
              & { Contact?: Maybe<(
                { __typename?: 'ContactEntity' }
                & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'Email'>
                & { Address?: Maybe<(
                  { __typename?: 'Address' }
                  & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
                )> }
              )> }
            )> }
          )>>, RoomType?: Maybe<(
            { __typename?: 'RoomTypeEntity' }
            & Pick<RoomTypeEntity, 'Code'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type FolioHistoryListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type FolioHistoryListingQuery = (
  { __typename?: 'Query' }
  & { FolioHistoryListing: Array<(
    { __typename?: 'FolioHistoryResponse' }
    & Pick<FolioHistoryResponse, 'ID' | 'InvoiceNo' | 'TrxDate' | 'RoomNo' | 'RoomType' | 'GuestName' | 'TrxAmount' | 'FolioType'>
  )> }
);

export type CheckOutMutationVariables = Exact<{
  BookingID: Scalars['String'];
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
}>;


export type CheckOutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CheckOut'>
);

export type FdReinstateBookingMutationVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  SharerNo: Scalars['Float'];
}>;


export type FdReinstateBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FDReinstateBooking'>
);

export type CheckoutReinstateInfoQueryVariables = Exact<{
  RegistrationID: Scalars['String'];
}>;


export type CheckoutReinstateInfoQuery = (
  { __typename?: 'Query' }
  & { CheckoutReinstateInfo: (
    { __typename?: 'CheckoutReinstateResponse' }
    & Pick<CheckoutReinstateResponse, 'ReasonCode' | 'ModifiedBy'>
  ) }
);

export type DueOutSubmenuBookingDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type DueOutSubmenuBookingDetailsQuery = (
  { __typename?: 'Query' }
  & { BookingDetails: (
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'IsGroupBooking' | 'ChargeToCityLedger' | 'Source' | 'DebtorAccount' | 'BillingInstruction' | 'BookingStatus'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'BirthDate' | 'PhoneNo' | 'Email' | 'Nationality' | 'Designation'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name' | 'CreditLimit'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'BookingRemark' | 'ID' | 'RoomID' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'NoOfAdult' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'HouseLimit' | 'SpecialRequest' | 'RegistrationStatus' | 'ServiceRequestCount' | 'RoomTypeID' | 'ChargeRoomTypeID' | 'Reason' | 'ModifiedDT' | 'BookingID'>
      & { BillSchedule?: Maybe<Array<(
        { __typename?: 'BillScheduleEntity' }
        & Pick<BillScheduleEntity, 'ID' | 'ChargeType' | 'RateID'>
      )>>, RoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerEntity' }
        & Pick<RoomLedgerEntity, 'ID' | 'ChargeType'>
      )>>, AdvancePayment?: Maybe<Array<(
        { __typename?: 'AdvancePaymentEntity' }
        & Pick<AdvancePaymentEntity, 'ID'>
      )>>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'ID'>
      )>>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'MaxGuest' | 'ID' | 'Overbooking'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo' | 'ID'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'IsCms' | 'IsCmsTtx'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name' | 'ID' | 'DebtorCategory' | 'DebtorAccount'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'BirthDate' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type FrontDeskInfoLinesQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type FrontDeskInfoLinesQuery = (
  { __typename?: 'Query' }
  & { FrontDeskInfoLines: (
    { __typename?: 'FrontDeskInfoLineResponse' }
    & Pick<FrontDeskInfoLineResponse, 'TotalDueIn' | 'TotalCheckIn' | 'TotalGroupDueIn' | 'TotalGroupCheckIn' | 'TotalWalkIn' | 'RegistrationDueOut' | 'DepartureDueOut' | 'TotalDepartureDueOut' | 'TotalRegistrationDueOut' | 'TotalRoom' | 'TotalGuest' | 'DepositSum' | 'TotalDepositRoom' | 'TotalUnAssignedRoomRegistration' | 'TotalNonGuestFolio' | 'TotalSuspendFolio' | 'TotalContactTracingOutlet'>
  ) }
);

export type GetAdjustmentTaxQueryVariables = Exact<{
  HotelID: Scalars['String'];
  EffectiveDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetAdjustmentTaxQuery = (
  { __typename?: 'Query' }
  & { GetAdjustmentTax: (
    { __typename?: 'AdjustmentTaxOutput' }
    & Pick<AdjustmentTaxOutput, 'TTx' | 'TTxAmount' | 'SSF' | 'SSFAmount'>
  ) }
);

export type RegistrationTaxLedgerListingQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
}>;


export type RegistrationTaxLedgerListingQuery = (
  { __typename?: 'Query' }
  & { RegistrationTaxLedgerListing: Array<(
    { __typename?: 'TaxLedgerEntity' }
    & Pick<TaxLedgerEntity, 'ID' | 'RegistrationID' | 'FolioID' | 'PaymentID' | 'ReversedTaxLedgerID' | 'CreatedDT' | 'CreatedBy' | 'TrxDate' | 'TaxType' | 'TaxDescription' | 'Description' | 'IsAdjustment' | 'Qty' | 'TaxAmount' | 'HotelID'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )> }
  )> }
);

export type AllRoomAsignListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
}>;


export type AllRoomAsignListingQuery = (
  { __typename?: 'Query' }
  & { AllRoomAsignListing: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'Description' | 'RoomNo' | 'RoomStatusCode'>
    & { RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'Code' | 'Description'>
    )>, Registrations?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'RegistrationStatus'>
    )>>, Location?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'Code' | 'Description'>
    )> }
  )> }
);

export type IsPaymentGateWayHotelQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type IsPaymentGateWayHotelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsPaymentGateWayHotel'>
);

export type FolioBeneficiaryNameUpdateMutationVariables = Exact<{
  FolioID: Scalars['String'];
  BeneficiaryName: Scalars['String'];
  BeneficiaryAddress: Scalars['String'];
}>;


export type FolioBeneficiaryNameUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FolioBeneficiaryNameUpdate'>
);

export type TaxLedgerInsertMutationVariables = Exact<{
  TaxLedgerInput: Array<TaxLedgerInput> | TaxLedgerInput;
}>;


export type TaxLedgerInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TaxLedgerInsert'>
);

export type LockUserProcessMutationVariables = Exact<{
  RoomNo: Array<Scalars['String']> | Scalars['String'];
  Process: Scalars['String'];
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
}>;


export type LockUserProcessMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LockUserProcess'>
);

export type IsLockUserProcessQueryVariables = Exact<{
  RoomNo: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type IsLockUserProcessQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsLockUserProcess'>
);

export type FrontDeskIncidentalChargeListingQueryVariables = Exact<{
  RegistrationID: Scalars['String'];
}>;


export type FrontDeskIncidentalChargeListingQuery = (
  { __typename?: 'Query' }
  & { FrontDeskIncidentalChargeListing: Array<(
    { __typename?: 'BillLedgerEntity' }
    & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'ReferenceNo' | 'CreatedBy' | 'CreatedName' | 'ModifiedDT' | 'CreatedDT' | 'ModifiedBy' | 'Description' | 'Qty' | 'UnitPrice' | 'ReversedBillLedgerID' | 'FolioID' | 'DiscountAmount' | 'TrxAmount' | 'TaxAmount' | 'Reason' | 'ServiceCharge' | 'BaseAmount'>
    & { TaxBillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerTaxEntity' }
      & Pick<BillLedgerTaxEntity, 'TaxSchemeID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'ID' | 'BillLedgerID'>
      & { TaxInfo?: Maybe<(
        { __typename?: 'taxEntity' }
        & Pick<TaxEntity, 'code'>
      )> }
    )>>, IncidentalCharge?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'Description' | 'IsOutlet' | 'IsEventInterface'>
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ModifiedDT'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )> }
  )> }
);

export type VoidBillLedgerMutationVariables = Exact<{
  input: BillLedgerInput;
}>;


export type VoidBillLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'VoidBillLedger'>
);

export type ComputeTaxbyHotelQueryVariables = Exact<{
  input: TaxInput;
}>;


export type ComputeTaxbyHotelQuery = (
  { __typename?: 'Query' }
  & { ComputeTaxbyHotel: (
    { __typename?: 'TaxOutput' }
    & Pick<TaxOutput, 'DiscountAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'TotalAmount'>
    & { HotelTaxOutput?: Maybe<Array<(
      { __typename?: 'HotelTaxOutput' }
      & Pick<HotelTaxOutput, 'TaxCode' | 'Value' | 'TaxID' | 'TaxRate' | 'SeqNo' | 'TaxScheme'>
    )>> }
  ) }
);

export type ComputeUpgradebyHotelQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RateID: Scalars['String'];
  RegistrationID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Mode: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
}>;


export type ComputeUpgradebyHotelQuery = (
  { __typename?: 'Query' }
  & { ComputeUpgradebyHotel: (
    { __typename?: 'TaxOutput' }
    & Pick<TaxOutput, 'DiscountAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'TotalAmount'>
    & { HotelTaxOutput?: Maybe<Array<(
      { __typename?: 'HotelTaxOutput' }
      & Pick<HotelTaxOutput, 'TaxCode' | 'Value' | 'TaxID' | 'TaxRate' | 'SeqNo' | 'TaxScheme'>
    )>> }
  ) }
);

export type IsRoomAvailableQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type IsRoomAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsRoomAvailable'>
);

export type IsRoomAvailableGroupQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomIDs: Array<Scalars['String']> | Scalars['String'];
  RegistrationIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type IsRoomAvailableGroupQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsRoomAvailableGroup'>
);

export type SpecialReqUpdateMutationVariables = Exact<{
  RegistrationID: Scalars['String'];
  SpecialReq: Scalars['String'];
}>;


export type SpecialReqUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SpecialReqUpdate'>
);

export type LateCheckOutInsertMutationVariables = Exact<{
  hour: Scalars['Float'];
  input: FrontDeskPaymentInput;
  ReceivedAmount?: Maybe<Scalars['Float']>;
  AdjustedAmount?: Maybe<Scalars['Float']>;
}>;


export type LateCheckOutInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LateCheckOutInsert'>
);

export type ExtendStayInsertMutationVariables = Exact<{
  RoomTypeID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
  input: FrontDeskPaymentInput;
  ReceivedAmount: Scalars['Float'];
  AdjustedAmount: Scalars['Float'];
}>;


export type ExtendStayInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ExtendStayInsert'>
);

export type ShortenStayInsertMutationVariables = Exact<{
  RoomTypeID: Scalars['String'];
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
  ArrivalDate: Scalars['DateTime'];
  DepartureDate: Scalars['DateTime'];
}>;


export type ShortenStayInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ShortenStayInsert'>
);

export type RoomUpGradeInsertMutationVariables = Exact<{
  RoomUpgradeInput: RoomUpgradeInput;
  PaymentInput?: Maybe<FrontDeskPaymentInput>;
  IsBooking?: Maybe<Scalars['Boolean']>;
  IsBaypassCMSControl?: Maybe<Scalars['Boolean']>;
  ReceivedAmount: Scalars['Float'];
  AdjustedAmount: Scalars['Float'];
}>;


export type RoomUpGradeInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RoomUpGradeInsert'>
);

export type RoomUpgradeCheckQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type RoomUpgradeCheckQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RoomUpgradeCheck'>
);

export type HouseLimitUpdateMutationVariables = Exact<{
  HouseLimitInput?: Maybe<Array<HouseLimitInput> | HouseLimitInput>;
}>;


export type HouseLimitUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HouseLimitUpdate'>
);

export type TransferRoomMutationVariables = Exact<{
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  OldRoomID: Scalars['String'];
  NewRoomID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
}>;


export type TransferRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TransferRoom'>
);

export type RegistrationListingQueryVariables = Exact<{
  RoomID: Scalars['String'];
}>;


export type RegistrationListingQuery = (
  { __typename?: 'Query' }
  & { RegistrationListing: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'CheckInDate' | 'CheckOutDate' | 'RegistrationStatus'>
    & { Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ArrivalDate' | 'DepartureDate'>
    )> }
  )> }
);

export type RateAdjustmentAuditListingQueryVariables = Exact<{
  RegistrationID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type RateAdjustmentAuditListingQuery = (
  { __typename?: 'Query' }
  & { RateAdjustmentAuditListing: Array<(
    { __typename?: 'RateAdjustmentAuditEntity' }
    & Pick<RateAdjustmentAuditEntity, 'ID' | 'HotelID' | 'BookingID' | 'RegistrationID' | 'OldRateID' | 'NewRateID' | 'EffectiveDate' | 'Reason' | 'OldPromoCode' | 'OldRoomRate' | 'OldDiscountAmount'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'RoomRate' | 'DiscountAmount' | 'PromoCode'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
      )> }
    )>, OldRate?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
    )>, NewRate?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
    )> }
  )> }
);

export type RateAdjustmentAuditInsertMutationVariables = Exact<{
  RateAdjustmentAuditInput: RateAdjustmentAuditInput;
}>;


export type RateAdjustmentAuditInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RateAdjustmentAuditInsert'>
);

export type InhouseQrQueryVariables = Exact<{
  BookingID: Scalars['String'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type InhouseQrQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'InhouseQr'>
);

export type InHouseSubmenuBookingDetailsQueryVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type InHouseSubmenuBookingDetailsQuery = (
  { __typename?: 'Query' }
  & { BookingDetails: (
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'isPrepaid' | 'IsGroupBooking' | 'ChargeToCityLedger' | 'Source' | 'DebtorAccount' | 'BillingInstruction' | 'BookingStatus'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'PhoneNo' | 'MobileNo' | 'Email' | 'NRIC'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorAccount' | 'DebtorCategory' | 'ID' | 'Name' | 'CreditLimit' | 'IseCommerce'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'BookingRemark' | 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'NoOfAdult' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'HouseLimit' | 'SpecialRequest' | 'RegistrationStatus' | 'ServiceRequestCount' | 'RoomTypeID' | 'ChargeRoomTypeID' | 'BookingID' | 'PassCode'>
      & { BillSchedule?: Maybe<Array<(
        { __typename?: 'BillScheduleEntity' }
        & Pick<BillScheduleEntity, 'ID' | 'ChargeType' | 'RateID'>
      )>>, RoomLedger?: Maybe<Array<(
        { __typename?: 'RoomLedgerEntity' }
        & Pick<RoomLedgerEntity, 'ID' | 'ChargeType'>
      )>>, AdvancePayment?: Maybe<Array<(
        { __typename?: 'AdvancePaymentEntity' }
        & Pick<AdvancePaymentEntity, 'ID'>
      )>>, BillLedger?: Maybe<Array<(
        { __typename?: 'BillLedgerEntity' }
        & Pick<BillLedgerEntity, 'ID'>
      )>>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'MaxGuest' | 'ID' | 'Overbooking'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo' | 'ID'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'isPrepaid' | 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'IsCms' | 'IsCmsTtx'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name' | 'ID' | 'DebtorCategory' | 'DebtorAccount'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'BirthDate' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
          )> }
        )> }
      )> }
    )>> }
  ) }
);

export type FrontDeskSubMenuInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RegistrationIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type FrontDeskSubMenuInfoQuery = (
  { __typename?: 'Query' }
  & { FrontDeskSubMenuInfo: Array<(
    { __typename?: 'FrontDeskInfoResponse' }
    & Pick<FrontDeskInfoResponse, 'RegistrationID' | 'BillScheduleSum' | 'IncidentalChargesSum' | 'TotalOutStandingAmount' | 'DepositSum' | 'FolioCount' | 'PaymentSum' | 'AdvPaymentSum' | 'ServiceRequestCount'>
  )> }
);

export type IsPresetIncChargesExistQueryVariables = Exact<{
  BookingID: Scalars['String'];
  RegistrationID: Scalars['String'];
  newDepartureDate: Scalars['DateTime'];
}>;


export type IsPresetIncChargesExistQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsPresetIncChargesExist'>
);

export type KeyCardInsertMutationVariables = Exact<{
  input: KeyCardInput;
  UserID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
}>;


export type KeyCardInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'KeyCardInsert'>
);

export type TTlockInfoQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type TTlockInfoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TTlockInfo'>
);

export type NonGuestFolioListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  FolioID?: Maybe<Scalars['String']>;
}>;


export type NonGuestFolioListingQuery = (
  { __typename?: 'Query' }
  & { NonGuestFolioListing: Array<(
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'AccountID' | 'HotelID' | 'FolioType' | 'RefID' | 'FolioNo' | 'FolioDate' | 'FolioAmount' | 'ClosedDate' | 'ClosedBy' | 'IsPosted' | 'is_einvoice' | 'einvoice_status'>
    & { NonGuestBalance: (
      { __typename?: 'NonGuestBalance' }
      & Pick<NonGuestBalance, 'TotalBill' | 'TotalPaid' | 'TotalLeft'>
    ), NonGuestInfo?: Maybe<(
      { __typename?: 'NonGuestInfoJson' }
      & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit' | 'ReferenceID'>
    )> }
  )> }
);

export type NonGuestFolioListingv2QueryVariables = Exact<{
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  HotelID: Scalars['String'];
  FolioID?: Maybe<Scalars['String']>;
  ReferenceID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  DebtorID?: Maybe<Scalars['String']>;
}>;


export type NonGuestFolioListingv2Query = (
  { __typename?: 'Query' }
  & { NonGuestFolioListingv2: (
    { __typename?: 'SuspendFolioResponse' }
    & Pick<SuspendFolioResponse, 'TotalCount'>
    & { Listing?: Maybe<Array<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'AccountID' | 'HotelID' | 'FolioType' | 'RefID' | 'FolioNo' | 'FolioDate' | 'FolioAmount' | 'ClosedDate' | 'ClosedBy' | 'IsPosted'>
      & { NonGuestBalance: (
        { __typename?: 'NonGuestBalance' }
        & Pick<NonGuestBalance, 'TotalBill' | 'TotalPaid' | 'TotalLeft'>
      ), NonGuestInfo?: Maybe<(
        { __typename?: 'NonGuestInfoJson' }
        & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit' | 'ReferenceID'>
      )> }
    )>> }
  ) }
);

export type NonGuestFolioInsertMutationVariables = Exact<{
  FolioInput: FolioInput;
}>;


export type NonGuestFolioInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NonGuestFolioInsert'>
);

export type NonGuestBillingListingQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
}>;


export type NonGuestBillingListingQuery = (
  { __typename?: 'Query' }
  & { NonGuestBillingListing: Array<(
    { __typename?: 'NonGuestFolioResponse' }
    & Pick<NonGuestFolioResponse, 'ID' | 'IncidentalDescription' | 'EffectiveDate' | 'ReferenceNo' | 'ReversedID' | 'TrxAmount' | 'TaxAmount' | 'ServiceCharge' | 'BaseAmount' | 'DiscountAmount' | 'Qty' | 'Type' | 'ReceiptNo' | 'Description' | 'CreatedBy' | 'CreatedDT' | 'IsOutlet' | 'IsEventInterface'>
    & { TaxBillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerTaxEntity' }
      & Pick<BillLedgerTaxEntity, 'ID' | 'BillLedgerID' | 'TaxSchemeID' | 'TaxSchemeDetailID' | 'TaxID' | 'TaxRate' | 'TaxAmount'>
      & { TaxInfo?: Maybe<(
        { __typename?: 'taxEntity' }
        & Pick<TaxEntity, 'code'>
      )> }
    )>> }
  )> }
);

export type NonGuestVoidLedgerMutationVariables = Exact<{
  BillLedgerID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
}>;


export type NonGuestVoidLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NonGuestVoidLedger'>
);

export type NonGuestPaymentInsertMutationVariables = Exact<{
  BillLedgerIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  PaymentInput: PaymentInput;
  HotelID: Scalars['String'];
}>;


export type NonGuestPaymentInsertMutation = (
  { __typename?: 'Mutation' }
  & { NonGuestPaymentInsert: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ID' | 'ReceiptNo'>
  ) }
);

export type NonGuestFolioUpdateMutationVariables = Exact<{
  FolioInput: FolioInput;
}>;


export type NonGuestFolioUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NonGuestFolioUpdate'>
);

export type NonGuestCloseFolioMutationVariables = Exact<{
  HotelID: Scalars['String'];
  FolioID: Scalars['String'];
  eInvoiceType?: Maybe<Scalars['String']>;
}>;


export type NonGuestCloseFolioMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NonGuestCloseFolio'>
);

export type FolioDeleteMutationVariables = Exact<{
  FolioID: Scalars['String'];
}>;


export type FolioDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FolioDelete'>
);

export type FolioUseListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  FolioID: Scalars['String'];
}>;


export type FolioUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'FolioUseListing'>
);

export type NonGuestTransferFolioMutationVariables = Exact<{
  FolioID: Scalars['String'];
  RecordIDs: Array<Scalars['String']> | Scalars['String'];
  IsSuspend?: Maybe<Scalars['Boolean']>;
  HotelID?: Maybe<Scalars['String']>;
}>;


export type NonGuestTransferFolioMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'NonGuestTransferFolio'>
);

export type FrontDeskPaymentListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type FrontDeskPaymentListingQuery = (
  { __typename?: 'Query' }
  & { FrontDeskPaymentListing: Array<(
    { __typename?: 'FrontDeskResponse' }
    & Pick<FrontDeskResponse, 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'Description' | 'FullName' | 'MaxGuest' | 'Code' | 'RoomNo' | 'BookingStatus' | 'GuestID' | 'IncidentalAmt' | 'PaymentAmt' | 'BookingID' | 'RegistrationID' | 'RoomTypeID' | 'RoomID' | 'DebtorName' | 'TrxAmount' | 'BaseAmount' | 'DiscountAmount' | 'TaxAmount' | 'ServiceCharge' | 'ChargeType' | 'FolioID'>
  )> }
);

export type CurrentTaxDetailsQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type CurrentTaxDetailsQuery = (
  { __typename?: 'Query' }
  & { CurrentTaxDetails: (
    { __typename?: 'CurrentTaxDetailsOutput' }
    & Pick<CurrentTaxDetailsOutput, 'HotelID' | 'GovTax' | 'ServiceCharge'>
  ) }
);

export type FrontDeskPaymentInsertMutationVariables = Exact<{
  input: Array<FrontDeskPaymentInput> | FrontDeskPaymentInput;
  TotalPaymentAmount: Scalars['Float'];
  DebtorID?: Maybe<Scalars['String']>;
  BeneficiaryName?: Maybe<Scalars['String']>;
  BeneficiaryAddress?: Maybe<Scalars['String']>;
  IsGroupFolio?: Maybe<Scalars['Boolean']>;
  AdjustedAmount?: Maybe<Scalars['Float']>;
}>;


export type FrontDeskPaymentInsertMutation = (
  { __typename?: 'Mutation' }
  & { FrontDeskPaymentInsert: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ID' | 'ReceiptNo' | 'PaymentDate' | 'PaymentClass' | 'PaymentType' | 'CardNo' | 'Amount' | 'ReferenceNo' | 'PaymentStatus' | 'FolioID'>
  ) }
);

export type SplitPaymentV2MutationVariables = Exact<{
  BookingID: Scalars['String'];
  RecordToSplit: Array<PaymentFolioInput> | PaymentFolioInput;
  SplitBy: Scalars['Float'];
  HotelID: Scalars['String'];
  SplitType: Scalars['String'];
  SplitValue: Scalars['Float'];
}>;


export type SplitPaymentV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SplitPaymentV2'>
);

export type TransferPaymentMutationVariables = Exact<{
  FromRegistrationID?: Maybe<Scalars['String']>;
  NewRegistrationID: Scalars['String'];
  RecordIDs: Array<Scalars['String']> | Scalars['String'];
  FolioID?: Maybe<Scalars['String']>;
  IsNonguest?: Maybe<Scalars['Boolean']>;
}>;


export type TransferPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TransferPayment'>
);

export type BillingByRegistrationQueryVariables = Exact<{
  RegistrationID: Array<Scalars['String']> | Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type BillingByRegistrationQuery = (
  { __typename?: 'Query' }
  & { BillingByRegistration: Array<(
    { __typename?: 'PaymentFoilioResponse' }
    & Pick<PaymentFoilioResponse, 'RegistrationID' | 'TrxDate' | 'ChargeType' | 'Description' | 'ReferenceNo' | 'FullName' | 'RoomNo' | 'FolioID' | 'TrxAmount' | 'BaseAmount' | 'DiscountAmount' | 'UnitPrice' | 'ID' | 'LedgerType' | 'ServiceCharge' | 'TaxAmount' | 'Type' | 'DebtorName' | 'DebtorID' | 'DebtorAccount' | 'CreatedName' | 'CreatedDT' | 'CreatedBy' | 'SplitGroup' | 'IsPosted' | 'FromRegistration' | 'IsOutlet' | 'IsEventInterface'>
    & { BillScheduleTax?: Maybe<Array<(
      { __typename?: 'BillScheduleTaxEntity' }
      & Pick<BillScheduleTaxEntity, 'ID' | 'HotelID' | 'AccountID' | 'TaxSchemeID' | 'TaxSchemeDetailID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'name'>
    )>>, RoomLedgerTax?: Maybe<Array<(
      { __typename?: 'RoomLedgerTaxEntity' }
      & Pick<RoomLedgerTaxEntity, 'ID' | 'HotelID' | 'AccountID' | 'TaxSchemeID' | 'TaxSchemeDetailID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'name'>
    )>>, BillLedgerTax?: Maybe<Array<(
      { __typename?: 'BillLedgerTaxEntity' }
      & Pick<BillLedgerTaxEntity, 'ID' | 'HotelID' | 'AccountID' | 'TaxSchemeID' | 'TaxSchemeDetailID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'name'>
    )>> }
  )> }
);

export type OnlinePaymentMutationVariables = Exact<{
  redirectUrl: Scalars['String'];
  PayAmount: Scalars['Float'];
  Detail: Scalars['String'];
  Title: Scalars['String'];
  HotelID: Scalars['String'];
  ReceiptNo: Scalars['String'];
  AdvancePaymentID?: Maybe<Scalars['String']>;
  PaymentID?: Maybe<Scalars['String']>;
}>;


export type OnlinePaymentMutation = (
  { __typename?: 'Mutation' }
  & { OnlinePayment?: Maybe<(
    { __typename?: 'CheckOut' }
    & Pick<CheckOut, 'code' | 'PaySessionNo'>
    & { item?: Maybe<(
      { __typename?: 'item' }
      & Pick<Item, 'checkoutId' | 'url' | 'qrCodeUrl' | 'status'>
    )>, error?: Maybe<(
      { __typename?: 'Errors' }
      & Pick<Errors, 'code' | 'message' | 'debug'>
    )> }
  )> }
);

export type PaymentUpdateMutationVariables = Exact<{
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Scalars['String']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  HotelID?: Maybe<Scalars['String']>;
}>;


export type PaymentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PaymentUpdate'>
);

export type PaymentDetailQueryVariables = Exact<{
  OrderID?: Maybe<Scalars['String']>;
  RegistrationID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type PaymentDetailQuery = (
  { __typename?: 'Query' }
  & { PaymentDetail: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ID' | 'FolioID' | 'RegistrationID' | 'HotelID' | 'ReceiptNo' | 'PaymentDate' | 'PaymentClass' | 'PaymentType' | 'DebtorAccount' | 'PaySessionNo' | 'CheckOutID' | 'CardNo' | 'Amount' | 'ReferenceNo' | 'PaymentStatus'>
  ) }
);

export type VoidLedgerMutationVariables = Exact<{
  ChargeType?: Maybe<Scalars['String']>;
  TrxDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  RegistrationID: Scalars['String'];
  ID: Scalars['String'];
  LedgerType: Scalars['String'];
  FromRegistration?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
}>;


export type VoidLedgerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'VoidLedger'>
);

export type RoundingAdjustmentQueryVariables = Exact<{
  Amount: Scalars['Float'];
  HotelID: Scalars['String'];
}>;


export type RoundingAdjustmentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RoundingAdjustment'>
);

export type RoundingAmountQueryVariables = Exact<{
  Amount: Scalars['Float'];
  HotelID: Scalars['String'];
}>;


export type RoundingAmountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RoundingAmount'>
);

export type TransferFrontDeskListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  Status?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  ArrivalDate?: Maybe<Scalars['DateTime']>;
}>;


export type TransferFrontDeskListingQuery = (
  { __typename?: 'Query' }
  & { FrontDeskListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'BookingStatus'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'Name' | 'DebtorCategory' | 'DebtorAccount' | 'DebtorContacts'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'Email'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'IsPrincipal' | 'RegistrationStatus' | 'ArrivalDate' | 'DepartureDate' | 'ID' | 'CheckOutDate'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'isPrepaid' | 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )>, Debtor?: Maybe<(
          { __typename?: 'DebtorEntity' }
          & Pick<DebtorEntity, 'Name' | 'ID' | 'DebtorCategory' | 'DebtorAccount' | 'IseCommerce'>
        )> }
      )>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'ID' | 'MaxGuest' | 'Code'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo' | 'RoomStatusCode'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName'>
        )> }
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )> }
    )>> }
  )> }
);

export type AssignRoomMutationVariables = Exact<{
  RoomID?: Maybe<Scalars['String']>;
  Mode?: Maybe<Scalars['Boolean']>;
  RegistrationID: Scalars['String'];
  BookingID: Scalars['String'];
}>;


export type AssignRoomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignRoom'>
);

export type AssignRoomLocationQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type AssignRoomLocationQuery = (
  { __typename?: 'Query' }
  & { LocationListing: Array<(
    { __typename?: 'LocationEntity' }
    & Pick<LocationEntity, 'ID' | 'Code' | 'Description'>
    & { Room?: Maybe<Array<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo' | 'MaxGuest' | 'RoomSize' | 'IsActive'>
      & { RoomAssignmentStatus?: Maybe<(
        { __typename?: 'RoomStatusEntity' }
        & Pick<RoomStatusEntity, 'ID' | 'Description' | 'RGBColor' | 'Code' | 'VacantOrOccupied' | 'CleanOrDirty'>
      )> }
    )>> }
  )> }
);

export type AssignRoomFrontDeskListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type AssignRoomFrontDeskListingQuery = (
  { __typename?: 'Query' }
  & { AssignRoomFrontDeskListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'BookingStatus'>
    & { BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'BookingID' | 'RegistrationStatus' | 'PromoCode' | 'DiscountAmount' | 'RoomRate' | 'Signature' | 'ID' | 'DepositSum' | 'PaymentSum' | 'CheckInDate' | 'CheckOutDate' | 'IncidentalChargesSum' | 'IncidentalChargesCount' | 'IsPrincipal' | 'RoomID' | 'TotalRoomPrice' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'ID' | 'MaxGuest' | 'Code'>
      )>, DepositLedger?: Maybe<Array<(
        { __typename?: 'DepositLedgerEntity' }
        & Pick<DepositLedgerEntity, 'ID' | 'FolioID' | 'DepositAmount' | 'DepositClass'>
      )>>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo' | 'ServiceRequestCount'>
        & { HouseKeepingLog?: Maybe<Array<(
          { __typename?: 'HouseKeepingLogEntity' }
          & Pick<HouseKeepingLogEntity, 'ID' | 'HotelDate'>
        )>> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'ContactID' | 'VehicleNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Nationality' | 'MobileNo' | 'BirthDate' | 'NRIC' | 'PassportNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'state' | 'country' | 'postCode' | 'city'>
          )> }
        )> }
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )> }
    )>> }
  )> }
);

export type FloorPlanGalleryQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type FloorPlanGalleryQuery = (
  { __typename?: 'Query' }
  & { FloorPlanGallery: (
    { __typename?: 'GalleryEntity' }
    & Pick<GalleryEntity, 'ID' | 'ImageURL'>
  ) }
);

export type RoomAssignListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate?: Maybe<Scalars['DateTime']>;
  IsBooking?: Maybe<Scalars['Boolean']>;
  RoomID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  RegistrationIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type RoomAssignListingQuery = (
  { __typename?: 'Query' }
  & { RoomAssignListing: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'RoomNo' | 'RoomTypeID' | 'FloorPlanID' | 'IsActive' | 'MaxGuest' | 'Description' | 'RoomSize'>
    & { FloorPlan?: Maybe<Array<(
      { __typename?: 'LocationFloorPlanEntity' }
      & Pick<LocationFloorPlanEntity, 'ID' | 'Coordinates' | 'IsHotel' | 'IsPreCheckin'>
    )>>, Registrations?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID'>
    )>>, Location?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'ID' | 'Code' | 'Description' | 'GalleryID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL'>
      )> }
    )>, RoomAssignmentStatus?: Maybe<(
      { __typename?: 'RoomStatusEntity' }
      & Pick<RoomStatusEntity, 'CleanOrDirty' | 'Code' | 'Description' | 'ID' | 'RGBColor' | 'VacantOrOccupied'>
    )> }
  )> }
);

export type RoomAssignmentNotifySubscriptionVariables = Exact<{
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate?: Maybe<Scalars['DateTime']>;
  IsBooking?: Maybe<Scalars['Boolean']>;
  RoomID?: Maybe<Scalars['String']>;
}>;


export type RoomAssignmentNotifySubscription = (
  { __typename?: 'Subscription' }
  & { RoomAssignmentNotify: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'RoomNo' | 'RoomTypeID' | 'IsActive' | 'MaxGuest' | 'Description' | 'RoomSize'>
    & { Registrations?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID'>
    )>>, Location?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'ID' | 'Code' | 'Description'>
    )>, RoomAssignmentStatus?: Maybe<(
      { __typename?: 'RoomStatusEntity' }
      & Pick<RoomStatusEntity, 'CleanOrDirty' | 'Code' | 'Description' | 'ID' | 'RGBColor' | 'VacantOrOccupied'>
    )> }
  )> }
);

export type WalkInRoomAssignedMutationVariables = Exact<{
  RoomID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
}>;


export type WalkInRoomAssignedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'walkInRoomAssigned'>
);

export type FrontDeskServiceRequestListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type FrontDeskServiceRequestListingQuery = (
  { __typename?: 'Query' }
  & { FrontDeskServiceRequestListing: Array<(
    { __typename?: 'ServiceRequestEntity' }
    & Pick<ServiceRequestEntity, 'ID' | 'Title' | 'Description' | 'EstimatedCompletion' | 'RequestBy' | 'Status' | 'Remarks' | 'DepartmentID' | 'ClosedDate' | 'CreatedBy' | 'ModifiedBy'>
    & { Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo'>
      & { Location?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'ID' | 'Description'>
      )> }
    )>, ServiceRequestGallery?: Maybe<Array<(
      { __typename?: 'ServiceRequestGalleryEntity' }
      & Pick<ServiceRequestGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Name'>
    )> }
  )> }
);

export type StayViewListingQueryVariables = Exact<{
  Location?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  RoomNo?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  RoomTypeIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  enddate: Scalars['DateTime'];
  startdate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type StayViewListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'StayViewListing'>
);

export type SuspendFolioListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  FolioID?: Maybe<Scalars['String']>;
}>;


export type SuspendFolioListingQuery = (
  { __typename?: 'Query' }
  & { SuspendFolioListing: Array<(
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'AccountID' | 'HotelID' | 'FolioType' | 'RefID' | 'FolioNo' | 'FolioDate' | 'FolioAmount' | 'ClosedDate' | 'ClosedBy' | 'IsPosted' | 'is_einvoice' | 'einvoice_status'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID'>
    )>, NonGuestBalance: (
      { __typename?: 'NonGuestBalance' }
      & Pick<NonGuestBalance, 'TotalBill' | 'TotalPaid' | 'TotalLeft'>
    ), NonGuestInfo?: Maybe<(
      { __typename?: 'NonGuestInfoJson' }
      & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit' | 'ReferenceID'>
    )> }
  )> }
);

export type SuspendFolioListingv2QueryVariables = Exact<{
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  HotelID: Scalars['String'];
  FolioID?: Maybe<Scalars['String']>;
  ReferenceID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  FolioDate?: Maybe<Scalars['DateTime']>;
  DebtorID?: Maybe<Scalars['String']>;
}>;


export type SuspendFolioListingv2Query = (
  { __typename?: 'Query' }
  & { SuspendFolioListingv2: (
    { __typename?: 'SuspendFolioResponse' }
    & Pick<SuspendFolioResponse, 'TotalCount'>
    & { Listing?: Maybe<Array<(
      { __typename?: 'FolioEntity' }
      & Pick<FolioEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'AccountID' | 'HotelID' | 'FolioType' | 'RefID' | 'FolioNo' | 'FolioDate' | 'FolioAmount' | 'ClosedDate' | 'ClosedBy' | 'IsPosted'>
      & { NonGuestBalance: (
        { __typename?: 'NonGuestBalance' }
        & Pick<NonGuestBalance, 'TotalBill' | 'TotalPaid' | 'TotalLeft'>
      ), NonGuestInfo?: Maybe<(
        { __typename?: 'NonGuestInfoJson' }
        & Pick<NonGuestInfoJson, 'EffectiveDate' | 'Title' | 'DebtorID' | 'Remark' | 'HouseLimit' | 'ReferenceID'>
      )> }
    )>> }
  ) }
);

export type PendingBillingListingQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  FolioID: Scalars['String'];
}>;


export type PendingBillingListingQuery = (
  { __typename?: 'Query' }
  & { PendingBillingListing: Array<(
    { __typename?: 'NonGuestFolioResponse' }
    & Pick<NonGuestFolioResponse, 'ID' | 'IncidentalDescription' | 'EffectiveDate' | 'ReferenceNo' | 'ReversedID' | 'TrxAmount' | 'TaxAmount' | 'ServiceCharge' | 'BaseAmount' | 'DiscountAmount' | 'Type' | 'TransactionType' | 'ReceiptNo' | 'Description' | 'Qty' | 'RegistrationID' | 'IsOutlet' | 'IsEventInterface' | 'CreatedBy' | 'CreatedDT' | 'UnitPrice' | 'Reason'>
    & { TaxBillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerTaxEntity' }
      & Pick<BillLedgerTaxEntity, 'ID' | 'BillLedgerID' | 'TaxSchemeID' | 'TaxSchemeDetailID' | 'TaxID' | 'TaxRate' | 'TaxAmount'>
      & { TaxInfo?: Maybe<(
        { __typename?: 'taxEntity' }
        & Pick<TaxEntity, 'code'>
      )> }
    )>>, TaxRoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerTaxEntity' }
      & Pick<RoomLedgerTaxEntity, 'ID' | 'RoomLedgerID' | 'TaxSchemeID' | 'TaxSchemeDetailID' | 'TaxID' | 'TaxRate' | 'TaxAmount'>
      & { TaxInfo?: Maybe<(
        { __typename?: 'taxEntity' }
        & Pick<TaxEntity, 'code'>
      )> }
    )>> }
  )> }
);

export type SuspendFolioInsertMutationVariables = Exact<{
  FolioInput: FolioInput;
}>;


export type SuspendFolioInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SuspendFolioInsert'>
);

export type SuspendDetailQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type SuspendDetailQuery = (
  { __typename?: 'Query' }
  & { SuspendDetail: (
    { __typename?: 'FolioEntity' }
    & Pick<FolioEntity, 'ID' | 'FolioNo'>
    & { NonGuestBalance: (
      { __typename?: 'NonGuestBalance' }
      & Pick<NonGuestBalance, 'TotalLeft'>
    ) }
  ) }
);

export type WalkIngBookingInsertMutationVariables = Exact<{
  RegistrationInput: RegistrationInput;
  ContactInput: ContactInput;
  BookingInput: BookingInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  IdPicture?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  IsTTx?: Maybe<Scalars['Boolean']>;
  TTxReasonID?: Maybe<Scalars['String']>;
}>;


export type WalkIngBookingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'WalkIngBookingInsert'>
);

export type HotelSalesChannelListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type HotelSalesChannelListingQuery = (
  { __typename?: 'Query' }
  & { HotelSalesChannelListing: Array<(
    { __typename?: 'SalesChannelEntity' }
    & Pick<SalesChannelEntity, 'ID' | 'Description'>
  )> }
);

export type AccountMappingChargesListQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type AccountMappingChargesListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AccountMappingChargesList'>
);

export type AccountMappingPaymentListQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type AccountMappingPaymentListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AccountMappingPaymentList'>
);

export type AccountMappingAdvPaymentListQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type AccountMappingAdvPaymentListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AccountMappingAdvPaymentList'>
);

export type AccountMappingRefundListQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type AccountMappingRefundListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AccountMappingRefundList'>
);

export type AccountMappingDepositListQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type AccountMappingDepositListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AccountMappingDepositList'>
);

export type AccountMappingGlInsertMutationVariables = Exact<{
  GuestLedgerAcctInput: GuestLedgerAcctInput;
  GLInput: AccountMappingGlInput;
  HotelID: Scalars['String'];
}>;


export type AccountMappingGlInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AccountMappingGLInsert'>
);

export type CityLedgerChargesListQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type CityLedgerChargesListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CityLedgerChargesList'>
);

export type CityLedgerDebtorListQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type CityLedgerDebtorListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CityLedgerDebtorList'>
);

export type CityLedgerGlInsertMutationVariables = Exact<{
  GLInput: AccountMappingGlInput;
  HotelID: Scalars['String'];
}>;


export type CityLedgerGlInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CityLedgerGLInsert'>
);

export type AccountMappingTaxGlListQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type AccountMappingTaxGlListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AccountMappingTaxGLList'>
);

export type AccountMappingTaxGlInsertMutationVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxGLInput: AccountMappingTaxGlInput;
}>;


export type AccountMappingTaxGlInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AccountMappingTaxGLInsert'>
);

export type CityLedgerTaxGlListQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type CityLedgerTaxGlListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CityLedgerTaxGLList'>
);

export type CityLedgerTaxGlInsertMutationVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  TaxGLInput: AccountMappingTaxGlInput;
}>;


export type CityLedgerTaxGlInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CityLedgerTaxGLInsert'>
);

export type BankAccountListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type BankAccountListingQuery = (
  { __typename?: 'Query' }
  & { BankAccountListing: Array<(
    { __typename?: 'BankAccountEntity' }
    & Pick<BankAccountEntity, 'ID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'IsActive' | 'HotelID' | 'BankName' | 'BankCode' | 'AccountHolderName' | 'AccountNo' | 'SwiftCode' | 'Branch' | 'BankContactNo' | 'IsSecurityCash' | 'IsKeyCardCash' | 'IsCash' | 'BankClearance'>
    & { Address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'country' | 'city' | 'state' | 'postCode'>
    )>, ContactPerson?: Maybe<Array<(
      { __typename?: 'ContactPerson' }
      & Pick<ContactPerson, 'name' | 'designation' | 'contactNo' | 'email'>
    )>> }
  )> }
);

export type BankAccountInsertMutationVariables = Exact<{
  BankAccountInput: BankAccountInput;
}>;


export type BankAccountInsertMutation = (
  { __typename?: 'Mutation' }
  & { BankAccountInsert: (
    { __typename?: 'BankAccountEntity' }
    & Pick<BankAccountEntity, 'ID'>
  ) }
);

export type BankAccountUpdateMutationVariables = Exact<{
  BankAccountInput: BankAccountInput;
}>;


export type BankAccountUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BankAccountUpdate'>
);

export type BankAccountDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type BankAccountDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BankAccountDelete'>
);

export type BankAccountInActiveMutationVariables = Exact<{
  ID: Scalars['String'];
  IsActive: Scalars['Boolean'];
}>;


export type BankAccountInActiveMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BankAccountInActive'>
);

export type GlExportQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
}>;


export type GlExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GLExport'>
);

export type GlPostMutationVariables = Exact<{
  GLDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  IsTransfer?: Maybe<Scalars['Boolean']>;
}>;


export type GlPostMutation = (
  { __typename?: 'Mutation' }
  & { GLPost: (
    { __typename?: 'GLPostResponse' }
    & Pick<GlPostResponse, 'GLPost'>
    & { ExportedGLTransfer?: Maybe<Array<(
      { __typename?: 'ExportedGLTransferResponse' }
      & Pick<ExportedGlTransferResponse, 'AccountID' | 'CompanyName' | 'JournalTypeName' | 'JournalDate' | 'DocNo' | 'Description' | 'AccountName' | 'DepartmentCode' | 'MasterCOACode' | 'Amount' | 'Remark'>
    )>> }
  ) }
);

export type ExportedGlListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type ExportedGlListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ExportedGLListing'>
);

export type ExportedGlDetailsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BatchNo: Scalars['Float'];
}>;


export type ExportedGlDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ExportedGLDetails'>
);

export type ExportedGlTransferDetailsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  BatchNo: Scalars['Float'];
}>;


export type ExportedGlTransferDetailsQuery = (
  { __typename?: 'Query' }
  & { ExportedGLTransferDetails: Array<(
    { __typename?: 'ExportedGLTransferResponse' }
    & Pick<ExportedGlTransferResponse, 'AccountID' | 'CompanyName' | 'JournalTypeName' | 'JournalDate' | 'DocNo' | 'Description' | 'AccountName' | 'DepartmentCode' | 'MasterCOACode' | 'Amount' | 'Remark'>
  )> }
);

export type GlExportDetailsByTrxTypeQueryVariables = Exact<{
  AccountCode?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  BatchNo: Scalars['Float'];
  TrxDate: Scalars['DateTime'];
  TrxDesc: Scalars['String'];
  TrxType: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type GlExportDetailsByTrxTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GLExportDetailsByTrxType'>
);

export type CbExportQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
}>;


export type CbExportQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CBExport'>
);

export type ExportedCbListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type ExportedCbListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ExportedCBListing'>
);

export type ExportedCbDetailsQueryVariables = Exact<{
  HotelID: Scalars['String'];
  BatchNo: Scalars['Float'];
}>;


export type ExportedCbDetailsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ExportedCBDetails'>
);

export type CbExportDetailsByTrxTypeQueryVariables = Exact<{
  TrxDate: Scalars['DateTime'];
  TrxType: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type CbExportDetailsByTrxTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CBExportDetailsByTrxType'>
);

export type CbPostMutationVariables = Exact<{
  GLDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  IsTransfer?: Maybe<Scalars['Boolean']>;
}>;


export type CbPostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CBPost'>
);

export type GlAccountPeriodQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type GlAccountPeriodQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GLAccountPeriod'>
);

export type HotelAccountXListQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type HotelAccountXListQuery = (
  { __typename?: 'Query' }
  & { HotelAccountXList?: Maybe<(
    { __typename?: 'HotelVendorInfoEntity' }
    & Pick<HotelVendorInfoEntity, 'ID' | 'HotelID' | 'VendorName' | 'IsActive'>
  )> }
);

export type GuestProfileListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  Country?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  BirthMonth?: Maybe<Scalars['String']>;
  Age1?: Maybe<Scalars['String']>;
  Age2?: Maybe<Scalars['String']>;
  VisitNo1?: Maybe<Scalars['String']>;
  VisitNo2?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  SearchValue?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
}>;


export type GuestProfileListingQuery = (
  { __typename?: 'Query' }
  & { GuestProfileListing: (
    { __typename?: 'GuestProfileResponse' }
    & Pick<GuestProfileResponse, 'TotalGuestProfile'>
    & { GuestProfile?: Maybe<Array<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'ID' | 'GuestType' | 'IsTTx' | 'TTxReasonID' | 'NewsLetter' | 'VehicleNo' | 'IdPicture' | 'LastYearOfVisit' | 'NoOfVisit'>
      & { ICGallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )>, PassportGallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )>, Booking?: Maybe<Array<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'BookingStatus'>
      )>>, Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName' | 'NRIC' | 'PassportNo' | 'PhoneNo' | 'MobileNo' | 'AccountID' | 'Email' | 'BirthDate' | 'Nationality' | 'Designation'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
        )> }
      )>, GuestHistoryList: Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'GuestID' | 'Signature' | 'RoomID' | 'IsPrincipal'>
        & { Room?: Maybe<(
          { __typename?: 'RoomEntity' }
          & Pick<RoomEntity, 'RoomNo'>
        )> }
      )> }
    )>> }
  ) }
);

export type GuestProfileListingV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  Country?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  BirthMonth?: Maybe<Scalars['String']>;
  Age1?: Maybe<Scalars['String']>;
  Age2?: Maybe<Scalars['String']>;
  VisitNo1?: Maybe<Scalars['String']>;
  VisitNo2?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  FullName?: Maybe<Scalars['String']>;
  RoomNo?: Maybe<Scalars['String']>;
  SpecialRequest?: Maybe<Scalars['String']>;
}>;


export type GuestProfileListingV2Query = (
  { __typename?: 'Query' }
  & { GuestProfileListingV2: Array<(
    { __typename?: 'GuestProfileEntity' }
    & Pick<GuestProfileEntity, 'SpecialRequest' | 'DebtorID' | 'ID' | 'GuestType' | 'IsTTx' | 'TTxReasonID' | 'NewsLetter' | 'VehicleNo' | 'IdPicture' | 'LastYearOfVisit' | 'NoOfVisit'>
    & { ICGallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
    )>, PassportGallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
    )>, Booking?: Maybe<Array<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'BookingStatus'>
    )>>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'FullName' | 'NRIC' | 'PassportNo' | 'PhoneNo' | 'MobileNo' | 'AccountID' | 'Email' | 'BirthDate' | 'Nationality' | 'Designation'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
      )> }
    )>, GuestHistoryList: Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ArrivalDate' | 'DepartureDate' | 'CheckInDate' | 'CheckOutDate' | 'GuestID' | 'Signature' | 'RoomID' | 'IsPrincipal'>
      & { Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )> }
  )> }
);

export type GuestHistoryListingV2QueryVariables = Exact<{
  GuestID: Scalars['String'];
}>;


export type GuestHistoryListingV2Query = (
  { __typename?: 'Query' }
  & Pick<Query, 'GuestHistoryListingV2'>
);

export type GuestHistoryListingQueryVariables = Exact<{
  GuestID: Scalars['String'];
}>;


export type GuestHistoryListingQuery = (
  { __typename?: 'Query' }
  & { GuestHistoryListing: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'CheckInDate' | 'CheckOutDate' | 'ArrivalDate' | 'DepartureDate' | 'RoomRate' | 'DiscountAmount' | 'RegistrationStatus'>
    & { Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'ID' | 'NewsLetter' | 'VehicleNo' | 'IdPicture' | 'GuestType' | 'IsTTx' | 'TTxReasonID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'Nationality' | 'PassportNo' | 'BirthDate' | 'FullName' | 'NRIC' | 'Email' | 'PhoneNo' | 'MobileNo' | 'Designation'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode' | 'AddressType'>
        )> }
      )>, ICGallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ImageURL' | 'BucketFileName'>
      )>, ICBackGallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ImageURL' | 'BucketFileName'>
      )>, GuestBookedRoomList: Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'GuestID' | 'IsPrincipal' | 'RegistrationStatus'>
      )> }
    )>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'BookingNo' | 'DepartureDate' | 'ArrivalDate' | 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'Nationality' | 'PassportNo' | 'BirthDate' | 'FullName' | 'NRIC' | 'Email' | 'PhoneNo' | 'MobileNo'>
        & { Address?: Maybe<(
          { __typename?: 'Address' }
          & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode' | 'AddressType'>
        )> }
      )> }
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code'>
      )> }
    )>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'Code' | 'Description'>
    )>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'Description' | 'Reason' | 'ReferenceNo' | 'TrxDate' | 'TrxAmount'>
    )>>, RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'ReferenceNo' | 'BaseAmount' | 'TrxAmount' | 'TrxDate' | 'ServiceCharge' | 'TaxAmount' | 'TransactionType'>
      & { Transaction?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'Description'>
      )> }
    )>>, TaxLedger?: Maybe<Array<(
      { __typename?: 'TaxLedgerEntity' }
      & Pick<TaxLedgerEntity, 'TaxType' | 'TaxDescription' | 'TaxAmount'>
    )>> }
  )> }
);

export type SelectedGuestListingQueryVariables = Exact<{
  GuestID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type SelectedGuestListingQuery = (
  { __typename?: 'Query' }
  & { SelectedGuestListing: (
    { __typename?: 'GuestProfileEntity' }
    & Pick<GuestProfileEntity, 'ID' | 'GuestType' | 'NewsLetter' | 'VehicleNo' | 'ContactID'>
    & { ICGallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ImageURL' | 'BucketFileName'>
    )>, ICBackGallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ImageURL' | 'BucketFileName'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'MobileNo' | 'Email' | 'Nationality' | 'PassportNo' | 'NRIC' | 'BirthDate' | 'Designation'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postCode' | 'city' | 'state' | 'country'>
      )> }
    )> }
  ) }
);

export type GuestProfileHistoryListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type GuestProfileHistoryListingQuery = (
  { __typename?: 'Query' }
  & { GuestProfileHistoryListing: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'CheckInDate' | 'CheckOutDate' | 'ArrivalDate' | 'DepartureDate' | 'GuestID'>
    & { RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'Code' | 'Description'>
    )> }
  )> }
);

export type GuestUpdateMutationVariables = Exact<{
  ContactInput: ContactInput;
  GuestProfileInput: GuestProfileInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GuestUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestUpdate'>
);

export type HotelListingQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
}>;


export type HotelListingQuery = (
  { __typename?: 'Query' }
  & { HotelListing: Array<(
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'HotelName' | 'HotelAdjustmentIncidentalID' | 'CompanyRegNo' | 'Tax' | 'IsInclusive' | 'IsFloorPlan' | 'ServiceCharge' | 'AccountID' | 'NAProcessTime' | 'Currency' | 'Encoders' | 'CompanyName'>
    & { HotelAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
    )>, LogoImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
    )> }
  )> }
);

export type UserTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type UserTypeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'UserType'>
);

export type ServiceRequestFullListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type ServiceRequestFullListingQuery = (
  { __typename?: 'Query' }
  & { ServiceRequestFullListing: Array<(
    { __typename?: 'ServiceRequestEntity' }
    & Pick<ServiceRequestEntity, 'ID' | 'Title' | 'Description' | 'CreatedDT' | 'EstimatedCompletion' | 'RequestBy' | 'Status' | 'Remarks' | 'DepartmentID' | 'CreatedBy' | 'ModifiedBy'>
    & { Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo'>
      & { Location?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'ID' | 'Description'>
      )> }
    )>, ServiceRequestGallery?: Maybe<Array<(
      { __typename?: 'ServiceRequestGalleryEntity' }
      & Pick<ServiceRequestGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Name'>
    )> }
  )> }
);

export type RoomServicesCountQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type RoomServicesCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RoomServicesCount'>
);

export type DashBoardAllListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type DashBoardAllListingQuery = (
  { __typename?: 'Query' }
  & { DashBoardAllListing: (
    { __typename?: 'DashBoardRespond' }
    & Pick<DashBoardRespond, 'DueIn' | 'TotalDueIn' | 'TotalInHouseRoom' | 'DueOut' | 'TotalDueOut' | 'TotalGuest' | 'TotalRoom' | 'TotalUnAssignedRoomRegistration' | 'RevenueToday' | 'RevenueMonthToDay' | 'CollectionToday' | 'CollectionMonthToDay' | 'CurrentOccupiedRoom' | 'CurrentInHouse' | 'CurrentComplimentary' | 'CurrentDayUse' | 'CurrentOthers' | 'CurrentOccupancyRate' | 'CurrentArr' | 'CurrentRoomRevenue' | 'ProjectOccupiedRoom' | 'ProjectInHouse' | 'ProjectComplimentary' | 'ProjectDayUse' | 'ProjectOthers' | 'ProjectOccupancyRate' | 'ProjectArr' | 'ProjectRoomRevenue'>
  ) }
);

export type GetMenuOptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMenuOptionQuery = (
  { __typename?: 'Query' }
  & { GetMenuOption: (
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'MenuOption' | 'ID'>
  ) }
);

export type CheckNaProcessLogQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type CheckNaProcessLogQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CheckNAProcessLog'>
);

export type HousekeepingListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
}>;


export type HousekeepingListingQuery = (
  { __typename?: 'Query' }
  & { HousekeepingListing: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'RoomNo' | 'RoomStatusCode'>
    & { RoomStatus?: Maybe<(
      { __typename?: 'RoomStatusEntity' }
      & Pick<RoomStatusEntity, 'CleanOrDirty' | 'Code' | 'Description' | 'RGBColor' | 'ID'>
    )>, BlockRoomLog?: Maybe<Array<(
      { __typename?: 'BlockRoomLogEntity' }
      & Pick<BlockRoomLogEntity, 'ID' | 'BlockDate' | 'ReleaseDate' | 'Reason' | 'Remarks' | 'CreatedDT' | 'ModifiedDT' | 'IsRelease' | 'GalleryID' | 'ReleaseRoomStatus'>
      & { BlockRoomLogGallery?: Maybe<Array<(
        { __typename?: 'BlockRoomLogGalleryEntity' }
        & Pick<BlockRoomLogGalleryEntity, 'ID' | 'GalleryID' | 'BlockRoomLogID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
        )> }
      )>>, BlockStatus?: Maybe<(
        { __typename?: 'RoomStatusEntity' }
        & Pick<RoomStatusEntity, 'ID' | 'Code'>
      )>, Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID'>
      )> }
    )>>, HouseKeepingLog?: Maybe<Array<(
      { __typename?: 'HouseKeepingLogEntity' }
      & Pick<HouseKeepingLogEntity, 'ID' | 'Status' | 'CleanedBy' | 'HotelDate'>
    )>>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code'>
    )>, Registrations?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'CheckInDate' | 'CheckOutDate' | 'RegistrationStatus' | 'IsPrincipal'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'DepartureDate' | 'ArrivalDate'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>> }
  )> }
);

export type SvcReqRoomListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  LocationID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
}>;


export type SvcReqRoomListingQuery = (
  { __typename?: 'Query' }
  & { HousekeepingListing: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'RoomNo'>
    & { RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code'>
    )>, Registrations?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'CheckInDate' | 'CheckOutDate' | 'RegistrationStatus' | 'IsPrincipal'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'DepartureDate' | 'ArrivalDate'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>> }
  )> }
);

export type HkMasterListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
  LocationID?: Maybe<Scalars['String']>;
  RoomStatusID?: Maybe<Scalars['String']>;
  IsBlocked?: Maybe<Scalars['Boolean']>;
}>;


export type HkMasterListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'HKMasterListing'>
);

export type HkLocationMasterListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
}>;


export type HkLocationMasterListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'HKLocationMasterListing'>
);

export type HousekeepingDetailQueryVariables = Exact<{
  HotelID: Scalars['String'];
  ID: Scalars['String'];
}>;


export type HousekeepingDetailQuery = (
  { __typename?: 'Query' }
  & { HousekeepingDetail: (
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'RoomNo'>
    & { RoomStatus?: Maybe<(
      { __typename?: 'RoomStatusEntity' }
      & Pick<RoomStatusEntity, 'ID' | 'CleanOrDirty' | 'Code' | 'Description' | 'RGBColor'>
    )>, BlockRoomLog?: Maybe<Array<(
      { __typename?: 'BlockRoomLogEntity' }
      & Pick<BlockRoomLogEntity, 'ID' | 'BlockDate' | 'ReleaseDate' | 'Reason' | 'Remarks' | 'CreatedDT' | 'ModifiedDT' | 'IsRelease' | 'GalleryID' | 'ReleaseRoomStatus'>
      & { BlockRoomLogGallery?: Maybe<Array<(
        { __typename?: 'BlockRoomLogGalleryEntity' }
        & Pick<BlockRoomLogGalleryEntity, 'ID' | 'GalleryID' | 'BlockRoomLogID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
        )> }
      )>>, BlockStatus?: Maybe<(
        { __typename?: 'RoomStatusEntity' }
        & Pick<RoomStatusEntity, 'ID' | 'Code'>
      )>, Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL'>
      )> }
    )>>, HouseKeepingLog?: Maybe<Array<(
      { __typename?: 'HouseKeepingLogEntity' }
      & Pick<HouseKeepingLogEntity, 'ID' | 'Status' | 'CleanedBy' | 'HotelDate'>
    )>>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code'>
    )>, Registrations?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'CheckInDate' | 'CheckOutDate' | 'RegistrationStatus' | 'IsPrincipal'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'DepartureDate' | 'ArrivalDate'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>> }
  ) }
);

export type BlockRoomLogInsertMutationVariables = Exact<{
  BlockRoomLogInput: BlockRoomLogInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type BlockRoomLogInsertMutation = (
  { __typename?: 'Mutation' }
  & { BlockRoomLogInsert: (
    { __typename?: 'BlockRoomLogEntity' }
    & Pick<BlockRoomLogEntity, 'ID' | 'HotelID' | 'RoomID' | 'Reason' | 'BlockDate' | 'ReleaseDate' | 'BlockStatusID' | 'Remarks'>
  ) }
);

export type BlockRoomLogInsertV2MutationVariables = Exact<{
  BlockRoomLogInput: Array<BlockRoomLogInput> | BlockRoomLogInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type BlockRoomLogInsertV2Mutation = (
  { __typename?: 'Mutation' }
  & { BlockRoomLogInsertV2: Array<(
    { __typename?: 'BlockRoomLogEntity' }
    & Pick<BlockRoomLogEntity, 'ID' | 'HotelID' | 'RoomID' | 'Reason' | 'BlockDate' | 'ReleaseDate' | 'BlockStatusID' | 'Remarks'>
  )> }
);

export type IsBlockRoomAvailableQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomID: Array<Scalars['String']> | Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  BlockRoomID?: Maybe<Scalars['String']>;
}>;


export type IsBlockRoomAvailableQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsBlockRoomAvailable'>
);

export type HouseKeepingLogInsertMutationVariables = Exact<{
  HouseKeepingLogInput: HouseKeepingLogInput;
  RoomIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type HouseKeepingLogInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HouseKeepingLogInsert'>
);

export type ReleaseRoomLogUpdateV2MutationVariables = Exact<{
  BlockRoomLogInput: BlockRoomLogInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ReleaseRoomLogUpdateV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReleaseRoomLogUpdateV2'>
);

export type BlockRoomLogUpdateMutationVariables = Exact<{
  BlockRoomLogInput: BlockRoomLogInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type BlockRoomLogUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BlockRoomLogUpdate'>
);

export type HousekeepingServiceRequestListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomID?: Maybe<Scalars['String']>;
}>;


export type HousekeepingServiceRequestListingQuery = (
  { __typename?: 'Query' }
  & { HousekeepingServiceRequestListing: Array<(
    { __typename?: 'ServiceRequestEntity' }
    & Pick<ServiceRequestEntity, 'ID' | 'Title' | 'Description' | 'EstimatedCompletion' | 'RequestBy' | 'Status' | 'Remarks' | 'DepartmentID' | 'ClosedDate' | 'CreatedBy' | 'ModifiedBy' | 'CreatedDT' | 'RoomID'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'IsPrincipal'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>, ServiceRequestGallery?: Maybe<Array<(
      { __typename?: 'ServiceRequestGalleryEntity' }
      & Pick<ServiceRequestGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Name'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo'>
      & { Registrations?: Maybe<Array<(
        { __typename?: 'RegistrationEntity' }
        & Pick<RegistrationEntity, 'ID' | 'BookingID'>
        & { Guest?: Maybe<(
          { __typename?: 'GuestProfileEntity' }
          & Pick<GuestProfileEntity, 'ID'>
          & { Contact?: Maybe<(
            { __typename?: 'ContactEntity' }
            & Pick<ContactEntity, 'ID' | 'FullName'>
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type HousekeepingServiceRequestUpdateMutationVariables = Exact<{
  ServiceRequestInput: ServiceRequestInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type HousekeepingServiceRequestUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HousekeepingServiceRequestUpdate'>
);

export type HousekeepingServiceRequestInsertMutationVariables = Exact<{
  ServiceRequestInput: ServiceRequestInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type HousekeepingServiceRequestInsertMutation = (
  { __typename?: 'Mutation' }
  & { HousekeepingServiceRequestInsert: (
    { __typename?: 'ServiceRequestEntity' }
    & Pick<ServiceRequestEntity, 'ID'>
  ) }
);

export type BillLedgerListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type BillLedgerListingQuery = (
  { __typename?: 'Query' }
  & { BillLedgerListing: Array<(
    { __typename?: 'BillLedgerEntity' }
    & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'Qty' | 'UnitPrice' | 'TrxAmount' | 'Reason' | 'RegistrationID' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'DiscountAmount' | 'ReferenceNo' | 'Description' | 'ReversedBillLedgerID' | 'CreatedBy' | 'ModifiedBy' | 'CreatedDT' | 'ModifiedDT'>
    & { IncidentalCharge?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'Description'>
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal'>
      & { Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'ID' | 'RoomNo'>
        & { RoomType?: Maybe<(
          { __typename?: 'RoomTypeEntity' }
          & Pick<RoomTypeEntity, 'Code'>
        )> }
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )>, TaxBillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerTaxEntity' }
      & Pick<BillLedgerTaxEntity, 'ID' | 'TaxID' | 'TaxRate' | 'TaxAmount' | 'BillLedgerID' | 'TaxSchemeID'>
      & { TaxInfo?: Maybe<(
        { __typename?: 'taxEntity' }
        & Pick<TaxEntity, 'code'>
      )> }
    )>> }
  )> }
);

export type HousekeepingIncidentalChargeListingQueryVariables = Exact<{
  DepartmentID?: Maybe<Scalars['String']>;
}>;


export type HousekeepingIncidentalChargeListingQuery = (
  { __typename?: 'Query' }
  & { HousekeepingIncidentalChargeListing: Array<(
    { __typename?: 'IncidentalChargeEntity' }
    & Pick<IncidentalChargeEntity, 'ID' | 'IsActive' | 'DepartmentID' | 'Description' | 'Amount' | 'Tax' | 'ServiceCharge' | 'IsRoomRevenue' | 'GalleryID'>
  )> }
);

export type HousekeepingBillLedgerUpdateMutationVariables = Exact<{
  BillLedgerInput: BillLedgerInput;
}>;


export type HousekeepingBillLedgerUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HousekeepingBillLedgerUpdate'>
);

export type TodayBookingListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type TodayBookingListingQuery = (
  { __typename?: 'Query' }
  & { TodayBookingListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'ArrivalDate'>
    & { Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'RoomID'>
    )>> }
  )> }
);

export type HousekeepingRoomStatusListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type HousekeepingRoomStatusListingQuery = (
  { __typename?: 'Query' }
  & { HousekeepingRoomStatusListing: Array<(
    { __typename?: 'RoomStatusEntity' }
    & Pick<RoomStatusEntity, 'ID' | 'Code' | 'Description' | 'RGBColor'>
  )> }
);

export type BlockRoomLogListingV2QueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type BlockRoomLogListingV2Query = (
  { __typename?: 'Query' }
  & { BlockRoomLogListingV2: Array<(
    { __typename?: 'BlockRoomLogEntity' }
    & Pick<BlockRoomLogEntity, 'ID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedBy' | 'ModifiedDT' | 'HotelID' | 'RoomID' | 'BlockDate' | 'ReleaseDate' | 'BlockStatusID' | 'Reason' | 'Remarks' | 'IsRelease' | 'ReleaseDT'>
    & { BlockStatus?: Maybe<(
      { __typename?: 'RoomStatusEntity' }
      & Pick<RoomStatusEntity, 'Code'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo'>
    )>, BlockRoomLogGallery?: Maybe<Array<(
      { __typename?: 'BlockRoomLogGalleryEntity' }
      & Pick<BlockRoomLogGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL'>
      )> }
    )>> }
  )> }
);

export type PackageRedemptionListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type PackageRedemptionListingQuery = (
  { __typename?: 'Query' }
  & { PackageRedemptionListing: Array<(
    { __typename?: 'PackageRedemptionListOutput' }
    & Pick<PackageRedemptionListOutput, 'IncidentalChargeID' | 'DepartmentID' | 'BillScheduleID' | 'Description' | 'ComputationRule' | 'Amount' | 'Total' | 'Redeemed' | 'Balance'>
  )> }
);

export type PackageRedemptionByItemQueryVariables = Exact<{
  HotelID: Scalars['String'];
  IncidentalChargeID: Scalars['String'];
  ComputationRule: Scalars['String'];
}>;


export type PackageRedemptionByItemQuery = (
  { __typename?: 'Query' }
  & { PackageRedemptionByItem: Array<(
    { __typename?: 'PackageRedemptionListOutput' }
    & Pick<PackageRedemptionListOutput, 'IncidentalChargeID' | 'Description' | 'ComputationRule' | 'Amount' | 'Total' | 'Redeemed' | 'Balance' | 'RegistrationID' | 'BookingID' | 'BillScheduleID' | 'RoomNo' | 'GuestNames' | 'DepartmentID' | 'DebtorName' | 'MaxPackage'>
    & { GuestPackageRedeem?: Maybe<Array<(
      { __typename?: 'GuestPackageRedeem' }
      & Pick<GuestPackageRedeem, 'GuestName' | 'IsRedeem' | 'IsBillLedger' | 'BillLedgerID' | 'Pax'>
    )>> }
  )> }
);

export type PackageRedemptionInsertMutationVariables = Exact<{
  PackageRedemptionInput: PackageRedemptionInput;
}>;


export type PackageRedemptionInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PackageRedemptionInsert'>
);

export type Q3DocumentHeaderQueryVariables = Exact<{
  DocumentType: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type Q3DocumentHeaderQuery = (
  { __typename?: 'Query' }
  & { Q3DocumentHeader: Array<(
    { __typename?: 'Q3DocumentHeaderEntity' }
    & Pick<Q3DocumentHeaderEntity, 'ID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DocumentType'>
    & { Q3DocumentDetail?: Maybe<Array<(
      { __typename?: 'Q3DocumentDetailEntity' }
      & Pick<Q3DocumentDetailEntity, 'ID' | 'DownloadDate' | 'DownloadNo' | 'BatchNo' | 'CreatedDT'>
    )>> }
  )> }
);

export type Q3DocumentDetailByHeaderQueryVariables = Exact<{
  HeaderID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type Q3DocumentDetailByHeaderQuery = (
  { __typename?: 'Query' }
  & { Q3DocumentDetailByHeader: Array<(
    { __typename?: 'Q3DocumentDetailEntity' }
    & Pick<Q3DocumentDetailEntity, 'ID' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DownloadDate' | 'HeaderID'>
    & { Q3DocumentHeader?: Maybe<(
      { __typename?: 'Q3DocumentHeaderEntity' }
      & Pick<Q3DocumentHeaderEntity, 'ID'>
    )> }
  )> }
);

export type Q3FRevenueQueryVariables = Exact<{
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  DownloadDate: Scalars['DateTime'];
  DownloadMode: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
}>;


export type Q3FRevenueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'Q3FRevenue'>
);

export type Q3FSegmentQueryVariables = Exact<{
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
}>;


export type Q3FSegmentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'Q3FSegment'>
);

export type Q3FStatisticQueryVariables = Exact<{
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
}>;


export type Q3FStatisticQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'Q3FStatistic'>
);

export type Q3ListingsQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type Q3ListingsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'Q3Listings'>
);

export type IsQ3FInterfaceQueryVariables = Exact<{
  HotelID: Scalars['String'];
  UserID: Scalars['String'];
}>;


export type IsQ3FInterfaceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsQ3FInterface'>
);

export type Q3RevenueMappingListQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type Q3RevenueMappingListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'Q3MappingChargesList' | 'Q3MappingPaymentList' | 'Q3MappingAdvPaymentList' | 'Q3MappingRefundList' | 'Q3MappingDepositList' | 'Q3MappingTaxList' | 'Q3MappingDebtorList'>
);

export type Q3DocumentInsertMutationVariables = Exact<{
  Q3DocumentDetailInput: Array<Q3DocumentDetailInput> | Q3DocumentDetailInput;
  Q3DocumentHeaderInput: Q3DocumentHeaderInput;
}>;


export type Q3DocumentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'Q3DocumentInsert'>
);

export type RevenueMappingInsertMutationVariables = Exact<{
  GuestLedgerAcctInput: RevenueGuestLedgerAcctInput;
  RevenueInput: RevenueMappingInput;
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type RevenueMappingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RevenueMappingInsert'>
);

export type SegmentMappingInsertMutationVariables = Exact<{
  HotelID: Scalars['String'];
  SegmentInput: Array<SegmentInput> | SegmentInput;
}>;


export type SegmentMappingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SegmentMappingInsert'>
);

export type StatisticMappingInsertMutationVariables = Exact<{
  HotelID: Scalars['String'];
  Q3Statistic: Array<Q3StatisticInput> | Q3StatisticInput;
}>;


export type StatisticMappingInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'StatisticMappingInsert'>
);

export type RoomServiceListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type RoomServiceListingQuery = (
  { __typename?: 'Query' }
  & { RoomServiceListing: Array<(
    { __typename?: 'BillLedgerEntity' }
    & Pick<BillLedgerEntity, 'ID' | 'FolioID' | 'ReversedBillLedgerID' | 'TrxDate' | 'Qty' | 'UnitPrice' | 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'ServiceCharge' | 'Reason' | 'IsDelivered' | 'ModifiedDT' | 'CreatedDT' | 'Signature' | 'IncidentalChargeID' | 'RegistrationID'>
    & { BillLedgerSignature?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ImageURL' | 'BucketFileName'>
    )>, IncidentalCharge?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'Description' | 'IsOutlet'>
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomTypeID' | 'RoomID' | 'GuestID'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName'>
        )> }
      )>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'Description'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )> }
  )> }
);

export type DeliveredRoomServiceListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
  RegistrationID?: Maybe<Scalars['String']>;
}>;


export type DeliveredRoomServiceListingQuery = (
  { __typename?: 'Query' }
  & { DeliveredRoomServiceListing: Array<(
    { __typename?: 'BillLedgerEntity' }
    & Pick<BillLedgerEntity, 'ID' | 'FolioID' | 'ReversedBillLedgerID' | 'TrxDate' | 'Qty' | 'UnitPrice' | 'BaseAmount' | 'TrxAmount' | 'TaxAmount' | 'ServiceCharge' | 'Reason' | 'IsDelivered' | 'ModifiedDT' | 'CreatedDT' | 'Signature' | 'IncidentalChargeID' | 'RegistrationID'>
    & { BillLedgerSignature?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ImageURL' | 'BucketFileName'>
    )>, IncidentalCharge?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'Description' | 'IsOutlet'>
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomTypeID' | 'RoomID' | 'GuestID'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName'>
        )> }
      )>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'Description'>
      )>, Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )> }
    )> }
  )> }
);

export type RoomServiceRegistrationListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
}>;


export type RoomServiceRegistrationListingQuery = (
  { __typename?: 'Query' }
  & { RoomServiceRegistrationListing: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID'>
    & { Payment?: Maybe<Array<(
      { __typename?: 'PaymentEntity' }
      & Pick<PaymentEntity, 'ID' | 'PaymentStatus'>
    )>>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'IsDelivered' | 'CreatedDT' | 'ModifiedDT' | 'Reason' | 'Description' | 'PaymentID' | 'TrxAmount' | 'ReversedBillLedgerID'>
      & { IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'IsOutlet'>
      )> }
    )>>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'Code'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName'>
      )> }
    )> }
  )> }
);

export type DeliveredRoomServiceRegistrationListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  TrxDate: Scalars['DateTime'];
}>;


export type DeliveredRoomServiceRegistrationListingQuery = (
  { __typename?: 'Query' }
  & { DeliveredRoomServiceRegistrationListing: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID'>
    & { BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'TrxDate' | 'IsDelivered' | 'CreatedDT' | 'ModifiedDT' | 'TrxAmount' | 'ReversedBillLedgerID'>
      & { IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'IsOutlet'>
      )> }
    )>>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'Code'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & Pick<GuestProfileEntity, 'ID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName'>
      )> }
    )> }
  )> }
);

export type PaymentBillLedgerListingQueryVariables = Exact<{
  FolioID: Scalars['String'];
}>;


export type PaymentBillLedgerListingQuery = (
  { __typename?: 'Query' }
  & { PaymentBillLedgerListing: Array<(
    { __typename?: 'BillLedgerEntity' }
    & Pick<BillLedgerEntity, 'ID' | 'IncidentalChargeID' | 'Qty' | 'UnitPrice' | 'ReferenceNo'>
  )> }
);

export type PaymentRegistrationListingQueryVariables = Exact<{
  RegistrationID: Scalars['String'];
}>;


export type PaymentRegistrationListingQuery = (
  { __typename?: 'Query' }
  & { PaymentRegistrationListing: (
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID'>
    & { Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
    )> }
  ) }
);

export type RoomNumberForFoodListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type RoomNumberForFoodListingQuery = (
  { __typename?: 'Query' }
  & { RoomNumberForFoodListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'DebtorAccount'>
    & { SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'IsActive' | 'Description'>
      & { Department?: Maybe<(
        { __typename?: 'DepartmentEntity' }
        & Pick<DepartmentEntity, 'ID'>
        & { IncidentalCharge?: Maybe<Array<(
          { __typename?: 'IncidentalChargeEntity' }
          & Pick<IncidentalChargeEntity, 'ID'>
        )>> }
      )> }
    )>, Registration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'IsPrincipal' | 'RegistrationStatus' | 'RoomTypeID' | 'CheckOutDate' | 'CheckInDate' | 'RoomID'>
      & { Room?: Maybe<(
        { __typename?: 'RoomEntity' }
        & Pick<RoomEntity, 'RoomNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName'>
        )> }
      )>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetHotelLatestTaxQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type GetHotelLatestTaxQuery = (
  { __typename?: 'Query' }
  & { GetLevyTax: (
    { __typename?: 'currentTaxOutput' }
    & Pick<CurrentTaxOutput, 'TaxScheme' | 'TaxCode' | 'EffectiveDate' | 'IsFixAmount' | 'Value' | 'IsTaxable'>
  ), GetPlatformTax: (
    { __typename?: 'currentTaxOutput' }
    & Pick<CurrentTaxOutput, 'TaxScheme' | 'TaxCode' | 'EffectiveDate' | 'IsFixAmount' | 'Value' | 'IsTaxable'>
  ), GetCurrentGovTax: (
    { __typename?: 'currentTaxOutput' }
    & Pick<CurrentTaxOutput, 'TaxScheme' | 'TaxCode' | 'EffectiveDate' | 'IsFixAmount' | 'Value' | 'IsTaxable'>
  ), GetCurrentTourismTax: (
    { __typename?: 'currentTaxOutput' }
    & Pick<CurrentTaxOutput, 'TaxScheme' | 'TaxCode' | 'EffectiveDate' | 'IsFixAmount' | 'Value' | 'IsTaxable'>
  ), GetServiceChargeTax: (
    { __typename?: 'currentTaxOutput' }
    & Pick<CurrentTaxOutput, 'TaxScheme' | 'TaxCode' | 'EffectiveDate' | 'IsFixAmount' | 'Value' | 'IsTaxable'>
  ) }
);

export type PaymentRoomServiceDetailQueryVariables = Exact<{
  OrderID: Scalars['String'];
  RegistrationID: Scalars['String'];
}>;


export type PaymentRoomServiceDetailQuery = (
  { __typename?: 'Query' }
  & { PaymentRoomServiceDetail: (
    { __typename?: 'PaymentEntity' }
    & Pick<PaymentEntity, 'ID' | 'FolioID' | 'RegistrationID' | 'HotelID' | 'ReceiptNo' | 'PaymentDate' | 'PaySessionNo' | 'CheckOutID' | 'CardNo' | 'Amount'>
  ) }
);

export type ComputeTaxArrByHotelQueryVariables = Exact<{
  TaxInput: Array<TaxInput> | TaxInput;
}>;


export type ComputeTaxArrByHotelQuery = (
  { __typename?: 'Query' }
  & { ComputeTaxArrByHotel: Array<(
    { __typename?: 'TaxOutput' }
    & Pick<TaxOutput, 'DiscountAmount' | 'BaseAmount' | 'TaxAmount' | 'ServiceCharge' | 'TotalAmount'>
    & { HotelTaxOutput?: Maybe<Array<(
      { __typename?: 'HotelTaxOutput' }
      & Pick<HotelTaxOutput, 'TaxCode' | 'TaxRate' | 'Value' | 'TaxID' | 'SeqNo' | 'TaxScheme'>
    )>> }
  )> }
);

export type BillLedgerInsertMutationVariables = Exact<{
  BillLedgerInput: Array<BillLedgerInput> | BillLedgerInput;
  IsNonGuest?: Maybe<Scalars['Boolean']>;
  IsBookingIncCharges?: Maybe<Scalars['Boolean']>;
}>;


export type BillLedgerInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BillLedgerInsert'>
);

export type BillLedgerUpdateMutationVariables = Exact<{
  BillLedgerInput: Array<BillLedgerInput> | BillLedgerInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type BillLedgerUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BillLedgerUpdate'>
);

export type BillLedgerCancelMutationVariables = Exact<{
  BillLedgerInput: Array<BillLedgerInput> | BillLedgerInput;
}>;


export type BillLedgerCancelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'BillLedgerCancel'>
);

export type FolioInsertMutationVariables = Exact<{
  BillLedgerInput: Array<BillLedgerInput> | BillLedgerInput;
  PaymentInput: PaymentInput;
  RefFolioID?: Maybe<Scalars['String']>;
}>;


export type FolioInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FolioInsert'>
);

export type OnlinePaymentRoomServicesMutationVariables = Exact<{
  BillLedgerInput: Array<BillLedgerInput> | BillLedgerInput;
  PaymentInput: PaymentInput;
}>;


export type OnlinePaymentRoomServicesMutation = (
  { __typename?: 'Mutation' }
  & { OnlinePaymentRoomServices: (
    { __typename?: 'OnlineItemEntity' }
    & Pick<OnlineItemEntity, 'ReceiptNo' | 'FolioID'>
  ) }
);

export type RoomServicePaymentUpdateMutationVariables = Exact<{
  HotelID: Scalars['String'];
  CheckOutID?: Maybe<Scalars['String']>;
  OrderID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  FolioID?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  CardNo?: Maybe<Scalars['String']>;
  PaymentType?: Maybe<Scalars['String']>;
}>;


export type RoomServicePaymentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RoomServicePaymentUpdate'>
);

export type ServiceRequestListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DepartmentID: Scalars['String'];
}>;


export type ServiceRequestListingQuery = (
  { __typename?: 'Query' }
  & { ServiceRequestListing: Array<(
    { __typename?: 'ServiceRequestEntity' }
    & Pick<ServiceRequestEntity, 'ID' | 'Title' | 'Description' | 'EstimatedCompletion' | 'RequestBy' | 'Status' | 'Remarks' | 'DepartmentID' | 'CreatedBy' | 'ModifiedBy'>
    & { Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo'>
      & { Location?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'ID' | 'Description'>
      )> }
    )>, ServiceRequestGallery?: Maybe<Array<(
      { __typename?: 'ServiceRequestGalleryEntity' }
      & Pick<ServiceRequestGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Name'>
    )> }
  )> }
);

export type SrDepartmentListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type SrDepartmentListingQuery = (
  { __typename?: 'Query' }
  & { DepartmentListing: Array<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'Code' | 'Name' | 'AllowReason' | 'AllowSalesChannel' | 'AllowIncidental' | 'AllowGuestApp'>
    & { ServiceRequest?: Maybe<Array<(
      { __typename?: 'ServiceRequestEntity' }
      & Pick<ServiceRequestEntity, 'ID' | 'Status'>
    )>> }
  )> }
);

export type SrLocationListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type SrLocationListingQuery = (
  { __typename?: 'Query' }
  & { SrLocationListing: Array<(
    { __typename?: 'LocationEntity' }
    & Pick<LocationEntity, 'ID' | 'Code' | 'Description' | 'IsActive'>
  )> }
);

export type ServiceRequestv2ListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DepartmentID?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
}>;


export type ServiceRequestv2ListingQuery = (
  { __typename?: 'Query' }
  & { ServiceRequestv2Listing: Array<(
    { __typename?: 'ServiceRequestEntity' }
    & Pick<ServiceRequestEntity, 'ID' | 'Title' | 'Description' | 'EstimatedCompletion' | 'RequestBy' | 'Status' | 'Remarks' | 'CreatedDT' | 'DepartmentID' | 'ClosedDate' | 'CreatedBy' | 'ModifiedBy'>
    & { Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo'>
      & { Location?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'ID' | 'Description'>
      )> }
    )>, Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'GuestType'>
      )> }
    )>, ServiceRequestGallery?: Maybe<Array<(
      { __typename?: 'ServiceRequestGalleryEntity' }
      & Pick<ServiceRequestGalleryEntity, 'ID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
      )> }
    )>>, Department?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Name'>
    )> }
  )> }
);

export type RoomAllocationLocationListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type RoomAllocationLocationListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RoomAllocationLocationListing'>
);

export type ChooseRoomAllocationDetailQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  EffectiveDate?: Maybe<Scalars['DateTime']>;
}>;


export type ChooseRoomAllocationDetailQuery = (
  { __typename?: 'Query' }
  & { ChooseRoomAllocationDetail: Array<(
    { __typename?: 'ChooseRoomAllocationEntity' }
    & Pick<ChooseRoomAllocationEntity, 'ID' | 'EffectiveDate'>
    & { Location?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'ID' | 'Code' | 'Description'>
    )>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description'>
    )>, Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo'>
    )> }
  )> }
);

export type ChooseRoomAllocationListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  DateSearch?: Maybe<Scalars['DateTime']>;
}>;


export type ChooseRoomAllocationListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ChooseRoomAllocationListing'>
);

export type IsChargeableQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type IsChargeableQuery = (
  { __typename?: 'Query' }
  & { IsChargeable: (
    { __typename?: 'GuestAppFeatureEntity' }
    & Pick<GuestAppFeatureEntity, 'ID' | 'HotelID' | 'Chargeable'>
    & { Incidental?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'Amount' | 'Description'>
    )> }
  ) }
);

export type ChooseRoomAllocationInsertMutationVariables = Exact<{
  RoomAllocationInput: ChooseRoomAllocationInput;
}>;


export type ChooseRoomAllocationInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ChooseRoomAllocationInsert'>
);

export type ChooseRoomAllocationUpdateMutationVariables = Exact<{
  RoomAllocationInput: ChooseRoomAllocationInput;
  OldEffectiveDate: Scalars['DateTime'];
}>;


export type ChooseRoomAllocationUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ChooseRoomAllocationUpdate'>
);

export type ChooseRoomAllocationDeleteMutationVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
}>;


export type ChooseRoomAllocationDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ChooseRoomAllocationDelete'>
);

export type DebtorTypeListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type DebtorTypeListingQuery = (
  { __typename?: 'Query' }
  & { DebtorTypeListing: Array<(
    { __typename?: 'DebtorTypeEntity' }
    & Pick<DebtorTypeEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'DebtorType' | 'Description' | 'ControlAccount' | 'DepositAccount' | 'AdvanceAccount' | 'AccrualAccount' | 'RevenueAccount'>
  )> }
);

export type DebtorTypeInsertMutationVariables = Exact<{
  input: DebtorTypeInput;
}>;


export type DebtorTypeInsertMutation = (
  { __typename?: 'Mutation' }
  & { DebtorTypeInsert: (
    { __typename?: 'DebtorTypeEntity' }
    & Pick<DebtorTypeEntity, 'ID'>
  ) }
);

export type DebtorTypeUpdateMutationVariables = Exact<{
  input: DebtorTypeInput;
  ID: Scalars['String'];
}>;


export type DebtorTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebtorTypeUpdate'>
);

export type DebtorTypeDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DebtorTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DebtorTypeDelete'>
);

export type DebtorTypeInUseQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
}>;


export type DebtorTypeInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DebtorTypeInUse'>
);

export type DepartmentInsertMutationVariables = Exact<{
  DepartmentInput: DepartmentInput;
}>;


export type DepartmentInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DepartmentInsert'>
);

export type DepartmentUpdateMutationVariables = Exact<{
  DepartmentInput: DepartmentInput;
}>;


export type DepartmentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DepartmentUpdate'>
);

export type IncidentalChargeInUseListingQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type IncidentalChargeInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IncidentalChargeInUseListing'>
);

export type IncidentalChargeInRateElementQueryVariables = Exact<{
  HotelID: Scalars['String'];
  ID: Scalars['String'];
}>;


export type IncidentalChargeInRateElementQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IncidentalChargeInRateElement'>
);

export type RateElementInactiveCheckQueryVariables = Exact<{
  HotelID: Scalars['String'];
  ID: Scalars['String'];
  Action: Scalars['String'];
}>;


export type RateElementInactiveCheckQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RateElementInactiveCheck'>
);

export type DeparmentCheckingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DepartmentID: Scalars['String'];
}>;


export type DeparmentCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'DeparmentChecking'>
);

export type DepartmentDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DepartmentDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DepartmentDelete'>
);

export type IncidentalChargesUpdateMutationVariables = Exact<{
  ID: Scalars['String'];
  status: Scalars['Boolean'];
}>;


export type IncidentalChargesUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'IncidentalChargesUpdate'>
);

export type EDocTemplateListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type EDocTemplateListingQuery = (
  { __typename?: 'Query' }
  & { EDocTemplateListing: Array<(
    { __typename?: 'EDocTemplateEntity' }
    & Pick<EDocTemplateEntity, 'ID' | 'HotelID' | 'AccountID' | 'TemplateBody' | 'DocType' | 'CreatedBy' | 'CreatedDT' | 'ModifiedBy' | 'ModifiedDT'>
  )> }
);

export type ConfirmationLetterListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type ConfirmationLetterListingQuery = (
  { __typename?: 'Query' }
  & { ConfirmationLetterListing: Array<(
    { __typename?: 'EDocTemplateEntity' }
    & Pick<EDocTemplateEntity, 'ID' | 'IsDefault' | 'TemplateTitle' | 'HotelID' | 'AccountID' | 'TemplateBody' | 'DocType' | 'CreatedBy' | 'CreatedDT' | 'ModifiedBy' | 'ModifiedDT'>
  )> }
);

export type EDocParamsListingQueryVariables = Exact<{
  DocType: Scalars['String'];
}>;


export type EDocParamsListingQuery = (
  { __typename?: 'Query' }
  & { EDocParamsListing: Array<(
    { __typename?: 'EDocParamsEntity' }
    & Pick<EDocParamsEntity, 'ID' | 'DocType' | 'ColName'>
  )> }
);

export type EDocInsertMutationVariables = Exact<{
  EDocTemplateInput: EDocTemplateInput;
}>;


export type EDocInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EDocInsert'>
);

export type EDocTemplateUpdateMutationVariables = Exact<{
  EDocTemplateInput: EDocTemplateInput;
}>;


export type EDocTemplateUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'EDocTemplateUpdate'>
);

export type ConfirmationTemplateDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type ConfirmationTemplateDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ConfirmationTemplateDelete'>
);

export type GuestappsettingDetailQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type GuestappsettingDetailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GuestappsettingDetail'>
);

export type GuestAppSetupUpdateMutationVariables = Exact<{
  GuestAppSettingInput: GuestAppSettingInput;
}>;


export type GuestAppSetupUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'GuestAppSetupUpdate'>
);

export type HotelDetailsQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  OnlineBookingURL?: Maybe<Scalars['String']>;
}>;


export type HotelDetailsQuery = (
  { __typename?: 'Query' }
  & { HotelDetails: (
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID' | 'IsActive' | 'CreatedBy' | 'ModifiedBy' | 'EmailBlastDays' | 'Currency' | 'AccountID' | 'CompanyID' | 'MSICCode' | 'MSICDesc' | 'EInvEffectiveDate' | 'EInvoiceSCClassification' | 'EInvoiceRCClassification' | 'EInvoiceAdvPaymentClassification' | 'EInvoiceDepositForfeitClassification' | 'EInvoiceStateTaxClassification' | 'StateFund' | 'HotelAdjustmentIncidentalID' | 'SSTRegNo' | 'TTxRegNo' | 'CompanyName' | 'CompanyRegNo' | 'Logo' | 'HotelType' | 'HotelName' | 'HotelCode' | 'ContactID' | 'CheckInTime' | 'CheckOutTime' | 'NAProcessTime' | 'IsPostPaid' | 'Tax' | 'ServiceCharge' | 'IsInclusive' | 'IsRoomTaxable' | 'IsCmsAllotment' | 'IsHouseUse' | 'IsDayUse' | 'IsComplimentary' | 'IsOOO' | 'IsOOI' | 'IsFloorPlan' | 'IsChargeToRoom' | 'IsOnlinePayment' | 'IsOnlineBooking' | 'TTx' | 'HTx' | 'Levy' | 'CheckInPrompt' | 'SecurityDeposit' | 'KeycardDeposit' | 'KeycardVendor' | 'CheckInRoomStatusID' | 'CancelCheckInRoomStatusID' | 'TransferRoomStatusID' | 'CheckOutRoomStatusID' | 'CancelCheckOutRoomStatusID' | 'BlockRoomReleaseStatusID' | 'CeilingPercentage' | 'FloorPercentage' | 'DayUseID' | 'HouseUseID' | 'ComplimentaryID' | 'FloorPriceKickInDays' | 'AllowCancellationDays' | 'OnlineTnC' | 'OnlinePaymentTnC' | 'DocumentRemark' | 'RegCardTnC' | 'OnlineBookingURL' | 'AboutHotelGalleryID' | 'TaxSchemeID' | 'AdvanceForfeitTaxID' | 'DepositForfeitTaxID' | 'AboutHotel' | 'ChannelManagerID' | 'PaymentGatewayID' | 'Encoders' | 'RegulationClause' | 'PDPA' | 'GuestNotice'>
    & { Company?: Maybe<(
      { __typename?: 'companyEntity' }
      & Pick<CompanyEntity, 'id' | 'name' | 'code' | 'isEInvIntegrated' | 'eInvStartDate' | 'eInvEndDate' | 'sstNo' | 'companyRegNo' | 'ttxNo'>
    )>, HotelAddress?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
    )>, Q3StatisticsMapping?: Maybe<(
      { __typename?: 'Q3StatisticsMapping' }
      & { RoomsAvailable?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )>, TotalRoomOccupied?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )>, RoomOccupiedWithComplimentary?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )>, ComplimentaryRoom?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )>, HouseUse?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )>, NoOfRoomGuests?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )>, NoOfShowRoom?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )>, TotalRooms?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )>, OutOfOrderRoom?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )>, CancellationRoom?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )>, DayUseRoom?: Maybe<(
        { __typename?: 'Q3Statistic' }
        & Pick<Q3Statistic, 'Name' | 'Debit' | 'Analysis' | 'Department'>
      )> }
    )>, ARSpecification?: Maybe<(
      { __typename?: 'ARSpecification' }
      & Pick<ArSpecification, 'InterestAccount'>
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'MobileNo' | 'GeneralEmail' | 'ReservationEmail' | 'BusinessEmail' | 'WebUrl' | 'NotificationMobileNo' | 'FaxNo'>
    )>, Facility?: Maybe<Array<(
      { __typename?: 'FacilityEntity' }
      & Pick<FacilityEntity, 'ID' | 'HotelID' | 'IsActive' | 'Title' | 'Description' | 'Level'>
      & { FacilityGallery?: Maybe<Array<(
        { __typename?: 'FacilityGalleryEntity' }
        & Pick<FacilityGalleryEntity, 'ID' | 'FacilityID' | 'GalleryID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
        )> }
      )>> }
    )>>, HotelGallery?: Maybe<Array<(
      { __typename?: 'HotelGalleryEntity' }
      & Pick<HotelGalleryEntity, 'ID' | 'HotelID' | 'IsDefault' | 'GalleryID'>
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
      )> }
    )>>, News?: Maybe<Array<(
      { __typename?: 'NewsEntity' }
      & Pick<NewsEntity, 'ID' | 'IsActive' | 'Description' | 'Title' | 'HotelID'>
      & { NewsGallery?: Maybe<Array<(
        { __typename?: 'NewsGalleryEntity' }
        & Pick<NewsGalleryEntity, 'ID'>
        & { Gallery?: Maybe<(
          { __typename?: 'GalleryEntity' }
          & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
        )> }
      )>> }
    )>>, DayUse?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
    )>, HouseUse?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
    )>, Complimentary?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateCode' | 'Description'>
    )>, AboutHotelImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
    )>, LogoImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
    )>, SOAImage?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
    )> }
  ) }
);

export type HotelPreRegQrQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type HotelPreRegQrQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'HotelPreRegQr'>
);

export type InvoiceRemarkMutationVariables = Exact<{
  InvoiceRemark?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  HotelID: Scalars['String'];
}>;


export type InvoiceRemarkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'InvoiceRemark'>
);

export type HotelUpdateMutationVariables = Exact<{
  HotelInput: HotelInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  Type?: Maybe<Scalars['String']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type HotelUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HotelUpdate'>
);

export type HotelInsertMutationVariables = Exact<{
  HotelInput: HotelInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  Type?: Maybe<Scalars['String']>;
}>;


export type HotelInsertMutation = (
  { __typename?: 'Mutation' }
  & { HotelInsert: (
    { __typename?: 'HotelEntity' }
    & Pick<HotelEntity, 'ID'>
  ) }
);

export type HotelContactUpdateMutationVariables = Exact<{
  ContactInput: ContactInput;
}>;


export type HotelContactUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HotelContactUpdate'>
);

export type HotelContactInsertMutationVariables = Exact<{
  ContactInput: ContactInput;
  HotelID: Scalars['String'];
}>;


export type HotelContactInsertMutation = (
  { __typename?: 'Mutation' }
  & { HotelContactInsert: (
    { __typename?: 'ContactEntity' }
    & Pick<ContactEntity, 'ID'>
  ) }
);

export type HotelFacilityUpdateMutationVariables = Exact<{
  FacilityInput: FacilityInput;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type HotelFacilityUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HotelFacilityUpdate'>
);

export type FacilitiesInactiveDeleteMutationVariables = Exact<{
  isInactive: Scalars['Boolean'];
  id: Scalars['String'];
}>;


export type FacilitiesInactiveDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FacilitiesInactiveDelete'>
);

export type HotelFacilityInsertMutationVariables = Exact<{
  FacilityInput: FacilityInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type HotelFacilityInsertMutation = (
  { __typename?: 'Mutation' }
  & { HotelFacilityInsert: (
    { __typename?: 'FacilityEntity' }
    & Pick<FacilityEntity, 'ID'>
  ) }
);

export type HotelNewsUpdateMutationVariables = Exact<{
  NewsInput: NewsInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type HotelNewsUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HotelNewsUpdate'>
);

export type HotelNewsInsertMutationVariables = Exact<{
  NewsInput: NewsInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type HotelNewsInsertMutation = (
  { __typename?: 'Mutation' }
  & { HotelNewsInsert: (
    { __typename?: 'NewsEntity' }
    & Pick<NewsEntity, 'ID' | 'Title' | 'Description'>
  ) }
);

export type GetMsicCodeListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMsicCodeListingQuery = (
  { __typename?: 'Query' }
  & { getMsicCodeListing: Array<(
    { __typename?: 'eInvoiceMsicEntity' }
    & Pick<EInvoiceMsicEntity, 'id' | 'msicCode' | 'description' | 'categoryReference'>
  )> }
);

export type GetGeneralTinQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralTinQuery = (
  { __typename?: 'Query' }
  & { getGeneralTin: Array<(
    { __typename?: 'GeneralTinEntity' }
    & Pick<GeneralTinEntity, 'id' | 'tinParty' | 'tin'>
  )> }
);

export type GetFolioInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DocType: Scalars['String'];
  FolioID: Scalars['String'];
}>;


export type GetFolioInfoQuery = (
  { __typename?: 'Query' }
  & { getFolioInfo: (
    { __typename?: 'EInvoiceQrResponse' }
    & Pick<EInvoiceQrResponse, 'ID' | 'DocStatus' | 'DocAmt' | 'DocNo' | 'DocDate' | 'HotelID' | 'QrCodeUrl'>
  ) }
);

export type IncidentalChargeListingQueryVariables = Exact<{
  DepartmentID: Scalars['String'];
}>;


export type IncidentalChargeListingQuery = (
  { __typename?: 'Query' }
  & { IncidentalChargeListing: Array<(
    { __typename?: 'IncidentalChargeEntity' }
    & Pick<IncidentalChargeEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DepartmentID' | 'Description' | 'Amount' | 'Tax' | 'ServiceCharge' | 'IsFnB' | 'IsEShop' | 'IsRoomRevenue' | 'IsOutlet' | 'IsEventInterface' | 'IsNegative' | 'IsPackage' | 'OutletCode' | 'GalleryID' | 'IsRateElement' | 'IsKiosk' | 'TaxSchemeID' | 'EInvClassification' | 'isEInvIntegrated' | 'MSICCode'>
    & { Gallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
    )> }
  )> }
);

export type GetClassificationListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClassificationListingQuery = (
  { __typename?: 'Query' }
  & { getClassificationListing: Array<(
    { __typename?: 'eInvoiceClassificationEntity' }
    & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
  )> }
);

export type GetClassificationListingByCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GetClassificationListingByCodeQuery = (
  { __typename?: 'Query' }
  & { getClassificationListingByCode: (
    { __typename?: 'eInvoiceClassificationEntity' }
    & Pick<EInvoiceClassificationEntity, 'id' | 'classificationCode' | 'description'>
  ) }
);

export type IncidentalChargeInsertMutationVariables = Exact<{
  IncidentalChargeInput: IncidentalChargeInput;
}>;


export type IncidentalChargeInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'IncidentalChargeInsert'>
);

export type IncidentalChargeUpdateMutationVariables = Exact<{
  IncidentalChargeInput: IncidentalChargeInput;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type IncidentalChargeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'IncidentalChargeUpdate'>
);

export type IncidentalChargeDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type IncidentalChargeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'IncidentalChargeDelete'>
);

export type LateCheckOutChargeListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type LateCheckOutChargeListingQuery = (
  { __typename?: 'Query' }
  & { LateCheckOutChargeListing: Array<(
    { __typename?: 'LateCheckOutChargeEntity' }
    & Pick<LateCheckOutChargeEntity, 'ID' | 'HotelID' | 'Name' | 'Amount' | 'IsActive' | 'CreatedBy' | 'ModifiedBy'>
  )> }
);

export type LateCheckOutChargeInsertMutationVariables = Exact<{
  LateCheckOutChargeInput: Array<LateCheckOutChargeInput> | LateCheckOutChargeInput;
}>;


export type LateCheckOutChargeInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LateCheckOutChargeInsert'>
);

export type LocationListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type LocationListingQuery = (
  { __typename?: 'Query' }
  & { LocationListing: Array<(
    { __typename?: 'LocationEntity' }
    & Pick<LocationEntity, 'ID' | 'Code' | 'Description' | 'IsActive' | 'DisplaySequence' | 'GalleryID'>
    & { Room?: Maybe<Array<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'MaxGuest' | 'ID' | 'RoomNo' | 'RoomStatusCode'>
      & { RoomStatus?: Maybe<(
        { __typename?: 'RoomStatusEntity' }
        & Pick<RoomStatusEntity, 'VacantOrOccupied' | 'CleanOrDirty' | 'Code' | 'Description'>
      )>, Location?: Maybe<(
        { __typename?: 'LocationEntity' }
        & Pick<LocationEntity, 'Code' | 'Description'>
      )> }
    )>>, Gallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ID' | 'BucketFileName' | 'ImageURL'>
    )> }
  )> }
);

export type LocationInUseListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  LocationID: Scalars['String'];
}>;


export type LocationInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'LocationInUseListing' | 'RoomInUseListing'>
);

export type LocationUpdateMutationVariables = Exact<{
  LocationInput: LocationInput;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type LocationUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LocationUpdate'>
);

export type LocationInsertMutationVariables = Exact<{
  LocationInput: LocationInput;
}>;


export type LocationInsertMutation = (
  { __typename?: 'Mutation' }
  & { LocationInsert: (
    { __typename?: 'LocationEntity' }
    & Pick<LocationEntity, 'ID'>
  ) }
);

export type LocationDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
  Description: Scalars['String'];
}>;


export type LocationDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'LocationDelete'>
);

export type DisplaySequenceUpdateMutationVariables = Exact<{
  Input: Array<DisplaySequenceInput> | DisplaySequenceInput;
  Type: Scalars['String'];
}>;


export type DisplaySequenceUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DisplaySequenceUpdate'>
);

export type LocationFloorPlanListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type LocationFloorPlanListingQuery = (
  { __typename?: 'Query' }
  & { LocationListing: Array<(
    { __typename?: 'LocationEntity' }
    & Pick<LocationEntity, 'ID' | 'Code'>
    & { Gallery?: Maybe<(
      { __typename?: 'GalleryEntity' }
      & Pick<GalleryEntity, 'ImageURL'>
    )>, Room?: Maybe<Array<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'ID' | 'RoomNo' | 'RoomTypeID' | 'FloorPlanID'>
      & { FloorPlan?: Maybe<Array<(
        { __typename?: 'LocationFloorPlanEntity' }
        & Pick<LocationFloorPlanEntity, 'ID' | 'Coordinates' | 'IsHotel' | 'IsPreCheckin'>
      )>> }
    )>> }
  )> }
);

export type FloorPlanListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  LocationID?: Maybe<Scalars['String']>;
}>;


export type FloorPlanListingQuery = (
  { __typename?: 'Query' }
  & { FloorPlanListing: Array<(
    { __typename?: 'LocationFloorPlanEntity' }
    & Pick<LocationFloorPlanEntity, 'ID' | 'HotelID' | 'LocationID' | 'RoomID' | 'RoomTypeID' | 'Coordinates' | 'IsHotel' | 'IsPreCheckin'>
  )> }
);

export type FloorPlanUpdateMutationVariables = Exact<{
  LocationFloorPlanInput: Array<LocationFloorPlanInput> | LocationFloorPlanInput;
  Type?: Maybe<Scalars['String']>;
}>;


export type FloorPlanUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FloorPlanUpdate'>
);

export type FloorPlanResetMutationVariables = Exact<{
  LocationID: Scalars['String'];
  HotelID: Scalars['String'];
  Type?: Maybe<Scalars['String']>;
}>;


export type FloorPlanResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'FloorPlanReset'>
);

export type MeasurementListingQueryVariables = Exact<{ [key: string]: never; }>;


export type MeasurementListingQuery = (
  { __typename?: 'Query' }
  & { MeasurementListing: Array<(
    { __typename?: 'MeasurementEntity' }
    & Pick<MeasurementEntity, 'ID' | 'Type' | 'Measurement' | 'Size'>
  )> }
);

export type RateElementListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type RateElementListingQuery = (
  { __typename?: 'Query' }
  & { RateElementListing: Array<(
    { __typename?: 'RateElementEntity' }
    & Pick<RateElementEntity, 'ID' | 'HotelID' | 'Amount' | 'ComputationRule' | 'CreatedDT' | 'ModifiedDT' | 'IsActive' | 'Min' | 'Max'>
    & { IncidentalCharge?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'DepartmentID' | 'Description' | 'Amount' | 'Tax' | 'ServiceCharge' | 'IsRoomRevenue' | 'IsActive' | 'IsFnB' | 'IsEShop' | 'GalleryID' | 'IsRateElement'>
    )> }
  )> }
);

export type RateElementUpdateMutationVariables = Exact<{
  RateElementInput: RateElementInput;
}>;


export type RateElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RateElementUpdate'>
);

export type RateElementInsertMutationVariables = Exact<{
  RateElementInput: RateElementInput;
}>;


export type RateElementInsertMutation = (
  { __typename?: 'Mutation' }
  & { RateElementInsert: (
    { __typename?: 'RateElementEntity' }
    & Pick<RateElementEntity, 'ID'>
  ) }
);

export type RatePolicyListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
}>;


export type RatePolicyListingQuery = (
  { __typename?: 'Query' }
  & { RatePolicyListing: Array<(
    { __typename?: 'RateEntity' }
    & Pick<RateEntity, 'ID' | 'HotelID' | 'RateTypeID' | 'IsActive' | 'RateCode' | 'Description' | 'RateFrequency' | 'OnlineRate' | 'Category'>
    & { RateEffective?: Maybe<Array<(
      { __typename?: 'RateEffectiveEntity' }
      & Pick<RateEffectiveEntity, 'ID'>
    )>> }
  )> }
);

export type RatePolicyInUseListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RatePolicyID: Scalars['String'];
  DebtorCategory: Scalars['String'];
}>;


export type RatePolicyInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RatePolicyInUseListing'>
);

export type IncidentalDepartmentListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type IncidentalDepartmentListingQuery = (
  { __typename?: 'Query' }
  & { IncidentalDepartmentListing: Array<(
    { __typename?: 'DepartmentEntity' }
    & Pick<DepartmentEntity, 'ID' | 'Code' | 'Name'>
    & { IncidentalCharge?: Maybe<Array<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'Amount' | 'Tax' | 'ServiceCharge' | 'Description'>
    )>> }
  )> }
);

export type RatePolicyDetailsQueryVariables = Exact<{
  RatePolicyID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type RatePolicyDetailsQuery = (
  { __typename?: 'Query' }
  & { RatePolicyDetails: (
    { __typename?: 'RatePolicyEntity' }
    & Pick<RatePolicyEntity, 'ID' | 'HotelID' | 'IsActive' | 'Category' | 'Code' | 'Description' | 'PublishOnline' | 'StartDate' | 'EndDate'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'ComplimentaryID' | 'HouseUseID' | 'DayUseID'>
    )>, Package?: Maybe<Array<(
      { __typename?: 'PackagesEntity' }
      & Pick<PackagesEntity, 'ID' | 'IsActive' | 'RatePolicyID' | 'IncidentalChargeID' | 'Description' | 'Amount' | 'GalleryID' | 'DisplaySeq' | 'Tax' | 'ServiceCharge' | 'ComputationRules' | 'IncludeRate'>
      & { IncidentalCharge?: Maybe<(
        { __typename?: 'IncidentalChargeEntity' }
        & Pick<IncidentalChargeEntity, 'ID' | 'Amount'>
      )> }
    )>>, Pricing?: Maybe<Array<(
      { __typename?: 'PricingEntity' }
      & Pick<PricingEntity, 'ID' | 'RatePolicyID' | 'RoomTypeID' | 'IsActive' | 'Standard' | 'Ceiling' | 'Floor' | 'PeakStandard' | 'PeakCeiling' | 'PeakFloor' | 'SuperPeakStandard' | 'SuperPeakCeiling' | 'SuperPeakFloor' | 'HotelID'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code'>
      )> }
    )>> }
  ) }
);

export type RatePolicyInsertMutationVariables = Exact<{
  RatePolicyInput: RatePolicyInput;
  PricingInput: Array<PricingInput> | PricingInput;
  PackagesInput: Array<PackagesInput> | PackagesInput;
}>;


export type RatePolicyInsertMutation = (
  { __typename?: 'Mutation' }
  & { RatePolicyInsert: (
    { __typename?: 'RatePolicyEntity' }
    & Pick<RatePolicyEntity, 'ID'>
  ) }
);

export type RatePolicyUpdateMutationVariables = Exact<{
  RatePolicyInput: RatePolicyInput;
  PricingInput?: Maybe<Array<PricingInput> | PricingInput>;
}>;


export type RatePolicyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RatePolicyUpdate'>
);

export type PackageInsertMutationVariables = Exact<{
  PackagesInput: Array<PackagesInput> | PackagesInput;
}>;


export type PackageInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PackageInsert'>
);

export type PackageUpdateMutationVariables = Exact<{
  PackagesInput: PackagesInput;
}>;


export type PackageUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'PackageUpdate'>
);

export type RatePolicyDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type RatePolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RatePolicyDelete'>
);

export type RatePolicyRoomTypeListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type RatePolicyRoomTypeListingQuery = (
  { __typename?: 'Query' }
  & { RatePolicyRoomTypeListing: Array<(
    { __typename?: 'RoomTypeEntity' }
    & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description' | 'TotalRoom' | 'MaxGuest' | 'IsActive' | 'DisplaySequence' | 'RoomSize' | 'MeasureType' | 'PropertyType'>
    & { RoomTypeAmenities?: Maybe<Array<(
      { __typename?: 'RoomTypeAmenitiesEntity' }
      & Pick<RoomTypeAmenitiesEntity, 'ID' | 'Name' | 'HotelID' | 'RoomTypeID' | 'GalleryID' | 'IsSelected'>
    )>>, RoomTypeGallery?: Maybe<Array<(
      { __typename?: 'RoomTypeGalleryEntity' }
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
      )> }
    )>> }
  )> }
);

export type SeasonListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RateEffectiveID?: Maybe<Scalars['String']>;
}>;


export type SeasonListingQuery = (
  { __typename?: 'Query' }
  & { SeasonListing: Array<(
    { __typename?: 'SeasonEntity' }
    & Pick<SeasonEntity, 'ID' | 'IsActive' | 'HotelID' | 'Name' | 'ModifiedDT' | 'RGBColor'>
    & { SeasonalRate?: Maybe<Array<(
      { __typename?: 'SeasonalRateEntity' }
      & Pick<SeasonalRateEntity, 'ID' | 'RoomTypeID' | 'RateEffectiveID' | 'SeasonID' | 'StandardRate'>
      & { DynamicRate: Array<(
        { __typename?: 'DynamicRateJson' }
        & Pick<DynamicRateJson, 'Tier' | 'RoomQuota' | 'Rate'>
      )>, Season?: Maybe<(
        { __typename?: 'SeasonEntity' }
        & Pick<SeasonEntity, 'ID' | 'IsActive' | 'HotelID' | 'Name' | 'RGBColor'>
      )>, RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'ID' | 'Description' | 'IsActive'>
      )> }
    )>> }
  )> }
);

export type SeasonUpdateMutationVariables = Exact<{
  SeasonInput: SeasonInput;
}>;


export type SeasonUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SeasonUpdate'>
);

export type SeasonInsertMutationVariables = Exact<{
  SeasonInput: SeasonInput;
}>;


export type SeasonInsertMutation = (
  { __typename?: 'Mutation' }
  & { SeasonInsert: (
    { __typename?: 'SeasonEntity' }
    & Pick<SeasonEntity, 'ID'>
  ) }
);

export type RateTypeListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type RateTypeListingQuery = (
  { __typename?: 'Query' }
  & { RateTypeListing: Array<(
    { __typename?: 'RateTypeEntity' }
    & Pick<RateTypeEntity, 'ID' | 'IsActive' | 'HotelID' | 'Description' | 'ModifiedDT'>
  )> }
);

export type RateTypeUpdateMutationVariables = Exact<{
  RateTypeInput: RateTypeInput;
}>;


export type RateTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RateTypeUpdate'>
);

export type RateTypeInsertMutationVariables = Exact<{
  RateTypeInput: RateTypeInput;
}>;


export type RateTypeInsertMutation = (
  { __typename?: 'Mutation' }
  & { RateTypeInsert: (
    { __typename?: 'RateTypeEntity' }
    & Pick<RateTypeEntity, 'ID'>
  ) }
);

export type RateAddOnListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type RateAddOnListingQuery = (
  { __typename?: 'Query' }
  & { RateAddOnListing: Array<(
    { __typename?: 'RateAddOnEntity' }
    & Pick<RateAddOnEntity, 'ID' | 'IsActive' | 'HotelID' | 'Amount' | 'PostingRythm' | 'ModifiedDT' | 'IncidentalChargeID' | 'Description'>
    & { IncidentalCharge?: Maybe<(
      { __typename?: 'IncidentalChargeEntity' }
      & Pick<IncidentalChargeEntity, 'ID' | 'DepartmentID' | 'Description' | 'Amount' | 'Tax' | 'ServiceCharge' | 'IsRoomRevenue' | 'IsActive' | 'IsFnB' | 'IsEShop' | 'GalleryID'>
    )> }
  )> }
);

export type RateAddOnUpdateMutationVariables = Exact<{
  RateAddOnInput: RateAddOnInput;
}>;


export type RateAddOnUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RateAddOnUpdate'>
);

export type RateAddOnInsertMutationVariables = Exact<{
  RateAddOnInput: RateAddOnInput;
}>;


export type RateAddOnInsertMutation = (
  { __typename?: 'Mutation' }
  & { RateAddOnInsert: (
    { __typename?: 'RateAddOnEntity' }
    & Pick<RateAddOnEntity, 'ID'>
  ) }
);

export type RateListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  Category?: Maybe<Scalars['String']>;
}>;


export type RateListingQuery = (
  { __typename?: 'Query' }
  & { RateListing: Array<(
    { __typename?: 'RateEntity' }
    & Pick<RateEntity, 'ID' | 'IsActive' | 'ModifiedDT' | 'CreatedBy' | 'ModifiedBy' | 'HotelID' | 'RateTypeID' | 'RateCode' | 'Description' | 'RateFrequency' | 'OnlineRate' | 'Category'>
  )> }
);

export type RateInHouseListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RateID: Scalars['String'];
}>;


export type RateInHouseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RateInHouseListing'>
);

export type RateInsertMutationVariables = Exact<{
  RateInput: RateInput;
}>;


export type RateInsertMutation = (
  { __typename?: 'Mutation' }
  & { RateInsert: (
    { __typename?: 'RateEntity' }
    & Pick<RateEntity, 'ID'>
  ) }
);

export type RateUpdateMutationVariables = Exact<{
  RateInput: RateInput;
}>;


export type RateUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RateUpdate'>
);

export type RateEffectiveListingQueryVariables = Exact<{
  RateID: Scalars['String'];
}>;


export type RateEffectiveListingQuery = (
  { __typename?: 'Query' }
  & { RateEffectiveListing: Array<(
    { __typename?: 'RateEffectiveEntity' }
    & Pick<RateEffectiveEntity, 'ID' | 'IsActive' | 'ModifiedDT' | 'CreatedBy' | 'ModifiedBy' | 'RateID' | 'StartDate' | 'EndDate'>
    & { RateElementIDs?: Maybe<Array<(
      { __typename?: 'EffectiveRateJson' }
      & Pick<EffectiveRateJson, 'ID' | 'Amount' | 'ComputationRule'>
    )>>, RateAddOnIDs?: Maybe<Array<(
      { __typename?: 'EffectiveRateJson' }
      & Pick<EffectiveRateJson, 'ID' | 'Amount' | 'Description'>
    )>>, Rate?: Maybe<(
      { __typename?: 'RateEntity' }
      & Pick<RateEntity, 'ID' | 'RateTypeID' | 'RateCode'>
    )> }
  )> }
);

export type RateEffectiveInsertMutationVariables = Exact<{
  SeasonalRateInput?: Maybe<Array<SeasonalRateInput> | SeasonalRateInput>;
  RateEffectiveInput: RateEffectiveInput;
}>;


export type RateEffectiveInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RateEffectiveInsert'>
);

export type RateEffectiveUpdateMutationVariables = Exact<{
  SeasonalRateInput?: Maybe<Array<SeasonalRateInput> | SeasonalRateInput>;
  RateEffectiveInput: RateEffectiveInput;
}>;


export type RateEffectiveUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RateEffectiveUpdate'>
);

export type RateEffectiveStatusUpdateMutationVariables = Exact<{
  RateEffectiveID: Scalars['String'];
  IsActive: Scalars['Boolean'];
}>;


export type RateEffectiveStatusUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RateEffectiveStatusUpdate'>
);

export type RatePolicyInfoQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type RatePolicyInfoQuery = (
  { __typename?: 'Query' }
  & { RatePolicyInfo: (
    { __typename?: 'RatePolicyResponse' }
    & Pick<RatePolicyResponse, 'TotalSeason' | 'TotalAddOn' | 'SeasonCalendarDate' | 'RateElementDate' | 'RateSetupDate' | 'RateTypeDate'>
  ) }
);

export type IsRateInUseQueryVariables = Exact<{
  RateID: Scalars['String'];
  RateEffectiveID: Scalars['String'];
}>;


export type IsRateInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsRateInUse'>
);

export type CmsRateSyncMutationVariables = Exact<{
  EndDate: Scalars['DateTime'];
  StartDate: Scalars['DateTime'];
  RateID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type CmsRateSyncMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CMSRateSync'>
);

export type SeasonListingCalenderQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type SeasonListingCalenderQuery = (
  { __typename?: 'Query' }
  & { SeasonListingCalender: Array<(
    { __typename?: 'SeasonEntity' }
    & Pick<SeasonEntity, 'HotelID' | 'ID' | 'IsActive' | 'ModifiedDT' | 'Name' | 'RGBColor'>
  )> }
);

export type ReasonListingQueryVariables = Exact<{
  DepartmentID?: Maybe<Scalars['String']>;
}>;


export type ReasonListingQuery = (
  { __typename?: 'Query' }
  & { ReasonListing: Array<(
    { __typename?: 'ReasonEntity' }
    & Pick<ReasonEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DepartmentID' | 'Description' | 'Code'>
  )> }
);

export type ReaonInUseListingQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type ReaonInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ReaonInUseListing'>
);

export type ReasonInsertMutationVariables = Exact<{
  ReasonInput: ReasonInput;
}>;


export type ReasonInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReasonInsert'>
);

export type ReasonUpdateMutationVariables = Exact<{
  ReasonInput: ReasonInput;
}>;


export type ReasonUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReasonUpdate'>
);

export type ReasonDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type ReasonDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'ReasonDelete'>
);

export type RegenBsQueueInsertMutationVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  RateCodeID?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  IsCms?: Maybe<Scalars['Boolean']>;
}>;


export type RegenBsQueueInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RegenBSQueueInsert'>
);

export type RegenBsQueueListingQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
}>;


export type RegenBsQueueListingQuery = (
  { __typename?: 'Query' }
  & { RegenBSQueueListing: Array<(
    { __typename?: 'RegenBSQueueEntity' }
    & Pick<RegenBsQueueEntity, 'ID' | 'Status' | 'BatchNo'>
    & { Registration?: Maybe<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate'>
      & { Booking?: Maybe<(
        { __typename?: 'BookingEntity' }
        & Pick<BookingEntity, 'ID' | 'BookingNo'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName'>
        )> }
      )> }
    )> }
  )> }
);

export type RegenBsQueueConfirmMutationVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
}>;


export type RegenBsQueueConfirmMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RegenBSQueueConfirm'>
);

export type RoomListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Location?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type RoomListingQuery = (
  { __typename?: 'Query' }
  & { RoomListing: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'IsActive' | 'HotelID' | 'LocationID' | 'RoomTypeID' | 'RoomNo' | 'MaxGuest' | 'RoomSize' | 'DisplaySequence' | 'Description'>
    & { BlockRoomLog?: Maybe<Array<(
      { __typename?: 'BlockRoomLogEntity' }
      & Pick<BlockRoomLogEntity, 'ID' | 'IsRelease'>
    )>>, RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description' | 'TotalRoom'>
    )>, Location?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'ID' | 'Code' | 'Description'>
    )>, RoomStatus?: Maybe<(
      { __typename?: 'RoomStatusEntity' }
      & Pick<RoomStatusEntity, 'ID' | 'Description' | 'RGBColor' | 'Code' | 'VacantOrOccupied' | 'CleanOrDirty'>
    )>, Registrations?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'IsPrincipal' | 'CheckInDate' | 'CheckOutDate'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'FullName' | 'Nationality' | 'PhoneNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
          )> }
        )> }
      )> }
    )>>, RoomAssignmentStatus?: Maybe<(
      { __typename?: 'RoomStatusEntity' }
      & Pick<RoomStatusEntity, 'ID' | 'Description' | 'RGBColor' | 'Code' | 'VacantOrOccupied' | 'CleanOrDirty'>
    )> }
  )> }
);

export type RoomListQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
}>;


export type RoomListQuery = (
  { __typename?: 'Query' }
  & { RoomListing: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'RoomNo'>
    & { BlockRoomLog?: Maybe<Array<(
      { __typename?: 'BlockRoomLogEntity' }
      & Pick<BlockRoomLogEntity, 'IsRelease' | 'BlockDate' | 'ReleaseDate'>
    )>>, Registrations?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'CheckInDate' | 'CheckOutDate' | 'IsPrincipal' | 'RegistrationStatus' | 'ArrivalDate' | 'DepartureDate'>
      & { Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'Nationality' | 'FullName' | 'PhoneNo' | 'Email'>
          & { Address?: Maybe<(
            { __typename?: 'Address' }
            & Pick<Address, 'address' | 'address2' | 'city' | 'state' | 'postCode' | 'AddressType' | 'country' | 'siteName'>
          )> }
        )> }
      )> }
    )>>, Location?: Maybe<(
      { __typename?: 'LocationEntity' }
      & Pick<LocationEntity, 'ID' | 'Code' | 'Description'>
    )> }
  )> }
);

export type BlockRoomLogListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
}>;


export type BlockRoomLogListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'BlockRoomLogListing'>
);

export type HotelRoomNumberListQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type HotelRoomNumberListQuery = (
  { __typename?: 'Query' }
  & { HotelRoomNumberList: Array<(
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID' | 'RoomTypeID' | 'RoomNo' | 'IsActive'>
  )> }
);

export type RoomUpdateMutationVariables = Exact<{
  RoomInput: RoomInput;
}>;


export type RoomUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RoomUpdate'>
);

export type RoomInsertMutationVariables = Exact<{
  RoomInput: RoomInput;
}>;


export type RoomInsertMutation = (
  { __typename?: 'Mutation' }
  & { RoomInsert: (
    { __typename?: 'RoomEntity' }
    & Pick<RoomEntity, 'ID'>
  ) }
);

export type RoomDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
  HotelID: Scalars['String'];
  RoomTypeID: Scalars['String'];
}>;


export type RoomDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RoomDelete'>
);

export type RoomInHouseListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
}>;


export type RoomInHouseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RoomInHouseListing'>
);

export type RoomBlockRoomListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  RoomID: Scalars['String'];
}>;


export type RoomBlockRoomListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RoomBlockRoomListing'>
);

export type RoomSaleOptimizationListingQueryVariables = Exact<{
  StartDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
}>;


export type RoomSaleOptimizationListingQuery = (
  { __typename?: 'Query' }
  & { RoomSaleOptimizationListing: Array<(
    { __typename?: 'RoomSaleOptimizationOutput' }
    & { RoomType?: Maybe<(
      { __typename?: 'RoomTypeEntity' }
      & Pick<RoomTypeEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'HotelID' | 'PropertyType' | 'Code' | 'Description' | 'TotalRoom' | 'MaxGuest' | 'RoomSize' | 'DisplaySequence'>
      & { RoomTypeAvailability?: Maybe<Array<(
        { __typename?: 'AvailabilityOutput' }
        & Pick<AvailabilityOutput, 'ID' | 'EffectiveDate' | 'BookedRoom' | 'BlockedRoom' | 'AllottedRoom' | 'TotalOccupied' | 'TotalVacant' | 'SeasonType'>
      )>> }
    )>, Availability?: Maybe<Array<(
      { __typename?: 'AvailabilityEntity' }
      & Pick<AvailabilityEntity, 'ID' | 'RoomTypeID' | 'EffectiveDate' | 'BookedRoom' | 'BlockedRoom' | 'AllottedRoom' | 'DynamicRoom'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'ID' | 'TotalRoom'>
      )> }
    )>> }
  )> }
);

export type RoomSaleOptimizationUpdateMutationVariables = Exact<{
  input: Array<AvailabilityInput> | AvailabilityInput;
}>;


export type RoomSaleOptimizationUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RoomSaleOptimizationUpdate'>
);

export type RoomStatusListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type RoomStatusListingQuery = (
  { __typename?: 'Query' }
  & { RoomStatusListing: Array<(
    { __typename?: 'RoomStatusEntity' }
    & Pick<RoomStatusEntity, 'ID' | 'HotelID' | 'Code' | 'RGBColor' | 'Description' | 'VacantOrOccupied' | 'CleanOrDirty' | 'IsActive' | 'CreatedBy' | 'ModifiedBy'>
  )> }
);

export type RoomStatusInsertMutationVariables = Exact<{
  RoomStatusInput: Array<RoomStatusInput> | RoomStatusInput;
}>;


export type RoomStatusInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RoomStatusInsert'>
);

export type RoomTypeListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
}>;


export type RoomTypeListingQuery = (
  { __typename?: 'Query' }
  & { RoomTypeListing: Array<(
    { __typename?: 'RoomTypeEntity' }
    & Pick<RoomTypeEntity, 'ID' | 'Code' | 'Description' | 'TotalRoom' | 'MaxGuest' | 'IsActive' | 'DisplaySequence' | 'RoomSize' | 'HouseLimit' | 'Overbooking' | 'MeasureType' | 'PropertyType'>
    & { RoomTypeAmenities?: Maybe<Array<(
      { __typename?: 'RoomTypeAmenitiesEntity' }
      & Pick<RoomTypeAmenitiesEntity, 'ID' | 'Name' | 'HotelID' | 'RoomTypeID' | 'GalleryID' | 'IsSelected'>
    )>>, RoomTypeGallery?: Maybe<Array<(
      { __typename?: 'RoomTypeGalleryEntity' }
      & { Gallery?: Maybe<(
        { __typename?: 'GalleryEntity' }
        & Pick<GalleryEntity, 'ID' | 'ImageURL' | 'BucketFileName'>
      )> }
    )>> }
  )> }
);

export type RegistrationRoomTypeListingQueryVariables = Exact<{
  RoomTypeID: Scalars['String'];
}>;


export type RegistrationRoomTypeListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RegistrationRoomTypeListing'>
);

export type PlatformAmenitiesListingQueryVariables = Exact<{ [key: string]: never; }>;


export type PlatformAmenitiesListingQuery = (
  { __typename?: 'Query' }
  & { PlatformAmenitiesListing: Array<(
    { __typename?: 'PlatformAmenitiesEntity' }
    & Pick<PlatformAmenitiesEntity, 'ID' | 'Name' | 'GalleryID' | 'IsActive'>
  )> }
);

export type RoomTypeInsertMutationVariables = Exact<{
  RoomTypeInput: RoomTypeInput;
  RoomTypeAmenitiesInput: Array<RoomTypeAmenitiesInput> | RoomTypeAmenitiesInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type RoomTypeInsertMutation = (
  { __typename?: 'Mutation' }
  & { RoomTypeInsert: (
    { __typename?: 'RoomTypeEntity' }
    & Pick<RoomTypeEntity, 'ID' | 'Description' | 'Code'>
  ) }
);

export type RoomTypeUpdateMutationVariables = Exact<{
  RoomTypeInput: RoomTypeInput;
  RoomTypeAmenitiesInput?: Maybe<Array<RoomTypeAmenitiesInput> | RoomTypeAmenitiesInput>;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
  OldGalleryID?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type RoomTypeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RoomTypeUpdate'>
);

export type RoomTypeDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type RoomTypeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'RoomTypeDelete'>
);

export type SalesChannelListingQueryVariables = Exact<{
  IsActive?: Maybe<Scalars['Boolean']>;
  DepartmentID: Scalars['String'];
}>;


export type SalesChannelListingQuery = (
  { __typename?: 'Query' }
  & { SalesChannelListing: Array<(
    { __typename?: 'SalesChannelEntity' }
    & Pick<SalesChannelEntity, 'ID' | 'IsActive' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'DepartmentID' | 'Description'>
  )> }
);

export type SalesChannelInsertMutationVariables = Exact<{
  SalesChannelInput: SalesChannelInput;
}>;


export type SalesChannelInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SalesChannelInsert'>
);

export type SalesChannelinUseListingQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type SalesChannelinUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SalesChannelinUseListing'>
);

export type SalesChannelUpdateMutationVariables = Exact<{
  SalesChannelInput: SalesChannelInput;
}>;


export type SalesChannelUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SalesChannelUpdate'>
);

export type SalesChannelDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type SalesChannelDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SalesChannelDelete'>
);

export type SeasonCalendarListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
}>;


export type SeasonCalendarListingQuery = (
  { __typename?: 'Query' }
  & { SeasonCalendarListing: Array<(
    { __typename?: 'SeasonCalendarEntity' }
    & Pick<SeasonCalendarEntity, 'ID' | 'HotelID' | 'ModifiedDT' | 'EffectiveDate' | 'SeasonID'>
  )> }
);

export type SeasonCalendarUpdateMutationVariables = Exact<{
  HotelID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  SeasonCalendarInput: Array<SeasonCalendarInput> | SeasonCalendarInput;
}>;


export type SeasonCalendarUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SeasonCalendarUpdate'>
);

export type SegmentListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  IsActive?: Maybe<Scalars['Boolean']>;
}>;


export type SegmentListingQuery = (
  { __typename?: 'Query' }
  & { SegmentListing: Array<(
    { __typename?: 'SegmentEntity' }
    & Pick<SegmentEntity, 'ID' | 'Description' | 'HotelID' | 'IsActive' | 'DisplaySequence' | 'StayViewIcon'>
    & { Q3MarketSegmentMapping?: Maybe<(
      { __typename?: 'Q3MarketSegmentMapping' }
      & Pick<Q3MarketSegmentMapping, 'RoomDebit' | 'RoomAnalysis' | 'RoomDepartment' | 'GuestsDebit' | 'GuestsAnalysis' | 'GuestsDepartment'>
    )> }
  )> }
);

export type SegmentInUseListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
  SegmentID: Scalars['String'];
}>;


export type SegmentInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'SegmentInUseListing'>
);

export type SegmentInsertMutationVariables = Exact<{
  SegmentInput: SegmentInput;
}>;


export type SegmentInsertMutation = (
  { __typename?: 'Mutation' }
  & { SegmentInsert: (
    { __typename?: 'SegmentEntity' }
    & Pick<SegmentEntity, 'ID' | 'Description' | 'IsActive'>
  ) }
);

export type SegmentUpdateMutationVariables = Exact<{
  SegmentInput: SegmentInput;
}>;


export type SegmentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SegmentUpdate'>
);

export type SegmentDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
  Description: Scalars['String'];
}>;


export type SegmentDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SegmentDelete'>
);

export type TaxEffectiveDateListingQueryVariables = Exact<{ [key: string]: never; }>;


export type TaxEffectiveDateListingQuery = (
  { __typename?: 'Query' }
  & { TaxEffectiveDateListing: Array<(
    { __typename?: 'TaxEffectiveDateEntity' }
    & Pick<TaxEffectiveDateEntity, 'ID' | 'Value' | 'Name' | 'IsFixAmount'>
  )> }
);

export type TaxInUseListingQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type TaxInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TaxInUseListing'>
);

export type TaxPolicyListingQueryVariables = Exact<{
  HotelID: Scalars['String'];
}>;


export type TaxPolicyListingQuery = (
  { __typename?: 'Query' }
  & { TaxPolicyListing: Array<(
    { __typename?: 'HotelTaxPolicyEntity' }
    & Pick<HotelTaxPolicyEntity, 'ID' | 'TaxType' | 'Description' | 'Value' | 'IsFixAmount' | 'IsTaxable' | 'EffectiveDate' | 'TTxExemptedReason' | 'TTxNonExemptedReason' | 'IsHouseUse' | 'IsComplimentary'>
  )> }
);

export type HotelTaxPolicyInsertMutationVariables = Exact<{
  HotelTaxPolicyInput: HotelTaxPolicyInput;
}>;


export type HotelTaxPolicyInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HotelTaxPolicyInsert'>
);

export type HotelTaxPolicyUpdateMutationVariables = Exact<{
  HotelTaxPolicyInput: HotelTaxPolicyInput;
}>;


export type HotelTaxPolicyUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HotelTaxPolicyUpdate'>
);

export type HotelTaxPolicyDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type HotelTaxPolicyDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'HotelTaxPolicyDelete'>
);

export type CreateRolePermissionMutationVariables = Exact<{
  permissionArr: Array<HotelPermission> | HotelPermission;
  input: RoleInput;
}>;


export type CreateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateRolePermission'>
);

export type GetRoleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'name' | 'MenuOption'>
    & { rolePerm?: Maybe<Array<(
      { __typename?: 'RolePermAsgEntity' }
      & Pick<RolePermAsgEntity, 'ID' | 'permName'>
    )>> }
  )> }
);

export type UpdateRolePermissionMutationVariables = Exact<{
  permissionArr: Array<HotelPermission> | HotelPermission;
  input: RoleInput;
}>;


export type UpdateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateRolePermission'>
);

export type DeleteRolePermissionMutationVariables = Exact<{
  roleID: Scalars['String'];
}>;


export type DeleteRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteRolePermission'>
);

export type CreateListEntityRoleUserMutationVariables = Exact<{
  hotelID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput> | RoleUserInput;
}>;


export type CreateListEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateListEntityRoleUser'>
);

export type EntRoleUsrAsgListQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
}>;


export type EntRoleUsrAsgListQuery = (
  { __typename?: 'Query' }
  & { EntRoleUsrAsgList: Array<(
    { __typename?: 'EntRoleUsrAsgEntity' }
    & Pick<EntRoleUsrAsgEntity, 'userID' | 'roleID'>
  )> }
);

export type MenuOptionUpdateMutationVariables = Exact<{
  input: RoleInput;
}>;


export type MenuOptionUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'MenuOptionUpdate'>
);

export type TaxInsertMutationVariables = Exact<{
  TaxInsertInput: TaxPolicyInput;
  AccountID: Scalars['String'];
}>;


export type TaxInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TaxInsert'>
);

export type TaxUpdateMutationVariables = Exact<{
  TaxEditInput: EditTaxPolicyInput;
  AccountID: Scalars['String'];
}>;


export type TaxUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TaxUpdate'>
);

export type GetTaxTypeListingQueryVariables = Exact<{
  AccountID: Scalars['String'];
}>;


export type GetTaxTypeListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetTaxTypeListing'>
);

export type GetTaxListingQueryVariables = Exact<{
  AccountID: Scalars['String'];
}>;


export type GetTaxListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'GetTaxListing'>
);

export type IsTaxInUseQueryVariables = Exact<{
  TaxID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type IsTaxInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsTaxInUse'>
);

export type GetTaxDetailsQueryVariables = Exact<{
  TaxID: Scalars['String'];
  AccountID: Scalars['String'];
}>;


export type GetTaxDetailsQuery = (
  { __typename?: 'Query' }
  & { GetTaxDetails: (
    { __typename?: 'taxEntity' }
    & Pick<TaxEntity, 'id' | 'accountId' | 'code' | 'description' | 'classType' | 'schemeTypeId' | 'isClaimable' | 'commonStatus'>
    & { taxDetail: Array<(
      { __typename?: 'taxDetailEntity' }
      & Pick<TaxDetailEntity, 'id' | 'effectiveDate' | 'rate'>
    )> }
  ) }
);

export type TaxDeleteMutationVariables = Exact<{
  TaxID: Scalars['String'];
  TaxDeleteInput: Array<TaxDetailInput> | TaxDetailInput;
}>;


export type TaxDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TaxDelete'>
);

export type IsTaxSameCodeQueryVariables = Exact<{
  AccountID: Scalars['String'];
  TaxCode: Scalars['String'];
}>;


export type IsTaxSameCodeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsTaxSameCode'>
);

export type GetTaxSchemeListingQueryVariables = Exact<{
  AccountID: Scalars['String'];
}>;


export type GetTaxSchemeListingQuery = (
  { __typename?: 'Query' }
  & { GetTaxSchemeListing: Array<(
    { __typename?: 'taxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'id' | 'accountId' | 'name' | 'description' | 'isExclusive' | 'commonStatus'>
    & { taxSchemeDetails?: Maybe<Array<(
      { __typename?: 'taxSchemeDetailEntity' }
      & Pick<TaxSchemeDetailEntity, 'id' | 'accountId' | 'taxSchemeId' | 'effectiveDate' | 'commonStatus'>
      & { taxSchemeDetailItems?: Maybe<Array<(
        { __typename?: 'taxSchemeDetailItemEntity' }
        & Pick<TaxSchemeDetailItemEntity, 'computeMethod' | 'id' | 'taxId' | 'seqNo'>
        & { taxOnSeq?: Maybe<Array<(
          { __typename?: 'taxOnSeqObjects' }
          & Pick<TaxOnSeqObjects, 'taxSchemeDetailItemId' | 'seqNo'>
        )>> }
      )>> }
    )>> }
  )> }
);

export type IsTaxSchemeInUseQueryVariables = Exact<{
  TaxSchemeID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type IsTaxSchemeInUseQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsTaxSchemeInUse'>
);

export type GetTaxListingDropdownQueryVariables = Exact<{
  AccountID: Scalars['String'];
}>;


export type GetTaxListingDropdownQuery = (
  { __typename?: 'Query' }
  & { GetTaxListingDropdown: Array<(
    { __typename?: 'TaxListingOutput' }
    & Pick<TaxListingOutput, 'tax_id' | 'code' | 'description' | 'class_type' | 'tax_status' | 'rate' | 'tax_type'>
  )> }
);

export type TaxSchemeDeleteMutationVariables = Exact<{
  TaxSchemeID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type TaxSchemeDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TaxSchemeDelete'>
);

export type SetDefaultTaxSchemeMutationVariables = Exact<{
  TaxSchemeID: Scalars['String'];
  AccountID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type SetDefaultTaxSchemeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'SetDefaultTaxScheme'>
);

export type TaxSchemeInsertMutationVariables = Exact<{
  TaxSchemeInput: TaxSchemeInput;
  AccountID: Scalars['String'];
}>;


export type TaxSchemeInsertMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TaxSchemeInsert'>
);

export type TaxSchemeUpdateMutationVariables = Exact<{
  EditTaxSchemeInput: EditTaxSchemeInput;
  AccountID: Scalars['String'];
}>;


export type TaxSchemeUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TaxSchemeUpdate'>
);

export type IsTaxSameNameQueryVariables = Exact<{
  AccountID: Scalars['String'];
  TaxSchemeName: Scalars['String'];
}>;


export type IsTaxSameNameQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'IsTaxSameName'>
);

export type GetTaxSchemeDetailQueryVariables = Exact<{
  AccountID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
}>;


export type GetTaxSchemeDetailQuery = (
  { __typename?: 'Query' }
  & { GetTaxSchemeDetail: Array<(
    { __typename?: 'TaxSchemeDetailOutput' }
    & Pick<TaxSchemeDetailOutput, 'taxId' | 'taxRate' | 'taxCode' | 'taxType' | 'taxSeqNo'>
  )> }
);

export type ComputeBookingbyHotelQueryVariables = Exact<{
  HotelID: Scalars['String'];
  input: Array<TaxInput> | TaxInput;
}>;


export type ComputeBookingbyHotelQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ComputeBookingbyHotel'>
);

export type ComputeTaxBookingQueryVariables = Exact<{
  RoomTypeID: Scalars['String'];
  RateID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  HotelID: Scalars['String'];
  DiscountAmount: Scalars['Float'];
}>;


export type ComputeTaxBookingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ComputeTaxBooking'>
);

export type GetUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'jobTitle' | 'contactNo' | 'superUser' | 'avatar' | 'commonStatus' | 'userName'>
  ) }
);

export type HotelAuthorizationInfoLineQueryVariables = Exact<{
  AccountID: Scalars['String'];
}>;


export type HotelAuthorizationInfoLineQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'HotelAuthorizationInfoLine'>
);

export type GetUsersByAccountQueryVariables = Exact<{
  accountID: Scalars['String'];
  HotelID?: Maybe<Scalars['String']>;
}>;


export type GetUsersByAccountQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccount: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'userName' | 'email' | 'contactNo' | 'commonStatus' | 'confirmed' | 'avatar' | 'superUser' | 'accountID' | 'softwareCode' | 'name' | 'department' | 'jobTitle'>
  )> }
);

export type RoleViewQueryVariables = Exact<{
  HotelID?: Maybe<Scalars['String']>;
  UserID?: Maybe<Scalars['String']>;
}>;


export type RoleViewQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'RoleView'>
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
  HotelID: Scalars['String'];
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'email'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID'>
  ) }
);

export type UpdateProfileMutationVariables = Exact<{
  input: UserInput;
  AttachmentInput?: Maybe<Array<Scalars['Upload']> | Scalars['Upload']>;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProfile'>
);

export type UnLockUserMutationVariables = Exact<{
  password: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
}>;


export type UnLockUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unLockUser'>
);

export type ReSendActivationEmailMutationVariables = Exact<{
  userID: Scalars['String'];
}>;


export type ReSendActivationEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reSendActivationEmail'>
);

export type TravelAgentListingQueryVariables = Exact<{
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  SalesChannel?: Maybe<Scalars['String']>;
  IsActive?: Maybe<Scalars['Boolean']>;
  DebtorAccount?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Adjusted?: Maybe<Scalars['Boolean']>;
  DebtorCategory: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type TravelAgentListingQuery = (
  { __typename?: 'Query' }
  & { TravelAgentListing: Array<(
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'Name' | 'IsActive' | 'IsIndividual' | 'DebtorCategory' | 'DebtorTypeID' | 'DebtorAccount' | 'CreditLimit' | 'Login' | 'Password' | 'Segment' | 'Remarks' | 'SalesChannel' | 'BillingInstruction' | 'IsFrontDeskActive'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'Email' | 'PhoneNo' | 'AccountID'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
      )> }
    )>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'ID' | 'DebtorID' | 'ContactID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'Designation' | 'MobileNo' | 'Email' | 'IsActive'>
      )> }
    )>> }
  )> }
);

export type PmsTravelAgentDetailQueryVariables = Exact<{
  HotelID: Scalars['String'];
  ID: Scalars['String'];
}>;


export type PmsTravelAgentDetailQuery = (
  { __typename?: 'Query' }
  & { PMSTravelAgentDetail: (
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID' | 'Name' | 'IsIndividual' | 'LastBooking' | 'DebtorCategory' | 'DebtorTypeID' | 'DebtorAccount' | 'CreditLimit' | 'Login' | 'Password' | 'Segment' | 'Remarks' | 'SalesChannel' | 'BillingInstruction' | 'CompanyRegNo' | 'Tin' | 'TaxRegNo' | 'IseCommerce' | 'InvoiceType'>
    & { Booking?: Maybe<Array<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID'>
    )>>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'Email' | 'PhoneNo' | 'MobileNo' | 'AccountID'>
      & { Address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'postCode' | 'state' | 'city' | 'country'>
      )> }
    )>, Contract?: Maybe<Array<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'IsActive'>
    )>>, DebtorContact?: Maybe<Array<(
      { __typename?: 'DebtorContactEntity' }
      & Pick<DebtorContactEntity, 'ID' | 'DebtorID' | 'ContactID'>
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'FullName' | 'Designation' | 'MobileNo' | 'Email' | 'IsActive'>
      )> }
    )>> }
  ) }
);

export type TravelAgentContractListingQueryVariables = Exact<{
  DebtorID: Scalars['String'];
}>;


export type TravelAgentContractListingQuery = (
  { __typename?: 'Query' }
  & { TravelAgentContractListing: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'DebtorID' | 'ContractNo' | 'Description' | 'StartDate' | 'EndDate' | 'RoomUpgrade' | 'Elastic' | 'Allotment' | 'CutOffDays' | 'PaymentTerms' | 'AllowDeleteContract' | 'Remarks' | 'IsActive' | 'IsCMS' | 'IsKiosk' | 'ModifiedDT' | 'ChannelManager'>
    & { Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'ID' | 'Name'>
    )>, ContractRate?: Maybe<Array<(
      { __typename?: 'ContractRateEntity' }
      & Pick<ContractRateEntity, 'ID'>
      & { RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'ID' | 'Description' | 'RateCode'>
      )> }
    )>>, ContractAllotmentByRoomType?: Maybe<Array<(
      { __typename?: 'ContractAllotmentEntity' }
      & Pick<ContractAllotmentEntity, 'ID' | 'AllowDeleteAllotment' | 'RoomTypeID' | 'InitialAllot' | 'Adjust' | 'SysAdjust' | 'PickUp' | 'SysRelease' | 'AllotDate'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'Description'>
      )> }
    )>> }
  )> }
);

export type TravelAgentUpdateMutationVariables = Exact<{
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
  DebtorContactInput?: Maybe<Array<ContactInput> | ContactInput>;
}>;


export type TravelAgentUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TravelAgentUpdate'>
);

export type TravelAgentMainUpdateMutationVariables = Exact<{
  DebtorInput: DebtorInput;
}>;


export type TravelAgentMainUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TravelAgentMainUpdate'>
);

export type TravelAgentInsertMutationVariables = Exact<{
  ContactInput: ContactInput;
  DebtorInput: DebtorInput;
  DebtorContactInput: Array<ContactInput> | ContactInput;
}>;


export type TravelAgentInsertMutation = (
  { __typename?: 'Mutation' }
  & { TravelAgentInsert: (
    { __typename?: 'DebtorEntity' }
    & Pick<DebtorEntity, 'ID'>
  ) }
);

export type TravelAgBookingListingQueryVariables = Exact<{
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
  DebtorType?: Maybe<Scalars['String']>;
  BookingStatus?: Maybe<Scalars['String']>;
  ArrStartDate?: Maybe<Scalars['DateTime']>;
  ArrEndDate?: Maybe<Scalars['DateTime']>;
  DepartStartDate?: Maybe<Scalars['DateTime']>;
  DepartEndDate?: Maybe<Scalars['DateTime']>;
  BookingDate?: Maybe<Scalars['DateTime']>;
  offset?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  BookingNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  ReferenceNo?: Maybe<Scalars['String']>;
  RoomTypeID?: Maybe<Scalars['String']>;
  GroupName?: Maybe<Scalars['String']>;
  SortBy?: Maybe<Scalars['String']>;
  SortByOrder?: Maybe<Scalars['String']>;
}>;


export type TravelAgBookingListingQuery = (
  { __typename?: 'Query' }
  & { TravelAgBookingListing: Array<(
    { __typename?: 'BookingEntity' }
    & Pick<BookingEntity, 'ID' | 'AccountID' | 'HotelID' | 'TotalAdvPayment' | 'CreatedDT' | 'BookingNo' | 'ArrivalDate' | 'DepartureDate' | 'ContactID' | 'GroupName' | 'SalesChannelID' | 'Source' | 'SegmentID' | 'SpecialRequest' | 'DebtorAccount' | 'BillingInstruction' | 'Reason' | 'BookingStatus'>
    & { Hotel?: Maybe<(
      { __typename?: 'HotelEntity' }
      & Pick<HotelEntity, 'HotelName'>
      & { HotelAddress?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'address2' | 'siteName' | 'country' | 'city' | 'state' | 'postCode'>
      )> }
    )>, Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'FullName' | 'PhoneNo' | 'Email' | 'Nationality' | 'ID' | 'MobileNo' | 'PassportNo' | 'NRIC'>
    )>, Debtor?: Maybe<(
      { __typename?: 'DebtorEntity' }
      & Pick<DebtorEntity, 'DebtorCategory' | 'ID' | 'Name'>
    )>, SalesChannel?: Maybe<(
      { __typename?: 'SalesChannelEntity' }
      & Pick<SalesChannelEntity, 'ID' | 'Description'>
    )>, Segment?: Maybe<(
      { __typename?: 'SegmentEntity' }
      & Pick<SegmentEntity, 'ID' | 'Description'>
    )>, BookedRegistration?: Maybe<Array<(
      { __typename?: 'RegistrationEntity' }
      & Pick<RegistrationEntity, 'ID' | 'RoomID' | 'CheckInDate' | 'SharerNo' | 'CheckOutDate' | 'PromoCode' | 'NoOfAdult' | 'NoOfChild' | 'NoOfInfant' | 'IsPrincipal' | 'Signature' | 'SpecialRequest' | 'RegistrationStatus' | 'RoomTypeID' | 'RatePolicyID' | 'RoomRate' | 'TotalRoomPrice' | 'TotalRoomTax' | 'TotalPackagePrice' | 'TotalPrice'>
      & { RoomType?: Maybe<(
        { __typename?: 'RoomTypeEntity' }
        & Pick<RoomTypeEntity, 'Code' | 'ID'>
      )>, RatePolicy?: Maybe<(
        { __typename?: 'RateEntity' }
        & Pick<RateEntity, 'RateCode' | 'ID' | 'Description'>
      )>, Guest?: Maybe<(
        { __typename?: 'GuestProfileEntity' }
        & Pick<GuestProfileEntity, 'ID' | 'VehicleNo'>
        & { Contact?: Maybe<(
          { __typename?: 'ContactEntity' }
          & Pick<ContactEntity, 'ID' | 'FullName' | 'Email' | 'NRIC' | 'PhoneNo' | 'MobileNo'>
        )> }
      )> }
    )>> }
  )> }
);

export type TravelAgentContractUpdateMutationVariables = Exact<{
  ContractInput: ContractInput;
  RatePolicyIDs?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  RoomTypeList?: Maybe<Array<ContractAllotmentInput> | ContractAllotmentInput>;
  HotelID: Scalars['String'];
}>;


export type TravelAgentContractUpdateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TravelAgentContractUpdate'>
);

export type TravelAgentContractInsertMutationVariables = Exact<{
  ContractInput: ContractInput;
  RatePolicyIDs: Array<Scalars['String']> | Scalars['String'];
  RoomTypeList: Array<ContractAllotmentInput> | ContractAllotmentInput;
  HotelID: Scalars['String'];
}>;


export type TravelAgentContractInsertMutation = (
  { __typename?: 'Mutation' }
  & { TravelAgentContractInsert: (
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID'>
  ) }
);

export type AllotmentListingQueryVariables = Exact<{
  ContractID: Scalars['String'];
  RoomTypeID?: Maybe<Scalars['String']>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type AllotmentListingQuery = (
  { __typename?: 'Query' }
  & { AllotmentListing: Array<(
    { __typename?: 'ContractAllotmentEntity' }
    & Pick<ContractAllotmentEntity, 'ID' | 'RoomTypeID' | 'InitialAllot' | 'Adjust' | 'SysAdjust' | 'PickUp' | 'SysRelease' | 'AllotDate' | 'IsActive'>
  )> }
);

export type UpdateAllotmentMutationVariables = Exact<{
  AllotmentInput: Array<ContractAllotmentInput> | ContractAllotmentInput;
  DebtorID: Scalars['String'];
  RoomTypeID: Scalars['String'];
  HotelID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
}>;


export type UpdateAllotmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateAllotment'>
);

export type TravelAgentDeleteMutationVariables = Exact<{
  DebtorID: Scalars['String'];
  HotelID: Scalars['String'];
}>;


export type TravelAgentDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'TravelAgentDelete'>
);

export type TravelAgentInUseListingQueryVariables = Exact<{
  DebtorID: Scalars['String'];
}>;


export type TravelAgentInUseListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'TravelAgentInUseListing'>
);

export type AgentInUseLisitingQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type AgentInUseLisitingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'AgentInUseLisiting'>
);

export type AgentDeleteMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type AgentDeleteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AgentDelete'>
);

export type DebtorAnalysisQueryVariables = Exact<{
  DebtorID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type DebtorAnalysisQuery = (
  { __typename?: 'Query' }
  & { DebtorAnalysis: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID'>
    & { ContractAllotment?: Maybe<Array<(
      { __typename?: 'ContractAllotmentEntity' }
      & Pick<ContractAllotmentEntity, 'ID' | 'PickUp' | 'SysRelease' | 'AllotDate'>
    )>> }
  )> }
);

export type TravelAgentRoomRevenueQueryVariables = Exact<{
  HotelID: Scalars['String'];
  DebtorID?: Maybe<Scalars['String']>;
  DebtorType: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
}>;


export type TravelAgentRoomRevenueQuery = (
  { __typename?: 'Query' }
  & { TravelAgentRoomRevenue: Array<(
    { __typename?: 'RegistrationEntity' }
    & Pick<RegistrationEntity, 'ID' | 'ArrivalDate' | 'DepartureDate' | 'IsPrincipal'>
    & { Room?: Maybe<(
      { __typename?: 'RoomEntity' }
      & Pick<RoomEntity, 'RoomNo'>
    )>, Guest?: Maybe<(
      { __typename?: 'GuestProfileEntity' }
      & { Contact?: Maybe<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'FullName'>
      )> }
    )>, RoomLedger?: Maybe<Array<(
      { __typename?: 'RoomLedgerEntity' }
      & Pick<RoomLedgerEntity, 'ID' | 'BaseAmount' | 'TrxDate'>
    )>>, BillLedger?: Maybe<Array<(
      { __typename?: 'BillLedgerEntity' }
      & Pick<BillLedgerEntity, 'ID' | 'BaseAmount' | 'TrxDate'>
    )>>, BillSchedule?: Maybe<Array<(
      { __typename?: 'BillScheduleEntity' }
      & Pick<BillScheduleEntity, 'ID' | 'BaseAmount' | 'TrxDate'>
    )>>, Booking?: Maybe<(
      { __typename?: 'BookingEntity' }
      & Pick<BookingEntity, 'ID' | 'BookingNo' | 'CreatedDT'>
    )> }
  )> }
);


export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    name
    email
    avatar
    contactNo
    department
    accountID
    jobTitle
    lastestAccessedEntity
    superUser
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const GetUserByUsernameDocument = gql`
    query GetUserByUsername($UserName: String!) {
  GetUserByUsername(UserName: $UserName) {
    ID
    userName
  }
}
    `;

/**
 * __useGetUserByUsernameQuery__
 *
 * To run a query within a React component, call `useGetUserByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByUsernameQuery({
 *   variables: {
 *      UserName: // value for 'UserName'
 *   },
 * });
 */
export function useGetUserByUsernameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>(GetUserByUsernameDocument, baseOptions);
      }
export function useGetUserByUsernameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>(GetUserByUsernameDocument, baseOptions);
        }
export type GetUserByUsernameQueryHookResult = ReturnType<typeof useGetUserByUsernameQuery>;
export type GetUserByUsernameLazyQueryHookResult = ReturnType<typeof useGetUserByUsernameLazyQuery>;
export type GetUserByUsernameQueryResult = ApolloReactCommon.QueryResult<GetUserByUsernameQuery, GetUserByUsernameQueryVariables>;
export const UpdateLastestAccessHotelDocument = gql`
    query updateLastestAccessHotel($userIDs: String!, $hotelID: String!) {
  updateLastestAccessHotel(userIDs: $userIDs, hotelID: $hotelID) {
    ID
    name
    email
    contactNo
    department
    accountID
    jobTitle
    lastestAccessedEntity
  }
}
    `;

/**
 * __useUpdateLastestAccessHotelQuery__
 *
 * To run a query within a React component, call `useUpdateLastestAccessHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastestAccessHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateLastestAccessHotelQuery({
 *   variables: {
 *      userIDs: // value for 'userIDs'
 *      hotelID: // value for 'hotelID'
 *   },
 * });
 */
export function useUpdateLastestAccessHotelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UpdateLastestAccessHotelQuery, UpdateLastestAccessHotelQueryVariables>) {
        return ApolloReactHooks.useQuery<UpdateLastestAccessHotelQuery, UpdateLastestAccessHotelQueryVariables>(UpdateLastestAccessHotelDocument, baseOptions);
      }
export function useUpdateLastestAccessHotelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UpdateLastestAccessHotelQuery, UpdateLastestAccessHotelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UpdateLastestAccessHotelQuery, UpdateLastestAccessHotelQueryVariables>(UpdateLastestAccessHotelDocument, baseOptions);
        }
export type UpdateLastestAccessHotelQueryHookResult = ReturnType<typeof useUpdateLastestAccessHotelQuery>;
export type UpdateLastestAccessHotelLazyQueryHookResult = ReturnType<typeof useUpdateLastestAccessHotelLazyQuery>;
export type UpdateLastestAccessHotelQueryResult = ApolloReactCommon.QueryResult<UpdateLastestAccessHotelQuery, UpdateLastestAccessHotelQueryVariables>;
export const LoginDocument = gql`
    mutation login($password: String!, $loginId: String!) {
  login(password: $password, loginId: $loginId) {
    accessToken
    user {
      ID
      name
      email
      avatar
      contactNo
      superUser
      department
      accountID
      jobTitle
      lastestAccessedEntity
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      loginId: // value for 'loginId'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword) {
    name
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($softwareCode: String!, $accountID: String!, $userID: String!, $confirmPassword: String!, $password: String!) {
  createPassword(softwareCode: $softwareCode, accountID: $accountID, userID: $userID, confirmPassword: $confirmPassword, password: $password)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      softwareCode: // value for 'softwareCode'
 *      accountID: // value for 'accountID'
 *      userID: // value for 'userID'
 *      confirmPassword: // value for 'confirmPassword'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const GetRolePermissionDocument = gql`
    query getRolePermission {
  getRolePermission
}
    `;

/**
 * __useGetRolePermissionQuery__
 *
 * To run a query within a React component, call `useGetRolePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolePermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolePermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRolePermissionQuery, GetRolePermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRolePermissionQuery, GetRolePermissionQueryVariables>(GetRolePermissionDocument, baseOptions);
      }
export function useGetRolePermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRolePermissionQuery, GetRolePermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRolePermissionQuery, GetRolePermissionQueryVariables>(GetRolePermissionDocument, baseOptions);
        }
export type GetRolePermissionQueryHookResult = ReturnType<typeof useGetRolePermissionQuery>;
export type GetRolePermissionLazyQueryHookResult = ReturnType<typeof useGetRolePermissionLazyQuery>;
export type GetRolePermissionQueryResult = ApolloReactCommon.QueryResult<GetRolePermissionQuery, GetRolePermissionQueryVariables>;
export const GetHotelPermissionDocument = gql`
    query getHotelPermission {
  getHotelPermission
}
    `;

/**
 * __useGetHotelPermissionQuery__
 *
 * To run a query within a React component, call `useGetHotelPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHotelPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHotelPermissionQuery, GetHotelPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHotelPermissionQuery, GetHotelPermissionQueryVariables>(GetHotelPermissionDocument, baseOptions);
      }
export function useGetHotelPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHotelPermissionQuery, GetHotelPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHotelPermissionQuery, GetHotelPermissionQueryVariables>(GetHotelPermissionDocument, baseOptions);
        }
export type GetHotelPermissionQueryHookResult = ReturnType<typeof useGetHotelPermissionQuery>;
export type GetHotelPermissionLazyQueryHookResult = ReturnType<typeof useGetHotelPermissionLazyQuery>;
export type GetHotelPermissionQueryResult = ApolloReactCommon.QueryResult<GetHotelPermissionQuery, GetHotelPermissionQueryVariables>;
export const BookingAttachmentListingDocument = gql`
    query BookingAttachmentListing($HotelID: String, $BookingID: String, $RegistrationID: String) {
  BookingAttachmentListing(HotelID: $HotelID, BookingID: $BookingID, RegistrationID: $RegistrationID) {
    ID
    CreatedDT
    HotelID
    Remark
    BookingID
    RegistrationID
    AttachmentDetail {
      ID
      CreatedDT
      Remark
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
  }
}
    `;

/**
 * __useBookingAttachmentListingQuery__
 *
 * To run a query within a React component, call `useBookingAttachmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAttachmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAttachmentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useBookingAttachmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingAttachmentListingQuery, BookingAttachmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingAttachmentListingQuery, BookingAttachmentListingQueryVariables>(BookingAttachmentListingDocument, baseOptions);
      }
export function useBookingAttachmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingAttachmentListingQuery, BookingAttachmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingAttachmentListingQuery, BookingAttachmentListingQueryVariables>(BookingAttachmentListingDocument, baseOptions);
        }
export type BookingAttachmentListingQueryHookResult = ReturnType<typeof useBookingAttachmentListingQuery>;
export type BookingAttachmentListingLazyQueryHookResult = ReturnType<typeof useBookingAttachmentListingLazyQuery>;
export type BookingAttachmentListingQueryResult = ApolloReactCommon.QueryResult<BookingAttachmentListingQuery, BookingAttachmentListingQueryVariables>;
export const BookingAttachmentSummaryDocument = gql`
    query BookingAttachmentSummary($HotelID: String, $BookingID: String, $RegistrationID: String) {
  BookingAttachmentSummary(HotelID: $HotelID, BookingID: $BookingID, RegistrationID: $RegistrationID) {
    ID
    ModifiedDT
  }
}
    `;

/**
 * __useBookingAttachmentSummaryQuery__
 *
 * To run a query within a React component, call `useBookingAttachmentSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingAttachmentSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingAttachmentSummaryQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useBookingAttachmentSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingAttachmentSummaryQuery, BookingAttachmentSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingAttachmentSummaryQuery, BookingAttachmentSummaryQueryVariables>(BookingAttachmentSummaryDocument, baseOptions);
      }
export function useBookingAttachmentSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingAttachmentSummaryQuery, BookingAttachmentSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingAttachmentSummaryQuery, BookingAttachmentSummaryQueryVariables>(BookingAttachmentSummaryDocument, baseOptions);
        }
export type BookingAttachmentSummaryQueryHookResult = ReturnType<typeof useBookingAttachmentSummaryQuery>;
export type BookingAttachmentSummaryLazyQueryHookResult = ReturnType<typeof useBookingAttachmentSummaryLazyQuery>;
export type BookingAttachmentSummaryQueryResult = ApolloReactCommon.QueryResult<BookingAttachmentSummaryQuery, BookingAttachmentSummaryQueryVariables>;
export const BookingAttachmentInsertDocument = gql`
    mutation BookingAttachmentInsert($HotelID: String, $BookingID: String, $RegistrationID: String, $Remark: String, $AttachmentInput: [Upload!]!) {
  BookingAttachmentInsert(HotelID: $HotelID, BookingID: $BookingID, RegistrationID: $RegistrationID, Remark: $Remark, AttachmentInput: $AttachmentInput)
}
    `;
export type BookingAttachmentInsertMutationFn = ApolloReactCommon.MutationFunction<BookingAttachmentInsertMutation, BookingAttachmentInsertMutationVariables>;

/**
 * __useBookingAttachmentInsertMutation__
 *
 * To run a mutation, you first call `useBookingAttachmentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingAttachmentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingAttachmentInsertMutation, { data, loading, error }] = useBookingAttachmentInsertMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      Remark: // value for 'Remark'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useBookingAttachmentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingAttachmentInsertMutation, BookingAttachmentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingAttachmentInsertMutation, BookingAttachmentInsertMutationVariables>(BookingAttachmentInsertDocument, baseOptions);
      }
export type BookingAttachmentInsertMutationHookResult = ReturnType<typeof useBookingAttachmentInsertMutation>;
export type BookingAttachmentInsertMutationResult = ApolloReactCommon.MutationResult<BookingAttachmentInsertMutation>;
export type BookingAttachmentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingAttachmentInsertMutation, BookingAttachmentInsertMutationVariables>;
export const BookingAttachmentUpdateDocument = gql`
    mutation BookingAttachmentUpdate($HotelID: String, $BookingID: String, $BookingAttachmentID: String, $Remark: String, $AttachmentDetailsID: [String!], $AttachmentInput: [Upload!]!) {
  BookingAttachmentUpdate(HotelID: $HotelID, BookingID: $BookingID, BookingAttachmentID: $BookingAttachmentID, Remark: $Remark, AttachmentDetailsID: $AttachmentDetailsID, AttachmentInput: $AttachmentInput)
}
    `;
export type BookingAttachmentUpdateMutationFn = ApolloReactCommon.MutationFunction<BookingAttachmentUpdateMutation, BookingAttachmentUpdateMutationVariables>;

/**
 * __useBookingAttachmentUpdateMutation__
 *
 * To run a mutation, you first call `useBookingAttachmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingAttachmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingAttachmentUpdateMutation, { data, loading, error }] = useBookingAttachmentUpdateMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      BookingAttachmentID: // value for 'BookingAttachmentID'
 *      Remark: // value for 'Remark'
 *      AttachmentDetailsID: // value for 'AttachmentDetailsID'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useBookingAttachmentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingAttachmentUpdateMutation, BookingAttachmentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingAttachmentUpdateMutation, BookingAttachmentUpdateMutationVariables>(BookingAttachmentUpdateDocument, baseOptions);
      }
export type BookingAttachmentUpdateMutationHookResult = ReturnType<typeof useBookingAttachmentUpdateMutation>;
export type BookingAttachmentUpdateMutationResult = ApolloReactCommon.MutationResult<BookingAttachmentUpdateMutation>;
export type BookingAttachmentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingAttachmentUpdateMutation, BookingAttachmentUpdateMutationVariables>;
export const BookingAttachmentDeleteDocument = gql`
    mutation BookingAttachmentDelete($BookingAttachmentID: String) {
  BookingAttachmentDelete(BookingAttachmentID: $BookingAttachmentID)
}
    `;
export type BookingAttachmentDeleteMutationFn = ApolloReactCommon.MutationFunction<BookingAttachmentDeleteMutation, BookingAttachmentDeleteMutationVariables>;

/**
 * __useBookingAttachmentDeleteMutation__
 *
 * To run a mutation, you first call `useBookingAttachmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingAttachmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingAttachmentDeleteMutation, { data, loading, error }] = useBookingAttachmentDeleteMutation({
 *   variables: {
 *      BookingAttachmentID: // value for 'BookingAttachmentID'
 *   },
 * });
 */
export function useBookingAttachmentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingAttachmentDeleteMutation, BookingAttachmentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingAttachmentDeleteMutation, BookingAttachmentDeleteMutationVariables>(BookingAttachmentDeleteDocument, baseOptions);
      }
export type BookingAttachmentDeleteMutationHookResult = ReturnType<typeof useBookingAttachmentDeleteMutation>;
export type BookingAttachmentDeleteMutationResult = ApolloReactCommon.MutationResult<BookingAttachmentDeleteMutation>;
export type BookingAttachmentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingAttachmentDeleteMutation, BookingAttachmentDeleteMutationVariables>;
export const BookFormDocument = gql`
    query BookForm($HotelID: String!, $StartDate: DateTime, $EndDate: DateTime, $IsActive: Boolean) {
  SegmentListing(HotelID: $HotelID) {
    ID
    IsActive
    Description
    StayViewIcon
  }
  RoomTypeListing(HotelID: $HotelID, IsActive: $IsActive) {
    ID
    HouseLimit
    Code
    Description
    Overbooking
  }
  PromotionListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    Code
    Description
    DiscountType
    DiscountValue
    PromotionDetail {
      ID
      RatePolicyID
      Value
    }
  }
  HotelSalesChannelListing(HotelID: $HotelID) {
    ID
    Description
    IsActive
  }
  DebtorListing(HotelID: $HotelID) {
    ID
    IsActive
    IsFrontDeskActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    DebtorCategory
    Name
    DebtorAccount
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    SalesChannel
    Remarks
    Segment
    Login
    Password
    IseCommerce
  }
  ChannelManagerProfile(HotelID: $HotelID) {
    ID
    Name
  }
}
    `;

/**
 * __useBookFormQuery__
 *
 * To run a query within a React component, call `useBookFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookFormQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useBookFormQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookFormQuery, BookFormQueryVariables>) {
        return ApolloReactHooks.useQuery<BookFormQuery, BookFormQueryVariables>(BookFormDocument, baseOptions);
      }
export function useBookFormLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookFormQuery, BookFormQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookFormQuery, BookFormQueryVariables>(BookFormDocument, baseOptions);
        }
export type BookFormQueryHookResult = ReturnType<typeof useBookFormQuery>;
export type BookFormLazyQueryHookResult = ReturnType<typeof useBookFormLazyQuery>;
export type BookFormQueryResult = ApolloReactCommon.QueryResult<BookFormQuery, BookFormQueryVariables>;
export const CancelReasonListingDocument = gql`
    query CancelReasonListing($HotelID: String!) {
  CancelReasonListing(HotelID: $HotelID) {
    ID
    Description
    Code
  }
}
    `;

/**
 * __useCancelReasonListingQuery__
 *
 * To run a query within a React component, call `useCancelReasonListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelReasonListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelReasonListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCancelReasonListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CancelReasonListingQuery, CancelReasonListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CancelReasonListingQuery, CancelReasonListingQueryVariables>(CancelReasonListingDocument, baseOptions);
      }
export function useCancelReasonListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CancelReasonListingQuery, CancelReasonListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CancelReasonListingQuery, CancelReasonListingQueryVariables>(CancelReasonListingDocument, baseOptions);
        }
export type CancelReasonListingQueryHookResult = ReturnType<typeof useCancelReasonListingQuery>;
export type CancelReasonListingLazyQueryHookResult = ReturnType<typeof useCancelReasonListingLazyQuery>;
export type CancelReasonListingQueryResult = ApolloReactCommon.QueryResult<CancelReasonListingQuery, CancelReasonListingQueryVariables>;
export const ShowBookingConfirmationEmailDocument = gql`
    query ShowBookingConfirmationEmail($BookingID: String!, $HotelID: String!) {
  ShowBookingConfirmationEmail(BookingID: $BookingID, HotelID: $HotelID)
}
    `;

/**
 * __useShowBookingConfirmationEmailQuery__
 *
 * To run a query within a React component, call `useShowBookingConfirmationEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowBookingConfirmationEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowBookingConfirmationEmailQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useShowBookingConfirmationEmailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ShowBookingConfirmationEmailQuery, ShowBookingConfirmationEmailQueryVariables>) {
        return ApolloReactHooks.useQuery<ShowBookingConfirmationEmailQuery, ShowBookingConfirmationEmailQueryVariables>(ShowBookingConfirmationEmailDocument, baseOptions);
      }
export function useShowBookingConfirmationEmailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ShowBookingConfirmationEmailQuery, ShowBookingConfirmationEmailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ShowBookingConfirmationEmailQuery, ShowBookingConfirmationEmailQueryVariables>(ShowBookingConfirmationEmailDocument, baseOptions);
        }
export type ShowBookingConfirmationEmailQueryHookResult = ReturnType<typeof useShowBookingConfirmationEmailQuery>;
export type ShowBookingConfirmationEmailLazyQueryHookResult = ReturnType<typeof useShowBookingConfirmationEmailLazyQuery>;
export type ShowBookingConfirmationEmailQueryResult = ApolloReactCommon.QueryResult<ShowBookingConfirmationEmailQuery, ShowBookingConfirmationEmailQueryVariables>;
export const BookingConfirmationTemplateDocument = gql`
    query BookingConfirmationTemplate($BookingID: String!, $TemplateID: String) {
  BookingConfirmationTemplate(BookingID: $BookingID, TemplateID: $TemplateID)
}
    `;

/**
 * __useBookingConfirmationTemplateQuery__
 *
 * To run a query within a React component, call `useBookingConfirmationTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingConfirmationTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingConfirmationTemplateQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      TemplateID: // value for 'TemplateID'
 *   },
 * });
 */
export function useBookingConfirmationTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingConfirmationTemplateQuery, BookingConfirmationTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingConfirmationTemplateQuery, BookingConfirmationTemplateQueryVariables>(BookingConfirmationTemplateDocument, baseOptions);
      }
export function useBookingConfirmationTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingConfirmationTemplateQuery, BookingConfirmationTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingConfirmationTemplateQuery, BookingConfirmationTemplateQueryVariables>(BookingConfirmationTemplateDocument, baseOptions);
        }
export type BookingConfirmationTemplateQueryHookResult = ReturnType<typeof useBookingConfirmationTemplateQuery>;
export type BookingConfirmationTemplateLazyQueryHookResult = ReturnType<typeof useBookingConfirmationTemplateLazyQuery>;
export type BookingConfirmationTemplateQueryResult = ApolloReactCommon.QueryResult<BookingConfirmationTemplateQuery, BookingConfirmationTemplateQueryVariables>;
export const BookingCancelReasonListingDocument = gql`
    query BookingCancelReasonListing($HotelID: String!) {
  BookingCancelReasonListing(HotelID: $HotelID) {
    ID
    Description
    Code
    Department {
      ID
      Code
      Name
    }
  }
}
    `;

/**
 * __useBookingCancelReasonListingQuery__
 *
 * To run a query within a React component, call `useBookingCancelReasonListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingCancelReasonListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingCancelReasonListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBookingCancelReasonListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingCancelReasonListingQuery, BookingCancelReasonListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingCancelReasonListingQuery, BookingCancelReasonListingQueryVariables>(BookingCancelReasonListingDocument, baseOptions);
      }
export function useBookingCancelReasonListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingCancelReasonListingQuery, BookingCancelReasonListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingCancelReasonListingQuery, BookingCancelReasonListingQueryVariables>(BookingCancelReasonListingDocument, baseOptions);
        }
export type BookingCancelReasonListingQueryHookResult = ReturnType<typeof useBookingCancelReasonListingQuery>;
export type BookingCancelReasonListingLazyQueryHookResult = ReturnType<typeof useBookingCancelReasonListingLazyQuery>;
export type BookingCancelReasonListingQueryResult = ApolloReactCommon.QueryResult<BookingCancelReasonListingQuery, BookingCancelReasonListingQueryVariables>;
export const GuestProfileListingByIdDocument = gql`
    query GuestProfileListingByID($RegistrationIDs: [String!]!) {
  GuestProfileListingByID(RegistrationIDs: $RegistrationIDs) {
    ID
    RoomTypeID
    RatePolicyID
    RoomID
    ArrivalDate
    DepartureDate
    RegistrationStatus
    RoomType {
      MaxGuest
    }
    BookingID
    IsPrincipal
    SharerNo
    IsTTx
    TTxReasonID
    Guest {
      ID
      IdPicture
      TTxReasonID
      IsTTx
      ICGallery {
        ID
        ImageURL
      }
      GuestHistoryList {
        ID
        Booking {
          ArrivalDate
          DepartureDate
          IsCmsTtx
        }
        RoomType {
          ID
          Code
        }
        Room {
          ID
          RoomNo
        }
        RatePolicy {
          ID
          RateCode
        }
        RoomRate
        TotalRoomPrice
        TotalRoomTax
        TotalPackagePrice
        TotalPrice
        RegistrationStatus
      }
      GuestHistoryCount
      VehicleNo
      GuestType
      NewsLetter
      IsVIP
      Contact {
        ID
        FullName
        BirthDate
        Email
        MobileNo
        NRIC
        PassportNo
        Nationality
        Designation
        Address {
          address
          country
          city
          state
          postCode
        }
      }
    }
    TaxLedger {
      ID
      TrxDate
      TaxType
      TaxDescription
      FolioID
      ReversedTaxLedgerID
      IsGLPosted
      FromRegistration
      IsAdjustment
    }
  }
}
    `;

/**
 * __useGuestProfileListingByIdQuery__
 *
 * To run a query within a React component, call `useGuestProfileListingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileListingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestProfileListingByIdQuery({
 *   variables: {
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *   },
 * });
 */
export function useGuestProfileListingByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestProfileListingByIdQuery, GuestProfileListingByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestProfileListingByIdQuery, GuestProfileListingByIdQueryVariables>(GuestProfileListingByIdDocument, baseOptions);
      }
export function useGuestProfileListingByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestProfileListingByIdQuery, GuestProfileListingByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestProfileListingByIdQuery, GuestProfileListingByIdQueryVariables>(GuestProfileListingByIdDocument, baseOptions);
        }
export type GuestProfileListingByIdQueryHookResult = ReturnType<typeof useGuestProfileListingByIdQuery>;
export type GuestProfileListingByIdLazyQueryHookResult = ReturnType<typeof useGuestProfileListingByIdLazyQuery>;
export type GuestProfileListingByIdQueryResult = ApolloReactCommon.QueryResult<GuestProfileListingByIdQuery, GuestProfileListingByIdQueryVariables>;
export const BookingListingDocument = gql`
    query BookingListing($HotelID: String, $DebtorType: String!, $BookingStatus: String, $ArrStartDate: DateTime, $ArrEndDate: DateTime, $DepartStartDate: DateTime, $DepartEndDate: DateTime, $BookingDate: DateTime, $offset: Float, $limit: Float, $BookingNo: String, $FullName: String, $AccountName: String, $ReferenceNo: String, $RoomTypeID: String, $GroupName: String, $SortBy: String, $SortByOrder: String, $SearchValue: String) {
  BookingListing(HotelID: $HotelID, DebtorType: $DebtorType, BookingStatus: $BookingStatus, ArrStartDate: $ArrStartDate, ArrEndDate: $ArrEndDate, DepartStartDate: $DepartStartDate, DepartEndDate: $DepartEndDate, BookingDate: $BookingDate, offset: $offset, limit: $limit, BookingNo: $BookingNo, FullName: $FullName, AccountName: $AccountName, ReferenceNo: $ReferenceNo, RoomTypeID: $RoomTypeID, GroupName: $GroupName, SortBy: $SortBy, SortByOrder: $SortByOrder, SearchValue: $SearchValue) {
    ID
    AccountID
    HotelID
    ChargeToCityLedger
    TotalAdvPayment
    Hotel {
      HotelName
      HotelAddress {
        address
        address2
        siteName
        country
        city
        state
        postCode
      }
    }
    IsGroupBooking
    Contact {
      FullName
      BirthDate
      PhoneNo
      Email
      Nationality
      Address {
        address
        country
        city
        state
        postCode
      }
      ID
      MobileNo
      PassportNo
      NRIC
    }
    Debtor {
      DebtorCategory
      ID
      Name
      IseCommerce
    }
    CreatedDT
    BookingNo
    IsCmsTtx
    ArrivalDate
    DepartureDate
    ContactID
    ReferenceNo
    Contact {
      ID
      FullName
      BirthDate
      Email
      MobileNo
      PhoneNo
      PassportNo
      NRIC
      Address {
        address
        country
        city
        state
        postCode
      }
    }
    GroupName
    SalesChannelID
    SalesChannel {
      ID
      Description
    }
    Source
    SegmentID
    Segment {
      ID
      Description
    }
    SpecialRequest
    DebtorAccount
    BillingInstruction
    BookingRemark
    Reason
    BookingStatus
    AllRegistration {
      ID
      Room {
        RoomNo
      }
      ArrivalDate
      DepartureDate
      Guest {
        Contact {
          FullName
          Email
          PhoneNo
          Address {
            address
            siteName
            address2
            country
            city
            state
            postCode
            AddressType
          }
        }
      }
    }
    BookedRegistration {
      ID
      RoomID
      CheckInDate
      SharerNo
      CheckOutDate
      ArrivalDate
      DepartureDate
      PromoCode
      NoOfAdult
      NoOfChild
      NoOfInfant
      IsPrincipal
      Signature
      SpecialRequest
      RegistrationStatus
      RoomTypeID
      RatePolicyID
      RoomType {
        Code
        ID
        Overbooking
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      Guest {
        ID
        VehicleNo
        Contact {
          ID
          FullName
          BirthDate
          Email
          NRIC
          PhoneNo
          MobileNo
        }
      }
      RoomRate
      TotalRoomPrice
      TotalRoomTax
      TotalPackagePrice
      TotalPrice
      PrintRate
      IncidentalChargesSum
    }
  }
}
    `;

/**
 * __useBookingListingQuery__
 *
 * To run a query within a React component, call `useBookingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *      BookingStatus: // value for 'BookingStatus'
 *      ArrStartDate: // value for 'ArrStartDate'
 *      ArrEndDate: // value for 'ArrEndDate'
 *      DepartStartDate: // value for 'DepartStartDate'
 *      DepartEndDate: // value for 'DepartEndDate'
 *      BookingDate: // value for 'BookingDate'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      BookingNo: // value for 'BookingNo'
 *      FullName: // value for 'FullName'
 *      AccountName: // value for 'AccountName'
 *      ReferenceNo: // value for 'ReferenceNo'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      GroupName: // value for 'GroupName'
 *      SortBy: // value for 'SortBy'
 *      SortByOrder: // value for 'SortByOrder'
 *      SearchValue: // value for 'SearchValue'
 *   },
 * });
 */
export function useBookingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingListingQuery, BookingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingListingQuery, BookingListingQueryVariables>(BookingListingDocument, baseOptions);
      }
export function useBookingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingListingQuery, BookingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingListingQuery, BookingListingQueryVariables>(BookingListingDocument, baseOptions);
        }
export type BookingListingQueryHookResult = ReturnType<typeof useBookingListingQuery>;
export type BookingListingLazyQueryHookResult = ReturnType<typeof useBookingListingLazyQuery>;
export type BookingListingQueryResult = ApolloReactCommon.QueryResult<BookingListingQuery, BookingListingQueryVariables>;
export const BookingDetailsDocument = gql`
    query BookingDetails($ID: String!, $HotelID: String!) {
  BookingDetails(ID: $ID, HotelID: $HotelID) {
    ID
    AccountID
    HotelID
    TotalAdvPayment
    AdvPaymentIsAdjust
    IsCms
    Hotel {
      HotelName
      CheckOutTime
      IsFloorPlan
      HotelAddress {
        address
        address2
        siteName
        country
        city
        state
        postCode
      }
    }
    CreatedDT
    Contact {
      FullName
      BirthDate
      PhoneNo
      MobileNo
      Email
      Nationality
      Designation
      Address {
        address
        country
        city
        state
        postCode
      }
      ID
      PassportNo
      NRIC
    }
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
      CreditLimit
      IseCommerce
    }
    BookingNo
    ArrivalDate
    DepartureDate
    IsCmsTtx
    ContactID
    Contact {
      ID
      FullName
      BirthDate
      Email
      MobileNo
      PhoneNo
      PassportNo
      NRIC
      Address {
        address
        country
        city
        state
        postCode
      }
    }
    GroupName
    IsGroupBooking
    ChargeToCityLedger
    SalesChannelID
    SalesChannel {
      ID
      Description
    }
    Source
    SegmentID
    Segment {
      ID
      Description
    }
    SpecialRequest
    DebtorAccount
    BillingInstruction
    BookingRemark
    Reason
    Remark
    ReferenceNo
    BookingStatus
    BookedRegistration {
      ID
      RoomID
      ArrivalDate
      DepartureDate
      CheckInDate
      SharerNo
      CheckOutDate
      PromoCode
      NoOfAdult
      DiscountAmount
      AdvPaymentSum
      NoOfChild
      NoOfInfant
      IsPrincipal
      HouseLimit
      Signature
      BillSchedule {
        ID
        TrxAmount
        ChargeType
        RateID
      }
      RoomLedger {
        ID
        ChargeType
      }
      AdvancePayment {
        ID
      }
      Payment {
        ID
      }
      BillLedger {
        ID
        Description
        TrxDate
      }
      SpecialRequest
      RegistrationStatus
      FolioCount
      ServiceRequestCount
      PaymentSum
      IncidentalChargesCount
      IncidentalChargesSum
      RoomTypeID
      ChargeRoomTypeID
      RatePolicyID
      Reason
      Remark
      ModifiedBy
      ModifiedDT
      RoomType {
        Code
        MaxGuest
        ID
        Overbooking
      }
      Room {
        RoomNo
        ID
        HKRoomStatus
      }
      RatePolicy {
        RateCode
        ID
        Description
        Category
      }
      BookingID
      Booking {
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        IsCms
        isPrepaid
        IsCmsTtx
        Contact {
          FullName
        }
        Debtor {
          Name
          ID
          DebtorCategory
          DebtorAccount
        }
      }
      Guest {
        ID
        VehicleNo
        IdPicture
        GuestType
        ICGallery {
          ID
          ImageURL
        }
        Contact {
          ID
          FullName
          BirthDate
          Email
          NRIC
          PhoneNo
          MobileNo
          Address {
            address
            country
            city
            state
            postCode
          }
        }
      }
      RoomRate
      TotalRoomPrice
      TotalOutStandingAmount
      RoomOutStanding
      TotalRoomTax
      TotalPackagePrice
      TotalPrice
      DepositSum
      PrintRate
      IsRoomUpgrade
    }
    AllRegistration {
      ID
      RoomID
      CheckInDate
      SharerNo
      CheckOutDate
      PromoCode
      NoOfAdult
      DiscountAmount
      NoOfChild
      NoOfInfant
      IsPrincipal
      IsPreCheckIn
      IsEmailBlasted
      HouseLimit
      Signature
      SpecialRequest
      RegistrationStatus
      IncidentalChargesCount
      RoomTypeID
      RatePolicyID
      DepartureDate
      RoomType {
        Code
        MaxGuest
        ID
      }
      Room {
        RoomNo
        ID
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      BookingID
      Booking {
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        Contact {
          FullName
        }
        Debtor {
          Name
          ID
          DebtorCategory
          DebtorAccount
        }
      }
      Guest {
        ID
        VehicleNo
        IdPicture
        GuestType
        ICGallery {
          ID
          ImageURL
        }
        Contact {
          ID
          FullName
          Email
          NRIC
          PhoneNo
          MobileNo
          Address {
            address
            country
            city
            state
            postCode
          }
        }
      }
      RoomRate
      TotalRoomPrice
      TotalOutStandingAmount
      TotalRoomTax
      TotalPackagePrice
      TotalPrice
      DepositSum
      IsRoomUpgrade
    }
  }
}
    `;

/**
 * __useBookingDetailsQuery__
 *
 * To run a query within a React component, call `useBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBookingDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingDetailsQuery, BookingDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingDetailsQuery, BookingDetailsQueryVariables>(BookingDetailsDocument, baseOptions);
      }
export function useBookingDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingDetailsQuery, BookingDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingDetailsQuery, BookingDetailsQueryVariables>(BookingDetailsDocument, baseOptions);
        }
export type BookingDetailsQueryHookResult = ReturnType<typeof useBookingDetailsQuery>;
export type BookingDetailsLazyQueryHookResult = ReturnType<typeof useBookingDetailsLazyQuery>;
export type BookingDetailsQueryResult = ApolloReactCommon.QueryResult<BookingDetailsQuery, BookingDetailsQueryVariables>;
export const IncChargeBookingDetailsDocument = gql`
    query IncChargeBookingDetails($ID: String!, $HotelID: String!) {
  BookingDetails(ID: $ID, HotelID: $HotelID) {
    ID
    AccountID
    HotelID
    TotalAdvPayment
    BookingNo
    ArrivalDate
    DepartureDate
    BookedRegistration {
      ID
      IsPrincipal
      RegistrationStatus
      IncidentalChargesSum
      TotalRoomPrice
      TotalPrice
    }
  }
}
    `;

/**
 * __useIncChargeBookingDetailsQuery__
 *
 * To run a query within a React component, call `useIncChargeBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncChargeBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncChargeBookingDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useIncChargeBookingDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IncChargeBookingDetailsQuery, IncChargeBookingDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<IncChargeBookingDetailsQuery, IncChargeBookingDetailsQueryVariables>(IncChargeBookingDetailsDocument, baseOptions);
      }
export function useIncChargeBookingDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IncChargeBookingDetailsQuery, IncChargeBookingDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IncChargeBookingDetailsQuery, IncChargeBookingDetailsQueryVariables>(IncChargeBookingDetailsDocument, baseOptions);
        }
export type IncChargeBookingDetailsQueryHookResult = ReturnType<typeof useIncChargeBookingDetailsQuery>;
export type IncChargeBookingDetailsLazyQueryHookResult = ReturnType<typeof useIncChargeBookingDetailsLazyQuery>;
export type IncChargeBookingDetailsQueryResult = ApolloReactCommon.QueryResult<IncChargeBookingDetailsQuery, IncChargeBookingDetailsQueryVariables>;
export const MiniBookingDetailsDocument = gql`
    query MiniBookingDetails($ID: String!, $HotelID: String!) {
  BookingDetails(ID: $ID, HotelID: $HotelID) {
    ID
    AccountID
    HotelID
    TotalAdvPayment
    IsCms
    Hotel {
      HotelName
      CheckOutTime
      IsFloorPlan
      HotelAddress {
        address
        address2
        siteName
        country
        city
        state
        postCode
      }
    }
    CreatedDT
    Contact {
      FullName
      BirthDate
      PhoneNo
      MobileNo
      Email
      Nationality
      Designation
      Address {
        address
        country
        city
        state
        postCode
      }
    }
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
      CreditLimit
    }
    BookingNo
    ArrivalDate
    DepartureDate
    IsCmsTtx
    isPrepaid
    ContactID
    GroupName
    IsGroupBooking
    ChargeToCityLedger
    SalesChannelID
    Source
    SegmentID
    SpecialRequest
    DebtorAccount
    BillingInstruction
    BookingRemark
    Reason
    Remark
    ReferenceNo
    BookingStatus
    BookedRegistration {
      ID
      RoomID
      ArrivalDate
      DepartureDate
      CheckInDate
      SharerNo
      CheckOutDate
      PromoCode
      NoOfAdult
      DiscountAmount
      NoOfChild
      NoOfInfant
      IsPrincipal
      HouseLimit
      Signature
      SpecialRequest
      RegistrationStatus
      RoomTypeID
      ChargeRoomTypeID
      RatePolicyID
      Reason
      Remark
      ModifiedBy
      ModifiedDT
      RoomType {
        Code
        MaxGuest
        ID
        Overbooking
      }
      Room {
        RoomNo
        ID
        HKRoomStatus
      }
      RatePolicy {
        RateCode
        ID
        Description
        Category
      }
      BookingID
      Booking {
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        isPrepaid
        IsCms
        IsCmsTtx
        Contact {
          FullName
        }
        Debtor {
          Name
          ID
          DebtorCategory
          DebtorAccount
        }
      }
      Guest {
        ID
        VehicleNo
        IdPicture
        GuestType
        ICGallery {
          ID
          ImageURL
        }
        Contact {
          ID
          FullName
          BirthDate
          Email
          NRIC
          PhoneNo
          MobileNo
          Address {
            address
            country
            city
            state
            postCode
          }
        }
        GuestHistoryCount
      }
      RoomRate
      TotalRoomPrice
      DepositSum
      PrintRate
      IsRoomUpgrade
    }
    AllRegistration {
      ID
      RoomID
      CheckInDate
      SharerNo
      CheckOutDate
      PromoCode
      NoOfAdult
      DiscountAmount
      NoOfChild
      NoOfInfant
      IsPrincipal
      IsPreCheckIn
      IsEmailBlasted
      HouseLimit
      Signature
      SpecialRequest
      RegistrationStatus
      IncidentalChargesCount
      RoomTypeID
      RatePolicyID
      DepartureDate
      RoomType {
        Code
        MaxGuest
        ID
      }
      Room {
        RoomNo
        ID
      }
      BookingID
      Booking {
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        Contact {
          FullName
        }
        Debtor {
          Name
          ID
          DebtorCategory
          DebtorAccount
        }
      }
      Guest {
        ID
        VehicleNo
        IdPicture
        GuestType
        ICGallery {
          ID
          ImageURL
        }
        Contact {
          ID
          FullName
          Email
          NRIC
          PhoneNo
          MobileNo
          Address {
            address
            country
            city
            state
            postCode
          }
        }
        GuestHistoryCount
      }
      RoomRate
      IsRoomUpgrade
    }
  }
}
    `;

/**
 * __useMiniBookingDetailsQuery__
 *
 * To run a query within a React component, call `useMiniBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMiniBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMiniBookingDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useMiniBookingDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MiniBookingDetailsQuery, MiniBookingDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<MiniBookingDetailsQuery, MiniBookingDetailsQueryVariables>(MiniBookingDetailsDocument, baseOptions);
      }
export function useMiniBookingDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MiniBookingDetailsQuery, MiniBookingDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MiniBookingDetailsQuery, MiniBookingDetailsQueryVariables>(MiniBookingDetailsDocument, baseOptions);
        }
export type MiniBookingDetailsQueryHookResult = ReturnType<typeof useMiniBookingDetailsQuery>;
export type MiniBookingDetailsLazyQueryHookResult = ReturnType<typeof useMiniBookingDetailsLazyQuery>;
export type MiniBookingDetailsQueryResult = ApolloReactCommon.QueryResult<MiniBookingDetailsQuery, MiniBookingDetailsQueryVariables>;
export const AvailabilityListingDocument = gql`
    query AvailabilityListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime, $Adddate: Float, $RoomTypeIDs: [String!], $TravelAgentID: String) {
  AvailabilityListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, Adddate: $Adddate, RoomTypeIDs: $RoomTypeIDs, TravelAgentID: $TravelAgentID) {
    RoomTypesList {
      ID
      Code
      Description
      TotalRoom
      Overbooking
      RoomTypeAvailability {
        ID
        EffectiveDate
        BookedRoom
        BlockedRoom
        AllottedRoom
        TotalOccupied
        TotalVacant
        SeasonType
        TotalVacantShow
        Waitlist
      }
    }
    Occupancy {
      EffectiveDate
      Season
      Occupancy
      TotalOccupied
      TotalRoom
      TotalVacant
      TotalVacantShow
      TotalBlock
    }
  }
}
    `;

/**
 * __useAvailabilityListingQuery__
 *
 * To run a query within a React component, call `useAvailabilityListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      Adddate: // value for 'Adddate'
 *      RoomTypeIDs: // value for 'RoomTypeIDs'
 *      TravelAgentID: // value for 'TravelAgentID'
 *   },
 * });
 */
export function useAvailabilityListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailabilityListingQuery, AvailabilityListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailabilityListingQuery, AvailabilityListingQueryVariables>(AvailabilityListingDocument, baseOptions);
      }
export function useAvailabilityListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailabilityListingQuery, AvailabilityListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailabilityListingQuery, AvailabilityListingQueryVariables>(AvailabilityListingDocument, baseOptions);
        }
export type AvailabilityListingQueryHookResult = ReturnType<typeof useAvailabilityListingQuery>;
export type AvailabilityListingLazyQueryHookResult = ReturnType<typeof useAvailabilityListingLazyQuery>;
export type AvailabilityListingQueryResult = ApolloReactCommon.QueryResult<AvailabilityListingQuery, AvailabilityListingQueryVariables>;
export const PackagePriceListingDocument = gql`
    query PackagePriceListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String, $DebtorID: String, $RoomTypeID: String, $RatePolicyID: String, $NoOfRoom: Float) {
  PackagePriceListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType, DebtorID: $DebtorID, RoomTypeID: $RoomTypeID, RatePolicyID: $RatePolicyID, NoOfRoom: $NoOfRoom) {
    RoomTypeID
    RoomTypeCode
    RoomTypeName
    RatePolicyID
    RateFrequency
    Code
    TotalVacant
    Pricing {
      Date
      Type
      Price
      BasePrice
      Packages {
        ID
        IncidentalChargeID
        Description
        IncludeRate
        ComputationRules
        ServiceCharge
        Tax
        Amount
      }
    }
  }
}
    `;

/**
 * __usePackagePriceListingQuery__
 *
 * To run a query within a React component, call `usePackagePriceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagePriceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagePriceListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *      DebtorID: // value for 'DebtorID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      RatePolicyID: // value for 'RatePolicyID'
 *      NoOfRoom: // value for 'NoOfRoom'
 *   },
 * });
 */
export function usePackagePriceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PackagePriceListingQuery, PackagePriceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PackagePriceListingQuery, PackagePriceListingQueryVariables>(PackagePriceListingDocument, baseOptions);
      }
export function usePackagePriceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PackagePriceListingQuery, PackagePriceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PackagePriceListingQuery, PackagePriceListingQueryVariables>(PackagePriceListingDocument, baseOptions);
        }
export type PackagePriceListingQueryHookResult = ReturnType<typeof usePackagePriceListingQuery>;
export type PackagePriceListingLazyQueryHookResult = ReturnType<typeof usePackagePriceListingLazyQuery>;
export type PackagePriceListingQueryResult = ApolloReactCommon.QueryResult<PackagePriceListingQuery, PackagePriceListingQueryVariables>;
export const BookingPackagePriceListingDocument = gql`
    query BookingPackagePriceListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String, $DebtorID: String, $RoomTypeID: [String!], $RatePolicyID: String, $NoOfRoom: Float) {
  BookingPackagePriceListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType, DebtorID: $DebtorID, RoomTypeID: $RoomTypeID, RatePolicyID: $RatePolicyID, NoOfRoom: $NoOfRoom) {
    RoomTypeID
    RoomTypeCode
    RoomTypeName
    RatePolicyID
    Code
    TotalVacant
    Pricing {
      Date
      Type
      Price
      BasePrice
      Packages {
        ID
        IncidentalChargeID
        Description
        IncludeRate
        ComputationRules
        ServiceCharge
        Tax
        Amount
      }
    }
  }
}
    `;

/**
 * __useBookingPackagePriceListingQuery__
 *
 * To run a query within a React component, call `useBookingPackagePriceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingPackagePriceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingPackagePriceListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *      DebtorID: // value for 'DebtorID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      RatePolicyID: // value for 'RatePolicyID'
 *      NoOfRoom: // value for 'NoOfRoom'
 *   },
 * });
 */
export function useBookingPackagePriceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingPackagePriceListingQuery, BookingPackagePriceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingPackagePriceListingQuery, BookingPackagePriceListingQueryVariables>(BookingPackagePriceListingDocument, baseOptions);
      }
export function useBookingPackagePriceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingPackagePriceListingQuery, BookingPackagePriceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingPackagePriceListingQuery, BookingPackagePriceListingQueryVariables>(BookingPackagePriceListingDocument, baseOptions);
        }
export type BookingPackagePriceListingQueryHookResult = ReturnType<typeof useBookingPackagePriceListingQuery>;
export type BookingPackagePriceListingLazyQueryHookResult = ReturnType<typeof useBookingPackagePriceListingLazyQuery>;
export type BookingPackagePriceListingQueryResult = ApolloReactCommon.QueryResult<BookingPackagePriceListingQuery, BookingPackagePriceListingQueryVariables>;
export const AdvancePaymentListingDocument = gql`
    query AdvancePaymentListing($BookingID: String!) {
  AdvancePaymentListing(BookingID: $BookingID) {
    ID
    PaymentDate
    HotelID
    BookingID
    RegistrationID
    PaymentClass
    ReceiptNo
    PaymentType
    DebtorAccount
    PaySessionNo
    CheckOutID
    Amount
    ReferenceNo
    Remark
    PaymentStatus
    IsPosted
    ReversedAdvancePaymentID
    CreatedBy
  }
}
    `;

/**
 * __useAdvancePaymentListingQuery__
 *
 * To run a query within a React component, call `useAdvancePaymentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancePaymentListingQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useAdvancePaymentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvancePaymentListingQuery, AdvancePaymentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvancePaymentListingQuery, AdvancePaymentListingQueryVariables>(AdvancePaymentListingDocument, baseOptions);
      }
export function useAdvancePaymentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvancePaymentListingQuery, AdvancePaymentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvancePaymentListingQuery, AdvancePaymentListingQueryVariables>(AdvancePaymentListingDocument, baseOptions);
        }
export type AdvancePaymentListingQueryHookResult = ReturnType<typeof useAdvancePaymentListingQuery>;
export type AdvancePaymentListingLazyQueryHookResult = ReturnType<typeof useAdvancePaymentListingLazyQuery>;
export type AdvancePaymentListingQueryResult = ApolloReactCommon.QueryResult<AdvancePaymentListingQuery, AdvancePaymentListingQueryVariables>;
export const InhousePaymentListingDocument = gql`
    query InhousePaymentListing($RegistrationID: String!) {
  InhousePaymentListing(RegistrationID: $RegistrationID) {
    ID
    PaymentDate
    FolioID
    HotelID
    RegistrationID
    PaymentClass
    ReceiptNo
    PaymentType
    DebtorAccount
    PaySessionNo
    CheckOutID
    Amount
    ReferenceNo
    Remark
    PaymentStatus
    ReversedPaymentID
    CreatedBy
    ModifiedBy
  }
}
    `;

/**
 * __useInhousePaymentListingQuery__
 *
 * To run a query within a React component, call `useInhousePaymentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInhousePaymentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInhousePaymentListingQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useInhousePaymentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InhousePaymentListingQuery, InhousePaymentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<InhousePaymentListingQuery, InhousePaymentListingQueryVariables>(InhousePaymentListingDocument, baseOptions);
      }
export function useInhousePaymentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InhousePaymentListingQuery, InhousePaymentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InhousePaymentListingQuery, InhousePaymentListingQueryVariables>(InhousePaymentListingDocument, baseOptions);
        }
export type InhousePaymentListingQueryHookResult = ReturnType<typeof useInhousePaymentListingQuery>;
export type InhousePaymentListingLazyQueryHookResult = ReturnType<typeof useInhousePaymentListingLazyQuery>;
export type InhousePaymentListingQueryResult = ApolloReactCommon.QueryResult<InhousePaymentListingQuery, InhousePaymentListingQueryVariables>;
export const InhouseRefundListingDocument = gql`
    query InhouseRefundListing($BookingID: String!) {
  InhouseRefundListing(BookingID: $BookingID) {
    ID
    PaymentDate
    FolioID
    HotelID
    RegistrationID
    PaymentClass
    ReceiptNo
    PaymentType
    DebtorAccount
    PaySessionNo
    CheckOutID
    Amount
    ReferenceNo
    Remark
    PaymentStatus
    ReversedPaymentID
    CreatedBy
    ModifiedBy
  }
}
    `;

/**
 * __useInhouseRefundListingQuery__
 *
 * To run a query within a React component, call `useInhouseRefundListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInhouseRefundListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInhouseRefundListingQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useInhouseRefundListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InhouseRefundListingQuery, InhouseRefundListingQueryVariables>) {
        return ApolloReactHooks.useQuery<InhouseRefundListingQuery, InhouseRefundListingQueryVariables>(InhouseRefundListingDocument, baseOptions);
      }
export function useInhouseRefundListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InhouseRefundListingQuery, InhouseRefundListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InhouseRefundListingQuery, InhouseRefundListingQueryVariables>(InhouseRefundListingDocument, baseOptions);
        }
export type InhouseRefundListingQueryHookResult = ReturnType<typeof useInhouseRefundListingQuery>;
export type InhouseRefundListingLazyQueryHookResult = ReturnType<typeof useInhouseRefundListingLazyQuery>;
export type InhouseRefundListingQueryResult = ApolloReactCommon.QueryResult<InhouseRefundListingQuery, InhouseRefundListingQueryVariables>;
export const ChangeOfDateCheckAvailabilityDocument = gql`
    query ChangeOfDateCheckAvailability($BookingID: String!, $HotelID: String!, $ArrivalDate: DateTime!, $DepartureDate: DateTime!) {
  ChangeOfDateCheckAvailability(BookingID: $BookingID, HotelID: $HotelID, ArrivalDate: $ArrivalDate, DepartureDate: $DepartureDate)
}
    `;

/**
 * __useChangeOfDateCheckAvailabilityQuery__
 *
 * To run a query within a React component, call `useChangeOfDateCheckAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeOfDateCheckAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeOfDateCheckAvailabilityQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      DepartureDate: // value for 'DepartureDate'
 *   },
 * });
 */
export function useChangeOfDateCheckAvailabilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChangeOfDateCheckAvailabilityQuery, ChangeOfDateCheckAvailabilityQueryVariables>) {
        return ApolloReactHooks.useQuery<ChangeOfDateCheckAvailabilityQuery, ChangeOfDateCheckAvailabilityQueryVariables>(ChangeOfDateCheckAvailabilityDocument, baseOptions);
      }
export function useChangeOfDateCheckAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChangeOfDateCheckAvailabilityQuery, ChangeOfDateCheckAvailabilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChangeOfDateCheckAvailabilityQuery, ChangeOfDateCheckAvailabilityQueryVariables>(ChangeOfDateCheckAvailabilityDocument, baseOptions);
        }
export type ChangeOfDateCheckAvailabilityQueryHookResult = ReturnType<typeof useChangeOfDateCheckAvailabilityQuery>;
export type ChangeOfDateCheckAvailabilityLazyQueryHookResult = ReturnType<typeof useChangeOfDateCheckAvailabilityLazyQuery>;
export type ChangeOfDateCheckAvailabilityQueryResult = ApolloReactCommon.QueryResult<ChangeOfDateCheckAvailabilityQuery, ChangeOfDateCheckAvailabilityQueryVariables>;
export const ChangeOfDateCheckAvailabilityBookEditDocument = gql`
    query ChangeOfDateCheckAvailabilityBookEdit($BookingID: String!, $HotelID: String!, $ArrivalDate: DateTime!, $DepartureDate: DateTime!, $EditRegInput: [EditRegInput!]!, $roomTypeIDs: [String!]) {
  ChangeOfDateCheckAvailabilityBookEdit(BookingID: $BookingID, HotelID: $HotelID, ArrivalDate: $ArrivalDate, DepartureDate: $DepartureDate, EditRegInput: $EditRegInput, roomTypeIDs: $roomTypeIDs)
}
    `;

/**
 * __useChangeOfDateCheckAvailabilityBookEditQuery__
 *
 * To run a query within a React component, call `useChangeOfDateCheckAvailabilityBookEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeOfDateCheckAvailabilityBookEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeOfDateCheckAvailabilityBookEditQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      DepartureDate: // value for 'DepartureDate'
 *      EditRegInput: // value for 'EditRegInput'
 *      roomTypeIDs: // value for 'roomTypeIDs'
 *   },
 * });
 */
export function useChangeOfDateCheckAvailabilityBookEditQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChangeOfDateCheckAvailabilityBookEditQuery, ChangeOfDateCheckAvailabilityBookEditQueryVariables>) {
        return ApolloReactHooks.useQuery<ChangeOfDateCheckAvailabilityBookEditQuery, ChangeOfDateCheckAvailabilityBookEditQueryVariables>(ChangeOfDateCheckAvailabilityBookEditDocument, baseOptions);
      }
export function useChangeOfDateCheckAvailabilityBookEditLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChangeOfDateCheckAvailabilityBookEditQuery, ChangeOfDateCheckAvailabilityBookEditQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChangeOfDateCheckAvailabilityBookEditQuery, ChangeOfDateCheckAvailabilityBookEditQueryVariables>(ChangeOfDateCheckAvailabilityBookEditDocument, baseOptions);
        }
export type ChangeOfDateCheckAvailabilityBookEditQueryHookResult = ReturnType<typeof useChangeOfDateCheckAvailabilityBookEditQuery>;
export type ChangeOfDateCheckAvailabilityBookEditLazyQueryHookResult = ReturnType<typeof useChangeOfDateCheckAvailabilityBookEditLazyQuery>;
export type ChangeOfDateCheckAvailabilityBookEditQueryResult = ApolloReactCommon.QueryResult<ChangeOfDateCheckAvailabilityBookEditQuery, ChangeOfDateCheckAvailabilityBookEditQueryVariables>;
export const AdvancePaymentDetailDocument = gql`
    query AdvancePaymentDetail($BookingID: String!, $OrderID: String!) {
  AdvancePaymentDetail(BookingID: $BookingID, OrderID: $OrderID) {
    ID
    RegistrationID
    HotelID
    ReceiptNo
    PaymentDate
    PaymentClass
    PaymentType
    DebtorAccount
    PaySessionNo
    CheckOutID
    Amount
    ReferenceNo
    PaymentStatus
  }
}
    `;

/**
 * __useAdvancePaymentDetailQuery__
 *
 * To run a query within a React component, call `useAdvancePaymentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancePaymentDetailQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      OrderID: // value for 'OrderID'
 *   },
 * });
 */
export function useAdvancePaymentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvancePaymentDetailQuery, AdvancePaymentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvancePaymentDetailQuery, AdvancePaymentDetailQueryVariables>(AdvancePaymentDetailDocument, baseOptions);
      }
export function useAdvancePaymentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvancePaymentDetailQuery, AdvancePaymentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvancePaymentDetailQuery, AdvancePaymentDetailQueryVariables>(AdvancePaymentDetailDocument, baseOptions);
        }
export type AdvancePaymentDetailQueryHookResult = ReturnType<typeof useAdvancePaymentDetailQuery>;
export type AdvancePaymentDetailLazyQueryHookResult = ReturnType<typeof useAdvancePaymentDetailLazyQuery>;
export type AdvancePaymentDetailQueryResult = ApolloReactCommon.QueryResult<AdvancePaymentDetailQuery, AdvancePaymentDetailQueryVariables>;
export const BookingInsertDocument = gql`
    mutation BookingInsert($BookingInput: BookingInput!, $ContactInput: ContactInput!, $RegistrationInput: [RegistrationInput!]!, $DebtorType: String, $IsSharedConfirmationLetter: Boolean) {
  BookingInsert(BookingInput: $BookingInput, ContactInput: $ContactInput, RegistrationInput: $RegistrationInput, DebtorType: $DebtorType, IsSharedConfirmationLetter: $IsSharedConfirmationLetter)
}
    `;
export type BookingInsertMutationFn = ApolloReactCommon.MutationFunction<BookingInsertMutation, BookingInsertMutationVariables>;

/**
 * __useBookingInsertMutation__
 *
 * To run a mutation, you first call `useBookingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingInsertMutation, { data, loading, error }] = useBookingInsertMutation({
 *   variables: {
 *      BookingInput: // value for 'BookingInput'
 *      ContactInput: // value for 'ContactInput'
 *      RegistrationInput: // value for 'RegistrationInput'
 *      DebtorType: // value for 'DebtorType'
 *      IsSharedConfirmationLetter: // value for 'IsSharedConfirmationLetter'
 *   },
 * });
 */
export function useBookingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingInsertMutation, BookingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingInsertMutation, BookingInsertMutationVariables>(BookingInsertDocument, baseOptions);
      }
export type BookingInsertMutationHookResult = ReturnType<typeof useBookingInsertMutation>;
export type BookingInsertMutationResult = ApolloReactCommon.MutationResult<BookingInsertMutation>;
export type BookingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingInsertMutation, BookingInsertMutationVariables>;
export const BookingUpdateDocument = gql`
    mutation BookingUpdate($BookingInput: BookingInput!, $ContactInput: ContactInput, $SpecialRequestInput: [SpecialRequestInput!]) {
  BookingUpdate(BookingInput: $BookingInput, ContactInput: $ContactInput, SpecialRequestInput: $SpecialRequestInput)
}
    `;
export type BookingUpdateMutationFn = ApolloReactCommon.MutationFunction<BookingUpdateMutation, BookingUpdateMutationVariables>;

/**
 * __useBookingUpdateMutation__
 *
 * To run a mutation, you first call `useBookingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingUpdateMutation, { data, loading, error }] = useBookingUpdateMutation({
 *   variables: {
 *      BookingInput: // value for 'BookingInput'
 *      ContactInput: // value for 'ContactInput'
 *      SpecialRequestInput: // value for 'SpecialRequestInput'
 *   },
 * });
 */
export function useBookingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingUpdateMutation, BookingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingUpdateMutation, BookingUpdateMutationVariables>(BookingUpdateDocument, baseOptions);
      }
export type BookingUpdateMutationHookResult = ReturnType<typeof useBookingUpdateMutation>;
export type BookingUpdateMutationResult = ApolloReactCommon.MutationResult<BookingUpdateMutation>;
export type BookingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingUpdateMutation, BookingUpdateMutationVariables>;
export const ReinstateBookingDocument = gql`
    mutation ReinstateBooking($BookingID: String!, $HotelID: String!, $ReasonID: String, $Remark: String) {
  ReinstateBooking(BookingID: $BookingID, HotelID: $HotelID, ReasonID: $ReasonID, Remark: $Remark)
}
    `;
export type ReinstateBookingMutationFn = ApolloReactCommon.MutationFunction<ReinstateBookingMutation, ReinstateBookingMutationVariables>;

/**
 * __useReinstateBookingMutation__
 *
 * To run a mutation, you first call `useReinstateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReinstateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reinstateBookingMutation, { data, loading, error }] = useReinstateBookingMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      ReasonID: // value for 'ReasonID'
 *      Remark: // value for 'Remark'
 *   },
 * });
 */
export function useReinstateBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReinstateBookingMutation, ReinstateBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<ReinstateBookingMutation, ReinstateBookingMutationVariables>(ReinstateBookingDocument, baseOptions);
      }
export type ReinstateBookingMutationHookResult = ReturnType<typeof useReinstateBookingMutation>;
export type ReinstateBookingMutationResult = ApolloReactCommon.MutationResult<ReinstateBookingMutation>;
export type ReinstateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<ReinstateBookingMutation, ReinstateBookingMutationVariables>;
export const CancelBookingDocument = gql`
    mutation CancelBooking($BookingID: String!, $HotelID: String!, $ReasonID: String, $Remark: String) {
  CancelBooking(BookingID: $BookingID, HotelID: $HotelID, ReasonID: $ReasonID, Remark: $Remark)
}
    `;
export type CancelBookingMutationFn = ApolloReactCommon.MutationFunction<CancelBookingMutation, CancelBookingMutationVariables>;

/**
 * __useCancelBookingMutation__
 *
 * To run a mutation, you first call `useCancelBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingMutation, { data, loading, error }] = useCancelBookingMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      ReasonID: // value for 'ReasonID'
 *      Remark: // value for 'Remark'
 *   },
 * });
 */
export function useCancelBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelBookingMutation, CancelBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelBookingMutation, CancelBookingMutationVariables>(CancelBookingDocument, baseOptions);
      }
export type CancelBookingMutationHookResult = ReturnType<typeof useCancelBookingMutation>;
export type CancelBookingMutationResult = ApolloReactCommon.MutationResult<CancelBookingMutation>;
export type CancelBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelBookingMutation, CancelBookingMutationVariables>;
export const BookingRoomTypeUpdateDocument = gql`
    mutation BookingRoomTypeUpdate($EditRegInput: EditRegInput!) {
  BookingRoomTypeUpdate(EditRegInput: $EditRegInput)
}
    `;
export type BookingRoomTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>;

/**
 * __useBookingRoomTypeUpdateMutation__
 *
 * To run a mutation, you first call `useBookingRoomTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingRoomTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingRoomTypeUpdateMutation, { data, loading, error }] = useBookingRoomTypeUpdateMutation({
 *   variables: {
 *      EditRegInput: // value for 'EditRegInput'
 *   },
 * });
 */
export function useBookingRoomTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>(BookingRoomTypeUpdateDocument, baseOptions);
      }
export type BookingRoomTypeUpdateMutationHookResult = ReturnType<typeof useBookingRoomTypeUpdateMutation>;
export type BookingRoomTypeUpdateMutationResult = ApolloReactCommon.MutationResult<BookingRoomTypeUpdateMutation>;
export type BookingRoomTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingRoomTypeUpdateMutation, BookingRoomTypeUpdateMutationVariables>;
export const GuestProfileInsertDocument = gql`
    mutation GuestProfileInsert($RegistrationID: String!, $ContactInput: ContactInput!) {
  GuestProfileInsert(RegistrationID: $RegistrationID, ContactInput: $ContactInput) {
    ID
  }
}
    `;
export type GuestProfileInsertMutationFn = ApolloReactCommon.MutationFunction<GuestProfileInsertMutation, GuestProfileInsertMutationVariables>;

/**
 * __useGuestProfileInsertMutation__
 *
 * To run a mutation, you first call `useGuestProfileInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestProfileInsertMutation, { data, loading, error }] = useGuestProfileInsertMutation({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      ContactInput: // value for 'ContactInput'
 *   },
 * });
 */
export function useGuestProfileInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestProfileInsertMutation, GuestProfileInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestProfileInsertMutation, GuestProfileInsertMutationVariables>(GuestProfileInsertDocument, baseOptions);
      }
export type GuestProfileInsertMutationHookResult = ReturnType<typeof useGuestProfileInsertMutation>;
export type GuestProfileInsertMutationResult = ApolloReactCommon.MutationResult<GuestProfileInsertMutation>;
export type GuestProfileInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestProfileInsertMutation, GuestProfileInsertMutationVariables>;
export const GuestContactUpdateDocument = gql`
    mutation GuestContactUpdate($ContactInput: ContactInput!, $VehicleNo: String, $ArrivalDate: DateTime, $DepartureDate: DateTime, $RegistrationID: String, $BookingID: String, $HotelID: String, $IsPrincipal: Boolean, $AttachmentInput: [Upload!], $OldGalleryID: [String!], $guestType: GuestType, $IsTTx: Boolean, $TTxReasonID: String) {
  GuestContactUpdate(ContactInput: $ContactInput, VehicleNo: $VehicleNo, ArrivalDate: $ArrivalDate, DepartureDate: $DepartureDate, RegistrationID: $RegistrationID, BookingID: $BookingID, HotelID: $HotelID, IsPrincipal: $IsPrincipal, AttachmentInput: $AttachmentInput, OldGalleryID: $OldGalleryID, guestType: $guestType, IsTTx: $IsTTx, TTxReasonID: $TTxReasonID)
}
    `;
export type GuestContactUpdateMutationFn = ApolloReactCommon.MutationFunction<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>;

/**
 * __useGuestContactUpdateMutation__
 *
 * To run a mutation, you first call `useGuestContactUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestContactUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestContactUpdateMutation, { data, loading, error }] = useGuestContactUpdateMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      VehicleNo: // value for 'VehicleNo'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      DepartureDate: // value for 'DepartureDate'
 *      RegistrationID: // value for 'RegistrationID'
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      IsPrincipal: // value for 'IsPrincipal'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *      guestType: // value for 'guestType'
 *      IsTTx: // value for 'IsTTx'
 *      TTxReasonID: // value for 'TTxReasonID'
 *   },
 * });
 */
export function useGuestContactUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>(GuestContactUpdateDocument, baseOptions);
      }
export type GuestContactUpdateMutationHookResult = ReturnType<typeof useGuestContactUpdateMutation>;
export type GuestContactUpdateMutationResult = ApolloReactCommon.MutationResult<GuestContactUpdateMutation>;
export type GuestContactUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestContactUpdateMutation, GuestContactUpdateMutationVariables>;
export const GuestProfileDeleteDocument = gql`
    mutation GuestProfileDelete($RegistrationID: String, $BookingID: String, $GuestID: String, $SharerNo: Float) {
  GuestProfileDelete(RegistrationID: $RegistrationID, BookingID: $BookingID, GuestID: $GuestID, SharerNo: $SharerNo)
}
    `;
export type GuestProfileDeleteMutationFn = ApolloReactCommon.MutationFunction<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>;

/**
 * __useGuestProfileDeleteMutation__
 *
 * To run a mutation, you first call `useGuestProfileDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestProfileDeleteMutation, { data, loading, error }] = useGuestProfileDeleteMutation({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      BookingID: // value for 'BookingID'
 *      GuestID: // value for 'GuestID'
 *      SharerNo: // value for 'SharerNo'
 *   },
 * });
 */
export function useGuestProfileDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>(GuestProfileDeleteDocument, baseOptions);
      }
export type GuestProfileDeleteMutationHookResult = ReturnType<typeof useGuestProfileDeleteMutation>;
export type GuestProfileDeleteMutationResult = ApolloReactCommon.MutationResult<GuestProfileDeleteMutation>;
export type GuestProfileDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestProfileDeleteMutation, GuestProfileDeleteMutationVariables>;
export const AdvancePaymentInsertDocument = gql`
    mutation AdvancePaymentInsert($AdvancePaymentInput: AdvancePaymentInput!) {
  AdvancePaymentInsert(AdvancePaymentInput: $AdvancePaymentInput) {
    ReceiptNo
    PaymentDate
    PaymentClass
    PaymentType
    Amount
    ReferenceNo
    PaymentStatus
    ID
  }
}
    `;
export type AdvancePaymentInsertMutationFn = ApolloReactCommon.MutationFunction<AdvancePaymentInsertMutation, AdvancePaymentInsertMutationVariables>;

/**
 * __useAdvancePaymentInsertMutation__
 *
 * To run a mutation, you first call `useAdvancePaymentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advancePaymentInsertMutation, { data, loading, error }] = useAdvancePaymentInsertMutation({
 *   variables: {
 *      AdvancePaymentInput: // value for 'AdvancePaymentInput'
 *   },
 * });
 */
export function useAdvancePaymentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvancePaymentInsertMutation, AdvancePaymentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvancePaymentInsertMutation, AdvancePaymentInsertMutationVariables>(AdvancePaymentInsertDocument, baseOptions);
      }
export type AdvancePaymentInsertMutationHookResult = ReturnType<typeof useAdvancePaymentInsertMutation>;
export type AdvancePaymentInsertMutationResult = ApolloReactCommon.MutationResult<AdvancePaymentInsertMutation>;
export type AdvancePaymentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvancePaymentInsertMutation, AdvancePaymentInsertMutationVariables>;
export const AdvancePaymentRefundDocument = gql`
    mutation AdvancePaymentRefund($AdvancePaymentInput: [AdvancePaymentInput!]!) {
  AdvancePaymentRefund(AdvancePaymentInput: $AdvancePaymentInput)
}
    `;
export type AdvancePaymentRefundMutationFn = ApolloReactCommon.MutationFunction<AdvancePaymentRefundMutation, AdvancePaymentRefundMutationVariables>;

/**
 * __useAdvancePaymentRefundMutation__
 *
 * To run a mutation, you first call `useAdvancePaymentRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advancePaymentRefundMutation, { data, loading, error }] = useAdvancePaymentRefundMutation({
 *   variables: {
 *      AdvancePaymentInput: // value for 'AdvancePaymentInput'
 *   },
 * });
 */
export function useAdvancePaymentRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvancePaymentRefundMutation, AdvancePaymentRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvancePaymentRefundMutation, AdvancePaymentRefundMutationVariables>(AdvancePaymentRefundDocument, baseOptions);
      }
export type AdvancePaymentRefundMutationHookResult = ReturnType<typeof useAdvancePaymentRefundMutation>;
export type AdvancePaymentRefundMutationResult = ApolloReactCommon.MutationResult<AdvancePaymentRefundMutation>;
export type AdvancePaymentRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvancePaymentRefundMutation, AdvancePaymentRefundMutationVariables>;
export const AdvancePaymentUpdateDocument = gql`
    mutation AdvancePaymentUpdate($CheckOutID: String, $OrderID: String, $Status: String, $RegistrationID: String, $ReceiptNo: String, $HotelID: String) {
  AdvancePaymentUpdate(CheckOutID: $CheckOutID, OrderID: $OrderID, Status: $Status, RegistrationID: $RegistrationID, ReceiptNo: $ReceiptNo, HotelID: $HotelID)
}
    `;
export type AdvancePaymentUpdateMutationFn = ApolloReactCommon.MutationFunction<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>;

/**
 * __useAdvancePaymentUpdateMutation__
 *
 * To run a mutation, you first call `useAdvancePaymentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [advancePaymentUpdateMutation, { data, loading, error }] = useAdvancePaymentUpdateMutation({
 *   variables: {
 *      CheckOutID: // value for 'CheckOutID'
 *      OrderID: // value for 'OrderID'
 *      Status: // value for 'Status'
 *      RegistrationID: // value for 'RegistrationID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAdvancePaymentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>(AdvancePaymentUpdateDocument, baseOptions);
      }
export type AdvancePaymentUpdateMutationHookResult = ReturnType<typeof useAdvancePaymentUpdateMutation>;
export type AdvancePaymentUpdateMutationResult = ApolloReactCommon.MutationResult<AdvancePaymentUpdateMutation>;
export type AdvancePaymentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<AdvancePaymentUpdateMutation, AdvancePaymentUpdateMutationVariables>;
export const ImageUploadDocument = gql`
    mutation ImageUpload($AttachmentInput: [Upload!]!) {
  ImageUpload(AttachmentInput: $AttachmentInput) {
    ImageURL
  }
}
    `;
export type ImageUploadMutationFn = ApolloReactCommon.MutationFunction<ImageUploadMutation, ImageUploadMutationVariables>;

/**
 * __useImageUploadMutation__
 *
 * To run a mutation, you first call `useImageUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageUploadMutation, { data, loading, error }] = useImageUploadMutation({
 *   variables: {
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useImageUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImageUploadMutation, ImageUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<ImageUploadMutation, ImageUploadMutationVariables>(ImageUploadDocument, baseOptions);
      }
export type ImageUploadMutationHookResult = ReturnType<typeof useImageUploadMutation>;
export type ImageUploadMutationResult = ApolloReactCommon.MutationResult<ImageUploadMutation>;
export type ImageUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<ImageUploadMutation, ImageUploadMutationVariables>;
export const BookingRoomAssignmentDocument = gql`
    mutation BookingRoomAssignment($RoomTypeID: String!, $RegistrationIDs: [String!], $RoomIDs: [String!], $BookingID: String!, $BookingRoomAssignInput: [BookingRoomAssignInput!]) {
  BookingRoomAssignment(BookingID: $BookingID, RoomTypeID: $RoomTypeID, RegistrationIDs: $RegistrationIDs, RoomIDs: $RoomIDs, BookingRoomAssignInput: $BookingRoomAssignInput)
}
    `;
export type BookingRoomAssignmentMutationFn = ApolloReactCommon.MutationFunction<BookingRoomAssignmentMutation, BookingRoomAssignmentMutationVariables>;

/**
 * __useBookingRoomAssignmentMutation__
 *
 * To run a mutation, you first call `useBookingRoomAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingRoomAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingRoomAssignmentMutation, { data, loading, error }] = useBookingRoomAssignmentMutation({
 *   variables: {
 *      RoomTypeID: // value for 'RoomTypeID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *      RoomIDs: // value for 'RoomIDs'
 *      BookingID: // value for 'BookingID'
 *      BookingRoomAssignInput: // value for 'BookingRoomAssignInput'
 *   },
 * });
 */
export function useBookingRoomAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingRoomAssignmentMutation, BookingRoomAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingRoomAssignmentMutation, BookingRoomAssignmentMutationVariables>(BookingRoomAssignmentDocument, baseOptions);
      }
export type BookingRoomAssignmentMutationHookResult = ReturnType<typeof useBookingRoomAssignmentMutation>;
export type BookingRoomAssignmentMutationResult = ApolloReactCommon.MutationResult<BookingRoomAssignmentMutation>;
export type BookingRoomAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingRoomAssignmentMutation, BookingRoomAssignmentMutationVariables>;
export const BillScheduleListByBookingDocument = gql`
    query BillScheduleListByBooking($BookingID: String!, $RegistrationID: String, $IsFrontDesk: Boolean) {
  BillScheduleListByBooking(BookingID: $BookingID, RegistrationID: $RegistrationID, IsFrontDesk: $IsFrontDesk)
}
    `;

/**
 * __useBillScheduleListByBookingQuery__
 *
 * To run a query within a React component, call `useBillScheduleListByBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillScheduleListByBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillScheduleListByBookingQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      IsFrontDesk: // value for 'IsFrontDesk'
 *   },
 * });
 */
export function useBillScheduleListByBookingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BillScheduleListByBookingQuery, BillScheduleListByBookingQueryVariables>) {
        return ApolloReactHooks.useQuery<BillScheduleListByBookingQuery, BillScheduleListByBookingQueryVariables>(BillScheduleListByBookingDocument, baseOptions);
      }
export function useBillScheduleListByBookingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BillScheduleListByBookingQuery, BillScheduleListByBookingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BillScheduleListByBookingQuery, BillScheduleListByBookingQueryVariables>(BillScheduleListByBookingDocument, baseOptions);
        }
export type BillScheduleListByBookingQueryHookResult = ReturnType<typeof useBillScheduleListByBookingQuery>;
export type BillScheduleListByBookingLazyQueryHookResult = ReturnType<typeof useBillScheduleListByBookingLazyQuery>;
export type BillScheduleListByBookingQueryResult = ApolloReactCommon.QueryResult<BillScheduleListByBookingQuery, BillScheduleListByBookingQueryVariables>;
export const NoOfPaxUpdateDocument = gql`
    mutation NoOfPaxUpdate($BookingID: String!, $NoOfAdult: Float!, $SharerNo: Float!, $RoomTypeID: String!, $IsBaypassCMSControl: Boolean) {
  NoOfPaxUpdate(BookingID: $BookingID, NoOfAdult: $NoOfAdult, SharerNo: $SharerNo, RoomTypeID: $RoomTypeID, IsBaypassCMSControl: $IsBaypassCMSControl)
}
    `;
export type NoOfPaxUpdateMutationFn = ApolloReactCommon.MutationFunction<NoOfPaxUpdateMutation, NoOfPaxUpdateMutationVariables>;

/**
 * __useNoOfPaxUpdateMutation__
 *
 * To run a mutation, you first call `useNoOfPaxUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNoOfPaxUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [noOfPaxUpdateMutation, { data, loading, error }] = useNoOfPaxUpdateMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      NoOfAdult: // value for 'NoOfAdult'
 *      SharerNo: // value for 'SharerNo'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      IsBaypassCMSControl: // value for 'IsBaypassCMSControl'
 *   },
 * });
 */
export function useNoOfPaxUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NoOfPaxUpdateMutation, NoOfPaxUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<NoOfPaxUpdateMutation, NoOfPaxUpdateMutationVariables>(NoOfPaxUpdateDocument, baseOptions);
      }
export type NoOfPaxUpdateMutationHookResult = ReturnType<typeof useNoOfPaxUpdateMutation>;
export type NoOfPaxUpdateMutationResult = ApolloReactCommon.MutationResult<NoOfPaxUpdateMutation>;
export type NoOfPaxUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<NoOfPaxUpdateMutation, NoOfPaxUpdateMutationVariables>;
export const NewBookingInfoUpdateDocument = gql`
    mutation NewBookingInfoUpdate($SpecialRequestInput: [SpecialRequestInput!], $BookingInput: BookingInput!, $BookingID: String!) {
  NewBookingInfoUpdate(SpecialRequestInput: $SpecialRequestInput, BookingInput: $BookingInput, BookingID: $BookingID)
}
    `;
export type NewBookingInfoUpdateMutationFn = ApolloReactCommon.MutationFunction<NewBookingInfoUpdateMutation, NewBookingInfoUpdateMutationVariables>;

/**
 * __useNewBookingInfoUpdateMutation__
 *
 * To run a mutation, you first call `useNewBookingInfoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewBookingInfoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newBookingInfoUpdateMutation, { data, loading, error }] = useNewBookingInfoUpdateMutation({
 *   variables: {
 *      SpecialRequestInput: // value for 'SpecialRequestInput'
 *      BookingInput: // value for 'BookingInput'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useNewBookingInfoUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewBookingInfoUpdateMutation, NewBookingInfoUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<NewBookingInfoUpdateMutation, NewBookingInfoUpdateMutationVariables>(NewBookingInfoUpdateDocument, baseOptions);
      }
export type NewBookingInfoUpdateMutationHookResult = ReturnType<typeof useNewBookingInfoUpdateMutation>;
export type NewBookingInfoUpdateMutationResult = ApolloReactCommon.MutationResult<NewBookingInfoUpdateMutation>;
export type NewBookingInfoUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<NewBookingInfoUpdateMutation, NewBookingInfoUpdateMutationVariables>;
export const NewBookingRemarkDocument = gql`
    mutation NewBookingRemark($BookingRemark: String!, $BookingID: String, $HotelID: String!, $RegistrationID: String) {
  NewBookingRemark(BookingRemark: $BookingRemark, BookingID: $BookingID, HotelID: $HotelID, RegistrationID: $RegistrationID)
}
    `;
export type NewBookingRemarkMutationFn = ApolloReactCommon.MutationFunction<NewBookingRemarkMutation, NewBookingRemarkMutationVariables>;

/**
 * __useNewBookingRemarkMutation__
 *
 * To run a mutation, you first call `useNewBookingRemarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewBookingRemarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newBookingRemarkMutation, { data, loading, error }] = useNewBookingRemarkMutation({
 *   variables: {
 *      BookingRemark: // value for 'BookingRemark'
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useNewBookingRemarkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewBookingRemarkMutation, NewBookingRemarkMutationVariables>) {
        return ApolloReactHooks.useMutation<NewBookingRemarkMutation, NewBookingRemarkMutationVariables>(NewBookingRemarkDocument, baseOptions);
      }
export type NewBookingRemarkMutationHookResult = ReturnType<typeof useNewBookingRemarkMutation>;
export type NewBookingRemarkMutationResult = ApolloReactCommon.MutationResult<NewBookingRemarkMutation>;
export type NewBookingRemarkMutationOptions = ApolloReactCommon.BaseMutationOptions<NewBookingRemarkMutation, NewBookingRemarkMutationVariables>;
export const BookingUpdateV2Document = gql`
    mutation BookingUpdateV2($EditRegInput: [EditRegInput!], $SpecialRequestInput: [SpecialRequestInput!], $ContactInput: ContactInput, $BookingInput: BookingInput!, $PrintRate: Boolean!, $BookingID: String!, $IsBaypassCMSControl: Boolean) {
  BookingUpdateV2(EditRegInput: $EditRegInput, SpecialRequestInput: $SpecialRequestInput, ContactInput: $ContactInput, BookingInput: $BookingInput, PrintRate: $PrintRate, BookingID: $BookingID, IsBaypassCMSControl: $IsBaypassCMSControl)
}
    `;
export type BookingUpdateV2MutationFn = ApolloReactCommon.MutationFunction<BookingUpdateV2Mutation, BookingUpdateV2MutationVariables>;

/**
 * __useBookingUpdateV2Mutation__
 *
 * To run a mutation, you first call `useBookingUpdateV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingUpdateV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingUpdateV2Mutation, { data, loading, error }] = useBookingUpdateV2Mutation({
 *   variables: {
 *      EditRegInput: // value for 'EditRegInput'
 *      SpecialRequestInput: // value for 'SpecialRequestInput'
 *      ContactInput: // value for 'ContactInput'
 *      BookingInput: // value for 'BookingInput'
 *      PrintRate: // value for 'PrintRate'
 *      BookingID: // value for 'BookingID'
 *      IsBaypassCMSControl: // value for 'IsBaypassCMSControl'
 *   },
 * });
 */
export function useBookingUpdateV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingUpdateV2Mutation, BookingUpdateV2MutationVariables>) {
        return ApolloReactHooks.useMutation<BookingUpdateV2Mutation, BookingUpdateV2MutationVariables>(BookingUpdateV2Document, baseOptions);
      }
export type BookingUpdateV2MutationHookResult = ReturnType<typeof useBookingUpdateV2Mutation>;
export type BookingUpdateV2MutationResult = ApolloReactCommon.MutationResult<BookingUpdateV2Mutation>;
export type BookingUpdateV2MutationOptions = ApolloReactCommon.BaseMutationOptions<BookingUpdateV2Mutation, BookingUpdateV2MutationVariables>;
export const BookingIncChargesCancelDocument = gql`
    mutation BookingIncChargesCancel($BillID: String, $RegistrationID: String!) {
  BookingIncChargesCancel(BillID: $BillID, RegistrationID: $RegistrationID)
}
    `;
export type BookingIncChargesCancelMutationFn = ApolloReactCommon.MutationFunction<BookingIncChargesCancelMutation, BookingIncChargesCancelMutationVariables>;

/**
 * __useBookingIncChargesCancelMutation__
 *
 * To run a mutation, you first call `useBookingIncChargesCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingIncChargesCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingIncChargesCancelMutation, { data, loading, error }] = useBookingIncChargesCancelMutation({
 *   variables: {
 *      BillID: // value for 'BillID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useBookingIncChargesCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BookingIncChargesCancelMutation, BookingIncChargesCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<BookingIncChargesCancelMutation, BookingIncChargesCancelMutationVariables>(BookingIncChargesCancelDocument, baseOptions);
      }
export type BookingIncChargesCancelMutationHookResult = ReturnType<typeof useBookingIncChargesCancelMutation>;
export type BookingIncChargesCancelMutationResult = ApolloReactCommon.MutationResult<BookingIncChargesCancelMutation>;
export type BookingIncChargesCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<BookingIncChargesCancelMutation, BookingIncChargesCancelMutationVariables>;
export const ReinstateRoomDocument = gql`
    mutation ReinstateRoom($SharerNo: Float!, $RegistrationID: String!, $HotelID: String!, $BookingID: String!, $ReasonID: String, $Remark: String) {
  ReinstateRoom(SharerNo: $SharerNo, RegistrationID: $RegistrationID, HotelID: $HotelID, BookingID: $BookingID, ReasonID: $ReasonID, Remark: $Remark)
}
    `;
export type ReinstateRoomMutationFn = ApolloReactCommon.MutationFunction<ReinstateRoomMutation, ReinstateRoomMutationVariables>;

/**
 * __useReinstateRoomMutation__
 *
 * To run a mutation, you first call `useReinstateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReinstateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reinstateRoomMutation, { data, loading, error }] = useReinstateRoomMutation({
 *   variables: {
 *      SharerNo: // value for 'SharerNo'
 *      RegistrationID: // value for 'RegistrationID'
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      ReasonID: // value for 'ReasonID'
 *      Remark: // value for 'Remark'
 *   },
 * });
 */
export function useReinstateRoomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReinstateRoomMutation, ReinstateRoomMutationVariables>) {
        return ApolloReactHooks.useMutation<ReinstateRoomMutation, ReinstateRoomMutationVariables>(ReinstateRoomDocument, baseOptions);
      }
export type ReinstateRoomMutationHookResult = ReturnType<typeof useReinstateRoomMutation>;
export type ReinstateRoomMutationResult = ApolloReactCommon.MutationResult<ReinstateRoomMutation>;
export type ReinstateRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<ReinstateRoomMutation, ReinstateRoomMutationVariables>;
export const CancelRoomDocument = gql`
    mutation CancelRoom($SharerNo: Float!, $RegistrationID: String!, $HotelID: String!, $BookingID: String!, $ReasonID: String, $Remark: String) {
  CancelRoom(SharerNo: $SharerNo, RegistrationID: $RegistrationID, HotelID: $HotelID, BookingID: $BookingID, ReasonID: $ReasonID, Remark: $Remark)
}
    `;
export type CancelRoomMutationFn = ApolloReactCommon.MutationFunction<CancelRoomMutation, CancelRoomMutationVariables>;

/**
 * __useCancelRoomMutation__
 *
 * To run a mutation, you first call `useCancelRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRoomMutation, { data, loading, error }] = useCancelRoomMutation({
 *   variables: {
 *      SharerNo: // value for 'SharerNo'
 *      RegistrationID: // value for 'RegistrationID'
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      ReasonID: // value for 'ReasonID'
 *      Remark: // value for 'Remark'
 *   },
 * });
 */
export function useCancelRoomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelRoomMutation, CancelRoomMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelRoomMutation, CancelRoomMutationVariables>(CancelRoomDocument, baseOptions);
      }
export type CancelRoomMutationHookResult = ReturnType<typeof useCancelRoomMutation>;
export type CancelRoomMutationResult = ApolloReactCommon.MutationResult<CancelRoomMutation>;
export type CancelRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelRoomMutation, CancelRoomMutationVariables>;
export const BillScheduleListingDocument = gql`
    query BillScheduleListing($RegistrationID: String, $IsFrontDesk: Boolean, $BookingID: String!) {
  BillScheduleListing(BookingID: $BookingID, IsFrontDesk: $IsFrontDesk, RegistrationID: $RegistrationID)
}
    `;

/**
 * __useBillScheduleListingQuery__
 *
 * To run a query within a React component, call `useBillScheduleListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillScheduleListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillScheduleListingQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      IsFrontDesk: // value for 'IsFrontDesk'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useBillScheduleListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BillScheduleListingQuery, BillScheduleListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BillScheduleListingQuery, BillScheduleListingQueryVariables>(BillScheduleListingDocument, baseOptions);
      }
export function useBillScheduleListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BillScheduleListingQuery, BillScheduleListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BillScheduleListingQuery, BillScheduleListingQueryVariables>(BillScheduleListingDocument, baseOptions);
        }
export type BillScheduleListingQueryHookResult = ReturnType<typeof useBillScheduleListingQuery>;
export type BillScheduleListingLazyQueryHookResult = ReturnType<typeof useBillScheduleListingLazyQuery>;
export type BillScheduleListingQueryResult = ApolloReactCommon.QueryResult<BillScheduleListingQuery, BillScheduleListingQueryVariables>;
export const BillScheduleAdjustmentDocument = gql`
    mutation BillScheduleAdjustment($Reason: String!, $Discount: Float!, $RateID: String!, $RegistrationIDs: [String!]!, $TrxDates: [DateTime!]!, $IsBaypassCMSControl: Boolean) {
  BillScheduleAdjustment(Reason: $Reason, Discount: $Discount, RateID: $RateID, RegistrationIDs: $RegistrationIDs, TrxDates: $TrxDates, IsBaypassCMSControl: $IsBaypassCMSControl)
}
    `;
export type BillScheduleAdjustmentMutationFn = ApolloReactCommon.MutationFunction<BillScheduleAdjustmentMutation, BillScheduleAdjustmentMutationVariables>;

/**
 * __useBillScheduleAdjustmentMutation__
 *
 * To run a mutation, you first call `useBillScheduleAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillScheduleAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billScheduleAdjustmentMutation, { data, loading, error }] = useBillScheduleAdjustmentMutation({
 *   variables: {
 *      Reason: // value for 'Reason'
 *      Discount: // value for 'Discount'
 *      RateID: // value for 'RateID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *      TrxDates: // value for 'TrxDates'
 *      IsBaypassCMSControl: // value for 'IsBaypassCMSControl'
 *   },
 * });
 */
export function useBillScheduleAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillScheduleAdjustmentMutation, BillScheduleAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<BillScheduleAdjustmentMutation, BillScheduleAdjustmentMutationVariables>(BillScheduleAdjustmentDocument, baseOptions);
      }
export type BillScheduleAdjustmentMutationHookResult = ReturnType<typeof useBillScheduleAdjustmentMutation>;
export type BillScheduleAdjustmentMutationResult = ApolloReactCommon.MutationResult<BillScheduleAdjustmentMutation>;
export type BillScheduleAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<BillScheduleAdjustmentMutation, BillScheduleAdjustmentMutationVariables>;
export const BookingSubMenuInfoDocument = gql`
    query BookingSubMenuInfo($HotelID: String!, $BookingID: String!) {
  BookingSubMenuInfo(BookingID: $BookingID, HotelID: $HotelID)
}
    `;

/**
 * __useBookingSubMenuInfoQuery__
 *
 * To run a query within a React component, call `useBookingSubMenuInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingSubMenuInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingSubMenuInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useBookingSubMenuInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingSubMenuInfoQuery, BookingSubMenuInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingSubMenuInfoQuery, BookingSubMenuInfoQueryVariables>(BookingSubMenuInfoDocument, baseOptions);
      }
export function useBookingSubMenuInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingSubMenuInfoQuery, BookingSubMenuInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingSubMenuInfoQuery, BookingSubMenuInfoQueryVariables>(BookingSubMenuInfoDocument, baseOptions);
        }
export type BookingSubMenuInfoQueryHookResult = ReturnType<typeof useBookingSubMenuInfoQuery>;
export type BookingSubMenuInfoLazyQueryHookResult = ReturnType<typeof useBookingSubMenuInfoLazyQuery>;
export type BookingSubMenuInfoQueryResult = ApolloReactCommon.QueryResult<BookingSubMenuInfoQuery, BookingSubMenuInfoQueryVariables>;
export const WaitinglistNewApiDocument = gql`
    mutation WaitinglistNewAPI($BookingID: String!, $RegistrationIDs: [String!]!, $HotelID: String!) {
  WaitinglistNewAPI(BookingID: $BookingID, RegistrationIDs: $RegistrationIDs, HotelID: $HotelID)
}
    `;
export type WaitinglistNewApiMutationFn = ApolloReactCommon.MutationFunction<WaitinglistNewApiMutation, WaitinglistNewApiMutationVariables>;

/**
 * __useWaitinglistNewApiMutation__
 *
 * To run a mutation, you first call `useWaitinglistNewApiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWaitinglistNewApiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [waitinglistNewApiMutation, { data, loading, error }] = useWaitinglistNewApiMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useWaitinglistNewApiMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WaitinglistNewApiMutation, WaitinglistNewApiMutationVariables>) {
        return ApolloReactHooks.useMutation<WaitinglistNewApiMutation, WaitinglistNewApiMutationVariables>(WaitinglistNewApiDocument, baseOptions);
      }
export type WaitinglistNewApiMutationHookResult = ReturnType<typeof useWaitinglistNewApiMutation>;
export type WaitinglistNewApiMutationResult = ApolloReactCommon.MutationResult<WaitinglistNewApiMutation>;
export type WaitinglistNewApiMutationOptions = ApolloReactCommon.BaseMutationOptions<WaitinglistNewApiMutation, WaitinglistNewApiMutationVariables>;
export const CmsCustomControlDocument = gql`
    query CMSCustomControl($HotelID: String!, $BookingID: String!, $ControlOP: String!) {
  CMSCustomControl(HotelID: $HotelID, BookingID: $BookingID, ControlOP: $ControlOP)
}
    `;

/**
 * __useCmsCustomControlQuery__
 *
 * To run a query within a React component, call `useCmsCustomControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useCmsCustomControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCmsCustomControlQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      ControlOP: // value for 'ControlOP'
 *   },
 * });
 */
export function useCmsCustomControlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CmsCustomControlQuery, CmsCustomControlQueryVariables>) {
        return ApolloReactHooks.useQuery<CmsCustomControlQuery, CmsCustomControlQueryVariables>(CmsCustomControlDocument, baseOptions);
      }
export function useCmsCustomControlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CmsCustomControlQuery, CmsCustomControlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CmsCustomControlQuery, CmsCustomControlQueryVariables>(CmsCustomControlDocument, baseOptions);
        }
export type CmsCustomControlQueryHookResult = ReturnType<typeof useCmsCustomControlQuery>;
export type CmsCustomControlLazyQueryHookResult = ReturnType<typeof useCmsCustomControlLazyQuery>;
export type CmsCustomControlQueryResult = ApolloReactCommon.QueryResult<CmsCustomControlQuery, CmsCustomControlQueryVariables>;
export const HotelNotificationUpdateDocument = gql`
    mutation HotelNotificationUpdate($operationType: String, $input: [HotelNotificationInput!]!) {
  HotelNotificationUpdate(operationType: $operationType, input: $input)
}
    `;
export type HotelNotificationUpdateMutationFn = ApolloReactCommon.MutationFunction<HotelNotificationUpdateMutation, HotelNotificationUpdateMutationVariables>;

/**
 * __useHotelNotificationUpdateMutation__
 *
 * To run a mutation, you first call `useHotelNotificationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelNotificationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelNotificationUpdateMutation, { data, loading, error }] = useHotelNotificationUpdateMutation({
 *   variables: {
 *      operationType: // value for 'operationType'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHotelNotificationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelNotificationUpdateMutation, HotelNotificationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelNotificationUpdateMutation, HotelNotificationUpdateMutationVariables>(HotelNotificationUpdateDocument, baseOptions);
      }
export type HotelNotificationUpdateMutationHookResult = ReturnType<typeof useHotelNotificationUpdateMutation>;
export type HotelNotificationUpdateMutationResult = ApolloReactCommon.MutationResult<HotelNotificationUpdateMutation>;
export type HotelNotificationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelNotificationUpdateMutation, HotelNotificationUpdateMutationVariables>;
export const HotelNotificationListingDocument = gql`
    query HotelNotificationListing($HotelID: String!, $UserID: String!, $NotificationType: String!) {
  HotelNotificationListing(HotelID: $HotelID, UserID: $UserID, NotificationType: $NotificationType) {
    ID
    HotelID
    UserID
    AccountID
    RefTable
    RefID
    ReadStatus
    NotificationType
  }
}
    `;

/**
 * __useHotelNotificationListingQuery__
 *
 * To run a query within a React component, call `useHotelNotificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelNotificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelNotificationListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      UserID: // value for 'UserID'
 *      NotificationType: // value for 'NotificationType'
 *   },
 * });
 */
export function useHotelNotificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelNotificationListingQuery, HotelNotificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelNotificationListingQuery, HotelNotificationListingQueryVariables>(HotelNotificationListingDocument, baseOptions);
      }
export function useHotelNotificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelNotificationListingQuery, HotelNotificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelNotificationListingQuery, HotelNotificationListingQueryVariables>(HotelNotificationListingDocument, baseOptions);
        }
export type HotelNotificationListingQueryHookResult = ReturnType<typeof useHotelNotificationListingQuery>;
export type HotelNotificationListingLazyQueryHookResult = ReturnType<typeof useHotelNotificationListingLazyQuery>;
export type HotelNotificationListingQueryResult = ApolloReactCommon.QueryResult<HotelNotificationListingQuery, HotelNotificationListingQueryVariables>;
export const HotelNotifyDocument = gql`
    subscription HotelNotify($HotelID: String!, $UserID: String!, $NotificationType: String!) {
  HotelNotify(HotelID: $HotelID, UserID: $UserID, NotificationType: $NotificationType) {
    ID
    HotelID
    AccountID
    RefTable
    RefID
    ReadStatus
    NotificationType
  }
}
    `;

/**
 * __useHotelNotifySubscription__
 *
 * To run a query within a React component, call `useHotelNotifySubscription` and pass it any options that fit your needs.
 * When your component renders, `useHotelNotifySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelNotifySubscription({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      UserID: // value for 'UserID'
 *      NotificationType: // value for 'NotificationType'
 *   },
 * });
 */
export function useHotelNotifySubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<HotelNotifySubscription, HotelNotifySubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<HotelNotifySubscription, HotelNotifySubscriptionVariables>(HotelNotifyDocument, baseOptions);
      }
export type HotelNotifySubscriptionHookResult = ReturnType<typeof useHotelNotifySubscription>;
export type HotelNotifySubscriptionResult = ApolloReactCommon.SubscriptionResult<HotelNotifySubscription>;
export const RoomTypeAvailableDocument = gql`
    query RoomTypeAvailable($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $RoomTypeID: String) {
  RoomTypeAvailable(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, RoomTypeID: $RoomTypeID)
}
    `;

/**
 * __useRoomTypeAvailableQuery__
 *
 * To run a query within a React component, call `useRoomTypeAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomTypeAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomTypeAvailableQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      RoomTypeID: // value for 'RoomTypeID'
 *   },
 * });
 */
export function useRoomTypeAvailableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomTypeAvailableQuery, RoomTypeAvailableQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomTypeAvailableQuery, RoomTypeAvailableQueryVariables>(RoomTypeAvailableDocument, baseOptions);
      }
export function useRoomTypeAvailableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomTypeAvailableQuery, RoomTypeAvailableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomTypeAvailableQuery, RoomTypeAvailableQueryVariables>(RoomTypeAvailableDocument, baseOptions);
        }
export type RoomTypeAvailableQueryHookResult = ReturnType<typeof useRoomTypeAvailableQuery>;
export type RoomTypeAvailableLazyQueryHookResult = ReturnType<typeof useRoomTypeAvailableLazyQuery>;
export type RoomTypeAvailableQueryResult = ApolloReactCommon.QueryResult<RoomTypeAvailableQuery, RoomTypeAvailableQueryVariables>;
export const AvailabilityCheckingRevampDocument = gql`
    query AvailabilityCheckingRevamp($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $RoomTypeID: String, $NoOfRoom: Float, $ArrivalDate: DateTime, $DepartureDate: DateTime) {
  AvailabilityCheckingRevamp(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, RoomTypeID: $RoomTypeID, NoOfRoom: $NoOfRoom, ArrivalDate: $ArrivalDate, DepartureDate: $DepartureDate)
}
    `;

/**
 * __useAvailabilityCheckingRevampQuery__
 *
 * To run a query within a React component, call `useAvailabilityCheckingRevampQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityCheckingRevampQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityCheckingRevampQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      NoOfRoom: // value for 'NoOfRoom'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      DepartureDate: // value for 'DepartureDate'
 *   },
 * });
 */
export function useAvailabilityCheckingRevampQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailabilityCheckingRevampQuery, AvailabilityCheckingRevampQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailabilityCheckingRevampQuery, AvailabilityCheckingRevampQueryVariables>(AvailabilityCheckingRevampDocument, baseOptions);
      }
export function useAvailabilityCheckingRevampLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailabilityCheckingRevampQuery, AvailabilityCheckingRevampQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailabilityCheckingRevampQuery, AvailabilityCheckingRevampQueryVariables>(AvailabilityCheckingRevampDocument, baseOptions);
        }
export type AvailabilityCheckingRevampQueryHookResult = ReturnType<typeof useAvailabilityCheckingRevampQuery>;
export type AvailabilityCheckingRevampLazyQueryHookResult = ReturnType<typeof useAvailabilityCheckingRevampLazyQuery>;
export type AvailabilityCheckingRevampQueryResult = ApolloReactCommon.QueryResult<AvailabilityCheckingRevampQuery, AvailabilityCheckingRevampQueryVariables>;
export const CheckForBlockRoomDocument = gql`
    query CheckForBlockRoom($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $RoomID: String) {
  CheckForBlockRoom(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, RoomID: $RoomID)
}
    `;

/**
 * __useCheckForBlockRoomQuery__
 *
 * To run a query within a React component, call `useCheckForBlockRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckForBlockRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckForBlockRoomQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      RoomID: // value for 'RoomID'
 *   },
 * });
 */
export function useCheckForBlockRoomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckForBlockRoomQuery, CheckForBlockRoomQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckForBlockRoomQuery, CheckForBlockRoomQueryVariables>(CheckForBlockRoomDocument, baseOptions);
      }
export function useCheckForBlockRoomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckForBlockRoomQuery, CheckForBlockRoomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckForBlockRoomQuery, CheckForBlockRoomQueryVariables>(CheckForBlockRoomDocument, baseOptions);
        }
export type CheckForBlockRoomQueryHookResult = ReturnType<typeof useCheckForBlockRoomQuery>;
export type CheckForBlockRoomLazyQueryHookResult = ReturnType<typeof useCheckForBlockRoomLazyQuery>;
export type CheckForBlockRoomQueryResult = ApolloReactCommon.QueryResult<CheckForBlockRoomQuery, CheckForBlockRoomQueryVariables>;
export const BookingBillScheduleInfoDocument = gql`
    query BookingBillScheduleInfo($HotelID: String!, $RegistrationIDs: [String!]!, $IsCurrentDate: Boolean) {
  BookingBillScheduleInfo(HotelID: $HotelID, RegistrationIDs: $RegistrationIDs, IsCurrentDate: $IsCurrentDate) {
    BillScheduleAmount {
      ID
      RegistrationID
      RateID
      TrxDate
      TrxAmount
      DiscountAmount
    }
    DiscountAmount {
      ID
      RegistrationID
      NewDiscountAmount
      EffectiveDate
    }
  }
}
    `;

/**
 * __useBookingBillScheduleInfoQuery__
 *
 * To run a query within a React component, call `useBookingBillScheduleInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingBillScheduleInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingBillScheduleInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *      IsCurrentDate: // value for 'IsCurrentDate'
 *   },
 * });
 */
export function useBookingBillScheduleInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingBillScheduleInfoQuery, BookingBillScheduleInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingBillScheduleInfoQuery, BookingBillScheduleInfoQueryVariables>(BookingBillScheduleInfoDocument, baseOptions);
      }
export function useBookingBillScheduleInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingBillScheduleInfoQuery, BookingBillScheduleInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingBillScheduleInfoQuery, BookingBillScheduleInfoQueryVariables>(BookingBillScheduleInfoDocument, baseOptions);
        }
export type BookingBillScheduleInfoQueryHookResult = ReturnType<typeof useBookingBillScheduleInfoQuery>;
export type BookingBillScheduleInfoLazyQueryHookResult = ReturnType<typeof useBookingBillScheduleInfoLazyQuery>;
export type BookingBillScheduleInfoQueryResult = ApolloReactCommon.QueryResult<BookingBillScheduleInfoQuery, BookingBillScheduleInfoQueryVariables>;
export const IsRoomNoAvailableDocument = gql`
    query IsRoomNoAvailable($HotelID: String!, $RegistrationInput: [RegistrationInput!]!) {
  IsRoomNoAvailable(HotelID: $HotelID, RegistrationInput: $RegistrationInput)
}
    `;

/**
 * __useIsRoomNoAvailableQuery__
 *
 * To run a query within a React component, call `useIsRoomNoAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsRoomNoAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsRoomNoAvailableQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RegistrationInput: // value for 'RegistrationInput'
 *   },
 * });
 */
export function useIsRoomNoAvailableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsRoomNoAvailableQuery, IsRoomNoAvailableQueryVariables>) {
        return ApolloReactHooks.useQuery<IsRoomNoAvailableQuery, IsRoomNoAvailableQueryVariables>(IsRoomNoAvailableDocument, baseOptions);
      }
export function useIsRoomNoAvailableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsRoomNoAvailableQuery, IsRoomNoAvailableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsRoomNoAvailableQuery, IsRoomNoAvailableQueryVariables>(IsRoomNoAvailableDocument, baseOptions);
        }
export type IsRoomNoAvailableQueryHookResult = ReturnType<typeof useIsRoomNoAvailableQuery>;
export type IsRoomNoAvailableLazyQueryHookResult = ReturnType<typeof useIsRoomNoAvailableLazyQuery>;
export type IsRoomNoAvailableQueryResult = ApolloReactCommon.QueryResult<IsRoomNoAvailableQuery, IsRoomNoAvailableQueryVariables>;
export const AuditLogDetailsDocument = gql`
    query AuditLogDetails($FieldID: String!, $TableName: String!, $offset: Float, $limit: Float) {
  AuditLogDetails(FieldID: $FieldID, TableName: $TableName, offset: $offset, limit: $limit)
}
    `;

/**
 * __useAuditLogDetailsQuery__
 *
 * To run a query within a React component, call `useAuditLogDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogDetailsQuery({
 *   variables: {
 *      FieldID: // value for 'FieldID'
 *      TableName: // value for 'TableName'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAuditLogDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>(AuditLogDetailsDocument, baseOptions);
      }
export function useAuditLogDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>(AuditLogDetailsDocument, baseOptions);
        }
export type AuditLogDetailsQueryHookResult = ReturnType<typeof useAuditLogDetailsQuery>;
export type AuditLogDetailsLazyQueryHookResult = ReturnType<typeof useAuditLogDetailsLazyQuery>;
export type AuditLogDetailsQueryResult = ApolloReactCommon.QueryResult<AuditLogDetailsQuery, AuditLogDetailsQueryVariables>;
export const IsExistRegistrationIncChargesDocument = gql`
    query IsExistRegistrationIncCharges($HotelID: String!, $RegistrationIDs: [String!]!) {
  IsExistRegistrationIncCharges(HotelID: $HotelID, RegistrationIDs: $RegistrationIDs)
}
    `;

/**
 * __useIsExistRegistrationIncChargesQuery__
 *
 * To run a query within a React component, call `useIsExistRegistrationIncChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsExistRegistrationIncChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsExistRegistrationIncChargesQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *   },
 * });
 */
export function useIsExistRegistrationIncChargesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsExistRegistrationIncChargesQuery, IsExistRegistrationIncChargesQueryVariables>) {
        return ApolloReactHooks.useQuery<IsExistRegistrationIncChargesQuery, IsExistRegistrationIncChargesQueryVariables>(IsExistRegistrationIncChargesDocument, baseOptions);
      }
export function useIsExistRegistrationIncChargesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsExistRegistrationIncChargesQuery, IsExistRegistrationIncChargesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsExistRegistrationIncChargesQuery, IsExistRegistrationIncChargesQueryVariables>(IsExistRegistrationIncChargesDocument, baseOptions);
        }
export type IsExistRegistrationIncChargesQueryHookResult = ReturnType<typeof useIsExistRegistrationIncChargesQuery>;
export type IsExistRegistrationIncChargesLazyQueryHookResult = ReturnType<typeof useIsExistRegistrationIncChargesLazyQuery>;
export type IsExistRegistrationIncChargesQueryResult = ApolloReactCommon.QueryResult<IsExistRegistrationIncChargesQuery, IsExistRegistrationIncChargesQueryVariables>;
export const BookingIncChargesRegistrationListingDocument = gql`
    query BookingIncChargesRegistrationListing($BookingID: String!, $RegistrationIDs: [String!]!) {
  BookingIncChargesRegistrationListing(BookingID: $BookingID, RegistrationIDs: $RegistrationIDs) {
    ID
    BillLedger {
      ID
      ReversedBillLedgerID
      IncidentalChargeID
      Reason
      Description
      TrxDate
      Qty
      UnitPrice
      TrxAmount
      DiscountAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      ReferenceNo
      PaymentID
      FolioID
      CreatedBy
      CreatedDT
      RegistrationID
      Registration {
        Guest {
          Contact {
            ID
            FullName
          }
        }
      }
      TaxBillLedger {
        ID
        TaxID
        TaxSchemeID
        TaxRate
        TaxAmount
        BillLedgerID
        TaxInfo {
          code
        }
      }
    }
  }
}
    `;

/**
 * __useBookingIncChargesRegistrationListingQuery__
 *
 * To run a query within a React component, call `useBookingIncChargesRegistrationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingIncChargesRegistrationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingIncChargesRegistrationListingQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *   },
 * });
 */
export function useBookingIncChargesRegistrationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingIncChargesRegistrationListingQuery, BookingIncChargesRegistrationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingIncChargesRegistrationListingQuery, BookingIncChargesRegistrationListingQueryVariables>(BookingIncChargesRegistrationListingDocument, baseOptions);
      }
export function useBookingIncChargesRegistrationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingIncChargesRegistrationListingQuery, BookingIncChargesRegistrationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingIncChargesRegistrationListingQuery, BookingIncChargesRegistrationListingQueryVariables>(BookingIncChargesRegistrationListingDocument, baseOptions);
        }
export type BookingIncChargesRegistrationListingQueryHookResult = ReturnType<typeof useBookingIncChargesRegistrationListingQuery>;
export type BookingIncChargesRegistrationListingLazyQueryHookResult = ReturnType<typeof useBookingIncChargesRegistrationListingLazyQuery>;
export type BookingIncChargesRegistrationListingQueryResult = ApolloReactCommon.QueryResult<BookingIncChargesRegistrationListingQuery, BookingIncChargesRegistrationListingQueryVariables>;
export const CustomizedDocListingDocument = gql`
    query CustomizedDocListing($HotelID: String) {
  CustomizedDocListing(HotelID: $HotelID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    DocType
    Hotel {
      ID
      HotelName
    }
  }
}
    `;

/**
 * __useCustomizedDocListingQuery__
 *
 * To run a query within a React component, call `useCustomizedDocListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomizedDocListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomizedDocListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCustomizedDocListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomizedDocListingQuery, CustomizedDocListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomizedDocListingQuery, CustomizedDocListingQueryVariables>(CustomizedDocListingDocument, baseOptions);
      }
export function useCustomizedDocListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomizedDocListingQuery, CustomizedDocListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomizedDocListingQuery, CustomizedDocListingQueryVariables>(CustomizedDocListingDocument, baseOptions);
        }
export type CustomizedDocListingQueryHookResult = ReturnType<typeof useCustomizedDocListingQuery>;
export type CustomizedDocListingLazyQueryHookResult = ReturnType<typeof useCustomizedDocListingLazyQuery>;
export type CustomizedDocListingQueryResult = ApolloReactCommon.QueryResult<CustomizedDocListingQuery, CustomizedDocListingQueryVariables>;
export const OutGoingDocTemplateDocument = gql`
    query OutGoingDocTemplate($HotelID: String) {
  OutGoingDocTemplate(HotelID: $HotelID) {
    ID
    HotelID
    Folio
    Receipt
    Invoice
    DebitNote
    CreditNote
    OfficialReceipt
    Refund
    APStatement
  }
}
    `;

/**
 * __useOutGoingDocTemplateQuery__
 *
 * To run a query within a React component, call `useOutGoingDocTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutGoingDocTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutGoingDocTemplateQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useOutGoingDocTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OutGoingDocTemplateQuery, OutGoingDocTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<OutGoingDocTemplateQuery, OutGoingDocTemplateQueryVariables>(OutGoingDocTemplateDocument, baseOptions);
      }
export function useOutGoingDocTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OutGoingDocTemplateQuery, OutGoingDocTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OutGoingDocTemplateQuery, OutGoingDocTemplateQueryVariables>(OutGoingDocTemplateDocument, baseOptions);
        }
export type OutGoingDocTemplateQueryHookResult = ReturnType<typeof useOutGoingDocTemplateQuery>;
export type OutGoingDocTemplateLazyQueryHookResult = ReturnType<typeof useOutGoingDocTemplateLazyQuery>;
export type OutGoingDocTemplateQueryResult = ApolloReactCommon.QueryResult<OutGoingDocTemplateQuery, OutGoingDocTemplateQueryVariables>;
export const CashierSummaryDocument = gql`
    query CashierSummary($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIBillLedgerListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    RegistrationID
    Registration {
      Guest {
        Contact {
          FullName
        }
      }
      Room {
        RoomNo
      }
    }
    IncidentalCharge {
      ID
      Description
      DepartmentID
      Department {
        Code
        Name
      }
    }
    TrxDate
    Qty
    UnitPrice
    TrxAmount
    BaseAmount
    TaxAmount
    ServiceCharge
    Description
    ReferenceNo
    PaymentID
  }
  BIDepositLedgerListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    Registration {
      DepositLedger {
        DepositClass
        DepositAmount
        TrxDate
        ReversedID
        Remarks
      }
      Room {
        RoomNo
      }
      Guest {
        Contact {
          FullName
        }
      }
    }
  }
}
    `;

/**
 * __useCashierSummaryQuery__
 *
 * To run a query within a React component, call `useCashierSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCashierSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCashierSummaryQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useCashierSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CashierSummaryQuery, CashierSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<CashierSummaryQuery, CashierSummaryQueryVariables>(CashierSummaryDocument, baseOptions);
      }
export function useCashierSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CashierSummaryQuery, CashierSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CashierSummaryQuery, CashierSummaryQueryVariables>(CashierSummaryDocument, baseOptions);
        }
export type CashierSummaryQueryHookResult = ReturnType<typeof useCashierSummaryQuery>;
export type CashierSummaryLazyQueryHookResult = ReturnType<typeof useCashierSummaryLazyQuery>;
export type CashierSummaryQueryResult = ApolloReactCommon.QueryResult<CashierSummaryQuery, CashierSummaryQueryVariables>;
export const EmailSendDocument = gql`
    mutation EmailSend($Body: EmailBodyInput!, $HotelID: String!, $Email: String, $BookingID: String, $isGuestEmail: Boolean, $Attachments: EmailAttachmentInput) {
  EmailSend(Body: $Body, HotelID: $HotelID, Email: $Email, BookingID: $BookingID, isGuestEmail: $isGuestEmail, Attachments: $Attachments)
}
    `;
export type EmailSendMutationFn = ApolloReactCommon.MutationFunction<EmailSendMutation, EmailSendMutationVariables>;

/**
 * __useEmailSendMutation__
 *
 * To run a mutation, you first call `useEmailSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailSendMutation, { data, loading, error }] = useEmailSendMutation({
 *   variables: {
 *      Body: // value for 'Body'
 *      HotelID: // value for 'HotelID'
 *      Email: // value for 'Email'
 *      BookingID: // value for 'BookingID'
 *      isGuestEmail: // value for 'isGuestEmail'
 *      Attachments: // value for 'Attachments'
 *   },
 * });
 */
export function useEmailSendMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmailSendMutation, EmailSendMutationVariables>) {
        return ApolloReactHooks.useMutation<EmailSendMutation, EmailSendMutationVariables>(EmailSendDocument, baseOptions);
      }
export type EmailSendMutationHookResult = ReturnType<typeof useEmailSendMutation>;
export type EmailSendMutationResult = ApolloReactCommon.MutationResult<EmailSendMutation>;
export type EmailSendMutationOptions = ApolloReactCommon.BaseMutationOptions<EmailSendMutation, EmailSendMutationVariables>;
export const BiRevenueListingDocument = gql`
    query BIRevenueListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIRevenueListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    HotelDate
    OCCRoom
    DueIn
    DueOut
    ExpOcc
    OCC
    NoOfPax
    BookedRoom
    AvailableRoom
    DayUse
    CompUse
    RoomRevenue
    OtherRevenue
    Arr
    OOO
    OOI
  }
}
    `;

/**
 * __useBiRevenueListingQuery__
 *
 * To run a query within a React component, call `useBiRevenueListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiRevenueListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiRevenueListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiRevenueListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiRevenueListingQuery, BiRevenueListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiRevenueListingQuery, BiRevenueListingQueryVariables>(BiRevenueListingDocument, baseOptions);
      }
export function useBiRevenueListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiRevenueListingQuery, BiRevenueListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiRevenueListingQuery, BiRevenueListingQueryVariables>(BiRevenueListingDocument, baseOptions);
        }
export type BiRevenueListingQueryHookResult = ReturnType<typeof useBiRevenueListingQuery>;
export type BiRevenueListingLazyQueryHookResult = ReturnType<typeof useBiRevenueListingLazyQuery>;
export type BiRevenueListingQueryResult = ApolloReactCommon.QueryResult<BiRevenueListingQuery, BiRevenueListingQueryVariables>;
export const BiRevenueListingV2Document = gql`
    query BIRevenueListingV2($HotelID: String!, $AccountID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIRevenueListingV2(HotelID: $HotelID, AccountID: $AccountID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useBiRevenueListingV2Query__
 *
 * To run a query within a React component, call `useBiRevenueListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useBiRevenueListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiRevenueListingV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiRevenueListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiRevenueListingV2Query, BiRevenueListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<BiRevenueListingV2Query, BiRevenueListingV2QueryVariables>(BiRevenueListingV2Document, baseOptions);
      }
export function useBiRevenueListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiRevenueListingV2Query, BiRevenueListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiRevenueListingV2Query, BiRevenueListingV2QueryVariables>(BiRevenueListingV2Document, baseOptions);
        }
export type BiRevenueListingV2QueryHookResult = ReturnType<typeof useBiRevenueListingV2Query>;
export type BiRevenueListingV2LazyQueryHookResult = ReturnType<typeof useBiRevenueListingV2LazyQuery>;
export type BiRevenueListingV2QueryResult = ApolloReactCommon.QueryResult<BiRevenueListingV2Query, BiRevenueListingV2QueryVariables>;
export const CountItemDocument = gql`
    query CountItem($HotelID: String!, $AccountID: String!) {
  CountItem(HotelID: $HotelID, AccountID: $AccountID) {
    CheckInDate
    CheckOutDate
    ArrivalDate
    DepartureDate
  }
}
    `;

/**
 * __useCountItemQuery__
 *
 * To run a query within a React component, call `useCountItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountItemQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useCountItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountItemQuery, CountItemQueryVariables>) {
        return ApolloReactHooks.useQuery<CountItemQuery, CountItemQueryVariables>(CountItemDocument, baseOptions);
      }
export function useCountItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountItemQuery, CountItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountItemQuery, CountItemQueryVariables>(CountItemDocument, baseOptions);
        }
export type CountItemQueryHookResult = ReturnType<typeof useCountItemQuery>;
export type CountItemLazyQueryHookResult = ReturnType<typeof useCountItemLazyQuery>;
export type CountItemQueryResult = ApolloReactCommon.QueryResult<CountItemQuery, CountItemQueryVariables>;
export const Top10DebtorRevenueDocument = gql`
    query Top10DebtorRevenue($HotelID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  Top10DebtorRevenue(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useTop10DebtorRevenueQuery__
 *
 * To run a query within a React component, call `useTop10DebtorRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useTop10DebtorRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTop10DebtorRevenueQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useTop10DebtorRevenueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Top10DebtorRevenueQuery, Top10DebtorRevenueQueryVariables>) {
        return ApolloReactHooks.useQuery<Top10DebtorRevenueQuery, Top10DebtorRevenueQueryVariables>(Top10DebtorRevenueDocument, baseOptions);
      }
export function useTop10DebtorRevenueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Top10DebtorRevenueQuery, Top10DebtorRevenueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Top10DebtorRevenueQuery, Top10DebtorRevenueQueryVariables>(Top10DebtorRevenueDocument, baseOptions);
        }
export type Top10DebtorRevenueQueryHookResult = ReturnType<typeof useTop10DebtorRevenueQuery>;
export type Top10DebtorRevenueLazyQueryHookResult = ReturnType<typeof useTop10DebtorRevenueLazyQuery>;
export type Top10DebtorRevenueQueryResult = ApolloReactCommon.QueryResult<Top10DebtorRevenueQuery, Top10DebtorRevenueQueryVariables>;
export const DebtorAgingDocument = gql`
    query DebtorAging($HotelID: String!, $StartDate: DateTime) {
  DebtorAging(HotelID: $HotelID, StartDate: $StartDate)
}
    `;

/**
 * __useDebtorAgingQuery__
 *
 * To run a query within a React component, call `useDebtorAgingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorAgingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorAgingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useDebtorAgingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorAgingQuery, DebtorAgingQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorAgingQuery, DebtorAgingQueryVariables>(DebtorAgingDocument, baseOptions);
      }
export function useDebtorAgingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorAgingQuery, DebtorAgingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorAgingQuery, DebtorAgingQueryVariables>(DebtorAgingDocument, baseOptions);
        }
export type DebtorAgingQueryHookResult = ReturnType<typeof useDebtorAgingQuery>;
export type DebtorAgingLazyQueryHookResult = ReturnType<typeof useDebtorAgingLazyQuery>;
export type DebtorAgingQueryResult = ApolloReactCommon.QueryResult<DebtorAgingQuery, DebtorAgingQueryVariables>;
export const AgingByDebtorDocument = gql`
    query AgingByDebtor($HotelID: String!, $ID: String!) {
  AgingByDebtor(HotelID: $HotelID, ID: $ID)
}
    `;

/**
 * __useAgingByDebtorQuery__
 *
 * To run a query within a React component, call `useAgingByDebtorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgingByDebtorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgingByDebtorQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useAgingByDebtorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AgingByDebtorQuery, AgingByDebtorQueryVariables>) {
        return ApolloReactHooks.useQuery<AgingByDebtorQuery, AgingByDebtorQueryVariables>(AgingByDebtorDocument, baseOptions);
      }
export function useAgingByDebtorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgingByDebtorQuery, AgingByDebtorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AgingByDebtorQuery, AgingByDebtorQueryVariables>(AgingByDebtorDocument, baseOptions);
        }
export type AgingByDebtorQueryHookResult = ReturnType<typeof useAgingByDebtorQuery>;
export type AgingByDebtorLazyQueryHookResult = ReturnType<typeof useAgingByDebtorLazyQuery>;
export type AgingByDebtorQueryResult = ApolloReactCommon.QueryResult<AgingByDebtorQuery, AgingByDebtorQueryVariables>;
export const TotalAgingDocument = gql`
    query TotalAging($DebtorID: [String!], $HotelID: String!) {
  TotalAging(DebtorID: $DebtorID, HotelID: $HotelID)
}
    `;

/**
 * __useTotalAgingQuery__
 *
 * To run a query within a React component, call `useTotalAgingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalAgingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalAgingQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useTotalAgingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TotalAgingQuery, TotalAgingQueryVariables>) {
        return ApolloReactHooks.useQuery<TotalAgingQuery, TotalAgingQueryVariables>(TotalAgingDocument, baseOptions);
      }
export function useTotalAgingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TotalAgingQuery, TotalAgingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TotalAgingQuery, TotalAgingQueryVariables>(TotalAgingDocument, baseOptions);
        }
export type TotalAgingQueryHookResult = ReturnType<typeof useTotalAgingQuery>;
export type TotalAgingLazyQueryHookResult = ReturnType<typeof useTotalAgingLazyQuery>;
export type TotalAgingQueryResult = ApolloReactCommon.QueryResult<TotalAgingQuery, TotalAgingQueryVariables>;
export const TransactionLedgerDocument = gql`
    query TransactionLedger($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIBillLedgerListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    CreatedBy
    ID
    RegistrationID
    Registration {
      Booking {
        ID
        BookingNo
      }
      Guest {
        Contact {
          FullName
        }
      }
      Room {
        RoomNo
      }
    }
    IncidentalCharge {
      ID
      Description
      DepartmentID
      Department {
        Code
        Name
      }
    }
    TrxDate
    Qty
    UnitPrice
    TrxAmount
    BaseAmount
    TaxAmount
    ServiceCharge
    Description
    ReferenceNo
    PaymentID
  }
}
    `;

/**
 * __useTransactionLedgerQuery__
 *
 * To run a query within a React component, call `useTransactionLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionLedgerQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useTransactionLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransactionLedgerQuery, TransactionLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<TransactionLedgerQuery, TransactionLedgerQueryVariables>(TransactionLedgerDocument, baseOptions);
      }
export function useTransactionLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransactionLedgerQuery, TransactionLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransactionLedgerQuery, TransactionLedgerQueryVariables>(TransactionLedgerDocument, baseOptions);
        }
export type TransactionLedgerQueryHookResult = ReturnType<typeof useTransactionLedgerQuery>;
export type TransactionLedgerLazyQueryHookResult = ReturnType<typeof useTransactionLedgerLazyQuery>;
export type TransactionLedgerQueryResult = ApolloReactCommon.QueryResult<TransactionLedgerQuery, TransactionLedgerQueryVariables>;
export const BiHotelListingDocument = gql`
    query BIHotelListing($ID: String!) {
  BIHotelListing(ID: $ID) {
    RegCardTnC
  }
}
    `;

/**
 * __useBiHotelListingQuery__
 *
 * To run a query within a React component, call `useBiHotelListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiHotelListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiHotelListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useBiHotelListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiHotelListingQuery, BiHotelListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiHotelListingQuery, BiHotelListingQueryVariables>(BiHotelListingDocument, baseOptions);
      }
export function useBiHotelListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiHotelListingQuery, BiHotelListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiHotelListingQuery, BiHotelListingQueryVariables>(BiHotelListingDocument, baseOptions);
        }
export type BiHotelListingQueryHookResult = ReturnType<typeof useBiHotelListingQuery>;
export type BiHotelListingLazyQueryHookResult = ReturnType<typeof useBiHotelListingLazyQuery>;
export type BiHotelListingQueryResult = ApolloReactCommon.QueryResult<BiHotelListingQuery, BiHotelListingQueryVariables>;
export const BiUsersLisitingDocument = gql`
    query BIUsersLisiting($ID: String!) {
  BIUsersLisiting(ID: $ID) {
    name
    userName
  }
}
    `;

/**
 * __useBiUsersLisitingQuery__
 *
 * To run a query within a React component, call `useBiUsersLisitingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiUsersLisitingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiUsersLisitingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useBiUsersLisitingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiUsersLisitingQuery, BiUsersLisitingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiUsersLisitingQuery, BiUsersLisitingQueryVariables>(BiUsersLisitingDocument, baseOptions);
      }
export function useBiUsersLisitingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiUsersLisitingQuery, BiUsersLisitingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiUsersLisitingQuery, BiUsersLisitingQueryVariables>(BiUsersLisitingDocument, baseOptions);
        }
export type BiUsersLisitingQueryHookResult = ReturnType<typeof useBiUsersLisitingQuery>;
export type BiUsersLisitingLazyQueryHookResult = ReturnType<typeof useBiUsersLisitingLazyQuery>;
export type BiUsersLisitingQueryResult = ApolloReactCommon.QueryResult<BiUsersLisitingQuery, BiUsersLisitingQueryVariables>;
export const BiAdvancePaymentListingDocument = gql`
    query BIAdvancePaymentListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIAdvancePaymentListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    PaymentDate
    ReceiptNo
    ReferenceNo
    PaymentStatus
    PaymentType
    PaymentClass
    Amount
    BookingID
    Booking {
      ID
      BookingNo
      Registration {
        ID
        IsPrincipal
        Room {
          RoomNo
        }
        RoomType {
          Code
        }
        Guest {
          Contact {
            FullName
            Email
            Address {
              address
              address2
              country
              city
              state
              postCode
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBiAdvancePaymentListingQuery__
 *
 * To run a query within a React component, call `useBiAdvancePaymentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiAdvancePaymentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiAdvancePaymentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiAdvancePaymentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiAdvancePaymentListingQuery, BiAdvancePaymentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiAdvancePaymentListingQuery, BiAdvancePaymentListingQueryVariables>(BiAdvancePaymentListingDocument, baseOptions);
      }
export function useBiAdvancePaymentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiAdvancePaymentListingQuery, BiAdvancePaymentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiAdvancePaymentListingQuery, BiAdvancePaymentListingQueryVariables>(BiAdvancePaymentListingDocument, baseOptions);
        }
export type BiAdvancePaymentListingQueryHookResult = ReturnType<typeof useBiAdvancePaymentListingQuery>;
export type BiAdvancePaymentListingLazyQueryHookResult = ReturnType<typeof useBiAdvancePaymentListingLazyQuery>;
export type BiAdvancePaymentListingQueryResult = ApolloReactCommon.QueryResult<BiAdvancePaymentListingQuery, BiAdvancePaymentListingQueryVariables>;
export const BiFolioListingDocument = gql`
    query BIFolioListing($HotelID: String!, $AccountID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $SearchValue: String, $DocumentType: String, $offset: Float, $limit: Float) {
  BIFolioListing(HotelID: $HotelID, AccountID: $AccountID, StartDate: $StartDate, EndDate: $EndDate, SearchValue: $SearchValue, DocumentType: $DocumentType, offset: $offset, limit: $limit) {
    Folio {
      ID
      FolioNo
      FolioType
      FolioDate
      ClosedDate
      DocumentType
      FolioAmount
      CreatedBy
      IsSuspend
      einvoice_irbm_uniqueID
      is_einvoice
      einvoice_status
      IsConsolidated
      ClosedDate
      NonGuestInfo {
        EffectiveDate
        Title
        DebtorID
        Remark
        HouseLimit
      }
      Registration {
        ID
        Booking {
          ID
          Debtor {
            Name
          }
        }
        Room {
          RoomNo
        }
        RoomType {
          Code
        }
        Guest {
          Contact {
            FullName
          }
        }
      }
      AdvancePayment {
        Amount
        PaymentType
        PaymentClass
        PaySessionNo
        ReferenceNo
        ID
        Booking {
          ID
          BookingNo
          Contact {
            FullName
          }
        }
      }
      TaxLedger {
        TrxDate
        TaxType
        TaxDescription
        TaxAmount
        Reason
        Description
      }
      BillLedger {
        ID
        TrxDate
        BaseAmount
        ServiceCharge
        TaxAmount
        TrxAmount
        ReferenceNo
        IncidentalCharge {
          Description
        }
      }
      RoomLedger {
        ID
        TrxAmount
        TrxDate
        TaxAmount
        BaseAmount
        TransactionType
        TransactionID
        Transaction {
          Description
        }
        ServiceCharge
        ReferenceNo
        Description
      }
      DepositLedger {
        DepositAmount
        DepositClass
        ReferenceNo
        TrxDate
      }
      Payment {
        PaymentType
        DebtorAccount
      }
    }
    TotalFolio
  }
}
    `;

/**
 * __useBiFolioListingQuery__
 *
 * To run a query within a React component, call `useBiFolioListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiFolioListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiFolioListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      SearchValue: // value for 'SearchValue'
 *      DocumentType: // value for 'DocumentType'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBiFolioListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiFolioListingQuery, BiFolioListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiFolioListingQuery, BiFolioListingQueryVariables>(BiFolioListingDocument, baseOptions);
      }
export function useBiFolioListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiFolioListingQuery, BiFolioListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiFolioListingQuery, BiFolioListingQueryVariables>(BiFolioListingDocument, baseOptions);
        }
export type BiFolioListingQueryHookResult = ReturnType<typeof useBiFolioListingQuery>;
export type BiFolioListingLazyQueryHookResult = ReturnType<typeof useBiFolioListingLazyQuery>;
export type BiFolioListingQueryResult = ApolloReactCommon.QueryResult<BiFolioListingQuery, BiFolioListingQueryVariables>;
export const DepositListingDocument = gql`
    query DepositListing($HotelID: String!, $AccountID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $SearchValue: String, $offset: Float, $limit: Float) {
  DepositListing(HotelID: $HotelID, AccountID: $AccountID, StartDate: $StartDate, EndDate: $EndDate, SearchValue: $SearchValue, offset: $offset, limit: $limit) {
    Folio {
      ID
      FolioNo
      FolioType
      FolioDate
      ClosedDate
      DocumentType
      FolioAmount
      IsSuspend
      einvoice_irbm_uniqueID
      is_einvoice
      einvoice_status
      IsConsolidated
      CreatedBy
      IsSuspend
      ClosedDate
      NonGuestInfo {
        EffectiveDate
        Title
        DebtorID
        Remark
        HouseLimit
      }
      Registration {
        ID
        Booking {
          ID
          Debtor {
            Name
          }
        }
        Room {
          RoomNo
        }
        RoomType {
          Code
        }
        Guest {
          Contact {
            FullName
          }
        }
      }
      AdvancePayment {
        Amount
        PaymentType
        PaymentClass
        PaySessionNo
        ReferenceNo
        ID
        Booking {
          ID
          BookingNo
          Contact {
            FullName
          }
        }
      }
      TaxLedger {
        TrxDate
        TaxType
        TaxDescription
        TaxAmount
        Reason
        Description
      }
      BillLedger {
        ID
        TrxDate
        BaseAmount
        ServiceCharge
        TaxAmount
        TrxAmount
        ReferenceNo
        IncidentalCharge {
          Description
        }
      }
      RoomLedger {
        ID
        TrxAmount
        TrxDate
        TaxAmount
        BaseAmount
        TransactionType
        TransactionID
        Transaction {
          Description
        }
        ServiceCharge
        ReferenceNo
        Description
      }
      DepositLedger {
        DepositAmount
        DepositClass
        ReferenceNo
        TrxDate
      }
      Payment {
        PaymentType
        DebtorAccount
      }
    }
    TotalFolio
  }
}
    `;

/**
 * __useDepositListingQuery__
 *
 * To run a query within a React component, call `useDepositListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      SearchValue: // value for 'SearchValue'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDepositListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepositListingQuery, DepositListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DepositListingQuery, DepositListingQueryVariables>(DepositListingDocument, baseOptions);
      }
export function useDepositListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepositListingQuery, DepositListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepositListingQuery, DepositListingQueryVariables>(DepositListingDocument, baseOptions);
        }
export type DepositListingQueryHookResult = ReturnType<typeof useDepositListingQuery>;
export type DepositListingLazyQueryHookResult = ReturnType<typeof useDepositListingLazyQuery>;
export type DepositListingQueryResult = ApolloReactCommon.QueryResult<DepositListingQuery, DepositListingQueryVariables>;
export const BiReceiptRefundListingDocument = gql`
    query BIReceiptRefundListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $SearchValue: String, $Limit: Float, $Offset: Float) {
  BIReceiptRefundListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, SearchValue: $SearchValue, Limit: $Limit, Offset: $Offset) {
    BIListing {
      ID
      ReceiptNo
      ReferenceNo
      PaymentDate
      BookingID
      PaymentType
      Amount
      CreatedBy
      PaymentStatus
      PaymentClass
      DebtorAccount
      Booking {
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        Contact {
          Nationality
          FullName
          PhoneNo
          MobileNo
          Email
          Address {
            address
            address2
            city
            state
            postCode
            AddressType
            country
            siteName
          }
        }
      }
      FolioID
      Folio {
        ID
        FolioType
        FolioNo
        IsSuspend
        NonGuestInfo {
          EffectiveDate
          Title
          DebtorID
          Remark
          HouseLimit
        }
        BillLedger {
          TrxDate
          TrxAmount
          BaseAmount
          ReferenceNo
          TaxAmount
          ServiceCharge
          Qty
          IncidentalCharge {
            Description
          }
        }
        RoomLedger {
          ReferenceNo
          BaseAmount
          TrxAmount
          TrxDate
          ServiceCharge
          TaxAmount
          TransactionType
          Transaction {
            Description
          }
        }
        DepositLedger {
          ID
          DepositAmount
          PaymentType
          ReferenceNo
          DepositClass
          ReversedID
        }
      }
      Registration {
        ArrivalDate
        CheckInDate
        CheckOutDate
        DepartureDate
        Guest {
          Contact {
            Nationality
            FullName
            PhoneNo
            MobileNo
            Email
            Address {
              address
              address2
              city
              state
              postCode
              AddressType
              country
              siteName
            }
          }
        }
        Booking {
          BookingNo
          DebtorAccount
          Source
          ArrivalDate
          DepartureDate
          CreatedBy
          Contact {
            Nationality
            FullName
            PhoneNo
            MobileNo
            Email
            Address {
              address
              address2
              city
              state
              postCode
              AddressType
              country
              siteName
            }
          }
        }
        TaxLedger {
          TaxType
          TaxDescription
          TaxAmount
        }
        Room {
          Registrations {
            CheckInDate
            CheckOutDate
            IsPrincipal
            Guest {
              Contact {
                Nationality
                FullName
                PhoneNo
                Email
                Address {
                  address
                  address2
                  city
                  state
                  postCode
                  AddressType
                  country
                  siteName
                }
              }
            }
          }
          RoomNo
          RoomType {
            Code
          }
        }
      }
    }
    TotalListing
  }
}
    `;

/**
 * __useBiReceiptRefundListingQuery__
 *
 * To run a query within a React component, call `useBiReceiptRefundListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiReceiptRefundListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiReceiptRefundListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      SearchValue: // value for 'SearchValue'
 *      Limit: // value for 'Limit'
 *      Offset: // value for 'Offset'
 *   },
 * });
 */
export function useBiReceiptRefundListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiReceiptRefundListingQuery, BiReceiptRefundListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiReceiptRefundListingQuery, BiReceiptRefundListingQueryVariables>(BiReceiptRefundListingDocument, baseOptions);
      }
export function useBiReceiptRefundListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiReceiptRefundListingQuery, BiReceiptRefundListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiReceiptRefundListingQuery, BiReceiptRefundListingQueryVariables>(BiReceiptRefundListingDocument, baseOptions);
        }
export type BiReceiptRefundListingQueryHookResult = ReturnType<typeof useBiReceiptRefundListingQuery>;
export type BiReceiptRefundListingLazyQueryHookResult = ReturnType<typeof useBiReceiptRefundListingLazyQuery>;
export type BiReceiptRefundListingQueryResult = ApolloReactCommon.QueryResult<BiReceiptRefundListingQuery, BiReceiptRefundListingQueryVariables>;
export const BiPaymentListingDocument = gql`
    query BIPaymentListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIAdvancePaymentListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    ReceiptNo
    ReferenceNo
    HotelID
    CreatedBy
    BookingID
    DebtorAccount
    PaymentClass
    PaymentStatus
    PaymentDate
    PaymentType
    Amount
    Registration {
      ID
      IsPrincipal
      Room {
        RoomNo
      }
      RoomType {
        Code
      }
      Guest {
        Contact {
          FullName
          Email
          Address {
            address
            address2
            country
            city
            state
            postCode
          }
        }
      }
    }
    Booking {
      ID
      BookingNo
      ArrivalDate
      DepartureDate
      Registration {
        ID
        IsPrincipal
        Room {
          RoomNo
        }
        RoomType {
          Code
        }
        Guest {
          Contact {
            FullName
            Email
            Address {
              address
              address2
              country
              city
              state
              postCode
            }
          }
        }
      }
      Contact {
        Nationality
        FullName
        PhoneNo
        MobileNo
        Email
        Address {
          address
          address2
          city
          state
          postCode
          AddressType
          country
          siteName
        }
      }
    }
    Folio {
      FolioType
      NonGuestInfo {
        EffectiveDate
        Title
        DebtorID
        Remark
        HouseLimit
      }
      BillLedger {
        ID
      }
    }
  }
  BIPaymentListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    ReceiptNo
    ReferenceNo
    PaymentDate
    BookingID
    PaymentType
    Amount
    CreatedBy
    PaymentStatus
    PaymentClass
    DebtorAccount
    Booking {
      ID
      BookingNo
      ArrivalDate
      DepartureDate
      Contact {
        Nationality
        FullName
        PhoneNo
        MobileNo
        Email
        Address {
          address
          address2
          city
          state
          postCode
          AddressType
          country
          siteName
        }
      }
    }
    Folio {
      ID
      FolioType
      FolioNo
      IsSuspend
      NonGuestInfo {
        EffectiveDate
        Title
        DebtorID
        Remark
        HouseLimit
      }
      BillLedger {
        TrxDate
        TrxAmount
        BaseAmount
        ReferenceNo
        TaxAmount
        ServiceCharge
        Qty
        IncidentalCharge {
          Description
        }
        TaxBillLedger {
          ID
          TaxID
          TaxRate
          TaxAmount
          BillLedgerID
          TaxSchemeID
          TaxInfo {
            code
          }
        }
      }
      RoomLedger {
        ReferenceNo
        BaseAmount
        TrxAmount
        TrxDate
        ServiceCharge
        TaxAmount
        TransactionType
        Transaction {
          Description
        }
        TaxRoomLedger {
          ID
          TaxID
          TaxRate
          TaxAmount
          RoomLedgerID
          TaxSchemeID
          TaxInfo {
            code
          }
        }
      }
      DepositLedger {
        ID
        DepositAmount
        PaymentType
        ReferenceNo
        DepositClass
        ReversedID
      }
    }
    Registration {
      ArrivalDate
      CheckInDate
      CheckOutDate
      DepartureDate
      Guest {
        Contact {
          Nationality
          FullName
          PhoneNo
          MobileNo
          Email
          Address {
            address
            address2
            city
            state
            postCode
            AddressType
            country
            siteName
          }
        }
      }
      Booking {
        BookingNo
        DebtorAccount
        Source
        ArrivalDate
        DepartureDate
        CreatedBy
        Contact {
          Nationality
          FullName
          PhoneNo
          MobileNo
          Email
          Address {
            address
            address2
            city
            state
            postCode
            AddressType
            country
            siteName
          }
        }
      }
      TaxLedger {
        TaxType
        TaxDescription
        TaxAmount
      }
      Room {
        Registrations {
          CheckInDate
          CheckOutDate
          IsPrincipal
          Guest {
            Contact {
              Nationality
              FullName
              PhoneNo
              Email
              Address {
                address
                address2
                city
                state
                postCode
                AddressType
                country
                siteName
              }
            }
          }
        }
        RoomNo
        RoomType {
          Code
        }
      }
    }
  }
}
    `;

/**
 * __useBiPaymentListingQuery__
 *
 * To run a query within a React component, call `useBiPaymentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiPaymentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiPaymentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiPaymentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiPaymentListingQuery, BiPaymentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiPaymentListingQuery, BiPaymentListingQueryVariables>(BiPaymentListingDocument, baseOptions);
      }
export function useBiPaymentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiPaymentListingQuery, BiPaymentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiPaymentListingQuery, BiPaymentListingQueryVariables>(BiPaymentListingDocument, baseOptions);
        }
export type BiPaymentListingQueryHookResult = ReturnType<typeof useBiPaymentListingQuery>;
export type BiPaymentListingLazyQueryHookResult = ReturnType<typeof useBiPaymentListingLazyQuery>;
export type BiPaymentListingQueryResult = ApolloReactCommon.QueryResult<BiPaymentListingQuery, BiPaymentListingQueryVariables>;
export const BiPaymentListingTestDocument = gql`
    query BIPaymentListingTest($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIPaymentListingTest(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    RegistrationID
    Amount
    ReceiptNo
    ReferenceNo
    PaymentType
    PaymentStatus
    PaymentClass
    PaymentDate
    CreatedDT
    CreatedBy
    Result {
      Registration {
        ArrivalDate
        DepartureDate
        NoOfAdult
        IsPrincipal
      }
      BillLedger {
        Description
        BaseAmount
      }
      BillSchedule {
        Description
        BaseAmount
      }
      AdvancePayment {
        ReferenceNo
      }
      Booking {
        BookingNo
      }
      Room {
        ID
        RoomNo
      }
      GuestProfile {
        ID
        VehicleNo
      }
      Contact {
        FullName
        PhoneNo
        Email
        Address {
          siteName
          address
        }
      }
      RoomType {
        Code
      }
    }
  }
}
    `;

/**
 * __useBiPaymentListingTestQuery__
 *
 * To run a query within a React component, call `useBiPaymentListingTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiPaymentListingTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiPaymentListingTestQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiPaymentListingTestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiPaymentListingTestQuery, BiPaymentListingTestQueryVariables>) {
        return ApolloReactHooks.useQuery<BiPaymentListingTestQuery, BiPaymentListingTestQueryVariables>(BiPaymentListingTestDocument, baseOptions);
      }
export function useBiPaymentListingTestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiPaymentListingTestQuery, BiPaymentListingTestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiPaymentListingTestQuery, BiPaymentListingTestQueryVariables>(BiPaymentListingTestDocument, baseOptions);
        }
export type BiPaymentListingTestQueryHookResult = ReturnType<typeof useBiPaymentListingTestQuery>;
export type BiPaymentListingTestLazyQueryHookResult = ReturnType<typeof useBiPaymentListingTestLazyQuery>;
export type BiPaymentListingTestQueryResult = ApolloReactCommon.QueryResult<BiPaymentListingTestQuery, BiPaymentListingTestQueryVariables>;
export const BiRegistrationListingDocument = gql`
    query BIRegistrationListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIRegistrationListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    BookingNo
    BookingStatus
    ArrivalDate
    TotalRoomRate
    DepartureDate
    BillingInstruction
    Registration {
      ID
      RoomRate
      DiscountAmount
      Signature
      SpecialRequest
      RoomRateDisplay
      RegistrationSignature {
        ID
        BucketFileName
        ImageURL
      }
      Payment {
        ReferenceNo
        Amount
      }
      CheckInDate
      CheckOutDate
      NoOfAdult
      NoOfInfant
      NoOfChild
      Room {
        RoomNo
        RoomType {
          Code
        }
      }
      Guest {
        ID
        VehicleNo
        Contact {
          ID
          PassportExpireDate
          Nationality
          PassportNo
          BirthDate
          FullName
          NRIC
          Email
          PhoneNo
          MobileNo
          Address {
            address
            address2
            siteName
            country
            city
            state
            postCode
            AddressType
          }
        }
      }
    }
    Debtor {
      ID
      DebtorAccount
      Name
    }
  }
}
    `;

/**
 * __useBiRegistrationListingQuery__
 *
 * To run a query within a React component, call `useBiRegistrationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiRegistrationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiRegistrationListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiRegistrationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiRegistrationListingQuery, BiRegistrationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiRegistrationListingQuery, BiRegistrationListingQueryVariables>(BiRegistrationListingDocument, baseOptions);
      }
export function useBiRegistrationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiRegistrationListingQuery, BiRegistrationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiRegistrationListingQuery, BiRegistrationListingQueryVariables>(BiRegistrationListingDocument, baseOptions);
        }
export type BiRegistrationListingQueryHookResult = ReturnType<typeof useBiRegistrationListingQuery>;
export type BiRegistrationListingLazyQueryHookResult = ReturnType<typeof useBiRegistrationListingLazyQuery>;
export type BiRegistrationListingQueryResult = ApolloReactCommon.QueryResult<BiRegistrationListingQuery, BiRegistrationListingQueryVariables>;
export const BiSharedGAlleryInsertDocument = gql`
    mutation BISharedGAlleryInsert($HotelID: String!, $AttachmentInput: [Upload!]!) {
  BISharedGAlleryInsert(HotelID: $HotelID, AttachmentInput: $AttachmentInput) {
    ID
    Gallery {
      ImageURL
    }
  }
}
    `;
export type BiSharedGAlleryInsertMutationFn = ApolloReactCommon.MutationFunction<BiSharedGAlleryInsertMutation, BiSharedGAlleryInsertMutationVariables>;

/**
 * __useBiSharedGAlleryInsertMutation__
 *
 * To run a mutation, you first call `useBiSharedGAlleryInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBiSharedGAlleryInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [biSharedGAlleryInsertMutation, { data, loading, error }] = useBiSharedGAlleryInsertMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useBiSharedGAlleryInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BiSharedGAlleryInsertMutation, BiSharedGAlleryInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BiSharedGAlleryInsertMutation, BiSharedGAlleryInsertMutationVariables>(BiSharedGAlleryInsertDocument, baseOptions);
      }
export type BiSharedGAlleryInsertMutationHookResult = ReturnType<typeof useBiSharedGAlleryInsertMutation>;
export type BiSharedGAlleryInsertMutationResult = ApolloReactCommon.MutationResult<BiSharedGAlleryInsertMutation>;
export type BiSharedGAlleryInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BiSharedGAlleryInsertMutation, BiSharedGAlleryInsertMutationVariables>;
export const BiRoomRateListingDocument = gql`
    query BIRoomRateListing($HotelID: String!, $Status: String, $GuestID: String, $StartDate: DateTime, $EndDate: DateTime) {
  BIRoomRateListing(HotelID: $HotelID, Status: $Status, GuestID: $GuestID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    BookingNo
    ArrivalDate
    DepartureDate
    BookingStatus
    Source
    GroupName
    TotalAdvPayment
    TotalRoomRate
    Segment {
      ID
      Description
    }
    ModifiedBy
    ModifiedDT
    Debtor {
      ID
      Name
      Contact {
        FullName
        Email
        MobileNo
      }
      CreditLimit
      DebtorAccount
      Segment
      Password
      Login
    }
    Contact {
      ID
      FullName
      Email
      MobileNo
      PhoneNo
      PassportNo
      NRIC
    }
    Registration {
      ID
      RegistrationStatus
      PromoCode
      TotalPackagePrice
      SpecialRequest
      TotalPrice
      RoomType {
        ID
        MaxGuest
        Code
      }
      BillSchedule {
        ID
        TransactionType
        ServiceCharge
        TaxAmount
        TrxDate
        BaseAmount
        TrxAmount
        AdvancePaymentID
        ReversedBillScheduleID
        IsPosted
      }
      TaxLedger {
        ID
        TaxType
        TaxDescription
        TaxAmount
        TrxDate
      }
      BillLedger {
        BaseAmount
        TaxAmount
        ServiceCharge
        TrxAmount
        ReversedBillLedgerID
        PaymentID
        Reason
      }
      DepositLedger {
        ID
        FolioID
        DepositAmount
        DepositClass
        ReversedID
        ReferenceNo
      }
      RoomLedger {
        ID
        TrxAmount
        TaxAmount
        CreatedBy
      }
      ID
      DepositSum
      PaymentSum
      CheckOutDate
      IncidentalChargesSum
      IsPrincipal
      RegistrationStatus
      NoOfAdult
      NoOfChild
      RoomRate
      DiscountAmount
      Room {
        ID
        RoomNo
        RoomType {
          ID
          Code
        }
      }
      Guest {
        ID
        ContactID
        VehicleNo
        Contact {
          ID
          FullName
          Nationality
          MobileNo
          NRIC
          Email
          Address {
            address
            state
            country
            postCode
            city
          }
        }
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
    }
  }
}
    `;

/**
 * __useBiRoomRateListingQuery__
 *
 * To run a query within a React component, call `useBiRoomRateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiRoomRateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiRoomRateListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      Status: // value for 'Status'
 *      GuestID: // value for 'GuestID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiRoomRateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiRoomRateListingQuery, BiRoomRateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiRoomRateListingQuery, BiRoomRateListingQueryVariables>(BiRoomRateListingDocument, baseOptions);
      }
export function useBiRoomRateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiRoomRateListingQuery, BiRoomRateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiRoomRateListingQuery, BiRoomRateListingQueryVariables>(BiRoomRateListingDocument, baseOptions);
        }
export type BiRoomRateListingQueryHookResult = ReturnType<typeof useBiRoomRateListingQuery>;
export type BiRoomRateListingLazyQueryHookResult = ReturnType<typeof useBiRoomRateListingLazyQuery>;
export type BiRoomRateListingQueryResult = ApolloReactCommon.QueryResult<BiRoomRateListingQuery, BiRoomRateListingQueryVariables>;
export const AllBiListingDocument = gql`
    query AllBiListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BIServiceRequestListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    Description
    RegistrationID
    RequestBy
    CreatedDT
    Title
    Remarks
    Status
    EstimatedCompletion
    Registration {
      ID
      Guest {
        Contact {
          FullName
        }
      }
    }
    Department {
      ID
      Code
      Name
    }
    Room {
      RoomNo
      Registrations {
        CheckInDate
        CheckOutDate
        ID
        IsPrincipal
        Guest {
          ID
          Contact {
            FullName
          }
        }
      }
    }
  }
  BIAnalysis(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
    }
    ArrivalDate
    DepartureDate
    GroupName
    SalesChannel {
      ID
      Description
      DepartmentID
    }
    Source
    Segment {
      ID
      Description
    }
    Registration {
      IsPrincipal
      BillLedger {
        IncidentalCharge {
          Description
          Amount
        }
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RatePolicy {
        ID
        RateCode
        Description
      }
      RegistrationStatus
      NoOfAdult
      NoOfChild
      RatePolicyID
      CheckInDate
      CheckOutDate
      RoomRate
      DiscountAmount
      Room {
        RoomType {
          Code
          Description
          ID
        }
        RoomNo
      }
      Guest {
        Contact {
          FullName
          Nationality
          Address {
            address
            address2
            city
            country
            postCode
            state
            AddressType
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAllBiListingQuery__
 *
 * To run a query within a React component, call `useAllBiListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBiListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBiListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useAllBiListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllBiListingQuery, AllBiListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AllBiListingQuery, AllBiListingQueryVariables>(AllBiListingDocument, baseOptions);
      }
export function useAllBiListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllBiListingQuery, AllBiListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllBiListingQuery, AllBiListingQueryVariables>(AllBiListingDocument, baseOptions);
        }
export type AllBiListingQueryHookResult = ReturnType<typeof useAllBiListingQuery>;
export type AllBiListingLazyQueryHookResult = ReturnType<typeof useAllBiListingLazyQuery>;
export type AllBiListingQueryResult = ApolloReactCommon.QueryResult<AllBiListingQuery, AllBiListingQueryVariables>;
export const BiAnalysisDocument = gql`
    query BIAnalysis($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BIAnalysis(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
    }
    ArrivalDate
    DepartureDate
    GroupName
    SalesChannel {
      ID
      Description
      DepartmentID
    }
    Source
    Segment {
      ID
      Description
    }
    Registration {
      BillLedger {
        IncidentalCharge {
          Description
          Amount
        }
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RatePolicy {
        ID
        RateCode
        Description
      }
      RegistrationStatus
      NoOfAdult
      NoOfChild
      RatePolicyID
      CheckInDate
      IsPrincipal
      CheckOutDate
      RoomRate
      DiscountAmount
      Room {
        RoomType {
          Code
          Description
          ID
        }
        RoomNo
      }
      Guest {
        Contact {
          FullName
          Nationality
          Address {
            address
            address2
            city
            country
            postCode
            state
            AddressType
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBiAnalysisQuery__
 *
 * To run a query within a React component, call `useBiAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiAnalysisQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBiAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiAnalysisQuery, BiAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<BiAnalysisQuery, BiAnalysisQueryVariables>(BiAnalysisDocument, baseOptions);
      }
export function useBiAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiAnalysisQuery, BiAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiAnalysisQuery, BiAnalysisQueryVariables>(BiAnalysisDocument, baseOptions);
        }
export type BiAnalysisQueryHookResult = ReturnType<typeof useBiAnalysisQuery>;
export type BiAnalysisLazyQueryHookResult = ReturnType<typeof useBiAnalysisLazyQuery>;
export type BiAnalysisQueryResult = ApolloReactCommon.QueryResult<BiAnalysisQuery, BiAnalysisQueryVariables>;
export const BiSourceDocument = gql`
    query BISource($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BISource(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
    }
    ArrivalDate
    DepartureDate
    GroupName
    Source
    Segment {
      ID
      Description
    }
    Registration {
      BillLedger {
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RegistrationStatus
      NoOfAdult
      NoOfChild
      RatePolicyID
      CheckInDate
      IsPrincipal
      CheckOutDate
      RoomRate
      DiscountAmount
    }
  }
}
    `;

/**
 * __useBiSourceQuery__
 *
 * To run a query within a React component, call `useBiSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiSourceQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBiSourceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiSourceQuery, BiSourceQueryVariables>) {
        return ApolloReactHooks.useQuery<BiSourceQuery, BiSourceQueryVariables>(BiSourceDocument, baseOptions);
      }
export function useBiSourceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiSourceQuery, BiSourceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiSourceQuery, BiSourceQueryVariables>(BiSourceDocument, baseOptions);
        }
export type BiSourceQueryHookResult = ReturnType<typeof useBiSourceQuery>;
export type BiSourceLazyQueryHookResult = ReturnType<typeof useBiSourceLazyQuery>;
export type BiSourceQueryResult = ApolloReactCommon.QueryResult<BiSourceQuery, BiSourceQueryVariables>;
export const BiSourceV2Document = gql`
    query BISourceV2($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BISourceV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useBiSourceV2Query__
 *
 * To run a query within a React component, call `useBiSourceV2Query` and pass it any options that fit your needs.
 * When your component renders, `useBiSourceV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiSourceV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiSourceV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiSourceV2Query, BiSourceV2QueryVariables>) {
        return ApolloReactHooks.useQuery<BiSourceV2Query, BiSourceV2QueryVariables>(BiSourceV2Document, baseOptions);
      }
export function useBiSourceV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiSourceV2Query, BiSourceV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiSourceV2Query, BiSourceV2QueryVariables>(BiSourceV2Document, baseOptions);
        }
export type BiSourceV2QueryHookResult = ReturnType<typeof useBiSourceV2Query>;
export type BiSourceV2LazyQueryHookResult = ReturnType<typeof useBiSourceV2LazyQuery>;
export type BiSourceV2QueryResult = ApolloReactCommon.QueryResult<BiSourceV2Query, BiSourceV2QueryVariables>;
export const BiSourceV3Document = gql`
    query BISourceV3($HotelID: String!, $AccountID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BISourceV3(HotelID: $HotelID, AccountID: $AccountID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useBiSourceV3Query__
 *
 * To run a query within a React component, call `useBiSourceV3Query` and pass it any options that fit your needs.
 * When your component renders, `useBiSourceV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiSourceV3Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiSourceV3Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiSourceV3Query, BiSourceV3QueryVariables>) {
        return ApolloReactHooks.useQuery<BiSourceV3Query, BiSourceV3QueryVariables>(BiSourceV3Document, baseOptions);
      }
export function useBiSourceV3LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiSourceV3Query, BiSourceV3QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiSourceV3Query, BiSourceV3QueryVariables>(BiSourceV3Document, baseOptions);
        }
export type BiSourceV3QueryHookResult = ReturnType<typeof useBiSourceV3Query>;
export type BiSourceV3LazyQueryHookResult = ReturnType<typeof useBiSourceV3LazyQuery>;
export type BiSourceV3QueryResult = ApolloReactCommon.QueryResult<BiSourceV3Query, BiSourceV3QueryVariables>;
export const BiTop10CountryV2Document = gql`
    query BITop10CountryV2($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BITop10CountryV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useBiTop10CountryV2Query__
 *
 * To run a query within a React component, call `useBiTop10CountryV2Query` and pass it any options that fit your needs.
 * When your component renders, `useBiTop10CountryV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiTop10CountryV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiTop10CountryV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiTop10CountryV2Query, BiTop10CountryV2QueryVariables>) {
        return ApolloReactHooks.useQuery<BiTop10CountryV2Query, BiTop10CountryV2QueryVariables>(BiTop10CountryV2Document, baseOptions);
      }
export function useBiTop10CountryV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiTop10CountryV2Query, BiTop10CountryV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiTop10CountryV2Query, BiTop10CountryV2QueryVariables>(BiTop10CountryV2Document, baseOptions);
        }
export type BiTop10CountryV2QueryHookResult = ReturnType<typeof useBiTop10CountryV2Query>;
export type BiTop10CountryV2LazyQueryHookResult = ReturnType<typeof useBiTop10CountryV2LazyQuery>;
export type BiTop10CountryV2QueryResult = ApolloReactCommon.QueryResult<BiTop10CountryV2Query, BiTop10CountryV2QueryVariables>;
export const BiTop10CountryDocument = gql`
    query BITop10Country($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BITop10Country(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    ArrivalDate
    DepartureDate
    GroupName
    Registration {
      BillLedger {
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RegistrationStatus
      NoOfAdult
      NoOfChild
      RatePolicyID
      CheckInDate
      IsPrincipal
      CheckOutDate
      RoomRate
      DiscountAmount
      Guest {
        Contact {
          FullName
          Nationality
          Address {
            address
            address2
            city
            country
            postCode
            state
            AddressType
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBiTop10CountryQuery__
 *
 * To run a query within a React component, call `useBiTop10CountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiTop10CountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiTop10CountryQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBiTop10CountryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiTop10CountryQuery, BiTop10CountryQueryVariables>) {
        return ApolloReactHooks.useQuery<BiTop10CountryQuery, BiTop10CountryQueryVariables>(BiTop10CountryDocument, baseOptions);
      }
export function useBiTop10CountryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiTop10CountryQuery, BiTop10CountryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiTop10CountryQuery, BiTop10CountryQueryVariables>(BiTop10CountryDocument, baseOptions);
        }
export type BiTop10CountryQueryHookResult = ReturnType<typeof useBiTop10CountryQuery>;
export type BiTop10CountryLazyQueryHookResult = ReturnType<typeof useBiTop10CountryLazyQuery>;
export type BiTop10CountryQueryResult = ApolloReactCommon.QueryResult<BiTop10CountryQuery, BiTop10CountryQueryVariables>;
export const BiTop10NationalityDocument = gql`
    query BITop10Nationality($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BITop10Nationality(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    ArrivalDate
    DepartureDate
    GroupName
    Registration {
      BillLedger {
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RegistrationStatus
      NoOfAdult
      NoOfChild
      RatePolicyID
      CheckInDate
      IsPrincipal
      CheckOutDate
      RoomRate
      DiscountAmount
      Guest {
        Contact {
          FullName
          Nationality
          Address {
            address
            address2
            city
            country
            postCode
            state
            AddressType
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBiTop10NationalityQuery__
 *
 * To run a query within a React component, call `useBiTop10NationalityQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiTop10NationalityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiTop10NationalityQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBiTop10NationalityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiTop10NationalityQuery, BiTop10NationalityQueryVariables>) {
        return ApolloReactHooks.useQuery<BiTop10NationalityQuery, BiTop10NationalityQueryVariables>(BiTop10NationalityDocument, baseOptions);
      }
export function useBiTop10NationalityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiTop10NationalityQuery, BiTop10NationalityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiTop10NationalityQuery, BiTop10NationalityQueryVariables>(BiTop10NationalityDocument, baseOptions);
        }
export type BiTop10NationalityQueryHookResult = ReturnType<typeof useBiTop10NationalityQuery>;
export type BiTop10NationalityLazyQueryHookResult = ReturnType<typeof useBiTop10NationalityLazyQuery>;
export type BiTop10NationalityQueryResult = ApolloReactCommon.QueryResult<BiTop10NationalityQuery, BiTop10NationalityQueryVariables>;
export const BiTop10NationalityV2Document = gql`
    query BITop10NationalityV2($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BITop10NationalityV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useBiTop10NationalityV2Query__
 *
 * To run a query within a React component, call `useBiTop10NationalityV2Query` and pass it any options that fit your needs.
 * When your component renders, `useBiTop10NationalityV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiTop10NationalityV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiTop10NationalityV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiTop10NationalityV2Query, BiTop10NationalityV2QueryVariables>) {
        return ApolloReactHooks.useQuery<BiTop10NationalityV2Query, BiTop10NationalityV2QueryVariables>(BiTop10NationalityV2Document, baseOptions);
      }
export function useBiTop10NationalityV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiTop10NationalityV2Query, BiTop10NationalityV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiTop10NationalityV2Query, BiTop10NationalityV2QueryVariables>(BiTop10NationalityV2Document, baseOptions);
        }
export type BiTop10NationalityV2QueryHookResult = ReturnType<typeof useBiTop10NationalityV2Query>;
export type BiTop10NationalityV2LazyQueryHookResult = ReturnType<typeof useBiTop10NationalityV2LazyQuery>;
export type BiTop10NationalityV2QueryResult = ApolloReactCommon.QueryResult<BiTop10NationalityV2Query, BiTop10NationalityV2QueryVariables>;
export const BiCorpGovTaDocument = gql`
    query BICorpGovTa($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BICorpGovTa(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
    }
    ArrivalDate
    DepartureDate
    GroupName
    Registration {
      BillLedger {
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RegistrationStatus
      NoOfAdult
      NoOfChild
      RatePolicyID
      CheckInDate
      IsPrincipal
      CheckOutDate
      RoomRate
      DiscountAmount
    }
  }
}
    `;

/**
 * __useBiCorpGovTaQuery__
 *
 * To run a query within a React component, call `useBiCorpGovTaQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiCorpGovTaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiCorpGovTaQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBiCorpGovTaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiCorpGovTaQuery, BiCorpGovTaQueryVariables>) {
        return ApolloReactHooks.useQuery<BiCorpGovTaQuery, BiCorpGovTaQueryVariables>(BiCorpGovTaDocument, baseOptions);
      }
export function useBiCorpGovTaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiCorpGovTaQuery, BiCorpGovTaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiCorpGovTaQuery, BiCorpGovTaQueryVariables>(BiCorpGovTaDocument, baseOptions);
        }
export type BiCorpGovTaQueryHookResult = ReturnType<typeof useBiCorpGovTaQuery>;
export type BiCorpGovTaLazyQueryHookResult = ReturnType<typeof useBiCorpGovTaLazyQuery>;
export type BiCorpGovTaQueryResult = ApolloReactCommon.QueryResult<BiCorpGovTaQuery, BiCorpGovTaQueryVariables>;
export const BiCorpGovTaV2Document = gql`
    query BICorpGovTaV2($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BICorpGovTaV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useBiCorpGovTaV2Query__
 *
 * To run a query within a React component, call `useBiCorpGovTaV2Query` and pass it any options that fit your needs.
 * When your component renders, `useBiCorpGovTaV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiCorpGovTaV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiCorpGovTaV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiCorpGovTaV2Query, BiCorpGovTaV2QueryVariables>) {
        return ApolloReactHooks.useQuery<BiCorpGovTaV2Query, BiCorpGovTaV2QueryVariables>(BiCorpGovTaV2Document, baseOptions);
      }
export function useBiCorpGovTaV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiCorpGovTaV2Query, BiCorpGovTaV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiCorpGovTaV2Query, BiCorpGovTaV2QueryVariables>(BiCorpGovTaV2Document, baseOptions);
        }
export type BiCorpGovTaV2QueryHookResult = ReturnType<typeof useBiCorpGovTaV2Query>;
export type BiCorpGovTaV2LazyQueryHookResult = ReturnType<typeof useBiCorpGovTaV2LazyQuery>;
export type BiCorpGovTaV2QueryResult = ApolloReactCommon.QueryResult<BiCorpGovTaV2Query, BiCorpGovTaV2QueryVariables>;
export const BiMarketingPersonnalDocument = gql`
    query BIMarketingPersonnal($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BIMarketingPersonnal(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    ArrivalDate
    DepartureDate
    GroupName
    SalesChannel {
      ID
      Description
      DepartmentID
    }
    Source
    Registration {
      BillLedger {
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RegistrationStatus
      NoOfAdult
      NoOfChild
      RatePolicyID
      CheckInDate
      IsPrincipal
      CheckOutDate
      RoomRate
      DiscountAmount
    }
  }
}
    `;

/**
 * __useBiMarketingPersonnalQuery__
 *
 * To run a query within a React component, call `useBiMarketingPersonnalQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiMarketingPersonnalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiMarketingPersonnalQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBiMarketingPersonnalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiMarketingPersonnalQuery, BiMarketingPersonnalQueryVariables>) {
        return ApolloReactHooks.useQuery<BiMarketingPersonnalQuery, BiMarketingPersonnalQueryVariables>(BiMarketingPersonnalDocument, baseOptions);
      }
export function useBiMarketingPersonnalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiMarketingPersonnalQuery, BiMarketingPersonnalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiMarketingPersonnalQuery, BiMarketingPersonnalQueryVariables>(BiMarketingPersonnalDocument, baseOptions);
        }
export type BiMarketingPersonnalQueryHookResult = ReturnType<typeof useBiMarketingPersonnalQuery>;
export type BiMarketingPersonnalLazyQueryHookResult = ReturnType<typeof useBiMarketingPersonnalLazyQuery>;
export type BiMarketingPersonnalQueryResult = ApolloReactCommon.QueryResult<BiMarketingPersonnalQuery, BiMarketingPersonnalQueryVariables>;
export const BiRoomTypeRevenueDetailV2Document = gql`
    query BIRoomTypeRevenueDetailV2($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $Mode: String!) {
  BIRoomTypeRevenueDetailV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, Mode: $Mode)
}
    `;

/**
 * __useBiRoomTypeRevenueDetailV2Query__
 *
 * To run a query within a React component, call `useBiRoomTypeRevenueDetailV2Query` and pass it any options that fit your needs.
 * When your component renders, `useBiRoomTypeRevenueDetailV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiRoomTypeRevenueDetailV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      Mode: // value for 'Mode'
 *   },
 * });
 */
export function useBiRoomTypeRevenueDetailV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiRoomTypeRevenueDetailV2Query, BiRoomTypeRevenueDetailV2QueryVariables>) {
        return ApolloReactHooks.useQuery<BiRoomTypeRevenueDetailV2Query, BiRoomTypeRevenueDetailV2QueryVariables>(BiRoomTypeRevenueDetailV2Document, baseOptions);
      }
export function useBiRoomTypeRevenueDetailV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiRoomTypeRevenueDetailV2Query, BiRoomTypeRevenueDetailV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiRoomTypeRevenueDetailV2Query, BiRoomTypeRevenueDetailV2QueryVariables>(BiRoomTypeRevenueDetailV2Document, baseOptions);
        }
export type BiRoomTypeRevenueDetailV2QueryHookResult = ReturnType<typeof useBiRoomTypeRevenueDetailV2Query>;
export type BiRoomTypeRevenueDetailV2LazyQueryHookResult = ReturnType<typeof useBiRoomTypeRevenueDetailV2LazyQuery>;
export type BiRoomTypeRevenueDetailV2QueryResult = ApolloReactCommon.QueryResult<BiRoomTypeRevenueDetailV2Query, BiRoomTypeRevenueDetailV2QueryVariables>;
export const BiRevenueByRoomTypeAnalysisDocument = gql`
    query BIRevenueByRoomTypeAnalysis($HotelID: String!, $AccountID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $Mode: String!) {
  BIRevenueByRoomTypeAnalysis(HotelID: $HotelID, AccountID: $AccountID, StartDate: $StartDate, EndDate: $EndDate, Mode: $Mode)
}
    `;

/**
 * __useBiRevenueByRoomTypeAnalysisQuery__
 *
 * To run a query within a React component, call `useBiRevenueByRoomTypeAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiRevenueByRoomTypeAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiRevenueByRoomTypeAnalysisQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      Mode: // value for 'Mode'
 *   },
 * });
 */
export function useBiRevenueByRoomTypeAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiRevenueByRoomTypeAnalysisQuery, BiRevenueByRoomTypeAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<BiRevenueByRoomTypeAnalysisQuery, BiRevenueByRoomTypeAnalysisQueryVariables>(BiRevenueByRoomTypeAnalysisDocument, baseOptions);
      }
export function useBiRevenueByRoomTypeAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiRevenueByRoomTypeAnalysisQuery, BiRevenueByRoomTypeAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiRevenueByRoomTypeAnalysisQuery, BiRevenueByRoomTypeAnalysisQueryVariables>(BiRevenueByRoomTypeAnalysisDocument, baseOptions);
        }
export type BiRevenueByRoomTypeAnalysisQueryHookResult = ReturnType<typeof useBiRevenueByRoomTypeAnalysisQuery>;
export type BiRevenueByRoomTypeAnalysisLazyQueryHookResult = ReturnType<typeof useBiRevenueByRoomTypeAnalysisLazyQuery>;
export type BiRevenueByRoomTypeAnalysisQueryResult = ApolloReactCommon.QueryResult<BiRevenueByRoomTypeAnalysisQuery, BiRevenueByRoomTypeAnalysisQueryVariables>;
export const BiMarketingPersonnalV2Document = gql`
    query BIMarketingPersonnalV2($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIMarketingPersonnalV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useBiMarketingPersonnalV2Query__
 *
 * To run a query within a React component, call `useBiMarketingPersonnalV2Query` and pass it any options that fit your needs.
 * When your component renders, `useBiMarketingPersonnalV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiMarketingPersonnalV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiMarketingPersonnalV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiMarketingPersonnalV2Query, BiMarketingPersonnalV2QueryVariables>) {
        return ApolloReactHooks.useQuery<BiMarketingPersonnalV2Query, BiMarketingPersonnalV2QueryVariables>(BiMarketingPersonnalV2Document, baseOptions);
      }
export function useBiMarketingPersonnalV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiMarketingPersonnalV2Query, BiMarketingPersonnalV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiMarketingPersonnalV2Query, BiMarketingPersonnalV2QueryVariables>(BiMarketingPersonnalV2Document, baseOptions);
        }
export type BiMarketingPersonnalV2QueryHookResult = ReturnType<typeof useBiMarketingPersonnalV2Query>;
export type BiMarketingPersonnalV2LazyQueryHookResult = ReturnType<typeof useBiMarketingPersonnalV2LazyQuery>;
export type BiMarketingPersonnalV2QueryResult = ApolloReactCommon.QueryResult<BiMarketingPersonnalV2Query, BiMarketingPersonnalV2QueryVariables>;
export const BiRoomTypeRevenueDetailDocument = gql`
    query BIRoomTypeRevenueDetail($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BIRoomTypeRevenueDetail(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
    }
    ArrivalDate
    DepartureDate
    GroupName
    SalesChannel {
      ID
      Description
      DepartmentID
    }
    Source
    Segment {
      ID
      Description
    }
    Registration {
      BillLedger {
        IncidentalCharge {
          Description
          Amount
        }
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RatePolicy {
        ID
        RateCode
        Description
      }
      RegistrationStatus
      NoOfAdult
      NoOfChild
      RatePolicyID
      CheckInDate
      IsPrincipal
      CheckOutDate
      RoomRate
      DiscountAmount
      Room {
        RoomType {
          Code
          Description
          ID
        }
        RoomNo
      }
      Guest {
        Contact {
          FullName
          Nationality
          Address {
            address
            address2
            city
            country
            postCode
            state
            AddressType
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBiRoomTypeRevenueDetailQuery__
 *
 * To run a query within a React component, call `useBiRoomTypeRevenueDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiRoomTypeRevenueDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiRoomTypeRevenueDetailQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBiRoomTypeRevenueDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiRoomTypeRevenueDetailQuery, BiRoomTypeRevenueDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<BiRoomTypeRevenueDetailQuery, BiRoomTypeRevenueDetailQueryVariables>(BiRoomTypeRevenueDetailDocument, baseOptions);
      }
export function useBiRoomTypeRevenueDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiRoomTypeRevenueDetailQuery, BiRoomTypeRevenueDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiRoomTypeRevenueDetailQuery, BiRoomTypeRevenueDetailQueryVariables>(BiRoomTypeRevenueDetailDocument, baseOptions);
        }
export type BiRoomTypeRevenueDetailQueryHookResult = ReturnType<typeof useBiRoomTypeRevenueDetailQuery>;
export type BiRoomTypeRevenueDetailLazyQueryHookResult = ReturnType<typeof useBiRoomTypeRevenueDetailLazyQuery>;
export type BiRoomTypeRevenueDetailQueryResult = ApolloReactCommon.QueryResult<BiRoomTypeRevenueDetailQuery, BiRoomTypeRevenueDetailQueryVariables>;
export const BiTop5OtaDocument = gql`
    query BITop5OTA($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BITop5OTA(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    ArrivalDate
    DepartureDate
    GroupName
    Source
    Segment {
      ID
      Description
    }
    Registration {
      BillLedger {
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RegistrationStatus
      NoOfAdult
      NoOfChild
      RatePolicyID
      CheckInDate
      IsPrincipal
      CheckOutDate
      RoomRate
      DiscountAmount
    }
  }
}
    `;

/**
 * __useBiTop5OtaQuery__
 *
 * To run a query within a React component, call `useBiTop5OtaQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiTop5OtaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiTop5OtaQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBiTop5OtaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiTop5OtaQuery, BiTop5OtaQueryVariables>) {
        return ApolloReactHooks.useQuery<BiTop5OtaQuery, BiTop5OtaQueryVariables>(BiTop5OtaDocument, baseOptions);
      }
export function useBiTop5OtaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiTop5OtaQuery, BiTop5OtaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiTop5OtaQuery, BiTop5OtaQueryVariables>(BiTop5OtaDocument, baseOptions);
        }
export type BiTop5OtaQueryHookResult = ReturnType<typeof useBiTop5OtaQuery>;
export type BiTop5OtaLazyQueryHookResult = ReturnType<typeof useBiTop5OtaLazyQuery>;
export type BiTop5OtaQueryResult = ApolloReactCommon.QueryResult<BiTop5OtaQuery, BiTop5OtaQueryVariables>;
export const BiSegmentDocument = gql`
    query BISegment($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BISegment(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    ArrivalDate
    DepartureDate
    GroupName
    Segment {
      ID
      Description
    }
    Registration {
      BillLedger {
        IncidentalCharge {
          Description
          Amount
        }
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RegistrationStatus
      CheckInDate
      IsPrincipal
      CheckOutDate
      RoomRate
      DiscountAmount
      NoOfAdult
      NoOfChild
    }
  }
}
    `;

/**
 * __useBiSegmentQuery__
 *
 * To run a query within a React component, call `useBiSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiSegmentQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBiSegmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiSegmentQuery, BiSegmentQueryVariables>) {
        return ApolloReactHooks.useQuery<BiSegmentQuery, BiSegmentQueryVariables>(BiSegmentDocument, baseOptions);
      }
export function useBiSegmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiSegmentQuery, BiSegmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiSegmentQuery, BiSegmentQueryVariables>(BiSegmentDocument, baseOptions);
        }
export type BiSegmentQueryHookResult = ReturnType<typeof useBiSegmentQuery>;
export type BiSegmentLazyQueryHookResult = ReturnType<typeof useBiSegmentLazyQuery>;
export type BiSegmentQueryResult = ApolloReactCommon.QueryResult<BiSegmentQuery, BiSegmentQueryVariables>;
export const BiSegmentV2Document = gql`
    query BISegmentV2($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BISegmentV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useBiSegmentV2Query__
 *
 * To run a query within a React component, call `useBiSegmentV2Query` and pass it any options that fit your needs.
 * When your component renders, `useBiSegmentV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiSegmentV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiSegmentV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiSegmentV2Query, BiSegmentV2QueryVariables>) {
        return ApolloReactHooks.useQuery<BiSegmentV2Query, BiSegmentV2QueryVariables>(BiSegmentV2Document, baseOptions);
      }
export function useBiSegmentV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiSegmentV2Query, BiSegmentV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiSegmentV2Query, BiSegmentV2QueryVariables>(BiSegmentV2Document, baseOptions);
        }
export type BiSegmentV2QueryHookResult = ReturnType<typeof useBiSegmentV2Query>;
export type BiSegmentV2LazyQueryHookResult = ReturnType<typeof useBiSegmentV2LazyQuery>;
export type BiSegmentV2QueryResult = ApolloReactCommon.QueryResult<BiSegmentV2Query, BiSegmentV2QueryVariables>;
export const BiTop10RevenueDocument = gql`
    query BITop10Revenue($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $DebtorType: String) {
  BITop10Revenue(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, DebtorType: $DebtorType) {
    BookingNo
    BookingStatus
    CreatedDT
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
    }
    ArrivalDate
    DepartureDate
    GroupName
    Source
    Registration {
      BillLedger {
        IncidentalCharge {
          Description
          Amount
        }
        BaseAmount
        TrxAmount
        TaxAmount
        Description
        Qty
        ServiceCharge
        DiscountAmount
      }
      RegistrationStatus
      RatePolicyID
      CheckInDate
      IsPrincipal
      CheckOutDate
      RoomRate
      DiscountAmount
      Room {
        RoomType {
          Code
          Description
          ID
        }
        RoomNo
      }
    }
  }
}
    `;

/**
 * __useBiTop10RevenueQuery__
 *
 * To run a query within a React component, call `useBiTop10RevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiTop10RevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiTop10RevenueQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useBiTop10RevenueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiTop10RevenueQuery, BiTop10RevenueQueryVariables>) {
        return ApolloReactHooks.useQuery<BiTop10RevenueQuery, BiTop10RevenueQueryVariables>(BiTop10RevenueDocument, baseOptions);
      }
export function useBiTop10RevenueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiTop10RevenueQuery, BiTop10RevenueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiTop10RevenueQuery, BiTop10RevenueQueryVariables>(BiTop10RevenueDocument, baseOptions);
        }
export type BiTop10RevenueQueryHookResult = ReturnType<typeof useBiTop10RevenueQuery>;
export type BiTop10RevenueLazyQueryHookResult = ReturnType<typeof useBiTop10RevenueLazyQuery>;
export type BiTop10RevenueQueryResult = ApolloReactCommon.QueryResult<BiTop10RevenueQuery, BiTop10RevenueQueryVariables>;
export const BiHotelDetailsDocument = gql`
    query BIHotelDetails($HotelID: String!) {
  BIHotelDetails(HotelID: $HotelID) {
    ID
    IsActive
    CreatedBy
    ModifiedBy
    AccountID
    EmailBlastDays
    HotelAddress {
      address
      postCode
      state
      city
      country
    }
    SSTRegNo
    CompanyRegNo
    Logo
    HotelType
    HotelName
    ContactID
    CheckInTime
    CheckOutTime
    Currency
    NAProcessTime
    IsPostPaid
    Tax
    ServiceCharge
    IsInclusive
    TTx
    HTx
    Levy
    CheckInPrompt
    SecurityDeposit
    KeycardDeposit
    CheckInRoomStatusID
    CancelCheckInRoomStatusID
    TransferRoomStatusID
    CheckOutRoomStatusID
    CancelCheckOutRoomStatusID
    BlockRoomReleaseStatusID
    CeilingPercentage
    FloorPercentage
    DayUseID
    HouseUseID
    ComplimentaryID
    FloorPriceKickInDays
    AllowCancellationDays
    OnlineTnC
    RegCardTnC
    OnlineBookingURL
    AboutHotelGalleryID
    AboutHotel
    ARSpecification {
      InterestAccount
    }
    ChannelManagerID
    PaymentGatewayID
    Contact {
      FullName
      PhoneNo
      MobileNo
      GeneralEmail
      ReservationEmail
      BusinessEmail
      WebUrl
      NotificationMobileNo
    }
    Facility {
      ID
      HotelID
      IsActive
      Title
      Description
      Level
      FacilityGallery {
        ID
        FacilityID
        GalleryID
        Gallery {
          ID
          ImageURL
          BucketFileName
        }
      }
    }
    News {
      ID
      IsActive
      Description
      Title
      NewsGallery {
        ID
        Gallery {
          ID
          ImageURL
          BucketFileName
        }
      }
      ID
      HotelID
      Title
      Description
      IsActive
    }
    DayUse {
      ID
      RateCode
      Description
    }
    HouseUse {
      ID
      RateCode
      Description
    }
    Complimentary {
      ID
      RateCode
      Description
    }
    AboutHotelImage {
      ID
      ImageURL
      BucketFileName
    }
    LogoImage {
      ID
      ImageURL
      BucketFileName
    }
  }
}
    `;

/**
 * __useBiHotelDetailsQuery__
 *
 * To run a query within a React component, call `useBiHotelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiHotelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiHotelDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBiHotelDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiHotelDetailsQuery, BiHotelDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<BiHotelDetailsQuery, BiHotelDetailsQueryVariables>(BiHotelDetailsDocument, baseOptions);
      }
export function useBiHotelDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiHotelDetailsQuery, BiHotelDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiHotelDetailsQuery, BiHotelDetailsQueryVariables>(BiHotelDetailsDocument, baseOptions);
        }
export type BiHotelDetailsQueryHookResult = ReturnType<typeof useBiHotelDetailsQuery>;
export type BiHotelDetailsLazyQueryHookResult = ReturnType<typeof useBiHotelDetailsLazyQuery>;
export type BiHotelDetailsQueryResult = ApolloReactCommon.QueryResult<BiHotelDetailsQuery, BiHotelDetailsQueryVariables>;
export const BiServiceRequestListingDocument = gql`
    query BIServiceRequestListing($HotelID: String!, $StartDate: DateTime, $EndDate: DateTime, $Department: String) {
  BIServiceRequestListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate, Department: $Department) {
    ID
    Description
    RegistrationID
    RequestBy
    CreatedDT
    Title
    Remarks
    Status
    EstimatedCompletion
    Registration {
      ID
      Guest {
        Contact {
          FullName
        }
      }
    }
    Department {
      ID
      Code
      Name
    }
    Room {
      RoomNo
      Registrations {
        CheckInDate
        CheckOutDate
        ID
        IsPrincipal
        Guest {
          ID
          Contact {
            FullName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBiServiceRequestListingQuery__
 *
 * To run a query within a React component, call `useBiServiceRequestListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiServiceRequestListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiServiceRequestListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      Department: // value for 'Department'
 *   },
 * });
 */
export function useBiServiceRequestListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiServiceRequestListingQuery, BiServiceRequestListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiServiceRequestListingQuery, BiServiceRequestListingQueryVariables>(BiServiceRequestListingDocument, baseOptions);
      }
export function useBiServiceRequestListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiServiceRequestListingQuery, BiServiceRequestListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiServiceRequestListingQuery, BiServiceRequestListingQueryVariables>(BiServiceRequestListingDocument, baseOptions);
        }
export type BiServiceRequestListingQueryHookResult = ReturnType<typeof useBiServiceRequestListingQuery>;
export type BiServiceRequestListingLazyQueryHookResult = ReturnType<typeof useBiServiceRequestListingLazyQuery>;
export type BiServiceRequestListingQueryResult = ApolloReactCommon.QueryResult<BiServiceRequestListingQuery, BiServiceRequestListingQueryVariables>;
export const BiRoomListingDocument = gql`
    query BIRoomListing($HotelID: String!) {
  BIRoomListing(HotelID: $HotelID) {
    ID
    IsActive
    HotelID
    LocationID
    RoomTypeID
    RoomNo
    MaxGuest
    RoomSize
    DisplaySequence
    Description
  }
}
    `;

/**
 * __useBiRoomListingQuery__
 *
 * To run a query within a React component, call `useBiRoomListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiRoomListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiRoomListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBiRoomListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiRoomListingQuery, BiRoomListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiRoomListingQuery, BiRoomListingQueryVariables>(BiRoomListingDocument, baseOptions);
      }
export function useBiRoomListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiRoomListingQuery, BiRoomListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiRoomListingQuery, BiRoomListingQueryVariables>(BiRoomListingDocument, baseOptions);
        }
export type BiRoomListingQueryHookResult = ReturnType<typeof useBiRoomListingQuery>;
export type BiRoomListingLazyQueryHookResult = ReturnType<typeof useBiRoomListingLazyQuery>;
export type BiRoomListingQueryResult = ApolloReactCommon.QueryResult<BiRoomListingQuery, BiRoomListingQueryVariables>;
export const ManagerReportDocument = gql`
    query ManagerReport($HotelID: String!, $EffectiveDate: DateTime!) {
  ManagerReport(HotelID: $HotelID, EffectiveDate: $EffectiveDate) {
    SegmentReport {
      SegmentID
      SegmentName
      SegmentYRoomCount
      SegmentYGuestCount
      SegmentYRoomRevenue
      SegmentYOtherRevenue
      SegmentYARR
      SegmentMRoomCount
      SegmentMGuestCount
      SegmentMRoomRevenue
      SegmentMOtherRevenue
      SegmentMARR
      SegmentDRoomCount
      SegmentDGuestCount
      SegmentDRoomRevenue
      SegmentDOtherRevenue
      SegmentDARR
    }
    SourceReport {
      SourceName
      SourceYRoomCount
      SourceYGuestCount
      SourceYRoomRevenue
      SourceYOtherRevenue
      SourceYARR
      SourceMRoomCount
      SourceMGuestCount
      SourceMRoomRevenue
      SourceMOtherRevenue
      SourceMARR
      SourceDRoomCount
      SourceDGuestCount
      SourceDRoomRevenue
      SourceDOtherRevenue
      SourceDARR
    }
    CountryReport {
      CountryName
      CountryYRoomCount
      CountryYGuestCount
      CountryYRoomRevenue
      CountryYOtherRevenue
      CountryYARR
      CountryMRoomCount
      CountryMGuestCount
      CountryMRoomRevenue
      CountryMOtherRevenue
      CountryMARR
      CountryDRoomCount
      CountryDGuestCount
      CountryDRoomRevenue
      CountryDOtherRevenue
      CountryDARR
    }
  }
}
    `;

/**
 * __useManagerReportQuery__
 *
 * To run a query within a React component, call `useManagerReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerReportQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      EffectiveDate: // value for 'EffectiveDate'
 *   },
 * });
 */
export function useManagerReportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ManagerReportQuery, ManagerReportQueryVariables>) {
        return ApolloReactHooks.useQuery<ManagerReportQuery, ManagerReportQueryVariables>(ManagerReportDocument, baseOptions);
      }
export function useManagerReportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ManagerReportQuery, ManagerReportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ManagerReportQuery, ManagerReportQueryVariables>(ManagerReportDocument, baseOptions);
        }
export type ManagerReportQueryHookResult = ReturnType<typeof useManagerReportQuery>;
export type ManagerReportLazyQueryHookResult = ReturnType<typeof useManagerReportLazyQuery>;
export type ManagerReportQueryResult = ApolloReactCommon.QueryResult<ManagerReportQuery, ManagerReportQueryVariables>;
export const BiTransactionCancellationDocument = gql`
    query BITransactionCancellation($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BITransactionCancellation(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    CreatedBy
    ID
    RegistrationID
    Registration {
      Booking {
        ID
        BookingNo
      }
      Guest {
        Contact {
          FullName
        }
      }
      Room {
        RoomNo
      }
    }
    IncidentalCharge {
      ID
      Description
      DepartmentID
      Department {
        Code
        Name
      }
    }
    TrxDate
    Qty
    UnitPrice
    TrxAmount
    BaseAmount
    TaxAmount
    ServiceCharge
    Description
    ReferenceNo
    PaymentID
    Reason
  }
}
    `;

/**
 * __useBiTransactionCancellationQuery__
 *
 * To run a query within a React component, call `useBiTransactionCancellationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiTransactionCancellationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiTransactionCancellationQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiTransactionCancellationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiTransactionCancellationQuery, BiTransactionCancellationQueryVariables>) {
        return ApolloReactHooks.useQuery<BiTransactionCancellationQuery, BiTransactionCancellationQueryVariables>(BiTransactionCancellationDocument, baseOptions);
      }
export function useBiTransactionCancellationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiTransactionCancellationQuery, BiTransactionCancellationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiTransactionCancellationQuery, BiTransactionCancellationQueryVariables>(BiTransactionCancellationDocument, baseOptions);
        }
export type BiTransactionCancellationQueryHookResult = ReturnType<typeof useBiTransactionCancellationQuery>;
export type BiTransactionCancellationLazyQueryHookResult = ReturnType<typeof useBiTransactionCancellationLazyQuery>;
export type BiTransactionCancellationQueryResult = ApolloReactCommon.QueryResult<BiTransactionCancellationQuery, BiTransactionCancellationQueryVariables>;
export const BiForeignListingDocument = gql`
    query BIForeignListing($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIForeignListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    ArrivalDate
    DepartureDate
    Registration {
      ID
      IsPrincipal
      TaxLedger {
        ID
        TaxAmount
        TaxType
        TaxDescription
      }
      Room {
        RoomNo
      }
      Guest {
        Contact {
          ID
          FullName
          Nationality
          MobileNo
          PassportNo
          Address {
            address
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBiForeignListingQuery__
 *
 * To run a query within a React component, call `useBiForeignListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiForeignListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiForeignListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiForeignListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiForeignListingQuery, BiForeignListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BiForeignListingQuery, BiForeignListingQueryVariables>(BiForeignListingDocument, baseOptions);
      }
export function useBiForeignListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiForeignListingQuery, BiForeignListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiForeignListingQuery, BiForeignListingQueryVariables>(BiForeignListingDocument, baseOptions);
        }
export type BiForeignListingQueryHookResult = ReturnType<typeof useBiForeignListingQuery>;
export type BiForeignListingLazyQueryHookResult = ReturnType<typeof useBiForeignListingLazyQuery>;
export type BiForeignListingQueryResult = ApolloReactCommon.QueryResult<BiForeignListingQuery, BiForeignListingQueryVariables>;
export const BiSecurityKeyCardDepositDocument = gql`
    query BISecurityKeyCardDeposit($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BISecurityKeyCardDeposit(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    TrxDate
    ReferenceNo
    DepositAmount
    DepositClass
    ReversedID
    CreatedBy
    Folio {
      FolioAmount
      FolioNo
    }
    Registration {
      ID
      Guest {
        Contact {
          FullName
        }
      }
      Room {
        RoomNo
      }
    }
  }
}
    `;

/**
 * __useBiSecurityKeyCardDepositQuery__
 *
 * To run a query within a React component, call `useBiSecurityKeyCardDepositQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiSecurityKeyCardDepositQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiSecurityKeyCardDepositQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiSecurityKeyCardDepositQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiSecurityKeyCardDepositQuery, BiSecurityKeyCardDepositQueryVariables>) {
        return ApolloReactHooks.useQuery<BiSecurityKeyCardDepositQuery, BiSecurityKeyCardDepositQueryVariables>(BiSecurityKeyCardDepositDocument, baseOptions);
      }
export function useBiSecurityKeyCardDepositLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiSecurityKeyCardDepositQuery, BiSecurityKeyCardDepositQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiSecurityKeyCardDepositQuery, BiSecurityKeyCardDepositQueryVariables>(BiSecurityKeyCardDepositDocument, baseOptions);
        }
export type BiSecurityKeyCardDepositQueryHookResult = ReturnType<typeof useBiSecurityKeyCardDepositQuery>;
export type BiSecurityKeyCardDepositLazyQueryHookResult = ReturnType<typeof useBiSecurityKeyCardDepositLazyQuery>;
export type BiSecurityKeyCardDepositQueryResult = ApolloReactCommon.QueryResult<BiSecurityKeyCardDepositQuery, BiSecurityKeyCardDepositQueryVariables>;
export const BiRoomDiscrepancyLogDocument = gql`
    query BIRoomDiscrepancyLog($HotelID: String!) {
  BIRoomDiscrepancyLog(HotelID: $HotelID) {
    ID
    DiscrepancyReason
    CreatedBy
    CreatedDT
    ModifiedDT
    RoomStatus {
      ID
      Description
      Code
    }
    HouseKeepingStatus {
      ID
      Description
      Code
    }
    Room {
      RoomNo
      Location {
        Description
      }
      RoomType {
        Code
      }
    }
  }
}
    `;

/**
 * __useBiRoomDiscrepancyLogQuery__
 *
 * To run a query within a React component, call `useBiRoomDiscrepancyLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiRoomDiscrepancyLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiRoomDiscrepancyLogQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBiRoomDiscrepancyLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiRoomDiscrepancyLogQuery, BiRoomDiscrepancyLogQueryVariables>) {
        return ApolloReactHooks.useQuery<BiRoomDiscrepancyLogQuery, BiRoomDiscrepancyLogQueryVariables>(BiRoomDiscrepancyLogDocument, baseOptions);
      }
export function useBiRoomDiscrepancyLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiRoomDiscrepancyLogQuery, BiRoomDiscrepancyLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiRoomDiscrepancyLogQuery, BiRoomDiscrepancyLogQueryVariables>(BiRoomDiscrepancyLogDocument, baseOptions);
        }
export type BiRoomDiscrepancyLogQueryHookResult = ReturnType<typeof useBiRoomDiscrepancyLogQuery>;
export type BiRoomDiscrepancyLogLazyQueryHookResult = ReturnType<typeof useBiRoomDiscrepancyLogLazyQuery>;
export type BiRoomDiscrepancyLogQueryResult = ApolloReactCommon.QueryResult<BiRoomDiscrepancyLogQuery, BiRoomDiscrepancyLogQueryVariables>;
export const BiRoomStatusDocument = gql`
    query BIRoomStatus($HotelID: String!, $IsActive: Boolean) {
  BIRoomStatus(HotelID: $HotelID, IsActive: $IsActive) {
    ID
    RoomNo
    Location {
      Code
    }
    RoomType {
      Code
    }
    RoomStatus {
      Code
    }
    Registrations {
      RegistrationStatus
      SpecialRequest
      IsPrincipal
      Booking {
        ArrivalDate
        DepartureDate
        SpecialRequest
      }
      Guest {
        Contact {
          FullName
        }
      }
    }
  }
}
    `;

/**
 * __useBiRoomStatusQuery__
 *
 * To run a query within a React component, call `useBiRoomStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiRoomStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiRoomStatusQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useBiRoomStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiRoomStatusQuery, BiRoomStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<BiRoomStatusQuery, BiRoomStatusQueryVariables>(BiRoomStatusDocument, baseOptions);
      }
export function useBiRoomStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiRoomStatusQuery, BiRoomStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiRoomStatusQuery, BiRoomStatusQueryVariables>(BiRoomStatusDocument, baseOptions);
        }
export type BiRoomStatusQueryHookResult = ReturnType<typeof useBiRoomStatusQuery>;
export type BiRoomStatusLazyQueryHookResult = ReturnType<typeof useBiRoomStatusLazyQuery>;
export type BiRoomStatusQueryResult = ApolloReactCommon.QueryResult<BiRoomStatusQuery, BiRoomStatusQueryVariables>;
export const BiBookingStatusDocument = gql`
    query BIBookingStatus($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIBookingStatus(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    GroupName
    ArrivalDate
    DepartureDate
    BookingStatus
    BookingNo
    TotalAdvPayment
    TotalRoomRate
    SpecialRequest
    AdvancePayment {
      PaymentStatus
      Amount
    }
    Debtor {
      Name
    }
    Segment {
      Description
    }
    Registration {
      IsPrincipal
      NoOfAdult
      RoomRate
      DiscountAmount
      SpecialRequest
      DepositSum
      TotalRoomPrice
      TotalRoomTax
      Payment {
        PaymentStatus
      }
      Room {
        RoomNo
      }
      RoomType {
        Code
      }
      Guest {
        Contact {
          FullName
          Nationality
        }
      }
    }
    CreatedDT
    Contact {
      FullName
      Nationality
    }
  }
}
    `;

/**
 * __useBiBookingStatusQuery__
 *
 * To run a query within a React component, call `useBiBookingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiBookingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiBookingStatusQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiBookingStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiBookingStatusQuery, BiBookingStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<BiBookingStatusQuery, BiBookingStatusQueryVariables>(BiBookingStatusDocument, baseOptions);
      }
export function useBiBookingStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiBookingStatusQuery, BiBookingStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiBookingStatusQuery, BiBookingStatusQueryVariables>(BiBookingStatusDocument, baseOptions);
        }
export type BiBookingStatusQueryHookResult = ReturnType<typeof useBiBookingStatusQuery>;
export type BiBookingStatusLazyQueryHookResult = ReturnType<typeof useBiBookingStatusLazyQuery>;
export type BiBookingStatusQueryResult = ApolloReactCommon.QueryResult<BiBookingStatusQuery, BiBookingStatusQueryVariables>;
export const BiTransactionLedgerDocument = gql`
    query BITransactionLedger($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BITransactionLedger(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    BookingNo
    Registration {
      IsPrincipal
      Guest {
        Contact {
          FullName
        }
      }
      Room {
        RoomNo
      }
      RoomLedger {
        ID
        TrxAmount
        TaxAmount
        TrxDate
        BaseAmount
        TransactionID
        Transaction {
          ID
          Description
        }
        ServiceCharge
        ReferenceNo
        ReversedRoomLedgerID
        ChargeType
        Description
        CreatedBy
      }
      TaxLedger {
        ReversedTaxLedgerID
        TrxDate
        TaxType
        TaxDescription
        TaxAmount
        CreatedBy
      }
      BillLedger {
        ID
        TrxAmount
        TaxAmount
        TrxDate
        BaseAmount
        ServiceCharge
        DiscountAmount
        ReferenceNo
        ReversedBillLedgerID
        Description
        CreatedBy
        IncidentalCharge {
          Description
        }
      }
    }
  }
}
    `;

/**
 * __useBiTransactionLedgerQuery__
 *
 * To run a query within a React component, call `useBiTransactionLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiTransactionLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiTransactionLedgerQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiTransactionLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiTransactionLedgerQuery, BiTransactionLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<BiTransactionLedgerQuery, BiTransactionLedgerQueryVariables>(BiTransactionLedgerDocument, baseOptions);
      }
export function useBiTransactionLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiTransactionLedgerQuery, BiTransactionLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiTransactionLedgerQuery, BiTransactionLedgerQueryVariables>(BiTransactionLedgerDocument, baseOptions);
        }
export type BiTransactionLedgerQueryHookResult = ReturnType<typeof useBiTransactionLedgerQuery>;
export type BiTransactionLedgerLazyQueryHookResult = ReturnType<typeof useBiTransactionLedgerLazyQuery>;
export type BiTransactionLedgerQueryResult = ApolloReactCommon.QueryResult<BiTransactionLedgerQuery, BiTransactionLedgerQueryVariables>;
export const BiPackageDocument = gql`
    query BIPackage($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  BIPackage(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    TransactionType
    TrxDate
    Registration {
      ID
      RoomID
      NoOfAdult
      NoOfChild
      NoOfInfant
      Booking {
        ArrivalDate
        DepartureDate
        BookingStatus
      }
      Room {
        RoomNo
      }
      Guest {
        Contact {
          FullName
        }
      }
    }
    Transaction {
      ID
      Description
    }
  }
}
    `;

/**
 * __useBiPackageQuery__
 *
 * To run a query within a React component, call `useBiPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiPackageQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiPackageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiPackageQuery, BiPackageQueryVariables>) {
        return ApolloReactHooks.useQuery<BiPackageQuery, BiPackageQueryVariables>(BiPackageDocument, baseOptions);
      }
export function useBiPackageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiPackageQuery, BiPackageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiPackageQuery, BiPackageQueryVariables>(BiPackageDocument, baseOptions);
        }
export type BiPackageQueryHookResult = ReturnType<typeof useBiPackageQuery>;
export type BiPackageLazyQueryHookResult = ReturnType<typeof useBiPackageLazyQuery>;
export type BiPackageQueryResult = ApolloReactCommon.QueryResult<BiPackageQuery, BiPackageQueryVariables>;
export const BiInHouseDocument = gql`
    query BIInHouse($HotelID: String!) {
  BIInHouse(HotelID: $HotelID) {
    ID
    SpecialRequest
    NoOfAdult
    NoOfChild
    RoomRate
    CheckInDate
    CheckOutDate
    RoomLedger {
      PaymentID
      ReversedRoomLedgerID
      BaseAmount
      ServiceCharge
      TaxAmount
    }
    BillSchedule {
      IsPosted
      ReversedBillScheduleID
      AdvancePaymentID
      Reason
      BaseAmount
      ServiceCharge
      TaxAmount
    }
    BillLedger {
      PaymentID
      ReversedBillLedgerID
      Reason
      BaseAmount
      ServiceCharge
      TaxAmount
    }
    TaxLedger {
      TaxAmount
    }
    AdvancePayment {
      IsPosted
      PaymentStatus
      ReversedAdvancePaymentID
      Amount
    }
    RatePolicy {
      RateCode
    }
    Guest {
      VehicleNo
      Contact {
        FullName
        Nationality
        MobileNo
      }
    }
    Booking {
      GroupName
      ArrivalDate
      DepartureDate
      IsGroupBooking
      SpecialRequest
      Registration {
        ID
      }
      Debtor {
        Name
      }
    }
    RoomType {
      Code
    }
    Room {
      RoomNo
    }
  }
}
    `;

/**
 * __useBiInHouseQuery__
 *
 * To run a query within a React component, call `useBiInHouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiInHouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiInHouseQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBiInHouseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiInHouseQuery, BiInHouseQueryVariables>) {
        return ApolloReactHooks.useQuery<BiInHouseQuery, BiInHouseQueryVariables>(BiInHouseDocument, baseOptions);
      }
export function useBiInHouseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiInHouseQuery, BiInHouseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiInHouseQuery, BiInHouseQueryVariables>(BiInHouseDocument, baseOptions);
        }
export type BiInHouseQueryHookResult = ReturnType<typeof useBiInHouseQuery>;
export type BiInHouseLazyQueryHookResult = ReturnType<typeof useBiInHouseLazyQuery>;
export type BiInHouseQueryResult = ApolloReactCommon.QueryResult<BiInHouseQuery, BiInHouseQueryVariables>;
export const ManagerReportDepartmentDocument = gql`
    query ManagerReportDepartment($HotelID: String!, $EffectiveDate: DateTime!) {
  ManagerReportDepartment(HotelID: $HotelID, EffectiveDate: $EffectiveDate) {
    DepartmentReport {
      DepartmentName
      TransactionName
      TransactionDAmount
      TransactionMAmount
      TransactionYAmount
    }
    TaxReport {
      DepartmentName
      TransactionName
      TransactionDAmount
      TransactionMAmount
      TransactionYAmount
    }
    PaymentReport {
      DepartmentName
      TransactionName
      TransactionDAmount
      TransactionMAmount
      TransactionYAmount
    }
    BookingCancellation {
      GuestName
      RoomNo
      NoOfGuest
      ArrivalDate
      DepartureDate
      NewExtendDate
      SpecialRequest
      RateAmount
      BookingNo
    }
    VIPInHouse {
      GuestName
      RoomNo
      NoOfGuest
      ArrivalDate
      DepartureDate
      SpecialRequest
    }
    GroupInHouse {
      GroupName
      NoOfRoom
      NoOfGuest
      ArrivalDate
      DepartureDate
      SpecialRequest
    }
    TomorrowGroup {
      GroupName
      NoOfRoom
      NoOfGuest
      ArrivalDate
      DepartureDate
      SpecialRequest
    }
    HouseUseRoom {
      GuestName
      RoomNo
      NoOfGuest
      ArrivalDate
      DepartureDate
      SpecialRequest
    }
    CompRoom {
      GuestName
      RoomNo
      NoOfGuest
      ArrivalDate
      DepartureDate
      SpecialRequest
    }
    ExtendStay {
      GuestName
      RoomNo
      NoOfGuest
      ArrivalDate
      DepartureDate
      NewExtendDate
      SpecialRequest
    }
    ThreeDayForcast {
      Category
      TodayDate
      TomorrowDate
      DayAfterTomorrow
    }
  }
}
    `;

/**
 * __useManagerReportDepartmentQuery__
 *
 * To run a query within a React component, call `useManagerReportDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerReportDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerReportDepartmentQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      EffectiveDate: // value for 'EffectiveDate'
 *   },
 * });
 */
export function useManagerReportDepartmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ManagerReportDepartmentQuery, ManagerReportDepartmentQueryVariables>) {
        return ApolloReactHooks.useQuery<ManagerReportDepartmentQuery, ManagerReportDepartmentQueryVariables>(ManagerReportDepartmentDocument, baseOptions);
      }
export function useManagerReportDepartmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ManagerReportDepartmentQuery, ManagerReportDepartmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ManagerReportDepartmentQuery, ManagerReportDepartmentQueryVariables>(ManagerReportDepartmentDocument, baseOptions);
        }
export type ManagerReportDepartmentQueryHookResult = ReturnType<typeof useManagerReportDepartmentQuery>;
export type ManagerReportDepartmentLazyQueryHookResult = ReturnType<typeof useManagerReportDepartmentLazyQuery>;
export type ManagerReportDepartmentQueryResult = ApolloReactCommon.QueryResult<ManagerReportDepartmentQuery, ManagerReportDepartmentQueryVariables>;
export const DebtorFolioInfoDocument = gql`
    query DebtorFolioInfo($DebtorAccount: String, $HotelID: String!) {
  DebtorFolioInfo(DebtorAccount: $DebtorAccount, HotelID: $HotelID) {
    Name
    DebtorAccount
    Contact {
      Address {
        address
        address2
        city
        country
        postCode
        state
      }
    }
  }
}
    `;

/**
 * __useDebtorFolioInfoQuery__
 *
 * To run a query within a React component, call `useDebtorFolioInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorFolioInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorFolioInfoQuery({
 *   variables: {
 *      DebtorAccount: // value for 'DebtorAccount'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDebtorFolioInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorFolioInfoQuery, DebtorFolioInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorFolioInfoQuery, DebtorFolioInfoQueryVariables>(DebtorFolioInfoDocument, baseOptions);
      }
export function useDebtorFolioInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorFolioInfoQuery, DebtorFolioInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorFolioInfoQuery, DebtorFolioInfoQueryVariables>(DebtorFolioInfoDocument, baseOptions);
        }
export type DebtorFolioInfoQueryHookResult = ReturnType<typeof useDebtorFolioInfoQuery>;
export type DebtorFolioInfoLazyQueryHookResult = ReturnType<typeof useDebtorFolioInfoLazyQuery>;
export type DebtorFolioInfoQueryResult = ApolloReactCommon.QueryResult<DebtorFolioInfoQuery, DebtorFolioInfoQueryVariables>;
export const FolioDetailsDocument = gql`
    query FolioDetails($ID: String!, $HotelID: String!) {
  FolioDetails(ID: $ID, HotelID: $HotelID) {
    ID
    FolioNo
    FolioType
    FolioDate
    FolioAmount
    BeneficiaryName
    BeneficiaryAddress
    CreatedBy
    ClosedDate
    ClosedBy
    FolioType
    IsSuspend
    DocumentType
    einvoice_qr_url
    is_einvoice
    einvoice_status
    einvoice_directsend
    QrCodeUrl
    NonGuestInfo {
      EffectiveDate
      Title
      DebtorID
      Remark
      HouseLimit
    }
    Booking {
      ID
      BookingNo
      ReferenceNo
      GroupName
      ArrivalDate
      DepartureDate
      Debtor {
        DebtorAccount
        Name
        ID
      }
      Contact {
        FullName
      }
      Registration {
        ID
        ArrivalDate
        DepartureDate
        CheckInDate
        CheckOutDate
        IsPrincipal
        Room {
          RoomNo
        }
        Guest {
          Contact {
            ID
            FullName
            PhoneNo
            MobileNo
            Email
            NRIC
            PassportNo
            Address {
              address
              address2
              city
              country
              postCode
              state
            }
          }
        }
      }
    }
    Registration {
      ID
      ArrivalDate
      DepartureDate
      CheckInDate
      CheckOutDate
      Guest {
        Contact {
          ID
          FullName
          PhoneNo
          MobileNo
          Email
          NRIC
          PassportNo
          Address {
            address
            address2
            city
            country
            postCode
            state
          }
        }
      }
      Booking {
        BookingNo
        isPrepaid
        ReferenceNo
        BookedRegistration {
          ID
          Room {
            RoomNo
          }
          Guest {
            Contact {
              FullName
            }
          }
        }
        Debtor {
          Name
          DebtorAccount
        }
      }
      Room {
        RoomNo
      }
    }
    AdvancePayment {
      Amount
      TaxAmount
      ServiceCharge
      PaymentType
      PaymentClass
      PaySessionNo
      ReferenceNo
      ID
      DocumentType
      PaymentDate
      ReceiptNo
      BookingID
      HotelID
      ReversedAdvancePaymentID
      IsCNAdjusted
      ForfeitTax {
        ID
        TaxID
        TaxRate
        TaxAmount
        TaxSchemeID
        TaxInfo {
          code
        }
      }
    }
    Payment {
      Amount
      PaymentType
      CardNo
      DebtorAccount
      ReceiptNo
      PaymentClass
      PaySessionNo
      PaymentDate
      ReversedPaymentID
      ReferenceNo
      ID
      DocumentType
      ForfeitTax {
        ID
        TaxID
        TaxRate
        TaxAmount
        TaxSchemeID
        TaxInfo {
          code
        }
      }
      Registration {
        ID
        Guest {
          Contact {
            FullName
          }
        }
        Room {
          RoomNo
        }
      }
    }
    TaxLedger {
      ID
      TrxDate
      TaxType
      TaxDescription
      TaxAmount
      Reason
      Description
      RegistrationID
      Qty
      IsCNAdjusted
      Registration {
        ID
        Guest {
          Contact {
            FullName
          }
        }
        Room {
          RoomNo
        }
      }
    }
    BillLedger {
      ID
      RefBillLedgerID
      ReversedBillLedgerID
      TrxDate
      BaseAmount
      ServiceCharge
      TaxAmount
      TrxAmount
      Qty
      UnitPrice
      ReferenceNo
      RegistrationID
      Description
      IsCNAdjusted
      Registration {
        ID
        Guest {
          Contact {
            FullName
          }
        }
        Room {
          RoomNo
        }
      }
      IncidentalCharge {
        Description
        ID
        IsOutlet
        IsEventInterface
        isEInvIntegrated
      }
      TaxBillLedger {
        ID
        TaxID
        TaxRate
        TaxAmount
        BillLedgerID
        TaxSchemeID
        TaxInfo {
          code
        }
      }
    }
    RoomCharges {
      TrxDate
      BaseAmount
      Description
      TaxAmount
      TrxAmount
      ServiceCharge
      RegistrationID
      TrxDescription
      LedgerID
      FromRegistration
      TaxRoomLedger {
        ID
        TaxID
        TaxRate
        TaxAmount
        RoomLedgerID
        TaxSchemeID
        TaxInfo {
          code
        }
      }
    }
    RoomChargesSuspend {
      TrxDate
      BaseAmount
      Description
      TaxAmount
      TrxAmount
      ServiceCharge
      RegistrationID
      TrxDescription
      LedgerID
      TaxRoomLedger {
        ID
        TaxID
        TaxRate
        TaxAmount
        RoomLedgerID
        TaxSchemeID
        TaxInfo {
          code
        }
      }
    }
    RoomLedger {
      ID
      RefRoomLedgerID
      TrxAmount
      TrxDate
      ChargeType
      TaxAmount
      BaseAmount
      IsCNAdjusted
      FromRegistration
      TransactionType
      TransactionID
      Transaction {
        Description
      }
      ServiceCharge
      ReferenceNo
      Description
      Registration {
        ID
        Guest {
          Contact {
            FullName
          }
        }
        Room {
          RoomNo
        }
      }
      TaxRoomLedger {
        ID
        TaxID
        TaxRate
        TaxAmount
        RoomLedgerID
        TaxSchemeID
        TaxInfo {
          code
        }
      }
    }
    DepositLedger {
      ID
      DepositAmount
      DepositClass
      ReferenceNo
      TrxDate
      RegistrationID
      ReversedID
      ActualDepositClass
      IsCNAdjusted
    }
  }
}
    `;

/**
 * __useFolioDetailsQuery__
 *
 * To run a query within a React component, call `useFolioDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolioDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolioDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useFolioDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FolioDetailsQuery, FolioDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<FolioDetailsQuery, FolioDetailsQueryVariables>(FolioDetailsDocument, baseOptions);
      }
export function useFolioDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FolioDetailsQuery, FolioDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FolioDetailsQuery, FolioDetailsQueryVariables>(FolioDetailsDocument, baseOptions);
        }
export type FolioDetailsQueryHookResult = ReturnType<typeof useFolioDetailsQuery>;
export type FolioDetailsLazyQueryHookResult = ReturnType<typeof useFolioDetailsLazyQuery>;
export type FolioDetailsQueryResult = ApolloReactCommon.QueryResult<FolioDetailsQuery, FolioDetailsQueryVariables>;
export const FolioDetailsWithIDsDocument = gql`
    query FolioDetailsWithIDs($HotelID: String!, $RegistrationID: String!, $LedgerIDs: LedgerIDsInput) {
  FolioDetailsWithIDs(HotelID: $HotelID, RegistrationID: $RegistrationID, LedgerIDs: $LedgerIDs) {
    ID
    ArrivalDate
    DepartureDate
    CheckInDate
    CheckOutDate
    IsPrincipal
    Room {
      RoomNo
    }
    Guest {
      Contact {
        ID
        FullName
        PhoneNo
        MobileNo
        Email
        NRIC
        PassportNo
        Address {
          address
          address2
          city
          country
          postCode
          state
        }
      }
    }
    Booking {
      ID
      BookingNo
      ReferenceNo
      Registration {
        ID
        ArrivalDate
        DepartureDate
        CheckInDate
        CheckOutDate
        Guest {
          Contact {
            ID
            FullName
            PhoneNo
            MobileNo
            Email
            NRIC
            PassportNo
            Address {
              address
              address2
              city
              country
              postCode
              state
            }
          }
        }
        Room {
          RoomNo
        }
      }
    }
    AdvancePayment {
      Amount
      PaymentType
      PaymentClass
      PaySessionNo
      ReferenceNo
      ID
    }
    TaxLedger {
      ID
      TrxDate
      TaxType
      TaxDescription
      TaxAmount
      Reason
      Description
      FolioID
    }
    BillLedger {
      ID
      TrxDate
      BaseAmount
      ServiceCharge
      TaxAmount
      TrxAmount
      ReferenceNo
      IncidentalCharge {
        Description
      }
    }
    RoomCharges {
      TrxDate
      BaseAmount
      Description
      TaxAmount
      ServiceCharge
      ReferenceNo
      Reason
      RegistrationID
      Description
      TrxDescription
    }
    RoomLedger {
      ID
      TrxAmount
      TrxDate
      TaxAmount
      BaseAmount
      TransactionType
      FromRegistration
      TransactionID
      Transaction {
        Description
      }
      ServiceCharge
      ReferenceNo
      Description
    }
    DepositLedger {
      DepositAmount
      DepositClass
      ReferenceNo
      TrxDate
    }
  }
}
    `;

/**
 * __useFolioDetailsWithIDsQuery__
 *
 * To run a query within a React component, call `useFolioDetailsWithIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolioDetailsWithIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolioDetailsWithIDsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *      LedgerIDs: // value for 'LedgerIDs'
 *   },
 * });
 */
export function useFolioDetailsWithIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FolioDetailsWithIDsQuery, FolioDetailsWithIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<FolioDetailsWithIDsQuery, FolioDetailsWithIDsQueryVariables>(FolioDetailsWithIDsDocument, baseOptions);
      }
export function useFolioDetailsWithIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FolioDetailsWithIDsQuery, FolioDetailsWithIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FolioDetailsWithIDsQuery, FolioDetailsWithIDsQueryVariables>(FolioDetailsWithIDsDocument, baseOptions);
        }
export type FolioDetailsWithIDsQueryHookResult = ReturnType<typeof useFolioDetailsWithIDsQuery>;
export type FolioDetailsWithIDsLazyQueryHookResult = ReturnType<typeof useFolioDetailsWithIDsLazyQuery>;
export type FolioDetailsWithIDsQueryResult = ApolloReactCommon.QueryResult<FolioDetailsWithIDsQuery, FolioDetailsWithIDsQueryVariables>;
export const PaymentDetailsDocument = gql`
    query PaymentDetails($ID: String!, $HotelID: String!) {
  PaymentDetails(ID: $ID, HotelID: $HotelID) {
    ID
    ReceiptNo
    ReferenceNo
    PaymentDate
    PaymentType
    Amount
    CreatedBy
    PaymentStatus
    PaymentClass
    DebtorAccount
    CardNo
    DocumentType
    Folio {
      FolioNo
      ID
      FolioType
      BeneficiaryName
      BeneficiaryAddress
      DocumentType
      NonGuestInfo {
        EffectiveDate
        Title
        DebtorID
        Remark
        HouseLimit
      }
      DepositLedger {
        DepositClass
        ReversedID
        Remarks
      }
    }
    Registration {
      ID
      ArrivalDate
      DepartureDate
      CheckInDate
      CheckOutDate
      Booking {
        BookingNo
        DebtorAccount
        Debtor {
          Name
        }
      }
      Room {
        RoomNo
      }
      Guest {
        Contact {
          ID
          FullName
          PhoneNo
          MobileNo
          Email
          NRIC
          PassportNo
          Address {
            address
            address2
            city
            country
            postCode
            state
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePaymentDetailsQuery__
 *
 * To run a query within a React component, call `usePaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function usePaymentDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentDetailsQuery, PaymentDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentDetailsQuery, PaymentDetailsQueryVariables>(PaymentDetailsDocument, baseOptions);
      }
export function usePaymentDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentDetailsQuery, PaymentDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentDetailsQuery, PaymentDetailsQueryVariables>(PaymentDetailsDocument, baseOptions);
        }
export type PaymentDetailsQueryHookResult = ReturnType<typeof usePaymentDetailsQuery>;
export type PaymentDetailsLazyQueryHookResult = ReturnType<typeof usePaymentDetailsLazyQuery>;
export type PaymentDetailsQueryResult = ApolloReactCommon.QueryResult<PaymentDetailsQuery, PaymentDetailsQueryVariables>;
export const DepositClassDocument = gql`
    query DepositClass($ReversedID: String!) {
  DepositClass(ReversedID: $ReversedID) {
    ID
    DepositClass
  }
}
    `;

/**
 * __useDepositClassQuery__
 *
 * To run a query within a React component, call `useDepositClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositClassQuery({
 *   variables: {
 *      ReversedID: // value for 'ReversedID'
 *   },
 * });
 */
export function useDepositClassQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepositClassQuery, DepositClassQueryVariables>) {
        return ApolloReactHooks.useQuery<DepositClassQuery, DepositClassQueryVariables>(DepositClassDocument, baseOptions);
      }
export function useDepositClassLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepositClassQuery, DepositClassQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepositClassQuery, DepositClassQueryVariables>(DepositClassDocument, baseOptions);
        }
export type DepositClassQueryHookResult = ReturnType<typeof useDepositClassQuery>;
export type DepositClassLazyQueryHookResult = ReturnType<typeof useDepositClassLazyQuery>;
export type DepositClassQueryResult = ApolloReactCommon.QueryResult<DepositClassQuery, DepositClassQueryVariables>;
export const DepositForfeitlistDocument = gql`
    query DepositForfeitlist($ReversedID: String!) {
  DepositForfeitlist(ReversedID: $ReversedID) {
    ID
    DepositClass
    ReversedID
    FolioID
    PaymentType
    TrxDate
    ReferenceNo
    Remarks
    DepositAmount
    Folio {
      ID
      Payment {
        ID
        ReceiptNo
      }
    }
  }
}
    `;

/**
 * __useDepositForfeitlistQuery__
 *
 * To run a query within a React component, call `useDepositForfeitlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositForfeitlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositForfeitlistQuery({
 *   variables: {
 *      ReversedID: // value for 'ReversedID'
 *   },
 * });
 */
export function useDepositForfeitlistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepositForfeitlistQuery, DepositForfeitlistQueryVariables>) {
        return ApolloReactHooks.useQuery<DepositForfeitlistQuery, DepositForfeitlistQueryVariables>(DepositForfeitlistDocument, baseOptions);
      }
export function useDepositForfeitlistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepositForfeitlistQuery, DepositForfeitlistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepositForfeitlistQuery, DepositForfeitlistQueryVariables>(DepositForfeitlistDocument, baseOptions);
        }
export type DepositForfeitlistQueryHookResult = ReturnType<typeof useDepositForfeitlistQuery>;
export type DepositForfeitlistLazyQueryHookResult = ReturnType<typeof useDepositForfeitlistLazyQuery>;
export type DepositForfeitlistQueryResult = ApolloReactCommon.QueryResult<DepositForfeitlistQuery, DepositForfeitlistQueryVariables>;
export const DepositRefundlistDocument = gql`
    query DepositRefundlist($ReversedID: String!) {
  DepositRefundlist(ReversedID: $ReversedID) {
    ID
    DepositClass
    ReversedID
    FolioID
    PaymentType
    TrxDate
    ReferenceNo
    Remarks
    DepositAmount
    Folio {
      ID
      Payment {
        ID
        ReceiptNo
      }
    }
  }
}
    `;

/**
 * __useDepositRefundlistQuery__
 *
 * To run a query within a React component, call `useDepositRefundlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositRefundlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositRefundlistQuery({
 *   variables: {
 *      ReversedID: // value for 'ReversedID'
 *   },
 * });
 */
export function useDepositRefundlistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepositRefundlistQuery, DepositRefundlistQueryVariables>) {
        return ApolloReactHooks.useQuery<DepositRefundlistQuery, DepositRefundlistQueryVariables>(DepositRefundlistDocument, baseOptions);
      }
export function useDepositRefundlistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepositRefundlistQuery, DepositRefundlistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepositRefundlistQuery, DepositRefundlistQueryVariables>(DepositRefundlistDocument, baseOptions);
        }
export type DepositRefundlistQueryHookResult = ReturnType<typeof useDepositRefundlistQuery>;
export type DepositRefundlistLazyQueryHookResult = ReturnType<typeof useDepositRefundlistLazyQuery>;
export type DepositRefundlistQueryResult = ApolloReactCommon.QueryResult<DepositRefundlistQuery, DepositRefundlistQueryVariables>;
export const AdvancePaymentDetailsDocument = gql`
    query AdvancePaymentDetails($ID: String!, $HotelID: String!) {
  AdvancePaymentDetails(ID: $ID, HotelID: $HotelID) {
    ID
    ReceiptNo
    ReferenceNo
    PaymentDate
    PaymentType
    Amount
    TaxAmount
    ServiceCharge
    CreatedBy
    PaymentStatus
    PaymentClass
    DebtorAccount
    DocumentType
    ReversedAdvancePaymentID
    Folio {
      ID
      FolioDate
      FolioNo
      BeneficiaryName
      BeneficiaryAddress
      DocumentType
    }
    Booking {
      BookingNo
      DebtorAccount
      IsGroupBooking
      GroupName
      ArrivalDate
      DepartureDate
      Debtor {
        ID
        Name
        DebtorAccount
      }
      Registration {
        ID
        ArrivalDate
        DepartureDate
        CheckInDate
        CheckOutDate
        IsPrincipal
        Room {
          RoomNo
        }
        Guest {
          Contact {
            ID
            FullName
            PhoneNo
            MobileNo
            Email
            NRIC
            PassportNo
            Address {
              address
              address2
              city
              country
              postCode
              state
            }
          }
        }
      }
      Contact {
        ID
        FullName
        PhoneNo
        MobileNo
        Email
        NRIC
        PassportNo
        Address {
          address
          address2
          city
          country
          postCode
          state
        }
      }
    }
    Registration {
      ID
      ArrivalDate
      DepartureDate
      CheckInDate
      CheckOutDate
      Booking {
        BookingNo
        DebtorAccount
      }
      Room {
        RoomNo
      }
      Guest {
        Contact {
          ID
          FullName
          PhoneNo
          MobileNo
          Email
          Address {
            address
            address2
            city
            country
            postCode
            state
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAdvancePaymentDetailsQuery__
 *
 * To run a query within a React component, call `useAdvancePaymentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancePaymentDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAdvancePaymentDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvancePaymentDetailsQuery, AdvancePaymentDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvancePaymentDetailsQuery, AdvancePaymentDetailsQueryVariables>(AdvancePaymentDetailsDocument, baseOptions);
      }
export function useAdvancePaymentDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvancePaymentDetailsQuery, AdvancePaymentDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvancePaymentDetailsQuery, AdvancePaymentDetailsQueryVariables>(AdvancePaymentDetailsDocument, baseOptions);
        }
export type AdvancePaymentDetailsQueryHookResult = ReturnType<typeof useAdvancePaymentDetailsQuery>;
export type AdvancePaymentDetailsLazyQueryHookResult = ReturnType<typeof useAdvancePaymentDetailsLazyQuery>;
export type AdvancePaymentDetailsQueryResult = ApolloReactCommon.QueryResult<AdvancePaymentDetailsQuery, AdvancePaymentDetailsQueryVariables>;
export const AdvancePaymentReversedDetailsDocument = gql`
    query AdvancePaymentReversedDetails($ID: String!, $HotelID: String!) {
  AdvancePaymentReversedDetails(ID: $ID, HotelID: $HotelID) {
    ID
    ReceiptNo
    ReferenceNo
    PaymentDate
    PaymentType
    Amount
    CreatedBy
    PaymentStatus
    PaymentClass
    DebtorAccount
    ReversedAdvancePaymentID
  }
}
    `;

/**
 * __useAdvancePaymentReversedDetailsQuery__
 *
 * To run a query within a React component, call `useAdvancePaymentReversedDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdvancePaymentReversedDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdvancePaymentReversedDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAdvancePaymentReversedDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdvancePaymentReversedDetailsQuery, AdvancePaymentReversedDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdvancePaymentReversedDetailsQuery, AdvancePaymentReversedDetailsQueryVariables>(AdvancePaymentReversedDetailsDocument, baseOptions);
      }
export function useAdvancePaymentReversedDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdvancePaymentReversedDetailsQuery, AdvancePaymentReversedDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdvancePaymentReversedDetailsQuery, AdvancePaymentReversedDetailsQueryVariables>(AdvancePaymentReversedDetailsDocument, baseOptions);
        }
export type AdvancePaymentReversedDetailsQueryHookResult = ReturnType<typeof useAdvancePaymentReversedDetailsQuery>;
export type AdvancePaymentReversedDetailsLazyQueryHookResult = ReturnType<typeof useAdvancePaymentReversedDetailsLazyQuery>;
export type AdvancePaymentReversedDetailsQueryResult = ApolloReactCommon.QueryResult<AdvancePaymentReversedDetailsQuery, AdvancePaymentReversedDetailsQueryVariables>;
export const RegistrationDetailsDocument = gql`
    query RegistrationDetails($BookingID: String!, $GuestID: String!, $RegistrationID: String) {
  RegistrationDetails(BookingID: $BookingID, GuestID: $GuestID, RegistrationID: $RegistrationID) {
    ID
    ArrivalDate
    DepartureDate
    CheckInDate
    CheckOutDate
    IsPrincipal
    RoomRate
    IsPDPA
    DiscountAmount
    TotalRoomPrice
    NoOfAdult
    PrintRate
    RoomRateDisplay
    RegistrationSignature {
      ID
      BucketFileName
      ImageURL
    }
    RoomType {
      Code
    }
    Room {
      RoomNo
    }
    Guest {
      VehicleNo
      Contact {
        Designation
        ID
        FullName
        PhoneNo
        MobileNo
        Email
        BirthDate
        Nationality
        NRIC
        PassportNo
        PassportExpireDate
        Address {
          address
          address2
          city
          country
          postCode
          state
        }
      }
    }
    Booking {
      BookingNo
      DebtorAccount
      BillingInstruction
      Debtor {
        ID
        Name
      }
    }
  }
}
    `;

/**
 * __useRegistrationDetailsQuery__
 *
 * To run a query within a React component, call `useRegistrationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationDetailsQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      GuestID: // value for 'GuestID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useRegistrationDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistrationDetailsQuery, RegistrationDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistrationDetailsQuery, RegistrationDetailsQueryVariables>(RegistrationDetailsDocument, baseOptions);
      }
export function useRegistrationDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistrationDetailsQuery, RegistrationDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistrationDetailsQuery, RegistrationDetailsQueryVariables>(RegistrationDetailsDocument, baseOptions);
        }
export type RegistrationDetailsQueryHookResult = ReturnType<typeof useRegistrationDetailsQuery>;
export type RegistrationDetailsLazyQueryHookResult = ReturnType<typeof useRegistrationDetailsLazyQuery>;
export type RegistrationDetailsQueryResult = ApolloReactCommon.QueryResult<RegistrationDetailsQuery, RegistrationDetailsQueryVariables>;
export const RegistrationDetailsMultiDocument = gql`
    query RegistrationDetailsMulti($InputReg: [InputReg!]) {
  RegistrationDetailsMulti(InputReg: $InputReg) {
    ID
    ArrivalDate
    DepartureDate
    CheckInDate
    CheckOutDate
    IsPrincipal
    RoomRate
    IsPDPA
    DiscountAmount
    TotalRoomPrice
    NoOfAdult
    PrintRate
    RoomRateDisplay
    SpecialRequest
    RegistrationSignature {
      ID
      BucketFileName
      ImageURL
    }
    RoomType {
      Code
    }
    Room {
      RoomNo
    }
    Guest {
      VehicleNo
      Contact {
        Designation
        ID
        FullName
        PhoneNo
        MobileNo
        Email
        BirthDate
        Nationality
        NRIC
        PassportNo
        PassportExpireDate
        Address {
          address
          address2
          city
          country
          postCode
          state
        }
      }
    }
    Booking {
      BookingNo
      DebtorAccount
      BillingInstruction
      Debtor {
        ID
        Name
      }
    }
  }
}
    `;

/**
 * __useRegistrationDetailsMultiQuery__
 *
 * To run a query within a React component, call `useRegistrationDetailsMultiQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationDetailsMultiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationDetailsMultiQuery({
 *   variables: {
 *      InputReg: // value for 'InputReg'
 *   },
 * });
 */
export function useRegistrationDetailsMultiQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistrationDetailsMultiQuery, RegistrationDetailsMultiQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistrationDetailsMultiQuery, RegistrationDetailsMultiQueryVariables>(RegistrationDetailsMultiDocument, baseOptions);
      }
export function useRegistrationDetailsMultiLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistrationDetailsMultiQuery, RegistrationDetailsMultiQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistrationDetailsMultiQuery, RegistrationDetailsMultiQueryVariables>(RegistrationDetailsMultiDocument, baseOptions);
        }
export type RegistrationDetailsMultiQueryHookResult = ReturnType<typeof useRegistrationDetailsMultiQuery>;
export type RegistrationDetailsMultiLazyQueryHookResult = ReturnType<typeof useRegistrationDetailsMultiLazyQuery>;
export type RegistrationDetailsMultiQueryResult = ApolloReactCommon.QueryResult<RegistrationDetailsMultiQuery, RegistrationDetailsMultiQueryVariables>;
export const HotelStatisticListingDocument = gql`
    query HotelStatisticListing($EndDate: DateTime!, $StartDate: DateTime!, $HotelID: String!, $HouseUse: Boolean, $DayUse: Boolean, $Complimentary: Boolean) {
  HotelStatisticListing(EndDate: $EndDate, StartDate: $StartDate, HotelID: $HotelID, HouseUse: $HouseUse, DayUse: $DayUse, Complimentary: $Complimentary) {
    TotalRoomRevenue
    TotalFnBRevenue
    TotalOtherRevenue
    TotalOccupancyRate
    TotalArr
    TotalRoom
    OOOOOI
    TotalRentableRoom
    TotalOccupied
    TotalComplimentary
    TotalHouseUse
    TotalDayUse
    TotalPax
  }
}
    `;

/**
 * __useHotelStatisticListingQuery__
 *
 * To run a query within a React component, call `useHotelStatisticListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelStatisticListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelStatisticListingQuery({
 *   variables: {
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      HotelID: // value for 'HotelID'
 *      HouseUse: // value for 'HouseUse'
 *      DayUse: // value for 'DayUse'
 *      Complimentary: // value for 'Complimentary'
 *   },
 * });
 */
export function useHotelStatisticListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelStatisticListingQuery, HotelStatisticListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelStatisticListingQuery, HotelStatisticListingQueryVariables>(HotelStatisticListingDocument, baseOptions);
      }
export function useHotelStatisticListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelStatisticListingQuery, HotelStatisticListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelStatisticListingQuery, HotelStatisticListingQueryVariables>(HotelStatisticListingDocument, baseOptions);
        }
export type HotelStatisticListingQueryHookResult = ReturnType<typeof useHotelStatisticListingQuery>;
export type HotelStatisticListingLazyQueryHookResult = ReturnType<typeof useHotelStatisticListingLazyQuery>;
export type HotelStatisticListingQueryResult = ApolloReactCommon.QueryResult<HotelStatisticListingQuery, HotelStatisticListingQueryVariables>;
export const HotelStatisticListingV2Document = gql`
    query HotelStatisticListingV2($BlockRoom: Boolean, $EndDate: DateTime!, $StartDate: DateTime!, $HotelID: String!, $HouseUse: Boolean, $DayUse: Boolean, $Complimentary: Boolean) {
  HotelStatisticListingV2(EndDate: $EndDate, StartDate: $StartDate, HotelID: $HotelID, HouseUse: $HouseUse, DayUse: $DayUse, Complimentary: $Complimentary, BlockRoom: $BlockRoom)
}
    `;

/**
 * __useHotelStatisticListingV2Query__
 *
 * To run a query within a React component, call `useHotelStatisticListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useHotelStatisticListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelStatisticListingV2Query({
 *   variables: {
 *      BlockRoom: // value for 'BlockRoom'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      HotelID: // value for 'HotelID'
 *      HouseUse: // value for 'HouseUse'
 *      DayUse: // value for 'DayUse'
 *      Complimentary: // value for 'Complimentary'
 *   },
 * });
 */
export function useHotelStatisticListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelStatisticListingV2Query, HotelStatisticListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<HotelStatisticListingV2Query, HotelStatisticListingV2QueryVariables>(HotelStatisticListingV2Document, baseOptions);
      }
export function useHotelStatisticListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelStatisticListingV2Query, HotelStatisticListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelStatisticListingV2Query, HotelStatisticListingV2QueryVariables>(HotelStatisticListingV2Document, baseOptions);
        }
export type HotelStatisticListingV2QueryHookResult = ReturnType<typeof useHotelStatisticListingV2Query>;
export type HotelStatisticListingV2LazyQueryHookResult = ReturnType<typeof useHotelStatisticListingV2LazyQuery>;
export type HotelStatisticListingV2QueryResult = ApolloReactCommon.QueryResult<HotelStatisticListingV2Query, HotelStatisticListingV2QueryVariables>;
export const HotelStateAnalysisDocument = gql`
    query HotelStateAnalysis($HotelID: String!, $EndDate: DateTime!, $StartDate: DateTime!) {
  HotelStateAnalysis(HotelID: $HotelID, EndDate: $EndDate, StartDate: $StartDate)
}
    `;

/**
 * __useHotelStateAnalysisQuery__
 *
 * To run a query within a React component, call `useHotelStateAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelStateAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelStateAnalysisQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useHotelStateAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelStateAnalysisQuery, HotelStateAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelStateAnalysisQuery, HotelStateAnalysisQueryVariables>(HotelStateAnalysisDocument, baseOptions);
      }
export function useHotelStateAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelStateAnalysisQuery, HotelStateAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelStateAnalysisQuery, HotelStateAnalysisQueryVariables>(HotelStateAnalysisDocument, baseOptions);
        }
export type HotelStateAnalysisQueryHookResult = ReturnType<typeof useHotelStateAnalysisQuery>;
export type HotelStateAnalysisLazyQueryHookResult = ReturnType<typeof useHotelStateAnalysisLazyQuery>;
export type HotelStateAnalysisQueryResult = ApolloReactCommon.QueryResult<HotelStateAnalysisQuery, HotelStateAnalysisQueryVariables>;
export const TotalDueAgingDocument = gql`
    query TotalDueAging($HotelID: String!, $StartDate: DateTime!) {
  TotalDueAging(HotelID: $HotelID, StartDate: $StartDate)
}
    `;

/**
 * __useTotalDueAgingQuery__
 *
 * To run a query within a React component, call `useTotalDueAgingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalDueAgingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalDueAgingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useTotalDueAgingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TotalDueAgingQuery, TotalDueAgingQueryVariables>) {
        return ApolloReactHooks.useQuery<TotalDueAgingQuery, TotalDueAgingQueryVariables>(TotalDueAgingDocument, baseOptions);
      }
export function useTotalDueAgingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TotalDueAgingQuery, TotalDueAgingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TotalDueAgingQuery, TotalDueAgingQueryVariables>(TotalDueAgingDocument, baseOptions);
        }
export type TotalDueAgingQueryHookResult = ReturnType<typeof useTotalDueAgingQuery>;
export type TotalDueAgingLazyQueryHookResult = ReturnType<typeof useTotalDueAgingLazyQuery>;
export type TotalDueAgingQueryResult = ApolloReactCommon.QueryResult<TotalDueAgingQuery, TotalDueAgingQueryVariables>;
export const BiServiceRequestDocument = gql`
    query BIServiceRequest($HotelID: String!) {
  BIServiceRequest(HotelID: $HotelID) {
    RoomRevenue
    Description
  }
}
    `;

/**
 * __useBiServiceRequestQuery__
 *
 * To run a query within a React component, call `useBiServiceRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiServiceRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiServiceRequestQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBiServiceRequestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiServiceRequestQuery, BiServiceRequestQueryVariables>) {
        return ApolloReactHooks.useQuery<BiServiceRequestQuery, BiServiceRequestQueryVariables>(BiServiceRequestDocument, baseOptions);
      }
export function useBiServiceRequestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiServiceRequestQuery, BiServiceRequestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiServiceRequestQuery, BiServiceRequestQueryVariables>(BiServiceRequestDocument, baseOptions);
        }
export type BiServiceRequestQueryHookResult = ReturnType<typeof useBiServiceRequestQuery>;
export type BiServiceRequestLazyQueryHookResult = ReturnType<typeof useBiServiceRequestLazyQuery>;
export type BiServiceRequestQueryResult = ApolloReactCommon.QueryResult<BiServiceRequestQuery, BiServiceRequestQueryVariables>;
export const BiytdRevenueDocument = gql`
    query BIYTDRevenue($HotelID: String!) {
  BIYTDRevenue(HotelID: $HotelID) {
    RoomRevenue
  }
  BICorpGovTAYTDRevenue(HotelID: $HotelID) {
    RoomRevenue
  }
  BISourceYTDRevenue(HotelID: $HotelID) {
    RoomRevenue
    Description
  }
  BITopCountry(HotelID: $HotelID) {
    RoomRevenue
    Description
  }
  BITopNationality(HotelID: $HotelID) {
    RoomRevenue
    Description
  }
  BIRoomTypeRevenue(HotelID: $HotelID) {
    RoomRevenue
    Description
  }
  BIMarketingPersonnalTopSales(HotelID: $HotelID) {
    RoomRevenue
    Description
  }
  BISegmentRevenue(HotelID: $HotelID) {
    RoomRevenue
    Description
  }
  BIServiceRequest(HotelID: $HotelID) {
    RoomRevenue
    Description
  }
  BITravelAgent(HotelID: $HotelID) {
    RoomRevenue
    Description
  }
  BITopTransaction(HotelID: $HotelID) {
    RoomRevenue
    Description
  }
}
    `;

/**
 * __useBiytdRevenueQuery__
 *
 * To run a query within a React component, call `useBiytdRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useBiytdRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiytdRevenueQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBiytdRevenueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BiytdRevenueQuery, BiytdRevenueQueryVariables>) {
        return ApolloReactHooks.useQuery<BiytdRevenueQuery, BiytdRevenueQueryVariables>(BiytdRevenueDocument, baseOptions);
      }
export function useBiytdRevenueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiytdRevenueQuery, BiytdRevenueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiytdRevenueQuery, BiytdRevenueQueryVariables>(BiytdRevenueDocument, baseOptions);
        }
export type BiytdRevenueQueryHookResult = ReturnType<typeof useBiytdRevenueQuery>;
export type BiytdRevenueLazyQueryHookResult = ReturnType<typeof useBiytdRevenueLazyQuery>;
export type BiytdRevenueQueryResult = ApolloReactCommon.QueryResult<BiytdRevenueQuery, BiytdRevenueQueryVariables>;
export const TotalOverdueByDebtorTypeDocument = gql`
    query TotalOverdueByDebtorType($HotelID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  TotalOverdueByDebtorType(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useTotalOverdueByDebtorTypeQuery__
 *
 * To run a query within a React component, call `useTotalOverdueByDebtorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalOverdueByDebtorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalOverdueByDebtorTypeQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useTotalOverdueByDebtorTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TotalOverdueByDebtorTypeQuery, TotalOverdueByDebtorTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<TotalOverdueByDebtorTypeQuery, TotalOverdueByDebtorTypeQueryVariables>(TotalOverdueByDebtorTypeDocument, baseOptions);
      }
export function useTotalOverdueByDebtorTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TotalOverdueByDebtorTypeQuery, TotalOverdueByDebtorTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TotalOverdueByDebtorTypeQuery, TotalOverdueByDebtorTypeQueryVariables>(TotalOverdueByDebtorTypeDocument, baseOptions);
        }
export type TotalOverdueByDebtorTypeQueryHookResult = ReturnType<typeof useTotalOverdueByDebtorTypeQuery>;
export type TotalOverdueByDebtorTypeLazyQueryHookResult = ReturnType<typeof useTotalOverdueByDebtorTypeLazyQuery>;
export type TotalOverdueByDebtorTypeQueryResult = ApolloReactCommon.QueryResult<TotalOverdueByDebtorTypeQuery, TotalOverdueByDebtorTypeQueryVariables>;
export const CollectionByDebtorTypeVsOverdueDocument = gql`
    query CollectionByDebtorTypeVSOverdue($HotelID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  CollectionByDebtorTypeVSOverdue(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useCollectionByDebtorTypeVsOverdueQuery__
 *
 * To run a query within a React component, call `useCollectionByDebtorTypeVsOverdueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionByDebtorTypeVsOverdueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionByDebtorTypeVsOverdueQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useCollectionByDebtorTypeVsOverdueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CollectionByDebtorTypeVsOverdueQuery, CollectionByDebtorTypeVsOverdueQueryVariables>) {
        return ApolloReactHooks.useQuery<CollectionByDebtorTypeVsOverdueQuery, CollectionByDebtorTypeVsOverdueQueryVariables>(CollectionByDebtorTypeVsOverdueDocument, baseOptions);
      }
export function useCollectionByDebtorTypeVsOverdueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CollectionByDebtorTypeVsOverdueQuery, CollectionByDebtorTypeVsOverdueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CollectionByDebtorTypeVsOverdueQuery, CollectionByDebtorTypeVsOverdueQueryVariables>(CollectionByDebtorTypeVsOverdueDocument, baseOptions);
        }
export type CollectionByDebtorTypeVsOverdueQueryHookResult = ReturnType<typeof useCollectionByDebtorTypeVsOverdueQuery>;
export type CollectionByDebtorTypeVsOverdueLazyQueryHookResult = ReturnType<typeof useCollectionByDebtorTypeVsOverdueLazyQuery>;
export type CollectionByDebtorTypeVsOverdueQueryResult = ApolloReactCommon.QueryResult<CollectionByDebtorTypeVsOverdueQuery, CollectionByDebtorTypeVsOverdueQueryVariables>;
export const DepartmentListingDocument = gql`
    query DepartmentListing($HotelID: String!) {
  DepartmentListing(HotelID: $HotelID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    Code
    Name
    AllowReason
    AllowSalesChannel
    AllowIncidental
    AllowGuestApp
    IsServiceRequest
    ServiceRequest {
      ID
    }
    IncidentalCharge {
      IsRateElement
      IsActive
      DepartmentID
      ID
      Description
      Amount
      Tax
      ServiceCharge
      IsFnB
      IsEShop
      IsRoomRevenue
      GalleryID
      IsOutlet
      IsNegative
      IsEventInterface
      isEInvIntegrated
      EInvClassification
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
    Reason {
      ID
    }
    SalesChannel {
      ID
    }
  }
}
    `;

/**
 * __useDepartmentListingQuery__
 *
 * To run a query within a React component, call `useDepartmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDepartmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepartmentListingQuery, DepartmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DepartmentListingQuery, DepartmentListingQueryVariables>(DepartmentListingDocument, baseOptions);
      }
export function useDepartmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepartmentListingQuery, DepartmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepartmentListingQuery, DepartmentListingQueryVariables>(DepartmentListingDocument, baseOptions);
        }
export type DepartmentListingQueryHookResult = ReturnType<typeof useDepartmentListingQuery>;
export type DepartmentListingLazyQueryHookResult = ReturnType<typeof useDepartmentListingLazyQuery>;
export type DepartmentListingQueryResult = ApolloReactCommon.QueryResult<DepartmentListingQuery, DepartmentListingQueryVariables>;
export const BusinessAnalyticInfoLineDocument = gql`
    query BusinessAnalyticInfoLine($HotelID: String!) {
  BusinessAnalyticInfoLine(HotelID: $HotelID)
}
    `;

/**
 * __useBusinessAnalyticInfoLineQuery__
 *
 * To run a query within a React component, call `useBusinessAnalyticInfoLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAnalyticInfoLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAnalyticInfoLineQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBusinessAnalyticInfoLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BusinessAnalyticInfoLineQuery, BusinessAnalyticInfoLineQueryVariables>) {
        return ApolloReactHooks.useQuery<BusinessAnalyticInfoLineQuery, BusinessAnalyticInfoLineQueryVariables>(BusinessAnalyticInfoLineDocument, baseOptions);
      }
export function useBusinessAnalyticInfoLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BusinessAnalyticInfoLineQuery, BusinessAnalyticInfoLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BusinessAnalyticInfoLineQuery, BusinessAnalyticInfoLineQueryVariables>(BusinessAnalyticInfoLineDocument, baseOptions);
        }
export type BusinessAnalyticInfoLineQueryHookResult = ReturnType<typeof useBusinessAnalyticInfoLineQuery>;
export type BusinessAnalyticInfoLineLazyQueryHookResult = ReturnType<typeof useBusinessAnalyticInfoLineLazyQuery>;
export type BusinessAnalyticInfoLineQueryResult = ApolloReactCommon.QueryResult<BusinessAnalyticInfoLineQuery, BusinessAnalyticInfoLineQueryVariables>;
export const BiTop5Otav2Document = gql`
    query BITop5OTAV2($HotelID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  BITop5OTAV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useBiTop5Otav2Query__
 *
 * To run a query within a React component, call `useBiTop5Otav2Query` and pass it any options that fit your needs.
 * When your component renders, `useBiTop5Otav2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiTop5Otav2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiTop5Otav2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiTop5Otav2Query, BiTop5Otav2QueryVariables>) {
        return ApolloReactHooks.useQuery<BiTop5Otav2Query, BiTop5Otav2QueryVariables>(BiTop5Otav2Document, baseOptions);
      }
export function useBiTop5Otav2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiTop5Otav2Query, BiTop5Otav2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiTop5Otav2Query, BiTop5Otav2QueryVariables>(BiTop5Otav2Document, baseOptions);
        }
export type BiTop5Otav2QueryHookResult = ReturnType<typeof useBiTop5Otav2Query>;
export type BiTop5Otav2LazyQueryHookResult = ReturnType<typeof useBiTop5Otav2LazyQuery>;
export type BiTop5Otav2QueryResult = ApolloReactCommon.QueryResult<BiTop5Otav2Query, BiTop5Otav2QueryVariables>;
export const BiTop10RevenueV2Document = gql`
    query BITop10RevenueV2($HotelID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  BITop10RevenueV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useBiTop10RevenueV2Query__
 *
 * To run a query within a React component, call `useBiTop10RevenueV2Query` and pass it any options that fit your needs.
 * When your component renders, `useBiTop10RevenueV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBiTop10RevenueV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useBiTop10RevenueV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BiTop10RevenueV2Query, BiTop10RevenueV2QueryVariables>) {
        return ApolloReactHooks.useQuery<BiTop10RevenueV2Query, BiTop10RevenueV2QueryVariables>(BiTop10RevenueV2Document, baseOptions);
      }
export function useBiTop10RevenueV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BiTop10RevenueV2Query, BiTop10RevenueV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BiTop10RevenueV2Query, BiTop10RevenueV2QueryVariables>(BiTop10RevenueV2Document, baseOptions);
        }
export type BiTop10RevenueV2QueryHookResult = ReturnType<typeof useBiTop10RevenueV2Query>;
export type BiTop10RevenueV2LazyQueryHookResult = ReturnType<typeof useBiTop10RevenueV2LazyQuery>;
export type BiTop10RevenueV2QueryResult = ApolloReactCommon.QueryResult<BiTop10RevenueV2Query, BiTop10RevenueV2QueryVariables>;
export const CheckoutAdjustmentListingDocument = gql`
    query CheckoutAdjustmentListing($HotelID: String!) {
  CheckoutAdjustmentListing(HotelID: $HotelID) {
    ID
    FolioNo
    RefFolio {
      ID
      FolioType
      FolioNo
      RefFolioID
      FolioDate
      IsActive
      HotelID
      FolioAmount
      ClosedDate
      ClosedBy
      IsPosted
      CreatedBy
      ModifiedBy
      Registration {
        ID
        ArrivalDate
        DepartureDate
        CheckInDate
        CheckOutDate
        Booking {
          ID
          BookingNo
        }
        Guest {
          GuestType
          ID
          Contact {
            ID
            Nationality
            FullName
            PhoneNo
            Email
            MobileNo
            Address {
              address
              address2
              city
              state
              postCode
              AddressType
              country
              siteName
            }
          }
        }
        Room {
          RoomNo
          RoomType {
            ID
            Description
            Code
          }
        }
      }
      BillLedger {
        ID
        TrxDate
        TrxAmount
        TaxAmount
        ServiceCharge
        PaymentID
        ReferenceNo
        RegistrationID
        Description
        PrincipalRegistration {
          ID
        }
        IncidentalCharge {
          ID
          Description
          DepartmentID
        }
      }
    }
    ID
    FolioType
    FolioNo
    RefFolioID
    FolioDate
    IsActive
    HotelID
    FolioAmount
    ClosedDate
    ClosedBy
    IsPosted
    Registration {
      ID
      ArrivalDate
      DepartureDate
      CheckInDate
      CheckOutDate
      Booking {
        ID
        BookingNo
      }
      Guest {
        GuestType
        ID
        Contact {
          ID
          Nationality
          FullName
          PhoneNo
          MobileNo
          Email
          Address {
            address
            address2
            city
            state
            postCode
            AddressType
            country
            siteName
          }
        }
      }
      Room {
        RoomNo
        RoomType {
          ID
          Description
          Code
        }
      }
    }
    BillLedger {
      ID
      TrxDate
      TrxAmount
      TaxAmount
      ServiceCharge
      PaymentID
      ReferenceNo
      RegistrationID
      Description
      PrincipalRegistration {
        ID
      }
      IncidentalCharge {
        ID
        Description
        DepartmentID
      }
    }
  }
}
    `;

/**
 * __useCheckoutAdjustmentListingQuery__
 *
 * To run a query within a React component, call `useCheckoutAdjustmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutAdjustmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutAdjustmentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCheckoutAdjustmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckoutAdjustmentListingQuery, CheckoutAdjustmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckoutAdjustmentListingQuery, CheckoutAdjustmentListingQueryVariables>(CheckoutAdjustmentListingDocument, baseOptions);
      }
export function useCheckoutAdjustmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutAdjustmentListingQuery, CheckoutAdjustmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckoutAdjustmentListingQuery, CheckoutAdjustmentListingQueryVariables>(CheckoutAdjustmentListingDocument, baseOptions);
        }
export type CheckoutAdjustmentListingQueryHookResult = ReturnType<typeof useCheckoutAdjustmentListingQuery>;
export type CheckoutAdjustmentListingLazyQueryHookResult = ReturnType<typeof useCheckoutAdjustmentListingLazyQuery>;
export type CheckoutAdjustmentListingQueryResult = ApolloReactCommon.QueryResult<CheckoutAdjustmentListingQuery, CheckoutAdjustmentListingQueryVariables>;
export const SearchFolioDocument = gql`
    query SearchFolio($HotelID: String!, $FolioNo: String!) {
  SearchFolio(HotelID: $HotelID, FolioNo: $FolioNo) {
    ID
    FolioNo
    RefID
    FolioType
    FolioDate
    FolioAmount
    CreatedBy
    Booking {
      ID
      BookingNo
      DebtorAccount
    }
    Registration {
      ID
      Room {
        RoomNo
        RoomType {
          Code
        }
      }
      Guest {
        GuestType
        Contact {
          ID
          FullName
          Nationality
          PhoneNo
          Email
          Address {
            address
            address2
            city
            state
            postCode
            AddressType
            country
            siteName
          }
        }
      }
      Booking {
        BookingNo
        BookingStatus
        Debtor {
          ID
          DebtorAccount
          Name
        }
      }
      ArrivalDate
      DepartureDate
      RegistrationStatus
    }
    BillLedger {
      ID
      RegistrationID
      TrxDate
      TrxAmount
      Description
      ReferenceNo
      IncidentalCharge {
        ID
        Description
      }
    }
    RoomLedger {
      ID
      RegistrationID
      TrxDate
      TrxAmount
      Description
      ReferenceNo
    }
    Payment {
      ID
      RegistrationID
      PaymentDate
      Amount
      CardNo
      ReceiptNo
      PaymentClass
      PaymentType
      PaymentStatus
      ReferenceNo
      DebtorAccount
    }
    Registration {
      ID
      ArrivalDate
      DepartureDate
      RegistrationStatus
      Booking {
        BookingNo
        BookingStatus
        Debtor {
          ID
          DebtorAccount
          Name
        }
      }
      Room {
        RoomNo
        RoomType {
          Code
        }
      }
      Guest {
        GuestType
        Contact {
          ID
          Nationality
          FullName
          PhoneNo
          Email
          Address {
            address
            address2
            city
            state
            postCode
            AddressType
            country
            siteName
          }
        }
      }
      Booking {
        BookingNo
        BookingStatus
        Debtor {
          ID
          DebtorAccount
          Name
        }
      }
    }
  }
}
    `;

/**
 * __useSearchFolioQuery__
 *
 * To run a query within a React component, call `useSearchFolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFolioQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      FolioNo: // value for 'FolioNo'
 *   },
 * });
 */
export function useSearchFolioQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchFolioQuery, SearchFolioQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchFolioQuery, SearchFolioQueryVariables>(SearchFolioDocument, baseOptions);
      }
export function useSearchFolioLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchFolioQuery, SearchFolioQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchFolioQuery, SearchFolioQueryVariables>(SearchFolioDocument, baseOptions);
        }
export type SearchFolioQueryHookResult = ReturnType<typeof useSearchFolioQuery>;
export type SearchFolioLazyQueryHookResult = ReturnType<typeof useSearchFolioLazyQuery>;
export type SearchFolioQueryResult = ApolloReactCommon.QueryResult<SearchFolioQuery, SearchFolioQueryVariables>;
export const CheckedOutFolioListingDocument = gql`
    query CheckedOutFolioListing($HotelID: String!, $FolioDate: DateTime, $RoomNo: String, $FolioNo: String, $FullName: String, $BookingNo: String, $Adjusted: Boolean, $AccountName: String, $StartDate: DateTime, $EndDate: DateTime, $offset: Float, $limit: Float) {
  CheckedOutFolioListing(HotelID: $HotelID, FolioDate: $FolioDate, RoomNo: $RoomNo, FolioNo: $FolioNo, FullName: $FullName, BookingNo: $BookingNo, Adjusted: $Adjusted, AccountName: $AccountName, StartDate: $StartDate, EndDate: $EndDate, offset: $offset, limit: $limit)
}
    `;

/**
 * __useCheckedOutFolioListingQuery__
 *
 * To run a query within a React component, call `useCheckedOutFolioListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckedOutFolioListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckedOutFolioListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      FolioDate: // value for 'FolioDate'
 *      RoomNo: // value for 'RoomNo'
 *      FolioNo: // value for 'FolioNo'
 *      FullName: // value for 'FullName'
 *      BookingNo: // value for 'BookingNo'
 *      Adjusted: // value for 'Adjusted'
 *      AccountName: // value for 'AccountName'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCheckedOutFolioListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckedOutFolioListingQuery, CheckedOutFolioListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckedOutFolioListingQuery, CheckedOutFolioListingQueryVariables>(CheckedOutFolioListingDocument, baseOptions);
      }
export function useCheckedOutFolioListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckedOutFolioListingQuery, CheckedOutFolioListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckedOutFolioListingQuery, CheckedOutFolioListingQueryVariables>(CheckedOutFolioListingDocument, baseOptions);
        }
export type CheckedOutFolioListingQueryHookResult = ReturnType<typeof useCheckedOutFolioListingQuery>;
export type CheckedOutFolioListingLazyQueryHookResult = ReturnType<typeof useCheckedOutFolioListingLazyQuery>;
export type CheckedOutFolioListingQueryResult = ApolloReactCommon.QueryResult<CheckedOutFolioListingQuery, CheckedOutFolioListingQueryVariables>;
export const AdjustedFolioDetailsDocument = gql`
    query AdjustedFolioDetails($ID: String!, $HotelID: String!) {
  AdjustedFolioDetails(ID: $ID, HotelID: $HotelID) {
    ID
    FolioNo
    FolioType
    IsSuspend
    FolioDate
    FolioAmount
    CreatedBy
    FolioType
    DocumentType
    NonGuestInfo {
      EffectiveDate
      Title
      DebtorID
      Remark
      HouseLimit
    }
    Registration {
      ID
      Booking {
        ID
        BookingNo
      }
      Guest {
        ID
        GuestType
        Contact {
          ID
          Nationality
          FullName
        }
      }
      Room {
        ID
        RoomNo
      }
    }
    AdvancePayment {
      Amount
      PaymentType
      PaymentClass
      PaySessionNo
      ReferenceNo
      ID
    }
    Payment {
      Amount
      CardNo
      ReceiptNo
      PaymentDate
      PaymentType
      PaymentClass
      DebtorAccount
      PaySessionNo
      ReferenceNo
      ID
      Registration {
        ID
        Guest {
          Contact {
            FullName
          }
        }
        Room {
          RoomNo
        }
      }
    }
    TaxLedger {
      ID
      TrxDate
      TaxType
      TaxDescription
      TaxAmount
      Reason
      Description
      Qty
      IsCNAdjusted
      Registration {
        ID
        Guest {
          Contact {
            FullName
          }
        }
        Room {
          RoomNo
        }
      }
    }
    BillLedger {
      ID
      TrxDate
      BaseAmount
      ServiceCharge
      TaxAmount
      TrxAmount
      ReferenceNo
      Qty
      UnitPrice
      IsCNAdjusted
      IncidentalCharge {
        ID
        Description
      }
      Registration {
        ID
        Guest {
          Contact {
            FullName
          }
        }
        Room {
          RoomNo
        }
      }
    }
    RoomCharges {
      TrxDate
      BaseAmount
      Description
      TaxAmount
      ServiceCharge
      RegistrationID
    }
    RoomLedger {
      ID
      TrxAmount
      TrxDate
      TaxAmount
      BaseAmount
      TransactionType
      TransactionID
      IsCNAdjusted
      Transaction {
        Description
      }
      Registration {
        ID
        Guest {
          Contact {
            FullName
          }
        }
        Room {
          RoomNo
        }
      }
      ServiceCharge
      ReferenceNo
      Description
    }
    DepositLedger {
      DepositAmount
      DepositClass
      ReferenceNo
      TrxDate
      ActualDepositClass
      IsCNAdjusted
    }
  }
}
    `;

/**
 * __useAdjustedFolioDetailsQuery__
 *
 * To run a query within a React component, call `useAdjustedFolioDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdjustedFolioDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdjustedFolioDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAdjustedFolioDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AdjustedFolioDetailsQuery, AdjustedFolioDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<AdjustedFolioDetailsQuery, AdjustedFolioDetailsQueryVariables>(AdjustedFolioDetailsDocument, baseOptions);
      }
export function useAdjustedFolioDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdjustedFolioDetailsQuery, AdjustedFolioDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AdjustedFolioDetailsQuery, AdjustedFolioDetailsQueryVariables>(AdjustedFolioDetailsDocument, baseOptions);
        }
export type AdjustedFolioDetailsQueryHookResult = ReturnType<typeof useAdjustedFolioDetailsQuery>;
export type AdjustedFolioDetailsLazyQueryHookResult = ReturnType<typeof useAdjustedFolioDetailsLazyQuery>;
export type AdjustedFolioDetailsQueryResult = ApolloReactCommon.QueryResult<AdjustedFolioDetailsQuery, AdjustedFolioDetailsQueryVariables>;
export const ChangePaymentDocument = gql`
    mutation ChangePayment($PaymentInput: PaymentInput!, $ReversedPaymentID: String!, $FolioID: String!, $HotelID: String!) {
  ChangePayment(PaymentInput: $PaymentInput, ReversedPaymentID: $ReversedPaymentID, FolioID: $FolioID, HotelID: $HotelID)
}
    `;
export type ChangePaymentMutationFn = ApolloReactCommon.MutationFunction<ChangePaymentMutation, ChangePaymentMutationVariables>;

/**
 * __useChangePaymentMutation__
 *
 * To run a mutation, you first call `useChangePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePaymentMutation, { data, loading, error }] = useChangePaymentMutation({
 *   variables: {
 *      PaymentInput: // value for 'PaymentInput'
 *      ReversedPaymentID: // value for 'ReversedPaymentID'
 *      FolioID: // value for 'FolioID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useChangePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePaymentMutation, ChangePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePaymentMutation, ChangePaymentMutationVariables>(ChangePaymentDocument, baseOptions);
      }
export type ChangePaymentMutationHookResult = ReturnType<typeof useChangePaymentMutation>;
export type ChangePaymentMutationResult = ApolloReactCommon.MutationResult<ChangePaymentMutation>;
export type ChangePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePaymentMutation, ChangePaymentMutationVariables>;
export const CheckIsGroupInvoiceDocument = gql`
    query CheckIsGroupInvoice($HotelID: String!, $SourceID: String!) {
  CheckIsGroupInvoice(HotelID: $HotelID, SourceID: $SourceID)
}
    `;

/**
 * __useCheckIsGroupInvoiceQuery__
 *
 * To run a query within a React component, call `useCheckIsGroupInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIsGroupInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIsGroupInvoiceQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      SourceID: // value for 'SourceID'
 *   },
 * });
 */
export function useCheckIsGroupInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckIsGroupInvoiceQuery, CheckIsGroupInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckIsGroupInvoiceQuery, CheckIsGroupInvoiceQueryVariables>(CheckIsGroupInvoiceDocument, baseOptions);
      }
export function useCheckIsGroupInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckIsGroupInvoiceQuery, CheckIsGroupInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckIsGroupInvoiceQuery, CheckIsGroupInvoiceQueryVariables>(CheckIsGroupInvoiceDocument, baseOptions);
        }
export type CheckIsGroupInvoiceQueryHookResult = ReturnType<typeof useCheckIsGroupInvoiceQuery>;
export type CheckIsGroupInvoiceLazyQueryHookResult = ReturnType<typeof useCheckIsGroupInvoiceLazyQuery>;
export type CheckIsGroupInvoiceQueryResult = ApolloReactCommon.QueryResult<CheckIsGroupInvoiceQuery, CheckIsGroupInvoiceQueryVariables>;
export const FolioRegIdsDocument = gql`
    query FolioRegIds($FolioID: String!) {
  FolioRegIds(FolioID: $FolioID)
}
    `;

/**
 * __useFolioRegIdsQuery__
 *
 * To run a query within a React component, call `useFolioRegIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolioRegIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolioRegIdsQuery({
 *   variables: {
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function useFolioRegIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FolioRegIdsQuery, FolioRegIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<FolioRegIdsQuery, FolioRegIdsQueryVariables>(FolioRegIdsDocument, baseOptions);
      }
export function useFolioRegIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FolioRegIdsQuery, FolioRegIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FolioRegIdsQuery, FolioRegIdsQueryVariables>(FolioRegIdsDocument, baseOptions);
        }
export type FolioRegIdsQueryHookResult = ReturnType<typeof useFolioRegIdsQuery>;
export type FolioRegIdsLazyQueryHookResult = ReturnType<typeof useFolioRegIdsLazyQuery>;
export type FolioRegIdsQueryResult = ApolloReactCommon.QueryResult<FolioRegIdsQuery, FolioRegIdsQueryVariables>;
export const FolioAdjustmentInsertDocument = gql`
    mutation FolioAdjustmentInsert($RefFolioID: String, $FolioType: String!, $IsSuspend: Boolean!, $PaymentInput: PaymentInput!, $RoomLedgerInput: [RoomLedgerInput!]!, $BillLedgerInput: [BillLedgerInput!]!, $TaxLedgerInput: [TaxLedgerInput!]!, $DepositLedgerInput: [DepositLedgerInput!]!, $AdvancePaymentInput: [AdvancePaymentInput!]!, $HotelID: String!, $IsEInvoice: Boolean) {
  FolioAdjustmentInsert(RefFolioID: $RefFolioID, FolioType: $FolioType, IsSuspend: $IsSuspend, PaymentInput: $PaymentInput, RoomLedgerInput: $RoomLedgerInput, BillLedgerInput: $BillLedgerInput, TaxLedgerInput: $TaxLedgerInput, DepositLedgerInput: $DepositLedgerInput, AdvancePaymentInput: $AdvancePaymentInput, HotelID: $HotelID, IsEInvoice: $IsEInvoice) {
    ReceiptNo
    ID
  }
}
    `;
export type FolioAdjustmentInsertMutationFn = ApolloReactCommon.MutationFunction<FolioAdjustmentInsertMutation, FolioAdjustmentInsertMutationVariables>;

/**
 * __useFolioAdjustmentInsertMutation__
 *
 * To run a mutation, you first call `useFolioAdjustmentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFolioAdjustmentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [folioAdjustmentInsertMutation, { data, loading, error }] = useFolioAdjustmentInsertMutation({
 *   variables: {
 *      RefFolioID: // value for 'RefFolioID'
 *      FolioType: // value for 'FolioType'
 *      IsSuspend: // value for 'IsSuspend'
 *      PaymentInput: // value for 'PaymentInput'
 *      RoomLedgerInput: // value for 'RoomLedgerInput'
 *      BillLedgerInput: // value for 'BillLedgerInput'
 *      TaxLedgerInput: // value for 'TaxLedgerInput'
 *      DepositLedgerInput: // value for 'DepositLedgerInput'
 *      AdvancePaymentInput: // value for 'AdvancePaymentInput'
 *      HotelID: // value for 'HotelID'
 *      IsEInvoice: // value for 'IsEInvoice'
 *   },
 * });
 */
export function useFolioAdjustmentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FolioAdjustmentInsertMutation, FolioAdjustmentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<FolioAdjustmentInsertMutation, FolioAdjustmentInsertMutationVariables>(FolioAdjustmentInsertDocument, baseOptions);
      }
export type FolioAdjustmentInsertMutationHookResult = ReturnType<typeof useFolioAdjustmentInsertMutation>;
export type FolioAdjustmentInsertMutationResult = ApolloReactCommon.MutationResult<FolioAdjustmentInsertMutation>;
export type FolioAdjustmentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<FolioAdjustmentInsertMutation, FolioAdjustmentInsertMutationVariables>;
export const FolioChargeToListDocument = gql`
    query FolioChargeToList($HotelID: String!, $FolioID: String!) {
  FolioChargeToList(HotelID: $HotelID, FolioID: $FolioID)
}
    `;

/**
 * __useFolioChargeToListQuery__
 *
 * To run a query within a React component, call `useFolioChargeToListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolioChargeToListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolioChargeToListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function useFolioChargeToListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FolioChargeToListQuery, FolioChargeToListQueryVariables>) {
        return ApolloReactHooks.useQuery<FolioChargeToListQuery, FolioChargeToListQueryVariables>(FolioChargeToListDocument, baseOptions);
      }
export function useFolioChargeToListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FolioChargeToListQuery, FolioChargeToListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FolioChargeToListQuery, FolioChargeToListQueryVariables>(FolioChargeToListDocument, baseOptions);
        }
export type FolioChargeToListQueryHookResult = ReturnType<typeof useFolioChargeToListQuery>;
export type FolioChargeToListLazyQueryHookResult = ReturnType<typeof useFolioChargeToListLazyQuery>;
export type FolioChargeToListQueryResult = ApolloReactCommon.QueryResult<FolioChargeToListQuery, FolioChargeToListQueryVariables>;
export const GuestRoomResultDocument = gql`
    query GuestRoomResult($HotelID: String!, $BookingID: String!) {
  GuestRoomResult(BookingID: $BookingID, HotelID: $HotelID)
}
    `;

/**
 * __useGuestRoomResultQuery__
 *
 * To run a query within a React component, call `useGuestRoomResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestRoomResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestRoomResultQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useGuestRoomResultQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestRoomResultQuery, GuestRoomResultQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestRoomResultQuery, GuestRoomResultQueryVariables>(GuestRoomResultDocument, baseOptions);
      }
export function useGuestRoomResultLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestRoomResultQuery, GuestRoomResultQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestRoomResultQuery, GuestRoomResultQueryVariables>(GuestRoomResultDocument, baseOptions);
        }
export type GuestRoomResultQueryHookResult = ReturnType<typeof useGuestRoomResultQuery>;
export type GuestRoomResultLazyQueryHookResult = ReturnType<typeof useGuestRoomResultLazyQuery>;
export type GuestRoomResultQueryResult = ApolloReactCommon.QueryResult<GuestRoomResultQuery, GuestRoomResultQueryVariables>;
export const ActivityInsertDocument = gql`
    mutation ActivityInsert($ActivitiesInput: [ActivitiesInput!], $AttachmentInput: [Upload!]) {
  ActivityInsert(ActivitiesInput: $ActivitiesInput, AttachmentInput: $AttachmentInput)
}
    `;
export type ActivityInsertMutationFn = ApolloReactCommon.MutationFunction<ActivityInsertMutation, ActivityInsertMutationVariables>;

/**
 * __useActivityInsertMutation__
 *
 * To run a mutation, you first call `useActivityInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityInsertMutation, { data, loading, error }] = useActivityInsertMutation({
 *   variables: {
 *      ActivitiesInput: // value for 'ActivitiesInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useActivityInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivityInsertMutation, ActivityInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivityInsertMutation, ActivityInsertMutationVariables>(ActivityInsertDocument, baseOptions);
      }
export type ActivityInsertMutationHookResult = ReturnType<typeof useActivityInsertMutation>;
export type ActivityInsertMutationResult = ApolloReactCommon.MutationResult<ActivityInsertMutation>;
export type ActivityInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivityInsertMutation, ActivityInsertMutationVariables>;
export const ActivityUpdateDocument = gql`
    mutation ActivityUpdate($ActivitiesInput: [ActivitiesInput!], $AttachmentInput: [Upload!]) {
  ActivityUpdate(ActivitiesInput: $ActivitiesInput, AttachmentInput: $AttachmentInput)
}
    `;
export type ActivityUpdateMutationFn = ApolloReactCommon.MutationFunction<ActivityUpdateMutation, ActivityUpdateMutationVariables>;

/**
 * __useActivityUpdateMutation__
 *
 * To run a mutation, you first call `useActivityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activityUpdateMutation, { data, loading, error }] = useActivityUpdateMutation({
 *   variables: {
 *      ActivitiesInput: // value for 'ActivitiesInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useActivityUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivityUpdateMutation, ActivityUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivityUpdateMutation, ActivityUpdateMutationVariables>(ActivityUpdateDocument, baseOptions);
      }
export type ActivityUpdateMutationHookResult = ReturnType<typeof useActivityUpdateMutation>;
export type ActivityUpdateMutationResult = ApolloReactCommon.MutationResult<ActivityUpdateMutation>;
export type ActivityUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivityUpdateMutation, ActivityUpdateMutationVariables>;
export const ActivitiesListingDocument = gql`
    query ActivitiesListing($HotelID: String!, $DebtorID: String, $DebtorTypeID: String, $Status: String, $ActivityDate: DateTime, $StartDate: DateTime, $EndDate: DateTime, $limit: Float, $offset: Float) {
  ActivitiesListing(HotelID: $HotelID, DebtorID: $DebtorID, DebtorTypeID: $DebtorTypeID, Status: $Status, ActivityDate: $ActivityDate, StartDate: $StartDate, EndDate: $EndDate, limit: $limit, offset: $offset) {
    ID
    HotelID
    Status
    ActivityDate
    Attachment
    Remark
    CreatedBy
    CreatedDT
    ModifiedBy
    ModifiedDT
    AccountID
    Audio {
      ID
      ImageURL
    }
    Debtor {
      ID
      ARTotalOutstanding
      ARTotalDue
      Name
      LastPaymentDate
      Contact {
        Email
        PhoneNo
        MobileNo
      }
      DebtorContact {
        ID
        Contact {
          ID
          FullName
          Email
          MobileNo
          Designation
        }
      }
    }
  }
}
    `;

/**
 * __useActivitiesListingQuery__
 *
 * To run a query within a React component, call `useActivitiesListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      DebtorTypeID: // value for 'DebtorTypeID'
 *      Status: // value for 'Status'
 *      ActivityDate: // value for 'ActivityDate'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useActivitiesListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActivitiesListingQuery, ActivitiesListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ActivitiesListingQuery, ActivitiesListingQueryVariables>(ActivitiesListingDocument, baseOptions);
      }
export function useActivitiesListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivitiesListingQuery, ActivitiesListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActivitiesListingQuery, ActivitiesListingQueryVariables>(ActivitiesListingDocument, baseOptions);
        }
export type ActivitiesListingQueryHookResult = ReturnType<typeof useActivitiesListingQuery>;
export type ActivitiesListingLazyQueryHookResult = ReturnType<typeof useActivitiesListingLazyQuery>;
export type ActivitiesListingQueryResult = ApolloReactCommon.QueryResult<ActivitiesListingQuery, ActivitiesListingQueryVariables>;
export const DebtorListingDocument = gql`
    query DebtorListing($HotelID: String!, $DebtorType: String, $IsActive: Boolean, $Country: String, $DebtorAccount: String, $CompanyName: String, $limit: Float, $offset: Float) {
  DebtorListing(HotelID: $HotelID, DebtorType: $DebtorType, IsActive: $IsActive, Country: $Country, DebtorAccount: $DebtorAccount, CompanyName: $CompanyName, limit: $limit, offset: $offset) {
    IseCommerce
    ID
    IsActive
    IsFrontDeskActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    IsGroupInvoice
    CreditCardMapping {
      Name
    }
    DebtorType {
      DebtorType
      Description
    }
    DebtorCategory
    Name
    DebtorAccount
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    Remarks
    Segment
    Login
    Password
    Tin
    InvoiceType
    TaxRegNo
    IsIndividual
    IseCommerce
    Contact {
      ID
      FullName
      PhoneNo
      Email
      Address {
        address2
        address
        city
        state
        country
        postCode
      }
    }
    LastPaymentDate
    DebtorContact {
      ID
      Contact {
        ID
        FullName
        Email
        MobileNo
        Designation
      }
    }
    DebtorType {
      DebtorType
      Description
    }
  }
}
    `;

/**
 * __useDebtorListingQuery__
 *
 * To run a query within a React component, call `useDebtorListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *      IsActive: // value for 'IsActive'
 *      Country: // value for 'Country'
 *      DebtorAccount: // value for 'DebtorAccount'
 *      CompanyName: // value for 'CompanyName'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDebtorListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorListingQuery, DebtorListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorListingQuery, DebtorListingQueryVariables>(DebtorListingDocument, baseOptions);
      }
export function useDebtorListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorListingQuery, DebtorListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorListingQuery, DebtorListingQueryVariables>(DebtorListingDocument, baseOptions);
        }
export type DebtorListingQueryHookResult = ReturnType<typeof useDebtorListingQuery>;
export type DebtorListingLazyQueryHookResult = ReturnType<typeof useDebtorListingLazyQuery>;
export type DebtorListingQueryResult = ApolloReactCommon.QueryResult<DebtorListingQuery, DebtorListingQueryVariables>;
export const SoADebtorListingDocument = gql`
    query SoADebtorListing($HotelID: String!, $DebtorType: String, $IsActive: Boolean, $Country: String, $DebtorAccount: String, $CompanyName: String, $Offset: Float, $Limit: Float) {
  DebtorListing(HotelID: $HotelID, DebtorType: $DebtorType, IsActive: $IsActive, Country: $Country, DebtorAccount: $DebtorAccount, CompanyName: $CompanyName, offset: $Offset, limit: $Limit) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    IsGroupInvoice
    ARTotalOutstanding
    CreditCardMapping {
      Name
    }
    DebtorType {
      DebtorType
      Description
    }
    DebtorCategory
    Name
    DebtorAccount
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    Remarks
    Segment
    Login
    Password
    Contact {
      ID
      FullName
      PhoneNo
      Email
      Address {
        address2
        address
        city
        state
        country
        postCode
      }
    }
    LastPaymentDate
    DebtorContact {
      ID
      Contact {
        ID
        FullName
        Email
        MobileNo
        Designation
      }
    }
    DebtorType {
      DebtorType
      Description
    }
  }
}
    `;

/**
 * __useSoADebtorListingQuery__
 *
 * To run a query within a React component, call `useSoADebtorListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoADebtorListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoADebtorListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *      IsActive: // value for 'IsActive'
 *      Country: // value for 'Country'
 *      DebtorAccount: // value for 'DebtorAccount'
 *      CompanyName: // value for 'CompanyName'
 *      Offset: // value for 'Offset'
 *      Limit: // value for 'Limit'
 *   },
 * });
 */
export function useSoADebtorListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SoADebtorListingQuery, SoADebtorListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SoADebtorListingQuery, SoADebtorListingQueryVariables>(SoADebtorListingDocument, baseOptions);
      }
export function useSoADebtorListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SoADebtorListingQuery, SoADebtorListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SoADebtorListingQuery, SoADebtorListingQueryVariables>(SoADebtorListingDocument, baseOptions);
        }
export type SoADebtorListingQueryHookResult = ReturnType<typeof useSoADebtorListingQuery>;
export type SoADebtorListingLazyQueryHookResult = ReturnType<typeof useSoADebtorListingLazyQuery>;
export type SoADebtorListingQueryResult = ApolloReactCommon.QueryResult<SoADebtorListingQuery, SoADebtorListingQueryVariables>;
export const DebtorBookingListingDocument = gql`
    query DebtorBookingListing($HotelID: String!, $DebtorType: String, $IsActive: Boolean, $Country: String, $DebtorAccount: String, $CompanyName: String, $Offset: Float, $Limit: Float) {
  DebtorListing(HotelID: $HotelID, DebtorType: $DebtorType, IsActive: $IsActive, Country: $Country, DebtorAccount: $DebtorAccount, CompanyName: $CompanyName, offset: $Offset, limit: $Limit) {
    ID
    IsActive
    DebtorCategory
    Name
    DebtorAccount
    CreditCardMapping {
      Name
    }
  }
}
    `;

/**
 * __useDebtorBookingListingQuery__
 *
 * To run a query within a React component, call `useDebtorBookingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorBookingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorBookingListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *      IsActive: // value for 'IsActive'
 *      Country: // value for 'Country'
 *      DebtorAccount: // value for 'DebtorAccount'
 *      CompanyName: // value for 'CompanyName'
 *      Offset: // value for 'Offset'
 *      Limit: // value for 'Limit'
 *   },
 * });
 */
export function useDebtorBookingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorBookingListingQuery, DebtorBookingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorBookingListingQuery, DebtorBookingListingQueryVariables>(DebtorBookingListingDocument, baseOptions);
      }
export function useDebtorBookingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorBookingListingQuery, DebtorBookingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorBookingListingQuery, DebtorBookingListingQueryVariables>(DebtorBookingListingDocument, baseOptions);
        }
export type DebtorBookingListingQueryHookResult = ReturnType<typeof useDebtorBookingListingQuery>;
export type DebtorBookingListingLazyQueryHookResult = ReturnType<typeof useDebtorBookingListingLazyQuery>;
export type DebtorBookingListingQueryResult = ApolloReactCommon.QueryResult<DebtorBookingListingQuery, DebtorBookingListingQueryVariables>;
export const DebtorListingCreditFacilityDocument = gql`
    query DebtorListingCreditFacility($HotelID: String!, $DebtorType: String, $IsActive: Boolean, $Country: String, $DebtorAccount: String, $CompanyName: String, $Offset: Float, $Limit: Float) {
  DebtorListingCreditFacility(HotelID: $HotelID, DebtorType: $DebtorType, IsActive: $IsActive, Country: $Country, DebtorAccount: $DebtorAccount, CompanyName: $CompanyName, offset: $Offset, limit: $Limit) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    CreditCardMapping {
      Name
    }
    DebtorType {
      DebtorType
      Description
    }
    DebtorCategory
    Name
    DebtorAccount
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    Remarks
    Segment
    Login
    Password
    ARTotalDue
    Contact {
      ID
      FullName
      PhoneNo
      Email
      Address {
        address2
        address
        city
        state
        country
        postCode
      }
    }
    LastPaymentDate
    DebtorContact {
      ID
      Contact {
        ID
        FullName
        Email
        MobileNo
        Designation
      }
    }
    DebtorType {
      DebtorType
      Description
    }
  }
}
    `;

/**
 * __useDebtorListingCreditFacilityQuery__
 *
 * To run a query within a React component, call `useDebtorListingCreditFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorListingCreditFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorListingCreditFacilityQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *      IsActive: // value for 'IsActive'
 *      Country: // value for 'Country'
 *      DebtorAccount: // value for 'DebtorAccount'
 *      CompanyName: // value for 'CompanyName'
 *      Offset: // value for 'Offset'
 *      Limit: // value for 'Limit'
 *   },
 * });
 */
export function useDebtorListingCreditFacilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorListingCreditFacilityQuery, DebtorListingCreditFacilityQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorListingCreditFacilityQuery, DebtorListingCreditFacilityQueryVariables>(DebtorListingCreditFacilityDocument, baseOptions);
      }
export function useDebtorListingCreditFacilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorListingCreditFacilityQuery, DebtorListingCreditFacilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorListingCreditFacilityQuery, DebtorListingCreditFacilityQueryVariables>(DebtorListingCreditFacilityDocument, baseOptions);
        }
export type DebtorListingCreditFacilityQueryHookResult = ReturnType<typeof useDebtorListingCreditFacilityQuery>;
export type DebtorListingCreditFacilityLazyQueryHookResult = ReturnType<typeof useDebtorListingCreditFacilityLazyQuery>;
export type DebtorListingCreditFacilityQueryResult = ApolloReactCommon.QueryResult<DebtorListingCreditFacilityQuery, DebtorListingCreditFacilityQueryVariables>;
export const DebtorListingNonCreditFacilityDocument = gql`
    query DebtorListingNonCreditFacility($HotelID: String!, $DebtorType: String, $IsActive: Boolean, $Country: String, $DebtorAccount: String, $CompanyName: String, $Offset: Float, $Limit: Float) {
  DebtorListingNonCreditFacility(HotelID: $HotelID, DebtorType: $DebtorType, IsActive: $IsActive, Country: $Country, DebtorAccount: $DebtorAccount, CompanyName: $CompanyName, offset: $Offset, limit: $Limit) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    CreditCardMapping {
      Name
    }
    DebtorType {
      DebtorType
      Description
    }
    DebtorCategory
    Name
    DebtorAccount
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    Remarks
    Segment
    Login
    Password
    ARTotalDue
    Contact {
      ID
      FullName
      PhoneNo
      Email
      Address {
        address2
        address
        city
        state
        country
        postCode
      }
    }
    LastPaymentDate
    DebtorContact {
      ID
      Contact {
        ID
        FullName
        Email
        MobileNo
        Designation
      }
    }
    DebtorType {
      DebtorType
      Description
    }
  }
}
    `;

/**
 * __useDebtorListingNonCreditFacilityQuery__
 *
 * To run a query within a React component, call `useDebtorListingNonCreditFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorListingNonCreditFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorListingNonCreditFacilityQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *      IsActive: // value for 'IsActive'
 *      Country: // value for 'Country'
 *      DebtorAccount: // value for 'DebtorAccount'
 *      CompanyName: // value for 'CompanyName'
 *      Offset: // value for 'Offset'
 *      Limit: // value for 'Limit'
 *   },
 * });
 */
export function useDebtorListingNonCreditFacilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorListingNonCreditFacilityQuery, DebtorListingNonCreditFacilityQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorListingNonCreditFacilityQuery, DebtorListingNonCreditFacilityQueryVariables>(DebtorListingNonCreditFacilityDocument, baseOptions);
      }
export function useDebtorListingNonCreditFacilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorListingNonCreditFacilityQuery, DebtorListingNonCreditFacilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorListingNonCreditFacilityQuery, DebtorListingNonCreditFacilityQueryVariables>(DebtorListingNonCreditFacilityDocument, baseOptions);
        }
export type DebtorListingNonCreditFacilityQueryHookResult = ReturnType<typeof useDebtorListingNonCreditFacilityQuery>;
export type DebtorListingNonCreditFacilityLazyQueryHookResult = ReturnType<typeof useDebtorListingNonCreditFacilityLazyQuery>;
export type DebtorListingNonCreditFacilityQueryResult = ApolloReactCommon.QueryResult<DebtorListingNonCreditFacilityQuery, DebtorListingNonCreditFacilityQueryVariables>;
export const CalculateDebtorListingDocument = gql`
    query CalculateDebtorListing($HotelID: String!) {
  CalculateDebtorListing(HotelID: $HotelID)
}
    `;

/**
 * __useCalculateDebtorListingQuery__
 *
 * To run a query within a React component, call `useCalculateDebtorListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateDebtorListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateDebtorListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCalculateDebtorListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalculateDebtorListingQuery, CalculateDebtorListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CalculateDebtorListingQuery, CalculateDebtorListingQueryVariables>(CalculateDebtorListingDocument, baseOptions);
      }
export function useCalculateDebtorListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalculateDebtorListingQuery, CalculateDebtorListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CalculateDebtorListingQuery, CalculateDebtorListingQueryVariables>(CalculateDebtorListingDocument, baseOptions);
        }
export type CalculateDebtorListingQueryHookResult = ReturnType<typeof useCalculateDebtorListingQuery>;
export type CalculateDebtorListingLazyQueryHookResult = ReturnType<typeof useCalculateDebtorListingLazyQuery>;
export type CalculateDebtorListingQueryResult = ApolloReactCommon.QueryResult<CalculateDebtorListingQuery, CalculateDebtorListingQueryVariables>;
export const DebtorListingInfoLines2Document = gql`
    query DebtorListingInfoLines2($HotelID: String!, $IsPosted: Boolean, $ActivityStartDate: DateTime, $ActivityEndDate: DateTime) {
  DebtorCountListing(HotelID: $HotelID)
  DebtorTypeCountListing(HotelID: $HotelID)
  ScheduleBillingCountList(HotelID: $HotelID, IsPosted: $IsPosted)
  ActivitiesCountListing(HotelID: $HotelID, StartDate: $ActivityStartDate, EndDate: $ActivityEndDate)
}
    `;

/**
 * __useDebtorListingInfoLines2Query__
 *
 * To run a query within a React component, call `useDebtorListingInfoLines2Query` and pass it any options that fit your needs.
 * When your component renders, `useDebtorListingInfoLines2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorListingInfoLines2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      IsPosted: // value for 'IsPosted'
 *      ActivityStartDate: // value for 'ActivityStartDate'
 *      ActivityEndDate: // value for 'ActivityEndDate'
 *   },
 * });
 */
export function useDebtorListingInfoLines2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorListingInfoLines2Query, DebtorListingInfoLines2QueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorListingInfoLines2Query, DebtorListingInfoLines2QueryVariables>(DebtorListingInfoLines2Document, baseOptions);
      }
export function useDebtorListingInfoLines2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorListingInfoLines2Query, DebtorListingInfoLines2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorListingInfoLines2Query, DebtorListingInfoLines2QueryVariables>(DebtorListingInfoLines2Document, baseOptions);
        }
export type DebtorListingInfoLines2QueryHookResult = ReturnType<typeof useDebtorListingInfoLines2Query>;
export type DebtorListingInfoLines2LazyQueryHookResult = ReturnType<typeof useDebtorListingInfoLines2LazyQuery>;
export type DebtorListingInfoLines2QueryResult = ApolloReactCommon.QueryResult<DebtorListingInfoLines2Query, DebtorListingInfoLines2QueryVariables>;
export const DebtorListDocument = gql`
    query DebtorList($HotelID: String!, $DebtorType: String, $IsActive: Boolean) {
  DebtorListing(HotelID: $HotelID, DebtorType: $DebtorType, IsActive: $IsActive) {
    ID
    Name
    DebtorAccount
  }
}
    `;

/**
 * __useDebtorListQuery__
 *
 * To run a query within a React component, call `useDebtorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useDebtorListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorListQuery, DebtorListQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorListQuery, DebtorListQueryVariables>(DebtorListDocument, baseOptions);
      }
export function useDebtorListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorListQuery, DebtorListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorListQuery, DebtorListQueryVariables>(DebtorListDocument, baseOptions);
        }
export type DebtorListQueryHookResult = ReturnType<typeof useDebtorListQuery>;
export type DebtorListLazyQueryHookResult = ReturnType<typeof useDebtorListLazyQuery>;
export type DebtorListQueryResult = ApolloReactCommon.QueryResult<DebtorListQuery, DebtorListQueryVariables>;
export const DebtorDetailByAccountCodeDocument = gql`
    query DebtorDetailByAccountCode($AccountCode: String!, $HotelID: String!) {
  DebtorDetailByAccountCode(AccountCode: $AccountCode, HotelID: $HotelID) {
    ID
    Name
    IsIndividual
    DebtorAccount
  }
}
    `;

/**
 * __useDebtorDetailByAccountCodeQuery__
 *
 * To run a query within a React component, call `useDebtorDetailByAccountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorDetailByAccountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorDetailByAccountCodeQuery({
 *   variables: {
 *      AccountCode: // value for 'AccountCode'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDebtorDetailByAccountCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorDetailByAccountCodeQuery, DebtorDetailByAccountCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorDetailByAccountCodeQuery, DebtorDetailByAccountCodeQueryVariables>(DebtorDetailByAccountCodeDocument, baseOptions);
      }
export function useDebtorDetailByAccountCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorDetailByAccountCodeQuery, DebtorDetailByAccountCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorDetailByAccountCodeQuery, DebtorDetailByAccountCodeQueryVariables>(DebtorDetailByAccountCodeDocument, baseOptions);
        }
export type DebtorDetailByAccountCodeQueryHookResult = ReturnType<typeof useDebtorDetailByAccountCodeQuery>;
export type DebtorDetailByAccountCodeLazyQueryHookResult = ReturnType<typeof useDebtorDetailByAccountCodeLazyQuery>;
export type DebtorDetailByAccountCodeQueryResult = ApolloReactCommon.QueryResult<DebtorDetailByAccountCodeQuery, DebtorDetailByAccountCodeQueryVariables>;
export const DebtorDetailDocument = gql`
    query DebtorDetail($ID: String!) {
  DebtorDetail(ID: $ID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    ContactID
    DebtorTypeID
    DebtorCategory
    Name
    DebtorAccount
    DebtorContacts
    CompanyRegNo
    GSTNo
    Currency
    CreditTerm
    CreditLimit
    Remarks
    Segment
    SegmentDetails {
      Description
    }
    Login
    Password
    ARTotalDue
    ARTotalOutstanding
    TaxRegNo
    Tin
    DebtorContact {
      Contact {
        FullName
        MobileNo
        Email
      }
      ID
    }
    Contact {
      PhoneNo
      Email
      FullName
      Email
      PhoneNo
      MobileNo
      BirthDate
      Designation
      Address {
        address
        address2
        postCode
        city
        state
        country
      }
    }
    DebtorContact {
      ID
      Contact {
        FullName
        Email
        MobileNo
      }
    }
  }
}
    `;

/**
 * __useDebtorDetailQuery__
 *
 * To run a query within a React component, call `useDebtorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebtorDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorDetailQuery, DebtorDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorDetailQuery, DebtorDetailQueryVariables>(DebtorDetailDocument, baseOptions);
      }
export function useDebtorDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorDetailQuery, DebtorDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorDetailQuery, DebtorDetailQueryVariables>(DebtorDetailDocument, baseOptions);
        }
export type DebtorDetailQueryHookResult = ReturnType<typeof useDebtorDetailQuery>;
export type DebtorDetailLazyQueryHookResult = ReturnType<typeof useDebtorDetailLazyQuery>;
export type DebtorDetailQueryResult = ApolloReactCommon.QueryResult<DebtorDetailQuery, DebtorDetailQueryVariables>;
export const DebtorInfoLineDocument = gql`
    query DebtorInfoLine($HotelID: String!, $DebtorID: String!) {
  ARTotalOutstanding(HotelID: $HotelID, DebtorID: $DebtorID) {
    LastPaymentDT
    TotalOstandingAmount
  }
}
    `;

/**
 * __useDebtorInfoLineQuery__
 *
 * To run a query within a React component, call `useDebtorInfoLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorInfoLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorInfoLineQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useDebtorInfoLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>(DebtorInfoLineDocument, baseOptions);
      }
export function useDebtorInfoLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>(DebtorInfoLineDocument, baseOptions);
        }
export type DebtorInfoLineQueryHookResult = ReturnType<typeof useDebtorInfoLineQuery>;
export type DebtorInfoLineLazyQueryHookResult = ReturnType<typeof useDebtorInfoLineLazyQuery>;
export type DebtorInfoLineQueryResult = ApolloReactCommon.QueryResult<DebtorInfoLineQuery, DebtorInfoLineQueryVariables>;
export const LedgerDocumentInfoLineDocument = gql`
    query LedgerDocumentInfoLine($HotelID: String!, $DebtorID: String!, $IsPostedDoc: Boolean!) {
  InvoiceInfo(HotelID: $HotelID, DebtorID: $DebtorID, IsPostedDoc: $IsPostedDoc) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  DebitNoteInfo(HotelID: $HotelID, DebtorID: $DebtorID, IsPostedDoc: $IsPostedDoc) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  CreditNoteInfo(HotelID: $HotelID, DebtorID: $DebtorID, IsPostedDoc: $IsPostedDoc) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  OfficialReceiptInfo(HotelID: $HotelID, DebtorID: $DebtorID, IsPostedDoc: $IsPostedDoc) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  RefundInfo(HotelID: $HotelID, DebtorID: $DebtorID, IsPostedDoc: $IsPostedDoc) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
  GroupInvoiceCount(HotelID: $HotelID, DebtorID: $DebtorID)
}
    `;

/**
 * __useLedgerDocumentInfoLineQuery__
 *
 * To run a query within a React component, call `useLedgerDocumentInfoLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useLedgerDocumentInfoLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLedgerDocumentInfoLineQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      IsPostedDoc: // value for 'IsPostedDoc'
 *   },
 * });
 */
export function useLedgerDocumentInfoLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>) {
        return ApolloReactHooks.useQuery<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>(LedgerDocumentInfoLineDocument, baseOptions);
      }
export function useLedgerDocumentInfoLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>(LedgerDocumentInfoLineDocument, baseOptions);
        }
export type LedgerDocumentInfoLineQueryHookResult = ReturnType<typeof useLedgerDocumentInfoLineQuery>;
export type LedgerDocumentInfoLineLazyQueryHookResult = ReturnType<typeof useLedgerDocumentInfoLineLazyQuery>;
export type LedgerDocumentInfoLineQueryResult = ApolloReactCommon.QueryResult<LedgerDocumentInfoLineQuery, LedgerDocumentInfoLineQueryVariables>;
export const MonthlyStatementListingDocument = gql`
    query MonthlyStatementListing($ID: String!) {
  MonthlyStatementListing(ID: $ID)
}
    `;

/**
 * __useMonthlyStatementListingQuery__
 *
 * To run a query within a React component, call `useMonthlyStatementListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonthlyStatementListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonthlyStatementListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useMonthlyStatementListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MonthlyStatementListingQuery, MonthlyStatementListingQueryVariables>) {
        return ApolloReactHooks.useQuery<MonthlyStatementListingQuery, MonthlyStatementListingQueryVariables>(MonthlyStatementListingDocument, baseOptions);
      }
export function useMonthlyStatementListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MonthlyStatementListingQuery, MonthlyStatementListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MonthlyStatementListingQuery, MonthlyStatementListingQueryVariables>(MonthlyStatementListingDocument, baseOptions);
        }
export type MonthlyStatementListingQueryHookResult = ReturnType<typeof useMonthlyStatementListingQuery>;
export type MonthlyStatementListingLazyQueryHookResult = ReturnType<typeof useMonthlyStatementListingLazyQuery>;
export type MonthlyStatementListingQueryResult = ApolloReactCommon.QueryResult<MonthlyStatementListingQuery, MonthlyStatementListingQueryVariables>;
export const OpeningClosingAmountDocument = gql`
    query OpeningClosingAmount($DebtorID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  OpeningClosingAmount(DebtorID: $DebtorID, StartDate: $StartDate, EndDate: $EndDate) {
    OpenInvoice {
      ID
      DocumentNo
      osAmt
    }
    OpendebitNote {
      ID
      DocumentNo
      osAmt
    }
    OpenCreditNote {
      ID
      DocumentNo
      osAmt
    }
    OpenOR {
      ID
      DocumentNo
      osAmt
    }
    OpenRefund {
      ID
      DocumentNo
      osAmt
    }
    ClosedInvoice {
      ID
      DocumentNo
      osAmt
    }
    CloseddebitNote {
      ID
      DocumentNo
      osAmt
    }
    ClosedCreditNote {
      ID
      DocumentNo
      osAmt
    }
    ClosedOR {
      ID
      DocumentNo
      osAmt
    }
    ClosedRefund {
      ID
      DocumentNo
      osAmt
    }
  }
}
    `;

/**
 * __useOpeningClosingAmountQuery__
 *
 * To run a query within a React component, call `useOpeningClosingAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpeningClosingAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpeningClosingAmountQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useOpeningClosingAmountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OpeningClosingAmountQuery, OpeningClosingAmountQueryVariables>) {
        return ApolloReactHooks.useQuery<OpeningClosingAmountQuery, OpeningClosingAmountQueryVariables>(OpeningClosingAmountDocument, baseOptions);
      }
export function useOpeningClosingAmountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OpeningClosingAmountQuery, OpeningClosingAmountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OpeningClosingAmountQuery, OpeningClosingAmountQueryVariables>(OpeningClosingAmountDocument, baseOptions);
        }
export type OpeningClosingAmountQueryHookResult = ReturnType<typeof useOpeningClosingAmountQuery>;
export type OpeningClosingAmountLazyQueryHookResult = ReturnType<typeof useOpeningClosingAmountLazyQuery>;
export type OpeningClosingAmountQueryResult = ApolloReactCommon.QueryResult<OpeningClosingAmountQuery, OpeningClosingAmountQueryVariables>;
export const StatementOfAccDocumentDocument = gql`
    query StatementOfAccDocument($DebtorID: String!, $HotelID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  StatementOfAccDocument(HotelID: $HotelID, DebtorID: $DebtorID, startDate: $StartDate, endDate: $EndDate) {
    Invoice {
      ID
      DocumentNo
      ReferenceNo
      Description
      DocumentDate
      Amount
      Status
      HotelID
      AccountID
      DebtorID
      Attachment
      Currency
      OutstandingAmount
      CurrencyRate
      Reason
      VoidDate
      PostedDate
      Booking
      LedgerTrx {
        TransactionID
        TrxName
        TrxAmount
        ReferenceNo
        UnitPrice
        Quantity
        DiscountedAmount
        BaseAmount
        TaxAmount
        ServiceCharge
        Remarks
      }
    }
    CnDoc {
      ID
      DocumentNo
      ReferenceNo
      Description
      DocumentDate
      Amount
      Status
      HotelID
      AccountID
      DebtorID
      Attachment
      Currency
      OutstandingAmount
      CurrencyRate
      Reason
      VoidDate
      PostedDate
      LedgerTrx {
        TransactionID
        TrxName
        TrxAmount
        ReferenceNo
        UnitPrice
        Quantity
        DiscountedAmount
        BaseAmount
        TaxAmount
        ServiceCharge
        Remarks
      }
    }
    DnDoc {
      ID
      DocumentNo
      ReferenceNo
      Description
      DocumentDate
      Amount
      Status
      HotelID
      AccountID
      DebtorID
      Attachment
      Currency
      OutstandingAmount
      CurrencyRate
      Reason
      VoidDate
      PostedDate
      LedgerTrx {
        TransactionID
        TrxName
        TrxAmount
        ReferenceNo
        UnitPrice
        Quantity
        DiscountedAmount
        BaseAmount
        TaxAmount
        ServiceCharge
        Remarks
      }
    }
    OrDoc {
      ID
      DocumentNo
      ReferenceNo
      Description
      DocumentDate
      Amount
      Status
      HotelID
      AccountID
      DebtorID
      Attachment
      Currency
      OutstandingAmount
      CurrencyRate
      Reason
      VoidDate
      PostedDate
      Commission
      Allocation {
        ID
        HotelID
        DebtorID
        AccountID
        DebitRefTable
        DebitID
        CreditRefTable
        CreditID
        AllocationAmount
      }
    }
    RefundDoc {
      ID
      DocumentNo
      ReferenceNo
      Description
      DocumentDate
      Amount
      Status
      HotelID
      AccountID
      DebtorID
      Attachment
      Currency
      OutstandingAmount
      CurrencyRate
      Reason
      VoidDate
      PostedDate
      Commission
      Allocation {
        ID
        HotelID
        DebtorID
        AccountID
        DebitRefTable
        DebitID
        CreditRefTable
        CreditID
        AllocationAmount
      }
    }
  }
}
    `;

/**
 * __useStatementOfAccDocumentQuery__
 *
 * To run a query within a React component, call `useStatementOfAccDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatementOfAccDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatementOfAccDocumentQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useStatementOfAccDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatementOfAccDocumentQuery, StatementOfAccDocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<StatementOfAccDocumentQuery, StatementOfAccDocumentQueryVariables>(StatementOfAccDocumentDocument, baseOptions);
      }
export function useStatementOfAccDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatementOfAccDocumentQuery, StatementOfAccDocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StatementOfAccDocumentQuery, StatementOfAccDocumentQueryVariables>(StatementOfAccDocumentDocument, baseOptions);
        }
export type StatementOfAccDocumentQueryHookResult = ReturnType<typeof useStatementOfAccDocumentQuery>;
export type StatementOfAccDocumentLazyQueryHookResult = ReturnType<typeof useStatementOfAccDocumentLazyQuery>;
export type StatementOfAccDocumentQueryResult = ApolloReactCommon.QueryResult<StatementOfAccDocumentQuery, StatementOfAccDocumentQueryVariables>;
export const PaymentFolioDebtorListingDocument = gql`
    query PaymentFolioDebtorListing($HotelID: String!, $DebtorType: String, $IsActive: Boolean, $Country: String, $DebtorAccount: String, $CompanyName: String, $limit: Float, $offset: Float) {
  DebtorListing(HotelID: $HotelID, DebtorType: $DebtorType, IsActive: $IsActive, Country: $Country, DebtorAccount: $DebtorAccount, CompanyName: $CompanyName, limit: $limit, offset: $offset) {
    ID
    IsActive
    CreditCardMapping {
      Name
    }
    DebtorType {
      DebtorType
    }
    DebtorCategory
    Name
    DebtorAccount
  }
}
    `;

/**
 * __usePaymentFolioDebtorListingQuery__
 *
 * To run a query within a React component, call `usePaymentFolioDebtorListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentFolioDebtorListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentFolioDebtorListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *      IsActive: // value for 'IsActive'
 *      Country: // value for 'Country'
 *      DebtorAccount: // value for 'DebtorAccount'
 *      CompanyName: // value for 'CompanyName'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePaymentFolioDebtorListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentFolioDebtorListingQuery, PaymentFolioDebtorListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentFolioDebtorListingQuery, PaymentFolioDebtorListingQueryVariables>(PaymentFolioDebtorListingDocument, baseOptions);
      }
export function usePaymentFolioDebtorListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentFolioDebtorListingQuery, PaymentFolioDebtorListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentFolioDebtorListingQuery, PaymentFolioDebtorListingQueryVariables>(PaymentFolioDebtorListingDocument, baseOptions);
        }
export type PaymentFolioDebtorListingQueryHookResult = ReturnType<typeof usePaymentFolioDebtorListingQuery>;
export type PaymentFolioDebtorListingLazyQueryHookResult = ReturnType<typeof usePaymentFolioDebtorListingLazyQuery>;
export type PaymentFolioDebtorListingQueryResult = ApolloReactCommon.QueryResult<PaymentFolioDebtorListingQuery, PaymentFolioDebtorListingQueryVariables>;
export const DebtorTypeCountDocument = gql`
    query DebtorTypeCount($HotelID: String!) {
  DebtorTypeCount(HotelID: $HotelID)
}
    `;

/**
 * __useDebtorTypeCountQuery__
 *
 * To run a query within a React component, call `useDebtorTypeCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorTypeCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorTypeCountQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDebtorTypeCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorTypeCountQuery, DebtorTypeCountQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorTypeCountQuery, DebtorTypeCountQueryVariables>(DebtorTypeCountDocument, baseOptions);
      }
export function useDebtorTypeCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorTypeCountQuery, DebtorTypeCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorTypeCountQuery, DebtorTypeCountQueryVariables>(DebtorTypeCountDocument, baseOptions);
        }
export type DebtorTypeCountQueryHookResult = ReturnType<typeof useDebtorTypeCountQuery>;
export type DebtorTypeCountLazyQueryHookResult = ReturnType<typeof useDebtorTypeCountLazyQuery>;
export type DebtorTypeCountQueryResult = ApolloReactCommon.QueryResult<DebtorTypeCountQuery, DebtorTypeCountQueryVariables>;
export const InvoiceListingsDocument = gql`
    query InvoiceListings($HotelID: String!, $DebtorID: String!, $StartDate: DateTime, $EndDate: DateTime, $DocumentNo: String, $Description: String, $Amount: String, $Offset: Float, $Limit: Float, $Status: String) {
  CityLedgerInvoiceListings(HotelID: $HotelID, DebtorID: $DebtorID, StartDate: $StartDate, EndDate: $EndDate, DocumentNo: $DocumentNo, Description: $Description, Amount: $Amount, offset: $Offset, limit: $Limit, Status: $Status)
}
    `;

/**
 * __useInvoiceListingsQuery__
 *
 * To run a query within a React component, call `useInvoiceListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DocumentNo: // value for 'DocumentNo'
 *      Description: // value for 'Description'
 *      Amount: // value for 'Amount'
 *      Offset: // value for 'Offset'
 *      Limit: // value for 'Limit'
 *      Status: // value for 'Status'
 *   },
 * });
 */
export function useInvoiceListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvoiceListingsQuery, InvoiceListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<InvoiceListingsQuery, InvoiceListingsQueryVariables>(InvoiceListingsDocument, baseOptions);
      }
export function useInvoiceListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceListingsQuery, InvoiceListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvoiceListingsQuery, InvoiceListingsQueryVariables>(InvoiceListingsDocument, baseOptions);
        }
export type InvoiceListingsQueryHookResult = ReturnType<typeof useInvoiceListingsQuery>;
export type InvoiceListingsLazyQueryHookResult = ReturnType<typeof useInvoiceListingsLazyQuery>;
export type InvoiceListingsQueryResult = ApolloReactCommon.QueryResult<InvoiceListingsQuery, InvoiceListingsQueryVariables>;
export const PostedScheduleBillingDocument = gql`
    query PostedScheduleBilling($ID: String!, $DebtorID: String!) {
  PostedScheduleBilling(ID: $ID, DebtorID: $DebtorID)
}
    `;

/**
 * __usePostedScheduleBillingQuery__
 *
 * To run a query within a React component, call `usePostedScheduleBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostedScheduleBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostedScheduleBillingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function usePostedScheduleBillingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostedScheduleBillingQuery, PostedScheduleBillingQueryVariables>) {
        return ApolloReactHooks.useQuery<PostedScheduleBillingQuery, PostedScheduleBillingQueryVariables>(PostedScheduleBillingDocument, baseOptions);
      }
export function usePostedScheduleBillingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostedScheduleBillingQuery, PostedScheduleBillingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostedScheduleBillingQuery, PostedScheduleBillingQueryVariables>(PostedScheduleBillingDocument, baseOptions);
        }
export type PostedScheduleBillingQueryHookResult = ReturnType<typeof usePostedScheduleBillingQuery>;
export type PostedScheduleBillingLazyQueryHookResult = ReturnType<typeof usePostedScheduleBillingLazyQuery>;
export type PostedScheduleBillingQueryResult = ApolloReactCommon.QueryResult<PostedScheduleBillingQuery, PostedScheduleBillingQueryVariables>;
export const EInvoiceDocListingsDocument = gql`
    query EInvoiceDocListings($HotelID: String!, $DebtorID: String!) {
  EInvoiceDocListings(HotelID: $HotelID, DebtorID: $DebtorID) {
    ID
    DocumentNo
    DocumentDate
    is_einvoice
    einvoice_status
  }
}
    `;

/**
 * __useEInvoiceDocListingsQuery__
 *
 * To run a query within a React component, call `useEInvoiceDocListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEInvoiceDocListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEInvoiceDocListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useEInvoiceDocListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EInvoiceDocListingsQuery, EInvoiceDocListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<EInvoiceDocListingsQuery, EInvoiceDocListingsQueryVariables>(EInvoiceDocListingsDocument, baseOptions);
      }
export function useEInvoiceDocListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EInvoiceDocListingsQuery, EInvoiceDocListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EInvoiceDocListingsQuery, EInvoiceDocListingsQueryVariables>(EInvoiceDocListingsDocument, baseOptions);
        }
export type EInvoiceDocListingsQueryHookResult = ReturnType<typeof useEInvoiceDocListingsQuery>;
export type EInvoiceDocListingsLazyQueryHookResult = ReturnType<typeof useEInvoiceDocListingsLazyQuery>;
export type EInvoiceDocListingsQueryResult = ApolloReactCommon.QueryResult<EInvoiceDocListingsQuery, EInvoiceDocListingsQueryVariables>;
export const InvoiceInsertDocument = gql`
    mutation InvoiceInsert($input: InvoiceEntityInput!, $ChargeItemInput: [InvoiceTrxInput!]!, $CityLedgerAttachmentInput: [Upload!]) {
  InvoiceInsert(input: $input, ChargeItemInput: $ChargeItemInput, CityLedgerAttachmentInput: $CityLedgerAttachmentInput) {
    ID
  }
}
    `;
export type InvoiceInsertMutationFn = ApolloReactCommon.MutationFunction<InvoiceInsertMutation, InvoiceInsertMutationVariables>;

/**
 * __useInvoiceInsertMutation__
 *
 * To run a mutation, you first call `useInvoiceInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceInsertMutation, { data, loading, error }] = useInvoiceInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *      CityLedgerAttachmentInput: // value for 'CityLedgerAttachmentInput'
 *   },
 * });
 */
export function useInvoiceInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceInsertMutation, InvoiceInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceInsertMutation, InvoiceInsertMutationVariables>(InvoiceInsertDocument, baseOptions);
      }
export type InvoiceInsertMutationHookResult = ReturnType<typeof useInvoiceInsertMutation>;
export type InvoiceInsertMutationResult = ApolloReactCommon.MutationResult<InvoiceInsertMutation>;
export type InvoiceInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceInsertMutation, InvoiceInsertMutationVariables>;
export const InvoiceUpdateDocument = gql`
    mutation InvoiceUpdate($input: InvoiceEntityInput!, $ChargeItemInput: [InvoiceTrxInput!]!, $ID: String!, $CityLedgerAttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  InvoiceUpdate(input: $input, ChargeItemInput: $ChargeItemInput, ID: $ID, CityLedgerAttachmentInput: $CityLedgerAttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type InvoiceUpdateMutationFn = ApolloReactCommon.MutationFunction<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>;

/**
 * __useInvoiceUpdateMutation__
 *
 * To run a mutation, you first call `useInvoiceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceUpdateMutation, { data, loading, error }] = useInvoiceUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *      ID: // value for 'ID'
 *      CityLedgerAttachmentInput: // value for 'CityLedgerAttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useInvoiceUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>(InvoiceUpdateDocument, baseOptions);
      }
export type InvoiceUpdateMutationHookResult = ReturnType<typeof useInvoiceUpdateMutation>;
export type InvoiceUpdateMutationResult = ApolloReactCommon.MutationResult<InvoiceUpdateMutation>;
export type InvoiceUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceUpdateMutation, InvoiceUpdateMutationVariables>;
export const InvoiceCancelDocument = gql`
    mutation InvoiceCancel($ReasonCode: String!, $Reason: String!, $ID: String!) {
  InvoiceCancel(ReasonCode: $ReasonCode, Reason: $Reason, ID: $ID)
}
    `;
export type InvoiceCancelMutationFn = ApolloReactCommon.MutationFunction<InvoiceCancelMutation, InvoiceCancelMutationVariables>;

/**
 * __useInvoiceCancelMutation__
 *
 * To run a mutation, you first call `useInvoiceCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceCancelMutation, { data, loading, error }] = useInvoiceCancelMutation({
 *   variables: {
 *      ReasonCode: // value for 'ReasonCode'
 *      Reason: // value for 'Reason'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useInvoiceCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceCancelMutation, InvoiceCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceCancelMutation, InvoiceCancelMutationVariables>(InvoiceCancelDocument, baseOptions);
      }
export type InvoiceCancelMutationHookResult = ReturnType<typeof useInvoiceCancelMutation>;
export type InvoiceCancelMutationResult = ApolloReactCommon.MutationResult<InvoiceCancelMutation>;
export type InvoiceCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceCancelMutation, InvoiceCancelMutationVariables>;
export const InvoiceDeleteDocument = gql`
    mutation InvoiceDelete($ID: String!) {
  InvoiceDelete(ID: $ID)
}
    `;
export type InvoiceDeleteMutationFn = ApolloReactCommon.MutationFunction<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>;

/**
 * __useInvoiceDeleteMutation__
 *
 * To run a mutation, you first call `useInvoiceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceDeleteMutation, { data, loading, error }] = useInvoiceDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useInvoiceDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>(InvoiceDeleteDocument, baseOptions);
      }
export type InvoiceDeleteMutationHookResult = ReturnType<typeof useInvoiceDeleteMutation>;
export type InvoiceDeleteMutationResult = ApolloReactCommon.MutationResult<InvoiceDeleteMutation>;
export type InvoiceDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceDeleteMutation, InvoiceDeleteMutationVariables>;
export const AllocateInvoiceDocument = gql`
    mutation AllocateInvoice($allocInput: [DebitAllocationInput!]!, $InvoiceID: String!) {
  AllocateInvoice(allocInput: $allocInput, InvoiceID: $InvoiceID)
}
    `;
export type AllocateInvoiceMutationFn = ApolloReactCommon.MutationFunction<AllocateInvoiceMutation, AllocateInvoiceMutationVariables>;

/**
 * __useAllocateInvoiceMutation__
 *
 * To run a mutation, you first call `useAllocateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateInvoiceMutation, { data, loading, error }] = useAllocateInvoiceMutation({
 *   variables: {
 *      allocInput: // value for 'allocInput'
 *      InvoiceID: // value for 'InvoiceID'
 *   },
 * });
 */
export function useAllocateInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AllocateInvoiceMutation, AllocateInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<AllocateInvoiceMutation, AllocateInvoiceMutationVariables>(AllocateInvoiceDocument, baseOptions);
      }
export type AllocateInvoiceMutationHookResult = ReturnType<typeof useAllocateInvoiceMutation>;
export type AllocateInvoiceMutationResult = ApolloReactCommon.MutationResult<AllocateInvoiceMutation>;
export type AllocateInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<AllocateInvoiceMutation, AllocateInvoiceMutationVariables>;
export const InvoiceInfoDocument = gql`
    query InvoiceInfo($HotelID: String!, $DebtorID: String!, $IsPostedDoc: Boolean!) {
  InvoiceInfo(HotelID: $HotelID, DebtorID: $DebtorID, IsPostedDoc: $IsPostedDoc) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useInvoiceInfoQuery__
 *
 * To run a query within a React component, call `useInvoiceInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      IsPostedDoc: // value for 'IsPostedDoc'
 *   },
 * });
 */
export function useInvoiceInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InvoiceInfoQuery, InvoiceInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<InvoiceInfoQuery, InvoiceInfoQueryVariables>(InvoiceInfoDocument, baseOptions);
      }
export function useInvoiceInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InvoiceInfoQuery, InvoiceInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InvoiceInfoQuery, InvoiceInfoQueryVariables>(InvoiceInfoDocument, baseOptions);
        }
export type InvoiceInfoQueryHookResult = ReturnType<typeof useInvoiceInfoQuery>;
export type InvoiceInfoLazyQueryHookResult = ReturnType<typeof useInvoiceInfoLazyQuery>;
export type InvoiceInfoQueryResult = ApolloReactCommon.QueryResult<InvoiceInfoQuery, InvoiceInfoQueryVariables>;
export const DebitNoteListingsDocument = gql`
    query DebitNoteListings($HotelID: String!, $DebtorID: String!, $StartDate: DateTime, $EndDate: DateTime, $Offset: Float, $Limit: Float, $Status: String, $DocumentNo: String, $Description: String, $Amount: String) {
  DebitNoteListings(HotelID: $HotelID, DebtorID: $DebtorID, StartDate: $StartDate, EndDate: $EndDate, offset: $Offset, limit: $Limit, Status: $Status, DocumentNo: $DocumentNo, Description: $Description, Amount: $Amount) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    IsPostedDoc
    einvoice_doc_ref_invoice_id
    is_einvoice
    einvoice_irbm_uniqueID
    einvoice_status
    QrCodeUrl
    LedgerTrx {
      TransactionID
      TrxName
      TrxAmount
      ReferenceNo
      UnitPrice
      Quantity
      DiscountedAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      Remarks
      DebitNoteTrxTax {
        TaxID
        TaxAmount
        TaxRate
        TaxSchemeID
        TaxInfo {
          id
          code
        }
      }
    }
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      ModifiedDT
      CreditID
      DebitDetail {
        ID
        DocumentNo
        DocumentDate
      }
      CreditDetail {
        ID
        DocumentNo
        DocumentDate
      }
      AllocationAmount
      AllocDate
    }
    CityLedgerAttachment {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
  }
}
    `;

/**
 * __useDebitNoteListingsQuery__
 *
 * To run a query within a React component, call `useDebitNoteListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebitNoteListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      Offset: // value for 'Offset'
 *      Limit: // value for 'Limit'
 *      Status: // value for 'Status'
 *      DocumentNo: // value for 'DocumentNo'
 *      Description: // value for 'Description'
 *      Amount: // value for 'Amount'
 *   },
 * });
 */
export function useDebitNoteListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>(DebitNoteListingsDocument, baseOptions);
      }
export function useDebitNoteListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>(DebitNoteListingsDocument, baseOptions);
        }
export type DebitNoteListingsQueryHookResult = ReturnType<typeof useDebitNoteListingsQuery>;
export type DebitNoteListingsLazyQueryHookResult = ReturnType<typeof useDebitNoteListingsLazyQuery>;
export type DebitNoteListingsQueryResult = ApolloReactCommon.QueryResult<DebitNoteListingsQuery, DebitNoteListingsQueryVariables>;
export const DebitNoteDetailDocument = gql`
    query DebitNoteDetail($ID: String!) {
  DebitNoteDetail(ID: $ID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    LedgerTrx {
      TransactionID
      TrxName
      TrxAmount
      ReferenceNo
      UnitPrice
      Quantity
      DiscountedAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      Remarks
    }
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      CreditID
      AllocationAmount
    }
  }
}
    `;

/**
 * __useDebitNoteDetailQuery__
 *
 * To run a query within a React component, call `useDebitNoteDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebitNoteDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebitNoteDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebitNoteDetailQuery, DebitNoteDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<DebitNoteDetailQuery, DebitNoteDetailQueryVariables>(DebitNoteDetailDocument, baseOptions);
      }
export function useDebitNoteDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebitNoteDetailQuery, DebitNoteDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebitNoteDetailQuery, DebitNoteDetailQueryVariables>(DebitNoteDetailDocument, baseOptions);
        }
export type DebitNoteDetailQueryHookResult = ReturnType<typeof useDebitNoteDetailQuery>;
export type DebitNoteDetailLazyQueryHookResult = ReturnType<typeof useDebitNoteDetailLazyQuery>;
export type DebitNoteDetailQueryResult = ApolloReactCommon.QueryResult<DebitNoteDetailQuery, DebitNoteDetailQueryVariables>;
export const DebitNoteInsertDocument = gql`
    mutation DebitNoteInsert($input: DebitNoteEntityInput!, $ChargeItemInput: [DebitNoteTrxInput!]!, $CityLedgerAttachmentInput: [Upload!]) {
  DebitNoteInsert(input: $input, ChargeItemInput: $ChargeItemInput, CityLedgerAttachmentInput: $CityLedgerAttachmentInput) {
    ID
  }
}
    `;
export type DebitNoteInsertMutationFn = ApolloReactCommon.MutationFunction<DebitNoteInsertMutation, DebitNoteInsertMutationVariables>;

/**
 * __useDebitNoteInsertMutation__
 *
 * To run a mutation, you first call `useDebitNoteInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debitNoteInsertMutation, { data, loading, error }] = useDebitNoteInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *      CityLedgerAttachmentInput: // value for 'CityLedgerAttachmentInput'
 *   },
 * });
 */
export function useDebitNoteInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebitNoteInsertMutation, DebitNoteInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DebitNoteInsertMutation, DebitNoteInsertMutationVariables>(DebitNoteInsertDocument, baseOptions);
      }
export type DebitNoteInsertMutationHookResult = ReturnType<typeof useDebitNoteInsertMutation>;
export type DebitNoteInsertMutationResult = ApolloReactCommon.MutationResult<DebitNoteInsertMutation>;
export type DebitNoteInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DebitNoteInsertMutation, DebitNoteInsertMutationVariables>;
export const DebitNoteUpdateDocument = gql`
    mutation DebitNoteUpdate($input: DebitNoteEntityInput!, $ChargeItemInput: [DebitNoteTrxInput!]!, $ID: String!, $CityLedgerAttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  DebitNoteUpdate(input: $input, ChargeItemInput: $ChargeItemInput, ID: $ID, CityLedgerAttachmentInput: $CityLedgerAttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type DebitNoteUpdateMutationFn = ApolloReactCommon.MutationFunction<DebitNoteUpdateMutation, DebitNoteUpdateMutationVariables>;

/**
 * __useDebitNoteUpdateMutation__
 *
 * To run a mutation, you first call `useDebitNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debitNoteUpdateMutation, { data, loading, error }] = useDebitNoteUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *      ID: // value for 'ID'
 *      CityLedgerAttachmentInput: // value for 'CityLedgerAttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useDebitNoteUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebitNoteUpdateMutation, DebitNoteUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DebitNoteUpdateMutation, DebitNoteUpdateMutationVariables>(DebitNoteUpdateDocument, baseOptions);
      }
export type DebitNoteUpdateMutationHookResult = ReturnType<typeof useDebitNoteUpdateMutation>;
export type DebitNoteUpdateMutationResult = ApolloReactCommon.MutationResult<DebitNoteUpdateMutation>;
export type DebitNoteUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DebitNoteUpdateMutation, DebitNoteUpdateMutationVariables>;
export const DebitNoteCancelDocument = gql`
    mutation DebitNoteCancel($ReasonCode: String!, $Reason: String!, $ID: String!) {
  DebitNoteCancel(Reason: $Reason, ReasonCode: $ReasonCode, ID: $ID)
}
    `;
export type DebitNoteCancelMutationFn = ApolloReactCommon.MutationFunction<DebitNoteCancelMutation, DebitNoteCancelMutationVariables>;

/**
 * __useDebitNoteCancelMutation__
 *
 * To run a mutation, you first call `useDebitNoteCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debitNoteCancelMutation, { data, loading, error }] = useDebitNoteCancelMutation({
 *   variables: {
 *      ReasonCode: // value for 'ReasonCode'
 *      Reason: // value for 'Reason'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebitNoteCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebitNoteCancelMutation, DebitNoteCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<DebitNoteCancelMutation, DebitNoteCancelMutationVariables>(DebitNoteCancelDocument, baseOptions);
      }
export type DebitNoteCancelMutationHookResult = ReturnType<typeof useDebitNoteCancelMutation>;
export type DebitNoteCancelMutationResult = ApolloReactCommon.MutationResult<DebitNoteCancelMutation>;
export type DebitNoteCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<DebitNoteCancelMutation, DebitNoteCancelMutationVariables>;
export const DebitNoteDeleteDocument = gql`
    mutation DebitNoteDelete($ID: String!) {
  DebitNoteDelete(ID: $ID)
}
    `;
export type DebitNoteDeleteMutationFn = ApolloReactCommon.MutationFunction<DebitNoteDeleteMutation, DebitNoteDeleteMutationVariables>;

/**
 * __useDebitNoteDeleteMutation__
 *
 * To run a mutation, you first call `useDebitNoteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debitNoteDeleteMutation, { data, loading, error }] = useDebitNoteDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebitNoteDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebitNoteDeleteMutation, DebitNoteDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DebitNoteDeleteMutation, DebitNoteDeleteMutationVariables>(DebitNoteDeleteDocument, baseOptions);
      }
export type DebitNoteDeleteMutationHookResult = ReturnType<typeof useDebitNoteDeleteMutation>;
export type DebitNoteDeleteMutationResult = ApolloReactCommon.MutationResult<DebitNoteDeleteMutation>;
export type DebitNoteDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DebitNoteDeleteMutation, DebitNoteDeleteMutationVariables>;
export const AllocateDebitNoteDocument = gql`
    mutation AllocateDebitNote($allocInput: [DebitAllocationInput!]!, $DebitNoteID: String!) {
  AllocateDebitNote(allocInput: $allocInput, DebitNoteID: $DebitNoteID)
}
    `;
export type AllocateDebitNoteMutationFn = ApolloReactCommon.MutationFunction<AllocateDebitNoteMutation, AllocateDebitNoteMutationVariables>;

/**
 * __useAllocateDebitNoteMutation__
 *
 * To run a mutation, you first call `useAllocateDebitNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateDebitNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateDebitNoteMutation, { data, loading, error }] = useAllocateDebitNoteMutation({
 *   variables: {
 *      allocInput: // value for 'allocInput'
 *      DebitNoteID: // value for 'DebitNoteID'
 *   },
 * });
 */
export function useAllocateDebitNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AllocateDebitNoteMutation, AllocateDebitNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AllocateDebitNoteMutation, AllocateDebitNoteMutationVariables>(AllocateDebitNoteDocument, baseOptions);
      }
export type AllocateDebitNoteMutationHookResult = ReturnType<typeof useAllocateDebitNoteMutation>;
export type AllocateDebitNoteMutationResult = ApolloReactCommon.MutationResult<AllocateDebitNoteMutation>;
export type AllocateDebitNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AllocateDebitNoteMutation, AllocateDebitNoteMutationVariables>;
export const DebitNoteInfoDocument = gql`
    query DebitNoteInfo($HotelID: String!, $DebtorID: String!, $IsPostedDoc: Boolean!) {
  DebitNoteInfo(HotelID: $HotelID, DebtorID: $DebtorID, IsPostedDoc: $IsPostedDoc) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useDebitNoteInfoQuery__
 *
 * To run a query within a React component, call `useDebitNoteInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebitNoteInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      IsPostedDoc: // value for 'IsPostedDoc'
 *   },
 * });
 */
export function useDebitNoteInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebitNoteInfoQuery, DebitNoteInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<DebitNoteInfoQuery, DebitNoteInfoQueryVariables>(DebitNoteInfoDocument, baseOptions);
      }
export function useDebitNoteInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebitNoteInfoQuery, DebitNoteInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebitNoteInfoQuery, DebitNoteInfoQueryVariables>(DebitNoteInfoDocument, baseOptions);
        }
export type DebitNoteInfoQueryHookResult = ReturnType<typeof useDebitNoteInfoQuery>;
export type DebitNoteInfoLazyQueryHookResult = ReturnType<typeof useDebitNoteInfoLazyQuery>;
export type DebitNoteInfoQueryResult = ApolloReactCommon.QueryResult<DebitNoteInfoQuery, DebitNoteInfoQueryVariables>;
export const CreditNoteListingsDocument = gql`
    query CreditNoteListings($HotelID: String!, $DebtorID: String!, $StartDate: DateTime, $EndDate: DateTime, $Offset: Float, $Limit: Float, $Status: String, $DocumentNo: String, $Description: String, $Amount: String) {
  CreditNoteListings(HotelID: $HotelID, DebtorID: $DebtorID, StartDate: $StartDate, EndDate: $EndDate, offset: $Offset, limit: $Limit, Status: $Status, DocumentNo: $DocumentNo, Description: $Description, Amount: $Amount)
}
    `;

/**
 * __useCreditNoteListingsQuery__
 *
 * To run a query within a React component, call `useCreditNoteListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditNoteListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      Offset: // value for 'Offset'
 *      Limit: // value for 'Limit'
 *      Status: // value for 'Status'
 *      DocumentNo: // value for 'DocumentNo'
 *      Description: // value for 'Description'
 *      Amount: // value for 'Amount'
 *   },
 * });
 */
export function useCreditNoteListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>(CreditNoteListingsDocument, baseOptions);
      }
export function useCreditNoteListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>(CreditNoteListingsDocument, baseOptions);
        }
export type CreditNoteListingsQueryHookResult = ReturnType<typeof useCreditNoteListingsQuery>;
export type CreditNoteListingsLazyQueryHookResult = ReturnType<typeof useCreditNoteListingsLazyQuery>;
export type CreditNoteListingsQueryResult = ApolloReactCommon.QueryResult<CreditNoteListingsQuery, CreditNoteListingsQueryVariables>;
export const CreditNoteInsertDocument = gql`
    mutation CreditNoteInsert($input: CreditNoteEntityInput!, $ChargeItemInput: [CreditNoteTrxInput!]!, $CityLedgerAttachmentInput: [Upload!]) {
  CreditNoteInsert(input: $input, ChargeItemInput: $ChargeItemInput, CityLedgerAttachmentInput: $CityLedgerAttachmentInput) {
    ID
  }
}
    `;
export type CreditNoteInsertMutationFn = ApolloReactCommon.MutationFunction<CreditNoteInsertMutation, CreditNoteInsertMutationVariables>;

/**
 * __useCreditNoteInsertMutation__
 *
 * To run a mutation, you first call `useCreditNoteInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditNoteInsertMutation, { data, loading, error }] = useCreditNoteInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *      CityLedgerAttachmentInput: // value for 'CityLedgerAttachmentInput'
 *   },
 * });
 */
export function useCreditNoteInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreditNoteInsertMutation, CreditNoteInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CreditNoteInsertMutation, CreditNoteInsertMutationVariables>(CreditNoteInsertDocument, baseOptions);
      }
export type CreditNoteInsertMutationHookResult = ReturnType<typeof useCreditNoteInsertMutation>;
export type CreditNoteInsertMutationResult = ApolloReactCommon.MutationResult<CreditNoteInsertMutation>;
export type CreditNoteInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CreditNoteInsertMutation, CreditNoteInsertMutationVariables>;
export const CreditNoteUpdateDocument = gql`
    mutation CreditNoteUpdate($input: CreditNoteEntityInput!, $ChargeItemInput: [CreditNoteTrxInput!]!, $ID: String!, $CityLedgerAttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  CreditNoteUpdate(input: $input, ChargeItemInput: $ChargeItemInput, ID: $ID, CityLedgerAttachmentInput: $CityLedgerAttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type CreditNoteUpdateMutationFn = ApolloReactCommon.MutationFunction<CreditNoteUpdateMutation, CreditNoteUpdateMutationVariables>;

/**
 * __useCreditNoteUpdateMutation__
 *
 * To run a mutation, you first call `useCreditNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditNoteUpdateMutation, { data, loading, error }] = useCreditNoteUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ChargeItemInput: // value for 'ChargeItemInput'
 *      ID: // value for 'ID'
 *      CityLedgerAttachmentInput: // value for 'CityLedgerAttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useCreditNoteUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreditNoteUpdateMutation, CreditNoteUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreditNoteUpdateMutation, CreditNoteUpdateMutationVariables>(CreditNoteUpdateDocument, baseOptions);
      }
export type CreditNoteUpdateMutationHookResult = ReturnType<typeof useCreditNoteUpdateMutation>;
export type CreditNoteUpdateMutationResult = ApolloReactCommon.MutationResult<CreditNoteUpdateMutation>;
export type CreditNoteUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreditNoteUpdateMutation, CreditNoteUpdateMutationVariables>;
export const CreditNoteCancelDocument = gql`
    mutation CreditNoteCancel($Reason: String!, $ReasonCode: String!, $ID: String!) {
  CreditNoteCancel(Reason: $Reason, ReasonCode: $ReasonCode, ID: $ID)
}
    `;
export type CreditNoteCancelMutationFn = ApolloReactCommon.MutationFunction<CreditNoteCancelMutation, CreditNoteCancelMutationVariables>;

/**
 * __useCreditNoteCancelMutation__
 *
 * To run a mutation, you first call `useCreditNoteCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditNoteCancelMutation, { data, loading, error }] = useCreditNoteCancelMutation({
 *   variables: {
 *      Reason: // value for 'Reason'
 *      ReasonCode: // value for 'ReasonCode'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreditNoteCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreditNoteCancelMutation, CreditNoteCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<CreditNoteCancelMutation, CreditNoteCancelMutationVariables>(CreditNoteCancelDocument, baseOptions);
      }
export type CreditNoteCancelMutationHookResult = ReturnType<typeof useCreditNoteCancelMutation>;
export type CreditNoteCancelMutationResult = ApolloReactCommon.MutationResult<CreditNoteCancelMutation>;
export type CreditNoteCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<CreditNoteCancelMutation, CreditNoteCancelMutationVariables>;
export const CreditNoteDeleteDocument = gql`
    mutation CreditNoteDelete($ID: String!) {
  CreditNoteDelete(ID: $ID)
}
    `;
export type CreditNoteDeleteMutationFn = ApolloReactCommon.MutationFunction<CreditNoteDeleteMutation, CreditNoteDeleteMutationVariables>;

/**
 * __useCreditNoteDeleteMutation__
 *
 * To run a mutation, you first call `useCreditNoteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creditNoteDeleteMutation, { data, loading, error }] = useCreditNoteDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreditNoteDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreditNoteDeleteMutation, CreditNoteDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreditNoteDeleteMutation, CreditNoteDeleteMutationVariables>(CreditNoteDeleteDocument, baseOptions);
      }
export type CreditNoteDeleteMutationHookResult = ReturnType<typeof useCreditNoteDeleteMutation>;
export type CreditNoteDeleteMutationResult = ApolloReactCommon.MutationResult<CreditNoteDeleteMutation>;
export type CreditNoteDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreditNoteDeleteMutation, CreditNoteDeleteMutationVariables>;
export const AllocateCreditNoteDocument = gql`
    mutation AllocateCreditNote($allocInput: [CreditAllocationInput!]!, $CreditNoteID: String!) {
  AllocateCreditNote(allocInput: $allocInput, CreditNoteID: $CreditNoteID)
}
    `;
export type AllocateCreditNoteMutationFn = ApolloReactCommon.MutationFunction<AllocateCreditNoteMutation, AllocateCreditNoteMutationVariables>;

/**
 * __useAllocateCreditNoteMutation__
 *
 * To run a mutation, you first call `useAllocateCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateCreditNoteMutation, { data, loading, error }] = useAllocateCreditNoteMutation({
 *   variables: {
 *      allocInput: // value for 'allocInput'
 *      CreditNoteID: // value for 'CreditNoteID'
 *   },
 * });
 */
export function useAllocateCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AllocateCreditNoteMutation, AllocateCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AllocateCreditNoteMutation, AllocateCreditNoteMutationVariables>(AllocateCreditNoteDocument, baseOptions);
      }
export type AllocateCreditNoteMutationHookResult = ReturnType<typeof useAllocateCreditNoteMutation>;
export type AllocateCreditNoteMutationResult = ApolloReactCommon.MutationResult<AllocateCreditNoteMutation>;
export type AllocateCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AllocateCreditNoteMutation, AllocateCreditNoteMutationVariables>;
export const CreditNoteInfoDocument = gql`
    query CreditNoteInfo($HotelID: String!, $DebtorID: String!, $IsPostedDoc: Boolean!) {
  CreditNoteInfo(HotelID: $HotelID, DebtorID: $DebtorID, IsPostedDoc: $IsPostedDoc) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useCreditNoteInfoQuery__
 *
 * To run a query within a React component, call `useCreditNoteInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditNoteInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      IsPostedDoc: // value for 'IsPostedDoc'
 *   },
 * });
 */
export function useCreditNoteInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditNoteInfoQuery, CreditNoteInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditNoteInfoQuery, CreditNoteInfoQueryVariables>(CreditNoteInfoDocument, baseOptions);
      }
export function useCreditNoteInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditNoteInfoQuery, CreditNoteInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditNoteInfoQuery, CreditNoteInfoQueryVariables>(CreditNoteInfoDocument, baseOptions);
        }
export type CreditNoteInfoQueryHookResult = ReturnType<typeof useCreditNoteInfoQuery>;
export type CreditNoteInfoLazyQueryHookResult = ReturnType<typeof useCreditNoteInfoLazyQuery>;
export type CreditNoteInfoQueryResult = ApolloReactCommon.QueryResult<CreditNoteInfoQuery, CreditNoteInfoQueryVariables>;
export const OfficialReceiptListingsDocument = gql`
    query OfficialReceiptListings($HotelID: String!, $DebtorID: String, $Status: String, $StartDate: DateTime, $EndDate: DateTime, $Offset: Float, $Limit: Float, $DocumentNo: String, $Description: String, $Amount: String) {
  OfficialReceiptListings(HotelID: $HotelID, DebtorID: $DebtorID, Status: $Status, StartDate: $StartDate, EndDate: $EndDate, offset: $Offset, limit: $Limit, DocumentNo: $DocumentNo, Description: $Description, Amount: $Amount)
}
    `;

/**
 * __useOfficialReceiptListingsQuery__
 *
 * To run a query within a React component, call `useOfficialReceiptListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialReceiptListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      Status: // value for 'Status'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      Offset: // value for 'Offset'
 *      Limit: // value for 'Limit'
 *      DocumentNo: // value for 'DocumentNo'
 *      Description: // value for 'Description'
 *      Amount: // value for 'Amount'
 *   },
 * });
 */
export function useOfficialReceiptListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>(OfficialReceiptListingsDocument, baseOptions);
      }
export function useOfficialReceiptListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>(OfficialReceiptListingsDocument, baseOptions);
        }
export type OfficialReceiptListingsQueryHookResult = ReturnType<typeof useOfficialReceiptListingsQuery>;
export type OfficialReceiptListingsLazyQueryHookResult = ReturnType<typeof useOfficialReceiptListingsLazyQuery>;
export type OfficialReceiptListingsQueryResult = ApolloReactCommon.QueryResult<OfficialReceiptListingsQuery, OfficialReceiptListingsQueryVariables>;
export const OfficialReceiptDetailDocument = gql`
    query OfficialReceiptDetail($ID: String!) {
  OfficialReceiptDetail(ID: $ID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    Commission
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      CreditID
      AllocationAmount
    }
  }
}
    `;

/**
 * __useOfficialReceiptDetailQuery__
 *
 * To run a query within a React component, call `useOfficialReceiptDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialReceiptDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useOfficialReceiptDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfficialReceiptDetailQuery, OfficialReceiptDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<OfficialReceiptDetailQuery, OfficialReceiptDetailQueryVariables>(OfficialReceiptDetailDocument, baseOptions);
      }
export function useOfficialReceiptDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfficialReceiptDetailQuery, OfficialReceiptDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfficialReceiptDetailQuery, OfficialReceiptDetailQueryVariables>(OfficialReceiptDetailDocument, baseOptions);
        }
export type OfficialReceiptDetailQueryHookResult = ReturnType<typeof useOfficialReceiptDetailQuery>;
export type OfficialReceiptDetailLazyQueryHookResult = ReturnType<typeof useOfficialReceiptDetailLazyQuery>;
export type OfficialReceiptDetailQueryResult = ApolloReactCommon.QueryResult<OfficialReceiptDetailQuery, OfficialReceiptDetailQueryVariables>;
export const OfficialReceiptInsertDocument = gql`
    mutation OfficialReceiptInsert($input: OfficialReceiptEntityInput!, $CityLedgerAttachmentInput: [Upload!]) {
  OfficialReceiptInsert(input: $input, CityLedgerAttachmentInput: $CityLedgerAttachmentInput) {
    ID
  }
}
    `;
export type OfficialReceiptInsertMutationFn = ApolloReactCommon.MutationFunction<OfficialReceiptInsertMutation, OfficialReceiptInsertMutationVariables>;

/**
 * __useOfficialReceiptInsertMutation__
 *
 * To run a mutation, you first call `useOfficialReceiptInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [officialReceiptInsertMutation, { data, loading, error }] = useOfficialReceiptInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      CityLedgerAttachmentInput: // value for 'CityLedgerAttachmentInput'
 *   },
 * });
 */
export function useOfficialReceiptInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfficialReceiptInsertMutation, OfficialReceiptInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<OfficialReceiptInsertMutation, OfficialReceiptInsertMutationVariables>(OfficialReceiptInsertDocument, baseOptions);
      }
export type OfficialReceiptInsertMutationHookResult = ReturnType<typeof useOfficialReceiptInsertMutation>;
export type OfficialReceiptInsertMutationResult = ApolloReactCommon.MutationResult<OfficialReceiptInsertMutation>;
export type OfficialReceiptInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<OfficialReceiptInsertMutation, OfficialReceiptInsertMutationVariables>;
export const OfficialReceiptUpdateDocument = gql`
    mutation OfficialReceiptUpdate($input: OfficialReceiptEntityInput!, $ID: String!, $CityLedgerAttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  OfficialReceiptUpdate(input: $input, ID: $ID, CityLedgerAttachmentInput: $CityLedgerAttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type OfficialReceiptUpdateMutationFn = ApolloReactCommon.MutationFunction<OfficialReceiptUpdateMutation, OfficialReceiptUpdateMutationVariables>;

/**
 * __useOfficialReceiptUpdateMutation__
 *
 * To run a mutation, you first call `useOfficialReceiptUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [officialReceiptUpdateMutation, { data, loading, error }] = useOfficialReceiptUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *      CityLedgerAttachmentInput: // value for 'CityLedgerAttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useOfficialReceiptUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfficialReceiptUpdateMutation, OfficialReceiptUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<OfficialReceiptUpdateMutation, OfficialReceiptUpdateMutationVariables>(OfficialReceiptUpdateDocument, baseOptions);
      }
export type OfficialReceiptUpdateMutationHookResult = ReturnType<typeof useOfficialReceiptUpdateMutation>;
export type OfficialReceiptUpdateMutationResult = ApolloReactCommon.MutationResult<OfficialReceiptUpdateMutation>;
export type OfficialReceiptUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<OfficialReceiptUpdateMutation, OfficialReceiptUpdateMutationVariables>;
export const OfficialReceiptCancelDocument = gql`
    mutation OfficialReceiptCancel($ReasonCode: String!, $Reason: String!, $ID: String!) {
  OfficialReceiptCancel(ReasonCode: $ReasonCode, Reason: $Reason, ID: $ID)
}
    `;
export type OfficialReceiptCancelMutationFn = ApolloReactCommon.MutationFunction<OfficialReceiptCancelMutation, OfficialReceiptCancelMutationVariables>;

/**
 * __useOfficialReceiptCancelMutation__
 *
 * To run a mutation, you first call `useOfficialReceiptCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [officialReceiptCancelMutation, { data, loading, error }] = useOfficialReceiptCancelMutation({
 *   variables: {
 *      ReasonCode: // value for 'ReasonCode'
 *      Reason: // value for 'Reason'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useOfficialReceiptCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfficialReceiptCancelMutation, OfficialReceiptCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<OfficialReceiptCancelMutation, OfficialReceiptCancelMutationVariables>(OfficialReceiptCancelDocument, baseOptions);
      }
export type OfficialReceiptCancelMutationHookResult = ReturnType<typeof useOfficialReceiptCancelMutation>;
export type OfficialReceiptCancelMutationResult = ApolloReactCommon.MutationResult<OfficialReceiptCancelMutation>;
export type OfficialReceiptCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<OfficialReceiptCancelMutation, OfficialReceiptCancelMutationVariables>;
export const OfficialReceiptDeleteDocument = gql`
    mutation OfficialReceiptDelete($ID: String!) {
  OfficialReceiptDelete(ID: $ID)
}
    `;
export type OfficialReceiptDeleteMutationFn = ApolloReactCommon.MutationFunction<OfficialReceiptDeleteMutation, OfficialReceiptDeleteMutationVariables>;

/**
 * __useOfficialReceiptDeleteMutation__
 *
 * To run a mutation, you first call `useOfficialReceiptDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [officialReceiptDeleteMutation, { data, loading, error }] = useOfficialReceiptDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useOfficialReceiptDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OfficialReceiptDeleteMutation, OfficialReceiptDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<OfficialReceiptDeleteMutation, OfficialReceiptDeleteMutationVariables>(OfficialReceiptDeleteDocument, baseOptions);
      }
export type OfficialReceiptDeleteMutationHookResult = ReturnType<typeof useOfficialReceiptDeleteMutation>;
export type OfficialReceiptDeleteMutationResult = ApolloReactCommon.MutationResult<OfficialReceiptDeleteMutation>;
export type OfficialReceiptDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<OfficialReceiptDeleteMutation, OfficialReceiptDeleteMutationVariables>;
export const AllocateOfficialReceiptDocument = gql`
    mutation AllocateOfficialReceipt($allocInput: [CreditAllocationInput!]!, $ReceiptID: String!) {
  AllocateOfficialReceipt(allocInput: $allocInput, ReceiptID: $ReceiptID)
}
    `;
export type AllocateOfficialReceiptMutationFn = ApolloReactCommon.MutationFunction<AllocateOfficialReceiptMutation, AllocateOfficialReceiptMutationVariables>;

/**
 * __useAllocateOfficialReceiptMutation__
 *
 * To run a mutation, you first call `useAllocateOfficialReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateOfficialReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateOfficialReceiptMutation, { data, loading, error }] = useAllocateOfficialReceiptMutation({
 *   variables: {
 *      allocInput: // value for 'allocInput'
 *      ReceiptID: // value for 'ReceiptID'
 *   },
 * });
 */
export function useAllocateOfficialReceiptMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AllocateOfficialReceiptMutation, AllocateOfficialReceiptMutationVariables>) {
        return ApolloReactHooks.useMutation<AllocateOfficialReceiptMutation, AllocateOfficialReceiptMutationVariables>(AllocateOfficialReceiptDocument, baseOptions);
      }
export type AllocateOfficialReceiptMutationHookResult = ReturnType<typeof useAllocateOfficialReceiptMutation>;
export type AllocateOfficialReceiptMutationResult = ApolloReactCommon.MutationResult<AllocateOfficialReceiptMutation>;
export type AllocateOfficialReceiptMutationOptions = ApolloReactCommon.BaseMutationOptions<AllocateOfficialReceiptMutation, AllocateOfficialReceiptMutationVariables>;
export const OfficialReceiptInfoDocument = gql`
    query OfficialReceiptInfo($HotelID: String!, $DebtorID: String!, $IsPostedDoc: Boolean!) {
  OfficialReceiptInfo(HotelID: $HotelID, DebtorID: $DebtorID, IsPostedDoc: $IsPostedDoc) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useOfficialReceiptInfoQuery__
 *
 * To run a query within a React component, call `useOfficialReceiptInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficialReceiptInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficialReceiptInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      IsPostedDoc: // value for 'IsPostedDoc'
 *   },
 * });
 */
export function useOfficialReceiptInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OfficialReceiptInfoQuery, OfficialReceiptInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OfficialReceiptInfoQuery, OfficialReceiptInfoQueryVariables>(OfficialReceiptInfoDocument, baseOptions);
      }
export function useOfficialReceiptInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OfficialReceiptInfoQuery, OfficialReceiptInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OfficialReceiptInfoQuery, OfficialReceiptInfoQueryVariables>(OfficialReceiptInfoDocument, baseOptions);
        }
export type OfficialReceiptInfoQueryHookResult = ReturnType<typeof useOfficialReceiptInfoQuery>;
export type OfficialReceiptInfoLazyQueryHookResult = ReturnType<typeof useOfficialReceiptInfoLazyQuery>;
export type OfficialReceiptInfoQueryResult = ApolloReactCommon.QueryResult<OfficialReceiptInfoQuery, OfficialReceiptInfoQueryVariables>;
export const RefundListingsDocument = gql`
    query RefundListings($HotelID: String!, $DebtorID: String!, $StartDate: DateTime, $EndDate: DateTime, $Offset: Float, $Limit: Float, $Status: String, $DocumentNo: String, $Description: String, $Amount: String) {
  RefundListings(HotelID: $HotelID, DebtorID: $DebtorID, StartDate: $StartDate, EndDate: $EndDate, offset: $Offset, limit: $Limit, Status: $Status, DocumentNo: $DocumentNo, Description: $Description, Amount: $Amount) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    Commission
    CreatedBy
    ModifiedBy
    IsPostedDoc
    bankDetails {
      Name
    }
    Allocation {
      ID
      HotelID
      DebtorID
      ModifiedDT
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      CreditID
      DebitDetail {
        ID
        DocumentNo
        DocumentDate
      }
      CreditDetail {
        ID
        DocumentNo
        DocumentDate
      }
      AllocationAmount
      AllocDate
    }
    CityLedgerAttachment {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
  }
}
    `;

/**
 * __useRefundListingsQuery__
 *
 * To run a query within a React component, call `useRefundListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      Offset: // value for 'Offset'
 *      Limit: // value for 'Limit'
 *      Status: // value for 'Status'
 *      DocumentNo: // value for 'DocumentNo'
 *      Description: // value for 'Description'
 *      Amount: // value for 'Amount'
 *   },
 * });
 */
export function useRefundListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefundListingsQuery, RefundListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<RefundListingsQuery, RefundListingsQueryVariables>(RefundListingsDocument, baseOptions);
      }
export function useRefundListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefundListingsQuery, RefundListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefundListingsQuery, RefundListingsQueryVariables>(RefundListingsDocument, baseOptions);
        }
export type RefundListingsQueryHookResult = ReturnType<typeof useRefundListingsQuery>;
export type RefundListingsLazyQueryHookResult = ReturnType<typeof useRefundListingsLazyQuery>;
export type RefundListingsQueryResult = ApolloReactCommon.QueryResult<RefundListingsQuery, RefundListingsQueryVariables>;
export const RefundDetailDocument = gql`
    query RefundDetail($ID: String!) {
  RefundDetail(ID: $ID) {
    ID
    DocumentNo
    ReferenceNo
    Description
    DocumentDate
    Amount
    Status
    HotelID
    AccountID
    DebtorID
    Attachment
    Currency
    OutstandingAmount
    CurrencyRate
    Reason
    VoidDate
    PostedDate
    Commission
    Allocation {
      ID
      HotelID
      DebtorID
      AccountID
      DebitRefTable
      DebitID
      CreditRefTable
      CreditID
      AllocationAmount
    }
  }
}
    `;

/**
 * __useRefundDetailQuery__
 *
 * To run a query within a React component, call `useRefundDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useRefundDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefundDetailQuery, RefundDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<RefundDetailQuery, RefundDetailQueryVariables>(RefundDetailDocument, baseOptions);
      }
export function useRefundDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefundDetailQuery, RefundDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefundDetailQuery, RefundDetailQueryVariables>(RefundDetailDocument, baseOptions);
        }
export type RefundDetailQueryHookResult = ReturnType<typeof useRefundDetailQuery>;
export type RefundDetailLazyQueryHookResult = ReturnType<typeof useRefundDetailLazyQuery>;
export type RefundDetailQueryResult = ApolloReactCommon.QueryResult<RefundDetailQuery, RefundDetailQueryVariables>;
export const RefundInsertDocument = gql`
    mutation RefundInsert($input: RefundEntityInput!, $CityLedgerAttachmentInput: [Upload!]) {
  RefundInsert(input: $input, CityLedgerAttachmentInput: $CityLedgerAttachmentInput) {
    ID
  }
}
    `;
export type RefundInsertMutationFn = ApolloReactCommon.MutationFunction<RefundInsertMutation, RefundInsertMutationVariables>;

/**
 * __useRefundInsertMutation__
 *
 * To run a mutation, you first call `useRefundInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundInsertMutation, { data, loading, error }] = useRefundInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      CityLedgerAttachmentInput: // value for 'CityLedgerAttachmentInput'
 *   },
 * });
 */
export function useRefundInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundInsertMutation, RefundInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RefundInsertMutation, RefundInsertMutationVariables>(RefundInsertDocument, baseOptions);
      }
export type RefundInsertMutationHookResult = ReturnType<typeof useRefundInsertMutation>;
export type RefundInsertMutationResult = ApolloReactCommon.MutationResult<RefundInsertMutation>;
export type RefundInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundInsertMutation, RefundInsertMutationVariables>;
export const RefundUpdateDocument = gql`
    mutation RefundUpdate($input: RefundEntityInput!, $ID: String!, $CityLedgerAttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  RefundUpdate(input: $input, ID: $ID, CityLedgerAttachmentInput: $CityLedgerAttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type RefundUpdateMutationFn = ApolloReactCommon.MutationFunction<RefundUpdateMutation, RefundUpdateMutationVariables>;

/**
 * __useRefundUpdateMutation__
 *
 * To run a mutation, you first call `useRefundUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundUpdateMutation, { data, loading, error }] = useRefundUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *      CityLedgerAttachmentInput: // value for 'CityLedgerAttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useRefundUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundUpdateMutation, RefundUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RefundUpdateMutation, RefundUpdateMutationVariables>(RefundUpdateDocument, baseOptions);
      }
export type RefundUpdateMutationHookResult = ReturnType<typeof useRefundUpdateMutation>;
export type RefundUpdateMutationResult = ApolloReactCommon.MutationResult<RefundUpdateMutation>;
export type RefundUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundUpdateMutation, RefundUpdateMutationVariables>;
export const RefundCancelDocument = gql`
    mutation RefundCancel($ReasonCode: String!, $Reason: String!, $ID: String!) {
  RefundCancel(ReasonCode: $ReasonCode, Reason: $Reason, ID: $ID)
}
    `;
export type RefundCancelMutationFn = ApolloReactCommon.MutationFunction<RefundCancelMutation, RefundCancelMutationVariables>;

/**
 * __useRefundCancelMutation__
 *
 * To run a mutation, you first call `useRefundCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundCancelMutation, { data, loading, error }] = useRefundCancelMutation({
 *   variables: {
 *      ReasonCode: // value for 'ReasonCode'
 *      Reason: // value for 'Reason'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useRefundCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundCancelMutation, RefundCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<RefundCancelMutation, RefundCancelMutationVariables>(RefundCancelDocument, baseOptions);
      }
export type RefundCancelMutationHookResult = ReturnType<typeof useRefundCancelMutation>;
export type RefundCancelMutationResult = ApolloReactCommon.MutationResult<RefundCancelMutation>;
export type RefundCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundCancelMutation, RefundCancelMutationVariables>;
export const RefundDeleteDocument = gql`
    mutation RefundDelete($ID: String!) {
  RefundDelete(ID: $ID)
}
    `;
export type RefundDeleteMutationFn = ApolloReactCommon.MutationFunction<RefundDeleteMutation, RefundDeleteMutationVariables>;

/**
 * __useRefundDeleteMutation__
 *
 * To run a mutation, you first call `useRefundDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundDeleteMutation, { data, loading, error }] = useRefundDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useRefundDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundDeleteMutation, RefundDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RefundDeleteMutation, RefundDeleteMutationVariables>(RefundDeleteDocument, baseOptions);
      }
export type RefundDeleteMutationHookResult = ReturnType<typeof useRefundDeleteMutation>;
export type RefundDeleteMutationResult = ApolloReactCommon.MutationResult<RefundDeleteMutation>;
export type RefundDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundDeleteMutation, RefundDeleteMutationVariables>;
export const AllocateRefundDocument = gql`
    mutation AllocateRefund($allocInput: [DebitAllocationInput!]!, $RefundID: String!) {
  AllocateRefund(allocInput: $allocInput, RefundID: $RefundID)
}
    `;
export type AllocateRefundMutationFn = ApolloReactCommon.MutationFunction<AllocateRefundMutation, AllocateRefundMutationVariables>;

/**
 * __useAllocateRefundMutation__
 *
 * To run a mutation, you first call `useAllocateRefundMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAllocateRefundMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [allocateRefundMutation, { data, loading, error }] = useAllocateRefundMutation({
 *   variables: {
 *      allocInput: // value for 'allocInput'
 *      RefundID: // value for 'RefundID'
 *   },
 * });
 */
export function useAllocateRefundMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AllocateRefundMutation, AllocateRefundMutationVariables>) {
        return ApolloReactHooks.useMutation<AllocateRefundMutation, AllocateRefundMutationVariables>(AllocateRefundDocument, baseOptions);
      }
export type AllocateRefundMutationHookResult = ReturnType<typeof useAllocateRefundMutation>;
export type AllocateRefundMutationResult = ApolloReactCommon.MutationResult<AllocateRefundMutation>;
export type AllocateRefundMutationOptions = ApolloReactCommon.BaseMutationOptions<AllocateRefundMutation, AllocateRefundMutationVariables>;
export const RefundInfoDocument = gql`
    query RefundInfo($HotelID: String!, $DebtorID: String!, $IsPostedDoc: Boolean!) {
  RefundInfo(HotelID: $HotelID, DebtorID: $DebtorID, IsPostedDoc: $IsPostedDoc) {
    LastCreatedDT
    TotalOstandingAmount
    TotalAmount
    noOfDocument
  }
}
    `;

/**
 * __useRefundInfoQuery__
 *
 * To run a query within a React component, call `useRefundInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefundInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefundInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      IsPostedDoc: // value for 'IsPostedDoc'
 *   },
 * });
 */
export function useRefundInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefundInfoQuery, RefundInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<RefundInfoQuery, RefundInfoQueryVariables>(RefundInfoDocument, baseOptions);
      }
export function useRefundInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefundInfoQuery, RefundInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefundInfoQuery, RefundInfoQueryVariables>(RefundInfoDocument, baseOptions);
        }
export type RefundInfoQueryHookResult = ReturnType<typeof useRefundInfoQuery>;
export type RefundInfoLazyQueryHookResult = ReturnType<typeof useRefundInfoLazyQuery>;
export type RefundInfoQueryResult = ApolloReactCommon.QueryResult<RefundInfoQuery, RefundInfoQueryVariables>;
export const IncidentalChargeByIdDocument = gql`
    query IncidentalChargeByID($ID: String!) {
  IncidentalChargeByID(ID: $ID) {
    ID
    Description
    EInvClassification
  }
}
    `;

/**
 * __useIncidentalChargeByIdQuery__
 *
 * To run a query within a React component, call `useIncidentalChargeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentalChargeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentalChargeByIdQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useIncidentalChargeByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IncidentalChargeByIdQuery, IncidentalChargeByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<IncidentalChargeByIdQuery, IncidentalChargeByIdQueryVariables>(IncidentalChargeByIdDocument, baseOptions);
      }
export function useIncidentalChargeByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IncidentalChargeByIdQuery, IncidentalChargeByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IncidentalChargeByIdQuery, IncidentalChargeByIdQueryVariables>(IncidentalChargeByIdDocument, baseOptions);
        }
export type IncidentalChargeByIdQueryHookResult = ReturnType<typeof useIncidentalChargeByIdQuery>;
export type IncidentalChargeByIdLazyQueryHookResult = ReturnType<typeof useIncidentalChargeByIdLazyQuery>;
export type IncidentalChargeByIdQueryResult = ApolloReactCommon.QueryResult<IncidentalChargeByIdQuery, IncidentalChargeByIdQueryVariables>;
export const OpeningClosingAmountV2Document = gql`
    query OpeningClosingAmountV2($DebtorID: String!, $StartDate: DateTime) {
  OpeningClosingAmountV2(DebtorID: $DebtorID, StartDate: $StartDate)
}
    `;

/**
 * __useOpeningClosingAmountV2Query__
 *
 * To run a query within a React component, call `useOpeningClosingAmountV2Query` and pass it any options that fit your needs.
 * When your component renders, `useOpeningClosingAmountV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpeningClosingAmountV2Query({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useOpeningClosingAmountV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<OpeningClosingAmountV2Query, OpeningClosingAmountV2QueryVariables>) {
        return ApolloReactHooks.useQuery<OpeningClosingAmountV2Query, OpeningClosingAmountV2QueryVariables>(OpeningClosingAmountV2Document, baseOptions);
      }
export function useOpeningClosingAmountV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OpeningClosingAmountV2Query, OpeningClosingAmountV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OpeningClosingAmountV2Query, OpeningClosingAmountV2QueryVariables>(OpeningClosingAmountV2Document, baseOptions);
        }
export type OpeningClosingAmountV2QueryHookResult = ReturnType<typeof useOpeningClosingAmountV2Query>;
export type OpeningClosingAmountV2LazyQueryHookResult = ReturnType<typeof useOpeningClosingAmountV2LazyQuery>;
export type OpeningClosingAmountV2QueryResult = ApolloReactCommon.QueryResult<OpeningClosingAmountV2Query, OpeningClosingAmountV2QueryVariables>;
export const CreditNoteAllocationListingDocument = gql`
    query CreditNoteAllocationListing($HotelID: String!, $CreditID: String!, $DebtorID: String!) {
  CreditNoteAllocationListing(HotelID: $HotelID, CreditID: $CreditID, DebtorID: $DebtorID)
}
    `;

/**
 * __useCreditNoteAllocationListingQuery__
 *
 * To run a query within a React component, call `useCreditNoteAllocationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteAllocationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditNoteAllocationListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      CreditID: // value for 'CreditID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useCreditNoteAllocationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditNoteAllocationListingQuery, CreditNoteAllocationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditNoteAllocationListingQuery, CreditNoteAllocationListingQueryVariables>(CreditNoteAllocationListingDocument, baseOptions);
      }
export function useCreditNoteAllocationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditNoteAllocationListingQuery, CreditNoteAllocationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditNoteAllocationListingQuery, CreditNoteAllocationListingQueryVariables>(CreditNoteAllocationListingDocument, baseOptions);
        }
export type CreditNoteAllocationListingQueryHookResult = ReturnType<typeof useCreditNoteAllocationListingQuery>;
export type CreditNoteAllocationListingLazyQueryHookResult = ReturnType<typeof useCreditNoteAllocationListingLazyQuery>;
export type CreditNoteAllocationListingQueryResult = ApolloReactCommon.QueryResult<CreditNoteAllocationListingQuery, CreditNoteAllocationListingQueryVariables>;
export const AllocationDetailListingDocument = gql`
    query AllocationDetailListing($HotelID: String!, $CreditID: String!, $DebtorID: String!) {
  AllocationDetailListing(HotelID: $HotelID, CreditID: $CreditID, DebtorID: $DebtorID)
}
    `;

/**
 * __useAllocationDetailListingQuery__
 *
 * To run a query within a React component, call `useAllocationDetailListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllocationDetailListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllocationDetailListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      CreditID: // value for 'CreditID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useAllocationDetailListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllocationDetailListingQuery, AllocationDetailListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AllocationDetailListingQuery, AllocationDetailListingQueryVariables>(AllocationDetailListingDocument, baseOptions);
      }
export function useAllocationDetailListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllocationDetailListingQuery, AllocationDetailListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllocationDetailListingQuery, AllocationDetailListingQueryVariables>(AllocationDetailListingDocument, baseOptions);
        }
export type AllocationDetailListingQueryHookResult = ReturnType<typeof useAllocationDetailListingQuery>;
export type AllocationDetailListingLazyQueryHookResult = ReturnType<typeof useAllocationDetailListingLazyQuery>;
export type AllocationDetailListingQueryResult = ApolloReactCommon.QueryResult<AllocationDetailListingQuery, AllocationDetailListingQueryVariables>;
export const DebitNoteAllocationListingDocument = gql`
    query DebitNoteAllocationListing($HotelID: String!, $DebitID: String!, $DebtorID: String!) {
  DebitNoteAllocationListing(HotelID: $HotelID, DebitID: $DebitID, DebtorID: $DebtorID)
}
    `;

/**
 * __useDebitNoteAllocationListingQuery__
 *
 * To run a query within a React component, call `useDebitNoteAllocationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebitNoteAllocationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebitNoteAllocationListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebitID: // value for 'DebitID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useDebitNoteAllocationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebitNoteAllocationListingQuery, DebitNoteAllocationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DebitNoteAllocationListingQuery, DebitNoteAllocationListingQueryVariables>(DebitNoteAllocationListingDocument, baseOptions);
      }
export function useDebitNoteAllocationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebitNoteAllocationListingQuery, DebitNoteAllocationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebitNoteAllocationListingQuery, DebitNoteAllocationListingQueryVariables>(DebitNoteAllocationListingDocument, baseOptions);
        }
export type DebitNoteAllocationListingQueryHookResult = ReturnType<typeof useDebitNoteAllocationListingQuery>;
export type DebitNoteAllocationListingLazyQueryHookResult = ReturnType<typeof useDebitNoteAllocationListingLazyQuery>;
export type DebitNoteAllocationListingQueryResult = ApolloReactCommon.QueryResult<DebitNoteAllocationListingQuery, DebitNoteAllocationListingQueryVariables>;
export const CreditNoteAndInvoiceListingsDocument = gql`
    query CreditNoteAndInvoiceListings($HotelID: String!, $DebtorID: String!, $StartDate: DateTime, $EndDate: DateTime, $offset: Float, $limit: Float, $DocumentNo: String, $Description: String, $Amount: String, $IsGroupInvoice: Boolean) {
  CreditNoteAndInvoiceListings(HotelID: $HotelID, DebtorID: $DebtorID, StartDate: $StartDate, EndDate: $EndDate, offset: $offset, limit: $limit, DocumentNo: $DocumentNo, Description: $Description, Amount: $Amount, IsGroupInvoice: $IsGroupInvoice)
}
    `;

/**
 * __useCreditNoteAndInvoiceListingsQuery__
 *
 * To run a query within a React component, call `useCreditNoteAndInvoiceListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteAndInvoiceListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditNoteAndInvoiceListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      DocumentNo: // value for 'DocumentNo'
 *      Description: // value for 'Description'
 *      Amount: // value for 'Amount'
 *      IsGroupInvoice: // value for 'IsGroupInvoice'
 *   },
 * });
 */
export function useCreditNoteAndInvoiceListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditNoteAndInvoiceListingsQuery, CreditNoteAndInvoiceListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditNoteAndInvoiceListingsQuery, CreditNoteAndInvoiceListingsQueryVariables>(CreditNoteAndInvoiceListingsDocument, baseOptions);
      }
export function useCreditNoteAndInvoiceListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditNoteAndInvoiceListingsQuery, CreditNoteAndInvoiceListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditNoteAndInvoiceListingsQuery, CreditNoteAndInvoiceListingsQueryVariables>(CreditNoteAndInvoiceListingsDocument, baseOptions);
        }
export type CreditNoteAndInvoiceListingsQueryHookResult = ReturnType<typeof useCreditNoteAndInvoiceListingsQuery>;
export type CreditNoteAndInvoiceListingsLazyQueryHookResult = ReturnType<typeof useCreditNoteAndInvoiceListingsLazyQuery>;
export type CreditNoteAndInvoiceListingsQueryResult = ApolloReactCommon.QueryResult<CreditNoteAndInvoiceListingsQuery, CreditNoteAndInvoiceListingsQueryVariables>;
export const GroupCreditNoteAndInvoiceListingsDocument = gql`
    query GroupCreditNoteAndInvoiceListings($HotelID: String!, $DebtorID: String!, $StartDate: DateTime, $EndDate: DateTime, $offset: Float, $limit: Float, $DocumentNo: String, $Description: String, $Amount: String, $BookingNo: String, $GroupInvoiceNo: String, $GroupReferenceNo: String) {
  GroupCreditNoteAndInvoiceListings(HotelID: $HotelID, DebtorID: $DebtorID, StartDate: $StartDate, EndDate: $EndDate, offset: $offset, limit: $limit, DocumentNo: $DocumentNo, Description: $Description, Amount: $Amount, BookingNo: $BookingNo, GroupInvoiceNo: $GroupInvoiceNo, GroupReferenceNo: $GroupReferenceNo)
}
    `;

/**
 * __useGroupCreditNoteAndInvoiceListingsQuery__
 *
 * To run a query within a React component, call `useGroupCreditNoteAndInvoiceListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupCreditNoteAndInvoiceListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupCreditNoteAndInvoiceListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      DocumentNo: // value for 'DocumentNo'
 *      Description: // value for 'Description'
 *      Amount: // value for 'Amount'
 *      BookingNo: // value for 'BookingNo'
 *      GroupInvoiceNo: // value for 'GroupInvoiceNo'
 *      GroupReferenceNo: // value for 'GroupReferenceNo'
 *   },
 * });
 */
export function useGroupCreditNoteAndInvoiceListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupCreditNoteAndInvoiceListingsQuery, GroupCreditNoteAndInvoiceListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupCreditNoteAndInvoiceListingsQuery, GroupCreditNoteAndInvoiceListingsQueryVariables>(GroupCreditNoteAndInvoiceListingsDocument, baseOptions);
      }
export function useGroupCreditNoteAndInvoiceListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupCreditNoteAndInvoiceListingsQuery, GroupCreditNoteAndInvoiceListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupCreditNoteAndInvoiceListingsQuery, GroupCreditNoteAndInvoiceListingsQueryVariables>(GroupCreditNoteAndInvoiceListingsDocument, baseOptions);
        }
export type GroupCreditNoteAndInvoiceListingsQueryHookResult = ReturnType<typeof useGroupCreditNoteAndInvoiceListingsQuery>;
export type GroupCreditNoteAndInvoiceListingsLazyQueryHookResult = ReturnType<typeof useGroupCreditNoteAndInvoiceListingsLazyQuery>;
export type GroupCreditNoteAndInvoiceListingsQueryResult = ApolloReactCommon.QueryResult<GroupCreditNoteAndInvoiceListingsQuery, GroupCreditNoteAndInvoiceListingsQueryVariables>;
export const CheckBanknameInUseDocument = gql`
    query CheckBanknameInUse($HotelID: String!, $BankName: String!) {
  CheckBanknameInUse(HotelID: $HotelID, BankName: $BankName)
}
    `;

/**
 * __useCheckBanknameInUseQuery__
 *
 * To run a query within a React component, call `useCheckBanknameInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckBanknameInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckBanknameInUseQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BankName: // value for 'BankName'
 *   },
 * });
 */
export function useCheckBanknameInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckBanknameInUseQuery, CheckBanknameInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckBanknameInUseQuery, CheckBanknameInUseQueryVariables>(CheckBanknameInUseDocument, baseOptions);
      }
export function useCheckBanknameInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckBanknameInUseQuery, CheckBanknameInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckBanknameInUseQuery, CheckBanknameInUseQueryVariables>(CheckBanknameInUseDocument, baseOptions);
        }
export type CheckBanknameInUseQueryHookResult = ReturnType<typeof useCheckBanknameInUseQuery>;
export type CheckBanknameInUseLazyQueryHookResult = ReturnType<typeof useCheckBanknameInUseLazyQuery>;
export type CheckBanknameInUseQueryResult = ApolloReactCommon.QueryResult<CheckBanknameInUseQuery, CheckBanknameInUseQueryVariables>;
export const CreditNoteAndInvoiceCountDocument = gql`
    query CreditNoteAndInvoiceCount($HotelID: String!, $DebtorID: String!) {
  CreditNoteAndInvoiceCount(HotelID: $HotelID, DebtorID: $DebtorID)
}
    `;

/**
 * __useCreditNoteAndInvoiceCountQuery__
 *
 * To run a query within a React component, call `useCreditNoteAndInvoiceCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditNoteAndInvoiceCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditNoteAndInvoiceCountQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useCreditNoteAndInvoiceCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditNoteAndInvoiceCountQuery, CreditNoteAndInvoiceCountQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditNoteAndInvoiceCountQuery, CreditNoteAndInvoiceCountQueryVariables>(CreditNoteAndInvoiceCountDocument, baseOptions);
      }
export function useCreditNoteAndInvoiceCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditNoteAndInvoiceCountQuery, CreditNoteAndInvoiceCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditNoteAndInvoiceCountQuery, CreditNoteAndInvoiceCountQueryVariables>(CreditNoteAndInvoiceCountDocument, baseOptions);
        }
export type CreditNoteAndInvoiceCountQueryHookResult = ReturnType<typeof useCreditNoteAndInvoiceCountQuery>;
export type CreditNoteAndInvoiceCountLazyQueryHookResult = ReturnType<typeof useCreditNoteAndInvoiceCountLazyQuery>;
export type CreditNoteAndInvoiceCountQueryResult = ApolloReactCommon.QueryResult<CreditNoteAndInvoiceCountQuery, CreditNoteAndInvoiceCountQueryVariables>;
export const DocumentNumberHeaderListingDocument = gql`
    query DocumentNumberHeaderListing($HotelID: String!) {
  DocumentNumberHeaderListing(HotelID: $HotelID) {
    ID
    HotelID
    AccountID
    Type
    SampleOutput
    DocumentNumberDetail {
      ID
      DocumentNumberHeaderID
      Sequence
      FormatType
      FormatValue
      NextNumber
      StartFrom
      IsActive
    }
  }
}
    `;

/**
 * __useDocumentNumberHeaderListingQuery__
 *
 * To run a query within a React component, call `useDocumentNumberHeaderListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentNumberHeaderListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentNumberHeaderListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDocumentNumberHeaderListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentNumberHeaderListingQuery, DocumentNumberHeaderListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentNumberHeaderListingQuery, DocumentNumberHeaderListingQueryVariables>(DocumentNumberHeaderListingDocument, baseOptions);
      }
export function useDocumentNumberHeaderListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentNumberHeaderListingQuery, DocumentNumberHeaderListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentNumberHeaderListingQuery, DocumentNumberHeaderListingQueryVariables>(DocumentNumberHeaderListingDocument, baseOptions);
        }
export type DocumentNumberHeaderListingQueryHookResult = ReturnType<typeof useDocumentNumberHeaderListingQuery>;
export type DocumentNumberHeaderListingLazyQueryHookResult = ReturnType<typeof useDocumentNumberHeaderListingLazyQuery>;
export type DocumentNumberHeaderListingQueryResult = ApolloReactCommon.QueryResult<DocumentNumberHeaderListingQuery, DocumentNumberHeaderListingQueryVariables>;
export const DocumentNumberingSetupInsertDocument = gql`
    mutation DocumentNumberingSetupInsert($DocumentNumberHeaderinput: DocumentNumberHeaderInput!, $DocumentNumberDetailInput: [DocumentNumberDetailInput!]!) {
  DocumentNumberingSetupInsert(DocumentNumberHeaderinput: $DocumentNumberHeaderinput, DocumentNumberDetailInput: $DocumentNumberDetailInput)
}
    `;
export type DocumentNumberingSetupInsertMutationFn = ApolloReactCommon.MutationFunction<DocumentNumberingSetupInsertMutation, DocumentNumberingSetupInsertMutationVariables>;

/**
 * __useDocumentNumberingSetupInsertMutation__
 *
 * To run a mutation, you first call `useDocumentNumberingSetupInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentNumberingSetupInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentNumberingSetupInsertMutation, { data, loading, error }] = useDocumentNumberingSetupInsertMutation({
 *   variables: {
 *      DocumentNumberHeaderinput: // value for 'DocumentNumberHeaderinput'
 *      DocumentNumberDetailInput: // value for 'DocumentNumberDetailInput'
 *   },
 * });
 */
export function useDocumentNumberingSetupInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentNumberingSetupInsertMutation, DocumentNumberingSetupInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentNumberingSetupInsertMutation, DocumentNumberingSetupInsertMutationVariables>(DocumentNumberingSetupInsertDocument, baseOptions);
      }
export type DocumentNumberingSetupInsertMutationHookResult = ReturnType<typeof useDocumentNumberingSetupInsertMutation>;
export type DocumentNumberingSetupInsertMutationResult = ApolloReactCommon.MutationResult<DocumentNumberingSetupInsertMutation>;
export type DocumentNumberingSetupInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentNumberingSetupInsertMutation, DocumentNumberingSetupInsertMutationVariables>;
export const DocumentNumberingUpdateDocument = gql`
    mutation DocumentNumberingUpdate($DocumentNumberHeaderID: String!, $SampleOutput: String!, $DocumentNumberDetailInput: [DocumentNumberDetailInput!]!) {
  DocumentNumberingUpdate(DocumentNumberHeaderID: $DocumentNumberHeaderID, SampleOutput: $SampleOutput, DocumentNumberDetailInput: $DocumentNumberDetailInput)
}
    `;
export type DocumentNumberingUpdateMutationFn = ApolloReactCommon.MutationFunction<DocumentNumberingUpdateMutation, DocumentNumberingUpdateMutationVariables>;

/**
 * __useDocumentNumberingUpdateMutation__
 *
 * To run a mutation, you first call `useDocumentNumberingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentNumberingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentNumberingUpdateMutation, { data, loading, error }] = useDocumentNumberingUpdateMutation({
 *   variables: {
 *      DocumentNumberHeaderID: // value for 'DocumentNumberHeaderID'
 *      SampleOutput: // value for 'SampleOutput'
 *      DocumentNumberDetailInput: // value for 'DocumentNumberDetailInput'
 *   },
 * });
 */
export function useDocumentNumberingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DocumentNumberingUpdateMutation, DocumentNumberingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DocumentNumberingUpdateMutation, DocumentNumberingUpdateMutationVariables>(DocumentNumberingUpdateDocument, baseOptions);
      }
export type DocumentNumberingUpdateMutationHookResult = ReturnType<typeof useDocumentNumberingUpdateMutation>;
export type DocumentNumberingUpdateMutationResult = ApolloReactCommon.MutationResult<DocumentNumberingUpdateMutation>;
export type DocumentNumberingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DocumentNumberingUpdateMutation, DocumentNumberingUpdateMutationVariables>;
export const ScheduleBillingListDocument = gql`
    query ScheduleBillingList($HotelID: String!, $IsPosted: Boolean, $DebtorAcc: [String!]!, $DocStartDate: DateTime, $DocEndDate: DateTime, $DebtorTypeID: [String!]!, $BookingNo: String, $IsGroupBooking: String, $IsGroupInvoice: String, $offset: Float, $limit: Float) {
  ScheduleBillingList(HotelID: $HotelID, IsPosted: $IsPosted, DebtorAcc: $DebtorAcc, DocStartDate: $DocStartDate, DocEndDate: $DocEndDate, DebtorTypeID: $DebtorTypeID, BookingNo: $BookingNo, IsGroupBooking: $IsGroupBooking, IsGroupInvoice: $IsGroupInvoice, offset: $offset, limit: $limit) {
    CreatedBy
    BookingNo
    BookingID
    GuestName
    GuestEmail
    DebtorName
    DebtorAccount
    ActualFolioNo
    CreditTerm
    DebtorID
    FolioNo
    FolioAmount
    RoomNo
    RoomType
    FolioDate
    FolioID
    PaymentID
    Description
    SourceTable
    IsGroupBooking
    IsGroupInvoice
  }
}
    `;

/**
 * __useScheduleBillingListQuery__
 *
 * To run a query within a React component, call `useScheduleBillingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleBillingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleBillingListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      IsPosted: // value for 'IsPosted'
 *      DebtorAcc: // value for 'DebtorAcc'
 *      DocStartDate: // value for 'DocStartDate'
 *      DocEndDate: // value for 'DocEndDate'
 *      DebtorTypeID: // value for 'DebtorTypeID'
 *      BookingNo: // value for 'BookingNo'
 *      IsGroupBooking: // value for 'IsGroupBooking'
 *      IsGroupInvoice: // value for 'IsGroupInvoice'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useScheduleBillingListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ScheduleBillingListQuery, ScheduleBillingListQueryVariables>) {
        return ApolloReactHooks.useQuery<ScheduleBillingListQuery, ScheduleBillingListQueryVariables>(ScheduleBillingListDocument, baseOptions);
      }
export function useScheduleBillingListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ScheduleBillingListQuery, ScheduleBillingListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ScheduleBillingListQuery, ScheduleBillingListQueryVariables>(ScheduleBillingListDocument, baseOptions);
        }
export type ScheduleBillingListQueryHookResult = ReturnType<typeof useScheduleBillingListQuery>;
export type ScheduleBillingListLazyQueryHookResult = ReturnType<typeof useScheduleBillingListLazyQuery>;
export type ScheduleBillingListQueryResult = ApolloReactCommon.QueryResult<ScheduleBillingListQuery, ScheduleBillingListQueryVariables>;
export const PostScheduleBillingDocument = gql`
    mutation PostScheduleBilling($input: [ScheduleBillingInput!]!, $HotelID: String!, $InvoiceDate: DateTime) {
  PostScheduleBilling(input: $input, HotelID: $HotelID, InvoiceDate: $InvoiceDate)
}
    `;
export type PostScheduleBillingMutationFn = ApolloReactCommon.MutationFunction<PostScheduleBillingMutation, PostScheduleBillingMutationVariables>;

/**
 * __usePostScheduleBillingMutation__
 *
 * To run a mutation, you first call `usePostScheduleBillingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostScheduleBillingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postScheduleBillingMutation, { data, loading, error }] = usePostScheduleBillingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      HotelID: // value for 'HotelID'
 *      InvoiceDate: // value for 'InvoiceDate'
 *   },
 * });
 */
export function usePostScheduleBillingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostScheduleBillingMutation, PostScheduleBillingMutationVariables>) {
        return ApolloReactHooks.useMutation<PostScheduleBillingMutation, PostScheduleBillingMutationVariables>(PostScheduleBillingDocument, baseOptions);
      }
export type PostScheduleBillingMutationHookResult = ReturnType<typeof usePostScheduleBillingMutation>;
export type PostScheduleBillingMutationResult = ApolloReactCommon.MutationResult<PostScheduleBillingMutation>;
export type PostScheduleBillingMutationOptions = ApolloReactCommon.BaseMutationOptions<PostScheduleBillingMutation, PostScheduleBillingMutationVariables>;
export const CompanyInsertDocument = gql`
    mutation CompanyInsert($CompanyInput: CompanyInput!, $AttachmentInput: [Upload!]) {
  CompanyInsert(CompanyInput: $CompanyInput, AttachmentInput: $AttachmentInput) {
    id
  }
}
    `;
export type CompanyInsertMutationFn = ApolloReactCommon.MutationFunction<CompanyInsertMutation, CompanyInsertMutationVariables>;

/**
 * __useCompanyInsertMutation__
 *
 * To run a mutation, you first call `useCompanyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyInsertMutation, { data, loading, error }] = useCompanyInsertMutation({
 *   variables: {
 *      CompanyInput: // value for 'CompanyInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useCompanyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyInsertMutation, CompanyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyInsertMutation, CompanyInsertMutationVariables>(CompanyInsertDocument, baseOptions);
      }
export type CompanyInsertMutationHookResult = ReturnType<typeof useCompanyInsertMutation>;
export type CompanyInsertMutationResult = ApolloReactCommon.MutationResult<CompanyInsertMutation>;
export type CompanyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyInsertMutation, CompanyInsertMutationVariables>;
export const CompanyUpdateDocument = gql`
    mutation CompanyUpdate($CompanyInput: CompanyInput!, $AttachmentInput: [Upload!]) {
  CompanyUpdate(CompanyInput: $CompanyInput, AttachmentInput: $AttachmentInput)
}
    `;
export type CompanyUpdateMutationFn = ApolloReactCommon.MutationFunction<CompanyUpdateMutation, CompanyUpdateMutationVariables>;

/**
 * __useCompanyUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateMutation, { data, loading, error }] = useCompanyUpdateMutation({
 *   variables: {
 *      CompanyInput: // value for 'CompanyInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useCompanyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyUpdateMutation, CompanyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyUpdateMutation, CompanyUpdateMutationVariables>(CompanyUpdateDocument, baseOptions);
      }
export type CompanyUpdateMutationHookResult = ReturnType<typeof useCompanyUpdateMutation>;
export type CompanyUpdateMutationResult = ApolloReactCommon.MutationResult<CompanyUpdateMutation>;
export type CompanyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyUpdateMutation, CompanyUpdateMutationVariables>;
export const CompanyUpdateStatusDocument = gql`
    mutation CompanyUpdateStatus($Status: String!, $CompanyID: String!) {
  CompanyUpdateStatus(Status: $Status, CompanyID: $CompanyID)
}
    `;
export type CompanyUpdateStatusMutationFn = ApolloReactCommon.MutationFunction<CompanyUpdateStatusMutation, CompanyUpdateStatusMutationVariables>;

/**
 * __useCompanyUpdateStatusMutation__
 *
 * To run a mutation, you first call `useCompanyUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyUpdateStatusMutation, { data, loading, error }] = useCompanyUpdateStatusMutation({
 *   variables: {
 *      Status: // value for 'Status'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCompanyUpdateStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CompanyUpdateStatusMutation, CompanyUpdateStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<CompanyUpdateStatusMutation, CompanyUpdateStatusMutationVariables>(CompanyUpdateStatusDocument, baseOptions);
      }
export type CompanyUpdateStatusMutationHookResult = ReturnType<typeof useCompanyUpdateStatusMutation>;
export type CompanyUpdateStatusMutationResult = ApolloReactCommon.MutationResult<CompanyUpdateStatusMutation>;
export type CompanyUpdateStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<CompanyUpdateStatusMutation, CompanyUpdateStatusMutationVariables>;
export const CompanyListingDocument = gql`
    query CompanyListing($AccountID: String!, $offset: Float, $limit: Float) {
  CompanyListing(AccountID: $AccountID, offset: $offset, limit: $limit) {
    id
    parentCompanyId
    accountId
    baseCurrencyId
    name
    description
    companyRegNo
    code
    address {
      address
      city
      state
      country
      postCode
    }
    contactNo
    email
    sstNo
    gstNo
    ttxNo
    gstExpiryDate
    image
    imageUrl
    imageBucketFile
    websiteUrl
    eInvClientID
    eInvClientSecret
    eInvClientSecret2
    regionId
    imageLogoPosition
    watermarkImplementation
    watermarkWording
    isoImplementation
    isoLogoPosition
    companyTax
    recordStatus
    groupGstRegNo
    officeTel
    tin
    eSign
    eSignExpiryDate
    eInvStartDate
    eInvEndDate
    isEInvIntegrated
  }
}
    `;

/**
 * __useCompanyListingQuery__
 *
 * To run a query within a React component, call `useCompanyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyListingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCompanyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CompanyListingQuery, CompanyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CompanyListingQuery, CompanyListingQueryVariables>(CompanyListingDocument, baseOptions);
      }
export function useCompanyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyListingQuery, CompanyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CompanyListingQuery, CompanyListingQueryVariables>(CompanyListingDocument, baseOptions);
        }
export type CompanyListingQueryHookResult = ReturnType<typeof useCompanyListingQuery>;
export type CompanyListingLazyQueryHookResult = ReturnType<typeof useCompanyListingLazyQuery>;
export type CompanyListingQueryResult = ApolloReactCommon.QueryResult<CompanyListingQuery, CompanyListingQueryVariables>;
export const CurrencyListingDocument = gql`
    query CurrencyListing {
  CurrencyListing {
    id
    code
    name
    symbol
    format
    common_status
  }
}
    `;

/**
 * __useCurrencyListingQuery__
 *
 * To run a query within a React component, call `useCurrencyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrencyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrencyListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrencyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrencyListingQuery, CurrencyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrencyListingQuery, CurrencyListingQueryVariables>(CurrencyListingDocument, baseOptions);
      }
export function useCurrencyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrencyListingQuery, CurrencyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrencyListingQuery, CurrencyListingQueryVariables>(CurrencyListingDocument, baseOptions);
        }
export type CurrencyListingQueryHookResult = ReturnType<typeof useCurrencyListingQuery>;
export type CurrencyListingLazyQueryHookResult = ReturnType<typeof useCurrencyListingLazyQuery>;
export type CurrencyListingQueryResult = ApolloReactCommon.QueryResult<CurrencyListingQuery, CurrencyListingQueryVariables>;
export const CountryListingDocument = gql`
    query CountryListing {
  CountryListing {
    ID
    name
    code
    einv_code
  }
}
    `;

/**
 * __useCountryListingQuery__
 *
 * To run a query within a React component, call `useCountryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountryListingQuery, CountryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CountryListingQuery, CountryListingQueryVariables>(CountryListingDocument, baseOptions);
      }
export function useCountryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountryListingQuery, CountryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountryListingQuery, CountryListingQueryVariables>(CountryListingDocument, baseOptions);
        }
export type CountryListingQueryHookResult = ReturnType<typeof useCountryListingQuery>;
export type CountryListingLazyQueryHookResult = ReturnType<typeof useCountryListingLazyQuery>;
export type CountryListingQueryResult = ApolloReactCommon.QueryResult<CountryListingQuery, CountryListingQueryVariables>;
export const StateListingDocument = gql`
    query StateListing {
  StateListing {
    ID
    name
    countryID
    einv_code
  }
}
    `;

/**
 * __useStateListingQuery__
 *
 * To run a query within a React component, call `useStateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStateListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useStateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StateListingQuery, StateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StateListingQuery, StateListingQueryVariables>(StateListingDocument, baseOptions);
      }
export function useStateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StateListingQuery, StateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StateListingQuery, StateListingQueryVariables>(StateListingDocument, baseOptions);
        }
export type StateListingQueryHookResult = ReturnType<typeof useStateListingQuery>;
export type StateListingLazyQueryHookResult = ReturnType<typeof useStateListingLazyQuery>;
export type StateListingQueryResult = ApolloReactCommon.QueryResult<StateListingQuery, StateListingQueryVariables>;
export const EInvSubscriptionDocument = gql`
    query EInvSubscription($AccountID: String!) {
  EInvSubscription(AccountID: $AccountID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    AccountID
  }
}
    `;

/**
 * __useEInvSubscriptionQuery__
 *
 * To run a query within a React component, call `useEInvSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEInvSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEInvSubscriptionQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useEInvSubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EInvSubscriptionQuery, EInvSubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<EInvSubscriptionQuery, EInvSubscriptionQueryVariables>(EInvSubscriptionDocument, baseOptions);
      }
export function useEInvSubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EInvSubscriptionQuery, EInvSubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EInvSubscriptionQuery, EInvSubscriptionQueryVariables>(EInvSubscriptionDocument, baseOptions);
        }
export type EInvSubscriptionQueryHookResult = ReturnType<typeof useEInvSubscriptionQuery>;
export type EInvSubscriptionLazyQueryHookResult = ReturnType<typeof useEInvSubscriptionLazyQuery>;
export type EInvSubscriptionQueryResult = ApolloReactCommon.QueryResult<EInvSubscriptionQuery, EInvSubscriptionQueryVariables>;
export const EInvoiceInformationDocument = gql`
    query EInvoiceInformation($HotelID: String!, $AccountID: String!, $ID: String!, $Type: String!) {
  EInvoiceInformation(HotelID: $HotelID, AccountID: $AccountID, ID: $ID, Type: $Type)
}
    `;

/**
 * __useEInvoiceInformationQuery__
 *
 * To run a query within a React component, call `useEInvoiceInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEInvoiceInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEInvoiceInformationQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      ID: // value for 'ID'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useEInvoiceInformationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EInvoiceInformationQuery, EInvoiceInformationQueryVariables>) {
        return ApolloReactHooks.useQuery<EInvoiceInformationQuery, EInvoiceInformationQueryVariables>(EInvoiceInformationDocument, baseOptions);
      }
export function useEInvoiceInformationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EInvoiceInformationQuery, EInvoiceInformationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EInvoiceInformationQuery, EInvoiceInformationQueryVariables>(EInvoiceInformationDocument, baseOptions);
        }
export type EInvoiceInformationQueryHookResult = ReturnType<typeof useEInvoiceInformationQuery>;
export type EInvoiceInformationLazyQueryHookResult = ReturnType<typeof useEInvoiceInformationLazyQuery>;
export type EInvoiceInformationQueryResult = ApolloReactCommon.QueryResult<EInvoiceInformationQuery, EInvoiceInformationQueryVariables>;
export const DocToConsolidateListingDocument = gql`
    query DocToConsolidateListing($HotelID: String!, $AccountID: String!, $DocType: String!, $Year: Float!, $Month: Float!, $Name: String, $Submenu: String, $DocNo: String, $IsSearch: Boolean, $offset: Float, $limit: Float) {
  DocToConsolidateListing(HotelID: $HotelID, AccountID: $AccountID, DocType: $DocType, Year: $Year, Month: $Month, Name: $Name, Submenu: $Submenu, DocNo: $DocNo, IsSearch: $IsSearch, offset: $offset, limit: $limit)
}
    `;

/**
 * __useDocToConsolidateListingQuery__
 *
 * To run a query within a React component, call `useDocToConsolidateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocToConsolidateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocToConsolidateListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      DocType: // value for 'DocType'
 *      Year: // value for 'Year'
 *      Month: // value for 'Month'
 *      Name: // value for 'Name'
 *      Submenu: // value for 'Submenu'
 *      DocNo: // value for 'DocNo'
 *      IsSearch: // value for 'IsSearch'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDocToConsolidateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocToConsolidateListingQuery, DocToConsolidateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocToConsolidateListingQuery, DocToConsolidateListingQueryVariables>(DocToConsolidateListingDocument, baseOptions);
      }
export function useDocToConsolidateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocToConsolidateListingQuery, DocToConsolidateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocToConsolidateListingQuery, DocToConsolidateListingQueryVariables>(DocToConsolidateListingDocument, baseOptions);
        }
export type DocToConsolidateListingQueryHookResult = ReturnType<typeof useDocToConsolidateListingQuery>;
export type DocToConsolidateListingLazyQueryHookResult = ReturnType<typeof useDocToConsolidateListingLazyQuery>;
export type DocToConsolidateListingQueryResult = ApolloReactCommon.QueryResult<DocToConsolidateListingQuery, DocToConsolidateListingQueryVariables>;
export const ConsolidatedInvoiceListingDocument = gql`
    query ConsolidatedInvoiceListing($HotelID: String!, $AccountID: String!, $CompanyID: String!, $StartDate: DateTime, $EndDate: DateTime, $DocNo: String, $DocType: String, $Submenu: String) {
  ConsolidatedInvoiceListing(HotelID: $HotelID, AccountID: $AccountID, CompanyID: $CompanyID, StartDate: $StartDate, EndDate: $EndDate, DocNo: $DocNo, DocType: $DocType, Submenu: $Submenu) {
    ID
    DocDate
    DocNo
    Description
    DocAmt
    Module
    einvoice_status
    einvoice_sent_date
    einvoice_trx_type
    CreatedDT
    CreatedBy
    EIConsolidationDetail {
      ID
      RefTable
    }
  }
}
    `;

/**
 * __useConsolidatedInvoiceListingQuery__
 *
 * To run a query within a React component, call `useConsolidatedInvoiceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsolidatedInvoiceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsolidatedInvoiceListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      DocNo: // value for 'DocNo'
 *      DocType: // value for 'DocType'
 *      Submenu: // value for 'Submenu'
 *   },
 * });
 */
export function useConsolidatedInvoiceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConsolidatedInvoiceListingQuery, ConsolidatedInvoiceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ConsolidatedInvoiceListingQuery, ConsolidatedInvoiceListingQueryVariables>(ConsolidatedInvoiceListingDocument, baseOptions);
      }
export function useConsolidatedInvoiceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConsolidatedInvoiceListingQuery, ConsolidatedInvoiceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConsolidatedInvoiceListingQuery, ConsolidatedInvoiceListingQueryVariables>(ConsolidatedInvoiceListingDocument, baseOptions);
        }
export type ConsolidatedInvoiceListingQueryHookResult = ReturnType<typeof useConsolidatedInvoiceListingQuery>;
export type ConsolidatedInvoiceListingLazyQueryHookResult = ReturnType<typeof useConsolidatedInvoiceListingLazyQuery>;
export type ConsolidatedInvoiceListingQueryResult = ApolloReactCommon.QueryResult<ConsolidatedInvoiceListingQuery, ConsolidatedInvoiceListingQueryVariables>;
export const GenerateConsolidatedDocOldDocument = gql`
    mutation GenerateConsolidatedDocOld($TrxDate: DateTime!, $EInvoiceConsolidationInput: EInvoiceConsolidationInput!, $DocType: String, $Year: Float!, $Month: Float!, $Name: String, $Submenu: String, $DocNo: String, $limit: Float) {
  GenerateConsolidatedDocOld(TrxDate: $TrxDate, EInvoiceConsolidationInput: $EInvoiceConsolidationInput, DocType: $DocType, Year: $Year, Month: $Month, Name: $Name, Submenu: $Submenu, DocNo: $DocNo, limit: $limit)
}
    `;
export type GenerateConsolidatedDocOldMutationFn = ApolloReactCommon.MutationFunction<GenerateConsolidatedDocOldMutation, GenerateConsolidatedDocOldMutationVariables>;

/**
 * __useGenerateConsolidatedDocOldMutation__
 *
 * To run a mutation, you first call `useGenerateConsolidatedDocOldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateConsolidatedDocOldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateConsolidatedDocOldMutation, { data, loading, error }] = useGenerateConsolidatedDocOldMutation({
 *   variables: {
 *      TrxDate: // value for 'TrxDate'
 *      EInvoiceConsolidationInput: // value for 'EInvoiceConsolidationInput'
 *      DocType: // value for 'DocType'
 *      Year: // value for 'Year'
 *      Month: // value for 'Month'
 *      Name: // value for 'Name'
 *      Submenu: // value for 'Submenu'
 *      DocNo: // value for 'DocNo'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGenerateConsolidatedDocOldMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateConsolidatedDocOldMutation, GenerateConsolidatedDocOldMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateConsolidatedDocOldMutation, GenerateConsolidatedDocOldMutationVariables>(GenerateConsolidatedDocOldDocument, baseOptions);
      }
export type GenerateConsolidatedDocOldMutationHookResult = ReturnType<typeof useGenerateConsolidatedDocOldMutation>;
export type GenerateConsolidatedDocOldMutationResult = ApolloReactCommon.MutationResult<GenerateConsolidatedDocOldMutation>;
export type GenerateConsolidatedDocOldMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateConsolidatedDocOldMutation, GenerateConsolidatedDocOldMutationVariables>;
export const DocToConsolidateViewDocument = gql`
    query DocToConsolidateView($HotelID: String!, $AccountID: String!, $CompanyID: String!, $ConsolidationID: String!, $Submenu: String, $DocType: String!, $limit: Float, $offset: Float) {
  DocToConsolidateView(HotelID: $HotelID, AccountID: $AccountID, CompanyID: $CompanyID, ConsolidationID: $ConsolidationID, Submenu: $Submenu, DocType: $DocType, limit: $limit, offset: $offset)
}
    `;

/**
 * __useDocToConsolidateViewQuery__
 *
 * To run a query within a React component, call `useDocToConsolidateViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocToConsolidateViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocToConsolidateViewQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      ConsolidationID: // value for 'ConsolidationID'
 *      Submenu: // value for 'Submenu'
 *      DocType: // value for 'DocType'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useDocToConsolidateViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocToConsolidateViewQuery, DocToConsolidateViewQueryVariables>) {
        return ApolloReactHooks.useQuery<DocToConsolidateViewQuery, DocToConsolidateViewQueryVariables>(DocToConsolidateViewDocument, baseOptions);
      }
export function useDocToConsolidateViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocToConsolidateViewQuery, DocToConsolidateViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocToConsolidateViewQuery, DocToConsolidateViewQueryVariables>(DocToConsolidateViewDocument, baseOptions);
        }
export type DocToConsolidateViewQueryHookResult = ReturnType<typeof useDocToConsolidateViewQuery>;
export type DocToConsolidateViewLazyQueryHookResult = ReturnType<typeof useDocToConsolidateViewLazyQuery>;
export type DocToConsolidateViewQueryResult = ApolloReactCommon.QueryResult<DocToConsolidateViewQuery, DocToConsolidateViewQueryVariables>;
export const CorporateGovernmentListingDocument = gql`
    query CorporateGovernmentListing($limit: Float, $offset: Float, $SalesChannel: String, $IsActive: Boolean, $DebtorAccount: String, $Name: String, $Adjusted: Boolean, $DebtorCategory: String!, $HotelID: String!) {
  CorporateGovernmentListing(limit: $limit, offset: $offset, SalesChannel: $SalesChannel, IsActive: $IsActive, DebtorAccount: $DebtorAccount, Name: $Name, Adjusted: $Adjusted, DebtorCategory: $DebtorCategory, HotelID: $HotelID) {
    ID
    Name
    IsActive
    IsIndividual
    Contact {
      ID
      Email
      PhoneNo
      MobileNo
      AccountID
      Address {
        address
        postCode
        state
        city
        country
      }
    }
    DebtorContact {
      ID
      DebtorID
      ContactID
      Contact {
        ID
        FullName
        Designation
        MobileNo
        Email
        IsActive
      }
    }
    DebtorCategory
    DebtorTypeID
    DebtorAccount
    CreditLimit
    Login
    Password
    Segment
    SalesChannel
    BillingInstruction
    IsFrontDeskActive
    CompanyRegNo
    Tin
    TaxRegNo
    InvoiceType
  }
}
    `;

/**
 * __useCorporateGovernmentListingQuery__
 *
 * To run a query within a React component, call `useCorporateGovernmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateGovernmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateGovernmentListingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      SalesChannel: // value for 'SalesChannel'
 *      IsActive: // value for 'IsActive'
 *      DebtorAccount: // value for 'DebtorAccount'
 *      Name: // value for 'Name'
 *      Adjusted: // value for 'Adjusted'
 *      DebtorCategory: // value for 'DebtorCategory'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCorporateGovernmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CorporateGovernmentListingQuery, CorporateGovernmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CorporateGovernmentListingQuery, CorporateGovernmentListingQueryVariables>(CorporateGovernmentListingDocument, baseOptions);
      }
export function useCorporateGovernmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CorporateGovernmentListingQuery, CorporateGovernmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CorporateGovernmentListingQuery, CorporateGovernmentListingQueryVariables>(CorporateGovernmentListingDocument, baseOptions);
        }
export type CorporateGovernmentListingQueryHookResult = ReturnType<typeof useCorporateGovernmentListingQuery>;
export type CorporateGovernmentListingLazyQueryHookResult = ReturnType<typeof useCorporateGovernmentListingLazyQuery>;
export type CorporateGovernmentListingQueryResult = ApolloReactCommon.QueryResult<CorporateGovernmentListingQuery, CorporateGovernmentListingQueryVariables>;
export const GovernmentListingDocument = gql`
    query GovernmentListing($DebtorCategory: String!, $HotelID: String!) {
  GovernmentListing(DebtorCategory: $DebtorCategory, HotelID: $HotelID) {
    ID
    Name
    IsActive
    Contact {
      ID
      Email
      PhoneNo
      AccountID
      Address {
        address
        postCode
        state
        city
        country
      }
    }
  }
}
    `;

/**
 * __useGovernmentListingQuery__
 *
 * To run a query within a React component, call `useGovernmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGovernmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGovernmentListingQuery({
 *   variables: {
 *      DebtorCategory: // value for 'DebtorCategory'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useGovernmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GovernmentListingQuery, GovernmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GovernmentListingQuery, GovernmentListingQueryVariables>(GovernmentListingDocument, baseOptions);
      }
export function useGovernmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GovernmentListingQuery, GovernmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GovernmentListingQuery, GovernmentListingQueryVariables>(GovernmentListingDocument, baseOptions);
        }
export type GovernmentListingQueryHookResult = ReturnType<typeof useGovernmentListingQuery>;
export type GovernmentListingLazyQueryHookResult = ReturnType<typeof useGovernmentListingLazyQuery>;
export type GovernmentListingQueryResult = ApolloReactCommon.QueryResult<GovernmentListingQuery, GovernmentListingQueryVariables>;
export const CorporateGovernmentDetailDocument = gql`
    query CorporateGovernmentDetail($HotelID: String!, $ID: String!) {
  CorporateGovernmentDetail(HotelID: $HotelID, ID: $ID) {
    ID
    Name
    LastBooking
    IsIndividual
    Booking {
      ID
    }
    Contact {
      ID
      Email
      PhoneNo
      MobileNo
      AccountID
      Address {
        address
        postCode
        state
        city
        country
      }
    }
    Contract {
      IsActive
      ID
    }
    DebtorContact {
      ID
      DebtorID
      ContactID
      Contact {
        ID
        AccountID
        FullName
        Designation
        MobileNo
        Email
        IsActive
      }
    }
    DebtorCategory
    DebtorTypeID
    DebtorAccount
    CreditLimit
    Login
    Password
    Segment
    Remarks
    SalesChannel
    BillingInstruction
    CompanyRegNo
    TaxRegNo
    Tin
    InvoiceType
  }
}
    `;

/**
 * __useCorporateGovernmentDetailQuery__
 *
 * To run a query within a React component, call `useCorporateGovernmentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateGovernmentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateGovernmentDetailQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCorporateGovernmentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CorporateGovernmentDetailQuery, CorporateGovernmentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<CorporateGovernmentDetailQuery, CorporateGovernmentDetailQueryVariables>(CorporateGovernmentDetailDocument, baseOptions);
      }
export function useCorporateGovernmentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CorporateGovernmentDetailQuery, CorporateGovernmentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CorporateGovernmentDetailQuery, CorporateGovernmentDetailQueryVariables>(CorporateGovernmentDetailDocument, baseOptions);
        }
export type CorporateGovernmentDetailQueryHookResult = ReturnType<typeof useCorporateGovernmentDetailQuery>;
export type CorporateGovernmentDetailLazyQueryHookResult = ReturnType<typeof useCorporateGovernmentDetailLazyQuery>;
export type CorporateGovernmentDetailQueryResult = ApolloReactCommon.QueryResult<CorporateGovernmentDetailQuery, CorporateGovernmentDetailQueryVariables>;
export const CorporateContractListingDocument = gql`
    query CorporateContractListing($DebtorID: String!) {
  CorporateContractListing(DebtorID: $DebtorID) {
    ID
    DebtorID
    ContractNo
    Description
    StartDate
    EndDate
    RoomUpgrade
    Elastic
    Allotment
    CutOffDays
    PaymentTerms
    AllowDeleteContract
    ModifiedDT
    Remarks
    IsActive
    Debtor {
      ID
      Name
    }
    ContractRate {
      ID
      IsActive
      ContractID
      RatePolicyID
      RatePolicy {
        ID
        RateCode
        Description
      }
    }
  }
}
    `;

/**
 * __useCorporateContractListingQuery__
 *
 * To run a query within a React component, call `useCorporateContractListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateContractListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateContractListingQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useCorporateContractListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CorporateContractListingQuery, CorporateContractListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CorporateContractListingQuery, CorporateContractListingQueryVariables>(CorporateContractListingDocument, baseOptions);
      }
export function useCorporateContractListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CorporateContractListingQuery, CorporateContractListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CorporateContractListingQuery, CorporateContractListingQueryVariables>(CorporateContractListingDocument, baseOptions);
        }
export type CorporateContractListingQueryHookResult = ReturnType<typeof useCorporateContractListingQuery>;
export type CorporateContractListingLazyQueryHookResult = ReturnType<typeof useCorporateContractListingLazyQuery>;
export type CorporateContractListingQueryResult = ApolloReactCommon.QueryResult<CorporateContractListingQuery, CorporateContractListingQueryVariables>;
export const CorporateBookingListingDocument = gql`
    query CorporateBookingListing($DebtorID: String!, $HotelID: String!) {
  CorporateBookingListing(DebtorID: $DebtorID, HotelID: $HotelID) {
    ID
    AccountID
    HotelID
    TotalAdvPayment
    Hotel {
      HotelName
      HotelAddress {
        address
        address2
        siteName
        country
        city
        state
        postCode
      }
    }
    Contact {
      FullName
      PhoneNo
      Email
      Nationality
      ID
      MobileNo
      PassportNo
      NRIC
    }
    Debtor {
      DebtorCategory
      ID
      Name
    }
    CreatedDT
    BookingNo
    ArrivalDate
    DepartureDate
    ContactID
    Contact {
      ID
      FullName
      Email
      MobileNo
      PhoneNo
      PassportNo
      NRIC
    }
    GroupName
    SalesChannelID
    SalesChannel {
      ID
      Description
    }
    Source
    SegmentID
    Segment {
      ID
      Description
    }
    SpecialRequest
    DebtorAccount
    BillingInstruction
    Reason
    BookingStatus
    BookedRegistration {
      ID
      RoomID
      CheckInDate
      SharerNo
      CheckOutDate
      PromoCode
      NoOfAdult
      NoOfChild
      NoOfInfant
      IsPrincipal
      Signature
      SpecialRequest
      RegistrationStatus
      RoomTypeID
      RatePolicyID
      RoomType {
        Code
        ID
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      Guest {
        ID
        VehicleNo
        Contact {
          ID
          FullName
          Email
          NRIC
          PhoneNo
          MobileNo
        }
      }
      RoomRate
      TotalRoomPrice
      TotalRoomTax
      TotalPackagePrice
      TotalPrice
    }
  }
}
    `;

/**
 * __useCorporateBookingListingQuery__
 *
 * To run a query within a React component, call `useCorporateBookingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporateBookingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporateBookingListingQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCorporateBookingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CorporateBookingListingQuery, CorporateBookingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CorporateBookingListingQuery, CorporateBookingListingQueryVariables>(CorporateBookingListingDocument, baseOptions);
      }
export function useCorporateBookingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CorporateBookingListingQuery, CorporateBookingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CorporateBookingListingQuery, CorporateBookingListingQueryVariables>(CorporateBookingListingDocument, baseOptions);
        }
export type CorporateBookingListingQueryHookResult = ReturnType<typeof useCorporateBookingListingQuery>;
export type CorporateBookingListingLazyQueryHookResult = ReturnType<typeof useCorporateBookingListingLazyQuery>;
export type CorporateBookingListingQueryResult = ApolloReactCommon.QueryResult<CorporateBookingListingQuery, CorporateBookingListingQueryVariables>;
export const CorporateContractUpdateDocument = gql`
    mutation CorporateContractUpdate($ContractInput: ContractInput!, $RatePolicyIDs: [String!]) {
  CorporateContractUpdate(ContractInput: $ContractInput, RatePolicyIDs: $RatePolicyIDs)
}
    `;
export type CorporateContractUpdateMutationFn = ApolloReactCommon.MutationFunction<CorporateContractUpdateMutation, CorporateContractUpdateMutationVariables>;

/**
 * __useCorporateContractUpdateMutation__
 *
 * To run a mutation, you first call `useCorporateContractUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCorporateContractUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [corporateContractUpdateMutation, { data, loading, error }] = useCorporateContractUpdateMutation({
 *   variables: {
 *      ContractInput: // value for 'ContractInput'
 *      RatePolicyIDs: // value for 'RatePolicyIDs'
 *   },
 * });
 */
export function useCorporateContractUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CorporateContractUpdateMutation, CorporateContractUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CorporateContractUpdateMutation, CorporateContractUpdateMutationVariables>(CorporateContractUpdateDocument, baseOptions);
      }
export type CorporateContractUpdateMutationHookResult = ReturnType<typeof useCorporateContractUpdateMutation>;
export type CorporateContractUpdateMutationResult = ApolloReactCommon.MutationResult<CorporateContractUpdateMutation>;
export type CorporateContractUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CorporateContractUpdateMutation, CorporateContractUpdateMutationVariables>;
export const CorporateContractInsertDocument = gql`
    mutation CorporateContractInsert($ContractInput: ContractInput!, $RatePolicyIDs: [String!]!) {
  CorporateContractInsert(ContractInput: $ContractInput, RatePolicyIDs: $RatePolicyIDs) {
    ID
  }
}
    `;
export type CorporateContractInsertMutationFn = ApolloReactCommon.MutationFunction<CorporateContractInsertMutation, CorporateContractInsertMutationVariables>;

/**
 * __useCorporateContractInsertMutation__
 *
 * To run a mutation, you first call `useCorporateContractInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCorporateContractInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [corporateContractInsertMutation, { data, loading, error }] = useCorporateContractInsertMutation({
 *   variables: {
 *      ContractInput: // value for 'ContractInput'
 *      RatePolicyIDs: // value for 'RatePolicyIDs'
 *   },
 * });
 */
export function useCorporateContractInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CorporateContractInsertMutation, CorporateContractInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CorporateContractInsertMutation, CorporateContractInsertMutationVariables>(CorporateContractInsertDocument, baseOptions);
      }
export type CorporateContractInsertMutationHookResult = ReturnType<typeof useCorporateContractInsertMutation>;
export type CorporateContractInsertMutationResult = ApolloReactCommon.MutationResult<CorporateContractInsertMutation>;
export type CorporateContractInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CorporateContractInsertMutation, CorporateContractInsertMutationVariables>;
export const CorporateUpdateDocument = gql`
    mutation CorporateUpdate($ContactInput: ContactInput!, $DebtorInput: DebtorInput!, $DebtorContactInput: [ContactInput!]) {
  CorporateUpdate(ContactInput: $ContactInput, DebtorInput: $DebtorInput, DebtorContactInput: $DebtorContactInput)
}
    `;
export type CorporateUpdateMutationFn = ApolloReactCommon.MutationFunction<CorporateUpdateMutation, CorporateUpdateMutationVariables>;

/**
 * __useCorporateUpdateMutation__
 *
 * To run a mutation, you first call `useCorporateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCorporateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [corporateUpdateMutation, { data, loading, error }] = useCorporateUpdateMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      DebtorInput: // value for 'DebtorInput'
 *      DebtorContactInput: // value for 'DebtorContactInput'
 *   },
 * });
 */
export function useCorporateUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CorporateUpdateMutation, CorporateUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<CorporateUpdateMutation, CorporateUpdateMutationVariables>(CorporateUpdateDocument, baseOptions);
      }
export type CorporateUpdateMutationHookResult = ReturnType<typeof useCorporateUpdateMutation>;
export type CorporateUpdateMutationResult = ApolloReactCommon.MutationResult<CorporateUpdateMutation>;
export type CorporateUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<CorporateUpdateMutation, CorporateUpdateMutationVariables>;
export const GovermentTestingUpdateDocument = gql`
    mutation GovermentTestingUpdate($DebtorInput: DebtorInput!) {
  GovermentTestingUpdate(DebtorInput: $DebtorInput)
}
    `;
export type GovermentTestingUpdateMutationFn = ApolloReactCommon.MutationFunction<GovermentTestingUpdateMutation, GovermentTestingUpdateMutationVariables>;

/**
 * __useGovermentTestingUpdateMutation__
 *
 * To run a mutation, you first call `useGovermentTestingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGovermentTestingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [govermentTestingUpdateMutation, { data, loading, error }] = useGovermentTestingUpdateMutation({
 *   variables: {
 *      DebtorInput: // value for 'DebtorInput'
 *   },
 * });
 */
export function useGovermentTestingUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GovermentTestingUpdateMutation, GovermentTestingUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<GovermentTestingUpdateMutation, GovermentTestingUpdateMutationVariables>(GovermentTestingUpdateDocument, baseOptions);
      }
export type GovermentTestingUpdateMutationHookResult = ReturnType<typeof useGovermentTestingUpdateMutation>;
export type GovermentTestingUpdateMutationResult = ApolloReactCommon.MutationResult<GovermentTestingUpdateMutation>;
export type GovermentTestingUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<GovermentTestingUpdateMutation, GovermentTestingUpdateMutationVariables>;
export const CorporateInsertDocument = gql`
    mutation CorporateInsert($ContactInput: ContactInput!, $DebtorInput: DebtorInput!, $DebtorContactInput: [ContactInput!]!) {
  CorporateInsert(ContactInput: $ContactInput, DebtorInput: $DebtorInput, DebtorContactInput: $DebtorContactInput) {
    ID
  }
}
    `;
export type CorporateInsertMutationFn = ApolloReactCommon.MutationFunction<CorporateInsertMutation, CorporateInsertMutationVariables>;

/**
 * __useCorporateInsertMutation__
 *
 * To run a mutation, you first call `useCorporateInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCorporateInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [corporateInsertMutation, { data, loading, error }] = useCorporateInsertMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      DebtorInput: // value for 'DebtorInput'
 *      DebtorContactInput: // value for 'DebtorContactInput'
 *   },
 * });
 */
export function useCorporateInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CorporateInsertMutation, CorporateInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CorporateInsertMutation, CorporateInsertMutationVariables>(CorporateInsertDocument, baseOptions);
      }
export type CorporateInsertMutationHookResult = ReturnType<typeof useCorporateInsertMutation>;
export type CorporateInsertMutationResult = ApolloReactCommon.MutationResult<CorporateInsertMutation>;
export type CorporateInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CorporateInsertMutation, CorporateInsertMutationVariables>;
export const CorporateDeleteDocument = gql`
    mutation CorporateDelete($DebtorID: String!) {
  CorporateDelete(DebtorID: $DebtorID)
}
    `;
export type CorporateDeleteMutationFn = ApolloReactCommon.MutationFunction<CorporateDeleteMutation, CorporateDeleteMutationVariables>;

/**
 * __useCorporateDeleteMutation__
 *
 * To run a mutation, you first call `useCorporateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCorporateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [corporateDeleteMutation, { data, loading, error }] = useCorporateDeleteMutation({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useCorporateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CorporateDeleteMutation, CorporateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CorporateDeleteMutation, CorporateDeleteMutationVariables>(CorporateDeleteDocument, baseOptions);
      }
export type CorporateDeleteMutationHookResult = ReturnType<typeof useCorporateDeleteMutation>;
export type CorporateDeleteMutationResult = ApolloReactCommon.MutationResult<CorporateDeleteMutation>;
export type CorporateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CorporateDeleteMutation, CorporateDeleteMutationVariables>;
export const CoporateInUseListingDocument = gql`
    query CoporateInUseListing($DebtorID: String!, $HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  CoporateInUseListing(DebtorID: $DebtorID, HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useCoporateInUseListingQuery__
 *
 * To run a query within a React component, call `useCoporateInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoporateInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoporateInUseListingQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useCoporateInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CoporateInUseListingQuery, CoporateInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CoporateInUseListingQuery, CoporateInUseListingQueryVariables>(CoporateInUseListingDocument, baseOptions);
      }
export function useCoporateInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CoporateInUseListingQuery, CoporateInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CoporateInUseListingQuery, CoporateInUseListingQueryVariables>(CoporateInUseListingDocument, baseOptions);
        }
export type CoporateInUseListingQueryHookResult = ReturnType<typeof useCoporateInUseListingQuery>;
export type CoporateInUseListingLazyQueryHookResult = ReturnType<typeof useCoporateInUseListingLazyQuery>;
export type CoporateInUseListingQueryResult = ApolloReactCommon.QueryResult<CoporateInUseListingQuery, CoporateInUseListingQueryVariables>;
export const DebtorListingDropDownDocument = gql`
    query DebtorListingDropDown($HotelID: String!) {
  DebtorListingDropDown(HotelID: $HotelID) {
    ID
    Name
    DebtorAccount
    DebtorCategory
    IsActive
  }
}
    `;

/**
 * __useDebtorListingDropDownQuery__
 *
 * To run a query within a React component, call `useDebtorListingDropDownQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorListingDropDownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorListingDropDownQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDebtorListingDropDownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorListingDropDownQuery, DebtorListingDropDownQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorListingDropDownQuery, DebtorListingDropDownQueryVariables>(DebtorListingDropDownDocument, baseOptions);
      }
export function useDebtorListingDropDownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorListingDropDownQuery, DebtorListingDropDownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorListingDropDownQuery, DebtorListingDropDownQueryVariables>(DebtorListingDropDownDocument, baseOptions);
        }
export type DebtorListingDropDownQueryHookResult = ReturnType<typeof useDebtorListingDropDownQuery>;
export type DebtorListingDropDownLazyQueryHookResult = ReturnType<typeof useDebtorListingDropDownLazyQuery>;
export type DebtorListingDropDownQueryResult = ApolloReactCommon.QueryResult<DebtorListingDropDownQuery, DebtorListingDropDownQueryVariables>;
export const GuestReviewInfoLinesDocument = gql`
    query GuestReviewInfoLines($HotelID: String!) {
  GuestReviewInfoLines(HotelID: $HotelID) {
    InActivated
    LessThan3Star
    MoreThan3Star
  }
}
    `;

/**
 * __useGuestReviewInfoLinesQuery__
 *
 * To run a query within a React component, call `useGuestReviewInfoLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestReviewInfoLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestReviewInfoLinesQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useGuestReviewInfoLinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestReviewInfoLinesQuery, GuestReviewInfoLinesQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestReviewInfoLinesQuery, GuestReviewInfoLinesQueryVariables>(GuestReviewInfoLinesDocument, baseOptions);
      }
export function useGuestReviewInfoLinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestReviewInfoLinesQuery, GuestReviewInfoLinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestReviewInfoLinesQuery, GuestReviewInfoLinesQueryVariables>(GuestReviewInfoLinesDocument, baseOptions);
        }
export type GuestReviewInfoLinesQueryHookResult = ReturnType<typeof useGuestReviewInfoLinesQuery>;
export type GuestReviewInfoLinesLazyQueryHookResult = ReturnType<typeof useGuestReviewInfoLinesLazyQuery>;
export type GuestReviewInfoLinesQueryResult = ApolloReactCommon.QueryResult<GuestReviewInfoLinesQuery, GuestReviewInfoLinesQueryVariables>;
export const GuestReviewListingDocument = gql`
    query GuestReviewListing($HotelID: String!, $Rating: Float, $IsActive: Boolean, $EndDate: DateTime, $StartDate: DateTime) {
  GuestReviewListing(HotelID: $HotelID, Rating: $Rating, IsActive: $IsActive, EndDate: $EndDate, StartDate: $StartDate) {
    ID
    IsActive
    CreatedDT
    Cleanliness
    Facilities
    Location
    ComfortQuality
    Service
    OverallExperience
    Comments
    ReviewCount
    Booking {
      ID
      BookingNo
      Contact {
        FullName
      }
      BookedRegistration {
        Room {
          RoomNo
        }
      }
      Registration {
        Guest {
          GuestType
        }
      }
    }
  }
}
    `;

/**
 * __useGuestReviewListingQuery__
 *
 * To run a query within a React component, call `useGuestReviewListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestReviewListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestReviewListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      Rating: // value for 'Rating'
 *      IsActive: // value for 'IsActive'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useGuestReviewListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestReviewListingQuery, GuestReviewListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestReviewListingQuery, GuestReviewListingQueryVariables>(GuestReviewListingDocument, baseOptions);
      }
export function useGuestReviewListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestReviewListingQuery, GuestReviewListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestReviewListingQuery, GuestReviewListingQueryVariables>(GuestReviewListingDocument, baseOptions);
        }
export type GuestReviewListingQueryHookResult = ReturnType<typeof useGuestReviewListingQuery>;
export type GuestReviewListingLazyQueryHookResult = ReturnType<typeof useGuestReviewListingLazyQuery>;
export type GuestReviewListingQueryResult = ApolloReactCommon.QueryResult<GuestReviewListingQuery, GuestReviewListingQueryVariables>;
export const GuestReviewActiveUpdateDocument = gql`
    mutation GuestReviewActiveUpdate($HotelRatingInput: HotelRatingInput!) {
  GuestReviewActiveUpdate(HotelRatingInput: $HotelRatingInput)
}
    `;
export type GuestReviewActiveUpdateMutationFn = ApolloReactCommon.MutationFunction<GuestReviewActiveUpdateMutation, GuestReviewActiveUpdateMutationVariables>;

/**
 * __useGuestReviewActiveUpdateMutation__
 *
 * To run a mutation, you first call `useGuestReviewActiveUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestReviewActiveUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestReviewActiveUpdateMutation, { data, loading, error }] = useGuestReviewActiveUpdateMutation({
 *   variables: {
 *      HotelRatingInput: // value for 'HotelRatingInput'
 *   },
 * });
 */
export function useGuestReviewActiveUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestReviewActiveUpdateMutation, GuestReviewActiveUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestReviewActiveUpdateMutation, GuestReviewActiveUpdateMutationVariables>(GuestReviewActiveUpdateDocument, baseOptions);
      }
export type GuestReviewActiveUpdateMutationHookResult = ReturnType<typeof useGuestReviewActiveUpdateMutation>;
export type GuestReviewActiveUpdateMutationResult = ApolloReactCommon.MutationResult<GuestReviewActiveUpdateMutation>;
export type GuestReviewActiveUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestReviewActiveUpdateMutation, GuestReviewActiveUpdateMutationVariables>;
export const PromotionListingDocument = gql`
    query PromotionListing($HotelID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  PromotionListing(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    Code
    Description
    DiscountType
    DiscountValue
    StartDate
    EndDate
    IsActive
    PromotionDetail {
      ID
      RatePolicy {
        ID
        RateCode
        Description
        IsActive
      }
      PromotionID
    }
  }
}
    `;

/**
 * __usePromotionListingQuery__
 *
 * To run a query within a React component, call `usePromotionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function usePromotionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PromotionListingQuery, PromotionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PromotionListingQuery, PromotionListingQueryVariables>(PromotionListingDocument, baseOptions);
      }
export function usePromotionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PromotionListingQuery, PromotionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PromotionListingQuery, PromotionListingQueryVariables>(PromotionListingDocument, baseOptions);
        }
export type PromotionListingQueryHookResult = ReturnType<typeof usePromotionListingQuery>;
export type PromotionListingLazyQueryHookResult = ReturnType<typeof usePromotionListingLazyQuery>;
export type PromotionListingQueryResult = ApolloReactCommon.QueryResult<PromotionListingQuery, PromotionListingQueryVariables>;
export const PromotionDetailListingDocument = gql`
    query PromotionDetailListing($PromotionID: String!) {
  PromotionDetailListing(PromotionID: $PromotionID) {
    ID
    RatePolicy {
      ID
      RateCode
      Description
      IsActive
    }
  }
}
    `;

/**
 * __usePromotionDetailListingQuery__
 *
 * To run a query within a React component, call `usePromotionDetailListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionDetailListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionDetailListingQuery({
 *   variables: {
 *      PromotionID: // value for 'PromotionID'
 *   },
 * });
 */
export function usePromotionDetailListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PromotionDetailListingQuery, PromotionDetailListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PromotionDetailListingQuery, PromotionDetailListingQueryVariables>(PromotionDetailListingDocument, baseOptions);
      }
export function usePromotionDetailListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PromotionDetailListingQuery, PromotionDetailListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PromotionDetailListingQuery, PromotionDetailListingQueryVariables>(PromotionDetailListingDocument, baseOptions);
        }
export type PromotionDetailListingQueryHookResult = ReturnType<typeof usePromotionDetailListingQuery>;
export type PromotionDetailListingLazyQueryHookResult = ReturnType<typeof usePromotionDetailListingLazyQuery>;
export type PromotionDetailListingQueryResult = ApolloReactCommon.QueryResult<PromotionDetailListingQuery, PromotionDetailListingQueryVariables>;
export const PromotionInUseListingDocument = gql`
    query PromotionInUseListing($ID: String!, $PromoCode: String!, $Date: DateTime!) {
  PromotionInUseListing(ID: $ID, PromoCode: $PromoCode, Date: $Date)
}
    `;

/**
 * __usePromotionInUseListingQuery__
 *
 * To run a query within a React component, call `usePromotionInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionInUseListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      PromoCode: // value for 'PromoCode'
 *      Date: // value for 'Date'
 *   },
 * });
 */
export function usePromotionInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PromotionInUseListingQuery, PromotionInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PromotionInUseListingQuery, PromotionInUseListingQueryVariables>(PromotionInUseListingDocument, baseOptions);
      }
export function usePromotionInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PromotionInUseListingQuery, PromotionInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PromotionInUseListingQuery, PromotionInUseListingQueryVariables>(PromotionInUseListingDocument, baseOptions);
        }
export type PromotionInUseListingQueryHookResult = ReturnType<typeof usePromotionInUseListingQuery>;
export type PromotionInUseListingLazyQueryHookResult = ReturnType<typeof usePromotionInUseListingLazyQuery>;
export type PromotionInUseListingQueryResult = ApolloReactCommon.QueryResult<PromotionInUseListingQuery, PromotionInUseListingQueryVariables>;
export const PromotionDetailsInUseListingDocument = gql`
    query PromotionDetailsInUseListing($RatePolicyID: String!, $PromoCode: String!) {
  PromotionDetailsInUseListing(RatePolicyID: $RatePolicyID, PromoCode: $PromoCode)
}
    `;

/**
 * __usePromotionDetailsInUseListingQuery__
 *
 * To run a query within a React component, call `usePromotionDetailsInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionDetailsInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionDetailsInUseListingQuery({
 *   variables: {
 *      RatePolicyID: // value for 'RatePolicyID'
 *      PromoCode: // value for 'PromoCode'
 *   },
 * });
 */
export function usePromotionDetailsInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PromotionDetailsInUseListingQuery, PromotionDetailsInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PromotionDetailsInUseListingQuery, PromotionDetailsInUseListingQueryVariables>(PromotionDetailsInUseListingDocument, baseOptions);
      }
export function usePromotionDetailsInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PromotionDetailsInUseListingQuery, PromotionDetailsInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PromotionDetailsInUseListingQuery, PromotionDetailsInUseListingQueryVariables>(PromotionDetailsInUseListingDocument, baseOptions);
        }
export type PromotionDetailsInUseListingQueryHookResult = ReturnType<typeof usePromotionDetailsInUseListingQuery>;
export type PromotionDetailsInUseListingLazyQueryHookResult = ReturnType<typeof usePromotionDetailsInUseListingLazyQuery>;
export type PromotionDetailsInUseListingQueryResult = ApolloReactCommon.QueryResult<PromotionDetailsInUseListingQuery, PromotionDetailsInUseListingQueryVariables>;
export const PromotionUpdateDocument = gql`
    mutation PromotionUpdate($PromotionInput: PromotionInput!, $PromotionDetailInput: [PromotionDetailInput!]!) {
  PromotionUpdate(PromotionInput: $PromotionInput, PromotionDetailInput: $PromotionDetailInput)
}
    `;
export type PromotionUpdateMutationFn = ApolloReactCommon.MutationFunction<PromotionUpdateMutation, PromotionUpdateMutationVariables>;

/**
 * __usePromotionUpdateMutation__
 *
 * To run a mutation, you first call `usePromotionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromotionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promotionUpdateMutation, { data, loading, error }] = usePromotionUpdateMutation({
 *   variables: {
 *      PromotionInput: // value for 'PromotionInput'
 *      PromotionDetailInput: // value for 'PromotionDetailInput'
 *   },
 * });
 */
export function usePromotionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PromotionUpdateMutation, PromotionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PromotionUpdateMutation, PromotionUpdateMutationVariables>(PromotionUpdateDocument, baseOptions);
      }
export type PromotionUpdateMutationHookResult = ReturnType<typeof usePromotionUpdateMutation>;
export type PromotionUpdateMutationResult = ApolloReactCommon.MutationResult<PromotionUpdateMutation>;
export type PromotionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PromotionUpdateMutation, PromotionUpdateMutationVariables>;
export const PromotionActiveUpdateDocument = gql`
    mutation PromotionActiveUpdate($PromotionInput: PromotionInput!) {
  PromotionActiveUpdate(PromotionInput: $PromotionInput)
}
    `;
export type PromotionActiveUpdateMutationFn = ApolloReactCommon.MutationFunction<PromotionActiveUpdateMutation, PromotionActiveUpdateMutationVariables>;

/**
 * __usePromotionActiveUpdateMutation__
 *
 * To run a mutation, you first call `usePromotionActiveUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromotionActiveUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promotionActiveUpdateMutation, { data, loading, error }] = usePromotionActiveUpdateMutation({
 *   variables: {
 *      PromotionInput: // value for 'PromotionInput'
 *   },
 * });
 */
export function usePromotionActiveUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PromotionActiveUpdateMutation, PromotionActiveUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PromotionActiveUpdateMutation, PromotionActiveUpdateMutationVariables>(PromotionActiveUpdateDocument, baseOptions);
      }
export type PromotionActiveUpdateMutationHookResult = ReturnType<typeof usePromotionActiveUpdateMutation>;
export type PromotionActiveUpdateMutationResult = ApolloReactCommon.MutationResult<PromotionActiveUpdateMutation>;
export type PromotionActiveUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PromotionActiveUpdateMutation, PromotionActiveUpdateMutationVariables>;
export const PromotionInsertDocument = gql`
    mutation PromotionInsert($PromotionInput: PromotionInput!, $PromotionDetailInput: [PromotionDetailInput!]!) {
  PromotionInsert(PromotionInput: $PromotionInput, PromotionDetailInput: $PromotionDetailInput) {
    ID
    Description
    Code
  }
}
    `;
export type PromotionInsertMutationFn = ApolloReactCommon.MutationFunction<PromotionInsertMutation, PromotionInsertMutationVariables>;

/**
 * __usePromotionInsertMutation__
 *
 * To run a mutation, you first call `usePromotionInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromotionInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promotionInsertMutation, { data, loading, error }] = usePromotionInsertMutation({
 *   variables: {
 *      PromotionInput: // value for 'PromotionInput'
 *      PromotionDetailInput: // value for 'PromotionDetailInput'
 *   },
 * });
 */
export function usePromotionInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PromotionInsertMutation, PromotionInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PromotionInsertMutation, PromotionInsertMutationVariables>(PromotionInsertDocument, baseOptions);
      }
export type PromotionInsertMutationHookResult = ReturnType<typeof usePromotionInsertMutation>;
export type PromotionInsertMutationResult = ApolloReactCommon.MutationResult<PromotionInsertMutation>;
export type PromotionInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PromotionInsertMutation, PromotionInsertMutationVariables>;
export const EmailCampignInsertDocument = gql`
    mutation EmailCampignInsert($EmailCampignInput: EmailCampignInput!) {
  EmailCampignInsert(EmailCampignInput: $EmailCampignInput) {
    ID
  }
}
    `;
export type EmailCampignInsertMutationFn = ApolloReactCommon.MutationFunction<EmailCampignInsertMutation, EmailCampignInsertMutationVariables>;

/**
 * __useEmailCampignInsertMutation__
 *
 * To run a mutation, you first call `useEmailCampignInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailCampignInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailCampignInsertMutation, { data, loading, error }] = useEmailCampignInsertMutation({
 *   variables: {
 *      EmailCampignInput: // value for 'EmailCampignInput'
 *   },
 * });
 */
export function useEmailCampignInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmailCampignInsertMutation, EmailCampignInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EmailCampignInsertMutation, EmailCampignInsertMutationVariables>(EmailCampignInsertDocument, baseOptions);
      }
export type EmailCampignInsertMutationHookResult = ReturnType<typeof useEmailCampignInsertMutation>;
export type EmailCampignInsertMutationResult = ApolloReactCommon.MutationResult<EmailCampignInsertMutation>;
export type EmailCampignInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EmailCampignInsertMutation, EmailCampignInsertMutationVariables>;
export const EmailCampignUpdateDocument = gql`
    mutation EmailCampignUpdate($EmailCampignInput: EmailCampignInput!, $OldGalleryID: [String!]) {
  EmailCampignUpdate(EmailCampignInput: $EmailCampignInput, OldGalleryID: $OldGalleryID)
}
    `;
export type EmailCampignUpdateMutationFn = ApolloReactCommon.MutationFunction<EmailCampignUpdateMutation, EmailCampignUpdateMutationVariables>;

/**
 * __useEmailCampignUpdateMutation__
 *
 * To run a mutation, you first call `useEmailCampignUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailCampignUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailCampignUpdateMutation, { data, loading, error }] = useEmailCampignUpdateMutation({
 *   variables: {
 *      EmailCampignInput: // value for 'EmailCampignInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useEmailCampignUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmailCampignUpdateMutation, EmailCampignUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EmailCampignUpdateMutation, EmailCampignUpdateMutationVariables>(EmailCampignUpdateDocument, baseOptions);
      }
export type EmailCampignUpdateMutationHookResult = ReturnType<typeof useEmailCampignUpdateMutation>;
export type EmailCampignUpdateMutationResult = ApolloReactCommon.MutationResult<EmailCampignUpdateMutation>;
export type EmailCampignUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EmailCampignUpdateMutation, EmailCampignUpdateMutationVariables>;
export const EmailMarketingListingDocument = gql`
    query EmailMarketingListing($HotelID: String!) {
  EmailMarketingListing(HotelID: $HotelID) {
    ID
    Title
    Description
    CreatedDT
    html
    IsDraft
    IsSent
    StartDate
    EndDate
    ShareDate
    EmailDate
    GalleryID
    Gallery {
      ID
      ImageURL
    }
  }
}
    `;

/**
 * __useEmailMarketingListingQuery__
 *
 * To run a query within a React component, call `useEmailMarketingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailMarketingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailMarketingListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useEmailMarketingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmailMarketingListingQuery, EmailMarketingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EmailMarketingListingQuery, EmailMarketingListingQueryVariables>(EmailMarketingListingDocument, baseOptions);
      }
export function useEmailMarketingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmailMarketingListingQuery, EmailMarketingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmailMarketingListingQuery, EmailMarketingListingQueryVariables>(EmailMarketingListingDocument, baseOptions);
        }
export type EmailMarketingListingQueryHookResult = ReturnType<typeof useEmailMarketingListingQuery>;
export type EmailMarketingListingLazyQueryHookResult = ReturnType<typeof useEmailMarketingListingLazyQuery>;
export type EmailMarketingListingQueryResult = ApolloReactCommon.QueryResult<EmailMarketingListingQuery, EmailMarketingListingQueryVariables>;
export const EmailMarketingDetailDocument = gql`
    query EmailMarketingDetail($HotelID: String!, $ID: String) {
  EmailMarketingDetail(HotelID: $HotelID, ID: $ID) {
    ID
    Title
    Description
    html
    IsDraft
    IsSent
  }
}
    `;

/**
 * __useEmailMarketingDetailQuery__
 *
 * To run a query within a React component, call `useEmailMarketingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailMarketingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailMarketingDetailQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useEmailMarketingDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EmailMarketingDetailQuery, EmailMarketingDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<EmailMarketingDetailQuery, EmailMarketingDetailQueryVariables>(EmailMarketingDetailDocument, baseOptions);
      }
export function useEmailMarketingDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmailMarketingDetailQuery, EmailMarketingDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EmailMarketingDetailQuery, EmailMarketingDetailQueryVariables>(EmailMarketingDetailDocument, baseOptions);
        }
export type EmailMarketingDetailQueryHookResult = ReturnType<typeof useEmailMarketingDetailQuery>;
export type EmailMarketingDetailLazyQueryHookResult = ReturnType<typeof useEmailMarketingDetailLazyQuery>;
export type EmailMarketingDetailQueryResult = ApolloReactCommon.QueryResult<EmailMarketingDetailQuery, EmailMarketingDetailQueryVariables>;
export const EmailCampaignDocument = gql`
    mutation EmailCampaign($email: [String!]!, $id: String!, $IsSelectAll: Boolean!, $HotelID: String!) {
  EmailCampaign(email: $email, id: $id, IsSelectAll: $IsSelectAll, HotelID: $HotelID)
}
    `;
export type EmailCampaignMutationFn = ApolloReactCommon.MutationFunction<EmailCampaignMutation, EmailCampaignMutationVariables>;

/**
 * __useEmailCampaignMutation__
 *
 * To run a mutation, you first call `useEmailCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailCampaignMutation, { data, loading, error }] = useEmailCampaignMutation({
 *   variables: {
 *      email: // value for 'email'
 *      id: // value for 'id'
 *      IsSelectAll: // value for 'IsSelectAll'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useEmailCampaignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmailCampaignMutation, EmailCampaignMutationVariables>) {
        return ApolloReactHooks.useMutation<EmailCampaignMutation, EmailCampaignMutationVariables>(EmailCampaignDocument, baseOptions);
      }
export type EmailCampaignMutationHookResult = ReturnType<typeof useEmailCampaignMutation>;
export type EmailCampaignMutationResult = ApolloReactCommon.MutationResult<EmailCampaignMutation>;
export type EmailCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<EmailCampaignMutation, EmailCampaignMutationVariables>;
export const EmailDeleteDocument = gql`
    mutation EmailDelete($ID: String!) {
  EmailDelete(ID: $ID)
}
    `;
export type EmailDeleteMutationFn = ApolloReactCommon.MutationFunction<EmailDeleteMutation, EmailDeleteMutationVariables>;

/**
 * __useEmailDeleteMutation__
 *
 * To run a mutation, you first call `useEmailDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailDeleteMutation, { data, loading, error }] = useEmailDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useEmailDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EmailDeleteMutation, EmailDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<EmailDeleteMutation, EmailDeleteMutationVariables>(EmailDeleteDocument, baseOptions);
      }
export type EmailDeleteMutationHookResult = ReturnType<typeof useEmailDeleteMutation>;
export type EmailDeleteMutationResult = ApolloReactCommon.MutationResult<EmailDeleteMutation>;
export type EmailDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<EmailDeleteMutation, EmailDeleteMutationVariables>;
export const PromotionDeleteDocument = gql`
    mutation PromotionDelete($ID: String!, $PromoCode: String!, $Date: DateTime!) {
  PromotionDelete(ID: $ID, PromoCode: $PromoCode, Date: $Date)
}
    `;
export type PromotionDeleteMutationFn = ApolloReactCommon.MutationFunction<PromotionDeleteMutation, PromotionDeleteMutationVariables>;

/**
 * __usePromotionDeleteMutation__
 *
 * To run a mutation, you first call `usePromotionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePromotionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [promotionDeleteMutation, { data, loading, error }] = usePromotionDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      PromoCode: // value for 'PromoCode'
 *      Date: // value for 'Date'
 *   },
 * });
 */
export function usePromotionDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PromotionDeleteMutation, PromotionDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PromotionDeleteMutation, PromotionDeleteMutationVariables>(PromotionDeleteDocument, baseOptions);
      }
export type PromotionDeleteMutationHookResult = ReturnType<typeof usePromotionDeleteMutation>;
export type PromotionDeleteMutationResult = ApolloReactCommon.MutationResult<PromotionDeleteMutation>;
export type PromotionDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PromotionDeleteMutation, PromotionDeleteMutationVariables>;
export const GuestMovementListDocument = gql`
    query GuestMovementList($HotelID: String!, $GuestID: String!, $BookingID: String!) {
  GuestMovementList(HotelID: $HotelID, GuestID: $GuestID, BookingID: $BookingID)
}
    `;

/**
 * __useGuestMovementListQuery__
 *
 * To run a query within a React component, call `useGuestMovementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestMovementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestMovementListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      GuestID: // value for 'GuestID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useGuestMovementListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestMovementListQuery, GuestMovementListQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestMovementListQuery, GuestMovementListQueryVariables>(GuestMovementListDocument, baseOptions);
      }
export function useGuestMovementListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestMovementListQuery, GuestMovementListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestMovementListQuery, GuestMovementListQueryVariables>(GuestMovementListDocument, baseOptions);
        }
export type GuestMovementListQueryHookResult = ReturnType<typeof useGuestMovementListQuery>;
export type GuestMovementListLazyQueryHookResult = ReturnType<typeof useGuestMovementListLazyQuery>;
export type GuestMovementListQueryResult = ApolloReactCommon.QueryResult<GuestMovementListQuery, GuestMovementListQueryVariables>;
export const SearchGuestDocument = gql`
    query SearchGuest($HotelID: String!, $GuestName: String!, $offset: Float, $limit: Float) {
  SearchGuest(HotelID: $HotelID, GuestName: $GuestName, offset: $offset, limit: $limit) {
    GuestID
    ContactID
    FullName
    BookingID
    ArrivalDate
    DepartureDate
    NRIC
    Nationality
    MobileNo
    Email
    Address {
      address
      state
      city
      country
      postCode
    }
  }
}
    `;

/**
 * __useSearchGuestQuery__
 *
 * To run a query within a React component, call `useSearchGuestQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGuestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGuestQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      GuestName: // value for 'GuestName'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchGuestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchGuestQuery, SearchGuestQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchGuestQuery, SearchGuestQueryVariables>(SearchGuestDocument, baseOptions);
      }
export function useSearchGuestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchGuestQuery, SearchGuestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchGuestQuery, SearchGuestQueryVariables>(SearchGuestDocument, baseOptions);
        }
export type SearchGuestQueryHookResult = ReturnType<typeof useSearchGuestQuery>;
export type SearchGuestLazyQueryHookResult = ReturnType<typeof useSearchGuestLazyQuery>;
export type SearchGuestQueryResult = ApolloReactCommon.QueryResult<SearchGuestQuery, SearchGuestQueryVariables>;
export const DepositListingByBookingDocument = gql`
    query DepositListingByBooking($RegistrationID: String!, $HotelID: String!, $BookingID: String!) {
  DepositListingByBooking(RegistrationID: $RegistrationID, HotelID: $HotelID, BookingID: $BookingID) {
    ID
    DepositClass
    TrxDate
    Remarks
    ReversedID
    DepositClass
    DepositAmount
    Folio {
      FolioNo
      ID
      FolioAmount
    }
    Registration {
      DepositLedger {
        ID
        FolioID
        DepositAmount
        DepositClass
      }
    }
  }
}
    `;

/**
 * __useDepositListingByBookingQuery__
 *
 * To run a query within a React component, call `useDepositListingByBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositListingByBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositListingByBookingQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useDepositListingByBookingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepositListingByBookingQuery, DepositListingByBookingQueryVariables>) {
        return ApolloReactHooks.useQuery<DepositListingByBookingQuery, DepositListingByBookingQueryVariables>(DepositListingByBookingDocument, baseOptions);
      }
export function useDepositListingByBookingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepositListingByBookingQuery, DepositListingByBookingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepositListingByBookingQuery, DepositListingByBookingQueryVariables>(DepositListingByBookingDocument, baseOptions);
        }
export type DepositListingByBookingQueryHookResult = ReturnType<typeof useDepositListingByBookingQuery>;
export type DepositListingByBookingLazyQueryHookResult = ReturnType<typeof useDepositListingByBookingLazyQuery>;
export type DepositListingByBookingQueryResult = ApolloReactCommon.QueryResult<DepositListingByBookingQuery, DepositListingByBookingQueryVariables>;
export const DepositListingByBookingV2Document = gql`
    query DepositListingByBookingV2($RegistrationID: String!, $HotelID: String!, $BookingID: String!) {
  DepositListingByBookingV2(RegistrationID: $RegistrationID, HotelID: $HotelID, BookingID: $BookingID)
}
    `;

/**
 * __useDepositListingByBookingV2Query__
 *
 * To run a query within a React component, call `useDepositListingByBookingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useDepositListingByBookingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositListingByBookingV2Query({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useDepositListingByBookingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<DepositListingByBookingV2Query, DepositListingByBookingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<DepositListingByBookingV2Query, DepositListingByBookingV2QueryVariables>(DepositListingByBookingV2Document, baseOptions);
      }
export function useDepositListingByBookingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepositListingByBookingV2Query, DepositListingByBookingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepositListingByBookingV2Query, DepositListingByBookingV2QueryVariables>(DepositListingByBookingV2Document, baseOptions);
        }
export type DepositListingByBookingV2QueryHookResult = ReturnType<typeof useDepositListingByBookingV2Query>;
export type DepositListingByBookingV2LazyQueryHookResult = ReturnType<typeof useDepositListingByBookingV2LazyQuery>;
export type DepositListingByBookingV2QueryResult = ApolloReactCommon.QueryResult<DepositListingByBookingV2Query, DepositListingByBookingV2QueryVariables>;
export const DepositInsertDocument = gql`
    mutation DepositInsert($input: DepositInput!) {
  DepositInsert(input: $input)
}
    `;
export type DepositInsertMutationFn = ApolloReactCommon.MutationFunction<DepositInsertMutation, DepositInsertMutationVariables>;

/**
 * __useDepositInsertMutation__
 *
 * To run a mutation, you first call `useDepositInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepositInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [depositInsertMutation, { data, loading, error }] = useDepositInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDepositInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DepositInsertMutation, DepositInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DepositInsertMutation, DepositInsertMutationVariables>(DepositInsertDocument, baseOptions);
      }
export type DepositInsertMutationHookResult = ReturnType<typeof useDepositInsertMutation>;
export type DepositInsertMutationResult = ApolloReactCommon.MutationResult<DepositInsertMutation>;
export type DepositInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DepositInsertMutation, DepositInsertMutationVariables>;
export const RefundDepositDocument = gql`
    mutation RefundDeposit($input: RefundDepositInput!) {
  RefundDeposit(input: $input)
}
    `;
export type RefundDepositMutationFn = ApolloReactCommon.MutationFunction<RefundDepositMutation, RefundDepositMutationVariables>;

/**
 * __useRefundDepositMutation__
 *
 * To run a mutation, you first call `useRefundDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefundDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refundDepositMutation, { data, loading, error }] = useRefundDepositMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefundDepositMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefundDepositMutation, RefundDepositMutationVariables>) {
        return ApolloReactHooks.useMutation<RefundDepositMutation, RefundDepositMutationVariables>(RefundDepositDocument, baseOptions);
      }
export type RefundDepositMutationHookResult = ReturnType<typeof useRefundDepositMutation>;
export type RefundDepositMutationResult = ApolloReactCommon.MutationResult<RefundDepositMutation>;
export type RefundDepositMutationOptions = ApolloReactCommon.BaseMutationOptions<RefundDepositMutation, RefundDepositMutationVariables>;
export const ActiveBookingRoomListingDocument = gql`
    query ActiveBookingRoomListing($HotelID: String!) {
  ActiveBookingRoomListing(HotelID: $HotelID) {
    ID
    RoomNo
    TodayBookingNo {
      ID
      Booking {
        ID
        BookingNo
      }
    }
  }
}
    `;

/**
 * __useActiveBookingRoomListingQuery__
 *
 * To run a query within a React component, call `useActiveBookingRoomListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveBookingRoomListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveBookingRoomListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useActiveBookingRoomListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActiveBookingRoomListingQuery, ActiveBookingRoomListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ActiveBookingRoomListingQuery, ActiveBookingRoomListingQueryVariables>(ActiveBookingRoomListingDocument, baseOptions);
      }
export function useActiveBookingRoomListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActiveBookingRoomListingQuery, ActiveBookingRoomListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActiveBookingRoomListingQuery, ActiveBookingRoomListingQueryVariables>(ActiveBookingRoomListingDocument, baseOptions);
        }
export type ActiveBookingRoomListingQueryHookResult = ReturnType<typeof useActiveBookingRoomListingQuery>;
export type ActiveBookingRoomListingLazyQueryHookResult = ReturnType<typeof useActiveBookingRoomListingLazyQuery>;
export type ActiveBookingRoomListingQueryResult = ApolloReactCommon.QueryResult<ActiveBookingRoomListingQuery, ActiveBookingRoomListingQueryVariables>;
export const PrincipalGuestListingDocument = gql`
    query PrincipalGuestListing($BookingID: String!) {
  PrincipalGuestListing(BookingID: $BookingID) {
    ID
    Room {
      ID
      RoomNo
      RoomType {
        Code
        ID
      }
    }
    Guest {
      ID
      Contact {
        ID
        FullName
      }
    }
  }
}
    `;

/**
 * __usePrincipalGuestListingQuery__
 *
 * To run a query within a React component, call `usePrincipalGuestListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrincipalGuestListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrincipalGuestListingQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function usePrincipalGuestListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PrincipalGuestListingQuery, PrincipalGuestListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PrincipalGuestListingQuery, PrincipalGuestListingQueryVariables>(PrincipalGuestListingDocument, baseOptions);
      }
export function usePrincipalGuestListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrincipalGuestListingQuery, PrincipalGuestListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PrincipalGuestListingQuery, PrincipalGuestListingQueryVariables>(PrincipalGuestListingDocument, baseOptions);
        }
export type PrincipalGuestListingQueryHookResult = ReturnType<typeof usePrincipalGuestListingQuery>;
export type PrincipalGuestListingLazyQueryHookResult = ReturnType<typeof usePrincipalGuestListingLazyQuery>;
export type PrincipalGuestListingQueryResult = ApolloReactCommon.QueryResult<PrincipalGuestListingQuery, PrincipalGuestListingQueryVariables>;
export const UnutilizedDepositListingDocument = gql`
    query UnutilizedDepositListing($HotelID: String!) {
  UnutilizedDepositListing(HotelID: $HotelID) {
    DepositResult
  }
}
    `;

/**
 * __useUnutilizedDepositListingQuery__
 *
 * To run a query within a React component, call `useUnutilizedDepositListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnutilizedDepositListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnutilizedDepositListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useUnutilizedDepositListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnutilizedDepositListingQuery, UnutilizedDepositListingQueryVariables>) {
        return ApolloReactHooks.useQuery<UnutilizedDepositListingQuery, UnutilizedDepositListingQueryVariables>(UnutilizedDepositListingDocument, baseOptions);
      }
export function useUnutilizedDepositListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnutilizedDepositListingQuery, UnutilizedDepositListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnutilizedDepositListingQuery, UnutilizedDepositListingQueryVariables>(UnutilizedDepositListingDocument, baseOptions);
        }
export type UnutilizedDepositListingQueryHookResult = ReturnType<typeof useUnutilizedDepositListingQuery>;
export type UnutilizedDepositListingLazyQueryHookResult = ReturnType<typeof useUnutilizedDepositListingLazyQuery>;
export type UnutilizedDepositListingQueryResult = ApolloReactCommon.QueryResult<UnutilizedDepositListingQuery, UnutilizedDepositListingQueryVariables>;
export const UtilizedDepositListingDocument = gql`
    query UtilizedDepositListing($HotelID: String!) {
  UtilizedDepositListing(HotelID: $HotelID) {
    DepositResult
  }
}
    `;

/**
 * __useUtilizedDepositListingQuery__
 *
 * To run a query within a React component, call `useUtilizedDepositListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUtilizedDepositListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtilizedDepositListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useUtilizedDepositListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UtilizedDepositListingQuery, UtilizedDepositListingQueryVariables>) {
        return ApolloReactHooks.useQuery<UtilizedDepositListingQuery, UtilizedDepositListingQueryVariables>(UtilizedDepositListingDocument, baseOptions);
      }
export function useUtilizedDepositListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UtilizedDepositListingQuery, UtilizedDepositListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UtilizedDepositListingQuery, UtilizedDepositListingQueryVariables>(UtilizedDepositListingDocument, baseOptions);
        }
export type UtilizedDepositListingQueryHookResult = ReturnType<typeof useUtilizedDepositListingQuery>;
export type UtilizedDepositListingLazyQueryHookResult = ReturnType<typeof useUtilizedDepositListingLazyQuery>;
export type UtilizedDepositListingQueryResult = ApolloReactCommon.QueryResult<UtilizedDepositListingQuery, UtilizedDepositListingQueryVariables>;
export const UnutilizedDepositListingV2Document = gql`
    query UnutilizedDepositListingV2($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  UnutilizedDepositListingV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useUnutilizedDepositListingV2Query__
 *
 * To run a query within a React component, call `useUnutilizedDepositListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useUnutilizedDepositListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnutilizedDepositListingV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useUnutilizedDepositListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<UnutilizedDepositListingV2Query, UnutilizedDepositListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<UnutilizedDepositListingV2Query, UnutilizedDepositListingV2QueryVariables>(UnutilizedDepositListingV2Document, baseOptions);
      }
export function useUnutilizedDepositListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnutilizedDepositListingV2Query, UnutilizedDepositListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnutilizedDepositListingV2Query, UnutilizedDepositListingV2QueryVariables>(UnutilizedDepositListingV2Document, baseOptions);
        }
export type UnutilizedDepositListingV2QueryHookResult = ReturnType<typeof useUnutilizedDepositListingV2Query>;
export type UnutilizedDepositListingV2LazyQueryHookResult = ReturnType<typeof useUnutilizedDepositListingV2LazyQuery>;
export type UnutilizedDepositListingV2QueryResult = ApolloReactCommon.QueryResult<UnutilizedDepositListingV2Query, UnutilizedDepositListingV2QueryVariables>;
export const UtilizedDepositListingV2Document = gql`
    query UtilizedDepositListingV2($HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  UtilizedDepositListingV2(HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useUtilizedDepositListingV2Query__
 *
 * To run a query within a React component, call `useUtilizedDepositListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useUtilizedDepositListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUtilizedDepositListingV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useUtilizedDepositListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<UtilizedDepositListingV2Query, UtilizedDepositListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<UtilizedDepositListingV2Query, UtilizedDepositListingV2QueryVariables>(UtilizedDepositListingV2Document, baseOptions);
      }
export function useUtilizedDepositListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UtilizedDepositListingV2Query, UtilizedDepositListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UtilizedDepositListingV2Query, UtilizedDepositListingV2QueryVariables>(UtilizedDepositListingV2Document, baseOptions);
        }
export type UtilizedDepositListingV2QueryHookResult = ReturnType<typeof useUtilizedDepositListingV2Query>;
export type UtilizedDepositListingV2LazyQueryHookResult = ReturnType<typeof useUtilizedDepositListingV2LazyQuery>;
export type UtilizedDepositListingV2QueryResult = ApolloReactCommon.QueryResult<UtilizedDepositListingV2Query, UtilizedDepositListingV2QueryVariables>;
export const DepositLedgerDetailsDocument = gql`
    query DepositLedgerDetails($HotelID: String!, $ID: String!) {
  DepositLedgerDetails(HotelID: $HotelID, ID: $ID)
}
    `;

/**
 * __useDepositLedgerDetailsQuery__
 *
 * To run a query within a React component, call `useDepositLedgerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositLedgerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositLedgerDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDepositLedgerDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepositLedgerDetailsQuery, DepositLedgerDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<DepositLedgerDetailsQuery, DepositLedgerDetailsQueryVariables>(DepositLedgerDetailsDocument, baseOptions);
      }
export function useDepositLedgerDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepositLedgerDetailsQuery, DepositLedgerDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepositLedgerDetailsQuery, DepositLedgerDetailsQueryVariables>(DepositLedgerDetailsDocument, baseOptions);
        }
export type DepositLedgerDetailsQueryHookResult = ReturnType<typeof useDepositLedgerDetailsQuery>;
export type DepositLedgerDetailsLazyQueryHookResult = ReturnType<typeof useDepositLedgerDetailsLazyQuery>;
export type DepositLedgerDetailsQueryResult = ApolloReactCommon.QueryResult<DepositLedgerDetailsQuery, DepositLedgerDetailsQueryVariables>;
export const DepositRefundDetailsDocument = gql`
    query DepositRefundDetails($DepositID: String!, $HotelID: String!) {
  DepositRefundDetails(DepositID: $DepositID, HotelID: $HotelID) {
    ID
    FolioID
    Folio {
      FolioNo
    }
  }
}
    `;

/**
 * __useDepositRefundDetailsQuery__
 *
 * To run a query within a React component, call `useDepositRefundDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepositRefundDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepositRefundDetailsQuery({
 *   variables: {
 *      DepositID: // value for 'DepositID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDepositRefundDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DepositRefundDetailsQuery, DepositRefundDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<DepositRefundDetailsQuery, DepositRefundDetailsQueryVariables>(DepositRefundDetailsDocument, baseOptions);
      }
export function useDepositRefundDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DepositRefundDetailsQuery, DepositRefundDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DepositRefundDetailsQuery, DepositRefundDetailsQueryVariables>(DepositRefundDetailsDocument, baseOptions);
        }
export type DepositRefundDetailsQueryHookResult = ReturnType<typeof useDepositRefundDetailsQuery>;
export type DepositRefundDetailsLazyQueryHookResult = ReturnType<typeof useDepositRefundDetailsLazyQuery>;
export type DepositRefundDetailsQueryResult = ApolloReactCommon.QueryResult<DepositRefundDetailsQuery, DepositRefundDetailsQueryVariables>;
export const DocumentTemplateListDocument = gql`
    query DocumentTemplateList($ID: String!) {
  DocumentTemplateList(ID: $ID) {
    RegCard
    Folio
    Receipt
    Invoice
  }
}
    `;

/**
 * __useDocumentTemplateListQuery__
 *
 * To run a query within a React component, call `useDocumentTemplateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentTemplateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentTemplateListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDocumentTemplateListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentTemplateListQuery, DocumentTemplateListQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentTemplateListQuery, DocumentTemplateListQueryVariables>(DocumentTemplateListDocument, baseOptions);
      }
export function useDocumentTemplateListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentTemplateListQuery, DocumentTemplateListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentTemplateListQuery, DocumentTemplateListQueryVariables>(DocumentTemplateListDocument, baseOptions);
        }
export type DocumentTemplateListQueryHookResult = ReturnType<typeof useDocumentTemplateListQuery>;
export type DocumentTemplateListLazyQueryHookResult = ReturnType<typeof useDocumentTemplateListLazyQuery>;
export type DocumentTemplateListQueryResult = ApolloReactCommon.QueryResult<DocumentTemplateListQuery, DocumentTemplateListQueryVariables>;
export const RegistrationSignDocument = gql`
    mutation RegistrationSign($ID: String!, $AttachmentInput: [Upload!]!) {
  RegistrationSign(ID: $ID, AttachmentInput: $AttachmentInput)
}
    `;
export type RegistrationSignMutationFn = ApolloReactCommon.MutationFunction<RegistrationSignMutation, RegistrationSignMutationVariables>;

/**
 * __useRegistrationSignMutation__
 *
 * To run a mutation, you first call `useRegistrationSignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationSignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationSignMutation, { data, loading, error }] = useRegistrationSignMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useRegistrationSignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegistrationSignMutation, RegistrationSignMutationVariables>) {
        return ApolloReactHooks.useMutation<RegistrationSignMutation, RegistrationSignMutationVariables>(RegistrationSignDocument, baseOptions);
      }
export type RegistrationSignMutationHookResult = ReturnType<typeof useRegistrationSignMutation>;
export type RegistrationSignMutationResult = ApolloReactCommon.MutationResult<RegistrationSignMutation>;
export type RegistrationSignMutationOptions = ApolloReactCommon.BaseMutationOptions<RegistrationSignMutation, RegistrationSignMutationVariables>;
export const SelectedRegistrationDocument = gql`
    query SelectedRegistration($ID: String!) {
  SelectedRegistration(ID: $ID) {
    ID
    Signature
    DiscountAmount
    RoomRate
    CheckInDate
    Guest {
      VehicleNo
      Contact {
        FullName
      }
    }
    RegistrationSignature {
      ID
      BucketFileName
      ImageURL
    }
  }
}
    `;

/**
 * __useSelectedRegistrationQuery__
 *
 * To run a query within a React component, call `useSelectedRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedRegistrationQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useSelectedRegistrationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectedRegistrationQuery, SelectedRegistrationQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectedRegistrationQuery, SelectedRegistrationQueryVariables>(SelectedRegistrationDocument, baseOptions);
      }
export function useSelectedRegistrationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectedRegistrationQuery, SelectedRegistrationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectedRegistrationQuery, SelectedRegistrationQueryVariables>(SelectedRegistrationDocument, baseOptions);
        }
export type SelectedRegistrationQueryHookResult = ReturnType<typeof useSelectedRegistrationQuery>;
export type SelectedRegistrationLazyQueryHookResult = ReturnType<typeof useSelectedRegistrationLazyQuery>;
export type SelectedRegistrationQueryResult = ApolloReactCommon.QueryResult<SelectedRegistrationQuery, SelectedRegistrationQueryVariables>;
export const SelectFolioDocument = gql`
    query SelectFolio($ID: String!) {
  SelectFolio(ID: $ID) {
    FolioNo
  }
}
    `;

/**
 * __useSelectFolioQuery__
 *
 * To run a query within a React component, call `useSelectFolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectFolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectFolioQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useSelectFolioQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectFolioQuery, SelectFolioQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectFolioQuery, SelectFolioQueryVariables>(SelectFolioDocument, baseOptions);
      }
export function useSelectFolioLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectFolioQuery, SelectFolioQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectFolioQuery, SelectFolioQueryVariables>(SelectFolioDocument, baseOptions);
        }
export type SelectFolioQueryHookResult = ReturnType<typeof useSelectFolioQuery>;
export type SelectFolioLazyQueryHookResult = ReturnType<typeof useSelectFolioLazyQuery>;
export type SelectFolioQueryResult = ApolloReactCommon.QueryResult<SelectFolioQuery, SelectFolioQueryVariables>;
export const BookingProfileDocument = gql`
    query BookingProfile($ID: String!) {
  BookingProfile(ID: $ID) {
    BookingNo
    ArrivalDate
    DepartureDate
    ContactID
    DebtorID
    Debtor {
      Name
    }
    Registration {
      ID
    }
  }
}
    `;

/**
 * __useBookingProfileQuery__
 *
 * To run a query within a React component, call `useBookingProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingProfileQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useBookingProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BookingProfileQuery, BookingProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<BookingProfileQuery, BookingProfileQueryVariables>(BookingProfileDocument, baseOptions);
      }
export function useBookingProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingProfileQuery, BookingProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BookingProfileQuery, BookingProfileQueryVariables>(BookingProfileDocument, baseOptions);
        }
export type BookingProfileQueryHookResult = ReturnType<typeof useBookingProfileQuery>;
export type BookingProfileLazyQueryHookResult = ReturnType<typeof useBookingProfileLazyQuery>;
export type BookingProfileQueryResult = ApolloReactCommon.QueryResult<BookingProfileQuery, BookingProfileQueryVariables>;
export const FrontDeskListingDocument = gql`
    query FrontDeskListing($HotelID: String!, $Status: String, $RoomTypeID: String, $ArrivalDate: DateTime, $ArrStartDate: DateTime, $ArrEndDate: DateTime, $DepartStartDate: DateTime, $DepartEndDate: DateTime, $ReferenceNo: String, $SortBy: String, $SortByOrder: String) {
  FrontDeskListing(HotelID: $HotelID, Status: $Status, RoomTypeID: $RoomTypeID, ArrivalDate: $ArrivalDate, ArrStartDate: $ArrStartDate, ArrEndDate: $ArrEndDate, DepartStartDate: $DepartStartDate, DepartEndDate: $DepartEndDate, ReferenceNo: $ReferenceNo, SortBy: $SortBy, SortByOrder: $SortByOrder) {
    ID
    BookingNo
    ArrivalDate
    DepartureDate
    BookingStatus
    IsGroupBooking
    GroupName
    IsCms
    IsCmsTtx
    ChargeToCityLedger
    BillingInstruction
    ReferenceNo
    isPrepaid
    Debtor {
      IseCommerce
      ID
      Name
      IseCommerce
      Contact {
        FullName
        PhoneNo
        Email
        MobileNo
        BirthDate
        Designation
        Address {
          address
          city
          state
          country
          postCode
        }
      }
      DebtorCategory
      CreditLimit
      DebtorAccount
      DebtorContacts
      Segment
      Password
      Login
      DebtorContact {
        ID
        Contact {
          FullName
          Email
          MobileNo
        }
      }
    }
    Source
    SalesChannel {
      ID
      Description
    }
    Segment {
      ID
      Description
    }
    Contact {
      ID
      FullName
      Email
      MobileNo
      PhoneNo
      PassportNo
      NRIC
      Address {
        address
        city
        state
        country
        postCode
      }
    }
    BookedRegistration(Status: $Status) {
      IsPDPA
      PrintRate
      SpecialRequest
      BookingID
      RegistrationStatus
      HouseLimit
      PromoCode
      DiscountAmount
      SharerNo
      RoomRate
      Signature
      ArrivalDate
      DepartureDate
      ServiceRequestCount
      BookingRemark
      BookingID
      NoOfAdult
      NoOfChild
      NoOfInfant
      CheckInBy
      CheckOutBy
      PaymentSum
      AdvancePaymentSum
      TotalOutStandingAmount
      IsPreCheckIn
      Booking {
        isPrepaid
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        Contact {
          FullName
        }
        Debtor {
          Name
          ID
          DebtorCategory
          DebtorAccount
        }
      }
      RoomType {
        ID
        MaxGuest
        Code
        Overbooking
      }
      DepositLedger {
        ID
        FolioID
        DepositAmount
        DepositClass
      }
      ID
      CheckInDate
      CheckOutDate
      IsPrincipal
      IsRoomUpgrade
      RegistrationStatus
      RoomID
      SharerNo
      IsTTx
      TTxReasonID
      Room {
        ID
        RoomNo
        RoomStatusCode
        HKRoomStatus
        ServiceRequestCount
        HouseKeepingLog {
          ID
          HotelDate
        }
      }
      Guest {
        ID
        ContactID
        VehicleNo
        GuestType
        IsTTx
        TTxReasonID
        Contact {
          ID
          FullName
          Nationality
          MobileNo
          BirthDate
          NRIC
          PassportNo
          Email
          Designation
          Address {
            address
            state
            country
            postCode
            city
          }
        }
        GuestHistoryCount
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
    }
  }
}
    `;

/**
 * __useFrontDeskListingQuery__
 *
 * To run a query within a React component, call `useFrontDeskListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontDeskListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      Status: // value for 'Status'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      ArrStartDate: // value for 'ArrStartDate'
 *      ArrEndDate: // value for 'ArrEndDate'
 *      DepartStartDate: // value for 'DepartStartDate'
 *      DepartEndDate: // value for 'DepartEndDate'
 *      ReferenceNo: // value for 'ReferenceNo'
 *      SortBy: // value for 'SortBy'
 *      SortByOrder: // value for 'SortByOrder'
 *   },
 * });
 */
export function useFrontDeskListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FrontDeskListingQuery, FrontDeskListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FrontDeskListingQuery, FrontDeskListingQueryVariables>(FrontDeskListingDocument, baseOptions);
      }
export function useFrontDeskListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FrontDeskListingQuery, FrontDeskListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FrontDeskListingQuery, FrontDeskListingQueryVariables>(FrontDeskListingDocument, baseOptions);
        }
export type FrontDeskListingQueryHookResult = ReturnType<typeof useFrontDeskListingQuery>;
export type FrontDeskListingLazyQueryHookResult = ReturnType<typeof useFrontDeskListingLazyQuery>;
export type FrontDeskListingQueryResult = ApolloReactCommon.QueryResult<FrontDeskListingQuery, FrontDeskListingQueryVariables>;
export const CheckInListingDocument = gql`
    query CheckInListing($HotelID: String!, $AccountID: String!, $BookingStatus: String!, $SearchValue: String, $limit: Float, $offset: Float) {
  CheckInListing(HotelID: $HotelID, AccountID: $AccountID, BookingStatus: $BookingStatus, SearchValue: $SearchValue, offset: $offset, limit: $limit) {
    ID
    BookingNo
    ArrivalDate
    BookingStatus
    DepartureDate
    ReferenceNo
    BillingInstruction
    Source
    Segment {
      Description
    }
    Contact {
      FullName
      MobileNo
      Email
      Designation
    }
    BookedRegistration(Status: $BookingStatus) {
      ID
      IsPrincipal
      RegistrationStatus
      ArrivalDate
      DepartureDate
      PrintRate
      Guest {
        ID
        ContactID
        VehicleNo
        GuestType
        IsTTx
        TTxReasonID
        Contact {
          ID
          FullName
          Nationality
          MobileNo
          BirthDate
          NRIC
          PassportNo
          Email
          Designation
          Address {
            address
            state
            country
            postCode
            city
          }
        }
        GuestHistoryCount
      }
    }
    Debtor {
      ID
      Name
      Segment
    }
  }
}
    `;

/**
 * __useCheckInListingQuery__
 *
 * To run a query within a React component, call `useCheckInListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      BookingStatus: // value for 'BookingStatus'
 *      SearchValue: // value for 'SearchValue'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCheckInListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckInListingQuery, CheckInListingQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckInListingQuery, CheckInListingQueryVariables>(CheckInListingDocument, baseOptions);
      }
export function useCheckInListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckInListingQuery, CheckInListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckInListingQuery, CheckInListingQueryVariables>(CheckInListingDocument, baseOptions);
        }
export type CheckInListingQueryHookResult = ReturnType<typeof useCheckInListingQuery>;
export type CheckInListingLazyQueryHookResult = ReturnType<typeof useCheckInListingLazyQuery>;
export type CheckInListingQueryResult = ApolloReactCommon.QueryResult<CheckInListingQuery, CheckInListingQueryVariables>;
export const RegistrationByBookingListingDocument = gql`
    query RegistrationByBookingListing($HotelID: String!, $BookingID: String!, $RegistrationStatus: String!) {
  RegistrationByBookingListing(HotelID: $HotelID, BookingID: $BookingID, RegistrationStatus: $RegistrationStatus) {
    ID
    RegistrationStatus
    IsRoomUpgrade
    ArrivalDate
    DepartureDate
    CheckInDate
    CheckOutDate
    IsPreCheckIn
    SharerNo
    BookingID
    IsPrincipal
    SpecialRequest
    Booking {
      ID
      ArrivalDate
      DepartureDate
      BookingNo
      DebtorAccount
      BillingInstruction
    }
    Room {
      ID
      RoomNo
      HKRoomStatus
    }
    RoomType {
      ID
      Code
    }
    Guest {
      ID
      GuestType
      ContactID
      GuestHistoryCount
      VehicleNo
      Contact {
        FullName
        MobileNo
        Email
        Nationality
        NRIC
        Designation
        BirthDate
        Address {
          address
          state
          city
          postCode
          country
        }
      }
    }
  }
}
    `;

/**
 * __useRegistrationByBookingListingQuery__
 *
 * To run a query within a React component, call `useRegistrationByBookingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationByBookingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationByBookingListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationStatus: // value for 'RegistrationStatus'
 *   },
 * });
 */
export function useRegistrationByBookingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistrationByBookingListingQuery, RegistrationByBookingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistrationByBookingListingQuery, RegistrationByBookingListingQueryVariables>(RegistrationByBookingListingDocument, baseOptions);
      }
export function useRegistrationByBookingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistrationByBookingListingQuery, RegistrationByBookingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistrationByBookingListingQuery, RegistrationByBookingListingQueryVariables>(RegistrationByBookingListingDocument, baseOptions);
        }
export type RegistrationByBookingListingQueryHookResult = ReturnType<typeof useRegistrationByBookingListingQuery>;
export type RegistrationByBookingListingLazyQueryHookResult = ReturnType<typeof useRegistrationByBookingListingLazyQuery>;
export type RegistrationByBookingListingQueryResult = ApolloReactCommon.QueryResult<RegistrationByBookingListingQuery, RegistrationByBookingListingQueryVariables>;
export const CheckInListingCountDocument = gql`
    query CheckInListingCount($HotelID: String!, $AccountID: String!) {
  CheckInListingCount(HotelID: $HotelID, AccountID: $AccountID)
}
    `;

/**
 * __useCheckInListingCountQuery__
 *
 * To run a query within a React component, call `useCheckInListingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckInListingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckInListingCountQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useCheckInListingCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckInListingCountQuery, CheckInListingCountQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckInListingCountQuery, CheckInListingCountQueryVariables>(CheckInListingCountDocument, baseOptions);
      }
export function useCheckInListingCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckInListingCountQuery, CheckInListingCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckInListingCountQuery, CheckInListingCountQueryVariables>(CheckInListingCountDocument, baseOptions);
        }
export type CheckInListingCountQueryHookResult = ReturnType<typeof useCheckInListingCountQuery>;
export type CheckInListingCountLazyQueryHookResult = ReturnType<typeof useCheckInListingCountLazyQuery>;
export type CheckInListingCountQueryResult = ApolloReactCommon.QueryResult<CheckInListingCountQuery, CheckInListingCountQueryVariables>;
export const FrontDeskListingV2Document = gql`
    query FrontDeskListingV2($HotelID: String!, $Status: String, $SearchValue: String, $ArrStartDate: DateTime, $ArrEndDate: DateTime, $DepartStartDate: DateTime, $DepartEndDate: DateTime, $ReferenceNo: String, $SortBy: String, $SortByOrder: String, $limit: Float, $offset: Float) {
  FrontDeskListingV2(HotelID: $HotelID, Status: $Status, SearchValue: $SearchValue, ArrStartDate: $ArrStartDate, ArrEndDate: $ArrEndDate, DepartStartDate: $DepartStartDate, DepartEndDate: $DepartEndDate, ReferenceNo: $ReferenceNo, SortBy: $SortBy, SortByOrder: $SortByOrder, limit: $limit, offset: $offset) {
    ID
    BookingID
    RegistrationStatus
    ArrivalDate
    DepartureDate
    CheckInDate
    CheckOutDate
    IsRoomUpgrade
    IsPrincipal
    SharerGuest
    RoomRate
    DiscountAmount
    PromoCode
    SharerNo
    AdvancePaymentSum
    TotalOutStandingAmount
    SharerRegistration {
      Guest {
        Contact {
          FullName
        }
      }
    }
    Booking {
      ID
      ArrivalDate
      DepartureDate
      BookingStatus
      BookingNo
      DebtorAccount
      ChargeToCityLedger
      Contact {
        FullName
        PhoneNo
        MobileNo
        Email
        NRIC
        Address {
          address
          country
          city
          state
          postCode
        }
      }
      Debtor {
        ID
        Name
        DebtorCategory
        Segment
      }
    }
    Guest {
      ID
      GuestType
      GuestHistoryCount
      Contact {
        FullName
        PhoneNo
        MobileNo
        Email
        NRIC
        Address {
          address
          country
          city
          state
          postCode
        }
      }
    }
    Room {
      ID
      RoomNo
      HKRoomStatus
    }
    RoomType {
      ID
      Code
    }
    RatePolicy {
      ID
      RateCode
    }
  }
}
    `;

/**
 * __useFrontDeskListingV2Query__
 *
 * To run a query within a React component, call `useFrontDeskListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontDeskListingV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      Status: // value for 'Status'
 *      SearchValue: // value for 'SearchValue'
 *      ArrStartDate: // value for 'ArrStartDate'
 *      ArrEndDate: // value for 'ArrEndDate'
 *      DepartStartDate: // value for 'DepartStartDate'
 *      DepartEndDate: // value for 'DepartEndDate'
 *      ReferenceNo: // value for 'ReferenceNo'
 *      SortBy: // value for 'SortBy'
 *      SortByOrder: // value for 'SortByOrder'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFrontDeskListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<FrontDeskListingV2Query, FrontDeskListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<FrontDeskListingV2Query, FrontDeskListingV2QueryVariables>(FrontDeskListingV2Document, baseOptions);
      }
export function useFrontDeskListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FrontDeskListingV2Query, FrontDeskListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FrontDeskListingV2Query, FrontDeskListingV2QueryVariables>(FrontDeskListingV2Document, baseOptions);
        }
export type FrontDeskListingV2QueryHookResult = ReturnType<typeof useFrontDeskListingV2Query>;
export type FrontDeskListingV2LazyQueryHookResult = ReturnType<typeof useFrontDeskListingV2LazyQuery>;
export type FrontDeskListingV2QueryResult = ApolloReactCommon.QueryResult<FrontDeskListingV2Query, FrontDeskListingV2QueryVariables>;
export const GuestInsertDocument = gql`
    mutation GuestInsert($ContactInput: [ContactInput!]!, $HotelID: String!, $BookingID: String!, $RegistrationID: String!, $AttachmentInput: [Upload!], $guestType: GuestType, $IsTTx: Boolean, $TTxReasonID: String) {
  GuestInsert(ContactInput: $ContactInput, HotelID: $HotelID, BookingID: $BookingID, RegistrationID: $RegistrationID, AttachmentInput: $AttachmentInput, guestType: $guestType, IsTTx: $IsTTx, TTxReasonID: $TTxReasonID)
}
    `;
export type GuestInsertMutationFn = ApolloReactCommon.MutationFunction<GuestInsertMutation, GuestInsertMutationVariables>;

/**
 * __useGuestInsertMutation__
 *
 * To run a mutation, you first call `useGuestInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestInsertMutation, { data, loading, error }] = useGuestInsertMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      guestType: // value for 'guestType'
 *      IsTTx: // value for 'IsTTx'
 *      TTxReasonID: // value for 'TTxReasonID'
 *   },
 * });
 */
export function useGuestInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestInsertMutation, GuestInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestInsertMutation, GuestInsertMutationVariables>(GuestInsertDocument, baseOptions);
      }
export type GuestInsertMutationHookResult = ReturnType<typeof useGuestInsertMutation>;
export type GuestInsertMutationResult = ApolloReactCommon.MutationResult<GuestInsertMutation>;
export type GuestInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestInsertMutation, GuestInsertMutationVariables>;
export const ContactPaginationDocument = gql`
    query ContactPagination($limit: Float, $offset: Float) {
  ContactPagination(offset: $offset, limit: $limit) {
    ID
    FullName
    Email
  }
}
    `;

/**
 * __useContactPaginationQuery__
 *
 * To run a query within a React component, call `useContactPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPaginationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useContactPaginationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContactPaginationQuery, ContactPaginationQueryVariables>) {
        return ApolloReactHooks.useQuery<ContactPaginationQuery, ContactPaginationQueryVariables>(ContactPaginationDocument, baseOptions);
      }
export function useContactPaginationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContactPaginationQuery, ContactPaginationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContactPaginationQuery, ContactPaginationQueryVariables>(ContactPaginationDocument, baseOptions);
        }
export type ContactPaginationQueryHookResult = ReturnType<typeof useContactPaginationQuery>;
export type ContactPaginationLazyQueryHookResult = ReturnType<typeof useContactPaginationLazyQuery>;
export type ContactPaginationQueryResult = ApolloReactCommon.QueryResult<ContactPaginationQuery, ContactPaginationQueryVariables>;
export const GuestProfileListbyRoomDocument = gql`
    query GuestProfileListbyRoom($BookingID: String!, $RoomID: String!) {
  GuestProfileListbyRoom(BookingID: $BookingID, RoomID: $RoomID) {
    ID
    VehicleNo
    Contact {
      ID
      FullName
      Nationality
      MobileNo
      NRIC
      PassportNo
      Email
      Address {
        address
        state
        country
        postCode
        city
      }
    }
  }
}
    `;

/**
 * __useGuestProfileListbyRoomQuery__
 *
 * To run a query within a React component, call `useGuestProfileListbyRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileListbyRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestProfileListbyRoomQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      RoomID: // value for 'RoomID'
 *   },
 * });
 */
export function useGuestProfileListbyRoomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestProfileListbyRoomQuery, GuestProfileListbyRoomQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestProfileListbyRoomQuery, GuestProfileListbyRoomQueryVariables>(GuestProfileListbyRoomDocument, baseOptions);
      }
export function useGuestProfileListbyRoomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestProfileListbyRoomQuery, GuestProfileListbyRoomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestProfileListbyRoomQuery, GuestProfileListbyRoomQueryVariables>(GuestProfileListbyRoomDocument, baseOptions);
        }
export type GuestProfileListbyRoomQueryHookResult = ReturnType<typeof useGuestProfileListbyRoomQuery>;
export type GuestProfileListbyRoomLazyQueryHookResult = ReturnType<typeof useGuestProfileListbyRoomLazyQuery>;
export type GuestProfileListbyRoomQueryResult = ApolloReactCommon.QueryResult<GuestProfileListbyRoomQuery, GuestProfileListbyRoomQueryVariables>;
export const FrontDeskBookingInfoDocument = gql`
    query FrontDeskBookingInfo($BookingID: String!, $HotelID: String!) {
  FrontDeskBookingInfo(BookingID: $BookingID, HotelID: $HotelID) {
    BookingNo
    FullName
    ContactNo
    BookingStatus
    ArrivalDate
    DepartureDate
    DebtorName
    DebtorID
    PromoCode
    DiscountAmount
    RoomInfo {
      RegistrationID
      RoomNo
      RoomType
      Description
      RateDescription
      TrxDate
      TrxAmount
      BaseAmount
      TaxAmount
      ServiceCharge
      SpecialRequest
      BookingRemark
      BillingInstruction
      IsTTx
      LedgerInfo {
        TrxAmount
        ServiceCharge
        TaxAmount
        Description
        BaseAmount
      }
      LedgerTax {
        TaxRate
        TaxAmount
        TrxDate
        TaxInfo {
          code
        }
      }
    }
  }
}
    `;

/**
 * __useFrontDeskBookingInfoQuery__
 *
 * To run a query within a React component, call `useFrontDeskBookingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskBookingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontDeskBookingInfoQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useFrontDeskBookingInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FrontDeskBookingInfoQuery, FrontDeskBookingInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<FrontDeskBookingInfoQuery, FrontDeskBookingInfoQueryVariables>(FrontDeskBookingInfoDocument, baseOptions);
      }
export function useFrontDeskBookingInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FrontDeskBookingInfoQuery, FrontDeskBookingInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FrontDeskBookingInfoQuery, FrontDeskBookingInfoQueryVariables>(FrontDeskBookingInfoDocument, baseOptions);
        }
export type FrontDeskBookingInfoQueryHookResult = ReturnType<typeof useFrontDeskBookingInfoQuery>;
export type FrontDeskBookingInfoLazyQueryHookResult = ReturnType<typeof useFrontDeskBookingInfoLazyQuery>;
export type FrontDeskBookingInfoQueryResult = ApolloReactCommon.QueryResult<FrontDeskBookingInfoQuery, FrontDeskBookingInfoQueryVariables>;
export const CancelCheckInDocument = gql`
    mutation CancelCheckIn($Remark: String, $ReasonID: String, $RoomID: String!, $RegistrationID: String!, $BookingID: String!) {
  CancelCheckIn(Remark: $Remark, ReasonID: $ReasonID, RoomID: $RoomID, RegistrationID: $RegistrationID, BookingID: $BookingID)
}
    `;
export type CancelCheckInMutationFn = ApolloReactCommon.MutationFunction<CancelCheckInMutation, CancelCheckInMutationVariables>;

/**
 * __useCancelCheckInMutation__
 *
 * To run a mutation, you first call `useCancelCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCheckInMutation, { data, loading, error }] = useCancelCheckInMutation({
 *   variables: {
 *      Remark: // value for 'Remark'
 *      ReasonID: // value for 'ReasonID'
 *      RoomID: // value for 'RoomID'
 *      RegistrationID: // value for 'RegistrationID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useCancelCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCheckInMutation, CancelCheckInMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCheckInMutation, CancelCheckInMutationVariables>(CancelCheckInDocument, baseOptions);
      }
export type CancelCheckInMutationHookResult = ReturnType<typeof useCancelCheckInMutation>;
export type CancelCheckInMutationResult = ApolloReactCommon.MutationResult<CancelCheckInMutation>;
export type CancelCheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCheckInMutation, CancelCheckInMutationVariables>;
export const MultiCheckInDocument = gql`
    mutation MultiCheckIn($GroupCheckIn: Boolean, $GuestAppCheckIn: Boolean, $ContactInput: [ContactInput!], $AdditionalMultiRegInput: [AdditionalMultiRegInput!], $RegistrationIDs: [String!]!, $HotelID: String!, $BookingID: String!) {
  MultiCheckIn(GroupCheckIn: $GroupCheckIn, GuestAppCheckIn: $GuestAppCheckIn, ContactInput: $ContactInput, AdditionalMultiRegInput: $AdditionalMultiRegInput, RegistrationIDs: $RegistrationIDs, HotelID: $HotelID, BookingID: $BookingID)
}
    `;
export type MultiCheckInMutationFn = ApolloReactCommon.MutationFunction<MultiCheckInMutation, MultiCheckInMutationVariables>;

/**
 * __useMultiCheckInMutation__
 *
 * To run a mutation, you first call `useMultiCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultiCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multiCheckInMutation, { data, loading, error }] = useMultiCheckInMutation({
 *   variables: {
 *      GroupCheckIn: // value for 'GroupCheckIn'
 *      GuestAppCheckIn: // value for 'GuestAppCheckIn'
 *      ContactInput: // value for 'ContactInput'
 *      AdditionalMultiRegInput: // value for 'AdditionalMultiRegInput'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useMultiCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MultiCheckInMutation, MultiCheckInMutationVariables>) {
        return ApolloReactHooks.useMutation<MultiCheckInMutation, MultiCheckInMutationVariables>(MultiCheckInDocument, baseOptions);
      }
export type MultiCheckInMutationHookResult = ReturnType<typeof useMultiCheckInMutation>;
export type MultiCheckInMutationResult = ApolloReactCommon.MutationResult<MultiCheckInMutation>;
export type MultiCheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<MultiCheckInMutation, MultiCheckInMutationVariables>;
export const CheckInDocument = gql`
    mutation CheckIn($IsPDPA: Boolean, $GroupCheckIn: Boolean, $GuestAppCheckIn: Boolean, $IdPicture: [Upload!], $IdPictureBack: [Upload!], $AttachmentInput: [Upload!], $ContactInput: ContactInput, $RegistrationIDs: [String!]!, $BookingID: String!, $HotelID: String!, $IsTTx: Boolean, $TTxReasonID: String) {
  CheckIn(IsPDPA: $IsPDPA, GroupCheckIn: $GroupCheckIn, GuestAppCheckIn: $GuestAppCheckIn, IdPicture: $IdPicture, IdPictureBack: $IdPictureBack, AttachmentInput: $AttachmentInput, ContactInput: $ContactInput, RegistrationIDs: $RegistrationIDs, BookingID: $BookingID, HotelID: $HotelID, IsTTx: $IsTTx, TTxReasonID: $TTxReasonID)
}
    `;
export type CheckInMutationFn = ApolloReactCommon.MutationFunction<CheckInMutation, CheckInMutationVariables>;

/**
 * __useCheckInMutation__
 *
 * To run a mutation, you first call `useCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInMutation, { data, loading, error }] = useCheckInMutation({
 *   variables: {
 *      IsPDPA: // value for 'IsPDPA'
 *      GroupCheckIn: // value for 'GroupCheckIn'
 *      GuestAppCheckIn: // value for 'GuestAppCheckIn'
 *      IdPicture: // value for 'IdPicture'
 *      IdPictureBack: // value for 'IdPictureBack'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      ContactInput: // value for 'ContactInput'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      IsTTx: // value for 'IsTTx'
 *      TTxReasonID: // value for 'TTxReasonID'
 *   },
 * });
 */
export function useCheckInMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckInMutation, CheckInMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckInMutation, CheckInMutationVariables>(CheckInDocument, baseOptions);
      }
export type CheckInMutationHookResult = ReturnType<typeof useCheckInMutation>;
export type CheckInMutationResult = ApolloReactCommon.MutationResult<CheckInMutation>;
export type CheckInMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckInMutation, CheckInMutationVariables>;
export const PreRegBookingInfoDocument = gql`
    query PreRegBookingInfo($BookingID: String!, $HotelID: String!, $RegistrationID: String, $QR: String) {
  PreRegBookingInfo(BookingID: $BookingID, HotelID: $HotelID, RegistrationID: $RegistrationID, QR: $QR)
}
    `;

/**
 * __usePreRegBookingInfoQuery__
 *
 * To run a query within a React component, call `usePreRegBookingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreRegBookingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreRegBookingInfoQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *      QR: // value for 'QR'
 *   },
 * });
 */
export function usePreRegBookingInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PreRegBookingInfoQuery, PreRegBookingInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<PreRegBookingInfoQuery, PreRegBookingInfoQueryVariables>(PreRegBookingInfoDocument, baseOptions);
      }
export function usePreRegBookingInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PreRegBookingInfoQuery, PreRegBookingInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PreRegBookingInfoQuery, PreRegBookingInfoQueryVariables>(PreRegBookingInfoDocument, baseOptions);
        }
export type PreRegBookingInfoQueryHookResult = ReturnType<typeof usePreRegBookingInfoQuery>;
export type PreRegBookingInfoLazyQueryHookResult = ReturnType<typeof usePreRegBookingInfoLazyQuery>;
export type PreRegBookingInfoQueryResult = ApolloReactCommon.QueryResult<PreRegBookingInfoQuery, PreRegBookingInfoQueryVariables>;
export const DueInSubmenuBookingDetailsDocument = gql`
    query DueInSubmenuBookingDetails($ID: String!, $HotelID: String!) {
  BookingDetails(ID: $ID, HotelID: $HotelID) {
    ID
    AccountID
    HotelID
    TotalAdvPayment
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
      CreditLimit
    }
    BookingNo
    ArrivalDate
    DepartureDate
    IsGroupBooking
    ChargeToCityLedger
    Source
    DebtorAccount
    BillingInstruction
    BookingStatus
    BookedRegistration {
      ID
      RoomID
      ArrivalDate
      BookingRemark
      DepartureDate
      CheckInDate
      SharerNo
      CheckOutDate
      NoOfAdult
      NoOfChild
      NoOfInfant
      IsPrincipal
      HouseLimit
      BillSchedule {
        ID
        ChargeType
        RateID
      }
      RoomLedger {
        ID
        ChargeType
      }
      AdvancePayment {
        ID
      }
      BillLedger {
        ID
        ReversedBillLedgerID
      }
      SpecialRequest
      RegistrationStatus
      ServiceRequestCount
      RoomTypeID
      ChargeRoomTypeID
      RoomType {
        Code
        MaxGuest
        ID
        Overbooking
      }
      Room {
        RoomNo
        ID
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      BookingID
      Booking {
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        IsCms
        IsCmsTtx
        Contact {
          FullName
        }
        Debtor {
          Name
          ID
          DebtorCategory
          DebtorAccount
        }
      }
      Guest {
        ID
        Contact {
          ID
          FullName
          BirthDate
          Email
          NRIC
          PhoneNo
          MobileNo
          Address {
            address
            country
            city
            state
            postCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDueInSubmenuBookingDetailsQuery__
 *
 * To run a query within a React component, call `useDueInSubmenuBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDueInSubmenuBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDueInSubmenuBookingDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDueInSubmenuBookingDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DueInSubmenuBookingDetailsQuery, DueInSubmenuBookingDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<DueInSubmenuBookingDetailsQuery, DueInSubmenuBookingDetailsQueryVariables>(DueInSubmenuBookingDetailsDocument, baseOptions);
      }
export function useDueInSubmenuBookingDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DueInSubmenuBookingDetailsQuery, DueInSubmenuBookingDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DueInSubmenuBookingDetailsQuery, DueInSubmenuBookingDetailsQueryVariables>(DueInSubmenuBookingDetailsDocument, baseOptions);
        }
export type DueInSubmenuBookingDetailsQueryHookResult = ReturnType<typeof useDueInSubmenuBookingDetailsQuery>;
export type DueInSubmenuBookingDetailsLazyQueryHookResult = ReturnType<typeof useDueInSubmenuBookingDetailsLazyQuery>;
export type DueInSubmenuBookingDetailsQueryResult = ApolloReactCommon.QueryResult<DueInSubmenuBookingDetailsQuery, DueInSubmenuBookingDetailsQueryVariables>;
export const FolioListingByRegistrationDocument = gql`
    query FolioListingByRegistration($HotelID: String!, $BookingID: String!, $RegistrationID: String!, $FolioID: String) {
  FolioListingByRegistration(HotelID: $HotelID, BookingID: $BookingID, RegistrationID: $RegistrationID, FolioID: $FolioID) {
    ID
    FolioNo
    FolioType
    FolioDate
    FolioAmount
    CreatedBy
    FolioName
    TaxLedger {
      TrxDate
      TaxType
      TaxDescription
      TaxAmount
      Reason
      Description
    }
    BillLedger {
      ID
      TrxDate
      ServiceCharge
      TaxAmount
      TrxAmount
      BaseAmount
      ReferenceNo
      IncidentalCharge {
        Description
      }
      Registration {
        Booking {
          BookingNo
          DebtorAccount
          Source
          ArrivalDate
          DepartureDate
          CreatedBy
          Contact {
            Nationality
            FullName
            PhoneNo
            Email
            Address {
              address
              address2
              city
              state
              postCode
              AddressType
              country
              siteName
            }
          }
        }
        TaxLedger {
          TaxType
          TaxDescription
          TaxAmount
          TrxDate
        }
        Room {
          Registrations {
            CheckInDate
            CheckOutDate
            IsPrincipal
            Guest {
              Contact {
                Nationality
                FullName
                PhoneNo
                MobileNo
                Email
                Address {
                  address
                  address2
                  city
                  state
                  postCode
                  AddressType
                  country
                  siteName
                }
              }
            }
          }
          RoomNo
          RoomType {
            Code
          }
        }
      }
    }
    RoomLedger {
      ID
      TrxAmount
      TaxAmount
      BaseAmount
      ServiceCharge
      TrxDate
      ReferenceNo
      TransactionType
      ChargeType
      TransactionID
      Transaction {
        ID
        Description
      }
      Registration {
        Booking {
          BookingNo
          DebtorAccount
          Source
          ArrivalDate
          DepartureDate
          CreatedBy
          Contact {
            Nationality
            FullName
            PhoneNo
            Email
            Address {
              address
              address2
              city
              state
              postCode
              AddressType
              country
              siteName
            }
          }
        }
        TaxLedger {
          TaxType
          TaxDescription
          TaxAmount
        }
        Room {
          Registrations {
            CheckInDate
            CheckOutDate
            IsPrincipal
            Guest {
              Contact {
                FullName
                PhoneNo
                Email
                Nationality
                Address {
                  address
                  address2
                  city
                  state
                  postCode
                  AddressType
                  country
                  siteName
                }
              }
            }
          }
          RoomNo
          RoomType {
            Code
          }
        }
      }
    }
    DepositLedger {
      DepositAmount
      DepositClass
      ReferenceNo
      TrxDate
      Registration {
        Booking {
          BookingNo
          DebtorAccount
          Source
          ArrivalDate
          DepartureDate
          CreatedBy
          Contact {
            Nationality
            FullName
            PhoneNo
            Email
            Address {
              address
              address2
              city
              state
              postCode
              AddressType
              country
              siteName
            }
          }
        }
        TaxLedger {
          TaxType
          TaxDescription
          TaxAmount
        }
        Room {
          Registrations {
            CheckInDate
            CheckOutDate
            IsPrincipal
            Guest {
              Contact {
                Nationality
                FullName
                PhoneNo
                Email
                Address {
                  address
                  address2
                  city
                  state
                  postCode
                  AddressType
                  country
                  siteName
                }
              }
            }
          }
          RoomNo
          RoomType {
            Code
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFolioListingByRegistrationQuery__
 *
 * To run a query within a React component, call `useFolioListingByRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolioListingByRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolioListingByRegistrationQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function useFolioListingByRegistrationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FolioListingByRegistrationQuery, FolioListingByRegistrationQueryVariables>) {
        return ApolloReactHooks.useQuery<FolioListingByRegistrationQuery, FolioListingByRegistrationQueryVariables>(FolioListingByRegistrationDocument, baseOptions);
      }
export function useFolioListingByRegistrationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FolioListingByRegistrationQuery, FolioListingByRegistrationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FolioListingByRegistrationQuery, FolioListingByRegistrationQueryVariables>(FolioListingByRegistrationDocument, baseOptions);
        }
export type FolioListingByRegistrationQueryHookResult = ReturnType<typeof useFolioListingByRegistrationQuery>;
export type FolioListingByRegistrationLazyQueryHookResult = ReturnType<typeof useFolioListingByRegistrationLazyQuery>;
export type FolioListingByRegistrationQueryResult = ApolloReactCommon.QueryResult<FolioListingByRegistrationQuery, FolioListingByRegistrationQueryVariables>;
export const FolioHistoryListingDocument = gql`
    query FolioHistoryListing($HotelID: String!, $BookingID: String!, $RegistrationID: String!) {
  FolioHistoryListing(HotelID: $HotelID, BookingID: $BookingID, RegistrationID: $RegistrationID) {
    ID
    InvoiceNo
    TrxDate
    RoomNo
    RoomType
    GuestName
    TrxAmount
    FolioType
  }
}
    `;

/**
 * __useFolioHistoryListingQuery__
 *
 * To run a query within a React component, call `useFolioHistoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolioHistoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolioHistoryListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useFolioHistoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FolioHistoryListingQuery, FolioHistoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FolioHistoryListingQuery, FolioHistoryListingQueryVariables>(FolioHistoryListingDocument, baseOptions);
      }
export function useFolioHistoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FolioHistoryListingQuery, FolioHistoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FolioHistoryListingQuery, FolioHistoryListingQueryVariables>(FolioHistoryListingDocument, baseOptions);
        }
export type FolioHistoryListingQueryHookResult = ReturnType<typeof useFolioHistoryListingQuery>;
export type FolioHistoryListingLazyQueryHookResult = ReturnType<typeof useFolioHistoryListingLazyQuery>;
export type FolioHistoryListingQueryResult = ApolloReactCommon.QueryResult<FolioHistoryListingQuery, FolioHistoryListingQueryVariables>;
export const CheckOutDocument = gql`
    mutation CheckOut($BookingID: String!, $RegistrationIDs: [String!]!, $HotelID: String!, $RoomID: String!) {
  CheckOut(BookingID: $BookingID, RegistrationIDs: $RegistrationIDs, HotelID: $HotelID, RoomID: $RoomID)
}
    `;
export type CheckOutMutationFn = ApolloReactCommon.MutationFunction<CheckOutMutation, CheckOutMutationVariables>;

/**
 * __useCheckOutMutation__
 *
 * To run a mutation, you first call `useCheckOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkOutMutation, { data, loading, error }] = useCheckOutMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *      HotelID: // value for 'HotelID'
 *      RoomID: // value for 'RoomID'
 *   },
 * });
 */
export function useCheckOutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckOutMutation, CheckOutMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckOutMutation, CheckOutMutationVariables>(CheckOutDocument, baseOptions);
      }
export type CheckOutMutationHookResult = ReturnType<typeof useCheckOutMutation>;
export type CheckOutMutationResult = ApolloReactCommon.MutationResult<CheckOutMutation>;
export type CheckOutMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckOutMutation, CheckOutMutationVariables>;
export const FdReinstateBookingDocument = gql`
    mutation FDReinstateBooking($BookingID: String!, $HotelID: String!, $RegistrationID: String!, $ReasonID: String, $Remark: String, $SharerNo: Float!) {
  FDReinstateBooking(BookingID: $BookingID, HotelID: $HotelID, RegistrationID: $RegistrationID, ReasonID: $ReasonID, Remark: $Remark, SharerNo: $SharerNo)
}
    `;
export type FdReinstateBookingMutationFn = ApolloReactCommon.MutationFunction<FdReinstateBookingMutation, FdReinstateBookingMutationVariables>;

/**
 * __useFdReinstateBookingMutation__
 *
 * To run a mutation, you first call `useFdReinstateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFdReinstateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fdReinstateBookingMutation, { data, loading, error }] = useFdReinstateBookingMutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *      ReasonID: // value for 'ReasonID'
 *      Remark: // value for 'Remark'
 *      SharerNo: // value for 'SharerNo'
 *   },
 * });
 */
export function useFdReinstateBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FdReinstateBookingMutation, FdReinstateBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<FdReinstateBookingMutation, FdReinstateBookingMutationVariables>(FdReinstateBookingDocument, baseOptions);
      }
export type FdReinstateBookingMutationHookResult = ReturnType<typeof useFdReinstateBookingMutation>;
export type FdReinstateBookingMutationResult = ApolloReactCommon.MutationResult<FdReinstateBookingMutation>;
export type FdReinstateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<FdReinstateBookingMutation, FdReinstateBookingMutationVariables>;
export const CheckoutReinstateInfoDocument = gql`
    query CheckoutReinstateInfo($RegistrationID: String!) {
  CheckoutReinstateInfo(RegistrationID: $RegistrationID) {
    ReasonCode
    ModifiedBy
  }
}
    `;

/**
 * __useCheckoutReinstateInfoQuery__
 *
 * To run a query within a React component, call `useCheckoutReinstateInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckoutReinstateInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckoutReinstateInfoQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useCheckoutReinstateInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckoutReinstateInfoQuery, CheckoutReinstateInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckoutReinstateInfoQuery, CheckoutReinstateInfoQueryVariables>(CheckoutReinstateInfoDocument, baseOptions);
      }
export function useCheckoutReinstateInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckoutReinstateInfoQuery, CheckoutReinstateInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckoutReinstateInfoQuery, CheckoutReinstateInfoQueryVariables>(CheckoutReinstateInfoDocument, baseOptions);
        }
export type CheckoutReinstateInfoQueryHookResult = ReturnType<typeof useCheckoutReinstateInfoQuery>;
export type CheckoutReinstateInfoLazyQueryHookResult = ReturnType<typeof useCheckoutReinstateInfoLazyQuery>;
export type CheckoutReinstateInfoQueryResult = ApolloReactCommon.QueryResult<CheckoutReinstateInfoQuery, CheckoutReinstateInfoQueryVariables>;
export const DueOutSubmenuBookingDetailsDocument = gql`
    query DueOutSubmenuBookingDetails($ID: String!, $HotelID: String!) {
  BookingDetails(ID: $ID, HotelID: $HotelID) {
    ID
    AccountID
    HotelID
    TotalAdvPayment
    Contact {
      FullName
      BirthDate
      PhoneNo
      Email
      Nationality
      Designation
      Address {
        address
        country
        city
        state
        postCode
      }
    }
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
      CreditLimit
    }
    BookingNo
    ArrivalDate
    DepartureDate
    IsGroupBooking
    ChargeToCityLedger
    Source
    DebtorAccount
    BillingInstruction
    BookingStatus
    BookedRegistration {
      BookingRemark
      ID
      RoomID
      ArrivalDate
      DepartureDate
      CheckInDate
      SharerNo
      CheckOutDate
      NoOfAdult
      NoOfChild
      NoOfInfant
      IsPrincipal
      HouseLimit
      BillSchedule {
        ID
        ChargeType
        RateID
      }
      RoomLedger {
        ID
        ChargeType
      }
      AdvancePayment {
        ID
      }
      BillLedger {
        ID
      }
      SpecialRequest
      RegistrationStatus
      ServiceRequestCount
      RoomTypeID
      ChargeRoomTypeID
      Reason
      ModifiedDT
      RoomType {
        Code
        MaxGuest
        ID
        Overbooking
      }
      Room {
        RoomNo
        ID
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      BookingID
      Booking {
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        IsCms
        IsCmsTtx
        Contact {
          FullName
        }
        Debtor {
          Name
          ID
          DebtorCategory
          DebtorAccount
        }
      }
      Guest {
        ID
        Contact {
          ID
          FullName
          BirthDate
          Email
          NRIC
          PhoneNo
          MobileNo
          Address {
            address
            country
            city
            state
            postCode
          }
        }
      }
    }
  }
}
    `;

/**
 * __useDueOutSubmenuBookingDetailsQuery__
 *
 * To run a query within a React component, call `useDueOutSubmenuBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDueOutSubmenuBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDueOutSubmenuBookingDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDueOutSubmenuBookingDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DueOutSubmenuBookingDetailsQuery, DueOutSubmenuBookingDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<DueOutSubmenuBookingDetailsQuery, DueOutSubmenuBookingDetailsQueryVariables>(DueOutSubmenuBookingDetailsDocument, baseOptions);
      }
export function useDueOutSubmenuBookingDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DueOutSubmenuBookingDetailsQuery, DueOutSubmenuBookingDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DueOutSubmenuBookingDetailsQuery, DueOutSubmenuBookingDetailsQueryVariables>(DueOutSubmenuBookingDetailsDocument, baseOptions);
        }
export type DueOutSubmenuBookingDetailsQueryHookResult = ReturnType<typeof useDueOutSubmenuBookingDetailsQuery>;
export type DueOutSubmenuBookingDetailsLazyQueryHookResult = ReturnType<typeof useDueOutSubmenuBookingDetailsLazyQuery>;
export type DueOutSubmenuBookingDetailsQueryResult = ApolloReactCommon.QueryResult<DueOutSubmenuBookingDetailsQuery, DueOutSubmenuBookingDetailsQueryVariables>;
export const FrontDeskInfoLinesDocument = gql`
    query FrontDeskInfoLines($HotelID: String!) {
  FrontDeskInfoLines(HotelID: $HotelID) {
    TotalDueIn
    TotalCheckIn
    TotalGroupDueIn
    TotalGroupCheckIn
    TotalWalkIn
    RegistrationDueOut
    DepartureDueOut
    TotalDepartureDueOut
    TotalRegistrationDueOut
    TotalRoom
    TotalGuest
    DepositSum
    TotalDepositRoom
    TotalUnAssignedRoomRegistration
    TotalNonGuestFolio
    TotalSuspendFolio
    TotalContactTracingOutlet
  }
}
    `;

/**
 * __useFrontDeskInfoLinesQuery__
 *
 * To run a query within a React component, call `useFrontDeskInfoLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskInfoLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontDeskInfoLinesQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useFrontDeskInfoLinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FrontDeskInfoLinesQuery, FrontDeskInfoLinesQueryVariables>) {
        return ApolloReactHooks.useQuery<FrontDeskInfoLinesQuery, FrontDeskInfoLinesQueryVariables>(FrontDeskInfoLinesDocument, baseOptions);
      }
export function useFrontDeskInfoLinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FrontDeskInfoLinesQuery, FrontDeskInfoLinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FrontDeskInfoLinesQuery, FrontDeskInfoLinesQueryVariables>(FrontDeskInfoLinesDocument, baseOptions);
        }
export type FrontDeskInfoLinesQueryHookResult = ReturnType<typeof useFrontDeskInfoLinesQuery>;
export type FrontDeskInfoLinesLazyQueryHookResult = ReturnType<typeof useFrontDeskInfoLinesLazyQuery>;
export type FrontDeskInfoLinesQueryResult = ApolloReactCommon.QueryResult<FrontDeskInfoLinesQuery, FrontDeskInfoLinesQueryVariables>;
export const GetAdjustmentTaxDocument = gql`
    query GetAdjustmentTax($HotelID: String!, $EffectiveDate: DateTime) {
  GetAdjustmentTax(HotelID: $HotelID, EffectiveDate: $EffectiveDate) {
    TTx
    TTxAmount
    SSF
    SSFAmount
  }
}
    `;

/**
 * __useGetAdjustmentTaxQuery__
 *
 * To run a query within a React component, call `useGetAdjustmentTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdjustmentTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdjustmentTaxQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      EffectiveDate: // value for 'EffectiveDate'
 *   },
 * });
 */
export function useGetAdjustmentTaxQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdjustmentTaxQuery, GetAdjustmentTaxQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdjustmentTaxQuery, GetAdjustmentTaxQueryVariables>(GetAdjustmentTaxDocument, baseOptions);
      }
export function useGetAdjustmentTaxLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdjustmentTaxQuery, GetAdjustmentTaxQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdjustmentTaxQuery, GetAdjustmentTaxQueryVariables>(GetAdjustmentTaxDocument, baseOptions);
        }
export type GetAdjustmentTaxQueryHookResult = ReturnType<typeof useGetAdjustmentTaxQuery>;
export type GetAdjustmentTaxLazyQueryHookResult = ReturnType<typeof useGetAdjustmentTaxLazyQuery>;
export type GetAdjustmentTaxQueryResult = ApolloReactCommon.QueryResult<GetAdjustmentTaxQuery, GetAdjustmentTaxQueryVariables>;
export const RegistrationTaxLedgerListingDocument = gql`
    query RegistrationTaxLedgerListing($HotelID: String, $RegistrationID: String, $FolioID: String) {
  RegistrationTaxLedgerListing(HotelID: $HotelID, RegistrationID: $RegistrationID, FolioID: $FolioID) {
    ID
    RegistrationID
    Registration {
      Guest {
        Contact {
          FullName
        }
      }
      Room {
        RoomNo
      }
    }
    FolioID
    PaymentID
    ReversedTaxLedgerID
    CreatedDT
    CreatedBy
    TrxDate
    TaxType
    TaxDescription
    Description
    IsAdjustment
    Qty
    TaxAmount
    HotelID
  }
}
    `;

/**
 * __useRegistrationTaxLedgerListingQuery__
 *
 * To run a query within a React component, call `useRegistrationTaxLedgerListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationTaxLedgerListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationTaxLedgerListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function useRegistrationTaxLedgerListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistrationTaxLedgerListingQuery, RegistrationTaxLedgerListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistrationTaxLedgerListingQuery, RegistrationTaxLedgerListingQueryVariables>(RegistrationTaxLedgerListingDocument, baseOptions);
      }
export function useRegistrationTaxLedgerListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistrationTaxLedgerListingQuery, RegistrationTaxLedgerListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistrationTaxLedgerListingQuery, RegistrationTaxLedgerListingQueryVariables>(RegistrationTaxLedgerListingDocument, baseOptions);
        }
export type RegistrationTaxLedgerListingQueryHookResult = ReturnType<typeof useRegistrationTaxLedgerListingQuery>;
export type RegistrationTaxLedgerListingLazyQueryHookResult = ReturnType<typeof useRegistrationTaxLedgerListingLazyQuery>;
export type RegistrationTaxLedgerListingQueryResult = ApolloReactCommon.QueryResult<RegistrationTaxLedgerListingQuery, RegistrationTaxLedgerListingQueryVariables>;
export const AllRoomAsignListingDocument = gql`
    query AllRoomAsignListing($HotelID: String!, $StartDate: DateTime!) {
  AllRoomAsignListing(HotelID: $HotelID, StartDate: $StartDate) {
    ID
    Description
    RoomNo
    RoomType {
      Code
      Description
    }
    Registrations {
      ID
      ArrivalDate
      DepartureDate
      RegistrationStatus
    }
    Location {
      Code
      Description
    }
    RoomStatusCode
  }
}
    `;

/**
 * __useAllRoomAsignListingQuery__
 *
 * To run a query within a React component, call `useAllRoomAsignListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRoomAsignListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRoomAsignListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useAllRoomAsignListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllRoomAsignListingQuery, AllRoomAsignListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AllRoomAsignListingQuery, AllRoomAsignListingQueryVariables>(AllRoomAsignListingDocument, baseOptions);
      }
export function useAllRoomAsignListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllRoomAsignListingQuery, AllRoomAsignListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllRoomAsignListingQuery, AllRoomAsignListingQueryVariables>(AllRoomAsignListingDocument, baseOptions);
        }
export type AllRoomAsignListingQueryHookResult = ReturnType<typeof useAllRoomAsignListingQuery>;
export type AllRoomAsignListingLazyQueryHookResult = ReturnType<typeof useAllRoomAsignListingLazyQuery>;
export type AllRoomAsignListingQueryResult = ApolloReactCommon.QueryResult<AllRoomAsignListingQuery, AllRoomAsignListingQueryVariables>;
export const IsPaymentGateWayHotelDocument = gql`
    query IsPaymentGateWayHotel($HotelID: String!) {
  IsPaymentGateWayHotel(HotelID: $HotelID)
}
    `;

/**
 * __useIsPaymentGateWayHotelQuery__
 *
 * To run a query within a React component, call `useIsPaymentGateWayHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPaymentGateWayHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPaymentGateWayHotelQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useIsPaymentGateWayHotelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>) {
        return ApolloReactHooks.useQuery<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>(IsPaymentGateWayHotelDocument, baseOptions);
      }
export function useIsPaymentGateWayHotelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>(IsPaymentGateWayHotelDocument, baseOptions);
        }
export type IsPaymentGateWayHotelQueryHookResult = ReturnType<typeof useIsPaymentGateWayHotelQuery>;
export type IsPaymentGateWayHotelLazyQueryHookResult = ReturnType<typeof useIsPaymentGateWayHotelLazyQuery>;
export type IsPaymentGateWayHotelQueryResult = ApolloReactCommon.QueryResult<IsPaymentGateWayHotelQuery, IsPaymentGateWayHotelQueryVariables>;
export const FolioBeneficiaryNameUpdateDocument = gql`
    mutation FolioBeneficiaryNameUpdate($FolioID: String!, $BeneficiaryName: String!, $BeneficiaryAddress: String!) {
  FolioBeneficiaryNameUpdate(FolioID: $FolioID, BeneficiaryName: $BeneficiaryName, BeneficiaryAddress: $BeneficiaryAddress)
}
    `;
export type FolioBeneficiaryNameUpdateMutationFn = ApolloReactCommon.MutationFunction<FolioBeneficiaryNameUpdateMutation, FolioBeneficiaryNameUpdateMutationVariables>;

/**
 * __useFolioBeneficiaryNameUpdateMutation__
 *
 * To run a mutation, you first call `useFolioBeneficiaryNameUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFolioBeneficiaryNameUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [folioBeneficiaryNameUpdateMutation, { data, loading, error }] = useFolioBeneficiaryNameUpdateMutation({
 *   variables: {
 *      FolioID: // value for 'FolioID'
 *      BeneficiaryName: // value for 'BeneficiaryName'
 *      BeneficiaryAddress: // value for 'BeneficiaryAddress'
 *   },
 * });
 */
export function useFolioBeneficiaryNameUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FolioBeneficiaryNameUpdateMutation, FolioBeneficiaryNameUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<FolioBeneficiaryNameUpdateMutation, FolioBeneficiaryNameUpdateMutationVariables>(FolioBeneficiaryNameUpdateDocument, baseOptions);
      }
export type FolioBeneficiaryNameUpdateMutationHookResult = ReturnType<typeof useFolioBeneficiaryNameUpdateMutation>;
export type FolioBeneficiaryNameUpdateMutationResult = ApolloReactCommon.MutationResult<FolioBeneficiaryNameUpdateMutation>;
export type FolioBeneficiaryNameUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<FolioBeneficiaryNameUpdateMutation, FolioBeneficiaryNameUpdateMutationVariables>;
export const TaxLedgerInsertDocument = gql`
    mutation TaxLedgerInsert($TaxLedgerInput: [TaxLedgerInput!]!) {
  TaxLedgerInsert(TaxLedgerInput: $TaxLedgerInput)
}
    `;
export type TaxLedgerInsertMutationFn = ApolloReactCommon.MutationFunction<TaxLedgerInsertMutation, TaxLedgerInsertMutationVariables>;

/**
 * __useTaxLedgerInsertMutation__
 *
 * To run a mutation, you first call `useTaxLedgerInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxLedgerInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxLedgerInsertMutation, { data, loading, error }] = useTaxLedgerInsertMutation({
 *   variables: {
 *      TaxLedgerInput: // value for 'TaxLedgerInput'
 *   },
 * });
 */
export function useTaxLedgerInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxLedgerInsertMutation, TaxLedgerInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxLedgerInsertMutation, TaxLedgerInsertMutationVariables>(TaxLedgerInsertDocument, baseOptions);
      }
export type TaxLedgerInsertMutationHookResult = ReturnType<typeof useTaxLedgerInsertMutation>;
export type TaxLedgerInsertMutationResult = ApolloReactCommon.MutationResult<TaxLedgerInsertMutation>;
export type TaxLedgerInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxLedgerInsertMutation, TaxLedgerInsertMutationVariables>;
export const LockUserProcessDocument = gql`
    mutation LockUserProcess($RoomNo: [String!]!, $Process: String!, $HotelID: String!, $UserID: String!) {
  LockUserProcess(RoomNo: $RoomNo, Process: $Process, HotelID: $HotelID, UserID: $UserID)
}
    `;
export type LockUserProcessMutationFn = ApolloReactCommon.MutationFunction<LockUserProcessMutation, LockUserProcessMutationVariables>;

/**
 * __useLockUserProcessMutation__
 *
 * To run a mutation, you first call `useLockUserProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockUserProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockUserProcessMutation, { data, loading, error }] = useLockUserProcessMutation({
 *   variables: {
 *      RoomNo: // value for 'RoomNo'
 *      Process: // value for 'Process'
 *      HotelID: // value for 'HotelID'
 *      UserID: // value for 'UserID'
 *   },
 * });
 */
export function useLockUserProcessMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LockUserProcessMutation, LockUserProcessMutationVariables>) {
        return ApolloReactHooks.useMutation<LockUserProcessMutation, LockUserProcessMutationVariables>(LockUserProcessDocument, baseOptions);
      }
export type LockUserProcessMutationHookResult = ReturnType<typeof useLockUserProcessMutation>;
export type LockUserProcessMutationResult = ApolloReactCommon.MutationResult<LockUserProcessMutation>;
export type LockUserProcessMutationOptions = ApolloReactCommon.BaseMutationOptions<LockUserProcessMutation, LockUserProcessMutationVariables>;
export const IsLockUserProcessDocument = gql`
    query IsLockUserProcess($RoomNo: String!, $HotelID: String!) {
  IsLockUserProcess(RoomNo: $RoomNo, HotelID: $HotelID)
}
    `;

/**
 * __useIsLockUserProcessQuery__
 *
 * To run a query within a React component, call `useIsLockUserProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLockUserProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLockUserProcessQuery({
 *   variables: {
 *      RoomNo: // value for 'RoomNo'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useIsLockUserProcessQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsLockUserProcessQuery, IsLockUserProcessQueryVariables>) {
        return ApolloReactHooks.useQuery<IsLockUserProcessQuery, IsLockUserProcessQueryVariables>(IsLockUserProcessDocument, baseOptions);
      }
export function useIsLockUserProcessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsLockUserProcessQuery, IsLockUserProcessQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsLockUserProcessQuery, IsLockUserProcessQueryVariables>(IsLockUserProcessDocument, baseOptions);
        }
export type IsLockUserProcessQueryHookResult = ReturnType<typeof useIsLockUserProcessQuery>;
export type IsLockUserProcessLazyQueryHookResult = ReturnType<typeof useIsLockUserProcessLazyQuery>;
export type IsLockUserProcessQueryResult = ApolloReactCommon.QueryResult<IsLockUserProcessQuery, IsLockUserProcessQueryVariables>;
export const FrontDeskIncidentalChargeListingDocument = gql`
    query FrontDeskIncidentalChargeListing($RegistrationID: String!) {
  FrontDeskIncidentalChargeListing(RegistrationID: $RegistrationID) {
    ID
    TrxDate
    ReferenceNo
    CreatedBy
    CreatedName
    ModifiedDT
    CreatedDT
    ModifiedBy
    Description
    Qty
    UnitPrice
    ReversedBillLedgerID
    FolioID
    DiscountAmount
    TrxAmount
    TaxAmount
    Reason
    ServiceCharge
    BaseAmount
    TaxBillLedger {
      TaxSchemeID
      TaxID
      TaxRate
      TaxAmount
      ID
      BillLedgerID
      TaxInfo {
        code
      }
    }
    IncidentalCharge {
      ID
      Description
      IsOutlet
      IsEventInterface
    }
    Registration {
      ID
      ModifiedDT
      Guest {
        ID
        Contact {
          FullName
        }
      }
    }
    TaxBillLedger {
      ID
      TaxID
      TaxSchemeID
      TaxRate
      TaxAmount
      BillLedgerID
      TaxInfo {
        code
      }
    }
  }
}
    `;

/**
 * __useFrontDeskIncidentalChargeListingQuery__
 *
 * To run a query within a React component, call `useFrontDeskIncidentalChargeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskIncidentalChargeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontDeskIncidentalChargeListingQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useFrontDeskIncidentalChargeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FrontDeskIncidentalChargeListingQuery, FrontDeskIncidentalChargeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FrontDeskIncidentalChargeListingQuery, FrontDeskIncidentalChargeListingQueryVariables>(FrontDeskIncidentalChargeListingDocument, baseOptions);
      }
export function useFrontDeskIncidentalChargeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FrontDeskIncidentalChargeListingQuery, FrontDeskIncidentalChargeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FrontDeskIncidentalChargeListingQuery, FrontDeskIncidentalChargeListingQueryVariables>(FrontDeskIncidentalChargeListingDocument, baseOptions);
        }
export type FrontDeskIncidentalChargeListingQueryHookResult = ReturnType<typeof useFrontDeskIncidentalChargeListingQuery>;
export type FrontDeskIncidentalChargeListingLazyQueryHookResult = ReturnType<typeof useFrontDeskIncidentalChargeListingLazyQuery>;
export type FrontDeskIncidentalChargeListingQueryResult = ApolloReactCommon.QueryResult<FrontDeskIncidentalChargeListingQuery, FrontDeskIncidentalChargeListingQueryVariables>;
export const VoidBillLedgerDocument = gql`
    mutation VoidBillLedger($input: BillLedgerInput!) {
  VoidBillLedger(input: $input)
}
    `;
export type VoidBillLedgerMutationFn = ApolloReactCommon.MutationFunction<VoidBillLedgerMutation, VoidBillLedgerMutationVariables>;

/**
 * __useVoidBillLedgerMutation__
 *
 * To run a mutation, you first call `useVoidBillLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidBillLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidBillLedgerMutation, { data, loading, error }] = useVoidBillLedgerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVoidBillLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VoidBillLedgerMutation, VoidBillLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<VoidBillLedgerMutation, VoidBillLedgerMutationVariables>(VoidBillLedgerDocument, baseOptions);
      }
export type VoidBillLedgerMutationHookResult = ReturnType<typeof useVoidBillLedgerMutation>;
export type VoidBillLedgerMutationResult = ApolloReactCommon.MutationResult<VoidBillLedgerMutation>;
export type VoidBillLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<VoidBillLedgerMutation, VoidBillLedgerMutationVariables>;
export const ComputeTaxbyHotelDocument = gql`
    query ComputeTaxbyHotel($input: TaxInput!) {
  ComputeTaxbyHotel(input: $input) {
    DiscountAmount
    BaseAmount
    TaxAmount
    ServiceCharge
    TotalAmount
    HotelTaxOutput {
      TaxCode
      Value
      TaxID
      TaxRate
      SeqNo
      TaxScheme
    }
  }
}
    `;

/**
 * __useComputeTaxbyHotelQuery__
 *
 * To run a query within a React component, call `useComputeTaxbyHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeTaxbyHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeTaxbyHotelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useComputeTaxbyHotelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ComputeTaxbyHotelQuery, ComputeTaxbyHotelQueryVariables>) {
        return ApolloReactHooks.useQuery<ComputeTaxbyHotelQuery, ComputeTaxbyHotelQueryVariables>(ComputeTaxbyHotelDocument, baseOptions);
      }
export function useComputeTaxbyHotelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ComputeTaxbyHotelQuery, ComputeTaxbyHotelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ComputeTaxbyHotelQuery, ComputeTaxbyHotelQueryVariables>(ComputeTaxbyHotelDocument, baseOptions);
        }
export type ComputeTaxbyHotelQueryHookResult = ReturnType<typeof useComputeTaxbyHotelQuery>;
export type ComputeTaxbyHotelLazyQueryHookResult = ReturnType<typeof useComputeTaxbyHotelLazyQuery>;
export type ComputeTaxbyHotelQueryResult = ApolloReactCommon.QueryResult<ComputeTaxbyHotelQuery, ComputeTaxbyHotelQueryVariables>;
export const ComputeUpgradebyHotelDocument = gql`
    query ComputeUpgradebyHotel($HotelID: String!, $RateID: String!, $RegistrationID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $Mode: String!, $RoomTypeID: String, $Amount: Float) {
  ComputeUpgradebyHotel(HotelID: $HotelID, RateID: $RateID, RegistrationID: $RegistrationID, Mode: $Mode, RoomTypeID: $RoomTypeID, StartDate: $StartDate, EndDate: $EndDate, Amount: $Amount) {
    DiscountAmount
    BaseAmount
    TaxAmount
    ServiceCharge
    TotalAmount
    HotelTaxOutput {
      TaxCode
      Value
      TaxID
      TaxRate
      SeqNo
      TaxScheme
    }
  }
}
    `;

/**
 * __useComputeUpgradebyHotelQuery__
 *
 * To run a query within a React component, call `useComputeUpgradebyHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeUpgradebyHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeUpgradebyHotelQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RateID: // value for 'RateID'
 *      RegistrationID: // value for 'RegistrationID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      Mode: // value for 'Mode'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      Amount: // value for 'Amount'
 *   },
 * });
 */
export function useComputeUpgradebyHotelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ComputeUpgradebyHotelQuery, ComputeUpgradebyHotelQueryVariables>) {
        return ApolloReactHooks.useQuery<ComputeUpgradebyHotelQuery, ComputeUpgradebyHotelQueryVariables>(ComputeUpgradebyHotelDocument, baseOptions);
      }
export function useComputeUpgradebyHotelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ComputeUpgradebyHotelQuery, ComputeUpgradebyHotelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ComputeUpgradebyHotelQuery, ComputeUpgradebyHotelQueryVariables>(ComputeUpgradebyHotelDocument, baseOptions);
        }
export type ComputeUpgradebyHotelQueryHookResult = ReturnType<typeof useComputeUpgradebyHotelQuery>;
export type ComputeUpgradebyHotelLazyQueryHookResult = ReturnType<typeof useComputeUpgradebyHotelLazyQuery>;
export type ComputeUpgradebyHotelQueryResult = ApolloReactCommon.QueryResult<ComputeUpgradebyHotelQuery, ComputeUpgradebyHotelQueryVariables>;
export const IsRoomAvailableDocument = gql`
    query IsRoomAvailable($HotelID: String!, $RoomID: String!, $RegistrationID: String!) {
  IsRoomAvailable(HotelID: $HotelID, RoomID: $RoomID, RegistrationID: $RegistrationID)
}
    `;

/**
 * __useIsRoomAvailableQuery__
 *
 * To run a query within a React component, call `useIsRoomAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsRoomAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsRoomAvailableQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomID: // value for 'RoomID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useIsRoomAvailableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsRoomAvailableQuery, IsRoomAvailableQueryVariables>) {
        return ApolloReactHooks.useQuery<IsRoomAvailableQuery, IsRoomAvailableQueryVariables>(IsRoomAvailableDocument, baseOptions);
      }
export function useIsRoomAvailableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsRoomAvailableQuery, IsRoomAvailableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsRoomAvailableQuery, IsRoomAvailableQueryVariables>(IsRoomAvailableDocument, baseOptions);
        }
export type IsRoomAvailableQueryHookResult = ReturnType<typeof useIsRoomAvailableQuery>;
export type IsRoomAvailableLazyQueryHookResult = ReturnType<typeof useIsRoomAvailableLazyQuery>;
export type IsRoomAvailableQueryResult = ApolloReactCommon.QueryResult<IsRoomAvailableQuery, IsRoomAvailableQueryVariables>;
export const IsRoomAvailableGroupDocument = gql`
    query IsRoomAvailableGroup($HotelID: String!, $RoomIDs: [String!]!, $RegistrationIDs: [String!]!) {
  IsRoomAvailableGroup(HotelID: $HotelID, RoomIDs: $RoomIDs, RegistrationIDs: $RegistrationIDs)
}
    `;

/**
 * __useIsRoomAvailableGroupQuery__
 *
 * To run a query within a React component, call `useIsRoomAvailableGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsRoomAvailableGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsRoomAvailableGroupQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomIDs: // value for 'RoomIDs'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *   },
 * });
 */
export function useIsRoomAvailableGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsRoomAvailableGroupQuery, IsRoomAvailableGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<IsRoomAvailableGroupQuery, IsRoomAvailableGroupQueryVariables>(IsRoomAvailableGroupDocument, baseOptions);
      }
export function useIsRoomAvailableGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsRoomAvailableGroupQuery, IsRoomAvailableGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsRoomAvailableGroupQuery, IsRoomAvailableGroupQueryVariables>(IsRoomAvailableGroupDocument, baseOptions);
        }
export type IsRoomAvailableGroupQueryHookResult = ReturnType<typeof useIsRoomAvailableGroupQuery>;
export type IsRoomAvailableGroupLazyQueryHookResult = ReturnType<typeof useIsRoomAvailableGroupLazyQuery>;
export type IsRoomAvailableGroupQueryResult = ApolloReactCommon.QueryResult<IsRoomAvailableGroupQuery, IsRoomAvailableGroupQueryVariables>;
export const SpecialReqUpdateDocument = gql`
    mutation SpecialReqUpdate($RegistrationID: String!, $SpecialReq: String!) {
  SpecialReqUpdate(RegistrationID: $RegistrationID, SpecialReq: $SpecialReq)
}
    `;
export type SpecialReqUpdateMutationFn = ApolloReactCommon.MutationFunction<SpecialReqUpdateMutation, SpecialReqUpdateMutationVariables>;

/**
 * __useSpecialReqUpdateMutation__
 *
 * To run a mutation, you first call `useSpecialReqUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpecialReqUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [specialReqUpdateMutation, { data, loading, error }] = useSpecialReqUpdateMutation({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      SpecialReq: // value for 'SpecialReq'
 *   },
 * });
 */
export function useSpecialReqUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SpecialReqUpdateMutation, SpecialReqUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SpecialReqUpdateMutation, SpecialReqUpdateMutationVariables>(SpecialReqUpdateDocument, baseOptions);
      }
export type SpecialReqUpdateMutationHookResult = ReturnType<typeof useSpecialReqUpdateMutation>;
export type SpecialReqUpdateMutationResult = ApolloReactCommon.MutationResult<SpecialReqUpdateMutation>;
export type SpecialReqUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SpecialReqUpdateMutation, SpecialReqUpdateMutationVariables>;
export const LateCheckOutInsertDocument = gql`
    mutation LateCheckOutInsert($hour: Float!, $input: FrontDeskPaymentInput!, $ReceivedAmount: Float, $AdjustedAmount: Float) {
  LateCheckOutInsert(hour: $hour, input: $input, ReceivedAmount: $ReceivedAmount, AdjustedAmount: $AdjustedAmount)
}
    `;
export type LateCheckOutInsertMutationFn = ApolloReactCommon.MutationFunction<LateCheckOutInsertMutation, LateCheckOutInsertMutationVariables>;

/**
 * __useLateCheckOutInsertMutation__
 *
 * To run a mutation, you first call `useLateCheckOutInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLateCheckOutInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lateCheckOutInsertMutation, { data, loading, error }] = useLateCheckOutInsertMutation({
 *   variables: {
 *      hour: // value for 'hour'
 *      input: // value for 'input'
 *      ReceivedAmount: // value for 'ReceivedAmount'
 *      AdjustedAmount: // value for 'AdjustedAmount'
 *   },
 * });
 */
export function useLateCheckOutInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LateCheckOutInsertMutation, LateCheckOutInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LateCheckOutInsertMutation, LateCheckOutInsertMutationVariables>(LateCheckOutInsertDocument, baseOptions);
      }
export type LateCheckOutInsertMutationHookResult = ReturnType<typeof useLateCheckOutInsertMutation>;
export type LateCheckOutInsertMutationResult = ApolloReactCommon.MutationResult<LateCheckOutInsertMutation>;
export type LateCheckOutInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LateCheckOutInsertMutation, LateCheckOutInsertMutationVariables>;
export const ExtendStayInsertDocument = gql`
    mutation ExtendStayInsert($RoomTypeID: String!, $ArrivalDate: DateTime!, $DepartureDate: DateTime!, $input: FrontDeskPaymentInput!, $ReceivedAmount: Float!, $AdjustedAmount: Float!) {
  ExtendStayInsert(RoomTypeID: $RoomTypeID, ArrivalDate: $ArrivalDate, DepartureDate: $DepartureDate, input: $input, ReceivedAmount: $ReceivedAmount, AdjustedAmount: $AdjustedAmount)
}
    `;
export type ExtendStayInsertMutationFn = ApolloReactCommon.MutationFunction<ExtendStayInsertMutation, ExtendStayInsertMutationVariables>;

/**
 * __useExtendStayInsertMutation__
 *
 * To run a mutation, you first call `useExtendStayInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendStayInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendStayInsertMutation, { data, loading, error }] = useExtendStayInsertMutation({
 *   variables: {
 *      RoomTypeID: // value for 'RoomTypeID'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      DepartureDate: // value for 'DepartureDate'
 *      input: // value for 'input'
 *      ReceivedAmount: // value for 'ReceivedAmount'
 *      AdjustedAmount: // value for 'AdjustedAmount'
 *   },
 * });
 */
export function useExtendStayInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ExtendStayInsertMutation, ExtendStayInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ExtendStayInsertMutation, ExtendStayInsertMutationVariables>(ExtendStayInsertDocument, baseOptions);
      }
export type ExtendStayInsertMutationHookResult = ReturnType<typeof useExtendStayInsertMutation>;
export type ExtendStayInsertMutationResult = ApolloReactCommon.MutationResult<ExtendStayInsertMutation>;
export type ExtendStayInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ExtendStayInsertMutation, ExtendStayInsertMutationVariables>;
export const ShortenStayInsertDocument = gql`
    mutation ShortenStayInsert($RoomTypeID: String!, $RegistrationID: String!, $BookingID: String!, $ArrivalDate: DateTime!, $DepartureDate: DateTime!) {
  ShortenStayInsert(RoomTypeID: $RoomTypeID, ArrivalDate: $ArrivalDate, DepartureDate: $DepartureDate, RegistrationID: $RegistrationID, BookingID: $BookingID)
}
    `;
export type ShortenStayInsertMutationFn = ApolloReactCommon.MutationFunction<ShortenStayInsertMutation, ShortenStayInsertMutationVariables>;

/**
 * __useShortenStayInsertMutation__
 *
 * To run a mutation, you first call `useShortenStayInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShortenStayInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shortenStayInsertMutation, { data, loading, error }] = useShortenStayInsertMutation({
 *   variables: {
 *      RoomTypeID: // value for 'RoomTypeID'
 *      RegistrationID: // value for 'RegistrationID'
 *      BookingID: // value for 'BookingID'
 *      ArrivalDate: // value for 'ArrivalDate'
 *      DepartureDate: // value for 'DepartureDate'
 *   },
 * });
 */
export function useShortenStayInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShortenStayInsertMutation, ShortenStayInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ShortenStayInsertMutation, ShortenStayInsertMutationVariables>(ShortenStayInsertDocument, baseOptions);
      }
export type ShortenStayInsertMutationHookResult = ReturnType<typeof useShortenStayInsertMutation>;
export type ShortenStayInsertMutationResult = ApolloReactCommon.MutationResult<ShortenStayInsertMutation>;
export type ShortenStayInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ShortenStayInsertMutation, ShortenStayInsertMutationVariables>;
export const RoomUpGradeInsertDocument = gql`
    mutation RoomUpGradeInsert($RoomUpgradeInput: RoomUpgradeInput!, $PaymentInput: FrontDeskPaymentInput, $IsBooking: Boolean, $IsBaypassCMSControl: Boolean, $ReceivedAmount: Float!, $AdjustedAmount: Float!) {
  RoomUpGradeInsert(RoomUpgradeInput: $RoomUpgradeInput, PaymentInput: $PaymentInput, IsBooking: $IsBooking, IsBaypassCMSControl: $IsBaypassCMSControl, ReceivedAmount: $ReceivedAmount, AdjustedAmount: $AdjustedAmount)
}
    `;
export type RoomUpGradeInsertMutationFn = ApolloReactCommon.MutationFunction<RoomUpGradeInsertMutation, RoomUpGradeInsertMutationVariables>;

/**
 * __useRoomUpGradeInsertMutation__
 *
 * To run a mutation, you first call `useRoomUpGradeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomUpGradeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomUpGradeInsertMutation, { data, loading, error }] = useRoomUpGradeInsertMutation({
 *   variables: {
 *      RoomUpgradeInput: // value for 'RoomUpgradeInput'
 *      PaymentInput: // value for 'PaymentInput'
 *      IsBooking: // value for 'IsBooking'
 *      IsBaypassCMSControl: // value for 'IsBaypassCMSControl'
 *      ReceivedAmount: // value for 'ReceivedAmount'
 *      AdjustedAmount: // value for 'AdjustedAmount'
 *   },
 * });
 */
export function useRoomUpGradeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RoomUpGradeInsertMutation, RoomUpGradeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RoomUpGradeInsertMutation, RoomUpGradeInsertMutationVariables>(RoomUpGradeInsertDocument, baseOptions);
      }
export type RoomUpGradeInsertMutationHookResult = ReturnType<typeof useRoomUpGradeInsertMutation>;
export type RoomUpGradeInsertMutationResult = ApolloReactCommon.MutationResult<RoomUpGradeInsertMutation>;
export type RoomUpGradeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RoomUpGradeInsertMutation, RoomUpGradeInsertMutationVariables>;
export const RoomUpgradeCheckDocument = gql`
    query RoomUpgradeCheck($HotelID: String!, $RegistrationID: String!) {
  RoomUpgradeCheck(HotelID: $HotelID, RegistrationID: $RegistrationID)
}
    `;

/**
 * __useRoomUpgradeCheckQuery__
 *
 * To run a query within a React component, call `useRoomUpgradeCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomUpgradeCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomUpgradeCheckQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useRoomUpgradeCheckQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomUpgradeCheckQuery, RoomUpgradeCheckQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomUpgradeCheckQuery, RoomUpgradeCheckQueryVariables>(RoomUpgradeCheckDocument, baseOptions);
      }
export function useRoomUpgradeCheckLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomUpgradeCheckQuery, RoomUpgradeCheckQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomUpgradeCheckQuery, RoomUpgradeCheckQueryVariables>(RoomUpgradeCheckDocument, baseOptions);
        }
export type RoomUpgradeCheckQueryHookResult = ReturnType<typeof useRoomUpgradeCheckQuery>;
export type RoomUpgradeCheckLazyQueryHookResult = ReturnType<typeof useRoomUpgradeCheckLazyQuery>;
export type RoomUpgradeCheckQueryResult = ApolloReactCommon.QueryResult<RoomUpgradeCheckQuery, RoomUpgradeCheckQueryVariables>;
export const HouseLimitUpdateDocument = gql`
    mutation HouseLimitUpdate($HouseLimitInput: [HouseLimitInput!]) {
  HouseLimitUpdate(HouseLimitInput: $HouseLimitInput)
}
    `;
export type HouseLimitUpdateMutationFn = ApolloReactCommon.MutationFunction<HouseLimitUpdateMutation, HouseLimitUpdateMutationVariables>;

/**
 * __useHouseLimitUpdateMutation__
 *
 * To run a mutation, you first call `useHouseLimitUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHouseLimitUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [houseLimitUpdateMutation, { data, loading, error }] = useHouseLimitUpdateMutation({
 *   variables: {
 *      HouseLimitInput: // value for 'HouseLimitInput'
 *   },
 * });
 */
export function useHouseLimitUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HouseLimitUpdateMutation, HouseLimitUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<HouseLimitUpdateMutation, HouseLimitUpdateMutationVariables>(HouseLimitUpdateDocument, baseOptions);
      }
export type HouseLimitUpdateMutationHookResult = ReturnType<typeof useHouseLimitUpdateMutation>;
export type HouseLimitUpdateMutationResult = ApolloReactCommon.MutationResult<HouseLimitUpdateMutation>;
export type HouseLimitUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<HouseLimitUpdateMutation, HouseLimitUpdateMutationVariables>;
export const TransferRoomDocument = gql`
    mutation TransferRoom($HotelID: String!, $BookingID: String!, $RegistrationID: String!, $OldRoomID: String!, $NewRoomID: String!, $Remark: String, $Reason: String) {
  TransferRoom(HotelID: $HotelID, BookingID: $BookingID, RegistrationID: $RegistrationID, OldRoomID: $OldRoomID, NewRoomID: $NewRoomID, Remark: $Remark, Reason: $Reason)
}
    `;
export type TransferRoomMutationFn = ApolloReactCommon.MutationFunction<TransferRoomMutation, TransferRoomMutationVariables>;

/**
 * __useTransferRoomMutation__
 *
 * To run a mutation, you first call `useTransferRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferRoomMutation, { data, loading, error }] = useTransferRoomMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      OldRoomID: // value for 'OldRoomID'
 *      NewRoomID: // value for 'NewRoomID'
 *      Remark: // value for 'Remark'
 *      Reason: // value for 'Reason'
 *   },
 * });
 */
export function useTransferRoomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferRoomMutation, TransferRoomMutationVariables>) {
        return ApolloReactHooks.useMutation<TransferRoomMutation, TransferRoomMutationVariables>(TransferRoomDocument, baseOptions);
      }
export type TransferRoomMutationHookResult = ReturnType<typeof useTransferRoomMutation>;
export type TransferRoomMutationResult = ApolloReactCommon.MutationResult<TransferRoomMutation>;
export type TransferRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferRoomMutation, TransferRoomMutationVariables>;
export const RegistrationListingDocument = gql`
    query RegistrationListing($RoomID: String!) {
  RegistrationListing(RoomID: $RoomID) {
    ID
    CheckInDate
    CheckOutDate
    RegistrationStatus
    Booking {
      ArrivalDate
      DepartureDate
    }
  }
}
    `;

/**
 * __useRegistrationListingQuery__
 *
 * To run a query within a React component, call `useRegistrationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationListingQuery({
 *   variables: {
 *      RoomID: // value for 'RoomID'
 *   },
 * });
 */
export function useRegistrationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistrationListingQuery, RegistrationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistrationListingQuery, RegistrationListingQueryVariables>(RegistrationListingDocument, baseOptions);
      }
export function useRegistrationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistrationListingQuery, RegistrationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistrationListingQuery, RegistrationListingQueryVariables>(RegistrationListingDocument, baseOptions);
        }
export type RegistrationListingQueryHookResult = ReturnType<typeof useRegistrationListingQuery>;
export type RegistrationListingLazyQueryHookResult = ReturnType<typeof useRegistrationListingLazyQuery>;
export type RegistrationListingQueryResult = ApolloReactCommon.QueryResult<RegistrationListingQuery, RegistrationListingQueryVariables>;
export const RateAdjustmentAuditListingDocument = gql`
    query RateAdjustmentAuditListing($RegistrationID: String!, $HotelID: String!) {
  RateAdjustmentAuditListing(RegistrationID: $RegistrationID, HotelID: $HotelID) {
    ID
    HotelID
    BookingID
    RegistrationID
    OldRateID
    NewRateID
    EffectiveDate
    Reason
    OldPromoCode
    OldRoomRate
    OldDiscountAmount
    Registration {
      ID
      ArrivalDate
      DepartureDate
      RoomRate
      DiscountAmount
      PromoCode
      RoomType {
        ID
        Code
        Description
      }
      Room {
        ID
        RoomNo
      }
    }
    OldRate {
      ID
      RateCode
      Description
    }
    NewRate {
      ID
      RateCode
      Description
    }
  }
}
    `;

/**
 * __useRateAdjustmentAuditListingQuery__
 *
 * To run a query within a React component, call `useRateAdjustmentAuditListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateAdjustmentAuditListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateAdjustmentAuditListingQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRateAdjustmentAuditListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RateAdjustmentAuditListingQuery, RateAdjustmentAuditListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RateAdjustmentAuditListingQuery, RateAdjustmentAuditListingQueryVariables>(RateAdjustmentAuditListingDocument, baseOptions);
      }
export function useRateAdjustmentAuditListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RateAdjustmentAuditListingQuery, RateAdjustmentAuditListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RateAdjustmentAuditListingQuery, RateAdjustmentAuditListingQueryVariables>(RateAdjustmentAuditListingDocument, baseOptions);
        }
export type RateAdjustmentAuditListingQueryHookResult = ReturnType<typeof useRateAdjustmentAuditListingQuery>;
export type RateAdjustmentAuditListingLazyQueryHookResult = ReturnType<typeof useRateAdjustmentAuditListingLazyQuery>;
export type RateAdjustmentAuditListingQueryResult = ApolloReactCommon.QueryResult<RateAdjustmentAuditListingQuery, RateAdjustmentAuditListingQueryVariables>;
export const RateAdjustmentAuditInsertDocument = gql`
    mutation RateAdjustmentAuditInsert($RateAdjustmentAuditInput: RateAdjustmentAuditInput!) {
  RateAdjustmentAuditInsert(RateAdjustmentAuditInput: $RateAdjustmentAuditInput)
}
    `;
export type RateAdjustmentAuditInsertMutationFn = ApolloReactCommon.MutationFunction<RateAdjustmentAuditInsertMutation, RateAdjustmentAuditInsertMutationVariables>;

/**
 * __useRateAdjustmentAuditInsertMutation__
 *
 * To run a mutation, you first call `useRateAdjustmentAuditInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateAdjustmentAuditInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateAdjustmentAuditInsertMutation, { data, loading, error }] = useRateAdjustmentAuditInsertMutation({
 *   variables: {
 *      RateAdjustmentAuditInput: // value for 'RateAdjustmentAuditInput'
 *   },
 * });
 */
export function useRateAdjustmentAuditInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateAdjustmentAuditInsertMutation, RateAdjustmentAuditInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RateAdjustmentAuditInsertMutation, RateAdjustmentAuditInsertMutationVariables>(RateAdjustmentAuditInsertDocument, baseOptions);
      }
export type RateAdjustmentAuditInsertMutationHookResult = ReturnType<typeof useRateAdjustmentAuditInsertMutation>;
export type RateAdjustmentAuditInsertMutationResult = ApolloReactCommon.MutationResult<RateAdjustmentAuditInsertMutation>;
export type RateAdjustmentAuditInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RateAdjustmentAuditInsertMutation, RateAdjustmentAuditInsertMutationVariables>;
export const InhouseQrDocument = gql`
    query InhouseQr($BookingID: String!, $HotelID: String!, $RegistrationID: String!) {
  InhouseQr(BookingID: $BookingID, HotelID: $HotelID, RegistrationID: $RegistrationID)
}
    `;

/**
 * __useInhouseQrQuery__
 *
 * To run a query within a React component, call `useInhouseQrQuery` and pass it any options that fit your needs.
 * When your component renders, `useInhouseQrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInhouseQrQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useInhouseQrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InhouseQrQuery, InhouseQrQueryVariables>) {
        return ApolloReactHooks.useQuery<InhouseQrQuery, InhouseQrQueryVariables>(InhouseQrDocument, baseOptions);
      }
export function useInhouseQrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InhouseQrQuery, InhouseQrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InhouseQrQuery, InhouseQrQueryVariables>(InhouseQrDocument, baseOptions);
        }
export type InhouseQrQueryHookResult = ReturnType<typeof useInhouseQrQuery>;
export type InhouseQrLazyQueryHookResult = ReturnType<typeof useInhouseQrLazyQuery>;
export type InhouseQrQueryResult = ApolloReactCommon.QueryResult<InhouseQrQuery, InhouseQrQueryVariables>;
export const InHouseSubmenuBookingDetailsDocument = gql`
    query InHouseSubmenuBookingDetails($ID: String!, $HotelID: String!) {
  BookingDetails(ID: $ID, HotelID: $HotelID) {
    ID
    AccountID
    HotelID
    TotalAdvPayment
    Contact {
      ID
      FullName
      PhoneNo
      MobileNo
      Email
      NRIC
      Address {
        address
        country
        city
        state
        postCode
      }
    }
    Debtor {
      DebtorAccount
      DebtorCategory
      ID
      Name
      CreditLimit
      IseCommerce
    }
    BookingNo
    ArrivalDate
    DepartureDate
    isPrepaid
    IsGroupBooking
    ChargeToCityLedger
    Source
    DebtorAccount
    BillingInstruction
    BookingStatus
    BookedRegistration {
      ID
      RoomID
      BookingRemark
      ArrivalDate
      DepartureDate
      CheckInDate
      SharerNo
      CheckOutDate
      NoOfAdult
      NoOfChild
      NoOfInfant
      IsPrincipal
      HouseLimit
      BillSchedule {
        ID
        ChargeType
        RateID
      }
      RoomLedger {
        ID
        ChargeType
      }
      AdvancePayment {
        ID
      }
      BillLedger {
        ID
      }
      SpecialRequest
      RegistrationStatus
      ServiceRequestCount
      RoomTypeID
      ChargeRoomTypeID
      RoomType {
        Code
        MaxGuest
        ID
        Overbooking
      }
      Room {
        RoomNo
        ID
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      BookingID
      Booking {
        isPrepaid
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        IsCms
        IsCmsTtx
        Contact {
          FullName
        }
        Debtor {
          Name
          ID
          DebtorCategory
          DebtorAccount
        }
      }
      Guest {
        ID
        Contact {
          ID
          FullName
          BirthDate
          Email
          NRIC
          PhoneNo
          MobileNo
          Address {
            address
            country
            city
            state
            postCode
          }
        }
      }
      PassCode
    }
  }
}
    `;

/**
 * __useInHouseSubmenuBookingDetailsQuery__
 *
 * To run a query within a React component, call `useInHouseSubmenuBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInHouseSubmenuBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInHouseSubmenuBookingDetailsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useInHouseSubmenuBookingDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InHouseSubmenuBookingDetailsQuery, InHouseSubmenuBookingDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<InHouseSubmenuBookingDetailsQuery, InHouseSubmenuBookingDetailsQueryVariables>(InHouseSubmenuBookingDetailsDocument, baseOptions);
      }
export function useInHouseSubmenuBookingDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InHouseSubmenuBookingDetailsQuery, InHouseSubmenuBookingDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InHouseSubmenuBookingDetailsQuery, InHouseSubmenuBookingDetailsQueryVariables>(InHouseSubmenuBookingDetailsDocument, baseOptions);
        }
export type InHouseSubmenuBookingDetailsQueryHookResult = ReturnType<typeof useInHouseSubmenuBookingDetailsQuery>;
export type InHouseSubmenuBookingDetailsLazyQueryHookResult = ReturnType<typeof useInHouseSubmenuBookingDetailsLazyQuery>;
export type InHouseSubmenuBookingDetailsQueryResult = ApolloReactCommon.QueryResult<InHouseSubmenuBookingDetailsQuery, InHouseSubmenuBookingDetailsQueryVariables>;
export const FrontDeskSubMenuInfoDocument = gql`
    query FrontDeskSubMenuInfo($HotelID: String!, $RegistrationIDs: [String!]) {
  FrontDeskSubMenuInfo(HotelID: $HotelID, RegistrationIDs: $RegistrationIDs) {
    RegistrationID
    BillScheduleSum
    IncidentalChargesSum
    TotalOutStandingAmount
    DepositSum
    FolioCount
    PaymentSum
    AdvPaymentSum
    ServiceRequestCount
  }
}
    `;

/**
 * __useFrontDeskSubMenuInfoQuery__
 *
 * To run a query within a React component, call `useFrontDeskSubMenuInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskSubMenuInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontDeskSubMenuInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *   },
 * });
 */
export function useFrontDeskSubMenuInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FrontDeskSubMenuInfoQuery, FrontDeskSubMenuInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<FrontDeskSubMenuInfoQuery, FrontDeskSubMenuInfoQueryVariables>(FrontDeskSubMenuInfoDocument, baseOptions);
      }
export function useFrontDeskSubMenuInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FrontDeskSubMenuInfoQuery, FrontDeskSubMenuInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FrontDeskSubMenuInfoQuery, FrontDeskSubMenuInfoQueryVariables>(FrontDeskSubMenuInfoDocument, baseOptions);
        }
export type FrontDeskSubMenuInfoQueryHookResult = ReturnType<typeof useFrontDeskSubMenuInfoQuery>;
export type FrontDeskSubMenuInfoLazyQueryHookResult = ReturnType<typeof useFrontDeskSubMenuInfoLazyQuery>;
export type FrontDeskSubMenuInfoQueryResult = ApolloReactCommon.QueryResult<FrontDeskSubMenuInfoQuery, FrontDeskSubMenuInfoQueryVariables>;
export const IsPresetIncChargesExistDocument = gql`
    query IsPresetIncChargesExist($BookingID: String!, $RegistrationID: String!, $newDepartureDate: DateTime!) {
  IsPresetIncChargesExist(BookingID: $BookingID, RegistrationID: $RegistrationID, newDepartureDate: $newDepartureDate)
}
    `;

/**
 * __useIsPresetIncChargesExistQuery__
 *
 * To run a query within a React component, call `useIsPresetIncChargesExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPresetIncChargesExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPresetIncChargesExistQuery({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      RegistrationID: // value for 'RegistrationID'
 *      newDepartureDate: // value for 'newDepartureDate'
 *   },
 * });
 */
export function useIsPresetIncChargesExistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsPresetIncChargesExistQuery, IsPresetIncChargesExistQueryVariables>) {
        return ApolloReactHooks.useQuery<IsPresetIncChargesExistQuery, IsPresetIncChargesExistQueryVariables>(IsPresetIncChargesExistDocument, baseOptions);
      }
export function useIsPresetIncChargesExistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsPresetIncChargesExistQuery, IsPresetIncChargesExistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsPresetIncChargesExistQuery, IsPresetIncChargesExistQueryVariables>(IsPresetIncChargesExistDocument, baseOptions);
        }
export type IsPresetIncChargesExistQueryHookResult = ReturnType<typeof useIsPresetIncChargesExistQuery>;
export type IsPresetIncChargesExistLazyQueryHookResult = ReturnType<typeof useIsPresetIncChargesExistLazyQuery>;
export type IsPresetIncChargesExistQueryResult = ApolloReactCommon.QueryResult<IsPresetIncChargesExistQuery, IsPresetIncChargesExistQueryVariables>;
export const KeyCardInsertDocument = gql`
    mutation KeyCardInsert($input: KeyCardInput!, $UserID: String!, $HotelID: String, $AccountID: String) {
  KeyCardInsert(input: $input, UserID: $UserID, HotelID: $HotelID, AccountID: $AccountID)
}
    `;
export type KeyCardInsertMutationFn = ApolloReactCommon.MutationFunction<KeyCardInsertMutation, KeyCardInsertMutationVariables>;

/**
 * __useKeyCardInsertMutation__
 *
 * To run a mutation, you first call `useKeyCardInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useKeyCardInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [keyCardInsertMutation, { data, loading, error }] = useKeyCardInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      UserID: // value for 'UserID'
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useKeyCardInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<KeyCardInsertMutation, KeyCardInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<KeyCardInsertMutation, KeyCardInsertMutationVariables>(KeyCardInsertDocument, baseOptions);
      }
export type KeyCardInsertMutationHookResult = ReturnType<typeof useKeyCardInsertMutation>;
export type KeyCardInsertMutationResult = ApolloReactCommon.MutationResult<KeyCardInsertMutation>;
export type KeyCardInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<KeyCardInsertMutation, KeyCardInsertMutationVariables>;
export const TTlockInfoDocument = gql`
    query TTlockInfo($HotelID: String, $RegistrationID: String) {
  TTlockInfo(HotelID: $HotelID, RegistrationID: $RegistrationID)
}
    `;

/**
 * __useTTlockInfoQuery__
 *
 * To run a query within a React component, call `useTTlockInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTTlockInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTTlockInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useTTlockInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TTlockInfoQuery, TTlockInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<TTlockInfoQuery, TTlockInfoQueryVariables>(TTlockInfoDocument, baseOptions);
      }
export function useTTlockInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TTlockInfoQuery, TTlockInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TTlockInfoQuery, TTlockInfoQueryVariables>(TTlockInfoDocument, baseOptions);
        }
export type TTlockInfoQueryHookResult = ReturnType<typeof useTTlockInfoQuery>;
export type TTlockInfoLazyQueryHookResult = ReturnType<typeof useTTlockInfoLazyQuery>;
export type TTlockInfoQueryResult = ApolloReactCommon.QueryResult<TTlockInfoQuery, TTlockInfoQueryVariables>;
export const NonGuestFolioListingDocument = gql`
    query NonGuestFolioListing($HotelID: String!, $FolioID: String) {
  NonGuestFolioListing(HotelID: $HotelID, FolioID: $FolioID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    AccountID
    HotelID
    NonGuestBalance {
      TotalBill
      TotalPaid
      TotalLeft
    }
    FolioType
    RefID
    FolioNo
    FolioDate
    FolioAmount
    ClosedDate
    ClosedBy
    IsPosted
    is_einvoice
    einvoice_status
    NonGuestInfo {
      EffectiveDate
      Title
      DebtorID
      Remark
      HouseLimit
      ReferenceID
    }
  }
}
    `;

/**
 * __useNonGuestFolioListingQuery__
 *
 * To run a query within a React component, call `useNonGuestFolioListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonGuestFolioListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonGuestFolioListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function useNonGuestFolioListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NonGuestFolioListingQuery, NonGuestFolioListingQueryVariables>) {
        return ApolloReactHooks.useQuery<NonGuestFolioListingQuery, NonGuestFolioListingQueryVariables>(NonGuestFolioListingDocument, baseOptions);
      }
export function useNonGuestFolioListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NonGuestFolioListingQuery, NonGuestFolioListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NonGuestFolioListingQuery, NonGuestFolioListingQueryVariables>(NonGuestFolioListingDocument, baseOptions);
        }
export type NonGuestFolioListingQueryHookResult = ReturnType<typeof useNonGuestFolioListingQuery>;
export type NonGuestFolioListingLazyQueryHookResult = ReturnType<typeof useNonGuestFolioListingLazyQuery>;
export type NonGuestFolioListingQueryResult = ApolloReactCommon.QueryResult<NonGuestFolioListingQuery, NonGuestFolioListingQueryVariables>;
export const NonGuestFolioListingv2Document = gql`
    query NonGuestFolioListingv2($limit: Float, $offset: Float, $HotelID: String!, $FolioID: String, $ReferenceID: String, $Title: String, $FolioDate: DateTime, $DebtorID: String) {
  NonGuestFolioListingv2(limit: $limit, offset: $offset, HotelID: $HotelID, FolioID: $FolioID, ReferenceID: $ReferenceID, Title: $Title, FolioDate: $FolioDate, DebtorID: $DebtorID) {
    TotalCount
    Listing {
      ID
      IsActive
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      AccountID
      HotelID
      NonGuestBalance {
        TotalBill
        TotalPaid
        TotalLeft
      }
      FolioType
      RefID
      FolioNo
      FolioDate
      FolioAmount
      ClosedDate
      ClosedBy
      IsPosted
      NonGuestInfo {
        EffectiveDate
        Title
        DebtorID
        Remark
        HouseLimit
        ReferenceID
      }
    }
  }
}
    `;

/**
 * __useNonGuestFolioListingv2Query__
 *
 * To run a query within a React component, call `useNonGuestFolioListingv2Query` and pass it any options that fit your needs.
 * When your component renders, `useNonGuestFolioListingv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonGuestFolioListingv2Query({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      HotelID: // value for 'HotelID'
 *      FolioID: // value for 'FolioID'
 *      ReferenceID: // value for 'ReferenceID'
 *      Title: // value for 'Title'
 *      FolioDate: // value for 'FolioDate'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useNonGuestFolioListingv2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<NonGuestFolioListingv2Query, NonGuestFolioListingv2QueryVariables>) {
        return ApolloReactHooks.useQuery<NonGuestFolioListingv2Query, NonGuestFolioListingv2QueryVariables>(NonGuestFolioListingv2Document, baseOptions);
      }
export function useNonGuestFolioListingv2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NonGuestFolioListingv2Query, NonGuestFolioListingv2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NonGuestFolioListingv2Query, NonGuestFolioListingv2QueryVariables>(NonGuestFolioListingv2Document, baseOptions);
        }
export type NonGuestFolioListingv2QueryHookResult = ReturnType<typeof useNonGuestFolioListingv2Query>;
export type NonGuestFolioListingv2LazyQueryHookResult = ReturnType<typeof useNonGuestFolioListingv2LazyQuery>;
export type NonGuestFolioListingv2QueryResult = ApolloReactCommon.QueryResult<NonGuestFolioListingv2Query, NonGuestFolioListingv2QueryVariables>;
export const NonGuestFolioInsertDocument = gql`
    mutation NonGuestFolioInsert($FolioInput: FolioInput!) {
  NonGuestFolioInsert(FolioInput: $FolioInput)
}
    `;
export type NonGuestFolioInsertMutationFn = ApolloReactCommon.MutationFunction<NonGuestFolioInsertMutation, NonGuestFolioInsertMutationVariables>;

/**
 * __useNonGuestFolioInsertMutation__
 *
 * To run a mutation, you first call `useNonGuestFolioInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNonGuestFolioInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nonGuestFolioInsertMutation, { data, loading, error }] = useNonGuestFolioInsertMutation({
 *   variables: {
 *      FolioInput: // value for 'FolioInput'
 *   },
 * });
 */
export function useNonGuestFolioInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NonGuestFolioInsertMutation, NonGuestFolioInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<NonGuestFolioInsertMutation, NonGuestFolioInsertMutationVariables>(NonGuestFolioInsertDocument, baseOptions);
      }
export type NonGuestFolioInsertMutationHookResult = ReturnType<typeof useNonGuestFolioInsertMutation>;
export type NonGuestFolioInsertMutationResult = ApolloReactCommon.MutationResult<NonGuestFolioInsertMutation>;
export type NonGuestFolioInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<NonGuestFolioInsertMutation, NonGuestFolioInsertMutationVariables>;
export const NonGuestBillingListingDocument = gql`
    query NonGuestBillingListing($HotelID: String, $FolioID: String!) {
  NonGuestBillingListing(HotelID: $HotelID, FolioID: $FolioID) {
    ID
    IncidentalDescription
    EffectiveDate
    ReferenceNo
    ReversedID
    TrxAmount
    TaxAmount
    ServiceCharge
    BaseAmount
    DiscountAmount
    Qty
    Type
    ReceiptNo
    Description
    CreatedBy
    CreatedDT
    IsOutlet
    IsEventInterface
    TaxBillLedger {
      ID
      BillLedgerID
      TaxSchemeID
      TaxSchemeDetailID
      TaxID
      TaxRate
      TaxAmount
      TaxInfo {
        code
      }
    }
  }
}
    `;

/**
 * __useNonGuestBillingListingQuery__
 *
 * To run a query within a React component, call `useNonGuestBillingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonGuestBillingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonGuestBillingListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function useNonGuestBillingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NonGuestBillingListingQuery, NonGuestBillingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<NonGuestBillingListingQuery, NonGuestBillingListingQueryVariables>(NonGuestBillingListingDocument, baseOptions);
      }
export function useNonGuestBillingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NonGuestBillingListingQuery, NonGuestBillingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NonGuestBillingListingQuery, NonGuestBillingListingQueryVariables>(NonGuestBillingListingDocument, baseOptions);
        }
export type NonGuestBillingListingQueryHookResult = ReturnType<typeof useNonGuestBillingListingQuery>;
export type NonGuestBillingListingLazyQueryHookResult = ReturnType<typeof useNonGuestBillingListingLazyQuery>;
export type NonGuestBillingListingQueryResult = ApolloReactCommon.QueryResult<NonGuestBillingListingQuery, NonGuestBillingListingQueryVariables>;
export const NonGuestVoidLedgerDocument = gql`
    mutation NonGuestVoidLedger($BillLedgerID: String, $PaymentID: String, $HotelID: String, $Reason: String, $Description: String, $Remark: String) {
  NonGuestVoidLedger(BillLedgerID: $BillLedgerID, PaymentID: $PaymentID, HotelID: $HotelID, Reason: $Reason, Description: $Description, Remark: $Remark)
}
    `;
export type NonGuestVoidLedgerMutationFn = ApolloReactCommon.MutationFunction<NonGuestVoidLedgerMutation, NonGuestVoidLedgerMutationVariables>;

/**
 * __useNonGuestVoidLedgerMutation__
 *
 * To run a mutation, you first call `useNonGuestVoidLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNonGuestVoidLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nonGuestVoidLedgerMutation, { data, loading, error }] = useNonGuestVoidLedgerMutation({
 *   variables: {
 *      BillLedgerID: // value for 'BillLedgerID'
 *      PaymentID: // value for 'PaymentID'
 *      HotelID: // value for 'HotelID'
 *      Reason: // value for 'Reason'
 *      Description: // value for 'Description'
 *      Remark: // value for 'Remark'
 *   },
 * });
 */
export function useNonGuestVoidLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NonGuestVoidLedgerMutation, NonGuestVoidLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<NonGuestVoidLedgerMutation, NonGuestVoidLedgerMutationVariables>(NonGuestVoidLedgerDocument, baseOptions);
      }
export type NonGuestVoidLedgerMutationHookResult = ReturnType<typeof useNonGuestVoidLedgerMutation>;
export type NonGuestVoidLedgerMutationResult = ApolloReactCommon.MutationResult<NonGuestVoidLedgerMutation>;
export type NonGuestVoidLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<NonGuestVoidLedgerMutation, NonGuestVoidLedgerMutationVariables>;
export const NonGuestPaymentInsertDocument = gql`
    mutation NonGuestPaymentInsert($BillLedgerIDs: [String!], $PaymentInput: PaymentInput!, $HotelID: String!) {
  NonGuestPaymentInsert(BillLedgerIDs: $BillLedgerIDs, PaymentInput: $PaymentInput, HotelID: $HotelID) {
    ID
    ReceiptNo
  }
}
    `;
export type NonGuestPaymentInsertMutationFn = ApolloReactCommon.MutationFunction<NonGuestPaymentInsertMutation, NonGuestPaymentInsertMutationVariables>;

/**
 * __useNonGuestPaymentInsertMutation__
 *
 * To run a mutation, you first call `useNonGuestPaymentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNonGuestPaymentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nonGuestPaymentInsertMutation, { data, loading, error }] = useNonGuestPaymentInsertMutation({
 *   variables: {
 *      BillLedgerIDs: // value for 'BillLedgerIDs'
 *      PaymentInput: // value for 'PaymentInput'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useNonGuestPaymentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NonGuestPaymentInsertMutation, NonGuestPaymentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<NonGuestPaymentInsertMutation, NonGuestPaymentInsertMutationVariables>(NonGuestPaymentInsertDocument, baseOptions);
      }
export type NonGuestPaymentInsertMutationHookResult = ReturnType<typeof useNonGuestPaymentInsertMutation>;
export type NonGuestPaymentInsertMutationResult = ApolloReactCommon.MutationResult<NonGuestPaymentInsertMutation>;
export type NonGuestPaymentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<NonGuestPaymentInsertMutation, NonGuestPaymentInsertMutationVariables>;
export const NonGuestFolioUpdateDocument = gql`
    mutation NonGuestFolioUpdate($FolioInput: FolioInput!) {
  NonGuestFolioUpdate(FolioInput: $FolioInput)
}
    `;
export type NonGuestFolioUpdateMutationFn = ApolloReactCommon.MutationFunction<NonGuestFolioUpdateMutation, NonGuestFolioUpdateMutationVariables>;

/**
 * __useNonGuestFolioUpdateMutation__
 *
 * To run a mutation, you first call `useNonGuestFolioUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNonGuestFolioUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nonGuestFolioUpdateMutation, { data, loading, error }] = useNonGuestFolioUpdateMutation({
 *   variables: {
 *      FolioInput: // value for 'FolioInput'
 *   },
 * });
 */
export function useNonGuestFolioUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NonGuestFolioUpdateMutation, NonGuestFolioUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<NonGuestFolioUpdateMutation, NonGuestFolioUpdateMutationVariables>(NonGuestFolioUpdateDocument, baseOptions);
      }
export type NonGuestFolioUpdateMutationHookResult = ReturnType<typeof useNonGuestFolioUpdateMutation>;
export type NonGuestFolioUpdateMutationResult = ApolloReactCommon.MutationResult<NonGuestFolioUpdateMutation>;
export type NonGuestFolioUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<NonGuestFolioUpdateMutation, NonGuestFolioUpdateMutationVariables>;
export const NonGuestCloseFolioDocument = gql`
    mutation NonGuestCloseFolio($HotelID: String!, $FolioID: String!, $eInvoiceType: String) {
  NonGuestCloseFolio(HotelID: $HotelID, FolioID: $FolioID, eInvoiceType: $eInvoiceType)
}
    `;
export type NonGuestCloseFolioMutationFn = ApolloReactCommon.MutationFunction<NonGuestCloseFolioMutation, NonGuestCloseFolioMutationVariables>;

/**
 * __useNonGuestCloseFolioMutation__
 *
 * To run a mutation, you first call `useNonGuestCloseFolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNonGuestCloseFolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nonGuestCloseFolioMutation, { data, loading, error }] = useNonGuestCloseFolioMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      FolioID: // value for 'FolioID'
 *      eInvoiceType: // value for 'eInvoiceType'
 *   },
 * });
 */
export function useNonGuestCloseFolioMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NonGuestCloseFolioMutation, NonGuestCloseFolioMutationVariables>) {
        return ApolloReactHooks.useMutation<NonGuestCloseFolioMutation, NonGuestCloseFolioMutationVariables>(NonGuestCloseFolioDocument, baseOptions);
      }
export type NonGuestCloseFolioMutationHookResult = ReturnType<typeof useNonGuestCloseFolioMutation>;
export type NonGuestCloseFolioMutationResult = ApolloReactCommon.MutationResult<NonGuestCloseFolioMutation>;
export type NonGuestCloseFolioMutationOptions = ApolloReactCommon.BaseMutationOptions<NonGuestCloseFolioMutation, NonGuestCloseFolioMutationVariables>;
export const FolioDeleteDocument = gql`
    mutation FolioDelete($FolioID: String!) {
  FolioDelete(FolioID: $FolioID)
}
    `;
export type FolioDeleteMutationFn = ApolloReactCommon.MutationFunction<FolioDeleteMutation, FolioDeleteMutationVariables>;

/**
 * __useFolioDeleteMutation__
 *
 * To run a mutation, you first call `useFolioDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFolioDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [folioDeleteMutation, { data, loading, error }] = useFolioDeleteMutation({
 *   variables: {
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function useFolioDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FolioDeleteMutation, FolioDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<FolioDeleteMutation, FolioDeleteMutationVariables>(FolioDeleteDocument, baseOptions);
      }
export type FolioDeleteMutationHookResult = ReturnType<typeof useFolioDeleteMutation>;
export type FolioDeleteMutationResult = ApolloReactCommon.MutationResult<FolioDeleteMutation>;
export type FolioDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<FolioDeleteMutation, FolioDeleteMutationVariables>;
export const FolioUseListingDocument = gql`
    query FolioUseListing($HotelID: String!, $FolioID: String!) {
  FolioUseListing(HotelID: $HotelID, FolioID: $FolioID)
}
    `;

/**
 * __useFolioUseListingQuery__
 *
 * To run a query within a React component, call `useFolioUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolioUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolioUseListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function useFolioUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FolioUseListingQuery, FolioUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FolioUseListingQuery, FolioUseListingQueryVariables>(FolioUseListingDocument, baseOptions);
      }
export function useFolioUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FolioUseListingQuery, FolioUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FolioUseListingQuery, FolioUseListingQueryVariables>(FolioUseListingDocument, baseOptions);
        }
export type FolioUseListingQueryHookResult = ReturnType<typeof useFolioUseListingQuery>;
export type FolioUseListingLazyQueryHookResult = ReturnType<typeof useFolioUseListingLazyQuery>;
export type FolioUseListingQueryResult = ApolloReactCommon.QueryResult<FolioUseListingQuery, FolioUseListingQueryVariables>;
export const NonGuestTransferFolioDocument = gql`
    mutation NonGuestTransferFolio($FolioID: String!, $RecordIDs: [String!]!, $IsSuspend: Boolean, $HotelID: String) {
  NonGuestTransferFolio(FolioID: $FolioID, RecordIDs: $RecordIDs, IsSuspend: $IsSuspend, HotelID: $HotelID)
}
    `;
export type NonGuestTransferFolioMutationFn = ApolloReactCommon.MutationFunction<NonGuestTransferFolioMutation, NonGuestTransferFolioMutationVariables>;

/**
 * __useNonGuestTransferFolioMutation__
 *
 * To run a mutation, you first call `useNonGuestTransferFolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNonGuestTransferFolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nonGuestTransferFolioMutation, { data, loading, error }] = useNonGuestTransferFolioMutation({
 *   variables: {
 *      FolioID: // value for 'FolioID'
 *      RecordIDs: // value for 'RecordIDs'
 *      IsSuspend: // value for 'IsSuspend'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useNonGuestTransferFolioMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NonGuestTransferFolioMutation, NonGuestTransferFolioMutationVariables>) {
        return ApolloReactHooks.useMutation<NonGuestTransferFolioMutation, NonGuestTransferFolioMutationVariables>(NonGuestTransferFolioDocument, baseOptions);
      }
export type NonGuestTransferFolioMutationHookResult = ReturnType<typeof useNonGuestTransferFolioMutation>;
export type NonGuestTransferFolioMutationResult = ApolloReactCommon.MutationResult<NonGuestTransferFolioMutation>;
export type NonGuestTransferFolioMutationOptions = ApolloReactCommon.BaseMutationOptions<NonGuestTransferFolioMutation, NonGuestTransferFolioMutationVariables>;
export const FrontDeskPaymentListingDocument = gql`
    query FrontDeskPaymentListing($HotelID: String!, $BookingID: String!) {
  FrontDeskPaymentListing(HotelID: $HotelID, BookingID: $BookingID) {
    BookingNo
    ArrivalDate
    DepartureDate
    Description
    FullName
    MaxGuest
    Code
    RoomNo
    BookingStatus
    GuestID
    IncidentalAmt
    PaymentAmt
    BookingID
    RegistrationID
    RoomTypeID
    RoomID
    DebtorName
    TrxAmount
    BaseAmount
    DiscountAmount
    TaxAmount
    ServiceCharge
    ChargeType
    FolioID
  }
}
    `;

/**
 * __useFrontDeskPaymentListingQuery__
 *
 * To run a query within a React component, call `useFrontDeskPaymentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskPaymentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontDeskPaymentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useFrontDeskPaymentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FrontDeskPaymentListingQuery, FrontDeskPaymentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FrontDeskPaymentListingQuery, FrontDeskPaymentListingQueryVariables>(FrontDeskPaymentListingDocument, baseOptions);
      }
export function useFrontDeskPaymentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FrontDeskPaymentListingQuery, FrontDeskPaymentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FrontDeskPaymentListingQuery, FrontDeskPaymentListingQueryVariables>(FrontDeskPaymentListingDocument, baseOptions);
        }
export type FrontDeskPaymentListingQueryHookResult = ReturnType<typeof useFrontDeskPaymentListingQuery>;
export type FrontDeskPaymentListingLazyQueryHookResult = ReturnType<typeof useFrontDeskPaymentListingLazyQuery>;
export type FrontDeskPaymentListingQueryResult = ApolloReactCommon.QueryResult<FrontDeskPaymentListingQuery, FrontDeskPaymentListingQueryVariables>;
export const CurrentTaxDetailsDocument = gql`
    query CurrentTaxDetails($HotelID: String!) {
  CurrentTaxDetails(HotelID: $HotelID) {
    HotelID
    GovTax
    ServiceCharge
  }
}
    `;

/**
 * __useCurrentTaxDetailsQuery__
 *
 * To run a query within a React component, call `useCurrentTaxDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTaxDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTaxDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCurrentTaxDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>(CurrentTaxDetailsDocument, baseOptions);
      }
export function useCurrentTaxDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>(CurrentTaxDetailsDocument, baseOptions);
        }
export type CurrentTaxDetailsQueryHookResult = ReturnType<typeof useCurrentTaxDetailsQuery>;
export type CurrentTaxDetailsLazyQueryHookResult = ReturnType<typeof useCurrentTaxDetailsLazyQuery>;
export type CurrentTaxDetailsQueryResult = ApolloReactCommon.QueryResult<CurrentTaxDetailsQuery, CurrentTaxDetailsQueryVariables>;
export const FrontDeskPaymentInsertDocument = gql`
    mutation FrontDeskPaymentInsert($input: [FrontDeskPaymentInput!]!, $TotalPaymentAmount: Float!, $DebtorID: String, $BeneficiaryName: String, $BeneficiaryAddress: String, $IsGroupFolio: Boolean, $AdjustedAmount: Float) {
  FrontDeskPaymentInsert(input: $input, TotalPaymentAmount: $TotalPaymentAmount, DebtorID: $DebtorID, BeneficiaryName: $BeneficiaryName, BeneficiaryAddress: $BeneficiaryAddress, IsGroupFolio: $IsGroupFolio, AdjustedAmount: $AdjustedAmount) {
    ID
    ReceiptNo
    PaymentDate
    PaymentClass
    PaymentType
    CardNo
    Amount
    ReferenceNo
    PaymentStatus
    FolioID
  }
}
    `;
export type FrontDeskPaymentInsertMutationFn = ApolloReactCommon.MutationFunction<FrontDeskPaymentInsertMutation, FrontDeskPaymentInsertMutationVariables>;

/**
 * __useFrontDeskPaymentInsertMutation__
 *
 * To run a mutation, you first call `useFrontDeskPaymentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskPaymentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [frontDeskPaymentInsertMutation, { data, loading, error }] = useFrontDeskPaymentInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *      TotalPaymentAmount: // value for 'TotalPaymentAmount'
 *      DebtorID: // value for 'DebtorID'
 *      BeneficiaryName: // value for 'BeneficiaryName'
 *      BeneficiaryAddress: // value for 'BeneficiaryAddress'
 *      IsGroupFolio: // value for 'IsGroupFolio'
 *      AdjustedAmount: // value for 'AdjustedAmount'
 *   },
 * });
 */
export function useFrontDeskPaymentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FrontDeskPaymentInsertMutation, FrontDeskPaymentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<FrontDeskPaymentInsertMutation, FrontDeskPaymentInsertMutationVariables>(FrontDeskPaymentInsertDocument, baseOptions);
      }
export type FrontDeskPaymentInsertMutationHookResult = ReturnType<typeof useFrontDeskPaymentInsertMutation>;
export type FrontDeskPaymentInsertMutationResult = ApolloReactCommon.MutationResult<FrontDeskPaymentInsertMutation>;
export type FrontDeskPaymentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<FrontDeskPaymentInsertMutation, FrontDeskPaymentInsertMutationVariables>;
export const SplitPaymentV2Document = gql`
    mutation SplitPaymentV2($BookingID: String!, $RecordToSplit: [PaymentFolioInput!]!, $SplitBy: Float!, $HotelID: String!, $SplitType: String!, $SplitValue: Float!) {
  SplitPaymentV2(BookingID: $BookingID, RecordToSplit: $RecordToSplit, SplitBy: $SplitBy, HotelID: $HotelID, SplitType: $SplitType, SplitValue: $SplitValue)
}
    `;
export type SplitPaymentV2MutationFn = ApolloReactCommon.MutationFunction<SplitPaymentV2Mutation, SplitPaymentV2MutationVariables>;

/**
 * __useSplitPaymentV2Mutation__
 *
 * To run a mutation, you first call `useSplitPaymentV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSplitPaymentV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [splitPaymentV2Mutation, { data, loading, error }] = useSplitPaymentV2Mutation({
 *   variables: {
 *      BookingID: // value for 'BookingID'
 *      RecordToSplit: // value for 'RecordToSplit'
 *      SplitBy: // value for 'SplitBy'
 *      HotelID: // value for 'HotelID'
 *      SplitType: // value for 'SplitType'
 *      SplitValue: // value for 'SplitValue'
 *   },
 * });
 */
export function useSplitPaymentV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SplitPaymentV2Mutation, SplitPaymentV2MutationVariables>) {
        return ApolloReactHooks.useMutation<SplitPaymentV2Mutation, SplitPaymentV2MutationVariables>(SplitPaymentV2Document, baseOptions);
      }
export type SplitPaymentV2MutationHookResult = ReturnType<typeof useSplitPaymentV2Mutation>;
export type SplitPaymentV2MutationResult = ApolloReactCommon.MutationResult<SplitPaymentV2Mutation>;
export type SplitPaymentV2MutationOptions = ApolloReactCommon.BaseMutationOptions<SplitPaymentV2Mutation, SplitPaymentV2MutationVariables>;
export const TransferPaymentDocument = gql`
    mutation TransferPayment($FromRegistrationID: String, $NewRegistrationID: String!, $RecordIDs: [String!]!, $FolioID: String, $IsNonguest: Boolean) {
  TransferPayment(FromRegistrationID: $FromRegistrationID, NewRegistrationID: $NewRegistrationID, RecordIDs: $RecordIDs, FolioID: $FolioID, IsNonguest: $IsNonguest)
}
    `;
export type TransferPaymentMutationFn = ApolloReactCommon.MutationFunction<TransferPaymentMutation, TransferPaymentMutationVariables>;

/**
 * __useTransferPaymentMutation__
 *
 * To run a mutation, you first call `useTransferPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferPaymentMutation, { data, loading, error }] = useTransferPaymentMutation({
 *   variables: {
 *      FromRegistrationID: // value for 'FromRegistrationID'
 *      NewRegistrationID: // value for 'NewRegistrationID'
 *      RecordIDs: // value for 'RecordIDs'
 *      FolioID: // value for 'FolioID'
 *      IsNonguest: // value for 'IsNonguest'
 *   },
 * });
 */
export function useTransferPaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TransferPaymentMutation, TransferPaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<TransferPaymentMutation, TransferPaymentMutationVariables>(TransferPaymentDocument, baseOptions);
      }
export type TransferPaymentMutationHookResult = ReturnType<typeof useTransferPaymentMutation>;
export type TransferPaymentMutationResult = ApolloReactCommon.MutationResult<TransferPaymentMutation>;
export type TransferPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<TransferPaymentMutation, TransferPaymentMutationVariables>;
export const BillingByRegistrationDocument = gql`
    query BillingByRegistration($RegistrationID: [String!]!, $BookingID: String!) {
  BillingByRegistration(RegistrationID: $RegistrationID, BookingID: $BookingID) {
    BillScheduleTax {
      ID
      HotelID
      AccountID
      TaxSchemeID
      TaxSchemeDetailID
      TaxID
      TaxRate
      TaxAmount
      name
    }
    RoomLedgerTax {
      ID
      HotelID
      AccountID
      TaxSchemeID
      TaxSchemeDetailID
      TaxID
      TaxRate
      TaxAmount
      name
    }
    BillLedgerTax {
      ID
      HotelID
      AccountID
      TaxSchemeID
      TaxSchemeDetailID
      TaxID
      TaxRate
      TaxAmount
      name
    }
    RegistrationID
    TrxDate
    ChargeType
    Description
    ReferenceNo
    FullName
    RoomNo
    FolioID
    TrxAmount
    BaseAmount
    DiscountAmount
    UnitPrice
    ID
    LedgerType
    ServiceCharge
    TaxAmount
    Type
    DebtorName
    DebtorID
    DebtorAccount
    CreatedName
    CreatedDT
    CreatedBy
    SplitGroup
    IsPosted
    FromRegistration
    IsOutlet
    IsEventInterface
  }
}
    `;

/**
 * __useBillingByRegistrationQuery__
 *
 * To run a query within a React component, call `useBillingByRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingByRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingByRegistrationQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useBillingByRegistrationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BillingByRegistrationQuery, BillingByRegistrationQueryVariables>) {
        return ApolloReactHooks.useQuery<BillingByRegistrationQuery, BillingByRegistrationQueryVariables>(BillingByRegistrationDocument, baseOptions);
      }
export function useBillingByRegistrationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BillingByRegistrationQuery, BillingByRegistrationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BillingByRegistrationQuery, BillingByRegistrationQueryVariables>(BillingByRegistrationDocument, baseOptions);
        }
export type BillingByRegistrationQueryHookResult = ReturnType<typeof useBillingByRegistrationQuery>;
export type BillingByRegistrationLazyQueryHookResult = ReturnType<typeof useBillingByRegistrationLazyQuery>;
export type BillingByRegistrationQueryResult = ApolloReactCommon.QueryResult<BillingByRegistrationQuery, BillingByRegistrationQueryVariables>;
export const OnlinePaymentDocument = gql`
    mutation OnlinePayment($redirectUrl: String!, $PayAmount: Float!, $Detail: String!, $Title: String!, $HotelID: String!, $ReceiptNo: String!, $AdvancePaymentID: String, $PaymentID: String) {
  OnlinePayment(redirectUrl: $redirectUrl, PayAmount: $PayAmount, Detail: $Detail, Title: $Title, HotelID: $HotelID, ReceiptNo: $ReceiptNo, AdvancePaymentID: $AdvancePaymentID, PaymentID: $PaymentID) {
    item {
      checkoutId
      url
      qrCodeUrl
      status
    }
    code
    error {
      code
      message
      debug
    }
    PaySessionNo
  }
}
    `;
export type OnlinePaymentMutationFn = ApolloReactCommon.MutationFunction<OnlinePaymentMutation, OnlinePaymentMutationVariables>;

/**
 * __useOnlinePaymentMutation__
 *
 * To run a mutation, you first call `useOnlinePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnlinePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onlinePaymentMutation, { data, loading, error }] = useOnlinePaymentMutation({
 *   variables: {
 *      redirectUrl: // value for 'redirectUrl'
 *      PayAmount: // value for 'PayAmount'
 *      Detail: // value for 'Detail'
 *      Title: // value for 'Title'
 *      HotelID: // value for 'HotelID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *      AdvancePaymentID: // value for 'AdvancePaymentID'
 *      PaymentID: // value for 'PaymentID'
 *   },
 * });
 */
export function useOnlinePaymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OnlinePaymentMutation, OnlinePaymentMutationVariables>) {
        return ApolloReactHooks.useMutation<OnlinePaymentMutation, OnlinePaymentMutationVariables>(OnlinePaymentDocument, baseOptions);
      }
export type OnlinePaymentMutationHookResult = ReturnType<typeof useOnlinePaymentMutation>;
export type OnlinePaymentMutationResult = ApolloReactCommon.MutationResult<OnlinePaymentMutation>;
export type OnlinePaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<OnlinePaymentMutation, OnlinePaymentMutationVariables>;
export const PaymentUpdateDocument = gql`
    mutation PaymentUpdate($CheckOutID: String, $OrderID: String, $Status: String, $RegistrationID: String, $ReceiptNo: String, $HotelID: String) {
  PaymentUpdate(CheckOutID: $CheckOutID, OrderID: $OrderID, Status: $Status, RegistrationID: $RegistrationID, ReceiptNo: $ReceiptNo, HotelID: $HotelID)
}
    `;
export type PaymentUpdateMutationFn = ApolloReactCommon.MutationFunction<PaymentUpdateMutation, PaymentUpdateMutationVariables>;

/**
 * __usePaymentUpdateMutation__
 *
 * To run a mutation, you first call `usePaymentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentUpdateMutation, { data, loading, error }] = usePaymentUpdateMutation({
 *   variables: {
 *      CheckOutID: // value for 'CheckOutID'
 *      OrderID: // value for 'OrderID'
 *      Status: // value for 'Status'
 *      RegistrationID: // value for 'RegistrationID'
 *      ReceiptNo: // value for 'ReceiptNo'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function usePaymentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PaymentUpdateMutation, PaymentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PaymentUpdateMutation, PaymentUpdateMutationVariables>(PaymentUpdateDocument, baseOptions);
      }
export type PaymentUpdateMutationHookResult = ReturnType<typeof usePaymentUpdateMutation>;
export type PaymentUpdateMutationResult = ApolloReactCommon.MutationResult<PaymentUpdateMutation>;
export type PaymentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PaymentUpdateMutation, PaymentUpdateMutationVariables>;
export const PaymentDetailDocument = gql`
    query PaymentDetail($OrderID: String, $RegistrationID: [String!]) {
  PaymentDetail(OrderID: $OrderID, RegistrationID: $RegistrationID) {
    ID
    FolioID
    RegistrationID
    HotelID
    ReceiptNo
    PaymentDate
    PaymentClass
    PaymentType
    DebtorAccount
    PaySessionNo
    CheckOutID
    CardNo
    Amount
    ReferenceNo
    PaymentStatus
  }
}
    `;

/**
 * __usePaymentDetailQuery__
 *
 * To run a query within a React component, call `usePaymentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentDetailQuery({
 *   variables: {
 *      OrderID: // value for 'OrderID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function usePaymentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentDetailQuery, PaymentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentDetailQuery, PaymentDetailQueryVariables>(PaymentDetailDocument, baseOptions);
      }
export function usePaymentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentDetailQuery, PaymentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentDetailQuery, PaymentDetailQueryVariables>(PaymentDetailDocument, baseOptions);
        }
export type PaymentDetailQueryHookResult = ReturnType<typeof usePaymentDetailQuery>;
export type PaymentDetailLazyQueryHookResult = ReturnType<typeof usePaymentDetailLazyQuery>;
export type PaymentDetailQueryResult = ApolloReactCommon.QueryResult<PaymentDetailQuery, PaymentDetailQueryVariables>;
export const VoidLedgerDocument = gql`
    mutation VoidLedger($ChargeType: String, $TrxDate: DateTime!, $HotelID: String!, $RegistrationID: String!, $ID: String!, $LedgerType: String!, $FromRegistration: String, $Reason: String, $Description: String, $Remark: String) {
  VoidLedger(ChargeType: $ChargeType, TrxDate: $TrxDate, HotelID: $HotelID, RegistrationID: $RegistrationID, ID: $ID, LedgerType: $LedgerType, FromRegistration: $FromRegistration, Reason: $Reason, Description: $Description, Remark: $Remark)
}
    `;
export type VoidLedgerMutationFn = ApolloReactCommon.MutationFunction<VoidLedgerMutation, VoidLedgerMutationVariables>;

/**
 * __useVoidLedgerMutation__
 *
 * To run a mutation, you first call `useVoidLedgerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidLedgerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidLedgerMutation, { data, loading, error }] = useVoidLedgerMutation({
 *   variables: {
 *      ChargeType: // value for 'ChargeType'
 *      TrxDate: // value for 'TrxDate'
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *      ID: // value for 'ID'
 *      LedgerType: // value for 'LedgerType'
 *      FromRegistration: // value for 'FromRegistration'
 *      Reason: // value for 'Reason'
 *      Description: // value for 'Description'
 *      Remark: // value for 'Remark'
 *   },
 * });
 */
export function useVoidLedgerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VoidLedgerMutation, VoidLedgerMutationVariables>) {
        return ApolloReactHooks.useMutation<VoidLedgerMutation, VoidLedgerMutationVariables>(VoidLedgerDocument, baseOptions);
      }
export type VoidLedgerMutationHookResult = ReturnType<typeof useVoidLedgerMutation>;
export type VoidLedgerMutationResult = ApolloReactCommon.MutationResult<VoidLedgerMutation>;
export type VoidLedgerMutationOptions = ApolloReactCommon.BaseMutationOptions<VoidLedgerMutation, VoidLedgerMutationVariables>;
export const RoundingAdjustmentDocument = gql`
    query RoundingAdjustment($Amount: Float!, $HotelID: String!) {
  RoundingAdjustment(Amount: $Amount, HotelID: $HotelID)
}
    `;

/**
 * __useRoundingAdjustmentQuery__
 *
 * To run a query within a React component, call `useRoundingAdjustmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoundingAdjustmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoundingAdjustmentQuery({
 *   variables: {
 *      Amount: // value for 'Amount'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRoundingAdjustmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoundingAdjustmentQuery, RoundingAdjustmentQueryVariables>) {
        return ApolloReactHooks.useQuery<RoundingAdjustmentQuery, RoundingAdjustmentQueryVariables>(RoundingAdjustmentDocument, baseOptions);
      }
export function useRoundingAdjustmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoundingAdjustmentQuery, RoundingAdjustmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoundingAdjustmentQuery, RoundingAdjustmentQueryVariables>(RoundingAdjustmentDocument, baseOptions);
        }
export type RoundingAdjustmentQueryHookResult = ReturnType<typeof useRoundingAdjustmentQuery>;
export type RoundingAdjustmentLazyQueryHookResult = ReturnType<typeof useRoundingAdjustmentLazyQuery>;
export type RoundingAdjustmentQueryResult = ApolloReactCommon.QueryResult<RoundingAdjustmentQuery, RoundingAdjustmentQueryVariables>;
export const RoundingAmountDocument = gql`
    query RoundingAmount($Amount: Float!, $HotelID: String!) {
  RoundingAmount(Amount: $Amount, HotelID: $HotelID)
}
    `;

/**
 * __useRoundingAmountQuery__
 *
 * To run a query within a React component, call `useRoundingAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoundingAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoundingAmountQuery({
 *   variables: {
 *      Amount: // value for 'Amount'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRoundingAmountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoundingAmountQuery, RoundingAmountQueryVariables>) {
        return ApolloReactHooks.useQuery<RoundingAmountQuery, RoundingAmountQueryVariables>(RoundingAmountDocument, baseOptions);
      }
export function useRoundingAmountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoundingAmountQuery, RoundingAmountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoundingAmountQuery, RoundingAmountQueryVariables>(RoundingAmountDocument, baseOptions);
        }
export type RoundingAmountQueryHookResult = ReturnType<typeof useRoundingAmountQuery>;
export type RoundingAmountLazyQueryHookResult = ReturnType<typeof useRoundingAmountLazyQuery>;
export type RoundingAmountQueryResult = ApolloReactCommon.QueryResult<RoundingAmountQuery, RoundingAmountQueryVariables>;
export const TransferFrontDeskListingDocument = gql`
    query TransferFrontDeskListing($HotelID: String!, $Status: String, $RoomTypeID: String, $ArrivalDate: DateTime) {
  FrontDeskListing(HotelID: $HotelID, Status: $Status, RoomTypeID: $RoomTypeID, ArrivalDate: $ArrivalDate) {
    ID
    BookingNo
    ArrivalDate
    DepartureDate
    BookingStatus
    Debtor {
      ID
      Name
      Contact {
        FullName
      }
      DebtorCategory
      DebtorAccount
      DebtorContacts
    }
    Contact {
      ID
      FullName
      Email
    }
    BookedRegistration(Status: $Status) {
      IsPrincipal
      RegistrationStatus
      ArrivalDate
      DepartureDate
      ID
      CheckOutDate
      Booking {
        isPrepaid
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        Contact {
          FullName
        }
        Debtor {
          Name
          ID
          DebtorCategory
          DebtorAccount
          IseCommerce
        }
      }
      RoomType {
        ID
        MaxGuest
        Code
      }
      Room {
        ID
        RoomNo
        RoomStatusCode
      }
      Guest {
        ID
        Contact {
          ID
          FullName
        }
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
    }
  }
}
    `;

/**
 * __useTransferFrontDeskListingQuery__
 *
 * To run a query within a React component, call `useTransferFrontDeskListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferFrontDeskListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferFrontDeskListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      Status: // value for 'Status'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      ArrivalDate: // value for 'ArrivalDate'
 *   },
 * });
 */
export function useTransferFrontDeskListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TransferFrontDeskListingQuery, TransferFrontDeskListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TransferFrontDeskListingQuery, TransferFrontDeskListingQueryVariables>(TransferFrontDeskListingDocument, baseOptions);
      }
export function useTransferFrontDeskListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TransferFrontDeskListingQuery, TransferFrontDeskListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TransferFrontDeskListingQuery, TransferFrontDeskListingQueryVariables>(TransferFrontDeskListingDocument, baseOptions);
        }
export type TransferFrontDeskListingQueryHookResult = ReturnType<typeof useTransferFrontDeskListingQuery>;
export type TransferFrontDeskListingLazyQueryHookResult = ReturnType<typeof useTransferFrontDeskListingLazyQuery>;
export type TransferFrontDeskListingQueryResult = ApolloReactCommon.QueryResult<TransferFrontDeskListingQuery, TransferFrontDeskListingQueryVariables>;
export const AssignRoomDocument = gql`
    mutation AssignRoom($RoomID: String, $Mode: Boolean, $RegistrationID: String!, $BookingID: String!) {
  AssignRoom(RoomID: $RoomID, Mode: $Mode, RegistrationID: $RegistrationID, BookingID: $BookingID)
}
    `;
export type AssignRoomMutationFn = ApolloReactCommon.MutationFunction<AssignRoomMutation, AssignRoomMutationVariables>;

/**
 * __useAssignRoomMutation__
 *
 * To run a mutation, you first call `useAssignRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignRoomMutation, { data, loading, error }] = useAssignRoomMutation({
 *   variables: {
 *      RoomID: // value for 'RoomID'
 *      Mode: // value for 'Mode'
 *      RegistrationID: // value for 'RegistrationID'
 *      BookingID: // value for 'BookingID'
 *   },
 * });
 */
export function useAssignRoomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignRoomMutation, AssignRoomMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignRoomMutation, AssignRoomMutationVariables>(AssignRoomDocument, baseOptions);
      }
export type AssignRoomMutationHookResult = ReturnType<typeof useAssignRoomMutation>;
export type AssignRoomMutationResult = ApolloReactCommon.MutationResult<AssignRoomMutation>;
export type AssignRoomMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignRoomMutation, AssignRoomMutationVariables>;
export const AssignRoomLocationDocument = gql`
    query AssignRoomLocation($HotelID: String!, $RoomTypeID: String, $StartDate: DateTime, $EndDate: DateTime) {
  LocationListing(HotelID: $HotelID) {
    ID
    Code
    Description
    Room(RoomTypeID: $RoomTypeID) {
      ID
      RoomNo
      MaxGuest
      RoomSize
      IsActive
      RoomAssignmentStatus(StartDate: $StartDate, EndDate: $EndDate) {
        ID
        Description
        RGBColor
        Code
        VacantOrOccupied
        CleanOrDirty
      }
    }
  }
}
    `;

/**
 * __useAssignRoomLocationQuery__
 *
 * To run a query within a React component, call `useAssignRoomLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignRoomLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignRoomLocationQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useAssignRoomLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssignRoomLocationQuery, AssignRoomLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<AssignRoomLocationQuery, AssignRoomLocationQueryVariables>(AssignRoomLocationDocument, baseOptions);
      }
export function useAssignRoomLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignRoomLocationQuery, AssignRoomLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssignRoomLocationQuery, AssignRoomLocationQueryVariables>(AssignRoomLocationDocument, baseOptions);
        }
export type AssignRoomLocationQueryHookResult = ReturnType<typeof useAssignRoomLocationQuery>;
export type AssignRoomLocationLazyQueryHookResult = ReturnType<typeof useAssignRoomLocationLazyQuery>;
export type AssignRoomLocationQueryResult = ApolloReactCommon.QueryResult<AssignRoomLocationQuery, AssignRoomLocationQueryVariables>;
export const AssignRoomFrontDeskListingDocument = gql`
    query AssignRoomFrontDeskListing($HotelID: String!) {
  AssignRoomFrontDeskListing(HotelID: $HotelID) {
    ID
    BookingNo
    ArrivalDate
    DepartureDate
    BookingStatus
    BookedRegistration {
      BookingID
      RegistrationStatus
      PromoCode
      DiscountAmount
      RoomRate
      Signature
      RoomType {
        ID
        MaxGuest
        Code
      }
      DepositLedger {
        ID
        FolioID
        DepositAmount
        DepositClass
      }
      ID
      DepositSum
      PaymentSum
      CheckInDate
      CheckOutDate
      IncidentalChargesSum
      IncidentalChargesCount
      IsPrincipal
      RegistrationStatus
      RoomRate
      RoomID
      TotalRoomPrice
      TotalRoomTax
      TotalPackagePrice
      TotalPrice
      Room {
        ID
        RoomNo
        ServiceRequestCount
        HouseKeepingLog {
          ID
          HotelDate
        }
      }
      Guest {
        ID
        ContactID
        VehicleNo
        Contact {
          ID
          FullName
          Nationality
          MobileNo
          BirthDate
          NRIC
          PassportNo
          Email
          Address {
            address
            state
            country
            postCode
            city
          }
        }
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
    }
  }
}
    `;

/**
 * __useAssignRoomFrontDeskListingQuery__
 *
 * To run a query within a React component, call `useAssignRoomFrontDeskListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignRoomFrontDeskListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignRoomFrontDeskListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAssignRoomFrontDeskListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AssignRoomFrontDeskListingQuery, AssignRoomFrontDeskListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AssignRoomFrontDeskListingQuery, AssignRoomFrontDeskListingQueryVariables>(AssignRoomFrontDeskListingDocument, baseOptions);
      }
export function useAssignRoomFrontDeskListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AssignRoomFrontDeskListingQuery, AssignRoomFrontDeskListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AssignRoomFrontDeskListingQuery, AssignRoomFrontDeskListingQueryVariables>(AssignRoomFrontDeskListingDocument, baseOptions);
        }
export type AssignRoomFrontDeskListingQueryHookResult = ReturnType<typeof useAssignRoomFrontDeskListingQuery>;
export type AssignRoomFrontDeskListingLazyQueryHookResult = ReturnType<typeof useAssignRoomFrontDeskListingLazyQuery>;
export type AssignRoomFrontDeskListingQueryResult = ApolloReactCommon.QueryResult<AssignRoomFrontDeskListingQuery, AssignRoomFrontDeskListingQueryVariables>;
export const FloorPlanGalleryDocument = gql`
    query FloorPlanGallery($ID: String!) {
  FloorPlanGallery(ID: $ID) {
    ID
    ImageURL
  }
}
    `;

/**
 * __useFloorPlanGalleryQuery__
 *
 * To run a query within a React component, call `useFloorPlanGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorPlanGalleryQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useFloorPlanGalleryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FloorPlanGalleryQuery, FloorPlanGalleryQueryVariables>) {
        return ApolloReactHooks.useQuery<FloorPlanGalleryQuery, FloorPlanGalleryQueryVariables>(FloorPlanGalleryDocument, baseOptions);
      }
export function useFloorPlanGalleryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FloorPlanGalleryQuery, FloorPlanGalleryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FloorPlanGalleryQuery, FloorPlanGalleryQueryVariables>(FloorPlanGalleryDocument, baseOptions);
        }
export type FloorPlanGalleryQueryHookResult = ReturnType<typeof useFloorPlanGalleryQuery>;
export type FloorPlanGalleryLazyQueryHookResult = ReturnType<typeof useFloorPlanGalleryLazyQuery>;
export type FloorPlanGalleryQueryResult = ApolloReactCommon.QueryResult<FloorPlanGalleryQuery, FloorPlanGalleryQueryVariables>;
export const RoomAssignListingDocument = gql`
    query RoomAssignListing($HotelID: String!, $RoomTypeID: String!, $StartDate: DateTime!, $EndDate: DateTime, $IsBooking: Boolean, $RoomID: [String!], $RegistrationIDs: [String!]) {
  RoomAssignListing(HotelID: $HotelID, RoomTypeID: $RoomTypeID, StartDate: $StartDate, EndDate: $EndDate, IsBooking: $IsBooking, RoomID: $RoomID, RegistrationIDs: $RegistrationIDs) {
    ID
    RoomNo
    RoomTypeID
    FloorPlanID
    FloorPlan {
      ID
      Coordinates
      IsHotel
      IsPreCheckin
    }
    IsActive
    MaxGuest
    Description
    RoomSize
    Registrations {
      ID
    }
    Location {
      ID
      Code
      Description
      GalleryID
      Gallery {
        ID
        ImageURL
      }
    }
    RoomAssignmentStatus(StartDate: $StartDate, EndDate: $EndDate) {
      CleanOrDirty
      Code
      Description
      ID
      RGBColor
      VacantOrOccupied
    }
  }
}
    `;

/**
 * __useRoomAssignListingQuery__
 *
 * To run a query within a React component, call `useRoomAssignListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomAssignListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomAssignListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      IsBooking: // value for 'IsBooking'
 *      RoomID: // value for 'RoomID'
 *      RegistrationIDs: // value for 'RegistrationIDs'
 *   },
 * });
 */
export function useRoomAssignListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomAssignListingQuery, RoomAssignListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomAssignListingQuery, RoomAssignListingQueryVariables>(RoomAssignListingDocument, baseOptions);
      }
export function useRoomAssignListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomAssignListingQuery, RoomAssignListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomAssignListingQuery, RoomAssignListingQueryVariables>(RoomAssignListingDocument, baseOptions);
        }
export type RoomAssignListingQueryHookResult = ReturnType<typeof useRoomAssignListingQuery>;
export type RoomAssignListingLazyQueryHookResult = ReturnType<typeof useRoomAssignListingLazyQuery>;
export type RoomAssignListingQueryResult = ApolloReactCommon.QueryResult<RoomAssignListingQuery, RoomAssignListingQueryVariables>;
export const RoomAssignmentNotifyDocument = gql`
    subscription RoomAssignmentNotify($HotelID: String!, $RoomTypeID: String!, $StartDate: DateTime!, $EndDate: DateTime, $IsBooking: Boolean, $RoomID: String) {
  RoomAssignmentNotify(HotelID: $HotelID, RoomTypeID: $RoomTypeID, StartDate: $StartDate, EndDate: $EndDate, IsBooking: $IsBooking, RoomID: $RoomID) {
    ID
    RoomNo
    RoomTypeID
    IsActive
    MaxGuest
    Description
    RoomSize
    Registrations {
      ID
    }
    Location {
      ID
      Code
      Description
    }
    RoomAssignmentStatus(StartDate: $StartDate, EndDate: $EndDate) {
      CleanOrDirty
      Code
      Description
      ID
      RGBColor
      VacantOrOccupied
    }
  }
}
    `;

/**
 * __useRoomAssignmentNotifySubscription__
 *
 * To run a query within a React component, call `useRoomAssignmentNotifySubscription` and pass it any options that fit your needs.
 * When your component renders, `useRoomAssignmentNotifySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomAssignmentNotifySubscription({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      IsBooking: // value for 'IsBooking'
 *      RoomID: // value for 'RoomID'
 *   },
 * });
 */
export function useRoomAssignmentNotifySubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<RoomAssignmentNotifySubscription, RoomAssignmentNotifySubscriptionVariables>) {
        return ApolloReactHooks.useSubscription<RoomAssignmentNotifySubscription, RoomAssignmentNotifySubscriptionVariables>(RoomAssignmentNotifyDocument, baseOptions);
      }
export type RoomAssignmentNotifySubscriptionHookResult = ReturnType<typeof useRoomAssignmentNotifySubscription>;
export type RoomAssignmentNotifySubscriptionResult = ApolloReactCommon.SubscriptionResult<RoomAssignmentNotifySubscription>;
export const WalkInRoomAssignedDocument = gql`
    mutation walkInRoomAssigned($RoomID: String!, $HotelID: String!, $RoomTypeID: String) {
  walkInRoomAssigned(RoomID: $RoomID, HotelID: $HotelID, RoomTypeID: $RoomTypeID)
}
    `;
export type WalkInRoomAssignedMutationFn = ApolloReactCommon.MutationFunction<WalkInRoomAssignedMutation, WalkInRoomAssignedMutationVariables>;

/**
 * __useWalkInRoomAssignedMutation__
 *
 * To run a mutation, you first call `useWalkInRoomAssignedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWalkInRoomAssignedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [walkInRoomAssignedMutation, { data, loading, error }] = useWalkInRoomAssignedMutation({
 *   variables: {
 *      RoomID: // value for 'RoomID'
 *      HotelID: // value for 'HotelID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *   },
 * });
 */
export function useWalkInRoomAssignedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WalkInRoomAssignedMutation, WalkInRoomAssignedMutationVariables>) {
        return ApolloReactHooks.useMutation<WalkInRoomAssignedMutation, WalkInRoomAssignedMutationVariables>(WalkInRoomAssignedDocument, baseOptions);
      }
export type WalkInRoomAssignedMutationHookResult = ReturnType<typeof useWalkInRoomAssignedMutation>;
export type WalkInRoomAssignedMutationResult = ApolloReactCommon.MutationResult<WalkInRoomAssignedMutation>;
export type WalkInRoomAssignedMutationOptions = ApolloReactCommon.BaseMutationOptions<WalkInRoomAssignedMutation, WalkInRoomAssignedMutationVariables>;
export const FrontDeskServiceRequestListingDocument = gql`
    query FrontDeskServiceRequestListing($HotelID: String!, $RoomID: String!, $RegistrationID: String!) {
  FrontDeskServiceRequestListing(HotelID: $HotelID, RoomID: $RoomID, RegistrationID: $RegistrationID) {
    ID
    Title
    Description
    EstimatedCompletion
    RequestBy
    Status
    Remarks
    DepartmentID
    ClosedDate
    Room {
      ID
      RoomNo
      Location {
        ID
        Description
      }
    }
    ServiceRequestGallery {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
    Department {
      Name
    }
    CreatedBy
    ModifiedBy
  }
}
    `;

/**
 * __useFrontDeskServiceRequestListingQuery__
 *
 * To run a query within a React component, call `useFrontDeskServiceRequestListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontDeskServiceRequestListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontDeskServiceRequestListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomID: // value for 'RoomID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useFrontDeskServiceRequestListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FrontDeskServiceRequestListingQuery, FrontDeskServiceRequestListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FrontDeskServiceRequestListingQuery, FrontDeskServiceRequestListingQueryVariables>(FrontDeskServiceRequestListingDocument, baseOptions);
      }
export function useFrontDeskServiceRequestListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FrontDeskServiceRequestListingQuery, FrontDeskServiceRequestListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FrontDeskServiceRequestListingQuery, FrontDeskServiceRequestListingQueryVariables>(FrontDeskServiceRequestListingDocument, baseOptions);
        }
export type FrontDeskServiceRequestListingQueryHookResult = ReturnType<typeof useFrontDeskServiceRequestListingQuery>;
export type FrontDeskServiceRequestListingLazyQueryHookResult = ReturnType<typeof useFrontDeskServiceRequestListingLazyQuery>;
export type FrontDeskServiceRequestListingQueryResult = ApolloReactCommon.QueryResult<FrontDeskServiceRequestListingQuery, FrontDeskServiceRequestListingQueryVariables>;
export const StayViewListingDocument = gql`
    query StayViewListing($Location: [String!], $RoomNo: [String!], $RoomTypeIDs: [String!], $enddate: DateTime!, $startdate: DateTime!, $HotelID: String!, $AccountID: String!) {
  StayViewListing(Location: $Location, RoomNo: $RoomNo, RoomTypeIDs: $RoomTypeIDs, enddate: $enddate, startdate: $startdate, HotelID: $HotelID, AccountID: $AccountID)
}
    `;

/**
 * __useStayViewListingQuery__
 *
 * To run a query within a React component, call `useStayViewListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useStayViewListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStayViewListingQuery({
 *   variables: {
 *      Location: // value for 'Location'
 *      RoomNo: // value for 'RoomNo'
 *      RoomTypeIDs: // value for 'RoomTypeIDs'
 *      enddate: // value for 'enddate'
 *      startdate: // value for 'startdate'
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useStayViewListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StayViewListingQuery, StayViewListingQueryVariables>) {
        return ApolloReactHooks.useQuery<StayViewListingQuery, StayViewListingQueryVariables>(StayViewListingDocument, baseOptions);
      }
export function useStayViewListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StayViewListingQuery, StayViewListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StayViewListingQuery, StayViewListingQueryVariables>(StayViewListingDocument, baseOptions);
        }
export type StayViewListingQueryHookResult = ReturnType<typeof useStayViewListingQuery>;
export type StayViewListingLazyQueryHookResult = ReturnType<typeof useStayViewListingLazyQuery>;
export type StayViewListingQueryResult = ApolloReactCommon.QueryResult<StayViewListingQuery, StayViewListingQueryVariables>;
export const SuspendFolioListingDocument = gql`
    query SuspendFolioListing($HotelID: String!, $FolioID: String) {
  SuspendFolioListing(HotelID: $HotelID, FolioID: $FolioID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    AccountID
    HotelID
    Registration {
      ID
    }
    NonGuestBalance {
      TotalBill
      TotalPaid
      TotalLeft
    }
    FolioType
    RefID
    FolioNo
    FolioDate
    FolioAmount
    ClosedDate
    ClosedBy
    IsPosted
    is_einvoice
    einvoice_status
    NonGuestInfo {
      EffectiveDate
      Title
      DebtorID
      Remark
      HouseLimit
      ReferenceID
    }
  }
}
    `;

/**
 * __useSuspendFolioListingQuery__
 *
 * To run a query within a React component, call `useSuspendFolioListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuspendFolioListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuspendFolioListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function useSuspendFolioListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SuspendFolioListingQuery, SuspendFolioListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SuspendFolioListingQuery, SuspendFolioListingQueryVariables>(SuspendFolioListingDocument, baseOptions);
      }
export function useSuspendFolioListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuspendFolioListingQuery, SuspendFolioListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SuspendFolioListingQuery, SuspendFolioListingQueryVariables>(SuspendFolioListingDocument, baseOptions);
        }
export type SuspendFolioListingQueryHookResult = ReturnType<typeof useSuspendFolioListingQuery>;
export type SuspendFolioListingLazyQueryHookResult = ReturnType<typeof useSuspendFolioListingLazyQuery>;
export type SuspendFolioListingQueryResult = ApolloReactCommon.QueryResult<SuspendFolioListingQuery, SuspendFolioListingQueryVariables>;
export const SuspendFolioListingv2Document = gql`
    query SuspendFolioListingv2($limit: Float, $offset: Float, $HotelID: String!, $FolioID: String, $ReferenceID: String, $Title: String, $FolioDate: DateTime, $DebtorID: String) {
  SuspendFolioListingv2(limit: $limit, offset: $offset, HotelID: $HotelID, FolioID: $FolioID, ReferenceID: $ReferenceID, Title: $Title, FolioDate: $FolioDate, DebtorID: $DebtorID) {
    TotalCount
    Listing {
      ID
      IsActive
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      AccountID
      HotelID
      NonGuestBalance {
        TotalBill
        TotalPaid
        TotalLeft
      }
      FolioType
      RefID
      FolioNo
      FolioDate
      FolioAmount
      ClosedDate
      ClosedBy
      IsPosted
      NonGuestInfo {
        EffectiveDate
        Title
        DebtorID
        Remark
        HouseLimit
        ReferenceID
      }
    }
  }
}
    `;

/**
 * __useSuspendFolioListingv2Query__
 *
 * To run a query within a React component, call `useSuspendFolioListingv2Query` and pass it any options that fit your needs.
 * When your component renders, `useSuspendFolioListingv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuspendFolioListingv2Query({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      HotelID: // value for 'HotelID'
 *      FolioID: // value for 'FolioID'
 *      ReferenceID: // value for 'ReferenceID'
 *      Title: // value for 'Title'
 *      FolioDate: // value for 'FolioDate'
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useSuspendFolioListingv2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<SuspendFolioListingv2Query, SuspendFolioListingv2QueryVariables>) {
        return ApolloReactHooks.useQuery<SuspendFolioListingv2Query, SuspendFolioListingv2QueryVariables>(SuspendFolioListingv2Document, baseOptions);
      }
export function useSuspendFolioListingv2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuspendFolioListingv2Query, SuspendFolioListingv2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SuspendFolioListingv2Query, SuspendFolioListingv2QueryVariables>(SuspendFolioListingv2Document, baseOptions);
        }
export type SuspendFolioListingv2QueryHookResult = ReturnType<typeof useSuspendFolioListingv2Query>;
export type SuspendFolioListingv2LazyQueryHookResult = ReturnType<typeof useSuspendFolioListingv2LazyQuery>;
export type SuspendFolioListingv2QueryResult = ApolloReactCommon.QueryResult<SuspendFolioListingv2Query, SuspendFolioListingv2QueryVariables>;
export const PendingBillingListingDocument = gql`
    query PendingBillingListing($HotelID: String, $FolioID: String!) {
  PendingBillingListing(HotelID: $HotelID, FolioID: $FolioID) {
    ID
    IncidentalDescription
    EffectiveDate
    ReferenceNo
    ReversedID
    TrxAmount
    TaxAmount
    ServiceCharge
    BaseAmount
    DiscountAmount
    Type
    TransactionType
    ReceiptNo
    Description
    Qty
    RegistrationID
    IsOutlet
    IsEventInterface
    TaxBillLedger {
      ID
      BillLedgerID
      TaxSchemeID
      TaxSchemeDetailID
      TaxID
      TaxRate
      TaxAmount
      TaxInfo {
        code
      }
    }
    TaxRoomLedger {
      ID
      RoomLedgerID
      TaxSchemeID
      TaxSchemeDetailID
      TaxID
      TaxRate
      TaxAmount
      TaxInfo {
        code
      }
    }
    CreatedBy
    CreatedDT
    UnitPrice
    Reason
  }
}
    `;

/**
 * __usePendingBillingListingQuery__
 *
 * To run a query within a React component, call `usePendingBillingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingBillingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingBillingListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function usePendingBillingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PendingBillingListingQuery, PendingBillingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PendingBillingListingQuery, PendingBillingListingQueryVariables>(PendingBillingListingDocument, baseOptions);
      }
export function usePendingBillingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PendingBillingListingQuery, PendingBillingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PendingBillingListingQuery, PendingBillingListingQueryVariables>(PendingBillingListingDocument, baseOptions);
        }
export type PendingBillingListingQueryHookResult = ReturnType<typeof usePendingBillingListingQuery>;
export type PendingBillingListingLazyQueryHookResult = ReturnType<typeof usePendingBillingListingLazyQuery>;
export type PendingBillingListingQueryResult = ApolloReactCommon.QueryResult<PendingBillingListingQuery, PendingBillingListingQueryVariables>;
export const SuspendFolioInsertDocument = gql`
    mutation SuspendFolioInsert($FolioInput: FolioInput!) {
  SuspendFolioInsert(FolioInput: $FolioInput)
}
    `;
export type SuspendFolioInsertMutationFn = ApolloReactCommon.MutationFunction<SuspendFolioInsertMutation, SuspendFolioInsertMutationVariables>;

/**
 * __useSuspendFolioInsertMutation__
 *
 * To run a mutation, you first call `useSuspendFolioInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendFolioInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendFolioInsertMutation, { data, loading, error }] = useSuspendFolioInsertMutation({
 *   variables: {
 *      FolioInput: // value for 'FolioInput'
 *   },
 * });
 */
export function useSuspendFolioInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SuspendFolioInsertMutation, SuspendFolioInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<SuspendFolioInsertMutation, SuspendFolioInsertMutationVariables>(SuspendFolioInsertDocument, baseOptions);
      }
export type SuspendFolioInsertMutationHookResult = ReturnType<typeof useSuspendFolioInsertMutation>;
export type SuspendFolioInsertMutationResult = ApolloReactCommon.MutationResult<SuspendFolioInsertMutation>;
export type SuspendFolioInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<SuspendFolioInsertMutation, SuspendFolioInsertMutationVariables>;
export const SuspendDetailDocument = gql`
    query SuspendDetail($ID: String!) {
  SuspendDetail(ID: $ID) {
    ID
    FolioNo
    NonGuestBalance {
      TotalLeft
    }
  }
}
    `;

/**
 * __useSuspendDetailQuery__
 *
 * To run a query within a React component, call `useSuspendDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuspendDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuspendDetailQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useSuspendDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SuspendDetailQuery, SuspendDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<SuspendDetailQuery, SuspendDetailQueryVariables>(SuspendDetailDocument, baseOptions);
      }
export function useSuspendDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuspendDetailQuery, SuspendDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SuspendDetailQuery, SuspendDetailQueryVariables>(SuspendDetailDocument, baseOptions);
        }
export type SuspendDetailQueryHookResult = ReturnType<typeof useSuspendDetailQuery>;
export type SuspendDetailLazyQueryHookResult = ReturnType<typeof useSuspendDetailLazyQuery>;
export type SuspendDetailQueryResult = ApolloReactCommon.QueryResult<SuspendDetailQuery, SuspendDetailQueryVariables>;
export const WalkIngBookingInsertDocument = gql`
    mutation WalkIngBookingInsert($RegistrationInput: RegistrationInput!, $ContactInput: ContactInput!, $BookingInput: BookingInput!, $AttachmentInput: [Upload!], $IdPicture: [Upload!], $IsTTx: Boolean, $TTxReasonID: String) {
  WalkIngBookingInsert(RegistrationInput: $RegistrationInput, ContactInput: $ContactInput, BookingInput: $BookingInput, AttachmentInput: $AttachmentInput, IdPicture: $IdPicture, IsTTx: $IsTTx, TTxReasonID: $TTxReasonID)
}
    `;
export type WalkIngBookingInsertMutationFn = ApolloReactCommon.MutationFunction<WalkIngBookingInsertMutation, WalkIngBookingInsertMutationVariables>;

/**
 * __useWalkIngBookingInsertMutation__
 *
 * To run a mutation, you first call `useWalkIngBookingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWalkIngBookingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [walkIngBookingInsertMutation, { data, loading, error }] = useWalkIngBookingInsertMutation({
 *   variables: {
 *      RegistrationInput: // value for 'RegistrationInput'
 *      ContactInput: // value for 'ContactInput'
 *      BookingInput: // value for 'BookingInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      IdPicture: // value for 'IdPicture'
 *      IsTTx: // value for 'IsTTx'
 *      TTxReasonID: // value for 'TTxReasonID'
 *   },
 * });
 */
export function useWalkIngBookingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WalkIngBookingInsertMutation, WalkIngBookingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<WalkIngBookingInsertMutation, WalkIngBookingInsertMutationVariables>(WalkIngBookingInsertDocument, baseOptions);
      }
export type WalkIngBookingInsertMutationHookResult = ReturnType<typeof useWalkIngBookingInsertMutation>;
export type WalkIngBookingInsertMutationResult = ApolloReactCommon.MutationResult<WalkIngBookingInsertMutation>;
export type WalkIngBookingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<WalkIngBookingInsertMutation, WalkIngBookingInsertMutationVariables>;
export const HotelSalesChannelListingDocument = gql`
    query HotelSalesChannelListing($HotelID: String!) {
  HotelSalesChannelListing(HotelID: $HotelID) {
    ID
    Description
  }
}
    `;

/**
 * __useHotelSalesChannelListingQuery__
 *
 * To run a query within a React component, call `useHotelSalesChannelListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelSalesChannelListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelSalesChannelListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useHotelSalesChannelListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelSalesChannelListingQuery, HotelSalesChannelListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelSalesChannelListingQuery, HotelSalesChannelListingQueryVariables>(HotelSalesChannelListingDocument, baseOptions);
      }
export function useHotelSalesChannelListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelSalesChannelListingQuery, HotelSalesChannelListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelSalesChannelListingQuery, HotelSalesChannelListingQueryVariables>(HotelSalesChannelListingDocument, baseOptions);
        }
export type HotelSalesChannelListingQueryHookResult = ReturnType<typeof useHotelSalesChannelListingQuery>;
export type HotelSalesChannelListingLazyQueryHookResult = ReturnType<typeof useHotelSalesChannelListingLazyQuery>;
export type HotelSalesChannelListingQueryResult = ApolloReactCommon.QueryResult<HotelSalesChannelListingQuery, HotelSalesChannelListingQueryVariables>;
export const AccountMappingChargesListDocument = gql`
    query AccountMappingChargesList($HotelID: String!) {
  AccountMappingChargesList(HotelID: $HotelID)
}
    `;

/**
 * __useAccountMappingChargesListQuery__
 *
 * To run a query within a React component, call `useAccountMappingChargesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingChargesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMappingChargesListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAccountMappingChargesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountMappingChargesListQuery, AccountMappingChargesListQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountMappingChargesListQuery, AccountMappingChargesListQueryVariables>(AccountMappingChargesListDocument, baseOptions);
      }
export function useAccountMappingChargesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountMappingChargesListQuery, AccountMappingChargesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountMappingChargesListQuery, AccountMappingChargesListQueryVariables>(AccountMappingChargesListDocument, baseOptions);
        }
export type AccountMappingChargesListQueryHookResult = ReturnType<typeof useAccountMappingChargesListQuery>;
export type AccountMappingChargesListLazyQueryHookResult = ReturnType<typeof useAccountMappingChargesListLazyQuery>;
export type AccountMappingChargesListQueryResult = ApolloReactCommon.QueryResult<AccountMappingChargesListQuery, AccountMappingChargesListQueryVariables>;
export const AccountMappingPaymentListDocument = gql`
    query AccountMappingPaymentList($HotelID: String!) {
  AccountMappingPaymentList(HotelID: $HotelID)
}
    `;

/**
 * __useAccountMappingPaymentListQuery__
 *
 * To run a query within a React component, call `useAccountMappingPaymentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingPaymentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMappingPaymentListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAccountMappingPaymentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountMappingPaymentListQuery, AccountMappingPaymentListQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountMappingPaymentListQuery, AccountMappingPaymentListQueryVariables>(AccountMappingPaymentListDocument, baseOptions);
      }
export function useAccountMappingPaymentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountMappingPaymentListQuery, AccountMappingPaymentListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountMappingPaymentListQuery, AccountMappingPaymentListQueryVariables>(AccountMappingPaymentListDocument, baseOptions);
        }
export type AccountMappingPaymentListQueryHookResult = ReturnType<typeof useAccountMappingPaymentListQuery>;
export type AccountMappingPaymentListLazyQueryHookResult = ReturnType<typeof useAccountMappingPaymentListLazyQuery>;
export type AccountMappingPaymentListQueryResult = ApolloReactCommon.QueryResult<AccountMappingPaymentListQuery, AccountMappingPaymentListQueryVariables>;
export const AccountMappingAdvPaymentListDocument = gql`
    query AccountMappingAdvPaymentList($HotelID: String!) {
  AccountMappingAdvPaymentList(HotelID: $HotelID)
}
    `;

/**
 * __useAccountMappingAdvPaymentListQuery__
 *
 * To run a query within a React component, call `useAccountMappingAdvPaymentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingAdvPaymentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMappingAdvPaymentListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAccountMappingAdvPaymentListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountMappingAdvPaymentListQuery, AccountMappingAdvPaymentListQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountMappingAdvPaymentListQuery, AccountMappingAdvPaymentListQueryVariables>(AccountMappingAdvPaymentListDocument, baseOptions);
      }
export function useAccountMappingAdvPaymentListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountMappingAdvPaymentListQuery, AccountMappingAdvPaymentListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountMappingAdvPaymentListQuery, AccountMappingAdvPaymentListQueryVariables>(AccountMappingAdvPaymentListDocument, baseOptions);
        }
export type AccountMappingAdvPaymentListQueryHookResult = ReturnType<typeof useAccountMappingAdvPaymentListQuery>;
export type AccountMappingAdvPaymentListLazyQueryHookResult = ReturnType<typeof useAccountMappingAdvPaymentListLazyQuery>;
export type AccountMappingAdvPaymentListQueryResult = ApolloReactCommon.QueryResult<AccountMappingAdvPaymentListQuery, AccountMappingAdvPaymentListQueryVariables>;
export const AccountMappingRefundListDocument = gql`
    query AccountMappingRefundList($HotelID: String!) {
  AccountMappingRefundList(HotelID: $HotelID)
}
    `;

/**
 * __useAccountMappingRefundListQuery__
 *
 * To run a query within a React component, call `useAccountMappingRefundListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingRefundListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMappingRefundListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAccountMappingRefundListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountMappingRefundListQuery, AccountMappingRefundListQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountMappingRefundListQuery, AccountMappingRefundListQueryVariables>(AccountMappingRefundListDocument, baseOptions);
      }
export function useAccountMappingRefundListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountMappingRefundListQuery, AccountMappingRefundListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountMappingRefundListQuery, AccountMappingRefundListQueryVariables>(AccountMappingRefundListDocument, baseOptions);
        }
export type AccountMappingRefundListQueryHookResult = ReturnType<typeof useAccountMappingRefundListQuery>;
export type AccountMappingRefundListLazyQueryHookResult = ReturnType<typeof useAccountMappingRefundListLazyQuery>;
export type AccountMappingRefundListQueryResult = ApolloReactCommon.QueryResult<AccountMappingRefundListQuery, AccountMappingRefundListQueryVariables>;
export const AccountMappingDepositListDocument = gql`
    query AccountMappingDepositList($HotelID: String!) {
  AccountMappingDepositList(HotelID: $HotelID)
}
    `;

/**
 * __useAccountMappingDepositListQuery__
 *
 * To run a query within a React component, call `useAccountMappingDepositListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingDepositListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMappingDepositListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAccountMappingDepositListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountMappingDepositListQuery, AccountMappingDepositListQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountMappingDepositListQuery, AccountMappingDepositListQueryVariables>(AccountMappingDepositListDocument, baseOptions);
      }
export function useAccountMappingDepositListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountMappingDepositListQuery, AccountMappingDepositListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountMappingDepositListQuery, AccountMappingDepositListQueryVariables>(AccountMappingDepositListDocument, baseOptions);
        }
export type AccountMappingDepositListQueryHookResult = ReturnType<typeof useAccountMappingDepositListQuery>;
export type AccountMappingDepositListLazyQueryHookResult = ReturnType<typeof useAccountMappingDepositListLazyQuery>;
export type AccountMappingDepositListQueryResult = ApolloReactCommon.QueryResult<AccountMappingDepositListQuery, AccountMappingDepositListQueryVariables>;
export const AccountMappingGlInsertDocument = gql`
    mutation AccountMappingGLInsert($GuestLedgerAcctInput: GuestLedgerAcctInput!, $GLInput: AccountMappingGLInput!, $HotelID: String!) {
  AccountMappingGLInsert(GuestLedgerAcctInput: $GuestLedgerAcctInput, GLInput: $GLInput, HotelID: $HotelID)
}
    `;
export type AccountMappingGlInsertMutationFn = ApolloReactCommon.MutationFunction<AccountMappingGlInsertMutation, AccountMappingGlInsertMutationVariables>;

/**
 * __useAccountMappingGlInsertMutation__
 *
 * To run a mutation, you first call `useAccountMappingGlInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingGlInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountMappingGlInsertMutation, { data, loading, error }] = useAccountMappingGlInsertMutation({
 *   variables: {
 *      GuestLedgerAcctInput: // value for 'GuestLedgerAcctInput'
 *      GLInput: // value for 'GLInput'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useAccountMappingGlInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AccountMappingGlInsertMutation, AccountMappingGlInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<AccountMappingGlInsertMutation, AccountMappingGlInsertMutationVariables>(AccountMappingGlInsertDocument, baseOptions);
      }
export type AccountMappingGlInsertMutationHookResult = ReturnType<typeof useAccountMappingGlInsertMutation>;
export type AccountMappingGlInsertMutationResult = ApolloReactCommon.MutationResult<AccountMappingGlInsertMutation>;
export type AccountMappingGlInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<AccountMappingGlInsertMutation, AccountMappingGlInsertMutationVariables>;
export const CityLedgerChargesListDocument = gql`
    query CityLedgerChargesList($HotelID: String!) {
  CityLedgerChargesList(HotelID: $HotelID)
}
    `;

/**
 * __useCityLedgerChargesListQuery__
 *
 * To run a query within a React component, call `useCityLedgerChargesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityLedgerChargesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityLedgerChargesListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCityLedgerChargesListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CityLedgerChargesListQuery, CityLedgerChargesListQueryVariables>) {
        return ApolloReactHooks.useQuery<CityLedgerChargesListQuery, CityLedgerChargesListQueryVariables>(CityLedgerChargesListDocument, baseOptions);
      }
export function useCityLedgerChargesListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CityLedgerChargesListQuery, CityLedgerChargesListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CityLedgerChargesListQuery, CityLedgerChargesListQueryVariables>(CityLedgerChargesListDocument, baseOptions);
        }
export type CityLedgerChargesListQueryHookResult = ReturnType<typeof useCityLedgerChargesListQuery>;
export type CityLedgerChargesListLazyQueryHookResult = ReturnType<typeof useCityLedgerChargesListLazyQuery>;
export type CityLedgerChargesListQueryResult = ApolloReactCommon.QueryResult<CityLedgerChargesListQuery, CityLedgerChargesListQueryVariables>;
export const CityLedgerDebtorListDocument = gql`
    query CityLedgerDebtorList($HotelID: String!) {
  CityLedgerDebtorList(HotelID: $HotelID)
}
    `;

/**
 * __useCityLedgerDebtorListQuery__
 *
 * To run a query within a React component, call `useCityLedgerDebtorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityLedgerDebtorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityLedgerDebtorListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCityLedgerDebtorListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CityLedgerDebtorListQuery, CityLedgerDebtorListQueryVariables>) {
        return ApolloReactHooks.useQuery<CityLedgerDebtorListQuery, CityLedgerDebtorListQueryVariables>(CityLedgerDebtorListDocument, baseOptions);
      }
export function useCityLedgerDebtorListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CityLedgerDebtorListQuery, CityLedgerDebtorListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CityLedgerDebtorListQuery, CityLedgerDebtorListQueryVariables>(CityLedgerDebtorListDocument, baseOptions);
        }
export type CityLedgerDebtorListQueryHookResult = ReturnType<typeof useCityLedgerDebtorListQuery>;
export type CityLedgerDebtorListLazyQueryHookResult = ReturnType<typeof useCityLedgerDebtorListLazyQuery>;
export type CityLedgerDebtorListQueryResult = ApolloReactCommon.QueryResult<CityLedgerDebtorListQuery, CityLedgerDebtorListQueryVariables>;
export const CityLedgerGlInsertDocument = gql`
    mutation CityLedgerGLInsert($GLInput: AccountMappingGLInput!, $HotelID: String!) {
  CityLedgerGLInsert(GLInput: $GLInput, HotelID: $HotelID)
}
    `;
export type CityLedgerGlInsertMutationFn = ApolloReactCommon.MutationFunction<CityLedgerGlInsertMutation, CityLedgerGlInsertMutationVariables>;

/**
 * __useCityLedgerGlInsertMutation__
 *
 * To run a mutation, you first call `useCityLedgerGlInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCityLedgerGlInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cityLedgerGlInsertMutation, { data, loading, error }] = useCityLedgerGlInsertMutation({
 *   variables: {
 *      GLInput: // value for 'GLInput'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCityLedgerGlInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CityLedgerGlInsertMutation, CityLedgerGlInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CityLedgerGlInsertMutation, CityLedgerGlInsertMutationVariables>(CityLedgerGlInsertDocument, baseOptions);
      }
export type CityLedgerGlInsertMutationHookResult = ReturnType<typeof useCityLedgerGlInsertMutation>;
export type CityLedgerGlInsertMutationResult = ApolloReactCommon.MutationResult<CityLedgerGlInsertMutation>;
export type CityLedgerGlInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CityLedgerGlInsertMutation, CityLedgerGlInsertMutationVariables>;
export const AccountMappingTaxGlListDocument = gql`
    query AccountMappingTaxGLList($HotelID: String!, $AccountID: String!) {
  AccountMappingTaxGLList(HotelID: $HotelID, AccountID: $AccountID)
}
    `;

/**
 * __useAccountMappingTaxGlListQuery__
 *
 * To run a query within a React component, call `useAccountMappingTaxGlListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingTaxGlListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMappingTaxGlListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useAccountMappingTaxGlListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountMappingTaxGlListQuery, AccountMappingTaxGlListQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountMappingTaxGlListQuery, AccountMappingTaxGlListQueryVariables>(AccountMappingTaxGlListDocument, baseOptions);
      }
export function useAccountMappingTaxGlListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountMappingTaxGlListQuery, AccountMappingTaxGlListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountMappingTaxGlListQuery, AccountMappingTaxGlListQueryVariables>(AccountMappingTaxGlListDocument, baseOptions);
        }
export type AccountMappingTaxGlListQueryHookResult = ReturnType<typeof useAccountMappingTaxGlListQuery>;
export type AccountMappingTaxGlListLazyQueryHookResult = ReturnType<typeof useAccountMappingTaxGlListLazyQuery>;
export type AccountMappingTaxGlListQueryResult = ApolloReactCommon.QueryResult<AccountMappingTaxGlListQuery, AccountMappingTaxGlListQueryVariables>;
export const AccountMappingTaxGlInsertDocument = gql`
    mutation AccountMappingTaxGLInsert($HotelID: String!, $AccountID: String!, $TaxGLInput: AccountMappingTaxGLInput!) {
  AccountMappingTaxGLInsert(HotelID: $HotelID, AccountID: $AccountID, TaxGLInput: $TaxGLInput)
}
    `;
export type AccountMappingTaxGlInsertMutationFn = ApolloReactCommon.MutationFunction<AccountMappingTaxGlInsertMutation, AccountMappingTaxGlInsertMutationVariables>;

/**
 * __useAccountMappingTaxGlInsertMutation__
 *
 * To run a mutation, you first call `useAccountMappingTaxGlInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountMappingTaxGlInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountMappingTaxGlInsertMutation, { data, loading, error }] = useAccountMappingTaxGlInsertMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      TaxGLInput: // value for 'TaxGLInput'
 *   },
 * });
 */
export function useAccountMappingTaxGlInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AccountMappingTaxGlInsertMutation, AccountMappingTaxGlInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<AccountMappingTaxGlInsertMutation, AccountMappingTaxGlInsertMutationVariables>(AccountMappingTaxGlInsertDocument, baseOptions);
      }
export type AccountMappingTaxGlInsertMutationHookResult = ReturnType<typeof useAccountMappingTaxGlInsertMutation>;
export type AccountMappingTaxGlInsertMutationResult = ApolloReactCommon.MutationResult<AccountMappingTaxGlInsertMutation>;
export type AccountMappingTaxGlInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<AccountMappingTaxGlInsertMutation, AccountMappingTaxGlInsertMutationVariables>;
export const CityLedgerTaxGlListDocument = gql`
    query CityLedgerTaxGLList($HotelID: String!, $AccountID: String!) {
  CityLedgerTaxGLList(HotelID: $HotelID, AccountID: $AccountID)
}
    `;

/**
 * __useCityLedgerTaxGlListQuery__
 *
 * To run a query within a React component, call `useCityLedgerTaxGlListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityLedgerTaxGlListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityLedgerTaxGlListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useCityLedgerTaxGlListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CityLedgerTaxGlListQuery, CityLedgerTaxGlListQueryVariables>) {
        return ApolloReactHooks.useQuery<CityLedgerTaxGlListQuery, CityLedgerTaxGlListQueryVariables>(CityLedgerTaxGlListDocument, baseOptions);
      }
export function useCityLedgerTaxGlListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CityLedgerTaxGlListQuery, CityLedgerTaxGlListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CityLedgerTaxGlListQuery, CityLedgerTaxGlListQueryVariables>(CityLedgerTaxGlListDocument, baseOptions);
        }
export type CityLedgerTaxGlListQueryHookResult = ReturnType<typeof useCityLedgerTaxGlListQuery>;
export type CityLedgerTaxGlListLazyQueryHookResult = ReturnType<typeof useCityLedgerTaxGlListLazyQuery>;
export type CityLedgerTaxGlListQueryResult = ApolloReactCommon.QueryResult<CityLedgerTaxGlListQuery, CityLedgerTaxGlListQueryVariables>;
export const CityLedgerTaxGlInsertDocument = gql`
    mutation CityLedgerTaxGLInsert($HotelID: String!, $AccountID: String!, $TaxGLInput: AccountMappingTaxGLInput!) {
  CityLedgerTaxGLInsert(HotelID: $HotelID, AccountID: $AccountID, TaxGLInput: $TaxGLInput)
}
    `;
export type CityLedgerTaxGlInsertMutationFn = ApolloReactCommon.MutationFunction<CityLedgerTaxGlInsertMutation, CityLedgerTaxGlInsertMutationVariables>;

/**
 * __useCityLedgerTaxGlInsertMutation__
 *
 * To run a mutation, you first call `useCityLedgerTaxGlInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCityLedgerTaxGlInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cityLedgerTaxGlInsertMutation, { data, loading, error }] = useCityLedgerTaxGlInsertMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      TaxGLInput: // value for 'TaxGLInput'
 *   },
 * });
 */
export function useCityLedgerTaxGlInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CityLedgerTaxGlInsertMutation, CityLedgerTaxGlInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<CityLedgerTaxGlInsertMutation, CityLedgerTaxGlInsertMutationVariables>(CityLedgerTaxGlInsertDocument, baseOptions);
      }
export type CityLedgerTaxGlInsertMutationHookResult = ReturnType<typeof useCityLedgerTaxGlInsertMutation>;
export type CityLedgerTaxGlInsertMutationResult = ApolloReactCommon.MutationResult<CityLedgerTaxGlInsertMutation>;
export type CityLedgerTaxGlInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<CityLedgerTaxGlInsertMutation, CityLedgerTaxGlInsertMutationVariables>;
export const BankAccountListingDocument = gql`
    query BankAccountListing($HotelID: String!) {
  BankAccountListing(HotelID: $HotelID) {
    ID
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    IsActive
    HotelID
    BankName
    BankCode
    AccountHolderName
    AccountNo
    SwiftCode
    Branch
    BankContactNo
    IsSecurityCash
    IsKeyCardCash
    IsCash
    BankClearance
    Address {
      address
      country
      city
      state
      postCode
    }
    ContactPerson {
      name
      designation
      contactNo
      email
    }
  }
}
    `;

/**
 * __useBankAccountListingQuery__
 *
 * To run a query within a React component, call `useBankAccountListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBankAccountListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBankAccountListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBankAccountListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BankAccountListingQuery, BankAccountListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BankAccountListingQuery, BankAccountListingQueryVariables>(BankAccountListingDocument, baseOptions);
      }
export function useBankAccountListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BankAccountListingQuery, BankAccountListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BankAccountListingQuery, BankAccountListingQueryVariables>(BankAccountListingDocument, baseOptions);
        }
export type BankAccountListingQueryHookResult = ReturnType<typeof useBankAccountListingQuery>;
export type BankAccountListingLazyQueryHookResult = ReturnType<typeof useBankAccountListingLazyQuery>;
export type BankAccountListingQueryResult = ApolloReactCommon.QueryResult<BankAccountListingQuery, BankAccountListingQueryVariables>;
export const BankAccountInsertDocument = gql`
    mutation BankAccountInsert($BankAccountInput: BankAccountInput!) {
  BankAccountInsert(BankAccountInput: $BankAccountInput) {
    ID
  }
}
    `;
export type BankAccountInsertMutationFn = ApolloReactCommon.MutationFunction<BankAccountInsertMutation, BankAccountInsertMutationVariables>;

/**
 * __useBankAccountInsertMutation__
 *
 * To run a mutation, you first call `useBankAccountInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankAccountInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankAccountInsertMutation, { data, loading, error }] = useBankAccountInsertMutation({
 *   variables: {
 *      BankAccountInput: // value for 'BankAccountInput'
 *   },
 * });
 */
export function useBankAccountInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BankAccountInsertMutation, BankAccountInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BankAccountInsertMutation, BankAccountInsertMutationVariables>(BankAccountInsertDocument, baseOptions);
      }
export type BankAccountInsertMutationHookResult = ReturnType<typeof useBankAccountInsertMutation>;
export type BankAccountInsertMutationResult = ApolloReactCommon.MutationResult<BankAccountInsertMutation>;
export type BankAccountInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BankAccountInsertMutation, BankAccountInsertMutationVariables>;
export const BankAccountUpdateDocument = gql`
    mutation BankAccountUpdate($BankAccountInput: BankAccountInput!) {
  BankAccountUpdate(BankAccountInput: $BankAccountInput)
}
    `;
export type BankAccountUpdateMutationFn = ApolloReactCommon.MutationFunction<BankAccountUpdateMutation, BankAccountUpdateMutationVariables>;

/**
 * __useBankAccountUpdateMutation__
 *
 * To run a mutation, you first call `useBankAccountUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankAccountUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankAccountUpdateMutation, { data, loading, error }] = useBankAccountUpdateMutation({
 *   variables: {
 *      BankAccountInput: // value for 'BankAccountInput'
 *   },
 * });
 */
export function useBankAccountUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BankAccountUpdateMutation, BankAccountUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BankAccountUpdateMutation, BankAccountUpdateMutationVariables>(BankAccountUpdateDocument, baseOptions);
      }
export type BankAccountUpdateMutationHookResult = ReturnType<typeof useBankAccountUpdateMutation>;
export type BankAccountUpdateMutationResult = ApolloReactCommon.MutationResult<BankAccountUpdateMutation>;
export type BankAccountUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BankAccountUpdateMutation, BankAccountUpdateMutationVariables>;
export const BankAccountDeleteDocument = gql`
    mutation BankAccountDelete($ID: String!) {
  BankAccountDelete(ID: $ID)
}
    `;
export type BankAccountDeleteMutationFn = ApolloReactCommon.MutationFunction<BankAccountDeleteMutation, BankAccountDeleteMutationVariables>;

/**
 * __useBankAccountDeleteMutation__
 *
 * To run a mutation, you first call `useBankAccountDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankAccountDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankAccountDeleteMutation, { data, loading, error }] = useBankAccountDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useBankAccountDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BankAccountDeleteMutation, BankAccountDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<BankAccountDeleteMutation, BankAccountDeleteMutationVariables>(BankAccountDeleteDocument, baseOptions);
      }
export type BankAccountDeleteMutationHookResult = ReturnType<typeof useBankAccountDeleteMutation>;
export type BankAccountDeleteMutationResult = ApolloReactCommon.MutationResult<BankAccountDeleteMutation>;
export type BankAccountDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<BankAccountDeleteMutation, BankAccountDeleteMutationVariables>;
export const BankAccountInActiveDocument = gql`
    mutation BankAccountInActive($ID: String!, $IsActive: Boolean!) {
  BankAccountInActive(ID: $ID, IsActive: $IsActive)
}
    `;
export type BankAccountInActiveMutationFn = ApolloReactCommon.MutationFunction<BankAccountInActiveMutation, BankAccountInActiveMutationVariables>;

/**
 * __useBankAccountInActiveMutation__
 *
 * To run a mutation, you first call `useBankAccountInActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBankAccountInActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bankAccountInActiveMutation, { data, loading, error }] = useBankAccountInActiveMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useBankAccountInActiveMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BankAccountInActiveMutation, BankAccountInActiveMutationVariables>) {
        return ApolloReactHooks.useMutation<BankAccountInActiveMutation, BankAccountInActiveMutationVariables>(BankAccountInActiveDocument, baseOptions);
      }
export type BankAccountInActiveMutationHookResult = ReturnType<typeof useBankAccountInActiveMutation>;
export type BankAccountInActiveMutationResult = ApolloReactCommon.MutationResult<BankAccountInActiveMutation>;
export type BankAccountInActiveMutationOptions = ApolloReactCommon.BaseMutationOptions<BankAccountInActiveMutation, BankAccountInActiveMutationVariables>;
export const GlExportDocument = gql`
    query GLExport($HotelID: String!, $AccountID: String!, $EndDate: DateTime!, $StartDate: DateTime!) {
  GLExport(HotelID: $HotelID, AccountID: $AccountID, EndDate: $EndDate, StartDate: $StartDate)
}
    `;

/**
 * __useGlExportQuery__
 *
 * To run a query within a React component, call `useGlExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlExportQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useGlExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlExportQuery, GlExportQueryVariables>) {
        return ApolloReactHooks.useQuery<GlExportQuery, GlExportQueryVariables>(GlExportDocument, baseOptions);
      }
export function useGlExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlExportQuery, GlExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlExportQuery, GlExportQueryVariables>(GlExportDocument, baseOptions);
        }
export type GlExportQueryHookResult = ReturnType<typeof useGlExportQuery>;
export type GlExportLazyQueryHookResult = ReturnType<typeof useGlExportLazyQuery>;
export type GlExportQueryResult = ApolloReactCommon.QueryResult<GlExportQuery, GlExportQueryVariables>;
export const GlPostDocument = gql`
    mutation GLPost($GLDate: DateTime!, $HotelID: String!, $AccountID: String!, $EndDate: DateTime!, $StartDate: DateTime!, $IsTransfer: Boolean) {
  GLPost(GLDate: $GLDate, HotelID: $HotelID, AccountID: $AccountID, EndDate: $EndDate, StartDate: $StartDate, IsTransfer: $IsTransfer) {
    GLPost
    ExportedGLTransfer {
      AccountID
      CompanyName
      JournalTypeName
      JournalDate
      DocNo
      Description
      AccountName
      DepartmentCode
      MasterCOACode
      Amount
      Remark
    }
  }
}
    `;
export type GlPostMutationFn = ApolloReactCommon.MutationFunction<GlPostMutation, GlPostMutationVariables>;

/**
 * __useGlPostMutation__
 *
 * To run a mutation, you first call `useGlPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGlPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [glPostMutation, { data, loading, error }] = useGlPostMutation({
 *   variables: {
 *      GLDate: // value for 'GLDate'
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      IsTransfer: // value for 'IsTransfer'
 *   },
 * });
 */
export function useGlPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GlPostMutation, GlPostMutationVariables>) {
        return ApolloReactHooks.useMutation<GlPostMutation, GlPostMutationVariables>(GlPostDocument, baseOptions);
      }
export type GlPostMutationHookResult = ReturnType<typeof useGlPostMutation>;
export type GlPostMutationResult = ApolloReactCommon.MutationResult<GlPostMutation>;
export type GlPostMutationOptions = ApolloReactCommon.BaseMutationOptions<GlPostMutation, GlPostMutationVariables>;
export const ExportedGlListingDocument = gql`
    query ExportedGLListing($HotelID: String!) {
  ExportedGLListing(HotelID: $HotelID)
}
    `;

/**
 * __useExportedGlListingQuery__
 *
 * To run a query within a React component, call `useExportedGlListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportedGlListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportedGlListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useExportedGlListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExportedGlListingQuery, ExportedGlListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ExportedGlListingQuery, ExportedGlListingQueryVariables>(ExportedGlListingDocument, baseOptions);
      }
export function useExportedGlListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportedGlListingQuery, ExportedGlListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExportedGlListingQuery, ExportedGlListingQueryVariables>(ExportedGlListingDocument, baseOptions);
        }
export type ExportedGlListingQueryHookResult = ReturnType<typeof useExportedGlListingQuery>;
export type ExportedGlListingLazyQueryHookResult = ReturnType<typeof useExportedGlListingLazyQuery>;
export type ExportedGlListingQueryResult = ApolloReactCommon.QueryResult<ExportedGlListingQuery, ExportedGlListingQueryVariables>;
export const ExportedGlDetailsDocument = gql`
    query ExportedGLDetails($HotelID: String!, $BatchNo: Float!) {
  ExportedGLDetails(HotelID: $HotelID, BatchNo: $BatchNo)
}
    `;

/**
 * __useExportedGlDetailsQuery__
 *
 * To run a query within a React component, call `useExportedGlDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportedGlDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportedGlDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BatchNo: // value for 'BatchNo'
 *   },
 * });
 */
export function useExportedGlDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExportedGlDetailsQuery, ExportedGlDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExportedGlDetailsQuery, ExportedGlDetailsQueryVariables>(ExportedGlDetailsDocument, baseOptions);
      }
export function useExportedGlDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportedGlDetailsQuery, ExportedGlDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExportedGlDetailsQuery, ExportedGlDetailsQueryVariables>(ExportedGlDetailsDocument, baseOptions);
        }
export type ExportedGlDetailsQueryHookResult = ReturnType<typeof useExportedGlDetailsQuery>;
export type ExportedGlDetailsLazyQueryHookResult = ReturnType<typeof useExportedGlDetailsLazyQuery>;
export type ExportedGlDetailsQueryResult = ApolloReactCommon.QueryResult<ExportedGlDetailsQuery, ExportedGlDetailsQueryVariables>;
export const ExportedGlTransferDetailsDocument = gql`
    query ExportedGLTransferDetails($HotelID: String!, $AccountID: String!, $BatchNo: Float!) {
  ExportedGLTransferDetails(HotelID: $HotelID, AccountID: $AccountID, BatchNo: $BatchNo) {
    AccountID
    CompanyName
    JournalTypeName
    JournalDate
    DocNo
    Description
    AccountName
    DepartmentCode
    MasterCOACode
    Amount
    Remark
  }
}
    `;

/**
 * __useExportedGlTransferDetailsQuery__
 *
 * To run a query within a React component, call `useExportedGlTransferDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportedGlTransferDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportedGlTransferDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      BatchNo: // value for 'BatchNo'
 *   },
 * });
 */
export function useExportedGlTransferDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExportedGlTransferDetailsQuery, ExportedGlTransferDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExportedGlTransferDetailsQuery, ExportedGlTransferDetailsQueryVariables>(ExportedGlTransferDetailsDocument, baseOptions);
      }
export function useExportedGlTransferDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportedGlTransferDetailsQuery, ExportedGlTransferDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExportedGlTransferDetailsQuery, ExportedGlTransferDetailsQueryVariables>(ExportedGlTransferDetailsDocument, baseOptions);
        }
export type ExportedGlTransferDetailsQueryHookResult = ReturnType<typeof useExportedGlTransferDetailsQuery>;
export type ExportedGlTransferDetailsLazyQueryHookResult = ReturnType<typeof useExportedGlTransferDetailsLazyQuery>;
export type ExportedGlTransferDetailsQueryResult = ApolloReactCommon.QueryResult<ExportedGlTransferDetailsQuery, ExportedGlTransferDetailsQueryVariables>;
export const GlExportDetailsByTrxTypeDocument = gql`
    query GLExportDetailsByTrxType($AccountCode: String, $Division: String, $Department: String, $BatchNo: Float!, $TrxDate: DateTime!, $TrxDesc: String!, $TrxType: String!, $HotelID: String!, $AccountID: String!) {
  GLExportDetailsByTrxType(AccountCode: $AccountCode, Division: $Division, Department: $Department, BatchNo: $BatchNo, TrxDate: $TrxDate, TrxDesc: $TrxDesc, TrxType: $TrxType, HotelID: $HotelID, AccountID: $AccountID)
}
    `;

/**
 * __useGlExportDetailsByTrxTypeQuery__
 *
 * To run a query within a React component, call `useGlExportDetailsByTrxTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlExportDetailsByTrxTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlExportDetailsByTrxTypeQuery({
 *   variables: {
 *      AccountCode: // value for 'AccountCode'
 *      Division: // value for 'Division'
 *      Department: // value for 'Department'
 *      BatchNo: // value for 'BatchNo'
 *      TrxDate: // value for 'TrxDate'
 *      TrxDesc: // value for 'TrxDesc'
 *      TrxType: // value for 'TrxType'
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGlExportDetailsByTrxTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlExportDetailsByTrxTypeQuery, GlExportDetailsByTrxTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GlExportDetailsByTrxTypeQuery, GlExportDetailsByTrxTypeQueryVariables>(GlExportDetailsByTrxTypeDocument, baseOptions);
      }
export function useGlExportDetailsByTrxTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlExportDetailsByTrxTypeQuery, GlExportDetailsByTrxTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlExportDetailsByTrxTypeQuery, GlExportDetailsByTrxTypeQueryVariables>(GlExportDetailsByTrxTypeDocument, baseOptions);
        }
export type GlExportDetailsByTrxTypeQueryHookResult = ReturnType<typeof useGlExportDetailsByTrxTypeQuery>;
export type GlExportDetailsByTrxTypeLazyQueryHookResult = ReturnType<typeof useGlExportDetailsByTrxTypeLazyQuery>;
export type GlExportDetailsByTrxTypeQueryResult = ApolloReactCommon.QueryResult<GlExportDetailsByTrxTypeQuery, GlExportDetailsByTrxTypeQueryVariables>;
export const CbExportDocument = gql`
    query CBExport($HotelID: String!, $AccountID: String!, $EndDate: DateTime!, $StartDate: DateTime!) {
  CBExport(HotelID: $HotelID, AccountID: $AccountID, EndDate: $EndDate, StartDate: $StartDate)
}
    `;

/**
 * __useCbExportQuery__
 *
 * To run a query within a React component, call `useCbExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCbExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCbExportQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *   },
 * });
 */
export function useCbExportQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CbExportQuery, CbExportQueryVariables>) {
        return ApolloReactHooks.useQuery<CbExportQuery, CbExportQueryVariables>(CbExportDocument, baseOptions);
      }
export function useCbExportLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CbExportQuery, CbExportQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CbExportQuery, CbExportQueryVariables>(CbExportDocument, baseOptions);
        }
export type CbExportQueryHookResult = ReturnType<typeof useCbExportQuery>;
export type CbExportLazyQueryHookResult = ReturnType<typeof useCbExportLazyQuery>;
export type CbExportQueryResult = ApolloReactCommon.QueryResult<CbExportQuery, CbExportQueryVariables>;
export const ExportedCbListingDocument = gql`
    query ExportedCBListing($HotelID: String!) {
  ExportedCBListing(HotelID: $HotelID)
}
    `;

/**
 * __useExportedCbListingQuery__
 *
 * To run a query within a React component, call `useExportedCbListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportedCbListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportedCbListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useExportedCbListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExportedCbListingQuery, ExportedCbListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ExportedCbListingQuery, ExportedCbListingQueryVariables>(ExportedCbListingDocument, baseOptions);
      }
export function useExportedCbListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportedCbListingQuery, ExportedCbListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExportedCbListingQuery, ExportedCbListingQueryVariables>(ExportedCbListingDocument, baseOptions);
        }
export type ExportedCbListingQueryHookResult = ReturnType<typeof useExportedCbListingQuery>;
export type ExportedCbListingLazyQueryHookResult = ReturnType<typeof useExportedCbListingLazyQuery>;
export type ExportedCbListingQueryResult = ApolloReactCommon.QueryResult<ExportedCbListingQuery, ExportedCbListingQueryVariables>;
export const ExportedCbDetailsDocument = gql`
    query ExportedCBDetails($HotelID: String!, $BatchNo: Float!) {
  ExportedCBDetails(HotelID: $HotelID, BatchNo: $BatchNo)
}
    `;

/**
 * __useExportedCbDetailsQuery__
 *
 * To run a query within a React component, call `useExportedCbDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportedCbDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportedCbDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      BatchNo: // value for 'BatchNo'
 *   },
 * });
 */
export function useExportedCbDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExportedCbDetailsQuery, ExportedCbDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExportedCbDetailsQuery, ExportedCbDetailsQueryVariables>(ExportedCbDetailsDocument, baseOptions);
      }
export function useExportedCbDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExportedCbDetailsQuery, ExportedCbDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExportedCbDetailsQuery, ExportedCbDetailsQueryVariables>(ExportedCbDetailsDocument, baseOptions);
        }
export type ExportedCbDetailsQueryHookResult = ReturnType<typeof useExportedCbDetailsQuery>;
export type ExportedCbDetailsLazyQueryHookResult = ReturnType<typeof useExportedCbDetailsLazyQuery>;
export type ExportedCbDetailsQueryResult = ApolloReactCommon.QueryResult<ExportedCbDetailsQuery, ExportedCbDetailsQueryVariables>;
export const CbExportDetailsByTrxTypeDocument = gql`
    query CBExportDetailsByTrxType($TrxDate: DateTime!, $TrxType: String!, $HotelID: String!, $AccountID: String!) {
  CBExportDetailsByTrxType(TrxDate: $TrxDate, TrxType: $TrxType, HotelID: $HotelID, AccountID: $AccountID)
}
    `;

/**
 * __useCbExportDetailsByTrxTypeQuery__
 *
 * To run a query within a React component, call `useCbExportDetailsByTrxTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCbExportDetailsByTrxTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCbExportDetailsByTrxTypeQuery({
 *   variables: {
 *      TrxDate: // value for 'TrxDate'
 *      TrxType: // value for 'TrxType'
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useCbExportDetailsByTrxTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CbExportDetailsByTrxTypeQuery, CbExportDetailsByTrxTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<CbExportDetailsByTrxTypeQuery, CbExportDetailsByTrxTypeQueryVariables>(CbExportDetailsByTrxTypeDocument, baseOptions);
      }
export function useCbExportDetailsByTrxTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CbExportDetailsByTrxTypeQuery, CbExportDetailsByTrxTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CbExportDetailsByTrxTypeQuery, CbExportDetailsByTrxTypeQueryVariables>(CbExportDetailsByTrxTypeDocument, baseOptions);
        }
export type CbExportDetailsByTrxTypeQueryHookResult = ReturnType<typeof useCbExportDetailsByTrxTypeQuery>;
export type CbExportDetailsByTrxTypeLazyQueryHookResult = ReturnType<typeof useCbExportDetailsByTrxTypeLazyQuery>;
export type CbExportDetailsByTrxTypeQueryResult = ApolloReactCommon.QueryResult<CbExportDetailsByTrxTypeQuery, CbExportDetailsByTrxTypeQueryVariables>;
export const CbPostDocument = gql`
    mutation CBPost($GLDate: DateTime!, $HotelID: String!, $AccountID: String!, $EndDate: DateTime!, $StartDate: DateTime!, $IsTransfer: Boolean) {
  CBPost(GLDate: $GLDate, HotelID: $HotelID, AccountID: $AccountID, EndDate: $EndDate, StartDate: $StartDate, IsTransfer: $IsTransfer)
}
    `;
export type CbPostMutationFn = ApolloReactCommon.MutationFunction<CbPostMutation, CbPostMutationVariables>;

/**
 * __useCbPostMutation__
 *
 * To run a mutation, you first call `useCbPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCbPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cbPostMutation, { data, loading, error }] = useCbPostMutation({
 *   variables: {
 *      GLDate: // value for 'GLDate'
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      IsTransfer: // value for 'IsTransfer'
 *   },
 * });
 */
export function useCbPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CbPostMutation, CbPostMutationVariables>) {
        return ApolloReactHooks.useMutation<CbPostMutation, CbPostMutationVariables>(CbPostDocument, baseOptions);
      }
export type CbPostMutationHookResult = ReturnType<typeof useCbPostMutation>;
export type CbPostMutationResult = ApolloReactCommon.MutationResult<CbPostMutation>;
export type CbPostMutationOptions = ApolloReactCommon.BaseMutationOptions<CbPostMutation, CbPostMutationVariables>;
export const GlAccountPeriodDocument = gql`
    query GLAccountPeriod($HotelID: String!, $AccountID: String!) {
  GLAccountPeriod(HotelID: $HotelID, AccountID: $AccountID)
}
    `;

/**
 * __useGlAccountPeriodQuery__
 *
 * To run a query within a React component, call `useGlAccountPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlAccountPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlAccountPeriodQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGlAccountPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlAccountPeriodQuery, GlAccountPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<GlAccountPeriodQuery, GlAccountPeriodQueryVariables>(GlAccountPeriodDocument, baseOptions);
      }
export function useGlAccountPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlAccountPeriodQuery, GlAccountPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlAccountPeriodQuery, GlAccountPeriodQueryVariables>(GlAccountPeriodDocument, baseOptions);
        }
export type GlAccountPeriodQueryHookResult = ReturnType<typeof useGlAccountPeriodQuery>;
export type GlAccountPeriodLazyQueryHookResult = ReturnType<typeof useGlAccountPeriodLazyQuery>;
export type GlAccountPeriodQueryResult = ApolloReactCommon.QueryResult<GlAccountPeriodQuery, GlAccountPeriodQueryVariables>;
export const HotelAccountXListDocument = gql`
    query HotelAccountXList($HotelID: String!) {
  HotelAccountXList(HotelID: $HotelID) {
    ID
    HotelID
    VendorName
    IsActive
  }
}
    `;

/**
 * __useHotelAccountXListQuery__
 *
 * To run a query within a React component, call `useHotelAccountXListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelAccountXListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelAccountXListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useHotelAccountXListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelAccountXListQuery, HotelAccountXListQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelAccountXListQuery, HotelAccountXListQueryVariables>(HotelAccountXListDocument, baseOptions);
      }
export function useHotelAccountXListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelAccountXListQuery, HotelAccountXListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelAccountXListQuery, HotelAccountXListQueryVariables>(HotelAccountXListDocument, baseOptions);
        }
export type HotelAccountXListQueryHookResult = ReturnType<typeof useHotelAccountXListQuery>;
export type HotelAccountXListLazyQueryHookResult = ReturnType<typeof useHotelAccountXListLazyQuery>;
export type HotelAccountXListQueryResult = ApolloReactCommon.QueryResult<HotelAccountXListQuery, HotelAccountXListQueryVariables>;
export const GuestProfileListingDocument = gql`
    query GuestProfileListing($HotelID: String!, $Country: String, $Nationality: String, $BirthMonth: String, $Age1: String, $Age2: String, $VisitNo1: String, $VisitNo2: String, $offset: Float, $limit: Float, $FullName: String, $RoomNo: String, $SearchValue: String, $SpecialRequest: String) {
  GuestProfileListing(HotelID: $HotelID, Country: $Country, Nationality: $Nationality, BirthMonth: $BirthMonth, Age1: $Age1, Age2: $Age2, VisitNo1: $VisitNo1, VisitNo2: $VisitNo2, offset: $offset, limit: $limit, FullName: $FullName, RoomNo: $RoomNo, SearchValue: $SearchValue, SpecialRequest: $SpecialRequest) {
    TotalGuestProfile
    GuestProfile {
      ID
      GuestType
      IsTTx
      TTxReasonID
      ICGallery {
        ID
        BucketFileName
        ImageURL
      }
      PassportGallery {
        ID
        BucketFileName
        ImageURL
      }
      Booking {
        ID
        BookingNo
        ArrivalDate
        DepartureDate
        BookingStatus
      }
      Contact {
        FullName
        NRIC
        PassportNo
        PhoneNo
        MobileNo
        AccountID
        Email
        BirthDate
        Nationality
        Designation
        Address {
          address
          postCode
          state
          city
          country
        }
      }
      GuestHistoryList {
        ArrivalDate
        DepartureDate
        CheckInDate
        CheckOutDate
        GuestID
        Signature
        RoomID
        Room {
          RoomNo
        }
        IsPrincipal
      }
      NewsLetter
      VehicleNo
      IdPicture
      LastYearOfVisit
      NoOfVisit
    }
  }
}
    `;

/**
 * __useGuestProfileListingQuery__
 *
 * To run a query within a React component, call `useGuestProfileListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestProfileListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      Country: // value for 'Country'
 *      Nationality: // value for 'Nationality'
 *      BirthMonth: // value for 'BirthMonth'
 *      Age1: // value for 'Age1'
 *      Age2: // value for 'Age2'
 *      VisitNo1: // value for 'VisitNo1'
 *      VisitNo2: // value for 'VisitNo2'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      FullName: // value for 'FullName'
 *      RoomNo: // value for 'RoomNo'
 *      SearchValue: // value for 'SearchValue'
 *      SpecialRequest: // value for 'SpecialRequest'
 *   },
 * });
 */
export function useGuestProfileListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestProfileListingQuery, GuestProfileListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestProfileListingQuery, GuestProfileListingQueryVariables>(GuestProfileListingDocument, baseOptions);
      }
export function useGuestProfileListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestProfileListingQuery, GuestProfileListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestProfileListingQuery, GuestProfileListingQueryVariables>(GuestProfileListingDocument, baseOptions);
        }
export type GuestProfileListingQueryHookResult = ReturnType<typeof useGuestProfileListingQuery>;
export type GuestProfileListingLazyQueryHookResult = ReturnType<typeof useGuestProfileListingLazyQuery>;
export type GuestProfileListingQueryResult = ApolloReactCommon.QueryResult<GuestProfileListingQuery, GuestProfileListingQueryVariables>;
export const GuestProfileListingV2Document = gql`
    query GuestProfileListingV2($HotelID: String!, $AccountID: String!, $Country: String, $Nationality: String, $BirthMonth: String, $Age1: String, $Age2: String, $VisitNo1: String, $VisitNo2: String, $offset: Float, $limit: Float, $FullName: String, $RoomNo: String, $SpecialRequest: String) {
  GuestProfileListingV2(HotelID: $HotelID, AccountID: $AccountID, Country: $Country, Nationality: $Nationality, BirthMonth: $BirthMonth, Age1: $Age1, Age2: $Age2, VisitNo1: $VisitNo1, VisitNo2: $VisitNo2, offset: $offset, limit: $limit, FullName: $FullName, SpecialRequest: $SpecialRequest, RoomNo: $RoomNo) {
    SpecialRequest
    DebtorID
    ID
    GuestType
    IsTTx
    TTxReasonID
    ICGallery {
      ID
      BucketFileName
      ImageURL
    }
    PassportGallery {
      ID
      BucketFileName
      ImageURL
    }
    Booking {
      ID
      BookingNo
      ArrivalDate
      DepartureDate
      BookingStatus
    }
    Contact {
      ID
      FullName
      NRIC
      PassportNo
      PhoneNo
      MobileNo
      AccountID
      Email
      BirthDate
      Nationality
      Designation
      Address {
        address
        postCode
        state
        city
        country
      }
    }
    GuestHistoryList {
      ArrivalDate
      DepartureDate
      CheckInDate
      CheckOutDate
      GuestID
      Signature
      RoomID
      Room {
        RoomNo
      }
      IsPrincipal
    }
    NewsLetter
    VehicleNo
    IdPicture
    LastYearOfVisit
    NoOfVisit
  }
}
    `;

/**
 * __useGuestProfileListingV2Query__
 *
 * To run a query within a React component, call `useGuestProfileListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestProfileListingV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      Country: // value for 'Country'
 *      Nationality: // value for 'Nationality'
 *      BirthMonth: // value for 'BirthMonth'
 *      Age1: // value for 'Age1'
 *      Age2: // value for 'Age2'
 *      VisitNo1: // value for 'VisitNo1'
 *      VisitNo2: // value for 'VisitNo2'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      FullName: // value for 'FullName'
 *      RoomNo: // value for 'RoomNo'
 *      SpecialRequest: // value for 'SpecialRequest'
 *   },
 * });
 */
export function useGuestProfileListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestProfileListingV2Query, GuestProfileListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<GuestProfileListingV2Query, GuestProfileListingV2QueryVariables>(GuestProfileListingV2Document, baseOptions);
      }
export function useGuestProfileListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestProfileListingV2Query, GuestProfileListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestProfileListingV2Query, GuestProfileListingV2QueryVariables>(GuestProfileListingV2Document, baseOptions);
        }
export type GuestProfileListingV2QueryHookResult = ReturnType<typeof useGuestProfileListingV2Query>;
export type GuestProfileListingV2LazyQueryHookResult = ReturnType<typeof useGuestProfileListingV2LazyQuery>;
export type GuestProfileListingV2QueryResult = ApolloReactCommon.QueryResult<GuestProfileListingV2Query, GuestProfileListingV2QueryVariables>;
export const GuestHistoryListingV2Document = gql`
    query GuestHistoryListingV2($GuestID: String!) {
  GuestHistoryListingV2(GuestID: $GuestID)
}
    `;

/**
 * __useGuestHistoryListingV2Query__
 *
 * To run a query within a React component, call `useGuestHistoryListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGuestHistoryListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestHistoryListingV2Query({
 *   variables: {
 *      GuestID: // value for 'GuestID'
 *   },
 * });
 */
export function useGuestHistoryListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestHistoryListingV2Query, GuestHistoryListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<GuestHistoryListingV2Query, GuestHistoryListingV2QueryVariables>(GuestHistoryListingV2Document, baseOptions);
      }
export function useGuestHistoryListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestHistoryListingV2Query, GuestHistoryListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestHistoryListingV2Query, GuestHistoryListingV2QueryVariables>(GuestHistoryListingV2Document, baseOptions);
        }
export type GuestHistoryListingV2QueryHookResult = ReturnType<typeof useGuestHistoryListingV2Query>;
export type GuestHistoryListingV2LazyQueryHookResult = ReturnType<typeof useGuestHistoryListingV2LazyQuery>;
export type GuestHistoryListingV2QueryResult = ApolloReactCommon.QueryResult<GuestHistoryListingV2Query, GuestHistoryListingV2QueryVariables>;
export const GuestHistoryListingDocument = gql`
    query GuestHistoryListing($GuestID: String!) {
  GuestHistoryListing(GuestID: $GuestID) {
    ID
    CheckInDate
    CheckOutDate
    ArrivalDate
    DepartureDate
    RoomRate
    DiscountAmount
    RegistrationStatus
    Guest {
      ID
      NewsLetter
      VehicleNo
      IdPicture
      GuestType
      IsTTx
      TTxReasonID
      Contact {
        Nationality
        PassportNo
        BirthDate
        FullName
        NRIC
        Email
        PhoneNo
        MobileNo
        Designation
        Address {
          address
          address2
          siteName
          country
          city
          state
          postCode
          AddressType
        }
      }
      ICGallery {
        ImageURL
        BucketFileName
      }
      ICBackGallery {
        ImageURL
        BucketFileName
      }
      GuestBookedRoomList {
        ID
        ArrivalDate
        DepartureDate
        GuestID
        IsPrincipal
        RegistrationStatus
      }
    }
    Booking {
      BookingNo
      DepartureDate
      ArrivalDate
      ID
      Contact {
        Nationality
        PassportNo
        BirthDate
        FullName
        NRIC
        Email
        PhoneNo
        MobileNo
        Address {
          address
          address2
          siteName
          country
          city
          state
          postCode
          AddressType
        }
      }
    }
    Room {
      RoomNo
      RoomType {
        Code
      }
    }
    RoomType {
      Code
      Description
    }
    BillLedger {
      Description
      Reason
      ReferenceNo
      TrxDate
      TrxAmount
    }
    RoomLedger {
      ReferenceNo
      BaseAmount
      TrxAmount
      TrxDate
      ServiceCharge
      TaxAmount
      TransactionType
      Transaction {
        Description
      }
    }
    TaxLedger {
      TaxType
      TaxDescription
      TaxAmount
    }
  }
}
    `;

/**
 * __useGuestHistoryListingQuery__
 *
 * To run a query within a React component, call `useGuestHistoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestHistoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestHistoryListingQuery({
 *   variables: {
 *      GuestID: // value for 'GuestID'
 *   },
 * });
 */
export function useGuestHistoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestHistoryListingQuery, GuestHistoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestHistoryListingQuery, GuestHistoryListingQueryVariables>(GuestHistoryListingDocument, baseOptions);
      }
export function useGuestHistoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestHistoryListingQuery, GuestHistoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestHistoryListingQuery, GuestHistoryListingQueryVariables>(GuestHistoryListingDocument, baseOptions);
        }
export type GuestHistoryListingQueryHookResult = ReturnType<typeof useGuestHistoryListingQuery>;
export type GuestHistoryListingLazyQueryHookResult = ReturnType<typeof useGuestHistoryListingLazyQuery>;
export type GuestHistoryListingQueryResult = ApolloReactCommon.QueryResult<GuestHistoryListingQuery, GuestHistoryListingQueryVariables>;
export const SelectedGuestListingDocument = gql`
    query SelectedGuestListing($GuestID: String!, $HotelID: String!) {
  SelectedGuestListing(GuestID: $GuestID, HotelID: $HotelID) {
    ID
    GuestType
    NewsLetter
    VehicleNo
    ContactID
    ICGallery {
      ImageURL
      BucketFileName
    }
    ICBackGallery {
      ImageURL
      BucketFileName
    }
    Contact {
      FullName
      MobileNo
      Email
      Nationality
      PassportNo
      NRIC
      BirthDate
      Designation
      Address {
        address
        postCode
        city
        state
        country
      }
    }
  }
}
    `;

/**
 * __useSelectedGuestListingQuery__
 *
 * To run a query within a React component, call `useSelectedGuestListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedGuestListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedGuestListingQuery({
 *   variables: {
 *      GuestID: // value for 'GuestID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useSelectedGuestListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectedGuestListingQuery, SelectedGuestListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectedGuestListingQuery, SelectedGuestListingQueryVariables>(SelectedGuestListingDocument, baseOptions);
      }
export function useSelectedGuestListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectedGuestListingQuery, SelectedGuestListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectedGuestListingQuery, SelectedGuestListingQueryVariables>(SelectedGuestListingDocument, baseOptions);
        }
export type SelectedGuestListingQueryHookResult = ReturnType<typeof useSelectedGuestListingQuery>;
export type SelectedGuestListingLazyQueryHookResult = ReturnType<typeof useSelectedGuestListingLazyQuery>;
export type SelectedGuestListingQueryResult = ApolloReactCommon.QueryResult<SelectedGuestListingQuery, SelectedGuestListingQueryVariables>;
export const GuestProfileHistoryListingDocument = gql`
    query GuestProfileHistoryListing($HotelID: String!) {
  GuestProfileHistoryListing(HotelID: $HotelID) {
    ID
    CheckInDate
    CheckOutDate
    ArrivalDate
    DepartureDate
    GuestID
    RoomType {
      Code
      Description
    }
  }
}
    `;

/**
 * __useGuestProfileHistoryListingQuery__
 *
 * To run a query within a React component, call `useGuestProfileHistoryListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestProfileHistoryListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestProfileHistoryListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useGuestProfileHistoryListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestProfileHistoryListingQuery, GuestProfileHistoryListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestProfileHistoryListingQuery, GuestProfileHistoryListingQueryVariables>(GuestProfileHistoryListingDocument, baseOptions);
      }
export function useGuestProfileHistoryListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestProfileHistoryListingQuery, GuestProfileHistoryListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestProfileHistoryListingQuery, GuestProfileHistoryListingQueryVariables>(GuestProfileHistoryListingDocument, baseOptions);
        }
export type GuestProfileHistoryListingQueryHookResult = ReturnType<typeof useGuestProfileHistoryListingQuery>;
export type GuestProfileHistoryListingLazyQueryHookResult = ReturnType<typeof useGuestProfileHistoryListingLazyQuery>;
export type GuestProfileHistoryListingQueryResult = ApolloReactCommon.QueryResult<GuestProfileHistoryListingQuery, GuestProfileHistoryListingQueryVariables>;
export const GuestUpdateDocument = gql`
    mutation GuestUpdate($ContactInput: ContactInput!, $GuestProfileInput: GuestProfileInput!, $AttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  GuestUpdate(ContactInput: $ContactInput, GuestProfileInput: $GuestProfileInput, AttachmentInput: $AttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type GuestUpdateMutationFn = ApolloReactCommon.MutationFunction<GuestUpdateMutation, GuestUpdateMutationVariables>;

/**
 * __useGuestUpdateMutation__
 *
 * To run a mutation, you first call `useGuestUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestUpdateMutation, { data, loading, error }] = useGuestUpdateMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      GuestProfileInput: // value for 'GuestProfileInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useGuestUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestUpdateMutation, GuestUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestUpdateMutation, GuestUpdateMutationVariables>(GuestUpdateDocument, baseOptions);
      }
export type GuestUpdateMutationHookResult = ReturnType<typeof useGuestUpdateMutation>;
export type GuestUpdateMutationResult = ApolloReactCommon.MutationResult<GuestUpdateMutation>;
export type GuestUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestUpdateMutation, GuestUpdateMutationVariables>;
export const HotelListingDocument = gql`
    query HotelListing($AccountID: String) {
  HotelListing(AccountID: $AccountID) {
    ID
    HotelName
    HotelAdjustmentIncidentalID
    CompanyRegNo
    Tax
    IsInclusive
    IsFloorPlan
    ServiceCharge
    AccountID
    NAProcessTime
    HotelAddress {
      address
      postCode
      state
      city
      country
    }
    Currency
    Encoders
    LogoImage {
      ID
      ImageURL
      BucketFileName
    }
    CompanyName
  }
}
    `;

/**
 * __useHotelListingQuery__
 *
 * To run a query within a React component, call `useHotelListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelListingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useHotelListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelListingQuery, HotelListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelListingQuery, HotelListingQueryVariables>(HotelListingDocument, baseOptions);
      }
export function useHotelListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelListingQuery, HotelListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelListingQuery, HotelListingQueryVariables>(HotelListingDocument, baseOptions);
        }
export type HotelListingQueryHookResult = ReturnType<typeof useHotelListingQuery>;
export type HotelListingLazyQueryHookResult = ReturnType<typeof useHotelListingLazyQuery>;
export type HotelListingQueryResult = ApolloReactCommon.QueryResult<HotelListingQuery, HotelListingQueryVariables>;
export const UserTypeDocument = gql`
    query UserType {
  UserType
}
    `;

/**
 * __useUserTypeQuery__
 *
 * To run a query within a React component, call `useUserTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserTypeQuery, UserTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<UserTypeQuery, UserTypeQueryVariables>(UserTypeDocument, baseOptions);
      }
export function useUserTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserTypeQuery, UserTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserTypeQuery, UserTypeQueryVariables>(UserTypeDocument, baseOptions);
        }
export type UserTypeQueryHookResult = ReturnType<typeof useUserTypeQuery>;
export type UserTypeLazyQueryHookResult = ReturnType<typeof useUserTypeLazyQuery>;
export type UserTypeQueryResult = ApolloReactCommon.QueryResult<UserTypeQuery, UserTypeQueryVariables>;
export const ServiceRequestFullListingDocument = gql`
    query ServiceRequestFullListing($HotelID: String!) {
  ServiceRequestFullListing(HotelID: $HotelID) {
    ID
    Title
    Description
    CreatedDT
    EstimatedCompletion
    RequestBy
    Status
    Remarks
    DepartmentID
    Room {
      ID
      RoomNo
      Location {
        ID
        Description
      }
    }
    ServiceRequestGallery {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
    Department {
      Name
    }
    CreatedBy
    ModifiedBy
  }
}
    `;

/**
 * __useServiceRequestFullListingQuery__
 *
 * To run a query within a React component, call `useServiceRequestFullListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceRequestFullListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceRequestFullListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useServiceRequestFullListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServiceRequestFullListingQuery, ServiceRequestFullListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ServiceRequestFullListingQuery, ServiceRequestFullListingQueryVariables>(ServiceRequestFullListingDocument, baseOptions);
      }
export function useServiceRequestFullListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServiceRequestFullListingQuery, ServiceRequestFullListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServiceRequestFullListingQuery, ServiceRequestFullListingQueryVariables>(ServiceRequestFullListingDocument, baseOptions);
        }
export type ServiceRequestFullListingQueryHookResult = ReturnType<typeof useServiceRequestFullListingQuery>;
export type ServiceRequestFullListingLazyQueryHookResult = ReturnType<typeof useServiceRequestFullListingLazyQuery>;
export type ServiceRequestFullListingQueryResult = ApolloReactCommon.QueryResult<ServiceRequestFullListingQuery, ServiceRequestFullListingQueryVariables>;
export const RoomServicesCountDocument = gql`
    query RoomServicesCount($HotelID: String!) {
  RoomServicesCount(HotelID: $HotelID)
}
    `;

/**
 * __useRoomServicesCountQuery__
 *
 * To run a query within a React component, call `useRoomServicesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomServicesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomServicesCountQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRoomServicesCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomServicesCountQuery, RoomServicesCountQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomServicesCountQuery, RoomServicesCountQueryVariables>(RoomServicesCountDocument, baseOptions);
      }
export function useRoomServicesCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomServicesCountQuery, RoomServicesCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomServicesCountQuery, RoomServicesCountQueryVariables>(RoomServicesCountDocument, baseOptions);
        }
export type RoomServicesCountQueryHookResult = ReturnType<typeof useRoomServicesCountQuery>;
export type RoomServicesCountLazyQueryHookResult = ReturnType<typeof useRoomServicesCountLazyQuery>;
export type RoomServicesCountQueryResult = ApolloReactCommon.QueryResult<RoomServicesCountQuery, RoomServicesCountQueryVariables>;
export const DashBoardAllListingDocument = gql`
    query DashBoardAllListing($HotelID: String!) {
  DashBoardAllListing(HotelID: $HotelID) {
    DueIn
    TotalDueIn
    TotalInHouseRoom
    DueOut
    TotalDueOut
    TotalGuest
    TotalRoom
    TotalUnAssignedRoomRegistration
    RevenueToday
    RevenueMonthToDay
    CollectionToday
    CollectionMonthToDay
    CurrentOccupiedRoom
    CurrentInHouse
    CurrentComplimentary
    CurrentDayUse
    CurrentOthers
    CurrentOccupancyRate
    CurrentArr
    CurrentRoomRevenue
    ProjectOccupiedRoom
    ProjectInHouse
    ProjectComplimentary
    ProjectDayUse
    ProjectOthers
    ProjectOccupancyRate
    ProjectArr
    ProjectRoomRevenue
  }
}
    `;

/**
 * __useDashBoardAllListingQuery__
 *
 * To run a query within a React component, call `useDashBoardAllListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashBoardAllListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashBoardAllListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDashBoardAllListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashBoardAllListingQuery, DashBoardAllListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DashBoardAllListingQuery, DashBoardAllListingQueryVariables>(DashBoardAllListingDocument, baseOptions);
      }
export function useDashBoardAllListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashBoardAllListingQuery, DashBoardAllListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DashBoardAllListingQuery, DashBoardAllListingQueryVariables>(DashBoardAllListingDocument, baseOptions);
        }
export type DashBoardAllListingQueryHookResult = ReturnType<typeof useDashBoardAllListingQuery>;
export type DashBoardAllListingLazyQueryHookResult = ReturnType<typeof useDashBoardAllListingLazyQuery>;
export type DashBoardAllListingQueryResult = ApolloReactCommon.QueryResult<DashBoardAllListingQuery, DashBoardAllListingQueryVariables>;
export const GetMenuOptionDocument = gql`
    query GetMenuOption {
  GetMenuOption {
    MenuOption
    ID
  }
}
    `;

/**
 * __useGetMenuOptionQuery__
 *
 * To run a query within a React component, call `useGetMenuOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuOptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMenuOptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMenuOptionQuery, GetMenuOptionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMenuOptionQuery, GetMenuOptionQueryVariables>(GetMenuOptionDocument, baseOptions);
      }
export function useGetMenuOptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMenuOptionQuery, GetMenuOptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMenuOptionQuery, GetMenuOptionQueryVariables>(GetMenuOptionDocument, baseOptions);
        }
export type GetMenuOptionQueryHookResult = ReturnType<typeof useGetMenuOptionQuery>;
export type GetMenuOptionLazyQueryHookResult = ReturnType<typeof useGetMenuOptionLazyQuery>;
export type GetMenuOptionQueryResult = ApolloReactCommon.QueryResult<GetMenuOptionQuery, GetMenuOptionQueryVariables>;
export const CheckNaProcessLogDocument = gql`
    query CheckNAProcessLog($HotelID: String!) {
  CheckNAProcessLog(HotelID: $HotelID)
}
    `;

/**
 * __useCheckNaProcessLogQuery__
 *
 * To run a query within a React component, call `useCheckNaProcessLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckNaProcessLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckNaProcessLogQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCheckNaProcessLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckNaProcessLogQuery, CheckNaProcessLogQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckNaProcessLogQuery, CheckNaProcessLogQueryVariables>(CheckNaProcessLogDocument, baseOptions);
      }
export function useCheckNaProcessLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckNaProcessLogQuery, CheckNaProcessLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckNaProcessLogQuery, CheckNaProcessLogQueryVariables>(CheckNaProcessLogDocument, baseOptions);
        }
export type CheckNaProcessLogQueryHookResult = ReturnType<typeof useCheckNaProcessLogQuery>;
export type CheckNaProcessLogLazyQueryHookResult = ReturnType<typeof useCheckNaProcessLogLazyQuery>;
export type CheckNaProcessLogQueryResult = ApolloReactCommon.QueryResult<CheckNaProcessLogQuery, CheckNaProcessLogQueryVariables>;
export const HousekeepingListingDocument = gql`
    query HousekeepingListing($HotelID: String!, $LocationID: String, $IsActive: Boolean) {
  HousekeepingListing(HotelID: $HotelID, LocationID: $LocationID, IsActive: $IsActive) {
    ID
    RoomNo
    RoomStatusCode
    RoomStatus {
      CleanOrDirty
      Code
      Description
      RGBColor
      ID
    }
    BlockRoomLog {
      ID
      BlockDate
      ReleaseDate
      Reason
      Remarks
      CreatedDT
      ModifiedDT
      IsRelease
      GalleryID
      ReleaseRoomStatus
      BlockRoomLogGallery {
        ID
        GalleryID
        BlockRoomLogID
        Gallery {
          ID
          BucketFileName
          ImageURL
        }
      }
      BlockStatus {
        ID
        Code
      }
      Gallery {
        ID
      }
    }
    HouseKeepingLog {
      ID
      Status
      CleanedBy
      HotelDate
    }
    RoomType {
      ID
      Code
    }
    Registrations {
      ID
      CheckInDate
      CheckOutDate
      RegistrationStatus
      IsPrincipal
      Booking {
        ID
        DepartureDate
        ArrivalDate
      }
      Guest {
        ID
        Contact {
          FullName
        }
      }
    }
  }
}
    `;

/**
 * __useHousekeepingListingQuery__
 *
 * To run a query within a React component, call `useHousekeepingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHousekeepingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHousekeepingListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      LocationID: // value for 'LocationID'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useHousekeepingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HousekeepingListingQuery, HousekeepingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HousekeepingListingQuery, HousekeepingListingQueryVariables>(HousekeepingListingDocument, baseOptions);
      }
export function useHousekeepingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HousekeepingListingQuery, HousekeepingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HousekeepingListingQuery, HousekeepingListingQueryVariables>(HousekeepingListingDocument, baseOptions);
        }
export type HousekeepingListingQueryHookResult = ReturnType<typeof useHousekeepingListingQuery>;
export type HousekeepingListingLazyQueryHookResult = ReturnType<typeof useHousekeepingListingLazyQuery>;
export type HousekeepingListingQueryResult = ApolloReactCommon.QueryResult<HousekeepingListingQuery, HousekeepingListingQueryVariables>;
export const SvcReqRoomListingDocument = gql`
    query SvcReqRoomListing($HotelID: String!, $LocationID: String, $IsActive: Boolean) {
  HousekeepingListing(HotelID: $HotelID, LocationID: $LocationID, IsActive: $IsActive) {
    ID
    RoomNo
    RoomType {
      ID
      Code
    }
    Registrations {
      ID
      CheckInDate
      CheckOutDate
      RegistrationStatus
      IsPrincipal
      Booking {
        ID
        DepartureDate
        ArrivalDate
      }
      Guest {
        ID
        Contact {
          FullName
        }
      }
    }
  }
}
    `;

/**
 * __useSvcReqRoomListingQuery__
 *
 * To run a query within a React component, call `useSvcReqRoomListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSvcReqRoomListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSvcReqRoomListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      LocationID: // value for 'LocationID'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useSvcReqRoomListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SvcReqRoomListingQuery, SvcReqRoomListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SvcReqRoomListingQuery, SvcReqRoomListingQueryVariables>(SvcReqRoomListingDocument, baseOptions);
      }
export function useSvcReqRoomListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SvcReqRoomListingQuery, SvcReqRoomListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SvcReqRoomListingQuery, SvcReqRoomListingQueryVariables>(SvcReqRoomListingDocument, baseOptions);
        }
export type SvcReqRoomListingQueryHookResult = ReturnType<typeof useSvcReqRoomListingQuery>;
export type SvcReqRoomListingLazyQueryHookResult = ReturnType<typeof useSvcReqRoomListingLazyQuery>;
export type SvcReqRoomListingQueryResult = ApolloReactCommon.QueryResult<SvcReqRoomListingQuery, SvcReqRoomListingQueryVariables>;
export const HkMasterListingDocument = gql`
    query HKMasterListing($HotelID: String!, $IsActive: Boolean, $LocationID: String, $RoomStatusID: String, $IsBlocked: Boolean) {
  HKMasterListing(HotelID: $HotelID, IsActive: $IsActive, LocationID: $LocationID, RoomStatusID: $RoomStatusID, IsBlocked: $IsBlocked)
}
    `;

/**
 * __useHkMasterListingQuery__
 *
 * To run a query within a React component, call `useHkMasterListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHkMasterListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHkMasterListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      IsActive: // value for 'IsActive'
 *      LocationID: // value for 'LocationID'
 *      RoomStatusID: // value for 'RoomStatusID'
 *      IsBlocked: // value for 'IsBlocked'
 *   },
 * });
 */
export function useHkMasterListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HkMasterListingQuery, HkMasterListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HkMasterListingQuery, HkMasterListingQueryVariables>(HkMasterListingDocument, baseOptions);
      }
export function useHkMasterListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HkMasterListingQuery, HkMasterListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HkMasterListingQuery, HkMasterListingQueryVariables>(HkMasterListingDocument, baseOptions);
        }
export type HkMasterListingQueryHookResult = ReturnType<typeof useHkMasterListingQuery>;
export type HkMasterListingLazyQueryHookResult = ReturnType<typeof useHkMasterListingLazyQuery>;
export type HkMasterListingQueryResult = ApolloReactCommon.QueryResult<HkMasterListingQuery, HkMasterListingQueryVariables>;
export const HkLocationMasterListingDocument = gql`
    query HKLocationMasterListing($HotelID: String!, $IsActive: Boolean) {
  HKLocationMasterListing(HotelID: $HotelID, IsActive: $IsActive)
}
    `;

/**
 * __useHkLocationMasterListingQuery__
 *
 * To run a query within a React component, call `useHkLocationMasterListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHkLocationMasterListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHkLocationMasterListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useHkLocationMasterListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HkLocationMasterListingQuery, HkLocationMasterListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HkLocationMasterListingQuery, HkLocationMasterListingQueryVariables>(HkLocationMasterListingDocument, baseOptions);
      }
export function useHkLocationMasterListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HkLocationMasterListingQuery, HkLocationMasterListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HkLocationMasterListingQuery, HkLocationMasterListingQueryVariables>(HkLocationMasterListingDocument, baseOptions);
        }
export type HkLocationMasterListingQueryHookResult = ReturnType<typeof useHkLocationMasterListingQuery>;
export type HkLocationMasterListingLazyQueryHookResult = ReturnType<typeof useHkLocationMasterListingLazyQuery>;
export type HkLocationMasterListingQueryResult = ApolloReactCommon.QueryResult<HkLocationMasterListingQuery, HkLocationMasterListingQueryVariables>;
export const HousekeepingDetailDocument = gql`
    query HousekeepingDetail($HotelID: String!, $ID: String!) {
  HousekeepingDetail(HotelID: $HotelID, ID: $ID) {
    ID
    RoomNo
    RoomStatus {
      ID
      CleanOrDirty
      Code
      Description
      RGBColor
    }
    BlockRoomLog {
      ID
      BlockDate
      ReleaseDate
      Reason
      Remarks
      CreatedDT
      ModifiedDT
      IsRelease
      GalleryID
      ReleaseRoomStatus
      BlockRoomLogGallery {
        ID
        GalleryID
        BlockRoomLogID
        Gallery {
          ID
          BucketFileName
          ImageURL
        }
      }
      BlockStatus {
        ID
        Code
      }
      Gallery {
        ID
        ImageURL
      }
    }
    HouseKeepingLog {
      ID
      Status
      CleanedBy
      HotelDate
    }
    RoomType {
      ID
      Code
    }
    Registrations {
      ID
      CheckInDate
      CheckOutDate
      RegistrationStatus
      IsPrincipal
      Booking {
        ID
        DepartureDate
        ArrivalDate
      }
      Guest {
        ID
        Contact {
          FullName
        }
      }
    }
  }
}
    `;

/**
 * __useHousekeepingDetailQuery__
 *
 * To run a query within a React component, call `useHousekeepingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useHousekeepingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHousekeepingDetailQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useHousekeepingDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HousekeepingDetailQuery, HousekeepingDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<HousekeepingDetailQuery, HousekeepingDetailQueryVariables>(HousekeepingDetailDocument, baseOptions);
      }
export function useHousekeepingDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HousekeepingDetailQuery, HousekeepingDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HousekeepingDetailQuery, HousekeepingDetailQueryVariables>(HousekeepingDetailDocument, baseOptions);
        }
export type HousekeepingDetailQueryHookResult = ReturnType<typeof useHousekeepingDetailQuery>;
export type HousekeepingDetailLazyQueryHookResult = ReturnType<typeof useHousekeepingDetailLazyQuery>;
export type HousekeepingDetailQueryResult = ApolloReactCommon.QueryResult<HousekeepingDetailQuery, HousekeepingDetailQueryVariables>;
export const BlockRoomLogInsertDocument = gql`
    mutation BlockRoomLogInsert($BlockRoomLogInput: BlockRoomLogInput!, $AttachmentInput: [Upload!]) {
  BlockRoomLogInsert(BlockRoomLogInput: $BlockRoomLogInput, AttachmentInput: $AttachmentInput) {
    ID
    HotelID
    RoomID
    Reason
    BlockDate
    ReleaseDate
    BlockStatusID
    Remarks
  }
}
    `;
export type BlockRoomLogInsertMutationFn = ApolloReactCommon.MutationFunction<BlockRoomLogInsertMutation, BlockRoomLogInsertMutationVariables>;

/**
 * __useBlockRoomLogInsertMutation__
 *
 * To run a mutation, you first call `useBlockRoomLogInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockRoomLogInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockRoomLogInsertMutation, { data, loading, error }] = useBlockRoomLogInsertMutation({
 *   variables: {
 *      BlockRoomLogInput: // value for 'BlockRoomLogInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useBlockRoomLogInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockRoomLogInsertMutation, BlockRoomLogInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockRoomLogInsertMutation, BlockRoomLogInsertMutationVariables>(BlockRoomLogInsertDocument, baseOptions);
      }
export type BlockRoomLogInsertMutationHookResult = ReturnType<typeof useBlockRoomLogInsertMutation>;
export type BlockRoomLogInsertMutationResult = ApolloReactCommon.MutationResult<BlockRoomLogInsertMutation>;
export type BlockRoomLogInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockRoomLogInsertMutation, BlockRoomLogInsertMutationVariables>;
export const BlockRoomLogInsertV2Document = gql`
    mutation BlockRoomLogInsertV2($BlockRoomLogInput: [BlockRoomLogInput!]!, $AttachmentInput: [Upload!]) {
  BlockRoomLogInsertV2(BlockRoomLogInput: $BlockRoomLogInput, AttachmentInput: $AttachmentInput) {
    ID
    HotelID
    RoomID
    Reason
    BlockDate
    ReleaseDate
    BlockStatusID
    Remarks
  }
}
    `;
export type BlockRoomLogInsertV2MutationFn = ApolloReactCommon.MutationFunction<BlockRoomLogInsertV2Mutation, BlockRoomLogInsertV2MutationVariables>;

/**
 * __useBlockRoomLogInsertV2Mutation__
 *
 * To run a mutation, you first call `useBlockRoomLogInsertV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockRoomLogInsertV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockRoomLogInsertV2Mutation, { data, loading, error }] = useBlockRoomLogInsertV2Mutation({
 *   variables: {
 *      BlockRoomLogInput: // value for 'BlockRoomLogInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useBlockRoomLogInsertV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockRoomLogInsertV2Mutation, BlockRoomLogInsertV2MutationVariables>) {
        return ApolloReactHooks.useMutation<BlockRoomLogInsertV2Mutation, BlockRoomLogInsertV2MutationVariables>(BlockRoomLogInsertV2Document, baseOptions);
      }
export type BlockRoomLogInsertV2MutationHookResult = ReturnType<typeof useBlockRoomLogInsertV2Mutation>;
export type BlockRoomLogInsertV2MutationResult = ApolloReactCommon.MutationResult<BlockRoomLogInsertV2Mutation>;
export type BlockRoomLogInsertV2MutationOptions = ApolloReactCommon.BaseMutationOptions<BlockRoomLogInsertV2Mutation, BlockRoomLogInsertV2MutationVariables>;
export const IsBlockRoomAvailableDocument = gql`
    query IsBlockRoomAvailable($HotelID: String!, $RoomID: [String!]!, $StartDate: DateTime!, $EndDate: DateTime!, $BlockRoomID: String) {
  IsBlockRoomAvailable(HotelID: $HotelID, RoomID: $RoomID, StartDate: $StartDate, EndDate: $EndDate, BlockRoomID: $BlockRoomID)
}
    `;

/**
 * __useIsBlockRoomAvailableQuery__
 *
 * To run a query within a React component, call `useIsBlockRoomAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsBlockRoomAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsBlockRoomAvailableQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomID: // value for 'RoomID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      BlockRoomID: // value for 'BlockRoomID'
 *   },
 * });
 */
export function useIsBlockRoomAvailableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsBlockRoomAvailableQuery, IsBlockRoomAvailableQueryVariables>) {
        return ApolloReactHooks.useQuery<IsBlockRoomAvailableQuery, IsBlockRoomAvailableQueryVariables>(IsBlockRoomAvailableDocument, baseOptions);
      }
export function useIsBlockRoomAvailableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsBlockRoomAvailableQuery, IsBlockRoomAvailableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsBlockRoomAvailableQuery, IsBlockRoomAvailableQueryVariables>(IsBlockRoomAvailableDocument, baseOptions);
        }
export type IsBlockRoomAvailableQueryHookResult = ReturnType<typeof useIsBlockRoomAvailableQuery>;
export type IsBlockRoomAvailableLazyQueryHookResult = ReturnType<typeof useIsBlockRoomAvailableLazyQuery>;
export type IsBlockRoomAvailableQueryResult = ApolloReactCommon.QueryResult<IsBlockRoomAvailableQuery, IsBlockRoomAvailableQueryVariables>;
export const HouseKeepingLogInsertDocument = gql`
    mutation HouseKeepingLogInsert($HouseKeepingLogInput: HouseKeepingLogInput!, $RoomIDs: [String!]) {
  HouseKeepingLogInsert(HouseKeepingLogInput: $HouseKeepingLogInput, RoomIDs: $RoomIDs)
}
    `;
export type HouseKeepingLogInsertMutationFn = ApolloReactCommon.MutationFunction<HouseKeepingLogInsertMutation, HouseKeepingLogInsertMutationVariables>;

/**
 * __useHouseKeepingLogInsertMutation__
 *
 * To run a mutation, you first call `useHouseKeepingLogInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHouseKeepingLogInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [houseKeepingLogInsertMutation, { data, loading, error }] = useHouseKeepingLogInsertMutation({
 *   variables: {
 *      HouseKeepingLogInput: // value for 'HouseKeepingLogInput'
 *      RoomIDs: // value for 'RoomIDs'
 *   },
 * });
 */
export function useHouseKeepingLogInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HouseKeepingLogInsertMutation, HouseKeepingLogInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<HouseKeepingLogInsertMutation, HouseKeepingLogInsertMutationVariables>(HouseKeepingLogInsertDocument, baseOptions);
      }
export type HouseKeepingLogInsertMutationHookResult = ReturnType<typeof useHouseKeepingLogInsertMutation>;
export type HouseKeepingLogInsertMutationResult = ApolloReactCommon.MutationResult<HouseKeepingLogInsertMutation>;
export type HouseKeepingLogInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<HouseKeepingLogInsertMutation, HouseKeepingLogInsertMutationVariables>;
export const ReleaseRoomLogUpdateV2Document = gql`
    mutation ReleaseRoomLogUpdateV2($BlockRoomLogInput: BlockRoomLogInput!, $AttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  ReleaseRoomLogUpdateV2(BlockRoomLogInput: $BlockRoomLogInput, AttachmentInput: $AttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type ReleaseRoomLogUpdateV2MutationFn = ApolloReactCommon.MutationFunction<ReleaseRoomLogUpdateV2Mutation, ReleaseRoomLogUpdateV2MutationVariables>;

/**
 * __useReleaseRoomLogUpdateV2Mutation__
 *
 * To run a mutation, you first call `useReleaseRoomLogUpdateV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReleaseRoomLogUpdateV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [releaseRoomLogUpdateV2Mutation, { data, loading, error }] = useReleaseRoomLogUpdateV2Mutation({
 *   variables: {
 *      BlockRoomLogInput: // value for 'BlockRoomLogInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useReleaseRoomLogUpdateV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReleaseRoomLogUpdateV2Mutation, ReleaseRoomLogUpdateV2MutationVariables>) {
        return ApolloReactHooks.useMutation<ReleaseRoomLogUpdateV2Mutation, ReleaseRoomLogUpdateV2MutationVariables>(ReleaseRoomLogUpdateV2Document, baseOptions);
      }
export type ReleaseRoomLogUpdateV2MutationHookResult = ReturnType<typeof useReleaseRoomLogUpdateV2Mutation>;
export type ReleaseRoomLogUpdateV2MutationResult = ApolloReactCommon.MutationResult<ReleaseRoomLogUpdateV2Mutation>;
export type ReleaseRoomLogUpdateV2MutationOptions = ApolloReactCommon.BaseMutationOptions<ReleaseRoomLogUpdateV2Mutation, ReleaseRoomLogUpdateV2MutationVariables>;
export const BlockRoomLogUpdateDocument = gql`
    mutation BlockRoomLogUpdate($BlockRoomLogInput: BlockRoomLogInput!, $AttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  BlockRoomLogUpdate(BlockRoomLogInput: $BlockRoomLogInput, AttachmentInput: $AttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type BlockRoomLogUpdateMutationFn = ApolloReactCommon.MutationFunction<BlockRoomLogUpdateMutation, BlockRoomLogUpdateMutationVariables>;

/**
 * __useBlockRoomLogUpdateMutation__
 *
 * To run a mutation, you first call `useBlockRoomLogUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockRoomLogUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockRoomLogUpdateMutation, { data, loading, error }] = useBlockRoomLogUpdateMutation({
 *   variables: {
 *      BlockRoomLogInput: // value for 'BlockRoomLogInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useBlockRoomLogUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BlockRoomLogUpdateMutation, BlockRoomLogUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BlockRoomLogUpdateMutation, BlockRoomLogUpdateMutationVariables>(BlockRoomLogUpdateDocument, baseOptions);
      }
export type BlockRoomLogUpdateMutationHookResult = ReturnType<typeof useBlockRoomLogUpdateMutation>;
export type BlockRoomLogUpdateMutationResult = ApolloReactCommon.MutationResult<BlockRoomLogUpdateMutation>;
export type BlockRoomLogUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BlockRoomLogUpdateMutation, BlockRoomLogUpdateMutationVariables>;
export const HousekeepingServiceRequestListingDocument = gql`
    query HousekeepingServiceRequestListing($HotelID: String!, $RoomID: String) {
  HousekeepingServiceRequestListing(HotelID: $HotelID, RoomID: $RoomID) {
    ID
    Title
    Description
    EstimatedCompletion
    RequestBy
    Status
    Remarks
    DepartmentID
    ClosedDate
    Registration {
      ID
      IsPrincipal
      Guest {
        ID
        Contact {
          FullName
        }
      }
    }
    ServiceRequestGallery {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
    Department {
      Name
    }
    CreatedBy
    ModifiedBy
    CreatedDT
    RoomID
    Room {
      ID
      RoomNo
      Registrations {
        ID
        BookingID
        Guest {
          ID
          Contact {
            ID
            FullName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useHousekeepingServiceRequestListingQuery__
 *
 * To run a query within a React component, call `useHousekeepingServiceRequestListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHousekeepingServiceRequestListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHousekeepingServiceRequestListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomID: // value for 'RoomID'
 *   },
 * });
 */
export function useHousekeepingServiceRequestListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HousekeepingServiceRequestListingQuery, HousekeepingServiceRequestListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HousekeepingServiceRequestListingQuery, HousekeepingServiceRequestListingQueryVariables>(HousekeepingServiceRequestListingDocument, baseOptions);
      }
export function useHousekeepingServiceRequestListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HousekeepingServiceRequestListingQuery, HousekeepingServiceRequestListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HousekeepingServiceRequestListingQuery, HousekeepingServiceRequestListingQueryVariables>(HousekeepingServiceRequestListingDocument, baseOptions);
        }
export type HousekeepingServiceRequestListingQueryHookResult = ReturnType<typeof useHousekeepingServiceRequestListingQuery>;
export type HousekeepingServiceRequestListingLazyQueryHookResult = ReturnType<typeof useHousekeepingServiceRequestListingLazyQuery>;
export type HousekeepingServiceRequestListingQueryResult = ApolloReactCommon.QueryResult<HousekeepingServiceRequestListingQuery, HousekeepingServiceRequestListingQueryVariables>;
export const HousekeepingServiceRequestUpdateDocument = gql`
    mutation HousekeepingServiceRequestUpdate($ServiceRequestInput: ServiceRequestInput!, $AttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  HousekeepingServiceRequestUpdate(ServiceRequestInput: $ServiceRequestInput, AttachmentInput: $AttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type HousekeepingServiceRequestUpdateMutationFn = ApolloReactCommon.MutationFunction<HousekeepingServiceRequestUpdateMutation, HousekeepingServiceRequestUpdateMutationVariables>;

/**
 * __useHousekeepingServiceRequestUpdateMutation__
 *
 * To run a mutation, you first call `useHousekeepingServiceRequestUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHousekeepingServiceRequestUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [housekeepingServiceRequestUpdateMutation, { data, loading, error }] = useHousekeepingServiceRequestUpdateMutation({
 *   variables: {
 *      ServiceRequestInput: // value for 'ServiceRequestInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useHousekeepingServiceRequestUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HousekeepingServiceRequestUpdateMutation, HousekeepingServiceRequestUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<HousekeepingServiceRequestUpdateMutation, HousekeepingServiceRequestUpdateMutationVariables>(HousekeepingServiceRequestUpdateDocument, baseOptions);
      }
export type HousekeepingServiceRequestUpdateMutationHookResult = ReturnType<typeof useHousekeepingServiceRequestUpdateMutation>;
export type HousekeepingServiceRequestUpdateMutationResult = ApolloReactCommon.MutationResult<HousekeepingServiceRequestUpdateMutation>;
export type HousekeepingServiceRequestUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<HousekeepingServiceRequestUpdateMutation, HousekeepingServiceRequestUpdateMutationVariables>;
export const HousekeepingServiceRequestInsertDocument = gql`
    mutation HousekeepingServiceRequestInsert($ServiceRequestInput: ServiceRequestInput!, $AttachmentInput: [Upload!]) {
  HousekeepingServiceRequestInsert(ServiceRequestInput: $ServiceRequestInput, AttachmentInput: $AttachmentInput) {
    ID
  }
}
    `;
export type HousekeepingServiceRequestInsertMutationFn = ApolloReactCommon.MutationFunction<HousekeepingServiceRequestInsertMutation, HousekeepingServiceRequestInsertMutationVariables>;

/**
 * __useHousekeepingServiceRequestInsertMutation__
 *
 * To run a mutation, you first call `useHousekeepingServiceRequestInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHousekeepingServiceRequestInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [housekeepingServiceRequestInsertMutation, { data, loading, error }] = useHousekeepingServiceRequestInsertMutation({
 *   variables: {
 *      ServiceRequestInput: // value for 'ServiceRequestInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useHousekeepingServiceRequestInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HousekeepingServiceRequestInsertMutation, HousekeepingServiceRequestInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<HousekeepingServiceRequestInsertMutation, HousekeepingServiceRequestInsertMutationVariables>(HousekeepingServiceRequestInsertDocument, baseOptions);
      }
export type HousekeepingServiceRequestInsertMutationHookResult = ReturnType<typeof useHousekeepingServiceRequestInsertMutation>;
export type HousekeepingServiceRequestInsertMutationResult = ApolloReactCommon.MutationResult<HousekeepingServiceRequestInsertMutation>;
export type HousekeepingServiceRequestInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<HousekeepingServiceRequestInsertMutation, HousekeepingServiceRequestInsertMutationVariables>;
export const BillLedgerListingDocument = gql`
    query BillLedgerListing($HotelID: String!, $RegistrationID: String) {
  BillLedgerListing(HotelID: $HotelID, RegistrationID: $RegistrationID) {
    ID
    TrxDate
    Qty
    UnitPrice
    TrxAmount
    Reason
    RegistrationID
    BaseAmount
    TaxAmount
    ServiceCharge
    DiscountAmount
    ReferenceNo
    Description
    ReversedBillLedgerID
    IncidentalCharge {
      ID
      Description
    }
    CreatedBy
    ModifiedBy
    CreatedDT
    ModifiedDT
    Registration {
      Room {
        ID
        RoomNo
        RoomType {
          Code
        }
      }
      CheckInDate
      CheckOutDate
      IsPrincipal
      Guest {
        Contact {
          FullName
        }
      }
    }
    TaxBillLedger {
      ID
      TaxID
      TaxRate
      TaxAmount
      BillLedgerID
      TaxSchemeID
      TaxInfo {
        code
      }
    }
  }
}
    `;

/**
 * __useBillLedgerListingQuery__
 *
 * To run a query within a React component, call `useBillLedgerListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillLedgerListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillLedgerListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useBillLedgerListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BillLedgerListingQuery, BillLedgerListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BillLedgerListingQuery, BillLedgerListingQueryVariables>(BillLedgerListingDocument, baseOptions);
      }
export function useBillLedgerListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BillLedgerListingQuery, BillLedgerListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BillLedgerListingQuery, BillLedgerListingQueryVariables>(BillLedgerListingDocument, baseOptions);
        }
export type BillLedgerListingQueryHookResult = ReturnType<typeof useBillLedgerListingQuery>;
export type BillLedgerListingLazyQueryHookResult = ReturnType<typeof useBillLedgerListingLazyQuery>;
export type BillLedgerListingQueryResult = ApolloReactCommon.QueryResult<BillLedgerListingQuery, BillLedgerListingQueryVariables>;
export const HousekeepingIncidentalChargeListingDocument = gql`
    query HousekeepingIncidentalChargeListing($DepartmentID: String) {
  HousekeepingIncidentalChargeListing(DepartmentID: $DepartmentID) {
    ID
    IsActive
    DepartmentID
    Description
    Amount
    Tax
    ServiceCharge
    IsRoomRevenue
    GalleryID
  }
}
    `;

/**
 * __useHousekeepingIncidentalChargeListingQuery__
 *
 * To run a query within a React component, call `useHousekeepingIncidentalChargeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHousekeepingIncidentalChargeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHousekeepingIncidentalChargeListingQuery({
 *   variables: {
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useHousekeepingIncidentalChargeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HousekeepingIncidentalChargeListingQuery, HousekeepingIncidentalChargeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HousekeepingIncidentalChargeListingQuery, HousekeepingIncidentalChargeListingQueryVariables>(HousekeepingIncidentalChargeListingDocument, baseOptions);
      }
export function useHousekeepingIncidentalChargeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HousekeepingIncidentalChargeListingQuery, HousekeepingIncidentalChargeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HousekeepingIncidentalChargeListingQuery, HousekeepingIncidentalChargeListingQueryVariables>(HousekeepingIncidentalChargeListingDocument, baseOptions);
        }
export type HousekeepingIncidentalChargeListingQueryHookResult = ReturnType<typeof useHousekeepingIncidentalChargeListingQuery>;
export type HousekeepingIncidentalChargeListingLazyQueryHookResult = ReturnType<typeof useHousekeepingIncidentalChargeListingLazyQuery>;
export type HousekeepingIncidentalChargeListingQueryResult = ApolloReactCommon.QueryResult<HousekeepingIncidentalChargeListingQuery, HousekeepingIncidentalChargeListingQueryVariables>;
export const HousekeepingBillLedgerUpdateDocument = gql`
    mutation HousekeepingBillLedgerUpdate($BillLedgerInput: BillLedgerInput!) {
  HousekeepingBillLedgerUpdate(BillLedgerInput: $BillLedgerInput)
}
    `;
export type HousekeepingBillLedgerUpdateMutationFn = ApolloReactCommon.MutationFunction<HousekeepingBillLedgerUpdateMutation, HousekeepingBillLedgerUpdateMutationVariables>;

/**
 * __useHousekeepingBillLedgerUpdateMutation__
 *
 * To run a mutation, you first call `useHousekeepingBillLedgerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHousekeepingBillLedgerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [housekeepingBillLedgerUpdateMutation, { data, loading, error }] = useHousekeepingBillLedgerUpdateMutation({
 *   variables: {
 *      BillLedgerInput: // value for 'BillLedgerInput'
 *   },
 * });
 */
export function useHousekeepingBillLedgerUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HousekeepingBillLedgerUpdateMutation, HousekeepingBillLedgerUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<HousekeepingBillLedgerUpdateMutation, HousekeepingBillLedgerUpdateMutationVariables>(HousekeepingBillLedgerUpdateDocument, baseOptions);
      }
export type HousekeepingBillLedgerUpdateMutationHookResult = ReturnType<typeof useHousekeepingBillLedgerUpdateMutation>;
export type HousekeepingBillLedgerUpdateMutationResult = ApolloReactCommon.MutationResult<HousekeepingBillLedgerUpdateMutation>;
export type HousekeepingBillLedgerUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<HousekeepingBillLedgerUpdateMutation, HousekeepingBillLedgerUpdateMutationVariables>;
export const TodayBookingListingDocument = gql`
    query TodayBookingListing($HotelID: String!) {
  TodayBookingListing(HotelID: $HotelID) {
    ID
    ArrivalDate
    Registration {
      RoomID
    }
  }
}
    `;

/**
 * __useTodayBookingListingQuery__
 *
 * To run a query within a React component, call `useTodayBookingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayBookingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayBookingListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useTodayBookingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TodayBookingListingQuery, TodayBookingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TodayBookingListingQuery, TodayBookingListingQueryVariables>(TodayBookingListingDocument, baseOptions);
      }
export function useTodayBookingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TodayBookingListingQuery, TodayBookingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TodayBookingListingQuery, TodayBookingListingQueryVariables>(TodayBookingListingDocument, baseOptions);
        }
export type TodayBookingListingQueryHookResult = ReturnType<typeof useTodayBookingListingQuery>;
export type TodayBookingListingLazyQueryHookResult = ReturnType<typeof useTodayBookingListingLazyQuery>;
export type TodayBookingListingQueryResult = ApolloReactCommon.QueryResult<TodayBookingListingQuery, TodayBookingListingQueryVariables>;
export const HousekeepingRoomStatusListingDocument = gql`
    query HousekeepingRoomStatusListing($HotelID: String!) {
  HousekeepingRoomStatusListing(HotelID: $HotelID) {
    ID
    Code
    Description
    RGBColor
  }
}
    `;

/**
 * __useHousekeepingRoomStatusListingQuery__
 *
 * To run a query within a React component, call `useHousekeepingRoomStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useHousekeepingRoomStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHousekeepingRoomStatusListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useHousekeepingRoomStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HousekeepingRoomStatusListingQuery, HousekeepingRoomStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<HousekeepingRoomStatusListingQuery, HousekeepingRoomStatusListingQueryVariables>(HousekeepingRoomStatusListingDocument, baseOptions);
      }
export function useHousekeepingRoomStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HousekeepingRoomStatusListingQuery, HousekeepingRoomStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HousekeepingRoomStatusListingQuery, HousekeepingRoomStatusListingQueryVariables>(HousekeepingRoomStatusListingDocument, baseOptions);
        }
export type HousekeepingRoomStatusListingQueryHookResult = ReturnType<typeof useHousekeepingRoomStatusListingQuery>;
export type HousekeepingRoomStatusListingLazyQueryHookResult = ReturnType<typeof useHousekeepingRoomStatusListingLazyQuery>;
export type HousekeepingRoomStatusListingQueryResult = ApolloReactCommon.QueryResult<HousekeepingRoomStatusListingQuery, HousekeepingRoomStatusListingQueryVariables>;
export const BlockRoomLogListingV2Document = gql`
    query BlockRoomLogListingV2($HotelID: String!) {
  BlockRoomLogListingV2(HotelID: $HotelID) {
    ID
    CreatedDT
    CreatedBy
    ModifiedBy
    ModifiedDT
    HotelID
    RoomID
    BlockDate
    ReleaseDate
    BlockStatusID
    Reason
    Remarks
    IsRelease
    ReleaseDT
    BlockStatus {
      Code
    }
    Room {
      ID
      RoomNo
    }
    BlockRoomLogGallery {
      ID
      Gallery {
        ID
        ImageURL
      }
    }
  }
}
    `;

/**
 * __useBlockRoomLogListingV2Query__
 *
 * To run a query within a React component, call `useBlockRoomLogListingV2Query` and pass it any options that fit your needs.
 * When your component renders, `useBlockRoomLogListingV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockRoomLogListingV2Query({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useBlockRoomLogListingV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<BlockRoomLogListingV2Query, BlockRoomLogListingV2QueryVariables>) {
        return ApolloReactHooks.useQuery<BlockRoomLogListingV2Query, BlockRoomLogListingV2QueryVariables>(BlockRoomLogListingV2Document, baseOptions);
      }
export function useBlockRoomLogListingV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BlockRoomLogListingV2Query, BlockRoomLogListingV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BlockRoomLogListingV2Query, BlockRoomLogListingV2QueryVariables>(BlockRoomLogListingV2Document, baseOptions);
        }
export type BlockRoomLogListingV2QueryHookResult = ReturnType<typeof useBlockRoomLogListingV2Query>;
export type BlockRoomLogListingV2LazyQueryHookResult = ReturnType<typeof useBlockRoomLogListingV2LazyQuery>;
export type BlockRoomLogListingV2QueryResult = ApolloReactCommon.QueryResult<BlockRoomLogListingV2Query, BlockRoomLogListingV2QueryVariables>;
export const PackageRedemptionListingDocument = gql`
    query PackageRedemptionListing($HotelID: String!, $RegistrationID: String) {
  PackageRedemptionListing(HotelID: $HotelID, RegistrationID: $RegistrationID) {
    IncidentalChargeID
    DepartmentID
    BillScheduleID
    Description
    ComputationRule
    Amount
    Total
    Redeemed
    Balance
  }
}
    `;

/**
 * __usePackageRedemptionListingQuery__
 *
 * To run a query within a React component, call `usePackageRedemptionListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageRedemptionListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageRedemptionListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function usePackageRedemptionListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PackageRedemptionListingQuery, PackageRedemptionListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PackageRedemptionListingQuery, PackageRedemptionListingQueryVariables>(PackageRedemptionListingDocument, baseOptions);
      }
export function usePackageRedemptionListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PackageRedemptionListingQuery, PackageRedemptionListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PackageRedemptionListingQuery, PackageRedemptionListingQueryVariables>(PackageRedemptionListingDocument, baseOptions);
        }
export type PackageRedemptionListingQueryHookResult = ReturnType<typeof usePackageRedemptionListingQuery>;
export type PackageRedemptionListingLazyQueryHookResult = ReturnType<typeof usePackageRedemptionListingLazyQuery>;
export type PackageRedemptionListingQueryResult = ApolloReactCommon.QueryResult<PackageRedemptionListingQuery, PackageRedemptionListingQueryVariables>;
export const PackageRedemptionByItemDocument = gql`
    query PackageRedemptionByItem($HotelID: String!, $IncidentalChargeID: String!, $ComputationRule: String!) {
  PackageRedemptionByItem(HotelID: $HotelID, IncidentalChargeID: $IncidentalChargeID, ComputationRule: $ComputationRule) {
    IncidentalChargeID
    Description
    ComputationRule
    Amount
    Total
    Redeemed
    Balance
    RegistrationID
    BookingID
    BillScheduleID
    RoomNo
    GuestNames
    DepartmentID
    DebtorName
    MaxPackage
    GuestPackageRedeem {
      GuestName
      IsRedeem
      IsBillLedger
      BillLedgerID
      Pax
    }
  }
}
    `;

/**
 * __usePackageRedemptionByItemQuery__
 *
 * To run a query within a React component, call `usePackageRedemptionByItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackageRedemptionByItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackageRedemptionByItemQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      IncidentalChargeID: // value for 'IncidentalChargeID'
 *      ComputationRule: // value for 'ComputationRule'
 *   },
 * });
 */
export function usePackageRedemptionByItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PackageRedemptionByItemQuery, PackageRedemptionByItemQueryVariables>) {
        return ApolloReactHooks.useQuery<PackageRedemptionByItemQuery, PackageRedemptionByItemQueryVariables>(PackageRedemptionByItemDocument, baseOptions);
      }
export function usePackageRedemptionByItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PackageRedemptionByItemQuery, PackageRedemptionByItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PackageRedemptionByItemQuery, PackageRedemptionByItemQueryVariables>(PackageRedemptionByItemDocument, baseOptions);
        }
export type PackageRedemptionByItemQueryHookResult = ReturnType<typeof usePackageRedemptionByItemQuery>;
export type PackageRedemptionByItemLazyQueryHookResult = ReturnType<typeof usePackageRedemptionByItemLazyQuery>;
export type PackageRedemptionByItemQueryResult = ApolloReactCommon.QueryResult<PackageRedemptionByItemQuery, PackageRedemptionByItemQueryVariables>;
export const PackageRedemptionInsertDocument = gql`
    mutation PackageRedemptionInsert($PackageRedemptionInput: PackageRedemptionInput!) {
  PackageRedemptionInsert(PackageRedemptionInput: $PackageRedemptionInput)
}
    `;
export type PackageRedemptionInsertMutationFn = ApolloReactCommon.MutationFunction<PackageRedemptionInsertMutation, PackageRedemptionInsertMutationVariables>;

/**
 * __usePackageRedemptionInsertMutation__
 *
 * To run a mutation, you first call `usePackageRedemptionInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackageRedemptionInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packageRedemptionInsertMutation, { data, loading, error }] = usePackageRedemptionInsertMutation({
 *   variables: {
 *      PackageRedemptionInput: // value for 'PackageRedemptionInput'
 *   },
 * });
 */
export function usePackageRedemptionInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PackageRedemptionInsertMutation, PackageRedemptionInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PackageRedemptionInsertMutation, PackageRedemptionInsertMutationVariables>(PackageRedemptionInsertDocument, baseOptions);
      }
export type PackageRedemptionInsertMutationHookResult = ReturnType<typeof usePackageRedemptionInsertMutation>;
export type PackageRedemptionInsertMutationResult = ApolloReactCommon.MutationResult<PackageRedemptionInsertMutation>;
export type PackageRedemptionInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PackageRedemptionInsertMutation, PackageRedemptionInsertMutationVariables>;
export const Q3DocumentHeaderDocument = gql`
    query Q3DocumentHeader($DocumentType: String!, $HotelID: String!) {
  Q3DocumentHeader(DocumentType: $DocumentType, HotelID: $HotelID) {
    ID
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    DocumentType
    Q3DocumentDetail {
      ID
      DownloadDate
      DownloadNo
      BatchNo
      CreatedDT
    }
  }
}
    `;

/**
 * __useQ3DocumentHeaderQuery__
 *
 * To run a query within a React component, call `useQ3DocumentHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useQ3DocumentHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQ3DocumentHeaderQuery({
 *   variables: {
 *      DocumentType: // value for 'DocumentType'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useQ3DocumentHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Q3DocumentHeaderQuery, Q3DocumentHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<Q3DocumentHeaderQuery, Q3DocumentHeaderQueryVariables>(Q3DocumentHeaderDocument, baseOptions);
      }
export function useQ3DocumentHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Q3DocumentHeaderQuery, Q3DocumentHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Q3DocumentHeaderQuery, Q3DocumentHeaderQueryVariables>(Q3DocumentHeaderDocument, baseOptions);
        }
export type Q3DocumentHeaderQueryHookResult = ReturnType<typeof useQ3DocumentHeaderQuery>;
export type Q3DocumentHeaderLazyQueryHookResult = ReturnType<typeof useQ3DocumentHeaderLazyQuery>;
export type Q3DocumentHeaderQueryResult = ApolloReactCommon.QueryResult<Q3DocumentHeaderQuery, Q3DocumentHeaderQueryVariables>;
export const Q3DocumentDetailByHeaderDocument = gql`
    query Q3DocumentDetailByHeader($HeaderID: String!, $HotelID: String!) {
  Q3DocumentDetailByHeader(HeaderID: $HeaderID, HotelID: $HotelID) {
    ID
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    DownloadDate
    HeaderID
    Q3DocumentHeader {
      ID
    }
  }
}
    `;

/**
 * __useQ3DocumentDetailByHeaderQuery__
 *
 * To run a query within a React component, call `useQ3DocumentDetailByHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useQ3DocumentDetailByHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQ3DocumentDetailByHeaderQuery({
 *   variables: {
 *      HeaderID: // value for 'HeaderID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useQ3DocumentDetailByHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Q3DocumentDetailByHeaderQuery, Q3DocumentDetailByHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<Q3DocumentDetailByHeaderQuery, Q3DocumentDetailByHeaderQueryVariables>(Q3DocumentDetailByHeaderDocument, baseOptions);
      }
export function useQ3DocumentDetailByHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Q3DocumentDetailByHeaderQuery, Q3DocumentDetailByHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Q3DocumentDetailByHeaderQuery, Q3DocumentDetailByHeaderQueryVariables>(Q3DocumentDetailByHeaderDocument, baseOptions);
        }
export type Q3DocumentDetailByHeaderQueryHookResult = ReturnType<typeof useQ3DocumentDetailByHeaderQuery>;
export type Q3DocumentDetailByHeaderLazyQueryHookResult = ReturnType<typeof useQ3DocumentDetailByHeaderLazyQuery>;
export type Q3DocumentDetailByHeaderQueryResult = ApolloReactCommon.QueryResult<Q3DocumentDetailByHeaderQuery, Q3DocumentDetailByHeaderQueryVariables>;
export const Q3FRevenueDocument = gql`
    query Q3FRevenue($AccountID: String!, $HotelID: String!, $DownloadDate: DateTime!, $DownloadMode: String!, $BatchNo: Float) {
  Q3FRevenue(AccountID: $AccountID, HotelID: $HotelID, DownloadDate: $DownloadDate, DownloadMode: $DownloadMode, BatchNo: $BatchNo)
}
    `;

/**
 * __useQ3FRevenueQuery__
 *
 * To run a query within a React component, call `useQ3FRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useQ3FRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQ3FRevenueQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      HotelID: // value for 'HotelID'
 *      DownloadDate: // value for 'DownloadDate'
 *      DownloadMode: // value for 'DownloadMode'
 *      BatchNo: // value for 'BatchNo'
 *   },
 * });
 */
export function useQ3FRevenueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Q3FRevenueQuery, Q3FRevenueQueryVariables>) {
        return ApolloReactHooks.useQuery<Q3FRevenueQuery, Q3FRevenueQueryVariables>(Q3FRevenueDocument, baseOptions);
      }
export function useQ3FRevenueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Q3FRevenueQuery, Q3FRevenueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Q3FRevenueQuery, Q3FRevenueQueryVariables>(Q3FRevenueDocument, baseOptions);
        }
export type Q3FRevenueQueryHookResult = ReturnType<typeof useQ3FRevenueQuery>;
export type Q3FRevenueLazyQueryHookResult = ReturnType<typeof useQ3FRevenueLazyQuery>;
export type Q3FRevenueQueryResult = ApolloReactCommon.QueryResult<Q3FRevenueQuery, Q3FRevenueQueryVariables>;
export const Q3FSegmentDocument = gql`
    query Q3FSegment($AccountID: String!, $HotelID: String!, $EffectiveDate: DateTime!) {
  Q3FSegment(AccountID: $AccountID, HotelID: $HotelID, EffectiveDate: $EffectiveDate)
}
    `;

/**
 * __useQ3FSegmentQuery__
 *
 * To run a query within a React component, call `useQ3FSegmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useQ3FSegmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQ3FSegmentQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      HotelID: // value for 'HotelID'
 *      EffectiveDate: // value for 'EffectiveDate'
 *   },
 * });
 */
export function useQ3FSegmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Q3FSegmentQuery, Q3FSegmentQueryVariables>) {
        return ApolloReactHooks.useQuery<Q3FSegmentQuery, Q3FSegmentQueryVariables>(Q3FSegmentDocument, baseOptions);
      }
export function useQ3FSegmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Q3FSegmentQuery, Q3FSegmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Q3FSegmentQuery, Q3FSegmentQueryVariables>(Q3FSegmentDocument, baseOptions);
        }
export type Q3FSegmentQueryHookResult = ReturnType<typeof useQ3FSegmentQuery>;
export type Q3FSegmentLazyQueryHookResult = ReturnType<typeof useQ3FSegmentLazyQuery>;
export type Q3FSegmentQueryResult = ApolloReactCommon.QueryResult<Q3FSegmentQuery, Q3FSegmentQueryVariables>;
export const Q3FStatisticDocument = gql`
    query Q3FStatistic($AccountID: String!, $HotelID: String!, $EffectiveDate: DateTime!) {
  Q3FStatistic(AccountID: $AccountID, HotelID: $HotelID, EffectiveDate: $EffectiveDate)
}
    `;

/**
 * __useQ3FStatisticQuery__
 *
 * To run a query within a React component, call `useQ3FStatisticQuery` and pass it any options that fit your needs.
 * When your component renders, `useQ3FStatisticQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQ3FStatisticQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      HotelID: // value for 'HotelID'
 *      EffectiveDate: // value for 'EffectiveDate'
 *   },
 * });
 */
export function useQ3FStatisticQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Q3FStatisticQuery, Q3FStatisticQueryVariables>) {
        return ApolloReactHooks.useQuery<Q3FStatisticQuery, Q3FStatisticQueryVariables>(Q3FStatisticDocument, baseOptions);
      }
export function useQ3FStatisticLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Q3FStatisticQuery, Q3FStatisticQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Q3FStatisticQuery, Q3FStatisticQueryVariables>(Q3FStatisticDocument, baseOptions);
        }
export type Q3FStatisticQueryHookResult = ReturnType<typeof useQ3FStatisticQuery>;
export type Q3FStatisticLazyQueryHookResult = ReturnType<typeof useQ3FStatisticLazyQuery>;
export type Q3FStatisticQueryResult = ApolloReactCommon.QueryResult<Q3FStatisticQuery, Q3FStatisticQueryVariables>;
export const Q3ListingsDocument = gql`
    query Q3Listings($HotelID: String!) {
  Q3Listings(HotelID: $HotelID)
}
    `;

/**
 * __useQ3ListingsQuery__
 *
 * To run a query within a React component, call `useQ3ListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQ3ListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQ3ListingsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useQ3ListingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Q3ListingsQuery, Q3ListingsQueryVariables>) {
        return ApolloReactHooks.useQuery<Q3ListingsQuery, Q3ListingsQueryVariables>(Q3ListingsDocument, baseOptions);
      }
export function useQ3ListingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Q3ListingsQuery, Q3ListingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Q3ListingsQuery, Q3ListingsQueryVariables>(Q3ListingsDocument, baseOptions);
        }
export type Q3ListingsQueryHookResult = ReturnType<typeof useQ3ListingsQuery>;
export type Q3ListingsLazyQueryHookResult = ReturnType<typeof useQ3ListingsLazyQuery>;
export type Q3ListingsQueryResult = ApolloReactCommon.QueryResult<Q3ListingsQuery, Q3ListingsQueryVariables>;
export const IsQ3FInterfaceDocument = gql`
    query IsQ3FInterface($HotelID: String!, $UserID: String!) {
  IsQ3FInterface(HotelID: $HotelID, UserID: $UserID)
}
    `;

/**
 * __useIsQ3FInterfaceQuery__
 *
 * To run a query within a React component, call `useIsQ3FInterfaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsQ3FInterfaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsQ3FInterfaceQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      UserID: // value for 'UserID'
 *   },
 * });
 */
export function useIsQ3FInterfaceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsQ3FInterfaceQuery, IsQ3FInterfaceQueryVariables>) {
        return ApolloReactHooks.useQuery<IsQ3FInterfaceQuery, IsQ3FInterfaceQueryVariables>(IsQ3FInterfaceDocument, baseOptions);
      }
export function useIsQ3FInterfaceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsQ3FInterfaceQuery, IsQ3FInterfaceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsQ3FInterfaceQuery, IsQ3FInterfaceQueryVariables>(IsQ3FInterfaceDocument, baseOptions);
        }
export type IsQ3FInterfaceQueryHookResult = ReturnType<typeof useIsQ3FInterfaceQuery>;
export type IsQ3FInterfaceLazyQueryHookResult = ReturnType<typeof useIsQ3FInterfaceLazyQuery>;
export type IsQ3FInterfaceQueryResult = ApolloReactCommon.QueryResult<IsQ3FInterfaceQuery, IsQ3FInterfaceQueryVariables>;
export const Q3RevenueMappingListDocument = gql`
    query Q3RevenueMappingList($HotelID: String!, $AccountID: String!) {
  Q3MappingChargesList(HotelID: $HotelID)
  Q3MappingPaymentList(HotelID: $HotelID)
  Q3MappingAdvPaymentList(HotelID: $HotelID)
  Q3MappingRefundList(HotelID: $HotelID)
  Q3MappingDepositList(HotelID: $HotelID)
  Q3MappingTaxList(HotelID: $HotelID, AccountID: $AccountID)
  Q3MappingDebtorList(HotelID: $HotelID)
}
    `;

/**
 * __useQ3RevenueMappingListQuery__
 *
 * To run a query within a React component, call `useQ3RevenueMappingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useQ3RevenueMappingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQ3RevenueMappingListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useQ3RevenueMappingListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Q3RevenueMappingListQuery, Q3RevenueMappingListQueryVariables>) {
        return ApolloReactHooks.useQuery<Q3RevenueMappingListQuery, Q3RevenueMappingListQueryVariables>(Q3RevenueMappingListDocument, baseOptions);
      }
export function useQ3RevenueMappingListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Q3RevenueMappingListQuery, Q3RevenueMappingListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Q3RevenueMappingListQuery, Q3RevenueMappingListQueryVariables>(Q3RevenueMappingListDocument, baseOptions);
        }
export type Q3RevenueMappingListQueryHookResult = ReturnType<typeof useQ3RevenueMappingListQuery>;
export type Q3RevenueMappingListLazyQueryHookResult = ReturnType<typeof useQ3RevenueMappingListLazyQuery>;
export type Q3RevenueMappingListQueryResult = ApolloReactCommon.QueryResult<Q3RevenueMappingListQuery, Q3RevenueMappingListQueryVariables>;
export const Q3DocumentInsertDocument = gql`
    mutation Q3DocumentInsert($Q3DocumentDetailInput: [Q3DocumentDetailInput!]!, $Q3DocumentHeaderInput: Q3DocumentHeaderInput!) {
  Q3DocumentInsert(Q3DocumentDetailInput: $Q3DocumentDetailInput, Q3DocumentHeaderInput: $Q3DocumentHeaderInput)
}
    `;
export type Q3DocumentInsertMutationFn = ApolloReactCommon.MutationFunction<Q3DocumentInsertMutation, Q3DocumentInsertMutationVariables>;

/**
 * __useQ3DocumentInsertMutation__
 *
 * To run a mutation, you first call `useQ3DocumentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQ3DocumentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [q3DocumentInsertMutation, { data, loading, error }] = useQ3DocumentInsertMutation({
 *   variables: {
 *      Q3DocumentDetailInput: // value for 'Q3DocumentDetailInput'
 *      Q3DocumentHeaderInput: // value for 'Q3DocumentHeaderInput'
 *   },
 * });
 */
export function useQ3DocumentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Q3DocumentInsertMutation, Q3DocumentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<Q3DocumentInsertMutation, Q3DocumentInsertMutationVariables>(Q3DocumentInsertDocument, baseOptions);
      }
export type Q3DocumentInsertMutationHookResult = ReturnType<typeof useQ3DocumentInsertMutation>;
export type Q3DocumentInsertMutationResult = ApolloReactCommon.MutationResult<Q3DocumentInsertMutation>;
export type Q3DocumentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<Q3DocumentInsertMutation, Q3DocumentInsertMutationVariables>;
export const RevenueMappingInsertDocument = gql`
    mutation RevenueMappingInsert($GuestLedgerAcctInput: RevenueGuestLedgerAcctInput!, $RevenueInput: RevenueMappingInput!, $HotelID: String!, $AccountID: String!) {
  RevenueMappingInsert(GuestLedgerAcctInput: $GuestLedgerAcctInput, RevenueInput: $RevenueInput, HotelID: $HotelID, AccountID: $AccountID)
}
    `;
export type RevenueMappingInsertMutationFn = ApolloReactCommon.MutationFunction<RevenueMappingInsertMutation, RevenueMappingInsertMutationVariables>;

/**
 * __useRevenueMappingInsertMutation__
 *
 * To run a mutation, you first call `useRevenueMappingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevenueMappingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revenueMappingInsertMutation, { data, loading, error }] = useRevenueMappingInsertMutation({
 *   variables: {
 *      GuestLedgerAcctInput: // value for 'GuestLedgerAcctInput'
 *      RevenueInput: // value for 'RevenueInput'
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useRevenueMappingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevenueMappingInsertMutation, RevenueMappingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RevenueMappingInsertMutation, RevenueMappingInsertMutationVariables>(RevenueMappingInsertDocument, baseOptions);
      }
export type RevenueMappingInsertMutationHookResult = ReturnType<typeof useRevenueMappingInsertMutation>;
export type RevenueMappingInsertMutationResult = ApolloReactCommon.MutationResult<RevenueMappingInsertMutation>;
export type RevenueMappingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RevenueMappingInsertMutation, RevenueMappingInsertMutationVariables>;
export const SegmentMappingInsertDocument = gql`
    mutation SegmentMappingInsert($HotelID: String!, $SegmentInput: [SegmentInput!]!) {
  SegmentMappingInsert(HotelID: $HotelID, SegmentInput: $SegmentInput)
}
    `;
export type SegmentMappingInsertMutationFn = ApolloReactCommon.MutationFunction<SegmentMappingInsertMutation, SegmentMappingInsertMutationVariables>;

/**
 * __useSegmentMappingInsertMutation__
 *
 * To run a mutation, you first call `useSegmentMappingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSegmentMappingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [segmentMappingInsertMutation, { data, loading, error }] = useSegmentMappingInsertMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      SegmentInput: // value for 'SegmentInput'
 *   },
 * });
 */
export function useSegmentMappingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SegmentMappingInsertMutation, SegmentMappingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<SegmentMappingInsertMutation, SegmentMappingInsertMutationVariables>(SegmentMappingInsertDocument, baseOptions);
      }
export type SegmentMappingInsertMutationHookResult = ReturnType<typeof useSegmentMappingInsertMutation>;
export type SegmentMappingInsertMutationResult = ApolloReactCommon.MutationResult<SegmentMappingInsertMutation>;
export type SegmentMappingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<SegmentMappingInsertMutation, SegmentMappingInsertMutationVariables>;
export const StatisticMappingInsertDocument = gql`
    mutation StatisticMappingInsert($HotelID: String!, $Q3Statistic: [Q3StatisticInput!]!) {
  StatisticMappingInsert(HotelID: $HotelID, Q3Statistic: $Q3Statistic)
}
    `;
export type StatisticMappingInsertMutationFn = ApolloReactCommon.MutationFunction<StatisticMappingInsertMutation, StatisticMappingInsertMutationVariables>;

/**
 * __useStatisticMappingInsertMutation__
 *
 * To run a mutation, you first call `useStatisticMappingInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStatisticMappingInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [statisticMappingInsertMutation, { data, loading, error }] = useStatisticMappingInsertMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      Q3Statistic: // value for 'Q3Statistic'
 *   },
 * });
 */
export function useStatisticMappingInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StatisticMappingInsertMutation, StatisticMappingInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<StatisticMappingInsertMutation, StatisticMappingInsertMutationVariables>(StatisticMappingInsertDocument, baseOptions);
      }
export type StatisticMappingInsertMutationHookResult = ReturnType<typeof useStatisticMappingInsertMutation>;
export type StatisticMappingInsertMutationResult = ApolloReactCommon.MutationResult<StatisticMappingInsertMutation>;
export type StatisticMappingInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<StatisticMappingInsertMutation, StatisticMappingInsertMutationVariables>;
export const RoomServiceListingDocument = gql`
    query RoomServiceListing($HotelID: String!, $TrxDate: DateTime!, $RegistrationID: String) {
  RoomServiceListing(HotelID: $HotelID, TrxDate: $TrxDate, RegistrationID: $RegistrationID) {
    ID
    FolioID
    ReversedBillLedgerID
    TrxDate
    Qty
    UnitPrice
    BaseAmount
    TrxAmount
    TaxAmount
    ServiceCharge
    Reason
    IsDelivered
    ModifiedDT
    CreatedDT
    Signature
    IncidentalChargeID
    BillLedgerSignature {
      ImageURL
      BucketFileName
    }
    IncidentalCharge {
      Description
      IsOutlet
    }
    RegistrationID
    Registration {
      ID
      RoomTypeID
      RoomID
      GuestID
      Guest {
        Contact {
          ID
          FullName
        }
      }
      RoomType {
        Code
        Description
      }
      Room {
        RoomNo
      }
    }
  }
}
    `;

/**
 * __useRoomServiceListingQuery__
 *
 * To run a query within a React component, call `useRoomServiceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomServiceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomServiceListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      TrxDate: // value for 'TrxDate'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useRoomServiceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomServiceListingQuery, RoomServiceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomServiceListingQuery, RoomServiceListingQueryVariables>(RoomServiceListingDocument, baseOptions);
      }
export function useRoomServiceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomServiceListingQuery, RoomServiceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomServiceListingQuery, RoomServiceListingQueryVariables>(RoomServiceListingDocument, baseOptions);
        }
export type RoomServiceListingQueryHookResult = ReturnType<typeof useRoomServiceListingQuery>;
export type RoomServiceListingLazyQueryHookResult = ReturnType<typeof useRoomServiceListingLazyQuery>;
export type RoomServiceListingQueryResult = ApolloReactCommon.QueryResult<RoomServiceListingQuery, RoomServiceListingQueryVariables>;
export const DeliveredRoomServiceListingDocument = gql`
    query DeliveredRoomServiceListing($HotelID: String!, $TrxDate: DateTime!, $RegistrationID: String) {
  DeliveredRoomServiceListing(HotelID: $HotelID, TrxDate: $TrxDate, RegistrationID: $RegistrationID) {
    ID
    FolioID
    ReversedBillLedgerID
    TrxDate
    Qty
    UnitPrice
    BaseAmount
    TrxAmount
    TaxAmount
    ServiceCharge
    Reason
    IsDelivered
    ModifiedDT
    CreatedDT
    Signature
    IncidentalChargeID
    BillLedgerSignature {
      ImageURL
      BucketFileName
    }
    IncidentalCharge {
      Description
      IsOutlet
    }
    RegistrationID
    Registration {
      ID
      RoomTypeID
      RoomID
      GuestID
      Guest {
        Contact {
          ID
          FullName
        }
      }
      RoomType {
        Code
        Description
      }
      Room {
        RoomNo
      }
    }
  }
}
    `;

/**
 * __useDeliveredRoomServiceListingQuery__
 *
 * To run a query within a React component, call `useDeliveredRoomServiceListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveredRoomServiceListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveredRoomServiceListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      TrxDate: // value for 'TrxDate'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function useDeliveredRoomServiceListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeliveredRoomServiceListingQuery, DeliveredRoomServiceListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DeliveredRoomServiceListingQuery, DeliveredRoomServiceListingQueryVariables>(DeliveredRoomServiceListingDocument, baseOptions);
      }
export function useDeliveredRoomServiceListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliveredRoomServiceListingQuery, DeliveredRoomServiceListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeliveredRoomServiceListingQuery, DeliveredRoomServiceListingQueryVariables>(DeliveredRoomServiceListingDocument, baseOptions);
        }
export type DeliveredRoomServiceListingQueryHookResult = ReturnType<typeof useDeliveredRoomServiceListingQuery>;
export type DeliveredRoomServiceListingLazyQueryHookResult = ReturnType<typeof useDeliveredRoomServiceListingLazyQuery>;
export type DeliveredRoomServiceListingQueryResult = ApolloReactCommon.QueryResult<DeliveredRoomServiceListingQuery, DeliveredRoomServiceListingQueryVariables>;
export const RoomServiceRegistrationListingDocument = gql`
    query RoomServiceRegistrationListing($HotelID: String!, $TrxDate: DateTime!) {
  RoomServiceRegistrationListing(HotelID: $HotelID, TrxDate: $TrxDate) {
    ID
    Payment {
      ID
      PaymentStatus
    }
    BillLedger {
      ID
      TrxDate
      IsDelivered
      CreatedDT
      ModifiedDT
      Reason
      Description
      PaymentID
      TrxAmount
      ReversedBillLedgerID
      IncidentalCharge {
        IsOutlet
      }
    }
    RoomType {
      Code
    }
    Room {
      RoomNo
    }
    Guest {
      ID
      Contact {
        ID
        FullName
      }
    }
  }
}
    `;

/**
 * __useRoomServiceRegistrationListingQuery__
 *
 * To run a query within a React component, call `useRoomServiceRegistrationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomServiceRegistrationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomServiceRegistrationListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      TrxDate: // value for 'TrxDate'
 *   },
 * });
 */
export function useRoomServiceRegistrationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomServiceRegistrationListingQuery, RoomServiceRegistrationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomServiceRegistrationListingQuery, RoomServiceRegistrationListingQueryVariables>(RoomServiceRegistrationListingDocument, baseOptions);
      }
export function useRoomServiceRegistrationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomServiceRegistrationListingQuery, RoomServiceRegistrationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomServiceRegistrationListingQuery, RoomServiceRegistrationListingQueryVariables>(RoomServiceRegistrationListingDocument, baseOptions);
        }
export type RoomServiceRegistrationListingQueryHookResult = ReturnType<typeof useRoomServiceRegistrationListingQuery>;
export type RoomServiceRegistrationListingLazyQueryHookResult = ReturnType<typeof useRoomServiceRegistrationListingLazyQuery>;
export type RoomServiceRegistrationListingQueryResult = ApolloReactCommon.QueryResult<RoomServiceRegistrationListingQuery, RoomServiceRegistrationListingQueryVariables>;
export const DeliveredRoomServiceRegistrationListingDocument = gql`
    query DeliveredRoomServiceRegistrationListing($HotelID: String!, $TrxDate: DateTime!) {
  DeliveredRoomServiceRegistrationListing(HotelID: $HotelID, TrxDate: $TrxDate) {
    ID
    BillLedger {
      ID
      TrxDate
      IsDelivered
      CreatedDT
      ModifiedDT
      TrxAmount
      ReversedBillLedgerID
      IncidentalCharge {
        IsOutlet
      }
    }
    RoomType {
      Code
    }
    Room {
      RoomNo
    }
    Guest {
      ID
      Contact {
        ID
        FullName
      }
    }
  }
}
    `;

/**
 * __useDeliveredRoomServiceRegistrationListingQuery__
 *
 * To run a query within a React component, call `useDeliveredRoomServiceRegistrationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveredRoomServiceRegistrationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveredRoomServiceRegistrationListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      TrxDate: // value for 'TrxDate'
 *   },
 * });
 */
export function useDeliveredRoomServiceRegistrationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeliveredRoomServiceRegistrationListingQuery, DeliveredRoomServiceRegistrationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DeliveredRoomServiceRegistrationListingQuery, DeliveredRoomServiceRegistrationListingQueryVariables>(DeliveredRoomServiceRegistrationListingDocument, baseOptions);
      }
export function useDeliveredRoomServiceRegistrationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeliveredRoomServiceRegistrationListingQuery, DeliveredRoomServiceRegistrationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeliveredRoomServiceRegistrationListingQuery, DeliveredRoomServiceRegistrationListingQueryVariables>(DeliveredRoomServiceRegistrationListingDocument, baseOptions);
        }
export type DeliveredRoomServiceRegistrationListingQueryHookResult = ReturnType<typeof useDeliveredRoomServiceRegistrationListingQuery>;
export type DeliveredRoomServiceRegistrationListingLazyQueryHookResult = ReturnType<typeof useDeliveredRoomServiceRegistrationListingLazyQuery>;
export type DeliveredRoomServiceRegistrationListingQueryResult = ApolloReactCommon.QueryResult<DeliveredRoomServiceRegistrationListingQuery, DeliveredRoomServiceRegistrationListingQueryVariables>;
export const PaymentBillLedgerListingDocument = gql`
    query PaymentBillLedgerListing($FolioID: String!) {
  PaymentBillLedgerListing(FolioID: $FolioID) {
    ID
    IncidentalChargeID
    Qty
    UnitPrice
    ReferenceNo
  }
}
    `;

/**
 * __usePaymentBillLedgerListingQuery__
 *
 * To run a query within a React component, call `usePaymentBillLedgerListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentBillLedgerListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentBillLedgerListingQuery({
 *   variables: {
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function usePaymentBillLedgerListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentBillLedgerListingQuery, PaymentBillLedgerListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentBillLedgerListingQuery, PaymentBillLedgerListingQueryVariables>(PaymentBillLedgerListingDocument, baseOptions);
      }
export function usePaymentBillLedgerListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentBillLedgerListingQuery, PaymentBillLedgerListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentBillLedgerListingQuery, PaymentBillLedgerListingQueryVariables>(PaymentBillLedgerListingDocument, baseOptions);
        }
export type PaymentBillLedgerListingQueryHookResult = ReturnType<typeof usePaymentBillLedgerListingQuery>;
export type PaymentBillLedgerListingLazyQueryHookResult = ReturnType<typeof usePaymentBillLedgerListingLazyQuery>;
export type PaymentBillLedgerListingQueryResult = ApolloReactCommon.QueryResult<PaymentBillLedgerListingQuery, PaymentBillLedgerListingQueryVariables>;
export const PaymentRegistrationListingDocument = gql`
    query PaymentRegistrationListing($RegistrationID: String!) {
  PaymentRegistrationListing(RegistrationID: $RegistrationID) {
    ID
    Room {
      RoomNo
    }
  }
}
    `;

/**
 * __usePaymentRegistrationListingQuery__
 *
 * To run a query within a React component, call `usePaymentRegistrationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRegistrationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRegistrationListingQuery({
 *   variables: {
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function usePaymentRegistrationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentRegistrationListingQuery, PaymentRegistrationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentRegistrationListingQuery, PaymentRegistrationListingQueryVariables>(PaymentRegistrationListingDocument, baseOptions);
      }
export function usePaymentRegistrationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentRegistrationListingQuery, PaymentRegistrationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentRegistrationListingQuery, PaymentRegistrationListingQueryVariables>(PaymentRegistrationListingDocument, baseOptions);
        }
export type PaymentRegistrationListingQueryHookResult = ReturnType<typeof usePaymentRegistrationListingQuery>;
export type PaymentRegistrationListingLazyQueryHookResult = ReturnType<typeof usePaymentRegistrationListingLazyQuery>;
export type PaymentRegistrationListingQueryResult = ApolloReactCommon.QueryResult<PaymentRegistrationListingQuery, PaymentRegistrationListingQueryVariables>;
export const RoomNumberForFoodListingDocument = gql`
    query RoomNumberForFoodListing($HotelID: String!) {
  RoomNumberForFoodListing(HotelID: $HotelID) {
    ID
    AccountID
    SalesChannel {
      ID
      IsActive
      Description
      Department {
        ID
        IncidentalCharge {
          ID
        }
      }
    }
    DebtorAccount
    Registration {
      ID
      IsPrincipal
      RegistrationStatus
      RoomTypeID
      CheckOutDate
      CheckInDate
      RoomID
      Room {
        RoomNo
      }
      Guest {
        Contact {
          ID
          FullName
        }
      }
      RoomType {
        Code
        Description
      }
    }
  }
}
    `;

/**
 * __useRoomNumberForFoodListingQuery__
 *
 * To run a query within a React component, call `useRoomNumberForFoodListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomNumberForFoodListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomNumberForFoodListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRoomNumberForFoodListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomNumberForFoodListingQuery, RoomNumberForFoodListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomNumberForFoodListingQuery, RoomNumberForFoodListingQueryVariables>(RoomNumberForFoodListingDocument, baseOptions);
      }
export function useRoomNumberForFoodListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomNumberForFoodListingQuery, RoomNumberForFoodListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomNumberForFoodListingQuery, RoomNumberForFoodListingQueryVariables>(RoomNumberForFoodListingDocument, baseOptions);
        }
export type RoomNumberForFoodListingQueryHookResult = ReturnType<typeof useRoomNumberForFoodListingQuery>;
export type RoomNumberForFoodListingLazyQueryHookResult = ReturnType<typeof useRoomNumberForFoodListingLazyQuery>;
export type RoomNumberForFoodListingQueryResult = ApolloReactCommon.QueryResult<RoomNumberForFoodListingQuery, RoomNumberForFoodListingQueryVariables>;
export const GetHotelLatestTaxDocument = gql`
    query GetHotelLatestTax($HotelID: String!) {
  GetLevyTax(HotelID: $HotelID) {
    TaxScheme
    TaxCode
    EffectiveDate
    IsFixAmount
    Value
    IsTaxable
  }
  GetPlatformTax(HotelID: $HotelID) {
    TaxScheme
    TaxCode
    EffectiveDate
    IsFixAmount
    Value
    IsTaxable
  }
  GetCurrentGovTax(HotelID: $HotelID) {
    TaxScheme
    TaxCode
    EffectiveDate
    IsFixAmount
    Value
    IsTaxable
  }
  GetCurrentTourismTax(HotelID: $HotelID) {
    TaxScheme
    TaxCode
    EffectiveDate
    IsFixAmount
    Value
    IsTaxable
  }
  GetServiceChargeTax(HotelID: $HotelID) {
    TaxScheme
    TaxCode
    EffectiveDate
    IsFixAmount
    Value
    IsTaxable
  }
}
    `;

/**
 * __useGetHotelLatestTaxQuery__
 *
 * To run a query within a React component, call `useGetHotelLatestTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHotelLatestTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHotelLatestTaxQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useGetHotelLatestTaxQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHotelLatestTaxQuery, GetHotelLatestTaxQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHotelLatestTaxQuery, GetHotelLatestTaxQueryVariables>(GetHotelLatestTaxDocument, baseOptions);
      }
export function useGetHotelLatestTaxLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHotelLatestTaxQuery, GetHotelLatestTaxQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHotelLatestTaxQuery, GetHotelLatestTaxQueryVariables>(GetHotelLatestTaxDocument, baseOptions);
        }
export type GetHotelLatestTaxQueryHookResult = ReturnType<typeof useGetHotelLatestTaxQuery>;
export type GetHotelLatestTaxLazyQueryHookResult = ReturnType<typeof useGetHotelLatestTaxLazyQuery>;
export type GetHotelLatestTaxQueryResult = ApolloReactCommon.QueryResult<GetHotelLatestTaxQuery, GetHotelLatestTaxQueryVariables>;
export const PaymentRoomServiceDetailDocument = gql`
    query PaymentRoomServiceDetail($OrderID: String!, $RegistrationID: String!) {
  PaymentRoomServiceDetail(OrderID: $OrderID, RegistrationID: $RegistrationID) {
    ID
    FolioID
    RegistrationID
    HotelID
    ReceiptNo
    PaymentDate
    PaySessionNo
    CheckOutID
    CardNo
    Amount
  }
}
    `;

/**
 * __usePaymentRoomServiceDetailQuery__
 *
 * To run a query within a React component, call `usePaymentRoomServiceDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentRoomServiceDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentRoomServiceDetailQuery({
 *   variables: {
 *      OrderID: // value for 'OrderID'
 *      RegistrationID: // value for 'RegistrationID'
 *   },
 * });
 */
export function usePaymentRoomServiceDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaymentRoomServiceDetailQuery, PaymentRoomServiceDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<PaymentRoomServiceDetailQuery, PaymentRoomServiceDetailQueryVariables>(PaymentRoomServiceDetailDocument, baseOptions);
      }
export function usePaymentRoomServiceDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentRoomServiceDetailQuery, PaymentRoomServiceDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaymentRoomServiceDetailQuery, PaymentRoomServiceDetailQueryVariables>(PaymentRoomServiceDetailDocument, baseOptions);
        }
export type PaymentRoomServiceDetailQueryHookResult = ReturnType<typeof usePaymentRoomServiceDetailQuery>;
export type PaymentRoomServiceDetailLazyQueryHookResult = ReturnType<typeof usePaymentRoomServiceDetailLazyQuery>;
export type PaymentRoomServiceDetailQueryResult = ApolloReactCommon.QueryResult<PaymentRoomServiceDetailQuery, PaymentRoomServiceDetailQueryVariables>;
export const ComputeTaxArrByHotelDocument = gql`
    query ComputeTaxArrByHotel($TaxInput: [TaxInput!]!) {
  ComputeTaxArrByHotel(TaxInput: $TaxInput) {
    DiscountAmount
    BaseAmount
    TaxAmount
    ServiceCharge
    TotalAmount
    HotelTaxOutput {
      TaxCode
      TaxRate
      Value
      TaxID
      SeqNo
      TaxScheme
    }
  }
}
    `;

/**
 * __useComputeTaxArrByHotelQuery__
 *
 * To run a query within a React component, call `useComputeTaxArrByHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeTaxArrByHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeTaxArrByHotelQuery({
 *   variables: {
 *      TaxInput: // value for 'TaxInput'
 *   },
 * });
 */
export function useComputeTaxArrByHotelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>) {
        return ApolloReactHooks.useQuery<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>(ComputeTaxArrByHotelDocument, baseOptions);
      }
export function useComputeTaxArrByHotelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>(ComputeTaxArrByHotelDocument, baseOptions);
        }
export type ComputeTaxArrByHotelQueryHookResult = ReturnType<typeof useComputeTaxArrByHotelQuery>;
export type ComputeTaxArrByHotelLazyQueryHookResult = ReturnType<typeof useComputeTaxArrByHotelLazyQuery>;
export type ComputeTaxArrByHotelQueryResult = ApolloReactCommon.QueryResult<ComputeTaxArrByHotelQuery, ComputeTaxArrByHotelQueryVariables>;
export const BillLedgerInsertDocument = gql`
    mutation BillLedgerInsert($BillLedgerInput: [BillLedgerInput!]!, $IsNonGuest: Boolean, $IsBookingIncCharges: Boolean) {
  BillLedgerInsert(BillLedgerInput: $BillLedgerInput, IsNonGuest: $IsNonGuest, IsBookingIncCharges: $IsBookingIncCharges)
}
    `;
export type BillLedgerInsertMutationFn = ApolloReactCommon.MutationFunction<BillLedgerInsertMutation, BillLedgerInsertMutationVariables>;

/**
 * __useBillLedgerInsertMutation__
 *
 * To run a mutation, you first call `useBillLedgerInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillLedgerInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billLedgerInsertMutation, { data, loading, error }] = useBillLedgerInsertMutation({
 *   variables: {
 *      BillLedgerInput: // value for 'BillLedgerInput'
 *      IsNonGuest: // value for 'IsNonGuest'
 *      IsBookingIncCharges: // value for 'IsBookingIncCharges'
 *   },
 * });
 */
export function useBillLedgerInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillLedgerInsertMutation, BillLedgerInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<BillLedgerInsertMutation, BillLedgerInsertMutationVariables>(BillLedgerInsertDocument, baseOptions);
      }
export type BillLedgerInsertMutationHookResult = ReturnType<typeof useBillLedgerInsertMutation>;
export type BillLedgerInsertMutationResult = ApolloReactCommon.MutationResult<BillLedgerInsertMutation>;
export type BillLedgerInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<BillLedgerInsertMutation, BillLedgerInsertMutationVariables>;
export const BillLedgerUpdateDocument = gql`
    mutation BillLedgerUpdate($BillLedgerInput: [BillLedgerInput!]!, $AttachmentInput: [Upload!]) {
  BillLedgerUpdate(BillLedgerInput: $BillLedgerInput, AttachmentInput: $AttachmentInput)
}
    `;
export type BillLedgerUpdateMutationFn = ApolloReactCommon.MutationFunction<BillLedgerUpdateMutation, BillLedgerUpdateMutationVariables>;

/**
 * __useBillLedgerUpdateMutation__
 *
 * To run a mutation, you first call `useBillLedgerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillLedgerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billLedgerUpdateMutation, { data, loading, error }] = useBillLedgerUpdateMutation({
 *   variables: {
 *      BillLedgerInput: // value for 'BillLedgerInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useBillLedgerUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillLedgerUpdateMutation, BillLedgerUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BillLedgerUpdateMutation, BillLedgerUpdateMutationVariables>(BillLedgerUpdateDocument, baseOptions);
      }
export type BillLedgerUpdateMutationHookResult = ReturnType<typeof useBillLedgerUpdateMutation>;
export type BillLedgerUpdateMutationResult = ApolloReactCommon.MutationResult<BillLedgerUpdateMutation>;
export type BillLedgerUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BillLedgerUpdateMutation, BillLedgerUpdateMutationVariables>;
export const BillLedgerCancelDocument = gql`
    mutation BillLedgerCancel($BillLedgerInput: [BillLedgerInput!]!) {
  BillLedgerCancel(BillLedgerInput: $BillLedgerInput)
}
    `;
export type BillLedgerCancelMutationFn = ApolloReactCommon.MutationFunction<BillLedgerCancelMutation, BillLedgerCancelMutationVariables>;

/**
 * __useBillLedgerCancelMutation__
 *
 * To run a mutation, you first call `useBillLedgerCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillLedgerCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billLedgerCancelMutation, { data, loading, error }] = useBillLedgerCancelMutation({
 *   variables: {
 *      BillLedgerInput: // value for 'BillLedgerInput'
 *   },
 * });
 */
export function useBillLedgerCancelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BillLedgerCancelMutation, BillLedgerCancelMutationVariables>) {
        return ApolloReactHooks.useMutation<BillLedgerCancelMutation, BillLedgerCancelMutationVariables>(BillLedgerCancelDocument, baseOptions);
      }
export type BillLedgerCancelMutationHookResult = ReturnType<typeof useBillLedgerCancelMutation>;
export type BillLedgerCancelMutationResult = ApolloReactCommon.MutationResult<BillLedgerCancelMutation>;
export type BillLedgerCancelMutationOptions = ApolloReactCommon.BaseMutationOptions<BillLedgerCancelMutation, BillLedgerCancelMutationVariables>;
export const FolioInsertDocument = gql`
    mutation FolioInsert($BillLedgerInput: [BillLedgerInput!]!, $PaymentInput: PaymentInput!, $RefFolioID: String) {
  FolioInsert(BillLedgerInput: $BillLedgerInput, PaymentInput: $PaymentInput, RefFolioID: $RefFolioID)
}
    `;
export type FolioInsertMutationFn = ApolloReactCommon.MutationFunction<FolioInsertMutation, FolioInsertMutationVariables>;

/**
 * __useFolioInsertMutation__
 *
 * To run a mutation, you first call `useFolioInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFolioInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [folioInsertMutation, { data, loading, error }] = useFolioInsertMutation({
 *   variables: {
 *      BillLedgerInput: // value for 'BillLedgerInput'
 *      PaymentInput: // value for 'PaymentInput'
 *      RefFolioID: // value for 'RefFolioID'
 *   },
 * });
 */
export function useFolioInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FolioInsertMutation, FolioInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<FolioInsertMutation, FolioInsertMutationVariables>(FolioInsertDocument, baseOptions);
      }
export type FolioInsertMutationHookResult = ReturnType<typeof useFolioInsertMutation>;
export type FolioInsertMutationResult = ApolloReactCommon.MutationResult<FolioInsertMutation>;
export type FolioInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<FolioInsertMutation, FolioInsertMutationVariables>;
export const OnlinePaymentRoomServicesDocument = gql`
    mutation OnlinePaymentRoomServices($BillLedgerInput: [BillLedgerInput!]!, $PaymentInput: PaymentInput!) {
  OnlinePaymentRoomServices(BillLedgerInput: $BillLedgerInput, PaymentInput: $PaymentInput) {
    ReceiptNo
    FolioID
  }
}
    `;
export type OnlinePaymentRoomServicesMutationFn = ApolloReactCommon.MutationFunction<OnlinePaymentRoomServicesMutation, OnlinePaymentRoomServicesMutationVariables>;

/**
 * __useOnlinePaymentRoomServicesMutation__
 *
 * To run a mutation, you first call `useOnlinePaymentRoomServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnlinePaymentRoomServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onlinePaymentRoomServicesMutation, { data, loading, error }] = useOnlinePaymentRoomServicesMutation({
 *   variables: {
 *      BillLedgerInput: // value for 'BillLedgerInput'
 *      PaymentInput: // value for 'PaymentInput'
 *   },
 * });
 */
export function useOnlinePaymentRoomServicesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<OnlinePaymentRoomServicesMutation, OnlinePaymentRoomServicesMutationVariables>) {
        return ApolloReactHooks.useMutation<OnlinePaymentRoomServicesMutation, OnlinePaymentRoomServicesMutationVariables>(OnlinePaymentRoomServicesDocument, baseOptions);
      }
export type OnlinePaymentRoomServicesMutationHookResult = ReturnType<typeof useOnlinePaymentRoomServicesMutation>;
export type OnlinePaymentRoomServicesMutationResult = ApolloReactCommon.MutationResult<OnlinePaymentRoomServicesMutation>;
export type OnlinePaymentRoomServicesMutationOptions = ApolloReactCommon.BaseMutationOptions<OnlinePaymentRoomServicesMutation, OnlinePaymentRoomServicesMutationVariables>;
export const RoomServicePaymentUpdateDocument = gql`
    mutation RoomServicePaymentUpdate($HotelID: String!, $CheckOutID: String, $OrderID: String, $Status: String, $FolioID: String, $ReferenceNo: String, $CardNo: String, $PaymentType: String) {
  RoomServicePaymentUpdate(HotelID: $HotelID, CheckOutID: $CheckOutID, OrderID: $OrderID, Status: $Status, FolioID: $FolioID, ReferenceNo: $ReferenceNo, CardNo: $CardNo, PaymentType: $PaymentType)
}
    `;
export type RoomServicePaymentUpdateMutationFn = ApolloReactCommon.MutationFunction<RoomServicePaymentUpdateMutation, RoomServicePaymentUpdateMutationVariables>;

/**
 * __useRoomServicePaymentUpdateMutation__
 *
 * To run a mutation, you first call `useRoomServicePaymentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomServicePaymentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomServicePaymentUpdateMutation, { data, loading, error }] = useRoomServicePaymentUpdateMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      CheckOutID: // value for 'CheckOutID'
 *      OrderID: // value for 'OrderID'
 *      Status: // value for 'Status'
 *      FolioID: // value for 'FolioID'
 *      ReferenceNo: // value for 'ReferenceNo'
 *      CardNo: // value for 'CardNo'
 *      PaymentType: // value for 'PaymentType'
 *   },
 * });
 */
export function useRoomServicePaymentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RoomServicePaymentUpdateMutation, RoomServicePaymentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RoomServicePaymentUpdateMutation, RoomServicePaymentUpdateMutationVariables>(RoomServicePaymentUpdateDocument, baseOptions);
      }
export type RoomServicePaymentUpdateMutationHookResult = ReturnType<typeof useRoomServicePaymentUpdateMutation>;
export type RoomServicePaymentUpdateMutationResult = ApolloReactCommon.MutationResult<RoomServicePaymentUpdateMutation>;
export type RoomServicePaymentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RoomServicePaymentUpdateMutation, RoomServicePaymentUpdateMutationVariables>;
export const ServiceRequestListingDocument = gql`
    query ServiceRequestListing($HotelID: String!, $DepartmentID: String!) {
  ServiceRequestListing(HotelID: $HotelID, DepartmentID: $DepartmentID) {
    ID
    Title
    Description
    EstimatedCompletion
    RequestBy
    Status
    Remarks
    DepartmentID
    Room {
      ID
      RoomNo
      Location {
        ID
        Description
      }
    }
    ServiceRequestGallery {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
    Department {
      Name
    }
    CreatedBy
    ModifiedBy
  }
}
    `;

/**
 * __useServiceRequestListingQuery__
 *
 * To run a query within a React component, call `useServiceRequestListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceRequestListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceRequestListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useServiceRequestListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServiceRequestListingQuery, ServiceRequestListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ServiceRequestListingQuery, ServiceRequestListingQueryVariables>(ServiceRequestListingDocument, baseOptions);
      }
export function useServiceRequestListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServiceRequestListingQuery, ServiceRequestListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServiceRequestListingQuery, ServiceRequestListingQueryVariables>(ServiceRequestListingDocument, baseOptions);
        }
export type ServiceRequestListingQueryHookResult = ReturnType<typeof useServiceRequestListingQuery>;
export type ServiceRequestListingLazyQueryHookResult = ReturnType<typeof useServiceRequestListingLazyQuery>;
export type ServiceRequestListingQueryResult = ApolloReactCommon.QueryResult<ServiceRequestListingQuery, ServiceRequestListingQueryVariables>;
export const SrDepartmentListingDocument = gql`
    query SrDepartmentListing($HotelID: String!) {
  DepartmentListing(HotelID: $HotelID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    Code
    Name
    AllowReason
    AllowSalesChannel
    AllowIncidental
    AllowGuestApp
    ServiceRequest {
      ID
      Status
    }
  }
}
    `;

/**
 * __useSrDepartmentListingQuery__
 *
 * To run a query within a React component, call `useSrDepartmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSrDepartmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSrDepartmentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useSrDepartmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SrDepartmentListingQuery, SrDepartmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SrDepartmentListingQuery, SrDepartmentListingQueryVariables>(SrDepartmentListingDocument, baseOptions);
      }
export function useSrDepartmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SrDepartmentListingQuery, SrDepartmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SrDepartmentListingQuery, SrDepartmentListingQueryVariables>(SrDepartmentListingDocument, baseOptions);
        }
export type SrDepartmentListingQueryHookResult = ReturnType<typeof useSrDepartmentListingQuery>;
export type SrDepartmentListingLazyQueryHookResult = ReturnType<typeof useSrDepartmentListingLazyQuery>;
export type SrDepartmentListingQueryResult = ApolloReactCommon.QueryResult<SrDepartmentListingQuery, SrDepartmentListingQueryVariables>;
export const SrLocationListingDocument = gql`
    query SrLocationListing($HotelID: String!) {
  SrLocationListing(HotelID: $HotelID) {
    ID
    Code
    Description
    IsActive
  }
}
    `;

/**
 * __useSrLocationListingQuery__
 *
 * To run a query within a React component, call `useSrLocationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSrLocationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSrLocationListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useSrLocationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SrLocationListingQuery, SrLocationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SrLocationListingQuery, SrLocationListingQueryVariables>(SrLocationListingDocument, baseOptions);
      }
export function useSrLocationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SrLocationListingQuery, SrLocationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SrLocationListingQuery, SrLocationListingQueryVariables>(SrLocationListingDocument, baseOptions);
        }
export type SrLocationListingQueryHookResult = ReturnType<typeof useSrLocationListingQuery>;
export type SrLocationListingLazyQueryHookResult = ReturnType<typeof useSrLocationListingLazyQuery>;
export type SrLocationListingQueryResult = ApolloReactCommon.QueryResult<SrLocationListingQuery, SrLocationListingQueryVariables>;
export const ServiceRequestv2ListingDocument = gql`
    query ServiceRequestv2Listing($HotelID: String!, $DepartmentID: String, $Status: String, $offset: Float, $limit: Float) {
  ServiceRequestv2Listing(HotelID: $HotelID, DepartmentID: $DepartmentID, Status: $Status, offset: $offset, limit: $limit) {
    ID
    Title
    Description
    EstimatedCompletion
    RequestBy
    Status
    Remarks
    CreatedDT
    DepartmentID
    ClosedDate
    Room {
      ID
      RoomNo
      Location {
        ID
        Description
      }
    }
    Registration {
      Guest {
        GuestType
      }
    }
    ServiceRequestGallery {
      ID
      Gallery {
        ID
        BucketFileName
        ImageURL
      }
    }
    Department {
      Name
    }
    CreatedBy
    ModifiedBy
  }
}
    `;

/**
 * __useServiceRequestv2ListingQuery__
 *
 * To run a query within a React component, call `useServiceRequestv2ListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceRequestv2ListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceRequestv2ListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DepartmentID: // value for 'DepartmentID'
 *      Status: // value for 'Status'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useServiceRequestv2ListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServiceRequestv2ListingQuery, ServiceRequestv2ListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ServiceRequestv2ListingQuery, ServiceRequestv2ListingQueryVariables>(ServiceRequestv2ListingDocument, baseOptions);
      }
export function useServiceRequestv2ListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServiceRequestv2ListingQuery, ServiceRequestv2ListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServiceRequestv2ListingQuery, ServiceRequestv2ListingQueryVariables>(ServiceRequestv2ListingDocument, baseOptions);
        }
export type ServiceRequestv2ListingQueryHookResult = ReturnType<typeof useServiceRequestv2ListingQuery>;
export type ServiceRequestv2ListingLazyQueryHookResult = ReturnType<typeof useServiceRequestv2ListingLazyQuery>;
export type ServiceRequestv2ListingQueryResult = ApolloReactCommon.QueryResult<ServiceRequestv2ListingQuery, ServiceRequestv2ListingQueryVariables>;
export const RoomAllocationLocationListingDocument = gql`
    query RoomAllocationLocationListing($HotelID: String!, $AccountID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  RoomAllocationLocationListing(HotelID: $HotelID, AccountID: $AccountID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;

/**
 * __useRoomAllocationLocationListingQuery__
 *
 * To run a query within a React component, call `useRoomAllocationLocationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomAllocationLocationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomAllocationLocationListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useRoomAllocationLocationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomAllocationLocationListingQuery, RoomAllocationLocationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomAllocationLocationListingQuery, RoomAllocationLocationListingQueryVariables>(RoomAllocationLocationListingDocument, baseOptions);
      }
export function useRoomAllocationLocationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomAllocationLocationListingQuery, RoomAllocationLocationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomAllocationLocationListingQuery, RoomAllocationLocationListingQueryVariables>(RoomAllocationLocationListingDocument, baseOptions);
        }
export type RoomAllocationLocationListingQueryHookResult = ReturnType<typeof useRoomAllocationLocationListingQuery>;
export type RoomAllocationLocationListingLazyQueryHookResult = ReturnType<typeof useRoomAllocationLocationListingLazyQuery>;
export type RoomAllocationLocationListingQueryResult = ApolloReactCommon.QueryResult<RoomAllocationLocationListingQuery, RoomAllocationLocationListingQueryVariables>;
export const ChooseRoomAllocationDetailDocument = gql`
    query ChooseRoomAllocationDetail($HotelID: String!, $AccountID: String!, $EffectiveDate: DateTime) {
  ChooseRoomAllocationDetail(HotelID: $HotelID, AccountID: $AccountID, EffectiveDate: $EffectiveDate) {
    ID
    EffectiveDate
    Location {
      ID
      Code
      Description
    }
    RoomType {
      ID
      Code
      Description
    }
    Room {
      ID
      RoomNo
    }
  }
}
    `;

/**
 * __useChooseRoomAllocationDetailQuery__
 *
 * To run a query within a React component, call `useChooseRoomAllocationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useChooseRoomAllocationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChooseRoomAllocationDetailQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      EffectiveDate: // value for 'EffectiveDate'
 *   },
 * });
 */
export function useChooseRoomAllocationDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChooseRoomAllocationDetailQuery, ChooseRoomAllocationDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<ChooseRoomAllocationDetailQuery, ChooseRoomAllocationDetailQueryVariables>(ChooseRoomAllocationDetailDocument, baseOptions);
      }
export function useChooseRoomAllocationDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChooseRoomAllocationDetailQuery, ChooseRoomAllocationDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChooseRoomAllocationDetailQuery, ChooseRoomAllocationDetailQueryVariables>(ChooseRoomAllocationDetailDocument, baseOptions);
        }
export type ChooseRoomAllocationDetailQueryHookResult = ReturnType<typeof useChooseRoomAllocationDetailQuery>;
export type ChooseRoomAllocationDetailLazyQueryHookResult = ReturnType<typeof useChooseRoomAllocationDetailLazyQuery>;
export type ChooseRoomAllocationDetailQueryResult = ApolloReactCommon.QueryResult<ChooseRoomAllocationDetailQuery, ChooseRoomAllocationDetailQueryVariables>;
export const ChooseRoomAllocationListingDocument = gql`
    query ChooseRoomAllocationListing($HotelID: String!, $AccountID: String!, $DateSearch: DateTime) {
  ChooseRoomAllocationListing(HotelID: $HotelID, AccountID: $AccountID, DateSearch: $DateSearch)
}
    `;

/**
 * __useChooseRoomAllocationListingQuery__
 *
 * To run a query within a React component, call `useChooseRoomAllocationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useChooseRoomAllocationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChooseRoomAllocationListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      DateSearch: // value for 'DateSearch'
 *   },
 * });
 */
export function useChooseRoomAllocationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ChooseRoomAllocationListingQuery, ChooseRoomAllocationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ChooseRoomAllocationListingQuery, ChooseRoomAllocationListingQueryVariables>(ChooseRoomAllocationListingDocument, baseOptions);
      }
export function useChooseRoomAllocationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChooseRoomAllocationListingQuery, ChooseRoomAllocationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ChooseRoomAllocationListingQuery, ChooseRoomAllocationListingQueryVariables>(ChooseRoomAllocationListingDocument, baseOptions);
        }
export type ChooseRoomAllocationListingQueryHookResult = ReturnType<typeof useChooseRoomAllocationListingQuery>;
export type ChooseRoomAllocationListingLazyQueryHookResult = ReturnType<typeof useChooseRoomAllocationListingLazyQuery>;
export type ChooseRoomAllocationListingQueryResult = ApolloReactCommon.QueryResult<ChooseRoomAllocationListingQuery, ChooseRoomAllocationListingQueryVariables>;
export const IsChargeableDocument = gql`
    query IsChargeable($HotelID: String!) {
  IsChargeable(HotelID: $HotelID) {
    ID
    HotelID
    Chargeable
    Incidental {
      ID
      Amount
      Description
    }
  }
}
    `;

/**
 * __useIsChargeableQuery__
 *
 * To run a query within a React component, call `useIsChargeableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsChargeableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsChargeableQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useIsChargeableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsChargeableQuery, IsChargeableQueryVariables>) {
        return ApolloReactHooks.useQuery<IsChargeableQuery, IsChargeableQueryVariables>(IsChargeableDocument, baseOptions);
      }
export function useIsChargeableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsChargeableQuery, IsChargeableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsChargeableQuery, IsChargeableQueryVariables>(IsChargeableDocument, baseOptions);
        }
export type IsChargeableQueryHookResult = ReturnType<typeof useIsChargeableQuery>;
export type IsChargeableLazyQueryHookResult = ReturnType<typeof useIsChargeableLazyQuery>;
export type IsChargeableQueryResult = ApolloReactCommon.QueryResult<IsChargeableQuery, IsChargeableQueryVariables>;
export const ChooseRoomAllocationInsertDocument = gql`
    mutation ChooseRoomAllocationInsert($RoomAllocationInput: ChooseRoomAllocationInput!) {
  ChooseRoomAllocationInsert(RoomAllocationInput: $RoomAllocationInput)
}
    `;
export type ChooseRoomAllocationInsertMutationFn = ApolloReactCommon.MutationFunction<ChooseRoomAllocationInsertMutation, ChooseRoomAllocationInsertMutationVariables>;

/**
 * __useChooseRoomAllocationInsertMutation__
 *
 * To run a mutation, you first call `useChooseRoomAllocationInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseRoomAllocationInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseRoomAllocationInsertMutation, { data, loading, error }] = useChooseRoomAllocationInsertMutation({
 *   variables: {
 *      RoomAllocationInput: // value for 'RoomAllocationInput'
 *   },
 * });
 */
export function useChooseRoomAllocationInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChooseRoomAllocationInsertMutation, ChooseRoomAllocationInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ChooseRoomAllocationInsertMutation, ChooseRoomAllocationInsertMutationVariables>(ChooseRoomAllocationInsertDocument, baseOptions);
      }
export type ChooseRoomAllocationInsertMutationHookResult = ReturnType<typeof useChooseRoomAllocationInsertMutation>;
export type ChooseRoomAllocationInsertMutationResult = ApolloReactCommon.MutationResult<ChooseRoomAllocationInsertMutation>;
export type ChooseRoomAllocationInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ChooseRoomAllocationInsertMutation, ChooseRoomAllocationInsertMutationVariables>;
export const ChooseRoomAllocationUpdateDocument = gql`
    mutation ChooseRoomAllocationUpdate($RoomAllocationInput: ChooseRoomAllocationInput!, $OldEffectiveDate: DateTime!) {
  ChooseRoomAllocationUpdate(RoomAllocationInput: $RoomAllocationInput, OldEffectiveDate: $OldEffectiveDate)
}
    `;
export type ChooseRoomAllocationUpdateMutationFn = ApolloReactCommon.MutationFunction<ChooseRoomAllocationUpdateMutation, ChooseRoomAllocationUpdateMutationVariables>;

/**
 * __useChooseRoomAllocationUpdateMutation__
 *
 * To run a mutation, you first call `useChooseRoomAllocationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseRoomAllocationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseRoomAllocationUpdateMutation, { data, loading, error }] = useChooseRoomAllocationUpdateMutation({
 *   variables: {
 *      RoomAllocationInput: // value for 'RoomAllocationInput'
 *      OldEffectiveDate: // value for 'OldEffectiveDate'
 *   },
 * });
 */
export function useChooseRoomAllocationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChooseRoomAllocationUpdateMutation, ChooseRoomAllocationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ChooseRoomAllocationUpdateMutation, ChooseRoomAllocationUpdateMutationVariables>(ChooseRoomAllocationUpdateDocument, baseOptions);
      }
export type ChooseRoomAllocationUpdateMutationHookResult = ReturnType<typeof useChooseRoomAllocationUpdateMutation>;
export type ChooseRoomAllocationUpdateMutationResult = ApolloReactCommon.MutationResult<ChooseRoomAllocationUpdateMutation>;
export type ChooseRoomAllocationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ChooseRoomAllocationUpdateMutation, ChooseRoomAllocationUpdateMutationVariables>;
export const ChooseRoomAllocationDeleteDocument = gql`
    mutation ChooseRoomAllocationDelete($HotelID: String!, $AccountID: String!, $EffectiveDate: DateTime!) {
  ChooseRoomAllocationDelete(HotelID: $HotelID, AccountID: $AccountID, EffectiveDate: $EffectiveDate)
}
    `;
export type ChooseRoomAllocationDeleteMutationFn = ApolloReactCommon.MutationFunction<ChooseRoomAllocationDeleteMutation, ChooseRoomAllocationDeleteMutationVariables>;

/**
 * __useChooseRoomAllocationDeleteMutation__
 *
 * To run a mutation, you first call `useChooseRoomAllocationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseRoomAllocationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseRoomAllocationDeleteMutation, { data, loading, error }] = useChooseRoomAllocationDeleteMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *      EffectiveDate: // value for 'EffectiveDate'
 *   },
 * });
 */
export function useChooseRoomAllocationDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChooseRoomAllocationDeleteMutation, ChooseRoomAllocationDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ChooseRoomAllocationDeleteMutation, ChooseRoomAllocationDeleteMutationVariables>(ChooseRoomAllocationDeleteDocument, baseOptions);
      }
export type ChooseRoomAllocationDeleteMutationHookResult = ReturnType<typeof useChooseRoomAllocationDeleteMutation>;
export type ChooseRoomAllocationDeleteMutationResult = ApolloReactCommon.MutationResult<ChooseRoomAllocationDeleteMutation>;
export type ChooseRoomAllocationDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ChooseRoomAllocationDeleteMutation, ChooseRoomAllocationDeleteMutationVariables>;
export const DebtorTypeListingDocument = gql`
    query DebtorTypeListing($HotelID: String!) {
  DebtorTypeListing(HotelID: $HotelID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    DebtorType
    Description
    ControlAccount
    DepositAccount
    AdvanceAccount
    AccrualAccount
    RevenueAccount
  }
}
    `;

/**
 * __useDebtorTypeListingQuery__
 *
 * To run a query within a React component, call `useDebtorTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorTypeListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useDebtorTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorTypeListingQuery, DebtorTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorTypeListingQuery, DebtorTypeListingQueryVariables>(DebtorTypeListingDocument, baseOptions);
      }
export function useDebtorTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorTypeListingQuery, DebtorTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorTypeListingQuery, DebtorTypeListingQueryVariables>(DebtorTypeListingDocument, baseOptions);
        }
export type DebtorTypeListingQueryHookResult = ReturnType<typeof useDebtorTypeListingQuery>;
export type DebtorTypeListingLazyQueryHookResult = ReturnType<typeof useDebtorTypeListingLazyQuery>;
export type DebtorTypeListingQueryResult = ApolloReactCommon.QueryResult<DebtorTypeListingQuery, DebtorTypeListingQueryVariables>;
export const DebtorTypeInsertDocument = gql`
    mutation DebtorTypeInsert($input: DebtorTypeInput!) {
  DebtorTypeInsert(input: $input) {
    ID
  }
}
    `;
export type DebtorTypeInsertMutationFn = ApolloReactCommon.MutationFunction<DebtorTypeInsertMutation, DebtorTypeInsertMutationVariables>;

/**
 * __useDebtorTypeInsertMutation__
 *
 * To run a mutation, you first call `useDebtorTypeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebtorTypeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debtorTypeInsertMutation, { data, loading, error }] = useDebtorTypeInsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDebtorTypeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebtorTypeInsertMutation, DebtorTypeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DebtorTypeInsertMutation, DebtorTypeInsertMutationVariables>(DebtorTypeInsertDocument, baseOptions);
      }
export type DebtorTypeInsertMutationHookResult = ReturnType<typeof useDebtorTypeInsertMutation>;
export type DebtorTypeInsertMutationResult = ApolloReactCommon.MutationResult<DebtorTypeInsertMutation>;
export type DebtorTypeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DebtorTypeInsertMutation, DebtorTypeInsertMutationVariables>;
export const DebtorTypeUpdateDocument = gql`
    mutation DebtorTypeUpdate($input: DebtorTypeInput!, $ID: String!) {
  DebtorTypeUpdate(input: $input, ID: $ID)
}
    `;
export type DebtorTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<DebtorTypeUpdateMutation, DebtorTypeUpdateMutationVariables>;

/**
 * __useDebtorTypeUpdateMutation__
 *
 * To run a mutation, you first call `useDebtorTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebtorTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debtorTypeUpdateMutation, { data, loading, error }] = useDebtorTypeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebtorTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebtorTypeUpdateMutation, DebtorTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DebtorTypeUpdateMutation, DebtorTypeUpdateMutationVariables>(DebtorTypeUpdateDocument, baseOptions);
      }
export type DebtorTypeUpdateMutationHookResult = ReturnType<typeof useDebtorTypeUpdateMutation>;
export type DebtorTypeUpdateMutationResult = ApolloReactCommon.MutationResult<DebtorTypeUpdateMutation>;
export type DebtorTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DebtorTypeUpdateMutation, DebtorTypeUpdateMutationVariables>;
export const DebtorTypeDeleteDocument = gql`
    mutation DebtorTypeDelete($ID: String!) {
  DebtorTypeDelete(ID: $ID)
}
    `;
export type DebtorTypeDeleteMutationFn = ApolloReactCommon.MutationFunction<DebtorTypeDeleteMutation, DebtorTypeDeleteMutationVariables>;

/**
 * __useDebtorTypeDeleteMutation__
 *
 * To run a mutation, you first call `useDebtorTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDebtorTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [debtorTypeDeleteMutation, { data, loading, error }] = useDebtorTypeDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebtorTypeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DebtorTypeDeleteMutation, DebtorTypeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DebtorTypeDeleteMutation, DebtorTypeDeleteMutationVariables>(DebtorTypeDeleteDocument, baseOptions);
      }
export type DebtorTypeDeleteMutationHookResult = ReturnType<typeof useDebtorTypeDeleteMutation>;
export type DebtorTypeDeleteMutationResult = ApolloReactCommon.MutationResult<DebtorTypeDeleteMutation>;
export type DebtorTypeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DebtorTypeDeleteMutation, DebtorTypeDeleteMutationVariables>;
export const DebtorTypeInUseDocument = gql`
    query DebtorTypeInUse($HotelID: String, $ID: String) {
  DebtorTypeInUse(HotelID: $HotelID, ID: $ID)
}
    `;

/**
 * __useDebtorTypeInUseQuery__
 *
 * To run a query within a React component, call `useDebtorTypeInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorTypeInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorTypeInUseQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDebtorTypeInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorTypeInUseQuery, DebtorTypeInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorTypeInUseQuery, DebtorTypeInUseQueryVariables>(DebtorTypeInUseDocument, baseOptions);
      }
export function useDebtorTypeInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorTypeInUseQuery, DebtorTypeInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorTypeInUseQuery, DebtorTypeInUseQueryVariables>(DebtorTypeInUseDocument, baseOptions);
        }
export type DebtorTypeInUseQueryHookResult = ReturnType<typeof useDebtorTypeInUseQuery>;
export type DebtorTypeInUseLazyQueryHookResult = ReturnType<typeof useDebtorTypeInUseLazyQuery>;
export type DebtorTypeInUseQueryResult = ApolloReactCommon.QueryResult<DebtorTypeInUseQuery, DebtorTypeInUseQueryVariables>;
export const DepartmentInsertDocument = gql`
    mutation DepartmentInsert($DepartmentInput: DepartmentInput!) {
  DepartmentInsert(DepartmentInput: $DepartmentInput)
}
    `;
export type DepartmentInsertMutationFn = ApolloReactCommon.MutationFunction<DepartmentInsertMutation, DepartmentInsertMutationVariables>;

/**
 * __useDepartmentInsertMutation__
 *
 * To run a mutation, you first call `useDepartmentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentInsertMutation, { data, loading, error }] = useDepartmentInsertMutation({
 *   variables: {
 *      DepartmentInput: // value for 'DepartmentInput'
 *   },
 * });
 */
export function useDepartmentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DepartmentInsertMutation, DepartmentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<DepartmentInsertMutation, DepartmentInsertMutationVariables>(DepartmentInsertDocument, baseOptions);
      }
export type DepartmentInsertMutationHookResult = ReturnType<typeof useDepartmentInsertMutation>;
export type DepartmentInsertMutationResult = ApolloReactCommon.MutationResult<DepartmentInsertMutation>;
export type DepartmentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<DepartmentInsertMutation, DepartmentInsertMutationVariables>;
export const DepartmentUpdateDocument = gql`
    mutation DepartmentUpdate($DepartmentInput: DepartmentInput!) {
  DepartmentUpdate(DepartmentInput: $DepartmentInput)
}
    `;
export type DepartmentUpdateMutationFn = ApolloReactCommon.MutationFunction<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>;

/**
 * __useDepartmentUpdateMutation__
 *
 * To run a mutation, you first call `useDepartmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentUpdateMutation, { data, loading, error }] = useDepartmentUpdateMutation({
 *   variables: {
 *      DepartmentInput: // value for 'DepartmentInput'
 *   },
 * });
 */
export function useDepartmentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>(DepartmentUpdateDocument, baseOptions);
      }
export type DepartmentUpdateMutationHookResult = ReturnType<typeof useDepartmentUpdateMutation>;
export type DepartmentUpdateMutationResult = ApolloReactCommon.MutationResult<DepartmentUpdateMutation>;
export type DepartmentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DepartmentUpdateMutation, DepartmentUpdateMutationVariables>;
export const IncidentalChargeInUseListingDocument = gql`
    query IncidentalChargeInUseListing($ID: String!) {
  IncidentalChargeInUseListing(ID: $ID)
}
    `;

/**
 * __useIncidentalChargeInUseListingQuery__
 *
 * To run a query within a React component, call `useIncidentalChargeInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentalChargeInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentalChargeInUseListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useIncidentalChargeInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IncidentalChargeInUseListingQuery, IncidentalChargeInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<IncidentalChargeInUseListingQuery, IncidentalChargeInUseListingQueryVariables>(IncidentalChargeInUseListingDocument, baseOptions);
      }
export function useIncidentalChargeInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IncidentalChargeInUseListingQuery, IncidentalChargeInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IncidentalChargeInUseListingQuery, IncidentalChargeInUseListingQueryVariables>(IncidentalChargeInUseListingDocument, baseOptions);
        }
export type IncidentalChargeInUseListingQueryHookResult = ReturnType<typeof useIncidentalChargeInUseListingQuery>;
export type IncidentalChargeInUseListingLazyQueryHookResult = ReturnType<typeof useIncidentalChargeInUseListingLazyQuery>;
export type IncidentalChargeInUseListingQueryResult = ApolloReactCommon.QueryResult<IncidentalChargeInUseListingQuery, IncidentalChargeInUseListingQueryVariables>;
export const IncidentalChargeInRateElementDocument = gql`
    query IncidentalChargeInRateElement($HotelID: String!, $ID: String!) {
  IncidentalChargeInRateElement(HotelID: $HotelID, ID: $ID)
}
    `;

/**
 * __useIncidentalChargeInRateElementQuery__
 *
 * To run a query within a React component, call `useIncidentalChargeInRateElementQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentalChargeInRateElementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentalChargeInRateElementQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useIncidentalChargeInRateElementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IncidentalChargeInRateElementQuery, IncidentalChargeInRateElementQueryVariables>) {
        return ApolloReactHooks.useQuery<IncidentalChargeInRateElementQuery, IncidentalChargeInRateElementQueryVariables>(IncidentalChargeInRateElementDocument, baseOptions);
      }
export function useIncidentalChargeInRateElementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IncidentalChargeInRateElementQuery, IncidentalChargeInRateElementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IncidentalChargeInRateElementQuery, IncidentalChargeInRateElementQueryVariables>(IncidentalChargeInRateElementDocument, baseOptions);
        }
export type IncidentalChargeInRateElementQueryHookResult = ReturnType<typeof useIncidentalChargeInRateElementQuery>;
export type IncidentalChargeInRateElementLazyQueryHookResult = ReturnType<typeof useIncidentalChargeInRateElementLazyQuery>;
export type IncidentalChargeInRateElementQueryResult = ApolloReactCommon.QueryResult<IncidentalChargeInRateElementQuery, IncidentalChargeInRateElementQueryVariables>;
export const RateElementInactiveCheckDocument = gql`
    query RateElementInactiveCheck($HotelID: String!, $ID: String!, $Action: String!) {
  RateElementInactiveCheck(HotelID: $HotelID, ID: $ID, Action: $Action)
}
    `;

/**
 * __useRateElementInactiveCheckQuery__
 *
 * To run a query within a React component, call `useRateElementInactiveCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateElementInactiveCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateElementInactiveCheckQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ID: // value for 'ID'
 *      Action: // value for 'Action'
 *   },
 * });
 */
export function useRateElementInactiveCheckQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RateElementInactiveCheckQuery, RateElementInactiveCheckQueryVariables>) {
        return ApolloReactHooks.useQuery<RateElementInactiveCheckQuery, RateElementInactiveCheckQueryVariables>(RateElementInactiveCheckDocument, baseOptions);
      }
export function useRateElementInactiveCheckLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RateElementInactiveCheckQuery, RateElementInactiveCheckQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RateElementInactiveCheckQuery, RateElementInactiveCheckQueryVariables>(RateElementInactiveCheckDocument, baseOptions);
        }
export type RateElementInactiveCheckQueryHookResult = ReturnType<typeof useRateElementInactiveCheckQuery>;
export type RateElementInactiveCheckLazyQueryHookResult = ReturnType<typeof useRateElementInactiveCheckLazyQuery>;
export type RateElementInactiveCheckQueryResult = ApolloReactCommon.QueryResult<RateElementInactiveCheckQuery, RateElementInactiveCheckQueryVariables>;
export const DeparmentCheckingDocument = gql`
    query DeparmentChecking($HotelID: String!, $DepartmentID: String!) {
  DeparmentChecking(HotelID: $HotelID, DepartmentID: $DepartmentID)
}
    `;

/**
 * __useDeparmentCheckingQuery__
 *
 * To run a query within a React component, call `useDeparmentCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeparmentCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeparmentCheckingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useDeparmentCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeparmentCheckingQuery, DeparmentCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<DeparmentCheckingQuery, DeparmentCheckingQueryVariables>(DeparmentCheckingDocument, baseOptions);
      }
export function useDeparmentCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeparmentCheckingQuery, DeparmentCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeparmentCheckingQuery, DeparmentCheckingQueryVariables>(DeparmentCheckingDocument, baseOptions);
        }
export type DeparmentCheckingQueryHookResult = ReturnType<typeof useDeparmentCheckingQuery>;
export type DeparmentCheckingLazyQueryHookResult = ReturnType<typeof useDeparmentCheckingLazyQuery>;
export type DeparmentCheckingQueryResult = ApolloReactCommon.QueryResult<DeparmentCheckingQuery, DeparmentCheckingQueryVariables>;
export const DepartmentDeleteDocument = gql`
    mutation DepartmentDelete($ID: String!) {
  DepartmentDelete(ID: $ID)
}
    `;
export type DepartmentDeleteMutationFn = ApolloReactCommon.MutationFunction<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>;

/**
 * __useDepartmentDeleteMutation__
 *
 * To run a mutation, you first call `useDepartmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDepartmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [departmentDeleteMutation, { data, loading, error }] = useDepartmentDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDepartmentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>(DepartmentDeleteDocument, baseOptions);
      }
export type DepartmentDeleteMutationHookResult = ReturnType<typeof useDepartmentDeleteMutation>;
export type DepartmentDeleteMutationResult = ApolloReactCommon.MutationResult<DepartmentDeleteMutation>;
export type DepartmentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<DepartmentDeleteMutation, DepartmentDeleteMutationVariables>;
export const IncidentalChargesUpdateDocument = gql`
    mutation IncidentalChargesUpdate($ID: String!, $status: Boolean!) {
  IncidentalChargesUpdate(ID: $ID, status: $status)
}
    `;
export type IncidentalChargesUpdateMutationFn = ApolloReactCommon.MutationFunction<IncidentalChargesUpdateMutation, IncidentalChargesUpdateMutationVariables>;

/**
 * __useIncidentalChargesUpdateMutation__
 *
 * To run a mutation, you first call `useIncidentalChargesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncidentalChargesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incidentalChargesUpdateMutation, { data, loading, error }] = useIncidentalChargesUpdateMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useIncidentalChargesUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IncidentalChargesUpdateMutation, IncidentalChargesUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<IncidentalChargesUpdateMutation, IncidentalChargesUpdateMutationVariables>(IncidentalChargesUpdateDocument, baseOptions);
      }
export type IncidentalChargesUpdateMutationHookResult = ReturnType<typeof useIncidentalChargesUpdateMutation>;
export type IncidentalChargesUpdateMutationResult = ApolloReactCommon.MutationResult<IncidentalChargesUpdateMutation>;
export type IncidentalChargesUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<IncidentalChargesUpdateMutation, IncidentalChargesUpdateMutationVariables>;
export const EDocTemplateListingDocument = gql`
    query EDocTemplateListing($HotelID: String!) {
  EDocTemplateListing(HotelID: $HotelID) {
    ID
    HotelID
    AccountID
    TemplateBody
    DocType
    CreatedBy
    CreatedDT
    ModifiedBy
    ModifiedDT
  }
}
    `;

/**
 * __useEDocTemplateListingQuery__
 *
 * To run a query within a React component, call `useEDocTemplateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEDocTemplateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEDocTemplateListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useEDocTemplateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EDocTemplateListingQuery, EDocTemplateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EDocTemplateListingQuery, EDocTemplateListingQueryVariables>(EDocTemplateListingDocument, baseOptions);
      }
export function useEDocTemplateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EDocTemplateListingQuery, EDocTemplateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EDocTemplateListingQuery, EDocTemplateListingQueryVariables>(EDocTemplateListingDocument, baseOptions);
        }
export type EDocTemplateListingQueryHookResult = ReturnType<typeof useEDocTemplateListingQuery>;
export type EDocTemplateListingLazyQueryHookResult = ReturnType<typeof useEDocTemplateListingLazyQuery>;
export type EDocTemplateListingQueryResult = ApolloReactCommon.QueryResult<EDocTemplateListingQuery, EDocTemplateListingQueryVariables>;
export const ConfirmationLetterListingDocument = gql`
    query ConfirmationLetterListing($HotelID: String!, $AccountID: String!) {
  ConfirmationLetterListing(HotelID: $HotelID, AccountID: $AccountID) {
    ID
    IsDefault
    TemplateTitle
    HotelID
    AccountID
    TemplateBody
    DocType
    CreatedBy
    CreatedDT
    ModifiedBy
    ModifiedDT
  }
}
    `;

/**
 * __useConfirmationLetterListingQuery__
 *
 * To run a query within a React component, call `useConfirmationLetterListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmationLetterListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmationLetterListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useConfirmationLetterListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConfirmationLetterListingQuery, ConfirmationLetterListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ConfirmationLetterListingQuery, ConfirmationLetterListingQueryVariables>(ConfirmationLetterListingDocument, baseOptions);
      }
export function useConfirmationLetterListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfirmationLetterListingQuery, ConfirmationLetterListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConfirmationLetterListingQuery, ConfirmationLetterListingQueryVariables>(ConfirmationLetterListingDocument, baseOptions);
        }
export type ConfirmationLetterListingQueryHookResult = ReturnType<typeof useConfirmationLetterListingQuery>;
export type ConfirmationLetterListingLazyQueryHookResult = ReturnType<typeof useConfirmationLetterListingLazyQuery>;
export type ConfirmationLetterListingQueryResult = ApolloReactCommon.QueryResult<ConfirmationLetterListingQuery, ConfirmationLetterListingQueryVariables>;
export const EDocParamsListingDocument = gql`
    query EDocParamsListing($DocType: String!) {
  EDocParamsListing(DocType: $DocType) {
    ID
    DocType
    ColName
  }
}
    `;

/**
 * __useEDocParamsListingQuery__
 *
 * To run a query within a React component, call `useEDocParamsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEDocParamsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEDocParamsListingQuery({
 *   variables: {
 *      DocType: // value for 'DocType'
 *   },
 * });
 */
export function useEDocParamsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EDocParamsListingQuery, EDocParamsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<EDocParamsListingQuery, EDocParamsListingQueryVariables>(EDocParamsListingDocument, baseOptions);
      }
export function useEDocParamsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EDocParamsListingQuery, EDocParamsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EDocParamsListingQuery, EDocParamsListingQueryVariables>(EDocParamsListingDocument, baseOptions);
        }
export type EDocParamsListingQueryHookResult = ReturnType<typeof useEDocParamsListingQuery>;
export type EDocParamsListingLazyQueryHookResult = ReturnType<typeof useEDocParamsListingLazyQuery>;
export type EDocParamsListingQueryResult = ApolloReactCommon.QueryResult<EDocParamsListingQuery, EDocParamsListingQueryVariables>;
export const EDocInsertDocument = gql`
    mutation EDocInsert($EDocTemplateInput: EDocTemplateInput!) {
  EDocInsert(EDocTemplateInput: $EDocTemplateInput)
}
    `;
export type EDocInsertMutationFn = ApolloReactCommon.MutationFunction<EDocInsertMutation, EDocInsertMutationVariables>;

/**
 * __useEDocInsertMutation__
 *
 * To run a mutation, you first call `useEDocInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEDocInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eDocInsertMutation, { data, loading, error }] = useEDocInsertMutation({
 *   variables: {
 *      EDocTemplateInput: // value for 'EDocTemplateInput'
 *   },
 * });
 */
export function useEDocInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EDocInsertMutation, EDocInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<EDocInsertMutation, EDocInsertMutationVariables>(EDocInsertDocument, baseOptions);
      }
export type EDocInsertMutationHookResult = ReturnType<typeof useEDocInsertMutation>;
export type EDocInsertMutationResult = ApolloReactCommon.MutationResult<EDocInsertMutation>;
export type EDocInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<EDocInsertMutation, EDocInsertMutationVariables>;
export const EDocTemplateUpdateDocument = gql`
    mutation EDocTemplateUpdate($EDocTemplateInput: EDocTemplateInput!) {
  EDocTemplateUpdate(EDocTemplateInput: $EDocTemplateInput)
}
    `;
export type EDocTemplateUpdateMutationFn = ApolloReactCommon.MutationFunction<EDocTemplateUpdateMutation, EDocTemplateUpdateMutationVariables>;

/**
 * __useEDocTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useEDocTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEDocTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eDocTemplateUpdateMutation, { data, loading, error }] = useEDocTemplateUpdateMutation({
 *   variables: {
 *      EDocTemplateInput: // value for 'EDocTemplateInput'
 *   },
 * });
 */
export function useEDocTemplateUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EDocTemplateUpdateMutation, EDocTemplateUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<EDocTemplateUpdateMutation, EDocTemplateUpdateMutationVariables>(EDocTemplateUpdateDocument, baseOptions);
      }
export type EDocTemplateUpdateMutationHookResult = ReturnType<typeof useEDocTemplateUpdateMutation>;
export type EDocTemplateUpdateMutationResult = ApolloReactCommon.MutationResult<EDocTemplateUpdateMutation>;
export type EDocTemplateUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<EDocTemplateUpdateMutation, EDocTemplateUpdateMutationVariables>;
export const ConfirmationTemplateDeleteDocument = gql`
    mutation ConfirmationTemplateDelete($ID: String!, $HotelID: String!, $AccountID: String!) {
  ConfirmationTemplateDelete(ID: $ID, HotelID: $HotelID, AccountID: $AccountID)
}
    `;
export type ConfirmationTemplateDeleteMutationFn = ApolloReactCommon.MutationFunction<ConfirmationTemplateDeleteMutation, ConfirmationTemplateDeleteMutationVariables>;

/**
 * __useConfirmationTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useConfirmationTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmationTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmationTemplateDeleteMutation, { data, loading, error }] = useConfirmationTemplateDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useConfirmationTemplateDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmationTemplateDeleteMutation, ConfirmationTemplateDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfirmationTemplateDeleteMutation, ConfirmationTemplateDeleteMutationVariables>(ConfirmationTemplateDeleteDocument, baseOptions);
      }
export type ConfirmationTemplateDeleteMutationHookResult = ReturnType<typeof useConfirmationTemplateDeleteMutation>;
export type ConfirmationTemplateDeleteMutationResult = ApolloReactCommon.MutationResult<ConfirmationTemplateDeleteMutation>;
export type ConfirmationTemplateDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfirmationTemplateDeleteMutation, ConfirmationTemplateDeleteMutationVariables>;
export const GuestappsettingDetailDocument = gql`
    query GuestappsettingDetail($HotelID: String!) {
  GuestappsettingDetail(HotelID: $HotelID)
}
    `;

/**
 * __useGuestappsettingDetailQuery__
 *
 * To run a query within a React component, call `useGuestappsettingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestappsettingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestappsettingDetailQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useGuestappsettingDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GuestappsettingDetailQuery, GuestappsettingDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GuestappsettingDetailQuery, GuestappsettingDetailQueryVariables>(GuestappsettingDetailDocument, baseOptions);
      }
export function useGuestappsettingDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestappsettingDetailQuery, GuestappsettingDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GuestappsettingDetailQuery, GuestappsettingDetailQueryVariables>(GuestappsettingDetailDocument, baseOptions);
        }
export type GuestappsettingDetailQueryHookResult = ReturnType<typeof useGuestappsettingDetailQuery>;
export type GuestappsettingDetailLazyQueryHookResult = ReturnType<typeof useGuestappsettingDetailLazyQuery>;
export type GuestappsettingDetailQueryResult = ApolloReactCommon.QueryResult<GuestappsettingDetailQuery, GuestappsettingDetailQueryVariables>;
export const GuestAppSetupUpdateDocument = gql`
    mutation GuestAppSetupUpdate($GuestAppSettingInput: GuestAppSettingInput!) {
  GuestAppSetupUpdate(GuestAppSettingInput: $GuestAppSettingInput)
}
    `;
export type GuestAppSetupUpdateMutationFn = ApolloReactCommon.MutationFunction<GuestAppSetupUpdateMutation, GuestAppSetupUpdateMutationVariables>;

/**
 * __useGuestAppSetupUpdateMutation__
 *
 * To run a mutation, you first call `useGuestAppSetupUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestAppSetupUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestAppSetupUpdateMutation, { data, loading, error }] = useGuestAppSetupUpdateMutation({
 *   variables: {
 *      GuestAppSettingInput: // value for 'GuestAppSettingInput'
 *   },
 * });
 */
export function useGuestAppSetupUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GuestAppSetupUpdateMutation, GuestAppSetupUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<GuestAppSetupUpdateMutation, GuestAppSetupUpdateMutationVariables>(GuestAppSetupUpdateDocument, baseOptions);
      }
export type GuestAppSetupUpdateMutationHookResult = ReturnType<typeof useGuestAppSetupUpdateMutation>;
export type GuestAppSetupUpdateMutationResult = ApolloReactCommon.MutationResult<GuestAppSetupUpdateMutation>;
export type GuestAppSetupUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<GuestAppSetupUpdateMutation, GuestAppSetupUpdateMutationVariables>;
export const HotelDetailsDocument = gql`
    query HotelDetails($HotelID: String, $OnlineBookingURL: String) {
  HotelDetails(HotelID: $HotelID, OnlineBookingURL: $OnlineBookingURL) {
    ID
    IsActive
    CreatedBy
    ModifiedBy
    EmailBlastDays
    Currency
    AccountID
    CompanyID
    MSICCode
    MSICDesc
    EInvEffectiveDate
    EInvoiceSCClassification
    EInvoiceRCClassification
    EInvoiceAdvPaymentClassification
    EInvoiceDepositForfeitClassification
    EInvoiceStateTaxClassification
    Company {
      id
      name
      code
      isEInvIntegrated
      eInvStartDate
      eInvEndDate
      sstNo
      companyRegNo
      ttxNo
    }
    HotelAddress {
      address
      postCode
      state
      city
      country
    }
    StateFund
    HotelAdjustmentIncidentalID
    SSTRegNo
    TTxRegNo
    CompanyName
    CompanyRegNo
    Logo
    HotelType
    HotelName
    HotelCode
    ContactID
    CheckInTime
    CheckOutTime
    Currency
    NAProcessTime
    IsPostPaid
    Tax
    ServiceCharge
    IsInclusive
    IsRoomTaxable
    IsCmsAllotment
    IsHouseUse
    IsDayUse
    IsComplimentary
    IsOOO
    IsOOI
    IsFloorPlan
    IsChargeToRoom
    IsOnlinePayment
    IsOnlineBooking
    TTx
    HTx
    Levy
    CheckInPrompt
    SecurityDeposit
    KeycardDeposit
    KeycardVendor
    CheckInRoomStatusID
    CancelCheckInRoomStatusID
    TransferRoomStatusID
    CheckOutRoomStatusID
    CancelCheckOutRoomStatusID
    BlockRoomReleaseStatusID
    CeilingPercentage
    FloorPercentage
    DayUseID
    HouseUseID
    ComplimentaryID
    FloorPriceKickInDays
    AllowCancellationDays
    OnlineTnC
    OnlinePaymentTnC
    DocumentRemark
    RegCardTnC
    OnlineBookingURL
    AboutHotelGalleryID
    TaxSchemeID
    AdvanceForfeitTaxID
    DepositForfeitTaxID
    AboutHotel
    Q3StatisticsMapping {
      RoomsAvailable {
        Name
        Debit
        Analysis
        Department
      }
      TotalRoomOccupied {
        Name
        Debit
        Analysis
        Department
      }
      RoomOccupiedWithComplimentary {
        Name
        Debit
        Analysis
        Department
      }
      ComplimentaryRoom {
        Name
        Debit
        Analysis
        Department
      }
      HouseUse {
        Name
        Debit
        Analysis
        Department
      }
      NoOfRoomGuests {
        Name
        Debit
        Analysis
        Department
      }
      NoOfShowRoom {
        Name
        Debit
        Analysis
        Department
      }
      TotalRooms {
        Name
        Debit
        Analysis
        Department
      }
      OutOfOrderRoom {
        Name
        Debit
        Analysis
        Department
      }
      CancellationRoom {
        Name
        Debit
        Analysis
        Department
      }
      DayUseRoom {
        Name
        Debit
        Analysis
        Department
      }
    }
    ARSpecification {
      InterestAccount
    }
    ChannelManagerID
    PaymentGatewayID
    Contact {
      FullName
      PhoneNo
      MobileNo
      GeneralEmail
      ReservationEmail
      BusinessEmail
      WebUrl
      NotificationMobileNo
      FaxNo
    }
    Facility {
      ID
      HotelID
      IsActive
      Title
      Description
      Level
      FacilityGallery {
        ID
        FacilityID
        GalleryID
        Gallery {
          ID
          ImageURL
          BucketFileName
        }
      }
    }
    Encoders
    HotelGallery {
      ID
      HotelID
      IsDefault
      GalleryID
      Gallery {
        ID
        ImageURL
        BucketFileName
      }
    }
    News {
      ID
      IsActive
      Description
      Title
      NewsGallery {
        ID
        Gallery {
          ID
          ImageURL
          BucketFileName
        }
      }
      ID
      HotelID
      Title
      Description
      IsActive
    }
    DayUse {
      ID
      RateCode
      Description
    }
    HouseUse {
      ID
      RateCode
      Description
    }
    Complimentary {
      ID
      RateCode
      Description
    }
    AboutHotelImage {
      ID
      ImageURL
      BucketFileName
    }
    LogoImage {
      ID
      ImageURL
      BucketFileName
    }
    SOAImage {
      ID
      ImageURL
      BucketFileName
    }
    Encoders
    RegulationClause
    PDPA
    GuestNotice
  }
}
    `;

/**
 * __useHotelDetailsQuery__
 *
 * To run a query within a React component, call `useHotelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelDetailsQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      OnlineBookingURL: // value for 'OnlineBookingURL'
 *   },
 * });
 */
export function useHotelDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelDetailsQuery, HotelDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelDetailsQuery, HotelDetailsQueryVariables>(HotelDetailsDocument, baseOptions);
      }
export function useHotelDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelDetailsQuery, HotelDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelDetailsQuery, HotelDetailsQueryVariables>(HotelDetailsDocument, baseOptions);
        }
export type HotelDetailsQueryHookResult = ReturnType<typeof useHotelDetailsQuery>;
export type HotelDetailsLazyQueryHookResult = ReturnType<typeof useHotelDetailsLazyQuery>;
export type HotelDetailsQueryResult = ApolloReactCommon.QueryResult<HotelDetailsQuery, HotelDetailsQueryVariables>;
export const HotelPreRegQrDocument = gql`
    query HotelPreRegQr($HotelID: String!) {
  HotelPreRegQr(HotelID: $HotelID)
}
    `;

/**
 * __useHotelPreRegQrQuery__
 *
 * To run a query within a React component, call `useHotelPreRegQrQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelPreRegQrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelPreRegQrQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useHotelPreRegQrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelPreRegQrQuery, HotelPreRegQrQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelPreRegQrQuery, HotelPreRegQrQueryVariables>(HotelPreRegQrDocument, baseOptions);
      }
export function useHotelPreRegQrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelPreRegQrQuery, HotelPreRegQrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelPreRegQrQuery, HotelPreRegQrQueryVariables>(HotelPreRegQrDocument, baseOptions);
        }
export type HotelPreRegQrQueryHookResult = ReturnType<typeof useHotelPreRegQrQuery>;
export type HotelPreRegQrLazyQueryHookResult = ReturnType<typeof useHotelPreRegQrLazyQuery>;
export type HotelPreRegQrQueryResult = ApolloReactCommon.QueryResult<HotelPreRegQrQuery, HotelPreRegQrQueryVariables>;
export const InvoiceRemarkDocument = gql`
    mutation InvoiceRemark($InvoiceRemark: String, $AccountID: String, $HotelID: String!) {
  InvoiceRemark(InvoiceRemark: $InvoiceRemark, AccountID: $AccountID, HotelID: $HotelID)
}
    `;
export type InvoiceRemarkMutationFn = ApolloReactCommon.MutationFunction<InvoiceRemarkMutation, InvoiceRemarkMutationVariables>;

/**
 * __useInvoiceRemarkMutation__
 *
 * To run a mutation, you first call `useInvoiceRemarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceRemarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceRemarkMutation, { data, loading, error }] = useInvoiceRemarkMutation({
 *   variables: {
 *      InvoiceRemark: // value for 'InvoiceRemark'
 *      AccountID: // value for 'AccountID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useInvoiceRemarkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceRemarkMutation, InvoiceRemarkMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceRemarkMutation, InvoiceRemarkMutationVariables>(InvoiceRemarkDocument, baseOptions);
      }
export type InvoiceRemarkMutationHookResult = ReturnType<typeof useInvoiceRemarkMutation>;
export type InvoiceRemarkMutationResult = ApolloReactCommon.MutationResult<InvoiceRemarkMutation>;
export type InvoiceRemarkMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceRemarkMutation, InvoiceRemarkMutationVariables>;
export const HotelUpdateDocument = gql`
    mutation HotelUpdate($HotelInput: HotelInput!, $AttachmentInput: [Upload!], $Type: String, $OldGalleryID: [String!]) {
  HotelUpdate(HotelInput: $HotelInput, AttachmentInput: $AttachmentInput, Type: $Type, OldGalleryID: $OldGalleryID)
}
    `;
export type HotelUpdateMutationFn = ApolloReactCommon.MutationFunction<HotelUpdateMutation, HotelUpdateMutationVariables>;

/**
 * __useHotelUpdateMutation__
 *
 * To run a mutation, you first call `useHotelUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelUpdateMutation, { data, loading, error }] = useHotelUpdateMutation({
 *   variables: {
 *      HotelInput: // value for 'HotelInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      Type: // value for 'Type'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useHotelUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelUpdateMutation, HotelUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelUpdateMutation, HotelUpdateMutationVariables>(HotelUpdateDocument, baseOptions);
      }
export type HotelUpdateMutationHookResult = ReturnType<typeof useHotelUpdateMutation>;
export type HotelUpdateMutationResult = ApolloReactCommon.MutationResult<HotelUpdateMutation>;
export type HotelUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelUpdateMutation, HotelUpdateMutationVariables>;
export const HotelInsertDocument = gql`
    mutation HotelInsert($HotelInput: HotelInput!, $AttachmentInput: [Upload!], $Type: String) {
  HotelInsert(HotelInput: $HotelInput, AttachmentInput: $AttachmentInput, Type: $Type) {
    ID
  }
}
    `;
export type HotelInsertMutationFn = ApolloReactCommon.MutationFunction<HotelInsertMutation, HotelInsertMutationVariables>;

/**
 * __useHotelInsertMutation__
 *
 * To run a mutation, you first call `useHotelInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelInsertMutation, { data, loading, error }] = useHotelInsertMutation({
 *   variables: {
 *      HotelInput: // value for 'HotelInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useHotelInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelInsertMutation, HotelInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelInsertMutation, HotelInsertMutationVariables>(HotelInsertDocument, baseOptions);
      }
export type HotelInsertMutationHookResult = ReturnType<typeof useHotelInsertMutation>;
export type HotelInsertMutationResult = ApolloReactCommon.MutationResult<HotelInsertMutation>;
export type HotelInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelInsertMutation, HotelInsertMutationVariables>;
export const HotelContactUpdateDocument = gql`
    mutation HotelContactUpdate($ContactInput: ContactInput!) {
  HotelContactUpdate(ContactInput: $ContactInput)
}
    `;
export type HotelContactUpdateMutationFn = ApolloReactCommon.MutationFunction<HotelContactUpdateMutation, HotelContactUpdateMutationVariables>;

/**
 * __useHotelContactUpdateMutation__
 *
 * To run a mutation, you first call `useHotelContactUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelContactUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelContactUpdateMutation, { data, loading, error }] = useHotelContactUpdateMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *   },
 * });
 */
export function useHotelContactUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelContactUpdateMutation, HotelContactUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelContactUpdateMutation, HotelContactUpdateMutationVariables>(HotelContactUpdateDocument, baseOptions);
      }
export type HotelContactUpdateMutationHookResult = ReturnType<typeof useHotelContactUpdateMutation>;
export type HotelContactUpdateMutationResult = ApolloReactCommon.MutationResult<HotelContactUpdateMutation>;
export type HotelContactUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelContactUpdateMutation, HotelContactUpdateMutationVariables>;
export const HotelContactInsertDocument = gql`
    mutation HotelContactInsert($ContactInput: ContactInput!, $HotelID: String!) {
  HotelContactInsert(ContactInput: $ContactInput, HotelID: $HotelID) {
    ID
  }
}
    `;
export type HotelContactInsertMutationFn = ApolloReactCommon.MutationFunction<HotelContactInsertMutation, HotelContactInsertMutationVariables>;

/**
 * __useHotelContactInsertMutation__
 *
 * To run a mutation, you first call `useHotelContactInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelContactInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelContactInsertMutation, { data, loading, error }] = useHotelContactInsertMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useHotelContactInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelContactInsertMutation, HotelContactInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelContactInsertMutation, HotelContactInsertMutationVariables>(HotelContactInsertDocument, baseOptions);
      }
export type HotelContactInsertMutationHookResult = ReturnType<typeof useHotelContactInsertMutation>;
export type HotelContactInsertMutationResult = ApolloReactCommon.MutationResult<HotelContactInsertMutation>;
export type HotelContactInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelContactInsertMutation, HotelContactInsertMutationVariables>;
export const HotelFacilityUpdateDocument = gql`
    mutation HotelFacilityUpdate($FacilityInput: FacilityInput!, $OldGalleryID: [String!], $AttachmentInput: [Upload!]) {
  HotelFacilityUpdate(FacilityInput: $FacilityInput, OldGalleryID: $OldGalleryID, AttachmentInput: $AttachmentInput)
}
    `;
export type HotelFacilityUpdateMutationFn = ApolloReactCommon.MutationFunction<HotelFacilityUpdateMutation, HotelFacilityUpdateMutationVariables>;

/**
 * __useHotelFacilityUpdateMutation__
 *
 * To run a mutation, you first call `useHotelFacilityUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelFacilityUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelFacilityUpdateMutation, { data, loading, error }] = useHotelFacilityUpdateMutation({
 *   variables: {
 *      FacilityInput: // value for 'FacilityInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useHotelFacilityUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelFacilityUpdateMutation, HotelFacilityUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelFacilityUpdateMutation, HotelFacilityUpdateMutationVariables>(HotelFacilityUpdateDocument, baseOptions);
      }
export type HotelFacilityUpdateMutationHookResult = ReturnType<typeof useHotelFacilityUpdateMutation>;
export type HotelFacilityUpdateMutationResult = ApolloReactCommon.MutationResult<HotelFacilityUpdateMutation>;
export type HotelFacilityUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelFacilityUpdateMutation, HotelFacilityUpdateMutationVariables>;
export const FacilitiesInactiveDeleteDocument = gql`
    mutation FacilitiesInactiveDelete($isInactive: Boolean!, $id: String!) {
  FacilitiesInactiveDelete(isInactive: $isInactive, id: $id)
}
    `;
export type FacilitiesInactiveDeleteMutationFn = ApolloReactCommon.MutationFunction<FacilitiesInactiveDeleteMutation, FacilitiesInactiveDeleteMutationVariables>;

/**
 * __useFacilitiesInactiveDeleteMutation__
 *
 * To run a mutation, you first call `useFacilitiesInactiveDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacilitiesInactiveDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facilitiesInactiveDeleteMutation, { data, loading, error }] = useFacilitiesInactiveDeleteMutation({
 *   variables: {
 *      isInactive: // value for 'isInactive'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFacilitiesInactiveDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FacilitiesInactiveDeleteMutation, FacilitiesInactiveDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<FacilitiesInactiveDeleteMutation, FacilitiesInactiveDeleteMutationVariables>(FacilitiesInactiveDeleteDocument, baseOptions);
      }
export type FacilitiesInactiveDeleteMutationHookResult = ReturnType<typeof useFacilitiesInactiveDeleteMutation>;
export type FacilitiesInactiveDeleteMutationResult = ApolloReactCommon.MutationResult<FacilitiesInactiveDeleteMutation>;
export type FacilitiesInactiveDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<FacilitiesInactiveDeleteMutation, FacilitiesInactiveDeleteMutationVariables>;
export const HotelFacilityInsertDocument = gql`
    mutation HotelFacilityInsert($FacilityInput: FacilityInput!, $AttachmentInput: [Upload!]) {
  HotelFacilityInsert(FacilityInput: $FacilityInput, AttachmentInput: $AttachmentInput) {
    ID
  }
}
    `;
export type HotelFacilityInsertMutationFn = ApolloReactCommon.MutationFunction<HotelFacilityInsertMutation, HotelFacilityInsertMutationVariables>;

/**
 * __useHotelFacilityInsertMutation__
 *
 * To run a mutation, you first call `useHotelFacilityInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelFacilityInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelFacilityInsertMutation, { data, loading, error }] = useHotelFacilityInsertMutation({
 *   variables: {
 *      FacilityInput: // value for 'FacilityInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useHotelFacilityInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelFacilityInsertMutation, HotelFacilityInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelFacilityInsertMutation, HotelFacilityInsertMutationVariables>(HotelFacilityInsertDocument, baseOptions);
      }
export type HotelFacilityInsertMutationHookResult = ReturnType<typeof useHotelFacilityInsertMutation>;
export type HotelFacilityInsertMutationResult = ApolloReactCommon.MutationResult<HotelFacilityInsertMutation>;
export type HotelFacilityInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelFacilityInsertMutation, HotelFacilityInsertMutationVariables>;
export const HotelNewsUpdateDocument = gql`
    mutation HotelNewsUpdate($NewsInput: NewsInput!, $AttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  HotelNewsUpdate(NewsInput: $NewsInput, AttachmentInput: $AttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type HotelNewsUpdateMutationFn = ApolloReactCommon.MutationFunction<HotelNewsUpdateMutation, HotelNewsUpdateMutationVariables>;

/**
 * __useHotelNewsUpdateMutation__
 *
 * To run a mutation, you first call `useHotelNewsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelNewsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelNewsUpdateMutation, { data, loading, error }] = useHotelNewsUpdateMutation({
 *   variables: {
 *      NewsInput: // value for 'NewsInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useHotelNewsUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelNewsUpdateMutation, HotelNewsUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelNewsUpdateMutation, HotelNewsUpdateMutationVariables>(HotelNewsUpdateDocument, baseOptions);
      }
export type HotelNewsUpdateMutationHookResult = ReturnType<typeof useHotelNewsUpdateMutation>;
export type HotelNewsUpdateMutationResult = ApolloReactCommon.MutationResult<HotelNewsUpdateMutation>;
export type HotelNewsUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelNewsUpdateMutation, HotelNewsUpdateMutationVariables>;
export const HotelNewsInsertDocument = gql`
    mutation HotelNewsInsert($NewsInput: NewsInput!, $AttachmentInput: [Upload!]) {
  HotelNewsInsert(NewsInput: $NewsInput, AttachmentInput: $AttachmentInput) {
    ID
    Title
    Description
  }
}
    `;
export type HotelNewsInsertMutationFn = ApolloReactCommon.MutationFunction<HotelNewsInsertMutation, HotelNewsInsertMutationVariables>;

/**
 * __useHotelNewsInsertMutation__
 *
 * To run a mutation, you first call `useHotelNewsInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelNewsInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelNewsInsertMutation, { data, loading, error }] = useHotelNewsInsertMutation({
 *   variables: {
 *      NewsInput: // value for 'NewsInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useHotelNewsInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelNewsInsertMutation, HotelNewsInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelNewsInsertMutation, HotelNewsInsertMutationVariables>(HotelNewsInsertDocument, baseOptions);
      }
export type HotelNewsInsertMutationHookResult = ReturnType<typeof useHotelNewsInsertMutation>;
export type HotelNewsInsertMutationResult = ApolloReactCommon.MutationResult<HotelNewsInsertMutation>;
export type HotelNewsInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelNewsInsertMutation, HotelNewsInsertMutationVariables>;
export const GetMsicCodeListingDocument = gql`
    query getMsicCodeListing {
  getMsicCodeListing {
    id
    msicCode
    description
    categoryReference
  }
}
    `;

/**
 * __useGetMsicCodeListingQuery__
 *
 * To run a query within a React component, call `useGetMsicCodeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMsicCodeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMsicCodeListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMsicCodeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>(GetMsicCodeListingDocument, baseOptions);
      }
export function useGetMsicCodeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>(GetMsicCodeListingDocument, baseOptions);
        }
export type GetMsicCodeListingQueryHookResult = ReturnType<typeof useGetMsicCodeListingQuery>;
export type GetMsicCodeListingLazyQueryHookResult = ReturnType<typeof useGetMsicCodeListingLazyQuery>;
export type GetMsicCodeListingQueryResult = ApolloReactCommon.QueryResult<GetMsicCodeListingQuery, GetMsicCodeListingQueryVariables>;
export const GetGeneralTinDocument = gql`
    query getGeneralTin {
  getGeneralTin {
    id
    tinParty
    tin
  }
}
    `;

/**
 * __useGetGeneralTinQuery__
 *
 * To run a query within a React component, call `useGetGeneralTinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralTinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralTinQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralTinQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralTinQuery, GetGeneralTinQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralTinQuery, GetGeneralTinQueryVariables>(GetGeneralTinDocument, baseOptions);
      }
export function useGetGeneralTinLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralTinQuery, GetGeneralTinQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralTinQuery, GetGeneralTinQueryVariables>(GetGeneralTinDocument, baseOptions);
        }
export type GetGeneralTinQueryHookResult = ReturnType<typeof useGetGeneralTinQuery>;
export type GetGeneralTinLazyQueryHookResult = ReturnType<typeof useGetGeneralTinLazyQuery>;
export type GetGeneralTinQueryResult = ApolloReactCommon.QueryResult<GetGeneralTinQuery, GetGeneralTinQueryVariables>;
export const GetFolioInfoDocument = gql`
    query getFolioInfo($HotelID: String!, $DocType: String!, $FolioID: String!) {
  getFolioInfo(HotelID: $HotelID, DocType: $DocType, FolioID: $FolioID) {
    ID
    DocStatus
    DocAmt
    DocNo
    DocDate
    HotelID
    QrCodeUrl
  }
}
    `;

/**
 * __useGetFolioInfoQuery__
 *
 * To run a query within a React component, call `useGetFolioInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFolioInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFolioInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DocType: // value for 'DocType'
 *      FolioID: // value for 'FolioID'
 *   },
 * });
 */
export function useGetFolioInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetFolioInfoQuery, GetFolioInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetFolioInfoQuery, GetFolioInfoQueryVariables>(GetFolioInfoDocument, baseOptions);
      }
export function useGetFolioInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetFolioInfoQuery, GetFolioInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetFolioInfoQuery, GetFolioInfoQueryVariables>(GetFolioInfoDocument, baseOptions);
        }
export type GetFolioInfoQueryHookResult = ReturnType<typeof useGetFolioInfoQuery>;
export type GetFolioInfoLazyQueryHookResult = ReturnType<typeof useGetFolioInfoLazyQuery>;
export type GetFolioInfoQueryResult = ApolloReactCommon.QueryResult<GetFolioInfoQuery, GetFolioInfoQueryVariables>;
export const IncidentalChargeListingDocument = gql`
    query IncidentalChargeListing($DepartmentID: String!) {
  IncidentalChargeListing(DepartmentID: $DepartmentID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    DepartmentID
    Description
    Amount
    Tax
    ServiceCharge
    IsFnB
    IsEShop
    IsRoomRevenue
    IsOutlet
    IsEventInterface
    IsNegative
    IsPackage
    OutletCode
    GalleryID
    IsRateElement
    IsKiosk
    TaxSchemeID
    EInvClassification
    isEInvIntegrated
    MSICCode
    Gallery {
      ID
      BucketFileName
      ImageURL
    }
  }
}
    `;

/**
 * __useIncidentalChargeListingQuery__
 *
 * To run a query within a React component, call `useIncidentalChargeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentalChargeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentalChargeListingQuery({
 *   variables: {
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useIncidentalChargeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IncidentalChargeListingQuery, IncidentalChargeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<IncidentalChargeListingQuery, IncidentalChargeListingQueryVariables>(IncidentalChargeListingDocument, baseOptions);
      }
export function useIncidentalChargeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IncidentalChargeListingQuery, IncidentalChargeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IncidentalChargeListingQuery, IncidentalChargeListingQueryVariables>(IncidentalChargeListingDocument, baseOptions);
        }
export type IncidentalChargeListingQueryHookResult = ReturnType<typeof useIncidentalChargeListingQuery>;
export type IncidentalChargeListingLazyQueryHookResult = ReturnType<typeof useIncidentalChargeListingLazyQuery>;
export type IncidentalChargeListingQueryResult = ApolloReactCommon.QueryResult<IncidentalChargeListingQuery, IncidentalChargeListingQueryVariables>;
export const GetClassificationListingDocument = gql`
    query getClassificationListing {
  getClassificationListing {
    id
    classificationCode
    description
  }
}
    `;

/**
 * __useGetClassificationListingQuery__
 *
 * To run a query within a React component, call `useGetClassificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClassificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassificationListingQuery, GetClassificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClassificationListingQuery, GetClassificationListingQueryVariables>(GetClassificationListingDocument, baseOptions);
      }
export function useGetClassificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassificationListingQuery, GetClassificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClassificationListingQuery, GetClassificationListingQueryVariables>(GetClassificationListingDocument, baseOptions);
        }
export type GetClassificationListingQueryHookResult = ReturnType<typeof useGetClassificationListingQuery>;
export type GetClassificationListingLazyQueryHookResult = ReturnType<typeof useGetClassificationListingLazyQuery>;
export type GetClassificationListingQueryResult = ApolloReactCommon.QueryResult<GetClassificationListingQuery, GetClassificationListingQueryVariables>;
export const GetClassificationListingByCodeDocument = gql`
    query getClassificationListingByCode($code: String!) {
  getClassificationListingByCode(code: $code) {
    id
    classificationCode
    description
  }
}
    `;

/**
 * __useGetClassificationListingByCodeQuery__
 *
 * To run a query within a React component, call `useGetClassificationListingByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassificationListingByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassificationListingByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetClassificationListingByCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassificationListingByCodeQuery, GetClassificationListingByCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClassificationListingByCodeQuery, GetClassificationListingByCodeQueryVariables>(GetClassificationListingByCodeDocument, baseOptions);
      }
export function useGetClassificationListingByCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassificationListingByCodeQuery, GetClassificationListingByCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClassificationListingByCodeQuery, GetClassificationListingByCodeQueryVariables>(GetClassificationListingByCodeDocument, baseOptions);
        }
export type GetClassificationListingByCodeQueryHookResult = ReturnType<typeof useGetClassificationListingByCodeQuery>;
export type GetClassificationListingByCodeLazyQueryHookResult = ReturnType<typeof useGetClassificationListingByCodeLazyQuery>;
export type GetClassificationListingByCodeQueryResult = ApolloReactCommon.QueryResult<GetClassificationListingByCodeQuery, GetClassificationListingByCodeQueryVariables>;
export const IncidentalChargeInsertDocument = gql`
    mutation IncidentalChargeInsert($IncidentalChargeInput: IncidentalChargeInput!) {
  IncidentalChargeInsert(IncidentalChargeInput: $IncidentalChargeInput)
}
    `;
export type IncidentalChargeInsertMutationFn = ApolloReactCommon.MutationFunction<IncidentalChargeInsertMutation, IncidentalChargeInsertMutationVariables>;

/**
 * __useIncidentalChargeInsertMutation__
 *
 * To run a mutation, you first call `useIncidentalChargeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncidentalChargeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incidentalChargeInsertMutation, { data, loading, error }] = useIncidentalChargeInsertMutation({
 *   variables: {
 *      IncidentalChargeInput: // value for 'IncidentalChargeInput'
 *   },
 * });
 */
export function useIncidentalChargeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IncidentalChargeInsertMutation, IncidentalChargeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<IncidentalChargeInsertMutation, IncidentalChargeInsertMutationVariables>(IncidentalChargeInsertDocument, baseOptions);
      }
export type IncidentalChargeInsertMutationHookResult = ReturnType<typeof useIncidentalChargeInsertMutation>;
export type IncidentalChargeInsertMutationResult = ApolloReactCommon.MutationResult<IncidentalChargeInsertMutation>;
export type IncidentalChargeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<IncidentalChargeInsertMutation, IncidentalChargeInsertMutationVariables>;
export const IncidentalChargeUpdateDocument = gql`
    mutation IncidentalChargeUpdate($IncidentalChargeInput: IncidentalChargeInput!, $OldGalleryID: [String!]) {
  IncidentalChargeUpdate(IncidentalChargeInput: $IncidentalChargeInput, OldGalleryID: $OldGalleryID)
}
    `;
export type IncidentalChargeUpdateMutationFn = ApolloReactCommon.MutationFunction<IncidentalChargeUpdateMutation, IncidentalChargeUpdateMutationVariables>;

/**
 * __useIncidentalChargeUpdateMutation__
 *
 * To run a mutation, you first call `useIncidentalChargeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncidentalChargeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incidentalChargeUpdateMutation, { data, loading, error }] = useIncidentalChargeUpdateMutation({
 *   variables: {
 *      IncidentalChargeInput: // value for 'IncidentalChargeInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useIncidentalChargeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IncidentalChargeUpdateMutation, IncidentalChargeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<IncidentalChargeUpdateMutation, IncidentalChargeUpdateMutationVariables>(IncidentalChargeUpdateDocument, baseOptions);
      }
export type IncidentalChargeUpdateMutationHookResult = ReturnType<typeof useIncidentalChargeUpdateMutation>;
export type IncidentalChargeUpdateMutationResult = ApolloReactCommon.MutationResult<IncidentalChargeUpdateMutation>;
export type IncidentalChargeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<IncidentalChargeUpdateMutation, IncidentalChargeUpdateMutationVariables>;
export const IncidentalChargeDeleteDocument = gql`
    mutation IncidentalChargeDelete($ID: String!) {
  IncidentalChargeDelete(ID: $ID)
}
    `;
export type IncidentalChargeDeleteMutationFn = ApolloReactCommon.MutationFunction<IncidentalChargeDeleteMutation, IncidentalChargeDeleteMutationVariables>;

/**
 * __useIncidentalChargeDeleteMutation__
 *
 * To run a mutation, you first call `useIncidentalChargeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncidentalChargeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incidentalChargeDeleteMutation, { data, loading, error }] = useIncidentalChargeDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useIncidentalChargeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IncidentalChargeDeleteMutation, IncidentalChargeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<IncidentalChargeDeleteMutation, IncidentalChargeDeleteMutationVariables>(IncidentalChargeDeleteDocument, baseOptions);
      }
export type IncidentalChargeDeleteMutationHookResult = ReturnType<typeof useIncidentalChargeDeleteMutation>;
export type IncidentalChargeDeleteMutationResult = ApolloReactCommon.MutationResult<IncidentalChargeDeleteMutation>;
export type IncidentalChargeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<IncidentalChargeDeleteMutation, IncidentalChargeDeleteMutationVariables>;
export const LateCheckOutChargeListingDocument = gql`
    query LateCheckOutChargeListing($HotelID: String!) {
  LateCheckOutChargeListing(HotelID: $HotelID) {
    ID
    HotelID
    Name
    Amount
    IsActive
    CreatedBy
    ModifiedBy
  }
}
    `;

/**
 * __useLateCheckOutChargeListingQuery__
 *
 * To run a query within a React component, call `useLateCheckOutChargeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLateCheckOutChargeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLateCheckOutChargeListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useLateCheckOutChargeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LateCheckOutChargeListingQuery, LateCheckOutChargeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LateCheckOutChargeListingQuery, LateCheckOutChargeListingQueryVariables>(LateCheckOutChargeListingDocument, baseOptions);
      }
export function useLateCheckOutChargeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LateCheckOutChargeListingQuery, LateCheckOutChargeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LateCheckOutChargeListingQuery, LateCheckOutChargeListingQueryVariables>(LateCheckOutChargeListingDocument, baseOptions);
        }
export type LateCheckOutChargeListingQueryHookResult = ReturnType<typeof useLateCheckOutChargeListingQuery>;
export type LateCheckOutChargeListingLazyQueryHookResult = ReturnType<typeof useLateCheckOutChargeListingLazyQuery>;
export type LateCheckOutChargeListingQueryResult = ApolloReactCommon.QueryResult<LateCheckOutChargeListingQuery, LateCheckOutChargeListingQueryVariables>;
export const LateCheckOutChargeInsertDocument = gql`
    mutation LateCheckOutChargeInsert($LateCheckOutChargeInput: [LateCheckOutChargeInput!]!) {
  LateCheckOutChargeInsert(LateCheckOutChargeInput: $LateCheckOutChargeInput)
}
    `;
export type LateCheckOutChargeInsertMutationFn = ApolloReactCommon.MutationFunction<LateCheckOutChargeInsertMutation, LateCheckOutChargeInsertMutationVariables>;

/**
 * __useLateCheckOutChargeInsertMutation__
 *
 * To run a mutation, you first call `useLateCheckOutChargeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLateCheckOutChargeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lateCheckOutChargeInsertMutation, { data, loading, error }] = useLateCheckOutChargeInsertMutation({
 *   variables: {
 *      LateCheckOutChargeInput: // value for 'LateCheckOutChargeInput'
 *   },
 * });
 */
export function useLateCheckOutChargeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LateCheckOutChargeInsertMutation, LateCheckOutChargeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LateCheckOutChargeInsertMutation, LateCheckOutChargeInsertMutationVariables>(LateCheckOutChargeInsertDocument, baseOptions);
      }
export type LateCheckOutChargeInsertMutationHookResult = ReturnType<typeof useLateCheckOutChargeInsertMutation>;
export type LateCheckOutChargeInsertMutationResult = ApolloReactCommon.MutationResult<LateCheckOutChargeInsertMutation>;
export type LateCheckOutChargeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LateCheckOutChargeInsertMutation, LateCheckOutChargeInsertMutationVariables>;
export const LocationListingDocument = gql`
    query LocationListing($HotelID: String!) {
  LocationListing(HotelID: $HotelID) {
    ID
    Code
    Description
    IsActive
    DisplaySequence
    Room {
      MaxGuest
      ID
      RoomNo
      RoomStatus {
        VacantOrOccupied
        CleanOrDirty
        Code
        Description
      }
      RoomStatusCode
      Location {
        Code
        Description
      }
    }
    GalleryID
    Gallery {
      ID
      BucketFileName
      ImageURL
    }
  }
}
    `;

/**
 * __useLocationListingQuery__
 *
 * To run a query within a React component, call `useLocationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useLocationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationListingQuery, LocationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationListingQuery, LocationListingQueryVariables>(LocationListingDocument, baseOptions);
      }
export function useLocationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationListingQuery, LocationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationListingQuery, LocationListingQueryVariables>(LocationListingDocument, baseOptions);
        }
export type LocationListingQueryHookResult = ReturnType<typeof useLocationListingQuery>;
export type LocationListingLazyQueryHookResult = ReturnType<typeof useLocationListingLazyQuery>;
export type LocationListingQueryResult = ApolloReactCommon.QueryResult<LocationListingQuery, LocationListingQueryVariables>;
export const LocationInUseListingDocument = gql`
    query LocationInUseListing($HotelID: String!, $LocationID: String!) {
  LocationInUseListing(HotelID: $HotelID, LocationID: $LocationID)
  RoomInUseListing(ID: $HotelID)
}
    `;

/**
 * __useLocationInUseListingQuery__
 *
 * To run a query within a React component, call `useLocationInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationInUseListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      LocationID: // value for 'LocationID'
 *   },
 * });
 */
export function useLocationInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationInUseListingQuery, LocationInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationInUseListingQuery, LocationInUseListingQueryVariables>(LocationInUseListingDocument, baseOptions);
      }
export function useLocationInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationInUseListingQuery, LocationInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationInUseListingQuery, LocationInUseListingQueryVariables>(LocationInUseListingDocument, baseOptions);
        }
export type LocationInUseListingQueryHookResult = ReturnType<typeof useLocationInUseListingQuery>;
export type LocationInUseListingLazyQueryHookResult = ReturnType<typeof useLocationInUseListingLazyQuery>;
export type LocationInUseListingQueryResult = ApolloReactCommon.QueryResult<LocationInUseListingQuery, LocationInUseListingQueryVariables>;
export const LocationUpdateDocument = gql`
    mutation LocationUpdate($LocationInput: LocationInput!, $OldGalleryID: [String!]) {
  LocationUpdate(LocationInput: $LocationInput, OldGalleryID: $OldGalleryID)
}
    `;
export type LocationUpdateMutationFn = ApolloReactCommon.MutationFunction<LocationUpdateMutation, LocationUpdateMutationVariables>;

/**
 * __useLocationUpdateMutation__
 *
 * To run a mutation, you first call `useLocationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationUpdateMutation, { data, loading, error }] = useLocationUpdateMutation({
 *   variables: {
 *      LocationInput: // value for 'LocationInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useLocationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LocationUpdateMutation, LocationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<LocationUpdateMutation, LocationUpdateMutationVariables>(LocationUpdateDocument, baseOptions);
      }
export type LocationUpdateMutationHookResult = ReturnType<typeof useLocationUpdateMutation>;
export type LocationUpdateMutationResult = ApolloReactCommon.MutationResult<LocationUpdateMutation>;
export type LocationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<LocationUpdateMutation, LocationUpdateMutationVariables>;
export const LocationInsertDocument = gql`
    mutation LocationInsert($LocationInput: LocationInput!) {
  LocationInsert(LocationInput: $LocationInput) {
    ID
  }
}
    `;
export type LocationInsertMutationFn = ApolloReactCommon.MutationFunction<LocationInsertMutation, LocationInsertMutationVariables>;

/**
 * __useLocationInsertMutation__
 *
 * To run a mutation, you first call `useLocationInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationInsertMutation, { data, loading, error }] = useLocationInsertMutation({
 *   variables: {
 *      LocationInput: // value for 'LocationInput'
 *   },
 * });
 */
export function useLocationInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LocationInsertMutation, LocationInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<LocationInsertMutation, LocationInsertMutationVariables>(LocationInsertDocument, baseOptions);
      }
export type LocationInsertMutationHookResult = ReturnType<typeof useLocationInsertMutation>;
export type LocationInsertMutationResult = ApolloReactCommon.MutationResult<LocationInsertMutation>;
export type LocationInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<LocationInsertMutation, LocationInsertMutationVariables>;
export const LocationDeleteDocument = gql`
    mutation LocationDelete($ID: String!, $Description: String!) {
  LocationDelete(ID: $ID, Description: $Description)
}
    `;
export type LocationDeleteMutationFn = ApolloReactCommon.MutationFunction<LocationDeleteMutation, LocationDeleteMutationVariables>;

/**
 * __useLocationDeleteMutation__
 *
 * To run a mutation, you first call `useLocationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationDeleteMutation, { data, loading, error }] = useLocationDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      Description: // value for 'Description'
 *   },
 * });
 */
export function useLocationDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LocationDeleteMutation, LocationDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<LocationDeleteMutation, LocationDeleteMutationVariables>(LocationDeleteDocument, baseOptions);
      }
export type LocationDeleteMutationHookResult = ReturnType<typeof useLocationDeleteMutation>;
export type LocationDeleteMutationResult = ApolloReactCommon.MutationResult<LocationDeleteMutation>;
export type LocationDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<LocationDeleteMutation, LocationDeleteMutationVariables>;
export const DisplaySequenceUpdateDocument = gql`
    mutation DisplaySequenceUpdate($Input: [DisplaySequenceInput!]!, $Type: String!) {
  DisplaySequenceUpdate(Input: $Input, Type: $Type)
}
    `;
export type DisplaySequenceUpdateMutationFn = ApolloReactCommon.MutationFunction<DisplaySequenceUpdateMutation, DisplaySequenceUpdateMutationVariables>;

/**
 * __useDisplaySequenceUpdateMutation__
 *
 * To run a mutation, you first call `useDisplaySequenceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisplaySequenceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [displaySequenceUpdateMutation, { data, loading, error }] = useDisplaySequenceUpdateMutation({
 *   variables: {
 *      Input: // value for 'Input'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useDisplaySequenceUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisplaySequenceUpdateMutation, DisplaySequenceUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<DisplaySequenceUpdateMutation, DisplaySequenceUpdateMutationVariables>(DisplaySequenceUpdateDocument, baseOptions);
      }
export type DisplaySequenceUpdateMutationHookResult = ReturnType<typeof useDisplaySequenceUpdateMutation>;
export type DisplaySequenceUpdateMutationResult = ApolloReactCommon.MutationResult<DisplaySequenceUpdateMutation>;
export type DisplaySequenceUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<DisplaySequenceUpdateMutation, DisplaySequenceUpdateMutationVariables>;
export const LocationFloorPlanListingDocument = gql`
    query LocationFloorPlanListing($HotelID: String!) {
  LocationListing(HotelID: $HotelID) {
    ID
    Code
    Gallery {
      ImageURL
    }
    Room {
      ID
      RoomNo
      RoomTypeID
      FloorPlanID
      FloorPlan {
        ID
        Coordinates
        IsHotel
        IsPreCheckin
      }
    }
  }
}
    `;

/**
 * __useLocationFloorPlanListingQuery__
 *
 * To run a query within a React component, call `useLocationFloorPlanListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationFloorPlanListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationFloorPlanListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useLocationFloorPlanListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LocationFloorPlanListingQuery, LocationFloorPlanListingQueryVariables>) {
        return ApolloReactHooks.useQuery<LocationFloorPlanListingQuery, LocationFloorPlanListingQueryVariables>(LocationFloorPlanListingDocument, baseOptions);
      }
export function useLocationFloorPlanListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationFloorPlanListingQuery, LocationFloorPlanListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LocationFloorPlanListingQuery, LocationFloorPlanListingQueryVariables>(LocationFloorPlanListingDocument, baseOptions);
        }
export type LocationFloorPlanListingQueryHookResult = ReturnType<typeof useLocationFloorPlanListingQuery>;
export type LocationFloorPlanListingLazyQueryHookResult = ReturnType<typeof useLocationFloorPlanListingLazyQuery>;
export type LocationFloorPlanListingQueryResult = ApolloReactCommon.QueryResult<LocationFloorPlanListingQuery, LocationFloorPlanListingQueryVariables>;
export const FloorPlanListingDocument = gql`
    query FloorPlanListing($HotelID: String!, $LocationID: String) {
  FloorPlanListing(HotelID: $HotelID, LocationID: $LocationID) {
    ID
    HotelID
    LocationID
    RoomID
    RoomTypeID
    Coordinates
    IsHotel
    IsPreCheckin
  }
}
    `;

/**
 * __useFloorPlanListingQuery__
 *
 * To run a query within a React component, call `useFloorPlanListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFloorPlanListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      LocationID: // value for 'LocationID'
 *   },
 * });
 */
export function useFloorPlanListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FloorPlanListingQuery, FloorPlanListingQueryVariables>) {
        return ApolloReactHooks.useQuery<FloorPlanListingQuery, FloorPlanListingQueryVariables>(FloorPlanListingDocument, baseOptions);
      }
export function useFloorPlanListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FloorPlanListingQuery, FloorPlanListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FloorPlanListingQuery, FloorPlanListingQueryVariables>(FloorPlanListingDocument, baseOptions);
        }
export type FloorPlanListingQueryHookResult = ReturnType<typeof useFloorPlanListingQuery>;
export type FloorPlanListingLazyQueryHookResult = ReturnType<typeof useFloorPlanListingLazyQuery>;
export type FloorPlanListingQueryResult = ApolloReactCommon.QueryResult<FloorPlanListingQuery, FloorPlanListingQueryVariables>;
export const FloorPlanUpdateDocument = gql`
    mutation FloorPlanUpdate($LocationFloorPlanInput: [LocationFloorPlanInput!]!, $Type: String) {
  FloorPlanUpdate(LocationFloorPlanInput: $LocationFloorPlanInput, Type: $Type)
}
    `;
export type FloorPlanUpdateMutationFn = ApolloReactCommon.MutationFunction<FloorPlanUpdateMutation, FloorPlanUpdateMutationVariables>;

/**
 * __useFloorPlanUpdateMutation__
 *
 * To run a mutation, you first call `useFloorPlanUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [floorPlanUpdateMutation, { data, loading, error }] = useFloorPlanUpdateMutation({
 *   variables: {
 *      LocationFloorPlanInput: // value for 'LocationFloorPlanInput'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useFloorPlanUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FloorPlanUpdateMutation, FloorPlanUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<FloorPlanUpdateMutation, FloorPlanUpdateMutationVariables>(FloorPlanUpdateDocument, baseOptions);
      }
export type FloorPlanUpdateMutationHookResult = ReturnType<typeof useFloorPlanUpdateMutation>;
export type FloorPlanUpdateMutationResult = ApolloReactCommon.MutationResult<FloorPlanUpdateMutation>;
export type FloorPlanUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<FloorPlanUpdateMutation, FloorPlanUpdateMutationVariables>;
export const FloorPlanResetDocument = gql`
    mutation FloorPlanReset($LocationID: String!, $HotelID: String!, $Type: String) {
  FloorPlanReset(LocationID: $LocationID, HotelID: $HotelID, Type: $Type)
}
    `;
export type FloorPlanResetMutationFn = ApolloReactCommon.MutationFunction<FloorPlanResetMutation, FloorPlanResetMutationVariables>;

/**
 * __useFloorPlanResetMutation__
 *
 * To run a mutation, you first call `useFloorPlanResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFloorPlanResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [floorPlanResetMutation, { data, loading, error }] = useFloorPlanResetMutation({
 *   variables: {
 *      LocationID: // value for 'LocationID'
 *      HotelID: // value for 'HotelID'
 *      Type: // value for 'Type'
 *   },
 * });
 */
export function useFloorPlanResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FloorPlanResetMutation, FloorPlanResetMutationVariables>) {
        return ApolloReactHooks.useMutation<FloorPlanResetMutation, FloorPlanResetMutationVariables>(FloorPlanResetDocument, baseOptions);
      }
export type FloorPlanResetMutationHookResult = ReturnType<typeof useFloorPlanResetMutation>;
export type FloorPlanResetMutationResult = ApolloReactCommon.MutationResult<FloorPlanResetMutation>;
export type FloorPlanResetMutationOptions = ApolloReactCommon.BaseMutationOptions<FloorPlanResetMutation, FloorPlanResetMutationVariables>;
export const MeasurementListingDocument = gql`
    query MeasurementListing {
  MeasurementListing {
    ID
    Type
    Measurement
    Size
  }
}
    `;

/**
 * __useMeasurementListingQuery__
 *
 * To run a query within a React component, call `useMeasurementListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeasurementListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeasurementListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeasurementListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeasurementListingQuery, MeasurementListingQueryVariables>) {
        return ApolloReactHooks.useQuery<MeasurementListingQuery, MeasurementListingQueryVariables>(MeasurementListingDocument, baseOptions);
      }
export function useMeasurementListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeasurementListingQuery, MeasurementListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeasurementListingQuery, MeasurementListingQueryVariables>(MeasurementListingDocument, baseOptions);
        }
export type MeasurementListingQueryHookResult = ReturnType<typeof useMeasurementListingQuery>;
export type MeasurementListingLazyQueryHookResult = ReturnType<typeof useMeasurementListingLazyQuery>;
export type MeasurementListingQueryResult = ApolloReactCommon.QueryResult<MeasurementListingQuery, MeasurementListingQueryVariables>;
export const RateElementListingDocument = gql`
    query RateElementListing($HotelID: String!) {
  RateElementListing(HotelID: $HotelID) {
    ID
    HotelID
    Amount
    ComputationRule
    CreatedDT
    ModifiedDT
    IsActive
    Min
    Max
    IncidentalCharge {
      ID
      DepartmentID
      Description
      Amount
      Tax
      ServiceCharge
      IsRoomRevenue
      IsActive
      IsFnB
      IsEShop
      GalleryID
      IsRateElement
    }
  }
}
    `;

/**
 * __useRateElementListingQuery__
 *
 * To run a query within a React component, call `useRateElementListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateElementListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateElementListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRateElementListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RateElementListingQuery, RateElementListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RateElementListingQuery, RateElementListingQueryVariables>(RateElementListingDocument, baseOptions);
      }
export function useRateElementListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RateElementListingQuery, RateElementListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RateElementListingQuery, RateElementListingQueryVariables>(RateElementListingDocument, baseOptions);
        }
export type RateElementListingQueryHookResult = ReturnType<typeof useRateElementListingQuery>;
export type RateElementListingLazyQueryHookResult = ReturnType<typeof useRateElementListingLazyQuery>;
export type RateElementListingQueryResult = ApolloReactCommon.QueryResult<RateElementListingQuery, RateElementListingQueryVariables>;
export const RateElementUpdateDocument = gql`
    mutation RateElementUpdate($RateElementInput: RateElementInput!) {
  RateElementUpdate(RateElementInput: $RateElementInput)
}
    `;
export type RateElementUpdateMutationFn = ApolloReactCommon.MutationFunction<RateElementUpdateMutation, RateElementUpdateMutationVariables>;

/**
 * __useRateElementUpdateMutation__
 *
 * To run a mutation, you first call `useRateElementUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateElementUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateElementUpdateMutation, { data, loading, error }] = useRateElementUpdateMutation({
 *   variables: {
 *      RateElementInput: // value for 'RateElementInput'
 *   },
 * });
 */
export function useRateElementUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateElementUpdateMutation, RateElementUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RateElementUpdateMutation, RateElementUpdateMutationVariables>(RateElementUpdateDocument, baseOptions);
      }
export type RateElementUpdateMutationHookResult = ReturnType<typeof useRateElementUpdateMutation>;
export type RateElementUpdateMutationResult = ApolloReactCommon.MutationResult<RateElementUpdateMutation>;
export type RateElementUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RateElementUpdateMutation, RateElementUpdateMutationVariables>;
export const RateElementInsertDocument = gql`
    mutation RateElementInsert($RateElementInput: RateElementInput!) {
  RateElementInsert(RateElementInput: $RateElementInput) {
    ID
  }
}
    `;
export type RateElementInsertMutationFn = ApolloReactCommon.MutationFunction<RateElementInsertMutation, RateElementInsertMutationVariables>;

/**
 * __useRateElementInsertMutation__
 *
 * To run a mutation, you first call `useRateElementInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateElementInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateElementInsertMutation, { data, loading, error }] = useRateElementInsertMutation({
 *   variables: {
 *      RateElementInput: // value for 'RateElementInput'
 *   },
 * });
 */
export function useRateElementInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateElementInsertMutation, RateElementInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RateElementInsertMutation, RateElementInsertMutationVariables>(RateElementInsertDocument, baseOptions);
      }
export type RateElementInsertMutationHookResult = ReturnType<typeof useRateElementInsertMutation>;
export type RateElementInsertMutationResult = ApolloReactCommon.MutationResult<RateElementInsertMutation>;
export type RateElementInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RateElementInsertMutation, RateElementInsertMutationVariables>;
export const RatePolicyListingDocument = gql`
    query RatePolicyListing($HotelID: String!, $DebtorType: String) {
  RatePolicyListing(HotelID: $HotelID, DebtorType: $DebtorType) {
    ID
    HotelID
    RateTypeID
    IsActive
    RateCode
    Description
    RateFrequency
    OnlineRate
    Category
    RateEffective {
      ID
    }
  }
}
    `;

/**
 * __useRatePolicyListingQuery__
 *
 * To run a query within a React component, call `useRatePolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatePolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatePolicyListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *   },
 * });
 */
export function useRatePolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RatePolicyListingQuery, RatePolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RatePolicyListingQuery, RatePolicyListingQueryVariables>(RatePolicyListingDocument, baseOptions);
      }
export function useRatePolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RatePolicyListingQuery, RatePolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RatePolicyListingQuery, RatePolicyListingQueryVariables>(RatePolicyListingDocument, baseOptions);
        }
export type RatePolicyListingQueryHookResult = ReturnType<typeof useRatePolicyListingQuery>;
export type RatePolicyListingLazyQueryHookResult = ReturnType<typeof useRatePolicyListingLazyQuery>;
export type RatePolicyListingQueryResult = ApolloReactCommon.QueryResult<RatePolicyListingQuery, RatePolicyListingQueryVariables>;
export const RatePolicyInUseListingDocument = gql`
    query RatePolicyInUseListing($HotelID: String!, $RatePolicyID: String!, $DebtorCategory: String!) {
  RatePolicyInUseListing(HotelID: $HotelID, RatePolicyID: $RatePolicyID, DebtorCategory: $DebtorCategory)
}
    `;

/**
 * __useRatePolicyInUseListingQuery__
 *
 * To run a query within a React component, call `useRatePolicyInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatePolicyInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatePolicyInUseListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RatePolicyID: // value for 'RatePolicyID'
 *      DebtorCategory: // value for 'DebtorCategory'
 *   },
 * });
 */
export function useRatePolicyInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RatePolicyInUseListingQuery, RatePolicyInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RatePolicyInUseListingQuery, RatePolicyInUseListingQueryVariables>(RatePolicyInUseListingDocument, baseOptions);
      }
export function useRatePolicyInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RatePolicyInUseListingQuery, RatePolicyInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RatePolicyInUseListingQuery, RatePolicyInUseListingQueryVariables>(RatePolicyInUseListingDocument, baseOptions);
        }
export type RatePolicyInUseListingQueryHookResult = ReturnType<typeof useRatePolicyInUseListingQuery>;
export type RatePolicyInUseListingLazyQueryHookResult = ReturnType<typeof useRatePolicyInUseListingLazyQuery>;
export type RatePolicyInUseListingQueryResult = ApolloReactCommon.QueryResult<RatePolicyInUseListingQuery, RatePolicyInUseListingQueryVariables>;
export const IncidentalDepartmentListingDocument = gql`
    query IncidentalDepartmentListing($HotelID: String!) {
  IncidentalDepartmentListing(HotelID: $HotelID) {
    ID
    Code
    Name
    IncidentalCharge {
      ID
      Amount
      Tax
      ServiceCharge
      Description
    }
  }
}
    `;

/**
 * __useIncidentalDepartmentListingQuery__
 *
 * To run a query within a React component, call `useIncidentalDepartmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncidentalDepartmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncidentalDepartmentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useIncidentalDepartmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IncidentalDepartmentListingQuery, IncidentalDepartmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<IncidentalDepartmentListingQuery, IncidentalDepartmentListingQueryVariables>(IncidentalDepartmentListingDocument, baseOptions);
      }
export function useIncidentalDepartmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IncidentalDepartmentListingQuery, IncidentalDepartmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IncidentalDepartmentListingQuery, IncidentalDepartmentListingQueryVariables>(IncidentalDepartmentListingDocument, baseOptions);
        }
export type IncidentalDepartmentListingQueryHookResult = ReturnType<typeof useIncidentalDepartmentListingQuery>;
export type IncidentalDepartmentListingLazyQueryHookResult = ReturnType<typeof useIncidentalDepartmentListingLazyQuery>;
export type IncidentalDepartmentListingQueryResult = ApolloReactCommon.QueryResult<IncidentalDepartmentListingQuery, IncidentalDepartmentListingQueryVariables>;
export const RatePolicyDetailsDocument = gql`
    query RatePolicyDetails($RatePolicyID: String!, $HotelID: String!) {
  RatePolicyDetails(RatePolicyID: $RatePolicyID, HotelID: $HotelID) {
    ID
    HotelID
    Hotel {
      ComplimentaryID
      HouseUseID
      DayUseID
    }
    IsActive
    Category
    Code
    Description
    PublishOnline
    StartDate
    EndDate
    Package {
      ID
      IsActive
      RatePolicyID
      IncidentalChargeID
      Description
      Amount
      GalleryID
      DisplaySeq
      Tax
      ServiceCharge
      ComputationRules
      IncludeRate
      IncidentalCharge {
        ID
        Amount
      }
    }
    Pricing {
      ID
      RatePolicyID
      RoomTypeID
      IsActive
      RoomType {
        Code
      }
      Standard
      Ceiling
      Floor
      PeakStandard
      PeakCeiling
      PeakFloor
      SuperPeakStandard
      SuperPeakCeiling
      SuperPeakFloor
      HotelID
    }
  }
}
    `;

/**
 * __useRatePolicyDetailsQuery__
 *
 * To run a query within a React component, call `useRatePolicyDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatePolicyDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatePolicyDetailsQuery({
 *   variables: {
 *      RatePolicyID: // value for 'RatePolicyID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRatePolicyDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RatePolicyDetailsQuery, RatePolicyDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<RatePolicyDetailsQuery, RatePolicyDetailsQueryVariables>(RatePolicyDetailsDocument, baseOptions);
      }
export function useRatePolicyDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RatePolicyDetailsQuery, RatePolicyDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RatePolicyDetailsQuery, RatePolicyDetailsQueryVariables>(RatePolicyDetailsDocument, baseOptions);
        }
export type RatePolicyDetailsQueryHookResult = ReturnType<typeof useRatePolicyDetailsQuery>;
export type RatePolicyDetailsLazyQueryHookResult = ReturnType<typeof useRatePolicyDetailsLazyQuery>;
export type RatePolicyDetailsQueryResult = ApolloReactCommon.QueryResult<RatePolicyDetailsQuery, RatePolicyDetailsQueryVariables>;
export const RatePolicyInsertDocument = gql`
    mutation RatePolicyInsert($RatePolicyInput: RatePolicyInput!, $PricingInput: [PricingInput!]!, $PackagesInput: [PackagesInput!]!) {
  RatePolicyInsert(RatePolicyInput: $RatePolicyInput, PricingInput: $PricingInput, PackagesInput: $PackagesInput) {
    ID
  }
}
    `;
export type RatePolicyInsertMutationFn = ApolloReactCommon.MutationFunction<RatePolicyInsertMutation, RatePolicyInsertMutationVariables>;

/**
 * __useRatePolicyInsertMutation__
 *
 * To run a mutation, you first call `useRatePolicyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRatePolicyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ratePolicyInsertMutation, { data, loading, error }] = useRatePolicyInsertMutation({
 *   variables: {
 *      RatePolicyInput: // value for 'RatePolicyInput'
 *      PricingInput: // value for 'PricingInput'
 *      PackagesInput: // value for 'PackagesInput'
 *   },
 * });
 */
export function useRatePolicyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RatePolicyInsertMutation, RatePolicyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RatePolicyInsertMutation, RatePolicyInsertMutationVariables>(RatePolicyInsertDocument, baseOptions);
      }
export type RatePolicyInsertMutationHookResult = ReturnType<typeof useRatePolicyInsertMutation>;
export type RatePolicyInsertMutationResult = ApolloReactCommon.MutationResult<RatePolicyInsertMutation>;
export type RatePolicyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RatePolicyInsertMutation, RatePolicyInsertMutationVariables>;
export const RatePolicyUpdateDocument = gql`
    mutation RatePolicyUpdate($RatePolicyInput: RatePolicyInput!, $PricingInput: [PricingInput!]) {
  RatePolicyUpdate(RatePolicyInput: $RatePolicyInput, PricingInput: $PricingInput)
}
    `;
export type RatePolicyUpdateMutationFn = ApolloReactCommon.MutationFunction<RatePolicyUpdateMutation, RatePolicyUpdateMutationVariables>;

/**
 * __useRatePolicyUpdateMutation__
 *
 * To run a mutation, you first call `useRatePolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRatePolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ratePolicyUpdateMutation, { data, loading, error }] = useRatePolicyUpdateMutation({
 *   variables: {
 *      RatePolicyInput: // value for 'RatePolicyInput'
 *      PricingInput: // value for 'PricingInput'
 *   },
 * });
 */
export function useRatePolicyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RatePolicyUpdateMutation, RatePolicyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RatePolicyUpdateMutation, RatePolicyUpdateMutationVariables>(RatePolicyUpdateDocument, baseOptions);
      }
export type RatePolicyUpdateMutationHookResult = ReturnType<typeof useRatePolicyUpdateMutation>;
export type RatePolicyUpdateMutationResult = ApolloReactCommon.MutationResult<RatePolicyUpdateMutation>;
export type RatePolicyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RatePolicyUpdateMutation, RatePolicyUpdateMutationVariables>;
export const PackageInsertDocument = gql`
    mutation PackageInsert($PackagesInput: [PackagesInput!]!) {
  PackageInsert(PackagesInput: $PackagesInput)
}
    `;
export type PackageInsertMutationFn = ApolloReactCommon.MutationFunction<PackageInsertMutation, PackageInsertMutationVariables>;

/**
 * __usePackageInsertMutation__
 *
 * To run a mutation, you first call `usePackageInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackageInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packageInsertMutation, { data, loading, error }] = usePackageInsertMutation({
 *   variables: {
 *      PackagesInput: // value for 'PackagesInput'
 *   },
 * });
 */
export function usePackageInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PackageInsertMutation, PackageInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<PackageInsertMutation, PackageInsertMutationVariables>(PackageInsertDocument, baseOptions);
      }
export type PackageInsertMutationHookResult = ReturnType<typeof usePackageInsertMutation>;
export type PackageInsertMutationResult = ApolloReactCommon.MutationResult<PackageInsertMutation>;
export type PackageInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<PackageInsertMutation, PackageInsertMutationVariables>;
export const PackageUpdateDocument = gql`
    mutation PackageUpdate($PackagesInput: PackagesInput!) {
  PackageUpdate(PackagesInput: $PackagesInput)
}
    `;
export type PackageUpdateMutationFn = ApolloReactCommon.MutationFunction<PackageUpdateMutation, PackageUpdateMutationVariables>;

/**
 * __usePackageUpdateMutation__
 *
 * To run a mutation, you first call `usePackageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packageUpdateMutation, { data, loading, error }] = usePackageUpdateMutation({
 *   variables: {
 *      PackagesInput: // value for 'PackagesInput'
 *   },
 * });
 */
export function usePackageUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PackageUpdateMutation, PackageUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PackageUpdateMutation, PackageUpdateMutationVariables>(PackageUpdateDocument, baseOptions);
      }
export type PackageUpdateMutationHookResult = ReturnType<typeof usePackageUpdateMutation>;
export type PackageUpdateMutationResult = ApolloReactCommon.MutationResult<PackageUpdateMutation>;
export type PackageUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PackageUpdateMutation, PackageUpdateMutationVariables>;
export const RatePolicyDeleteDocument = gql`
    mutation RatePolicyDelete($ID: String!, $DebtorCategory: String!, $HotelID: String!) {
  RatePolicyDelete(ID: $ID, DebtorCategory: $DebtorCategory, HotelID: $HotelID)
}
    `;
export type RatePolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<RatePolicyDeleteMutation, RatePolicyDeleteMutationVariables>;

/**
 * __useRatePolicyDeleteMutation__
 *
 * To run a mutation, you first call `useRatePolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRatePolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ratePolicyDeleteMutation, { data, loading, error }] = useRatePolicyDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      DebtorCategory: // value for 'DebtorCategory'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRatePolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RatePolicyDeleteMutation, RatePolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RatePolicyDeleteMutation, RatePolicyDeleteMutationVariables>(RatePolicyDeleteDocument, baseOptions);
      }
export type RatePolicyDeleteMutationHookResult = ReturnType<typeof useRatePolicyDeleteMutation>;
export type RatePolicyDeleteMutationResult = ApolloReactCommon.MutationResult<RatePolicyDeleteMutation>;
export type RatePolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RatePolicyDeleteMutation, RatePolicyDeleteMutationVariables>;
export const RatePolicyRoomTypeListingDocument = gql`
    query RatePolicyRoomTypeListing($HotelID: String!) {
  RatePolicyRoomTypeListing(HotelID: $HotelID) {
    ID
    Code
    Description
    TotalRoom
    MaxGuest
    IsActive
    DisplaySequence
    RoomSize
    RoomTypeAmenities {
      ID
      Name
      HotelID
      RoomTypeID
      GalleryID
      IsSelected
    }
    MeasureType
    PropertyType
    RoomTypeGallery {
      Gallery {
        ID
        ImageURL
        BucketFileName
      }
    }
  }
}
    `;

/**
 * __useRatePolicyRoomTypeListingQuery__
 *
 * To run a query within a React component, call `useRatePolicyRoomTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatePolicyRoomTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatePolicyRoomTypeListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRatePolicyRoomTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RatePolicyRoomTypeListingQuery, RatePolicyRoomTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RatePolicyRoomTypeListingQuery, RatePolicyRoomTypeListingQueryVariables>(RatePolicyRoomTypeListingDocument, baseOptions);
      }
export function useRatePolicyRoomTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RatePolicyRoomTypeListingQuery, RatePolicyRoomTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RatePolicyRoomTypeListingQuery, RatePolicyRoomTypeListingQueryVariables>(RatePolicyRoomTypeListingDocument, baseOptions);
        }
export type RatePolicyRoomTypeListingQueryHookResult = ReturnType<typeof useRatePolicyRoomTypeListingQuery>;
export type RatePolicyRoomTypeListingLazyQueryHookResult = ReturnType<typeof useRatePolicyRoomTypeListingLazyQuery>;
export type RatePolicyRoomTypeListingQueryResult = ApolloReactCommon.QueryResult<RatePolicyRoomTypeListingQuery, RatePolicyRoomTypeListingQueryVariables>;
export const SeasonListingDocument = gql`
    query SeasonListing($HotelID: String!, $RateEffectiveID: String) {
  SeasonListing(HotelID: $HotelID) {
    ID
    IsActive
    HotelID
    Name
    ModifiedDT
    RGBColor
    SeasonalRate(RateEffectiveID: $RateEffectiveID) {
      ID
      RoomTypeID
      RateEffectiveID
      SeasonID
      StandardRate
      DynamicRate {
        Tier
        RoomQuota
        Rate
      }
      Season {
        ID
        IsActive
        HotelID
        Name
        RGBColor
      }
      RoomType {
        ID
        Description
        IsActive
      }
    }
  }
}
    `;

/**
 * __useSeasonListingQuery__
 *
 * To run a query within a React component, call `useSeasonListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RateEffectiveID: // value for 'RateEffectiveID'
 *   },
 * });
 */
export function useSeasonListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SeasonListingQuery, SeasonListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SeasonListingQuery, SeasonListingQueryVariables>(SeasonListingDocument, baseOptions);
      }
export function useSeasonListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SeasonListingQuery, SeasonListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SeasonListingQuery, SeasonListingQueryVariables>(SeasonListingDocument, baseOptions);
        }
export type SeasonListingQueryHookResult = ReturnType<typeof useSeasonListingQuery>;
export type SeasonListingLazyQueryHookResult = ReturnType<typeof useSeasonListingLazyQuery>;
export type SeasonListingQueryResult = ApolloReactCommon.QueryResult<SeasonListingQuery, SeasonListingQueryVariables>;
export const SeasonUpdateDocument = gql`
    mutation SeasonUpdate($SeasonInput: SeasonInput!) {
  SeasonUpdate(SeasonInput: $SeasonInput)
}
    `;
export type SeasonUpdateMutationFn = ApolloReactCommon.MutationFunction<SeasonUpdateMutation, SeasonUpdateMutationVariables>;

/**
 * __useSeasonUpdateMutation__
 *
 * To run a mutation, you first call `useSeasonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeasonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seasonUpdateMutation, { data, loading, error }] = useSeasonUpdateMutation({
 *   variables: {
 *      SeasonInput: // value for 'SeasonInput'
 *   },
 * });
 */
export function useSeasonUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SeasonUpdateMutation, SeasonUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SeasonUpdateMutation, SeasonUpdateMutationVariables>(SeasonUpdateDocument, baseOptions);
      }
export type SeasonUpdateMutationHookResult = ReturnType<typeof useSeasonUpdateMutation>;
export type SeasonUpdateMutationResult = ApolloReactCommon.MutationResult<SeasonUpdateMutation>;
export type SeasonUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SeasonUpdateMutation, SeasonUpdateMutationVariables>;
export const SeasonInsertDocument = gql`
    mutation SeasonInsert($SeasonInput: SeasonInput!) {
  SeasonInsert(SeasonInput: $SeasonInput) {
    ID
  }
}
    `;
export type SeasonInsertMutationFn = ApolloReactCommon.MutationFunction<SeasonInsertMutation, SeasonInsertMutationVariables>;

/**
 * __useSeasonInsertMutation__
 *
 * To run a mutation, you first call `useSeasonInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeasonInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seasonInsertMutation, { data, loading, error }] = useSeasonInsertMutation({
 *   variables: {
 *      SeasonInput: // value for 'SeasonInput'
 *   },
 * });
 */
export function useSeasonInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SeasonInsertMutation, SeasonInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<SeasonInsertMutation, SeasonInsertMutationVariables>(SeasonInsertDocument, baseOptions);
      }
export type SeasonInsertMutationHookResult = ReturnType<typeof useSeasonInsertMutation>;
export type SeasonInsertMutationResult = ApolloReactCommon.MutationResult<SeasonInsertMutation>;
export type SeasonInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<SeasonInsertMutation, SeasonInsertMutationVariables>;
export const RateTypeListingDocument = gql`
    query RateTypeListing($HotelID: String!) {
  RateTypeListing(HotelID: $HotelID) {
    ID
    IsActive
    HotelID
    Description
    ModifiedDT
  }
}
    `;

/**
 * __useRateTypeListingQuery__
 *
 * To run a query within a React component, call `useRateTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateTypeListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRateTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RateTypeListingQuery, RateTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RateTypeListingQuery, RateTypeListingQueryVariables>(RateTypeListingDocument, baseOptions);
      }
export function useRateTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RateTypeListingQuery, RateTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RateTypeListingQuery, RateTypeListingQueryVariables>(RateTypeListingDocument, baseOptions);
        }
export type RateTypeListingQueryHookResult = ReturnType<typeof useRateTypeListingQuery>;
export type RateTypeListingLazyQueryHookResult = ReturnType<typeof useRateTypeListingLazyQuery>;
export type RateTypeListingQueryResult = ApolloReactCommon.QueryResult<RateTypeListingQuery, RateTypeListingQueryVariables>;
export const RateTypeUpdateDocument = gql`
    mutation RateTypeUpdate($RateTypeInput: RateTypeInput!) {
  RateTypeUpdate(RateTypeInput: $RateTypeInput)
}
    `;
export type RateTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<RateTypeUpdateMutation, RateTypeUpdateMutationVariables>;

/**
 * __useRateTypeUpdateMutation__
 *
 * To run a mutation, you first call `useRateTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateTypeUpdateMutation, { data, loading, error }] = useRateTypeUpdateMutation({
 *   variables: {
 *      RateTypeInput: // value for 'RateTypeInput'
 *   },
 * });
 */
export function useRateTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateTypeUpdateMutation, RateTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RateTypeUpdateMutation, RateTypeUpdateMutationVariables>(RateTypeUpdateDocument, baseOptions);
      }
export type RateTypeUpdateMutationHookResult = ReturnType<typeof useRateTypeUpdateMutation>;
export type RateTypeUpdateMutationResult = ApolloReactCommon.MutationResult<RateTypeUpdateMutation>;
export type RateTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RateTypeUpdateMutation, RateTypeUpdateMutationVariables>;
export const RateTypeInsertDocument = gql`
    mutation RateTypeInsert($RateTypeInput: RateTypeInput!) {
  RateTypeInsert(RateTypeInput: $RateTypeInput) {
    ID
  }
}
    `;
export type RateTypeInsertMutationFn = ApolloReactCommon.MutationFunction<RateTypeInsertMutation, RateTypeInsertMutationVariables>;

/**
 * __useRateTypeInsertMutation__
 *
 * To run a mutation, you first call `useRateTypeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateTypeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateTypeInsertMutation, { data, loading, error }] = useRateTypeInsertMutation({
 *   variables: {
 *      RateTypeInput: // value for 'RateTypeInput'
 *   },
 * });
 */
export function useRateTypeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateTypeInsertMutation, RateTypeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RateTypeInsertMutation, RateTypeInsertMutationVariables>(RateTypeInsertDocument, baseOptions);
      }
export type RateTypeInsertMutationHookResult = ReturnType<typeof useRateTypeInsertMutation>;
export type RateTypeInsertMutationResult = ApolloReactCommon.MutationResult<RateTypeInsertMutation>;
export type RateTypeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RateTypeInsertMutation, RateTypeInsertMutationVariables>;
export const RateAddOnListingDocument = gql`
    query RateAddOnListing($HotelID: String!) {
  RateAddOnListing(HotelID: $HotelID) {
    ID
    IsActive
    HotelID
    Amount
    PostingRythm
    ModifiedDT
    IncidentalChargeID
    Description
    IncidentalCharge {
      ID
      DepartmentID
      Description
      Amount
      Tax
      ServiceCharge
      IsRoomRevenue
      IsActive
      IsFnB
      IsEShop
      GalleryID
    }
  }
}
    `;

/**
 * __useRateAddOnListingQuery__
 *
 * To run a query within a React component, call `useRateAddOnListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateAddOnListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateAddOnListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRateAddOnListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RateAddOnListingQuery, RateAddOnListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RateAddOnListingQuery, RateAddOnListingQueryVariables>(RateAddOnListingDocument, baseOptions);
      }
export function useRateAddOnListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RateAddOnListingQuery, RateAddOnListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RateAddOnListingQuery, RateAddOnListingQueryVariables>(RateAddOnListingDocument, baseOptions);
        }
export type RateAddOnListingQueryHookResult = ReturnType<typeof useRateAddOnListingQuery>;
export type RateAddOnListingLazyQueryHookResult = ReturnType<typeof useRateAddOnListingLazyQuery>;
export type RateAddOnListingQueryResult = ApolloReactCommon.QueryResult<RateAddOnListingQuery, RateAddOnListingQueryVariables>;
export const RateAddOnUpdateDocument = gql`
    mutation RateAddOnUpdate($RateAddOnInput: RateAddOnInput!) {
  RateAddOnUpdate(RateAddOnInput: $RateAddOnInput)
}
    `;
export type RateAddOnUpdateMutationFn = ApolloReactCommon.MutationFunction<RateAddOnUpdateMutation, RateAddOnUpdateMutationVariables>;

/**
 * __useRateAddOnUpdateMutation__
 *
 * To run a mutation, you first call `useRateAddOnUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateAddOnUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateAddOnUpdateMutation, { data, loading, error }] = useRateAddOnUpdateMutation({
 *   variables: {
 *      RateAddOnInput: // value for 'RateAddOnInput'
 *   },
 * });
 */
export function useRateAddOnUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateAddOnUpdateMutation, RateAddOnUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RateAddOnUpdateMutation, RateAddOnUpdateMutationVariables>(RateAddOnUpdateDocument, baseOptions);
      }
export type RateAddOnUpdateMutationHookResult = ReturnType<typeof useRateAddOnUpdateMutation>;
export type RateAddOnUpdateMutationResult = ApolloReactCommon.MutationResult<RateAddOnUpdateMutation>;
export type RateAddOnUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RateAddOnUpdateMutation, RateAddOnUpdateMutationVariables>;
export const RateAddOnInsertDocument = gql`
    mutation RateAddOnInsert($RateAddOnInput: RateAddOnInput!) {
  RateAddOnInsert(RateAddOnInput: $RateAddOnInput) {
    ID
  }
}
    `;
export type RateAddOnInsertMutationFn = ApolloReactCommon.MutationFunction<RateAddOnInsertMutation, RateAddOnInsertMutationVariables>;

/**
 * __useRateAddOnInsertMutation__
 *
 * To run a mutation, you first call `useRateAddOnInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateAddOnInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateAddOnInsertMutation, { data, loading, error }] = useRateAddOnInsertMutation({
 *   variables: {
 *      RateAddOnInput: // value for 'RateAddOnInput'
 *   },
 * });
 */
export function useRateAddOnInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateAddOnInsertMutation, RateAddOnInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RateAddOnInsertMutation, RateAddOnInsertMutationVariables>(RateAddOnInsertDocument, baseOptions);
      }
export type RateAddOnInsertMutationHookResult = ReturnType<typeof useRateAddOnInsertMutation>;
export type RateAddOnInsertMutationResult = ApolloReactCommon.MutationResult<RateAddOnInsertMutation>;
export type RateAddOnInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RateAddOnInsertMutation, RateAddOnInsertMutationVariables>;
export const RateListingDocument = gql`
    query RateListing($HotelID: String!, $Category: String) {
  RateListing(HotelID: $HotelID, Category: $Category) {
    ID
    IsActive
    ModifiedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    HotelID
    RateTypeID
    RateCode
    Description
    RateFrequency
    OnlineRate
    Category
  }
}
    `;

/**
 * __useRateListingQuery__
 *
 * To run a query within a React component, call `useRateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useRateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RateListingQuery, RateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RateListingQuery, RateListingQueryVariables>(RateListingDocument, baseOptions);
      }
export function useRateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RateListingQuery, RateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RateListingQuery, RateListingQueryVariables>(RateListingDocument, baseOptions);
        }
export type RateListingQueryHookResult = ReturnType<typeof useRateListingQuery>;
export type RateListingLazyQueryHookResult = ReturnType<typeof useRateListingLazyQuery>;
export type RateListingQueryResult = ApolloReactCommon.QueryResult<RateListingQuery, RateListingQueryVariables>;
export const RateInHouseListingDocument = gql`
    query RateInHouseListing($HotelID: String!, $RateID: String!) {
  RateInHouseListing(HotelID: $HotelID, RateID: $RateID)
}
    `;

/**
 * __useRateInHouseListingQuery__
 *
 * To run a query within a React component, call `useRateInHouseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateInHouseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateInHouseListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RateID: // value for 'RateID'
 *   },
 * });
 */
export function useRateInHouseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RateInHouseListingQuery, RateInHouseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RateInHouseListingQuery, RateInHouseListingQueryVariables>(RateInHouseListingDocument, baseOptions);
      }
export function useRateInHouseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RateInHouseListingQuery, RateInHouseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RateInHouseListingQuery, RateInHouseListingQueryVariables>(RateInHouseListingDocument, baseOptions);
        }
export type RateInHouseListingQueryHookResult = ReturnType<typeof useRateInHouseListingQuery>;
export type RateInHouseListingLazyQueryHookResult = ReturnType<typeof useRateInHouseListingLazyQuery>;
export type RateInHouseListingQueryResult = ApolloReactCommon.QueryResult<RateInHouseListingQuery, RateInHouseListingQueryVariables>;
export const RateInsertDocument = gql`
    mutation RateInsert($RateInput: RateInput!) {
  RateInsert(RateInput: $RateInput) {
    ID
  }
}
    `;
export type RateInsertMutationFn = ApolloReactCommon.MutationFunction<RateInsertMutation, RateInsertMutationVariables>;

/**
 * __useRateInsertMutation__
 *
 * To run a mutation, you first call `useRateInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateInsertMutation, { data, loading, error }] = useRateInsertMutation({
 *   variables: {
 *      RateInput: // value for 'RateInput'
 *   },
 * });
 */
export function useRateInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateInsertMutation, RateInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RateInsertMutation, RateInsertMutationVariables>(RateInsertDocument, baseOptions);
      }
export type RateInsertMutationHookResult = ReturnType<typeof useRateInsertMutation>;
export type RateInsertMutationResult = ApolloReactCommon.MutationResult<RateInsertMutation>;
export type RateInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RateInsertMutation, RateInsertMutationVariables>;
export const RateUpdateDocument = gql`
    mutation RateUpdate($RateInput: RateInput!) {
  RateUpdate(RateInput: $RateInput)
}
    `;
export type RateUpdateMutationFn = ApolloReactCommon.MutationFunction<RateUpdateMutation, RateUpdateMutationVariables>;

/**
 * __useRateUpdateMutation__
 *
 * To run a mutation, you first call `useRateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateUpdateMutation, { data, loading, error }] = useRateUpdateMutation({
 *   variables: {
 *      RateInput: // value for 'RateInput'
 *   },
 * });
 */
export function useRateUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateUpdateMutation, RateUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RateUpdateMutation, RateUpdateMutationVariables>(RateUpdateDocument, baseOptions);
      }
export type RateUpdateMutationHookResult = ReturnType<typeof useRateUpdateMutation>;
export type RateUpdateMutationResult = ApolloReactCommon.MutationResult<RateUpdateMutation>;
export type RateUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RateUpdateMutation, RateUpdateMutationVariables>;
export const RateEffectiveListingDocument = gql`
    query RateEffectiveListing($RateID: String!) {
  RateEffectiveListing(RateID: $RateID) {
    ID
    IsActive
    ModifiedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    RateID
    StartDate
    EndDate
    RateElementIDs {
      ID
      Amount
      ComputationRule
    }
    RateAddOnIDs {
      ID
      Amount
      Description
    }
    Rate {
      ID
      RateTypeID
      RateCode
    }
  }
}
    `;

/**
 * __useRateEffectiveListingQuery__
 *
 * To run a query within a React component, call `useRateEffectiveListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateEffectiveListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateEffectiveListingQuery({
 *   variables: {
 *      RateID: // value for 'RateID'
 *   },
 * });
 */
export function useRateEffectiveListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RateEffectiveListingQuery, RateEffectiveListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RateEffectiveListingQuery, RateEffectiveListingQueryVariables>(RateEffectiveListingDocument, baseOptions);
      }
export function useRateEffectiveListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RateEffectiveListingQuery, RateEffectiveListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RateEffectiveListingQuery, RateEffectiveListingQueryVariables>(RateEffectiveListingDocument, baseOptions);
        }
export type RateEffectiveListingQueryHookResult = ReturnType<typeof useRateEffectiveListingQuery>;
export type RateEffectiveListingLazyQueryHookResult = ReturnType<typeof useRateEffectiveListingLazyQuery>;
export type RateEffectiveListingQueryResult = ApolloReactCommon.QueryResult<RateEffectiveListingQuery, RateEffectiveListingQueryVariables>;
export const RateEffectiveInsertDocument = gql`
    mutation RateEffectiveInsert($SeasonalRateInput: [SeasonalRateInput!], $RateEffectiveInput: RateEffectiveInput!) {
  RateEffectiveInsert(SeasonalRateInput: $SeasonalRateInput, RateEffectiveInput: $RateEffectiveInput)
}
    `;
export type RateEffectiveInsertMutationFn = ApolloReactCommon.MutationFunction<RateEffectiveInsertMutation, RateEffectiveInsertMutationVariables>;

/**
 * __useRateEffectiveInsertMutation__
 *
 * To run a mutation, you first call `useRateEffectiveInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateEffectiveInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateEffectiveInsertMutation, { data, loading, error }] = useRateEffectiveInsertMutation({
 *   variables: {
 *      SeasonalRateInput: // value for 'SeasonalRateInput'
 *      RateEffectiveInput: // value for 'RateEffectiveInput'
 *   },
 * });
 */
export function useRateEffectiveInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateEffectiveInsertMutation, RateEffectiveInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RateEffectiveInsertMutation, RateEffectiveInsertMutationVariables>(RateEffectiveInsertDocument, baseOptions);
      }
export type RateEffectiveInsertMutationHookResult = ReturnType<typeof useRateEffectiveInsertMutation>;
export type RateEffectiveInsertMutationResult = ApolloReactCommon.MutationResult<RateEffectiveInsertMutation>;
export type RateEffectiveInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RateEffectiveInsertMutation, RateEffectiveInsertMutationVariables>;
export const RateEffectiveUpdateDocument = gql`
    mutation RateEffectiveUpdate($SeasonalRateInput: [SeasonalRateInput!], $RateEffectiveInput: RateEffectiveInput!) {
  RateEffectiveUpdate(SeasonalRateInput: $SeasonalRateInput, RateEffectiveInput: $RateEffectiveInput)
}
    `;
export type RateEffectiveUpdateMutationFn = ApolloReactCommon.MutationFunction<RateEffectiveUpdateMutation, RateEffectiveUpdateMutationVariables>;

/**
 * __useRateEffectiveUpdateMutation__
 *
 * To run a mutation, you first call `useRateEffectiveUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateEffectiveUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateEffectiveUpdateMutation, { data, loading, error }] = useRateEffectiveUpdateMutation({
 *   variables: {
 *      SeasonalRateInput: // value for 'SeasonalRateInput'
 *      RateEffectiveInput: // value for 'RateEffectiveInput'
 *   },
 * });
 */
export function useRateEffectiveUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateEffectiveUpdateMutation, RateEffectiveUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RateEffectiveUpdateMutation, RateEffectiveUpdateMutationVariables>(RateEffectiveUpdateDocument, baseOptions);
      }
export type RateEffectiveUpdateMutationHookResult = ReturnType<typeof useRateEffectiveUpdateMutation>;
export type RateEffectiveUpdateMutationResult = ApolloReactCommon.MutationResult<RateEffectiveUpdateMutation>;
export type RateEffectiveUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RateEffectiveUpdateMutation, RateEffectiveUpdateMutationVariables>;
export const RateEffectiveStatusUpdateDocument = gql`
    mutation RateEffectiveStatusUpdate($RateEffectiveID: String!, $IsActive: Boolean!) {
  RateEffectiveStatusUpdate(RateEffectiveID: $RateEffectiveID, IsActive: $IsActive)
}
    `;
export type RateEffectiveStatusUpdateMutationFn = ApolloReactCommon.MutationFunction<RateEffectiveStatusUpdateMutation, RateEffectiveStatusUpdateMutationVariables>;

/**
 * __useRateEffectiveStatusUpdateMutation__
 *
 * To run a mutation, you first call `useRateEffectiveStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateEffectiveStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateEffectiveStatusUpdateMutation, { data, loading, error }] = useRateEffectiveStatusUpdateMutation({
 *   variables: {
 *      RateEffectiveID: // value for 'RateEffectiveID'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useRateEffectiveStatusUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RateEffectiveStatusUpdateMutation, RateEffectiveStatusUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RateEffectiveStatusUpdateMutation, RateEffectiveStatusUpdateMutationVariables>(RateEffectiveStatusUpdateDocument, baseOptions);
      }
export type RateEffectiveStatusUpdateMutationHookResult = ReturnType<typeof useRateEffectiveStatusUpdateMutation>;
export type RateEffectiveStatusUpdateMutationResult = ApolloReactCommon.MutationResult<RateEffectiveStatusUpdateMutation>;
export type RateEffectiveStatusUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RateEffectiveStatusUpdateMutation, RateEffectiveStatusUpdateMutationVariables>;
export const RatePolicyInfoDocument = gql`
    query RatePolicyInfo($HotelID: String!) {
  RatePolicyInfo(HotelID: $HotelID) {
    TotalSeason
    TotalAddOn
    SeasonCalendarDate
    RateElementDate
    RateSetupDate
    RateTypeDate
  }
}
    `;

/**
 * __useRatePolicyInfoQuery__
 *
 * To run a query within a React component, call `useRatePolicyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRatePolicyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRatePolicyInfoQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRatePolicyInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RatePolicyInfoQuery, RatePolicyInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<RatePolicyInfoQuery, RatePolicyInfoQueryVariables>(RatePolicyInfoDocument, baseOptions);
      }
export function useRatePolicyInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RatePolicyInfoQuery, RatePolicyInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RatePolicyInfoQuery, RatePolicyInfoQueryVariables>(RatePolicyInfoDocument, baseOptions);
        }
export type RatePolicyInfoQueryHookResult = ReturnType<typeof useRatePolicyInfoQuery>;
export type RatePolicyInfoLazyQueryHookResult = ReturnType<typeof useRatePolicyInfoLazyQuery>;
export type RatePolicyInfoQueryResult = ApolloReactCommon.QueryResult<RatePolicyInfoQuery, RatePolicyInfoQueryVariables>;
export const IsRateInUseDocument = gql`
    query IsRateInUse($RateID: String!, $RateEffectiveID: String!) {
  IsRateInUse(RateID: $RateID, RateEffectiveID: $RateEffectiveID)
}
    `;

/**
 * __useIsRateInUseQuery__
 *
 * To run a query within a React component, call `useIsRateInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsRateInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsRateInUseQuery({
 *   variables: {
 *      RateID: // value for 'RateID'
 *      RateEffectiveID: // value for 'RateEffectiveID'
 *   },
 * });
 */
export function useIsRateInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsRateInUseQuery, IsRateInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsRateInUseQuery, IsRateInUseQueryVariables>(IsRateInUseDocument, baseOptions);
      }
export function useIsRateInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsRateInUseQuery, IsRateInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsRateInUseQuery, IsRateInUseQueryVariables>(IsRateInUseDocument, baseOptions);
        }
export type IsRateInUseQueryHookResult = ReturnType<typeof useIsRateInUseQuery>;
export type IsRateInUseLazyQueryHookResult = ReturnType<typeof useIsRateInUseLazyQuery>;
export type IsRateInUseQueryResult = ApolloReactCommon.QueryResult<IsRateInUseQuery, IsRateInUseQueryVariables>;
export const CmsRateSyncDocument = gql`
    mutation CMSRateSync($EndDate: DateTime!, $StartDate: DateTime!, $RateID: String!, $HotelID: String!) {
  CMSRateSync(EndDate: $EndDate, StartDate: $StartDate, RateID: $RateID, HotelID: $HotelID)
}
    `;
export type CmsRateSyncMutationFn = ApolloReactCommon.MutationFunction<CmsRateSyncMutation, CmsRateSyncMutationVariables>;

/**
 * __useCmsRateSyncMutation__
 *
 * To run a mutation, you first call `useCmsRateSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCmsRateSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cmsRateSyncMutation, { data, loading, error }] = useCmsRateSyncMutation({
 *   variables: {
 *      EndDate: // value for 'EndDate'
 *      StartDate: // value for 'StartDate'
 *      RateID: // value for 'RateID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCmsRateSyncMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CmsRateSyncMutation, CmsRateSyncMutationVariables>) {
        return ApolloReactHooks.useMutation<CmsRateSyncMutation, CmsRateSyncMutationVariables>(CmsRateSyncDocument, baseOptions);
      }
export type CmsRateSyncMutationHookResult = ReturnType<typeof useCmsRateSyncMutation>;
export type CmsRateSyncMutationResult = ApolloReactCommon.MutationResult<CmsRateSyncMutation>;
export type CmsRateSyncMutationOptions = ApolloReactCommon.BaseMutationOptions<CmsRateSyncMutation, CmsRateSyncMutationVariables>;
export const SeasonListingCalenderDocument = gql`
    query SeasonListingCalender($HotelID: String!) {
  SeasonListingCalender(HotelID: $HotelID) {
    HotelID
    ID
    IsActive
    ModifiedDT
    Name
    RGBColor
  }
}
    `;

/**
 * __useSeasonListingCalenderQuery__
 *
 * To run a query within a React component, call `useSeasonListingCalenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonListingCalenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonListingCalenderQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useSeasonListingCalenderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SeasonListingCalenderQuery, SeasonListingCalenderQueryVariables>) {
        return ApolloReactHooks.useQuery<SeasonListingCalenderQuery, SeasonListingCalenderQueryVariables>(SeasonListingCalenderDocument, baseOptions);
      }
export function useSeasonListingCalenderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SeasonListingCalenderQuery, SeasonListingCalenderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SeasonListingCalenderQuery, SeasonListingCalenderQueryVariables>(SeasonListingCalenderDocument, baseOptions);
        }
export type SeasonListingCalenderQueryHookResult = ReturnType<typeof useSeasonListingCalenderQuery>;
export type SeasonListingCalenderLazyQueryHookResult = ReturnType<typeof useSeasonListingCalenderLazyQuery>;
export type SeasonListingCalenderQueryResult = ApolloReactCommon.QueryResult<SeasonListingCalenderQuery, SeasonListingCalenderQueryVariables>;
export const ReasonListingDocument = gql`
    query ReasonListing($DepartmentID: String) {
  ReasonListing(DepartmentID: $DepartmentID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    DepartmentID
    Description
    Code
  }
}
    `;

/**
 * __useReasonListingQuery__
 *
 * To run a query within a React component, call `useReasonListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReasonListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReasonListingQuery({
 *   variables: {
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useReasonListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReasonListingQuery, ReasonListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReasonListingQuery, ReasonListingQueryVariables>(ReasonListingDocument, baseOptions);
      }
export function useReasonListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReasonListingQuery, ReasonListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReasonListingQuery, ReasonListingQueryVariables>(ReasonListingDocument, baseOptions);
        }
export type ReasonListingQueryHookResult = ReturnType<typeof useReasonListingQuery>;
export type ReasonListingLazyQueryHookResult = ReturnType<typeof useReasonListingLazyQuery>;
export type ReasonListingQueryResult = ApolloReactCommon.QueryResult<ReasonListingQuery, ReasonListingQueryVariables>;
export const ReaonInUseListingDocument = gql`
    query ReaonInUseListing($ID: String!) {
  ReaonInUseListing(ID: $ID)
}
    `;

/**
 * __useReaonInUseListingQuery__
 *
 * To run a query within a React component, call `useReaonInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReaonInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReaonInUseListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useReaonInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReaonInUseListingQuery, ReaonInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ReaonInUseListingQuery, ReaonInUseListingQueryVariables>(ReaonInUseListingDocument, baseOptions);
      }
export function useReaonInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReaonInUseListingQuery, ReaonInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReaonInUseListingQuery, ReaonInUseListingQueryVariables>(ReaonInUseListingDocument, baseOptions);
        }
export type ReaonInUseListingQueryHookResult = ReturnType<typeof useReaonInUseListingQuery>;
export type ReaonInUseListingLazyQueryHookResult = ReturnType<typeof useReaonInUseListingLazyQuery>;
export type ReaonInUseListingQueryResult = ApolloReactCommon.QueryResult<ReaonInUseListingQuery, ReaonInUseListingQueryVariables>;
export const ReasonInsertDocument = gql`
    mutation ReasonInsert($ReasonInput: ReasonInput!) {
  ReasonInsert(ReasonInput: $ReasonInput)
}
    `;
export type ReasonInsertMutationFn = ApolloReactCommon.MutationFunction<ReasonInsertMutation, ReasonInsertMutationVariables>;

/**
 * __useReasonInsertMutation__
 *
 * To run a mutation, you first call `useReasonInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReasonInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reasonInsertMutation, { data, loading, error }] = useReasonInsertMutation({
 *   variables: {
 *      ReasonInput: // value for 'ReasonInput'
 *   },
 * });
 */
export function useReasonInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReasonInsertMutation, ReasonInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<ReasonInsertMutation, ReasonInsertMutationVariables>(ReasonInsertDocument, baseOptions);
      }
export type ReasonInsertMutationHookResult = ReturnType<typeof useReasonInsertMutation>;
export type ReasonInsertMutationResult = ApolloReactCommon.MutationResult<ReasonInsertMutation>;
export type ReasonInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<ReasonInsertMutation, ReasonInsertMutationVariables>;
export const ReasonUpdateDocument = gql`
    mutation ReasonUpdate($ReasonInput: ReasonInput!) {
  ReasonUpdate(ReasonInput: $ReasonInput)
}
    `;
export type ReasonUpdateMutationFn = ApolloReactCommon.MutationFunction<ReasonUpdateMutation, ReasonUpdateMutationVariables>;

/**
 * __useReasonUpdateMutation__
 *
 * To run a mutation, you first call `useReasonUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReasonUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reasonUpdateMutation, { data, loading, error }] = useReasonUpdateMutation({
 *   variables: {
 *      ReasonInput: // value for 'ReasonInput'
 *   },
 * });
 */
export function useReasonUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReasonUpdateMutation, ReasonUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ReasonUpdateMutation, ReasonUpdateMutationVariables>(ReasonUpdateDocument, baseOptions);
      }
export type ReasonUpdateMutationHookResult = ReturnType<typeof useReasonUpdateMutation>;
export type ReasonUpdateMutationResult = ApolloReactCommon.MutationResult<ReasonUpdateMutation>;
export type ReasonUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ReasonUpdateMutation, ReasonUpdateMutationVariables>;
export const ReasonDeleteDocument = gql`
    mutation ReasonDelete($ID: String!) {
  ReasonDelete(ID: $ID)
}
    `;
export type ReasonDeleteMutationFn = ApolloReactCommon.MutationFunction<ReasonDeleteMutation, ReasonDeleteMutationVariables>;

/**
 * __useReasonDeleteMutation__
 *
 * To run a mutation, you first call `useReasonDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReasonDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reasonDeleteMutation, { data, loading, error }] = useReasonDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useReasonDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReasonDeleteMutation, ReasonDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ReasonDeleteMutation, ReasonDeleteMutationVariables>(ReasonDeleteDocument, baseOptions);
      }
export type ReasonDeleteMutationHookResult = ReturnType<typeof useReasonDeleteMutation>;
export type ReasonDeleteMutationResult = ApolloReactCommon.MutationResult<ReasonDeleteMutation>;
export type ReasonDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ReasonDeleteMutation, ReasonDeleteMutationVariables>;
export const RegenBsQueueInsertDocument = gql`
    mutation RegenBSQueueInsert($HotelID: String, $ArrStartDate: DateTime, $ArrEndDate: DateTime, $RateCodeID: String, $RoomTypeID: String, $BookingNo: String, $FullName: String, $IsCms: Boolean) {
  RegenBSQueueInsert(HotelID: $HotelID, ArrStartDate: $ArrStartDate, ArrEndDate: $ArrEndDate, RateCodeID: $RateCodeID, RoomTypeID: $RoomTypeID, BookingNo: $BookingNo, FullName: $FullName, IsCms: $IsCms)
}
    `;
export type RegenBsQueueInsertMutationFn = ApolloReactCommon.MutationFunction<RegenBsQueueInsertMutation, RegenBsQueueInsertMutationVariables>;

/**
 * __useRegenBsQueueInsertMutation__
 *
 * To run a mutation, you first call `useRegenBsQueueInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenBsQueueInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenBsQueueInsertMutation, { data, loading, error }] = useRegenBsQueueInsertMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ArrStartDate: // value for 'ArrStartDate'
 *      ArrEndDate: // value for 'ArrEndDate'
 *      RateCodeID: // value for 'RateCodeID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      BookingNo: // value for 'BookingNo'
 *      FullName: // value for 'FullName'
 *      IsCms: // value for 'IsCms'
 *   },
 * });
 */
export function useRegenBsQueueInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegenBsQueueInsertMutation, RegenBsQueueInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RegenBsQueueInsertMutation, RegenBsQueueInsertMutationVariables>(RegenBsQueueInsertDocument, baseOptions);
      }
export type RegenBsQueueInsertMutationHookResult = ReturnType<typeof useRegenBsQueueInsertMutation>;
export type RegenBsQueueInsertMutationResult = ApolloReactCommon.MutationResult<RegenBsQueueInsertMutation>;
export type RegenBsQueueInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RegenBsQueueInsertMutation, RegenBsQueueInsertMutationVariables>;
export const RegenBsQueueListingDocument = gql`
    query RegenBSQueueListing($HotelID: String) {
  RegenBSQueueListing(HotelID: $HotelID) {
    ID
    Status
    BatchNo
    Registration {
      ID
      ArrivalDate
      DepartureDate
      Booking {
        ID
        BookingNo
      }
      Guest {
        Contact {
          FullName
        }
      }
    }
  }
}
    `;

/**
 * __useRegenBsQueueListingQuery__
 *
 * To run a query within a React component, call `useRegenBsQueueListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegenBsQueueListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegenBsQueueListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRegenBsQueueListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegenBsQueueListingQuery, RegenBsQueueListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RegenBsQueueListingQuery, RegenBsQueueListingQueryVariables>(RegenBsQueueListingDocument, baseOptions);
      }
export function useRegenBsQueueListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegenBsQueueListingQuery, RegenBsQueueListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegenBsQueueListingQuery, RegenBsQueueListingQueryVariables>(RegenBsQueueListingDocument, baseOptions);
        }
export type RegenBsQueueListingQueryHookResult = ReturnType<typeof useRegenBsQueueListingQuery>;
export type RegenBsQueueListingLazyQueryHookResult = ReturnType<typeof useRegenBsQueueListingLazyQuery>;
export type RegenBsQueueListingQueryResult = ApolloReactCommon.QueryResult<RegenBsQueueListingQuery, RegenBsQueueListingQueryVariables>;
export const RegenBsQueueConfirmDocument = gql`
    mutation RegenBSQueueConfirm($HotelID: String) {
  RegenBSQueueConfirm(HotelID: $HotelID)
}
    `;
export type RegenBsQueueConfirmMutationFn = ApolloReactCommon.MutationFunction<RegenBsQueueConfirmMutation, RegenBsQueueConfirmMutationVariables>;

/**
 * __useRegenBsQueueConfirmMutation__
 *
 * To run a mutation, you first call `useRegenBsQueueConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenBsQueueConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenBsQueueConfirmMutation, { data, loading, error }] = useRegenBsQueueConfirmMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRegenBsQueueConfirmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegenBsQueueConfirmMutation, RegenBsQueueConfirmMutationVariables>) {
        return ApolloReactHooks.useMutation<RegenBsQueueConfirmMutation, RegenBsQueueConfirmMutationVariables>(RegenBsQueueConfirmDocument, baseOptions);
      }
export type RegenBsQueueConfirmMutationHookResult = ReturnType<typeof useRegenBsQueueConfirmMutation>;
export type RegenBsQueueConfirmMutationResult = ApolloReactCommon.MutationResult<RegenBsQueueConfirmMutation>;
export type RegenBsQueueConfirmMutationOptions = ApolloReactCommon.BaseMutationOptions<RegenBsQueueConfirmMutation, RegenBsQueueConfirmMutationVariables>;
export const RoomListingDocument = gql`
    query RoomListing($HotelID: String!, $RoomTypeID: String, $IsActive: Boolean, $StartDate: DateTime, $EndDate: DateTime, $Location: [String!]) {
  RoomListing(HotelID: $HotelID, RoomTypeID: $RoomTypeID, IsActive: $IsActive, Location: $Location) {
    ID
    IsActive
    HotelID
    LocationID
    RoomTypeID
    RoomNo
    MaxGuest
    RoomSize
    DisplaySequence
    Description
    BlockRoomLog {
      ID
      IsRelease
    }
    RoomType {
      ID
      Code
      Description
      TotalRoom
    }
    Location {
      ID
      Code
      Description
    }
    RoomStatus {
      ID
      Description
      RGBColor
      Code
      VacantOrOccupied
      CleanOrDirty
    }
    Registrations {
      ID
      IsPrincipal
      Guest {
        Contact {
          FullName
          Nationality
          PhoneNo
          Email
          Address {
            address
            address2
            city
            state
            postCode
            AddressType
            country
            siteName
          }
        }
      }
      CheckInDate
      CheckOutDate
    }
    RoomAssignmentStatus(StartDate: $StartDate, EndDate: $EndDate) {
      ID
      Description
      RGBColor
      Code
      VacantOrOccupied
      CleanOrDirty
    }
    Registrations {
      ID
      CheckInDate
      CheckOutDate
      IsPrincipal
      Guest {
        Contact {
          Nationality
          FullName
          PhoneNo
          Email
          Address {
            address
            address2
            city
            state
            postCode
            AddressType
            country
            siteName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRoomListingQuery__
 *
 * To run a query within a React component, call `useRoomListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      IsActive: // value for 'IsActive'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      Location: // value for 'Location'
 *   },
 * });
 */
export function useRoomListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomListingQuery, RoomListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomListingQuery, RoomListingQueryVariables>(RoomListingDocument, baseOptions);
      }
export function useRoomListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomListingQuery, RoomListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomListingQuery, RoomListingQueryVariables>(RoomListingDocument, baseOptions);
        }
export type RoomListingQueryHookResult = ReturnType<typeof useRoomListingQuery>;
export type RoomListingLazyQueryHookResult = ReturnType<typeof useRoomListingLazyQuery>;
export type RoomListingQueryResult = ApolloReactCommon.QueryResult<RoomListingQuery, RoomListingQueryVariables>;
export const RoomListDocument = gql`
    query RoomList($HotelID: String!, $RoomTypeID: String) {
  RoomListing(HotelID: $HotelID, RoomTypeID: $RoomTypeID) {
    ID
    RoomNo
    BlockRoomLog {
      IsRelease
      BlockDate
      ReleaseDate
    }
    Registrations {
      ID
      CheckInDate
      CheckOutDate
      IsPrincipal
      RegistrationStatus
      ArrivalDate
      DepartureDate
      Guest {
        Contact {
          Nationality
          FullName
          PhoneNo
          Email
          Address {
            address
            address2
            city
            state
            postCode
            AddressType
            country
            siteName
          }
        }
      }
    }
    Location {
      ID
      Code
      Description
    }
  }
}
    `;

/**
 * __useRoomListQuery__
 *
 * To run a query within a React component, call `useRoomListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *   },
 * });
 */
export function useRoomListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomListQuery, RoomListQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomListQuery, RoomListQueryVariables>(RoomListDocument, baseOptions);
      }
export function useRoomListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomListQuery, RoomListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomListQuery, RoomListQueryVariables>(RoomListDocument, baseOptions);
        }
export type RoomListQueryHookResult = ReturnType<typeof useRoomListQuery>;
export type RoomListLazyQueryHookResult = ReturnType<typeof useRoomListLazyQuery>;
export type RoomListQueryResult = ApolloReactCommon.QueryResult<RoomListQuery, RoomListQueryVariables>;
export const BlockRoomLogListingDocument = gql`
    query BlockRoomLogListing($HotelID: String!, $RoomID: String!) {
  BlockRoomLogListing(HotelID: $HotelID, RoomID: $RoomID)
}
    `;

/**
 * __useBlockRoomLogListingQuery__
 *
 * To run a query within a React component, call `useBlockRoomLogListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockRoomLogListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockRoomLogListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomID: // value for 'RoomID'
 *   },
 * });
 */
export function useBlockRoomLogListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BlockRoomLogListingQuery, BlockRoomLogListingQueryVariables>) {
        return ApolloReactHooks.useQuery<BlockRoomLogListingQuery, BlockRoomLogListingQueryVariables>(BlockRoomLogListingDocument, baseOptions);
      }
export function useBlockRoomLogListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BlockRoomLogListingQuery, BlockRoomLogListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BlockRoomLogListingQuery, BlockRoomLogListingQueryVariables>(BlockRoomLogListingDocument, baseOptions);
        }
export type BlockRoomLogListingQueryHookResult = ReturnType<typeof useBlockRoomLogListingQuery>;
export type BlockRoomLogListingLazyQueryHookResult = ReturnType<typeof useBlockRoomLogListingLazyQuery>;
export type BlockRoomLogListingQueryResult = ApolloReactCommon.QueryResult<BlockRoomLogListingQuery, BlockRoomLogListingQueryVariables>;
export const HotelRoomNumberListDocument = gql`
    query HotelRoomNumberList($HotelID: String!) {
  HotelRoomNumberList(HotelID: $HotelID) {
    ID
    RoomTypeID
    RoomNo
    IsActive
  }
}
    `;

/**
 * __useHotelRoomNumberListQuery__
 *
 * To run a query within a React component, call `useHotelRoomNumberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelRoomNumberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelRoomNumberListQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useHotelRoomNumberListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelRoomNumberListQuery, HotelRoomNumberListQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelRoomNumberListQuery, HotelRoomNumberListQueryVariables>(HotelRoomNumberListDocument, baseOptions);
      }
export function useHotelRoomNumberListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelRoomNumberListQuery, HotelRoomNumberListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelRoomNumberListQuery, HotelRoomNumberListQueryVariables>(HotelRoomNumberListDocument, baseOptions);
        }
export type HotelRoomNumberListQueryHookResult = ReturnType<typeof useHotelRoomNumberListQuery>;
export type HotelRoomNumberListLazyQueryHookResult = ReturnType<typeof useHotelRoomNumberListLazyQuery>;
export type HotelRoomNumberListQueryResult = ApolloReactCommon.QueryResult<HotelRoomNumberListQuery, HotelRoomNumberListQueryVariables>;
export const RoomUpdateDocument = gql`
    mutation RoomUpdate($RoomInput: RoomInput!) {
  RoomUpdate(RoomInput: $RoomInput)
}
    `;
export type RoomUpdateMutationFn = ApolloReactCommon.MutationFunction<RoomUpdateMutation, RoomUpdateMutationVariables>;

/**
 * __useRoomUpdateMutation__
 *
 * To run a mutation, you first call `useRoomUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomUpdateMutation, { data, loading, error }] = useRoomUpdateMutation({
 *   variables: {
 *      RoomInput: // value for 'RoomInput'
 *   },
 * });
 */
export function useRoomUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RoomUpdateMutation, RoomUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RoomUpdateMutation, RoomUpdateMutationVariables>(RoomUpdateDocument, baseOptions);
      }
export type RoomUpdateMutationHookResult = ReturnType<typeof useRoomUpdateMutation>;
export type RoomUpdateMutationResult = ApolloReactCommon.MutationResult<RoomUpdateMutation>;
export type RoomUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RoomUpdateMutation, RoomUpdateMutationVariables>;
export const RoomInsertDocument = gql`
    mutation RoomInsert($RoomInput: RoomInput!) {
  RoomInsert(RoomInput: $RoomInput) {
    ID
  }
}
    `;
export type RoomInsertMutationFn = ApolloReactCommon.MutationFunction<RoomInsertMutation, RoomInsertMutationVariables>;

/**
 * __useRoomInsertMutation__
 *
 * To run a mutation, you first call `useRoomInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomInsertMutation, { data, loading, error }] = useRoomInsertMutation({
 *   variables: {
 *      RoomInput: // value for 'RoomInput'
 *   },
 * });
 */
export function useRoomInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RoomInsertMutation, RoomInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RoomInsertMutation, RoomInsertMutationVariables>(RoomInsertDocument, baseOptions);
      }
export type RoomInsertMutationHookResult = ReturnType<typeof useRoomInsertMutation>;
export type RoomInsertMutationResult = ApolloReactCommon.MutationResult<RoomInsertMutation>;
export type RoomInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RoomInsertMutation, RoomInsertMutationVariables>;
export const RoomDeleteDocument = gql`
    mutation RoomDelete($ID: String!, $HotelID: String!, $RoomTypeID: String!) {
  RoomDelete(ID: $ID, HotelID: $HotelID, RoomTypeID: $RoomTypeID)
}
    `;
export type RoomDeleteMutationFn = ApolloReactCommon.MutationFunction<RoomDeleteMutation, RoomDeleteMutationVariables>;

/**
 * __useRoomDeleteMutation__
 *
 * To run a mutation, you first call `useRoomDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomDeleteMutation, { data, loading, error }] = useRoomDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      HotelID: // value for 'HotelID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *   },
 * });
 */
export function useRoomDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RoomDeleteMutation, RoomDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RoomDeleteMutation, RoomDeleteMutationVariables>(RoomDeleteDocument, baseOptions);
      }
export type RoomDeleteMutationHookResult = ReturnType<typeof useRoomDeleteMutation>;
export type RoomDeleteMutationResult = ApolloReactCommon.MutationResult<RoomDeleteMutation>;
export type RoomDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RoomDeleteMutation, RoomDeleteMutationVariables>;
export const RoomInHouseListingDocument = gql`
    query RoomInHouseListing($HotelID: String!, $RoomID: String!) {
  RoomInHouseListing(HotelID: $HotelID, RoomID: $RoomID)
}
    `;

/**
 * __useRoomInHouseListingQuery__
 *
 * To run a query within a React component, call `useRoomInHouseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomInHouseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomInHouseListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomID: // value for 'RoomID'
 *   },
 * });
 */
export function useRoomInHouseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomInHouseListingQuery, RoomInHouseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomInHouseListingQuery, RoomInHouseListingQueryVariables>(RoomInHouseListingDocument, baseOptions);
      }
export function useRoomInHouseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomInHouseListingQuery, RoomInHouseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomInHouseListingQuery, RoomInHouseListingQueryVariables>(RoomInHouseListingDocument, baseOptions);
        }
export type RoomInHouseListingQueryHookResult = ReturnType<typeof useRoomInHouseListingQuery>;
export type RoomInHouseListingLazyQueryHookResult = ReturnType<typeof useRoomInHouseListingLazyQuery>;
export type RoomInHouseListingQueryResult = ApolloReactCommon.QueryResult<RoomInHouseListingQuery, RoomInHouseListingQueryVariables>;
export const RoomBlockRoomListingDocument = gql`
    query RoomBlockRoomListing($HotelID: String!, $RoomID: String!) {
  RoomBlockRoomListing(HotelID: $HotelID, RoomID: $RoomID)
}
    `;

/**
 * __useRoomBlockRoomListingQuery__
 *
 * To run a query within a React component, call `useRoomBlockRoomListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomBlockRoomListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomBlockRoomListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      RoomID: // value for 'RoomID'
 *   },
 * });
 */
export function useRoomBlockRoomListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomBlockRoomListingQuery, RoomBlockRoomListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomBlockRoomListingQuery, RoomBlockRoomListingQueryVariables>(RoomBlockRoomListingDocument, baseOptions);
      }
export function useRoomBlockRoomListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomBlockRoomListingQuery, RoomBlockRoomListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomBlockRoomListingQuery, RoomBlockRoomListingQueryVariables>(RoomBlockRoomListingDocument, baseOptions);
        }
export type RoomBlockRoomListingQueryHookResult = ReturnType<typeof useRoomBlockRoomListingQuery>;
export type RoomBlockRoomListingLazyQueryHookResult = ReturnType<typeof useRoomBlockRoomListingLazyQuery>;
export type RoomBlockRoomListingQueryResult = ApolloReactCommon.QueryResult<RoomBlockRoomListingQuery, RoomBlockRoomListingQueryVariables>;
export const RoomSaleOptimizationListingDocument = gql`
    query RoomSaleOptimizationListing($StartDate: DateTime!, $HotelID: String!) {
  RoomSaleOptimizationListing(HotelID: $HotelID, StartDate: $StartDate) {
    RoomType {
      ID
      IsActive
      CreatedDT
      CreatedBy
      ModifiedDT
      ModifiedBy
      HotelID
      PropertyType
      Code
      Description
      TotalRoom
      MaxGuest
      RoomSize
      DisplaySequence
      RoomTypeAvailability {
        ID
        EffectiveDate
        BookedRoom
        BlockedRoom
        AllottedRoom
        TotalOccupied
        TotalVacant
        SeasonType
      }
    }
    Availability {
      ID
      RoomTypeID
      EffectiveDate
      BookedRoom
      BlockedRoom
      AllottedRoom
      DynamicRoom
      RoomType {
        ID
        TotalRoom
      }
    }
  }
}
    `;

/**
 * __useRoomSaleOptimizationListingQuery__
 *
 * To run a query within a React component, call `useRoomSaleOptimizationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomSaleOptimizationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomSaleOptimizationListingQuery({
 *   variables: {
 *      StartDate: // value for 'StartDate'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRoomSaleOptimizationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomSaleOptimizationListingQuery, RoomSaleOptimizationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomSaleOptimizationListingQuery, RoomSaleOptimizationListingQueryVariables>(RoomSaleOptimizationListingDocument, baseOptions);
      }
export function useRoomSaleOptimizationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomSaleOptimizationListingQuery, RoomSaleOptimizationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomSaleOptimizationListingQuery, RoomSaleOptimizationListingQueryVariables>(RoomSaleOptimizationListingDocument, baseOptions);
        }
export type RoomSaleOptimizationListingQueryHookResult = ReturnType<typeof useRoomSaleOptimizationListingQuery>;
export type RoomSaleOptimizationListingLazyQueryHookResult = ReturnType<typeof useRoomSaleOptimizationListingLazyQuery>;
export type RoomSaleOptimizationListingQueryResult = ApolloReactCommon.QueryResult<RoomSaleOptimizationListingQuery, RoomSaleOptimizationListingQueryVariables>;
export const RoomSaleOptimizationUpdateDocument = gql`
    mutation RoomSaleOptimizationUpdate($input: [AvailabilityInput!]!) {
  RoomSaleOptimizationUpdate(input: $input)
}
    `;
export type RoomSaleOptimizationUpdateMutationFn = ApolloReactCommon.MutationFunction<RoomSaleOptimizationUpdateMutation, RoomSaleOptimizationUpdateMutationVariables>;

/**
 * __useRoomSaleOptimizationUpdateMutation__
 *
 * To run a mutation, you first call `useRoomSaleOptimizationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomSaleOptimizationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomSaleOptimizationUpdateMutation, { data, loading, error }] = useRoomSaleOptimizationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoomSaleOptimizationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RoomSaleOptimizationUpdateMutation, RoomSaleOptimizationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RoomSaleOptimizationUpdateMutation, RoomSaleOptimizationUpdateMutationVariables>(RoomSaleOptimizationUpdateDocument, baseOptions);
      }
export type RoomSaleOptimizationUpdateMutationHookResult = ReturnType<typeof useRoomSaleOptimizationUpdateMutation>;
export type RoomSaleOptimizationUpdateMutationResult = ApolloReactCommon.MutationResult<RoomSaleOptimizationUpdateMutation>;
export type RoomSaleOptimizationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RoomSaleOptimizationUpdateMutation, RoomSaleOptimizationUpdateMutationVariables>;
export const RoomStatusListingDocument = gql`
    query RoomStatusListing($HotelID: String!) {
  RoomStatusListing(HotelID: $HotelID) {
    ID
    HotelID
    Code
    RGBColor
    Description
    VacantOrOccupied
    CleanOrDirty
    IsActive
    CreatedBy
    ModifiedBy
  }
}
    `;

/**
 * __useRoomStatusListingQuery__
 *
 * To run a query within a React component, call `useRoomStatusListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomStatusListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomStatusListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useRoomStatusListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomStatusListingQuery, RoomStatusListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomStatusListingQuery, RoomStatusListingQueryVariables>(RoomStatusListingDocument, baseOptions);
      }
export function useRoomStatusListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomStatusListingQuery, RoomStatusListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomStatusListingQuery, RoomStatusListingQueryVariables>(RoomStatusListingDocument, baseOptions);
        }
export type RoomStatusListingQueryHookResult = ReturnType<typeof useRoomStatusListingQuery>;
export type RoomStatusListingLazyQueryHookResult = ReturnType<typeof useRoomStatusListingLazyQuery>;
export type RoomStatusListingQueryResult = ApolloReactCommon.QueryResult<RoomStatusListingQuery, RoomStatusListingQueryVariables>;
export const RoomStatusInsertDocument = gql`
    mutation RoomStatusInsert($RoomStatusInput: [RoomStatusInput!]!) {
  RoomStatusInsert(RoomStatusInput: $RoomStatusInput)
}
    `;
export type RoomStatusInsertMutationFn = ApolloReactCommon.MutationFunction<RoomStatusInsertMutation, RoomStatusInsertMutationVariables>;

/**
 * __useRoomStatusInsertMutation__
 *
 * To run a mutation, you first call `useRoomStatusInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomStatusInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomStatusInsertMutation, { data, loading, error }] = useRoomStatusInsertMutation({
 *   variables: {
 *      RoomStatusInput: // value for 'RoomStatusInput'
 *   },
 * });
 */
export function useRoomStatusInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RoomStatusInsertMutation, RoomStatusInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RoomStatusInsertMutation, RoomStatusInsertMutationVariables>(RoomStatusInsertDocument, baseOptions);
      }
export type RoomStatusInsertMutationHookResult = ReturnType<typeof useRoomStatusInsertMutation>;
export type RoomStatusInsertMutationResult = ApolloReactCommon.MutationResult<RoomStatusInsertMutation>;
export type RoomStatusInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RoomStatusInsertMutation, RoomStatusInsertMutationVariables>;
export const RoomTypeListingDocument = gql`
    query RoomTypeListing($HotelID: String!, $IsActive: Boolean) {
  RoomTypeListing(HotelID: $HotelID, IsActive: $IsActive) {
    ID
    Code
    Description
    TotalRoom
    MaxGuest
    IsActive
    DisplaySequence
    RoomSize
    HouseLimit
    Overbooking
    RoomTypeAmenities {
      ID
      Name
      HotelID
      RoomTypeID
      GalleryID
      IsSelected
    }
    MeasureType
    PropertyType
    RoomTypeGallery {
      Gallery {
        ID
        ImageURL
        BucketFileName
      }
    }
  }
}
    `;

/**
 * __useRoomTypeListingQuery__
 *
 * To run a query within a React component, call `useRoomTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoomTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoomTypeListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useRoomTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoomTypeListingQuery, RoomTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RoomTypeListingQuery, RoomTypeListingQueryVariables>(RoomTypeListingDocument, baseOptions);
      }
export function useRoomTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoomTypeListingQuery, RoomTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoomTypeListingQuery, RoomTypeListingQueryVariables>(RoomTypeListingDocument, baseOptions);
        }
export type RoomTypeListingQueryHookResult = ReturnType<typeof useRoomTypeListingQuery>;
export type RoomTypeListingLazyQueryHookResult = ReturnType<typeof useRoomTypeListingLazyQuery>;
export type RoomTypeListingQueryResult = ApolloReactCommon.QueryResult<RoomTypeListingQuery, RoomTypeListingQueryVariables>;
export const RegistrationRoomTypeListingDocument = gql`
    query RegistrationRoomTypeListing($RoomTypeID: String!) {
  RegistrationRoomTypeListing(RoomTypeID: $RoomTypeID)
}
    `;

/**
 * __useRegistrationRoomTypeListingQuery__
 *
 * To run a query within a React component, call `useRegistrationRoomTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationRoomTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationRoomTypeListingQuery({
 *   variables: {
 *      RoomTypeID: // value for 'RoomTypeID'
 *   },
 * });
 */
export function useRegistrationRoomTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistrationRoomTypeListingQuery, RegistrationRoomTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistrationRoomTypeListingQuery, RegistrationRoomTypeListingQueryVariables>(RegistrationRoomTypeListingDocument, baseOptions);
      }
export function useRegistrationRoomTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistrationRoomTypeListingQuery, RegistrationRoomTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistrationRoomTypeListingQuery, RegistrationRoomTypeListingQueryVariables>(RegistrationRoomTypeListingDocument, baseOptions);
        }
export type RegistrationRoomTypeListingQueryHookResult = ReturnType<typeof useRegistrationRoomTypeListingQuery>;
export type RegistrationRoomTypeListingLazyQueryHookResult = ReturnType<typeof useRegistrationRoomTypeListingLazyQuery>;
export type RegistrationRoomTypeListingQueryResult = ApolloReactCommon.QueryResult<RegistrationRoomTypeListingQuery, RegistrationRoomTypeListingQueryVariables>;
export const PlatformAmenitiesListingDocument = gql`
    query PlatformAmenitiesListing {
  PlatformAmenitiesListing {
    ID
    Name
    GalleryID
    IsActive
  }
}
    `;

/**
 * __usePlatformAmenitiesListingQuery__
 *
 * To run a query within a React component, call `usePlatformAmenitiesListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformAmenitiesListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformAmenitiesListingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlatformAmenitiesListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlatformAmenitiesListingQuery, PlatformAmenitiesListingQueryVariables>) {
        return ApolloReactHooks.useQuery<PlatformAmenitiesListingQuery, PlatformAmenitiesListingQueryVariables>(PlatformAmenitiesListingDocument, baseOptions);
      }
export function usePlatformAmenitiesListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlatformAmenitiesListingQuery, PlatformAmenitiesListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlatformAmenitiesListingQuery, PlatformAmenitiesListingQueryVariables>(PlatformAmenitiesListingDocument, baseOptions);
        }
export type PlatformAmenitiesListingQueryHookResult = ReturnType<typeof usePlatformAmenitiesListingQuery>;
export type PlatformAmenitiesListingLazyQueryHookResult = ReturnType<typeof usePlatformAmenitiesListingLazyQuery>;
export type PlatformAmenitiesListingQueryResult = ApolloReactCommon.QueryResult<PlatformAmenitiesListingQuery, PlatformAmenitiesListingQueryVariables>;
export const RoomTypeInsertDocument = gql`
    mutation RoomTypeInsert($RoomTypeInput: RoomTypeInput!, $RoomTypeAmenitiesInput: [RoomTypeAmenitiesInput!]!, $AttachmentInput: [Upload!]) {
  RoomTypeInsert(RoomTypeInput: $RoomTypeInput, RoomTypeAmenitiesInput: $RoomTypeAmenitiesInput, AttachmentInput: $AttachmentInput) {
    ID
    Description
    Code
  }
}
    `;
export type RoomTypeInsertMutationFn = ApolloReactCommon.MutationFunction<RoomTypeInsertMutation, RoomTypeInsertMutationVariables>;

/**
 * __useRoomTypeInsertMutation__
 *
 * To run a mutation, you first call `useRoomTypeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomTypeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomTypeInsertMutation, { data, loading, error }] = useRoomTypeInsertMutation({
 *   variables: {
 *      RoomTypeInput: // value for 'RoomTypeInput'
 *      RoomTypeAmenitiesInput: // value for 'RoomTypeAmenitiesInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useRoomTypeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RoomTypeInsertMutation, RoomTypeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<RoomTypeInsertMutation, RoomTypeInsertMutationVariables>(RoomTypeInsertDocument, baseOptions);
      }
export type RoomTypeInsertMutationHookResult = ReturnType<typeof useRoomTypeInsertMutation>;
export type RoomTypeInsertMutationResult = ApolloReactCommon.MutationResult<RoomTypeInsertMutation>;
export type RoomTypeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<RoomTypeInsertMutation, RoomTypeInsertMutationVariables>;
export const RoomTypeUpdateDocument = gql`
    mutation RoomTypeUpdate($RoomTypeInput: RoomTypeInput!, $RoomTypeAmenitiesInput: [RoomTypeAmenitiesInput!], $AttachmentInput: [Upload!], $OldGalleryID: [String!]) {
  RoomTypeUpdate(RoomTypeInput: $RoomTypeInput, RoomTypeAmenitiesInput: $RoomTypeAmenitiesInput, AttachmentInput: $AttachmentInput, OldGalleryID: $OldGalleryID)
}
    `;
export type RoomTypeUpdateMutationFn = ApolloReactCommon.MutationFunction<RoomTypeUpdateMutation, RoomTypeUpdateMutationVariables>;

/**
 * __useRoomTypeUpdateMutation__
 *
 * To run a mutation, you first call `useRoomTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomTypeUpdateMutation, { data, loading, error }] = useRoomTypeUpdateMutation({
 *   variables: {
 *      RoomTypeInput: // value for 'RoomTypeInput'
 *      RoomTypeAmenitiesInput: // value for 'RoomTypeAmenitiesInput'
 *      AttachmentInput: // value for 'AttachmentInput'
 *      OldGalleryID: // value for 'OldGalleryID'
 *   },
 * });
 */
export function useRoomTypeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RoomTypeUpdateMutation, RoomTypeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<RoomTypeUpdateMutation, RoomTypeUpdateMutationVariables>(RoomTypeUpdateDocument, baseOptions);
      }
export type RoomTypeUpdateMutationHookResult = ReturnType<typeof useRoomTypeUpdateMutation>;
export type RoomTypeUpdateMutationResult = ApolloReactCommon.MutationResult<RoomTypeUpdateMutation>;
export type RoomTypeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<RoomTypeUpdateMutation, RoomTypeUpdateMutationVariables>;
export const RoomTypeDeleteDocument = gql`
    mutation RoomTypeDelete($ID: String!) {
  RoomTypeDelete(ID: $ID)
}
    `;
export type RoomTypeDeleteMutationFn = ApolloReactCommon.MutationFunction<RoomTypeDeleteMutation, RoomTypeDeleteMutationVariables>;

/**
 * __useRoomTypeDeleteMutation__
 *
 * To run a mutation, you first call `useRoomTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoomTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roomTypeDeleteMutation, { data, loading, error }] = useRoomTypeDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useRoomTypeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RoomTypeDeleteMutation, RoomTypeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<RoomTypeDeleteMutation, RoomTypeDeleteMutationVariables>(RoomTypeDeleteDocument, baseOptions);
      }
export type RoomTypeDeleteMutationHookResult = ReturnType<typeof useRoomTypeDeleteMutation>;
export type RoomTypeDeleteMutationResult = ApolloReactCommon.MutationResult<RoomTypeDeleteMutation>;
export type RoomTypeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<RoomTypeDeleteMutation, RoomTypeDeleteMutationVariables>;
export const SalesChannelListingDocument = gql`
    query SalesChannelListing($IsActive: Boolean, $DepartmentID: String!) {
  SalesChannelListing(IsActive: $IsActive, DepartmentID: $DepartmentID) {
    ID
    IsActive
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    DepartmentID
    Description
  }
}
    `;

/**
 * __useSalesChannelListingQuery__
 *
 * To run a query within a React component, call `useSalesChannelListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesChannelListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesChannelListingQuery({
 *   variables: {
 *      IsActive: // value for 'IsActive'
 *      DepartmentID: // value for 'DepartmentID'
 *   },
 * });
 */
export function useSalesChannelListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SalesChannelListingQuery, SalesChannelListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SalesChannelListingQuery, SalesChannelListingQueryVariables>(SalesChannelListingDocument, baseOptions);
      }
export function useSalesChannelListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SalesChannelListingQuery, SalesChannelListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SalesChannelListingQuery, SalesChannelListingQueryVariables>(SalesChannelListingDocument, baseOptions);
        }
export type SalesChannelListingQueryHookResult = ReturnType<typeof useSalesChannelListingQuery>;
export type SalesChannelListingLazyQueryHookResult = ReturnType<typeof useSalesChannelListingLazyQuery>;
export type SalesChannelListingQueryResult = ApolloReactCommon.QueryResult<SalesChannelListingQuery, SalesChannelListingQueryVariables>;
export const SalesChannelInsertDocument = gql`
    mutation SalesChannelInsert($SalesChannelInput: SalesChannelInput!) {
  SalesChannelInsert(SalesChannelInput: $SalesChannelInput)
}
    `;
export type SalesChannelInsertMutationFn = ApolloReactCommon.MutationFunction<SalesChannelInsertMutation, SalesChannelInsertMutationVariables>;

/**
 * __useSalesChannelInsertMutation__
 *
 * To run a mutation, you first call `useSalesChannelInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesChannelInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesChannelInsertMutation, { data, loading, error }] = useSalesChannelInsertMutation({
 *   variables: {
 *      SalesChannelInput: // value for 'SalesChannelInput'
 *   },
 * });
 */
export function useSalesChannelInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SalesChannelInsertMutation, SalesChannelInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<SalesChannelInsertMutation, SalesChannelInsertMutationVariables>(SalesChannelInsertDocument, baseOptions);
      }
export type SalesChannelInsertMutationHookResult = ReturnType<typeof useSalesChannelInsertMutation>;
export type SalesChannelInsertMutationResult = ApolloReactCommon.MutationResult<SalesChannelInsertMutation>;
export type SalesChannelInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<SalesChannelInsertMutation, SalesChannelInsertMutationVariables>;
export const SalesChannelinUseListingDocument = gql`
    query SalesChannelinUseListing($ID: String!) {
  SalesChannelinUseListing(ID: $ID)
}
    `;

/**
 * __useSalesChannelinUseListingQuery__
 *
 * To run a query within a React component, call `useSalesChannelinUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesChannelinUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesChannelinUseListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useSalesChannelinUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SalesChannelinUseListingQuery, SalesChannelinUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SalesChannelinUseListingQuery, SalesChannelinUseListingQueryVariables>(SalesChannelinUseListingDocument, baseOptions);
      }
export function useSalesChannelinUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SalesChannelinUseListingQuery, SalesChannelinUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SalesChannelinUseListingQuery, SalesChannelinUseListingQueryVariables>(SalesChannelinUseListingDocument, baseOptions);
        }
export type SalesChannelinUseListingQueryHookResult = ReturnType<typeof useSalesChannelinUseListingQuery>;
export type SalesChannelinUseListingLazyQueryHookResult = ReturnType<typeof useSalesChannelinUseListingLazyQuery>;
export type SalesChannelinUseListingQueryResult = ApolloReactCommon.QueryResult<SalesChannelinUseListingQuery, SalesChannelinUseListingQueryVariables>;
export const SalesChannelUpdateDocument = gql`
    mutation SalesChannelUpdate($SalesChannelInput: SalesChannelInput!) {
  SalesChannelUpdate(SalesChannelInput: $SalesChannelInput)
}
    `;
export type SalesChannelUpdateMutationFn = ApolloReactCommon.MutationFunction<SalesChannelUpdateMutation, SalesChannelUpdateMutationVariables>;

/**
 * __useSalesChannelUpdateMutation__
 *
 * To run a mutation, you first call `useSalesChannelUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesChannelUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesChannelUpdateMutation, { data, loading, error }] = useSalesChannelUpdateMutation({
 *   variables: {
 *      SalesChannelInput: // value for 'SalesChannelInput'
 *   },
 * });
 */
export function useSalesChannelUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SalesChannelUpdateMutation, SalesChannelUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SalesChannelUpdateMutation, SalesChannelUpdateMutationVariables>(SalesChannelUpdateDocument, baseOptions);
      }
export type SalesChannelUpdateMutationHookResult = ReturnType<typeof useSalesChannelUpdateMutation>;
export type SalesChannelUpdateMutationResult = ApolloReactCommon.MutationResult<SalesChannelUpdateMutation>;
export type SalesChannelUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SalesChannelUpdateMutation, SalesChannelUpdateMutationVariables>;
export const SalesChannelDeleteDocument = gql`
    mutation SalesChannelDelete($ID: String!) {
  SalesChannelDelete(ID: $ID)
}
    `;
export type SalesChannelDeleteMutationFn = ApolloReactCommon.MutationFunction<SalesChannelDeleteMutation, SalesChannelDeleteMutationVariables>;

/**
 * __useSalesChannelDeleteMutation__
 *
 * To run a mutation, you first call `useSalesChannelDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSalesChannelDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [salesChannelDeleteMutation, { data, loading, error }] = useSalesChannelDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useSalesChannelDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SalesChannelDeleteMutation, SalesChannelDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<SalesChannelDeleteMutation, SalesChannelDeleteMutationVariables>(SalesChannelDeleteDocument, baseOptions);
      }
export type SalesChannelDeleteMutationHookResult = ReturnType<typeof useSalesChannelDeleteMutation>;
export type SalesChannelDeleteMutationResult = ApolloReactCommon.MutationResult<SalesChannelDeleteMutation>;
export type SalesChannelDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<SalesChannelDeleteMutation, SalesChannelDeleteMutationVariables>;
export const SeasonCalendarListingDocument = gql`
    query SeasonCalendarListing($HotelID: String!, $CalendarDate: DateTime!) {
  SeasonCalendarListing(HotelID: $HotelID, CalendarDate: $CalendarDate) {
    ID
    HotelID
    ModifiedDT
    EffectiveDate
    SeasonID
  }
}
    `;

/**
 * __useSeasonCalendarListingQuery__
 *
 * To run a query within a React component, call `useSeasonCalendarListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonCalendarListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonCalendarListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      CalendarDate: // value for 'CalendarDate'
 *   },
 * });
 */
export function useSeasonCalendarListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SeasonCalendarListingQuery, SeasonCalendarListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SeasonCalendarListingQuery, SeasonCalendarListingQueryVariables>(SeasonCalendarListingDocument, baseOptions);
      }
export function useSeasonCalendarListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SeasonCalendarListingQuery, SeasonCalendarListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SeasonCalendarListingQuery, SeasonCalendarListingQueryVariables>(SeasonCalendarListingDocument, baseOptions);
        }
export type SeasonCalendarListingQueryHookResult = ReturnType<typeof useSeasonCalendarListingQuery>;
export type SeasonCalendarListingLazyQueryHookResult = ReturnType<typeof useSeasonCalendarListingLazyQuery>;
export type SeasonCalendarListingQueryResult = ApolloReactCommon.QueryResult<SeasonCalendarListingQuery, SeasonCalendarListingQueryVariables>;
export const SeasonCalendarUpdateDocument = gql`
    mutation SeasonCalendarUpdate($HotelID: String!, $CalendarDate: DateTime!, $SeasonCalendarInput: [SeasonCalendarInput!]!) {
  SeasonCalendarUpdate(SeasonCalendarInput: $SeasonCalendarInput, HotelID: $HotelID, CalendarDate: $CalendarDate)
}
    `;
export type SeasonCalendarUpdateMutationFn = ApolloReactCommon.MutationFunction<SeasonCalendarUpdateMutation, SeasonCalendarUpdateMutationVariables>;

/**
 * __useSeasonCalendarUpdateMutation__
 *
 * To run a mutation, you first call `useSeasonCalendarUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeasonCalendarUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seasonCalendarUpdateMutation, { data, loading, error }] = useSeasonCalendarUpdateMutation({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      CalendarDate: // value for 'CalendarDate'
 *      SeasonCalendarInput: // value for 'SeasonCalendarInput'
 *   },
 * });
 */
export function useSeasonCalendarUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SeasonCalendarUpdateMutation, SeasonCalendarUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SeasonCalendarUpdateMutation, SeasonCalendarUpdateMutationVariables>(SeasonCalendarUpdateDocument, baseOptions);
      }
export type SeasonCalendarUpdateMutationHookResult = ReturnType<typeof useSeasonCalendarUpdateMutation>;
export type SeasonCalendarUpdateMutationResult = ApolloReactCommon.MutationResult<SeasonCalendarUpdateMutation>;
export type SeasonCalendarUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SeasonCalendarUpdateMutation, SeasonCalendarUpdateMutationVariables>;
export const SegmentListingDocument = gql`
    query SegmentListing($HotelID: String!, $IsActive: Boolean) {
  SegmentListing(HotelID: $HotelID, IsActive: $IsActive) {
    ID
    Description
    HotelID
    IsActive
    DisplaySequence
    StayViewIcon
    Q3MarketSegmentMapping {
      RoomDebit
      RoomAnalysis
      RoomDepartment
      GuestsDebit
      GuestsAnalysis
      GuestsDepartment
    }
  }
}
    `;

/**
 * __useSegmentListingQuery__
 *
 * To run a query within a React component, call `useSegmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      IsActive: // value for 'IsActive'
 *   },
 * });
 */
export function useSegmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SegmentListingQuery, SegmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SegmentListingQuery, SegmentListingQueryVariables>(SegmentListingDocument, baseOptions);
      }
export function useSegmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SegmentListingQuery, SegmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SegmentListingQuery, SegmentListingQueryVariables>(SegmentListingDocument, baseOptions);
        }
export type SegmentListingQueryHookResult = ReturnType<typeof useSegmentListingQuery>;
export type SegmentListingLazyQueryHookResult = ReturnType<typeof useSegmentListingLazyQuery>;
export type SegmentListingQueryResult = ApolloReactCommon.QueryResult<SegmentListingQuery, SegmentListingQueryVariables>;
export const SegmentInUseListingDocument = gql`
    query SegmentInUseListing($HotelID: String!, $SegmentID: String!) {
  SegmentInUseListing(HotelID: $HotelID, SegmentID: $SegmentID)
}
    `;

/**
 * __useSegmentInUseListingQuery__
 *
 * To run a query within a React component, call `useSegmentInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSegmentInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSegmentInUseListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      SegmentID: // value for 'SegmentID'
 *   },
 * });
 */
export function useSegmentInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SegmentInUseListingQuery, SegmentInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SegmentInUseListingQuery, SegmentInUseListingQueryVariables>(SegmentInUseListingDocument, baseOptions);
      }
export function useSegmentInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SegmentInUseListingQuery, SegmentInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SegmentInUseListingQuery, SegmentInUseListingQueryVariables>(SegmentInUseListingDocument, baseOptions);
        }
export type SegmentInUseListingQueryHookResult = ReturnType<typeof useSegmentInUseListingQuery>;
export type SegmentInUseListingLazyQueryHookResult = ReturnType<typeof useSegmentInUseListingLazyQuery>;
export type SegmentInUseListingQueryResult = ApolloReactCommon.QueryResult<SegmentInUseListingQuery, SegmentInUseListingQueryVariables>;
export const SegmentInsertDocument = gql`
    mutation SegmentInsert($SegmentInput: SegmentInput!) {
  SegmentInsert(SegmentInput: $SegmentInput) {
    ID
    Description
    IsActive
  }
}
    `;
export type SegmentInsertMutationFn = ApolloReactCommon.MutationFunction<SegmentInsertMutation, SegmentInsertMutationVariables>;

/**
 * __useSegmentInsertMutation__
 *
 * To run a mutation, you first call `useSegmentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSegmentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [segmentInsertMutation, { data, loading, error }] = useSegmentInsertMutation({
 *   variables: {
 *      SegmentInput: // value for 'SegmentInput'
 *   },
 * });
 */
export function useSegmentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SegmentInsertMutation, SegmentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<SegmentInsertMutation, SegmentInsertMutationVariables>(SegmentInsertDocument, baseOptions);
      }
export type SegmentInsertMutationHookResult = ReturnType<typeof useSegmentInsertMutation>;
export type SegmentInsertMutationResult = ApolloReactCommon.MutationResult<SegmentInsertMutation>;
export type SegmentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<SegmentInsertMutation, SegmentInsertMutationVariables>;
export const SegmentUpdateDocument = gql`
    mutation SegmentUpdate($SegmentInput: SegmentInput!) {
  SegmentUpdate(SegmentInput: $SegmentInput)
}
    `;
export type SegmentUpdateMutationFn = ApolloReactCommon.MutationFunction<SegmentUpdateMutation, SegmentUpdateMutationVariables>;

/**
 * __useSegmentUpdateMutation__
 *
 * To run a mutation, you first call `useSegmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSegmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [segmentUpdateMutation, { data, loading, error }] = useSegmentUpdateMutation({
 *   variables: {
 *      SegmentInput: // value for 'SegmentInput'
 *   },
 * });
 */
export function useSegmentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SegmentUpdateMutation, SegmentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SegmentUpdateMutation, SegmentUpdateMutationVariables>(SegmentUpdateDocument, baseOptions);
      }
export type SegmentUpdateMutationHookResult = ReturnType<typeof useSegmentUpdateMutation>;
export type SegmentUpdateMutationResult = ApolloReactCommon.MutationResult<SegmentUpdateMutation>;
export type SegmentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SegmentUpdateMutation, SegmentUpdateMutationVariables>;
export const SegmentDeleteDocument = gql`
    mutation SegmentDelete($ID: String!, $Description: String!) {
  SegmentDelete(ID: $ID, Description: $Description)
}
    `;
export type SegmentDeleteMutationFn = ApolloReactCommon.MutationFunction<SegmentDeleteMutation, SegmentDeleteMutationVariables>;

/**
 * __useSegmentDeleteMutation__
 *
 * To run a mutation, you first call `useSegmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSegmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [segmentDeleteMutation, { data, loading, error }] = useSegmentDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      Description: // value for 'Description'
 *   },
 * });
 */
export function useSegmentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SegmentDeleteMutation, SegmentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<SegmentDeleteMutation, SegmentDeleteMutationVariables>(SegmentDeleteDocument, baseOptions);
      }
export type SegmentDeleteMutationHookResult = ReturnType<typeof useSegmentDeleteMutation>;
export type SegmentDeleteMutationResult = ApolloReactCommon.MutationResult<SegmentDeleteMutation>;
export type SegmentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<SegmentDeleteMutation, SegmentDeleteMutationVariables>;
export const TaxEffectiveDateListingDocument = gql`
    query TaxEffectiveDateListing {
  TaxEffectiveDateListing {
    ID
    Value
    Name
    IsFixAmount
  }
}
    `;

/**
 * __useTaxEffectiveDateListingQuery__
 *
 * To run a query within a React component, call `useTaxEffectiveDateListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxEffectiveDateListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxEffectiveDateListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaxEffectiveDateListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TaxEffectiveDateListingQuery, TaxEffectiveDateListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TaxEffectiveDateListingQuery, TaxEffectiveDateListingQueryVariables>(TaxEffectiveDateListingDocument, baseOptions);
      }
export function useTaxEffectiveDateListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaxEffectiveDateListingQuery, TaxEffectiveDateListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TaxEffectiveDateListingQuery, TaxEffectiveDateListingQueryVariables>(TaxEffectiveDateListingDocument, baseOptions);
        }
export type TaxEffectiveDateListingQueryHookResult = ReturnType<typeof useTaxEffectiveDateListingQuery>;
export type TaxEffectiveDateListingLazyQueryHookResult = ReturnType<typeof useTaxEffectiveDateListingLazyQuery>;
export type TaxEffectiveDateListingQueryResult = ApolloReactCommon.QueryResult<TaxEffectiveDateListingQuery, TaxEffectiveDateListingQueryVariables>;
export const TaxInUseListingDocument = gql`
    query TaxInUseListing($ID: String!) {
  TaxInUseListing(ID: $ID)
}
    `;

/**
 * __useTaxInUseListingQuery__
 *
 * To run a query within a React component, call `useTaxInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxInUseListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useTaxInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TaxInUseListingQuery, TaxInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TaxInUseListingQuery, TaxInUseListingQueryVariables>(TaxInUseListingDocument, baseOptions);
      }
export function useTaxInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaxInUseListingQuery, TaxInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TaxInUseListingQuery, TaxInUseListingQueryVariables>(TaxInUseListingDocument, baseOptions);
        }
export type TaxInUseListingQueryHookResult = ReturnType<typeof useTaxInUseListingQuery>;
export type TaxInUseListingLazyQueryHookResult = ReturnType<typeof useTaxInUseListingLazyQuery>;
export type TaxInUseListingQueryResult = ApolloReactCommon.QueryResult<TaxInUseListingQuery, TaxInUseListingQueryVariables>;
export const TaxPolicyListingDocument = gql`
    query TaxPolicyListing($HotelID: String!) {
  TaxPolicyListing(HotelID: $HotelID) {
    ID
    TaxType
    Description
    Value
    IsFixAmount
    IsTaxable
    EffectiveDate
    TTxExemptedReason
    TTxNonExemptedReason
    IsHouseUse
    IsComplimentary
  }
}
    `;

/**
 * __useTaxPolicyListingQuery__
 *
 * To run a query within a React component, call `useTaxPolicyListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxPolicyListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxPolicyListingQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useTaxPolicyListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TaxPolicyListingQuery, TaxPolicyListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TaxPolicyListingQuery, TaxPolicyListingQueryVariables>(TaxPolicyListingDocument, baseOptions);
      }
export function useTaxPolicyListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaxPolicyListingQuery, TaxPolicyListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TaxPolicyListingQuery, TaxPolicyListingQueryVariables>(TaxPolicyListingDocument, baseOptions);
        }
export type TaxPolicyListingQueryHookResult = ReturnType<typeof useTaxPolicyListingQuery>;
export type TaxPolicyListingLazyQueryHookResult = ReturnType<typeof useTaxPolicyListingLazyQuery>;
export type TaxPolicyListingQueryResult = ApolloReactCommon.QueryResult<TaxPolicyListingQuery, TaxPolicyListingQueryVariables>;
export const HotelTaxPolicyInsertDocument = gql`
    mutation HotelTaxPolicyInsert($HotelTaxPolicyInput: HotelTaxPolicyInput!) {
  HotelTaxPolicyInsert(HotelTaxPolicyInput: $HotelTaxPolicyInput)
}
    `;
export type HotelTaxPolicyInsertMutationFn = ApolloReactCommon.MutationFunction<HotelTaxPolicyInsertMutation, HotelTaxPolicyInsertMutationVariables>;

/**
 * __useHotelTaxPolicyInsertMutation__
 *
 * To run a mutation, you first call `useHotelTaxPolicyInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelTaxPolicyInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelTaxPolicyInsertMutation, { data, loading, error }] = useHotelTaxPolicyInsertMutation({
 *   variables: {
 *      HotelTaxPolicyInput: // value for 'HotelTaxPolicyInput'
 *   },
 * });
 */
export function useHotelTaxPolicyInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelTaxPolicyInsertMutation, HotelTaxPolicyInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelTaxPolicyInsertMutation, HotelTaxPolicyInsertMutationVariables>(HotelTaxPolicyInsertDocument, baseOptions);
      }
export type HotelTaxPolicyInsertMutationHookResult = ReturnType<typeof useHotelTaxPolicyInsertMutation>;
export type HotelTaxPolicyInsertMutationResult = ApolloReactCommon.MutationResult<HotelTaxPolicyInsertMutation>;
export type HotelTaxPolicyInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelTaxPolicyInsertMutation, HotelTaxPolicyInsertMutationVariables>;
export const HotelTaxPolicyUpdateDocument = gql`
    mutation HotelTaxPolicyUpdate($HotelTaxPolicyInput: HotelTaxPolicyInput!) {
  HotelTaxPolicyUpdate(HotelTaxPolicyInput: $HotelTaxPolicyInput)
}
    `;
export type HotelTaxPolicyUpdateMutationFn = ApolloReactCommon.MutationFunction<HotelTaxPolicyUpdateMutation, HotelTaxPolicyUpdateMutationVariables>;

/**
 * __useHotelTaxPolicyUpdateMutation__
 *
 * To run a mutation, you first call `useHotelTaxPolicyUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelTaxPolicyUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelTaxPolicyUpdateMutation, { data, loading, error }] = useHotelTaxPolicyUpdateMutation({
 *   variables: {
 *      HotelTaxPolicyInput: // value for 'HotelTaxPolicyInput'
 *   },
 * });
 */
export function useHotelTaxPolicyUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelTaxPolicyUpdateMutation, HotelTaxPolicyUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelTaxPolicyUpdateMutation, HotelTaxPolicyUpdateMutationVariables>(HotelTaxPolicyUpdateDocument, baseOptions);
      }
export type HotelTaxPolicyUpdateMutationHookResult = ReturnType<typeof useHotelTaxPolicyUpdateMutation>;
export type HotelTaxPolicyUpdateMutationResult = ApolloReactCommon.MutationResult<HotelTaxPolicyUpdateMutation>;
export type HotelTaxPolicyUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelTaxPolicyUpdateMutation, HotelTaxPolicyUpdateMutationVariables>;
export const HotelTaxPolicyDeleteDocument = gql`
    mutation HotelTaxPolicyDelete($ID: String!) {
  HotelTaxPolicyDelete(ID: $ID)
}
    `;
export type HotelTaxPolicyDeleteMutationFn = ApolloReactCommon.MutationFunction<HotelTaxPolicyDeleteMutation, HotelTaxPolicyDeleteMutationVariables>;

/**
 * __useHotelTaxPolicyDeleteMutation__
 *
 * To run a mutation, you first call `useHotelTaxPolicyDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHotelTaxPolicyDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hotelTaxPolicyDeleteMutation, { data, loading, error }] = useHotelTaxPolicyDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useHotelTaxPolicyDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<HotelTaxPolicyDeleteMutation, HotelTaxPolicyDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<HotelTaxPolicyDeleteMutation, HotelTaxPolicyDeleteMutationVariables>(HotelTaxPolicyDeleteDocument, baseOptions);
      }
export type HotelTaxPolicyDeleteMutationHookResult = ReturnType<typeof useHotelTaxPolicyDeleteMutation>;
export type HotelTaxPolicyDeleteMutationResult = ApolloReactCommon.MutationResult<HotelTaxPolicyDeleteMutation>;
export type HotelTaxPolicyDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<HotelTaxPolicyDeleteMutation, HotelTaxPolicyDeleteMutationVariables>;
export const CreateRolePermissionDocument = gql`
    mutation CreateRolePermission($permissionArr: [HotelPermission!]!, $input: RoleInput!) {
  CreateRolePermission(permissionArr: $permissionArr, input: $input)
}
    `;
export type CreateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;

/**
 * __useCreateRolePermissionMutation__
 *
 * To run a mutation, you first call `useCreateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRolePermissionMutation, { data, loading, error }] = useCreateRolePermissionMutation({
 *   variables: {
 *      permissionArr: // value for 'permissionArr'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>(CreateRolePermissionDocument, baseOptions);
      }
export type CreateRolePermissionMutationHookResult = ReturnType<typeof useCreateRolePermissionMutation>;
export type CreateRolePermissionMutationResult = ApolloReactCommon.MutationResult<CreateRolePermissionMutation>;
export type CreateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($ID: String) {
  getRole(ID: $ID) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    name
    MenuOption
    rolePerm {
      ID
      permName
    }
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = ApolloReactCommon.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const UpdateRolePermissionDocument = gql`
    mutation UpdateRolePermission($permissionArr: [HotelPermission!]!, $input: RoleInput!) {
  UpdateRolePermission(permissionArr: $permissionArr, input: $input)
}
    `;
export type UpdateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;

/**
 * __useUpdateRolePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolePermissionMutation, { data, loading, error }] = useUpdateRolePermissionMutation({
 *   variables: {
 *      permissionArr: // value for 'permissionArr'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>(UpdateRolePermissionDocument, baseOptions);
      }
export type UpdateRolePermissionMutationHookResult = ReturnType<typeof useUpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationResult = ApolloReactCommon.MutationResult<UpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;
export const DeleteRolePermissionDocument = gql`
    mutation DeleteRolePermission($roleID: String!) {
  DeleteRolePermission(roleID: $roleID)
}
    `;
export type DeleteRolePermissionMutationFn = ApolloReactCommon.MutationFunction<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;

/**
 * __useDeleteRolePermissionMutation__
 *
 * To run a mutation, you first call `useDeleteRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolePermissionMutation, { data, loading, error }] = useDeleteRolePermissionMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useDeleteRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>(DeleteRolePermissionDocument, baseOptions);
      }
export type DeleteRolePermissionMutationHookResult = ReturnType<typeof useDeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationResult = ApolloReactCommon.MutationResult<DeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;
export const CreateListEntityRoleUserDocument = gql`
    mutation createListEntityRoleUser($hotelID: String!, $roleUserIDs: [RoleUserInput!]!) {
  CreateListEntityRoleUser(hotelID: $hotelID, roleUserIDs: $roleUserIDs)
}
    `;
export type CreateListEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;

/**
 * __useCreateListEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateListEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListEntityRoleUserMutation, { data, loading, error }] = useCreateListEntityRoleUserMutation({
 *   variables: {
 *      hotelID: // value for 'hotelID'
 *      roleUserIDs: // value for 'roleUserIDs'
 *   },
 * });
 */
export function useCreateListEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>(CreateListEntityRoleUserDocument, baseOptions);
      }
export type CreateListEntityRoleUserMutationHookResult = ReturnType<typeof useCreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;
export const EntRoleUsrAsgListDocument = gql`
    query EntRoleUsrAsgList($ID: String, $entityID: String) {
  EntRoleUsrAsgList(ID: $ID, entityID: $entityID) {
    userID
    roleID
  }
}
    `;

/**
 * __useEntRoleUsrAsgListQuery__
 *
 * To run a query within a React component, call `useEntRoleUsrAsgListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntRoleUsrAsgListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntRoleUsrAsgListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useEntRoleUsrAsgListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntRoleUsrAsgListQuery, EntRoleUsrAsgListQueryVariables>) {
        return ApolloReactHooks.useQuery<EntRoleUsrAsgListQuery, EntRoleUsrAsgListQueryVariables>(EntRoleUsrAsgListDocument, baseOptions);
      }
export function useEntRoleUsrAsgListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntRoleUsrAsgListQuery, EntRoleUsrAsgListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntRoleUsrAsgListQuery, EntRoleUsrAsgListQueryVariables>(EntRoleUsrAsgListDocument, baseOptions);
        }
export type EntRoleUsrAsgListQueryHookResult = ReturnType<typeof useEntRoleUsrAsgListQuery>;
export type EntRoleUsrAsgListLazyQueryHookResult = ReturnType<typeof useEntRoleUsrAsgListLazyQuery>;
export type EntRoleUsrAsgListQueryResult = ApolloReactCommon.QueryResult<EntRoleUsrAsgListQuery, EntRoleUsrAsgListQueryVariables>;
export const MenuOptionUpdateDocument = gql`
    mutation MenuOptionUpdate($input: RoleInput!) {
  MenuOptionUpdate(input: $input)
}
    `;
export type MenuOptionUpdateMutationFn = ApolloReactCommon.MutationFunction<MenuOptionUpdateMutation, MenuOptionUpdateMutationVariables>;

/**
 * __useMenuOptionUpdateMutation__
 *
 * To run a mutation, you first call `useMenuOptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMenuOptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [menuOptionUpdateMutation, { data, loading, error }] = useMenuOptionUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMenuOptionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MenuOptionUpdateMutation, MenuOptionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<MenuOptionUpdateMutation, MenuOptionUpdateMutationVariables>(MenuOptionUpdateDocument, baseOptions);
      }
export type MenuOptionUpdateMutationHookResult = ReturnType<typeof useMenuOptionUpdateMutation>;
export type MenuOptionUpdateMutationResult = ApolloReactCommon.MutationResult<MenuOptionUpdateMutation>;
export type MenuOptionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<MenuOptionUpdateMutation, MenuOptionUpdateMutationVariables>;
export const TaxInsertDocument = gql`
    mutation TaxInsert($TaxInsertInput: TaxPolicyInput!, $AccountID: String!) {
  TaxInsert(TaxInsertInput: $TaxInsertInput, AccountID: $AccountID)
}
    `;
export type TaxInsertMutationFn = ApolloReactCommon.MutationFunction<TaxInsertMutation, TaxInsertMutationVariables>;

/**
 * __useTaxInsertMutation__
 *
 * To run a mutation, you first call `useTaxInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxInsertMutation, { data, loading, error }] = useTaxInsertMutation({
 *   variables: {
 *      TaxInsertInput: // value for 'TaxInsertInput'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useTaxInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxInsertMutation, TaxInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxInsertMutation, TaxInsertMutationVariables>(TaxInsertDocument, baseOptions);
      }
export type TaxInsertMutationHookResult = ReturnType<typeof useTaxInsertMutation>;
export type TaxInsertMutationResult = ApolloReactCommon.MutationResult<TaxInsertMutation>;
export type TaxInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxInsertMutation, TaxInsertMutationVariables>;
export const TaxUpdateDocument = gql`
    mutation TaxUpdate($TaxEditInput: EditTaxPolicyInput!, $AccountID: String!) {
  TaxUpdate(TaxEditInput: $TaxEditInput, AccountID: $AccountID)
}
    `;
export type TaxUpdateMutationFn = ApolloReactCommon.MutationFunction<TaxUpdateMutation, TaxUpdateMutationVariables>;

/**
 * __useTaxUpdateMutation__
 *
 * To run a mutation, you first call `useTaxUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxUpdateMutation, { data, loading, error }] = useTaxUpdateMutation({
 *   variables: {
 *      TaxEditInput: // value for 'TaxEditInput'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useTaxUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxUpdateMutation, TaxUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxUpdateMutation, TaxUpdateMutationVariables>(TaxUpdateDocument, baseOptions);
      }
export type TaxUpdateMutationHookResult = ReturnType<typeof useTaxUpdateMutation>;
export type TaxUpdateMutationResult = ApolloReactCommon.MutationResult<TaxUpdateMutation>;
export type TaxUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxUpdateMutation, TaxUpdateMutationVariables>;
export const GetTaxTypeListingDocument = gql`
    query GetTaxTypeListing($AccountID: String!) {
  GetTaxTypeListing(AccountID: $AccountID)
}
    `;

/**
 * __useGetTaxTypeListingQuery__
 *
 * To run a query within a React component, call `useGetTaxTypeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxTypeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxTypeListingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetTaxTypeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxTypeListingQuery, GetTaxTypeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxTypeListingQuery, GetTaxTypeListingQueryVariables>(GetTaxTypeListingDocument, baseOptions);
      }
export function useGetTaxTypeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxTypeListingQuery, GetTaxTypeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxTypeListingQuery, GetTaxTypeListingQueryVariables>(GetTaxTypeListingDocument, baseOptions);
        }
export type GetTaxTypeListingQueryHookResult = ReturnType<typeof useGetTaxTypeListingQuery>;
export type GetTaxTypeListingLazyQueryHookResult = ReturnType<typeof useGetTaxTypeListingLazyQuery>;
export type GetTaxTypeListingQueryResult = ApolloReactCommon.QueryResult<GetTaxTypeListingQuery, GetTaxTypeListingQueryVariables>;
export const GetTaxListingDocument = gql`
    query GetTaxListing($AccountID: String!) {
  GetTaxListing(AccountID: $AccountID)
}
    `;

/**
 * __useGetTaxListingQuery__
 *
 * To run a query within a React component, call `useGetTaxListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxListingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetTaxListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxListingQuery, GetTaxListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxListingQuery, GetTaxListingQueryVariables>(GetTaxListingDocument, baseOptions);
      }
export function useGetTaxListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxListingQuery, GetTaxListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxListingQuery, GetTaxListingQueryVariables>(GetTaxListingDocument, baseOptions);
        }
export type GetTaxListingQueryHookResult = ReturnType<typeof useGetTaxListingQuery>;
export type GetTaxListingLazyQueryHookResult = ReturnType<typeof useGetTaxListingLazyQuery>;
export type GetTaxListingQueryResult = ApolloReactCommon.QueryResult<GetTaxListingQuery, GetTaxListingQueryVariables>;
export const IsTaxInUseDocument = gql`
    query IsTaxInUse($TaxID: String!, $AccountID: String!) {
  IsTaxInUse(TaxID: $TaxID, AccountID: $AccountID)
}
    `;

/**
 * __useIsTaxInUseQuery__
 *
 * To run a query within a React component, call `useIsTaxInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTaxInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTaxInUseQuery({
 *   variables: {
 *      TaxID: // value for 'TaxID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useIsTaxInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsTaxInUseQuery, IsTaxInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsTaxInUseQuery, IsTaxInUseQueryVariables>(IsTaxInUseDocument, baseOptions);
      }
export function useIsTaxInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsTaxInUseQuery, IsTaxInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsTaxInUseQuery, IsTaxInUseQueryVariables>(IsTaxInUseDocument, baseOptions);
        }
export type IsTaxInUseQueryHookResult = ReturnType<typeof useIsTaxInUseQuery>;
export type IsTaxInUseLazyQueryHookResult = ReturnType<typeof useIsTaxInUseLazyQuery>;
export type IsTaxInUseQueryResult = ApolloReactCommon.QueryResult<IsTaxInUseQuery, IsTaxInUseQueryVariables>;
export const GetTaxDetailsDocument = gql`
    query GetTaxDetails($TaxID: String!, $AccountID: String!) {
  GetTaxDetails(TaxID: $TaxID, AccountID: $AccountID) {
    id
    accountId
    code
    description
    classType
    schemeTypeId
    isClaimable
    commonStatus
    taxDetail {
      id
      effectiveDate
      rate
    }
  }
}
    `;

/**
 * __useGetTaxDetailsQuery__
 *
 * To run a query within a React component, call `useGetTaxDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxDetailsQuery({
 *   variables: {
 *      TaxID: // value for 'TaxID'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetTaxDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxDetailsQuery, GetTaxDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxDetailsQuery, GetTaxDetailsQueryVariables>(GetTaxDetailsDocument, baseOptions);
      }
export function useGetTaxDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxDetailsQuery, GetTaxDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxDetailsQuery, GetTaxDetailsQueryVariables>(GetTaxDetailsDocument, baseOptions);
        }
export type GetTaxDetailsQueryHookResult = ReturnType<typeof useGetTaxDetailsQuery>;
export type GetTaxDetailsLazyQueryHookResult = ReturnType<typeof useGetTaxDetailsLazyQuery>;
export type GetTaxDetailsQueryResult = ApolloReactCommon.QueryResult<GetTaxDetailsQuery, GetTaxDetailsQueryVariables>;
export const TaxDeleteDocument = gql`
    mutation TaxDelete($TaxID: String!, $TaxDeleteInput: [TaxDetailInput!]!) {
  TaxDelete(TaxID: $TaxID, TaxDeleteInput: $TaxDeleteInput)
}
    `;
export type TaxDeleteMutationFn = ApolloReactCommon.MutationFunction<TaxDeleteMutation, TaxDeleteMutationVariables>;

/**
 * __useTaxDeleteMutation__
 *
 * To run a mutation, you first call `useTaxDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxDeleteMutation, { data, loading, error }] = useTaxDeleteMutation({
 *   variables: {
 *      TaxID: // value for 'TaxID'
 *      TaxDeleteInput: // value for 'TaxDeleteInput'
 *   },
 * });
 */
export function useTaxDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxDeleteMutation, TaxDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxDeleteMutation, TaxDeleteMutationVariables>(TaxDeleteDocument, baseOptions);
      }
export type TaxDeleteMutationHookResult = ReturnType<typeof useTaxDeleteMutation>;
export type TaxDeleteMutationResult = ApolloReactCommon.MutationResult<TaxDeleteMutation>;
export type TaxDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxDeleteMutation, TaxDeleteMutationVariables>;
export const IsTaxSameCodeDocument = gql`
    query IsTaxSameCode($AccountID: String!, $TaxCode: String!) {
  IsTaxSameCode(AccountID: $AccountID, TaxCode: $TaxCode)
}
    `;

/**
 * __useIsTaxSameCodeQuery__
 *
 * To run a query within a React component, call `useIsTaxSameCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTaxSameCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTaxSameCodeQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      TaxCode: // value for 'TaxCode'
 *   },
 * });
 */
export function useIsTaxSameCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsTaxSameCodeQuery, IsTaxSameCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<IsTaxSameCodeQuery, IsTaxSameCodeQueryVariables>(IsTaxSameCodeDocument, baseOptions);
      }
export function useIsTaxSameCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsTaxSameCodeQuery, IsTaxSameCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsTaxSameCodeQuery, IsTaxSameCodeQueryVariables>(IsTaxSameCodeDocument, baseOptions);
        }
export type IsTaxSameCodeQueryHookResult = ReturnType<typeof useIsTaxSameCodeQuery>;
export type IsTaxSameCodeLazyQueryHookResult = ReturnType<typeof useIsTaxSameCodeLazyQuery>;
export type IsTaxSameCodeQueryResult = ApolloReactCommon.QueryResult<IsTaxSameCodeQuery, IsTaxSameCodeQueryVariables>;
export const GetTaxSchemeListingDocument = gql`
    query GetTaxSchemeListing($AccountID: String!) {
  GetTaxSchemeListing(AccountID: $AccountID) {
    id
    accountId
    name
    description
    isExclusive
    commonStatus
    taxSchemeDetails {
      id
      accountId
      taxSchemeId
      effectiveDate
      commonStatus
      taxSchemeDetailItems {
        computeMethod
        id
        taxId
        seqNo
        taxOnSeq {
          taxSchemeDetailItemId
          seqNo
        }
      }
    }
  }
}
    `;

/**
 * __useGetTaxSchemeListingQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeListingQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetTaxSchemeListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>(GetTaxSchemeListingDocument, baseOptions);
      }
export function useGetTaxSchemeListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>(GetTaxSchemeListingDocument, baseOptions);
        }
export type GetTaxSchemeListingQueryHookResult = ReturnType<typeof useGetTaxSchemeListingQuery>;
export type GetTaxSchemeListingLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeListingLazyQuery>;
export type GetTaxSchemeListingQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeListingQuery, GetTaxSchemeListingQueryVariables>;
export const IsTaxSchemeInUseDocument = gql`
    query IsTaxSchemeInUse($TaxSchemeID: String!, $HotelID: String!) {
  IsTaxSchemeInUse(TaxSchemeID: $TaxSchemeID, HotelID: $HotelID)
}
    `;

/**
 * __useIsTaxSchemeInUseQuery__
 *
 * To run a query within a React component, call `useIsTaxSchemeInUseQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTaxSchemeInUseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTaxSchemeInUseQuery({
 *   variables: {
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useIsTaxSchemeInUseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsTaxSchemeInUseQuery, IsTaxSchemeInUseQueryVariables>) {
        return ApolloReactHooks.useQuery<IsTaxSchemeInUseQuery, IsTaxSchemeInUseQueryVariables>(IsTaxSchemeInUseDocument, baseOptions);
      }
export function useIsTaxSchemeInUseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsTaxSchemeInUseQuery, IsTaxSchemeInUseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsTaxSchemeInUseQuery, IsTaxSchemeInUseQueryVariables>(IsTaxSchemeInUseDocument, baseOptions);
        }
export type IsTaxSchemeInUseQueryHookResult = ReturnType<typeof useIsTaxSchemeInUseQuery>;
export type IsTaxSchemeInUseLazyQueryHookResult = ReturnType<typeof useIsTaxSchemeInUseLazyQuery>;
export type IsTaxSchemeInUseQueryResult = ApolloReactCommon.QueryResult<IsTaxSchemeInUseQuery, IsTaxSchemeInUseQueryVariables>;
export const GetTaxListingDropdownDocument = gql`
    query GetTaxListingDropdown($AccountID: String!) {
  GetTaxListingDropdown(AccountID: $AccountID) {
    tax_id
    code
    description
    class_type
    tax_status
    rate
    tax_type
  }
}
    `;

/**
 * __useGetTaxListingDropdownQuery__
 *
 * To run a query within a React component, call `useGetTaxListingDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxListingDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxListingDropdownQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetTaxListingDropdownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxListingDropdownQuery, GetTaxListingDropdownQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxListingDropdownQuery, GetTaxListingDropdownQueryVariables>(GetTaxListingDropdownDocument, baseOptions);
      }
export function useGetTaxListingDropdownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxListingDropdownQuery, GetTaxListingDropdownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxListingDropdownQuery, GetTaxListingDropdownQueryVariables>(GetTaxListingDropdownDocument, baseOptions);
        }
export type GetTaxListingDropdownQueryHookResult = ReturnType<typeof useGetTaxListingDropdownQuery>;
export type GetTaxListingDropdownLazyQueryHookResult = ReturnType<typeof useGetTaxListingDropdownLazyQuery>;
export type GetTaxListingDropdownQueryResult = ApolloReactCommon.QueryResult<GetTaxListingDropdownQuery, GetTaxListingDropdownQueryVariables>;
export const TaxSchemeDeleteDocument = gql`
    mutation TaxSchemeDelete($TaxSchemeID: String!, $AccountID: String!, $HotelID: String!) {
  TaxSchemeDelete(TaxSchemeID: $TaxSchemeID, AccountID: $AccountID, HotelID: $HotelID)
}
    `;
export type TaxSchemeDeleteMutationFn = ApolloReactCommon.MutationFunction<TaxSchemeDeleteMutation, TaxSchemeDeleteMutationVariables>;

/**
 * __useTaxSchemeDeleteMutation__
 *
 * To run a mutation, you first call `useTaxSchemeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxSchemeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxSchemeDeleteMutation, { data, loading, error }] = useTaxSchemeDeleteMutation({
 *   variables: {
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *      AccountID: // value for 'AccountID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useTaxSchemeDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxSchemeDeleteMutation, TaxSchemeDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxSchemeDeleteMutation, TaxSchemeDeleteMutationVariables>(TaxSchemeDeleteDocument, baseOptions);
      }
export type TaxSchemeDeleteMutationHookResult = ReturnType<typeof useTaxSchemeDeleteMutation>;
export type TaxSchemeDeleteMutationResult = ApolloReactCommon.MutationResult<TaxSchemeDeleteMutation>;
export type TaxSchemeDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxSchemeDeleteMutation, TaxSchemeDeleteMutationVariables>;
export const SetDefaultTaxSchemeDocument = gql`
    mutation SetDefaultTaxScheme($TaxSchemeID: String!, $AccountID: String!, $HotelID: String!) {
  SetDefaultTaxScheme(TaxSchemeID: $TaxSchemeID, AccountID: $AccountID, HotelID: $HotelID)
}
    `;
export type SetDefaultTaxSchemeMutationFn = ApolloReactCommon.MutationFunction<SetDefaultTaxSchemeMutation, SetDefaultTaxSchemeMutationVariables>;

/**
 * __useSetDefaultTaxSchemeMutation__
 *
 * To run a mutation, you first call `useSetDefaultTaxSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultTaxSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultTaxSchemeMutation, { data, loading, error }] = useSetDefaultTaxSchemeMutation({
 *   variables: {
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *      AccountID: // value for 'AccountID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useSetDefaultTaxSchemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDefaultTaxSchemeMutation, SetDefaultTaxSchemeMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDefaultTaxSchemeMutation, SetDefaultTaxSchemeMutationVariables>(SetDefaultTaxSchemeDocument, baseOptions);
      }
export type SetDefaultTaxSchemeMutationHookResult = ReturnType<typeof useSetDefaultTaxSchemeMutation>;
export type SetDefaultTaxSchemeMutationResult = ApolloReactCommon.MutationResult<SetDefaultTaxSchemeMutation>;
export type SetDefaultTaxSchemeMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDefaultTaxSchemeMutation, SetDefaultTaxSchemeMutationVariables>;
export const TaxSchemeInsertDocument = gql`
    mutation TaxSchemeInsert($TaxSchemeInput: TaxSchemeInput!, $AccountID: String!) {
  TaxSchemeInsert(TaxSchemeInput: $TaxSchemeInput, AccountID: $AccountID)
}
    `;
export type TaxSchemeInsertMutationFn = ApolloReactCommon.MutationFunction<TaxSchemeInsertMutation, TaxSchemeInsertMutationVariables>;

/**
 * __useTaxSchemeInsertMutation__
 *
 * To run a mutation, you first call `useTaxSchemeInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxSchemeInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxSchemeInsertMutation, { data, loading, error }] = useTaxSchemeInsertMutation({
 *   variables: {
 *      TaxSchemeInput: // value for 'TaxSchemeInput'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useTaxSchemeInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxSchemeInsertMutation, TaxSchemeInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxSchemeInsertMutation, TaxSchemeInsertMutationVariables>(TaxSchemeInsertDocument, baseOptions);
      }
export type TaxSchemeInsertMutationHookResult = ReturnType<typeof useTaxSchemeInsertMutation>;
export type TaxSchemeInsertMutationResult = ApolloReactCommon.MutationResult<TaxSchemeInsertMutation>;
export type TaxSchemeInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxSchemeInsertMutation, TaxSchemeInsertMutationVariables>;
export const TaxSchemeUpdateDocument = gql`
    mutation TaxSchemeUpdate($EditTaxSchemeInput: EditTaxSchemeInput!, $AccountID: String!) {
  TaxSchemeUpdate(EditTaxSchemeInput: $EditTaxSchemeInput, AccountID: $AccountID)
}
    `;
export type TaxSchemeUpdateMutationFn = ApolloReactCommon.MutationFunction<TaxSchemeUpdateMutation, TaxSchemeUpdateMutationVariables>;

/**
 * __useTaxSchemeUpdateMutation__
 *
 * To run a mutation, you first call `useTaxSchemeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaxSchemeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taxSchemeUpdateMutation, { data, loading, error }] = useTaxSchemeUpdateMutation({
 *   variables: {
 *      EditTaxSchemeInput: // value for 'EditTaxSchemeInput'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useTaxSchemeUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TaxSchemeUpdateMutation, TaxSchemeUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TaxSchemeUpdateMutation, TaxSchemeUpdateMutationVariables>(TaxSchemeUpdateDocument, baseOptions);
      }
export type TaxSchemeUpdateMutationHookResult = ReturnType<typeof useTaxSchemeUpdateMutation>;
export type TaxSchemeUpdateMutationResult = ApolloReactCommon.MutationResult<TaxSchemeUpdateMutation>;
export type TaxSchemeUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TaxSchemeUpdateMutation, TaxSchemeUpdateMutationVariables>;
export const IsTaxSameNameDocument = gql`
    query IsTaxSameName($AccountID: String!, $TaxSchemeName: String!) {
  IsTaxSameName(AccountID: $AccountID, TaxSchemeName: $TaxSchemeName)
}
    `;

/**
 * __useIsTaxSameNameQuery__
 *
 * To run a query within a React component, call `useIsTaxSameNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTaxSameNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTaxSameNameQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      TaxSchemeName: // value for 'TaxSchemeName'
 *   },
 * });
 */
export function useIsTaxSameNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsTaxSameNameQuery, IsTaxSameNameQueryVariables>) {
        return ApolloReactHooks.useQuery<IsTaxSameNameQuery, IsTaxSameNameQueryVariables>(IsTaxSameNameDocument, baseOptions);
      }
export function useIsTaxSameNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsTaxSameNameQuery, IsTaxSameNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsTaxSameNameQuery, IsTaxSameNameQueryVariables>(IsTaxSameNameDocument, baseOptions);
        }
export type IsTaxSameNameQueryHookResult = ReturnType<typeof useIsTaxSameNameQuery>;
export type IsTaxSameNameLazyQueryHookResult = ReturnType<typeof useIsTaxSameNameLazyQuery>;
export type IsTaxSameNameQueryResult = ApolloReactCommon.QueryResult<IsTaxSameNameQuery, IsTaxSameNameQueryVariables>;
export const GetTaxSchemeDetailDocument = gql`
    query GetTaxSchemeDetail($AccountID: String!, $TaxSchemeID: String!) {
  GetTaxSchemeDetail(AccountID: $AccountID, TaxSchemeID: $TaxSchemeID) {
    taxId
    taxRate
    taxCode
    taxType
    taxSeqNo
  }
}
    `;

/**
 * __useGetTaxSchemeDetailQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeDetailQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *   },
 * });
 */
export function useGetTaxSchemeDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>(GetTaxSchemeDetailDocument, baseOptions);
      }
export function useGetTaxSchemeDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>(GetTaxSchemeDetailDocument, baseOptions);
        }
export type GetTaxSchemeDetailQueryHookResult = ReturnType<typeof useGetTaxSchemeDetailQuery>;
export type GetTaxSchemeDetailLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeDetailLazyQuery>;
export type GetTaxSchemeDetailQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeDetailQuery, GetTaxSchemeDetailQueryVariables>;
export const ComputeBookingbyHotelDocument = gql`
    query ComputeBookingbyHotel($HotelID: String!, $input: [TaxInput!]!) {
  ComputeBookingbyHotel(HotelID: $HotelID, input: $input)
}
    `;

/**
 * __useComputeBookingbyHotelQuery__
 *
 * To run a query within a React component, call `useComputeBookingbyHotelQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeBookingbyHotelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeBookingbyHotelQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useComputeBookingbyHotelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ComputeBookingbyHotelQuery, ComputeBookingbyHotelQueryVariables>) {
        return ApolloReactHooks.useQuery<ComputeBookingbyHotelQuery, ComputeBookingbyHotelQueryVariables>(ComputeBookingbyHotelDocument, baseOptions);
      }
export function useComputeBookingbyHotelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ComputeBookingbyHotelQuery, ComputeBookingbyHotelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ComputeBookingbyHotelQuery, ComputeBookingbyHotelQueryVariables>(ComputeBookingbyHotelDocument, baseOptions);
        }
export type ComputeBookingbyHotelQueryHookResult = ReturnType<typeof useComputeBookingbyHotelQuery>;
export type ComputeBookingbyHotelLazyQueryHookResult = ReturnType<typeof useComputeBookingbyHotelLazyQuery>;
export type ComputeBookingbyHotelQueryResult = ApolloReactCommon.QueryResult<ComputeBookingbyHotelQuery, ComputeBookingbyHotelQueryVariables>;
export const ComputeTaxBookingDocument = gql`
    query ComputeTaxBooking($RoomTypeID: String!, $RateID: String!, $StartDate: DateTime!, $EndDate: DateTime!, $HotelID: String!, $DiscountAmount: Float!) {
  ComputeTaxBooking(RoomTypeID: $RoomTypeID, RateID: $RateID, StartDate: $StartDate, EndDate: $EndDate, HotelID: $HotelID, DiscountAmount: $DiscountAmount)
}
    `;

/**
 * __useComputeTaxBookingQuery__
 *
 * To run a query within a React component, call `useComputeTaxBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useComputeTaxBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useComputeTaxBookingQuery({
 *   variables: {
 *      RoomTypeID: // value for 'RoomTypeID'
 *      RateID: // value for 'RateID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *      HotelID: // value for 'HotelID'
 *      DiscountAmount: // value for 'DiscountAmount'
 *   },
 * });
 */
export function useComputeTaxBookingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ComputeTaxBookingQuery, ComputeTaxBookingQueryVariables>) {
        return ApolloReactHooks.useQuery<ComputeTaxBookingQuery, ComputeTaxBookingQueryVariables>(ComputeTaxBookingDocument, baseOptions);
      }
export function useComputeTaxBookingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ComputeTaxBookingQuery, ComputeTaxBookingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ComputeTaxBookingQuery, ComputeTaxBookingQueryVariables>(ComputeTaxBookingDocument, baseOptions);
        }
export type ComputeTaxBookingQueryHookResult = ReturnType<typeof useComputeTaxBookingQuery>;
export type ComputeTaxBookingLazyQueryHookResult = ReturnType<typeof useComputeTaxBookingLazyQuery>;
export type ComputeTaxBookingQueryResult = ApolloReactCommon.QueryResult<ComputeTaxBookingQuery, ComputeTaxBookingQueryVariables>;
export const GetUserDocument = gql`
    query getUser($ID: String!) {
  getUser(ID: $ID) {
    ID
    name
    department
    email
    jobTitle
    contactNo
    superUser
    avatar
    commonStatus
    userName
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const HotelAuthorizationInfoLineDocument = gql`
    query HotelAuthorizationInfoLine($AccountID: String!) {
  HotelAuthorizationInfoLine(AccountID: $AccountID)
}
    `;

/**
 * __useHotelAuthorizationInfoLineQuery__
 *
 * To run a query within a React component, call `useHotelAuthorizationInfoLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotelAuthorizationInfoLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotelAuthorizationInfoLineQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useHotelAuthorizationInfoLineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HotelAuthorizationInfoLineQuery, HotelAuthorizationInfoLineQueryVariables>) {
        return ApolloReactHooks.useQuery<HotelAuthorizationInfoLineQuery, HotelAuthorizationInfoLineQueryVariables>(HotelAuthorizationInfoLineDocument, baseOptions);
      }
export function useHotelAuthorizationInfoLineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HotelAuthorizationInfoLineQuery, HotelAuthorizationInfoLineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HotelAuthorizationInfoLineQuery, HotelAuthorizationInfoLineQueryVariables>(HotelAuthorizationInfoLineDocument, baseOptions);
        }
export type HotelAuthorizationInfoLineQueryHookResult = ReturnType<typeof useHotelAuthorizationInfoLineQuery>;
export type HotelAuthorizationInfoLineLazyQueryHookResult = ReturnType<typeof useHotelAuthorizationInfoLineLazyQuery>;
export type HotelAuthorizationInfoLineQueryResult = ApolloReactCommon.QueryResult<HotelAuthorizationInfoLineQuery, HotelAuthorizationInfoLineQueryVariables>;
export const GetUsersByAccountDocument = gql`
    query getUsersByAccount($accountID: String!, $HotelID: String) {
  getUsersByAccount(accountID: $accountID, HotelID: $HotelID) {
    ID
    userName
    email
    contactNo
    commonStatus
    confirmed
    avatar
    superUser
    accountID
    softwareCode
    name
    department
    jobTitle
  }
}
    `;

/**
 * __useGetUsersByAccountQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useGetUsersByAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>(GetUsersByAccountDocument, baseOptions);
      }
export function useGetUsersByAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>(GetUsersByAccountDocument, baseOptions);
        }
export type GetUsersByAccountQueryHookResult = ReturnType<typeof useGetUsersByAccountQuery>;
export type GetUsersByAccountLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountLazyQuery>;
export type GetUsersByAccountQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountQuery, GetUsersByAccountQueryVariables>;
export const RoleViewDocument = gql`
    query RoleView($HotelID: String, $UserID: String) {
  RoleView(HotelID: $HotelID, UserID: $UserID)
}
    `;

/**
 * __useRoleViewQuery__
 *
 * To run a query within a React component, call `useRoleViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleViewQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      UserID: // value for 'UserID'
 *   },
 * });
 */
export function useRoleViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RoleViewQuery, RoleViewQueryVariables>) {
        return ApolloReactHooks.useQuery<RoleViewQuery, RoleViewQueryVariables>(RoleViewDocument, baseOptions);
      }
export function useRoleViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RoleViewQuery, RoleViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RoleViewQuery, RoleViewQueryVariables>(RoleViewDocument, baseOptions);
        }
export type RoleViewQueryHookResult = ReturnType<typeof useRoleViewQuery>;
export type RoleViewLazyQueryHookResult = ReturnType<typeof useRoleViewLazyQuery>;
export type RoleViewQueryResult = ApolloReactCommon.QueryResult<RoleViewQuery, RoleViewQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInput!, $HotelID: String!) {
  createUser(input: $input, HotelID: $HotelID) {
    ID
    email
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserInput!) {
  updateUser(input: $input) {
    ID
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UserInput!, $AttachmentInput: [Upload!]) {
  updateProfile(input: $input, AttachmentInput: $AttachmentInput)
}
    `;
export type UpdateProfileMutationFn = ApolloReactCommon.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      AttachmentInput: // value for 'AttachmentInput'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, baseOptions);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = ApolloReactCommon.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UnLockUserDocument = gql`
    mutation unLockUser($password: String!, $accountID: String!, $userID: String!) {
  unLockUser(password: $password, accountID: $accountID, userID: $userID)
}
    `;
export type UnLockUserMutationFn = ApolloReactCommon.MutationFunction<UnLockUserMutation, UnLockUserMutationVariables>;

/**
 * __useUnLockUserMutation__
 *
 * To run a mutation, you first call `useUnLockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnLockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unLockUserMutation, { data, loading, error }] = useUnLockUserMutation({
 *   variables: {
 *      password: // value for 'password'
 *      accountID: // value for 'accountID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUnLockUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnLockUserMutation, UnLockUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UnLockUserMutation, UnLockUserMutationVariables>(UnLockUserDocument, baseOptions);
      }
export type UnLockUserMutationHookResult = ReturnType<typeof useUnLockUserMutation>;
export type UnLockUserMutationResult = ApolloReactCommon.MutationResult<UnLockUserMutation>;
export type UnLockUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UnLockUserMutation, UnLockUserMutationVariables>;
export const ReSendActivationEmailDocument = gql`
    mutation reSendActivationEmail($userID: String!) {
  reSendActivationEmail(userID: $userID)
}
    `;
export type ReSendActivationEmailMutationFn = ApolloReactCommon.MutationFunction<ReSendActivationEmailMutation, ReSendActivationEmailMutationVariables>;

/**
 * __useReSendActivationEmailMutation__
 *
 * To run a mutation, you first call `useReSendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReSendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reSendActivationEmailMutation, { data, loading, error }] = useReSendActivationEmailMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useReSendActivationEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReSendActivationEmailMutation, ReSendActivationEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<ReSendActivationEmailMutation, ReSendActivationEmailMutationVariables>(ReSendActivationEmailDocument, baseOptions);
      }
export type ReSendActivationEmailMutationHookResult = ReturnType<typeof useReSendActivationEmailMutation>;
export type ReSendActivationEmailMutationResult = ApolloReactCommon.MutationResult<ReSendActivationEmailMutation>;
export type ReSendActivationEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ReSendActivationEmailMutation, ReSendActivationEmailMutationVariables>;
export const TravelAgentListingDocument = gql`
    query TravelAgentListing($limit: Float, $offset: Float, $SalesChannel: String, $IsActive: Boolean, $DebtorAccount: String, $Name: String, $Adjusted: Boolean, $DebtorCategory: String!, $HotelID: String!) {
  TravelAgentListing(limit: $limit, offset: $offset, SalesChannel: $SalesChannel, IsActive: $IsActive, DebtorAccount: $DebtorAccount, Name: $Name, Adjusted: $Adjusted, DebtorCategory: $DebtorCategory, HotelID: $HotelID) {
    ID
    Name
    IsActive
    IsIndividual
    Contact {
      ID
      Email
      PhoneNo
      AccountID
      Address {
        address
        postCode
        state
        city
        country
      }
    }
    DebtorContact {
      ID
      DebtorID
      ContactID
      Contact {
        ID
        FullName
        Designation
        MobileNo
        Email
        IsActive
      }
    }
    DebtorCategory
    DebtorTypeID
    DebtorAccount
    CreditLimit
    Login
    Password
    Segment
    Remarks
    SalesChannel
    BillingInstruction
    IsFrontDeskActive
  }
}
    `;

/**
 * __useTravelAgentListingQuery__
 *
 * To run a query within a React component, call `useTravelAgentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelAgentListingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      SalesChannel: // value for 'SalesChannel'
 *      IsActive: // value for 'IsActive'
 *      DebtorAccount: // value for 'DebtorAccount'
 *      Name: // value for 'Name'
 *      Adjusted: // value for 'Adjusted'
 *      DebtorCategory: // value for 'DebtorCategory'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useTravelAgentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelAgentListingQuery, TravelAgentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelAgentListingQuery, TravelAgentListingQueryVariables>(TravelAgentListingDocument, baseOptions);
      }
export function useTravelAgentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelAgentListingQuery, TravelAgentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelAgentListingQuery, TravelAgentListingQueryVariables>(TravelAgentListingDocument, baseOptions);
        }
export type TravelAgentListingQueryHookResult = ReturnType<typeof useTravelAgentListingQuery>;
export type TravelAgentListingLazyQueryHookResult = ReturnType<typeof useTravelAgentListingLazyQuery>;
export type TravelAgentListingQueryResult = ApolloReactCommon.QueryResult<TravelAgentListingQuery, TravelAgentListingQueryVariables>;
export const PmsTravelAgentDetailDocument = gql`
    query PMSTravelAgentDetail($HotelID: String!, $ID: String!) {
  PMSTravelAgentDetail(HotelID: $HotelID, ID: $ID) {
    ID
    Name
    IsIndividual
    LastBooking
    Booking {
      ID
    }
    Contact {
      ID
      Email
      PhoneNo
      MobileNo
      AccountID
      Address {
        address
        postCode
        state
        city
        country
      }
    }
    Contract {
      ID
      IsActive
    }
    DebtorContact {
      ID
      DebtorID
      ContactID
      Contact {
        ID
        FullName
        Designation
        MobileNo
        Email
        IsActive
      }
    }
    DebtorCategory
    DebtorTypeID
    DebtorAccount
    CreditLimit
    Login
    Password
    Segment
    Remarks
    SalesChannel
    BillingInstruction
    CompanyRegNo
    Tin
    TaxRegNo
    IseCommerce
    InvoiceType
  }
}
    `;

/**
 * __usePmsTravelAgentDetailQuery__
 *
 * To run a query within a React component, call `usePmsTravelAgentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePmsTravelAgentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePmsTravelAgentDetailQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function usePmsTravelAgentDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PmsTravelAgentDetailQuery, PmsTravelAgentDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<PmsTravelAgentDetailQuery, PmsTravelAgentDetailQueryVariables>(PmsTravelAgentDetailDocument, baseOptions);
      }
export function usePmsTravelAgentDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PmsTravelAgentDetailQuery, PmsTravelAgentDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PmsTravelAgentDetailQuery, PmsTravelAgentDetailQueryVariables>(PmsTravelAgentDetailDocument, baseOptions);
        }
export type PmsTravelAgentDetailQueryHookResult = ReturnType<typeof usePmsTravelAgentDetailQuery>;
export type PmsTravelAgentDetailLazyQueryHookResult = ReturnType<typeof usePmsTravelAgentDetailLazyQuery>;
export type PmsTravelAgentDetailQueryResult = ApolloReactCommon.QueryResult<PmsTravelAgentDetailQuery, PmsTravelAgentDetailQueryVariables>;
export const TravelAgentContractListingDocument = gql`
    query TravelAgentContractListing($DebtorID: String!) {
  TravelAgentContractListing(DebtorID: $DebtorID) {
    ID
    DebtorID
    ContractNo
    Description
    StartDate
    EndDate
    RoomUpgrade
    Elastic
    Allotment
    CutOffDays
    PaymentTerms
    AllowDeleteContract
    Remarks
    IsActive
    IsCMS
    IsKiosk
    ModifiedDT
    ChannelManager
    Debtor {
      ID
      Name
    }
    ContractRate {
      ID
      RatePolicy {
        ID
        Description
        RateCode
      }
    }
    ContractAllotmentByRoomType {
      ID
      AllowDeleteAllotment
      ID
      RoomTypeID
      InitialAllot
      Adjust
      SysAdjust
      PickUp
      SysRelease
      AllotDate
      AllowDeleteAllotment
      RoomType {
        Code
        Description
      }
    }
  }
}
    `;

/**
 * __useTravelAgentContractListingQuery__
 *
 * To run a query within a React component, call `useTravelAgentContractListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentContractListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelAgentContractListingQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useTravelAgentContractListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelAgentContractListingQuery, TravelAgentContractListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelAgentContractListingQuery, TravelAgentContractListingQueryVariables>(TravelAgentContractListingDocument, baseOptions);
      }
export function useTravelAgentContractListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelAgentContractListingQuery, TravelAgentContractListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelAgentContractListingQuery, TravelAgentContractListingQueryVariables>(TravelAgentContractListingDocument, baseOptions);
        }
export type TravelAgentContractListingQueryHookResult = ReturnType<typeof useTravelAgentContractListingQuery>;
export type TravelAgentContractListingLazyQueryHookResult = ReturnType<typeof useTravelAgentContractListingLazyQuery>;
export type TravelAgentContractListingQueryResult = ApolloReactCommon.QueryResult<TravelAgentContractListingQuery, TravelAgentContractListingQueryVariables>;
export const TravelAgentUpdateDocument = gql`
    mutation TravelAgentUpdate($ContactInput: ContactInput!, $DebtorInput: DebtorInput!, $DebtorContactInput: [ContactInput!]) {
  TravelAgentUpdate(ContactInput: $ContactInput, DebtorInput: $DebtorInput, DebtorContactInput: $DebtorContactInput)
}
    `;
export type TravelAgentUpdateMutationFn = ApolloReactCommon.MutationFunction<TravelAgentUpdateMutation, TravelAgentUpdateMutationVariables>;

/**
 * __useTravelAgentUpdateMutation__
 *
 * To run a mutation, you first call `useTravelAgentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelAgentUpdateMutation, { data, loading, error }] = useTravelAgentUpdateMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      DebtorInput: // value for 'DebtorInput'
 *      DebtorContactInput: // value for 'DebtorContactInput'
 *   },
 * });
 */
export function useTravelAgentUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TravelAgentUpdateMutation, TravelAgentUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TravelAgentUpdateMutation, TravelAgentUpdateMutationVariables>(TravelAgentUpdateDocument, baseOptions);
      }
export type TravelAgentUpdateMutationHookResult = ReturnType<typeof useTravelAgentUpdateMutation>;
export type TravelAgentUpdateMutationResult = ApolloReactCommon.MutationResult<TravelAgentUpdateMutation>;
export type TravelAgentUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TravelAgentUpdateMutation, TravelAgentUpdateMutationVariables>;
export const TravelAgentMainUpdateDocument = gql`
    mutation TravelAgentMainUpdate($DebtorInput: DebtorInput!) {
  TravelAgentMainUpdate(DebtorInput: $DebtorInput)
}
    `;
export type TravelAgentMainUpdateMutationFn = ApolloReactCommon.MutationFunction<TravelAgentMainUpdateMutation, TravelAgentMainUpdateMutationVariables>;

/**
 * __useTravelAgentMainUpdateMutation__
 *
 * To run a mutation, you first call `useTravelAgentMainUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentMainUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelAgentMainUpdateMutation, { data, loading, error }] = useTravelAgentMainUpdateMutation({
 *   variables: {
 *      DebtorInput: // value for 'DebtorInput'
 *   },
 * });
 */
export function useTravelAgentMainUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TravelAgentMainUpdateMutation, TravelAgentMainUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TravelAgentMainUpdateMutation, TravelAgentMainUpdateMutationVariables>(TravelAgentMainUpdateDocument, baseOptions);
      }
export type TravelAgentMainUpdateMutationHookResult = ReturnType<typeof useTravelAgentMainUpdateMutation>;
export type TravelAgentMainUpdateMutationResult = ApolloReactCommon.MutationResult<TravelAgentMainUpdateMutation>;
export type TravelAgentMainUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TravelAgentMainUpdateMutation, TravelAgentMainUpdateMutationVariables>;
export const TravelAgentInsertDocument = gql`
    mutation TravelAgentInsert($ContactInput: ContactInput!, $DebtorInput: DebtorInput!, $DebtorContactInput: [ContactInput!]!) {
  TravelAgentInsert(ContactInput: $ContactInput, DebtorInput: $DebtorInput, DebtorContactInput: $DebtorContactInput) {
    ID
  }
}
    `;
export type TravelAgentInsertMutationFn = ApolloReactCommon.MutationFunction<TravelAgentInsertMutation, TravelAgentInsertMutationVariables>;

/**
 * __useTravelAgentInsertMutation__
 *
 * To run a mutation, you first call `useTravelAgentInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelAgentInsertMutation, { data, loading, error }] = useTravelAgentInsertMutation({
 *   variables: {
 *      ContactInput: // value for 'ContactInput'
 *      DebtorInput: // value for 'DebtorInput'
 *      DebtorContactInput: // value for 'DebtorContactInput'
 *   },
 * });
 */
export function useTravelAgentInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TravelAgentInsertMutation, TravelAgentInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TravelAgentInsertMutation, TravelAgentInsertMutationVariables>(TravelAgentInsertDocument, baseOptions);
      }
export type TravelAgentInsertMutationHookResult = ReturnType<typeof useTravelAgentInsertMutation>;
export type TravelAgentInsertMutationResult = ApolloReactCommon.MutationResult<TravelAgentInsertMutation>;
export type TravelAgentInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TravelAgentInsertMutation, TravelAgentInsertMutationVariables>;
export const TravelAgBookingListingDocument = gql`
    query TravelAgBookingListing($DebtorID: String!, $HotelID: String!, $DebtorType: String, $BookingStatus: String, $ArrStartDate: DateTime, $ArrEndDate: DateTime, $DepartStartDate: DateTime, $DepartEndDate: DateTime, $BookingDate: DateTime, $offset: Float, $limit: Float, $BookingNo: String, $FullName: String, $AccountName: String, $ReferenceNo: String, $RoomTypeID: String, $GroupName: String, $SortBy: String, $SortByOrder: String) {
  TravelAgBookingListing(DebtorID: $DebtorID, HotelID: $HotelID, DebtorType: $DebtorType, BookingStatus: $BookingStatus, ArrStartDate: $ArrStartDate, ArrEndDate: $ArrEndDate, DepartStartDate: $DepartStartDate, DepartEndDate: $DepartEndDate, BookingDate: $BookingDate, offset: $offset, limit: $limit, BookingNo: $BookingNo, FullName: $FullName, AccountName: $AccountName, ReferenceNo: $ReferenceNo, RoomTypeID: $RoomTypeID, GroupName: $GroupName, SortBy: $SortBy, SortByOrder: $SortByOrder) {
    ID
    AccountID
    HotelID
    TotalAdvPayment
    Hotel {
      HotelName
      HotelAddress {
        address
        address2
        siteName
        country
        city
        state
        postCode
      }
    }
    Contact {
      FullName
      PhoneNo
      Email
      Nationality
      ID
      MobileNo
      PassportNo
      NRIC
    }
    Debtor {
      DebtorCategory
      ID
      Name
    }
    CreatedDT
    BookingNo
    ArrivalDate
    DepartureDate
    ContactID
    Contact {
      ID
      FullName
      Email
      MobileNo
      PhoneNo
      PassportNo
      NRIC
    }
    GroupName
    SalesChannelID
    SalesChannel {
      ID
      Description
    }
    Source
    SegmentID
    Segment {
      ID
      Description
    }
    SpecialRequest
    DebtorAccount
    BillingInstruction
    Reason
    BookingStatus
    BookedRegistration {
      ID
      RoomID
      CheckInDate
      SharerNo
      CheckOutDate
      PromoCode
      NoOfAdult
      NoOfChild
      NoOfInfant
      IsPrincipal
      Signature
      SpecialRequest
      RegistrationStatus
      RoomTypeID
      RatePolicyID
      RoomType {
        Code
        ID
      }
      RatePolicy {
        RateCode
        ID
        Description
      }
      Guest {
        ID
        VehicleNo
        Contact {
          ID
          FullName
          Email
          NRIC
          PhoneNo
          MobileNo
        }
      }
      RoomRate
      TotalRoomPrice
      TotalRoomTax
      TotalPackagePrice
      TotalPrice
    }
  }
}
    `;

/**
 * __useTravelAgBookingListingQuery__
 *
 * To run a query within a React component, call `useTravelAgBookingListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelAgBookingListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelAgBookingListingQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *      HotelID: // value for 'HotelID'
 *      DebtorType: // value for 'DebtorType'
 *      BookingStatus: // value for 'BookingStatus'
 *      ArrStartDate: // value for 'ArrStartDate'
 *      ArrEndDate: // value for 'ArrEndDate'
 *      DepartStartDate: // value for 'DepartStartDate'
 *      DepartEndDate: // value for 'DepartEndDate'
 *      BookingDate: // value for 'BookingDate'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      BookingNo: // value for 'BookingNo'
 *      FullName: // value for 'FullName'
 *      AccountName: // value for 'AccountName'
 *      ReferenceNo: // value for 'ReferenceNo'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      GroupName: // value for 'GroupName'
 *      SortBy: // value for 'SortBy'
 *      SortByOrder: // value for 'SortByOrder'
 *   },
 * });
 */
export function useTravelAgBookingListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelAgBookingListingQuery, TravelAgBookingListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelAgBookingListingQuery, TravelAgBookingListingQueryVariables>(TravelAgBookingListingDocument, baseOptions);
      }
export function useTravelAgBookingListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelAgBookingListingQuery, TravelAgBookingListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelAgBookingListingQuery, TravelAgBookingListingQueryVariables>(TravelAgBookingListingDocument, baseOptions);
        }
export type TravelAgBookingListingQueryHookResult = ReturnType<typeof useTravelAgBookingListingQuery>;
export type TravelAgBookingListingLazyQueryHookResult = ReturnType<typeof useTravelAgBookingListingLazyQuery>;
export type TravelAgBookingListingQueryResult = ApolloReactCommon.QueryResult<TravelAgBookingListingQuery, TravelAgBookingListingQueryVariables>;
export const TravelAgentContractUpdateDocument = gql`
    mutation TravelAgentContractUpdate($ContractInput: ContractInput!, $RatePolicyIDs: [String!], $RoomTypeList: [ContractAllotmentInput!], $HotelID: String!) {
  TravelAgentContractUpdate(ContractInput: $ContractInput, RatePolicyIDs: $RatePolicyIDs, RoomTypeList: $RoomTypeList, HotelID: $HotelID)
}
    `;
export type TravelAgentContractUpdateMutationFn = ApolloReactCommon.MutationFunction<TravelAgentContractUpdateMutation, TravelAgentContractUpdateMutationVariables>;

/**
 * __useTravelAgentContractUpdateMutation__
 *
 * To run a mutation, you first call `useTravelAgentContractUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentContractUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelAgentContractUpdateMutation, { data, loading, error }] = useTravelAgentContractUpdateMutation({
 *   variables: {
 *      ContractInput: // value for 'ContractInput'
 *      RatePolicyIDs: // value for 'RatePolicyIDs'
 *      RoomTypeList: // value for 'RoomTypeList'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useTravelAgentContractUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TravelAgentContractUpdateMutation, TravelAgentContractUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TravelAgentContractUpdateMutation, TravelAgentContractUpdateMutationVariables>(TravelAgentContractUpdateDocument, baseOptions);
      }
export type TravelAgentContractUpdateMutationHookResult = ReturnType<typeof useTravelAgentContractUpdateMutation>;
export type TravelAgentContractUpdateMutationResult = ApolloReactCommon.MutationResult<TravelAgentContractUpdateMutation>;
export type TravelAgentContractUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TravelAgentContractUpdateMutation, TravelAgentContractUpdateMutationVariables>;
export const TravelAgentContractInsertDocument = gql`
    mutation TravelAgentContractInsert($ContractInput: ContractInput!, $RatePolicyIDs: [String!]!, $RoomTypeList: [ContractAllotmentInput!]!, $HotelID: String!) {
  TravelAgentContractInsert(ContractInput: $ContractInput, RatePolicyIDs: $RatePolicyIDs, RoomTypeList: $RoomTypeList, HotelID: $HotelID) {
    ID
  }
}
    `;
export type TravelAgentContractInsertMutationFn = ApolloReactCommon.MutationFunction<TravelAgentContractInsertMutation, TravelAgentContractInsertMutationVariables>;

/**
 * __useTravelAgentContractInsertMutation__
 *
 * To run a mutation, you first call `useTravelAgentContractInsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentContractInsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelAgentContractInsertMutation, { data, loading, error }] = useTravelAgentContractInsertMutation({
 *   variables: {
 *      ContractInput: // value for 'ContractInput'
 *      RatePolicyIDs: // value for 'RatePolicyIDs'
 *      RoomTypeList: // value for 'RoomTypeList'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useTravelAgentContractInsertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TravelAgentContractInsertMutation, TravelAgentContractInsertMutationVariables>) {
        return ApolloReactHooks.useMutation<TravelAgentContractInsertMutation, TravelAgentContractInsertMutationVariables>(TravelAgentContractInsertDocument, baseOptions);
      }
export type TravelAgentContractInsertMutationHookResult = ReturnType<typeof useTravelAgentContractInsertMutation>;
export type TravelAgentContractInsertMutationResult = ApolloReactCommon.MutationResult<TravelAgentContractInsertMutation>;
export type TravelAgentContractInsertMutationOptions = ApolloReactCommon.BaseMutationOptions<TravelAgentContractInsertMutation, TravelAgentContractInsertMutationVariables>;
export const AllotmentListingDocument = gql`
    query AllotmentListing($ContractID: String!, $RoomTypeID: String, $StartDate: DateTime!, $EndDate: DateTime!) {
  AllotmentListing(ContractID: $ContractID, RoomTypeID: $RoomTypeID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    RoomTypeID
    InitialAllot
    Adjust
    SysAdjust
    PickUp
    SysRelease
    AllotDate
    IsActive
  }
}
    `;

/**
 * __useAllotmentListingQuery__
 *
 * To run a query within a React component, call `useAllotmentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentListingQuery({
 *   variables: {
 *      ContractID: // value for 'ContractID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useAllotmentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllotmentListingQuery, AllotmentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<AllotmentListingQuery, AllotmentListingQueryVariables>(AllotmentListingDocument, baseOptions);
      }
export function useAllotmentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentListingQuery, AllotmentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllotmentListingQuery, AllotmentListingQueryVariables>(AllotmentListingDocument, baseOptions);
        }
export type AllotmentListingQueryHookResult = ReturnType<typeof useAllotmentListingQuery>;
export type AllotmentListingLazyQueryHookResult = ReturnType<typeof useAllotmentListingLazyQuery>;
export type AllotmentListingQueryResult = ApolloReactCommon.QueryResult<AllotmentListingQuery, AllotmentListingQueryVariables>;
export const UpdateAllotmentDocument = gql`
    mutation UpdateAllotment($AllotmentInput: [ContractAllotmentInput!]!, $DebtorID: String!, $RoomTypeID: String!, $HotelID: String!, $StartDate: DateTime!, $EndDate: DateTime!) {
  UpdateAllotment(AllotmentInput: $AllotmentInput, DebtorID: $DebtorID, RoomTypeID: $RoomTypeID, HotelID: $HotelID, StartDate: $StartDate, EndDate: $EndDate)
}
    `;
export type UpdateAllotmentMutationFn = ApolloReactCommon.MutationFunction<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>;

/**
 * __useUpdateAllotmentMutation__
 *
 * To run a mutation, you first call `useUpdateAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllotmentMutation, { data, loading, error }] = useUpdateAllotmentMutation({
 *   variables: {
 *      AllotmentInput: // value for 'AllotmentInput'
 *      DebtorID: // value for 'DebtorID'
 *      RoomTypeID: // value for 'RoomTypeID'
 *      HotelID: // value for 'HotelID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useUpdateAllotmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>(UpdateAllotmentDocument, baseOptions);
      }
export type UpdateAllotmentMutationHookResult = ReturnType<typeof useUpdateAllotmentMutation>;
export type UpdateAllotmentMutationResult = ApolloReactCommon.MutationResult<UpdateAllotmentMutation>;
export type UpdateAllotmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>;
export const TravelAgentDeleteDocument = gql`
    mutation TravelAgentDelete($DebtorID: String!, $HotelID: String!) {
  TravelAgentDelete(DebtorID: $DebtorID, HotelID: $HotelID)
}
    `;
export type TravelAgentDeleteMutationFn = ApolloReactCommon.MutationFunction<TravelAgentDeleteMutation, TravelAgentDeleteMutationVariables>;

/**
 * __useTravelAgentDeleteMutation__
 *
 * To run a mutation, you first call `useTravelAgentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [travelAgentDeleteMutation, { data, loading, error }] = useTravelAgentDeleteMutation({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *      HotelID: // value for 'HotelID'
 *   },
 * });
 */
export function useTravelAgentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TravelAgentDeleteMutation, TravelAgentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TravelAgentDeleteMutation, TravelAgentDeleteMutationVariables>(TravelAgentDeleteDocument, baseOptions);
      }
export type TravelAgentDeleteMutationHookResult = ReturnType<typeof useTravelAgentDeleteMutation>;
export type TravelAgentDeleteMutationResult = ApolloReactCommon.MutationResult<TravelAgentDeleteMutation>;
export type TravelAgentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TravelAgentDeleteMutation, TravelAgentDeleteMutationVariables>;
export const TravelAgentInUseListingDocument = gql`
    query TravelAgentInUseListing($DebtorID: String!) {
  TravelAgentInUseListing(DebtorID: $DebtorID)
}
    `;

/**
 * __useTravelAgentInUseListingQuery__
 *
 * To run a query within a React component, call `useTravelAgentInUseListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentInUseListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelAgentInUseListingQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *   },
 * });
 */
export function useTravelAgentInUseListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelAgentInUseListingQuery, TravelAgentInUseListingQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelAgentInUseListingQuery, TravelAgentInUseListingQueryVariables>(TravelAgentInUseListingDocument, baseOptions);
      }
export function useTravelAgentInUseListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelAgentInUseListingQuery, TravelAgentInUseListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelAgentInUseListingQuery, TravelAgentInUseListingQueryVariables>(TravelAgentInUseListingDocument, baseOptions);
        }
export type TravelAgentInUseListingQueryHookResult = ReturnType<typeof useTravelAgentInUseListingQuery>;
export type TravelAgentInUseListingLazyQueryHookResult = ReturnType<typeof useTravelAgentInUseListingLazyQuery>;
export type TravelAgentInUseListingQueryResult = ApolloReactCommon.QueryResult<TravelAgentInUseListingQuery, TravelAgentInUseListingQueryVariables>;
export const AgentInUseLisitingDocument = gql`
    query AgentInUseLisiting($ID: String!) {
  AgentInUseLisiting(ID: $ID)
}
    `;

/**
 * __useAgentInUseLisitingQuery__
 *
 * To run a query within a React component, call `useAgentInUseLisitingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentInUseLisitingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentInUseLisitingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useAgentInUseLisitingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AgentInUseLisitingQuery, AgentInUseLisitingQueryVariables>) {
        return ApolloReactHooks.useQuery<AgentInUseLisitingQuery, AgentInUseLisitingQueryVariables>(AgentInUseLisitingDocument, baseOptions);
      }
export function useAgentInUseLisitingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgentInUseLisitingQuery, AgentInUseLisitingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AgentInUseLisitingQuery, AgentInUseLisitingQueryVariables>(AgentInUseLisitingDocument, baseOptions);
        }
export type AgentInUseLisitingQueryHookResult = ReturnType<typeof useAgentInUseLisitingQuery>;
export type AgentInUseLisitingLazyQueryHookResult = ReturnType<typeof useAgentInUseLisitingLazyQuery>;
export type AgentInUseLisitingQueryResult = ApolloReactCommon.QueryResult<AgentInUseLisitingQuery, AgentInUseLisitingQueryVariables>;
export const AgentDeleteDocument = gql`
    mutation AgentDelete($ID: String!) {
  AgentDelete(ID: $ID)
}
    `;
export type AgentDeleteMutationFn = ApolloReactCommon.MutationFunction<AgentDeleteMutation, AgentDeleteMutationVariables>;

/**
 * __useAgentDeleteMutation__
 *
 * To run a mutation, you first call `useAgentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agentDeleteMutation, { data, loading, error }] = useAgentDeleteMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useAgentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AgentDeleteMutation, AgentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<AgentDeleteMutation, AgentDeleteMutationVariables>(AgentDeleteDocument, baseOptions);
      }
export type AgentDeleteMutationHookResult = ReturnType<typeof useAgentDeleteMutation>;
export type AgentDeleteMutationResult = ApolloReactCommon.MutationResult<AgentDeleteMutation>;
export type AgentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<AgentDeleteMutation, AgentDeleteMutationVariables>;
export const DebtorAnalysisDocument = gql`
    query DebtorAnalysis($DebtorID: String!, $StartDate: DateTime, $EndDate: DateTime) {
  DebtorAnalysis(DebtorID: $DebtorID, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    ContractAllotment {
      ID
      PickUp
      SysRelease
      AllotDate
    }
  }
}
    `;

/**
 * __useDebtorAnalysisQuery__
 *
 * To run a query within a React component, call `useDebtorAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useDebtorAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDebtorAnalysisQuery({
 *   variables: {
 *      DebtorID: // value for 'DebtorID'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useDebtorAnalysisQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DebtorAnalysisQuery, DebtorAnalysisQueryVariables>) {
        return ApolloReactHooks.useQuery<DebtorAnalysisQuery, DebtorAnalysisQueryVariables>(DebtorAnalysisDocument, baseOptions);
      }
export function useDebtorAnalysisLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DebtorAnalysisQuery, DebtorAnalysisQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DebtorAnalysisQuery, DebtorAnalysisQueryVariables>(DebtorAnalysisDocument, baseOptions);
        }
export type DebtorAnalysisQueryHookResult = ReturnType<typeof useDebtorAnalysisQuery>;
export type DebtorAnalysisLazyQueryHookResult = ReturnType<typeof useDebtorAnalysisLazyQuery>;
export type DebtorAnalysisQueryResult = ApolloReactCommon.QueryResult<DebtorAnalysisQuery, DebtorAnalysisQueryVariables>;
export const TravelAgentRoomRevenueDocument = gql`
    query TravelAgentRoomRevenue($HotelID: String!, $DebtorID: String, $DebtorType: String!, $StartDate: DateTime, $EndDate: DateTime) {
  TravelAgentRoomRevenue(HotelID: $HotelID, DebtorID: $DebtorID, DebtorType: $DebtorType, StartDate: $StartDate, EndDate: $EndDate) {
    ID
    ArrivalDate
    DepartureDate
    IsPrincipal
    Room {
      RoomNo
    }
    Guest {
      Contact {
        FullName
      }
    }
    RoomLedger {
      ID
      BaseAmount
      TrxDate
    }
    BillLedger {
      ID
      BaseAmount
      TrxDate
    }
    BillSchedule {
      ID
      BaseAmount
      TrxDate
    }
    Booking {
      ID
      BookingNo
      CreatedDT
    }
  }
}
    `;

/**
 * __useTravelAgentRoomRevenueQuery__
 *
 * To run a query within a React component, call `useTravelAgentRoomRevenueQuery` and pass it any options that fit your needs.
 * When your component renders, `useTravelAgentRoomRevenueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTravelAgentRoomRevenueQuery({
 *   variables: {
 *      HotelID: // value for 'HotelID'
 *      DebtorID: // value for 'DebtorID'
 *      DebtorType: // value for 'DebtorType'
 *      StartDate: // value for 'StartDate'
 *      EndDate: // value for 'EndDate'
 *   },
 * });
 */
export function useTravelAgentRoomRevenueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TravelAgentRoomRevenueQuery, TravelAgentRoomRevenueQueryVariables>) {
        return ApolloReactHooks.useQuery<TravelAgentRoomRevenueQuery, TravelAgentRoomRevenueQueryVariables>(TravelAgentRoomRevenueDocument, baseOptions);
      }
export function useTravelAgentRoomRevenueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TravelAgentRoomRevenueQuery, TravelAgentRoomRevenueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TravelAgentRoomRevenueQuery, TravelAgentRoomRevenueQueryVariables>(TravelAgentRoomRevenueDocument, baseOptions);
        }
export type TravelAgentRoomRevenueQueryHookResult = ReturnType<typeof useTravelAgentRoomRevenueQuery>;
export type TravelAgentRoomRevenueLazyQueryHookResult = ReturnType<typeof useTravelAgentRoomRevenueLazyQuery>;
export type TravelAgentRoomRevenueQueryResult = ApolloReactCommon.QueryResult<TravelAgentRoomRevenueQuery, TravelAgentRoomRevenueQueryVariables>;