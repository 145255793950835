import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import React, { useEffect } from 'react';
import Logo from 'assets/images/HotelX-Logo.png'
import Background from 'assets/images/HR-Background.jpg'
import { Card, CardContent, Grid, useMediaQuery } from '@material-ui/core';
import { useGetFolioInfoQuery, useHotelDetailsQuery } from 'generated/graphql';
import { amtStr } from 'containers/helper/numFormatter';
import theme from '@ifca-root/react-component/src/assets/theme';
import { useSnackBar } from 'containers/helper/hooks/useSnackBar';
import SnackBarMsg from 'components/SnackBar/SnackBarMsg';

const dateFormat = require('dateformat')

export const EinvoiceQr = props => {
    
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
    
    const url = new URL(window.location.href);
    const segments = url.pathname.split('/');

    const HotelID = segments[2]; 
    const DocType = segments[3];
    const FolioID = segments[4];

    console.log(url,segments,"storedFolio")

	const { snackFunc, close, snackBarMessage, openSnackBar } = useSnackBar()

	const { data: { getFolioInfo } = { getFolioInfo: {} } } = useGetFolioInfoQuery({
		variables: { 
			HotelID: HotelID, 
			DocType: DocType, 
			FolioID: FolioID 
		},
		fetchPolicy: 'network-only',
		onCompleted: data =>{
			if ( data.getFolioInfo?.QrCodeUrl !== null){
				window.location.href = data.getFolioInfo?.QrCodeUrl
			}
		},
		onError: error => {
			error.graphQLErrors.map(({ message }) => {
				snackFunc(message, false)
			})
		},
	});

	const { data: { HotelDetails } = { HotelDetails: null } } = useHotelDetailsQuery({
		variables: {
			HotelID: HotelID,
		},
		fetchPolicy: 'no-cache',
	})

	const hotelLogo = HotelDetails?.LogoImage?.ImageURL

    console.log(getFolioInfo,'getFolioInfo')

    return (
		<AuthLayout image={Background}>
		<br></br>
        <Card style={{ padding: '10px 0'}}>
			<span 
				style={{ 
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',      
					height: '100%',
				}}
			>
				<img 
					src={hotelLogo ? hotelLogo : Logo}
					style={{ 
						width: '150px',
						height: 'auto',
					}}
				/>
			</span>
			<span
				style={{
					fontFamily: 'Poppins, sans-serif',
					fontWeight: 700,
					fontSize: '24px',
					display: 'flex', 
					justifyContent: 'center',
					padding: '5px 0',
					marginBottom: '2px'
				}}
			>
				e-Invoice Request
			</span>
            <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '5px'}}>
                <Card
                    style={{
                        backgroundColor: '#f0f0f0',
                        borderRadius: '8px', 
                        width: isDesktop ? '70%' : '95.5%',
                    }}
                >
					<CardContent>
							<Grid container>
									<Grid item xs={6} style={{ textAlign: 'left' }}>
											<div
												style={{
													fontSize: '12px',
													fontWeight: 400,
													lineHeight: '20px',
												}}
											>
												Document No
											</div>
									</Grid>
									<Grid item xs={1} style={{ textAlign: 'left' }}>
											<div
												style={{
													fontSize: '12px',
													fontWeight: 400,
													lineHeight: '20px',
												}}
											>
												:
											</div>
									</Grid>
									<Grid item xs={5} style={{ textAlign: 'left' }}>
											<div
												style={{
													fontSize: '12px',
													fontWeight: 400,
													lineHeight: '20px',
												}}
											>
												{getFolioInfo?.DocNo}
											</div>
									</Grid>
							</Grid>
							<Grid container>
									<Grid item xs={6} style={{ textAlign: 'left' }}>
											<div
												style={{
													fontSize: '12px',
													fontWeight: 400,
													lineHeight: '20px',
												}}
											>
												Document Date
											</div>
									</Grid>
									<Grid item xs={1} style={{ textAlign: 'left' }}>
											<div
												style={{
													fontSize: '12px',
													fontWeight: 400,
													lineHeight: '20px',
												}}
											>
												:
											</div>
									</Grid>
									<Grid item xs={5} style={{ textAlign: 'left' }}>
											<div
												style={{
													fontSize: '12px',
													fontWeight: 400,
													lineHeight: '20px',
												}}
											>
												{dateFormat(getFolioInfo?.DocDate,'dd/mm/yyyy')}
											</div>
									</Grid>
							</Grid>
							<Grid container>
									<Grid item xs={5} style={{ textAlign: 'left' }}>
											{' '}
											<div
												style={{
													fontSize: '12px',
													fontWeight: 400,
													lineHeight: '20px',
												}}
											>
											
											</div>
									</Grid>
							</Grid>
							<Grid container>
									<Grid item xs={6} style={{ textAlign: 'left' }}>
											<div
												style={{
													fontSize: '12px',
													fontWeight: 400,
													lineHeight: '20px',
												}}
											>
												Amount
											</div>
									</Grid>
									<Grid item xs={1} style={{ textAlign: 'left' }}>
											<div
												style={{
													fontSize: '12px',
													fontWeight: 400,
													lineHeight: '20px',
												}}
											>
												:
											</div>
									</Grid>
									<Grid item xs={5} style={{ textAlign: 'left' }}>
											<div
												style={{
													fontSize: '12px',
													fontWeight: 400,
													lineHeight: '20px',
												}}
											>
												{amtStr(getFolioInfo?.DocAmt)}
											</div>
									</Grid>
							</Grid>
							<span 
								className='fw-bold fs-25'
								style={{
									color: 'red',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: '10px'
								}}
							>
								<span>Work in progress</span>
								<span>Please wait....</span>
							</span>
					</CardContent>
                </Card>
            </div>
        </Card>
        <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
    </AuthLayout>
    )
  
}
