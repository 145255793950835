import { getAccessToken } from "AccessToken";
import { hotelxSocketUrl } from "client";
import React, { createContext, useState, useEffect } from 'react';
import io from 'socket.io-client';

export const SocketContext = createContext(null); // Use null as the initial value
export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const token = getAccessToken();
  const urlInfo =  window.location.pathname

  useEffect(() => {
    const newSocket = io(hotelxSocketUrl,{transports:["polling"], upgrade: false});
    setSocket(newSocket);

    // Show the popup dialog when the user tries to leave the page
    const onBeforeUnload = () => {
      newSocket.emit('data', {
        Status: 'LOGOUT',
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
      });
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    // Reconnect when the token is available
    if (token) {
      newSocket.emit('data', {
        Status: 'LOGIN',
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
      });

      const urlPage =  window.location.pathname
      const folioPage = urlPage?.includes('/payment/folio')
      const userLock = JSON.parse(localStorage.getItem('LoggedInUser'))?.LockUserProcess

      if (folioPage === true && userLock === true) {
        window.history.back();
      }
    }

    // Cleanup function
    return () => {
      newSocket.disconnect();
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [token]); // Add token as a dependency

  useEffect(() => {
    const urlPage =  window.location.pathname
    const folioPage = urlPage?.includes('/payment/folio')
    const userLock = JSON.parse(localStorage.getItem('LoggedInUser'))?.LockUserProcess

    if (socket) {
      if (folioPage === false && userLock === true ) {
        socket.emit('ClearUserProcess', {
          HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
          UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
        });
        const LoggedInUserInfo = localStorage.getItem('LoggedInUser')
        const userObject = LoggedInUserInfo ? JSON.parse(LoggedInUserInfo) : {}
        userObject.LockUserProcess = false
        localStorage.setItem('LoggedInUser', JSON.stringify(userObject))
        localStorage.removeItem('RoomNo')
      }
    }
  }, [socket, urlInfo])

  const LOGOUT = () => {
    if (socket) {
      socket.emit('data', {
        Status: 'LOGOUT',
        HotelID: JSON.parse(localStorage.getItem('Hotel'))?.ID,
        UserID: JSON.parse(localStorage.getItem('LoggedInUser'))?.ID,
      });
      socket.disconnect();
    }
  }

  return (
    <SocketContext.Provider
      value={{
        socket,
        LOGOUT
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
